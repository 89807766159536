import GreenTagJacket from 'assets/img/uniforms/sportOutfit/jacket/GreenTagJacket'
import JacketSvg from 'assets/img/uniforms/sportOutfit/jacket/JacketSvg'
import LabelJacket from 'assets/img/uniforms/sportOutfit/jacket/LabelJacket'
import ShadowsJacket from 'assets/img/uniforms/sportOutfit/jacket/ShadowsJacket'
import React,{Fragment} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'


const JacketPics = () => {
    const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div
            className={`${
              style[`logo-position-${"left"}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <JacketSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsJacket className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelJacket className={`${style['div-label-svg-absolute']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagJacket className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
  )
}

export default JacketPics