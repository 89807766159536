import { Modal } from 'antd'
import { memo } from 'react'

interface ModalProps {
  title: string
  open: boolean
  width?:number
  style?:any
  onCancel: () => any
  children: JSX.Element
  centered?:boolean
}

const Modals = memo((props: ModalProps) => {
  return (
    <Modal
      {...props}
      footer={null}
    >
      {props.children}
    </Modal>
  )
})

export default Modals
