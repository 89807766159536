import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

import FaldaShortFrontalSvg from 'assets/img/uniforms/sportOutfit/faldaShort/frontal/FaldaShortFrontalSvg'
import LabelFaldaShort from 'assets/img/uniforms/sportOutfit/faldaShort/frontal/LabelFaldaShort'
import { handleClotheName } from 'redux/reducers/uniformsSlice'

const FaldaShortEditable = () => {
  const { faldaShortDeportivaForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Falda short deportiva',
        description: 'Descripcion de la Falda short deportiva'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {faldaShortDeportivaForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-faldashort-${faldaShortDeportivaForm.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}>
            <FaldaShortFrontalSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <LabelFaldaShort className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default FaldaShortEditable
