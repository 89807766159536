import React, { memo, useEffect, useState } from 'react'
import { Col, Row, Tabs } from 'antd'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import type { TabsProps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import UtilsTable from './utilsTable/UtilsTable'
import BookTable from './bookTable/BookTable'
import NotebookTable from './notebookTable/NotebookTable'
import QuotasTable from './QuotasTable/QuotasTable'

const index = memo(() => {
  const items: TabsProps['items'] = [
    {
      key: 'libros',
      label: `Libros`,
      children: <BookTable />
    },
    {
      key: 'cuadernos',
      label: `Cuadernos`,
      children: <NotebookTable/>
    },
    {
      key: 'uniformes',
      label: `Uniformes`,
      children: 'uniformes'
    },
    {
      key: 'utiles',
      label: `Útiles`,
      children: <UtilsTable />
    },
    {
      key: 'cuotas',
      label: `Cuotas`,
      children: <QuotasTable />
    }
  ]

  const location = useLocation()
  const navigate = useNavigate()
  const query: any = useQuery()
  const [activeTab, setactiveTab] = useState('')

  const onChange = (key: string) => {
    const path = {
      pathname: '.',
      search: `?module=${key}`
    }
    const state = { state: location.state }
    navigate(path, state)
  }
  useEffect(() => {
    if (query) setactiveTab(query?.module)
  }, [query, location.search])
  return (
    <Row align='middle' gutter={[0, 16]}>
      <Col span={24}>
        <TitleHeader
          IconName='estructure'
          title='Lista de elementos escolares'
        />
        <Tabs activeKey={activeTab} items={items} onChange={onChange} />
      </Col>
    </Row>
  )
})
index.displayName = 'ElementsList'
export default index
