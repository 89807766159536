import CamisaSvg from 'assets/img/uniforms/camisa/CamisaSvg'
import GreenTagCamisa from 'assets/img/uniforms/camisa/GreenTagCamisa'
import React,{Fragment} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'


const CamisaMCMLPics = () => {
    const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
    <Fragment>
      <div
        className={`${
          style[`logo-position-${"left"}`]
        }`}
      >
        <img className={`${style['svg-logo']}`} src={pictureLogo} />
      </div>
      <div className={`${style['div-polo-svg-absolute']}`}>
        <CamisaSvg className={`${style['svg']}`} />
      </div>
      <div className={`${style['div-shadows-svg-absolute']}`}>
        <GreenTagCamisa className={`${style['svg']}`} />
      </div>
    </Fragment>
  </div>
  )
}

export default CamisaMCMLPics