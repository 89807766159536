import { Spin } from 'antd'
import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../notebookEditables/notebooksEitable.module.scss'

const DraggableImgCenterProporsal = ({
  item,
  edithSides,
  positions,
  setPositions,
  onlyShow = false
}: {
  item: number
  edithSides: { left: boolean; right: boolean }
  positions: { back: number; front: number }
  setPositions: (dat: { back: number; front: number }) => void
  onlyShow?: boolean
}) => {
  const { notebookFrontValues, outsidePropuesta, outsidePropuestaBack } =
    useSelector((state: RootState) => state.notebooks)

  const { rounded } = notebookFrontValues

  const [proprotion, setProprotion] = useState({ h: 0, w: 0 })
  const [flag, setflag] = useState(!onlyShow)
  const containerDivRef = useRef<HTMLDivElement>(null)
  const dragableDivRef = useRef<HTMLDivElement>(null)
  const ImgRef = useRef<HTMLImageElement>(null)
  const isClickedDrag = useRef<boolean>(false)
  const coord = useRef<{
    startX: number
    startY: number
    lastX: number
    lastY: number
  }>({
    startX: 0,
    startY: 0,
    lastX: 0,
    lastY: 0
  })

  useEffect(() => {
    if (!ImgRef.current) return
    const img = ImgRef.current
    const h = img.naturalHeight
    const w = img.naturalWidth
    if (h !== 0 && w !== 0) setProprotion({ h, w })
    else setflag(!flag)
  }, [ImgRef.current, flag])

  useEffect(() => {
    if (!containerDivRef.current || !dragableDivRef.current || !ImgRef.current)
      return
    const img = ImgRef.current
    const inSquare = dragableDivRef.current
    if (img.naturalHeight > img.naturalWidth) {
      if (item === 1) {
        inSquare.style.top = `${positions.back}%`
        coord.current.lastY = positions.back
      }
      if (item === 2) {
        inSquare.style.top = `${positions.front}%`
        coord.current.lastY = positions.front
      }
    } else {
      if (item === 1) {
        inSquare.style.left = `${positions.back}%`
        coord.current.lastX = positions.back
      }
      if (item === 2) {
        inSquare.style.left = `${positions.front}%`
        coord.current.lastX = positions.front
      }
    }
  }, [
    ImgRef.current,
    dragableDivRef.current,
    containerDivRef.current,
    proprotion
  ])

  useEffect(() => {
    if (!containerDivRef.current || !dragableDivRef.current || !ImgRef.current)
      return

    const container = containerDivRef.current
    const inSquare = dragableDivRef.current
    const img = ImgRef.current

    const onMouseDownSquare = (e: MouseEvent) => {
      isClickedDrag.current = true
      coord.current.startX = e.clientX
      coord.current.startY = e.clientY
      inSquare.style.background = '#d9d9d9'
    }
    const onMouseUpSquare = (_e: MouseEvent) => {
      isClickedDrag.current = false
      coord.current.lastX = inSquare.offsetLeft
      coord.current.lastY = inSquare.offsetTop
      inSquare.style.background = 'transparent'
      if (img.naturalHeight > img.naturalWidth) {
        if (item === 1)
          setPositions({ ...positions, back: parseInt(inSquare.style.top) })
        if (item === 2)
          setPositions({ ...positions, front: parseInt(inSquare.style.top) })
      } else {
        if (item === 1)
          setPositions({ ...positions, back: parseInt(inSquare.style.left) })
        if (item === 2)
          setPositions({ ...positions, front: parseInt(inSquare.style.left) })
      }
    }
    const onMouseLeaveContainer = () => {
      isClickedDrag.current = false
      coord.current.lastX = inSquare.offsetLeft
      coord.current.lastY = inSquare.offsetTop
      if (img.naturalHeight > img.naturalWidth) {
        if (item === 1)
          setPositions({ ...positions, back: parseInt(inSquare.style.top) })
        if (item === 2)
          setPositions({ ...positions, front: parseInt(inSquare.style.top) })
      } else {
        if (item === 1)
          setPositions({ ...positions, back: parseInt(inSquare.style.left) })
        if (item === 2)
          setPositions({ ...positions, front: parseInt(inSquare.style.left) })
      }
    }
    const onMouseMoveContainer = (e: MouseEvent) => {
      const fn = () => {
        if (isClickedDrag.current) {
          const nextX = e.clientX - coord.current.startX + coord.current.lastX
          const nextY = e.clientY - coord.current.startY + coord.current.lastY

          const yPercent = (nextY / container.clientHeight) * 100
          const xPercent = (nextX / container.clientWidth) * 100

          if (img.naturalHeight > img.naturalWidth) {
            if (nextY > 0) inSquare.style.top = `${0}%`
            else if (nextY > container.clientHeight - inSquare.clientHeight)
              inSquare.style.top = `${yPercent}%`
          } else {
            if (nextX > 0) inSquare.style.left = `${0}%`
            else if (nextX > container.clientWidth / 4 - inSquare.clientWidth)
              inSquare.style.left = `${xPercent}%`
          }
        }
      }

      if (item === 1 && edithSides.left) fn()
      if (item === 2 && edithSides.right) fn()
    }

    inSquare.addEventListener('mousedown', onMouseDownSquare)
    inSquare.addEventListener('mouseup', onMouseUpSquare)
    container.addEventListener('mousemove', onMouseMoveContainer)
    container.addEventListener('mouseleave', onMouseLeaveContainer)

    const cleanup = () => {
      inSquare.removeEventListener('mousedown', onMouseDownSquare)
      inSquare.removeEventListener('mouseup', onMouseUpSquare)
      container.removeEventListener('mousemove', onMouseMoveContainer)
      container.removeEventListener('mouseleave', onMouseLeaveContainer)
    }
    return cleanup
  }, [
    ImgRef.current,
    dragableDivRef.current,
    containerDivRef.current,
    proprotion
  ])

  return (
    <div
      className={`${style['div-upload-container-relative']} 
      ${item === 1 && style['transform-invert']}`}
    >
      {proprotion.h !== 0 && proprotion.w !== 0 ? (
        <div
          className={`${
            item === 1
              ? style['div-img-upload-inverter']
              : style['div-img-upload']
          } 
        
        ${item === 1 && rounded.value && style['rounded-borders-left']}
        ${item === 2 && rounded.value && style['rounded-borders']}
        ${item === 1 && !edithSides.left && style['overflow-hidden']}
          ${item === 2 && !edithSides.right && style['overflow-hidden']}
        `}
        >
          <div
            ref={containerDivRef}
            className={`${style['div-img-upload-container']}
          ${
            !(proprotion.h > proprotion.w) &&
            style['div-img-upload-container-width']
          }

          ${item === 1 && !edithSides.left && style['overflow-hidden']}
          ${item === 2 && !edithSides.right && style['overflow-hidden']}
          `}
          >
            <div
              ref={dragableDivRef}
              className={`
            ${
              proprotion.h > proprotion.w
                ? style['div-img-container-22']
                : style['div-img-container-33']
            }
            ${item === 1 && edithSides.left && style['number-opacity']}
            ${item === 2 && edithSides.right && style['number-opacity']}
            `}
            >
              {outsidePropuesta !== '' && item === 2 && (
                <img
                  ref={ImgRef}
                  onLoad={(e) => {
                    setProprotion({
                      h: e.currentTarget.naturalHeight,
                      w: e.currentTarget.naturalWidth
                    })
                  }}
                  draggable={false}
                  className={`${style['img-upload']}`}
                  src={outsidePropuesta}
                />
              )}
              {outsidePropuestaBack !== '' &&
                outsidePropuestaBack !== 'vacio' &&
                item === 1 && (
                  <img
                    ref={ImgRef}
                    onLoad={(e) => {
                      setProprotion({
                        h: e.currentTarget.naturalHeight,
                        w: e.currentTarget.naturalWidth
                      })
                    }}
                    draggable={false}
                    className={`${style['img-upload']} `}
                    src={outsidePropuestaBack}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${style['center-spin']}`}>
          <div className={`${style['hidde']}`}>
            {outsidePropuesta !== '' && item === 2 && (
              <img
                ref={ImgRef}
                onLoad={(e) => {
                  setProprotion({
                    h: e.currentTarget.naturalHeight,
                    w: e.currentTarget.naturalWidth
                  })
                }}
                draggable={false}
                className={`${style['img-upload']}`}
                src={outsidePropuesta}
              />
            )}
            {outsidePropuestaBack !== '' &&
              outsidePropuestaBack !== 'vacio' &&
              item === 1 && (
                <img
                  ref={ImgRef}
                  onLoad={(e) => {
                    setProprotion({
                      h: e.currentTarget.naturalHeight,
                      w: e.currentTarget.naturalWidth
                    })
                  }}
                  draggable={false}
                  className={`${style['img-upload']} `}
                  src={outsidePropuestaBack}
                />
              )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DraggableImgCenterProporsal
