import { IJsonParameters } from 'interfaces/notebooks-interface'
import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  setIdTextBack,
  setIdTextFront,
  setImgIdBack,
  setImgIdFront,
  setJsonImgBack,
  setJsonImgFront,
  setJsonTextBack,
  setJsonTextfront,
  setTextPositionNow
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import DraggableCovers from '../editor/components/EditorDiv/DraggableCovers'
import style from './notebooksDownload.module.scss'
const HorizontalDownloadDragable = ({ viewMode = -1 }) => {
  const {
    jsonImgBack,
    jsonImgfront,
    jsonTextBack,
    jsonTextfront,
    TextBackId,
    TextfrontkId,
    textPositionNow,
    ImgBackId,
    ImgfrontkId,
    isCoverOnEdith,
    notebookFrontValues
  } = useSelector((state: RootState) => state.notebooks)
  const leftAbsolute = useRef<HTMLDivElement>(null)
  const rightAbsolute = useRef<HTMLDivElement>(null)
  const [flagReset, setFlagReset] = useState(false)
  const [ params ] = useSearchParams()
  const dispatch = useDispatch()

  const { rounded, spine_color } = notebookFrontValues

  const eraseTextBack = (id: number) => {
    let values = jsonTextBack
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonTextBack(values))
  }
  const eraseImgBack = (id: number) => {
    let values = jsonImgBack
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonImgBack(values))
  }

  const eraseTextFront = (id: number) => {
    let values = jsonTextfront
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonTextfront(values))
  }
  const eraseImgFront = (id: number) => {
    let values = jsonImgfront
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonImgFront(values))
  }

  const handleTextSizeBiIdBack = (id: number) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text }
        }
      })
    )
  }
  const handleImgSizeBiIdBack = (id: number) => {
    dispatch(
      setJsonImgBack({
        ...jsonImgBack,
        [id]: {
          ...textPositionNow,
          img: jsonImgBack[id].img
        }
      })
    )
  }
  const handleTextSizeBiIdFront = (id: number) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text }
        }
      })
    )
  }
  const handleImgSizeBiIdFront = (id: number) => {
    dispatch(
      setJsonImgFront({
        ...jsonImgfront,
        [id]: {
          ...textPositionNow,
          img: jsonImgfront[id].img
        }
      })
    )
  }

  const resetIds = () => {
    dispatch(setIdTextFront(0))
    dispatch(setIdTextBack(0))
    dispatch(setImgIdBack(0))
    dispatch(setImgIdFront(0))
  }

  useEffect(() => {
    if (TextBackId !== 0) handleTextSizeBiIdBack(TextBackId)
  }, [TextBackId])
  useEffect(() => {
    if (TextfrontkId !== 0) handleTextSizeBiIdFront(TextfrontkId)
  }, [TextfrontkId])

  useEffect(() => {
    if (ImgBackId !== 0) handleImgSizeBiIdBack(ImgBackId)
  }, [ImgBackId])
  useEffect(() => {
    if (ImgfrontkId !== 0) handleImgSizeBiIdFront(ImgfrontkId)
  }, [ImgfrontkId])

  useEffect(() => {
    if (!flagReset) setFlagReset(true)
    if (!isCoverOnEdith) resetIds()
  }, [isCoverOnEdith])

  useEffect(() => {
    if (!flagReset) setFlagReset(true)
  }, [jsonImgBack, jsonImgfront, jsonTextBack, jsonTextfront])

  useEffect(() => {
    if (flagReset) setFlagReset(false)
  }, [flagReset])

  return (
    <div className={`${style['ppp']} `}>
      {!flagReset && (
        <div className={`${style['dragable-grid']}`}>
          {(params.has('viewMode') && params.get('viewMode') == '2') && (
            <div
              ref={leftAbsolute}
              style={{backgroundColor:(spine_color.value as string)}}
              className={`${
                viewMode == 2
                  ? style['Position-left-draggable-horizontal-2']
                  : style['Position-left-draggable-horizontal']
              }
                  ${!isCoverOnEdith && style['overflow-hidden']}
                  ${
                    !isCoverOnEdith &&
                    rounded.value &&
                    style['border-left-rounded-hori']
                  }
                  `}
            >
              <DraggableCovers
                setpos={() => {
                  // setPositionsImg(jsonImgBack, 'Back')
                  // setPositionsText(jsonTextBack, 'Back')
                }}
                customContainerRef={leftAbsolute}
                jsonImgPositions={jsonImgBack}
                jsonTextPositions={jsonTextBack}
                IdEraseText={eraseTextBack}
                IdEraseImg={eraseImgBack}
                selectedIdImg={ImgBackId}
                selectedIdText={TextBackId}
                setImgId={(dat: number, MyInfo: IJsonParameters) => {
                  if (isCoverOnEdith) {
                    dispatch(setTextPositionNow(MyInfo))
                    dispatch(setImgIdBack(dat))
                    dispatch(setImgIdFront(0))
                    dispatch(setIdTextFront(0))
                    dispatch(setIdTextBack(0))
                  }
                }}
                setTextId={(dat: number, MyInfo: IJsonParameters) => {
                  if (isCoverOnEdith) {
                    dispatch(setTextPositionNow(MyInfo))
                    dispatch(setIdTextBack(dat))
                    dispatch(setIdTextFront(0))
                    dispatch(setImgIdFront(0))
                    dispatch(setImgIdBack(0))
                  }
                }}
                resetIds={resetIds}
              />
            </div>
          )}
          {(params.has('viewMode') && params.get('viewMode') == '1') && (
            <div
              ref={rightAbsolute}
              style={{backgroundColor:(spine_color.value as string)}}
              className={`${
                viewMode == 1
                  ? style['Position-right-draggable-horizontal-2']
                  : style['Position-right-draggable-horizontal']
              } 
                  ${!isCoverOnEdith && style['overflow-hidden']}
                  ${
                    !isCoverOnEdith &&
                    rounded.value &&
                    style['border-right-rounded-hori']
                  }
                  `}
            >
              <DraggableCovers
                customContainerRef={rightAbsolute}
                setpos={() => {
                  // setPositionsImg(jsonImgfront, 'Front')
                  // setPositionsText(jsonTextfront, 'Front')zj
                }}
                jsonImgPositions={jsonImgfront}
                jsonTextPositions={jsonTextfront}
                IdEraseText={eraseTextFront}
                IdEraseImg={eraseImgFront}
                selectedIdImg={ImgfrontkId}
                selectedIdText={TextfrontkId}
                setImgId={(dat: number, MyInfo: IJsonParameters) => {
                  if (isCoverOnEdith) {
                    dispatch(setTextPositionNow(MyInfo))
                    dispatch(setImgIdFront(dat))
                    dispatch(setImgIdBack(0))
                    dispatch(setIdTextBack(0))
                    dispatch(setIdTextFront(0))
                  }
                }}
                setTextId={(dat: number, MyInfo: IJsonParameters) => {
                  if (isCoverOnEdith) {
                    dispatch(setTextPositionNow(MyInfo))
                    dispatch(setIdTextFront(dat))
                    dispatch(setIdTextBack(0))
                    dispatch(setImgIdBack(0))
                    dispatch(setImgIdFront(0))
                  }
                }}
                resetIds={resetIds}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default HorizontalDownloadDragable
