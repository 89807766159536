import { TFormCorbataOptions } from 'interfaces/uniforms-interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleCorbataForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const CorbataOptionsEditable = () => {
  const { corbataForm } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormCorbataOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleCorbataForm({
        ...corbataForm,
        [keyName]: {
          ...corbataForm[keyName as TFormCorbataOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary
            handleChange={handleChange}
            form={corbataForm}
          />
        )
      })()}
    </div>
  )
}

export default CorbataOptionsEditable
