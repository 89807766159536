import { Select, Table, Typography } from 'antd'
import React, { memo } from 'react'
import { useNotebookTable } from './hook/useNotebookTable'
const NotebookTable = memo(() => {
  const {
    notebooks,
    loading,
    pagination,
    NotebookColumn,
    statusCatalog,
    onHandleChangeStatus
  } = useNotebookTable()
  return (
    <div>
      <div>
        <Typography>Estatus</Typography>
        <Select
          style={{ width: 200 }}
          defaultValue={'todos'}
          placeholder={'Estatus'}
          onChange={onHandleChangeStatus}
          options={statusCatalog.filter((item: any) => item.label !== 'Nuevo')}
        />
      </div>
      <Table
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        dataSource={notebooks}
        style={{ marginTop: '2.5rem' }}
        columns={NotebookColumn}
        loading={loading}
        pagination={pagination}
        onRow={() => {
          return {
            onClick: () => undefined
          }
        }}
      />
    </div>
  )
})
NotebookTable.displayName = 'NotebookTable'
export default NotebookTable
