import React from 'react'

const GreenTagCamisa = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Green_Tag" data-name="Green Tag" stroke='black'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <g id="Green_Tag-3" data-name="Green Tag">
        <rect   x="373.53" y="64.21" width="45.48" height="42.91"/>
        <g>
          <path   d="m390.88,71.87s.4-.02,1.09.28c.7.28,1.49.72,2.07,1.1.58.38,1.2.97,1.4,1.22.19.27.66.98.67,1.12.03.16-.02.53-.16.82-.15.29-.53.86-.7,1.05-.17.19-.32.34-.37.36-.05.02-.08.09-.08.09,0,0,0,.03,0,.06,0,.04.02.12.02.12,0,0-.4.29-.8.55-.4.26-.69.42-.85.52-.15.09-.28.21-.28.21,0,0-.03.1,0,.1.05,0,.71-.08,2.01-.38,1.31-.3,2.09-.52,2.6-.81.51-.29.73-.65.86-.83.12-.17.16-.33.21-.5.05-.17.07-.29.07-.29,0,0,.37-.12.6-.28.24-.16.57-.45.69-.56.12-.1.31-.19.41-.24.1-.03.27-.09.27-.09,0,0-.05-.11-.07-.14-.02-.02-.15-.22-.15-.33,0-.12-.19-.42-.31-.42-.13,0-.34-.01-.47.01-.13.02-.33.06-.41.07-.08,0-.14-.01-.14-.01,0,0,0-.08.05-.15.03-.07.29-.75.25-1.11-.04-.35-.24-1.29-.46-1.87-.21-.58-.55-1.35-.65-1.55-.11-.19-.15-.23-.18-.15-.02.09-.01.41,0,.75.03.33.05.56.07.62.02.06,0,.33,0,.38,0,.04-.03.17-.02.19.01.01.02.08.02.08l-.13.04s-.01.16-.04.27c-.03.11-.12.46-.26.84-.14.38-.28.76-.37.92-.08.17-.17.3-.22.3-.05,0-.43-.23-.9-.49-.46-.26-.81-.55-1.3-.94-.48-.39-1.11-1.39-1.19-1.54-.1-.14-.35-.6-.62-1.04-.26-.46-.64-1.08-.78-1.29-.15-.21-.34-.43-.38-.5-.05-.05-.16-.15-.19-.12-.03.03,0,.07.03.14.03.08.44.72.68,1.12.24.4.72,1.25.85,1.52.14.26.37.76.45.94.08.18.1.48.1.48,0,0-.08,0-.33-.07-.25-.07-1.19-.48-1.4-.53-.19-.08-.71-.22-.85-.23-.13,0-.2.01-.27.02-.07,0-.24.03-.16.18Z"/>
          <g>
            <path   d="m383.96,84.59v.22l-.42.14s-.08.03-.1.07c-.02.01-.04.06-.05.11-.01.12-.03.4-.03.86,0,.28.02.64.03,1.11-.09.02-.26.08-.52.15-.36.11-.57.17-.67.18-.18.04-.37.04-.57.04-.66,0-1.16-.23-1.51-.7-.36-.47-.53-1.11-.53-1.93,0-.87.21-1.6.62-2.14.41-.55.95-.83,1.63-.83.17,0,.33.01.5.04.02.01.34.08.94.24v.33c0,.33.01.72.03,1.15h-.2c-.09-.44-.18-.72-.25-.86-.08-.13-.19-.25-.37-.33-.22-.12-.46-.17-.72-.17-.57,0-1.01.29-1.3.88-.19.42-.29.93-.29,1.54,0,.78.16,1.37.47,1.77.32.4.73.6,1.21.6.32,0,.63-.09.97-.27.01-.27.02-.67.02-1.2,0-.28,0-.45-.02-.5,0-.05-.03-.09-.06-.12-.04-.03-.17-.07-.37-.13l-.11-.03v-.22l.94.02c.22,0,.45,0,.71-.02Z"/>
            <path   d="m384.32,82.91v-.19c.46.03.83.03,1.11.03.15,0,.31,0,.44,0,.19,0,.31-.01.38-.01.34,0,.6.1.78.31.17.22.26.48.26.84,0,.24-.04.44-.12.63-.08.18-.19.33-.34.45-.11.09-.26.17-.45.25l.87,1.45c.1.18.17.29.2.32.05.05.1.09.17.12.1.06.26.11.49.18v.18c-.28-.03-.48-.03-.6-.03-.14,0-.29,0-.44.03-.09-.16-.18-.37-.32-.62-.14-.25-.22-.41-.25-.45-.01-.03-.03-.06-.04-.09l-.59-1.01c-.14-.01-.3-.02-.46-.05v1.69c.01.08.03.12.07.15.03.04.17.09.39.15.03,0,.08.01.14.04v.18c-.31-.03-.59-.03-.83-.03-.26,0-.55,0-.87.03v-.18l.11-.04c.25-.06.39-.11.42-.15.04-.04.06-.11.06-.21.01-.07.01-.63.01-1.72v-1.58c0-.24-.01-.37-.02-.41-.01-.03-.03-.06-.06-.08-.08-.06-.22-.1-.4-.15-.03,0-.07-.02-.13-.03Zm1.1,2.05c.18.04.32.04.42.04.32,0,.55-.1.7-.27.15-.18.24-.44.24-.75,0-.29-.07-.52-.21-.67-.13-.17-.33-.25-.59-.25-.1,0-.27,0-.55.04v1.85Z"/>
            <path   d="m392.8,84.82h.59c.23,0,.36-.01.41-.02.04-.02.08-.06.1-.1.03-.06.06-.21.09-.44,0-.03.02-.07.02-.13h.16c-.01.36-.02.66-.02.86,0,.19.01.48.02.83h-.16c-.05-.32-.08-.5-.08-.55-.02-.05-.06-.08-.09-.11-.05-.01-.16-.03-.37-.03h-.68v1.96h1.13c.14,0,.24-.01.27-.03.06-.04.12-.07.16-.14.06-.06.14-.26.27-.58.01-.03.02-.08.07-.14h.16c-.02.07-.12.49-.3,1.25-.59-.03-1.14-.03-1.64-.03-.34,0-.72,0-1.15.03v-.18l.4-.13c.07-.03.11-.05.13-.07.02-.06.03-.14.03-.27v-.84c.01-.15.01-.45.01-.89,0-.65,0-1.12-.01-1.43,0-.29,0-.45-.02-.51-.02-.03-.02-.04-.06-.06-.01-.01-.09-.05-.21-.08l-.27-.09v-.18c.31.01.68.03,1.11.03.23,0,.8-.01,1.68-.03,0,.37.02.75.06,1.13h-.17c-.07-.3-.12-.5-.17-.6-.02-.06-.06-.12-.1-.15-.06-.02-.17-.03-.36-.03h-1l-.02,1.07v.67Z"/>
            <path   d="m389.32,84.82h.6c.23,0,.36-.01.4-.02.05-.02.08-.06.11-.1.02-.06.06-.21.09-.44,0-.03,0-.07.03-.13h.16c-.02.36-.04.66-.04.86,0,.19.01.48.04.83h-.16c-.06-.32-.08-.5-.1-.55-.02-.05-.05-.08-.08-.11-.05-.01-.16-.03-.37-.03h-.67v1.96h1.14c.14,0,.23-.01.26-.03.06-.04.11-.07.16-.14.05-.06.14-.26.27-.58,0-.03.03-.08.06-.14h.17c-.02.07-.13.49-.31,1.25-.6-.03-1.14-.03-1.64-.03-.34,0-.73,0-1.14.03v-.18l.4-.13c.07-.03.1-.05.12-.07.03-.06.03-.14.03-.27v-.06l.02-.78v-.89c0-.65,0-1.12,0-1.43,0-.29-.01-.45-.02-.51-.01-.03-.03-.04-.05-.06-.01-.01-.09-.05-.22-.08l-.27-.09v-.18c.31.01.68.03,1.11.03.23,0,.79-.01,1.67-.03,0,.37.02.75.05,1.13h-.17c-.06-.3-.12-.5-.16-.6-.03-.06-.06-.12-.1-.15-.06-.02-.18-.03-.36-.03h-1v1.07s-.02.67-.02.67Z"/>
            <path   d="m395.27,82.83v-.2c.19.03.36.05.49.05.09,0,.27-.01.52-.05l.7,1.19c.07.1.56.92,1.48,2.44.01-.32.01-.55.01-.68,0-.34-.02-.94-.03-1.82,0-.41-.01-.64-.02-.68,0-.04-.02-.06-.05-.09-.03-.03-.14-.07-.34-.13-.02,0-.06-.02-.11-.03v-.2c.29.03.52.05.7.05.17,0,.4-.01.69-.05v.2s-.08.03-.08.03c-.24.06-.36.1-.38.12-.03.01-.04.04-.05.06-.01.04-.03.11-.04.2,0,.03,0,.34,0,.91-.01.57-.01,1.11-.01,1.64,0,.62.01,1.14.02,1.58l-.15.1-1.03-1.72-1.35-2.2v1.97c.01.87.03,1.33.04,1.39,0,.03.02.07.05.11.03.04.15.08.36.15.03,0,.06,0,.11.01v.2c-.25-.02-.46-.04-.63-.04-.14,0-.4.02-.76.04v-.2c.06-.01.1-.03.14-.05.2-.04.32-.09.35-.11.01-.03.02-.06.03-.11.01-.1.03-.49.03-1.17.01-.95.01-1.52.01-1.68,0-.56,0-.87-.01-.93-.01-.05-.03-.08-.07-.12-.03-.03-.2-.08-.47-.14-.03,0-.08-.02-.13-.03Z"/>
            <path   d="m401.18,83.61h-.22c.03-.44.07-.93.1-1.47.83.03,1.41.04,1.77.04.29,0,.81,0,1.59-.04.03.56.05,1.05.09,1.47h-.22c-.08-.46-.14-.73-.16-.81-.03-.07-.07-.14-.12-.18-.04-.03-.18-.05-.37-.05h-.62c-.02.67-.03,1.49-.03,2.47,0,.91,0,1.52.03,1.85,0,.07.01.13.04.15.03.03.18.09.46.16.02.01.06.01.11.04v.21c-.37-.03-.69-.03-.99-.03s-.56,0-.85.03v-.21c.07-.02.12-.04.16-.05.25-.05.39-.1.43-.15.02-.02.03-.04.03-.08.02-.04.02-.32.03-.83.01-.81.01-1.41.01-1.78,0-.67,0-1.28-.01-1.79h-.78c-.1,0-.18.02-.21.05-.05.05-.1.13-.14.27-.03.1-.07.34-.14.71Z"/>
            <path   d="m405.91,82.71h.15c.22.75.48,1.62.79,2.59l.57,1.68s.05.1.09.13c.02.01.14.08.36.17v.18c-.29-.03-.51-.03-.65-.03s-.38,0-.72.03v-.18c.19-.06.31-.1.34-.13.03-.05.04-.09.04-.13,0-.05-.01-.12-.04-.24l-.25-.81h-1.4l-.26.84c-.05.14-.07.21-.07.26,0,.02.01.05.03.08.02.01.11.04.28.09,0,0,.04.01.07.03v.18c-.28-.03-.48-.03-.59-.03-.14,0-.33,0-.54.03v-.18c.14-.04.25-.1.3-.16.04-.04.08-.11.12-.22.1-.21.33-.9.71-2.09l.66-2.1Zm-.63,2.94c.22,0,.38,0,.48,0,.02,0,.27,0,.74,0l-.61-1.97-.6,1.97Z"/>
            <path   d="m411.65,84.93v.18l-.37.11s-.07.04-.09.07c-.02.03-.03.06-.03.11-.01.1-.03.35-.03.76,0,.24.01.57.03.97-.07.03-.23.07-.46.16-.31.08-.51.13-.6.15-.15.03-.31.03-.49.03-.57,0-1.01-.2-1.32-.62-.3-.41-.46-.98-.46-1.69,0-.78.18-1.41.54-1.9.37-.48.84-.73,1.44-.73.15,0,.29.02.42.04.03.01.31.08.83.21v.3c0,.29.01.63.03,1.01h-.18c-.07-.37-.15-.63-.21-.76-.06-.11-.17-.21-.32-.28-.19-.11-.4-.16-.63-.16-.51,0-.88.27-1.14.78-.17.37-.26.81-.26,1.36,0,.68.14,1.2.42,1.55.27.36.63.53,1.06.53.27,0,.55-.07.85-.23.01-.25.01-.6.01-1.06,0-.25-.01-.4-.01-.45-.01-.04-.03-.07-.07-.1-.03-.04-.15-.07-.33-.13l-.1-.03v-.18l.84.02c.19,0,.39,0,.63-.02Z"/>
          </g>
        </g>
        <g>
          <path   d="m389.87,97.68v-.29c0-.2-.02-.26-.14-.26v-.06h.41v.06c-.12,0-.14.06-.14.26v.71c0,.2.01.26.14.26v.06h-.41v-.06c.11,0,.14-.06.14-.26v-.34h-.45v.34c0,.2.01.26.14.26v.06h-.42v-.06c.12,0,.15-.06.15-.26v-.71c0-.2-.02-.26-.15-.26v-.06h.42v.06c-.13,0-.14.06-.14.26v.29h.45Z"/>
          <path   d="m390.92,97.98h-.05c0-.24-.12-.25-.24-.24h-.06v.42c0,.14.01.19.15.19.23,0,.27-.08.42-.31h.04s-.11.37-.11.37h-.77v-.06c.11,0,.13-.06.13-.26v-.71c0-.2-.02-.26-.13-.26v-.06h.76l.11.32-.03.03c-.14-.21-.19-.28-.4-.28-.15,0-.16.03-.16.21v.34h.06c.14,0,.23-.01.24-.22h.05v.53Z"/>
          <path   d="m392.18,97.41h-.03c-.09-.12-.23-.28-.36-.28-.25,0-.3.33-.3.58s.1.62.35.62c.16,0,.26-.14.33-.3l.03.02c-.07.21-.22.38-.41.38-.28,0-.47-.29-.47-.64,0-.17.04-.33.12-.48.07-.14.19-.26.34-.26.05,0,.09.01.12.03.03.02.06.02.09.02.05,0,.07-.01.11-.04l.09.34Z"/>
          <path   d="m393.05,97.68v-.29c0-.2-.01-.26-.14-.26v-.06h.42v.06c-.12,0-.14.06-.14.26v.71c0,.2.02.26.14.26v.06h-.42v-.06c.12,0,.14-.06.14-.26v-.34h-.45v.34c0,.2.02.26.14.26v.06h-.42v-.06c.11,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.42v.06c-.12,0-.14.06-.14.26v.29h.45Z"/>
          <path   d="m393.97,98.43c-.3,0-.47-.34-.47-.7,0-.33.17-.68.47-.68.32,0,.48.35.48.7s-.18.68-.48.68Zm-.3-.7c0,.22.06.64.3.64s.32-.39.32-.64c0-.23-.07-.62-.32-.62s-.3.39-.3.62Z"/>
          <path   d="m395.66,97.98h-.05c0-.24-.11-.25-.23-.24h-.06v.42c0,.14.01.19.15.19.24,0,.28-.08.42-.31h.04s-.11.37-.11.37h-.78v-.06c.11,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.76l.1.32-.03.03c-.13-.21-.17-.28-.4-.28-.15,0-.15.03-.15.21v.34h.06c.14,0,.23-.01.23-.22h.05v.53Z"/>
          <path   d="m396.84,98.07v-.68c0-.2-.01-.26-.13-.26v-.06h.34v.06c-.12,0-.14.06-.14.26v1.03h-.05l-.63-1.13v.82c0,.2.02.26.13.26v.06h-.34v-.06c.12,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.26l.56,1Z"/>
          <path   d="m398.72,98.1c0,.2.02.26.14.26v.06h-.42v-.06c.13,0,.15-.06.15-.26v-.89h0l-.37,1.2h-.05l-.36-1.2h0v.89c0,.2.02.26.13.26v.06h-.33v-.06c.12,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.31l.33,1.08.31-1.08h.32v.06c-.11,0-.14.06-.14.26v.71Z"/>
          <path   d="m399.63,97.98h-.04c0-.24-.13-.25-.25-.24h-.05v.42c0,.14.01.19.15.19.23,0,.27-.08.41-.31h.04s-.1.37-.1.37h-.78v-.06c.11,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.76l.11.32-.04.03c-.13-.21-.17-.28-.38-.28-.15,0-.16.03-.16.21v.34h.05c.14,0,.23-.01.25-.22h.04v.53Z"/>
          <path   d="m400.69,98.16c.05.1.1.21.22.2v.06h-.44v-.06s.12,0,.12-.07c0-.03-.03-.1-.05-.13l-.15-.32-.1.19c-.02.05-.1.18-.1.25,0,.07.07.08.11.08v.06h-.37v-.06c.13,0,.16-.08.23-.2l.19-.4-.19-.4c-.07-.11-.1-.23-.23-.23v-.06h.43v.06s-.1,0-.1.06.03.12.06.17l.11.24.13-.23s.06-.12.06-.16c0-.08-.07-.07-.1-.08v-.06h.36v.06c-.12,0-.16.08-.22.2l-.18.33.23.49Z"/>
          <path   d="m401.25,98.1c0,.2.01.26.12.26v.06h-.41v-.06c.11,0,.14-.06.14-.26v-.71c0-.2-.02-.26-.14-.26v-.06h.41v.06c-.11,0-.12.06-.12.26v.71Z"/>
          <path   d="m402.39,97.41h-.04c-.08-.12-.23-.28-.36-.28-.23,0-.31.33-.31.58s.11.62.36.62c.15,0,.26-.14.33-.3l.03.02c-.08.21-.23.38-.42.38-.29,0-.47-.29-.47-.64,0-.17.03-.33.12-.48.08-.14.19-.26.34-.26.05,0,.09.01.11.03.03.02.07.02.1.02.04,0,.07-.01.1-.04l.1.34Z"/>
          <path   d="m403.03,98.43c-.31,0-.47-.34-.47-.7,0-.33.18-.68.48-.68s.46.35.46.7-.17.68-.47.68Zm-.31-.7c0,.22.06.64.31.64s.31-.39.31-.64c0-.23-.06-.62-.3-.62s-.31.39-.31.62Z"/>
        </g>
        <path   d="m395.89,94.75c0,.36.03.44.24.44v.1h-.71v-.1c.2,0,.24-.08.24-.44v-1.3c0-.16.03-.35-.13-.35-.24,0-.42.25-.53.51l-.06-.03.17-.58h1.34l.16.58-.05.03c-.1-.27-.3-.5-.53-.51-.16,0-.13.19-.13.35v1.3Z"/>
        <path   d="m396.64,95.28v-.14c.11-.33.29-.58.47-.86.14-.19.28-.43.28-.71,0-.23-.11-.45-.28-.45-.11,0-.23.09-.23.25,0,.1.06.15.06.26,0,.08-.05.16-.12.16-.09,0-.15-.11-.15-.22,0-.34.25-.55.47-.55.24,0,.48.21.48.57,0,.32-.18.54-.34.73-.26.27-.32.36-.49.73h.23c.14,0,.26,0,.35-.05.07-.02.14-.13.19-.31l.06.02-.1.58h-.87Z"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagCamisa
