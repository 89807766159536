import React from 'react'

const ShadowsHoodieTrasera= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m273.62,524.93s-.44-37.45,1.8-36.06c2.24,1.38,16.61,23.23,16.61,23.23,0,0,.57-5.9-.08-7.73-2.62-7.29-12.59-28.18-16.73-34.81-4.4-7.03-21.53-26.78-17.22-19.69,6.44,10.61,9.87,19.13,9.87,19.13l5.76,55.94Z"/>
      <path   d="m241.8,428.43c-7.95,3.32-26.93-20.84-26.93-20.84,0,0,21.58,20.98,25.32,16.56,6.02-7.11-20.4-42.61-20.4-42.61,0,0,19.18,21.41,18.69,6.85-.27-8.09,11.14,36.76,3.32,40.04Z"/>
      <path   d="m185.41,640.49c5.5,5.4,17.24,6.52,18.06,23.73.11,2.47,1.38-14.17.28-16.4-2.45-4.97-22.38-11.28-18.34-7.33Z"/>
      <path   d="m506.25,441.41c-2.52-4.86,7.27-14.71,9.46-19.71,4.37-9.95,6.13-42.72,6.13-42.72,0,0,13.58,47.99,7.1,62.44-.65,1.45-3.63,3.47-5.24,3.86-4.34,1.06-15.48-.05-17.46-3.86Z"/>
      <polygon   points="164.16 500.25 169.98 524.8 189.38 555.54 162.08 529.09 164.16 500.25"/>
      <path   d="m153.92,659.82c.26,4.11,3.02,15,6.04,17.22,4.65,3.43,23.07,15.58,19.27,11.3-3.77-4.24,9.74-4.88,12.73-5.01,5.86-.26-20.87-.35-26.1-3.46-4.66-2.76-12.27-25.26-11.93-20.05Z"/>
      <path   d="m594.42,399.93c-4.32,11.84-10.31,19.2-8.71,26.65,1.73,8.06,5.78,13.68,13.88,24.22,3.6,4.7-20.87-19.78-22.11-25.47-1.86-8.5,19.94-33.61,16.94-25.4Z"/>
      <path   d="m536.95,329.7s-6.14-36.26-11.54-47.42c-2.3-4.8-14.02-14.33-16.58-16.96-2.55-2.64-12.24-6.95-12.24-6.95,0,0,19.34,1.58,24.7,4.74,4.58,2.67,10.05,12.24,11.78,17.07,4.19,11.76,3.88,49.52,3.88,49.52Z"/>
      <path   d="m591.59,551.5c13.42-21.72,9.65-20.6,25.82-13.6,1.6.7,3.3-5.08,2.16-6.34-3.27-3.62-13.35-23.91-17.4-21.1-4.23,2.93-10.59,41.05-10.59,41.05Z"/>
      <polygon   points="600.29 671.83 637.41 675 621.12 677.26 598.87 678.17 600.29 671.83"/>
      <path   d="m301.53,276.14c6.56,5.65-10.06,32.39-10.06,32.39,0,0,12.37-27.73,6.41-29.21-9.58-2.37-34.61,35.64-34.61,35.64,0,0,13.78-25.79-.66-19.34-8.03,3.59,32.47-25.04,38.92-19.49Z"/>
      <path   d="m313.75,509.97l52.53-12.69,114.1-20.86c-39.89,3.54-85.38,16.53-107.3,23.84-2.9.96-59.33,9.71-59.33,9.71Z"/>
      <path   d="m465.34,633.48c-17.25,17.59-59.87,17.46-118.93,4.89-25.59-5.44-44.58,17.48-82.21-8.43-1.49-1.04,45.34,12.68,45.37,11.21.37-15.7,148.41,10.18,155.17-11.29.28-.91,1.37,2.84.6,3.62Z"/>
      <path   d="m462.21,651.45l57.76-15.63,11-19.98s-7.96,28.01-10.92,28.74c-2.96.72-57.85,6.87-57.85,6.87Z"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsHoodieTrasera