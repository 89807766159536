import React from 'react'

const PathPortada = ({
  className,
  showSizes,
  viewBox = '0 0 800 800',
  notebookSelected,
  rounded,
  colorLomo
}: {
  className: string
  showSizes: number
  viewBox?: string
  notebookSelected: number
  rounded: boolean
  colorLomo: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      <defs>
        <style>
          {`
      .cls-1, .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: #0f48ff;
        font-family: MuseoSans-700, 'Museo Sans';
        font-size: 12px;
      }
      

      .cls-5 {
        letter-spacing: -.02em;
      }

      .cls-6 {
        letter-spacing: -.02em;
      }

      .cls-2 {
        stroke: #0f48ff;
        stroke-miterlimit: 10;
      }`}
        </style>
      </defs>
      <g id='Path_portada_contraportada' data-name='Path portada contraportada'>
        <g
          id='Path_portada_contraportada-2'
          data-name='Path portada contraportada'
        >
          <g fill={colorLomo}>
            {rounded ? (
              <g id='portada_contraportada-2' data-name='portada contraportada'>
                <path
                  className='cls-4'
                  d='m150.2,762.5V80.5h502.1c14.17,0,25.7,11.53,25.7,25.7v630.6c0,14.17-11.53,25.7-25.7,25.7H150.2Z'
                />
                <path d='m652.3,81c13.9,0,25.2,11.3,25.2,25.2v630.6c0,13.9-11.3,25.2-25.2,25.2H150.7V81h501.6m0-1H149.7v683h502.6c14.47,0,26.2-11.73,26.2-26.2V106.2c0-14.47-11.73-26.2-26.2-26.2h0Z' />
              </g>
            ) : (
              <g id='portada_contraportada' data-name='portada contraportada'>
                <rect
                  className='cls-4'
                  x='150.2'
                  y='80.5'
                  width='527.8'
                  height='682'
                />
                <path d='m677.5,81v681H150.7V81h526.8m1-1H149.7v683h528.8V80h0Z' />
              </g>
            )}
          </g>

          {showSizes !== 1 && (
            <g>
              <g id='Medida_ancho' data-name='Medida ancho'>
                <line
                  className='cls-2'
                  x1='149.7'
                  y1='33.48'
                  x2='149.7'
                  y2='50.69'
                />
                <line
                  className='cls-2'
                  x1='678.7'
                  y1='33'
                  x2='678.7'
                  y2='50.21'
                />
                <line
                  className='cls-2'
                  x1='149.7'
                  y1='42.09'
                  x2='678.5'
                  y2='42.09'
                />
              </g>
            </g>
          )}
          {showSizes !== 1 && (
            <g>
              <g id='Medida_alto' data-name='Medida alto'>
                <line
                  className='cls-2'
                  x1='714.96'
                  y1='81.35'
                  x2='714.96'
                  y2='762.74'
                />
                <line
                  className='cls-2'
                  x1='723.56'
                  y1='81.35'
                  x2='706.36'
                  y2='81.35'
                />
                <line
                  className='cls-2'
                  x1='724.05'
                  y1='763'
                  x2='706.84'
                  y2='763'
                />
              </g>
              {(() => {
                switch (notebookSelected) {
                  case 3:
                    return (
                      <g>
                        <g
                          id='Block_esquela_alto_21_cm'
                          data-name='Block esquela alto 21 cm'
                        >
                          <text
                            className='cls-3'
                            transform='translate(723.88 105.42)'
                          >
                            <tspan x='0' y='0'>
                              21 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          id='Block_esquela_ancho_14_cm'
                          data-name='Block esquela ancho 14 cm'
                          className='cls-3'
                          transform='translate(156.88 33.42)'
                        >
                          <tspan className='cls-6' x='0' y='0'>
                            1
                          </tspan>
                          <tspan x='5.95' y='0'>
                            4 cm
                          </tspan>
                        </text>
                      </g>
                    )
                  case 2:
                    return (
                      <g>
                        <g
                          id='Block_carta_alto_27_cm'
                          data-name='Block carta alto 27 cm'
                        >
                          <text
                            className='cls-3'
                            transform='translate(723.88 105.42)'
                          >
                            <tspan x='0' y='0'>
                              27 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          id='Block_carta_ancho_21_cm'
                          data-name='Block carta ancho 21 cm'
                          className='cls-3'
                          transform='translate(156.88 33.42)'
                        >
                          <tspan x='0' y='0'>
                            21 cm
                          </tspan>
                        </text>
                      </g>
                    )
                  case 5:
                    return (
                      <g>
                        <g
                          id='Frances_alto_21_cm'
                          data-name='Frances alto 21 cm'
                        >
                          <text
                            className='cls-3'
                            transform='translate(723.88 105.42)'
                          >
                            <tspan x='0' y='0'>
                              21 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          id='Frances_ancho_16_cm'
                          data-name='Frances ancho 16 cm'
                          className='cls-3'
                          transform='translate(156.88 33.42)'
                        >
                          <tspan x='0' y='0'>
                            16 cm
                          </tspan>
                        </text>
                      </g>
                    )
                  case 7:
                    return (
                      <g>
                        <g
                          id='College_alto_25_cm'
                          data-name='College alto 25 cm'
                        >
                          <text
                            className='cls-3'
                            transform='translate(723.88 105.42)'
                          >
                            <tspan x='0' y='0'>
                              25 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          id='College_ancho_19.5_cm'
                          data-name='College ancho 19.5 cm'
                          className='cls-3'
                          transform='translate(156.88 33.42)'
                        >
                          <tspan x='0' y='0'>
                            1
                          </tspan>
                          <tspan className='cls-5' x='6.14' y='0'>
                            9
                          </tspan>
                          <tspan x='13.12' y='0'>
                            .5 cm
                          </tspan>
                        </text>
                      </g>
                    )
                  case 1:
                    return (
                      <g>
                        <g
                          id='Profesional_alto_26_cm'
                          data-name='Profesional alto 26 cm'
                        >
                          <text
                            className='cls-3'
                            transform='translate(723.88 105.42)'
                          >
                            <tspan x='0' y='0'>
                              26 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          id='Profesional_ancho_20_cm'
                          data-name='Profesional ancho 20 cm'
                          className='cls-3'
                          transform='translate(156.88 33.42)'
                        >
                          <tspan x='0' y='0'>
                            20 cm
                          </tspan>
                        </text>
                      </g>
                    )

                  default:
                    ;<></>
                }
              })()}
            </g>
          )}
        </g>
      </g>
    </svg>
  )
}

export default PathPortada
