import React from 'react'

const GreenTagSueterFemale = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
        <g id="Green_Tag" data-name="Green Tag">
    <g id="Green_Tag-2" data-name="Green Tag">
      <rect   x="384.81" y="119.95" width="27.99" height="32.12" transform="translate(260.56 534.04) rotate(-89.68)"/>
      <rect   x="384.36" y="123.83" width="28.91" height="24.04"/>
      <g>
        <path   d="m395.65,128.23s.23,0,.64.13c.41.14.86.35,1.21.53.34.18.7.46.82.59.12.12.38.46.39.53.02.07,0,.25-.09.39-.09.14-.31.41-.41.5-.1.09-.19.16-.21.17-.03,0-.05.04-.05.04,0,0,0,.02,0,.04,0,.01.02.05.02.05,0,0-.24.14-.47.26-.23.13-.4.2-.49.25-.08.04-.16.1-.16.1,0,0-.02.05,0,.05s.41-.04,1.18-.18c.76-.14,1.22-.25,1.52-.39.3-.14.43-.31.5-.4.07-.08.09-.16.12-.24.02-.08.04-.14.04-.14,0,0,.21-.06.35-.14.13-.07.33-.22.4-.27.07-.05.18-.09.24-.11.05-.02.15-.04.15-.04,0,0-.03-.06-.04-.06-.01-.01-.09-.1-.09-.16s-.11-.2-.18-.2c-.07,0-.2,0-.27.01-.07.02-.19.03-.24.03-.05,0-.08,0-.08,0,0,0,0-.05.03-.08.02-.03.17-.36.15-.53-.03-.17-.15-.62-.27-.9-.12-.28-.32-.65-.38-.75-.06-.09-.09-.12-.1-.08-.02.04-.01.2,0,.36.02.16.03.27.04.3,0,.03,0,.16,0,.18,0,.02-.02.09-.02.09,0,0,.02.04.02.04l-.08.02s-.02.08-.03.13c-.02.05-.07.22-.15.4-.08.18-.17.36-.22.44-.05.08-.1.14-.13.14-.03,0-.25-.11-.52-.23-.27-.13-.47-.26-.76-.45-.28-.19-.65-.67-.7-.74-.05-.07-.2-.28-.35-.5-.16-.22-.37-.51-.46-.62-.08-.1-.2-.21-.23-.24-.03-.03-.1-.08-.11-.07-.02.01,0,.04.02.07.02.04.26.35.4.54.13.19.42.6.5.73.07.13.21.37.26.46.05.09.06.23.06.23,0,0-.04,0-.19-.03-.14-.04-.7-.23-.81-.27-.12-.03-.42-.1-.5-.1-.08,0-.12,0-.16,0-.04,0-.14.02-.09.08Z"/>
        <g>
          <path   d="m391.06,134.68v.11l-.28.08s-.05.02-.06.04c-.01.01-.02.04-.03.07,0,.06-.01.22-.01.47,0,.15,0,.35.01.6-.06.01-.17.04-.35.09-.24.06-.38.09-.45.1-.12.01-.24.02-.38.02-.43,0-.77-.13-1-.39-.23-.25-.35-.6-.35-1.05,0-.48.13-.87.41-1.17.27-.3.64-.45,1.09-.45.11,0,.22,0,.32.02.02,0,.23.04.63.12,0,.07,0,.13,0,.18,0,.18,0,.39.03.63h-.14c-.05-.24-.11-.4-.16-.47-.05-.07-.13-.13-.24-.18-.15-.06-.31-.1-.48-.1-.38,0-.67.16-.86.49-.13.23-.2.51-.2.84,0,.42.11.75.32.97.21.22.48.33.81.33.2,0,.42-.05.64-.15,0-.15.01-.37.01-.65,0-.16,0-.25-.01-.28,0-.03-.02-.05-.04-.06-.03-.02-.11-.05-.25-.08l-.07-.02v-.11l.63.02c.14,0,.3,0,.47-.02Z"/>
          <path   d="m391.29,133.77v-.11c.3.02.55.02.73.02.11,0,.21,0,.3,0,.13,0,.21,0,.25,0,.22,0,.4.06.51.17.12.11.17.26.17.46,0,.13-.03.25-.07.35-.05.1-.13.19-.23.25-.07.05-.17.09-.3.13l.58.79c.07.1.12.16.14.17.02.03.06.05.11.06.06.03.17.06.33.1v.11c-.19-.01-.32-.02-.4-.02-.09,0-.2,0-.3.02-.05-.09-.12-.21-.21-.35-.09-.14-.14-.22-.16-.25,0-.01-.02-.03-.03-.04l-.38-.56c-.1,0-.2-.01-.31-.02v.92s.03.06.05.08c.03.03.11.05.26.08.02,0,.05.01.1.02v.11c-.21-.01-.39-.02-.55-.02-.17,0-.36,0-.57.02v-.11l.07-.02c.16-.04.26-.06.28-.09.03-.02.04-.05.04-.11,0-.04,0-.35,0-.94v-.86c0-.13-.01-.21-.02-.23,0-.02-.02-.04-.03-.04-.06-.03-.15-.06-.27-.08-.02,0-.05,0-.08-.01Zm.72,1.12c.12.02.21.02.28.02.21,0,.37-.05.47-.15.1-.1.15-.24.15-.41,0-.16-.04-.28-.13-.37-.09-.09-.22-.14-.39-.14-.06,0-.19,0-.37.02v1.02Z"/>
          <path   d="m396.93,134.81h.39c.15,0,.24,0,.27-.01.02,0,.04-.03.06-.05.02-.03.04-.11.06-.24,0-.01,0-.03.01-.06h.11c0,.2-.02.36-.02.46s0,.26.02.46h-.11c-.04-.18-.05-.28-.06-.3,0-.03-.03-.05-.06-.06-.02-.01-.1-.02-.24-.02h-.45v1.07h.75c.09,0,.15,0,.18-.01.04-.01.08-.04.11-.08.03-.04.09-.14.17-.31,0-.02.03-.04.04-.07h.12s-.08.26-.2.69c-.4-.01-.76-.02-1.08-.02-.23,0-.48,0-.76.02v-.11l.26-.06s.06-.03.08-.04c.02-.03.03-.07.03-.14v-.46c0-.08,0-.24,0-.49,0-.35,0-.61,0-.77,0-.16,0-.26-.01-.28,0-.02-.02-.03-.03-.04-.02,0-.06-.03-.15-.04l-.17-.04v-.11c.2.01.45.02.73.02.16,0,.53,0,1.11-.02,0,.21.02.42.04.62h-.12c-.04-.16-.08-.27-.1-.33-.02-.04-.04-.06-.07-.07-.03-.02-.11-.02-.24-.02h-.66v.59s-.02.36-.02.36Z"/>
          <path   d="m394.62,134.81h.4c.15,0,.24,0,.27-.01.03,0,.05-.03.07-.05.02-.03.04-.11.06-.24,0-.01,0-.03.02-.06h.1c0,.2-.02.36-.02.46s.01.26.02.46h-.1c-.03-.18-.05-.28-.06-.3-.01-.03-.03-.05-.06-.06-.03-.01-.11-.02-.24-.02h-.45v1.07h.75c.09,0,.15,0,.17-.01.04-.01.08-.04.11-.08.03-.04.09-.14.17-.31,0-.02.02-.04.04-.07h.11s-.08.26-.2.69c-.4-.01-.76-.02-1.09-.02-.23,0-.48,0-.76.02v-.11l.26-.06s.07-.03.08-.04c.01-.03.02-.07.02-.14v-.46c0-.08,0-.24,0-.49,0-.35,0-.61,0-.77,0-.16,0-.26-.02-.28,0-.02-.01-.03-.03-.04-.01,0-.07-.03-.15-.04l-.18-.04v-.11c.21.01.45.02.74.02.15,0,.53,0,1.12-.02,0,.21,0,.42.04.62h-.12c-.04-.16-.08-.27-.1-.33-.02-.04-.04-.06-.07-.07-.04-.02-.12-.02-.24-.02h-.66v.59s-.02.36-.02.36Z"/>
          <path   d="m398.57,133.72v-.11c.14.01.25.02.33.02.06,0,.18,0,.34-.02l.47.65c.04.05.37.5.99,1.33,0-.17,0-.29,0-.37,0-.18,0-.51-.02-.99,0-.23-.01-.35-.01-.37,0-.02-.02-.04-.04-.05-.02-.02-.1-.04-.23-.06-.02,0-.04,0-.07-.02v-.11c.19.01.34.02.46.02s.27,0,.46-.02v.11s-.05.01-.07.02c-.15.03-.23.06-.24.06-.01,0-.02.02-.03.03-.01.02-.02.06-.02.11,0,.02,0,.19,0,.5,0,.3,0,.61,0,.89,0,.34,0,.63.02.86l-.11.06-.68-.94-.9-1.21v1.08c0,.47.01.72.03.76,0,.02.01.04.03.05.02.02.1.05.24.08,0,0,.04,0,.07.02v.1c-.17-.01-.3-.02-.42-.02-.1,0-.26,0-.5.02v-.1s.06-.02.09-.02c.13-.03.21-.05.23-.07,0-.01.02-.03.03-.05,0-.06.02-.27.02-.64,0-.52,0-.83,0-.93,0-.3,0-.48,0-.51,0-.02-.02-.04-.04-.06-.02-.02-.13-.04-.31-.07-.02,0-.05,0-.09-.02Z"/>
          <path   d="m402.5,134.14h-.14c.02-.24.04-.51.06-.8.55.02.94.03,1.17.03.2,0,.55,0,1.06-.03.02.31.03.57.05.8h-.14c-.06-.25-.1-.39-.11-.44-.01-.05-.04-.07-.07-.09-.03-.02-.11-.03-.25-.03h-.41c0,.37-.01.82-.01,1.35,0,.5,0,.84.01,1.01,0,.04,0,.07.03.08.02.02.13.05.31.09.01,0,.04,0,.08.02v.12c-.24-.01-.46-.02-.66-.02-.18,0-.37,0-.56.02v-.12s.07-.02.09-.02c.17-.03.27-.06.3-.08,0-.01.02-.03.02-.05,0-.02,0-.17.02-.46,0-.44,0-.76,0-.97,0-.37,0-.69,0-.98h-.52c-.07,0-.11.01-.14.03-.04.03-.06.07-.09.15-.02.06-.05.19-.1.39Z"/>
          <path   d="m405.65,133.65h.1c.14.42.32.89.52,1.42l.38.92s.04.05.06.07c0,0,.09.04.23.09v.11c-.19-.01-.33-.02-.43-.02s-.25,0-.48.02v-.11c.12-.03.2-.05.22-.07.03-.02.04-.04.04-.06,0-.03-.02-.07-.04-.13l-.16-.44c-.23,0-.39,0-.49,0h-.44s-.17.46-.17.46c-.03.07-.04.12-.04.13s0,.03.02.04c0,0,.07.03.18.06.01,0,.03,0,.05.01v.11c-.18-.01-.31-.02-.39-.02-.1,0-.21,0-.36.02v-.11c.1-.03.16-.05.2-.08.03-.02.06-.06.09-.12.06-.12.21-.5.46-1.15l.44-1.15Zm-.42,1.61c.15,0,.26,0,.32,0,.02,0,.18,0,.5,0l-.41-1.08-.4,1.08Z"/>
          <path   d="m409.46,134.87v.1l-.24.07s-.05.02-.06.03c0,0-.02.03-.02.06,0,.05-.01.19-.01.41,0,.13,0,.31.01.54-.05,0-.15.03-.31.07-.2.05-.33.08-.39.09-.11.01-.21.02-.33.02-.38,0-.67-.11-.87-.34-.21-.23-.31-.54-.31-.93,0-.42.12-.77.36-1.03.23-.26.55-.4.95-.4.1,0,.19,0,.28.02.02,0,.2.04.55.11,0,.07,0,.12,0,.16,0,.16,0,.35.02.55h-.13c-.05-.21-.09-.34-.14-.41-.04-.07-.11-.12-.21-.16-.13-.06-.27-.08-.42-.08-.33,0-.59.14-.76.42-.11.2-.18.45-.18.74,0,.38.09.66.28.86.19.19.42.29.71.29.18,0,.37-.04.56-.13,0-.13,0-.32,0-.58,0-.14,0-.22-.02-.24,0-.03-.02-.04-.03-.06-.03-.02-.1-.04-.22-.07l-.06-.02v-.1h.55c.13.01.26,0,.41,0Z"/>
        </g>
      </g>
      <g>
        <path   d="m390.17,145.16h-.69v.37c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.04v.05h-.59v-.05h.03c.05,0,.1,0,.12-.02.02-.01.04-.04.04-.08,0-.03.01-.12.01-.24v-.74c0-.12,0-.2-.01-.24,0-.04-.02-.06-.04-.07-.02-.01-.06-.02-.12-.02h-.02v-.06h.59v.06h-.03c-.06,0-.1,0-.13.02-.02,0-.04.03-.04.07,0,.03,0,.11,0,.24v.3h.69v-.3c0-.12,0-.2,0-.24,0-.04-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.04v-.06h.6v.06h-.02c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.74c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.03v.05h-.6v-.05h.04c.05,0,.1,0,.12-.02.02-.01.04-.04.04-.08,0-.03,0-.12,0-.24v-.37Z"/>
        <path   d="m391.77,145.45h.04l-.03.47h-1.09v-.05h.02c.07,0,.11,0,.13-.03.02-.02.03-.04.04-.07,0-.03,0-.11,0-.24v-.74c0-.12,0-.2,0-.23,0-.04-.01-.06-.04-.08-.03-.01-.06-.02-.13-.02v-.06h.95l.03.39h-.05c-.02-.11-.04-.18-.07-.23s-.06-.07-.1-.08c-.04-.01-.11-.02-.2-.02h-.18v.63h.14c.06,0,.11,0,.13-.02.03-.01.04-.03.05-.06,0-.02.01-.08.01-.15h.04v.52h-.04c0-.08,0-.14-.02-.17-.01-.03-.03-.04-.05-.05-.03-.01-.06-.01-.12-.01h-.14v.36c0,.18,0,.29.03.32.02.03.07.04.16.04h.1c.07,0,.13,0,.17-.03.05-.02.09-.06.13-.13.04-.06.07-.15.09-.26Z"/>
        <path   d="m393.13,145.35h.04v.53h-.04c0-.06-.03-.11-.06-.16-.12.15-.27.22-.45.22-.19,0-.34-.07-.47-.2-.12-.14-.18-.32-.18-.55,0-.15.03-.29.09-.42.06-.13.13-.23.23-.3.1-.06.2-.09.33-.09.1,0,.19.02.27.07.08.05.14.1.18.16.02-.06.04-.13.05-.2h.04v.58s-.03,0-.03,0c-.03-.21-.09-.35-.18-.43-.09-.08-.19-.12-.3-.12-.14,0-.25.06-.33.19-.08.13-.12.31-.12.55s.05.4.13.52c.09.12.2.18.33.18.07,0,.14-.02.21-.06.07-.04.13-.11.18-.2.04-.09.07-.19.07-.27Z"/>
        <path   d="m394.45,145.16h-.69v.37c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.04v.05h-.6v-.05h.04c.05,0,.09,0,.12-.02.02-.01.04-.04.04-.08,0-.03,0-.12,0-.24v-.74c0-.12,0-.2,0-.24,0-.04-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.02v-.06h.6v.06h-.03c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.3h.69v-.3c0-.12,0-.2,0-.24,0-.04-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.03v-.06h.6v.06h-.02c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.74c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.07.03.12.03h.03v.05h-.6v-.05h.04c.06,0,.09,0,.11-.02.02-.01.04-.04.04-.08,0-.03,0-.12,0-.24v-.37Z"/>
        <path   d="m395.01,145.16c0-.24.06-.43.2-.57.13-.14.3-.21.51-.21s.38.07.52.21c.13.14.2.33.2.57s-.07.43-.2.57c-.13.14-.3.21-.52.21s-.38-.07-.51-.21c-.13-.15-.2-.34-.2-.58Zm.22,0c0,.25.04.43.13.55.08.13.21.19.38.19.14,0,.26-.06.35-.19.09-.12.13-.3.13-.54s-.04-.42-.12-.54c-.08-.13-.21-.19-.37-.19s-.28.06-.36.19c-.08.12-.13.31-.13.54Z"/>
        <path   d="m398.18,145.45h.05l-.03.47h-1.09v-.05h.03c.06,0,.1,0,.12-.03.02-.02.04-.04.04-.07,0-.03,0-.11,0-.24v-.74c0-.12,0-.2,0-.23,0-.04-.02-.06-.04-.08-.02-.01-.06-.02-.12-.02v-.06h.94l.03.39h-.04c-.02-.11-.05-.18-.08-.23-.02-.04-.06-.07-.11-.08-.03-.01-.1-.02-.2-.02h-.18v.63h.13c.07,0,.11,0,.13-.02.03-.01.04-.03.05-.06.01-.02.01-.08.01-.15h.04v.52h-.04c0-.08,0-.14-.02-.17-.01-.03-.03-.04-.05-.05-.02-.01-.06-.01-.12-.01h-.14v.36c0,.18,0,.29.02.32.02.03.07.04.16.04h.09c.07,0,.13,0,.17-.03.04-.02.09-.06.12-.13.04-.06.07-.15.09-.26Z"/>
        <path   d="m399.51,145.96l-.93-1.43v1.05c0,.09,0,.15.01.17,0,.03.03.06.06.08.03.03.08.03.16.03v.05h-.49v-.05c.06,0,.12-.02.14-.04.04-.02.05-.04.06-.07,0-.03,0-.08,0-.18v-.8c0-.12,0-.2-.02-.24,0-.04-.02-.06-.04-.08-.02,0-.07,0-.14,0v-.06h.39l.78,1.19v-.91c0-.07,0-.11-.02-.14-.02-.03-.04-.05-.07-.06-.04-.02-.08-.02-.13-.02v-.06h.48v.06c-.06,0-.1.02-.13.04-.03.02-.05.04-.06.07,0,.03,0,.07,0,.14v1.25h-.04Z"/>
        <path   d="m401.2,145.66l.48-1.26h.4v.06h-.05c-.06,0-.11,0-.12.02-.02,0-.04.03-.04.07,0,.03-.01.11-.01.24v.74c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.07.03.12.03h.05v.05h-.63v-.05h.04c.06,0,.09,0,.12-.02.02-.01.04-.04.04-.08,0-.03,0-.12,0-.24v-.96l-.52,1.36h-.05l-.52-1.36v.89c0,.12,0,.2,0,.24,0,.05.03.08.06.12.03.03.08.05.14.05v.05h-.47v-.05c.06,0,.1-.02.14-.05.04-.03.06-.07.07-.12,0-.05,0-.13,0-.26v-.66c0-.12,0-.2,0-.24-.01-.04-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.04v-.06h.4l.48,1.26Z"/>
        <path   d="m403.26,145.45h.05l-.03.47h-1.09v-.05h.03c.07,0,.1,0,.13-.03.02-.02.03-.04.04-.07,0-.03,0-.11,0-.24v-.74c0-.12,0-.2,0-.23,0-.04-.02-.06-.04-.08-.02-.01-.06-.02-.13-.02v-.06h.95l.03.39h-.04c-.02-.11-.05-.18-.07-.23-.03-.04-.07-.07-.11-.08-.04-.01-.11-.02-.2-.02h-.18v.63h.14c.07,0,.11,0,.13-.02.03-.01.04-.03.05-.06,0-.02,0-.08,0-.15h.04v.52h-.04c0-.08,0-.14-.02-.17,0-.03-.03-.04-.05-.05-.02-.01-.06-.01-.12-.01h-.14v.36c0,.18.01.29.03.32.02.03.07.04.16.04h.09c.07,0,.13,0,.17-.03.05-.02.09-.06.13-.13.04-.06.07-.15.09-.26Z"/>
        <path   d="m404.2,145.08l.34.48c.12.17.2.27.23.28.03.02.07.03.13.03v.05h-.65v-.05c.07,0,.12,0,.14-.02.03-.01.04-.03.04-.07,0-.02,0-.04-.02-.07,0-.02-.04-.07-.09-.14l-.24-.33-.21.29c-.05.06-.08.11-.09.14-.02.03-.03.06-.03.08,0,.08.05.12.16.12v.05h-.51v-.05c.05,0,.1-.02.15-.04.04-.03.08-.06.12-.1.04-.04.09-.12.16-.22l.22-.3-.35-.48-.11-.15s-.04-.06-.08-.08c-.03-.02-.06-.03-.11-.03v-.06h.56v.06h-.02c-.07,0-.11.02-.11.07,0,.02,0,.04.02.06,0,.02.04.06.08.12l.24.34.21-.32c.05-.07.07-.11.08-.13s.02-.04.02-.05c0-.04-.02-.06-.04-.07-.03-.01-.07-.02-.13-.02v-.06h.49v.06c-.08,0-.15.03-.21.08-.05.06-.12.13-.19.24l-.21.3Z"/>
        <path   d="m404.96,144.46v-.06h.6v.06h-.03c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.74c0,.12,0,.2,0,.23,0,.04.02.06.04.08.03.02.07.03.12.03h.04v.05h-.6v-.05h.04c.05,0,.09,0,.11-.02.02-.01.04-.04.04-.08,0-.03,0-.12,0-.24v-.74c0-.12,0-.2,0-.24,0-.04-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.03Z"/>
        <path   d="m406.87,145.35h.04v.53h-.04c0-.06-.04-.11-.06-.16-.12.15-.27.22-.45.22-.19,0-.35-.07-.47-.2-.12-.14-.18-.32-.18-.55,0-.15.02-.29.09-.42.05-.13.13-.23.23-.3.1-.06.21-.09.33-.09.1,0,.18.02.27.07.08.05.14.1.18.16.03-.06.04-.13.05-.2h.04l.02.58h-.04c-.03-.21-.09-.35-.18-.43-.09-.08-.19-.12-.31-.12-.13,0-.24.06-.33.19-.08.13-.12.31-.12.55s.04.4.13.52c.09.12.2.18.33.18.07,0,.14-.02.22-.06.07-.04.13-.11.17-.2.04-.09.07-.19.07-.27Z"/>
        <path   d="m407.15,145.16c0-.24.07-.43.2-.57.14-.14.3-.21.51-.21s.37.07.51.21c.14.14.2.33.2.57s-.07.43-.19.57c-.13.14-.31.21-.52.21s-.38-.07-.51-.21c-.13-.15-.2-.34-.2-.58Zm.22,0c0,.25.05.43.13.55.08.13.21.19.38.19.14,0,.26-.06.35-.19.08-.12.13-.3.13-.54s-.04-.42-.12-.54c-.09-.13-.21-.19-.37-.19s-.28.06-.37.19c-.08.12-.13.31-.13.54Z"/>
      </g>
      <path   d="m398.05,141.04l-.02-.18c.2-.41.48-.75.76-1.09.21-.25.44-.56.44-.92,0-.29-.17-.58-.45-.58-.17,0-.36.11-.36.32,0,.13.1.2.1.33,0,.11-.09.21-.19.21-.15,0-.23-.14-.23-.29,0-.42.39-.7.74-.7.37,0,.77.28.77.73,0,.41-.29.7-.55.93-.4.35-.49.46-.77.93h.35c.22,0,.42,0,.55-.05.11-.05.21-.18.3-.42l.11.03-.16.75h-1.39Z"/>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagSueterFemale
