import { TFormJumperOptions } from 'interfaces/uniforms-interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleJumperForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const JumperOptionsEditable = () => {
  const { jumperForm } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormJumperOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleJumperForm({
        ...jumperForm,
        [keyName]: {
          ...jumperForm[keyName as TFormJumperOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary handleChange={handleChange} form={jumperForm} />
        )
      })()}
    </div>
  )
}
export default JumperOptionsEditable
