import React from 'react'
import { IconProps } from '../../../types'
export const Book = ({
  height = 20,
  width = 18,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.5 0.875H3.75C2.85489 0.875 1.99645 1.23058 1.36351 1.86351C0.730579 2.49645 0.375 3.35489 0.375 4.25V20C0.375 20.2984 0.493526 20.5845 0.704505 20.7955C0.915483 21.0065 1.20163 21.125 1.5 21.125H15C15.2984 21.125 15.5845 21.0065 15.7955 20.7955C16.0065 20.5845 16.125 20.2984 16.125 20C16.125 19.7016 16.0065 19.4155 15.7955 19.2045C15.5845 18.9935 15.2984 18.875 15 18.875H2.625V18.5C2.625 18.2016 2.74353 17.9155 2.9545 17.7045C3.16548 17.4935 3.45163 17.375 3.75 17.375H16.5C16.7984 17.375 17.0845 17.2565 17.2955 17.0455C17.5065 16.8345 17.625 16.5484 17.625 16.25V2C17.625 1.70163 17.5065 1.41548 17.2955 1.2045C17.0845 0.993526 16.7984 0.875 16.5 0.875ZM15.375 15.125H3.75C3.36627 15.1232 2.9853 15.1898 2.625 15.3219V4.25C2.625 3.95163 2.74353 3.66548 2.9545 3.4545C3.16548 3.24353 3.45163 3.125 3.75 3.125H15.375V15.125Z'
        fill='currentColor'
      />
    </svg>
  )
}
