import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'


import FaldaShortFrontalSvg from 'assets/img/uniforms/sportOutfit/faldaShort/frontal/FaldaShortFrontalSvg'
import LabelFaldaShort from 'assets/img/uniforms/sportOutfit/faldaShort/frontal/LabelFaldaShort'

const FaldaShortPics = () => {
    const { poloValues, pictureLogo } = useSelector(
        (state: RootState) => state.uniforms
      )
    
      return (
          <div className={`${style['div-polo-svg-relative']}`}>
            <Fragment>
              <div className={`${style[`logo-position-${'left'}`]}`}>
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              </div>
              <div className={`${style['div-polo-svg-absolute']}`}></div>
    
              <div className={`${style['div-label-svg-absolute']}`}>
                <FaldaShortFrontalSvg className={`${style['svg']}`} />
              </div>
              <div className={`${style['div-tags-svg-absolute']}`}>
                <LabelFaldaShort className={`${style['svg']}`} />
              </div>
            </Fragment>
          </div>
      )
    }
export default FaldaShortPics
