import { Divider, Form, Input, Upload } from 'antd'
import Caption from 'components/texts/Caption/Caption'
import React, { memo, useEffect } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Button from 'components/buttons/Button/Button'
import B from 'components/texts/B/B'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { PaperClipOutlined } from '@ant-design/icons'
dayjs.extend(relativeTime)

const CommentsSection = memo(
  ({
    comments,
    isStatus9,
    isNotebookStatus9,
    sentNewComment,
    form,
    loadingPost
  }: any) => {
    let { data } = useSelector((state: RootState) => state.comments)
 

    return (
      <div className='tickets-container'>
        {comments.length > 0 && <Divider>Comentarios</Divider>}
        {comments.map((comment: any, key: any) => {
          const sentTime = comment.date ?? comment.createdAt
          const date = dayjs(sentTime).fromNow()
          let user = 'Colegio'
          if (comment.user_role === 'Ezetera') user = 'Ezetera'
          return (
            <>
              <div key={key} className='ticket-card'>
                <div className='title'>
                  <div className='user'>
                    <Caption>{user}</Caption>{' '}
                    <Caption size='Light'>{date}</Caption>
                  </div>
                  {/* <div className='check'>
                    <RenderCheck
                      comment={comment}
                      updateComment={updateComment}
                    />
                  </div> */}
                </div>
                <div className='description'>
                  <B size='Light'> {comment.content}</B>
                  <div className='images'>
                    {comment.pictures.map((url: any, key: number) => {
                      return (
                        <img
                          onClick={() => {
                            window.open(url, '_blank')
                          }}
                          src={url}
                          alt={`${key}-commentimage`}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          )
        })}
        {(data.status < 4 || isStatus9 || isNotebookStatus9) && (
          <Form onFinish={sentNewComment} form={form}>
            <Form.Item name='comment' rules={[{ required: true, message:'Por favor, ingrese un comentario' }]}>
              <Input.TextArea
                placeholder='Agrega un comentario'
                maxLength={500}
              />
            </Form.Item>

            <Form.Item name='image'>
              <Upload
                beforeUpload={() => false}
                multiple
                name='image'
                listType='picture'
                accept='image/png, image/jpeg, image/jpg, image/svg'
              >
                Agregar Imagen:
                <PaperClipOutlined />
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                isLoading={loadingPost}
                label='Agregar comentario'
                rounded
                sector='primary'
                background='white'
                buttonType='submit'
              />
            </Form.Item>
          </Form>
        )}
      </div>
    )
  }
)
CommentsSection.displayName = 'CommentsSection'
export default CommentsSection
