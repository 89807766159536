import React, { memo } from 'react'
import style from './Caption.module.scss'
interface CaptionInter {
  variation?: '1'
  size?: 'Bold' | 'Regular' | 'Light'
  children: string | JSX.Element | JSX.Element[] | any
}
const Caption = memo(
  ({ variation = '1', size = 'Regular', children }: CaptionInter) => {
    return (
      <span className={style.Caption} data-variation={variation} data-size={size}>
        {children}
      </span>
    )
  }
)
Caption.displayName = 'Caption'
export default Caption
