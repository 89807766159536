import { Button, Form } from 'antd'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './login.module.scss'
import FormInput from 'components/formInputs/input'
import { loginData } from 'services/auth/users'
import { InputsLogin, useLogin } from 'hooks/login/useLogin'
// import { useSelector } from 'react-redux'
// import { AppStore } from 'redux/store/store'

const LoginFormModule = memo(() => {
  const { loading, handleLogin } = useLogin()
  const navigate = useNavigate()

  // const { token } = useSelector((state: AppStore) => state.auth)
  const onSubmit = (values: loginData): void => {
    handleLogin(values)
  }

  return (
    <>
      <Form
        className={styles.formLogin}
        onFinish={onSubmit}
        layout='vertical'
        autoComplete='off'
      >
        {InputsLogin.map((input) => (
          <FormInput key={input.name} {...input} />
        ))}

        <Button
          className={styles.btn}
          type='primary'
          htmlType='submit'
          loading={loading}
        >
          Ingresar
        </Button>
      </Form>

      <Button
        type='link'
        className={styles.btnForgot}
        onClick={() => navigate(`/forgot-password`)}
      >
        {' '}
        Olvidé mi contraseña
      </Button>

      {/* <p>{token}</p> */}
    </>
  )
})

export default LoginFormModule
