import React, { useState, useEffect, Fragment } from 'react'
import { Form, Modal, Select } from 'antd'
import Button from 'components/buttons/Button/Button'
import style from './modals.module.scss'
import { RootState } from 'redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  IEdithNotebook,
  edithNotebook,
  notebookCategory
} from 'services/notebooks/putNotebook'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { handleOptionsOutside } from 'redux/reducers/notebooksSlice'

const NotebookEdthParamsModal = ({
  show,
  onOk,
  onCancel
}: {
  show: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const { edithOutside4specs } = useSelector(
    (state: RootState) => state.notebooks
  )
  const dispatch = useDispatch()
  const [categorias, setcategorias] = useState<null | string[]>(null)

  const saveSendDataNotebookChange = (
    data: IEdithNotebook,
    showToast?: boolean,
    ejecute?: () => void
  ) => {
    let thisNotebook: IEdithNotebook = {
      ...data,
      id: edithOutside4specs.id,
      id_package: edithOutside4specs.id_package
    }
    edithNotebook(thisNotebook)
      .then(() => {
        showToast && SuccessToast('Se guardó con éxito')
        ejecute && ejecute()
        onOk()
        window.location.reload()
      })
      .catch((e) => {
        ErrorToast('No se pudo guardar el cambio al cuaderno')
      })
  }
  const { inventariable, category, tax, price_list } = edithOutside4specs

  const getCategories = () => {
    notebookCategory()
      .then((d) => setcategorias(d.data.categories))
      .catch((w) => console.log(w))
  }

  useEffect(() => {
    if (show) getCategories()
  }, [show])

  return (
    <Modal
      title={`Editar los parametros del cuaderno`}
      centered
      destroyOnClose
      open={show}
      onOk={onOk}
      width={800}
      onCancel={onCancel}
      footer={[
        <div className={`${style['footer-style']}`}>
          <Button
            onClick={onCancel}
            rounded
            sector='tertiary'
            background='white'
            label='Cancelar'
          />
          <Button
            onClick={() => saveSendDataNotebookChange(edithOutside4specs, true)}
            rounded
            label='Guardar'
          />
        </div>
      ]}
    >
      <div className={`${style['div-container']}`}>
        <p className={`${style['label-modal']}`}>
          Editar especificaciones del cuaderno
        </p>
        <Form layout='vertical' autoComplete='off'>
          <Form.Item
            name='gender'
            label='Editar categoria'
            rules={[{ required: true }]}
            initialValue={category}
          >
            <Select
              placeholder='Seleccionar categoria'
              size='large'
              onChange={(r) =>
                dispatch(
                  handleOptionsOutside({
                    ...edithOutside4specs,
                    category: r
                  })
                )
              }
              allowClear
            >
              {categorias !== null &&
                categorias.length !== 0 &&
                categorias.map((item: string, index) => {
                  return (
                    <Fragment key={`${item + index}`}>
                      <Select.Option value={item}>{item}</Select.Option>
                    </Fragment>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name='impuesto'
            label='Editar indicador de impuestos'
            rules={[{ required: true }]}
            initialValue={tax}
          >
            <Select
              placeholder='Seleccionar impuesto'
              size='large'
              onChange={(r) =>
                dispatch(
                  handleOptionsOutside({
                    ...edithOutside4specs,
                    tax: r
                  })
                )
              }
              allowClear
            >
              {['v0', 'v4'].map((item: string, index) => {
                return (
                  <Fragment key={`${item + index}`}>
                    <Select.Option value={item}>{item}</Select.Option>
                  </Fragment>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='priceList'
            label='Editar precio de lista'
            rules={[{ required: true }]}
            initialValue={price_list}
          >
            <Select
              placeholder='Selecciona un precio de lista'
              size='large'
              onChange={(r) =>
                dispatch(
                  handleOptionsOutside({
                    ...edithOutside4specs,
                    price_list: r
                  })
                )
              }
              allowClear
            >
              {[1, 4].map((item: number, index) => {
                return (
                  <Fragment key={`${item + index}`}>
                    <Select.Option value={item}>{item}</Select.Option>
                  </Fragment>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            width: '38%'
          }}
        >
          Inventariable
          <input
            className={`${style['modal-input']}`}
            type='checkbox'
            checked={inventariable}
            onChange={() =>
              dispatch(
                handleOptionsOutside({
                  ...edithOutside4specs,
                  inventariable: !inventariable
                })
              )
            }
          />
        </label>
      </div>
    </Modal>
  )
}

export default NotebookEdthParamsModal
