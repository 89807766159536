import React, { Fragment } from 'react'
import {
  TFormSacoFrontalOptions,
  TFormSacoTraseroOptions
} from 'interfaces/uniforms-interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleSacoForm,
  handleSacoTraseroForm
} from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const SacoOptionsEditable = () => {
  const { sacoForm, sacoTraseroForm, selectedMenu } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormSacoFrontalOptions | TFormSacoTraseroOptions,
    value: string | boolean
  ) => {
    switch (selectedMenu) {
      case 'saco':
        dispatch(
          handleSacoForm({
            ...sacoForm,
            [keyName]: {
              ...sacoForm[keyName as TFormSacoFrontalOptions],
              value: value
            }
          })
        )
        break
      case 'saco trasero':
        dispatch(
          handleSacoTraseroForm({
            ...sacoTraseroForm,
            [keyName]: {
              ...sacoTraseroForm[keyName as TFormSacoTraseroOptions],
              value: value
            }
          })
        )
        break

      default:
        break
    }
  }
  return (
    <div>
      {(() => {
        switch (selectedMenu) {
          case 'saco':
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sacoForm}
              />
            )
          case 'saco trasero':
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sacoTraseroForm}
              />
            )

          default:
            break
        }
      })()}
    </div>
  )
}
export default SacoOptionsEditable
