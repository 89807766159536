import { BooksData } from 'interfaces/books-interface'
import { AUTHSERVICE } from '../config'

export const getBooks = async (
  page: number,
  school: string,
  status: string,
  search: string
): Promise<any> => {
  const filterSchool = school && `&school=${school}`
  const filterStatus = status && `&status=${status}`
  const searchValue = search && `&search=${search}`
  return await AUTHSERVICE().get(
    `/books?page=${page}${filterSchool}${filterStatus}${searchValue}`
  )
}

export const createBook = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/books', data)
}
export const createAwsBook = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/books/booksAws', data)
}

// export const createBook = async (data: BooksData): Promise<any> => {
//   return await AUTHSERVICE().post('/books', data)
// }
export const updateBook = async (id: number, data: BooksData): Promise<any> => {
  return await AUTHSERVICE().put(`/books/${id}`, data)
}
export const bookSugestionService = async ( data: any): Promise<any> => {
  return await AUTHSERVICE().put(`/suggestions/authorization/book`, data)
}
export const changeBookStatus = async (id: number, body: any): Promise<any> => {
  return await AUTHSERVICE().put(`/books/school/${id}`, body)
}
export const searchBook = async (
  search: string,
  page: number
): Promise<any> => {
  return await AUTHSERVICE().get(`/books/search?search=${search}&page=${page}`)
}
export const autoCompleteBook = async (search: string): Promise<any> => {
  return await AUTHSERVICE().get(`/books/autocomplete?search=${search}`)
} 

export const getEditorials = async (search: string): Promise<any> => {
  return await AUTHSERVICE().get(`/books/editorial?search=${search}`)
}
export const getProviders = async (search: string): Promise<any> => {
  return await AUTHSERVICE().get(`/books/providers?search=${search}`)
}

export const createEditorial = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/books/editorial', data)
}

export const getSchoolBooks = async (
  idSchool: number,
  idLevel: number,
  idGrade: number
): Promise<any> => {
  return await AUTHSERVICE().get(
    `/books/school?idSchool=${idSchool}&level=${idLevel}&grade=${idGrade}`
  )
}

export const createSchoolBook = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/books/school', data)
}
export const deleteSchoolBook = async (id: number): Promise<any> => {
  return await AUTHSERVICE().delete(`/books/school/${id}`)
}
export const multipleBookStatus = async (body: any): Promise<any> => {
  return await AUTHSERVICE().put(`books/school/all`, body)
}

export const getCatalogStatus = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/books/status`)
}
export const downloadBooks = async (
  status: string,
  search: string,
  school?: string
): Promise<any> => {
  let url = `/books/download?status=${status}&search=${search}`
  if (school) {
    url += `&idSchool=${school}`
  }
  return await AUTHSERVICE().get(url)
}
export const downloadGralBooks = async (school?: string): Promise<any> => {
  let url = `/books/download`
  if (school) {
    url += `?idSchool=${school}`
  }
  return await AUTHSERVICE().get(url)
}

export const downloadFormatBooks = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/books/download-format`)
}

export const uploadBooks = async (file: any): Promise<any> => {
  return await AUTHSERVICE().post('/books/upload-books', file)
}

export const getBooksAuthorize = async (
  level?: any,
  grade?: any
): Promise<any> => {
  const leveLValue = level ? `?level=${level}` : ''
  const gradeLevel = grade ? `&grade=${grade}` : ''

  return await AUTHSERVICE().get(`/books/authorize${leveLValue}${gradeLevel}`)
}

export const requestChangesBooks = async (body: any): Promise<any> => {
  return await AUTHSERVICE().put('/books/update/request-changes', body)
}

export const endListBooks = async (body: any): Promise<any> => {
  return await AUTHSERVICE().put('/books/update/end-list', body)
}
