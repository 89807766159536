import PantalonSvg from 'assets/img/uniforms/pantalon/PantalonSvg'
import ShadowsPantalon from 'assets/img/uniforms/pantalon/ShadowsPantalon'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

const PantalonPics = () => {
  const { pictureLogo } = useSelector((state: RootState) => state.uniforms)

  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      <Fragment>
        <div className={`${style[`logo-position-${'left'}`]}`}>
          <img className={`${style['svg-logo']}`} src={pictureLogo} />
        </div>
        <div className={`${style['div-polo-svg-absolute']}`}>
          <PantalonSvg className={`${style['svg']}`} />
        </div>
        <div className={`${style['div-shadows-svg-absolute']}`}>
          <ShadowsPantalon className={`${style['svg']}`} />
        </div>
      </Fragment>
    </div>
  )
}

export default PantalonPics
