import React from 'react'
import { IconProps } from '../../../types'
export const Mail = ({
  height = 20,
  width = 22,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
    width={width}
    height={height}
      viewBox='0 0 22 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.999968 0.142822C0.526581 0.142822 0.142825 0.526578 0.142825 0.999965V3.16629C0.142821 3.16768 0.142821 3.16908 0.142825 3.17047V19C0.142825 19.4734 0.526581 19.8571 0.999968 19.8571H21C21.4734 19.8571 21.8571 19.4734 21.8571 19V3.17059C21.8571 3.16912 21.8571 3.16764 21.8571 3.16617V0.999965C21.8571 0.526578 21.4734 0.142822 21 0.142822H0.999968ZM20.1428 2.79797V1.85711H1.85711V2.79797L10.5948 11.0144L10.5949 11.0144C10.7221 11.1341 10.8665 11.1859 11 11.1859C11.1334 11.1859 11.2778 11.1341 11.405 11.0144L11.4051 11.0144L20.1428 2.79797ZM1.85711 5.15113V18.1428H20.1428V5.15113L12.5795 12.2632L12.5795 12.2633C12.1515 12.6657 11.5929 12.9002 11 12.9002C10.407 12.9002 9.84842 12.6657 9.42048 12.2633L9.42043 12.2632L1.85711 5.15113Z'
        fill='currentColor'
      />
    </svg>
  )
}
