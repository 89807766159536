import React, { memo, useEffect, useState } from 'react'
import { Space, Modal, Form, Row, Col } from 'antd'
import { Tooltip } from 'antd'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import Input from 'components/inputs/Input'
import {
  INPUTS_INITIAL_STATE,
  INPUTS_INITIAL_STATE_GRADES,
  setGradesForm,
  setLevelsForm,
  setMagentoGrades
} from 'redux/reducers/levelsAndGrades'
import { useDispatch, useSelector } from 'react-redux'

import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { LevelListInt } from 'types'
import levelStyle from '../LevelActions/LevelActions.module.scss'
import { RootState } from 'redux/store/store'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import Select from 'components/inputs/Select'
import { getMagentoGrades } from 'services/schoolUserServices/levels'
import { disabledCreateGradeButton } from '../LevelActions/LevelActions'

const GradesActions = memo(
  ({
    data,
    level,
    deleteGradeById,
    loadingModal,
    updateGradeById,
    schoolInfo
  }: any): JSX.Element | null => {
    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const dispatch = useDispatch()

    const { levelsForm, gradesForm, magentoGrades } = useSelector(
      (state: RootState) => state.levelsAndGrades
    )

    const [open2, setOpen2] = useState(false)
    const [open, setOpen] = useState(false)

    const getGradesByLevelId = async () => {

      try {
        const result: any = await getMagentoGrades(parseInt(level.id_magento))
        const MAGENTO_GRADES = result.data.levels_magento.map(
          (res: { id: number; name: string }) => {
            return { label: res.name, value: res.id }
          }
        )
        dispatch(setMagentoGrades(MAGENTO_GRADES))
      } catch (err: any) {
        console.log('magento levels', err)
      }
    }

    useEffect(() => {
      if (open) getGradesByLevelId()
    }, [open, levelsForm?.id_magento?.value])

    const handleCancel = () => {
      setOpen(false)
      setOpen2(false)
      dispatch(setLevelsForm({ levelsForm: INPUTS_INITIAL_STATE }))
      dispatch(setGradesForm({ gradesForm: INPUTS_INITIAL_STATE_GRADES }))
    }

    const showModal2 = () => {
      setOpen2(true)
    }
    const handleOk = () => {
      deleteGradeById(data, level)
    }

    const handleUpdateGrade = () => {
      updateGradeById(data, handleCancel)
    }

    const returnLevelCleanData = (data: LevelListInt) => {
      const { name, id_magento } = { ...levelsForm }
      let updateData = { ...name }
      let updateData2 = { ...id_magento }
      updateData.value = data.name
      updateData.id = data.id
      updateData2.value = data.id_magento
      return { name: updateData, id_magento: updateData2 }
    }

    const returnGradeCleanData = (data: any) => {
      let goodData = { ...INPUTS_INITIAL_STATE_GRADES }
      Object.keys(gradesForm).forEach((element) => {
        const objKey = element as keyof typeof INPUTS_INITIAL_STATE_GRADES
        let objInput = goodData[objKey]
        objInput = {
          ...objInput,
          value: data[objKey]
        }
        goodData = { ...goodData, [objKey]: objInput }
      })
      return goodData
    }

    const showModal = (event: any, data: any) => {
      event.stopPropagation()
      dispatch(setLevelsForm({ levelsForm: returnLevelCleanData(data) }))
      dispatch(setLevelsForm({ gradesForm: returnGradeCleanData(data) }))
      setOpen(true)
    }

    const handleChangeGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setGradesForm({ gradesForm: cleanSingleUpdatedState(e, gradesForm) })
      )
    }

    const handleChangeSelectGrades = (e: any, name: string) => {
      dispatch(
        setGradesForm({
          gradesForm: cleanSingleSelectUpdatedState(e, name, gradesForm)
        })
      )
    }

    if (uid.role !== 'Administrador') return null

    return (
      <Space>
        <Tooltip title='Editar Grado'>
          <PencilSquareIcon
            className={`${levelStyle['iconStyle']} ${levelStyle['iconStyleFill']}`}
            onClick={(event) => showModal(event, data)}
          />
        </Tooltip>
        <Tooltip title='Eliminar Grado'>
          <TrashIcon
            className={`${levelStyle['iconStyle']} ${levelStyle['red']}`}
            onClick={showModal2}
          />
        </Tooltip>
        <button
          style={{ opacity: '0', position: 'absolute' }}
          id='closeAntdModal'
          onClick={handleCancel}
        ></button>
        <Modal
          title='Edita el grado'
          open={open}
          onOk={handleUpdateGrade}
          okButtonProps={{disabled:disabledCreateGradeButton(gradesForm,schoolInfo,loadingModal)}}
          confirmLoading={loadingModal}
          cancelButtonProps={{ id: `closeCreateGradeButton-${data.id}` }}
          onCancel={handleCancel}
        >
          <Form layout='vertical' autoComplete='off'>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Select
                  name={gradesForm.id_magento.name}
                  placeholder={gradesForm.id_magento.placeholder}
                  label={gradesForm.id_magento.label}
                  value={gradesForm.id_magento.value}
                  onChange={(e) =>
                    handleChangeSelectGrades(e, gradesForm.id_magento.name)
                  }
                  options={magentoGrades}
                />
              </Col>
              <Col span={12}>
                <Input
                  name={gradesForm.name.name}
                  placeholder={gradesForm.name.placeholder}
                  label={gradesForm.name.label}
                  type={gradesForm.name.type}
                  value={gradesForm.name.value}
                  onChange={handleChangeGrades}
                  onClick={(e: any) => e?.stopPropagation()}
                />
              </Col>

              {gradesForm.male_population &&
                (schoolInfo.sex === 'm' || schoolInfo.sex === 'u') && (
                  <Col span={12}>
                    <Input
                      name={gradesForm.male_population.name}
                      placeholder={gradesForm.male_population.placeholder}
                      label={gradesForm.male_population.label}
                      type={gradesForm.male_population.type}
                      value={gradesForm.male_population.value}
                      onChange={handleChangeGrades}
                      onClick={(e: any) => e?.stopPropagation()}
                    />
                  </Col>
                )}

              {gradesForm.female_population &&
                (schoolInfo.sex === 'f' || schoolInfo.sex === 'u') && (
                  <Col span={12}>
                    <Input
                      name={gradesForm.female_population.name}
                      placeholder={gradesForm.female_population.placeholder}
                      label={gradesForm.female_population.label}
                      type={gradesForm.female_population.type}
                      value={gradesForm.female_population.value}
                      onChange={handleChangeGrades}
                      onClick={(e: any) => e?.stopPropagation()}
                    />
                  </Col>
                )}
            </Row>
          </Form>
        </Modal>

        <Modal
          title='Eliminar Grado'
          open={open2}
          cancelButtonProps={{ id: `closeDeleteGradeButton-${data.id}` }}
          onOk={handleOk}
          confirmLoading={loadingModal}
          onCancel={handleCancel}
        >
          <p>{`Seguro que desea eliminar: "${data.name}"`}</p>
        </Modal>
      </Space>
    )
  }
)
GradesActions.displayName = 'GradesActions'
export default GradesActions
