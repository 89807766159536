import FaldaSvg from 'assets/img/uniforms/falda/FaldaSvg'
import LabelFalda from 'assets/img/uniforms/falda/LabelFalda'
import React,{Fragment} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

const FaldaPics = () => {
    const { pictureLogo} = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
    <Fragment>
      <div
        className={`${
          style[`logo-position-${"left"}`]
        }`}
      >
        <img className={`${style['svg-logo']}`} src={pictureLogo} />
      </div>
      <div className={`${style['div-polo-svg-absolute']}`}>
        <FaldaSvg className={`${style['svg']}`} />
      </div>
      <div className={`${style['div-shadows-svg-absolute']}`}>
        <LabelFalda className={`${style['svg']}`} />
      </div>
    </Fragment>
  </div>
    )
}

export default FaldaPics