import { Col, Row } from 'antd'
import CLoader from 'components/CLoader/CLoader'
import useBarChart from 'hooks/reports/useBarChart'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryZoomContainer
} from 'victory'

const BarChart = memo(({ maxHeight, COLOR_SCALE }: any) => {
  const { loadingFilter } = useSelector((state: RootState) => state.sells)

  const { tickFormat, SellsByArticle, loading } = useBarChart()
  if (loadingFilter || loading)
    return (
      <Col span={12}>
        <Row
          style={{
            height: maxHeight,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <CLoader />
        </Row>
      </Col>
    )
  const renderInnerCharts = () => {
    const charts: any = []
    Object.entries(SellsByArticle).forEach(
      ([, values]: any, position: number) => {
        if (values.length > 0)
          charts.push(
            <VictoryBar
              style={{ data: { fill: COLOR_SCALE[position] } }}
              data={values}
              x='x'
              y='y'
              labels={({ datum }) => datum.label}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={0}
                  flyoutStyle={{ fill: 'white' }}
                />
              }
              barWidth={6}
            />
          )
      }
    )
    return charts
  }
  return (
    <Col span={14} style={{ maxHeight }}>
      <VictoryChart
        width={650}
        domainPadding={15}
        theme={VictoryTheme.material}
        /* domain={{ y: [-500, 150000] }} */
        containerComponent={<VictoryZoomContainer />}
        colorScale={COLOR_SCALE}
      >
        <VictoryAxis /* tickValues={tickValues}  */ tickFormat={tickFormat} />
        <VictoryAxis dependentAxis />
        <VictoryStack>
          {renderInnerCharts().map((res: any) => res)}
        </VictoryStack>
      </VictoryChart>
    </Col>
  )
})
BarChart.displayName = 'BarChart'
export default BarChart
