import { memo } from 'react'
import Filters from '../Filters/Filters'
import { Col } from 'antd'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useLocation } from 'react-router-dom'
import GraphicSection from '../GraphicSection/GraphicSection'
import { Alert } from 'antd'
import Marquee from 'react-fast-marquee'

const ReportDetails = memo(() => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const { state } = useLocation()

  return (
    <>
      <Col span={24}>
        <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              ¡Sección en desarrollo! Podrías experimentar problemas, estamos
              trabajando para mejorarla. 
              Agradecemos su paciencia. Contacten con
              atención al cliente si necesitan ayuda. Gracias.
            </Marquee>
          }
        />
      </Col>
      <Col span={24}>
        <TitleHeader
          IconName='schoolIcon'
          title={
            uid.role !== 'Ezetera' ? 'Reportes' : ` Reportes de ${state?.name}`
          }
        />
      </Col>

      <Col span={24}>
        <Filters />
      </Col>

      <Col span={24}>
        <GraphicSection />
      </Col>
    </>
  )
})
ReportDetails.displayName = 'ReportDetails'
export default ReportDetails
