import { createSlice } from '@reduxjs/toolkit'
import { InputFormConfig } from 'types'

export interface levelsFormI {
  name: InputFormConfig
  id_magento: InputFormConfig
}
export interface gradesFormI {
  name: InputFormConfig
  male_population?: InputFormConfig
  female_population?: InputFormConfig
  id_magento?: any
}

export interface levelsAndGradesRedux {
  levelsForm: levelsFormI
  gradesForm: gradesFormI
  page_type: 'structure' | 'element_list'
  magentoGrades: any
  terminateStructure:boolean
}

export const INPUTS_INITIAL_STATE = {
  id_magento: {
    label: 'Selecciona el nivel',
    name: 'id_magento',
    placeholder: 'Selecciona el nivel',
    value: null,
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: false
  },
  name: {
    label: 'Renombra el nivel',
    name: 'name',
    placeholder: 'Renombra el nivel',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: '',
    id: undefined
  }
}
export const INPUTS_INITIAL_STATE_GRADES = {
  name: {
    label: 'Renombra el grado',
    name: 'name',
    placeholder: 'Renombra el grado',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: ''
  },
  male_population: {
    label: 'Población masculina proyectada',
    name: 'male_population',
    placeholder: '000',
    value: '',
    type: 'number',
    required: true,
    minLenght: 0,
    maxLength: 6, //maxLength solo funciona para tipo text
    status: '' // puede ser 'warning' o 'error' segun antd
  },
  female_population: {
    label: 'Población femenina proyectada',
    name: 'female_population',
    placeholder: '000',
    value: '',
    type: 'number',
    required: true,
    minLenght: 0,
    maxLength: 6, //maxLength solo funciona para tipo text
    status: '' // puede ser 'warning' o 'error' segun antd
  },
  id_magento: {
    label: 'Selecciona el grado',
    name: 'id_magento',
    placeholder: 'Selecciona el grado',
    value: null,
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: false
  }
}

const initialState: levelsAndGradesRedux = {
  levelsForm: INPUTS_INITIAL_STATE,
  gradesForm: INPUTS_INITIAL_STATE_GRADES,
  page_type: 'structure',
  magentoGrades: [],
  terminateStructure:false
}

export const authSlice = createSlice({
  name: 'levelsAndGrades',
  initialState,
  reducers: {
    setLevelsForm: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setGradesForm: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    moveToStructure: (state) => {
      return {
        ...state,
        page_type: 'structure'
      }
    },
    moveToElementList: (state) => {
      return {
        ...state,
        page_type: 'element_list'
      }
    },
    setMagentoGrades: (state, action) => {
      return {
        ...state,
        magentoGrades: action.payload
      }
    },
    setTerminateStructure: (state, action) => {
      return {
        ...state,
        terminateStructure: action.payload
      }
    }
  }
})

// Metodo global para actualizar el state
export const {
  setLevelsForm,
  setGradesForm,
  moveToElementList,
  moveToStructure,
  setMagentoGrades,
  setTerminateStructure
} = authSlice.actions

export default authSlice.reducer
