import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import style from './BackButton.module.scss'
import useIsAdminEzetera from 'hooks/isAdminEzetera/useIsAdminEzetera'
import { RootState } from 'redux/store/store'
import { useSelector } from 'react-redux'
interface BackButtonI {
  text?: string
  onClick?: () => void
  textDecoration?:
    | 'dashed'
    | 'dotted'
    | 'double'
    | 'line-through'
    | 'none'
    | 'overline'
    | 'solid'
    | 'underline'
    | 'wavy'
}
const BackButton = memo(
  ({ text = 'Regresar', onClick, textDecoration }: BackButtonI) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const isAdmin = useIsAdminEzetera()
    const { hiddeBack } = useSelector((state: RootState) => state.notebooks)

    const handleClick = () => {
      if (onClick) return onClick() // si le pasas una funcion la ejecuta
      navigate(-1) // sino se va una vista atras
    }
    const backToPackage = () => {
      if (isAdmin) navigate(-1)
      else navigate('/cuadernos')
    }
    let Style = {}
    if (textDecoration) Style = { ...Style, textDecoration }
    return (
      <div className={style.backButtonsContainer}>
        {pathname.includes('cuadernos') && !isAdmin && !hiddeBack && (
          <span
            onClick={handleClick}
            style={Style}
            className={style.backButton}
          >
            <ChevronLeftIcon />
            {text}
          </span>
        )}

        {pathname.includes('cuadernos') && (
          <>
            |
            <span
              onClick={backToPackage}
              style={Style}
              className={style.backButton}
            >
              Regresar a paquetes
            </span>
          </>
        )}
      </div>
    )
  }
)

export default BackButton
