import React from 'react'
import { IconProps } from '../../../types'
export const GradeBoy = ({
  height = 30,
  width = 22,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 9.66667C13.2091 9.66667 15 7.87581 15 5.66667C15 3.45753 13.2091 1.66667 11 1.66667C8.79086 1.66667 7 3.45753 7 5.66667C7 7.87581 8.79086 9.66667 11 9.66667Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0003 23V28.3333M9.00033 23V28.3333M15.0003 23H7.00033L1.66699 9.66667H20.3337L15.0003 23Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
