import { useEffect, useState } from 'react'
import { RootState } from 'redux/store/store'
import { useSelector } from 'react-redux'
import {
  getSchoolListByIsbn,
  getSchoolListBySku
} from 'services/commentsServices/comments'
import { useLocation } from 'react-router-dom'
export interface SchoolListbySkuI {
  sku: string
  id_school: number
  name_school: string,
  origin?: boolean
}
export function useRenderList() {
  const { data } = useSelector((state: RootState) => state.comments)
  const [schoolList, setschoolList] = useState<SchoolListbySkuI[]>([])
  const [loading, setloading] = useState(true)
  const { pathname } = useLocation()

  const name = data.name
  const sku = data.sku
  const isbn = data.isbn

  useEffect(() => {
    const getSchoolList = async () => {
      try {
        let result = undefined
        let result2:any = undefined
        if (pathname.includes('utiles')) {
          result = await getSchoolListBySku({ sku: (sku!=null && sku!=undefined) ? sku: name, 
              id_package: data?.id_package })
          result = {
            ...(result || {}),
            data: {
              ...(result?.data || {}),
              schools: result?.data?.schools?.map((it:any) => ({
                ...it,
                origin: it.owner
              }))
            }
          }
        }
        if (pathname.includes('libros')) {
          result = await getSchoolListByIsbn({ isbn: (isbn!=null && isbn!=undefined) ? isbn: name,
            id_package: data?.id_package })
          result = {
            ...(result || {}),
            data: {
              ...(result?.data || {}),
              schools: result?.data?.schools?.map((it:any) => ({
                ...it,
                origin: it?.id_school == data?.id_school
              }))
            }
          }
        }
        
        setschoolList(result.data.schools)
      } catch (error) {
        console.log(error)
      } finally {
        setloading(false)
      }
    }
    getSchoolList()
  }, [data])

  return {
    loading,
    schoolList
  }
}
