import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

import ShortDeportivoFrontalSvg from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/ShortDeportivoFrontalSvg'
import ShadowsShortDeportivoFrontal from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/ShadowsShortDeportivoFrontal'
import LabelShortDeportivoFrontal from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/LabelShortDeportivoFrontal'

const ShortsDeportivosPics = () => {
  const { poloValues, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      <Fragment>
        <div className={`${style[`logo-position-${'left'}`]}`}>
          <img className={`${style['svg-logo']}`} src={pictureLogo} />
        </div>
        <div className={`${style['div-polo-svg-absolute']}`}>
          <ShortDeportivoFrontalSvg className={`${style['svg']}`} />
        </div>

        <div className={`${style['div-label-svg-absolute']}`}>
          <ShadowsShortDeportivoFrontal className={`${style['svg']}`} />
        </div>
        <div className={`${style['div-tags-svg-absolute']}`}>
          <LabelShortDeportivoFrontal className={`${style['svg']}`} />
        </div>
      </Fragment>
    </div>
  )
}

export default ShortsDeportivosPics
