import React from 'react'

const ChalecoSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Contorno_chaleco" data-name="Contorno chaleco"   d="m498,116.26c.88.18,73.73,20.06,78.77,21.1-8.03,116.57-21.74,181.88,16.95,196.09-48.4,183.83-15.08,334.8-18.4,337.83-200.07,39.87-349.78-2.84-350.64-4.31.31-2.54,19.28-191.65-18.4-333.52,37.29-11.17,27.59-79.35,17.33-195.45.03-.15.33-.22.44-.25.37-.12.76-.2,1.14-.27,1.25-.25,2.52-.42,3.79-.59,1.59-.22,3.19-.42,4.78-.62s3.05-.39,4.56-.62c.89-.13,1.79-.19,2.66-.45s1.8-.58,2.72-.82,1.77-.4,2.65-.61,1.78-.42,2.67-.63c1.07-.25,2.13-.51,3.2-.76,1.22-.29,2.43-.58,3.65-.87,1.35-.32,2.69-.64,4.04-.97,1.45-.35,2.9-.69,4.35-1.04,1.53-.37,3.06-.73,4.59-1.1s3.18-.76,4.77-1.14,3.25-.78,4.87-1.17,3.27-.78,4.9-1.18,3.24-.78,4.86-1.17,3.17-.76,4.75-1.14,3.05-.73,4.57-1.1c1.44-.35,2.88-.69,4.32-1.04,1.33-.32,2.66-.64,4-.96,1.2-.29,2.4-.58,3.6-.87,1.05-.25,2.09-.51,3.14-.76.87-.21,1.74-.42,2.61-.63.67-.16,1.33-.32,2-.49.44-.11.88-.21,1.32-.32.19-.05.39-.09.59-.15,19.93-5.72,143.91-6.28,178.86,0Z"/>
      <g id="Cuello">
        <path id="Cuello_izquierda" data-name="Cuello izquierda"   d="m328.04,114.87c1.67,4.21,16.57,41.42,35.08,72.29,8.08,13.49,47.94,64.52,47.94,64.52v19.36s-36.01-29.74-62.53-74.63c-19.4-32.83-36.99-73.23-38.86-77.54l6.86-1.98,7.4-1.63,4.11-.4Z"/>
        <path id="Cuello_derecha" data-name="Cuello derecha"   d="m512.99,120.52l-13.94-3.76s-18.97,37.63-38.62,70.4c-8.08,13.49-47.94,64.52-47.94,64.52v19.36s36.01-29.74,62.53-74.63c20.52-34.73,37.96-75.9,37.96-75.9Z"/>
        <path id="Cuello_interno" data-name="Cuello interno"   d="m493.86,126.84s-43.32-2.13-78.21-2.83c-42.05-.84-82.88,2.31-82.88,2.31"/>
        <path id="Linea_1_cuello_izquierda" data-name="Linea 1 cuello izquierda"   d="m315.35,117.15s26.16,60.92,40.2,83.09c22.61,35.72,55.5,65.66,55.5,65.66"/>
        <path id="Linea_2_cuello_izquierda" data-name="Linea 2 cuello izquierda"   d="m321.71,115.83s24.5,57.92,38.53,80.09c22.61,35.72,50.4,62.44,50.4,62.44l.41.36"/>
        <path id="Linea_1_cuello_derecha" data-name="Linea 1 cuello derecha"   d="m508.49,119.3s-27.77,59.05-41.81,81.22c-20.71,32.72-49.5,60.3-54.19,64.7"/>
        <path id="Linea_2_cuello_derecha" data-name="Linea 2 cuello derecha"   d="m503.8,118.03s-25.13,53.3-39.16,75.47c-20.54,32.45-47.34,59.45-52.07,64.11"/>
      </g>
      <path id="Lateral_izquierda" data-name="Lateral izquierda"   d="m209.84,347.68s24.46-7.57,33.16-34.59c8.7-27.02,2.04-99.3.84-111.9-3.05-31.98-4.34-65.69-4.34-65.69"/>
      <path id="Lateral_derecha" data-name="Lateral derecha"   d="m559.56,133.38s-.9,35.83-3.94,67.81c-1.2,12.6-7.86,84.88.84,111.9,8.7,27.02,33.16,34.59,33.16,34.59l.46.16"/>
      <path id="Cintura"   d="m226.98,637.79s95.43,15.72,175.41,15.72,170.42-10.25,170.42-10.25"/>
      <path id="Linea_1_cintura" data-name="Linea 1 cintura"   d="m226.32,659.59s95.43,15.72,175.41,15.72,171.96-12.36,171.96-12.36"/>
      <path id="Linea_2_cintura" data-name="Linea 2 cintura"   d="m226.21,650.28s95.43,15.72,175.41,15.72,171.96-12.36,171.96-12.36"/>
      <path id="Linea_1_lateral_izquierda" data-name="Linea 1 lateral izquierda"   d="m209.01,344.22s20.25-6.1,28.95-33.13c8.7-27.02,1.08-101.82-.12-114.42-3.05-31.98-5.34-60.07-5.34-60.07"/>
      <path id="Linea_2_lateral_izquierda" data-name="Linea 2 lateral izquierda"   d="m208.15,339.35s16.62-2.93,25.32-29.95c8.7-27.02.86-100.14-.34-112.74-3.05-31.98-5.34-60.07-5.34-60.07"/>
      <path id="Linea_1_lateral_derecha" data-name="Linea 1 lateral derecha"   d="m566.35,135.26s-1.69,29.58-4.73,61.56c-1.2,12.6-9.06,87.81-.36,114.83,8.7,27.02,29.24,32.85,29.24,32.85h0s.37.11.37.11"/>
      <path id="Linea_2_lateral_derecha" data-name="Linea 2 lateral derecha"   d="m591.85,339.35s-16.56-2.36-25.26-29.39c-8.7-27.02-.59-98.12.61-110.72,3.05-31.98,4.73-61.56,4.73-61.56"/>
      <g id="Ubicación_escudo" data-name="Ubicación escudo">
        <circle   cx="499.05" cy="305.09" r="22.66"/>
        <circle   cx="287.05" cy="305.09" r="22.66"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default ChalecoSvg
