import { AUTHSERVICE } from '../config'

interface IPriceNotebook {
  data: {
    price_notebook: string
    success: boolean
  }
  status: number
  statusText: string
}

const notebookPrice = async (): Promise<IPriceNotebook> => {
  return await AUTHSERVICE().get(`/notebook/price`)
}

const getNotebookPrice = async () => {
  const results = await notebookPrice()
  return results as IPriceNotebook
}

export { getNotebookPrice }
