import { useState } from 'react'
import { changePassword, changePasswordData } from 'services/auth/users'
import { LockOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useNavigate } from 'react-router-dom'
import { validPass } from '../../constants'
import { InputFormProps } from 'components/formInputs/input'
import { Form, FormInstance, Tooltip } from 'antd'

export const InputsNewPass: InputFormProps[] = [
  {
    name: 'password',
    type: 'password',
    label: (
      <div
        style={{ width: '260px', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Tooltip title='Escribe una contraseña valida. Al menos una letra mayuscula, un número, un carácter especial y mayor o igual 8 caracteres.'>
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
        </Tooltip>
      </div>
    ),
    placeholder: '**********',
    rules: [
      {
        validator: (_: any, value: string): Promise<void> => {
          if (value && validPass.test(value.trim())) {
            return Promise.resolve()
          }
          return Promise.reject('Escribe una contraseña valida.')
        }
      }
    ],

    prefix: <LockOutlined />,
    suffix: (
      <Tooltip title='Escribe una contraseña valida. Al menos una letra mayuscula, un número, un carácter especial y mayor o igual 8 caracteres.'>
        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
      </Tooltip>
    )
  },
  {
    name: 'confirmPassword',
    type: 'password',
    placeholder: '**********',
    rules: [
      { required: true, message: '' },
      ({ getFieldValue }: any) => ({
        validator(_: any, value: string) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve()
          }
          return Promise.reject('Las contraseñas tienen que ser iguales.')
        }
      })
    ],

    prefix: <LockOutlined />
  }
]

interface Functions {
  loading: boolean
  form: FormInstance<any>
  handleNewPassword: (value: changePasswordData, token: string) => Promise<void>
}

export const useNewPassword = (): Functions => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const handleNewPassword = async (
    value: changePasswordData,
    token: string
  ): Promise<void> => {
    try {
      setLoading(true)

      if (!token) {
        ErrorToast('Ups!. Algo salio mal')
        return
      }
      await changePassword(value, token)

      SuccessToast(
        `Tu contraseña fue actualizada exitosamente. Por favor vuelve a iniciar sesión y utiliza tu nueva contraseña.`
      )
      form.resetFields()
      navigate('/')
    } catch (error: any) {
      console.log(error)
      ErrorToast('Ups!. Algo salio mal')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    form,
    handleNewPassword
  }
}
