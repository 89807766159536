import React from 'react'
import Navbar from 'components/Navbar/Navbar'
import style from './editor.module.scss'
import FixedInfoFooter from '../components/fixedInfoFooter/FixedInfoFooter'
import PoloEditable from './components/clothing/PoloEditable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PoloOptionsEditable from './components/clotheOptions/PoloOptionsEditable'
import {
  handleModalPicture,
  setFemale
} from '../../../redux/reducers/uniformsSlice'
import SportShirtOptionEditable from './components/clotheOptions/SportShirtOptionEditable'
import TShirtEditable from './components/clothing/TShirtEditable'
import TShirtOptionEditable from './components/clotheOptions/TShirtOptionEditable'
import JumperEditable from './components/clothing/JumperEditable'
import Blusa_3_4_Editable from './components/clothing/Blusa_3_4_Editable'
import FaldaEditable from './components/clothing/FaldaEditable'
import SueterEditable from './components/clothing/SueterEditable'
import CamisaMcMlEditable from './components/clothing/CamisaMcMlEditable'
import CorbataEditable from './components/clothing/CorbataEditable'
import TrajedeBanoMasculinoEditable from './components/clothing/TrajedeBanoMasculinoEditable'
import TrajeBanoFem2pzaEditable from './components/clothing/TrajeBanoFem2pzaEditable'
import ConjuntoPantalonEditable from './components/clothing/ConjuntoPantalonEditable'
import LabelsUniforms from '../components/LabelsUniforms/LabelsUniforms'
import ConjuntoSacoEditable from './components/clothing/ConjuntoSacoEditable'
import ConjuntoHoodieEditable from './components/clothing/ConjuntoHoodieEditable'
import ChalecoEditable from './components/clothing/ChalecoEditable'
import ConjuntoTrajeBanoFem from './components/clothing/ConjuntoTrajeBanoFem'
import Blusa3_4OptionsEditable from './components/clotheOptions/Blusa3_4OptionsEditable'
import JumperOptionsEditable from './components/clotheOptions/JumperOptionsEditable'
import FaldaOptionsEditable from './components/clotheOptions/FaldaOptionsEditable'
import SueterOptionsEditable from './components/clotheOptions/SueterOptionsEditable'
import SacoOptionsEditable from './components/clotheOptions/SacoOptionsEditable'
import CorbataOptionsEditable from './components/clotheOptions/CorbataOptionsEditable'
import HoodieOptionsEditable from './components/clotheOptions/HoodieOptionsEditable'
import AddImgUnifotmsModal from 'modules/uniforms/editor/components/selectCardOption/AddImgUnifotmsModal'
import CamisaMlMcOptionsEditable from './components/clotheOptions/CamisaMlMcOptionsEditable'
import ChalecoOptionsEditable from './components/clotheOptions/ChalecoOptionsEditable'
import PantalonOptionsEditable from './components/clotheOptions/PantalonOptionsEditable'
import TrajeBanoMaleOptionsEditable from './components/clotheOptions/TrajeBanoMaleOptionsEditable'
import TrajeFem1pzaOptionsEditable from './components/clotheOptions/TrajeFem1pzaOptionsEditable'
import TrajeBanoFema2pzaOptionsEditable from './components/clotheOptions/TrajeBanoFema2pzaOptionsEditable'
import JacketEditable from './components/clothing/JacketEditable'
import ConjuntoPantsDeportivos from './components/clothing/ConjuntoPantsDeportivos'
import SportShirtEditable from './components/clothing/SportShirtEditable'
import ConjuntoFaldaShort from './components/clothing/ConjuntoFaldaShort'
import ConjuntoShortDeportivo from './components/clothing/ConjuntoShortDeportivo'
import ChalecoCapitonadoEditable from './components/clothing/ChalecoCapitonadoEditable'
import ChamarraCapitonadaEditable from './components/clothing/ChamarraCapitonadaEditable'
import ChamarraDeportivaOptions from './components/clotheOptions/ChamarraDeportivaOptions'
import PantsDeportivosOptions from './components/clotheOptions/PantsDeportivosOptions'
import FaldaShortDeportivoOptions from './components/clotheOptions/FaldaShortDeportivoOptions'
import ShortDeportivoOptions from './components/clotheOptions/ShortDeportivoOptions'
import ChalecoCapitonadoOptions from './components/clotheOptions/ChalecoCapitonadoOptions'
import ChamarraCapitonadaOptions from './components/clotheOptions/ChamarraCapitonadaOptions'

const EditorModule = () => {
  const {
    clotheSelected,
    female,
    showModalPictureLogo,
    pictureLogo,
    clotheNameDescription: clotheName
  } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleOk = () => {
    dispatch(handleModalPicture(false))
  }
  const handleCancel = () => {
    dispatch(handleModalPicture(false))
  }
  return (
    <div className={`${style['body-div']}`}>
      <Navbar complete />
      <FixedInfoFooter />
      <div className={`${style['div-grid']}`}>
        <div className={`${style['div-left']}`}>
          {(() => {
            switch (clotheSelected) {
              case 1:
                return <PoloEditable />
              case 3:
                return <TShirtEditable />
              case 4:
                return <JacketEditable />
              case 11:
                return <ConjuntoPantsDeportivos />
              case 12:
                return <SportShirtEditable />
              case 13:
                return <ConjuntoFaldaShort />
              case 14:
                return <ConjuntoShortDeportivo />
              case 6:
                return <Blusa_3_4_Editable />
              case 8:
                return <JumperEditable />
              case 9:
                return <FaldaEditable />
              case 10:
                return <SueterEditable />
              case 17:
                return <CamisaMcMlEditable />
              case 18:
                return <ChalecoEditable />
              case 19:
                return <ConjuntoPantalonEditable /> // <PantalonEditable />
              case 28:
                return <ConjuntoSacoEditable />
              case 30:
                return <ConjuntoHoodieEditable />
              case 32:
                return <ChalecoCapitonadoEditable />
              case 15:
                return <ChamarraCapitonadaEditable />
              case 34:
                return <CorbataEditable />
              case 35:
                return <TrajedeBanoMasculinoEditable />
              case 36:
                return <ConjuntoTrajeBanoFem />
              case 38:
                return <TrajeBanoFem2pzaEditable />

              default:
                return <></>
            }
          })()}
        </div>
        <div className={`${style['div-select-options']}`}>
          <LabelsUniforms />
          <div
            onClick={() => {
              dispatch(setFemale(!female))
            }}
            className={`${style['div-title-type']}`}
          >
            <h3 className={`${style['title-h']}`}>{clotheName.name} </h3>
            <p className={`${style['description-title']}`}>
              {clotheName.description}
            </p>
          </div>

          {(() => {
            switch (clotheSelected) {
              case 1:
                return <PoloOptionsEditable />
              case 2:
                return <SportShirtOptionEditable />
              case 3:
                return <TShirtOptionEditable />
              case 4:
                return <ChamarraDeportivaOptions /> //<ConjuntoDeportivoOptionsEditable />
              case 11:
                return <PantsDeportivosOptions />
              case 12:
                return <SportShirtOptionEditable />
              case 13:
                return <FaldaShortDeportivoOptions />
              case 14:
                return <ShortDeportivoOptions />
              case 6:
                return <Blusa3_4OptionsEditable />
              case 8:
                return <JumperOptionsEditable />
              case 9:
                return <FaldaOptionsEditable />
              case 10:
                return <SueterOptionsEditable />
              case 17:
                return <CamisaMlMcOptionsEditable />
              case 18:
                return <ChalecoOptionsEditable />
              case 19:
                return <PantalonOptionsEditable />
              case 28:
                return <SacoOptionsEditable />
              case 30:
                return <HoodieOptionsEditable />
              case 34:
                return <CorbataOptionsEditable />
              case 35:
                return <TrajeBanoMaleOptionsEditable />
              case 36:
                return <TrajeFem1pzaOptionsEditable />
              case 32:
                return <ChalecoCapitonadoOptions /> //<CapitonadosOptionsEditable />
              case 15:
                return <ChamarraCapitonadaOptions />
              case 38:
                return <TrajeBanoFema2pzaOptionsEditable />
              default:
                return <></>
            }
          })()}
        </div>
      </div>
      <AddImgUnifotmsModal
        thisPicture={pictureLogo}
        isModalOpen={showModalPictureLogo}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default EditorModule
