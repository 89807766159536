import React from 'react'

const PantsFrontalSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Outline_pants" data-name="Outline pants"   d="m294.28,34.94s17.4-9.36,88.45-8.44c71.04.93,95.01,3.62,106.1,4.82,12.35,1.34,16.61,4.87,16.61,4.87l5.54,30.91s17.07,71.04,18.91,126.86c1.85,55.82-2.77,220.5-2.77,220.5,0,0,.41,63.2,3.21,72.42,2.79,9.23,5.1,12.46,4.18,14.3-.92,1.85-8.31,261.56-12.46,265.25-4.15,3.7-68.88-.48-68.88-.48-6.46-21.3-14.16-206.65-14.16-206.65,0,0-3.69-37.83.46-38.29,4.15-.46-.92-11.54-.92-11.54,0,0-.46-13.84,1.38-15.68,1.84-1.84-2.06-19.13-3.23-22.14-3.13-8.12-14.48-107.08-14.16-120.9.19-7.73-4.76-38.25-4.76-38.25,0,0-9.69-53.51-19.84-56.28-10.15-2.77-19.84,64.58-19.84,64.58,0,0-7.84,86.26-7.84,99.64s-6.45,50.74-9.22,54.43c-2.77,3.69-2.31,16.15-.92,17.99,1.38,1.85.46,16.61-.92,17.53-1.38.92,2.31,11.53,2.31,11.53,1.85,6.92.92,36.9.92,51.66s-7.72,194.36-7.72,194.36c0,0-53.85,12.3-75.9.43,0,0-12.65-219.84-13.25-224.78-1.19-9.7,1.84-63.65,4.61-63.2,2.77.46,2.77-63.32,2.77-81.25s-4.61-188.62-4.61-188.62c7.38-108.29,16.55-124.56,18.74-143.12,3.38-28.66,7.22-32.5,7.22-32.5Z"/>
      <path id="Cintura"   d="m294.28,34.94s20.37-8.5,86.63-8.46c24.12.01,31.83.57,31.83.57,0,0,93.4,2.58,92.7,9.14-.7,6.56-88.01,8.41-88.01,8.41h-44.95s-68.36-2.87-78.19-9.66Z"/>
      <path id="Pretina_cintura" data-name="Pretina cintura"   d="m287.06,67.44s3.01-21.66,4.2-24.59c1.55-3.8,3.02-7.91,3.02-7.91,0,0,35.85,7.67,87.32,9.66,28.6,1.11,37.93,0,37.93,0,0,0,63.9-.69,85.91-8.41l4.23,23.63,1.3,7.28s-45.08,8.66-97.06,7.71c-23.9-.44-36.05.07-36.05.07,0,0-64.15,0-90.8-7.44Z"/>
      <line id="División_en_medio" data-name="División en medio"   x1="397.95" y1="256.23" x2="397.4" y2="74.88"/>
      <g id="Costuras">
        <g>
          <line   x1="295.52" y1="81.43" x2="296.25" y2="81.59"/>
          <line   x1="297.49" y1="81.86" x2="304.3" y2="83.36"/>
          <path   d="m304.92,83.49l.73.16s-.04.25-.12.74"/>
          <path   d="m305.3,85.87c-2.28,14.35-14.83,93.57-13.85,95.53.82,1.65.36,8,.09,10.92"/>
          <path   d="m291.47,193.07c-.05.47-.08.74-.08.74h-.75"/>
          <line   x1="289.34" y1="193.82" x2="282.19" y2="193.82"/>
          <line   x1="281.54" y1="193.82" x2="280.79" y2="193.82"/>
        </g>
        <g>
          <line   x1="499.16" y1="82.73" x2="498.43" y2="82.89"/>
          <line   x1="497.19" y1="83.16" x2="490.38" y2="84.64"/>
          <path   d="m489.76,84.78l-.73.16s.04.25.12.74"/>
          <path   d="m489.38,87.16c2.28,14.36,14.83,93.57,13.85,95.53-.82,1.64-.36,8-.09,10.93"/>
          <path   d="m503.21,194.37c.05.47.08.74.08.74h.75"/>
          <line   x1="505.62" y1="195.11" x2="514.36" y2="195.11"/>
          <line   x1="515.15" y1="195.11" x2="515.9" y2="195.11"/>
        </g>
        <g>
          <path   d="m291.08,47.41s.25.03.74.07"/>
          <path   d="m293.31,47.62c18.39,1.73,145.7,12.98,212.65.08"/>
          <path   d="m506.71,47.55c.25-.05.49-.1.74-.15"/>
        </g>
        <g>
          <path   d="m289.22,57.58s.25.02.74.07"/>
          <path   d="m291.46,57.79c18.58,1.71,148.4,13,215.44.08"/>
          <path   d="m507.64,57.73c.25-.05.49-.1.74-.15"/>
        </g>
        <g>
          <path   d="m451.8,753.77s.24.06.73.15"/>
          <path   d="m454.01,754.19c6.59,1.09,28.19,3.7,68.36-.27"/>
          <path   d="m523.14,753.85c.25-.02.5-.05.75-.08"/>
        </g>
        <g>
          <path   d="m451.8,757.18s.24.06.73.15"/>
          <path   d="m454.01,757.6c6.59,1.09,28.19,3.7,68.36-.27"/>
          <path   d="m523.14,757.26c.25-.02.5-.05.75-.08"/>
        </g>
        <g>
          <path   d="m278.01,758.88s.24.06.73.15"/>
          <path   d="m280.19,759.28c6.64,1.09,28.74,3.73,69.97-.26"/>
          <path   d="m350.9,758.95c.25-.02.5-.05.75-.07"/>
        </g>
        <g>
          <path   d="m279.75,756.05s.24.06.73.15"/>
          <path   d="m281.93,756.45c6.64,1.09,28.74,3.73,69.97-.26"/>
          <path   d="m352.64,756.12c.25-.02.5-.05.75-.07"/>
        </g>
      </g>
      <path id="Línea_1_lado_izqueirdo" data-name="Línea 1 lado izqueirdo"   d="m295,69s-16.84,123.53-17.04,142.09c-.33,31.64,5.45,127.77,5.03,188.91-.32,47.04,4.03,72.55-4.19,88.1-6.69,12.66-.55,77,0,108.49.55,31.48,9.2,175.41,9.2,175.41"/>
      <path id="Línea_2_lado_izqueirdo" data-name="Línea 2 lado izqueirdo"   d="m290.08,68.44s-16.92,124.1-17.11,142.65c-.33,31.64,5.45,127.77,5.03,188.91-.32,47.04,4.03,72.55-4.19,88.1-6.69,12.66-.55,77,0,108.49.55,31.48,9.2,173.41,9.2,173.41"/>
      <path id="Línea_1_lado_derecho" data-name="Línea 1 lado derecho"   d="m499,69s19.83,79.17,18.59,141.25c-.64,31.64-3.36,128.61-2.94,189.75.32,47.04-2.98,80.51,5.24,96.07,6.69,12.66-.52,69.04-1.07,100.52-.55,31.48-8.44,170.94-8.44,170.94"/>
      <path id="Línea_2_lado_derecho" data-name="Línea 2 lado derecho"   d="m505.44,67.68s20.39,80.49,19.15,142.57c-.64,31.64-3.36,128.61-2.94,189.75.32,47.04-2.98,80.51,5.24,96.07,6.69,12.66-.52,69.04-1.07,100.52-.55,31.48-8.44,170.94-8.44,170.94"/>
      <g id="Escudo">
        <g>
          <circle   cx="479.5" cy="213.5" r="14"/>
          <path d="m479.5,200c7.44,0,13.5,6.06,13.5,13.5s-6.06,13.5-13.5,13.5-13.5-6.06-13.5-13.5,6.06-13.5,13.5-13.5m0-1c-8.01,0-14.5,6.49-14.5,14.5s6.49,14.5,14.5,14.5,14.5-6.49,14.5-14.5-6.49-14.5-14.5-14.5h0Z"/>
        </g>
        <g>
          <circle   cx="305.5" cy="213.5" r="14"/>
          <path d="m305.5,200c7.44,0,13.5,6.06,13.5,13.5s-6.06,13.5-13.5,13.5-13.5-6.06-13.5-13.5,6.06-13.5,13.5-13.5m0-1c-8.01,0-14.5,6.49-14.5,14.5s6.49,14.5,14.5,14.5,14.5-6.49,14.5-14.5-6.49-14.5-14.5-14.5h0Z"/>
        </g>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default PantsFrontalSvg
