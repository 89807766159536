import {
  TFormPoloFemaleOptions,
  TFormPoloMaleOptions
} from 'interfaces/uniforms-interface'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handlePoloForm,
  handlePoloFormFemale
} from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import styled from './clotheoptions.module.scss'
import ConstructorFormulary from './ConstructorFormulary'

const PoloOptionsEditable = () => {
  const {
    poloValues,
    poloValuesFemale,
    female
  } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormPoloMaleOptions | TFormPoloFemaleOptions,
    value: string | boolean
  ) => {
    if (!female)
      dispatch(
        handlePoloForm({
          ...poloValues,
          [keyName]: {
            ...poloValues[keyName as TFormPoloMaleOptions],
            value: value
          }
        })
      )
    else
      dispatch(
        handlePoloFormFemale({
          ...poloValuesFemale,
          [keyName]: {
            ...poloValuesFemale[keyName as TFormPoloFemaleOptions],
            value: value
          }
        })
      )
  }

  return (
    <div>
      {(() => {
        return (
          <Fragment>
            {female ? (
              <ConstructorFormulary
                handleChange={handleChange}
                form={poloValuesFemale}
              />
            ) : (
              <ConstructorFormulary
                handleChange={handleChange}
                form={poloValues}
              />
            )}
          </Fragment>
        )
      })()}
    </div>
  )
}

export default PoloOptionsEditable
