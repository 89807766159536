import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathDobleRayaVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  show,
  ink
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  show: number
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Doble raya Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Doble_raya_Completo'
              data-name='Path Doble raya Completo'
            >
              <g
                id='Path_Doble_raya_Completo-2'
                data-name='Path Doble raya Completo'
                fill={`${ink !== 'black' && ink}`}
                stroke={`${ink !== 'black' && ink}`}
              >
                <g id='Rayado_Completo' data-name='Rayado Completo'>
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='126.23'
                    x2='200.18'
                    y2='126.23'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='139.21'
                    x2='200.18'
                    y2='139.21'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='161.42'
                    x2='200.18'
                    y2='161.42'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='174.4'
                    x2='200.18'
                    y2='174.4'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='196.61'
                    x2='200.18'
                    y2='196.61'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='209.6'
                    x2='200.18'
                    y2='209.6'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='231.81'
                    x2='200.18'
                    y2='231.81'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='244.79'
                    x2='200.18'
                    y2='244.79'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='267'
                    x2='200.18'
                    y2='267'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='279.99'
                    x2='200.18'
                    y2='279.99'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='302.2'
                    x2='200.18'
                    y2='302.2'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='315.18'
                    x2='200.18'
                    y2='315.18'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='337.39'
                    x2='200.18'
                    y2='337.39'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='350.37'
                    x2='200.18'
                    y2='350.37'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='372.58'
                    x2='200.18'
                    y2='372.58'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='385.57'
                    x2='200.18'
                    y2='385.57'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='407.78'
                    x2='200.18'
                    y2='407.78'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='420.76'
                    x2='200.18'
                    y2='420.76'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='442.97'
                    x2='200.18'
                    y2='442.97'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='455.96'
                    x2='200.18'
                    y2='455.96'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='478.17'
                    x2='200.18'
                    y2='478.17'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='491.15'
                    x2='200.18'
                    y2='491.15'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='513.36'
                    x2='200.18'
                    y2='513.36'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='526.34'
                    x2='200.18'
                    y2='526.34'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='548.55'
                    x2='200.18'
                    y2='548.55'
                  />
                  <line
                    id='Hoja_esq._recta-26'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='561.54'
                    x2='200.18'
                    y2='561.54'
                  />
                  <line
                    id='Hoja_esq._recta-27'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='583.75'
                    x2='200.18'
                    y2='583.75'
                  />
                  <line
                    id='Hoja_esq._recta-28'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='596.73'
                    x2='200.18'
                    y2='596.73'
                  />
                  <line
                    id='Hoja_esq._recta-29'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='618.94'
                    x2='200.18'
                    y2='618.94'
                  />
                  <line
                    id='Hoja_esq._recta-30'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='631.92'
                    x2='200.18'
                    y2='631.92'
                  />
                  <line
                    id='Hoja_esq._recta-31'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='654.14'
                    x2='200.18'
                    y2='654.14'
                  />
                  <line
                    id='Hoja_esq._recta-32'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='667.12'
                    x2='200.18'
                    y2='667.12'
                  />
                  <line
                    id='Hoja_esq._recta-33'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='689.33'
                    x2='200.18'
                    y2='689.33'
                  />
                  <line
                    id='Hoja_esq._recta-34'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='702.31'
                    x2='200.18'
                    y2='702.31'
                  />
                  <line
                    id='Hoja_esq._recta-35'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='724.52'
                    x2='200.18'
                    y2='724.52'
                  />
                  {show === 2 && (
                    <g
                      fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                      stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                    >
                      <line x1='220.26' y1='104.02' x2='220.26' y2='745' />
                    </g>
                  )}
                </g>
                <g
                  fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                >
                  {(() => {
                    switch (show) {
                      case 2:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='M210.27,118a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,134.76a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,148.8a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,187.55a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,170a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,201.59a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,240.34a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,222.74a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,254.38a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,293.13a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,275.53a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,307.17a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31A21.06,21.06,0,0,1,211.9,308a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,345.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,328.32a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,360a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.84,1.84,0,0,0-1.22.7,1.66,1.66,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a8.38,8.38,0,0,1,.35,1A8.2,8.2,0,0,1,209,365l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,398.71a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,381.12a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,412.75a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,451.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,433.91a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,465.54a17.68,17.68,0,0,0-2.68,3.43l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,504.29a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,486.7a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,518.34a17.33,17.33,0,0,0-2.68,3.42l-.2-.44Q208,520,207.5,520a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,557.08a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,539.49a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,571.13a17.33,17.33,0,0,0-2.68,3.42l-.2-.43c-.38-.87-.73-1.3-1.05-1.3a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31A21.35,21.35,0,0,1,211.9,572a17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,609.88a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,592.28a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,623.92a17.33,17.33,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,662.67a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,645.07a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,676.71a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,715.46a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,697.86a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,731.27a17.33,17.33,0,0,0-2.68,3.42l-.2-.43c-.38-.87-.73-1.3-1.05-1.3a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='M210.27,152.35a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,166.4a17.33,17.33,0,0,0-2.68,3.42l-.2-.43c-.38-.87-.73-1.3-1.05-1.3a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,118a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,131.2a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.84,1.84,0,0,0-1.22.7,1.66,1.66,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31A21.06,21.06,0,0,1,211.9,132a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,187.55a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,201.59a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,222.74a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,236.78a17.68,17.68,0,0,0-2.68,3.43l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M211.43,272a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,293.13a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,257.94a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,307.17a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31A21.06,21.06,0,0,1,211.9,308a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,328.32a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,342.37a17.33,17.33,0,0,0-2.68,3.42l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,363.52a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,377.56a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,398.71a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,412.75a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,433.91a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,448a17.62,17.62,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,469.1a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,483.14a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.12.4-.32.77-.58l.46-.31A21.06,21.06,0,0,1,211.9,484a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,504.29a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,518.34a17.33,17.33,0,0,0-2.68,3.42l-.2-.44Q208,520,207.5,520a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,539.49a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,553.53a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,574.68a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,588.79a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,609.94a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,624a17.33,17.33,0,0,0-2.68,3.42l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,645.14a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,659.18a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.58l.46-.31A21.06,21.06,0,0,1,211.9,660a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,680.33a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,694.37a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,715.53a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,731.47a17.33,17.33,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )
                      case 1:
                        return (
                          <g>
                            <line
                              id='_Trazado_espejo_'
                              data-name='&amp;lt;Trazado espejo&amp;gt;'
                              x1='628.51'
                              y1='104.02'
                              x2='628.51'
                              y2='745'
                            />
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m638.5,117.95c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,134.76c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,148.8c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,187.55c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,169.95c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,201.59c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,240.34c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,222.74c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,254.38c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,293.13c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,275.53c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,307.17c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,345.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,328.32c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,359.96c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,398.71c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,381.12c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,412.75c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,451.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,433.91c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,465.54c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,504.29c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,486.7c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,518.34c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,557.08c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,539.49c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,571.13c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,609.88c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,592.28c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,623.92c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,662.67c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,645.07c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,676.71c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,715.46c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,697.86c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,731.27c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                      </g>
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo-2'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m638.5,152.35c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,166.4c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,117.95c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,131.2c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,187.55c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,201.59c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,222.74c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,236.78c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m637.34,271.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,293.13c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.5,257.94c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,307.17c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,328.32c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,342.37c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,363.52c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,377.56c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,398.71c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,412.75c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,433.91c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,447.95c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,469.1c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,483.14c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,504.29c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,518.34c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,539.49c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,553.53c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,574.68c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,588.79c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,609.94c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,623.99c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,645.14c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,659.18c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,680.33c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,694.37c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.5,715.53c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m637.34,731.47c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                      </g>
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
          <title>Path Doble raya Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Doble_raya_Datos' data-name='Path Doble raya Datos'>
              <g id='Path_Doble_raya_Datos-2' data-name='Path Doble raya Datos'>
                <g id='Rayado_Datos' data-name='Rayado Datos'>
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='170.77'
                    x2='200.18'
                    y2='170.77'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='183.76'
                    x2='200.18'
                    y2='183.76'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='205.97'
                    x2='200.18'
                    y2='205.97'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='218.95'
                    x2='200.18'
                    y2='218.95'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='241.16'
                    x2='200.18'
                    y2='241.16'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='254.14'
                    x2='200.18'
                    y2='254.14'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='276.35'
                    x2='200.18'
                    y2='276.35'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='289.34'
                    x2='200.18'
                    y2='289.34'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='311.55'
                    x2='200.18'
                    y2='311.55'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='324.53'
                    x2='200.18'
                    y2='324.53'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='346.74'
                    x2='200.18'
                    y2='346.74'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='359.73'
                    x2='200.18'
                    y2='359.73'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='381.94'
                    x2='200.18'
                    y2='381.94'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='394.92'
                    x2='200.18'
                    y2='394.92'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='417.13'
                    x2='200.18'
                    y2='417.13'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='430.11'
                    x2='200.18'
                    y2='430.11'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='452.32'
                    x2='200.18'
                    y2='452.32'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='465.31'
                    x2='200.18'
                    y2='465.31'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='487.52'
                    x2='200.18'
                    y2='487.52'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='500.5'
                    x2='200.18'
                    y2='500.5'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='522.71'
                    x2='200.18'
                    y2='522.71'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='535.7'
                    x2='200.18'
                    y2='535.7'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='557.91'
                    x2='200.18'
                    y2='557.91'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='570.89'
                    x2='200.18'
                    y2='570.89'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='593.1'
                    x2='200.18'
                    y2='593.1'
                  />
                  <line
                    id='Hoja_esq._recta-26'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='606.08'
                    x2='200.18'
                    y2='606.08'
                  />
                  <line
                    id='Hoja_esq._recta-27'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='628.29'
                    x2='200.18'
                    y2='628.29'
                  />
                  <line
                    id='Hoja_esq._recta-28'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='641.28'
                    x2='200.18'
                    y2='641.28'
                  />
                  <line
                    id='Hoja_esq._recta-29'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='663.49'
                    x2='200.18'
                    y2='663.49'
                  />
                  <line
                    id='Hoja_esq._recta-30'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='676.47'
                    x2='200.18'
                    y2='676.47'
                  />
                  <line
                    id='Hoja_esq._recta-31'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='698.68'
                    x2='200.18'
                    y2='698.68'
                  />
                  <line
                    id='Hoja_esq._recta-32'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='711.66'
                    x2='200.18'
                    y2='711.66'
                  />
                  <line
                    id='Hoja_esq._recta-33'
                    data-name='Hoja esq. recta'
                    x1='648.08'
                    y1='733.87'
                    x2='200.18'
                    y2='733.87'
                  />
                  {show === 2 && (
                    <g
                      fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                      stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                    >
                      <line x1='220.26' y1='148.56' x2='220.26' y2='749' />
                    </g>
                  )}
                </g>
                <g
                  fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                >
                  {(() => {
                    switch (show) {
                      case 2:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='M210.27,162.83a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,179.64a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,193.68a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,232.43a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,214.83a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,246.47a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,285.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,267.62a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,299.26a17.93,17.93,0,0,0-2.68,3.42l-.2-.43Q208,301,207.5,301a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,338a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,320.41a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,352.05a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,390.8a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,373.2a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,404.84a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,443.59a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,426a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,457.63a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,496.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,478.78a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,510.42a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.56,1.56,0,0,1,.71.54,6.77,6.77,0,0,1,.69,1.28l.17.4a8.38,8.38,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,549.17a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,531.58a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,563.21a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31A20.68,20.68,0,0,1,211.9,564a17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,602a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,584.37a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,616a17.68,17.68,0,0,0-2.68,3.43l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1A8.2,8.2,0,0,1,209,621l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,654.75a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,637.16a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,668.8a17.33,17.33,0,0,0-2.68,3.42l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,707.54a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,690a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,721.59a17.33,17.33,0,0,0-2.68,3.42l-.2-.43c-.38-.87-.73-1.3-1.05-1.3a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,742.39a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='M210.27,197.23a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,211.27a17.68,17.68,0,0,0-2.68,3.43l-.2-.44Q208,213,207.5,213a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,162.83a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,176.08a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,232.43a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,246.47a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,267.62a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,281.66a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.84,1.84,0,0,0-1.22.7,1.66,1.66,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M211.43,316.86a17.33,17.33,0,0,0-2.68,3.42l-.2-.43c-.38-.87-.73-1.3-1.05-1.3a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,338a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M210.27,302.81a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,352.05a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,373.2a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,387.24a17.68,17.68,0,0,0-2.68,3.43l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,408.4a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,422.44a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,443.59a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,457.63a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,478.78a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,492.83a17.33,17.33,0,0,0-2.68,3.42l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,514a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,528a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1A8.6,8.6,0,0,1,209,533l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,549.17a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,563.21a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,8.2,8.2,0,0,1,.77-.59l.46-.31A20.68,20.68,0,0,1,211.9,564a17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,584.37a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,598.41a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.13.4-.32.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,619.56a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,633.67a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,654.82a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,668.86a17.68,17.68,0,0,0-2.68,3.43l-.2-.44q-.57-1.29-1.05-1.29a1.82,1.82,0,0,0-1.22.71,1.51,1.51,0,0,1,.71.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,8.2,8.2,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.48,15.48,0,0,0-3.19,2.55' />
                                      <path d='M210.27,690a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,704.06a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.51,1.51,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95c.14-.13.4-.32.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                      <path d='M210.27,725.21a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                      <path d='M211.43,738.41a17.93,17.93,0,0,0-2.68,3.42l-.2-.43q-.57-1.29-1.05-1.29a1.8,1.8,0,0,0-1.22.7,1.58,1.58,0,0,1,.71.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1c.14-.12.4-.32.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.83,14.83,0,0,0-3.19,2.55' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )
                      case 1:
                        return (
                          <g>
                            <line
                              id='_Trazado_espejo_'
                              data-name='&amp;lt;Trazado espejo&amp;gt;'
                              x1='629.26'
                              y1='148.56'
                              x2='629.26'
                              y2='749'
                            />
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m639.25,162.83c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,179.64c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,193.68c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,232.43c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,214.83c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,246.47c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,285.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,267.62c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,299.26c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,338.01c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,320.41c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,352.05c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,390.8c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,373.2c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,404.84c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,443.59c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,425.99c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,457.63c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,496.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,478.78c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,510.42c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,549.17c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,531.58c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,563.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,601.96c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,584.37c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,616c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,654.75c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,637.16c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,668.8c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,707.54c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,689.95c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,721.59c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,742.39c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                      </g>
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo-2'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m639.25,197.23c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,211.27c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,162.83c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,176.08c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,232.43c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,246.47c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,267.62c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,281.66c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m638.1,316.86c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,338.01c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m639.25,302.81c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,352.05c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,373.2c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,387.24c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,408.4c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,422.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,443.59c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,457.63c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,478.78c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,492.83c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,513.98c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,528.02c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,549.17c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,563.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,584.37c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,598.41c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,619.56c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,633.67c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,654.82c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,668.86c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,690.02c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,704.06c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                        <path d='m639.25,725.21c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                        <path d='m638.1,738.41c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                      </g>
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
      {marginComplete ? (
        <g
          fill={`${ink !== 'black' && ink}`}
          stroke={`${ink !== 'black' && ink}`}
        >
          <title>Margenes Doble raya Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Doble_raya_Completo'
              data-name='Margenes Doble raya Completo'
            >
              <g
                id='Margenes_Doble_raya_Completo-2'
                data-name='Margenes Doble raya Completo'
              >
                {roundedMargin ? (
                  <g id='Hoja_esq._redondeada' data-name='Hoja esq. redondeada'>
                    <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V730.92A13.09,13.09,0,0,1,634.16,744H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V730.92A14.08,14.08,0,0,0,214,745H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                  </g>
                ) : (
                  <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                    <path d='M647.24,104.56V744H200.93V104.56H647.24m1-1H199.93V745H648.24V103.56Z' />
                  </g>
                )}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g
          fill={`${ink !== 'black' && ink}`}
          stroke={`${ink !== 'black' && ink}`}
        >
          <title>Margenes Doble raya Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Doble_raya_Datos'
              data-name='Margenes Doble raya Datos'
            >
              <g
                id='Margenes_Doble_raya_Datos-2'
                data-name='Margenes Doble raya Datos'
              >
                {roundedMargin ? (
                  <g id='Hoja_esq._redondeada' data-name='Hoja esq. redondeada'>
                    <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V734.92A13.09,13.09,0,0,1,634.16,748H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V734.92A14.08,14.08,0,0,0,214,749H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                  </g>
                ) : (
                  <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                    <path d='M647.24,149.56V748H200.93V149.56H647.24m1-1H199.93V749H648.24V148.56Z' />
                  </g>
                )}
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}

export default PathDobleRayaVertical
