import Tag from 'components/tag'
import Tags from 'components/tag'
import dayjs from 'dayjs'
import UtilListActions from 'modules/Utils/UtilListActions/UtilListActions'
import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import Button from 'components/buttons/Button/Button'

interface DataType {
  key: React.Key
  id: 49
  name: string
  sex: string
  phone: number
  social_reason: string
  address: string
  opening_date: string
  id_magento: number
  id_gea: number
  status: string
  quota: number
  price_notebook: number
  principal: string
  email: string
  bilingual: boolean
}

const statusMap: any = {
  Nuevo: 1,
  Renovado: 2,
  'En construcción': 3,
  Disponible: 4,
  Autorizado: 5
}



export const UtilsColumns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Marca',
    dataIndex: 'supply_make_name',
    key: 'supply_make_name'
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku'
  },
  {
    title: 'Presentación',
    dataIndex: 'presentation',
    key: 'presentation'
  },
  {
    title: 'Comentario',
    dataIndex: 'comment',
    key: 'comment',
    render: (comment: any) => {
      return comment !== '' ? comment : 'sin comentarios'
    }
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
    render: (status: any) => {
      return <Tags variation={status === 'active' ? 3 : 1} title={status} />
    }
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    render: (e: any) => <UtilListActions utilsData={e} />
  }
]

export const validEmail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const validPass: RegExp =
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?<!.*(.)\1)(?=.{8,})/g

export const ONLY_NUMBERS = (e: {
  key: string
  preventDefault: () => void
}) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault()
  }
}

export const EXTENSIONS = {
  accept:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',

  validate: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/vnd.ms-excel'
  ]
}

