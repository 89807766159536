import { Form, FormInstance } from 'antd'
import { ErrorToast } from 'components/Toasts/Toasts'
import { Editorial } from 'interfaces/books-interface'
import { useEffect, useState } from 'react'
import * as services from 'services/books/books'
import { getCategoriesForCreateItemsForms } from 'services/genericServices/genericServices'
import { Options } from 'types'

export const optionsLanguage: Options[] = [
  { label: 'Español', value: 'Español' },
  { label: 'Alemán', value: 'Alemán' },
  { label: 'Francés', value: 'Frances' },
  { label: 'Inglés', value: 'Inglés' },
  { label: 'Italiano', value: 'Italiano' },
  { label: 'Otro', value: 'Otro' }
]

export const optionsHeritable: Options[] = [
  { label: 'Si', value: true },
  { label: 'No', value: false }
]

export const optionsPurpose: Options[] = [
  { label: 'Consulta', value: 1 },
  { label: 'Trabajo', value: 2 }
]

export const optionsProviderFee: Options[] = [
  { label: 'Si', value: true },
  { label: 'No', value: false }
]


interface Functions {
  loading: boolean
  optionEditorial: Options[]
  optionProvider: Options[]
  modalEditorial: boolean
  categoryOptions:any[]
  form: FormInstance<any>
  onHandleAutoCompleteEditorials: (value: string) => Promise<any>
  onHandleAutoCompleteProviders: (value: string) => Promise<any>
  setModalEditorial: (value: boolean) => void
  handleNewEditorial: (
    value: Editorial,
    form: FormInstance<any>
  ) => Promise<any>
}

export const useEditorials = (): Functions => {
  const [loading, setLoading] = useState<boolean>(false)
  const [optionEditorial, setOptionEditorial] = useState<Options[]>([])
  const [optionProvider, setOptionProvider] = useState<Options[]>([])
  const [modalEditorial, setModalEditorial] = useState<boolean>(false)

  let timeOutId: ReturnType<typeof setTimeout>
  const [form] = Form.useForm()
const [categoryOptions, setcategoryOptions] = useState([]);
useEffect(() => {
  const getCategories = async() =>{
    try {
      const resul2 = await getCategoriesForCreateItemsForms('Libros')
      const allOptions2 = resul2.data.categories
      const cleanOptions2 = allOptions2.map((res: any) => {
        return { label: res, value: res }
      })
      setcategoryOptions(cleanOptions2)
    } catch (error) {
      //handleErrors(error)
    }
  }
  getCategories()

}, []);
  const onHandleAutoCompleteEditorials = async (
    value: string
  ): Promise<any> => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(async () => {
      try {
        const res = await services.getEditorials(value)
        setOptionEditorial(value ? res?.data?.editorials : [])
      } catch (error) {
        console.log(error)
      }
    }, 500)
  }

  const onHandleAutoCompleteProviders = async (
    value: string
  ): Promise<any> => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(async () => {
      try {
        const res = await services.getProviders(value)
        setOptionProvider(value ? res?.data?.providers : [])
      } catch (error) {
        console.log(error)
      }
    }, 500)
  }

  const handleNewEditorial = async (
    value: Editorial,
    formPrincipal: FormInstance<any>
  ): Promise<any> => {
    try {
      setLoading(true)
      await services.createEditorial(value)

      formPrincipal.setFieldValue('editorial', value?.name)
      form.resetFields()

      setModalEditorial(false)
    } catch (error) {
      ErrorToast('Ups!. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  return {
    categoryOptions,
    loading,
    optionEditorial,
    optionProvider,
    modalEditorial,
    form,
    onHandleAutoCompleteEditorials,
    onHandleAutoCompleteProviders,
    setModalEditorial,
    handleNewEditorial
  }
}
