import { Fragment, memo, useEffect, useState } from 'react'
import { INPUTS_INITIAL_STATE_PACKAGES } from 'redux/reducers/packages'
import { usePackages } from 'hooks/utils/usePackages'
import { Form, Modal, Row } from 'antd'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import { IGradesDinamic, ILevelsDinamic } from 'interfaces/schools-interface'
import { setFemale, setStep1 } from 'redux/reducers/uniformsSlice'
import { useDispatch, useSelector } from 'react-redux'
import style from './uniformlist.module.scss'
import componentsStyle from './components/thiscomponents.module.scss'
import Button from 'components/buttons/Button/Button'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'redux/store/store'
import { getLevels } from 'services/schoolUserServices/levels'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import GroupCheck from './components/GroupCheck'
import FormInput from 'components/formInputs/input'
import { UniformsList } from './components/UniformsList'

const UniformPackageList = memo(() => {
  const { downloadList, handleGetPackageListByLevel, handleDownloadUtilsList } =
    usePackages()

  Object.freeze(INPUTS_INITIAL_STATE_PACKAGES)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { female } = useSelector((state: RootState) => state.uniforms)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [levels, setLevels] = useState<ILevelsDinamic[]>([])
  const [physicalTestModal, setPhysicalTestModal] = useState(false)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [form] = Form.useForm()

  useEffect(() => {
    getLevels({})
      .then((dat) => {
        let data: ILevelsDinamic[] = []

        dat.data.levels.forEach((item) => {
          let grads: IGradesDinamic[] = []
          item.grades.forEach((content) => {
            grads.push({ ...content, checked: false })
          })
          data.push({ ...item, grades: grads })
        })
        setLevels(data)
      })
      .catch(() => console.log('error'))
  }, [])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    dispatch(setStep1())
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleSubmitPhysicalTest = async (values: any) => {
    console.log('values: ', values)
  }

  return (
    <MainTemplate>
      <button
        style={{ display: 'none' }}
        id='get-packages-again'
        onClick={() => handleGetPackageListByLevel()}
      >
        get packages
      </button>
      <Row align='middle' gutter={[0, 16]}>
        <TitleHeader
          IconName='uniform'
          title='Uniformes'
          buttonProps={[
            {
              isLoading: downloadList,
              label: 'Pedir muestras físicas',
              onClick: () => {
                form.resetFields()
                setPhysicalTestModal(true)
              },
              size: 'm',
              sector: 'tertiary',
              rounded: true
            },
            {
              isLoading: downloadList,
              label: 'Descargar',
              onClick: () => handleDownloadUtilsList(),
              background: 'white',
              rounded: true,
              size: 'm',
              sector: 'tertiary'
            },
            {
              rounded: true,
              sector: 'secondary',
              size: 'm',
              label: 'Crear nueva prenda',
              onClick: showModal
            }
          ]}
        />
        <CommentsComponent />
        <UniformsList />
      </Row>

      <Modal
        title='Crear nueva prenda'
        centered
        open={isModalOpen}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() => navigate('/uniforms/create-new')}
              rounded
              label='Crear'
            />
          </div>
        ]}
      >
        <p className={`${style['label-modal']}`}>
          Grado o Nivel al que pertenece
        </p>
        <div className={`${style['div-max-heigh']}`}>
          {levels.length !== 0 && (
            <Fragment>
              {levels.map((item, index: number) => {
                let grades: Array<any> = []

                if (uid) {
                  let { responsabilities } = uid
                  responsabilities = responsabilities?.map((it: any) => it?.id)
                  item?.grades?.forEach((it: any) => {
                    if (responsabilities?.includes(it?.id)) {
                      grades.push(it)
                    }
                  })
                }

                if (grades?.length == 0) return null

                return (
                  <Fragment key={index}>
                    <GroupCheck
                      level={item}
                      onHandleCheck={() => {}}
                      name={item.name}
                      grades={item.grades}
                    />
                    {levels.length !== index + 1 && (
                      <hr className={`${componentsStyle['hr-line']}`} />
                    )}
                  </Fragment>
                )
              })}
            </Fragment>
          )}
        </div>
        <p className={`${style['label-modal']} ${style['margen-top-gender']}`}>
          A qué género pertenecerá la prenda
        </p>

        <div className={`${style['buttons-gender']}`}>
          <button
            onClick={() => dispatch(setFemale(false))}
            className={`${
              style[`button-male-female-${female ? 'unselected' : 'selected'}`]
            }`}
          >
            Hombres
          </button>
          <button
            onClick={() => dispatch(setFemale(true))}
            className={`${
              style[`button-male-female-${!female ? 'unselected' : 'selected'}`]
            }`}
          >
            Mujeres
          </button>
        </div>
      </Modal>

      <Modal
        title='Agregar datos de contacto'
        centered
        open={physicalTestModal}
        width={800}
        onCancel={() => setPhysicalTestModal(false)}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() => form?.submit()}
              rounded
              label='Enviar a esta dirección'
            />
          </div>
        ]}
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmitPhysicalTest}
          autoComplete='off'
        >
          <FormInput
            name='address'
            placeholder='Dirección de envío'
            label='Dirección'
            rules={[{ required: true, message: '' }]}
            type='textarea'
          />
          <FormInput
            name='phone'
            placeholder='Teléfono'
            label='Teléfono'
            rules={[
              { required: true, message: '' },
              { pattern: /^\d+$/, message: 'Teléfono inválido' }
            ]}
            type='text'
          />
          <FormInput
            name='email'
            placeholder='Correo electrónico'
            label='Correo electrónico'
            rules={[{ required: true, message: '' }]}
            type='text'
          />
        </Form>
      </Modal>
    </MainTemplate>
  )
})
UniformPackageList.displayName = 'UniformPackageList'
export default UniformPackageList
