import React from 'react'

const LabelShortDeportivoFrontal = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path d="m549.16,312.45c-1.04.24-1.38.56-1.04,1.86.93,3.54,1.76,7.11,2.54,10.69.25,1.14.63,1.34,1.59,1.11,6.17-1.55,12.35-3.03,18.54-4.53l-3.21-13.57-.26.07c-6.04,1.46-12.1,2.95-18.15,4.37Z"/>
      <path d="m558.94,321.16c.22-.19.44-.4.68-.58.75-.54,1.13-1.37,1.52-2.19.23-.49.13-.88-.33-1.23-1.43-1.07-3.04-1.34-4.71-1.42-.06,0-.12-.01-.18-.04.65-.2,1.31.02,1.97.01.18,0,.46.15.54-.1.08-.23-.18-.37-.32-.53-.21-.26-.45-.48-.65-.77,1.12,1,2.38,1.6,3.78,1.91.61.13.85-.07.96-.67.18-.96.37-1.9-.11-2.87.89.99,1.94,1.88,1.77,3.48.32-.08.59-.14.86-.21.27-.07.42.14.54.33.2.31-.14.27-.23.39-.5.63-1.24,1.01-1.5,1.93-.16.61-.76.9-1.29,1.18-1.05.57-2.16,1.01-3.31,1.38Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelShortDeportivoFrontal
