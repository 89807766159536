import { ErrorToast } from 'components/Toasts/Toasts'
import { useEffect, useRef, useState } from 'react'
import {
  downloadSchoolList,
  getSchoolsAdmin
} from 'services/schoolAdminServices/schools'
import { SchoolAdminListInt } from 'types'
import {
  InitialPagination,
  Pagination,
  restructurePagination
} from 'utilities/restructurePagination'

import { addKeyToRows } from 'utilities/addKeyToTableRows'
import { handleErrors } from 'utilities/handleErrors'
import { useNavigate } from 'react-router-dom'
import { getLogs } from 'services/genericServices/genericServices'
import React from 'react'
import lodash from 'lodash'

const SCHOOL_STATUS = [
  {
    label: 'Todos',
    value: ''
  },
  {
    label: 'Nuevo',
    value: 'Nuevo'
  },
  {
    label: 'En Construcción',
    value: 'En Construcción'
  },
  {
    label: 'Autorizado',
    value: 'Autorizado'
  },
  {
    label: 'Solicitud de Modificación',
    value: 'Solicitud de Modificación'
  }
]

export function useSchoolList() {
  const navigate = useNavigate()

  const [schoolList, setSchoolList] = useState<SchoolAdminListInt[]>([])
  const [LoadingList, setLoadingList] = useState(true)
  const [pagination, setPagination] = useState<Pagination>(InitialPagination)
  const [searchSchool, setSearchSchool] = useState<string>('')
  const [status, setStatus] = useState('')

  const [downloadModal, setdownloadModal] = useState(false)
  const [loadingDowload, setloadingDowload] = useState(false)
  const navigateToCreate = () => {
    navigate('/crear-colegio')
  }

  const confirmDownload = async (): Promise<void> => {
    setloadingDowload(true)
    try {
      const result = await downloadSchoolList()
      window.open(result.data.url, '_blank')
      setloadingDowload(false)
    } catch (err: any) {
      setloadingDowload(false)
      handleErrors(err)
    }

    setdownloadModal(false)
  }

  const cancelDownload = () => {
    setdownloadModal(false)
  }

  const onHandleChangeStatus = (e: any) => {
    setPagination((prev) => {
      return {
        ...prev,
        current: 1
      }
    })
    if (e === 'todos') return setStatus('')
    return setStatus(e)
  }

  const donwloadLogs = async () => {
    setloadingDowload(true)
    try {
      const res: any = await getLogs('school')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')

    }
    setloadingDowload(false)
  }

  const debouncedSearch = React.useRef(
    lodash.debounce(async ({ value }: any) => {
      getSchoolList(1, value)
    }, 300)
  ).current

  const onHandleSearchSchool = (value: string) => {
    setSearchSchool(value ? value : '')
    setPagination((prev) => {
      return { ...prev, current: 1 }
    })
    debouncedSearch({
      value
    })
  }

  const changePage = (e: any) => {
    setLoadingList(true)
    getSchoolList(e.current, searchSchool)
  }

  const getSchoolList = async (
    page: number,
    search = '',
    controller?: AbortController
  ) => {
    try {
      const result = await getSchoolsAdmin({ page, controller, search, status })
      setSchoolList(result.data.data.schools)
      setPagination(restructurePagination(result.data.data))
      setLoadingList(false)
    } catch (err: any) {
      setLoadingList(false)
      handleErrors(err)
    }
  }

  useEffect(() => {
    let unmounted = false
    const controller = new AbortController()
    if (!unmounted)
      void getSchoolList(pagination.current, searchSchool, controller)
    return () => {
      controller.abort()
    }
  }, [status])

  return {
    schoolList,
    pagination,
    LoadingList,
    changePage,
    searchSchool,
    onHandleSearchSchool,
    navigateToCreate,
    setdownloadModal,
    downloadModal,
    confirmDownload,
    cancelDownload,
    loadingDowload,
    status,
    onHandleChangeStatus,
    SCHOOL_STATUS,
    donwloadLogs
  }
}
