import React from 'react'

const SueterFemaleSvg = ({
  className,
  bolsas = true
}: {
  className: string
  bolsas?: boolean
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Path'>
        <g id='Path-2' data-name='Path'>
          <path
            id='Contorno_sueter'
            data-name='Contorno sueter'
            d='m557.19,205.18s-6.33,63.59-1.49,83.43c6.88,28.21,13.28,55.76,13.28,55.76,0,0-9.18,79.06-4.11,112.49,10.2,67.26-4.51,223.39-4.51,223.39,0,0-92.52,5.94-161.58,6.01-89.64.08-157.15-7.59-157.15-7.59h0s-15.42-147.74-9.47-229.1c2.75-37.47-1.13-107.09-1.13-107.09,0,0,7.65-30.39,16.17-82.53,5.52-33.76-12.93-135.8-12.93-135.8,0,0,6.78-4.25,12.24-7.9,22-14.68,78.02-34.82,78.02-34.82,0,0,17.26,12.21,74.27,11.58,63.29-.7,76.25-11.58,76.25-11.58,0,0,52.42,16.45,87.62,44.86l-5.49,78.91Z'
          />
          <path
            id='Cuello'
            d='m325.42,81c7.31,3.26,18.78,7,27.24,7.74,15.9,1.39,31.05,2.6,47.07,2.58,23.17-.02,48.1-1.75,70.42-8.23,3.6-.61,4.94-2.39,3.99,0-2.67,6.66-4.79,19.08-7.33,25.77-.74,2.01-5,3.26-7.82,3.79-14.11,2.65-28.42,4.21-42.99,5.19-11.72.79-23.28.26-34.85-.23-14.21-.6-28.26-2.13-41.96-5-1.49-.32-5.39-.18-5.77-1.39-2.67-8.28-5.15-16.43-7.1-25.33l-.9-4.88Z'
          />
          <g id='Manga_izquierda' data-name='Manga izquierda'>
            <path
              id='Manga_izquierda-2'
              data-name='Manga izquierda'
              d='m234.28,123.39s-43.3,4.61-75.86,119.07c-12.52,44-24.48,74.24-31.15,102.77-18.52,79.3-24.58,126.23-24.58,126.23,0,0-.3,64.04-.79,146.99-.12,21.58,4.21,50.28,4.21,50.28l3.47,43.86s29.11,3.6,31.8,3.6c4.82,0,31.86-3.86,31.86-3.86l3.26-43.4s4.53-20.51,3.24-39.87c-1.84-27.81-.59-23.87.09-42.26,1.56-41.62,4.03-88.25,7.8-111.77,12.52-78.36,43.39-134.22,43.39-134.22,0,0,17.26-69.64,17.27-97.2.03-45.5-14.02-120.23-14.02-120.23Z'
            />
            <path
              id='manga_izquierda'
              data-name='manga izquierda'
              d='m103.93,651.42c3.29-.61,24.75-4.45,36.57-3.92,11.85.53,35.09,4.35,38.56,4.93'
            />
            <path
              id='Linea_1_puño_izquierda'
              data-name='Linea 1 puño izquierda'
              d='m107.32,688.78s23.69,3.44,36.52,2.87c12.83-.57,31.47-2.87,31.47-2.87'
            />
            <path
              id='Linea_2_puño_izquierda'
              data-name='Linea 2 puño izquierda'
              d='m106.63,673.15s24.17,3.51,37.26,2.93c13.09-.58,32.11-2.93,32.11-2.93'
            />
          </g>
          <path
            id='Contorno_botones_derecha'
            data-name='Contorno botones derecha'
            d='m434.07,284.99c-6.82,19.46-21.1,55.28-22.98,90.93-.53,6.16-.48,12.27-.48,18.41-.04,97.1.26,194.93.26,292.03-.21,1.09-22.68,1.3-22.68.32,0-104.12-.07-206.17,0-310.3.01-13.41,2.4-32.46,6.41-45.69,2.85-15.79,32.49-86.14,44.19-122.03,9.12-27.86,13.78-46.41,22.27-76.89.38-1.72,12.22-49.41,13.39-50.78l20.12,7.18-10.77,47.73c-5.66,24.31-34.9,115.83-49.73,149.09Z'
          />
          <g id='Manga_derecha' data-name='Manga derecha'>
            <path
              id='Manga_derecha-2'
              data-name='Manga derecha'
              d='m562.67,125.53s46.34,5.27,78.91,119.73c12.52,44,24.48,74.24,31.15,102.77,18.52,79.3,24.58,126.23,24.58,126.23,0,0,.3,64.04.79,146.99.12,21.58-4.21,50.28-4.21,50.28l-3.47,43.86s-29.11,3.6-31.8,3.6c-4.82,0-31.86-3.85-31.86-3.85l-3.27-43.4s-4.53-20.51-3.24-39.87c1.83-27.81.59-23.87-.09-42.26-1.56-41.62-4.03-88.25-7.8-111.77-12.52-78.36-43.39-134.22-43.39-134.22,0,0-12.54-36.5-14.58-64.86-2.71-37.56,8.29-153.23,8.29-153.23Z'
            />
            <path
              id='manga_derecha'
              data-name='manga derecha'
              d='m620.88,655.84s28.79-4.92,41.62-4.34c12.83.57,33,5,33,5'
            />
            <path
              id='Linea_1_puño_derecha'
              data-name='Linea 1 puño derecha'
              d='m624.79,688.95c5.01.69,24.21,3.19,35.3,2.7,12.83-.57,32.41-3.15,32.41-3.15'
            />
            <path
              id='Linea_2_puño_derecha'
              data-name='Linea 2 puño derecha'
              d='m623.5,673.5s24.64,3.12,37.47,2.55c12.83-.57,32.53-2.55,32.53-2.55'
            />
          </g>

          {bolsas && (
            <g>
              <path
                id='Bolsa_izquierda'
                data-name='Bolsa izquierda'
                d='m302.98,546.32c-11.94.26-23.88.46-35.82.83-2.72.08-3.84-.47-3.76-3.11.14-4.74-.02-9.48-.22-14.22-.08-2.07.4-3.06,3.04-3.1,24.38-.46,48.77-1.01,73.15-1.59,2.2-.05,3.19.45,3.17,2.59-.05,4.97.03,9.94.26,14.9.11,2.27-.83,2.89-3.24,2.93-12.19.17-24.38.48-36.58.76Z'
              />
              <path
                id='Bolsa_derecha'
                data-name='Bolsa derecha'
                d='m495.18,546.32c11.94.26,23.88.46,35.82.83,2.72.08,3.84-.47,3.76-3.11-.14-4.74.02-9.48.22-14.22.09-2.07-.4-3.06-3.04-3.1-24.39-.46-48.77-1.01-73.15-1.59-2.2-.05-3.19.45-3.17,2.59.05,4.97-.03,9.94-.26,14.9-.11,2.27.82,2.89,3.23,2.93,12.19.17,24.38.48,36.58.76Z'
              />
            </g>
          )}

          <path
            id='Contorno_botones_izquierda'
            data-name='Contorno botones izquierda'
            d='m315.19,135.9l-12.21-46.7,21.56-8.2c1.18,1.37,13.02,49.06,13.4,50.78,8.49,30.49,13.15,49.04,22.27,76.89,11.7,35.89,41.34,106.23,44.19,122.03,4.01,13.23,6.39,32.28,6.41,45.69.07,104.12,0,206.17,0,310.3,0,.99-22.47.77-22.68-.32,0-97.1.3-194.94.26-292.03,0-6.14.05-12.25-.48-18.41-1.88-35.65-16.16-71.48-22.98-90.93-14.83-33.27-44.08-124.78-49.73-149.09Z'
          />
          <g id='Botones'>
            <g>
              <polyline points='399.8 377.01 403.28 377.1 399.58 378.35 403.76 378.49 400.34 379.79 402.02 380.52 400.71 381 401.54 381.36 400.81 382.08 401.5 382.54 401.16 383.42' />
              <path d='m401.92,361.22c4.8.89,7.88,5.07,6.89,9.34-.99,4.28-5.67,7.03-10.47,6.16-4.8-.88-7.88-5.08-6.9-9.35.98-4.28,5.68-7.04,10.48-6.15Z' />
              <g>
                <path d='m395.04,366.83c-.02.14-.07.4-.23.66-.17.29-.4.49-.71.57-.28.07-.63.02-.97-.16-.65-.35-.9-1-.52-1.65.13-.22.29-.37.38-.43l.2.18c-.11.08-.23.18-.34.38-.28.47-.12.96.42,1.25.56.3,1.06.17,1.32-.28.1-.16.14-.29.14-.37l-.65-.35-.23.4-.23-.12.37-.64,1.05.56Z' />
                <path d='m393.73,364.9c.09-.12.24-.27.39-.41.24-.21.43-.29.63-.28.16,0,.32.09.45.23.21.24.22.53.09.75h0c.18-.06.37,0,.58.16.28.21.48.35.58.39l-.23.2c-.07-.03-.25-.15-.51-.34-.28-.21-.45-.22-.66-.06l-.21.18.71.8-.22.19-1.61-1.81Zm.97.64l.23-.2c.24-.21.26-.48.08-.69-.21-.24-.46-.2-.7,0-.11.09-.17.17-.2.21l.6.67Z' />
                <path d='m397.17,364.11l-.8.39.38.8.89-.43.11.24-1.15.56-1.06-2.22,1.1-.54.12.24-.84.41.33.7.79-.39.11.24Z' />
                <path d='m398.9,363.65l-.86.2.18.87.96-.22.05.26-1.24.29-.49-2.42,1.19-.28.05.26-.91.21.15.76.86-.2.05.26Z' />
                <path d='m399.45,364.72l.16-2.49h.32s.64,1.29.64,1.29c.15.3.26.56.35.82h0c0-.34,0-.64.03-1.03l.07-1.04h.27s-.16,2.5-.16,2.5h-.29s-.63-1.29-.63-1.29c-.14-.28-.27-.57-.37-.84h-.01c0,.32-.02.62-.04,1.03l-.07,1.06h-.27Z' />
                <path d='m403.24,363.03l-.63-.23.11-.26,1.53.57-.11.26-.63-.23-.87,2.08-.27-.1.87-2.08Z' />
                <path d='m403.91,364.93l-.65.53-.25-.16,2.08-1.69.29.19-.83,2.5-.25-.16.26-.79-.65-.42Zm.75.18l.24-.72c.05-.16.11-.31.17-.44h0c-.11.1-.23.21-.36.32l-.6.49.55.36Z' />
                <path d='m405.78,367.6c-.12-.06-.35-.2-.53-.43-.21-.26-.3-.53-.25-.84.05-.28.24-.58.55-.83.6-.48,1.32-.48,1.78.08.16.19.24.39.25.49l-.25.12c-.03-.13-.08-.27-.22-.44-.34-.41-.86-.44-1.36-.03-.51.41-.6.91-.27,1.3.12.14.22.22.29.25l.6-.48-.28-.34.21-.17.45.56-.96.77Z' />
              </g>
              <path d='m400.66,365.09c2.67.28,4.58,2.33,4.28,4.55-.3,2.24-2.69,3.82-5.35,3.53-2.67-.29-4.59-2.33-4.28-4.56.3-2.23,2.69-3.81,5.35-3.53Z' />
              <path d='m401.73,369.99c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.66,369.99c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.66,367.23c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m402.1,367.23c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
            </g>
            <g>
              <polyline points='399.25 449.25 402.73 449.34 399.03 450.59 403.21 450.74 399.8 452.03 401.47 452.76 400.17 453.24 400.99 453.6 400.26 454.33 400.95 454.79 400.61 455.66' />
              <path d='m401.37,433.46c4.8.89,7.88,5.07,6.89,9.34-.99,4.28-5.67,7.03-10.47,6.16-4.8-.88-7.88-5.08-6.9-9.35.98-4.28,5.68-7.04,10.48-6.15Z' />
              <g>
                <path d='m395.38,437.77c-.02.11-.06.33-.19.54-.14.24-.32.4-.58.47-.23.06-.52.01-.8-.14-.53-.29-.73-.83-.42-1.36.11-.18.24-.3.31-.35l.16.15c-.09.06-.19.15-.28.31-.23.39-.1.79.35,1.03.46.24.87.14,1.08-.23.08-.13.11-.24.11-.3l-.53-.29-.19.32-.19-.1.31-.52.86.46Z' />
                <path d='m394.31,436.19c.07-.1.2-.23.32-.33.19-.17.35-.24.52-.23.13,0,.26.07.36.19.18.2.18.43.08.62h0c.14-.05.3,0,.48.13.23.17.39.29.48.32l-.19.16c-.06-.02-.21-.12-.41-.28-.23-.17-.37-.18-.54-.05l-.17.15.58.65-.18.16-1.32-1.49Zm.79.53l.19-.16c.19-.17.22-.39.06-.56-.17-.19-.38-.16-.57,0-.09.08-.14.14-.16.17l.49.55Z' />
                <path d='m397.13,435.54l-.65.32.31.66.73-.36.09.2-.94.46-.87-1.82.91-.44.09.2-.69.34.27.58.65-.32.09.19Z' />
                <path d='m398.55,435.16l-.7.16.14.72.78-.18.04.22-1.02.24-.4-1.98.98-.23.04.21-.74.17.13.63.7-.16.04.21Z' />
                <path d='m399,436.04l.13-2.04h.26s.52,1.05.52,1.05c.12.24.21.46.29.67h0c0-.28,0-.52.03-.84l.06-.85h.22s-.13,2.05-.13,2.05h-.24s-.52-1.06-.52-1.06c-.11-.23-.22-.47-.3-.69h0c0,.26-.01.51-.04.85l-.06.87h-.22Z' />
                <path d='m402.11,434.65l-.52-.19.09-.21,1.26.47-.09.21-.52-.19-.72,1.71-.22-.08.72-1.71Z' />
                <path d='m402.66,436.21l-.53.44-.2-.13,1.7-1.38.23.15-.68,2.05-.21-.13.21-.64-.53-.35Zm.61.15l.2-.59c.04-.13.09-.25.14-.36h0c-.09.08-.19.17-.29.26l-.49.4.45.29Z' />
                <path d='m404.19,438.4c-.1-.05-.29-.17-.44-.35-.17-.21-.25-.43-.21-.69.04-.23.2-.48.45-.68.49-.39,1.08-.39,1.46.07.13.16.19.32.21.41l-.21.1c-.02-.11-.07-.22-.18-.36-.28-.34-.71-.36-1.12-.03-.42.33-.49.74-.23,1.07.1.12.18.18.24.2l.49-.39-.23-.28.17-.14.37.45-.79.63Z' />
              </g>
              <path d='m400.11,437.34c2.67.28,4.58,2.33,4.28,4.55-.3,2.24-2.69,3.82-5.35,3.53-2.67-.29-4.59-2.33-4.28-4.56.3-2.23,2.69-3.81,5.35-3.53Z' />
              <path d='m401.19,442.23c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.11,442.23c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.11,439.47c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m401.55,439.47c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
            </g>
            <g>
              <polyline points='399.24 525.43 402.73 525.51 399.03 526.77 403.21 526.91 399.79 528.2 401.46 528.93 400.16 529.41 400.98 529.78 400.26 530.5 400.94 530.96 400.6 531.83' />
              <path d='m401.37,509.63c4.8.89,7.88,5.07,6.89,9.34-.99,4.28-5.67,7.03-10.47,6.16-4.8-.88-7.88-5.08-6.9-9.35.98-4.28,5.68-7.04,10.48-6.15Z' />
              <g>
                <path d='m395.16,514.02c-.02.12-.06.34-.2.57-.15.25-.34.42-.62.49-.24.06-.55.01-.84-.14-.56-.31-.77-.87-.44-1.43.11-.19.25-.32.33-.37l.17.16c-.1.07-.2.16-.3.33-.24.41-.11.83.36,1.08.48.26.91.15,1.14-.24.08-.14.12-.25.12-.32l-.56-.3-.2.34-.2-.11.32-.55.91.49Z' />
                <path d='m394.03,512.35c.08-.11.21-.24.34-.35.21-.18.37-.25.54-.24.14,0,.28.08.38.2.19.21.19.46.08.65h0c.15-.05.32,0,.5.14.24.18.42.3.5.34l-.2.17c-.06-.02-.22-.13-.44-.29-.24-.18-.39-.19-.57-.05l-.18.16.61.69-.19.17-1.39-1.57Zm.83.56l.2-.17c.2-.18.23-.41.07-.59-.18-.2-.4-.17-.61,0-.09.08-.15.15-.17.18l.51.58Z' />
                <path d='m397,511.67l-.69.34.33.69.77-.37.1.21-.99.49-.92-1.92.95-.47.1.21-.73.36.29.61.69-.34.1.21Z' />
                <path d='m398.5,511.27l-.74.17.15.75.83-.19.05.23-1.07.25-.42-2.09,1.03-.24.05.23-.78.18.13.66.74-.17.04.22Z' />
                <path d='m398.97,512.19l.14-2.15h.27s.55,1.11.55,1.11c.13.26.22.49.3.71h0c0-.29,0-.55.03-.89l.06-.9h.24s-.14,2.16-.14,2.16h-.25s-.54-1.11-.54-1.11c-.12-.24-.23-.49-.32-.73h0c0,.27-.01.53-.04.89l-.06.92h-.24Z' />
                <path d='m402.24,510.73l-.54-.2.09-.22,1.32.49-.09.22-.55-.2-.75,1.8-.23-.09.75-1.8Z' />
                <path d='m402.83,512.37l-.56.46-.21-.14,1.79-1.46.25.16-.71,2.16-.22-.14.23-.68-.56-.37Zm.65.16l.21-.62c.05-.14.1-.26.15-.38h0c-.1.09-.2.18-.31.27l-.52.42.48.31Z' />
                <path d='m404.44,514.68c-.11-.05-.3-.17-.46-.37-.18-.22-.26-.46-.22-.73.04-.24.21-.5.48-.72.52-.41,1.14-.42,1.54.07.14.17.2.34.22.43l-.22.1c-.02-.11-.07-.23-.19-.38-.29-.35-.74-.38-1.18-.03-.44.35-.52.78-.24,1.12.1.12.19.19.25.21l.52-.41-.24-.3.18-.14.39.48-.83.67Z' />
              </g>
              <path d='m400.1,513.51c2.67.28,4.58,2.33,4.28,4.55-.3,2.24-2.69,3.82-5.35,3.53-2.67-.29-4.59-2.33-4.28-4.56.3-2.23,2.69-3.81,5.35-3.53Z' />
              <path d='m401.18,518.4c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.11,518.4c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.11,515.64c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m401.54,515.64c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
            </g>
            <g>
              <polyline points='399.27 601.09 402.75 601.17 399.05 602.42 403.23 602.57 399.81 603.86 401.49 604.59 400.18 605.07 401 605.43 400.28 606.16 400.97 606.62 400.63 607.49' />
              <path d='m401.39,585.29c4.8.89,7.88,5.07,6.89,9.34-.99,4.28-5.67,7.03-10.47,6.16-4.8-.88-7.88-5.08-6.9-9.35.98-4.28,5.68-7.04,10.48-6.15Z' />
              <g>
                <path d='m395.69,589.5c-.02.1-.06.3-.17.5-.13.22-.3.37-.54.44-.21.05-.48.01-.74-.13-.5-.27-.68-.77-.39-1.26.1-.17.22-.28.29-.33l.15.14c-.09.06-.18.14-.26.29-.21.36-.09.73.32.96.42.23.8.14,1.01-.21.07-.13.1-.22.1-.28l-.5-.27-.18.3-.17-.09.28-.49.8.43Z' />
                <path d='m394.69,588.03c.07-.09.18-.21.3-.31.18-.16.33-.22.48-.21.12,0,.24.07.34.18.16.18.16.4.07.57h0c.14-.04.28,0,.44.12.22.16.37.27.44.3l-.17.15c-.06-.02-.19-.12-.38-.26-.21-.16-.34-.17-.5-.04l-.16.14.54.61-.17.15-1.23-1.38Zm.74.49l.17-.15c.18-.16.2-.36.06-.52-.16-.18-.35-.15-.53,0-.08.07-.13.13-.15.16l.45.51Z' />
                <path d='m397.31,587.43l-.61.3.29.61.68-.33.09.18-.88.43-.81-1.69.84-.41.09.18-.64.31.26.54.61-.3.09.18Z' />
                <path d='m398.63,587.08l-.65.15.13.66.73-.17.04.2-.95.22-.37-1.84.91-.21.04.2-.69.16.12.58.65-.15.04.2Z' />
                <path d='m399.05,587.9l.12-1.9h.24s.49.98.49.98c.11.22.2.43.27.62h0c0-.26,0-.49.03-.78l.05-.79h.21s-.12,1.9-.12,1.9h-.22s-.48-.98-.48-.98c-.11-.21-.21-.43-.28-.64h0c0,.24-.01.47-.03.79l-.05.81h-.21Z' />
                <path d='m401.94,586.61l-.48-.18.08-.2,1.17.43-.08.2-.48-.18-.67,1.59-.21-.08.67-1.59Z' />
                <path d='m402.45,588.06l-.49.41-.19-.12,1.58-1.29.22.14-.63,1.91-.19-.13.2-.6-.5-.32Zm.57.14l.18-.55c.04-.12.09-.23.13-.34h0c-.09.08-.18.16-.27.24l-.46.37.42.27Z' />
                <path d='m403.88,590.09c-.09-.05-.26-.15-.41-.33-.16-.19-.23-.4-.19-.64.04-.21.18-.44.42-.64.45-.36,1-.37,1.36.06.12.15.18.3.19.38l-.19.09c-.02-.1-.06-.21-.17-.34-.26-.31-.65-.33-1.03-.02-.39.31-.45.69-.21.99.09.11.17.17.22.19l.45-.37-.21-.26.16-.13.35.42-.73.59Z' />
              </g>
              <path d='m400.12,589.17c2.67.28,4.58,2.33,4.28,4.55-.3,2.24-2.69,3.82-5.35,3.53-2.67-.29-4.59-2.33-4.28-4.56.3-2.23,2.69-3.81,5.35-3.53Z' />
              <path d='m401.2,594.06c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.13,594.06c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.13,591.3c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m401.56,591.3c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
            </g>
            <g>
              <polyline points='399.55 673.18 403.03 673.26 399.33 674.52 403.51 674.66 400.09 675.95 401.77 676.68 400.46 677.16 401.28 677.53 400.56 678.25 401.25 678.71 400.91 679.58' />
              <path d='m401.67,657.38c4.8.89,7.88,5.07,6.89,9.34-.99,4.28-5.67,7.03-10.47,6.16-4.8-.88-7.88-5.08-6.9-9.35.98-4.28,5.68-7.04,10.48-6.15Z' />
              <g>
                <path d='m395.81,661.65c-.02.11-.06.32-.18.53-.14.23-.31.39-.57.45-.22.05-.5.01-.77-.13-.52-.28-.71-.8-.41-1.32.1-.18.23-.3.3-.34l.16.15c-.09.06-.18.15-.27.3-.22.38-.1.76.34,1,.44.24.84.14,1.05-.22.08-.13.11-.23.11-.29l-.52-.28-.18.31-.18-.1.3-.51.84.45Z' />
                <path d='m394.77,660.12c.07-.1.19-.22.31-.32.19-.16.34-.23.5-.22.13,0,.25.07.35.18.17.19.17.42.07.6h0c.14-.05.3,0,.46.13.23.17.38.28.46.31l-.18.16c-.06-.02-.2-.12-.4-.27-.22-.17-.36-.18-.52-.05l-.17.14.56.63-.18.15-1.28-1.44Zm.77.51l.18-.16c.19-.16.21-.38.06-.54-.17-.19-.37-.16-.56,0-.08.07-.14.14-.16.17l.47.53Z' />
                <path d='m397.5,659.5l-.63.31.3.64.7-.35.09.19-.91.45-.84-1.77.88-.43.09.19-.67.33.27.56.63-.31.09.19Z' />
                <path d='m398.88,659.12l-.68.16.14.69.76-.18.04.21-.99.23-.39-1.92.95-.22.04.21-.72.17.12.61.68-.16.04.21Z' />
                <path d='m399.31,659.98l.13-1.98h.25s.51,1.02.51,1.02c.12.24.21.45.28.65h0c0-.27,0-.51.03-.82l.05-.83h.22s-.13,1.98-.13,1.98h-.23s-.5-1.02-.5-1.02c-.11-.22-.21-.45-.29-.67h0c0,.25-.01.49-.03.82l-.06.85h-.22Z' />
                <path d='m402.32,658.63l-.5-.19.09-.2,1.22.45-.09.2-.5-.19-.7,1.65-.21-.08.69-1.65Z' />
                <path d='m402.86,660.14l-.51.42-.19-.13,1.65-1.34.23.15-.66,1.99-.2-.13.21-.62-.52-.34Zm.59.14l.19-.57c.04-.13.09-.24.14-.35h0c-.09.08-.18.17-.28.25l-.48.39.44.29Z' />
                <path d='m404.34,662.27c-.1-.05-.28-.16-.42-.34-.17-.2-.24-.42-.2-.67.04-.22.19-.46.44-.66.47-.38,1.04-.38,1.41.07.13.16.19.31.2.39l-.2.09c-.02-.1-.06-.22-.18-.35-.27-.33-.68-.35-1.08-.02-.4.32-.47.72-.21,1.03.09.11.17.18.23.2l.47-.38-.22-.27.16-.13.36.44-.76.61Z' />
              </g>
              <path d='m400.4,661.26c2.67.28,4.58,2.33,4.28,4.55-.3,2.24-2.69,3.82-5.35,3.53-2.67-.29-4.59-2.33-4.28-4.56.3-2.23,2.69-3.81,5.35-3.53Z' />
              <path d='m401.48,666.15c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.41,666.15c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m398.41,663.39c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
              <path d='m401.84,663.39c.3.03.51.27.47.54-.03.26-.3.45-.6.42-.29-.04-.5-.27-.46-.54.03-.27.3-.46.6-.42Z' />
            </g>
          </g>
          <g id='Cintura'>
            <path
              id='cintura'
              d='m238.32,642.95s66.32,7.16,72.67,7.16,76.79,1.32,76.79,1.32'
            />
            <path
              id='cintura-2'
              data-name='cintura'
              d='m410.5,651.5s68.37-.25,82.63-1.39,70.1-5.1,70.1-5.1'
            />
            <path
              id='Linea_2_cintura'
              data-name='Linea 2 cintura'
              d='m239.19,655.8s30.92,3.84,74.96,7.06c20.73,1.52,74.35,1.64,74.35,1.64'
            />
            <path
              id='Linea_1_cintura'
              data-name='Linea 1 cintura'
              d='m561.98,665.37s-34.22,3.64-78.26,6.87c-20.73,1.52-72.88,1.09-72.88,1.09'
            />
            <path
              id='Linea_1_cintura-2'
              data-name='Linea 1 cintura'
              d='m240.33,665.47c5.96.71,34.72,4.06,73.81,6.93,20.73,1.52,74.35,1.11,74.35,1.11'
            />
            <path
              id='Linea_2_cintura-2'
              data-name='Linea 2 cintura'
              d='m561.98,657.22s-34.22,3.64-78.26,6.87c-20.73,1.52-72.88,1.09-72.88,1.09'
            />
          </g>
          <g id='Escudo'>
            <circle cx='294.54' cy='320.73' r='18.23' />
            <circle cx='513.54' cy='320.73' r='18.23' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SueterFemaleSvg
