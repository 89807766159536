import { createSlice } from "@reduxjs/toolkit";

export const utilsAdminPageSlice = createSlice({
    name: 'utilsAdminPage',
    initialState: {
        value: 'management'
    },
    reducers: {
        moveToManagement: (state) => {
            state.value = 'management'
        },
        moveToCatalog: (state) => {
            state.value = 'catalog'
        }
    }
});

export const { moveToCatalog, moveToManagement } = utilsAdminPageSlice.actions
export default utilsAdminPageSlice.reducer