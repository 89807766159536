import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'
import style from './modals.module.scss'
import Button from 'components/buttons/Button/Button'
import { createPackageNotebook } from 'services/notebooks/postNewNotebookPackage'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { edithPackageNotebook } from 'services/notebooks/putNotebookPackage'

const AddNewPackageModal = ({
  create,
  show,
  onOk,
  onCancel,
  form,
  setForm,
  data,
  idLevel,
  edithPackageId
}: {
  create: boolean
  show: boolean
  onCancel: () => void
  onOk: () => void
  form: { quanty: number; name: string }
  setForm: (dat: { quanty: number; name: string }) => void
  data: any | null
  idLevel: number
  edithPackageId: number
}) => {
  const [loading, setLoading] = useState(false)
  const [showMessage, setshowMessage] = useState(false)
  const createNewPackage = () => {
    setLoading(true)
    if (create)
      createPackageNotebook({
        name: form.name,
        description: 'nn',
        id_level: idLevel,
        id_grade: data.id,
        quantity: form.quanty
      })
        .then(() => {
          setLoading(false)
          SuccessToast(`El paquete ${form.name} fue creado correctamente.`)
          onOk()
        })
        .catch((e) => {
          setLoading(false)
          ErrorToast(
            `No se pudo crear el paquete ${form.name}, contactar con soporte.`
          )
        })
    else
      edithPackageNotebook({
        name: form.name,
        description: 'nn',
        id: edithPackageId,
        quantity: form.quanty
      })
        .then(() => {
          setLoading(false)
          SuccessToast(`El paquete ${form.name} fue editado correctamente.`)
          onOk()
        })
        .catch((e) => {
          setLoading(false)
          ErrorToast(
            `No se pudo editar el paquete ${form.name}, contactar con soporte.`
          )
          console.log(e)
        })
  }
  const onChangeQuanty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)

    if (value >= 0 || e.target.value === '') {
      if (e.target.value === '')
        setForm({
          ...form,
          quanty: 0
        })
      else {
        if (value > data?.female_population + data?.male_population) {
          setshowMessage(true)
        }
        setForm({
          ...form,
          quanty: value
        })
      }
    }
  }

  const disabledButton = () => {
    if (form.quanty > 0 && form.name !== '') return false
    else return true
  }

  return (
    <Modal
      title={
        create
          ? `Crear nuevo paquete de cuadernos para "${
              data !== null && data.name
            }" `
          : `Editar paquete existente "${data !== null && data.name}"`
      }
      centered
      open={show}
      onOk={onOk}
      width={800}
      onCancel={() => {
        onCancel()
      }}
      footer={[
        <div className={`${style['footer-style']}`}>
          <Button
            isLoading={loading}
            onClick={createNewPackage}
            rounded
            label={create ? 'Crear' : 'Editar'}
            disabled={disabledButton()}
          />
        </div>
      ]}
    >
      <div className={`${style['div-container']}`}>
        <p className={`${style['label-modal']}`}>Nombre del paquete</p>

        <input
          className={`${style['modal-input']}`}
          placeholder='Nombre del grado, grupo o materia'
          value={form.name}
          onChange={(e) =>
            setForm({
              ...form,
              name: e.target.value
            })
          }
        />
        <p className={`${style['label-modal']}`}>
          Cantidad de paquetes requerida
        </p>

        <input
          className={`${style['modal-input']} ${
            form.quanty === 0 && style['border-red']
          }`}
          placeholder='00000'
          type={'number'}
          value={form.quanty !== 0 ? form.quanty : ''}
          onChange={onChangeQuanty}
        />
        {form.quanty === 0 ? (
          <label className={`${style['label-error']}`}>
            La cantidad de paquetes no puede ser 0
          </label>
        ) : (
          <Fragment>
            {form.quanty !== 0 &&
            form.quanty < data?.female_population + data?.male_population ? (
              <label className={`${style['label-error']}`}>
                La cantidad de paquetes que esta ingresando es menor a la
                poblacion total del grupo (
                {data?.female_population + data?.male_population}).
              </label>
            ) : (
              <Fragment>
                {form.quanty !== 0 &&
                  form.quanty >
                    data?.female_population + data?.male_population && (
                    <label
                      className={`${style['label-error']}`}
                      style={{ color: 'yellowgreen' }}
                    >
                      La cantidad de paquetes que esta ingresando es mayor a la
                      poblacion total del grupo (
                      {data?.female_population + data?.male_population}) y si es
                      acepable.
                    </label>
                  )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Modal>
  )
}

export default AddNewPackageModal

/*
create
          ? `Crear nuevo paquete de cuadernos para "
          ${
            data !== null ? data.name + '" id:' + data.id : ''
          } id_level:${idLevel}`
          : `Editar paquete existente ${
              data !== null ? data.name + '" id:' + data.id : ''
            } id_level:${idLevel}`
*/
