import React from 'react'

const DatosFechaLogo = ({
  className,
  datos2,
  positionDates,
  dateStyle,
  viewBox = '0 0 800 800',
  showSquare,
  show,
  ink
}: {
  datos2: string
  className: string
  positionDates: string
  dateStyle: number
  viewBox?: string
  showSquare: boolean
  show: number
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={viewBox}
      className={className}
      fill={`${ink !== 'black' ? '#f5333f' : ink}`}
      stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
    >
      <g id='Capa_2' data-name='Capa 2'>
        <g id='Path_Cuadros_para_Datos' data-name='Path Cuadros para Datos'>
          <g id='Path_Cuadros_para_Datos-2' data-name='Path Cuadros para Datos'>
            {(() => {
              let thisPos = positionDates
              if (show === 1) {
                if (positionDates === 'left') thisPos = 'right'
                if (positionDates === 'right') thisPos = 'left'
              }
              switch (thisPos) {
                case 'right':
                  return (
                    <g>
                      <g
                        id='datos_nombre_logo_derecho'
                        data-name='datos nombre logo derecho'
                        fill='white'
                      >
                        {false && (
                          <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                            <path d='M628.11,134.9a20.06,20.06,0,1,1,20.06-20.05A20.08,20.08,0,0,1,628.11,134.9Z' />
                            <path d='M628.11,95a19.81,19.81,0,1,1-19.81,19.81A19.83,19.83,0,0,1,628.11,95m0-.5a20.31,20.31,0,1,0,20.31,20.31,20.31,20.31,0,0,0-20.31-20.31Z' />
                          </g>
                        )}

                        <g stroke={`${!showSquare && 'transparent'}`}>
                          <rect
                            x='200.02'
                            y='97.04'
                            width='397.32'
                            height='35.61'
                            rx='5.02'
                          />
                          <path d='M592.32,97.29a4.77,4.77,0,0,1,4.76,4.77v25.57a4.77,4.77,0,0,1-4.76,4.77H205a4.78,4.78,0,0,1-4.77-4.77V102.06A4.78,4.78,0,0,1,205,97.29H592.32m0-.5H205a5.27,5.27,0,0,0-5.27,5.27v25.57A5.27,5.27,0,0,0,205,132.9H592.32a5.26,5.26,0,0,0,5.26-5.27V102.06a5.26,5.26,0,0,0-5.26-5.27Z' />
                        </g>

                        <line x1='199.58' y1='114.85' x2='596.78' y2='114.85' />
                      </g>

                      <g
                        id='Datos_2'
                        data-name='Datos 2'
                        stroke='transparent'
                        fill='black'
                      >
                        {(() => {
                          switch (dateStyle) {
                            case 1:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Fecha:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 129.94)'
                                            >
                                              Título:
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 128.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 2:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Fecha / Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 128.94)'
                                            >
                                              Título /
                                              <tspan x='36.68' y='0'></tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha / Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título /
                                              </tspan>
                                              <tspan x='36.68' y='0'>
                                                {' '}
                                              </tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 3:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 128.94)'
                                            >
                                              <tspan>T</tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )

                            default:
                              return <></>
                          }
                        })()}
                      </g>
                    </g>
                  )
                case 'left':
                  return (
                    <g>
                      <g
                        id='datos_nombre_logo_izquierda'
                        data-name='datos nombre logo izquierda'
                        fill='white'
                      >
                        {false && (
                          <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                            <path d='M220.06,134.9a20.06,20.06,0,1,1,20.06-20.05A20.08,20.08,0,0,1,220.06,134.9Z' />
                            <path d='M220.06,95a19.81,19.81,0,1,1-19.81,19.81A19.82,19.82,0,0,1,220.06,95m0-.5a20.31,20.31,0,1,0,20.31,20.31,20.31,20.31,0,0,0-20.31-20.31Z' />
                          </g>
                        )}
                        <g stroke={`${!showSquare && 'transparent'}`}>
                          <rect
                            x='251.15'
                            y='97.04'
                            width='397.18'
                            height='35.61'
                            rx='5.02'
                          />
                          <path d='M643.32,97.29a4.77,4.77,0,0,1,4.76,4.77v25.57a4.77,4.77,0,0,1-4.76,4.77H256.17a4.78,4.78,0,0,1-4.77-4.77V102.06a4.78,4.78,0,0,1,4.77-4.77H643.32m0-.5H256.17a5.27,5.27,0,0,0-5.27,5.27v25.57a5.27,5.27,0,0,0,5.27,5.27H643.32a5.26,5.26,0,0,0,5.26-5.27V102.06a5.26,5.26,0,0,0-5.26-5.27Z' />
                        </g>

                        <line x1='647.72' y1='114.84' x2='251.76' y2='114.84' />
                      </g>

                      <g
                        id='Datos_1'
                        data-name='Datos 1'
                        fill='black'
                        stroke='transparent'
                      >
                        {(() => {
                          switch (dateStyle) {
                            case 1:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 110.53)'
                                            >
                                              Fecha:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 128.58)'
                                            >
                                              Título:
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 110.53) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 128.58) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 2:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 110.53)'
                                            >
                                              Fecha / Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 128.58)'
                                            >
                                              Título /
                                              <tspan x='36.79' y='0'>
                                                {' '}
                                              </tspan>
                                              <tspan x='39.91' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.82' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 110.53) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha / Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 128.58) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título /
                                              </tspan>
                                              <tspan x='36.79' y='0'>
                                                {' '}
                                              </tspan>
                                              <tspan x='39.91' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.82' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 3:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 110.53)'
                                            >
                                              Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(259.9 128.58)'
                                            >
                                              <tspan>T</tspan>
                                              <tspan x='6.9' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 110.53) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(632.9 128.58) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='6.9' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )

                            default:
                              return <></>
                          }
                        })()}
                      </g>
                    </g>
                  )
                case 'extremos':
                  return (
                    <g>
                      <g
                        id='datos_nombre_logo_derecho'
                        data-name='datos nombre logo derecho'
                        fill='white'
                      >
                        {false && (
                          <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                            <path d='M628.11,134.9a20.06,20.06,0,1,1,20.06-20.05A20.08,20.08,0,0,1,628.11,134.9Z' />
                            <path d='M628.11,95a19.81,19.81,0,1,1-19.81,19.81A19.83,19.83,0,0,1,628.11,95m0-.5a20.31,20.31,0,1,0,20.31,20.31,20.31,20.31,0,0,0-20.31-20.31Z' />
                          </g>
                        )}

                        <g stroke={`${!showSquare && 'transparent'}`}>
                          <rect
                            x='200.02'
                            y='97.04'
                            width='397.32'
                            height='35.61'
                            rx='5.02'
                          />
                          <path d='M592.32,97.29a4.77,4.77,0,0,1,4.76,4.77v25.57a4.77,4.77,0,0,1-4.76,4.77H205a4.78,4.78,0,0,1-4.77-4.77V102.06A4.78,4.78,0,0,1,205,97.29H592.32m0-.5H205a5.27,5.27,0,0,0-5.27,5.27v25.57A5.27,5.27,0,0,0,205,132.9H592.32a5.26,5.26,0,0,0,5.26-5.27V102.06a5.26,5.26,0,0,0-5.26-5.27Z' />
                        </g>

                        <line x1='199.58' y1='114.85' x2='596.78' y2='114.85' />
                      </g>

                      <g
                        id='Datos_2'
                        data-name='Datos 2'
                        stroke='transparent'
                        fill='black'
                      >
                        {(() => {
                          switch (dateStyle) {
                            case 1:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Fecha:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 129.94)'
                                            >
                                              Título:
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 128.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 2:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Fecha / Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 128.94)'
                                            >
                                              Título /
                                              <tspan x='36.68' y='0'></tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha / Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título /
                                              </tspan>
                                              <tspan x='36.68' y='0'>
                                                {' '}
                                              </tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 3:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              Date:
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 128.94)'
                                            >
                                              <tspan>T</tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(589.79 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )

                            default:
                              return <></>
                          }
                        })()}
                      </g>
                    </g>
                  )
                case 'none':
                  return (
                    <g>
                      <g
                        id='cuadro_datos_sin_logo'
                        data-name='cuadro datos sin logo'
                        fill='white'
                      >
                        <g>
                          <g stroke={`${!showSquare && 'transparent'}`}>
                            <rect
                              x='200.05'
                              y='97.06'
                              width='447.92'
                              height='35.57'
                              rx='5.33'
                              ry='5.33'
                            />
                            <path d='m642.65,97.32c2.79,0,5.06,2.27,5.06,5.06v24.92c0,2.79-2.27,5.06-5.06,5.06H205.38c-2.79,0-5.06-2.27-5.06-5.06v-24.92c0-2.79,2.27-5.06,5.06-5.06h437.27m0-.53H205.38c-3.09,0-5.59,2.5-5.59,5.59v24.92c0,3.09,2.5,5.59,5.59,5.59h437.27c3.09,0,5.59-2.5,5.59-5.59v-24.92c0-3.09-2.5-5.59-5.59-5.59h0Z' />
                          </g>

                          <line
                            x1='199.58'
                            y1='114.85'
                            x2='647.34'
                            y2='114.85'
                          />
                        </g>
                      </g>
                      <g
                        id='Datos_cuadro_sin_logo'
                        data-name='Datos cuadro sin logo'
                        fill='black'
                        stroke='transparent'
                      >
                        {(() => {
                          switch (dateStyle) {
                            case 1:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 109.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 127.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 109.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 2:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 111.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha / Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 128.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título /
                                              </tspan>
                                              <tspan x='36.68' y='0'></tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 111.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Fecha / Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 128.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Título /
                                              </tspan>
                                              <tspan x='36.68' y='0'>
                                                {' '}
                                              </tspan>
                                              <tspan x='39.8' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='46.68' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )
                            case 3:
                              return (
                                <g>
                                  {(() => {
                                    switch (show) {
                                      case 2:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 110.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(210.72 127.94)'
                                            >
                                              <tspan x='0' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )
                                      case 1:
                                        return (
                                          <g>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 110.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                Date:
                                              </tspan>
                                            </text>
                                            <text
                                              className={datos2}
                                              transform='translate(634.54 127.94) rotate(-180) scale(1 -1)'
                                            >
                                              <tspan x='0' y='0'>
                                                T
                                              </tspan>
                                              <tspan x='6.88' y='0'>
                                                itle:
                                              </tspan>
                                            </text>
                                          </g>
                                        )

                                      default:
                                        return <></>
                                    }
                                  })()}
                                </g>
                              )

                            default:
                              return <></>
                          }
                        })()}
                      </g>
                    </g>
                  )
                default:
                  return <></>
              }
            })()}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DatosFechaLogo
