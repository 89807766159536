import { AUTHSERVICE, SERVICE } from '../config'

export interface levelInterface {
  name: string
}

export const createGrade = async (data: levelInterface): Promise<any> => {
  return await AUTHSERVICE().post('/grade/', data)
}

export const updateGrade = async (data: levelInterface): Promise<any> => {
  return await AUTHSERVICE().put(`/grade/`, data)
}

export const deleteGrade = async (id: string | number): Promise<any> => {
  return await AUTHSERVICE().delete(`/grade/${id}`)
}
