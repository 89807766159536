import React, { Fragment, useState } from 'react'
import GradeCheck from './GradeCheck'
import style from './thiscomponents.module.scss'
import {
  IGrades,
  IResponsabilities
} from 'interfaces/user-management-interface'

const NewGroupCheck = ({
  name,
  grades,
  onHandleCheck,
  idSchool
}: {
  name: string
  grades: IGrades[]
  onHandleCheck: (
    idSchool: number,
    levelName: string,
    idGrade: number,
    all: null | boolean
  ) => void
  idSchool: number
}) => {
  const [selectAll, setSelectAll] = useState(false)

  const changeAllGrades = (state: boolean) => {
    setSelectAll(state)
    onHandleCheck(idSchool, name, 0, state)
  }

  return (
    <Fragment>
      <div className={`${style['div-select-all']}`}>
        <p className={`${style['p-level-title']}`}>{name}</p>
        <label className={`${style['center-label']}`}>
          <input
            type={'checkbox'}
            checked={selectAll}
            onChange={(_) => changeAllGrades(!selectAll)}
          />
          Seleccionar todo
        </label>
      </div>
      <div className={`${style['div-wrap-label']}`}>
        {grades.map((item, indexKey: number) => {
          return (
            <Fragment key={indexKey}>
              <GradeCheck
                onHandleCheck={() =>
                  onHandleCheck(idSchool, name, item.id, null)
                }
                check={item.check as boolean}
                name={item.name}
                allSelect={selectAll}
              />
            </Fragment>
          )
        })}
      </div>
    </Fragment>
  )
}

export default NewGroupCheck
