import React from 'react'

const LabelFalda = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
    <g id="Etiqueta_lateral-2" data-name="Etiqueta lateral">
      <path d="m234.84,195.42l-.39-.04-2.42,17.58c9.07,1.02,18.14,2.02,27.21,3.09,1.41.17,1.92-.14,2.1-1.62.54-4.62,1.15-9.25,1.92-13.85.28-1.68-.28-2.03-1.78-2.2-8.88-.94-17.75-1.96-26.63-2.97Z"/>
      <path d="m245.69,210.66c-.35-.21-.7-.45-1.07-.64-1.15-.57-1.83-1.56-2.51-2.55-.41-.59-.33-1.1.28-1.6,1.88-1.55,4.14-2.13,6.5-2.47.09-.01.17-.03.25-.07-.97-.15-1.88.2-2.81.29-.27.03-.64.25-.8-.05-.15-.27.19-.49.36-.71.27-.35.57-.68.8-1.07-1.43,1.43-3.13,2.36-5.09,2.94-.85.26-1.22.03-1.48-.7-.42-1.19-.84-2.35-.29-3.64-1.12,1.38-2.47,2.63-1.98,4.65-.47-.06-.86-.09-1.27-.15-.4-.06-.57.23-.72.49-.25.41.24.32.39.45.81.74,1.93,1.11,2.44,2.22.34.75,1.24,1.03,2.04,1.31,1.6.57,3.25.97,4.95,1.28Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelFalda
