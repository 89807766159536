import React from 'react'

const LabelLateralPants= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
stroke='black'
viewBox='0 0 800 800'
className={className}
>
<g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path   d="m400.94,57.77c-.81.16-1.1.42-.84,1.57.71,3.14,1.34,6.31,1.94,9.48.19,1.01.49,1.21,1.25,1.04,4.89-1.04,9.79-2.02,14.68-3.02l-2.45-12.03-.21.04c-4.79.98-9.58,1.98-14.38,2.91Z"/>
      <path   d="m408.61,65.88c.18-.16.36-.33.55-.48.59-.43.9-1.13,1.21-1.83.19-.43.11-.77-.25-1.1-1.13-1.01-2.4-1.33-3.71-1.48-.05,0-.1-.02-.14-.05.52-.13,1.04.08,1.55.11.15,0,.37.15.43-.06.06-.19-.14-.33-.24-.48-.17-.23-.36-.45-.51-.71.88.93,1.87,1.52,2.97,1.86.48.15.67-.02.76-.54.15-.82.31-1.64-.07-2.51.7.91,1.52,1.73,1.38,3.14.25-.05.46-.09.68-.14.22-.05.33.14.43.32.16.28-.11.23-.19.32-.4.53-.99.83-1.19,1.61-.14.53-.61.75-1.03.97-.83.44-1.72.77-2.63,1.04Z"/>
    </g>
  </g>
</svg>
)
}

export default LabelLateralPants