import { AUTHSERVICE } from '../config'

interface IResults {
  status: number
  statusText: string
  data: {
    notebookPackage: INotebook
    success: boolean
  }
}

export interface INotebook {
  back_cover_img: string | null
  college_proposal: boolean | null
  createdAt: string | null
  ext_binding: string | null
  ext_color: string | null
  ext_cover_desing: string | null
  ext_coverfinish: string | null
  ext_logo: string | null
  ext_rountips: boolean | null
  ext_spinecolor: string | null
  ezetera_proposal: false
  front_cover_img: null
  id: number | null
  id_format: string | null
  id_package: number | null
  int_datarange: boolean | null
  int_datetype: string | null
  int_fullmargin: boolean | null
  int_guidepoint: string | null
  int_interiorinks: string | null
  int_logo: string | null
  int_positionlogos: string | null
  int_roundtips: boolean | null
  int_sheets: string | null
  int_striped: string | null
  is_deleted: boolean | null
  matter: string | null
  price: string | null
  provider: string | null
  provider_fee: boolean | null
  quantity: string | null
  refill_time: string | null
  request_changes: boolean | null
  resource_free: boolean | null
  return_percentage: string | null
  special_price: string | null
  status: string | null
  status_name: string | null
  step: number | null
  updatedAt: string | null
  uuid: string | null
  require_cover_desing: boolean | null
  require_cover_img: boolean | null
  require_cover_text: boolean | null

  jsonimgback: null | string
  jsonimgfront: null | string
  jsontextback: null | string
  jsontextfront: null | string
  uploadposition: null | string

  category: null | string
  inventariable: null | boolean
  tax: null | string
  price_list: null | string
}

const getNotebook = async (id: number): Promise<any> => {
  return await AUTHSERVICE().get(`/notebook/id/${id}`)
}

const getNotebookByIdUuid = async (id: number) => {
  const results = await getNotebook(id)
  return results as IResults
}

export { getNotebookByIdUuid }
