import {
  TFormPoloFemaleOptions,
  TFormPoloMaleOptions,
  TFormSportShirtOptions
} from 'interfaces/uniforms-interface'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSportShirtMaleForm, handleSportShirtMFemaleForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import styled from './clotheoptions.module.scss'
import ConstructorFormulary from './ConstructorFormulary'

const SportShirtOptionEditable = () => {
  const { female, sportShirtMale, sportShirtFemale } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormPoloMaleOptions | TFormPoloFemaleOptions,
    value: string | boolean
  ) => {
    if (female)
    dispatch(
      handleSportShirtMFemaleForm({
        ...sportShirtFemale,
        [keyName]: {
          ...sportShirtFemale[keyName as TFormSportShirtOptions],
          value: value
        }
      })
    )
    else
    dispatch(
      handleSportShirtMaleForm({
        ...sportShirtMale,
        [keyName]: {
          ...sportShirtMale[keyName as TFormSportShirtOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <Fragment>
            {female ? (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sportShirtFemale}
              />
            ) : (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sportShirtMale}
              />
            )}
          </Fragment>
        )
      })()}
    </div>
  )
}
export default SportShirtOptionEditable
