import { Col, Collapse, Table, Tabs, Tag, Tooltip } from 'antd'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { memo, useState } from 'react'
import styles from './tabsAdmin.module.scss'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import B from 'components/texts/B/B'
import { RootState } from 'redux/store/store'
import * as services from 'services/quotas/quotas'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import Modals from 'components/modal'
import globalStyle from 'styles/global.module.scss'
import ErrorAlert from 'assets/img/errorAlert.svg'
import Button from 'components/buttons/Button/Button'
import { setQuotasSchoolAdmin, updateRequestChanges } from 'redux/reducers/quotas'

interface ITabsAdmin {
  selectStatus: string | number
  searchGralQuotas: string
  quotaColumns: ColumnsType<any>
  idSchool: any
  idLevel: any
  idGrade: any
  setIdLevel: any
  setIdGrade: any
}

const TabsAdminModule = memo(
  ({
    selectStatus,
    searchGralQuotas,
    idSchool,
    idLevel,
    quotaColumns,
    setIdLevel,
    setIdGrade
  }: ITabsAdmin) => {
    const { Panel } = Collapse

    const { quotasSchoolAdmin, levelsQuotasAdmin } = useSelector(
      (state: RootState) => state.quotas
    )

    const columns = quotasSchoolAdmin?.[0] && Object.keys(quotasSchoolAdmin?.[0])

    const [loading, setLoading] = useState<boolean>(false)
    const [modalChanges,setModalChanges] = useState<boolean>(false)
    const [IDGrade,setIDgrade] = useState<number>(0)


    const dispatch = useDispatch()

    const handleChangeLevelTab = (key: string) => {
      setIdLevel(key)
    }

 

    const handleCollapseBooks = async (idGrade: number): Promise<void> => {
      setLoading(true)

      try {
        const response = await services.getQuotas(
          idSchool,
          idLevel,
          idGrade
        )

        const saveResponse = response?.data?.quotas.map((quota: any) => {
          return {
            ...quota,
            idGrade
          }
        })

        dispatch(setQuotasSchoolAdmin(saveResponse))
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
    }

    const handleOpenModalChanges = (idGrade:number) =>{
      setModalChanges(true)
      setIDgrade(idGrade)
    }
    const handleCancelModalChanges = () =>{

      setModalChanges(false)
    }

    const negateRequestChanges = async():Promise<void>=>{

      setLoading(true)
      try {
        await services.requestChangesQuotas({
          id_grade: IDGrade,
          request_changes: false,
          mode:'denied'
        })
       
        dispatch(updateRequestChanges({ idGrade: IDGrade, status:false }))
        SuccessToast('No fue aceptada la petición de cambio.')
        setModalChanges(false)
        
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
      
    }
    const aceptRequestChanges = async():Promise<void>=>{
      setLoading(true)
      try {
        await services.updateOpenListQuota({ id_grade: IDGrade, close: false,mode:'send-request' })
        await services.requestChangesQuotas({
          id_grade: IDGrade,
          request_changes: false,
          mode:'accepted'
        })
     
        dispatch(updateRequestChanges({ idGrade: IDGrade, status:false }))
    
        SuccessToast('Fue aceptada la petición de cambio.')
        setModalChanges(false)
        
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
      

    }

    const onChange = (key: string | string[]) => {
      key.length > 0 && handleCollapseBooks(+key.slice(-1))
      key.length > 0 && setIdGrade(+key.slice(-1))
    }
    return (
      <>
        <Col span={24}>
          <Tabs
            items={levelsQuotasAdmin}
            defaultActiveKey={idLevel}
            activeKey={idLevel}
            onChange={handleChangeLevelTab}
          />
        </Col>

        <Col span={24}>
          {levelsQuotasAdmin
            ?.find((level: any) => level.key === idLevel)
            ?.grades?.map((res: any) => {
              return (
                <Collapse key={res.id} onChange={onChange} bordered={false}>
                  <Panel
                    className={styles.collapsePanel}
                    header={
                      <>
                        <B>{res?.name}</B>
                      </>
                    }
                    key={res.id}
                    extra={
                     res.listQuotasChange &&(
                      <>
                        <Tooltip title='Cambios solicitados'>
                          <Tag
                            onClick={(e: any) => {
                              e.stopPropagation()
                              handleOpenModalChanges(res.id)
                            }}
                            className={styles.tag}
                          >
                            <small className={styles.status} data-variation={8}>
                              Cambios solicitados
                            </small>
                          </Tag>
                        </Tooltip>
                      </>
                     )
                    }
                  >
                    {quotasSchoolAdmin
                      .filter(
                        (item: { idGrade: any; status: any }) =>
                          item.idGrade === res.id && item.status !== 1
                      )
                      ?.filter((item: any) =>
                        item.status.toString().includes(selectStatus)
                      )
                      ?.filter((item: any) =>
                        columns?.some(
                          (column: any) =>
                            item[column]
                              ?.toString()
                              ?.toLowerCase()
                              ?.indexOf(searchGralQuotas.toLowerCase()) > -1
                        )
                      )?.length > 0 ? (
                      <>
                        <Table
                          dataSource={quotasSchoolAdmin
                            .filter(
                              (item: { idGrade: any; status: any }) =>
                                item.idGrade === res.id && item.status !== 1
                            )
                            ?.filter((item: any) =>
                              item.status.toString().includes(selectStatus)
                            )
                            ?.filter((item: any) =>
                              columns?.some(
                                (column: any) =>
                                  item[column]
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.indexOf(searchGralQuotas.toLowerCase()) > -1
                              )
                            )}
                          loading={loading}
                          columns={quotaColumns}
                          scroll={{ x: 800 }}
                          rowKey={(row) => row.id}
                         
                         
                        />
                      </>
                    ) : (
                      <EmptyListMessage
                        iconHeight={40}
                        iconWidth={40}
                        icon='emptySchoolListIcon'
                        text='Sin cuotas'
                      />
                    )}
                  </Panel>
                </Collapse>
              )
            })}
        </Col>

        <Modals
            title='Solicitud de cambio'
            open={modalChanges}
            onCancel={handleCancelModalChanges}
          >
            <div className={globalStyle.confirmModal}>
                <div className={globalStyle.confirmModalBody}>
          <img src={ErrorAlert} alt='lista alerta' />
          <B size='Light'>
            ¿Estás seguro que deseas permitir la modificación de la lista?,
            Al hacer esto, el estatus de la lista pasará a "En Construcción" y el
            usuario podra hacer cambios sobre la misma.
          </B>
        </div>
        <br></br>

        <div className={globalStyle.confirmModalFooter}>
          <Button
            rounded
            onClick={negateRequestChanges}
            label={'No'}
            sector='secondary'
            isLoading={loading}
          />
          <Button isLoading={loading} rounded onClick={aceptRequestChanges} label={'Si'} />
        </div>
        </div>
          </Modals>
      </>
    )
  }
)

export default TabsAdminModule
