import React, { Fragment, memo } from 'react'
import { Col, List, Tabs } from 'antd'
import Collapse from 'components/Collapse/Collapse'
import style from './list.module.scss'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import B from 'components/texts/B/B'
import ItemsTable from './ItemsTable'
import { ILevelGradesWithPackages } from 'interfaces/utils-interface'
import LevelActionsPackage from 'modules/Utils/LevelActionsPackage/LevelActionsPackage'
import { usePackageItemList } from 'hooks/utils/usePackageItemList'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
const packageTitle = ['Nombre del paquete', '', '']
const colStyle = { cursor: 'pointer', padding: '0 20px' }

interface ListOfListI {
  selectedTab: string | number
  tabs: any
  selectedLevel: any
  loadingModal?: any
  handleChangeLevelTab: (key: any) => void
  postNewPackage?: any
  deletePackageById?: (id: any, handleCancel: () => void) => void
  updatePackageById?: any
  requestChanges?:any
}

export const ListOfLists = memo(({
  tabs,
  selectedLevel,
  loadingModal,
  postNewPackage,
  selectedTab,
  requestChanges,
  updatePackageById,
  deletePackageById,
  handleChangeLevelTab,
}: ListOfListI) => {
  const { itemsList, getItemsList, LoadingList, removeItemFromPackage } = usePackageItemList('utils')
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const RenderPackageList = ({
    name,
    LevelGrades
  }: {
    LevelGrades: ILevelGradesWithPackages[]
    name?: string
  }) => {
    return (
      <Fragment>
        <List className={`${style['padding-inline']}`} itemLayout='horizontal'>
          {(() => {
            let value: ILevelGradesWithPackages[] = LevelGrades
            value = value.filter((contents) => contents.name === name)
            return (
              <Fragment>
                {value[0].packages.length !== 0 ? (
                  <Fragment>
                    <List.Item
                      style={{ padding: ' 10px 0', background: '#F3F7FF80' }}
                    >
                      {packageTitle.map((item, index) => {
                        return (
                          <Col
                            key={index}
                            className='ListTitles'
                            style={colStyle}
                            span={8}
                          >
                            <B size='Light'>{item}</B>
                          </Col>
                        )
                      })}
                    </List.Item>
                    {value[0].packages.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <ItemsTable
                            packageData={item}
                            loadingModal={loadingModal}
                            deletePackageById={deletePackageById}
                            updatePackageById={updatePackageById}
                            removeItemFromPackage={removeItemFromPackage}
                            LoadingList={LoadingList}
                            itemsList={itemsList}
                            getItemsList={getItemsList}
                            requestChanges={requestChanges}
                          />
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ) : (
                  <EmptyListMessage
                    iconHeight={40}
                    iconWidth={40}
                    icon='utilsIcon'
                    text='Aún no has creado paquetes'
                    buttonProps={uid.role==='Director'?[]:[
                      {
                        label: 'Crear primer paquete',
                        onClick: () => {
                          const button = document.getElementById(`add-package-grade-${value[0].id}`)
                          button?.click()
                          // `add-items-list-modal-${packageData.id}`
                        },
                        rounded: true,
                        sector: 'tertiary',
                        background: 'white',
                        size: 'm'
                      }
                    ]}
                  />
                )}
              </Fragment>
            )
          })()}
        </List>
      </Fragment>
    )
  }

 return (
    <Fragment>
      <Col span={24}>
        <Tabs defaultActiveKey={selectedTab as string} activeKey={selectedTab as string} items={tabs}  onChange={handleChangeLevelTab} />
      </Col>
      <Col span={24}>
        {selectedLevel?.grades?.map((res: any) => {
          return (
            <div key={res.id} className={`${style['']}`}>
              <Collapse
                id={res.id}
                title={res.name}
                customSideElement={
                  <LevelActionsPackage
                    data={res}
                    loadingModal={loadingModal}
                    postNewPackage={postNewPackage}
                  />
                }
              >
                <RenderPackageList
                  LevelGrades={selectedLevel.grades}
                  name={res.name}
                />
              </Collapse>
            </div>
          )
        })}
      </Col>
    </Fragment>
 )
});



