import React from 'react'
import { TFormHoodieOptions } from 'interfaces/uniforms-interface'
import { useDispatch, useSelector } from 'react-redux'
import { handleHoodieForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const HoodieOptionsEditable = () => {
  const { hoodieForm, selectedMenu } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormHoodieOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleHoodieForm({
        ...hoodieForm,
        [keyName]: {
          ...hoodieForm[keyName as TFormHoodieOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        switch (selectedMenu) {
          case 'hoodie':
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={hoodieForm}
              />
            )

          default:
            break
        }
      })()}
    </div>
  )
}

export default HoodieOptionsEditable
