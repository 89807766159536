import React from 'react'

const FaldaSvg = ({
  className,
  bolsas = true
}: {
  className: string
  bolsas?: boolean
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Path' stroke='black'>
        <g id='Path-2' data-name='Path'>
          {
            <g id='Pinzas'>
              <path d='m595.47,620.72c.16,2.15-18.37,4.3-41.2,6.31-18.75,1.64-40.4,3.18-56.97,4.53-12.5,1.01,14.04,2.6,4.56,3.22-.48.03-15.56,2.03-24.71,2.52-6.64.36,13.36-3.5,3.93-3.02-12.89.66-32.81.38-54.51-.24-37.26-1.06-78.82-3.52-100.06-3.54-4.59,0,10.88-.25,6.83,1.15-9.21,3.17-19.96.86-27.49-.34-11.94-1.92,16.07-.2,4.6-.72-40.81-1.85-83.06-2.13-105.91-9.86,29.22-330.34,30.17-346.28,55.02-437.04,0,0,130.86,14.72,281.87,0,0,0,30.75,128.77,54.05,437.04Z' />
              <image
                width='88'
                height='414'
                transform='translate(276 254)'
                href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAGeCAYAAAAQU8YpAAAACXBIWXMAAAsSAAALEgHS3X78AAADgElEQVR4nO3cMY4cMQwAQa7//+d14sjBZY0jhaoXCA0BMwAlzQAAAAAAAAAAAAAAAMDM97cX8JM/v72A1wkcEzgmcOx64NUfuJn7gdcTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcuxz4+/2uH2icDnyCwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWNXA5+Yx83cDXyGwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOHYx8JkzETM3A58icEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMCxa4FPHfybuRf4HIFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHLsU+NxN+5lbgU8SOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcuxL45OvXM3cCnyVwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxy4EPjuyn7kR+LTzgf/t7rVb/Hzg7QSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFj2wOfHhfN7A98nsAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4NjmwOenGTO7Az9B4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOPZE4M3v9mwN/MQ0Y2Zv4GcIHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOLYx8DPzuJmdgZ8icEzgmMAxgWPPBN56fOqZwFsJHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTODYM4E/n8/MzOe31/G/ZwJvJXBM4JjAMYFjTwTe+gcBAAAAAAAAAAAAAAAAJ/wFNKI8d9eflR4AAAAASUVORK5CYII='
              />
              <image
                width='96'
                height='408'
                transform='translate(437 264)'
                href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAGYCAYAAAC9Luf4AAAACXBIWXMAAAsSAAALEgHS3X78AAADx0lEQVR4nO3cO3LcMBBAQa7L978ylShSIFlVsh5m2H0CLF+AD7G8LgAAAAAAAAAAAAAAAAD4eXc9gN/wpx7AF9ZHODXAfd/rn/11XecGeAwBYgLEBIgJEBMgdnSA96Xo6vXo0QGeQICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyA2PEBtr+WPD7AdgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBsRIDNV9RHBNhMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyA2JgAW/+mNCbAVgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBsVICNV9RHBdhIgJgAMQFiAsQEiAkQEyAmQOzUAK/X61WP4VecGuAxBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWLjAmy7njguwDYCxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxEYG2HQ9cWSATQSICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiJ0c4BGfsD85wCMIEBMgJkBMgJgAMQFiYwNsuZ44NsAWAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgNjrAhpsRowNsIEBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRA7PcD6T9acHmC98QGmv5YcH2A6AWICxASICRATICZATICYADEBYgLEBIgJEBMgtiLA5CPpFQEmEyAmQEyAmACxCQFWX02ZEGA1AWICxASICRATILYmwNQDuTUBphIgJkBMgJgAMQFiAsQEiE0JsPadwJQA/2TibnhVgIkEiAkQEyAmQEyAmAAxAWLrAkzbjK0LMI0AMQFiAsQmBVh5JD0pwEoCxFYGmLQXWBlgEgFiAsQEiAkQWxtgykpobYApBIhNC7DuPGhagHUEiK0OMGEltDrABALEBIgJEFsf4PSJeH2A0wkQEyA2McCq86CJAb7t5In4EQFOJkBMgNhjApw6DzwmwKkEiAkQ+1sP4H87fdN29ug+d79PrNd1ffqgj/6NRw/uCx9XNJN/CwAAAAAAAAAAAAAAAADwXW/qOl1qvv0UAAAAAABJRU5ErkJggg=='
              />
              <line x1='324.29' y1='294.99' x2='325.8' y2='188.57' />
              <line x1='475.6' y1='303.46' x2='472.78' y2='184.92' />
              <path d='m594.03,621.15l-91.98,13.62,91.98-13.62Z' />
              <path d='m428.28,634.28l48.87,3.02-48.87-3.02Z' />
              <path d='m312.44,610.57c-40.81-1.85-83.06-2.13-105.91-9.87' />
              <path d='m479.05,612.45c-35.01,1.8-118.05-3.73-151.67-3.76' />
              <path d='m593.76,598.56c.29,3.83-64,9.3-101.17,12.32' />
              <path d='m327.37,615.33c-7.47,1.65-10.09,1.01-15.11-.22' />
              <path d='m493.29,616.06c-.48.03-2.64,1.19-11.79,1.67' />
            </g>
          }
          <g id='Tablones'>
            <path d='m595.5,620.25c.16,2.15-14.43,6.17-37.23,8.17-18.73,1.64-19.65-.13-36.21,1.21-12.49,1.01-3.05,2.29-12.53,2.91-.48.03-29.4-2.77-38.54-2.28-6.63.35-3.19,1.97-12.62,2.45-12.87.66-6.48,2.95-31.13,3.26-37.38.47-30.07-3.24-37.67-3.24-4.41,0-36.34,1.03-40.96-.2-1.04-.28-6.13-1.52-13.66-2.73-11.93-1.91-27.03,1.22-38.49.7-3.83-.17,0,0-11.93-2.74-7.8-1.79-23.93,3.49-31.11-.19-4.2-2.15-34.63-6.52-48.47-7.32,29.2-330.01,30.14-345.94,54.96-436.61,0,0,130.72,14.7,281.59,0,0,0,30.73,128.64,54,436.61Z' />
            <image
              width='108'
              height='409'
              transform='translate(204 256)'
              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAAGZCAYAAABsTtTTAAAACXBIWXMAAAsSAAALEgHS3X78AAAD/klEQVR4nO3azU7kZhRF0dNR3v+VyaRbalUgaaB+vO21JjBgUGjr2p+vawMAAAAAAAAAAAAAAAAAAAA+7e3VH+A9f736AxzcIaPxb283Pw/DhMUIFiNYjGAxgpH39sHvh2DCYgSLESxGsBjBYgSLESxGsBjBSLvdbNh08D2CxQhG2qE39ZsJyxEsRrAYwWIEixEsRrAYwcg6/OJ3M2E5gsUIFiNYjGAxgsUIFiNYjGAxgsUIFiMYSe8tei1/+T7BYgQjKfHycjNhOYLFCBYjWIxgMYLFCBYjWIxgMYKRk3m1spmwHMFiBIsRLEawGMFiBIsRLEawGMHIyXxjajNhOYKRktrUbyYsR7AYwWIEixEsRrAYwWIEixEsRrAYwWIEixGMjI+28rb13I9gZOReXm4mLEewGMFiBIsRLEawGMFiBIsRLEYwEpKb+s2E5QgWI1iMYDGCxQgWIxgJya8HbCYsR7AYwWIEixGMw8tu6jcTliNYjGAxgsUIFiNYjGAxgsUIFiNYjGAxgnF42a8HXFF6U7+5JOYIFiNYjGAxgsUIFiNYjGAxgsUIFiNYjGAc1n8teC1/eQzBYgTjsPIvLzcTliNYjGAxgsUIFiNYjGAxgnFIp1j8biYsR7AYwWIEixEsRrAYwWIE45BO8bZ5M2E5gsUIxuGcZvG7mbAcwWIEixEsRrAYwWIEixEsRrAYwWIEixGMQznV4nczYTmCxQgWI1iMYDGCxQgWI1iMYDGCxQgWIxiH8X/LXctfHk+wGME4jNO9vNxMWI5gMYLFCBYjWIxgMYJxCKfcI24mLEewGMFiBIsRLEawGMFiBOMQTvnycjNhOYLxcqfdI24mLEewGMFiBIsRLEawGMFiBIsRLEawGMF4qT/ZE9olwledelO/mbAcwWIEixEsRrAYwWIEixGMlzn9WmozYTmCxQgWIxgvc/pN/WbCcgSLESxGMF7iEluOzYTlCBYjWIxgMYLFCBYjGC9xicXvZsJ4gctsOTYTliNYjGAxgsUIFiNYjGA81Z8+X53mOazuUg/Nm0tijmAxgsUIFiNYjGAxgvE0l3xoNmE8zeW2HJsJyxEsRrAYwXiKSx7pNxOWI1iMYDGC8RSX3HJsJownuOyRfjNhOYLFCBYjWIxgPNRnTn6nPCXWXPpIv7kk5ggWIxgP48AxE5YjWIxgPMzln8E2E8aDOCH+ZMJiBIsRjLv77D3JPQw+wwnxNyYsRjDuyoHjhgmLESxGMO7mK/ej09/DjsyB4x0uiTGCcRfuXx8wYTGCxQjGt331XnSJe9gRvc0z2If+fvUHeMePnz9vI/y4/cMrOmKwX24DCbj2Py0gAAAAAAAAAAAAAAAAAAAA/O4f2/p1w/Nh9d8AAAAASUVORK5CYII='
            />
            <line x1='275.24' y1='293.79' x2='289.6' y2='186.31' />
            <path d='m327.68,608.23c-40.76-1.85-97.9-.25-120.73-7.98' />
            <path d='m490.49,610.59c-34.97,1.8-129.22-2.34-162.81-2.36' />
            <path d='m593.8,598.11c.29,3.9-66.58,9.51-103.3,12.49' />
            <line x1='358.63' y1='297.29' x2='360.5' y2='189.98' />
            <line x1='530.69' y1='289.85' x2='517.5' y2='185.5' />
            <polyline points='447.3 298.62 445.5 190 445.5 189.49' />
            <line x1='270.53' y1='293.35' x2='284.89' y2='185.87' />
            <line x1='353.92' y1='296.84' x2='355.79' y2='189.54' />
            <line x1='525.97' y1='289.4' x2='513.5' y2='186.5' />
            <line x1='442.59' y1='298.18' x2='441.5' y2='190' />
            <image
              width='98'
              height='409'
              transform='translate(298 260)'
              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGIAAAGZCAYAAAByh+RgAAAACXBIWXMAAAsSAAALEgHS3X78AAADyUlEQVR4nO3cSZLiQBAAweyx+f+XmXsbYsQmQoX7pfsoIyy1lApmAAAAAAAAAAAAAAAAAIBVXD59ANf8+fQBfEguxreGyBGCj7j8+pthIiKEiBAiQogIISKEiBAiQggOd9n4P8FERAgRIUSEEBFCRAgRIUSEEBFCRAgRIUSEEBFCRAjBoX4ve1sG5zohIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIEIJDpb8bMWMiOFD+NemMicgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCE4xLWNAjYPsE2ICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQgkOc4ucfVnearTQzTk0ZQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQvBWW9tmbKfhNiEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkII3ubWTg27OPg+p9vTNGMiMoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCCN7ilFtpZkxEhhARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBC8xSl/h2M1p93TNOPUlCFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIwUv9b5eGXRzsI0SEEBFCRAgRIUSEEBFCRAgRIUSEEBFCRAgRIUSEELzUqb9ROmMiMoSIEIKXOf376hkTkSFEhBARQkQIESFEhBARQkQIESFEhBARQkQIESFEhBARQkQIwUvsefvmDR37CREhRIQQEUJECBEhRIQQvMQS2y1nTESGEBFCRAgRIQRPW2YJfMZEZAgRIUSEEBFCRAgRIUSEEBFCRAgRIUSEEDxlqQW/GRORIUSEEBFCRAgRIUSEEBFCRAgRIQQP27t0YYmD+wkRIQQPW24JfMZEZAgRIUSEEDxkyYe5GRORIUSEEBFCRAgRIUSEENxt2WeIGRORIUSEEBFCRAgRIQR3ueeW1O0rjxMiQogIIbjL0utMZ7H8HdOMU1OGEBFCRAgRIQS73HsX5K6J5wgRIQS7fMVTdd3XXKhnnJoyhIgQgpseOd+7RvA8ISKEiBCCTY9edE99sS76ujummZm/nz6AK35m+4P9OfJAjlQMMbP9gV8LtGycs7nMAqcmAAAAAAAAAAAAAAAAAFjVP9jOTBkImhkfAAAAAElFTkSuQmCC'
            />
            <image
              width='106'
              height='413'
              transform='translate(493 253)'
              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAGdCAYAAAD6weaCAAAACXBIWXMAAAsSAAALEgHS3X78AAAD2UlEQVR4nO3dvW4USxhF0RHi/V/ZJA7MMAab/qnaVWsl3NDS1hl1fx3cxwMAAAAAAAAAAAAAAAAAANjB2+g/YDY/Rv8BL4gU8fb0L485F8ULQkUIFSEU/+3tk//emkVFCBUhFId46X1iURFCRQjFId6lnlhUhFARQkUIFSFUhFARQkUIFSEUhzxfI7a/TlhUhFARQkUIFSFUhFARQnGYr7wfWBSHuU58YFERQkUIFSFUhFARQkUIFSFUhFARQnHYq5PRtmcki4oQKkIoTuFTxzuLihAqQqgIoSKEihCKUzgjvbOoCKEihIoQKkKoCKEihOI0PnU8LCpDqAihOI1738OiMoSKECpCqAihIoSKECpCqAihIoTiVNufkSwqQqgIoSKEihAqQqgIoSKEihAqQihO9dm5aJszkkVFCMXptr6gW1SEUBFCRQgVIVSEUBFCcbqtz0gWFSFUhFARQkUIxSW2vaBbVIRQEUJFCMUltj0jWVSEUBFCRQgVIVSEUBFCRQjFZba8oFsUl9nyjGRREUJFCBUhVIRQEUJFCBUhVIRQEUJxmb+dipY+IxVtd+/z0xchVIRQEUJFCBUhVIRQEUJxqe2uExYVIVSEUFxuq8OsRUUIFSFUhFARQkUIxeW2OiNZVIRQEUJFCBUhVIRQ3GKbC7pFRQgVIVSEUBFCcYttDrMWFSFUhFARQkUIFSEUt9niMGtREUJFCBUhVIRQEUJFCMVttvjUYVERQkUIFSEUt1r+MGtREUJFCBUhVIRQEUJFCBUhVIRQEUJFCMWttvh4uIqlD7N++iKEihAqQqgIoSKEihAqQqgIoSKEihAqQiiGeHWAdZTlPkJFCBUhFEMs+5XXoiKEihAqQqgIoSKEYpgl730WFSFUhFARQkUIFSFUhFARQkUIFSEUwyz5ldeiGGq5w6xFRQgVIVSEUBFCRQgVIVSEUBFCRQgVIVSEUAy13KcOi4oQiuGW+iZlURFCRQgVIVSEUBFCRQgVIRTDLXXvs6gIoSKEihAqQqgIoSKEihAqQiiG83+7DlnmjOSnL0KoCKEihIoQKkIoprDMu5RFRQgVIVSEUBFCRQjFFP71CJ55RLeoCKEihIoQKkIopvCVp7rEk59FRQgVIVSEUBFCMYWvPtFN/+RnURFCRQgVIRTTWOaMtLolnvz89EUIFSFUhFARQkUIFSEU0/jO+9G071IWFSFUhFARQkUIFSFUhFBM47vvRlO+S1lUhFARQkUIFSFUhFARQkUIFSFUhFARQkUIFSFUhFARP0f/ATeZ8tMFvxMJAAAAAAAAAAAAAAAAAAD4wy+hfGndD3vN1AAAAABJRU5ErkJggg=='
            />
            <image
              width='98'
              height='409'
              transform='translate(410 261)'
              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGIAAAGZCAYAAAByh+RgAAAACXBIWXMAAAsSAAALEgHS3X78AAADu0lEQVR4nO3bzU7CQBhAUTS+/yvXha6Ihf5guJ05JzG6LF4+BqbD7QYAAAAAAAAAAAAAAAAAMJnl92cqn+++AH4Iwarl7vcUTESEEBFCRAgRIUSEEBFCRAgRIQSrlpW/h2YiIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoRg1f3W9xRb4SYiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQogIISKEiBCCh6b8skrRdLdLvTRFCBEhRIQQEUJECBEhRIQQEUJECBEhRIQQEUJECBEhRIQQEUJECBEhRIQQPPTXYYGhDxCYiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCCB5aO7Ex9EmOqqmO1HhpihAiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQogIISKE4KmpjtSYiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAgheOrRsZnhjtSYiAgh2GSa034mIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCHYZJojNSYiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQogIISKEiBAiQgg2m+JIjYmIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIEILNnh2ZGeJIjYmIECJCiAghIoSIECJCiAghIoSIECJCiAghIoSIECJCiAgh2GX471qbiAgh2GX4+9YmIkKICCEihIgQIkKICCEihIgQIkKICCEihIgQIkKICCEihGCXLTd+Ln1zyERECBEhRIQQEUJECBEhRIQQ7Db0aT8TESFEhBARQkQIwW5Db4WbiAghIoSIECJCiAghIoSIECJCiAghIoSIEIJDht34MxERQkQIESFEhBARQkQIESFEhBARQnDI1u2Ly21zmIgIISKE4LAht8JNRIQQEUJECMFhQ36oMxERQkQIESFEhBARQkQIwSnDfZYwERFCRAgRIUSEEBFCcMqet6WXeAtrIiKEiBAiQghOG26/6aqGeufkpSlCiAghIoTgtL0LcHrBNhERQkQIESEELzHMgm0iIoSIECJCCF7iyOKbXLBNRIQQEUJECMFLHF14cwv2x7sv4AWW27bHcf/PTz32r3dfwD9Ye7an/vH30he3UfqZDgAAAAAAAAAAAAAAAACQ8A1lokYfrrtd3AAAAABJRU5ErkJggg=='
            />
          </g>
          <path
            id='Cintura'
            d='m259.55,183.68l1.68-20.83c88.09-.63,129.57,3.1,287.28-.17,3.78,12.5,4.33,21,4.33,21,0,0-85.05,7.76-144.41,7.76-69.53,0-148.87-7.76-148.87-7.76Z'
          />
          <path
            id='Boton'
            d='m543.37,172.8c0,2.61-.65,4.72-1.45,4.72s-1.45-2.11-1.45-4.72.65-4.72,1.45-4.72,1.45,2.11,1.45,4.72Z'
          />
          <path
            id='Cierre'
            d='m549.15,192.07c-.09.19-3.36-1.41-5.46-4.12-.88-1.15-1.39-.44-1.3-.64.09-.19.25-.49,1.64-.06,3.04.94,5.21,4.62,5.12,4.81Z'
          />

          {bolsas && (
            <g id='Bolsas'>
              <path
                id='Bolsa'
                d='m238.02,279.33c28.48-33.13,33.7-73.39,40.79-93.86'
              />
              <path
                id='Costura_bolsa'
                data-name='Costura bolsa'
                d='m237.64,283.07c29.7-32.78,37.51-73.24,43.76-97.39'
              />
              <path
                id='Bolsa-2'
                data-name='Bolsa'
                d='m558.73,280.13c-25.03-47.32-25.33-67.06-28.65-95.43'
              />
              <path
                id='Costura_bolsa-2'
                data-name='Costura bolsa'
                d='m559.25,284.27c-20.89-30.89-30.9-68.67-31.76-99.35'
              />
            </g>
          )}

          <g id='Escudo'>
            <circle cx='242.47' cy='568.48' r='18.3' />
            <circle cx='567.47' cy='568.48' r='18.3' />
            <circle cx='574' cy='571.29' r='15.5' />
            <circle cx='229' cy='571' r='15.5' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FaldaSvg
