import JumperSvg from 'assets/img/uniforms/jumper/JumperSvg'
import LabelJunper from 'assets/img/uniforms/jumper/LabelJunper'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

const JumperPic = () => {
    const { pictureLogo} = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
    <Fragment>
      <div
        className={`${
          style[`logo-position-${"left"}`]
        }`}
      >
        <img className={`${style['svg-logo']}`} src={pictureLogo} />
      </div>
      <div className={`${style['div-polo-svg-absolute']}`}>
        <JumperSvg className={`${style['svg']}`} />
      </div>
      <div className={`${style['div-shadows-svg-absolute']}`}>
        <LabelJunper className={`${style['svg']}`} />
      </div>
    </Fragment>
  </div>
  )
}

export default JumperPic