import { createSlice } from '@reduxjs/toolkit'

export const utilsAdminPageSlice = createSlice({
  name: 'utilsSchoolPage',
  initialState: {
    selectedGradeId: undefined,
    selectedpackageId: undefined
  },
  reducers: {
    setSelectedGrade: (state, action) => {
      return {
        ...state,
        selectedGradeId: action.payload
      }
    },
    setSelectedPackage: (state, action) => {
      return {
        ...state,
        selectedpackageId: action.payload
      }
    }
  }
})

export const { setSelectedGrade, setSelectedPackage } =
  utilsAdminPageSlice.actions
export default utilsAdminPageSlice.reducer
