import React, { Fragment, useState } from 'react'
import GradeCheck from './GradeCheck'
import { IGradesDinamic, ILevelsDinamic } from 'interfaces/schools-interface'
import style from './thiscomponents.module.scss'

const GroupCheck = ({
  name,
  grades,
  level,
  onHandleCheck
}: {
  name: string
  grades: IGradesDinamic[]
  level: ILevelsDinamic
  onHandleCheck: (
    dat: IGradesDinamic,
    level: ILevelsDinamic,
    allGrades: IGradesDinamic[]
  ) => void
}) => {
  const [selectAll, setSelectAll] = useState(false)

  const changeAllGrades = (state: boolean) => {
    setSelectAll(state)
    let grads: IGradesDinamic[] = []

    grades.forEach((item) => {
      grads.push({ ...item, checked: state })
    })
    onHandleCheck(
      {
        female_population: 0,
        id: 0,
        male_population: 0,
        name: '',
        checked: false
      },
      level,
      grads
    )
  }

  return (
    <Fragment>
      <div className={`${style['div-select-all']}`}>
        <p className={`${style['p-level-title']}`}>{name}</p>
        <label className={`${style['center-label']}`}>
          <input
            type={'checkbox'}
            checked={selectAll}
            onChange={(_) => changeAllGrades(!selectAll)}
          />
          Seleccionar todo
        </label>
      </div>
      <div className={`${style['div-wrap-label']}`}>
        {grades.map((item, indexKey: number) => {
          return (
            <Fragment key={indexKey}>
              <GradeCheck
                onHandleCheck={() =>
                  onHandleCheck(item, { id: 0, name: '', grades: [] }, [])
                }
                check={item.checked}
                name={item.name}
                allSelect={selectAll}
              />
            </Fragment>
          )
        })}
      </div>
    </Fragment>
  )
}

export default GroupCheck
