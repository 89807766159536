import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathRayado7mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Rayado Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Rayado_Completo' data-name='Path Rayado Completo'>
              <g id='Path_Rayado_Completo-2' data-name='Path Rayado Completo'>
                <g id='Rayado' stroke={`${ink !== 'black' ? ink : 'black'}`}>
                  <line x1='199.93' y1='164.6' x2='648.24' y2='164.6' />
                  <line x1='199.93' y1='144.6' x2='648.24' y2='144.6' />
                  <line x1='199.93' y1='124.6' x2='648.24' y2='124.6' />
                  <line x1='199.93' y1='184.63' x2='648.24' y2='184.63' />
                  <line x1='199.93' y1='204.63' x2='648.24' y2='204.63' />
                  <line x1='199.93' y1='224.63' x2='648.24' y2='224.63' />
                  <line x1='199.93' y1='244.63' x2='648.24' y2='244.63' />
                  <line x1='199.93' y1='264.63' x2='648.24' y2='264.63' />
                  <line x1='199.93' y1='284.63' x2='648.24' y2='284.63' />
                  <line x1='199.93' y1='304.63' x2='648.24' y2='304.63' />
                  <line x1='199.93' y1='324.63' x2='648.24' y2='324.63' />
                  <line x1='199.93' y1='344.63' x2='648.24' y2='344.63' />
                  <line x1='199.93' y1='364.63' x2='648.24' y2='364.63' />
                  <line x1='199.93' y1='384.63' x2='648.24' y2='384.63' />
                  <line x1='199.93' y1='404.63' x2='648.24' y2='404.63' />
                  <line x1='199.93' y1='424.63' x2='648.24' y2='424.63' />
                  <line x1='199.93' y1='444.63' x2='648.24' y2='444.63' />
                  <line x1='199.93' y1='464.63' x2='648.24' y2='464.63' />
                  <line x1='199.93' y1='484.63' x2='648.24' y2='484.63' />
                  <line x1='199.93' y1='504.63' x2='648.24' y2='504.63' />
                  <line x1='199.93' y1='524.63' x2='648.24' y2='524.63' />
                  <line x1='199.93' y1='544.63' x2='648.24' y2='544.63' />
                  <line x1='199.93' y1='564.63' x2='648.24' y2='564.63' />
                  <line x1='199.93' y1='584.63' x2='648.24' y2='584.63' />
                  <line x1='199.93' y1='604.63' x2='648.24' y2='604.63' />
                  <line x1='199.93' y1='624.63' x2='648.24' y2='624.63' />
                  <line x1='199.93' y1='644.63' x2='648.24' y2='644.63' />
                  <line x1='199.93' y1='664.63' x2='648.24' y2='664.63' />
                  <line x1='199.93' y1='684.63' x2='648.24' y2='684.63' />
                  <line x1='199.93' y1='704.63' x2='648.24' y2='704.63' />
                  <line x1='199.93' y1='724.63' x2='648.24' y2='724.63' />
                </g>
                <g
                  fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                >
                  <line x1='220.34' y1='103.56' x2='220.34' y2='745' />
                  {(() => {
                    switch (puntoGuia) {
                      case 'PuntoPuntoPaloma':
                        return (
                          <g id='Punto_guia' data-name='Punto guia'>
                            <path d='M210.86,116.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,137.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,152.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,176.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,197.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,212.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,236.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,257.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,272.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,296.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,317.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,332.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,356.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,377.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,392.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,416.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,437.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,452.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,476.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,497.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,512.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,536.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,557.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,572.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,596.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,617.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,632.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,656.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,677.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,717.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,737.28a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,692.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                          </g>
                        )
                      case 'PuntoPaloma':
                        return (
                          <g id='Punto_guia-2' data-name='Punto guia'>
                            <path d='M210.86,156.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M210.86,116.9a2.54,2.54,0,1,0-2.54-2.53,2.54,2.54,0,0,0,2.54,2.53' />
                            <path d='M211.75,172.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M211.75,132.47a21.7,21.7,0,0,0-3.33,4.25l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                            <path d='M210.86,196.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,212.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,236.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,252.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,276.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,292.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,316.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,332.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,356.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,372.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,396.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,412.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,436.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,452.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,476.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,492.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,516.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,532.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,556.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,572.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,596.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,612.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,636.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,652.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,676.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,692.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                            <path d='M210.86,716.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                            <path d='M211.75,732.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Rayado Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Rayado_Datos' data-name='Path Rayado Datos'>
              <g id='Path_Rayado_Datos-2' data-name='Path Rayado Datos'>
                <g id='Rayado' stroke={`${ink !== 'black' ? ink : 'black'}`}>
                  <line x1='199.93' y1='168.6' x2='648.24' y2='168.6' />
                  <line x1='199.93' y1='188.63' x2='648.24' y2='188.63' />
                  <line x1='199.93' y1='208.63' x2='648.24' y2='208.63' />
                  <line x1='199.93' y1='228.63' x2='648.24' y2='228.63' />
                  <line x1='199.93' y1='248.63' x2='648.24' y2='248.63' />
                  <line x1='199.93' y1='268.63' x2='648.24' y2='268.63' />
                  <line x1='199.93' y1='288.63' x2='648.24' y2='288.63' />
                  <line x1='199.93' y1='308.63' x2='648.24' y2='308.63' />
                  <line x1='199.93' y1='328.63' x2='648.24' y2='328.63' />
                  <line x1='199.93' y1='348.63' x2='648.24' y2='348.63' />
                  <line x1='199.93' y1='368.63' x2='648.24' y2='368.63' />
                  <line x1='199.93' y1='388.63' x2='648.24' y2='388.63' />
                  <line x1='199.93' y1='408.63' x2='648.24' y2='408.63' />
                  <line x1='199.93' y1='428.63' x2='648.24' y2='428.63' />
                  <line x1='199.93' y1='448.63' x2='648.24' y2='448.63' />
                  <line x1='199.93' y1='468.63' x2='648.24' y2='468.63' />
                  <line x1='199.93' y1='488.63' x2='648.24' y2='488.63' />
                  <line x1='199.93' y1='508.63' x2='648.24' y2='508.63' />
                  <line x1='199.93' y1='528.63' x2='648.24' y2='528.63' />
                  <line x1='199.93' y1='548.63' x2='648.24' y2='548.63' />
                  <line x1='199.93' y1='568.63' x2='648.24' y2='568.63' />
                  <line x1='199.93' y1='588.63' x2='648.24' y2='588.63' />
                  <line x1='199.93' y1='608.63' x2='648.24' y2='608.63' />
                  <line x1='199.93' y1='628.63' x2='648.24' y2='628.63' />
                  <line x1='199.93' y1='648.63' x2='648.24' y2='648.63' />
                  <line x1='199.93' y1='668.63' x2='648.24' y2='668.63' />
                  <line x1='199.93' y1='688.63' x2='648.24' y2='688.63' />
                  <line x1='199.93' y1='708.63' x2='648.24' y2='708.63' />
                  <line x1='199.93' y1='728.63' x2='648.24' y2='728.63' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          <line
                            id='_Trazado_espejo_'
                            data-name='&amp;lt;Trazado espejo&amp;gt;'
                            x1='627.33'
                            y1='149'
                            x2='627.33'
                            y2='749'
                          />
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m636.73,160.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,181.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,196.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,220.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,241.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,256.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,280.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,301.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,316.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,340.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,361.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,376.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,400.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,421.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,436.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,460.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,481.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,496.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,520.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,541.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,556.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,580.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,601.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,616.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,640.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,661.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,676.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,700.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m636.73,721.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,736.87c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m636.73,160.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,176.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,200.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,216.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,240.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,256.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,280.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,296.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,320.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,336.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,360.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,376.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,400.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,416.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,440.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,456.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,480.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,496.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,520.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,536.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,560.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,576.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,600.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,616.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,640.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,656.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,680.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,696.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    <path d='m636.73,720.88c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    <path d='m635.84,736.45c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          <line x1='220.26' y1='149' x2='220.26' y2='749' />
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M210.86,160.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,181.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,196.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,220.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,241.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,256.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,280.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,301.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,316.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,340.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,361.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,376.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,400.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,421.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,436.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,460.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,481.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,496.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,520.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,541.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,556.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,580.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,601.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,616.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,640.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,661.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,676.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                    <path d='M210.86,700.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M210.86,721.1a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,736.87a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.18' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M210.86,160.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,176.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,200.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,216.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,240.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,256.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,280.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,296.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,320.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,336.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,360.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,376.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,400.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,416.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,440.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,456.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,480.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,496.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,520.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,536.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,560.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,576.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,600.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,616.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,640.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,656.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,680.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,696.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                    <path d='M210.86,720.88a2.54,2.54,0,1,0-2.54-2.54,2.54,2.54,0,0,0,2.54,2.54' />
                                    <path d='M211.75,736.45a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.89,18.89,0,0,0-4,3.17' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Rayado Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Rayado_Completo'
                data-name='Margenes Rayado Completo'
              >
                <g
                  id='Margenes_Rayado_Completo-2'
                  data-name='Margenes Rayado Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V730.92A13.09,13.09,0,0,1,634.16,744H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V730.92A14.08,14.08,0,0,0,214,745H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56V744H200.93V104.56H647.24m1-1H199.93V745H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Rayado Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g id='Margenes_Rayado_Datos' data-name='Margenes Rayado Datos'>
                <g
                  id='Margenes_Rayado_Datos-2'
                  data-name='Margenes Rayado Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V734.92A13.09,13.09,0,0,1,634.16,748H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V734.92A14.08,14.08,0,0,0,214,749H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56V748H200.93V149.56H647.24m1-1H199.93V749H648.24V148.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathRayado7mmVertical
