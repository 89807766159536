import React from 'react'
import './ant-modal.scss'
import style from './modals.module.scss'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import { deletePackageNotebook } from 'services/notebooks/deletePackage'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'

const EraseSometingModal = ({
  show,
  onOk,
  onCancel,
  buttonText = 'vacio',
  data
}: {
  show: boolean
  onCancel: () => void
  onOk: () => void

  buttonText?: string
  data?: any
}) => {
  const deletePackage = () => {
    deletePackageNotebook(data.id)
      .then(() => {
        SuccessToast('Paquete borrado exitosamente')
        onOk()
      })
      .catch((error) => {
        console.log(error)
        ErrorToast('No se ha podido borrar el paquete, contactar con soporte')
      })
  }
  return (
    <Modal
      title={`Eliminar "${data !== null ? data.name + '" id:' + data.id : ''}`}
      className='modal-in-upload-img-uniforms'
      centered
      onOk={onOk}
      maskStyle={{ background: '#FFFFFF99' }}
      open={show}
      width={572}
      onCancel={onCancel}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={deletePackage}
            rounded
            label={buttonText}
            sector='secondary'
          />
        </div>
      ]}
    >
      <div className={`${style['div-warning']}`}>
        <div className={`${style['div-center-warning']}`}>
          <ExclamationTriangleIcon
            className={`${style['exclamation-triangle']}`}
          />
        </div>
        <p
          className={`${style['label-modal']}`}
        >{`Estas seguro que deseas eliminar: 
            ${data !== null ? `"${data.name}",` : ''}
             los datos ya no podran ser recuperados, asi que piensa bien lo que vas a hacer.`}</p>
      </div>
    </Modal>
  )
}

export default EraseSometingModal
