import React, { Fragment } from 'react'
import style from './poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SacoTraseroSvg from 'assets/img/uniforms/saco/SacoTraseroSvg'

const SacoTraseroEditable = () => {
  const { pictureLogo } = useSelector((state: RootState) => state.uniforms)
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div className={`${style[`logo-position-${'left'}`]}`}></div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SacoTraseroSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      </div>
    </div>
  )
}

export default SacoTraseroEditable
