import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare5mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
      stroke='black'
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 5mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_5mm_Completo'
              data-name='Path Cuadro 5mm Completo'
            >
              <g
                id='Path_Cuadro_5mm_Completo-2'
                data-name='Path Cuadro 5mm Completo'
              >
                <g
                  id='Cuadricula_Completa'
                  data-name='Cuadricula Completa'
                  fill={`${ink !== 'black' ? ink : 'black'}`}
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='213.68' y1='736.24' x2='213.68' y2='104.69' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='635.01' y1='736.18' x2='635.01' y2='104.64' />
                  </g>
                  <line x1='200.59' y1='117.46' x2='647.58' y2='117.46' />
                  <line x1='200.59' y1='130.59' x2='647.58' y2='130.59' />
                  <line x1='200.62' y1='143.85' x2='647.62' y2='143.85' />
                  <line x1='200.59' y1='156.84' x2='647.58' y2='156.84' />
                  <line x1='200.63' y1='170.12' x2='647.62' y2='170.12' />
                  <line x1='200.63' y1='183.31' x2='647.62' y2='183.31' />
                  <line x1='200.63' y1='196.45' x2='647.62' y2='196.45' />
                  <line x1='200.63' y1='209.6' x2='647.62' y2='209.6' />
                  <line x1='200.63' y1='222.83' x2='647.62' y2='222.83' />
                  <line x1='200.63' y1='236.06' x2='647.62' y2='236.06' />
                  <line x1='200.63' y1='249.17' x2='647.62' y2='249.17' />
                  <line x1='200.63' y1='262.34' x2='647.62' y2='262.34' />
                  <line x1='200.63' y1='275.45' x2='647.62' y2='275.45' />
                  <line x1='200.59' y1='288.43' x2='647.58' y2='288.43' />
                  <line x1='200.59' y1='301.55' x2='647.58' y2='301.55' />
                  <line x1='200.59' y1='314.68' x2='647.58' y2='314.68' />
                  <line x1='200.62' y1='327.94' x2='647.62' y2='327.94' />
                  <line x1='200.59' y1='340.93' x2='647.58' y2='340.93' />
                  <line x1='200.63' y1='354.21' x2='647.62' y2='354.21' />
                  <line x1='200.63' y1='367.39' x2='647.62' y2='367.39' />
                  <line x1='200.63' y1='380.54' x2='647.62' y2='380.54' />
                  <line x1='200.63' y1='393.69' x2='647.62' y2='393.69' />
                  <line x1='200.63' y1='406.92' x2='647.62' y2='406.92' />
                  <line x1='200.63' y1='420.15' x2='647.62' y2='420.15' />
                  <line x1='200.63' y1='433.26' x2='647.62' y2='433.26' />
                  <line x1='200.63' y1='446.43' x2='647.62' y2='446.43' />
                  <line x1='200.63' y1='459.54' x2='647.62' y2='459.54' />
                  <line x1='200.59' y1='472.93' x2='647.58' y2='472.93' />
                  <line x1='200.59' y1='486.06' x2='647.58' y2='486.06' />
                  <line x1='200.62' y1='499.31' x2='647.62' y2='499.31' />
                  <line x1='200.59' y1='512.31' x2='647.58' y2='512.31' />
                  <line x1='200.63' y1='525.59' x2='647.62' y2='525.59' />
                  <line x1='200.63' y1='538.77' x2='647.62' y2='538.77' />
                  <line x1='200.63' y1='551.92' x2='647.62' y2='551.92' />
                  <line x1='200.63' y1='565.06' x2='647.62' y2='565.06' />
                  <line x1='200.63' y1='578.29' x2='647.62' y2='578.29' />
                  <line x1='200.63' y1='591.53' x2='647.62' y2='591.53' />
                  <line x1='200.63' y1='604.63' x2='647.62' y2='604.63' />
                  <line x1='200.63' y1='617.81' x2='647.62' y2='617.81' />
                  <line x1='200.63' y1='630.92' x2='647.62' y2='630.92' />
                  <line x1='200.59' y1='643.89' x2='647.58' y2='643.89' />
                  <line x1='200.59' y1='657.02' x2='647.58' y2='657.02' />
                  <line x1='200.59' y1='670.15' x2='647.58' y2='670.15' />
                  <line x1='200.62' y1='683.4' x2='647.62' y2='683.4' />
                  <line x1='200.59' y1='696.4' x2='647.58' y2='696.4' />

                  <line x1='200.63' y1='709.68' x2='647.62' y2='709.68' />
                  <line x1='200.63' y1='722.86' x2='647.62' y2='722.86' />
                  <line x1='226.94' y1='736.19' x2='226.94' y2='104.64' />
                  <line x1='239.93' y1='736.24' x2='239.93' y2='104.69' />
                  <line x1='253.21' y1='736.18' x2='253.21' y2='104.64' />
                  <line x1='266.4' y1='736.18' x2='266.4' y2='104.64' />
                  <line x1='279.54' y1='736.18' x2='279.54' y2='104.64' />
                  <line x1='292.69' y1='736.18' x2='292.69' y2='104.64' />
                  <line x1='305.92' y1='736.18' x2='305.92' y2='104.64' />
                  <line x1='319.15' y1='736.18' x2='319.15' y2='104.64' />
                  <line x1='332.26' y1='736.18' x2='332.26' y2='104.64' />
                  <line x1='345.43' y1='736.18' x2='345.43' y2='104.64' />
                  <line x1='358.54' y1='736.18' x2='358.54' y2='104.64' />
                  <line x1='371.52' y1='736.24' x2='371.52' y2='104.69' />
                  <line x1='384.65' y1='736.24' x2='384.65' y2='104.69' />
                  <line x1='397.77' y1='736.24' x2='397.77' y2='104.69' />
                  <line x1='411.03' y1='736.19' x2='411.03' y2='104.64' />
                  <line x1='424.02' y1='736.24' x2='424.02' y2='104.69' />
                  <line x1='437.3' y1='736.18' x2='437.3' y2='104.64' />
                  <line x1='450.49' y1='736.18' x2='450.49' y2='104.64' />
                  <line x1='463.63' y1='736.18' x2='463.63' y2='104.64' />
                  <line x1='476.78' y1='736.18' x2='476.78' y2='104.64' />
                  <line x1='490.01' y1='736.18' x2='490.01' y2='104.64' />
                  <line x1='503.24' y1='736.18' x2='503.24' y2='104.64' />
                  <line x1='516.35' y1='736.18' x2='516.35' y2='104.64' />
                  <line x1='529.52' y1='736.18' x2='529.52' y2='104.64' />
                  <line x1='542.63' y1='736.18' x2='542.63' y2='104.64' />
                  <line x1='556.02' y1='736.24' x2='556.02' y2='104.69' />
                  <line x1='569.15' y1='736.24' x2='569.15' y2='104.69' />
                  <line x1='582.41' y1='736.19' x2='582.41' y2='104.64' />
                  <line x1='595.4' y1='736.24' x2='595.4' y2='104.69' />
                  <line x1='608.68' y1='736.18' x2='608.68' y2='104.64' />
                  <line x1='621.86' y1='736.18' x2='621.86' y2='104.64' />
                </g>
                {(() => {
                  switch (page) {
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M207.2,113.88a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,125.48a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,135.86a13,13,0,0,0-2,2.5L206,138c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.55,4.55,0,0,1,.51.94l.12.29a5.74,5.74,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.39,15.39,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M207.2,164.85a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,151.65a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,175.45a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.29,4.29,0,0,1,.51.94l.12.29a5.18,5.18,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.39,15.39,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.86' />
                                    <path d='M207.2,204.45a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,191.25a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,215.05a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.77-1a1.36,1.36,0,0,0-.9.52,1.15,1.15,0,0,1,.52.4,4.07,4.07,0,0,1,.51.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.42l.34-.23a15.44,15.44,0,0,1,1.8-2.83,13,13,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,244.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,230.88a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,254.65a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,283.62a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,270.58a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,294.25a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,321.76a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,310.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,333.85a13,13,0,0,0-2,2.5L206,336c-.29-.62-.54-.94-.77-.94a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.29a6.43,6.43,0,0,1,.26.7,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,519.75a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,362.85a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,349.65a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,373.45a13,13,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.11,1.11,0,0,1,.52.41,4.18,4.18,0,0,1,.51.93l.12.29a6.43,6.43,0,0,1,.26.7,6.57,6.57,0,0,1,.56-.43l.34-.23a15.74,15.74,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M207.2,401.77a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,388.8a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,412.66a13,13,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.11,1.11,0,0,1,.52.41,4.18,4.18,0,0,1,.51.93l.12.29a6.43,6.43,0,0,1,.26.7,6.57,6.57,0,0,1,.56-.43l.34-.23a15.74,15.74,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M207.2,441a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,441' />
                                    <path d='M207.2,428.07a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,451.81a13,13,0,0,0-2,2.5L206,454c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,481a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,481' />
                                    <path d='M207.2,467.85a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,491.74a13,13,0,0,0-2,2.5l-.14-.32c-.29-.62-.54-.94-.77-.94a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,507.4a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,531.11a13,13,0,0,0-2,2.5l-.14-.32c-.29-.62-.54-.94-.77-.94a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,559.77a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,546.5a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,570.71a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.36,1.36,0,0,0-.9.52,1.15,1.15,0,0,1,.52.4,4.07,4.07,0,0,1,.51.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,13,13,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,599.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,586.32a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,610.57a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.29,4.29,0,0,1,.51.94l.12.29a5.18,5.18,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.39,15.39,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.86' />
                                    <path d='M207.2,638.55a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,625.46a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,649.85a13,13,0,0,0-2,2.5L206,652c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.11,1.11,0,0,1,.52.41,4.18,4.18,0,0,1,.51.93l.12.29a6.43,6.43,0,0,1,.26.7,6.57,6.57,0,0,1,.56-.43l.34-.23a15.74,15.74,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M207.2,678.15a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,665.16a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.05,689.06a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.77-1a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M207.2,717.92a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M207.2,704.74a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.39,726.78a13.06,13.06,0,0,0-1.86,2.5l-.13-.31c-.27-.63-.52-1-.74-1a1.26,1.26,0,0,0-.84.52,1.06,1.06,0,0,1,.49.4,4.31,4.31,0,0,1,.48.93l.12.3a6.59,6.59,0,0,1,.24.69c.1-.09.28-.23.54-.43l.32-.22a15.11,15.11,0,0,1,1.71-2.83,12.28,12.28,0,0,1,2-2.24l-.15-.22a10.52,10.52,0,0,0-2.22,1.86' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M207.32,138.84a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,149.26a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,113.9a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,123a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,165.28a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,175.7a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,191.58a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,202a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,217.88a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,228.3a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,244.19a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M208.17,254.6a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,270.49a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,280.91a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,296.79a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,307.21a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,323.09a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,333.51a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,349.4a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,359.81a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.89,15.89,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M207.32,375.93a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,386.23a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,402a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,412.69a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.89,15.89,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M207.32,427.93a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,439.12a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M207.32,454.43a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,465.29a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,481.27a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,491.56a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,507.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,517.58a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,533.16a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,544.42a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M207.32,559.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,570.75a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.2,1.2,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,586.23a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,596.72a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,612.11a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,623.14a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,638.87a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,649.9a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,665.52a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,675.65a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M207.32,691.83a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.17,702.17a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M207.32,717.79a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.39,726.78a13.06,13.06,0,0,0-1.86,2.5l-.13-.31c-.27-.63-.52-1-.74-1a1.26,1.26,0,0,0-.84.52,1.06,1.06,0,0,1,.49.4,4.31,4.31,0,0,1,.48.93l.12.3a6.59,6.59,0,0,1,.24.69c.1-.09.28-.23.54-.43l.32-.22a15.11,15.11,0,0,1,1.71-2.83,12.28,12.28,0,0,1,2-2.24l-.15-.22a10.52,10.52,0,0,0-2.22,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <g
                                      id='Punto_guia_espejo'
                                      data-name='Punto guia espejo'
                                    >
                                      <path d='m641.64,113.88c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,125.48c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,135.86c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,164.85c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,151.65c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,175.45c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,204.45c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,191.25c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,215.05c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,244.05c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,230.88c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,254.65c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,283.62c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,270.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,294.25c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,321.76c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,310.05c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,333.85c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,519.75c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,362.85c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,349.65c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,373.45c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,401.77c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,388.8c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,412.66c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,440.95c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,428.07c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,451.81c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,480.99c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,467.85c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,491.74c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,507.4c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,531.11c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,559.77c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,546.5c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,570.71c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,599.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,586.32c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,610.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,638.55c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,625.46c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,649.85c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,678.15c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,665.16c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.79,689.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.64,717.92c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m641.64,704.74c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.44,726.78c.76.81,1.38,1.64,1.86,2.5l.13-.32c.27-.63.51-.94.73-.94.26,0,.54.17.85.51-.18.07-.35.2-.5.4-.15.2-.31.51-.48.93l-.12.29c-.13.32-.21.55-.24.7-.09-.09-.27-.24-.54-.43l-.32-.23c-.39-.88-.97-1.82-1.71-2.83-.75-1.01-1.43-1.75-2.04-2.24l.15-.23c.72.43,1.46,1.05,2.22,1.86' />
                                    </g>
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <g
                                      id='Punto_guia_espejo-2'
                                      data-name='Punto guia espejo'
                                    >
                                      <path d='m641.51,138.84c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,149.26c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,113.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,122.99c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,165.28c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,175.7c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,191.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,202c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,217.88c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,228.3c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,244.19c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,254.6c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,270.49c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,280.91c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,296.79c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,307.21c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,323.09c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,333.51c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,349.4c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,359.81c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,375.93c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,386.23c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,402.02c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,412.69c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,427.93c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,439.12c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,454.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,465.29c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,481.27c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,491.56c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,507.47c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,517.58c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,533.16c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,544.42c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,559.47c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,570.75c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,586.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,596.72c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,612.11c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,623.14c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,638.87c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,649.9c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,665.52c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,675.65c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,691.83c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      <path d='m640.67,702.17c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      <path d='m641.51,717.79c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                      <path d='m640.44,726.78c.76.81,1.38,1.64,1.86,2.5l.13-.32c.27-.63.51-.94.73-.94.26,0,.54.17.85.51-.18.07-.35.2-.5.4-.15.2-.31.51-.48.93l-.12.29c-.13.32-.21.55-.24.7-.09-.09-.27-.24-.54-.43l-.32-.23c-.39-.88-.97-1.82-1.71-2.83-.75-1.01-1.43-1.75-2.04-2.24l.15-.23c.72.43,1.46,1.05,2.22,1.86' />
                                    </g>
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 5mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_5mm_Datos' data-name='Path Cuadro 5mm Datos'>
              <g id='Path_Cuadro_5mm_Datos-2' data-name='Path Cuadro 5mm Datos'>
                <g
                  fill={`${ink !== 'black' ? ink : 'black'}`}
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='213.68' y1='741.21' x2='213.68' y2='149.41' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='635.01' y1='741.15' x2='635.01' y2='149.35' />
                  </g>

                  <line x1='200.59' y1='162.18' x2='647.58' y2='162.18' />
                  <line x1='200.59' y1='175.31' x2='647.58' y2='175.31' />
                  <line x1='200.62' y1='188.56' x2='647.62' y2='188.56' />
                  <line x1='200.59' y1='201.56' x2='647.58' y2='201.56' />
                  <line x1='200.63' y1='214.84' x2='647.62' y2='214.84' />
                  <line x1='200.63' y1='228.02' x2='647.62' y2='228.02' />
                  <line x1='200.63' y1='241.17' x2='647.62' y2='241.17' />
                  <line x1='200.63' y1='254.31' x2='647.62' y2='254.31' />
                  <line x1='200.63' y1='267.54' x2='647.62' y2='267.54' />
                  <line x1='200.63' y1='280.78' x2='647.62' y2='280.78' />
                  <line x1='200.63' y1='293.88' x2='647.62' y2='293.88' />
                  <line x1='200.63' y1='307.06' x2='647.62' y2='307.06' />
                  <line x1='200.63' y1='320.17' x2='647.62' y2='320.17' />
                  <line x1='200.59' y1='333.14' x2='647.58' y2='333.14' />
                  <line x1='200.59' y1='346.27' x2='647.58' y2='346.27' />
                  <line x1='200.59' y1='359.4' x2='647.58' y2='359.4' />
                  <line x1='200.62' y1='372.65' x2='647.62' y2='372.65' />
                  <line x1='200.59' y1='385.65' x2='647.58' y2='385.65' />
                  <line x1='200.63' y1='398.93' x2='647.62' y2='398.93' />
                  <line x1='200.63' y1='412.11' x2='647.62' y2='412.11' />
                  <line x1='200.63' y1='425.26' x2='647.62' y2='425.26' />
                  <line x1='200.63' y1='438.4' x2='647.62' y2='438.4' />
                  <line x1='200.63' y1='451.63' x2='647.62' y2='451.63' />
                  <line x1='200.63' y1='464.87' x2='647.62' y2='464.87' />
                  <line x1='200.63' y1='477.97' x2='647.62' y2='477.97' />
                  <line x1='200.63' y1='491.15' x2='647.62' y2='491.15' />
                  <line x1='200.63' y1='504.26' x2='647.62' y2='504.26' />
                  <line x1='200.59' y1='517.65' x2='647.58' y2='517.65' />
                  <line x1='200.59' y1='530.77' x2='647.58' y2='530.77' />
                  <line x1='200.62' y1='544.03' x2='647.62' y2='544.03' />
                  <line x1='200.59' y1='557.02' x2='647.58' y2='557.02' />
                  <line x1='200.63' y1='570.3' x2='647.62' y2='570.3' />
                  <line x1='200.63' y1='583.49' x2='647.62' y2='583.49' />
                  <line x1='200.63' y1='596.63' x2='647.62' y2='596.63' />
                  <line x1='200.63' y1='609.78' x2='647.62' y2='609.78' />
                  <line x1='200.63' y1='623.01' x2='647.62' y2='623.01' />
                  <line x1='200.63' y1='636.24' x2='647.62' y2='636.24' />
                  <line x1='200.63' y1='649.35' x2='647.62' y2='649.35' />
                  <line x1='200.63' y1='662.52' x2='647.62' y2='662.52' />
                  <line x1='200.63' y1='675.63' x2='647.62' y2='675.63' />
                  <line x1='200.59' y1='688.61' x2='647.58' y2='688.61' />
                  <line x1='200.59' y1='701.74' x2='647.58' y2='701.74' />
                  <line x1='200.59' y1='714.86' x2='647.58' y2='714.86' />
                  <line x1='200.62' y1='728.12' x2='647.62' y2='728.12' />
                  <line x1='226.94' y1='741.16' x2='226.94' y2='149.36' />
                  <line x1='239.93' y1='741.21' x2='239.93' y2='149.41' />
                  <line x1='253.21' y1='741.15' x2='253.21' y2='149.35' />
                  <line x1='266.4' y1='741.15' x2='266.4' y2='149.35' />
                  <line x1='279.54' y1='741.15' x2='279.54' y2='149.35' />
                  <line x1='292.69' y1='741.15' x2='292.69' y2='149.35' />
                  <line x1='305.92' y1='741.15' x2='305.92' y2='149.35' />
                  <line x1='319.15' y1='741.15' x2='319.15' y2='149.35' />
                  <line x1='332.26' y1='741.15' x2='332.26' y2='149.35' />
                  <line x1='345.43' y1='741.15' x2='345.43' y2='149.35' />
                  <line x1='358.54' y1='741.15' x2='358.54' y2='149.35' />
                  <line x1='371.52' y1='741.21' x2='371.52' y2='149.41' />
                  <line x1='384.65' y1='741.21' x2='384.65' y2='149.41' />
                  <line x1='397.77' y1='741.21' x2='397.77' y2='149.41' />
                  <line x1='411.03' y1='741.16' x2='411.03' y2='149.36' />
                  <line x1='424.02' y1='741.21' x2='424.02' y2='149.41' />
                  <line x1='437.3' y1='741.15' x2='437.3' y2='149.35' />
                  <line x1='450.49' y1='741.15' x2='450.49' y2='149.35' />
                  <line x1='463.63' y1='741.15' x2='463.63' y2='149.35' />
                  <line x1='476.78' y1='741.15' x2='476.78' y2='149.35' />
                  <line x1='490.01' y1='741.15' x2='490.01' y2='149.35' />
                  <line x1='503.24' y1='741.15' x2='503.24' y2='149.35' />
                  <line x1='516.35' y1='741.15' x2='516.35' y2='149.35' />
                  <line x1='529.52' y1='741.15' x2='529.52' y2='149.35' />
                  <line x1='542.63' y1='741.15' x2='542.63' y2='149.35' />
                  <line x1='556.02' y1='741.21' x2='556.02' y2='149.41' />
                  <line x1='569.15' y1='741.21' x2='569.15' y2='149.41' />
                  <line x1='582.41' y1='741.16' x2='582.41' y2='149.36' />
                  <line x1='595.4' y1='741.21' x2='595.4' y2='149.41' />
                  <line x1='608.68' y1='741.15' x2='608.68' y2='149.35' />
                  <line x1='621.86' y1='741.15' x2='621.86' y2='149.35' />
                </g>
                {(() => {
                  switch (page) {
                    case 2:
                      return (
                        <g>
                          <g
                            fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                            stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                          >
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='M207.2,171a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,171' />
                                      <path d='M207.2,158.42a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,181.34a13,13,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.55,4.55,0,0,1,.51.94l.12.29a5.18,5.18,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.39,15.39,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a10.72,10.72,0,0,0-2.33,1.87' />
                                      <path d='M207.2,210.33a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,197.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,220.93a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.29,4.29,0,0,1,.51.94l.12.29a5.18,5.18,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.44,15.44,0,0,1,1.8-2.83,12.52,12.52,0,0,1,2.15-2.23l-.16-.23a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,249.93a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,236.73a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,260.53a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.77-1a1.36,1.36,0,0,0-.9.52,1.15,1.15,0,0,1,.52.4,4.07,4.07,0,0,1,.51.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,13,13,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,289.53a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,276.36a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,300.13a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,329.1a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,316.06a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,339.73a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,368.4a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,355a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,355' />
                                      <path d='M208.05,379a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.36,1.36,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,565.23a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,408.33a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,395.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,418.42a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,447.24a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,434.28a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,458.14a13,13,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.11,1.11,0,0,1,.52.41,4.18,4.18,0,0,1,.51.93l.12.29a6.43,6.43,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.74,15.74,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                      <path d='M207.2,486.43a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,473.55a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,497.29a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.77-1a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,526.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,513.33a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,537.22a13,13,0,0,0-2,2.5l-.14-.32c-.29-.62-.54-.94-.77-.94a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69,6.57,6.57,0,0,1,.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,552.88a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,576.24a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.77-.95a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,605.25a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,592a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,592' />
                                      <path d='M208.05,615.68a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.51,1.15,1.15,0,0,1,.52.4,4.29,4.29,0,0,1,.51.94l.12.29a5.18,5.18,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a15.44,15.44,0,0,1,1.8-2.83,12.52,12.52,0,0,1,2.15-2.23l-.16-.23a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,644.61a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,631.8a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,655.44a13,13,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.77-1a1.32,1.32,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,684a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,207.2,684' />
                                      <path d='M207.2,670.93a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.05,694.82a13.33,13.33,0,0,0-2,2.5L206,697c-.29-.63-.54-1-.77-1a1.36,1.36,0,0,0-.9.52,1.08,1.08,0,0,1,.52.4,4.18,4.18,0,0,1,.51.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.56-.43l.34-.22a15.44,15.44,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                      <path d='M207.2,723.62a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M207.2,710.64a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.43,732.27a13.42,13.42,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.22.56.26.7a5.23,5.23,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='M207.32,183.51a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,193.93a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,158.57a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,167.66a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,209.94a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,220.36a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,236.24a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,246.66a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,262.55a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,273a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,288.85a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,299.27a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,315.15a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,325.57a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,341.46a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                      <path d='M208.17,351.87a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,367.76a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,378.18a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,394.06a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,404.48a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,420.6a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,430.89a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,446.68a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,457.36a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,473.28a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,483.78a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,499.57a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,510a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,525.94a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                      <path d='M208.17,536.23a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,552.14a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,562.24a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,578.62a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,589.08a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,604.88a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,615.41a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,630.89a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,641.39a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.2,1.2,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,657.75a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,667.81a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,683.54a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,694.57a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                      <path d='M207.32,710.19a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                      <path d='M208.17,720.62a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                      <path d='M207.32,736.07a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      )
                    case 1:
                      return (
                        <g>
                          <g
                            fill={`${ink !== 'black' ? '#f5333f' : ink}`}
                            stroke={`${ink !== 'black' ? '#f5333f' : ink}`}
                          >
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m641.72,170.96c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,158.42c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,181.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,210.33c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,197.13c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,220.93c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,249.93c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,236.73c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,260.53c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,289.53c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,276.36c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,300.13c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,329.1c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,316.06c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,339.73c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,368.4c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,354.95c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,379c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,565.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,408.33c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,395.13c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,418.42c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,447.24c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,434.28c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,458.14c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,486.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,473.55c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,497.29c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,526.47c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,513.33c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,537.22c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,552.88c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,576.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,605.25c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,591.98c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,615.68c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,644.61c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,631.8c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,655.44c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,684.03c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,670.93c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.87,694.82c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.72,723.62c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m641.72,710.64c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.49,732.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                      </g>
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <g
                                        id='Punto_guia_espejo-2'
                                        data-name='Punto guia espejo'
                                      >
                                        <path d='m641.6,183.51c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,193.93c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,158.57c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,167.66c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,209.94c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,220.36c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,236.24c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,246.66c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,262.55c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,272.97c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,288.85c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,299.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,315.15c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,325.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,341.46c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,351.87c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,367.76c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,378.18c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,394.06c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,404.48c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,420.6c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,430.89c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,446.68c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,457.36c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,473.28c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,483.78c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,499.57c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,509.96c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,525.94c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,536.23c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,552.14c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,562.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,578.62c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,589.08c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,604.88c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,615.41c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,630.89c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,641.39c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,657.75c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,667.81c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,683.54c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,694.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,710.19c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                        <path d='m640.75,720.62c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                        <path d='m641.6,736.07c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                      </g>
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      {marginComplete ? (
        <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
          <title>Margenes Cuadro 5mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Cuadro_5mm_Completo'
              data-name='Margenes Cuadro 5mm Completo'
            >
              <g
                id='Margenes_Cuadro_5mm_Completo-2'
                data-name='Margenes Cuadro 5mm Completo'
              >
                {roundedMargin ? (
                  <g id='Hoja_esq._redondeada' data-name='Hoja esq. redondeada'>
                    <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V722.16a13.09,13.09,0,0,1-13.08,13.08H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V722.16A14.08,14.08,0,0,0,214,736.24H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                  </g>
                ) : (
                  <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                    <path d='M647.24,104.56V735.24H200.93V104.56H647.24m1-1H199.93V736.24H648.24V103.56Z' />
                  </g>
                )}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
          <title>Margenes Cuadro 5mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Cuadro_5mm_Datos'
              data-name='Margenes Cuadro 5mm Datos'
            >
              <g
                id='Margenes_Cuadro_5mm_Datos-2'
                data-name='Margenes Cuadro 5mm Datos'
              >
                {roundedMargin ? (
                  //aqui esta el cuadrado normal
                  <rect
                    id='Hoja_esq._redondeada'
                    data-name='Hoja esq. redondeada'
                    x='199.93'
                    y='148.56'
                    width='448.31'
                    height='593.48'
                    rx='14.08'
                  />
                ) : (
                  //aqui estan las esquinas redondeadas
                  <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                    <path d='M647.24,149.56V741H200.93V149.56H647.24m1-1H199.93V742H648.24V148.56Z' />
                  </g>
                )}
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}

export default PathSquare5mmVertical
