import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare5mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 5mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_5mm_Completo'
              data-name='Path Cuadro 5mm Completo'
            >
              <g
                id='Path_Cuadro_5mm_Completo-2'
                data-name='Path Cuadro 5mm Completo'
              >
                <g
                  id='Cuadrado_completo'
                  data-name='Cuadrado completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='99.21' y1='152.01' x2='99.21' y2='622.1' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='672.26' y1='152.01' x2='672.26' y2='622.1' />
                  </g>
                  <line x1='113.15' y1='152.01' x2='113.15' y2='622.1' />
                  <line x1='127.23' y1='152.05' x2='127.23' y2='622.14' />
                  <line x1='141.03' y1='152.01' x2='141.03' y2='622.1' />
                  <line x1='155.13' y1='152.05' x2='155.13' y2='622.14' />
                  <line x1='169.13' y1='152.05' x2='169.13' y2='622.14' />
                  <line x1='183.1' y1='152.05' x2='183.1' y2='622.14' />
                  <line x1='197.06' y1='152.05' x2='197.06' y2='622.14' />
                  <line x1='211.11' y1='152.05' x2='211.11' y2='622.14' />
                  <line x1='225.17' y1='152.05' x2='225.17' y2='622.14' />
                  <line x1='239.09' y1='152.05' x2='239.09' y2='622.14' />
                  <line x1='253.08' y1='152.05' x2='253.08' y2='622.14' />
                  <line x1='267' y1='152.05' x2='267' y2='622.14' />
                  <line x1='280.79' y1='152.01' x2='280.79' y2='622.1' />
                  <line x1='294.73' y1='152.01' x2='294.73' y2='622.1' />
                  <line x1='308.67' y1='152.01' x2='308.67' y2='622.1' />
                  <line x1='322.75' y1='152.05' x2='322.75' y2='622.14' />
                  <line x1='336.55' y1='152.01' x2='336.55' y2='622.1' />
                  <line x1='350.65' y1='152.05' x2='350.65' y2='622.14' />
                  <line x1='364.65' y1='152.05' x2='364.65' y2='622.14' />
                  <line x1='378.61' y1='152.05' x2='378.61' y2='622.14' />
                  <line x1='392.58' y1='152.05' x2='392.58' y2='622.14' />
                  <line x1='406.63' y1='152.05' x2='406.63' y2='622.14' />
                  <line x1='420.68' y1='152.05' x2='420.68' y2='622.14' />
                  <line x1='434.6' y1='152.05' x2='434.6' y2='622.14' />
                  <line x1='448.59' y1='152.05' x2='448.59' y2='622.14' />
                  <line x1='462.52' y1='152.05' x2='462.52' y2='622.14' />
                  <line x1='476.74' y1='152.01' x2='476.74' y2='622.1' />
                  <line x1='490.68' y1='152.01' x2='490.68' y2='622.1' />
                  <line x1='504.76' y1='152.05' x2='504.76' y2='622.14' />
                  <line x1='518.56' y1='152.01' x2='518.56' y2='622.1' />
                  <line x1='532.66' y1='152.05' x2='532.66' y2='622.14' />
                  <line x1='546.67' y1='152.05' x2='546.67' y2='622.14' />
                  <line x1='560.63' y1='152.05' x2='560.63' y2='622.14' />
                  <line x1='574.59' y1='152.05' x2='574.59' y2='622.14' />
                  <line x1='588.65' y1='152.05' x2='588.65' y2='622.14' />
                  <line x1='602.7' y1='152.05' x2='602.7' y2='622.14' />
                  <line x1='616.62' y1='152.05' x2='616.62' y2='622.14' />
                  <line x1='630.61' y1='152.05' x2='630.61' y2='622.14' />
                  <line x1='644.53' y1='152.05' x2='644.53' y2='622.14' />
                  <line x1='658.32' y1='152.01' x2='658.32' y2='622.1' />
                  <line x1='686.31' y1='165.78' x2='85.64' y2='165.78' />
                  <line x1='686.26' y1='179.73' x2='85.59' y2='179.73' />
                  <line x1='686.31' y1='193.39' x2='85.64' y2='193.39' />
                  <line x1='686.25' y1='207.35' x2='85.58' y2='207.35' />
                  <line x1='686.25' y1='221.22' x2='85.58' y2='221.22' />
                  <line x1='686.25' y1='235.05' x2='85.58' y2='235.05' />
                  <line x1='686.25' y1='248.87' x2='85.58' y2='248.87' />
                  <line x1='686.25' y1='262.79' x2='85.58' y2='262.79' />
                  <line x1='686.25' y1='276.7' x2='85.58' y2='276.7' />
                  <line x1='686.25' y1='290.49' x2='85.58' y2='290.49' />
                  <line x1='686.25' y1='304.34' x2='85.58' y2='304.34' />
                  <line x1='686.25' y1='318.13' x2='85.58' y2='318.13' />
                  <line x1='686.31' y1='331.78' x2='85.64' y2='331.78' />
                  <line x1='686.31' y1='345.58' x2='85.64' y2='345.58' />
                  <line x1='686.31' y1='359.38' x2='85.64' y2='359.38' />
                  <line x1='686.26' y1='373.33' x2='85.59' y2='373.33' />
                  <line x1='686.31' y1='386.99' x2='85.64' y2='386.99' />
                  <line x1='686.25' y1='400.95' x2='85.58' y2='400.95' />
                  <line x1='686.25' y1='414.82' x2='85.58' y2='414.82' />
                  <line x1='686.25' y1='428.65' x2='85.58' y2='428.65' />
                  <line x1='686.25' y1='442.47' x2='85.58' y2='442.47' />
                  <line x1='686.25' y1='456.39' x2='85.58' y2='456.39' />
                  <line x1='686.25' y1='470.3' x2='85.58' y2='470.3' />
                  <line x1='686.25' y1='484.09' x2='85.58' y2='484.09' />
                  <line x1='686.25' y1='497.94' x2='85.58' y2='497.94' />
                  <line x1='686.25' y1='511.73' x2='85.58' y2='511.73' />
                  <line x1='686.31' y1='525.81' x2='85.64' y2='525.81' />
                  <line x1='686.31' y1='539.61' x2='85.64' y2='539.61' />
                  <line x1='686.26' y1='553.56' x2='85.59' y2='553.56' />
                  <line x1='686.31' y1='567.22' x2='85.64' y2='567.22' />
                  <line x1='686.25' y1='581.19' x2='85.58' y2='581.19' />
                  <line x1='686.25' y1='595.05' x2='85.58' y2='595.05' />
                  <line x1='686.25' y1='608.88' x2='85.58' y2='608.88' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m679.28,161.89c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,173.89c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,185.6c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,215.32c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,202.5c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,227.03c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,256.75c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,243.93c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,268.45c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,298.18c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,285.36c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,309.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,339.61c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,326.78c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,351.31c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,381.03c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,368.21c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,392.74c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,588.18c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,422.46c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,409.64c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,434.17c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,463.89c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,451.07c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,475.59c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,505.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,492.5c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,517.02c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,546.75c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.28,533.93c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,558.45c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,575.35c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,599.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,615.96c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m679.28,188.47c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,199.68c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,161.81c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,172.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,216.09c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,227.3c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,243.71c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,254.92c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,271.33c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,282.54c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,298.95c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,310.16c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,326.57c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,337.78c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,354.19c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,365.4c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,381.81c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,393.02c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,409.43c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,420.64c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,437.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,448.26c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,464.67c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,475.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,492.29c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,503.5c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,519.91c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,531.12c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,547.53c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,558.74c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,575.14c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,586.36c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.28,602.76c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.44,612c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M93,161.89a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,161.89' />
                                    <path d='M93,173.89a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,173.89' />
                                    <path d='M93.89,185.6a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82A12,12,0,0,1,96.39,184l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,215.32a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,215.32' />
                                    <path d='M93,202.5A1.49,1.49,0,1,0,91.55,201,1.49,1.49,0,0,0,93,202.5' />
                                    <path d='M93.89,227a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11,11,0,0,0,93.89,227' />
                                    <path d='M93,256.75a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,256.75' />
                                    <path d='M93,243.93a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,243.93' />
                                    <path d='M93.89,268.45a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,298.18a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,298.18' />
                                    <path d='M93,285.36a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,285.36' />
                                    <path d='M93.89,309.88a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,339.61a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,339.61' />
                                    <path d='M93,326.78a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,326.78' />
                                    <path d='M93.89,351.31a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,381a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,381' />
                                    <path d='M93,368.21a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,368.21' />
                                    <path d='M93.89,392.74a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,588.18a1.5,1.5,0,1,0-1.49-1.5,1.49,1.49,0,0,0,1.49,1.5' />
                                    <path d='M93,422.46A1.49,1.49,0,1,0,91.55,421,1.49,1.49,0,0,0,93,422.46' />
                                    <path d='M93,409.64a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,409.64' />
                                    <path d='M93.89,434.17a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,463.89a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,463.89' />
                                    <path d='M93,451.07a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,451.07' />
                                    <path d='M93.89,475.59a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82A12,12,0,0,1,96.39,474l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M93,505.32a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,505.32' />
                                    <path d='M93,492.5A1.49,1.49,0,1,0,91.55,491,1.49,1.49,0,0,0,93,492.5' />
                                    <path d='M93.89,517a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,93.89,517' />
                                    <path d='M93,546.75a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,546.75' />
                                    <path d='M93,533.93a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,533.93' />
                                    <path d='M93.89,558.45a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,575.35a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,575.35' />
                                    <path d='M93.89,599.88a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,616a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,616' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M93,188.47A1.49,1.49,0,1,0,91.55,187,1.48,1.48,0,0,0,93,188.47' />
                                    <path d='M93.89,199.68a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,161.81a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,161.81' />
                                    <path d='M93.89,172.06a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.89,15.89,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M93,216.09a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,216.09' />
                                    <path d='M93.89,227.3a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,243.71a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,243.71' />
                                    <path d='M93.89,254.92a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,271.33a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,271.33' />
                                    <path d='M93.89,282.54a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,299a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,299' />
                                    <path d='M93.89,310.16a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,326.57a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,326.57' />
                                    <path d='M93.89,337.78a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,354.19a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,354.19' />
                                    <path d='M93.89,365.4a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22A15.54,15.54,0,0,1,94.24,366a12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,381.81a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,381.81' />
                                    <path d='M93.89,393a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,93.89,393' />
                                    <path d='M93,409.43a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,409.43' />
                                    <path d='M93.89,420.64a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83A12.29,12.29,0,0,1,96.39,419l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,437.05a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,437.05' />
                                    <path d='M93.89,448.26a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,464.67a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,464.67' />
                                    <path d='M93.89,475.88a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,492.29a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,492.29' />
                                    <path d='M93.89,503.5a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,519.91a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,519.91' />
                                    <path d='M93.89,531.12a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,547.53a1.5,1.5,0,1,0-1.49-1.5,1.49,1.49,0,0,0,1.49,1.5' />
                                    <path d='M93.89,558.74a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,575.14a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,575.14' />
                                    <path d='M93.89,586.36a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23A15.49,15.49,0,0,1,94.24,587a12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M93,602.76a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,602.76' />
                                    <path d='M93.89,612a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11,11,0,0,0,93.89,612' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 5mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_5mm_Datos' data-name='Path Cuadro 5mm Datos'>
              <g id='Path_Cuadro_5mm_Datos-2' data-name='Path Cuadro 5mm Datos'>
                <g
                  id='Cuadrado_Datos'
                  data-name='Cuadrado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='99.21' y1='196.56' x2='99.21' y2='611.78' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='672.26' y1='196.56' x2='672.26' y2='611.78' />
                  </g>
                  <line x1='113.15' y1='196.56' x2='113.15' y2='611.78' />
                  <line x1='127.23' y1='196.6' x2='127.23' y2='611.82' />
                  <line x1='141.03' y1='196.56' x2='141.03' y2='611.78' />
                  <line x1='155.13' y1='196.6' x2='155.13' y2='611.82' />
                  <line x1='169.13' y1='196.6' x2='169.13' y2='611.82' />
                  <line x1='183.1' y1='196.6' x2='183.1' y2='611.82' />
                  <line x1='197.06' y1='196.6' x2='197.06' y2='611.82' />
                  <line x1='211.11' y1='196.6' x2='211.11' y2='611.82' />
                  <line x1='225.17' y1='196.6' x2='225.17' y2='611.82' />
                  <line x1='239.09' y1='196.6' x2='239.09' y2='611.82' />
                  <line x1='253.08' y1='196.6' x2='253.08' y2='611.82' />
                  <line x1='267' y1='196.6' x2='267' y2='611.82' />
                  <line x1='280.79' y1='196.56' x2='280.79' y2='611.78' />
                  <line x1='294.73' y1='196.56' x2='294.73' y2='611.78' />
                  <line x1='308.67' y1='196.56' x2='308.67' y2='611.78' />
                  <line x1='322.75' y1='196.6' x2='322.75' y2='611.82' />
                  <line x1='336.55' y1='196.56' x2='336.55' y2='611.78' />
                  <line x1='350.65' y1='196.6' x2='350.65' y2='611.82' />
                  <line x1='364.65' y1='196.6' x2='364.65' y2='611.82' />
                  <line x1='378.61' y1='196.6' x2='378.61' y2='611.82' />
                  <line x1='392.58' y1='196.6' x2='392.58' y2='611.82' />
                  <line x1='406.63' y1='196.6' x2='406.63' y2='611.82' />
                  <line x1='420.68' y1='196.6' x2='420.68' y2='611.82' />
                  <line x1='434.6' y1='196.6' x2='434.6' y2='611.82' />
                  <line x1='448.59' y1='196.6' x2='448.59' y2='611.82' />
                  <line x1='462.52' y1='196.6' x2='462.52' y2='611.82' />
                  <line x1='476.74' y1='196.56' x2='476.74' y2='611.78' />
                  <line x1='490.68' y1='196.56' x2='490.68' y2='611.78' />
                  <line x1='504.76' y1='196.6' x2='504.76' y2='611.82' />
                  <line x1='518.56' y1='196.56' x2='518.56' y2='611.78' />
                  <line x1='532.66' y1='196.6' x2='532.66' y2='611.82' />
                  <line x1='546.67' y1='196.6' x2='546.67' y2='611.82' />
                  <line x1='560.63' y1='196.6' x2='560.63' y2='611.82' />
                  <line x1='574.59' y1='196.6' x2='574.59' y2='611.82' />
                  <line x1='588.65' y1='196.6' x2='588.65' y2='611.82' />
                  <line x1='602.7' y1='196.6' x2='602.7' y2='611.82' />
                  <line x1='616.62' y1='196.6' x2='616.62' y2='611.82' />
                  <line x1='630.61' y1='196.6' x2='630.61' y2='611.82' />
                  <line x1='644.53' y1='196.6' x2='644.53' y2='611.82' />
                  <line x1='658.32' y1='196.56' x2='658.32' y2='611.78' />
                  <line x1='686.31' y1='210.33' x2='85.64' y2='210.33' />
                  <line x1='686.26' y1='224.28' x2='85.59' y2='224.28' />
                  <line x1='686.31' y1='237.94' x2='85.64' y2='237.94' />
                  <line x1='686.25' y1='251.9' x2='85.58' y2='251.9' />
                  <line x1='686.25' y1='265.77' x2='85.58' y2='265.77' />
                  <line x1='686.25' y1='279.6' x2='85.58' y2='279.6' />
                  <line x1='686.25' y1='293.42' x2='85.58' y2='293.42' />
                  <line x1='686.25' y1='307.34' x2='85.58' y2='307.34' />
                  <line x1='686.25' y1='321.25' x2='85.58' y2='321.25' />
                  <line x1='686.25' y1='335.04' x2='85.58' y2='335.04' />
                  <line x1='686.25' y1='348.89' x2='85.58' y2='348.89' />
                  <line x1='686.25' y1='362.68' x2='85.58' y2='362.68' />
                  <line x1='686.31' y1='376.33' x2='85.64' y2='376.33' />
                  <line x1='686.31' y1='390.13' x2='85.64' y2='390.13' />
                  <line x1='686.31' y1='403.93' x2='85.64' y2='403.93' />
                  <line x1='686.26' y1='417.88' x2='85.59' y2='417.88' />
                  <line x1='686.31' y1='431.54' x2='85.64' y2='431.54' />
                  <line x1='686.25' y1='445.51' x2='85.58' y2='445.51' />
                  <line x1='686.25' y1='459.37' x2='85.58' y2='459.37' />
                  <line x1='686.25' y1='473.2' x2='85.58' y2='473.2' />
                  <line x1='686.25' y1='487.02' x2='85.58' y2='487.02' />
                  <line x1='686.25' y1='500.94' x2='85.58' y2='500.94' />
                  <line x1='686.25' y1='514.85' x2='85.58' y2='514.85' />
                  <line x1='686.25' y1='528.64' x2='85.58' y2='528.64' />
                  <line x1='686.25' y1='542.49' x2='85.58' y2='542.49' />
                  <line x1='686.25' y1='556.28' x2='85.58' y2='556.28' />
                  <line x1='686.31' y1='570.36' x2='85.64' y2='570.36' />
                  <line x1='686.31' y1='584.16' x2='85.64' y2='584.16' />
                  <line x1='686.26' y1='598.11' x2='85.59' y2='598.11' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m679.09,206.83c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,218.83c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,230.53c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,260.25c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,247.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,271.96c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,301.68c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,288.86c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,313.39c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,343.11c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,330.29c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,354.82c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,384.54c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,371.72c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,396.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,425.97c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,413.15c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,437.67c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,467.4c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,454.57c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,479.1c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,508.82c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,496c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,520.53c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,550.25c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,537.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,561.96c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,591.68c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.09,578.86c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.17,602.43c.77.78,1.4,1.59,1.9,2.42l.14-.31c.27-.61.52-.91.75-.91.26,0,.55.17.87.5-.19.06-.36.19-.51.39-.15.2-.31.5-.49.9l-.12.28c-.13.31-.21.54-.25.67-.1-.09-.28-.23-.55-.42l-.32-.22c-.4-.85-.98-1.76-1.75-2.74-.76-.98-1.46-1.7-2.08-2.17l.15-.22c.74.42,1.49,1.02,2.26,1.8' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m679.09,233.41c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,244.62c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,206.75c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,217c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,261.03c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,272.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,288.65c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,299.86c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,316.26c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,327.48c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,343.88c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,355.09c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,371.5c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,382.71c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,399.12c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,410.33c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,426.74c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,437.95c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,454.36c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,465.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,481.98c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,493.19c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,509.6c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,520.81c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,537.22c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,548.43c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,564.84c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.25,576.05c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.09,592.46c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.17,602.43c.77.78,1.4,1.59,1.9,2.42l.14-.31c.27-.61.52-.91.75-.91.26,0,.55.17.87.5-.19.06-.36.19-.51.39-.15.2-.31.5-.49.9l-.12.28c-.13.31-.21.54-.25.67-.1-.09-.28-.23-.55-.42l-.32-.22c-.4-.85-.98-1.76-1.75-2.74-.76-.98-1.46-1.7-2.08-2.17l.15-.22c.74.42,1.49,1.02,2.26,1.8' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M93,206.83a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,206.83' />
                                    <path d='M93,218.83a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,218.83' />
                                    <path d='M93.89,230.53a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,260.25a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,260.25' />
                                    <path d='M93,247.43a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,247.43' />
                                    <path d='M93.89,272a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,93.89,272' />
                                    <path d='M93,301.68a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,301.68' />
                                    <path d='M93,288.86a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,288.86' />
                                    <path d='M93.89,313.39a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23A15.84,15.84,0,0,1,94.24,314a12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,343.11a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,343.11' />
                                    <path d='M93,330.29a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,330.29' />
                                    <path d='M93.89,354.82a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M93,384.54a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,384.54' />
                                    <path d='M93,371.72a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,371.72' />
                                    <path d='M93.89,396.24a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,426a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,426' />
                                    <path d='M93,413.15a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,413.15' />
                                    <path d='M93.89,437.67a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83A12.29,12.29,0,0,1,96.39,436l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,467.4a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,467.4' />
                                    <path d='M93,454.57a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,454.57' />
                                    <path d='M93.89,479.1a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,508.82a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,508.82' />
                                    <path d='M93,496a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,496' />
                                    <path d='M93.89,520.53a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,550.25a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,550.25' />
                                    <path d='M93,537.43a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,537.43' />
                                    <path d='M93.89,562a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,93.89,562' />
                                    <path d='M93,591.68a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,591.68' />
                                    <path d='M93,578.86a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,578.86' />
                                    <path d='M94,602.43a13,13,0,0,0-1.9,2.42l-.13-.3c-.28-.61-.52-.92-.75-.92a1.29,1.29,0,0,0-.86.5,1.06,1.06,0,0,1,.5.39,4.64,4.64,0,0,1,.49.9l.12.29a6.58,6.58,0,0,1,.25.67,5.1,5.1,0,0,1,.55-.41l.32-.22A14.54,14.54,0,0,1,94.31,603a11.84,11.84,0,0,1,2.08-2.17l-.16-.21a10.52,10.52,0,0,0-2.26,1.8' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M93,233.41a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,233.41' />
                                    <path d='M93.89,244.62a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82A12,12,0,0,1,96.39,243l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,206.75a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,206.75' />
                                    <path d='M93.89,217a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a5.81,5.81,0,0,1,.25.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,93.89,217' />
                                    <path d='M93,261a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,261' />
                                    <path d='M93.89,272.24a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,288.65a1.5,1.5,0,1,0-1.49-1.5,1.49,1.49,0,0,0,1.49,1.5' />
                                    <path d='M93.89,299.86a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a5.25,5.25,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M93,316.26a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,316.26' />
                                    <path d='M93.89,327.48a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M93,343.88a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,343.88' />
                                    <path d='M93.89,355.09a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a4.8,4.8,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M93,371.5A1.49,1.49,0,1,0,91.55,370,1.48,1.48,0,0,0,93,371.5' />
                                    <path d='M93.89,382.71a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,399.12a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,399.12' />
                                    <path d='M93.89,410.33a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,426.74a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,93,426.74' />
                                    <path d='M93.89,438a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,93.89,438' />
                                    <path d='M93,454.36a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,454.36' />
                                    <path d='M93.89,465.57a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,482a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,482' />
                                    <path d='M93.89,493.19a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,509.6a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,509.6' />
                                    <path d='M93.89,520.81a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,537.22a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,537.22' />
                                    <path d='M93.89,548.43a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69c.1-.09.29-.23.57-.43l.33-.22A15.54,15.54,0,0,1,94.24,549a12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,564.84a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,93,564.84' />
                                    <path d='M93.89,576.05a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3a5.26,5.26,0,0,1,.25.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M93,592.46A1.49,1.49,0,1,0,91.55,591,1.49,1.49,0,0,0,93,592.46' />
                                    <path d='M94,602.43a13,13,0,0,0-1.9,2.42l-.13-.3c-.28-.61-.52-.92-.75-.92a1.29,1.29,0,0,0-.86.5,1.06,1.06,0,0,1,.5.39,4.64,4.64,0,0,1,.49.9l.12.29a6.58,6.58,0,0,1,.25.67,5.1,5.1,0,0,1,.55-.41l.32-.22A14.54,14.54,0,0,1,94.31,603a11.84,11.84,0,0,1,2.08-2.17l-.16-.21a10.52,10.52,0,0,0-2.26,1.8' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 5mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_5mm_Completo'
                data-name='Margenes Cuadro 5mm Completo'
              >
                <g
                  id='Margenes_Cuadro_5mm_Completo-2'
                  data-name='Margenes Cuadro 5mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro 5mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_5mm_Datos'
                data-name='Margenes Cuadro 5mm Datos'
              >
                <g
                  id='Margenes_Cuadro_5mm_Datos-2'
                  data-name='Margenes Cuadro 5mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='414.26'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08v387.1a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08v387.1a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='414.26'
                      />
                      <path d='M685.92,197.56V610.82H86.23V197.56H685.92m1-1H85.23V611.82H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare5mmHorizontal
