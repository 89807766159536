import React from 'react'
import { Spin } from 'antd'
import style from './style.module.scss'

const ShowSpin = () => {
  return (
    <div className={`${style['container-div']}`}>
      <Spin tip='Loading' size='large'></Spin>
    </div>
  )
}

export default ShowSpin
