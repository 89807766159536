import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatUrlNames } from 'utilities/formatUrlNames'
import { handleErrors } from 'utilities/handleErrors'
import {
  getUtilsPaginated,
  updateUtilsStatus
} from 'services/utilsServices/utils'
import { Form } from 'antd'
import { RootState } from 'redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import {
  setCommentList,
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import {
  changeCommentStatus,
  creteNewComment,
  getBookCommentsById
} from 'services/commentsServices/comments'
import {
  changeBookStatus,
  getSchoolBooks,
  searchBook
} from 'services/books/books'
import { changeAction, setBooksSchoolAdmin } from 'redux/reducers/books'
import { updateStatusNotebook } from 'services/notebooks/putNotebookPackage'
import dayjs from 'dayjs'
import { setSupplyToCatModal } from 'redux/reducers/addSupplyModal'
import { getDetailedLog } from 'services/genericServices/genericServices'
import { handleRefresComent } from 'redux/reducers/notebooksSlice'

export function useComments() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const [loadingPost, setloadingPost] = useState(false)
  const [loadingDelete, setloadingDelete] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const { data } = useSelector((state: RootState) => state.comments)
  const location = useLocation()
  const { sendAction, dataCollege } = useSelector(
    (state: RootState) => state.books
  )

  useEffect(() => {
    return () => closeDrawComments()
  }, [])

  useEffect(() => {
    let typeOfComment = 'book'
    if (pathname.includes('utiles')) typeOfComment = 'supply'
    if (pathname.includes('cuadernos')) typeOfComment = 'notebook'
    if (Object.keys(data).length > 0) getComments(typeOfComment)
  }, [data])

  const closeDrawComments = () => {
    dispatch(setopenComments(false))
    dispatch(setCommentsObjectInformation({}))
  }

  const handleNavigate = () => {
    if (pathname.includes('libros')) {
      navigate(`${pathname}/editar`, { state: data })
      dispatch(setopenComments(false))
      return
    } else if (pathname.includes('utiles') && uid.role === 'Ezetera') {
      navigate(`/utiles?action=editar`, {
        state: { ...data, requestUtil: false }
      })
      return
    } else if (pathname.includes('cuadernos')) {
      navigate(`/cuadernos/preview/${data?.id}`)
    } else {
      navigate(`/utiles/util/${formatUrlNames(data.name)}`, {
        state: { ...data, requestUtil: false }
      })
      return
    }
  }

  const renderUpdateMessage = () => {
    let message = 'Este libro fue validado correctamente'
    if (pathname.includes('util')) {
      message = 'Este útil fue validado correctamente'
    }
    if (pathname.includes('cuadernos')) {
      message = 'Este cuaderno fue validado correctamente'
    }
    return message
  }

  const getComments = async (route: any) => {
    const id = data.id_supply_school || data.id

    if (!id) return

    setloadingPost(true)
    try {
      const result = await getBookCommentsById({ id, route })
      dispatch(setCommentList(result.data.comment))
      setloadingPost(false)
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const postNewComment = async (values: any) => {
    setloadingPost(true)
    const id: any = data.id_supply_school ?? data.id
    let typeOfComment = 'book'
    if (pathname.includes('utiles')) typeOfComment = 'supply'
    if (pathname.includes('cuadernos')) typeOfComment = 'notebook'
    const now: any = dayjs()
    const formData = new FormData()
    formData.append('content', values.comment)
    formData.append('comment_type', typeOfComment)
    formData.append('id_module', id as string)
    formData.append('date', now.$d)
    values.image?.fileList?.forEach((file: any) => {
      formData.append('pictures', file.originFileObj)
    })
    try {
      await creteNewComment(formData)
      await updateItem(3)
      /*if (pathname.includes('libros'))*/
      dispatch(changeAction(!sendAction))
      SuccessToast('Comentario enviado')
      form.resetFields()
      getComments(typeOfComment)
      //console.log(dataCollege) //getSchoolBooks(dataCollege)
      await getSchoolBooks(
        dataCollege.idSchool,
        dataCollege.idLevel,
        dataCollege.idGrade
      ).then((e) => {
        const saveResponse = e?.data?.books.map((book: any) => {
          return {
            ...book,
            idGrade: dataCollege.idGrade
          }
        })

        dispatch(setBooksSchoolAdmin(saveResponse))
      })
      if (pathname.includes('cuadernos')) dispatch(handleRefresComent())
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingPost(false)
    }
  }

  const updateComment = async (id: string) => {
    let typeOfComment = 'book'
    if (pathname.includes('utiles')) typeOfComment = 'supply'
    if (pathname.includes('cuadernos')) typeOfComment = 'notebook'
    setloadingPost(true)
    const body = { id }

    try {
      await changeCommentStatus(body)
      if (uid.role === 'Ezetera') getComments(typeOfComment)
      location?.pathname === '/libros' && dispatch(changeAction(!sendAction))
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingPost(false)
    }
  }

  const handleSearchSku = async (skuValue: string, status: number) => {
    try {
      const res = await getUtilsPaginated(1, skuValue, 4)
      const { supplies } = res.data?.data
      if (supplies.length === 0 && status === 4)
        dispatch(setSupplyToCatModal(true)) // esto habilita el modal de agregar util al catalogo de utiles, luego de ser validado por el admin
    } catch (error) {
      ErrorToast('No se encontró información para el ISBN.')
    }
  }

  const updateItem = async (status: number) => {
    setloadingPost(true)
    const id = data.id_supply_school ?? data.id
    const body = { status, id_book: data.id_book }
    const body2 = {
      id: [id],
      status
    }
    const button = document.getElementById('save-books-comment-info')
    try {
      if (pathname.includes('libros')) await changeBookStatus(id, body)
      if (pathname.includes('util')) {
        await updateUtilsStatus({ data: body2 })
        await handleSearchSku(data.sku, status)
      }
      if (pathname.includes('cuadernos'))
        await updateStatusNotebook({ data: body2 })

      if (status === 4) {
        SuccessToast(renderUpdateMessage())
        button?.click()
        setTimeout(() => {
          !pathname.includes('util') && closeDrawComments() // esto se coloco asi, porq el sidebar se cerrara en utiles luego de el admin decida si agregar o no el producto validado al catalogo de utiles, en el moda llamado addSupToCatModal
        }, 500)
      }
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingPost(false)
    }
  }

  const donwloadLogs = async () => {
    const id = data.id_supply_school ?? data.id
    let typeOfComment = 'book'
    if (pathname.includes('utiles')) typeOfComment = 'supply'
    if (pathname.includes('cuadernos')) typeOfComment = 'notebook'
    if (pathname.includes('uniformes')) typeOfComment = 'uniform'
    setLoadingDownload(true)
    try {
      const res: any = await getDetailedLog({ type: typeOfComment, id })
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()

      /* const response = await fetch(url)
      const file = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(file)
      link.download = new Date().getTime()
      link.click() */
    } catch (error) {
      ErrorToast('No hay datos')
    }
    setLoadingDownload(false)
  }

  const onSubmit = (values: any) => {
    form.resetFields()
  }

  return {
    form,
    loadingPost,
    loadingDelete,
    loadingDownload,
    updateComment,
    onSubmit,
    postNewComment,
    handleNavigate,
    closeDrawComments,
    updateItem,
    navigate,
    donwloadLogs
  }
}
