import React from 'react'

const LabelChaleco = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Etiqueta_lateral' data-name='Etiqueta lateral'>
        <g id='Etiqueta_lateral-2' data-name='Etiqueta lateral'>
          <path d='m561.41,614.24c-1.56.31-2.1.73-1.63,2.5,1.3,4.84,2.44,9.72,3.51,14.61.34,1.56.91,1.84,2.36,1.53,9.33-1.97,18.68-3.85,28.02-5.76l-4.43-18.54-.4.08c-9.14,1.87-18.28,3.77-27.43,5.58Z' />
          <path d='m578.17,625.85c.34-.26.68-.54,1.04-.78,1.14-.71,1.74-1.83,2.35-2.95.36-.67.22-1.2-.46-1.68-2.13-1.49-4.54-1.9-7.04-2.04-.09,0-.18-.02-.27-.06.99-.24,1.97.05,2.95.06.29,0,.69.21.83-.13.12-.3-.25-.51-.45-.73-.32-.35-.67-.67-.96-1.07,1.65,1.39,3.53,2.23,5.63,2.68.92.2,1.28-.08,1.46-.89.3-1.3.61-2.58-.09-3.91,1.31,1.37,2.86,2.59,2.57,4.78.48-.1.89-.17,1.29-.27.42-.09.62.19.81.47.3.41-.22.36-.36.51-.76.86-1.89,1.36-2.3,2.6-.27.83-1.18,1.2-1.99,1.58-1.6.75-3.28,1.33-5.03,1.81Z' />
        </g>
      </g>
    </svg>
  )
}

export default LabelChaleco
