import { Col, Row } from 'antd'
import H from 'components/texts/H/H'
import { memo } from 'react'
import formatNumber from 'utilities/numberFormater'
import ArticleSells from '../ArticleSells/ArticleSells'
import Sells from '../Sells/Sells'
import TableSells from '../TableSells/TableSells'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

const maxHeight = 400

const GraphicSection = memo(() => {
  const { totalSells } = useSelector((state: RootState) => state.sells)
  const getTotals = (totalArray: any) => {
    const ventas = [...totalArray]
    let suma = ventas.reduce((acc, numero) => acc + parseFloat(numero.Total), 0)
    return suma
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <H variation='4'>Ventas por grupo de articulo </H>
      </Col>
      <Col span={12}>
        <Row justify={'end'}>
          <H variation='4'>
            Ventas Totales: $ {formatNumber(getTotals(totalSells))}{' '}
          </H>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <ArticleSells maxHeight={maxHeight} />
        </Row>
      </Col>
      <Col span={24}>
        <TableSells />
      </Col>
      <Col span={24}>
        <Sells maxHeight={maxHeight} />
      </Col>
    </Row>
  )
})

export default GraphicSection
