import React, { useState, Fragment } from 'react'
import style from './selectoption.module.scss'

import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'

const SelectOption = ({
  label = 'vacio',
  picture,
  onClick,
  toolTipLabel = 'vacio',
  selected = false,
  background = 'transparent',
  help = false,
  topTitle
}: {
  label?: string
  toolTipLabel?: string
  picture?: string | null
  onClick?: () => void
  selected?: boolean
  background?: string
  help?: boolean
  topTitle?: string
}) => {
  const [show, setShow] = useState(false)
  return (
    <div className={`${style['div-container']}`}>
      {topTitle && <p className={`${style['optional-title']}`}>{topTitle}</p>}
      <div
        className={`${style['div-hover']} 
        ${selected ? style['selected'] : style['div-border']}`}
      >
        <div
          className={`${style['selected-div']}`}
          onClick={() => onClick && onClick()}
          style={{ background: background }}
        >
          {picture && (
            <img className={`${style['img-option']}`} src={picture} />
          )}
        </div>
      </div>
      <div className={`${style['P-title']}`}>
        <div className={`${!label.includes(' ') && style['no-wrap']}`}>
          <p className={`${style['title-label']}`}>
            {(() => {
              const sentence = label.split('_')
              return (
                <Fragment>
                  {sentence.length > 1 ? (
                    <Fragment>
                      {sentence[0]}
                      <span className={`${style['color-transparent']}`}>_</span>
                      {sentence[1]}
                    </Fragment>
                  ) : (
                    <Fragment>{label}</Fragment>
                  )}
                </Fragment>
              )
            })()}
          </p>
        </div>
        {help && (
          <div className={`${style['div-pop-father']}`}>
            <QuestionMarkCircleIcon
              onMouseOver={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              className={`${style['question-icon']}`}
            />
            {show && (
              <Fragment>
                <div className={`${style['div-pop-tootltip']}`}>
                  <div className={`${style['tooltip']}`}>
                    <div className={`${style['pop-square']}`}></div>
                    <div className={`${style['pop-lorem']}`}>
                      <p className={`${style['tooltip-label']}`}>
                        {toolTipLabel}
                      </p>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectOption
