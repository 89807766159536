import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

import PantsLateralSvg from 'assets/img/uniforms/sportOutfit/pantsDeportivo/lateral/PantsLateralSvg'
import ShadowsPantsLateral from 'assets/img/uniforms/sportOutfit/pantsDeportivo/lateral/ShadowsPantsLateral'
import LabelLateralPants from 'assets/img/uniforms/sportOutfit/pantsDeportivo/lateral/LabelLateralPants'
import { handleClotheName } from 'redux/reducers/uniformsSlice'

const PantsDeportivosLateralEditable = () => {
  const { poloValues } = useSelector((state: RootState) => state.uniforms)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Pants deportivos lateral',
        description: 'Descripcion de los pants deportivos'
      })
    )
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div className={`${style[`logo-position-${'left'}`]}`}></div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <PantsLateralSvg className={`${style['svg']}`} />
          </div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <ShadowsPantsLateral className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <LabelLateralPants className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default PantsDeportivosLateralEditable
