import React from 'react'

const Blusa_3_4Svg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Blusa_mitad_izquierda" data-name="Blusa mitad izquierda"   d="m381.05,131.71l.31,622.61s-32.73,1.42-57.21-5.65c-31.92-9.22-45.39-24.48-77.31-36.87-29.29-11.37-61.71-14.18-61.71-14.18,0,0,12.06-134.76,36.89-229.1,11.4-43.34-9.18-177.98-9.18-177.98,0,0,5.23-58.8,4.91-79.42-.42-26.78-17.74-110.04-17.74-110.04l105.19-35.55s17.72,49.5,31.14,66.91c9.6,12.44,26.84,29.01,26.84,29.01l17.89-29.74Z"/>
      <path id="Blusa_mitad_derecha" data-name="Blusa mitad derecha"   d="m411.82,127.63s6.6,13.15,9.41,17.49c3.4,5.24,12.86,16.33,12.86,16.33,0,0,30.5-29.08,39.01-50.36,2.89-7.24,14.18-43.98,14.18-43.98l102.85,32.62c-13.71,97.38-12.09,96.8-12.06,114.91.03,21.4,10.64,82.98,10.64,82.98,0,0-28.06,138.9-17.73,175.16,23.4,82.24,37.59,225.53,37.59,225.53,0,0-38.47,4.14-52.49,9.22-14.35,5.2-55.32,29.79-55.32,29.79,0,0,6.07-34.94,7.8-47.52,1.99-14.55,3.56-57.35,3.56-57.35l-96.33-.22-3.97-504.6Z"/>
      <path   d="m184.42,697.62s41.97,10.96,59.58,24.09c78.03,58.16,117.52,50.21,151.79,49.65,36.22-.59,91.5,4.25,143.99-39.01,18.19-15,68.8-34.04,68.8-34.04"/>
      <polyline   points="500.77 737.32 494.38 738.75 410.13 655.61 381.88 631.07 418.96 631.98"/>
      <path id="Pinza"   d="m292.94,371.35s7.31,84.58,7.81,112.07c.56,31.21-17.5,245.95-17.5,245.95"/>
      <path id="Pinza-2" data-name="Pinza"   d="m501.48,371.35s-2.81,98.41-2.13,126.25c.8,33.08,26.64,225.13,26.64,225.13"/>
      <line id="Pinza_busto" data-name="Pinza busto"   x1="219.24" y1="339.8" x2="289.39" y2="309.74"/>
      <line id="Pinza_busto-2" data-name="Pinza busto"   x1="501.78" y1="311.78" x2="581.13" y2="339.03"/>
      <polyline   points="416.4 656.76 415.37 754.03 380.2 754.32"/>
      <line id="Hombro_izquierda" data-name="Hombro izquierda"   x1="205.95" y1="132.7" x2="317.62" y2="94.7"/>
      <line id="Hombro_derecha" data-name="Hombro derecha"   x1="477.5" y1="97.02" x2="586.86" y2="131.04"/>
      <path   d="m383.1,127.4s11.24,3.07,15.17,3.07c4.6,0,13.92-2.26,13.92-2.26"/>
      <g id="Manga_izquierda" data-name="Manga izquierda">
        <path id="Manga_izquierda-2" data-name="Manga izquierda"   d="m200,101.08s-41.99.13-72.31,86.03c-12.7,35.99-35.57,128.37-51.59,194.33-6.59,27.17-5.14,78.64-5.14,78.64l94.38,28.65s7.11-35.27,9.87-54.38c12.01-83.18,36.74-139.76,36.74-139.76,0,0,7.57-68.32,5.75-92.32-1.65-21.78-17.71-101.19-17.71-101.19Z"/>
        <polygon id="Puño_izquierda" data-name="Puño izquierda"   points="71.04 459.15 68.83 488.47 160.32 515.21 165.42 487.8 71.04 459.15"/>
      </g>
      <polyline id="Doblez"   points="415.79 632.22 416.4 656.76 499.34 655.76"/>
      <g id="Cuello">
        <path id="Cuello-2" data-name="Cuello"   d="m460.99,27.97s-8.92,29.73-18.83,43.28c-11.18,15.28-43.43,35.13-43.43,35.13l14.1,22.59"/>
        <path id="Cuello-3" data-name="Cuello"   d="m332.48,29.29s18.03,40.43,25.44,48.89c16.19,18.5,39.31,28.41,39.31,28.41l-16.52,25.44"/>
        <path id="Cuello-4" data-name="Cuello"   d="m305.39,67.29l27.09-37.99s47.9,3.48,64.42,3.3c15.61-.16,64.09-4.62,64.09-4.62l27.08,39.64"/>
      </g>
      <line id="Linea_interior_espalda" data-name="Linea interior espalda"   x1="352.6" y1="67.88" x2="443.59" y2="68.08"/>
      <g id="Botones">
        <g>
          <polyline   points="396.46 186.55 398.92 186.72 396.28 187.61 399.21 187.82 396.76 188.75 397.93 189.38 396.99 189.72 397.56 190.03 397.04 190.58 397.5 190.96 397.24 191.63"/>
          <path   d="m398.4,174c3.35.81,5.4,4.19,4.59,7.55-.82,3.35-4.2,5.4-7.55,4.58-3.36-.82-5.4-4.2-4.59-7.55.82-3.34,4.2-5.39,7.55-4.58Z"/>
          <g>
            <path   d="m398.39,176.18c.07.09.17.18.31.23.2.08.35.02.41-.14.05-.14,0-.25-.16-.41-.21-.18-.31-.37-.23-.58.09-.24.35-.34.64-.22.15.06.25.14.3.2l-.11.14s-.12-.13-.26-.18c-.2-.08-.33.02-.37.12-.05.14,0,.25.18.41.21.2.29.37.21.59-.09.23-.34.37-.69.23-.14-.06-.28-.16-.34-.24l.11-.14Z"/>
            <path   d="m400.39,176.68l-.51-.36-.33.48.57.4-.1.14-.73-.51.93-1.33.71.49-.1.14-.54-.38-.29.42.51.36-.1.14Z"/>
            <path   d="m401.49,178.06c.18-.14.39-.31.55-.43h0c-.19.06-.39.13-.62.2l-.8.24-.09-.13.47-.66c.14-.2.27-.37.4-.52h0c-.16.11-.39.26-.6.39l-.59.38-.11-.16,1.37-.87.16.21-.47.68c-.12.17-.23.32-.35.45h0c.16-.06.33-.12.54-.18l.8-.23.15.21-1.25,1.04-.12-.16.55-.45Z"/>
            <path   d="m402.61,178.4c.04.09.1.21.14.37.06.2.05.35,0,.47-.05.11-.15.19-.29.23-.14.04-.26.03-.37-.02-.14-.07-.25-.22-.31-.42-.02-.06-.03-.11-.03-.16l-.62.18-.06-.2,1.53-.45Zm-.69.42s.01.1.03.17c.07.24.23.35.44.29.21-.06.26-.23.2-.45-.03-.09-.05-.15-.07-.18l-.6.18Z"/>
            <path   d="m402.14,180.74v-.62s-.6.01-.6.01v.69s-.16,0-.16,0l-.02-.9,1.62-.03.02.86h-.18s-.01-.65-.01-.65h-.51s.01.63.01.63h-.17Z"/>
            <path   d="m402.9,181.36c0,.11-.03.25-.06.39-.05.21-.13.34-.24.41-.09.06-.21.08-.33.05-.21-.05-.31-.22-.33-.39h0c-.07.11-.2.15-.37.14-.23,0-.39,0-.46,0l.05-.21c.05-.01.19-.01.4,0,.23,0,.33-.05.38-.23l.05-.19-.68-.17.05-.2,1.54.39Zm-.76.02l-.05.2c-.05.21.03.38.21.42.2.05.33-.07.38-.28.02-.1.03-.17.03-.2l-.57-.15Z"/>
            <path   d="m400.07,184.07l-.14-.52.18-.12.44,1.65-.21.14-1.34-1.05.18-.12.42.33.47-.31Zm-.35.42l.38.3c.09.07.16.14.23.2h0c-.03-.1-.07-.19-.09-.29l-.13-.48-.4.26Z"/>
            <path   d="m399.36,185.73l-.2.06-.44-1.37-.65.21-.05-.17.85-.27.49,1.54Z"/>
            <path   d="m397.95,185.9l.48-.05.02.18-1.17.12-.02-.18.48-.05-.15-1.43.21-.02.15,1.43Z"/>
            <path   d="m396.69,186.09l.13-1.61.21.02-.13,1.61-.21-.02Z"/>
            <path   d="m395.88,185.96l.28-.91c.11-.35,0-.54-.2-.6-.23-.07-.42.04-.52.38l-.28.91-.2-.06.28-.9c.15-.48.45-.59.78-.49.31.1.49.35.34.83l-.28.91-.2-.06Z"/>
            <path   d="m395.14,184.25c-.04-.1-.13-.21-.25-.29-.17-.12-.34-.09-.43.04-.08.13-.06.24.07.43.16.22.22.42.09.61-.14.21-.41.25-.67.08-.14-.09-.21-.19-.25-.26l.14-.11c.03.05.09.15.21.23.18.12.32.06.38-.03.08-.13.04-.24-.09-.44-.16-.24-.2-.42-.07-.62.14-.21.41-.28.72-.08.13.09.24.22.28.31l-.14.12Z"/>
          </g>
          <path   d="m397.33,177.71c1.55.24,2.64,1.63,2.4,3.08-.23,1.47-1.68,2.45-3.22,2.2-1.55-.24-2.64-1.63-2.4-3.08.22-1.46,1.67-2.45,3.21-2.21Z"/>
          <path   d="m395.91,180.84c.42.07.72.47.65.91-.06.41-.47.71-.9.64-.43-.06-.72-.46-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m395.9,178.52c.43.06.72.46.65.88-.06.43-.46.72-.89.65-.43-.07-.72-.46-.65-.88.06-.43.46-.73.88-.65Z"/>
          <path   d="m397.93,180.84c.43.07.72.47.65.89-.06.43-.46.72-.89.65-.42-.07-.72-.47-.65-.89.06-.42.47-.71.89-.65Z"/>
          <path   d="m398.12,178.51c.42.07.72.47.65.89-.06.43-.47.72-.89.65-.42-.07-.71-.46-.64-.89.06-.42.46-.71.89-.65Z"/>
        </g>
        <g>
          <polyline   points="397.54 298.78 399.99 298.95 397.35 299.84 400.28 300.06 397.83 300.98 399 301.61 398.06 301.95 398.64 302.26 398.11 302.81 398.57 303.19 398.31 303.86"/>
          <path   d="m399.47,286.23c3.36.81,5.4,4.19,4.59,7.55-.82,3.34-4.2,5.4-7.55,4.58-3.35-.82-5.4-4.2-4.59-7.55.83-3.34,4.2-5.39,7.55-4.58Z"/>
          <g>
            <path   d="m399.46,288.48c.06.09.17.18.3.23.19.07.35.01.4-.14.05-.14,0-.25-.16-.4-.21-.18-.31-.36-.22-.57.09-.23.34-.33.63-.22.15.06.25.13.3.2l-.11.14s-.12-.13-.25-.18c-.2-.08-.32.01-.36.12-.05.14,0,.24.18.4.21.19.29.36.21.58-.09.23-.33.36-.68.23-.14-.05-.28-.16-.34-.24l.11-.14Z"/>
            <path   d="m401.43,288.97l-.5-.35-.33.47.56.39-.1.14-.72-.51.91-1.31.69.49-.1.14-.53-.37-.29.41.5.35-.1.14Z"/>
            <path   d="m402.52,290.33c.17-.14.38-.31.54-.43h0c-.18.06-.38.13-.61.19l-.79.23-.09-.12.46-.65c.14-.19.27-.36.39-.51h0c-.16.11-.38.26-.59.39l-.58.37-.11-.16,1.35-.85.15.21-.47.67c-.12.17-.23.32-.34.45h0c.15-.06.33-.12.53-.18l.79-.23.15.21-1.23,1.02-.12-.16.54-.45Z"/>
            <path   d="m403.62,290.66c.04.09.09.21.14.36.06.19.05.35,0,.46-.05.1-.15.18-.28.22-.14.04-.26.03-.36-.02-.14-.07-.25-.22-.3-.41-.02-.06-.03-.11-.03-.16l-.61.18-.06-.19,1.51-.44Zm-.68.41s.01.1.03.17c.07.23.23.34.44.28.2-.06.26-.23.2-.44-.03-.08-.05-.15-.07-.18l-.59.17Z"/>
            <path   d="m403.15,292.97v-.61s-.59.01-.59.01v.68s-.16,0-.16,0l-.02-.88,1.59-.03.02.85h-.17s-.01-.64-.01-.64h-.5s.01.62.01.62h-.17Z"/>
            <path   d="m403.91,293.57c0,.1-.03.25-.06.38-.05.21-.12.34-.24.41-.09.06-.2.08-.32.05-.2-.05-.31-.21-.32-.38h0c-.07.11-.2.15-.37.14-.23,0-.38,0-.45.01l.05-.2s.19-.01.39,0c.22,0,.32-.05.38-.23l.05-.18-.67-.17.05-.2,1.52.38Zm-.75.02l-.05.2c-.05.21.03.37.21.42.2.05.32-.07.38-.28.02-.09.03-.16.03-.2l-.56-.14Z"/>
            <path   d="m401.12,296.25l-.14-.51.18-.11.44,1.62-.2.13-1.33-1.04.18-.12.42.33.47-.31Zm-.34.42l.38.3c.09.07.16.13.23.2h0c-.03-.09-.07-.19-.09-.29l-.13-.47-.39.26Z"/>
            <path   d="m400.41,297.88l-.19.06-.43-1.35-.64.2-.05-.16.83-.27.48,1.51Z"/>
            <path   d="m399.03,298.04l.47-.05.02.17-1.15.12-.02-.17.48-.05-.15-1.41.2-.02.15,1.41Z"/>
            <path   d="m397.79,298.23l.13-1.59.2.02-.13,1.59-.2-.02Z"/>
            <path   d="m397.01,298.12l.28-.9c.11-.34,0-.53-.2-.59-.22-.07-.41.04-.51.37l-.28.9-.2-.06.28-.89c.14-.47.45-.58.77-.48.31.1.48.35.34.82l-.28.9-.2-.06Z"/>
            <path   d="m396.26,296.42c-.04-.1-.13-.21-.25-.29-.17-.11-.34-.09-.42.05-.08.12-.06.24.07.43.16.22.22.42.1.6-.14.21-.41.25-.66.08-.13-.09-.21-.18-.25-.26l.14-.11c.03.05.09.15.21.23.18.12.32.05.38-.04.08-.12.04-.24-.09-.43-.16-.23-.21-.42-.08-.61.13-.21.4-.28.71-.08.13.08.24.21.28.31l-.14.12Z"/>
          </g>
          <path   d="m398.41,289.94c1.55.24,2.63,1.63,2.4,3.08-.23,1.46-1.67,2.45-3.22,2.2-1.55-.24-2.63-1.63-2.4-3.08.23-1.46,1.67-2.45,3.22-2.21Z"/>
          <path   d="m396.98,293.07c.42.07.71.47.65.9-.06.41-.47.71-.9.64-.42-.06-.71-.47-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m396.97,290.75c.43.06.72.46.66.88-.07.43-.47.72-.89.65-.43-.07-.72-.46-.65-.88.06-.43.47-.73.89-.65Z"/>
          <path   d="m399,293.07c.43.06.72.47.65.89-.06.42-.45.72-.89.65-.42-.07-.71-.47-.65-.89.06-.43.47-.72.89-.65Z"/>
          <path   d="m399.19,290.74c.42.07.72.47.65.89-.06.43-.46.72-.89.65-.43-.07-.72-.46-.65-.89.06-.42.46-.71.89-.65Z"/>
        </g>
        <g>
          <polyline   points="397.84 398.74 400.29 398.9 397.65 399.79 400.58 400.01 398.13 400.93 399.29 401.56 398.37 401.91 398.94 402.21 398.41 402.76 398.87 403.14 398.61 403.81"/>
          <path   d="m399.78,386.18c3.35.81,5.4,4.19,4.58,7.55-.82,3.34-4.2,5.4-7.54,4.58-3.36-.82-5.4-4.2-4.59-7.55.82-3.34,4.2-5.39,7.55-4.58Z"/>
          <g>
            <path   d="m399.76,388.72c.06.08.16.17.28.21.18.07.32.01.38-.13.05-.13,0-.23-.15-.38-.19-.17-.29-.34-.21-.53.08-.22.32-.31.59-.21.14.05.23.13.28.19l-.11.13s-.11-.12-.23-.17c-.19-.07-.3.01-.34.11-.05.13,0,.23.17.37.2.18.27.34.19.54-.08.21-.31.34-.63.22-.13-.05-.26-.15-.32-.22l.1-.13Z"/>
            <path   d="m401.6,389.17l-.47-.33-.31.44.52.36-.09.13-.68-.47.85-1.22.65.45-.09.13-.49-.35-.27.39.47.33-.09.13Z"/>
            <path   d="m402.61,390.44c.16-.13.36-.29.51-.4h0c-.17.06-.36.12-.57.18l-.74.22-.08-.12.43-.61c.13-.18.25-.34.37-.48h0c-.15.1-.36.24-.55.36l-.54.35-.11-.15,1.26-.8.14.2-.44.63c-.11.16-.22.29-.32.42h0c.14-.05.31-.11.5-.17l.74-.21.14.19-1.15.95-.11-.15.51-.42Z"/>
            <path   d="m403.65,390.76c.04.08.09.19.13.34.05.18.05.32,0,.43-.05.1-.14.17-.26.21-.13.04-.24.03-.34-.02-.13-.07-.23-.21-.28-.38-.02-.05-.03-.1-.03-.15l-.57.17-.05-.18,1.41-.41Zm-.64.38s.01.09.03.15c.06.22.21.32.41.26.19-.06.24-.21.18-.41-.02-.08-.05-.14-.07-.17l-.56.16Z"/>
            <path   d="m403.21,392.91v-.57s-.55.01-.55.01v.64s-.15,0-.15,0v-.83s1.48-.03,1.48-.03l.02.79h-.16s-.01-.6-.01-.6h-.47s.01.58.01.58h-.16Z"/>
            <path   d="m403.92,393.48c0,.1-.03.23-.06.36-.05.2-.12.31-.22.38-.08.06-.19.07-.3.04-.19-.05-.29-.2-.3-.36h0c-.07.1-.18.14-.34.13-.21,0-.36,0-.42.01l.05-.19s.18-.01.37,0c.21,0,.3-.05.35-.21l.04-.17-.63-.16.05-.18,1.42.35Zm-.7.02l-.05.19c-.05.2.03.35.19.39.19.05.3-.07.35-.26.02-.09.03-.15.03-.19l-.53-.13Z"/>
            <path   d="m401.31,395.97l-.13-.48.16-.11.41,1.52-.19.13-1.24-.97.17-.11.39.31.43-.29Zm-.32.39l.36.28c.08.06.15.12.21.19h0c-.03-.09-.06-.18-.09-.27l-.12-.44-.37.24Z"/>
            <path   d="m400.65,397.5l-.18.06-.4-1.26-.6.19-.05-.15.78-.25.45,1.42Z"/>
            <path   d="m399.36,397.66l.44-.05.02.16-1.08.11-.02-.16.45-.05-.14-1.32.19-.02.14,1.32Z"/>
            <path   d="m398.2,397.83l.12-1.48.19.02-.12,1.48h-.19Z"/>
            <path   d="m397.45,397.72l.26-.84c.1-.32,0-.5-.19-.55-.21-.06-.38.03-.48.35l-.26.84-.18-.06.26-.83c.14-.44.42-.55.72-.45.29.09.45.32.32.76l-.26.84-.18-.06Z"/>
            <path   d="m396.77,396.14c-.04-.09-.12-.2-.23-.27-.16-.11-.31-.08-.4.04-.08.12-.05.23.07.4.15.2.21.39.09.57-.13.19-.38.23-.62.08-.13-.08-.2-.17-.23-.24l.13-.1s.08.14.19.21c.17.11.3.05.35-.03.08-.12.04-.22-.08-.4-.15-.22-.19-.39-.07-.57.13-.19.38-.27.67-.08.12.08.22.2.26.29l-.13.11Z"/>
          </g>
          <path   d="m398.71,389.89c1.55.24,2.63,1.63,2.4,3.08-.23,1.47-1.67,2.45-3.22,2.2-1.55-.24-2.63-1.63-2.4-3.08.23-1.46,1.67-2.45,3.22-2.21Z"/>
          <path   d="m397.28,393.02c.42.07.71.47.65.9-.06.41-.47.71-.9.64-.42-.06-.71-.47-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m397.27,390.7c.43.06.72.46.66.88-.06.43-.46.72-.89.65-.43-.07-.72-.46-.65-.88.06-.43.46-.73.88-.65Z"/>
          <path   d="m399.3,393.02c.43.06.72.47.65.89-.07.43-.46.72-.9.65-.41-.07-.71-.47-.65-.89.06-.42.47-.72.89-.65Z"/>
          <path   d="m399.49,390.69c.42.07.72.47.65.89-.07.43-.47.72-.89.65-.42-.07-.71-.46-.64-.89.06-.42.46-.71.88-.65Z"/>
        </g>
        <g>
          <polyline   points="398.3 520.87 400.75 521.03 398.11 521.93 401.05 522.14 398.59 523.07 399.76 523.7 398.83 524.04 399.4 524.35 398.87 524.9 399.33 525.28 399.07 525.95"/>
          <path   d="m400.24,508.32c3.36.81,5.4,4.19,4.59,7.55-.82,3.34-4.2,5.4-7.55,4.58-3.35-.82-5.4-4.2-4.59-7.55.82-3.34,4.2-5.39,7.55-4.58Z"/>
          <g>
            <path   d="m399.95,510.27c.06.09.17.18.3.23.19.07.34.01.4-.14.05-.14,0-.25-.16-.4-.2-.18-.3-.36-.22-.57.09-.23.34-.33.62-.22.15.06.24.13.29.2l-.11.13s-.12-.13-.25-.18c-.2-.08-.32.01-.36.11-.05.14,0,.24.18.4.21.19.29.36.2.58-.09.23-.33.36-.67.23-.14-.05-.28-.15-.34-.24l.11-.14Z"/>
            <path   d="m401.9,510.76l-.5-.35-.33.47.55.39-.1.14-.72-.5.91-1.3.69.48-.1.14-.52-.37-.29.41.49.35-.1.14Z"/>
            <path   d="m402.97,512.11c.17-.14.38-.31.54-.42h0c-.18.06-.38.13-.6.19l-.78.23-.09-.12.46-.65c.14-.19.26-.36.39-.51h0c-.16.11-.38.26-.58.38l-.57.37-.11-.15,1.34-.85.15.21-.46.67c-.12.17-.23.31-.34.44h0c.15-.06.32-.11.53-.18l.78-.23.15.21-1.22,1.01-.11-.16.54-.44Z"/>
            <path   d="m404.07,512.44c.04.09.09.21.14.36.06.19.05.34,0,.46-.05.1-.15.18-.28.22-.14.04-.26.03-.36-.02-.14-.07-.25-.22-.3-.41-.02-.06-.03-.11-.03-.16l-.61.18-.06-.19,1.5-.44Zm-.68.41s.01.1.03.16c.07.23.22.34.43.28.2-.06.26-.23.19-.44-.02-.08-.05-.15-.07-.18l-.59.17Z"/>
            <path   d="m403.6,514.73v-.6s-.58.01-.58.01v.67s-.16,0-.16,0l-.02-.88,1.58-.03.02.84h-.17s-.01-.64-.01-.64h-.5s.01.61.01.61h-.17Z"/>
            <path   d="m404.35,515.33c0,.1-.03.25-.06.38-.05.21-.13.33-.24.4-.09.06-.2.07-.32.04-.2-.05-.3-.21-.32-.38h0c-.07.1-.2.15-.36.14-.23,0-.38,0-.45,0l.05-.2s.19-.01.39,0c.22,0,.32-.05.37-.23l.05-.18-.66-.17.05-.19,1.51.38Zm-.75.02l-.05.2c-.05.21.03.37.2.41.2.05.32-.07.37-.27.02-.09.03-.16.03-.2l-.56-.14Z"/>
            <path   d="m401.59,517.97l-.14-.5.17-.11.43,1.61-.2.13-1.32-1.03.18-.12.41.32.46-.3Zm-.34.41l.38.3c.09.07.16.13.23.2h0c-.03-.09-.06-.19-.09-.29l-.13-.47-.39.26Z"/>
            <path   d="m400.91,519.58l-.19.06-.43-1.34-.63.2-.05-.16.83-.26.48,1.5Z"/>
            <path   d="m399.52,519.76l.47-.05.02.17-1.14.12-.02-.17.47-.05-.15-1.4.2-.02.15,1.4Z"/>
            <path   d="m398.29,519.95l.12-1.58.2.02-.12,1.58-.2-.02Z"/>
            <path   d="m397.51,519.82l.28-.89c.11-.34,0-.53-.2-.59-.22-.07-.41.03-.51.37l-.28.89-.19-.06.27-.88c.14-.46.44-.58.76-.48.3.09.48.34.33.81l-.28.89-.19-.06Z"/>
            <path   d="m396.77,518.15c-.04-.1-.13-.21-.24-.28-.17-.11-.33-.09-.42.05-.08.12-.06.24.07.42.16.22.22.42.1.6-.14.21-.4.25-.66.08-.13-.09-.21-.18-.24-.25l.14-.11c.03.05.09.15.2.23.18.12.32.05.37-.04.08-.12.04-.24-.09-.43-.16-.23-.2-.41-.07-.61.13-.2.4-.28.7-.08.13.08.24.21.28.3l-.14.11Z"/>
          </g>
          <path   d="m399.17,512.03c1.55.24,2.63,1.63,2.4,3.08-.23,1.46-1.67,2.45-3.22,2.2-1.55-.24-2.63-1.63-2.4-3.08.23-1.46,1.67-2.45,3.22-2.21Z"/>
          <path   d="m397.74,515.16c.41.07.71.47.65.9-.06.41-.47.71-.9.64-.43-.06-.71-.47-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m397.73,512.83c.43.06.72.46.65.88-.06.43-.46.72-.88.66-.43-.07-.72-.46-.65-.88.06-.43.47-.73.89-.66Z"/>
          <path   d="m399.76,515.16c.43.06.72.47.65.89-.06.43-.46.72-.89.65-.42-.07-.71-.47-.65-.89.06-.42.47-.72.89-.66Z"/>
          <path   d="m399.95,512.83c.43.07.72.47.65.89-.06.43-.46.72-.89.65-.42-.07-.71-.46-.64-.89.06-.42.46-.71.89-.65Z"/>
        </g>
        <g>
          <polyline   points="398.6 624.27 401.05 624.43 398.41 625.32 401.34 625.54 398.89 626.47 400.06 627.09 399.12 627.44 399.7 627.75 399.17 628.29 399.64 628.68 399.37 629.35"/>
          <path   d="m400.53,611.72c3.36.81,5.4,4.19,4.59,7.55-.82,3.34-4.2,5.4-7.55,4.58-3.35-.82-5.4-4.2-4.58-7.55.82-3.34,4.2-5.39,7.54-4.58Z"/>
          <g>
            <path   d="m400.35,613.46c.07.09.18.18.31.23.2.08.35.02.41-.14.05-.14,0-.25-.17-.41-.21-.18-.31-.37-.23-.58.09-.24.35-.34.64-.23.15.06.25.14.3.2l-.12.14s-.12-.13-.26-.18c-.2-.08-.33.02-.37.12-.05.14.01.25.19.41.22.2.3.37.21.6-.09.24-.34.37-.69.24-.14-.06-.29-.16-.35-.25l.11-.15Z"/>
            <path   d="m402.37,613.96l-.51-.36-.34.48.57.4-.1.15-.74-.52.94-1.34.71.5-.1.15-.54-.38-.3.42.51.36-.1.14Z"/>
            <path   d="m403.48,615.35c.18-.14.39-.32.56-.44h0c-.19.06-.39.13-.62.2l-.81.24-.09-.13.48-.67c.14-.2.27-.37.4-.52h0c-.17.11-.4.26-.6.4l-.59.38-.12-.16,1.39-.87.16.21-.48.69c-.12.17-.24.32-.35.46h0c.16-.06.34-.12.54-.18l.81-.24.16.21-1.26,1.05-.12-.16.55-.46Z"/>
            <path   d="m404.61,615.68c.04.09.09.21.14.37.06.2.05.36,0,.47-.05.11-.15.19-.29.23-.14.04-.26.03-.37-.02-.15-.07-.25-.23-.31-.42-.02-.06-.03-.11-.03-.16l-.62.19-.06-.2,1.54-.46Zm-.69.42s.01.1.03.17c.07.24.23.35.45.29.21-.06.26-.24.2-.45-.02-.09-.05-.15-.07-.18l-.61.18Z"/>
            <path   d="m404.13,618.06v-.63s-.6.01-.6.01v.7s-.16,0-.16,0l-.02-.9,1.63-.03.02.87h-.18s-.01-.66-.01-.66h-.52s.01.64.01.64h-.17Z"/>
            <path   d="m404.91,618.67c0,.11-.03.26-.06.39-.05.21-.13.34-.24.42-.09.06-.21.08-.33.05-.21-.05-.31-.22-.33-.39h0c-.07.11-.2.15-.38.15-.23,0-.39,0-.46.01l.05-.21c.05-.01.2-.01.4,0,.23,0,.33-.05.39-.24l.05-.19-.68-.17.05-.2,1.56.39Zm-.77.02l-.05.21c-.05.22.03.38.21.43.2.05.33-.07.38-.29.02-.1.03-.17.03-.2l-.58-.14Z"/>
            <path   d="m402.06,621.41l-.15-.52.18-.12.45,1.66-.21.14-1.36-1.06.18-.12.43.33.47-.31Zm-.35.43l.39.31c.09.07.16.14.23.2h0c-.03-.1-.07-.19-.1-.29l-.13-.48-.4.27Z"/>
            <path   d="m401.35,623.08l-.2.06-.44-1.38-.65.21-.05-.17.85-.27.49,1.55Z"/>
            <path   d="m399.91,623.25l.49-.05.02.18-1.18.12-.02-.18.49-.05-.15-1.45.21-.02.15,1.45Z"/>
            <path   d="m398.64,623.45l.13-1.63.21.02-.13,1.63-.21-.02Z"/>
            <path   d="m397.84,623.32l.29-.92c.11-.35,0-.54-.2-.61-.23-.07-.42.04-.53.38l-.29.92-.2-.06.28-.91c.15-.48.46-.6.79-.5.31.1.5.35.35.84l-.28.92-.2-.06Z"/>
            <path   d="m397.07,621.59c-.05-.1-.13-.22-.25-.29-.18-.12-.34-.09-.44.05-.08.13-.06.25.07.44.17.22.22.43.1.62-.14.21-.42.26-.68.08-.14-.09-.21-.19-.25-.26l.14-.11c.03.05.09.15.21.23.18.12.33.06.39-.04.09-.13.04-.24-.09-.44-.17-.24-.21-.43-.08-.63.14-.21.41-.29.73-.08.13.09.25.22.29.31l-.14.12Z"/>
          </g>
          <path   d="m399.47,615.42c1.55.24,2.63,1.63,2.4,3.08-.23,1.46-1.67,2.45-3.22,2.2-1.55-.24-2.63-1.63-2.4-3.08.23-1.46,1.67-2.45,3.22-2.21Z"/>
          <path   d="m398.04,618.56c.42.07.71.47.65.9-.06.41-.47.71-.9.64-.43-.06-.71-.47-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m398.03,616.23c.43.06.72.46.66.88-.06.43-.46.72-.89.65-.43-.07-.72-.46-.65-.88.07-.43.47-.73.89-.65Z"/>
          <path   d="m400.06,618.55c.43.06.72.47.65.89-.06.42-.45.72-.89.65-.42-.07-.71-.47-.65-.89.06-.43.47-.72.89-.65Z"/>
          <path   d="m400.25,616.23c.43.07.72.47.65.89-.06.43-.46.72-.89.65-.42-.07-.72-.46-.65-.89.06-.42.47-.71.88-.65Z"/>
        </g>
        <g>
          <polyline   points="399.67 673.41 402.12 673.57 399.49 674.46 402.41 674.68 399.97 675.61 401.13 676.23 400.2 676.58 400.77 676.89 400.24 677.43 400.71 677.82 400.45 678.49"/>
          <path   d="m401.61,660.86c3.35.81,5.4,4.19,4.59,7.55-.83,3.35-4.2,5.4-7.55,4.58-3.36-.82-5.4-4.2-4.59-7.55.82-3.34,4.2-5.39,7.55-4.58Z"/>
          <g>
            <path   d="m401.59,663.23c.06.08.17.17.29.22.19.07.34.01.39-.13.05-.13,0-.24-.16-.39-.2-.17-.3-.35-.22-.55.09-.22.33-.32.61-.21.15.06.24.13.29.19l-.11.13s-.11-.12-.24-.17c-.19-.07-.31.01-.35.11-.05.13,0,.23.18.39.2.19.28.35.2.56-.09.22-.32.35-.66.22-.14-.05-.27-.15-.33-.23l.11-.14Z"/>
            <path   d="m403.51,663.71l-.48-.34-.32.46.54.38-.1.14-.7-.49.89-1.27.67.47-.1.14-.51-.36-.28.4.48.34-.09.14Z"/>
            <path   d="m404.56,665.03c.17-.14.37-.3.53-.41h0c-.18.06-.37.12-.59.19l-.77.23-.09-.12.45-.63c.13-.19.26-.35.38-.5h0c-.16.11-.38.25-.57.38l-.56.36-.11-.15,1.31-.83.15.2-.45.65c-.12.16-.22.31-.33.43h0c.15-.06.32-.11.52-.17l.76-.22.15.2-1.19.99-.11-.15.53-.43Z"/>
            <path   d="m405.62,665.34c.04.09.09.2.13.35.05.19.05.34,0,.45-.05.1-.14.18-.27.22-.13.04-.25.03-.35-.02-.14-.07-.24-.21-.29-.4-.02-.06-.03-.11-.03-.16l-.59.18-.06-.19,1.46-.44Zm-.66.4s.01.1.03.16c.07.23.22.33.42.27.2-.06.25-.22.19-.43-.02-.08-.05-.14-.07-.17l-.58.17Z"/>
            <path   d="m405.18,667.6v-.59s-.57.01-.57.01v.66s-.16,0-.16,0l-.02-.86,1.55-.03.02.82h-.17s-.01-.62-.01-.62h-.49s.01.6.01.6h-.17Z"/>
            <path   d="m405.91,668.18c0,.1-.03.24-.06.37-.05.2-.12.33-.23.4-.09.06-.2.07-.31.05-.2-.05-.3-.2-.31-.37h0c-.07.1-.19.14-.36.14-.22,0-.37,0-.44.01l.05-.2s.19-.01.38,0c.22,0,.32-.05.36-.22l.05-.18-.65-.16.05-.19,1.48.37Zm-.73.02l-.05.2c-.05.2.03.36.2.4.19.05.31-.07.36-.27.02-.09.03-.16.03-.19l-.55-.14Z"/>
            <path   d="m403.21,670.77l-.14-.49.17-.11.43,1.58-.2.13-1.29-1,.17-.12.4.32.45-.3Zm-.33.41l.37.29c.08.07.15.13.22.19h0c-.03-.09-.06-.18-.09-.28l-.12-.46-.38.25Z"/>
            <path   d="m402.54,672.35l-.19.06-.42-1.31-.62.2-.05-.16.81-.26.47,1.47Z"/>
            <path   d="m401.18,672.52l.46-.05.02.17-1.12.12-.02-.17.46-.05-.14-1.37.2-.02.14,1.37Z"/>
            <path   d="m399.98,672.7l.12-1.54.2.02-.12,1.54-.2-.02Z"/>
            <path   d="m399.21,672.58l.27-.87c.1-.33,0-.52-.19-.58-.22-.07-.4.03-.5.36l-.27.87-.19-.06.27-.86c.14-.45.43-.57.75-.47.3.09.47.34.33.79l-.27.87-.19-.06Z"/>
            <path   d="m398.49,670.94c-.04-.09-.13-.2-.24-.28-.17-.11-.33-.09-.41.04-.08.12-.06.23.07.42.16.21.21.41.09.59-.13.2-.4.24-.64.08-.13-.09-.2-.18-.24-.25l.14-.11c.03.05.09.14.2.22.17.11.31.05.37-.04.08-.12.04-.23-.09-.42-.16-.23-.2-.4-.07-.59.13-.2.39-.28.69-.08.12.08.23.21.27.3l-.13.11Z"/>
          </g>
          <path   d="m400.54,664.56c1.55.24,2.63,1.63,2.4,3.08-.23,1.47-1.68,2.45-3.22,2.2-1.55-.24-2.64-1.63-2.4-3.08.23-1.46,1.67-2.45,3.22-2.21Z"/>
          <path   d="m399.11,667.7c.42.07.72.47.65.91-.06.41-.47.71-.9.64-.42-.07-.71-.47-.65-.89.07-.44.47-.72.9-.66Z"/>
          <path   d="m399.11,665.37c.43.06.72.46.65.88-.06.43-.46.72-.89.65-.42-.07-.72-.46-.65-.88.06-.43.46-.72.89-.65Z"/>
          <path   d="m401.14,667.69c.42.07.72.47.65.89-.06.43-.46.72-.89.65-.42-.07-.72-.47-.65-.89.07-.42.47-.71.9-.65Z"/>
          <path   d="m401.33,665.37c.42.07.72.47.65.89-.06.43-.47.72-.89.65-.42-.07-.71-.46-.64-.89.06-.43.46-.71.89-.65Z"/>
        </g>
        <g>
          <polyline   points="391.98 119.23 391.72 121.67 390.94 119 390.61 121.93 389.77 119.44 389.1 120.58 388.8 119.64 388.46 120.2 387.93 119.65 387.54 120.1 386.88 119.81"/>
          <path   d="m404.45,121.65c-.94,3.32-4.4,5.24-7.72,4.29-3.31-.95-5.23-4.4-4.29-7.72.95-3.32,4.41-5.23,7.73-4.29,3.31.95,5.23,4.4,4.28,7.71Z"/>
          <g>
            <path   d="m402.33,121.71c-.09.06-.18.16-.24.29-.08.19-.03.34.12.4.13.06.24.01.4-.14.18-.2.37-.29.57-.2.22.1.31.35.19.62-.06.14-.14.24-.2.28l-.13-.12s.13-.11.18-.24c.08-.19,0-.32-.1-.36-.13-.06-.24,0-.4.16-.2.2-.37.27-.58.18-.22-.1-.34-.34-.2-.67.06-.14.16-.27.25-.32l.14.11Z"/>
            <path   d="m401.78,123.61l.36-.48-.45-.34-.4.53-.13-.1.52-.69,1.24.95-.5.66-.13-.1.38-.5-.39-.3-.36.48-.13-.1Z"/>
            <path   d="m400.4,124.63c.13.17.29.38.4.55h0c-.06-.19-.12-.38-.17-.6l-.2-.78.13-.08.62.48c.18.14.35.28.49.41h0c-.1-.17-.24-.39-.36-.59l-.34-.58.16-.11.78,1.36-.21.14-.64-.48c-.16-.12-.3-.24-.42-.35h0c.06.16.11.33.16.53l.2.78-.21.14-.95-1.24.16-.11.41.55Z"/>
            <path   d="m400.04,125.7c-.09.04-.21.08-.36.12-.19.05-.34.04-.45-.02-.1-.05-.18-.15-.21-.28-.04-.14-.02-.26.03-.35.07-.14.22-.23.41-.28.06-.01.11-.03.16-.03l-.15-.61.19-.05.38,1.5Zm-.38-.68s-.1,0-.16.02c-.23.06-.34.21-.29.42.05.2.22.26.43.21.08-.02.15-.04.18-.06l-.15-.59Z"/>
            <path   d="m397.79,125.15h.6s.01-.55.01-.55h-.67s0-.18,0-.18l.87.02-.03,1.56-.83-.02v-.17s.64.01.64.01v-.49s-.59-.01-.59-.01v-.17Z"/>
            <path   d="m397.17,125.87c-.1,0-.24-.04-.37-.07-.2-.06-.32-.14-.39-.25-.06-.09-.07-.2-.03-.32.06-.2.22-.29.39-.3h0c-.1-.08-.14-.21-.13-.37.01-.22.02-.38,0-.44l.2.06s0,.19,0,.39c-.02.22.04.32.21.38l.18.05.19-.65.19.06-.43,1.48Zm0-.74l-.2-.06c-.2-.06-.37.02-.42.19-.06.19.06.32.26.38.09.03.16.04.19.04l.16-.55Z"/>
            <path   d="m394.66,123.04l.5-.12.11.18-1.61.37-.12-.2,1.07-1.26.11.18-.34.4.28.47Zm-.4-.35l-.31.36c-.07.08-.14.15-.2.22h0c.09-.02.19-.05.28-.08l.46-.11-.24-.4Z"/>
            <path   d="m393.1,122.31l-.05-.19,1.34-.37-.18-.63.16-.04.23.83-1.5.41Z"/>
            <path   d="m392.98,120.93l.03.47h-.17s-.08-1.13-.08-1.13h.17s.03.46.03.46l1.39-.09v.2s-1.37.09-1.37.09Z"/>
            <path   d="m392.84,119.71l1.55.18-.02.2-1.55-.18.02-.2Z"/>
            <path   d="m392.99,118.94l.87.31c.33.12.52.02.59-.17.08-.22-.02-.4-.35-.52l-.87-.31.07-.19.86.3c.45.16.55.46.44.77-.1.3-.36.46-.81.3l-.87-.31.07-.19Z"/>
            <path   d="m394.68,118.28c.1-.04.21-.12.29-.23.12-.16.1-.32-.03-.42-.12-.09-.23-.07-.42.05-.22.15-.42.2-.6.07-.2-.14-.23-.41-.06-.65.09-.13.19-.2.26-.23l.1.14c-.05.02-.15.08-.23.19-.12.17-.07.31.02.37.12.09.23.05.42-.07.24-.15.42-.19.6-.05.2.14.26.4.05.7-.09.12-.22.23-.31.26l-.11-.14Z"/>
          </g>
          <path   d="m400.79,120.44c-.31,1.54-1.74,2.57-3.18,2.28-1.46-.29-2.38-1.77-2.07-3.3.3-1.54,1.73-2.57,3.17-2.28,1.45.28,2.39,1.76,2.08,3.3Z"/>
          <path   d="m397.71,118.9c-.08.41-.5.69-.93.61-.41-.08-.69-.49-.61-.92.08-.42.49-.69.91-.61.43.09.69.49.62.92Z"/>
          <path   d="m400.03,118.98c-.08.43-.49.7-.9.62-.43-.08-.7-.49-.62-.91.09-.42.5-.7.91-.61.43.08.71.49.62.91Z"/>
          <path   d="m397.63,120.91c-.08.43-.49.7-.91.61-.42-.08-.7-.49-.62-.91.09-.42.49-.7.91-.61.42.08.69.5.62.91Z"/>
          <path   d="m399.95,121.19c-.08.42-.49.7-.91.61-.43-.08-.7-.49-.61-.91s.49-.7.91-.61c.42.08.69.49.61.91Z"/>
        </g>
      </g>
      <g id="Maga_derecha" data-name="Maga derecha">
        <polygon id="Puño_derecha" data-name="Puño derecha"   points="728.96 461.06 731.17 490.38 639.68 517.12 634.58 489.71 728.96 461.06"/>
        <path id="Manga_derecha" data-name="Manga derecha"   d="m590.13,99.73s51.54,1.84,81.86,87.73c12.7,35.99,35.57,128.37,51.59,194.33,6.6,27.17,5.14,78.64,5.14,78.64l-94.38,28.65s-7.11-35.27-9.87-54.38c-12.01-83.18-36.74-139.76-36.74-139.76,0,0-11.5-69.26-9.68-93.27,1.65-21.78,12.09-101.95,12.09-101.95Z"/>
      </g>
      <g id="Sombras">
        <path   d="m189.38,353.2l-24.95-32.75s-15.59,1.56,3.12,17.15c18.5,15.42,1.78,16.29,7.8,17.15,42.99,6.14-78.25-17.25-32.75,0,45.23,17.16,34.31,3.12,34.31,3.12"/>
        <path   d="m624.19,372s28.07-67.06,20.27-38.98c-7.8,28.07-31.19,32.75-7.8,28.07,23.39-4.68,45.23-15.59,23.39-4.68-21.83,10.92-23.39,12.48-10.91,17.15,12.47,4.68,24.95,7.8-17.16,3.12"/>
      </g>
      <g id="Costuras">
        <g>
          <path   d="m499.34,631.64s.05.35.13.99"/>
          <path   d="m499.71,634.6c1,8.29,3.62,31.78,2.51,41.47-1.3,11.33-8.66,46.82-10.49,55.53"/>
          <path   d="m491.53,732.58c-.13.63-.21.97-.21.97"/>
        </g>
        <g>
          <line   x1="204.07" y1="125.34" x2="204.54" y2="125.18"/>
          <line   x1="205.49" y1="124.87" x2="314.7" y2="88.15"/>
          <line   x1="315.17" y1="87.99" x2="315.64" y2="87.83"/>
        </g>
        <g>
          <line   x1="481.65" y1="89.68" x2="482.13" y2="89.83"/>
          <line   x1="483.09" y1="90.14" x2="584.37" y2="122.42"/>
          <line   x1="584.85" y1="122.57" x2="585.33" y2="122.72"/>
        </g>
        <g>
          <line   x1="387.25" y1="752.38" x2="387.25" y2="751.38"/>
          <line   x1="387.25" y1="749.38" x2="386.56" y2="126.77"/>
          <line   x1="386.56" y1="125.77" x2="386.56" y2="124.77"/>
        </g>
        <line   x1="408.47" y1="128.46" x2="411.04" y2="637.42"/>
        <path   d="m224.69,705.17s46.92,33.96,85.99,49.61c33.97,13.61,48.33,9.33,83.82,10.13,23.39.54,87.68,4.63,124.35-23.49,27.64-21.19,68.17-38.69,68.17-38.69"/>
        <g>
          <line   x1="351.23" y1="63.37" x2="352.23" y2="63.38"/>
          <line   x1="354.24" y1="63.39" x2="442.02" y2="63.82"/>
          <line   x1="443.02" y1="63.83" x2="444.02" y2="63.83"/>
        </g>
        <line   x1="70.74" y1="463.97" x2="164.74" y2="491.45"/>
        <line   x1="68.83" y1="484.07" x2="161.25" y2="510.17"/>
        <path   d="m385.61,124.91s9.57,2.14,12.71,2.2c3.63.07,11.71-1.71,11.71-1.71"/>
        <line   x1="417.69" y1="637.61" x2="509.33" y2="638.86"/>
        <path   d="m185.08,689.95s66.31,8.05,110.84,41.39c7.45,5.58,33.96,12.48,49.17,14.57,22.29,3.06,36.31,1.69,36.3,1.69"/>
        <path   d="m503.39,729.37s34.61-21.26,54.65-27.93c20.15-6.72,49.19-7.9,49.19-7.9"/>
        <path   d="m308.39,64.08s21.72,51.25,27.5,61.06c7.34,12.45,26.5,29.27,26.5,29.27l31.04-50.21"/>
        <path   d="m485.46,62.73s-12.44,38.97-17.83,50.1c-10.42,21.52-33.28,42.03-33.28,42.03,0,0-6.39-7.31-9.76-12.44-4.79-7.31-22.86-37.32-22.86-37.32"/>
        <line   x1="729.26" y1="465.88" x2="635.25" y2="493.37"/>
        <line   x1="731.17" y1="485.99" x2="639.21" y2="514.56"/>
      </g>
      <circle   cx="283.23" cy="232.77" r="25.3"/>
      <circle   cx="144.23" cy="232.77" r="25.3"/>
      <circle   cx="656.23" cy="232.77" r="25.3"/>
      <circle   cx="517.23" cy="232.77" r="25.3"/>
    </g>
  </g>
    </svg>
  )
}

export default Blusa_3_4Svg
