import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare6mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
      stroke='black'
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 6mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_6mm_Completo'
              data-name='Path Cuadro 6mm Completo'
            >
              <g
                id='Path_Cuadro_6mm_Completo-2'
                data-name='Path Cuadro 6mm Completo'
              >
                <g
                  id='Cuadricula_Completa'
                  data-name='Cuadricula Completa'
                  fill={`${ink !== 'black' ? ink : 'black'}`}
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='215.89' y1='103.56' x2='215.89' y2='736.46' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='631.69' y1='103.56' x2='631.69' y2='736.46' />
                  </g>
                  <line x1='647.65' y1='119.87' x2='200.47' y2='119.87' />
                  <line x1='647.65' y1='135.64' x2='200.47' y2='135.64' />
                  <line x1='647.65' y1='151.42' x2='200.47' y2='151.42' />
                  <line x1='647.65' y1='167.19' x2='200.47' y2='167.19' />
                  <line x1='647.65' y1='183.15' x2='200.47' y2='183.15' />
                  <line x1='647.65' y1='198.9' x2='200.47' y2='198.9' />
                  <line x1='647.65' y1='214.69' x2='200.47' y2='214.69' />
                  <line x1='647.65' y1='230.47' x2='200.47' y2='230.47' />
                  <line x1='647.65' y1='246.24' x2='200.47' y2='246.24' />
                  <line x1='647.65' y1='262.01' x2='200.47' y2='262.01' />
                  <line x1='647.65' y1='277.98' x2='200.47' y2='277.98' />
                  <line x1='647.65' y1='293.75' x2='200.47' y2='293.75' />
                  <line x1='647.65' y1='309.52' x2='200.47' y2='309.52' />
                  <line x1='647.65' y1='325.29' x2='200.47' y2='325.29' />
                  <line x1='647.65' y1='341.07' x2='200.47' y2='341.07' />
                  <line x1='647.65' y1='356.84' x2='200.47' y2='356.84' />
                  <line x1='647.65' y1='372.8' x2='200.47' y2='372.8' />
                  <line x1='647.65' y1='388.55' x2='200.47' y2='388.55' />
                  <line x1='647.65' y1='404.36' x2='200.47' y2='404.36' />
                  <line x1='647.65' y1='420.13' x2='200.47' y2='420.13' />
                  <line x1='647.65' y1='435.91' x2='200.47' y2='435.91' />
                  <line x1='647.65' y1='451.68' x2='200.47' y2='451.68' />
                  <line x1='647.65' y1='467.64' x2='200.47' y2='467.64' />
                  <line x1='647.65' y1='483.39' x2='200.47' y2='483.39' />
                  <line x1='647.65' y1='499.19' x2='200.47' y2='499.19' />
                  <line x1='647.65' y1='514.96' x2='200.47' y2='514.96' />
                  <line x1='647.65' y1='530.73' x2='200.47' y2='530.73' />
                  <line x1='647.65' y1='546.51' x2='200.47' y2='546.51' />
                  <line x1='647.65' y1='562.47' x2='200.47' y2='562.47' />
                  <line x1='647.65' y1='578.24' x2='200.47' y2='578.24' />
                  <line x1='647.65' y1='594.01' x2='200.47' y2='594.01' />
                  <line x1='647.65' y1='609.78' x2='200.47' y2='609.78' />
                  <line x1='647.65' y1='625.56' x2='200.47' y2='625.56' />
                  <line x1='647.65' y1='641.33' x2='200.47' y2='641.33' />
                  <line x1='647.65' y1='657.29' x2='200.47' y2='657.29' />
                  <line x1='647.65' y1='673.04' x2='200.47' y2='673.04' />
                  <line x1='647.65' y1='688.79' x2='200.47' y2='688.79' />
                  <line x1='647.65' y1='704.76' x2='200.47' y2='704.76' />
                  <line x1='647.65' y1='720.51' x2='200.47' y2='720.51' />
                  <line x1='231.86' y1='103.56' x2='231.86' y2='736.46' />
                  <line x1='247.82' y1='103.56' x2='247.82' y2='736.46' />
                  <line x1='263.97' y1='103.56' x2='263.97' y2='736.46' />
                  <line x1='279.91' y1='103.56' x2='279.91' y2='736.46' />
                  <line x1='295.89' y1='103.56' x2='295.89' y2='736.46' />
                  <line x1='311.86' y1='103.56' x2='311.86' y2='736.46' />
                  <line x1='327.82' y1='103.56' x2='327.82' y2='736.46' />
                  <line x1='343.78' y1='103.56' x2='343.78' y2='736.46' />
                  <line x1='359.94' y1='103.56' x2='359.94' y2='736.46' />
                  <line x1='375.9' y1='103.56' x2='375.9' y2='736.46' />
                  <line x1='391.86' y1='103.56' x2='391.86' y2='736.46' />
                  <line x1='407.82' y1='103.56' x2='407.82' y2='736.46' />
                  <line x1='423.78' y1='103.56' x2='423.78' y2='736.46' />
                  <line x1='439.75' y1='103.56' x2='439.75' y2='736.46' />
                  <line x1='455.9' y1='103.56' x2='455.9' y2='736.46' />
                  <line x1='471.84' y1='103.56' x2='471.84' y2='736.46' />
                  <line x1='487.84' y1='103.56' x2='487.84' y2='736.46' />
                  <line x1='503.8' y1='103.56' x2='503.8' y2='736.46' />
                  <line x1='519.76' y1='103.56' x2='519.76' y2='736.46' />
                  <line x1='535.73' y1='103.56' x2='535.73' y2='736.46' />
                  <line x1='551.88' y1='103.56' x2='551.88' y2='736.46' />
                  <line x1='567.82' y1='103.56' x2='567.82' y2='736.46' />
                  <line x1='583.8' y1='103.56' x2='583.8' y2='736.46' />
                  <line x1='599.77' y1='103.56' x2='599.77' y2='736.46' />
                  <line x1='615.73' y1='103.56' x2='615.73' y2='736.46' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m639.82,114.55c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,129.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,143.02c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,176.69c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,161.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,190.37c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,224.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,209.25c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,237.73c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,271.4c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,256.6c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,285.08c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,318.75c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,303.96c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,332.43c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,366.11c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,351.31c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,379.79c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,602.87c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,413.46c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,398.66c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,427.14c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,460.81c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,446.02c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,474.49c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,508.16c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,493.37c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,521.84c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,555.52c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,540.72c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,569.2c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,588.07c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,616.55c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,650.22c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,635.43c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,663.9c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,697.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.82,682.78c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.98,711.26c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.82,730.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m639.81,144.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,158.11c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,113.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,126.49c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,176.52c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,189.72c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,208.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,221.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,239.74c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,252.95c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,271.36c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,284.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,302.97c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,316.18c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,334.59c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,347.8c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,366.2c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,379.41c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,397.82c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,411.03c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,429.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,442.64c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,461.05c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,474.26c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,492.66c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,505.87c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,524.28c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,537.49c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,555.89c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,569.1c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,587.51c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,600.71c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,619.12c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,632.33c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,650.74c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,663.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,682.35c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,695.56c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.81,713.97c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.96,726.3c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M208.45,114.55a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M208.45,129.34a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,143a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,209.3,143' />
                                    <path d='M208.45,176.69A1.49,1.49,0,1,0,207,175.2a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,161.9a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,190.37a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.22.55.26.69a4.56,4.56,0,0,1,.56-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,224.05a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,209.25a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,237.73a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,271.4a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,256.6a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,285.08a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a4.34,4.34,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,318.75a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,304a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,332.43a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,366.11a1.5,1.5,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,351.31a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,379.79a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a7.12,7.12,0,0,1,.26.7,3.58,3.58,0,0,1,.56-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M208.45,602.87a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,413.46A1.49,1.49,0,1,0,207,412a1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,398.66a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,427.14a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.22.56.26.7a4.34,4.34,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,460.81a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,446a1.5,1.5,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,474.49a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,508.16a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,493.37a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,521.84a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29c.13.32.22.55.26.69a4.56,4.56,0,0,1,.56-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,555.52A1.49,1.49,0,1,0,207,554a1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,540.72a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,569.2a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,588.07a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,616.55a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a4.34,4.34,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,650.22a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,635.43a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,663.9a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,697.58a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,682.78a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,711.26a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M208.45,730.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M208.47,144.9a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,158.11a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.2,1.2,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,113.9a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,126.49a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,176.52A1.5,1.5,0,1,0,207,175a1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M209.32,189.72a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,208.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,221.34a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,239.74a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,253a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,271.36a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,284.57a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,303a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,316.18a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,334.59A1.49,1.49,0,1,0,207,333.1a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,347.8a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,366.2a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,379.41a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,397.82a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,411a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,429.43a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,442.64a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,461.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,474.26a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,492.66a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,505.87a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,524.28a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,537.49a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M208.47,555.89A1.49,1.49,0,1,0,207,554.4a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,569.1a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,587.51A1.5,1.5,0,1,0,207,586a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,600.71a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M208.47,619.12a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,632.33a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,650.74a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,663.94a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,682.35a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,695.56a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,714a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M209.32,726.3a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 6mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_6mm_Datos' data-name='Path Cuadro 6mm Datos'>
              <g id='Path_Cuadro_6mm_Datos-2' data-name='Path Cuadro 6mm Datos'>
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  fill={`${ink !== 'black' ? ink : 'black'}`}
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='215.89' y1='148.56' x2='215.89' y2='749.95' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='631.69' y1='148.56' x2='631.69' y2='749.95' />
                  </g>

                  <line x1='647.65' y1='164.87' x2='200.47' y2='164.87' />
                  <line x1='647.65' y1='180.64' x2='200.47' y2='180.64' />
                  <line x1='647.65' y1='196.42' x2='200.47' y2='196.42' />
                  <line x1='647.65' y1='212.19' x2='200.47' y2='212.19' />
                  <line x1='647.65' y1='228.15' x2='200.47' y2='228.15' />
                  <line x1='647.65' y1='243.9' x2='200.47' y2='243.9' />
                  <line x1='647.65' y1='259.69' x2='200.47' y2='259.69' />
                  <line x1='647.65' y1='275.47' x2='200.47' y2='275.47' />
                  <line x1='647.65' y1='291.24' x2='200.47' y2='291.24' />
                  <line x1='647.65' y1='307.01' x2='200.47' y2='307.01' />
                  <line x1='647.65' y1='322.98' x2='200.47' y2='322.98' />
                  <line x1='647.65' y1='338.75' x2='200.47' y2='338.75' />
                  <line x1='647.65' y1='354.52' x2='200.47' y2='354.52' />
                  <line x1='647.65' y1='370.29' x2='200.47' y2='370.29' />
                  <line x1='647.65' y1='386.07' x2='200.47' y2='386.07' />
                  <line x1='647.65' y1='401.84' x2='200.47' y2='401.84' />
                  <line x1='647.65' y1='417.8' x2='200.47' y2='417.8' />
                  <line x1='647.65' y1='433.55' x2='200.47' y2='433.55' />
                  <line x1='647.65' y1='449.36' x2='200.47' y2='449.36' />
                  <line x1='647.65' y1='465.13' x2='200.47' y2='465.13' />
                  <line x1='647.65' y1='480.91' x2='200.47' y2='480.91' />
                  <line x1='647.65' y1='496.68' x2='200.47' y2='496.68' />
                  <line x1='647.65' y1='512.64' x2='200.47' y2='512.64' />
                  <line x1='647.65' y1='528.39' x2='200.47' y2='528.39' />
                  <line x1='647.65' y1='544.19' x2='200.47' y2='544.19' />
                  <line x1='647.65' y1='559.96' x2='200.47' y2='559.96' />
                  <line x1='647.65' y1='575.73' x2='200.47' y2='575.73' />
                  <line x1='647.65' y1='591.5' x2='200.47' y2='591.5' />
                  <line x1='647.65' y1='607.47' x2='200.47' y2='607.47' />
                  <line x1='647.65' y1='623.24' x2='200.47' y2='623.24' />
                  <line x1='647.65' y1='639.01' x2='200.47' y2='639.01' />
                  <line x1='647.65' y1='654.78' x2='200.47' y2='654.78' />
                  <line x1='647.65' y1='670.56' x2='200.47' y2='670.56' />
                  <line x1='647.65' y1='686.33' x2='200.47' y2='686.33' />
                  <line x1='647.65' y1='702.29' x2='200.47' y2='702.29' />
                  <line x1='647.65' y1='718.04' x2='200.47' y2='718.04' />
                  <line x1='647.65' y1='733.79' x2='200.47' y2='733.79' />
                  <line x1='231.86' y1='148.56' x2='231.86' y2='749.95' />
                  <line x1='247.82' y1='148.56' x2='247.82' y2='749.95' />
                  <line x1='263.97' y1='148.56' x2='263.97' y2='749.95' />
                  <line x1='279.91' y1='148.56' x2='279.91' y2='749.95' />
                  <line x1='295.89' y1='148.56' x2='295.89' y2='749.95' />
                  <line x1='311.86' y1='148.56' x2='311.86' y2='749.95' />
                  <line x1='327.82' y1='148.56' x2='327.82' y2='749.95' />
                  <line x1='343.78' y1='148.56' x2='343.78' y2='749.95' />
                  <line x1='359.94' y1='148.56' x2='359.94' y2='749.95' />
                  <line x1='375.9' y1='148.56' x2='375.9' y2='749.95' />
                  <line x1='391.86' y1='148.56' x2='391.86' y2='749.95' />
                  <line x1='407.82' y1='148.56' x2='407.82' y2='749.95' />
                  <line x1='423.78' y1='148.56' x2='423.78' y2='749.95' />
                  <line x1='439.75' y1='148.56' x2='439.75' y2='749.95' />
                  <line x1='455.9' y1='148.56' x2='455.9' y2='749.95' />
                  <line x1='471.84' y1='148.56' x2='471.84' y2='749.95' />
                  <line x1='487.84' y1='148.56' x2='487.84' y2='749.95' />
                  <line x1='503.8' y1='148.56' x2='503.8' y2='749.95' />
                  <line x1='519.76' y1='148.56' x2='519.76' y2='749.95' />
                  <line x1='535.73' y1='148.56' x2='535.73' y2='749.95' />
                  <line x1='551.88' y1='148.56' x2='551.88' y2='749.95' />
                  <line x1='567.82' y1='148.56' x2='567.82' y2='749.95' />
                  <line x1='583.8' y1='148.56' x2='583.8' y2='749.95' />
                  <line x1='599.77' y1='148.56' x2='599.77' y2='749.95' />
                  <line x1='615.73' y1='148.56' x2='615.73' y2='749.95' />
                </g>
                {(() => {
                  switch (page) {
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M208.45,159.34a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,174.14a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,187.82a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a4.34,4.34,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,221.49A1.49,1.49,0,1,0,207,220a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,206.7a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,235.17a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,268.85a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,254.05a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,282.53a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M208.45,316.2a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,301.4a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,329.88a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.22.56.26.7a4.34,4.34,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,363.55a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,348.76a1.5,1.5,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,377.23a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,410.91a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M208.45,396.11a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,424.58a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a7.12,7.12,0,0,1,.26.7,3.58,3.58,0,0,1,.56-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83A12.19,12.19,0,0,1,211.8,423l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M208.45,647.67a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,458.26a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,443.46A1.49,1.49,0,1,0,207,442a1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,471.94a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,505.61a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,490.81a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,519.29a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a4.34,4.34,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,553a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,538.17a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,566.64a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.22.55.26.69a4.56,4.56,0,0,1,.56-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83A12.63,12.63,0,0,1,211.8,565l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,600.32a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,585.52A1.49,1.49,0,1,0,207,584a1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,614a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29c.13.32.22.56.26.7a3.58,3.58,0,0,1,.56-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,209.3,614' />
                                    <path d='M208.45,632.87a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.3,661.35a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.22.56.26.7a4.34,4.34,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M208.45,695a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M208.45,680.23a1.5,1.5,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M209.3,708.7a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.22.55.26.69a5.53,5.53,0,0,1,.56-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M208.45,727.58a1.49,1.49,0,1,0-1.49-1.49,1.5,1.5,0,0,0,1.49,1.49' />
                                    <path d='M208.45,742.66a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M208.47,190.35a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,203.56a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M208.47,159.35a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,171.94a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,222a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,235.17a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,253.58a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,266.79a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M208.47,285.19A1.49,1.49,0,1,0,207,283.7a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,298.4a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,316.81a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,330a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M208.47,348.42a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,361.63a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,380a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,393.24a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,411.65a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,424.86a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,443.26a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,456.47a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,474.88a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,488.09a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,506.49A1.49,1.49,0,1,0,207,505a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,519.7a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,538.11a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,551.32a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,569.72a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,582.93a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,601.34a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,614.55a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,633a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,646.16a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,664.57a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,677.78a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M208.47,696.18a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M209.32,709.39a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M209.32,739.35a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M208.47,727.8a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m639.89,159.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,174.14c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,187.82c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,221.49c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,206.7c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,235.17c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,268.85c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,254.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,282.53c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,316.2c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,301.4c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,329.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,363.55c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,348.76c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,377.23c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,410.91c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,396.11c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,424.58c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,647.67c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,458.26c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,443.46c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,471.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,505.61c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,490.81c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,519.29c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,552.96c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,538.17c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,566.64c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,600.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,585.52c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,614c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,632.87c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,661.35c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,695.02c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,680.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.04,708.7c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.89,727.58c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.89,742.66c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m639.87,190.35c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,203.56c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,159.35c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,171.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,221.96c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,235.17c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,253.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,266.79c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,285.19c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,298.4c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,316.81c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,330.01c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,348.42c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,361.63c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,380.04c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,393.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,411.65c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,424.86c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,443.26c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,456.47c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,474.88c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,488.09c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,506.49c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,519.7c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,538.11c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,551.32c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,569.72c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,582.93c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,601.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,614.55c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,632.95c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,646.16c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,664.57c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,677.78c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,696.18c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m639.03,709.39c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.03,739.35c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m639.87,727.8c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 6mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_6mm_Completo'
                data-name='Margenes Cuadro 6mm Completo'
              >
                <g
                  id='Margenes_Cuadro_6mm_Completo-2'
                  data-name='Margenes Cuadro 6mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V722.38a13.09,13.09,0,0,1-13.08,13.08H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V722.38A14.08,14.08,0,0,0,214,736.46H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56v630.9H200.93V104.56H647.24m1-1H199.93v632.9H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            {' '}
            <title>Margenes Cuadro 6mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_6mm_Datos'
                data-name='Margenes Cuadro 6mm Datos'
              >
                <g
                  id='Margenes_Cuadro_6mm_Datos-2'
                  data-name='Margenes Cuadro 6mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V735.87A13.09,13.09,0,0,1,634.16,749H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V735.87A14.08,14.08,0,0,0,214,750H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56V749H200.93V149.56H647.24m1-1H199.93V750H648.24V148.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare6mmVertical
