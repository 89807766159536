import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthData, setUserSession } from 'redux/reducers/auth'
import { login, loginData } from 'services/auth/users'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { ErrorToast } from 'components/Toasts/Toasts'
import { InputFormProps } from 'components/formInputs/input'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

export const InputsLogin: InputFormProps[] = [
  {
    name: 'mail',
    placeholder: 'Correo electrónico',
    type: 'email',

    rules: [
      { required: true, message: '' },
      { type: 'email', message: 'Correo no valido.' }
    ],
    prefix: <UserOutlined />
  },
  {
    name: 'password',
    type: 'password',
    placeholder: '**********',
    rules: [{ required: true, message: '' }],
    prefix: <LockOutlined />
  }
]

interface Functions {
  loading: boolean
  handleLogin: (values: loginData) => Promise<AuthData | void>
}

export const useLogin = (): Functions => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const handleLogin = async (values: loginData): Promise<AuthData | void> => {
    try {
      setLoading(true)
      const result = await login(values)
      const token: string = result?.data.token ?? ''
      sessionStorage.setItem('ezeteraToken', token)
      const userInfo: any = jwtDecode(token)
      dispatch(setUserSession(result?.data))
      if (userInfo?.uid?.role === 'Ezetera')
        return navigate('/lista-de-colegios')
      if (userInfo.uid.schools.length === 1) return navigate('/inicio')
      navigate('/selecciona-una-escuela')
    } catch (error: any) {
      ErrorToast('Datos incorrectos')
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    handleLogin
  }
}
