import React from 'react'

const JacketSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
         <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Outline_chamarra" data-name="Outline chamarra"   d="m595.33,167.68s5.62,33.71,1.43,58.4c-5.27,31.04-11.53,129.19-11.53,129.19,0,0-13.49,90.88-5.29,127.04,11.21,49.46,16.44,173.19,16.44,173.19,0,0-148.53-.44-197.39-.22-46.33.19-187.58,1.33-187.58,1.33l-11.12.78s-1.8-108.4,15.52-184.58c7.75-34.13-5.49-114.38-5.49-114.38,0,0-7.35-37.19,17.91-89.37,16.16-33.36,32.83-66.93,32.83-66.93,0,0,20.31-39.19,27.67-58.49,9.18-24.05,20.78-46.68,20.78-46.68,0,0,15.53,45.07,89.21,44.79,84.57-.32,93.79-65.27,93.79-65.27,0,0,26.63,25.36,85.02,62.5,15.75,10.02,17.81,28.69,17.81,28.69Z"/>
      <g id="Resorte">
        <path id="Resorte-2" data-name="Resorte"   d="m209.07,700.94s144.54,6.15,194.07,6.2c46,.04,185.21-6.24,185.21-6.24,0,0,2.82-16.12,2.99-21.49.19-5.84-1.57-23.34-1.57-23.34,0,0-138.57-1.01-186.19-1.05-48.38-.04-197.81.73-197.81.73,0,0-1.84,16.46-1.77,22.04.06,5.63,5.07,23.15,5.07,23.15Z"/>
        <path id="Línea_1_resorte" data-name="Línea 1 resorte"   d="m204.12,684.46s142.81,5.56,190.01,6.39c52.83.93,196.87-3.91,196.87-3.91"/>
        <path id="Línea_2_resorte" data-name="Línea 2 resorte"   d="m204.12,670.57s142.81,5.56,190.01,6.39c52.83.93,196.87-3.91,196.87-3.91"/>
      </g>
      <g id="Costuras">
        <polygon   points="238.31 576.5 277.4 435.35 302.01 441.91 262.92 584.43 238.31 576.5"/>
        <polygon   points="558.6 576.52 519.5 435.37 494.89 441.93 533.98 584.45 558.6 576.52"/>
      </g>
      <path id="Cuello_alto" data-name="Cuello alto"   d="m302.72,80.31l31.98-55.72s5.1,40.7,61.19,40.95c57.2.25,66.39-42.54,66.39-42.54l29.44,53.19s-6.96,69.1-98.46,70.29c-73.45.95-90.54-66.17-90.54-66.17Z"/>
      <g id="Cierre_cuello_alto" data-name="Cierre cuello alto">
        <polyline   points="404.21 68.93 406.05 704.54 386.48 704.54 386.62 68.93"/>
        <g>
          <g>
            <path   d="m395.02,83.76l-1.29.02c-.18,0-.32-.13-.44-.28v-1.17c0-.76-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.58c0,.73.36,1.36.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37l-.02-1.27c.24-.15.44-.2.44-.2h1.29c.16,0,.32.2.47.41l.59,1.19c.16.26.35.45.6.44.51,0,.47-.71.47-1.59v-4.59c-.01-.87.02-1.57-.49-1.57-.4,0-.71.4-.86.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.17,89.46l1.28-.02c.18,0,.31-.13.44-.28v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.02c.46,0,.83.61.83,1.35v6.57c.01.77-.34,1.39-.8,1.39l-2.83.03c-.45,0-.83-.62-.83-1.38v-1.29c-.22-.15-.43-.16-.43-.16h-1.29c-.15,0-.31.2-.46.41l-.59,1.2c-.16.28-.34.45-.59.45-.52,0-.49-.71-.49-1.58v-4.59c0-.89-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.88,95.11h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36h-2.84c-.44.01-.82.63-.81,1.38l.02,6.57c0,.74.37,1.37.82,1.36l2.84-.03c.44,0,.8-.61.8-1.37v-1.28c.22-.15.44-.2.44-.2h1.29c.16,0,.32.2.47.41l.59,1.19c.15.26.34.46.59.44.51,0,.48-.7.48-1.59v-4.58c-.02-.87,0-1.57-.5-1.57-.4,0-.71.39-.86.96,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.11,100.81h1.28c.17,0,.31-.14.44-.29v-1.17c0-.75.36-1.37.81-1.37l2.83-.02c.45,0,.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.02c-.44,0-.82-.62-.82-1.38v-1.28c-.22-.15-.44-.16-.44-.16h-1.27c-.16,0-.32.2-.46.41l-.59,1.2c-.15.28-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.6.46-1.6.4,0,.73.4.87.97,0,0,0,.57.8,1.07Z"/>
          </g>
          <g>
            <path   d="m394.93,106.61h-1.29c-.17.01-.31-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.81.61-.81,1.37l.02,6.57c0,.74.36,1.36.82,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.33.19.46.4l.59,1.19c.15.27.35.46.6.44.51,0,.48-.7.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.4-.86.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.16,112.31l1.28-.02c.18,0,.31-.12.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.44,0-.81-.62-.81-1.38v-1.27c-.23-.16-.44-.18-.44-.18h-1.28c-.16,0-.32.21-.47.42l-.59,1.2c-.15.28-.34.44-.59.44-.53,0-.5-.71-.5-1.57v-4.59c0-.89-.04-1.61.47-1.61.4,0,.72.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,129.59h-1.29c-.17,0-.31-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.81.61-.81,1.38l.02,6.57c0,.74.36,1.37.82,1.37l2.83-.03c.45,0,.81-.61.81-1.37v-1.27c.21-.16.43-.19.43-.19h1.29c.15,0,.33.19.46.41l.59,1.18c.15.26.35.45.6.44.51-.01.48-.71.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.4-.86.97,0,0,0,.57-.79,1.09Z"/>
            <path   d="m396.16,135.3l1.28-.03c.18,0,.31-.13.44-.28v-1.16c0-.76.36-1.38.81-1.38l2.82-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4h-2.83c-.44.01-.81-.6-.81-1.37v-1.28c-.23-.15-.44-.17-.44-.17h-1.28c-.16,0-.32.21-.47.42l-.59,1.2c-.15.28-.34.44-.59.44-.53,0-.5-.7-.5-1.56v-4.6c0-.89-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.87,141.16h-1.3c-.17,0-.32-.12-.44-.28v-1.16c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.56c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.62.81-1.36v-1.27c.22-.16.43-.19.43-.19h1.29c.16,0,.32.19.46.41l.59,1.18c.16.27.34.46.59.45.51-.01.48-.71.48-1.59v-4.59c-.01-.86.01-1.57-.49-1.57-.4,0-.72.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.09,146.86l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.37l.02,6.56c0,.78-.36,1.4-.81,1.4l-2.83.03c-.45,0-.82-.62-.82-1.39v-1.28c-.23-.15-.44-.17-.44-.17h-1.28c-.16,0-.33.21-.47.43l-.6,1.19c-.15.28-.34.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.61.46-1.61.4,0,.72.4.87.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m394.87,118.1h-1.3c-.17,0-.32-.11-.44-.27v-1.16c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.36,1.36.82,1.36l2.83-.03c.45,0,.81-.62.81-1.36v-1.28c.22-.15.43-.19.43-.19h1.29c.16,0,.32.19.46.4l.59,1.19c.16.27.34.45.59.44.51-.01.48-.7.48-1.59v-4.59c-.01-.87.01-1.57-.49-1.57-.4,0-.72.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.09,123.81l1.28-.03c.18,0,.32-.12.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.35l.02,6.57c0,.78-.36,1.4-.81,1.4h-2.83c-.45.01-.82-.6-.82-1.36v-1.28c-.23-.16-.44-.18-.44-.18h-1.28c-.16,0-.33.21-.47.42l-.6,1.2c-.15.27-.34.44-.59.44-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.59.79,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,152.65h-1.29c-.17.01-.31-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.81.61-.81,1.37l.02,6.57c0,.74.36,1.36.82,1.36l2.83-.03c.45,0,.81-.61.81-1.36v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.33.19.46.41l.59,1.19c.15.26.35.45.6.44.51-.01.48-.7.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.39-.86.97,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.16,158.34l1.28-.02c.18,0,.31-.12.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.44,0-.81-.61-.81-1.39v-1.27c-.23-.16-.44-.18-.44-.18h-1.28c-.16,0-.32.21-.47.42l-.59,1.19c-.15.28-.34.44-.59.44-.53,0-.5-.71-.5-1.56v-4.61c0-.88-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,164.21h-1.29c-.17,0-.31-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.73.36,1.37.82,1.36l2.82-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.46.4l.59,1.18c.15.26.35.45.6.44.51-.01.47-.7.47-1.59v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.24,169.91l1.28-.03c.18,0,.31-.13.43-.28v-1.16c0-.76.37-1.38.81-1.38l2.82-.03c.46,0,.82.62.82,1.37l.02,6.56c0,.78-.36,1.4-.81,1.4l-2.83.02c-.45,0-.83-.61-.83-1.38v-1.27c-.22-.16-.43-.18-.43-.18h-1.29c-.15.01-.31.21-.47.42l-.59,1.19c-.14.28-.34.44-.58.44-.52,0-.49-.7-.49-1.56v-4.6c0-.88-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.23,175.82h-1.29c-.17,0-.32-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.83.61-.82,1.37l.02,6.57c0,.73.36,1.36.82,1.35l2.82-.02c.45-.01.82-.62.82-1.37v-1.28c.22-.15.43-.19.43-.19h1.3c.15,0,.32.19.46.41l.6,1.19c.16.26.35.45.6.44.5,0,.47-.71.47-1.6v-4.58c-.02-.87.01-1.58-.49-1.58-.39,0-.72.39-.85.97,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.38,181.52l1.28-.02c.17,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.83-.03c.45-.01.82.62.82,1.36v6.56c.01.77-.34,1.39-.8,1.4l-2.84.02c-.43,0-.81-.62-.81-1.38v-1.28c-.23-.15-.44-.16-.44-.16h-1.28c-.15,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.44-.59.44-.52,0-.5-.7-.5-1.57v-4.59c-.01-.89-.04-1.61.47-1.61.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.08,187.17h-1.29c-.17.01-.32-.1-.44-.26v-1.18c0-.76-.36-1.38-.83-1.36h-2.82c-.45,0-.82.63-.82,1.38l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.28c.22-.15.43-.19.43-.19h1.3c.15,0,.31.19.46.41l.58,1.19c.16.26.35.45.6.44.51,0,.47-.71.47-1.59v-4.59c-.01-.87,0-1.58-.49-1.58-.39,0-.71.4-.85.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.32,192.88l1.28-.02c.18,0,.31-.13.44-.28v-1.17c0-.75.35-1.37.82-1.37l2.81-.03c.46,0,.83.62.83,1.36l.02,6.57c0,.77-.36,1.39-.82,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.27c-.23-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.21-.46.42l-.58,1.19c-.16.28-.35.44-.59.44-.52,0-.49-.7-.49-1.57v-4.59c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.14,198.67h-1.29c-.17.01-.32-.11-.44-.26v-1.17c0-.77-.36-1.37-.82-1.37l-2.82.02c-.45,0-.82.61-.82,1.36l.02,6.57c0,.75.36,1.38.82,1.37l2.83-.03c.44,0,.81-.63.81-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.16,0,.32.19.47.4l.59,1.18c.16.27.34.46.6.44.51,0,.47-.71.47-1.58v-4.59c-.02-.87,0-1.58-.49-1.58-.39,0-.71.4-.85.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.37,204.37l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.35v6.57c.01.77-.34,1.38-.79,1.4h-2.83c-.45.01-.82-.6-.82-1.36v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.72.4.87.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.14,221.65h-1.29c-.17,0-.32-.11-.44-.27v-1.16c0-.77-.36-1.38-.82-1.37l-2.82.02c-.45,0-.82.6-.82,1.36l.02,6.57c0,.74.36,1.37.82,1.37l2.83-.03c.44,0,.81-.62.81-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.16-.01.32.19.47.41l.59,1.19c.16.27.34.46.6.44.51-.02.47-.7.47-1.59v-4.6c-.02-.86,0-1.57-.49-1.57-.39,0-.71.4-.85.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.37,227.35l1.28-.02c.17,0,.31-.12.44-.28v-1.17c0-.75.36-1.36.81-1.36l2.82-.03c.46,0,.82.62.82,1.35v6.57c.01.78-.34,1.39-.79,1.4h-2.83c-.45.01-.82-.6-.82-1.36v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.07,233.21h-1.28c-.18.01-.33-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.36,1.37.81,1.37l2.84-.04c.44,0,.81-.61.81-1.37v-1.26c.21-.15.43-.2.43-.2h1.29c.16-.01.32.2.47.41l.58,1.18c.16.27.35.46.6.44.51-.01.48-.7.48-1.58v-4.6c-.02-.87,0-1.57-.5-1.57-.4,0-.72.4-.85.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.3,238.91l1.28-.02c.18,0,.32-.12.44-.28v-1.18c-.01-.75.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.38-.82,1.39l-2.82.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.33.2-.47.42l-.59,1.19c-.16.28-.35.45-.58.45-.53,0-.5-.71-.5-1.57v-4.6c-.01-.88-.04-1.6.46-1.6.4,0,.72.4.88.96,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.07,210.16h-1.28c-.18.01-.33-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.56c0,.75.36,1.38.81,1.37l2.84-.03c.44-.01.81-.62.81-1.37v-1.28c.21-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.41l.58,1.18c.16.27.35.46.6.44.51,0,.48-.69.48-1.58v-4.59c-.02-.86,0-1.56-.5-1.56-.4,0-.72.39-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.3,215.86l1.28-.02c.18,0,.32-.13.44-.28v-1.17c-.01-.75.36-1.36.81-1.36l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.78-.36,1.39-.82,1.4l-2.82.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.33.2-.47.42l-.59,1.2c-.16.28-.35.45-.58.45-.53,0-.5-.71-.5-1.57v-4.6c-.01-.89-.04-1.61.46-1.61.4,0,.72.4.88.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.14,244.7h-1.29c-.17.01-.32-.11-.44-.26v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.03c-.45,0-.82.6-.82,1.36l.02,6.57c0,.75.36,1.37.82,1.37l2.83-.03c.44,0,.81-.62.81-1.38v-1.27c.21-.14.43-.2.43-.2h1.29c.16,0,.32.2.47.41l.59,1.2c.16.26.34.46.6.43.51,0,.47-.7.47-1.59v-4.59c-.02-.86,0-1.56-.49-1.56-.39,0-.71.4-.85.96,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.37,250.41l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.76.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36v6.57c.01.77-.34,1.4-.79,1.4l-2.83.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.27-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.61.47-1.61.4,0,.72.4.87.98,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.21,256.27h-1.29c-.17,0-.32-.12-.44-.27v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.44,0-.82.61-.81,1.37l.02,6.56c0,.75.36,1.37.82,1.37l2.82-.03c.45,0,.82-.62.82-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.32.19.46.4l.59,1.18c.16.27.35.46.6.44.51,0,.48-.7.48-1.58v-4.6c-.02-.87,0-1.57-.5-1.57-.4,0-.72.39-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.44,261.97l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.75.36-1.37.82-1.37l2.81-.03c.46,0,.82.62.82,1.35l.02,6.58c0,.77-.36,1.38-.81,1.39l-2.83.02c-.45,0-.83-.61-.83-1.38v-1.27c-.22-.17-.44-.18-.44-.18h-1.28c-.15,0-.32.21-.47.42l-.58,1.2c-.16.28-.36.45-.59.45-.53,0-.49-.71-.49-1.57v-4.6c0-.88-.05-1.6.46-1.6.4,0,.72.4.87.96,0,0,0,.58.79,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395,268.09h-1.29c-.17.01-.32-.1-.43-.26v-1.17c-.01-.77-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.37,1.37.82,1.36l2.82-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.15.44-.18.44-.18h1.29c.15,0,.32.18.46.4l.59,1.19c.15.26.35.45.6.45.51,0,.47-.71.47-1.6v-4.58c-.02-.87,0-1.58-.5-1.58-.39,0-.71.4-.85.98,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.16,273.79l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.83-.02c.45-.01.82.61.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.29c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.44-.59.44-.52,0-.49-.69-.49-1.56v-4.59c-.01-.9-.05-1.6.46-1.6.4,0,.72.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.87,279.45h-1.29c-.18,0-.32-.11-.44-.27v-1.17c-.01-.76-.38-1.38-.83-1.37l-2.83.02c-.45,0-.82.62-.82,1.37l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.02c.45-.01.81-.62.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.28c.16,0,.32.2.46.42l.59,1.18c.16.27.35.45.59.44.51,0,.48-.71.48-1.6v-4.58c-.01-.88,0-1.58-.5-1.58-.4,0-.71.39-.86.97,0,0,0,.55-.78,1.09Z"/>
            <path   d="m396.09,285.15l1.28-.02c.18,0,.32-.13.44-.28v-1.18c0-.75.35-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36v6.56c.01.77-.34,1.39-.8,1.4l-2.83.02c-.46,0-.82-.61-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16.01-.33.21-.46.42l-.59,1.2c-.15.27-.35.44-.59.44-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.39,0,.71.39.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,290.94h-1.28c-.17.01-.32-.11-.44-.27v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.36l2.82-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.28c.16,0,.33.2.47.41l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,296.64l1.28-.03c.17,0,.31-.12.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.36l.02,6.58c0,.77-.36,1.38-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.27c-.24-.16-.45-.18-.45-.18h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.59c0-.9-.05-1.61.47-1.61.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,313.92l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.36l-2.83.03c-.45,0-.82.6-.81,1.37v6.56c.01.75.38,1.37.84,1.37l2.82-.03c.45,0,.81-.62.81-1.38v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.4l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,319.63l1.28-.03c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.81-.62-.81-1.39v-1.27c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.21-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.86,325.48h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.37l-2.83.03c-.46,0-.82.61-.82,1.36l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.03c.46,0,.81-.62.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.4l.59,1.19c.15.26.35.46.59.44.51,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.4-.85.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.09,331.19l1.28-.02c.17,0,.3-.13.44-.28v-1.17c-.01-.75.35-1.38.81-1.38l2.82-.02c.46,0,.82.61.82,1.35l.02,6.58c0,.77-.36,1.39-.82,1.4h-2.83c-.45.01-.82-.6-.82-1.37v-1.28c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.42l-.59,1.19c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,302.43h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.6-.82,1.36l.02,6.57c0,.75.37,1.37.82,1.37l2.83-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.41l.59,1.19c.15.27.35.46.59.44.51,0,.48-.7.48-1.59v-4.59c-.02-.86,0-1.56-.49-1.56-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,308.13l1.28-.02c.17,0,.3-.12.44-.28v-1.17c-.01-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.35l.02,6.57c0,.78-.36,1.38-.82,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.88-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,336.98h-1.28c-.17.01-.32-.11-.44-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.37l2.82-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.18.44-.18h1.28c.16-.01.33.18.47.4l.6,1.19c.15.28.35.46.6.44.5,0,.48-.69.48-1.58v-4.6c-.02-.86,0-1.56-.5-1.56-.39,0-.71.39-.86.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,342.68l1.28-.02c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.36l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,348.53l-1.29.02c-.17,0-.32-.12-.44-.27v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.56c0,.75.36,1.38.82,1.37l2.83-.03c.45,0,.81-.62.81-1.38v-1.27c.22-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.4l.59,1.19c.15.27.36.45.6.44.51,0,.47-.7.47-1.59v-4.6c-.01-.86.01-1.56-.49-1.56-.4,0-.72.4-.87.98,0,0,0,.55-.77,1.07Z"/>
            <path   d="m396.22,354.24l1.28-.03c.17,0,.31-.12.44-.27v-1.18c0-.75.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.35,1.39-.81,1.4h-2.83c-.45.01-.82-.61-.82-1.37v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.49-.7-.49-1.56l-.02-4.6c0-.89-.04-1.6.48-1.6.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395,360.09h-1.29c-.17.01-.32-.11-.43-.26v-1.16c-.01-.77-.37-1.38-.82-1.37l-2.83.03c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.37,1.37.82,1.37l2.82-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.14.44-.18.44-.18h1.29c.15-.01.32.18.46.4l.59,1.19c.15.27.35.45.6.44.51,0,.47-.7.47-1.59v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.85.97,0,0,0,.57-.79,1.08Z"/>
            <path   d="m396.16,365.8l1.28-.02c.18,0,.32-.13.44-.27v-1.17c0-.76.36-1.38.81-1.38l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.18-.44-.18h-1.29c-.16,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c-.01-.89-.05-1.6.46-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.87,371.45h-1.29c-.18.01-.32-.11-.44-.27v-1.16c-.01-.77-.38-1.38-.83-1.37l-2.83.02c-.45,0-.82.61-.82,1.38l.02,6.56c0,.74.37,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.32.19.46.4l.59,1.19c.16.26.35.45.59.44.51-.01.48-.71.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.4,0-.71.39-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.09,377.15l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.35-1.36.81-1.36l2.83-.04c.45,0,.82.63.82,1.36v6.57c.01.77-.34,1.39-.8,1.41h-2.83c-.46.01-.82-.61-.82-1.37v-1.27c-.22-.15-.44-.18-.44-.18h-1.28c-.16.01-.33.21-.46.43l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.59.79,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,382.95h-1.28c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.45.01-.82.63-.81,1.38v6.57c.01.74.38,1.37.84,1.36l2.82-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.19c.15.26.35.45.6.44.5,0,.48-.71.48-1.59v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.96,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.15,388.65l1.28-.02c.17,0,.31-.13.44-.27v-1.18c-.01-.75.36-1.36.81-1.36l2.83-.03c.46,0,.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.03c-.46,0-.81-.62-.81-1.38v-1.27c-.24-.16-.45-.18-.45-.18h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,405.93l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.35h-2.83c-.45.01-.82.63-.81,1.39v6.56c.01.75.38,1.38.84,1.37l2.82-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.19c.15.26.35.45.6.44.5,0,.48-.71.48-1.59v-4.6c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.15,411.64l1.28-.03c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.36.81-1.36l2.83-.03c.46-.01.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.59c0-.9-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,417.49l-1.29.02c-.17,0-.32-.13-.44-.28v-1.17c0-.76-.37-1.37-.82-1.36h-2.83c-.46.01-.82.63-.82,1.38l.02,6.57c0,.74.37,1.38.82,1.36l2.83-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.41l.59,1.18c.15.27.35.46.59.45.51-.01.48-.71.48-1.6v-4.58c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,423.19h1.28c.17-.01.3-.15.44-.29v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.38-.82,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.41l-.59,1.2c-.15.27-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,394.44h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.36l.02,6.58c0,.73.37,1.37.82,1.36l2.83-.02c.46-.01.81-.63.81-1.38v-1.27c.22-.14.44-.2.44-.2h1.29c.15,0,.32.2.47.42l.59,1.19c.15.26.35.46.59.44.51,0,.48-.7.48-1.59v-4.58c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,400.14l1.28-.02c.17,0,.3-.13.44-.27v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.02c.46,0,.82.61.82,1.35l.02,6.57c0,.77-.36,1.39-.82,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.15-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.41l-.59,1.2c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.58v-4.59c0-.89-.05-1.59.46-1.59.4,0,.72.4.87.96,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,428.98l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.37l2.82-.03c.45-.01.81-.62.81-1.38v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.4l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,434.68h1.28c.17-.01.31-.14.44-.29v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.35l.02,6.58c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.15-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.41l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.7-.49-1.57v-4.59c0-.89-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,440.54h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.41l.59,1.18c.15.27.36.45.6.45.51-.01.47-.71.47-1.59v-4.59c-.01-.88.01-1.57-.49-1.57-.4,0-.72.39-.87.97,0,0,0,.55-.77,1.08Z"/>
            <path   d="m396.22,446.24l1.28-.02c.17,0,.31-.12.44-.27v-1.17c0-.76.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.35,1.38-.81,1.39l-2.83.03c-.45,0-.82-.62-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16.01-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.49-.7-.49-1.56l-.02-4.59c0-.89-.04-1.61.48-1.61.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.27,451.89h-1.29c-.18.01-.32-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.62-.82,1.37l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.36v-1.29c.22-.14.44-.18.44-.18h1.29c.14,0,.32.18.46.4l.59,1.19c.15.26.35.44.59.44.51,0,.48-.71.48-1.6v-4.59c-.02-.87.01-1.57-.5-1.57-.39,0-.71.39-.85.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,457.59l1.29-.02c.17,0,.32-.13.44-.28v-1.17c0-.75.35-1.37.82-1.37l2.82-.03c.46,0,.82.62.82,1.36v6.57c.01.77-.34,1.39-.79,1.4h-2.83c-.46.01-.83-.61-.83-1.37v-1.27c-.22-.16-.44-.18-.44-.18l-1.29.02c-.15,0-.32.19-.46.41l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.69-.49-1.56v-4.6c-.01-.89-.05-1.6.47-1.6.39,0,.71.39.85.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.13,463.25h-1.29c-.17,0-.32-.11-.44-.27v-1.17c0-.76-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.62-.82,1.37l.03,6.57c0,.73.36,1.37.81,1.36l2.83-.03c.46,0,.81-.61.81-1.36v-1.28c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.18c.15.26.34.45.59.44.51,0,.48-.7.48-1.58v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.36,468.94l1.28-.02c.17,0,.31-.14.44-.28v-1.17c-.01-.75.35-1.37.81-1.37l2.82-.03c.46-.01.83.61.83,1.36l.02,6.56c0,.77-.36,1.39-.82,1.4l-2.83.03c-.45,0-.82-.63-.82-1.39v-1.27c-.23-.16-.44-.18-.44-.18l-1.28.02c-.16,0-.32.19-.46.41l-.59,1.2c-.15.28-.35.44-.59.44-.52,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.46-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.19,474.74l-1.29.02c-.17,0-.32-.12-.44-.28v-1.16c0-.77-.36-1.39-.82-1.37l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.37l2.82-.03c.46,0,.82-.61.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.18c.16.27.35.46.59.44.51,0,.48-.7.48-1.58l-.02-4.59c0-.86.03-1.57-.48-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,480.45l1.28-.03c.17,0,.31-.12.44-.27v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.35v6.57c0,.77-.35,1.39-.8,1.4h-2.82c-.46.01-.83-.6-.83-1.37v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.41l-.59,1.19c-.15.28-.35.45-.59.45-.51,0-.48-.71-.48-1.57v-4.59c-.01-.89-.05-1.59.46-1.59.39,0,.72.4.86.96,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.19,497.73h-1.29c-.17,0-.32-.11-.44-.27v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.36l2.82-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.19c.16.26.35.46.59.44.51,0,.48-.7.48-1.59l-.02-4.59c0-.87.03-1.57-.48-1.57-.39,0-.71.39-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.42,503.43l1.28-.03c.17,0,.31-.12.44-.28v-1.17c0-.76.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.36v6.57c0,.77-.35,1.38-.8,1.4l-2.82.02c-.46,0-.83-.61-.83-1.38v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.22-.46.42l-.59,1.2c-.15.28-.35.44-.59.44-.51,0-.48-.7-.48-1.56v-4.6c-.01-.89-.05-1.6.46-1.6.39,0,.72.41.86.98,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.12,509.29h-1.29c-.17.01-.31-.11-.43-.27v-1.17c0-.78-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.36,1.37.82,1.37l2.82-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.47.4l.59,1.19c.15.28.35.46.6.44.51-.01.48-.7.48-1.59v-4.59c-.01-.87,0-1.56-.5-1.56-.39,0-.71.39-.86.96,0,0,.01.56-.78,1.08Z"/>
            <path   d="m396.35,514.99l1.28-.02c.18,0,.32-.12.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.35v6.58c.01.77-.35,1.39-.79,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.18-.44-.18h-1.28c-.16,0-.33.2-.47.42l-.59,1.2c-.16.28-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.88-.05-1.6.46-1.6.39,0,.71.4.86.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.12,486.23h-1.29c-.17,0-.31-.11-.43-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.75.36,1.37.82,1.36l2.82-.03c.46,0,.81-.61.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.47.41l.59,1.19c.15.27.35.46.6.44.51,0,.48-.7.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,.01.55-.78,1.08Z"/>
            <path   d="m396.35,491.93l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.35-1.36.81-1.36l2.82-.03c.45,0,.82.61.82,1.35v6.57c.01.77-.35,1.38-.79,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.33.21-.47.42l-.59,1.19c-.16.28-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.89-.05-1.6.46-1.6.39,0,.71.4.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.19,520.78h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.37l2.82-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.19c.16.27.35.46.59.44.51-.01.48-.7.48-1.58l-.02-4.61c0-.86.03-1.56-.48-1.56-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,526.48l1.28-.03c.17,0,.31-.12.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.36v6.57c0,.77-.35,1.39-.8,1.4l-2.82.02c-.46,0-.83-.62-.83-1.38v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.28-.35.45-.59.45-.51,0-.48-.72-.48-1.57v-4.6c-.01-.89-.05-1.61.46-1.61.39,0,.72.41.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.26,532.34h-1.29c-.18,0-.32-.1-.44-.26v-1.18c0-.76-.37-1.38-.82-1.36l-2.83.02c-.45,0-.81.6-.81,1.36v6.57c.01.75.38,1.38.83,1.37l2.83-.03c.46,0,.82-.62.82-1.37v-1.27c.21-.14.43-.19.43-.19h1.29c.15-.01.32.19.46.4l.6,1.19c.15.27.35.45.59.44.52-.01.48-.7.48-1.59v-4.59c-.01-.87,0-1.56-.49-1.56-.4,0-.72.39-.86.96,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.49,538.04l1.28-.02c.17,0,.31-.13.44-.28v-1.18c-.01-.75.36-1.37.82-1.37l2.81-.02c.46,0,.83.62.83,1.35v6.57c0,.77-.35,1.39-.8,1.4l-2.82.02c-.46,0-.82-.62-.82-1.38v-1.28c-.23-.16-.44-.17-.44-.17h-1.29c-.15.01-.31.21-.46.42l-.59,1.19c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.56v-4.61c0-.88-.04-1.6.47-1.6.39,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.54,543.8h-1.29c-.18.01-.33-.1-.44-.26v-1.18c0-.77-.37-1.37-.82-1.35h-2.83c-.45.01-.83.62-.82,1.39v6.56c.01.74.39,1.37.84,1.37l2.82-.02c.46,0,.82-.62.82-1.38v-1.27c.21-.15.44-.19.44-.19h1.28c.15,0,.33.19.47.41l.59,1.19c.15.27.35.45.59.44.51,0,.48-.7.48-1.58l-.02-4.59c0-.87.02-1.57-.47-1.57-.4,0-.72.39-.87.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.69,549.5l1.29-.02c.17,0,.31-.13.44-.28v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.02c.45-.02.82.61.82,1.35l.02,6.57c0,.77-.36,1.38-.81,1.39l-2.83.02c-.46,0-.82-.62-.82-1.38v-1.29c-.23-.15-.44-.16-.44-.16h-1.28c-.16,0-.33.2-.47.41l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.4,555.15h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.36l2.82-.03c.46,0,.82-.61.82-1.36v-1.28c.21-.15.43-.2.43-.2h1.28c.16,0,.33.2.47.42l.6,1.18c.15.26.34.46.59.44.5-.01.48-.7.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.39,0-.72.39-.86.96,0,0,.01.55-.78,1.08Z"/>
            <path   d="m396.62,560.85l1.28-.02c.18,0,.32-.13.44-.28v-1.18c0-.75.36-1.37.81-1.37l2.82-.02c.46,0,.82.61.82,1.36v6.57c.01.76-.34,1.38-.79,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.15-.44-.16-.44-.16h-1.28c-.16,0-.32.2-.46.41l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.46-1.6.39,0,.71.4.86.96,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.45,566.65h-1.29c-.18,0-.32-.11-.44-.27v-1.17c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.36.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.29c.15,0,.32.2.46.41l.59,1.19c.15.27.34.46.59.45.51-.01.48-.71.48-1.6v-4.59c-.01-.86.01-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.69,572.36l1.27-.03c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.83-.62-.83-1.39v-1.27c-.22-.15-.44-.18-.44-.18l-1.29.02c-.15,0-.32.2-.46.41l-.59,1.18c-.15.28-.35.45-.6.45-.51,0-.48-.71-.48-1.56v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.45,589.63h-1.29c-.18.01-.32-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.73.37,1.37.82,1.37l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.4l.59,1.19c.15.27.34.45.59.44.51,0,.48-.7.48-1.59v-4.59c-.01-.87.01-1.58-.5-1.58-.39,0-.71.4-.86.98,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.69,595.34l1.27-.03c.18,0,.32-.12.44-.27v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.41h-2.83c-.46.01-.83-.6-.83-1.37v-1.27c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.42l-.59,1.18c-.15.29-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.41.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.39,601.2h-1.28c-.18,0-.33-.11-.45-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.37l.03,6.56c0,.75.36,1.37.81,1.37l2.83-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.29c.16,0,.32.19.46.41l.59,1.18c.15.27.35.46.59.44.51,0,.48-.7.48-1.59v-4.58c-.01-.87,0-1.58-.5-1.58-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.62,606.9l1.28-.03c.17,0,.31-.13.44-.28v-1.16c0-.75.35-1.37.82-1.37l2.82-.03c.45,0,.82.63.82,1.37l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.39v-1.28c-.23-.14-.44-.17-.44-.17h-1.29c-.15.01-.32.21-.46.43l-.58,1.2c-.16.28-.35.44-.6.44-.52,0-.48-.7-.48-1.57v-4.59c0-.9-.05-1.61.46-1.61.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.39,578.14h-1.28c-.18.01-.33-.11-.45-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.37l.03,6.57c0,.74.36,1.37.81,1.36l2.83-.03c.46,0,.82-.61.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.29c.16-.01.32.19.46.4l.59,1.19c.15.26.35.45.59.44.51-.01.48-.71.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.62,583.85l1.28-.03c.17,0,.31-.12.44-.28v-1.16c0-.75.35-1.37.82-1.37l2.82-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.78-.36,1.4-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.39v-1.27c-.23-.16-.44-.18-.44-.18h-1.29c-.15.01-.32.21-.46.42l-.58,1.2c-.16.27-.35.44-.6.44-.52,0-.48-.69-.48-1.56v-4.6c0-.89-.05-1.6.46-1.6.4,0,.71.4.86.97,0,0,0,.59.8,1.1Z"/>
          </g>
          <g>
            <path   d="m395.45,612.69h-1.29c-.18,0-.32-.12-.44-.28v-1.16c0-.76-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.56c0,.74.37,1.37.82,1.37l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.41l.59,1.18c.15.27.34.45.59.45.51-.01.48-.71.48-1.59v-4.59c-.01-.87.01-1.58-.5-1.58-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.69,618.39l1.27-.03c.18,0,.32-.13.44-.28v-1.17c0-.74.36-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.83-.61-.83-1.38v-1.28c-.22-.15-.44-.18-.44-.18h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.27-.35.44-.6.44-.51,0-.48-.7-.48-1.56v-4.6c0-.88-.05-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.53,624.26h-1.29c-.17,0-.32-.12-.44-.28v-1.17c0-.77-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.6-.82,1.37l.02,6.57c0,.73.37,1.36.83,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.41l.59,1.19c.16.26.34.44.59.43.51,0,.48-.71.48-1.59v-4.58c-.02-.86.01-1.58-.49-1.58-.39,0-.71.4-.85.98,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.75,629.95l1.29-.03c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.82-1.37l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.4-.81,1.4l-2.83.02c-.45,0-.82-.61-.82-1.39v-1.27c-.22-.16-.44-.16-.44-.16h-1.29c-.16,0-.32.2-.46.41l-.59,1.2c-.16.28-.36.44-.6.44-.52,0-.49-.7-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.39,0,.72.4.86.97,0,0,0,.58.79,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.4,636.11h-1.28c-.17.01-.32-.1-.44-.27v-1.16c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.83.61-.81,1.38v6.56c.01.74.38,1.37.83,1.37l2.84-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.59,1.19c.15.26.35.45.59.44.51,0,.48-.71.48-1.6v-4.58c-.01-.86.01-1.58-.49-1.58-.39,0-.72.4-.86.98,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.56,641.82l1.29-.03c.17,0,.32-.13.44-.27v-1.17c-.01-.75.35-1.38.81-1.38l2.83-.02c.45,0,.82.62.82,1.36l.02,6.56c0,.78-.36,1.39-.81,1.41h-2.83c-.46,0-.82-.6-.82-1.36v-1.28c-.23-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.19c-.15.28-.35.44-.6.44-.51,0-.49-.7-.49-1.56v-4.6c-.01-.89-.05-1.6.47-1.6.39,0,.71.4.85.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.27,647.47h-1.29c-.18,0-.32-.11-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.46.01-.82.62-.82,1.39v6.56c.01.74.39,1.36.85,1.36l2.82-.03c.46,0,.81-.62.81-1.37v-1.26c.22-.16.44-.2.44-.2h1.29c.16-.01.33.2.47.4l.59,1.19c.15.27.35.44.59.44.52-.01.49-.7.49-1.59l-.02-4.59c0-.87.02-1.57-.48-1.57-.4,0-.71.39-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.49,653.17l1.28-.03c.18,0,.32-.12.44-.28v-1.16c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.15-.44-.18-.44-.18h-1.29c-.16,0-.33.21-.47.42l-.59,1.2c-.15.28-.35.44-.59.44-.52,0-.49-.71-.49-1.57l-.02-4.6c0-.89-.04-1.6.48-1.6.4,0,.72.39.85.96,0,0,0,.59.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.33,658.96l-1.29.02c-.17,0-.32-.13-.44-.28v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.46.01-.82.63-.82,1.39l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.03c.46,0,.81-.62.81-1.37v-1.28c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.46.4l.59,1.19c.15.26.35.45.6.44.5,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.86.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.55,664.65h1.28c.18-.01.32-.14.44-.29v-1.18c0-.74.36-1.36.81-1.36l2.83-.02c.45-.02.81.61.81,1.35l.02,6.57c0,.77-.36,1.38-.81,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.41l-.59,1.19c-.16.29-.35.45-.6.45-.51,0-.49-.7-.49-1.57v-4.59c0-.89-.04-1.6.47-1.6.4,0,.71.41.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.33,681.94l-1.29.02c-.17,0-.32-.11-.44-.28v-1.18c0-.76-.37-1.37-.82-1.35h-2.83c-.46.01-.82.63-.82,1.39l.02,6.57c0,.74.37,1.37.82,1.37l2.83-.03c.46,0,.81-.61.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.29c.15,0,.32.2.46.42l.59,1.18c.15.27.35.46.6.44.5-.01.48-.7.48-1.58v-4.6c-.02-.87,0-1.56-.49-1.56-.4,0-.72.39-.86.96,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.55,687.64h1.28c.18-.01.32-.14.44-.3v-1.16c0-.76.36-1.37.81-1.37l2.83-.02c.45-.02.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.03c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.16-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.6.45-.51,0-.49-.71-.49-1.56v-4.6c0-.89-.04-1.6.47-1.6.4,0,.71.39.86.96,0,0,0,.58.79,1.08Z"/>
          </g>
          <path   d="m395.26,693.5l-1.29.02c-.17,0-.31-.13-.44-.28v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.61-.81,1.36v6.58c.01.74.38,1.37.83,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.15.44-.2.44-.2h1.29c.14,0,.32.2.46.41l.59,1.19c.15.26.35.46.59.44.51,0,.48-.71.48-1.59v-4.59c-.02-.87.01-1.56-.49-1.56-.4,0-.71.4-.86.97,0,0,0,.55-.78,1.07Z"/>
          <g>
            <path   d="m395.26,670.45h-1.29c-.17,0-.31-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.37l-2.83.02c-.46,0-.82.61-.81,1.37v6.57c.01.75.38,1.37.83,1.36l2.83-.02c.45-.01.81-.63.81-1.37v-1.29c.22-.14.44-.18.44-.18h1.29c.14,0,.32.18.46.41l.59,1.18c.15.26.35.45.59.44.51,0,.48-.71.48-1.6v-4.59c-.02-.87.01-1.57-.49-1.57-.4,0-.71.4-.86.97,0,0,0,.55-.78,1.09Z"/>
            <path   d="m396.48,676.15l1.28-.02c.17,0,.32-.12.44-.28v-1.18c-.02-.75.35-1.36.8-1.36l2.83-.02c.46-.02.82.61.82,1.34l.02,6.58c0,.77-.36,1.39-.82,1.4h-2.82c-.46.01-.83-.61-.83-1.37v-1.28c-.22-.16-.43-.17-.43-.17h-1.29c-.15,0-.32.2-.46.41l-.6,1.2c-.15.27-.34.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.57.8,1.08Z"/>
          </g>
        </g>
        <g>
          <polyline   points="380.37 701.21 380.36 701.96 380.37 701.21"/>
          <line   x1="380.37" y1="699.71" x2="382.54" y2="66.79"/>
          <polyline   points="382.54 66.04 382.54 65.29 382.54 66.04"/>
          <line   x1="382.53" y1="67.54" x2="380.37" y2="700.46"/>
        </g>
        <g>
          <line   x1="408.26" y1="65.44" x2="408.27" y2="66.19"/>
          <line   x1="408.28" y1="67.69" x2="411.6" y2="701.66"/>
          <line   x1="411.61" y1="702.41" x2="411.61" y2="703.16"/>
        </g>
        <g id="CIerre">
          <path   d="m397.33,77.52l.41-.34,1.25,1.67s1.98,3.4,4.26,1.52c2.19-1.8-.39-4.34-.39-4.34l-1.31-1.72.42-.32s11.88,7.89,14.32,11.87c1.05,1.72,2.24,4.22,1.02,6.38-1.12,1.99-5.91,5.58-8.24,4.97-1.06-.27-3.19-1.24-4.47-3.21-1.04-1.6-2.67-4.43-3.95-7.42-1.91-4.44-3.33-9.06-3.33-9.06Z"/>
          <path   d="m396.59,77.42l.91-.57-3.73-4.94s-.82-2.03.79-3.15c1.78-1.24,3.29.49,3.29.49l3.48,4.65.86-.66,5.42,3.56s-.13-1.93-1.75-3.25c-1.41-1.15-5.07-2.35-5.07-2.35,0,0-.88-.73-1.14-1.02-.96-1.12-3.75-4.65-3.75-4.65l-5.03,3.83s2.51,3.37,3.29,4.5c.25.36.84,1.48.84,1.48,0,0-.05,2.73.91,5.55.58,1.7,2.69,2.69,2.69,2.69l-1.99-6.18Z"/>
          <path   d="m405.34,91.33c.61,1.06,1.78,2.84,2.81,2.77,1.68-.13,6.63-3.81,7.23-5.37.36-.93-.86-2.17-1.58-3.09-.86-1.1-2.25-1.96-3.42-1.83-1.19.13-5.22,3.13-5.8,4.14-.48.84.18,2.38.76,3.38Z"/>
          <path   d="m394.88,71.73s-.5-1.27.42-1.94c.91-.67,1.95.19,1.95.19l5.53,7.29s1.03,1.7-.05,2.45c-.95.65-2.26-.6-2.26-.6l-5.59-7.39Z"/>
        </g>
        <polygon   points="397.27 686.92 403.3 686.92 403.37 703.46 389.3 703.4 389.17 696.89 397.41 696.89 397.27 686.92"/>
      </g>
      <g id="Cuello_redondo" data-name="Cuello redondo">
        <path   d="m304,78.99s10.14-23.57,16.18-29.03c3.41-3.08,11.35-2.85,15.57-3.24,9.1-.85,18.24-1.2,27.37-1.48,26.25-.8,52.24-.71,78.48.25,12.19.45,24.38.93,36.57,1.45.13-.03,12.33,13.58,14.33,29.58"/>
        <path   d="m331.12,81.6s57.07-3.45,65.83-3.46c10.98,0,63.31,1.2,69.88,1.35"/>
        <path   d="m319.9,49.96c2.73,9.05,5.88,17.99,9.43,26.75,2.7,6.67,5.57,13.44,9.34,19.59,6.34,10.35,17.42,18.96,26.9,26.35,7.01,5.47,15.22,10.49,19.41,18.39"/>
        <path   d="m478.17,46.92s-6.97,26.92-17.97,45.76c-2.38,4.07-23.16,26.09-27.01,28.71-12.5,8.5-19.09,14.45-22.54,19.65"/>
        <path id="Línea_1_cuello_R_izquierda" data-name="Línea 1 cuello R izquierda"   d="m310,66.18s12.87,34.69,24.52,47.42c12.37,13.52,24.56,20.31,31.89,24.75"/>
        <path id="Línea_2_cuello_R_izquierda" data-name="Línea 2 cuello R izquierda"   d="m314,58.18s12.87,34.69,24.52,47.42c12.37,13.52,32.65,30.46,39.99,34.9"/>
        <path id="Línea_1_cuello_R_derecha" data-name="Línea 1 cuello R derecha"   d="m488.5,62.5c-2.71,8.04-5.58,15.25-9,23-3.09,7.01-8.23,12.52-12.43,18.94-4.43,6.77-9.86,12.3-15.7,17.83-5.15,4.88-10.38,9.71-15.86,14.22"/>
        <path id="Línea_2_cuello_R_derecha" data-name="Línea 2 cuello R derecha"   d="m484.29,55.14c-4.4,13.01-9.59,26.05-16.82,37.75-4.76,7.71-26.5,30.93-33.62,37.02-3.76,3.22-7.52,6.45-11.34,9.59"/>
      </g>
      <g id="Cierre_cuello_redondo" data-name="Cierre cuello redondo">
        <polyline   points="404.21 141 406.05 704.54 386.48 704.54 386.62 141"/>
        <g>
          <g>
            <path   d="m394.93,152.65h-1.29c-.17.01-.31-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.81.61-.81,1.37l.02,6.57c0,.74.36,1.36.82,1.36l2.83-.03c.45,0,.81-.61.81-1.36v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.33.19.46.41l.59,1.19c.15.26.35.45.6.44.51-.01.48-.7.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.39-.86.97,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.16,158.34l1.28-.02c.18,0,.31-.12.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.44,0-.81-.61-.81-1.39v-1.27c-.23-.16-.44-.18-.44-.18h-1.28c-.16,0-.32.21-.47.42l-.59,1.19c-.15.28-.34.44-.59.44-.53,0-.5-.71-.5-1.56v-4.61c0-.88-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m388.75,140.84v3.96c.01.74.37,1.36.83,1.36l2.83-.03c.45,0,.81-.61.81-1.36v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.33.19.46.41l.59,1.19c.15.26.35.45.6.44.51-.01.48-.7.48-1.59v-2.94s-8.33.03-8.33.03Z"/>
            <path   d="m396.16,145.34l1.28-.02c.18,0,.31-.12.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.44,0-.81-.61-.81-1.39v-1.27c-.23-.16-.44-.18-.44-.18h-1.28c-.16,0-.32.21-.47.42l-.59,1.19c-.15.28-.34.44-.59.44-.53,0-.5-.71-.5-1.56v-4.61c0-.88-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,164.21h-1.29c-.17,0-.31-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.73.36,1.37.82,1.36l2.82-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.46.4l.59,1.18c.15.26.35.45.6.44.51-.01.47-.7.47-1.59v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.24,169.91l1.28-.03c.18,0,.31-.13.43-.28v-1.16c0-.76.37-1.38.81-1.38l2.82-.03c.46,0,.82.62.82,1.37l.02,6.56c0,.78-.36,1.4-.81,1.4l-2.83.02c-.45,0-.83-.61-.83-1.38v-1.27c-.22-.16-.43-.18-.43-.18h-1.29c-.15.01-.31.21-.47.42l-.59,1.19c-.14.28-.34.44-.58.44-.52,0-.49-.7-.49-1.56v-4.6c0-.88-.04-1.6.47-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.23,175.82h-1.29c-.17,0-.32-.11-.44-.27v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.83.61-.82,1.37l.02,6.57c0,.73.36,1.36.82,1.35l2.82-.02c.45-.01.82-.62.82-1.37v-1.28c.22-.15.43-.19.43-.19h1.3c.15,0,.32.19.46.41l.6,1.19c.16.26.35.45.6.44.5,0,.47-.71.47-1.6v-4.58c-.02-.87.01-1.58-.49-1.58-.39,0-.72.39-.85.97,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.38,181.52l1.28-.02c.17,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.83-.03c.45-.01.82.62.82,1.36v6.56c.01.77-.34,1.39-.8,1.4l-2.84.02c-.43,0-.81-.62-.81-1.38v-1.28c-.23-.15-.44-.16-.44-.16h-1.28c-.15,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.44-.59.44-.52,0-.5-.7-.5-1.57v-4.59c-.01-.89-.04-1.61.47-1.61.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.08,187.17h-1.29c-.17.01-.32-.1-.44-.26v-1.18c0-.76-.36-1.38-.83-1.36h-2.82c-.45,0-.82.63-.82,1.38l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.28c.22-.15.43-.19.43-.19h1.3c.15,0,.31.19.46.41l.58,1.19c.16.26.35.45.6.44.51,0,.47-.71.47-1.59v-4.59c-.01-.87,0-1.58-.49-1.58-.39,0-.71.4-.85.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.32,192.88l1.28-.02c.18,0,.31-.13.44-.28v-1.17c0-.75.35-1.37.82-1.37l2.81-.03c.46,0,.83.62.83,1.36l.02,6.57c0,.77-.36,1.39-.82,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.27c-.23-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.21-.46.42l-.58,1.19c-.16.28-.35.44-.59.44-.52,0-.49-.7-.49-1.57v-4.59c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.14,198.67h-1.29c-.17.01-.32-.11-.44-.26v-1.17c0-.77-.36-1.37-.82-1.37l-2.82.02c-.45,0-.82.61-.82,1.36l.02,6.57c0,.75.36,1.38.82,1.37l2.83-.03c.44,0,.81-.63.81-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.16,0,.32.19.47.4l.59,1.18c.16.27.34.46.6.44.51,0,.47-.71.47-1.58v-4.59c-.02-.87,0-1.58-.49-1.58-.39,0-.71.4-.85.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.37,204.37l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.35v6.57c.01.77-.34,1.38-.79,1.4h-2.83c-.45.01-.82-.6-.82-1.36v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.72.4.87.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.14,221.65h-1.29c-.17,0-.32-.11-.44-.27v-1.16c0-.77-.36-1.38-.82-1.37l-2.82.02c-.45,0-.82.6-.82,1.36l.02,6.57c0,.74.36,1.37.82,1.37l2.83-.03c.44,0,.81-.62.81-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.16-.01.32.19.47.41l.59,1.19c.16.27.34.46.6.44.51-.02.47-.7.47-1.59v-4.6c-.02-.86,0-1.57-.49-1.57-.39,0-.71.4-.85.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.37,227.35l1.28-.02c.17,0,.31-.12.44-.28v-1.17c0-.75.36-1.36.81-1.36l2.82-.03c.46,0,.82.62.82,1.35v6.57c.01.78-.34,1.39-.79,1.4h-2.83c-.45.01-.82-.6-.82-1.36v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.07,233.21h-1.28c-.18.01-.33-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.36,1.37.81,1.37l2.84-.04c.44,0,.81-.61.81-1.37v-1.26c.21-.15.43-.2.43-.2h1.29c.16-.01.32.2.47.41l.58,1.18c.16.27.35.46.6.44.51-.01.48-.7.48-1.58v-4.6c-.02-.87,0-1.57-.5-1.57-.4,0-.72.4-.85.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.3,238.91l1.28-.02c.18,0,.32-.12.44-.28v-1.18c-.01-.75.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.38-.82,1.39l-2.82.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.33.2-.47.42l-.59,1.19c-.16.28-.35.45-.58.45-.53,0-.5-.71-.5-1.57v-4.6c-.01-.88-.04-1.6.46-1.6.4,0,.72.4.88.96,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.07,210.16h-1.28c-.18.01-.33-.11-.44-.26v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.56c0,.75.36,1.38.81,1.37l2.84-.03c.44-.01.81-.62.81-1.37v-1.28c.21-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.41l.58,1.18c.16.27.35.46.6.44.51,0,.48-.69.48-1.58v-4.59c-.02-.86,0-1.56-.5-1.56-.4,0-.72.39-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.3,215.86l1.28-.02c.18,0,.32-.13.44-.28v-1.17c-.01-.75.36-1.36.81-1.36l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.78-.36,1.39-.82,1.4l-2.82.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.33.2-.47.42l-.59,1.2c-.16.28-.35.45-.58.45-.53,0-.5-.71-.5-1.57v-4.6c-.01-.89-.04-1.61.46-1.61.4,0,.72.4.88.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.14,244.7h-1.29c-.17.01-.32-.11-.44-.26v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.03c-.45,0-.82.6-.82,1.36l.02,6.57c0,.75.36,1.37.82,1.37l2.83-.03c.44,0,.81-.62.81-1.38v-1.27c.21-.14.43-.2.43-.2h1.29c.16,0,.32.2.47.41l.59,1.2c.16.26.34.46.6.43.51,0,.47-.7.47-1.59v-4.59c-.02-.86,0-1.56-.49-1.56-.39,0-.71.4-.85.96,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.37,250.41l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.76.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36v6.57c.01.77-.34,1.4-.79,1.4l-2.83.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.27-.35.45-.59.45-.52,0-.5-.71-.5-1.57v-4.6c0-.89-.04-1.61.47-1.61.4,0,.72.4.87.98,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.21,256.27h-1.29c-.17,0-.32-.12-.44-.27v-1.17c0-.77-.36-1.38-.82-1.37l-2.83.02c-.44,0-.82.61-.81,1.37l.02,6.56c0,.75.36,1.37.82,1.37l2.82-.03c.45,0,.82-.62.82-1.37v-1.27c.21-.15.43-.19.43-.19h1.29c.15,0,.32.19.46.4l.59,1.18c.16.27.35.46.6.44.51,0,.48-.7.48-1.58v-4.6c-.02-.87,0-1.57-.5-1.57-.4,0-.72.39-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.44,261.97l1.28-.02c.17,0,.31-.13.44-.28v-1.17c0-.75.36-1.37.82-1.37l2.81-.03c.46,0,.82.62.82,1.35l.02,6.58c0,.77-.36,1.38-.81,1.39l-2.83.02c-.45,0-.83-.61-.83-1.38v-1.27c-.22-.17-.44-.18-.44-.18h-1.28c-.15,0-.32.21-.47.42l-.58,1.2c-.16.28-.36.45-.59.45-.53,0-.49-.71-.49-1.57v-4.6c0-.88-.05-1.6.46-1.6.4,0,.72.4.87.96,0,0,0,.58.79,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395,268.09h-1.29c-.17.01-.32-.1-.43-.26v-1.17c-.01-.77-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.37,1.37.82,1.36l2.82-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.15.44-.18.44-.18h1.29c.15,0,.32.18.46.4l.59,1.19c.15.26.35.45.6.45.51,0,.47-.71.47-1.6v-4.58c-.02-.87,0-1.58-.5-1.58-.39,0-.71.4-.85.98,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.16,273.79l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.83-.02c.45-.01.82.61.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.17-.44-.17h-1.29c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.44-.59.44-.52,0-.49-.69-.49-1.56v-4.59c-.01-.9-.05-1.6.46-1.6.4,0,.72.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.87,279.45h-1.29c-.18,0-.32-.11-.44-.27v-1.17c-.01-.76-.38-1.38-.83-1.37l-2.83.02c-.45,0-.82.62-.82,1.37l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.02c.45-.01.81-.62.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.28c.16,0,.32.2.46.42l.59,1.18c.16.27.35.45.59.44.51,0,.48-.71.48-1.6v-4.58c-.01-.88,0-1.58-.5-1.58-.4,0-.71.39-.86.97,0,0,0,.55-.78,1.09Z"/>
            <path   d="m396.09,285.15l1.28-.02c.18,0,.32-.13.44-.28v-1.18c0-.75.35-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36v6.56c.01.77-.34,1.39-.8,1.4l-2.83.02c-.46,0-.82-.61-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16.01-.33.21-.46.42l-.59,1.2c-.15.27-.35.44-.59.44-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.39,0,.71.39.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,290.94h-1.28c-.17.01-.32-.11-.44-.27v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.36l2.82-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.28c.16,0,.33.2.47.41l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,296.64l1.28-.03c.17,0,.31-.12.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.36l.02,6.58c0,.77-.36,1.38-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.27c-.24-.16-.45-.18-.45-.18h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.59c0-.9-.05-1.61.47-1.61.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,313.92l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.36l-2.83.03c-.45,0-.82.6-.81,1.37v6.56c.01.75.38,1.37.84,1.37l2.82-.03c.45,0,.81-.62.81-1.38v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.4l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,319.63l1.28-.03c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.81-.62-.81-1.39v-1.27c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.21-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m394.86,325.48h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.37l-2.83.03c-.46,0-.82.61-.82,1.36l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.03c.46,0,.81-.62.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.4l.59,1.19c.15.26.35.46.59.44.51,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.57-.49-1.57-.4,0-.72.4-.85.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.09,331.19l1.28-.02c.17,0,.3-.13.44-.28v-1.17c-.01-.75.35-1.38.81-1.38l2.82-.02c.46,0,.82.61.82,1.35l.02,6.58c0,.77-.36,1.39-.82,1.4h-2.83c-.45.01-.82-.6-.82-1.37v-1.28c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.42l-.59,1.19c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,302.43h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.6-.82,1.36l.02,6.57c0,.75.37,1.37.82,1.37l2.83-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.41l.59,1.19c.15.27.35.46.59.44.51,0,.48-.7.48-1.59v-4.59c-.02-.86,0-1.56-.49-1.56-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,308.13l1.28-.02c.17,0,.3-.12.44-.28v-1.17c-.01-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.35l.02,6.57c0,.78-.36,1.38-.82,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.88-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,336.98h-1.28c-.17.01-.32-.11-.44-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.37l2.82-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.18.44-.18h1.28c.16-.01.33.18.47.4l.6,1.19c.15.28.35.46.6.44.5,0,.48-.69.48-1.58v-4.6c-.02-.86,0-1.56-.5-1.56-.39,0-.71.39-.86.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,342.68l1.28-.02c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.36l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,348.53l-1.29.02c-.17,0-.32-.12-.44-.27v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.56c0,.75.36,1.38.82,1.37l2.83-.03c.45,0,.81-.62.81-1.38v-1.27c.22-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.4l.59,1.19c.15.27.36.45.6.44.51,0,.47-.7.47-1.59v-4.6c-.01-.86.01-1.56-.49-1.56-.4,0-.72.4-.87.98,0,0,0,.55-.77,1.07Z"/>
            <path   d="m396.22,354.24l1.28-.03c.17,0,.31-.12.44-.27v-1.18c0-.75.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.35,1.39-.81,1.4h-2.83c-.45.01-.82-.61-.82-1.37v-1.27c-.22-.16-.44-.17-.44-.17h-1.28c-.16,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.49-.7-.49-1.56l-.02-4.6c0-.89-.04-1.6.48-1.6.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395,360.09h-1.29c-.17.01-.32-.11-.43-.26v-1.16c-.01-.77-.37-1.38-.82-1.37l-2.83.03c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.37,1.37.82,1.37l2.82-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.14.44-.18.44-.18h1.29c.15-.01.32.18.46.4l.59,1.19c.15.27.35.45.6.44.51,0,.47-.7.47-1.59v-4.6c-.02-.86,0-1.57-.5-1.57-.39,0-.71.4-.85.97,0,0,0,.57-.79,1.08Z"/>
            <path   d="m396.16,365.8l1.28-.02c.18,0,.32-.13.44-.27v-1.17c0-.76.36-1.38.81-1.38l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.18-.44-.18h-1.29c-.16,0-.32.2-.46.42l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c-.01-.89-.05-1.6.46-1.6.4,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.87,371.45h-1.29c-.18.01-.32-.11-.44-.27v-1.16c-.01-.77-.38-1.38-.83-1.37l-2.83.02c-.45,0-.82.61-.82,1.38l.02,6.56c0,.74.37,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.32.19.46.4l.59,1.19c.16.26.35.45.59.44.51-.01.48-.71.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.4,0-.71.39-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.09,377.15l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.35-1.36.81-1.36l2.83-.04c.45,0,.82.63.82,1.36v6.57c.01.77-.34,1.39-.8,1.41h-2.83c-.46.01-.82-.61-.82-1.37v-1.27c-.22-.15-.44-.18-.44-.18h-1.28c-.16.01-.33.21-.46.43l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.59.79,1.09Z"/>
          </g>
          <g>
            <path   d="m394.93,382.95h-1.28c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.45.01-.82.63-.81,1.38v6.57c.01.74.38,1.37.84,1.36l2.82-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.19c.15.26.35.45.6.44.5,0,.48-.71.48-1.59v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.96,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.15,388.65l1.28-.02c.17,0,.31-.13.44-.27v-1.18c-.01-.75.36-1.36.81-1.36l2.83-.03c.46,0,.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.03c-.46,0-.81-.62-.81-1.38v-1.27c-.24-.16-.45-.18-.45-.18h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,405.93l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.35h-2.83c-.45.01-.82.63-.81,1.39v6.56c.01.75.38,1.38.84,1.37l2.82-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.19c.15.26.35.45.6.44.5,0,.48-.71.48-1.59v-4.6c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.15,411.64l1.28-.03c.17,0,.31-.13.44-.28v-1.17c-.01-.75.36-1.36.81-1.36l2.83-.03c.46-.01.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.16-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.51,0-.49-.71-.49-1.57v-4.59c0-.9-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,417.49l-1.29.02c-.17,0-.32-.13-.44-.28v-1.17c0-.76-.37-1.37-.82-1.36h-2.83c-.46.01-.82.63-.82,1.38l.02,6.57c0,.74.37,1.38.82,1.36l2.83-.03c.46,0,.81-.61.81-1.37v-1.28c.22-.14.44-.19.44-.19h1.29c.15,0,.32.19.47.41l.59,1.18c.15.27.35.46.59.45.51-.01.48-.71.48-1.6v-4.58c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,423.19h1.28c.17-.01.3-.15.44-.29v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.38-.82,1.4l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.41l-.59,1.2c-.15.27-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.05-1.6.46-1.6.4,0,.72.4.87.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.86,394.44h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.36l.02,6.58c0,.73.37,1.37.82,1.36l2.83-.02c.46-.01.81-.63.81-1.38v-1.27c.22-.14.44-.2.44-.2h1.29c.15,0,.32.2.47.42l.59,1.19c.15.26.35.46.59.44.51,0,.48-.7.48-1.59v-4.58c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.85.97,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.09,400.14l1.28-.02c.17,0,.3-.13.44-.27v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.02c.46,0,.82.61.82,1.35l.02,6.57c0,.77-.36,1.39-.82,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.15-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.41l-.59,1.2c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.58v-4.59c0-.89-.05-1.59.46-1.59.4,0,.72.4.87.96,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m394.93,428.98l-1.28.02c-.17,0-.32-.12-.44-.28v-1.18c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37v6.57c.01.74.38,1.37.84,1.37l2.82-.03c.45-.01.81-.62.81-1.38v-1.27c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.4l.6,1.19c.15.27.35.45.6.44.5,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.98,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.15,434.68h1.28c.17-.01.31-.14.44-.29v-1.17c-.01-.75.36-1.37.81-1.37l2.83-.03c.46,0,.81.62.81,1.35l.02,6.58c0,.77-.36,1.39-.81,1.39l-2.83.02c-.46,0-.81-.62-.81-1.38v-1.28c-.24-.15-.45-.17-.45-.17h-1.28c-.15,0-.32.2-.46.41l-.59,1.2c-.16.28-.35.45-.59.45-.51,0-.49-.7-.49-1.57v-4.59c0-.89-.05-1.6.47-1.6.4,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395,440.54h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.14.43-.19.43-.19h1.29c.16,0,.32.19.47.41l.59,1.18c.15.27.36.45.6.45.51-.01.47-.71.47-1.59v-4.59c-.01-.88.01-1.57-.49-1.57-.4,0-.72.39-.87.97,0,0,0,.55-.77,1.08Z"/>
            <path   d="m396.22,446.24l1.28-.02c.17,0,.31-.12.44-.27v-1.17c0-.76.36-1.37.81-1.37l2.83-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.77-.35,1.38-.81,1.39l-2.83.03c-.45,0-.82-.62-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16.01-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.59.45-.52,0-.49-.7-.49-1.56l-.02-4.59c0-.89-.04-1.61.48-1.61.4,0,.72.4.87.97,0,0,0,.57.79,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.27,451.89h-1.29c-.18.01-.32-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.62-.82,1.37l.02,6.57c0,.74.36,1.37.82,1.36l2.83-.03c.45,0,.81-.61.81-1.36v-1.29c.22-.14.44-.18.44-.18h1.29c.14,0,.32.18.46.4l.59,1.19c.15.26.35.44.59.44.51,0,.48-.71.48-1.6v-4.59c-.02-.87.01-1.57-.5-1.57-.39,0-.71.39-.85.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,457.59l1.29-.02c.17,0,.32-.13.44-.28v-1.17c0-.75.35-1.37.82-1.37l2.82-.03c.46,0,.82.62.82,1.36v6.57c.01.77-.34,1.39-.79,1.4h-2.83c-.46.01-.83-.61-.83-1.37v-1.27c-.22-.16-.44-.18-.44-.18l-1.29.02c-.15,0-.32.19-.46.41l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.69-.49-1.56v-4.6c-.01-.89-.05-1.6.47-1.6.39,0,.71.39.85.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.13,463.25h-1.29c-.17,0-.32-.11-.44-.27v-1.17c0-.76-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.62-.82,1.37l.03,6.57c0,.73.36,1.37.81,1.36l2.83-.03c.46,0,.81-.61.81-1.36v-1.28c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.6,1.18c.15.26.34.45.59.44.51,0,.48-.7.48-1.58v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.36,468.94l1.28-.02c.17,0,.31-.14.44-.28v-1.17c-.01-.75.35-1.37.81-1.37l2.82-.03c.46-.01.83.61.83,1.36l.02,6.56c0,.77-.36,1.39-.82,1.4l-2.83.03c-.45,0-.82-.63-.82-1.39v-1.27c-.23-.16-.44-.18-.44-.18l-1.28.02c-.16,0-.32.19-.46.41l-.59,1.2c-.15.28-.35.44-.59.44-.52,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.46-1.6.4,0,.71.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.19,474.74l-1.29.02c-.17,0-.32-.12-.44-.28v-1.16c0-.77-.36-1.39-.82-1.37l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.37l2.82-.03c.46,0,.82-.61.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.18c.16.27.35.46.59.44.51,0,.48-.7.48-1.58l-.02-4.59c0-.86.03-1.57-.48-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,480.45l1.28-.03c.17,0,.31-.12.44-.27v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.35v6.57c0,.77-.35,1.39-.8,1.4h-2.82c-.46.01-.83-.6-.83-1.37v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.41l-.59,1.19c-.15.28-.35.45-.59.45-.51,0-.48-.71-.48-1.57v-4.59c-.01-.89-.05-1.59.46-1.59.39,0,.72.4.86.96,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.19,497.73h-1.29c-.17,0-.32-.11-.44-.27v-1.18c0-.77-.36-1.37-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.36l2.82-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.19c.16.26.35.46.59.44.51,0,.48-.7.48-1.59l-.02-4.59c0-.87.03-1.57-.48-1.57-.39,0-.71.39-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.42,503.43l1.28-.03c.17,0,.31-.12.44-.28v-1.17c0-.76.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.36v6.57c0,.77-.35,1.38-.8,1.4l-2.82.02c-.46,0-.83-.61-.83-1.38v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.22-.46.42l-.59,1.2c-.15.28-.35.44-.59.44-.51,0-.48-.7-.48-1.56v-4.6c-.01-.89-.05-1.6.46-1.6.39,0,.72.41.86.98,0,0,0,.57.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.12,509.29h-1.29c-.17.01-.31-.11-.43-.27v-1.17c0-.78-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.74.36,1.37.82,1.37l2.82-.03c.46,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.47.4l.59,1.19c.15.28.35.46.6.44.51-.01.48-.7.48-1.59v-4.59c-.01-.87,0-1.56-.5-1.56-.39,0-.71.39-.86.96,0,0,.01.56-.78,1.08Z"/>
            <path   d="m396.35,514.99l1.28-.02c.18,0,.32-.12.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.82.62.82,1.35v6.58c.01.77-.35,1.39-.79,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.27c-.22-.16-.44-.18-.44-.18h-1.28c-.16,0-.33.2-.47.42l-.59,1.2c-.16.28-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.88-.05-1.6.46-1.6.39,0,.71.4.86.97,0,0,0,.57.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.12,486.23h-1.29c-.17,0-.31-.11-.43-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.81,1.37l.02,6.57c0,.75.36,1.37.82,1.36l2.82-.03c.46,0,.81-.61.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.47.41l.59,1.19c.15.27.35.46.6.44.51,0,.48-.7.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,.01.55-.78,1.08Z"/>
            <path   d="m396.35,491.93l1.28-.02c.18,0,.32-.13.44-.28v-1.17c0-.75.35-1.36.81-1.36l2.82-.03c.45,0,.82.61.82,1.35v6.57c.01.77-.35,1.38-.79,1.4l-2.83.02c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.15-.44-.17-.44-.17h-1.28c-.16,0-.33.21-.47.42l-.59,1.19c-.16.28-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.89-.05-1.6.46-1.6.39,0,.71.4.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.19,520.78h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.37l2.82-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.28c.16,0,.33.19.47.4l.59,1.19c.16.27.35.46.59.44.51-.01.48-.7.48-1.58l-.02-4.61c0-.86.03-1.56-.48-1.56-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.42,526.48l1.28-.03c.17,0,.31-.12.44-.28v-1.17c0-.75.35-1.37.81-1.37l2.82-.03c.45,0,.83.62.83,1.36v6.57c0,.77-.35,1.39-.8,1.4l-2.82.02c-.46,0-.83-.62-.83-1.38v-1.28c-.23-.15-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.28-.35.45-.59.45-.51,0-.48-.72-.48-1.57v-4.6c-.01-.89-.05-1.61.46-1.61.39,0,.72.41.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.26,532.34h-1.29c-.18,0-.32-.1-.44-.26v-1.18c0-.76-.37-1.38-.82-1.36l-2.83.02c-.45,0-.81.6-.81,1.36v6.57c.01.75.38,1.38.83,1.37l2.83-.03c.46,0,.82-.62.82-1.37v-1.27c.21-.14.43-.19.43-.19h1.29c.15-.01.32.19.46.4l.6,1.19c.15.27.35.45.59.44.52-.01.48-.7.48-1.59v-4.59c-.01-.87,0-1.56-.49-1.56-.4,0-.72.39-.86.96,0,0,0,.55-.79,1.08Z"/>
            <path   d="m396.49,538.04l1.28-.02c.17,0,.31-.13.44-.28v-1.18c-.01-.75.36-1.37.82-1.37l2.81-.02c.46,0,.83.62.83,1.35v6.57c0,.77-.35,1.39-.8,1.4l-2.82.02c-.46,0-.82-.62-.82-1.38v-1.28c-.23-.16-.44-.17-.44-.17h-1.29c-.15.01-.31.21-.46.42l-.59,1.19c-.15.28-.35.45-.59.45-.52,0-.49-.71-.49-1.56v-4.61c0-.88-.04-1.6.47-1.6.39,0,.72.4.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.54,543.8h-1.29c-.18.01-.33-.1-.44-.26v-1.18c0-.77-.37-1.37-.82-1.35h-2.83c-.45.01-.83.62-.82,1.39v6.56c.01.74.39,1.37.84,1.37l2.82-.02c.46,0,.82-.62.82-1.38v-1.27c.21-.15.44-.19.44-.19h1.28c.15,0,.33.19.47.41l.59,1.19c.15.27.35.45.59.44.51,0,.48-.7.48-1.58l-.02-4.59c0-.87.02-1.57-.47-1.57-.4,0-.72.39-.87.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.69,549.5l1.29-.02c.17,0,.31-.13.44-.28v-1.18c-.01-.75.35-1.37.81-1.37l2.82-.02c.45-.02.82.61.82,1.35l.02,6.57c0,.77-.36,1.38-.81,1.39l-2.83.02c-.46,0-.82-.62-.82-1.38v-1.29c-.23-.15-.44-.16-.44-.16h-1.28c-.16,0-.33.2-.47.41l-.59,1.19c-.15.28-.35.44-.59.44-.51,0-.49-.7-.49-1.56v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.39.86.97,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.4,555.15h-1.29c-.17.01-.32-.1-.44-.26v-1.17c0-.77-.36-1.38-.82-1.36l-2.82.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.37.83,1.36l2.82-.03c.46,0,.82-.61.82-1.36v-1.28c.21-.15.43-.2.43-.2h1.28c.16,0,.33.2.47.42l.6,1.18c.15.26.34.46.59.44.5-.01.48-.7.48-1.59v-4.59c-.02-.87,0-1.57-.49-1.57-.39,0-.72.39-.86.96,0,0,.01.55-.78,1.08Z"/>
            <path   d="m396.62,560.85l1.28-.02c.18,0,.32-.13.44-.28v-1.18c0-.75.36-1.37.81-1.37l2.82-.02c.46,0,.82.61.82,1.36v6.57c.01.76-.34,1.38-.79,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.15-.44-.16-.44-.16h-1.28c-.16,0-.32.2-.46.41l-.59,1.2c-.16.28-.35.45-.59.45-.52,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.46-1.6.39,0,.71.4.86.96,0,0,0,.58.8,1.08Z"/>
          </g>
          <g>
            <path   d="m395.45,566.65h-1.29c-.18,0-.32-.11-.44-.27v-1.17c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.74.37,1.36.82,1.36l2.83-.03c.45,0,.81-.61.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.29c.15,0,.32.2.46.41l.59,1.19c.15.27.34.46.59.45.51-.01.48-.71.48-1.6v-4.59c-.01-.86.01-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.69,572.36l1.27-.03c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.57c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.83-.62-.83-1.39v-1.27c-.22-.15-.44-.18-.44-.18l-1.29.02c-.15,0-.32.2-.46.41l-.59,1.18c-.15.28-.35.45-.6.45-.51,0-.48-.71-.48-1.56v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.45,589.63h-1.29c-.18.01-.32-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.57c0,.73.37,1.37.82,1.37l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.4l.59,1.19c.15.27.34.45.59.44.51,0,.48-.7.48-1.59v-4.59c-.01-.87.01-1.58-.5-1.58-.39,0-.71.4-.86.98,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.69,595.34l1.27-.03c.18,0,.32-.12.44-.27v-1.17c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.41h-2.83c-.46.01-.83-.6-.83-1.37v-1.27c-.22-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.2-.46.42l-.59,1.18c-.15.29-.35.45-.6.45-.51,0-.48-.71-.48-1.57v-4.6c0-.89-.05-1.6.47-1.6.39,0,.71.41.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.39,601.2h-1.28c-.18,0-.33-.11-.45-.27v-1.17c0-.76-.37-1.37-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.37l.03,6.56c0,.75.36,1.37.81,1.37l2.83-.03c.46,0,.82-.62.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.29c.16,0,.32.19.46.41l.59,1.18c.15.27.35.46.59.44.51,0,.48-.7.48-1.59v-4.58c-.01-.87,0-1.58-.5-1.58-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.09Z"/>
            <path   d="m396.62,606.9l1.28-.03c.17,0,.31-.13.44-.28v-1.16c0-.75.35-1.37.82-1.37l2.82-.03c.45,0,.82.63.82,1.37l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.39v-1.28c-.23-.14-.44-.17-.44-.17h-1.29c-.15.01-.32.21-.46.43l-.58,1.2c-.16.28-.35.44-.6.44-.52,0-.48-.7-.48-1.57v-4.59c0-.9-.05-1.61.46-1.61.4,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.39,578.14h-1.28c-.18.01-.33-.11-.45-.27v-1.17c0-.77-.37-1.37-.82-1.36l-2.83.02c-.46,0-.82.61-.82,1.37l.03,6.57c0,.74.36,1.37.81,1.36l2.83-.03c.46,0,.82-.61.82-1.37v-1.27c.22-.15.43-.19.43-.19h1.29c.16-.01.32.19.46.4l.59,1.19c.15.26.35.45.59.44.51-.01.48-.71.48-1.59v-4.59c-.01-.87,0-1.57-.5-1.57-.39,0-.71.4-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.62,583.85l1.28-.03c.17,0,.31-.12.44-.28v-1.16c0-.75.35-1.37.82-1.37l2.82-.03c.45,0,.82.62.82,1.36l.02,6.57c0,.78-.36,1.4-.81,1.4l-2.83.02c-.45,0-.82-.62-.82-1.39v-1.27c-.23-.16-.44-.18-.44-.18h-1.29c-.15.01-.32.21-.46.42l-.58,1.2c-.16.27-.35.44-.6.44-.52,0-.48-.69-.48-1.56v-4.6c0-.89-.05-1.6.46-1.6.4,0,.71.4.86.97,0,0,0,.59.8,1.1Z"/>
          </g>
          <g>
            <path   d="m395.45,612.69h-1.29c-.18,0-.32-.12-.44-.28v-1.16c0-.76-.37-1.38-.82-1.36l-2.83.02c-.45,0-.82.61-.82,1.37l.02,6.56c0,.74.37,1.37.82,1.37l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.41l.59,1.18c.15.27.34.45.59.45.51-.01.48-.71.48-1.59v-4.59c-.01-.87.01-1.58-.5-1.58-.39,0-.71.4-.86.97,0,0,0,.56-.78,1.08Z"/>
            <path   d="m396.69,618.39l1.27-.03c.18,0,.32-.13.44-.28v-1.17c0-.74.36-1.37.81-1.37l2.82-.03c.46,0,.82.61.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.46,0-.83-.61-.83-1.38v-1.28c-.22-.15-.44-.18-.44-.18h-1.29c-.15,0-.32.21-.46.42l-.59,1.2c-.15.27-.35.44-.6.44-.51,0-.48-.7-.48-1.56v-4.6c0-.88-.05-1.6.47-1.6.39,0,.71.4.86.97,0,0,0,.58.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.53,624.26h-1.29c-.17,0-.32-.12-.44-.28v-1.17c0-.77-.36-1.38-.82-1.36l-2.83.02c-.45,0-.82.6-.82,1.37l.02,6.57c0,.73.37,1.36.83,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.27c.22-.15.44-.19.44-.19h1.29c.15,0,.32.19.46.41l.59,1.19c.16.26.34.44.59.43.51,0,.48-.71.48-1.59v-4.58c-.02-.86.01-1.58-.49-1.58-.39,0-.71.4-.85.98,0,0,0,.56-.79,1.09Z"/>
            <path   d="m396.75,629.95l1.29-.03c.18,0,.32-.13.44-.28v-1.17c0-.75.36-1.37.82-1.37l2.83-.03c.45,0,.82.62.82,1.35l.02,6.57c0,.77-.36,1.4-.81,1.4l-2.83.02c-.45,0-.82-.61-.82-1.39v-1.27c-.22-.16-.44-.16-.44-.16h-1.29c-.16,0-.32.2-.46.41l-.59,1.2c-.16.28-.36.44-.6.44-.52,0-.49-.7-.49-1.57v-4.6c0-.89-.05-1.6.47-1.6.39,0,.72.4.86.97,0,0,0,.58.79,1.09Z"/>
          </g>
        </g>
        <g>
          <g>
            <path   d="m395.4,636.11h-1.28c-.17.01-.32-.1-.44-.27v-1.16c0-.77-.37-1.38-.82-1.37l-2.83.02c-.45,0-.83.61-.81,1.38v6.56c.01.74.38,1.37.83,1.37l2.84-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.15.44-.19.44-.19h1.28c.16,0,.33.19.47.41l.59,1.19c.15.26.35.45.59.44.51,0,.48-.71.48-1.6v-4.58c-.01-.86.01-1.58-.49-1.58-.39,0-.72.4-.86.98,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.56,641.82l1.29-.03c.17,0,.32-.13.44-.27v-1.17c-.01-.75.35-1.38.81-1.38l2.83-.02c.45,0,.82.62.82,1.36l.02,6.56c0,.78-.36,1.39-.81,1.41h-2.83c-.46,0-.82-.6-.82-1.36v-1.28c-.23-.16-.44-.17-.44-.17h-1.29c-.15,0-.32.21-.46.42l-.59,1.19c-.15.28-.35.44-.6.44-.51,0-.49-.7-.49-1.56v-4.6c-.01-.89-.05-1.6.47-1.6.39,0,.71.4.85.97,0,0,0,.58.79,1.09Z"/>
          </g>
          <g>
            <path   d="m395.27,647.47h-1.29c-.18,0-.32-.11-.44-.26v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.46.01-.82.62-.82,1.39v6.56c.01.74.39,1.36.85,1.36l2.82-.03c.46,0,.81-.62.81-1.37v-1.26c.22-.16.44-.2.44-.2h1.29c.16-.01.33.2.47.4l.59,1.19c.15.27.35.44.59.44.52-.01.49-.7.49-1.59l-.02-4.59c0-.87.02-1.57-.48-1.57-.4,0-.71.39-.86.97,0,0,0,.55-.78,1.08Z"/>
            <path   d="m396.49,653.17l1.28-.03c.18,0,.32-.12.44-.28v-1.16c0-.75.36-1.37.81-1.37l2.82-.03c.46,0,.82.62.82,1.36l.02,6.56c0,.77-.36,1.39-.81,1.4l-2.83.02c-.45,0-.82-.61-.82-1.38v-1.27c-.22-.15-.44-.18-.44-.18h-1.29c-.16,0-.33.21-.47.42l-.59,1.2c-.15.28-.35.44-.59.44-.52,0-.49-.71-.49-1.57l-.02-4.6c0-.89-.04-1.6.48-1.6.4,0,.72.39.85.96,0,0,0,.59.8,1.09Z"/>
          </g>
          <g>
            <path   d="m395.33,658.96l-1.29.02c-.17,0-.32-.13-.44-.28v-1.17c0-.77-.37-1.38-.82-1.36h-2.83c-.46.01-.82.63-.82,1.39l.02,6.57c0,.74.37,1.37.82,1.36l2.83-.03c.46,0,.81-.62.81-1.37v-1.28c.22-.15.44-.19.44-.19h1.29c.15-.01.32.19.46.4l.59,1.19c.15.26.35.45.6.44.5,0,.48-.7.48-1.58v-4.59c-.02-.87,0-1.58-.49-1.58-.4,0-.72.4-.86.97,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.55,664.65h1.28c.18-.01.32-.14.44-.29v-1.18c0-.74.36-1.36.81-1.36l2.83-.02c.45-.02.81.61.81,1.35l.02,6.57c0,.77-.36,1.38-.81,1.39l-2.83.02c-.45,0-.82-.62-.82-1.38v-1.28c-.22-.16-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.41l-.59,1.19c-.16.29-.35.45-.6.45-.51,0-.49-.7-.49-1.57v-4.59c0-.89-.04-1.6.47-1.6.4,0,.71.41.86.97,0,0,0,.58.79,1.08Z"/>
          </g>
          <g>
            <path   d="m395.33,681.94l-1.29.02c-.17,0-.32-.11-.44-.28v-1.18c0-.76-.37-1.37-.82-1.35h-2.83c-.46.01-.82.63-.82,1.39l.02,6.57c0,.74.37,1.37.82,1.37l2.83-.03c.46,0,.81-.61.81-1.37v-1.27c.22-.15.44-.2.44-.2h1.29c.15,0,.32.2.46.42l.59,1.18c.15.27.35.46.6.44.5-.01.48-.7.48-1.58v-4.6c-.02-.87,0-1.56-.49-1.56-.4,0-.72.39-.86.96,0,0,0,.56-.79,1.08Z"/>
            <path   d="m396.55,687.64h1.28c.18-.01.32-.14.44-.3v-1.16c0-.76.36-1.37.81-1.37l2.83-.02c.45-.02.81.61.81,1.35l.02,6.57c0,.77-.36,1.39-.81,1.39l-2.83.03c-.45,0-.82-.63-.82-1.39v-1.28c-.22-.16-.44-.16-.44-.16h-1.29c-.15,0-.32.2-.46.42l-.59,1.19c-.16.28-.35.45-.6.45-.51,0-.49-.71-.49-1.56v-4.6c0-.89-.04-1.6.47-1.6.4,0,.71.39.86.96,0,0,0,.58.79,1.08Z"/>
          </g>
          <path   d="m395.26,693.5l-1.29.02c-.17,0-.31-.13-.44-.28v-1.17c0-.77-.37-1.38-.82-1.36l-2.83.02c-.46,0-.82.61-.81,1.36v6.58c.01.74.38,1.37.83,1.36l2.83-.03c.45,0,.81-.62.81-1.37v-1.28c.22-.15.44-.2.44-.2h1.29c.14,0,.32.2.46.41l.59,1.19c.15.26.35.46.59.44.51,0,.48-.71.48-1.59v-4.59c-.02-.87.01-1.56-.49-1.56-.4,0-.71.4-.86.97,0,0,0,.55-.78,1.07Z"/>
          <g>
            <path   d="m395.26,670.45h-1.29c-.17,0-.31-.11-.44-.27v-1.17c0-.76-.37-1.37-.82-1.37l-2.83.02c-.46,0-.82.61-.81,1.37v6.57c.01.75.38,1.37.83,1.36l2.83-.02c.45-.01.81-.63.81-1.37v-1.29c.22-.14.44-.18.44-.18h1.29c.14,0,.32.18.46.41l.59,1.18c.15.26.35.45.59.44.51,0,.48-.71.48-1.6v-4.59c-.02-.87.01-1.57-.49-1.57-.4,0-.71.4-.86.97,0,0,0,.55-.78,1.09Z"/>
            <path   d="m396.48,676.15l1.28-.02c.17,0,.32-.12.44-.28v-1.18c-.02-.75.35-1.36.8-1.36l2.83-.02c.46-.02.82.61.82,1.34l.02,6.58c0,.77-.36,1.39-.82,1.4h-2.82c-.46.01-.83-.61-.83-1.37v-1.28c-.22-.16-.43-.17-.43-.17h-1.29c-.15,0-.32.2-.46.41l-.6,1.2c-.15.27-.34.45-.59.45-.51,0-.49-.71-.49-1.57v-4.6c0-.89-.04-1.6.47-1.6.4,0,.71.4.86.97,0,0,0,.57.8,1.08Z"/>
          </g>
        </g>
        <polygon   points="397.27 686.92 403.3 686.92 403.37 703.46 389.3 703.4 389.17 696.89 397.41 696.89 397.27 686.92"/>
        <g>
          <polyline   points="380.37 701.35 380.36 702.1 380.37 701.35"/>
          <line   x1="380.37" y1="699.85" x2="382.54" y2="142.5"/>
          <polyline   points="382.54 141.75 382.54 141 382.54 141.75"/>
          <line   x1="382.53" y1="143.25" x2="380.37" y2="700.6"/>
        </g>
        <g>
          <line   x1="408.26" y1="141.13" x2="408.27" y2="141.88"/>
          <line   x1="408.28" y1="143.38" x2="411.6" y2="701.66"/>
          <line   x1="411.61" y1="702.41" x2="411.61" y2="703.16"/>
        </g>
        <g id="CIerre-2" data-name="CIerre">
          <path   d="m397.33,152.52l.41-.34,1.25,1.67s1.98,3.4,4.26,1.52c2.19-1.8-.39-4.34-.39-4.34l-1.31-1.72.42-.32s11.88,7.89,14.32,11.87c1.05,1.72,2.24,4.22,1.02,6.38-1.12,1.99-5.91,5.58-8.24,4.97-1.06-.27-3.19-1.24-4.47-3.21-1.04-1.6-2.67-4.43-3.95-7.42-1.91-4.44-3.33-9.06-3.33-9.06Z"/>
          <path   d="m396.59,152.42l.91-.57-3.73-4.94s-.82-2.03.79-3.15c1.78-1.24,3.29.49,3.29.49l3.48,4.65.86-.66,5.42,3.56s-.13-1.93-1.75-3.25c-1.41-1.15-5.07-2.35-5.07-2.35,0,0-.88-.73-1.14-1.02-.96-1.12-3.75-4.65-3.75-4.65l-5.03,3.83s2.51,3.37,3.29,4.5c.25.36.84,1.48.84,1.48,0,0-.05,2.73.91,5.55.58,1.7,2.69,2.69,2.69,2.69l-1.99-6.18Z"/>
          <path   d="m405.34,166.33c.61,1.06,1.78,2.84,2.81,2.77,1.68-.13,6.63-3.81,7.23-5.37.36-.93-.86-2.17-1.58-3.09-.86-1.1-2.25-1.96-3.42-1.83-1.19.13-5.22,3.13-5.8,4.14-.48.84.18,2.38.76,3.38Z"/>
          <path   d="m394.88,146.73s-.5-1.27.42-1.94c.91-.67,1.95.19,1.95.19l5.53,7.29s1.03,1.7-.05,2.45c-.95.65-2.26-.6-2.26-.6l-5.59-7.39Z"/>
        </g>
      </g>
      <g id="Puño_derecha" data-name="Puño derecha">
        <path id="Puño_derecha-2" data-name="Puño derecha"   d="m650.55,708.88l3.44,63.1s14.97,1.97,31.24,1.57c12.49-.32,29.67-2.81,29.67-2.81l3.44-60.6s-17.56-7.8-31.86-6.87c-17.48,1.15-35.92,5.62-35.92,5.62Z"/>
        <path id="Línea_1_puño_derecha" data-name="Línea 1 puño derecha"   d="m715.55,753s-13.71-8.45-35.21-8.45c-13.25,0-26.84,8.95-26.84,8.95l-.44.32"/>
        <path id="Línea_2_puño_derecha" data-name="Línea 2 puño derecha"   d="m652.5,743.5s15.59-8.9,28.84-8.9c21.5,0,35.21,8.45,35.21,8.45"/>
      </g>
      <g id="Manga_derecha" data-name="Manga derecha">
        <path id="Manga_derecha-2" data-name="Manga derecha"   d="m492.5,76.5c3.81,1.56,140.14,40.46,173.38,173.06,10.89,43.43,28.19,84.96,38.53,128.54,5.6,23.58,12.64,47.57,17.09,71.39,12.72,68.13,6.55,138.1,6.96,207.21.15,23.37-3.86,54.69-3.86,54.69,0,0-28.85-8.47-38.15-8.47-10.68,0-41.79,8.47-41.79,8.47,0,0-3.8-22.21-2.38-43.17,2.03-30.11.66-25.85-.1-45.77-1.71-45.08-4.46-95.55-8.63-121.03-13.87-84.85-48.05-145.34-48.05-145.34,0,0-16.57-98.36-34.19-122.73-89.5-123.85-58.8-156.85-58.8-156.85Z"/>
        <path id="Linea_1_manga_derecha" data-name="Linea 1 manga derecha"   d="m487.5,93.5s37.94,7.21,77.88,40.8c19.25,16.19,36.57,34.9,51.53,55.12,22.58,30.53,30.96,69.72,44.88,104.35,4.2,10.44,22.34,68.85,25.18,80.51,6.81,27.96,14.43,55.19,20.37,83.38,5.2,24.66,6.14,50.02,7.65,75.12,1.13,18.86-1.07,63.31-.34,90.28.88,32.73-4.15,84.44-4.15,84.44"/>
        <path id="Linea_2_manga_derecha" data-name="Linea 2 manga derecha"   d="m716.5,709.5c2.98-37.35,3.74-73.87,3.5-111.31-.24-37.63,1.96-75.47-3-112.9-5.74-43.28-18.79-85.08-28.86-127.44-9.29-39.1-24.61-76.38-37.3-114.43-11.03-33.09-29.82-63.36-53.9-88.53-29.74-31.08-67.44-54.4-108.43-67.39"/>
        <path id="Linea_3_manga_derecha" data-name="Linea 3 manga derecha"   d="m489.5,83.5c37.28,10.83,73.5,30.8,102.05,57.22,25.23,23.35,47.36,50.95,59.15,83.6,15.38,42.58,28.96,85.81,40.87,129.49,11.62,42.6,24.77,86.78,30.09,130.68,5.67,46.85,3.84,93.98,3.84,141,0,16-4,85-4,85"/>
        <path id="Línea_1_hombro_manga_izquierda" data-name="Línea 1 hombro manga izquierda"   d="m665.5,242.1s-26.32,38.38-89.81,58.4"/>
        <path id="Línea_2_hombro_manga_derecha" data-name="Línea 2 hombro manga derecha"   d="m649.5,203.1s-21.32,37.38-84.81,57.4"/>
        <path id="Línea_3_hombro_manga_derecha" data-name="Línea 3 hombro manga derecha"   d="m627.5,169.1s-17.32,36.38-80.81,56.4"/>
      </g>
      <g id="Puño_izquierda" data-name="Puño izquierda">
        <path id="Puño_izquierda-2" data-name="Puño izquierda"   d="m79.05,711.69l3.63,60.53s23.87,4.81,31.5,4.79c7.79-.03,28.86-5.12,28.86-5.12l3.46-60.52s-28.4-6.35-36.44-6.26c-7.51.07-31.01,6.58-31.01,6.58Z"/>
        <path id="Línea_1_puño_izquierda" data-name="Línea 1 puño izquierda"   d="m81.37,753.38s14.69-8.33,27.94-8.33c21.5,0,35.21,8.45,35.21,8.45"/>
        <path id="Línea_2_puño_izquierda" data-name="Línea 2 puño izquierda"   d="m81.37,742.63s14.69-8.33,27.94-8.33c21.5,0,35.21,8.45,35.21,8.45"/>
      </g>
      <g id="Manga_izquierda" data-name="Manga izquierda">
        <path id="Manga_izquierda-2" data-name="Manga izquierda"   d="m303.46,78.73c-4.56,3.65-138.59,39.95-171.84,172.56-12.06,48.11-27.11,80.39-34.49,111.29-5.27,22.06-10.21,44.21-14.73,66.44-3.09,15.19-6,30.43-8.62,45.71-2.27,13.24-3.88,26.47-3.95,40-.07,14.5-.15,29-.23,43.49-.12,21.46-.24,42.92-.36,64.38-.07,11.94-.14,23.89-.21,35.83-.14,23.37,3.86,54.69,3.86,54.69,0,0,28.86-8.47,38.16-8.47,10.68,0,41.79,8.47,41.79,8.47,0,0,3.81-22.22,2.38-43.18-2.04-30.1-.66-25.85.1-45.76,1.72-45.07,4.46-95.54,8.63-121.03,13.87-84.84,48.05-145.34,48.05-145.34,0,0,16.57-98.35,34.19-122.73,89.5-123.86,57.26-156.35,57.26-156.35Z"/>
        <path id="Linea_1_manga_izquierda" data-name="Linea 1 manga izquierda"   d="m309.5,96.97s-33.7,3.74-73.64,37.33c-28.74,24.18-56.9,52.12-69.52,88.26-15.02,42.99-30.72,79.32-43.84,122.93-9.48,31.52-17.92,69.9-25.59,101.93-6.79,28.37-8.91,56.29-10.66,85.35-1.13,18.86.07,63.31-.66,90.28-.88,32.73,5.04,85.58,5.04,85.58"/>
        <path id="Linea_2_manga_izquierda" data-name="Linea 2 manga izquierda"   d="m84.48,710.07s-7.17-74.6-5.98-106.57c1.08-28.93,2.99-55.47,2.81-72.93-.33-32.62,4.61-62.69,12.6-94.15,5.41-21.29,11.87-42.57,16.07-64.15,7.3-37.55,22.56-73.57,34.93-109.66,11.68-34.07,24.43-68.33,48.26-96,28.87-33.53,66.45-60.79,108.77-74.42,2.09-.67,4.19-1.31,6.31-1.92"/>
        <path id="Linea_3_manga_izquierda" data-name="Linea 3 manga izquierda"   d="m306.74,84.74s-48.14,14.08-88.08,47.68c-26.86,22.59-51.5,50.14-65.58,82.64-11.2,25.86-16.44,54.11-26.96,80.26-4.2,10.44-21.9,69.83-24.73,81.49-6.91,28.39-13.74,56.82-19.21,85.53-4.63,24.32-6.03,48.78-7.51,73.48-3.5,58.33-2,117.78,4.37,175.86"/>
        <path id="Línea_1_hombro_manga_izquierda-2" data-name="Línea 1 hombro manga izquierda"   d="m133.69,242.1s26.32,38.38,89.81,58.4"/>
        <path id="Línea_2_hombro_manga_izquierda" data-name="Línea 2 hombro manga izquierda"   d="m149.69,203.1s21.32,37.38,84.81,57.4"/>
        <path id="Línea_3_hombro_manga_izquierda" data-name="Línea 3 hombro manga izquierda"   d="m171.69,169.1s17.32,36.38,80.81,56.4"/>
      </g>
      <polygon id="Bolsa_izquierda" data-name="Bolsa izquierda"   points="243.84 573.69 280.06 440.5 289.35 442.81 297.04 444.72 259.43 579.32 252.15 576.3 243.84 573.69"/>
      <polygon id="Bolsa_derecha" data-name="Bolsa derecha"   points="553.06 573.71 516.84 440.52 507.55 442.83 499.86 444.74 537.47 579.34 544.75 576.33 553.06 573.71"/>
      <g id="Escudo">
        <g id="Escudo_derecho" data-name="Escudo derecho">
          <circle   cx="497" cy="255" r="29.5"/>
          <path d="m497,226c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
        </g>
        <g id="Escudo_izquierdo" data-name="Escudo izquierdo">
          <circle   cx="309" cy="255" r="29.5"/>
          <path d="m309,226c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
        </g>
      </g>
      
    </g>
  </g>
    </svg>
  )
}

export default JacketSvg
