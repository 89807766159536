import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { InputFormConfig } from 'types'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import {
  getSchoolsAdminById,
  schoolAdminInterface,
  updateSchoolAdmin
} from 'services/schoolAdminServices/schools'
import { handleErrors } from 'utilities/handleErrors'

export interface levelsFormI {
  name: InputFormConfig
}

const INPUTS_INITIAL_STATE = {
  name: {
    label: 'Nombre del colegio',
    name: 'name',
    placeholder: 'Nombre del colegio',
    value: '',
    type: 'text',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: true
  },
  social_reason: {
    label: 'Razón Social',
    name: 'social_reason',
    placeholder: 'Razón Social',
    value: '',
    type: 'text',
    required: true,
    maxLength: 6, //maxLength solo funciona para tipo text
    status: '', // puede ser 'warning' o 'error' segun antd,
    disabled: true
  },
  sex: {
    label: 'Tipo de colegio',
    name: 'sex',
    placeholder: 'Tipo de colegio',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: true
  },
  opening_date: {
    label: 'Fecha de apertura',
    name: 'opening_date',
    placeholder: 'Fecha de apertura',
    value: '',
    type: 'date',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: ''
  },
  address: {
    label: 'Dirección',
    name: 'address',
    placeholder: 'Dirección',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: '',
    disabled: true
  },
  phone: {
    label: 'Teléfono',
    name: 'phone',
    placeholder: 'Teléfono',
    value: '',
    type: 'text',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: true
  }
  /* quota: {
    label: 'Cuota',
    name: 'quota',
    placeholder: '0.00',
    value: '',
    type: 'text',
    required: true,
    maxLength: 5, //maxLength solo funciona para tipo text
    status: '',
    disabled:true,
    rules: [
      { required: true, message: '' },
      {
        validator: async (_: any, quote: any) => {
          const regexp = /^\d{0,3}(\.\d{1,2})?$/;
          const valid = regexp.test(quote)
          const numericQuote = parseFloat(quote)
          console.log(numericQuote);
          if (numericQuote>100) {
            return Promise.reject(
              new Error('La cuota no puede ser mayor a 100')
            )
          }
          if (!valid) {
            return Promise.reject(
              new Error('La cuota debe de poseer máximo dos decimales separados por puntos')
            )
          }
          
        }
      }
    ]
  } */
}

export function useUserSchoolDetails() {
  const [schoolDBInfo, setschoolDBInfo] = useState({
    id: 0,
    name: '',
    sex: '',
    phone: '',
    social_reason: '',
    address: '',
    opening_date: '',
    id_magento: '',
    id_gea: '',
    finished_structure: false,
    quota:''
  })
  Object.freeze(INPUTS_INITIAL_STATE)
  const [schoolData, setschoolData] = useState(INPUTS_INITIAL_STATE)
  const { dataToken } = useDecodeToken()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [LoadingList, setLoadingList] = useState(true)
  const [loadingPost, setloadingPost] = useState(false)

  const getSchoolData = async (): Promise<void> => {
    const { uid } = dataToken()
    try {
      const result = await getSchoolsAdminById(uid?.id_school as number)
      if (result.data.school !== null) {
        let newObject = { ...INPUTS_INITIAL_STATE }
        Object.keys(schoolData).forEach((res: any, key) => {
          const objKey = res as keyof typeof INPUTS_INITIAL_STATE
          const objInput = schoolData[objKey]
          objInput.value = result.data.school[objKey]
          newObject = { ...newObject, [objKey]: objInput }
        })
        setschoolData(newObject)
      }
      setschoolDBInfo(result.data.school)
    } catch (err: any) {
      const Error = err?.response?.data?.message
      if (Error) ErrorToast(Error)
    } finally {
      setLoadingList(false)
    }
  }

  useEffect(() => {
    let unmounted = false
    const controller = new AbortController()
    if (!unmounted) {
      void getSchoolData()
    }
    return () => {
      controller.abort()
    }
  }, [])

  /* ----------------------- SCHOOL ----------------------------- */

  const cleanDataToPost = (
    structuredForm: any,
    id?: number
  ): schoolAdminInterface => {
    let goodData: schoolAdminInterface = {
      name: '',
      sex: '',
      social_reason: '',
      address: '',
      phone: '',
      id: undefined,
      opening_date: '',
      id_magento:schoolDBInfo.id_magento,
      id_gea:schoolDBInfo.id_gea,
      quota:''
    }
    Object.keys(structuredForm).forEach((element) => {
      const objKey = element as keyof typeof INPUTS_INITIAL_STATE
      const objInput = schoolData[objKey]
      goodData[objKey] = objInput.value
    })
    if (id) goodData.id = id
    return goodData
  }

  const updateSchool = async () => {
    setloadingPost(true)
    const data = cleanDataToPost(schoolData, schoolDBInfo.id)
    
    delete data.quota

    try {
      await updateSchoolAdmin({ data })
      SuccessToast(`El colegio "${data.name}" fue editado correctamente`)
      setOpenDeleteModal(false)
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingPost(false)
    }
  }

  const onSubmit = () => {
    updateSchool()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setschoolData((prev) => cleanSingleUpdatedState(e, prev))
  }

  const handleChangeSelect = (value: string, name: string) => {
    setschoolData((prev) => cleanSingleSelectUpdatedState(value, name, prev))
  }
  const handleChangeDate = (
    value: string,
    name: string,
    dateString: string
  ) => {
    setschoolData((prev) =>
      cleanSingleSelectUpdatedState(dateString, name, prev)
    )
  }

  return {
    LoadingList,
    schoolDBInfo,
    schoolData,
    handleChange,
    onSubmit,
    handleChangeSelect,
    handleChangeDate,
    INPUTS_INITIAL_STATE,
    openDeleteModal, setOpenDeleteModal,
    loadingPost
  }
}
