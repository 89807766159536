import { memo } from 'react'
import { Col, Form, Modal, Row, Tooltip } from 'antd'
import Button from 'components/buttons/Button/Button'
import style from './CreateUtilsForm.module.scss'
import { useUtilsForm } from 'hooks/utils/useUtilsForm'
import { useLocation } from 'react-router-dom'
import UploadInput from 'components/inputs/UploadInput'
import FormInput from 'components/formInputs/input'
import FormAutoComplete from 'components/formInputs/autocomplete'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import FormSelect from 'components/formInputs/select'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import globalStyle from 'styles/global.module.scss'
import { PRESENTATION_TYPE } from '../AddUtilsForm/hook/useAddUtilsForm'

const CreateUtilsForm = memo(({ openDeleteModal, setOpenDeleteModal }: any) => {
  const {
    form,
    formEditorial,
    schoolData,
    loadingPost,
    OptionBrand,
    INPUTS_INITIAL_STATE,
    onSubmit,
    checkDisabled,
    handleChangeSelect,
    postNewMakeSupply,
    modalMake,
    setmodalMake
  } = useUtilsForm()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const location = useLocation()

  let timeOutId: ReturnType<typeof setTimeout>
  const onHandleAutoCompleteMarca = async (value: string): Promise<any> => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(async () => {
      try {
        /* const res = await services.getEditorials(value)
        setOptionEditorial(value ? res?.data?.editorials : []) */
      } catch (error) {
        console.log(error)
      }
    }, 500)
  }

  const renderCorrectInput2 = (key: number, objInput: any) => {
    if (objInput.type === 'select')
      return (
        <FormSelect
          name={objInput.name}
          options={PRESENTATION_TYPE}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          disabled={objInput.disabled}
          hasFeedback={false}
        />
      )
    if (objInput.type === 'autocomplete')
      return (
        <FormAutoComplete
          name={objInput.name}
          type={'text'}
          label={
            <>
              <span>{objInput.label}</span>&nbsp;
              <Tooltip title={`Agregar ${objInput.label}`}>
                <PlusCircleIcon
                  onClick={() => setmodalMake(true)}
                  className={style.addMake}
                />
              </Tooltip>
            </>
          }
          hasFeedback={false}
          placeholder={objInput.placeholder}
          options={OptionBrand}
          rules={objInput.rules}
          onSearch={onHandleAutoCompleteMarca}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'file')
      return (
        <UploadInput
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleChangeSelect}
          hasFeedback={false}
          disabled={objInput.disabled}
        />
      )
    return (
      <FormInput
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
        hasFeedback={false}
        disabled={objInput.disabled}
      />
    )
  }

  const renderButtons = () => {
    if (location.state.status > 4) return null
    if (location.state.requestUtil)
      return (
        <Button
          isLoading={loadingPost}
          disabled={checkDisabled()}
          buttonType='submit'
          rounded={true}
          sector='primary'
          size='m'
        >
          Solicitar Material
        </Button>
      )
    if (uid.role !== 'Director')
      return (
        <Button
          isLoading={loadingPost}
          disabled={checkDisabled()}
          buttonType='submit'
          rounded={true}
          sector='primary'
          size='m'
        >
          Guardar Cambios
        </Button>
      )
    return null
  }

  return (
    <Form
      onFinish={onSubmit}
      layout='vertical'
      autoComplete='off'
      className={style.CreateSchoolForm}
      form={form}
    >
      <Row gutter={[16, 16]}>
        {Object.keys(schoolData).map((res: any, key) => {
          const objKey = res as keyof typeof INPUTS_INITIAL_STATE
          const objInput = schoolData[objKey]
          return <Col span={12}>{renderCorrectInput2(key, objInput)}</Col>
        })}
      </Row>

      <Form.Item>{renderButtons()}</Form.Item>
      <Modal
        title='Agregar marca'
        open={modalMake}
        centered
        onCancel={() => {
          formEditorial.resetFields()
          setmodalMake(false)
        }}
        footer={false}
        className={globalStyle.confirmModal}
      >
        <Form
          form={formEditorial}
          layout='vertical'
          onFinish={postNewMakeSupply}
          autoComplete='off'
        >
          <FormInput
            name='name'
            placeholder='Nombre'
            label='Nombre'
            rules={[{ required: true, message: '' }]}
          />
          <div className={globalStyle.confirmModalFooter}>
            <Button
              isLoading={loadingPost}
              buttonType='submit'
              rounded
              sector='primary'
            >
              Agregar
            </Button>
          </div>
        </Form>
      </Modal>
    </Form>
  )
})
CreateUtilsForm.displayName = 'CreateUtilsForm'
export default CreateUtilsForm
