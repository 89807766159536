import React, { Fragment, useEffect, useState } from 'react'
import Navbar from 'components/Navbar/Navbar'
import FixedInfoFooter from 'modules/uniforms/components/fixedInfoFooter/FixedInfoFooter'
import style from './selecttype.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  allNotebooks,
  setSelectedNotebook
} from 'redux/reducers/notebooksSlice'

import useNotebooks from 'hooks/notebooks/useNotebooks'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'redux/store/store'
import Horizontal from 'assets/img/notebooks/Horizontal'
import Vertical from 'assets/img/notebooks/Vertical'
import { Spin } from 'antd'
import ShowSpin from '../notebooksList/components/showSpin/ShowSpin'

//horizontal 4,6,8
//vertical 1,2,3,5,7

const SelectTypeModule = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [show, setshow] = useState(false)

  const { notebookSelected } = useSelector(
    (state: RootState) => state.notebooks
  )
  const setNotebookId = (id: number) => dispatch(setSelectedNotebook(id))
  const { saveNotebookChange, saveSendDataNotebookChange, notebook } =
    useNotebooks({
      uuid: parseInt(uuid || '0')
    })

  const goBack = () => {
    let thisPath = window.location.pathname
    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'select-type':
          saveSendDataNotebookChange({ step: 0 }, false, () =>
            navigate(`/cuadernos`)
          )
          break
        case 'edith-front':
          saveSendDataNotebookChange({ step: 1 }, false, () =>
            navigate(`/cuadernos/select-type/${uuid}`)
          )

          break
        case 'edith-inside':
          saveSendDataNotebookChange({ step: 2 }, false, () =>
            navigate(`/cuadernos/edith-front/${uuid}`)
          )
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (notebook !== null) {
      if (`${notebook.status}` === '4' || `${notebook.status}` === '5') {
        navigate(`/cuadernos/preview/${uuid}`)
      } else {
        setshow(true)
      }
    }
  }, [notebook])

  return (
    <div className={`${style['body-div']}`}>
      {show ? (
        <Fragment>
          <Navbar complete onClick={goBack} />
          <FixedInfoFooter
            uuidNotebook={uuid}
            save={() => {
              saveNotebookChange()
            }}
            next={(step?: number, fn?: () => void) => {
              saveNotebookChange()
              saveSendDataNotebookChange(
                { step: step || 0 },
                false,
                () => fn && fn()
              )
            }}
          />
          <p className={`${style['p-instruction']}`}>
            Elige un diseño de cuaderno
          </p>
          <div className={`${style['div-container-grid']}`}>
            {allNotebooks.map((item, index: number) => {
              let thisId = item.id === 4 || item.id === 6 || item.id === 8

              return (
                <div
                  key={index}
                  className={`${style['div-container']} ${
                    notebookSelected === item.id && style['selected-notebook']
                  }`}
                  onClick={() => setNotebookId(item.id)}
                >
                  <div
                    className={`${style['div-img']} ${
                      thisId && style['center']
                    }`}
                  >
                    {thisId ? (
                      <Horizontal className='' />
                    ) : (
                      <Vertical className='' />
                    )}
                  </div>
                  <p className={`${style['p-name-notebook']}`}>{item.label}</p>
                </div>
              )
            })}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <ShowSpin />
        </Fragment>
      )}
    </div>
  )
}

export default SelectTypeModule
