import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare10mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 10mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_10mm_Completo'
              data-name='Path Cuadro 10mm Completo'
            >
              <g
                id='Path_Cuadro_10mm_Completo-2'
                data-name='Path Cuadro 10mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='112.84' y1='151.56' x2='112.84' y2='622.51' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='656.9' y1='151.56' x2='656.9' y2='622.51' />
                  </g>
                  <line x1='141.27' y1='151.56' x2='141.27' y2='622.51' />
                  <line x1='170.17' y1='151.56' x2='170.17' y2='622.51' />
                  <line x1='198.78' y1='151.56' x2='198.78' y2='622.51' />
                  <line x1='227.39' y1='151.56' x2='227.39' y2='622.51' />
                  <line x1='256.05' y1='151.56' x2='256.05' y2='622.51' />
                  <line x1='284.84' y1='151.56' x2='284.84' y2='622.51' />
                  <line x1='313.26' y1='151.56' x2='313.26' y2='622.51' />
                  <line x1='342.16' y1='151.56' x2='342.16' y2='622.51' />
                  <line x1='370.77' y1='151.56' x2='370.77' y2='622.51' />
                  <line x1='399.39' y1='151.56' x2='399.39' y2='622.51' />
                  <line x1='428.04' y1='151.56' x2='428.04' y2='622.51' />
                  <line x1='456.48' y1='151.56' x2='456.48' y2='622.51' />
                  <line x1='484.9' y1='151.56' x2='484.9' y2='622.51' />
                  <line x1='513.81' y1='151.56' x2='513.81' y2='622.51' />
                  <line x1='542.42' y1='151.56' x2='542.42' y2='622.51' />
                  <line x1='571.03' y1='151.56' x2='571.03' y2='622.51' />
                  <line x1='599.69' y1='151.56' x2='599.69' y2='622.51' />
                  <line x1='628.48' y1='151.56' x2='628.48' y2='622.51' />

                  <line x1='85.23' y1='591.96' x2='686.44' y2='591.96' />
                  <line x1='85.23' y1='562.58' x2='686.44' y2='562.58' />
                  <line x1='85.23' y1='533.21' x2='686.44' y2='533.21' />
                  <line x1='85.23' y1='503.79' x2='686.44' y2='503.79' />
                  <line x1='85.23' y1='474.24' x2='686.44' y2='474.24' />
                  <line x1='85.23' y1='445.06' x2='686.44' y2='445.06' />
                  <line x1='85.23' y1='415.39' x2='686.44' y2='415.39' />
                  <line x1='85.23' y1='386.02' x2='686.44' y2='386.02' />
                  <line x1='85.23' y1='356.65' x2='686.44' y2='356.65' />
                  <line x1='85.23' y1='327.23' x2='686.44' y2='327.23' />
                  <line x1='85.23' y1='298.04' x2='686.44' y2='298.04' />
                  <line x1='85.23' y1='268.86' x2='686.44' y2='268.86' />
                  <line x1='85.23' y1='239.19' x2='686.44' y2='239.19' />
                  <line x1='85.23' y1='209.81' x2='686.44' y2='209.81' />
                  <line x1='85.23' y1='180.44' x2='686.44' y2='180.44' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m670.8,170.08c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m670.8,198.01c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,224.41c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,285.84c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m670.8,257.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,312.24c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,373.67c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m670.8,345.75c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,400.07c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,461.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m670.8,433.58c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,487.9c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,549.34c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m670.8,521.41c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,575.73c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,609.24c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m670.8,228.56c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,254.24c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,170.08c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,195.76c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,287.03c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,312.71c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,345.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,371.19c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,403.98c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,429.66c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,462.45c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,488.13c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,520.93c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,546.61c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m670.8,579.4c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.64,605.08c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M100.27,170.08a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,198a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,224.41a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.3-1-1.3a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,285.84a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,257.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,312.24a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,373.67a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,345.75a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,400.07a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7A1.55,1.55,0,0,1,97,403a6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,461.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,433.58a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,487.9a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,549.34a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,521.41a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,575.73a18.07,18.07,0,0,0-2.68,3.43l-.19-.44c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,609.24a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M100.27,228.56a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,254.24a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,170.08a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,195.76a18.07,18.07,0,0,0-2.68,3.43l-.19-.44c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,287a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,312.71a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,345.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,371.19a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.38-.87-.74-1.3-1-1.3a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31A21.35,21.35,0,0,1,101.9,372a17.21,17.21,0,0,1,2.94-3.07l-.22-.3a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,404a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,429.66a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,462.45a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,488.13a18.07,18.07,0,0,0-2.68,3.43l-.19-.44c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31A20.68,20.68,0,0,1,101.9,489a17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,520.93a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,546.61A18.34,18.34,0,0,0,98.74,550l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,579.4a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,605.08a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7A1.55,1.55,0,0,1,97,608a6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 10mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_10mm_Datos' data-name='Path Cuadro 10mm Datos'>
              <g
                id='Path_Cuadro_10mm_Datos-2'
                data-name='Path Cuadro 10mm Datos'
              >
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='112.84' y1='196.56' x2='112.84' y2='608.45' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='656.9' y1='196.56' x2='656.9' y2='608.45' />
                  </g>
                  <line x1='141.27' y1='196.56' x2='141.27' y2='608.45' />
                  <line x1='170.17' y1='196.56' x2='170.17' y2='608.45' />
                  <line x1='198.78' y1='196.56' x2='198.78' y2='608.45' />
                  <line x1='227.39' y1='196.56' x2='227.39' y2='608.45' />
                  <line x1='256.05' y1='196.56' x2='256.05' y2='608.45' />
                  <line x1='284.84' y1='196.56' x2='284.84' y2='608.45' />
                  <line x1='313.26' y1='196.56' x2='313.26' y2='608.45' />
                  <line x1='342.16' y1='196.56' x2='342.16' y2='608.45' />
                  <line x1='370.77' y1='196.56' x2='370.77' y2='608.45' />
                  <line x1='399.39' y1='196.56' x2='399.39' y2='608.45' />
                  <line x1='428.04' y1='196.56' x2='428.04' y2='608.45' />
                  <line x1='456.48' y1='196.56' x2='456.48' y2='608.45' />
                  <line x1='484.9' y1='196.56' x2='484.9' y2='608.45' />
                  <line x1='513.81' y1='196.56' x2='513.81' y2='608.45' />
                  <line x1='542.42' y1='196.56' x2='542.42' y2='608.45' />
                  <line x1='571.03' y1='196.56' x2='571.03' y2='608.45' />
                  <line x1='599.69' y1='196.56' x2='599.69' y2='608.45' />
                  <line x1='628.48' y1='196.56' x2='628.48' y2='608.45' />
                  <line x1='85.23' y1='578.21' x2='686.44' y2='578.21' />
                  <line x1='85.23' y1='548.79' x2='686.44' y2='548.79' />
                  <line x1='85.23' y1='519.24' x2='686.44' y2='519.24' />
                  <line x1='85.23' y1='490.06' x2='686.44' y2='490.06' />
                  <line x1='85.23' y1='460.39' x2='686.44' y2='460.39' />
                  <line x1='85.23' y1='431.02' x2='686.44' y2='431.02' />
                  <line x1='85.23' y1='401.65' x2='686.44' y2='401.65' />
                  <line x1='85.23' y1='372.23' x2='686.44' y2='372.23' />
                  <line x1='85.23' y1='343.04' x2='686.44' y2='343.04' />
                  <line x1='85.23' y1='313.86' x2='686.44' y2='313.86' />
                  <line x1='85.23' y1='284.19' x2='686.44' y2='284.19' />
                  <line x1='85.23' y1='254.81' x2='686.44' y2='254.81' />
                  <line x1='85.23' y1='225.44' x2='686.44' y2='225.44' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m671,215.33c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671,243.25c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,269.65c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,331.09c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671,303.16c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,357.48c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,418.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671,390.99c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,445.32c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,506.75c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671,478.82c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,533.15c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,594.58c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671,566.66c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m671,273.8c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,299.48c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,215.33c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,241.01c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,332.28c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,357.96c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,390.75c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,416.43c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,449.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,474.9c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,507.7c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,533.38c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671,566.17c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m669.84,591.85c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M100.27,215.33a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,243.25a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,269.65a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,331.09a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,303.16a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,357.48a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,418.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,391a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,445.32a17.71,17.71,0,0,0-2.68,3.42l-.19-.44c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.18,6.18,0,0,1,.69,1.28l.17.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,506.75a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,478.82a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,533.15a18,18,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31A21.35,21.35,0,0,1,101.9,534a16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,594.58a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.27,566.66a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M100.27,273.8a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,299.48a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,215.33a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,241a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7A1.55,1.55,0,0,1,97,244a6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1A7.31,7.31,0,0,1,99,246l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,332.28a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,358a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.18,6.18,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1A8.6,8.6,0,0,1,99,363l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,390.75a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,416.43a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,449.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,474.9a18.07,18.07,0,0,0-2.68,3.43l-.19-.44c-.38-.86-.74-1.29-1-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,6.33,6.33,0,0,1,.69,1.27l.17.41a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                    <path d='M100.27,507.7a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,533.38a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6.46,6.46,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.11,15.11,0,0,0-3.2,2.55' />
                                    <path d='M100.27,566.17a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M101.42,591.85a18.34,18.34,0,0,0-2.68,3.42l-.19-.43c-.38-.86-.74-1.29-1-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.77,6.77,0,0,1,.69,1.28l.17.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.78,15.78,0,0,0-3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 10mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_10mm_Completo'
                data-name='Margenes Cuadro 10mm Completo'
              >
                <g
                  id='Margenes_Cuadro_10mm_Completo-2'
                  data-name='Margenes Cuadro 10mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro 10mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_10mm_Datos'
                data-name='Margenes Cuadro 10mm Datos'
              >
                <g
                  id='Margenes_Cuadro_10mm_Datos-2'
                  data-name='Margenes Cuadro 10mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.89'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V594.37a13.09,13.09,0,0,1-13.08,13.08H99.31a13.09,13.09,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V594.37a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.89'
                      />
                      <path d='M685.92,197.56V607.45H86.23V197.56H685.92m1-1H85.23V608.45H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare10mmHorizontal
