import { Col, DatePicker, Form, Row } from 'antd'
import Button from 'components/buttons/Button/Button'
import FormSelect from 'components/formInputs/select'
import H from 'components/texts/H/H'
import dayjs, { Dayjs } from 'dayjs'
import { useFilters } from 'hooks/reports/useFilters'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
const { RangePicker } = DatePicker

const Filters = memo(() => {
  const {
    INPUTS_INITIAL_STATE,
    loadingFilter,
    onSubmit,
    form,
    loadingReport,
    handleDownloadSellsReport,
    handleDownloadAccessReport
  } = useFilters()
  const { inputFilters } = useSelector((state: RootState) => state.sells)
  function disabledDate(current: Dayjs, minDate: any, maxDate: any) {
    return current && (current.isBefore(minDate) || current.isAfter(maxDate))
  }

  const renderCorrectInput2 = (key: number, objInput: any) => {
    if (objInput.type === 'date') {
      const dateFormat = 'YYYY/MM/DD'
      const minDate = dayjs(objInput.defaultValue[0], dateFormat)
      const maxDate = dayjs()
      return (
        <Form.Item name={objInput.name} key={key} label={objInput.label}>
          <RangePicker
            size='large'
            style={{ width: '100%' }}
            defaultPickerValue={[dayjs().subtract(1, 'month'), dayjs()]}
            placeholder={objInput.placeholder}
            disabledDate={(current) => disabledDate(current, minDate, maxDate)}
            /* defaultValue={[
              dayjs(objInput.defaultValue[0], dateFormat),
              dayjs(objInput.defaultValue[1], dateFormat)
            ]} */
          />
        </Form.Item>
      )
    }

    return (
      <FormSelect
        key={key}
        name={objInput.name}
        options={objInput.options}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
        disabled={objInput.disabled}
        hasFeedback={false}
        allowClear={true}
      />
    )
  }

  return (
    <Form form={form} layout='vertical' onFinish={onSubmit} autoComplete='off'>
      <Row align={'middle'} gutter={[16, 16]}>
        <Col span={24}>
          <Row justify='space-between' gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <H variation='4'>Filtrar por: </H>
            </Col>
            {!loadingFilter && (
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Row gutter={[16, 16]} justify='end'>
                  <Col>
                    <Button
                      isLoading={loadingReport}
                      rounded
                      sector='tertiary'
                      onClick={handleDownloadSellsReport}
                    >
                      Reporte de cuotas/ventas
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      isLoading={loadingReport}
                      onClick={handleDownloadAccessReport}
                      rounded
                      sector='tertiary'
                    >
                      Reporte de accesos
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        {loadingFilter && (
          <Col span={24}>
            <Row justify={'center'}>Loading filters...</Row>
          </Col>
        )}
        {!loadingFilter &&
          Object.keys(inputFilters).map((res: any, key: number) => {
            const objKey = res as keyof typeof INPUTS_INITIAL_STATE
            let objInput: any = inputFilters[objKey]
            return (
              <Col key={`filters-${key}`} xs={24} sm={24} md={5} lg={5} xl={5}>
                {renderCorrectInput2(key, objInput)}
              </Col>
            )
          })}
        {!loadingFilter && (
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <Row justify={'end'}>
              <Button
                /*     isLoading={loadingPost} */
                buttonType='submit'
                rounded
                sector='primary'
              >
                Aplicar filtro
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </Form>
  )
})
Filters.displayName = 'Filters'
export default Filters
