import React from 'react'

import { Modal, Spin } from 'antd'

import style from './selectedcardsoption.module.scss'

const ModalWaitImg = ({ isModalOpen }: { isModalOpen: boolean }) => {
  return (
    <Modal
      title='Cambiando el color del logotipo'
      className='modal-in-upload-img-uniforms'
      centered
      bodyStyle={{ background: '#F3F7FF' }}
      keyboard
      closable={false}
      open={isModalOpen}
      maskClosable={false}
      width={572}
      footer={[
        <div className={`${style['center-div']}`}>
          <Spin spinning></Spin>
        </div>
      ]}
    >
      <div className={`${style['div-container-modal']}`}>
        <p className={`${style['p-modal']}`}>
          Se esta trabajando para cambiar el color del logotipo al color de la
          tinta seleccionada, por favor espere un momento.
        </p>
      </div>
    </Modal>
  )
}

export default ModalWaitImg
