import { AUTHSERVICE } from '../config'

export const createQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/quotas', data)
}

export const getQuotasAdmin = async (
  page: number,
  school: string,
  status: string,
  search: string
): Promise<any> => {
  const filterSchool = school && `&school=${school}`
  const filterStatus = status && `&status=${status}`
  const searchValue = search && `&search=${search}`
  return await AUTHSERVICE().get(
    `/quotas/admin?page=${page}${filterSchool}${filterStatus}${searchValue}`
  )
}

export const getQuotas = async (
  idSchool: number,
  idLevel: number,
  idGrade: number
): Promise<any> => {
  return await AUTHSERVICE().get(
    `/quotas?id_school=${idSchool}&id_level=${idLevel}&id_grade=${idGrade}`
  )
}
export const getAvailableQuotas = async (
  idSchool: number,
  idLevel: number,
  idGrade: number
): Promise<any> => {
  return await AUTHSERVICE().get(
    `/quotas/available?id_school=${idSchool}&id_level=${idLevel}&id_grade=${idGrade}`
  )
}

export const updateQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas', data)
}

export const deleteQuota = async (id: number): Promise<any> => {
  return await AUTHSERVICE().delete(`/quotas/${id}`)
}
export const updateStatusQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas/status', data)
}
export const updateEndListQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas/end-list', data)
}
export const updateOpenListQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas/open-list', data)
}
export const authorizeQuota = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas/authorize', data)
}

export const requestChangesQuotas = async (body: any): Promise<any> => {
  return await AUTHSERVICE().put('/quotas/update/request-changes', body)
}
export const getQuotaSchool = async (id_school: number): Promise<any> => {
  return await AUTHSERVICE().get(`/quotas/school?id_school=${id_school}`)
}
export const downloadQuotas = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/quotas/download`)
}
