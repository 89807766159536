import React, { memo } from 'react'
import Birrete from 'assets/img/birrete.svg'
import './FirstTimeComponent.scss'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import S from 'components/texts/S/S'
import B from 'components/texts/B/B'
import Button from 'components/buttons/Button/Button'
import { useNavigate } from 'react-router-dom'

const FirstTimeComponent = memo(() => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/estructura')
  }

  const mensaje = () => {
    if (uid.school_status === 'Nuevo') return 'Empezar'
    return 'Continuar'
  }
  return (
    <div className='first-time-container'>
      <img src={Birrete} alt='Birrete' />
      <S>Bienvenido {uid.name}</S>
      <B size='Light'>
        {uid.school_status === 'Nuevo' &&
          uid.role === 'Administrador' &&
          'El parecer no posees una estructura creada'}
        {uid.school_status === 'En Construcción' &&
          uid.role === 'Administrador' &&
          'Ud posee una estructura, sin terminar. Haga click en el boton para seguir trabajando en ella.'}
      </B>
      {uid.role === 'Administrador' && (
        <Button
          onClick={handleNavigate}
          sector='primary'
          label={mensaje()}
          rounded
        />
      )}
    </div>
  )
})
FirstTimeComponent.displayName = 'FirstTimeComponent'
export default FirstTimeComponent
