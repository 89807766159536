export const cleanSingleUpdatedState = (
  e: React.ChangeEvent<HTMLInputElement>,
  prevData: any,
  withTrim = false
): any => {
  const { name, value } = e.target
  const inputConfig = { ...prevData[name] }
  if (withTrim) inputConfig.value = value.trimStart()
  else inputConfig.value = value
  inputConfig.status = ''
  return { ...prevData, [name]: inputConfig }
}

export const cleanSingleSelectUpdatedState = (
  value: string,
  name: string,
  prevData: any
): any => {
  const inputConfig = { ...prevData[name] }
  inputConfig.value = value
  inputConfig.status = ''
  return { ...prevData, [name]: inputConfig }
}
