import React, { Fragment } from 'react'
import style from './notebooksEitable.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import Acotaciones from 'assets/img/notebooks/profesional_20x26/Acotaciones'
import DatosFechaLogo from 'assets/img/notebooks/profesional_20x26/DatosFechaLogo'

import PathSquare5mmVertical from 'assets/img/notebooks/profesional_20x26/PathSquare5mmVertical'
import PathSquare6mmVertical from 'assets/img/notebooks/profesional_20x26/PathSquare6mmVertical'
import PathSquare7mmVertical from 'assets/img/notebooks/profesional_20x26/PathSquare7mmVertical'
import PathSquare10mmVertical from 'assets/img/notebooks/profesional_20x26/PathSquare10mmVertical'
import PathSquare14mmVertical from 'assets/img/notebooks/profesional_20x26/PathSquare14mmVertical'
import PathDobleRayaVertical from 'assets/img/notebooks/profesional_20x26/PathDobleRayaVertical'
import PathPautadoVertical from 'assets/img/notebooks/profesional_20x26/PathPautadoVertical'
import PathRayado7mmVertical from 'assets/img/notebooks/profesional_20x26/PathRayado7mmVertical'
import { TPoints, TRayadosInside } from 'redux/reducers/notebooksSlice'
import PathBlancoVertical from 'assets/img/notebooks/profesional_20x26/PathBlancoVertical'

import PathSujecionEngomadoCocido from 'assets/img/notebooks/profesional_20x26/PathSujecionEngomadoCocido'
import PathSujecionSpiralInside from 'assets/img/notebooks/profesional_20x26/PathSujecionSpiralInside'

const VerticalNotebookEditable = () => {
  const {
    notebookFrontValues,
    notebookInsideValues,
    notebookSelected,
    insideLogo
  } = useSelector((state: RootState) => state.notebooks)
  const {
    striped,
    rounded,
    magin_complete,
    guide_point,
    logo_position,
    date_type,
    margin_date,
    interior_inks
  } = notebookInsideValues
  const { binding_color, binding_type } = notebookFrontValues

  const getInk = () => {
    return interior_inks.value !== ''
      ? (interior_inks.value as string)
      : 'black'
  }
  const getMargin = () => {
    return margin_date.value !== '' ? (margin_date.value as boolean) : true
  }
  return (
    <div className={`${style['div-container-sticky']}`}>
      <div className={`${style['div-notebook-svg']}`}>
        {[1, 2].map((item: number, index: number) => {
          let viewBox = item === 1 ? '150 0 640 700' : '150 0 640 700'

          return (
            <div
              key={index}
              className={`${style['div-polo-notebook-relative']} 
                  ${item === 1 && style['transform-invert']}`}
            >
              {logo_position.value !== 'none' && logo_position.value !== '' && (
                <Fragment>
                  {(() => {
                    let thisPosition = logo_position.value
                    if (item === 1) {
                      if (logo_position.value === 'left') thisPosition = 'right'
                      if (logo_position.value === 'right') thisPosition = 'left'
                    }
                    return (
                      <div
                        className={`
                            ${style[`logo-position-ver-${thisPosition}`]} 
                            ${item === 1 && style['transform-invert']}`}
                      >
                        <img className={`${style['svg']}`} src={insideLogo} />
                      </div>
                    )
                  })()}
                </Fragment>
              )}
              <div className={`${style['div-polo-svg-absolute']}`}>
                <Acotaciones
                  notebookType={notebookSelected}
                  className={`${style['svg']}`}
                  viewBox={viewBox}
                  show={item}
                />
              </div>
              <div className={`${style['div-shadows-svg-absolute']}`}></div>
              <div className={`${style['div-label-svg-absolute']}`}>
                {(() => {
                  {
                    switch (striped.value as TRayadosInside) {
                      case 'blanco':
                        return (
                          <PathBlancoVertical
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            viewBox={viewBox}
                            ink={getInk()}
                          />
                        )
                      case '5mm':
                        return (
                          <PathSquare5mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )
                      case '6mm':
                        return (
                          <PathSquare6mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )
                      case '7mm':
                        return (
                          <PathSquare7mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )
                      case '10mm':
                        return (
                          <PathSquare10mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )
                      case '14mm':
                        return (
                          <PathSquare14mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )
                      case 'rayado':
                        return (
                          <PathDobleRayaVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            show={item}
                            ink={getInk()}
                          />
                        )
                      case 'Pautado':
                        return (
                          <PathPautadoVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            ink={getInk()}
                          />
                        )
                      case 'rayado 7mm':
                        return (
                          <PathRayado7mmVertical
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={getInk()}
                            page={item}
                          />
                        )

                      default:
                        return <></>
                    }
                  }
                })()}
              </div>
              <div className={`${style['div-tags-svg-absolute']} `}>
                <DatosFechaLogo
                  dateStyle={date_type.value as number}
                  positionDates={logo_position.value as string}
                  datos2={`${style['date1-font-12']} 
                    ${getInk() !== 'black' && style['date1-blue']}`}
                  className={`${style['svg']}`}
                  viewBox={viewBox}
                  showSquare={getMargin()}
                  show={item}
                  ink={getInk()}
                />
              </div>

              <div className={`${style['div-sujecion-svg-absolute']}`}>
                {binding_type.value === 'Espiral' ? (
                  <PathSujecionSpiralInside
                    className={`${style['svg']}`}
                    viewBox={viewBox}
                    spiralColor={binding_color.value as string}
                    show={2}
                  />
                ) : (
                  <PathSujecionEngomadoCocido
                    className={`${style['svg']}`}
                    viewBox={viewBox}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VerticalNotebookEditable
