import React, { Fragment, useEffect } from 'react'
import GreenTagJacket from 'assets/img/uniforms/sportOutfit/jacket/GreenTagJacket'
import JacketSvg from 'assets/img/uniforms/sportOutfit/jacket/JacketSvg'
import LabelJacket from 'assets/img/uniforms/sportOutfit/jacket/LabelJacket'
import ShadowsJacket from 'assets/img/uniforms/sportOutfit/jacket/ShadowsJacket'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './poloeditable.module.scss'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const JacketEditable = () => {
  const { pictureLogo, chamarraDeportivaForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Chaqueta deportiva',
        description: 'Descripcion de la chaqueta deportiva'
      })
    )
    dispatch(setLabelsMenu(null))
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {chamarraDeportivaForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-chaqueta-${chamarraDeportivaForm.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}

          <div className={`${style['div-polo-svg-absolute']}`}>
            <JacketSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsJacket className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelJacket className={`${style['div-label-svg-absolute']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagJacket className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default JacketEditable
