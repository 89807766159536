import { memo } from 'react'
import { Form, Select } from 'antd'

export interface Options {
  value: string | number
  label: string
}

export interface SelectFormProps {
  name: string
  type?: string
  placeholder?: string
  options: Options[]
  label?: string
  rules?: Array<any>
  disabled?: boolean
  hasFeedback?:boolean
  allowClear?:boolean
}

const FormSelect = memo((props: SelectFormProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      hasFeedback={props?.hasFeedback != undefined ? props.hasFeedback: true}
    >
      <Select
        placeholder={props.placeholder}
        disabled={props.disabled}
        options={props.options}
        size='large'
        allowClear={props.allowClear}
      />
    </Form.Item>
  )
})

export default FormSelect
