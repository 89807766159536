import React from 'react'
import { TFormFaldaShortDeportivoOptions } from 'interfaces/uniforms-interface'
import { useDispatch, useSelector } from 'react-redux'
import { handlefaldaShortDeportivaForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const FaldaShortDeportivoOptions = () => {
  const { faldaShortDeportivaForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormFaldaShortDeportivoOptions,
    value: string | boolean
  ) => {
    dispatch(
      handlefaldaShortDeportivaForm({
        ...faldaShortDeportivaForm,
        [keyName]: {
          ...faldaShortDeportivaForm[
            keyName as TFormFaldaShortDeportivoOptions
          ],
          value: value
        }
      })
    )
  }

  return (
    <div>
      <ConstructorFormulary
        handleChange={handleChange}
        form={faldaShortDeportivaForm}
      />
    </div>
  )
}
export default FaldaShortDeportivoOptions
