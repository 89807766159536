import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  openModal: false,
}

export const addSupplyModalSlice = createSlice({
  name: 'addSupplyModal',
  initialState,
  reducers: {
    setSupplyToCatModal: (state, action) => {
      return {
        ...state,
        openModal: action.payload
      }
    },

  }
})

// Metodo global para actualizar el state
export const { setSupplyToCatModal } = addSupplyModalSlice.actions

export default addSupplyModalSlice.reducer
