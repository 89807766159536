import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './poloeditable.module.scss'
import { RootState } from 'redux/store/store'
import PathTShirt from 'assets/img/uniforms/T-shirt/male/PathTShirt'
import ShadowsTShirt from 'assets/img/uniforms/T-shirt/male/ShadowsTShirt'
import LabelsTShirts from 'assets/img/uniforms/T-shirt/male/LabelsTShirts'
import GreenTagsTShirt from 'assets/img/uniforms/T-shirt/male/GreenTagsTShirt'
import PathTShirtFemale from 'assets/img/uniforms/T-shirt/female/PathTShirtFemale'
import ShadowsTShirtFemale from 'assets/img/uniforms/T-shirt/female/ShadowsTShirtFemale'
import LabelsTShirtsFemale from 'assets/img/uniforms/T-shirt/female/LabelsTShirtsFemale'
import GreenTagsTShirtFemale from 'assets/img/uniforms/T-shirt/female/GreenTagsTShirtFemale'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const TShirtEditable = () => {
  const { pictureLogo, female, TShirtMale, TShirtFemale } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Playera tipo "T-Shirt"',
        description:
          'Descripcion de la t shirt'
      })
    )
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        {female ? (
          <Fragment>
            <div
              className={`${
                style[
                  `logo-position-TShirt-fema-${TShirtFemale.logoPosition.value}`
                ]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathTShirtFemale
                fill={TShirtFemale.color.value as string}
                stroke='black'
                className={`${style['svg']}`}
                neckType={TShirtFemale.neckType.value as string}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsTShirtFemale
                fill={TShirtFemale.color.value as string}
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelsTShirtsFemale className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsTShirtFemale className={`${style['svg']}`} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={`${
                style[`logo-position-TShirt-${TShirtMale.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathTShirt
                fill={TShirtMale.color.value as string}
                stroke='black'
                className={`${style['svg']}`}
                neckType={TShirtMale.neckType.value as string}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsTShirt
                fill={TShirtMale.color.value as string}
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelsTShirts className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsTShirt className={`${style['svg']}`} />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}
export default TShirtEditable
