import { getShadow } from 'pages/utils/uniforms/getShadows'
import React from 'react'

const ShadowsPolo = ({
  className,
  fill = '#dadada'
}: {
  className: string
  fill?: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Sombras' stroke={getShadow(fill)} fill={getShadow(fill)}>
        <g id='Sombras-2' data-name='Sombras'>
          <path
            id='Sombra'
            d='m634.53,411.28c4.89-.35,23.36-10.54,38.24-18.04-10.87-17.82-11.29-32.15-14.98-57.92-4.91-34.39-9.94-15.92-9.94,13.92s-1.99,30.83-7.96,6.96c-5.97-23.87-4.97-46.74-3.98-70.61.99-23.87,5.97-44.75-1.99-31.83-7.96,12.93-11.93,30.83-13.93,52.71-1.99,21.88-6.96,22.87-9.95,10.94-5.34-21.35,3.98-17.9-7.95-39.78-7.68-14.08-9.95-22.87-6.96-55.69l-1-2.99c-17.5,19.52-28.39,43.25-35.14,66.11,3.36,20.93,21.9,31.46,26.49,37.96,8.61,12.2,20.67,42.31,22.46,43.03,1.8.72,4.33,3.95,4.33,7.53s8.25,18.66,11.48,28.35c3.23,9.69,5.74,9.69,10.77,9.33Z'
          />
          <path
            id='Sombra-2'
            data-name='Sombra'
            d='m60.98,355.12c13.99,10.37,29.75,18.45,42.98,22.73-1.05-69.54,16.89-114.03,29.46-179.66,12.7-66.35-44.56,80.6-72.44,156.92Z'
          />
          <path
            id='Sombra-3'
            data-name='Sombra'
            d='m233.09,245.92c-2.61-17.24-37.62-22.93-41.6,18.84-1.73,18.19,2.34,47.12,2.98,56.69,1.36,20.12-21.96-53.6-24.57-87.02-2.61-33.42-8.3-25.9-12-3.65-3.66,21.93,2.09,70.5,8.35,108.61,6.52,39.71.54,30.45-7.83,17.23-9.93-15.66-13.58-38.12-14.1-70.5-.52-32.41-3.13-29.24-6.79-8.35-3.42,19.51-9.56,77.28,2.41,112.48,3.88,1.43,7.78,2.89,12.26,4.29,13.05,4.1,19.76,4.48,24.61,3.35,4.85-1.12,6.34-3.35,8.58-7.83,2.24-4.48,8.95-19.02,14.92-26.48,5.97-7.46,6.71-10.44,8.58-16.04,1.86-5.59,7.46-16.41,22-29.09,1.89-1.64,4.03-3.47,6.24-5.59-.18-26.28-2.43-56.19-4.05-66.95Z'
          />
          <path
            id='Sombra-4'
            data-name='Sombra'
            d='m245.43,764.73c14.29,4.6,30.01,8.72,49.48,11.35,27.19-85.87,56.91-234.88,54.28-276.36-3.48-54.81-8.36,32.61-40.98,103.35-16.95,36.75-13.06,22.53-9.87-3.25,3.19-25.78,10.8-125.13,8.71-166.9-2.15-42.84-5.71-33.99-10.97,6.67-5.26,40.65-40.38,181.05-65.98,243.43-26.66,64.94-3.55,54.48,16.4,22.85,27.36-43.39,25.87-34.38-2.14,58.52.36.12.71.24,1.08.36Z'
          />
          <path
            id='Sombra-5'
            data-name='Sombra'
            d='m600.45,720.87c-5.12-10.89-33.81-55.01-66.62-152.2-27.07-80.17-42.15-112.41-67.95-146.48-30.87-40.77-27.29-25.32-8.71,18.7,31.62,74.92,49.19,135.64,56.4,190.81,7.21,55.17,13.24,100.4,24.42,126.71,1.53,3.6,2.96,7.01,4.27,10.25,19.51-5.89,56.91-14.23,59.86-19.8,2.57-4.84,1.68-11.35,1.68-21.55-.36-.75-3-5.68-3.36-6.44Z'
          />
          <path
            id='Sombra-6'
            data-name='Sombra'
            d='m594.7,543.5c-37.06-73.82-81.05-111.03-102.41-129.32-33.13-28.37-59.68-42.82-16.44,7.21,23.31,26.97,52.93,53.97,119.28,204.07-1.38-22.88,1.9-58.76,0-75.55-.34-2.99-.27-3.51-.43-6.42Z'
          />
          <path
            id='Sombra-7'
            data-name='Sombra'
            d='m191.14,218.92c-21.88-66.63-10.88-55.17,1.06-27.32,11.9,27.75,12.16,72.65,34.98,108.39,13.33,20.88,4.08-3.21.41-17.9-3.98-15.91,2.53-9.86,7.96,1,5.96,11.93,21.37,39.03,30.83,45.75,30.83,21.88,27.58,27.59,4.97,18.89-25.86-9.94-23.87,4.97-27.85,64.65-4.18,62.71-25.85,180.01-19.89,74.59,5.97-105.42-3.01-178.35-32.46-268.04Z'
          />
          <path
            id='Sombra-8'
            data-name='Sombra'
            d='m622.07,179.42c13.76-38.29,25.81-58.5,4.66-23.41-21.15,35.09-36.77,91.57-42.3,121.28-9.7,52.13-17.46,73.16-40.05,82.66-19.77,8.31-23.87,11.93-11.11,17.04,23.54,9.43,21.39,11.82,24.74,16.4,22.18,30.2,18.05-9.46,23.17-52.34,5.12-42.88,25.25-118.12,40.89-161.63Z'
          />
          <path
            id='Sombra-9'
            data-name='Sombra'
            d='m260.19,437.31c.99-50.72,7.6-34.62,9.95-9.94,5.97,62.65-4.97,145.2-24.86,196.91-19.89,51.71-35.42,24.07-17.52-28.65,17.9-52.71,31.87-129.22,32.44-158.33Z'
          />
          <path
            id='Sombra-10'
            data-name='Sombra'
            d='m728.12,348.93c-27.35-53.11-53.66-134.1-63.89-169.06-11.93-40.78-18.89-35.8-11.93-5.97,6.96,29.83,3.98,68.62,13.92,132.27,5.57,35.67,13.02,52.61,19.02,67.25,14.46-7.43,32.51-17.12,42.88-24.5Z'
          />
          <path
            id='Sombra-11'
            data-name='Sombra'
            d='m443.79,462.98c-17.8-46.63-10.17,20.35-11.87,50.02-1.7,29.67-8.48-32.86-45.9-86.62-39.73-57.09,16.32,72.12-6.41,156.87-11.93,44.49-74.41,164.14-32.35,113.23,34.15-41.34,41.34-35.94,35.95,0-3.3,21.99-2.82,44.68-25.04,86.69,18.96,1.23,33-.67,47.24-1.45,21.3-1.16,37.54-1.88,76.5-3.87,1.15-.06,2.27-.31,3.4-.48,3.14-16.77,5.95-40.36,5.95-71.08,0-69.51-29.67-196.68-47.47-243.3Z'
          />
          <path
            id='Sombra-12'
            data-name='Sombra'
            d='m386.56,199.76c1.69-12.13,17.85-32,46.08-38.54h0c-26.97,10.92-35.09,20.69-42.77,34.17-2.67,4.69-1.87,9.1-4.44,22.49-.42-7.09.77-15.52,1.13-18.12Z'
          />
          <path
            id='Sombra-13'
            data-name='Sombra'
            d='m383.5,184.4c-1.69-12.13-8.06-34.77-27.67-37.48l8.64,4.13c12.06,10.18,10.19,12.12,16.05,25.73,2.14,4.95,1.29,14.52,3.87,27.91.41-7.09-.54-17.68-.9-20.28Z'
          />
          <path
            id='Sombra-14'
            data-name='Sombra'
            d='m391.82,184.88c6.69-9.9,16.55-17.25,26.93-22.97,10.42-5.78,22.06-9.41,33.92-10.53,0,0,.01.1.01.1-23,4.25-46.53,14.48-60.86,33.4h0Z'
          />
          <path
            id='Sombra-15'
            data-name='Sombra'
            d='m350.93,134.14s-15.18-36.03-16.13-37.12c-.95-1.09-9.01-20.59-9.43-26.68-.47-6.66.88-14.77,9.58-19.25,14.11-7.27,72.68-5.8,72.68-5.8,0,0-54.97-.73-59.64-.38-10.51.78-22.17,8.38-24.75,11.64-2.57,3.26-4.12,5.75-2.13,14.71,1.99,8.96,12.1,31.39,13.37,35.17,1.27,3.77,16.45,27.72,16.45,27.72Z'
          />
          <path
            id='Sombra-16'
            data-name='Sombra'
            d='m452.79,122.64c9.16-13.51,21.63-36.44,22.79-52.36.67-9.18-.19-8.55-9.36-12.89-1.39-.7-16.52-.15-18.51-1.66,5.63.44,21.95-1.26,27.71,5.88,3.12,3.88,1.74,9.94,1.74,11.69-.66,11.15-7.77,23.43-10.26,33.11-2.33,9.08-6.78,16.9-9.41,20.52-1.43,1.97-29.25,11.61-35.11,17.3,4.2-8.43,24.61-13.37,30.4-21.58Z'
          />
          <polygon
            id='Sombra-17'
            data-name='Sombra'
            points='403.92 199.05 403.57 198.93 403.78 198.35 403.08 198 403.65 197.38 402.71 197.11 403.84 196.59 402.32 196.17 404.83 194.98 401.01 195.31 404.22 193.91 402.26 194.1 402.22 193.73 406.45 193.34 403.2 194.75 406.81 194.44 403.36 196.08 404.9 196.5 403.75 197.03 404.31 197.19 403.68 197.89 404.23 198.16 403.92 199.05'
          />
        </g>
        <g id='Botones'>
          <g id='Botón_abajo' data-name='Botón abajo'>
            <g id='Oyitos_botón' data-name='Oyitos botón'>
              <path d='m402.89,182.6c3.07.35,5.28,3.12,4.93,6.19-.35,3.07-3.12,5.27-6.19,4.93-3.07-.35-5.27-3.12-4.93-6.19.34-3.07,3.11-5.28,6.19-4.93Z' />
              <path d='m402.53,185.84c1.21.14,2.08,1.16,1.96,2.3-.13,1.14-1.21,1.95-2.42,1.81-1.21-.14-2.09-1.17-1.95-2.3.13-1.14,1.21-1.94,2.41-1.81Z' />
              <path d='m401.47,188.25c.27.03.47.28.44.56-.03.27-.28.47-.56.44-.27-.03-.47-.28-.44-.55.03-.28.29-.48.56-.44Z' />
              <path d='m401.47,186.67c.27.03.47.28.44.56-.03.27-.27.47-.56.44-.27-.03-.47-.27-.44-.55.03-.27.29-.47.56-.44Z' />
              <path d='m402.97,188.25c.28.02.48.28.44.55-.03.28-.28.48-.56.44-.27-.04-.47-.28-.44-.55.02-.28.27-.48.55-.44Z' />
              <path d='m403.19,186.67c.28.03.47.28.44.56-.03.27-.28.47-.56.44-.27-.03-.46-.27-.44-.55.03-.27.27-.47.55-.44Z' />
            </g>
            <g id='Semper'>
              <path d='m402.62,183.47s.09.08.16.09c.09.03.16,0,.18-.08.02-.07-.01-.12-.1-.18-.1-.08-.16-.16-.14-.26.03-.11.14-.17.28-.13.07.02.12.06.15.08l-.04.07s-.06-.06-.13-.07c-.1-.03-.15.02-.16.07-.02.07.02.11.11.18.11.08.16.16.13.26-.03.11-.13.18-.3.13-.07-.02-.14-.06-.17-.1l.04-.07Z' />
              <path d='m403.58,183.55l-.28-.11-.09.24.31.12-.03.07-.4-.16.25-.68.39.15-.03.07-.29-.12-.08.21.28.11-.03.07Z' />
              <path d='m404.31,183.94c.05-.09.1-.2.15-.28h0c-.06.06-.14.13-.22.2l-.28.25-.06-.03.07-.36c.02-.11.04-.2.06-.29h0c-.05.08-.11.18-.17.28l-.17.27-.08-.04.4-.62.1.06-.06.37c-.02.09-.03.18-.05.25h0c.05-.05.12-.11.19-.18l.29-.25.1.06-.31.66-.08-.04.14-.29Z' />
              <path d='m404.84,183.81s.1.06.16.1c.08.06.12.12.13.18.01.05,0,.11-.04.16-.04.05-.09.08-.15.09-.07.01-.16-.02-.23-.08-.02-.02-.05-.04-.06-.05l-.19.23-.08-.06.46-.56Zm-.14.34s.03.04.06.05c.09.07.19.07.25,0,.06-.07.03-.15-.05-.22-.03-.03-.06-.04-.08-.05l-.18.22Z' />
              <path d='m405.4,184.8l-.21-.21-.19.19.23.23-.06.06-.3-.3.53-.53.29.29-.06.06-.22-.22-.17.17.21.21-.06.06Z' />
              <path d='m405.83,184.73s.08.09.13.14c.06.08.09.15.09.21,0,.05-.03.1-.07.14-.08.06-.17.05-.23,0h0c.02.06,0,.12-.05.18-.06.08-.11.14-.12.17l-.06-.08s.05-.08.1-.15c.07-.08.07-.14.02-.21l-.06-.07-.25.2-.06-.08.57-.46Zm-.2.29l.06.08c.06.08.15.1.21.05.07-.06.06-.14,0-.23-.03-.04-.05-.06-.07-.07l-.21.17Z' />
              <path d='m406.01,185.83l-.24.04-.05-.09.76-.13.06.11-.52.59-.05-.09.17-.18-.13-.24Zm.18.19l.15-.17s.07-.07.1-.1h0s-.09.02-.13.03l-.22.04.11.2Z' />
              <path d='m406.75,186.16l.03.09-.6.26.12.3-.07.03-.16-.4.68-.28Z' />
              <path d='m406.92,186.83l-.07-.22.08-.02.16.53-.08.02-.06-.22-.64.19-.03-.09.64-.19Z' />
              <path d='m407.11,187.33l-.71.14-.02-.1.71-.14.02.1Z' />
              <path d='m407.19,187.67l-.44.04c-.17.02-.23.1-.22.2.01.11.09.18.25.16l.44-.04v.1s-.43.04-.43.04c-.23.02-.34-.09-.35-.25-.01-.15.06-.28.29-.3l.44-.04v.1Z' />
              <path d='m406.63,188.34s-.05.11-.05.17c0,.1.05.16.13.16s.11-.04.15-.14c.04-.12.11-.2.21-.2.12,0,.2.1.2.24,0,.08-.02.13-.04.17l-.08-.03s.03-.07.03-.14c0-.1-.06-.14-.11-.14-.07,0-.1.05-.15.15-.05.13-.11.19-.22.19s-.21-.08-.21-.26c0-.07.02-.15.05-.19l.08.02Z' />
            </g>
          </g>
          <g id='Botón'>
            <g id='Oyitos_botón-2' data-name='Oyitos botón'>
              <path d='m390.47,160.9c3.07.35,5.28,3.12,4.93,6.19-.35,3.07-3.12,5.27-6.19,4.93-3.07-.35-5.27-3.12-4.93-6.19.34-3.07,3.11-5.28,6.19-4.93Z' />
              <path d='m390.12,164.13c1.21.14,2.08,1.16,1.96,2.3-.13,1.14-1.21,1.95-2.42,1.81-1.21-.14-2.09-1.17-1.95-2.3.13-1.14,1.21-1.94,2.41-1.81Z' />
              <path d='m389.06,166.54c.27.03.47.28.44.56-.03.27-.28.47-.56.44-.27-.03-.47-.28-.44-.55.03-.28.29-.48.56-.44Z' />
              <path d='m389.06,164.96c.27.03.47.28.44.56-.03.27-.27.47-.56.44-.27-.03-.47-.27-.44-.55.03-.27.29-.47.56-.44Z' />
              <path d='m390.55,166.54c.28.02.48.28.44.55-.03.28-.28.48-.56.44-.27-.04-.47-.28-.44-.55.02-.28.27-.48.55-.44Z' />
              <path d='m390.78,164.96c.28.03.47.28.44.56-.03.27-.28.47-.56.44-.27-.03-.46-.27-.44-.55.03-.27.27-.47.55-.44Z' />
            </g>
            <g id='Semper-2' data-name='Semper'>
              <path d='m390.21,161.76s.09.08.16.09c.09.03.16,0,.18-.08.02-.07-.01-.12-.1-.18-.1-.08-.16-.16-.14-.26.03-.11.14-.17.28-.13.07.02.12.06.15.08l-.04.07s-.06-.06-.13-.07c-.1-.03-.15.02-.16.07-.02.07.02.11.11.18.11.08.16.16.13.26-.03.11-.13.18-.3.13-.07-.02-.14-.06-.17-.1l.04-.07Z' />
              <path d='m391.16,161.85l-.28-.11-.09.24.31.12-.03.07-.4-.16.25-.68.39.15-.03.07-.29-.12-.08.21.28.11-.03.07Z' />
              <path d='m391.9,162.23c.05-.09.1-.2.15-.28h0c-.06.06-.14.13-.22.2l-.28.25-.06-.03.07-.36c.02-.11.04-.2.06-.29h0c-.05.08-.11.18-.17.28l-.17.27-.08-.04.4-.62.1.06-.06.37c-.02.09-.03.18-.05.25h0c.05-.05.12-.11.19-.18l.29-.25.1.06-.31.66-.08-.04.14-.29Z' />
              <path d='m392.42,162.11s.1.06.16.1c.08.06.12.12.13.18.01.05,0,.11-.04.16-.04.05-.09.08-.15.09-.07.01-.16-.02-.23-.08-.02-.02-.05-.04-.06-.05l-.19.23-.08-.06.46-.56Zm-.14.34s.03.04.06.05c.09.07.19.07.25,0,.06-.07.03-.15-.05-.22-.03-.03-.06-.04-.08-.05l-.18.22Z' />
              <path d='m392.99,163.09l-.21-.21-.19.19.23.23-.06.06-.3-.3.53-.53.29.29-.06.06-.22-.22-.17.17.21.21-.06.06Z' />
              <path d='m393.42,163.02s.08.09.13.14c.06.08.09.15.09.21,0,.05-.03.1-.07.14-.08.06-.17.05-.23,0h0c.02.06,0,.12-.05.18-.06.08-.11.14-.12.17l-.06-.08s.05-.08.1-.15c.07-.08.07-.14.02-.21l-.06-.07-.25.2-.06-.08.57-.46Zm-.2.29l.06.08c.06.08.15.1.21.05.07-.06.06-.14,0-.23-.03-.04-.05-.06-.07-.07l-.21.17Z' />
              <path d='m393.6,164.12l-.24.04-.05-.09.76-.13.06.11-.52.59-.05-.09.17-.18-.13-.24Zm.18.19l.15-.17s.07-.07.1-.1h0s-.09.02-.13.03l-.22.04.11.2Z' />
              <path d='m394.34,164.45l.03.09-.6.26.12.3-.07.03-.16-.4.68-.28Z' />
              <path d='m394.51,165.12l-.07-.22.08-.02.16.53-.08.02-.06-.22-.64.19-.03-.09.64-.19Z' />
              <path d='m394.7,165.63l-.71.14-.02-.1.71-.14.02.1Z' />
              <path d='m394.78,165.96l-.44.04c-.17.02-.23.1-.22.2.01.11.09.18.25.16l.44-.04v.1s-.43.04-.43.04c-.23.02-.34-.09-.35-.25-.01-.15.06-.28.29-.3l.44-.04v.1Z' />
              <path d='m394.21,166.63s-.05.11-.05.17c0,.1.05.16.13.16s.11-.04.15-.14c.04-.12.11-.2.21-.2.12,0,.2.1.2.24,0,.08-.02.13-.04.17l-.08-.03s.03-.07.03-.14c0-.1-.06-.14-.11-.14-.07,0-.1.05-.15.15-.05.13-.11.19-.22.19s-.21-.08-.21-.26c0-.07.02-.15.05-.19l.08.02Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShadowsPolo
