import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare14mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro aleman 14mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_aleman_14mm_Completo'
              data-name='Path Cuadro aleman 14mm Completo'
            >
              <g
                id='Path_Cuadro_aleman_14mm_Completo-2'
                data-name='Path Cuadro aleman 14mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='123.16' y1='151.56' x2='123.16' y2='622.51' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='648.81' y1='151.56' x2='648.81' y2='622.51' />
                  </g>

                  <line x1='160.79' y1='151.56' x2='160.79' y2='622.51' />
                  <line x1='198.42' y1='151.56' x2='198.42' y2='622.51' />
                  <line x1='236.05' y1='151.56' x2='236.05' y2='622.51' />
                  <line x1='273.68' y1='151.56' x2='273.68' y2='622.51' />
                  <line x1='311.02' y1='151.56' x2='311.02' y2='622.51' />
                  <line x1='348.35' y1='151.56' x2='348.35' y2='622.51' />
                  <line x1='385.98' y1='151.56' x2='385.98' y2='622.51' />
                  <line x1='423.61' y1='151.56' x2='423.61' y2='622.51' />
                  <line x1='461.24' y1='151.56' x2='461.24' y2='622.51' />
                  <line x1='498.87' y1='151.56' x2='498.87' y2='622.51' />
                  <line x1='536.21' y1='151.56' x2='536.21' y2='622.51' />
                  <line x1='573.55' y1='151.56' x2='573.55' y2='622.51' />
                  <line x1='611.18' y1='151.56' x2='611.18' y2='622.51' />
                  <line x1='85.23' y1='583.35' x2='686.44' y2='583.35' />
                  <line x1='85.23' y1='544.11' x2='686.44' y2='544.11' />
                  <line x1='85.23' y1='504.86' x2='686.44' y2='504.86' />
                  <line x1='85.23' y1='465.62' x2='686.44' y2='465.62' />
                  <line x1='85.23' y1='426.68' x2='686.44' y2='426.68' />
                  <line x1='85.23' y1='387.74' x2='686.44' y2='387.74' />
                  <line x1='85.23' y1='348.5' x2='686.44' y2='348.5' />
                  <line x1='85.23' y1='309.25' x2='686.44' y2='309.25' />
                  <line x1='85.23' y1='270.01' x2='686.44' y2='270.01' />
                  <line x1='85.23' y1='230.76' x2='686.44' y2='230.76' />
                  <line x1='85.23' y1='191.82' x2='686.44' y2='191.82' />
                </g>
                <g
                  fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                >
                  {(() => {
                    switch (puntoGuia) {
                      case 'PuntoPuntoPaloma':
                        return (
                          <g id='Punto_guia' data-name='Punto guia'>
                            <path d='M105.72,247.2a28.21,28.21,0,0,0-4.26,5.44l-.31-.68c-.61-1.37-1.17-2.06-1.68-2.06A2.94,2.94,0,0,0,97.53,251a2.53,2.53,0,0,1,1.14.88,10,10,0,0,1,1.09,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.35-.49a23.8,23.8,0,0,0-5.08,4' />
                            <path d='M104.58,212.6a3.25,3.25,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                            <path d='M104.58,175.56a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                            <path d='M104.58,293.12a3.25,3.25,0,1,0-3.25-3.25,3.25,3.25,0,0,0,3.25,3.25' />
                            <path d='M105.72,364.76a27.91,27.91,0,0,0-4.26,5.44l-.31-.69c-.61-1.37-1.17-2.05-1.68-2.05a2.9,2.9,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.14.87,10.26,10.26,0,0,1,1.09,2l.27.64a14.32,14.32,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.73-.49a33.47,33.47,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.35-.49a24.19,24.19,0,0,0-5.08,4.06' />
                            <path d='M104.58,330.15a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                            <path d='M104.58,410.67a3.25,3.25,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                            <path d='M105.72,482.31a28.21,28.21,0,0,0-4.26,5.44l-.31-.69c-.61-1.36-1.17-2.05-1.68-2.05a2.9,2.9,0,0,0-1.94,1.12,2.57,2.57,0,0,1,1.14.87,10.1,10.1,0,0,1,1.09,2l.27.64a13.53,13.53,0,0,1,.56,1.51,12,12,0,0,1,1.23-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.35-.49a23.8,23.8,0,0,0-5.08,4.05' />
                            <path d='M104.58,447.71a3.25,3.25,0,1,0-3.25-3.25,3.25,3.25,0,0,0,3.25,3.25' />
                            <path d='M104.58,528.22a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                            <path d='M105.72,599.87a28.16,28.16,0,0,0-4.26,5.43l-.31-.68c-.61-1.37-1.17-2-1.68-2a2.92,2.92,0,0,0-1.94,1.11,2.53,2.53,0,0,1,1.14.88,10,10,0,0,1,1.09,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.35-.49a23.56,23.56,0,0,0-5.08,4.06' />
                            <path d='M104.58,565.26a3.25,3.25,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                          </g>
                        )
                      case 'PuntoPaloma':
                        return (
                          <g id='Punto_guia-2' data-name='Punto guia'>
                            <path d='M104.6,175.56a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                            <path d='M105.75,209a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a23.87,23.87,0,0,0-5.08,4.06' />
                            <path d='M104.6,253.74a3.25,3.25,0,1,0-3.24-3.25,3.26,3.26,0,0,0,3.24,3.25' />
                            <path d='M105.75,287.17a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05A2.86,2.86,0,0,0,97.56,291a2.36,2.36,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                            <path d='M104.6,331.91a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                            <path d='M105.75,365.34a28.68,28.68,0,0,0-4.27,5.44l-.3-.68c-.62-1.37-1.17-2.06-1.68-2.06a2.9,2.9,0,0,0-1.94,1.12,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4' />
                            <path d='M104.6,410.08a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                            <path d='M105.75,443.52a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2-1.68-2a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10.2,10.2,0,0,1,1.1,2l.27.64a14.32,14.32,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a34.57,34.57,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a24.19,24.19,0,0,0-5.08,4.06' />
                            <path d='M104.6,488.26a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                            <path d='M105.75,521.69a28.68,28.68,0,0,0-4.27,5.44l-.3-.69c-.62-1.36-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,12,12,0,0,1,1.23-.94l.72-.49A34.17,34.17,0,0,1,106.5,523a26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                            <path d='M104.6,566.43a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                            <path d='M105.75,599.87a28.63,28.63,0,0,0-4.27,5.43l-.3-.68c-.62-1.37-1.17-2-1.68-2a2.88,2.88,0,0,0-1.94,1.11,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a23.56,23.56,0,0,0-5.08,4.06' />
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro aleman 14mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_aleman_14mm_Datos'
              data-name='Path Cuadro aleman 14mm Datos'
            >
              <g
                id='Path_Cuadro_aleman_14mm_Datos-2'
                data-name='Path Cuadro aleman 14mm Datos'
              >
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='123.16' y1='196.94' x2='123.16' y2='588.7' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='648.81' y1='196.94' x2='648.81' y2='588.7' />
                  </g>
                  <line x1='160.79' y1='196.94' x2='160.79' y2='588.7' />
                  <line x1='198.42' y1='196.94' x2='198.42' y2='588.7' />
                  <line x1='236.05' y1='196.94' x2='236.05' y2='588.7' />
                  <line x1='273.68' y1='196.94' x2='273.68' y2='588.7' />
                  <line x1='311.02' y1='196.94' x2='311.02' y2='588.7' />
                  <line x1='348.35' y1='196.94' x2='348.35' y2='588.7' />
                  <line x1='385.98' y1='196.94' x2='385.98' y2='588.7' />
                  <line x1='423.61' y1='196.94' x2='423.61' y2='588.7' />
                  <line x1='461.24' y1='196.94' x2='461.24' y2='588.7' />
                  <line x1='498.87' y1='196.94' x2='498.87' y2='588.7' />
                  <line x1='536.21' y1='196.94' x2='536.21' y2='588.7' />
                  <line x1='573.55' y1='196.94' x2='573.55' y2='588.7' />
                  <line x1='611.18' y1='196.94' x2='611.18' y2='588.7' />

                  <line x1='85.23' y1='550.24' x2='686.44' y2='550.24' />
                  <line x1='85.23' y1='510.99' x2='686.44' y2='510.99' />
                  <line x1='85.23' y1='472.06' x2='686.44' y2='472.06' />
                  <line x1='85.23' y1='433.12' x2='686.44' y2='433.12' />
                  <line x1='85.23' y1='393.87' x2='686.44' y2='393.87' />
                  <line x1='85.23' y1='354.63' x2='686.44' y2='354.63' />
                  <line x1='85.23' y1='315.38' x2='686.44' y2='315.38' />
                  <line x1='85.23' y1='276.14' x2='686.44' y2='276.14' />
                  <line x1='85.23' y1='237.2' x2='686.44' y2='237.2' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m666.12,292.97c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.27,258.37c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m667.27,221.33c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m667.27,338.88c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.12,410.53c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.27,375.92c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m667.27,456.44c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.12,528.08c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.27,493.47c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m667.27,573.99c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m667.24,221.33c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.1,254.77c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.24,299.5c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.1,332.94c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.24,377.68c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.1,411.11c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.24,455.85c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.1,489.29c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m667.24,534.03c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m666.1,567.46c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M105.72,293a28.21,28.21,0,0,0-4.26,5.44l-.31-.68c-.61-1.37-1.17-2.06-1.68-2.06a2.94,2.94,0,0,0-1.94,1.12,2.53,2.53,0,0,1,1.14.88,9.7,9.7,0,0,1,1.09,2l.27.64a13.53,13.53,0,0,1,.56,1.51,12.3,12.3,0,0,1,1.23-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.35-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M104.58,258.37a3.25,3.25,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M104.58,221.33a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M104.58,338.88a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M105.72,410.53a27.91,27.91,0,0,0-4.26,5.44l-.31-.69c-.61-1.37-1.17-2-1.68-2a2.9,2.9,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.14.87,10.26,10.26,0,0,1,1.09,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.73-.49a33.14,33.14,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.35-.49a23.87,23.87,0,0,0-5.08,4.06' />
                                    <path d='M104.58,375.92a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M104.58,456.44a3.25,3.25,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M105.72,528.08a27.91,27.91,0,0,0-4.26,5.44l-.31-.69c-.61-1.37-1.17-2.05-1.68-2.05a2.9,2.9,0,0,0-1.94,1.12,2.5,2.5,0,0,1,1.14.87,10.1,10.1,0,0,1,1.09,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.35-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M104.58,493.47a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                    <path d='M104.58,574a3.24,3.24,0,1,0-3.25-3.24,3.24,3.24,0,0,0,3.25,3.24' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M104.6,221.33a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M105.75,254.77a28.63,28.63,0,0,0-4.27,5.43l-.3-.68c-.62-1.37-1.17-2.06-1.68-2.06a2.9,2.9,0,0,0-1.94,1.12,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.56,23.56,0,0,0-5.08,4.06' />
                                    <path d='M104.6,299.5a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M105.75,332.94a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10.2,10.2,0,0,1,1.1,2l.27.64a14.32,14.32,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a34.57,34.57,0,0,1,3.93-6.15,26.83,26.83,0,0,1,4.67-4.86l-.34-.5a24.19,24.19,0,0,0-5.08,4.06' />
                                    <path d='M104.6,377.68a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M105.75,411.11a28.68,28.68,0,0,0-4.27,5.44l-.3-.69c-.62-1.36-1.17-2.05-1.68-2.05a2.9,2.9,0,0,0-1.94,1.12,2.45,2.45,0,0,1,1.13.88,9.65,9.65,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,12.3,12.3,0,0,1,1.23-.93l.72-.5a34.17,34.17,0,0,1,3.93-6.14,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M104.6,455.85a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M105.75,489.29a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10.2,10.2,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a34.21,34.21,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a23.87,23.87,0,0,0-5.08,4.06' />
                                    <path d='M104.6,534a3.25,3.25,0,1,0-3.24-3.25A3.25,3.25,0,0,0,104.6,534' />
                                    <path d='M105.75,567.46a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro aleman 14mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_aleman_14mm_Completo'
                data-name='Margenes Cuadro aleman 14mm Completo'
              >
                <g
                  id='Margenes_Cuadro_aleman_14mm_Completo-2'
                  data-name='Margenes Cuadro aleman 14mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro Cuadro aleman 14mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_Cuadro_aleman_14mm_Datos'
                data-name='Margenes Cuadro Cuadro aleman 14mm Datos'
              >
                <g
                  id='Margenes_Cuadro_Cuadro_aleman_14mm_Datos-2'
                  data-name='Margenes Cuadro Cuadro aleman 14mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='391.13'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08v364a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08v-364a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08v364A14.08,14.08,0,0,0,99.31,588.7H672.84a14.08,14.08,0,0,0,14.08-14.08v-364a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='391.13'
                      />
                      <path d='M685.92,197.56V587.7H86.23V197.56H685.92m1-1H85.23V588.7H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare14mmHorizontal
