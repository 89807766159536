import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { InputFormConfig } from 'types'

import { getLevels } from 'services/schoolUserServices/levels'
import { INPUTS_INITIAL_STATE_PACKAGES } from 'redux/reducers/packages'

import { getNotebookPackageByLevel } from 'services/notebooks/getNotebookPackage'
import filterlevelsByPermission from 'utilities/filterlevelsByPermission'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

export interface levelsFormI {
  name: InputFormConfig
}

interface tabInterface {
  key: any
  id: number
  label: string
  grades: any[]
}
export const useNotebooksPackages = () => {
  Object.freeze(INPUTS_INITIAL_STATE_PACKAGES)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [reLoad, setReLoad] = useState(false)

  const [LoadingList, setLoadingList] = useState(true)

  const [tabs, setTabs] = useState<tabInterface[]>([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedLevel, setSelectedLevel] = useState<any>(undefined)

  useEffect(() => {
    let unmounted = false
    const controller = new AbortController()
    if (!unmounted) void getlevelList(controller)
    return () => {
      controller.abort()
    }
  }, [])

  const handleGetPackageListByLevel = () => {
    void getNotebookPackageByLevel(tabs[selectedTab].id)
      .then((result) => {
        const levelsGradesPackages = reOrderPackagesOnGrades(
          tabs[selectedTab],
          result.data.details
        )
        setSelectedLevel(levelsGradesPackages)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handleReload = () => {
    setReLoad(!reLoad)
  }
  const reOrderPackagesOnGrades = (selectedLevel: any, packages: any) => {
    let grades = selectedLevel.grades
    const gradesWithPackages = grades.map((res: any) => {
      const packagesForThisGrade = packages.filter(
        (grade: any) => grade.id_grade === res.id
      )
      return { ...res, packages: packagesForThisGrade }
    })
    return { ...selectedLevel, grades: gradesWithPackages }
  }

  useEffect(() => {
    if (tabs.length > 0) {
      handleGetPackageListByLevel()
    }
  }, [selectedTab, tabs, reLoad])

  const handleChangeLevelTab = (key: any) => {
    setSelectedTab(key)
  }

  /* GETS */

  const getlevelList = async (controller?: AbortController) => {
    try {
      setLoadingList(true)
      const result:any = await getLevels({ controller })
      const filteredLevelsByPermision = filterlevelsByPermission(
        result.data.levels,
        uid.responsabilities,
        uid.role
      )
      const TabsByLevels: any = filteredLevelsByPermision.map(
        (res: any, key: number) => {
          return {
            key,
            id: res.id,
            label: res.name,
            grades: res.grades
          }
        }
      )
      setTabs(TabsByLevels)
      setLoadingList(false)
    } catch (err: any) {
      const Error = err?.response?.data?.message
      if (Error) ErrorToast(Error)
      setLoadingList(false)
    }
  }

  return {
    tabs,
    LoadingList,
    handleReload,
    handleChangeLevelTab,
    selectedLevel,
    setSelectedLevel
  }
}
