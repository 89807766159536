import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

import PantsFrontalSvg from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/PantsFrontalSvg'
import ShadowsFrontalPants from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/ShadowsFrontalPants'
import LabelFrontalPants from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/LabelFrontalPants'
import { handleClotheName } from 'redux/reducers/uniformsSlice'

const PantsDeportivoEditable = () => {
  const { pantsDeportivaForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Pants deportivos',
        description: 'Descripcion de los pants deportivos'
      })
    )
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {pantsDeportivaForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-pants-${pantsDeportivaForm.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}

          <div className={`${style['div-polo-svg-absolute']}`}>
            <PantsFrontalSvg className={`${style['svg']}`} />
          </div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <ShadowsFrontalPants className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <LabelFrontalPants className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default PantsDeportivoEditable
