import { memo } from 'react'
import styles from './tag.module.scss'
import { Tag } from 'antd'

const STATUS = {
  1: 'Nuevo',
  2: 'En revisión',
  3: 'Con observaciones',
  4: 'Disponible',
  5: 'Autorizado',
  6: 'En Construcción',
  7: 'Solicitud propuesta'
}

enum STATS {
  Nuevo = 1,
  'En revisión' = 2,
  'Con observaciones' = 3,
  Disponible = 4,
  Autorizado = 5,
  'En Construcción' = 6
}

interface TagProps {
  variation: number | string
  title?: string
  click?: any
}

const Tags = memo((props: TagProps) => {
  const { variation } = props
  let VARI = parseInt(variation as string)
  let NUMERIC_VAR = 1
  let TITLE: any = ''
  if (isNaN(VARI)) {
    Object.entries(STATUS).forEach(([key, value]) => {
      if (value === variation) NUMERIC_VAR = parseInt(key)
    })
    TITLE = variation
  } else {
    NUMERIC_VAR = parseInt(variation as string)
    TITLE = STATS[variation as number]
  }

  if (props.title) TITLE = props.title

  return (
    <Tag onClick={props.click} className={styles.tag}>
      <small className={styles.status} data-variation={NUMERIC_VAR}>
        {TITLE}
      </small>
    </Tag>
  )
})

export default Tags
