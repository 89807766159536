import React, { memo, useEffect, useState } from 'react'
import BooksModule from 'modules/UserSchool/Grades/BooksModule/BooksModule'
import NotebookModule from 'modules/UserSchool/Grades/NotebookModule/NotebookModule'
import UniformModule from 'modules/UserSchool/Grades/UniformModule/UniformModule'
import UtilitiesModule from 'modules/UserSchool/Grades/UtilitiesModule/UtilitiesModule'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row, Statistic, Modal, Tabs, Typography } from 'antd'
import type { TabsProps } from 'antd'
import { useQuery } from 'hooks/useQuery'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Button } from 'antd'
import style from './grades.module.scss'
import { cleanSingleUpdatedState } from 'utilities/cleanSingleUpdatedState'
import { Form } from 'antd'
import Input from 'components/inputs/Input'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import BackButton from 'components/buttons/BackButton/BackButton'

const items: TabsProps['items'] = [
  {
    key: 'libros',
    label: `Libros`,
    children: <BooksModule />
  },
  {
    key: 'cuadernos',
    label: `Cuadernos`,
    children: <NotebookModule />
  },
  {
    key: 'uniformes',
    label: `Uniformes`,
    children: <UniformModule />
  },
  {
    key: 'utiles',
    label: `Útiles`,
    children: <UtilitiesModule />
  }
]
const index = memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const query: any = useQuery()
  const [activeTab, setactiveTab] = useState('')

  useEffect(() => {
    if (query) setactiveTab(query?.module)
  }, [query, location.search])

  const onChange = (key: string) => {
    const path = {
      pathname: '.',
      search: `?module=${key}`
    }
    const state = { state: location.state }
    navigate(path, state)
  }
  const goBack = () => {
    navigate('/estructura')
  }
  return (
    <MainTemplate>
      <BackButton onClick={goBack} textDecoration='underline' />
      <br />
      <br />
      <br />
      <TitleHeader
        gradeData={location.state}
        type='Grade'
        IconName='estructure'
        title='Creación de secciones y grados escolares'
      />

      <Tabs activeKey={activeTab} items={items} onChange={onChange} />
    </MainTemplate>
  )
})
index.displayName = 'Grades'
export default index
