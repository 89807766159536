import GreenTagSueterFemale from 'assets/img/uniforms/sueter/female/GreenTagSueterFemale'
import SueterFemaleSvg from 'assets/img/uniforms/sueter/female/SueterFemaleSvg'
import GreenTagSueter from 'assets/img/uniforms/sueter/male/GreenTagSueter'
import SueterSvg from 'assets/img/uniforms/sueter/male/SueterSvg'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

const SueterPics = () => {
  const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
    (state: RootState) => state.uniforms
  )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      {!female ? (
        <Fragment>
          <div
            className={`${
              style[`logo-position-${"left"}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SueterSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagSueter className={`${style['svg']}`} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div
            className={`${
              style[`logo-position-${poloValues.logoPosition.value}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SueterFemaleSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagSueterFemale className={`${style['svg']}`} />
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default SueterPics
