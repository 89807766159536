import React from 'react'

const GreenTagBlusa_3_4 = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
        <g id="Green_Tag" data-name="Green Tag" stroke='black'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <rect   x="380.73" y="75.49" width="30.08" height="21.06"/>
      <g>
        <path   d="m392.21,79.26s.26,0,.72.13c.45.14.97.36,1.36.54.39.19.8.47.93.6.13.13.43.47.45.55.02.07-.01.26-.11.4-.1.14-.36.42-.47.52-.11.09-.21.17-.24.18-.03,0-.05.04-.05.04,0,0-.01.01,0,.03,0,.02.01.06.01.06,0,0-.26.14-.52.26-.26.13-.46.21-.56.25-.1.05-.19.1-.19.1,0,0-.02.06.01.06s.47-.04,1.33-.18c.86-.15,1.38-.26,1.72-.4.34-.14.48-.31.57-.4.08-.09.1-.17.13-.25s.05-.13.05-.13c0,0,.24-.06.4-.14.15-.08.38-.23.45-.28.08-.05.21-.09.27-.11.07-.02.18-.05.18-.05,0,0-.03-.06-.05-.07-.01,0-.1-.1-.1-.16s-.13-.21-.2-.21c-.08,0-.23,0-.31.01-.09.01-.22.03-.27.03-.05,0-.09,0-.09,0,0,0,0-.04.03-.07.02-.03.19-.37.17-.54-.03-.17-.17-.63-.3-.92-.15-.28-.37-.66-.44-.76-.06-.09-.1-.11-.12-.07-.02.03-.02.2,0,.36.02.16.03.27.04.31.01.03,0,.16,0,.18,0,.02-.03.09-.02.1,0,0,.02.04.02.04h-.09s-.02.1-.04.15c-.01.05-.08.22-.16.41-.09.19-.19.37-.24.45-.05.08-.11.14-.14.14-.03,0-.29-.11-.59-.24-.31-.13-.54-.27-.86-.47-.33-.2-.74-.68-.8-.75-.06-.07-.23-.29-.41-.52-.17-.22-.42-.52-.52-.63-.09-.1-.22-.21-.25-.24-.03-.03-.11-.07-.13-.07-.01.01.01.03.03.07.02.04.3.36.45.55.15.19.48.62.56.75.08.13.24.38.29.46.05.09.07.23.07.23,0,0-.06,0-.22-.03-.16-.03-.79-.24-.93-.27-.13-.03-.47-.1-.56-.1s-.14,0-.18,0c-.05,0-.16.02-.1.09Z"/>
        <g>
          <path   d="m387.63,85.5v.1l-.28.07s-.05.02-.06.03c-.02.01-.02.04-.03.06-.01.06-.01.2-.01.42,0,.13,0,.31.01.54-.05.01-.17.03-.35.08-.23.05-.38.08-.44.08-.11.02-.24.02-.38.02-.43,0-.76-.11-.99-.35-.23-.23-.35-.54-.35-.94,0-.43.13-.79.41-1.05.27-.27.63-.4,1.08-.4.12,0,.22,0,.33.02.02,0,.23.04.63.11,0,.07,0,.13,0,.17,0,.16.01.35.03.56h-.14c-.06-.21-.11-.35-.16-.42-.05-.06-.13-.12-.24-.16-.15-.06-.3-.09-.48-.09-.38,0-.66.15-.86.43-.13.2-.2.45-.2.75,0,.38.1.67.31.87.21.2.48.29.8.29.2,0,.42-.04.65-.13,0-.14,0-.33,0-.59,0-.14,0-.23-.01-.25,0-.02-.02-.04-.04-.06-.03-.02-.12-.04-.26-.07l-.07-.02v-.1h.63c.14.01.3,0,.47,0Z"/>
          <path   d="m387.86,84.68v-.1c.3.01.55.02.73.02.1,0,.2,0,.3,0,.12,0,.21,0,.25,0,.23,0,.4.06.51.15.11.1.17.24.17.41,0,.12-.02.21-.08.31-.05.09-.12.16-.22.22-.07.04-.17.08-.3.12l.58.71c.07.09.11.14.13.16.02.02.06.04.11.06.06.02.17.05.33.09v.09c-.18,0-.31-.02-.4-.02-.09,0-.19,0-.29.02-.05-.08-.13-.19-.22-.31-.09-.13-.15-.2-.16-.22-.01,0-.03-.03-.03-.04l-.38-.5c-.1,0-.21,0-.31-.02v.83s.03.06.05.07c.03.02.12.05.26.08.02,0,.05,0,.1.02v.09c-.21,0-.39-.02-.55-.02s-.36,0-.57.02v-.09h.08c.16-.05.26-.08.28-.09.02-.02.03-.05.03-.1,0-.03,0-.31.01-.85v-.77c0-.12-.01-.19-.02-.21,0-.01-.01-.03-.03-.03-.05-.03-.14-.05-.27-.07-.02,0-.05,0-.08-.01Zm.72,1c.12.01.22.02.29.02.2,0,.36-.05.45-.14.1-.09.16-.21.16-.37,0-.13-.05-.24-.13-.32-.09-.08-.22-.12-.39-.12-.06,0-.18,0-.36.02v.91Z"/>
          <path   d="m393.47,85.61h.4c.15,0,.24,0,.27-.01.02,0,.05-.03.06-.05.02-.03.03-.1.06-.22,0-.01.01-.03.02-.06h.11c-.01.18-.02.32-.02.42,0,.1,0,.24.02.41h-.11c-.03-.16-.05-.25-.06-.26-.01-.03-.03-.05-.06-.06-.02-.01-.11-.02-.24-.02h-.45v.97h.75c.1,0,.16,0,.17-.01.05-.01.08-.04.11-.07.03-.04.09-.13.18-.29,0-.01.02-.04.04-.07h.12s-.08.24-.2.62c-.4,0-.76-.02-1.08-.02-.23,0-.48,0-.76.02v-.09l.26-.06s.07-.02.08-.04c.02-.02.02-.07.02-.13v-.42c.01-.07.01-.22.01-.43,0-.31,0-.55,0-.69,0-.15,0-.23-.02-.25,0-.01-.01-.02-.03-.03-.02,0-.06-.02-.15-.04l-.17-.04v-.1c.2.01.45.02.73.02.15,0,.52,0,1.11-.02,0,.19.02.38.03.56h-.11c-.04-.15-.08-.25-.1-.3-.02-.03-.04-.06-.07-.07-.03-.01-.12-.02-.24-.02h-.66v.53s-.02.32-.02.32Z"/>
          <path   d="m391.17,85.61h.4c.15,0,.24,0,.26-.01.03,0,.05-.03.07-.05.02-.03.04-.1.06-.22,0-.01,0-.03.02-.06h.1c-.02.18-.02.32-.02.42,0,.1,0,.24.02.41h-.1c-.03-.16-.05-.25-.06-.26,0-.03-.03-.05-.06-.06-.03-.01-.1-.02-.24-.02h-.45v.97h.75c.09,0,.16,0,.18-.01.04-.01.07-.04.1-.07.03-.04.09-.13.17-.29,0-.01.02-.04.03-.07h.12s-.08.24-.2.62c-.39,0-.75-.02-1.08-.02-.23,0-.48,0-.76.02v-.09l.26-.06s.07-.02.08-.04c.02-.02.03-.07.03-.13v-.42c0-.07.01-.22.01-.43,0-.31,0-.55-.01-.69,0-.15,0-.23-.01-.25-.01-.01-.02-.02-.03-.03,0,0-.06-.02-.15-.04l-.17-.04v-.1c.2.01.45.02.73.02.16,0,.52,0,1.11-.02,0,.19.02.38.05.56h-.12c-.05-.15-.09-.25-.1-.3-.02-.03-.05-.06-.07-.07-.04-.01-.12-.02-.24-.02h-.66v.53s-.01.32-.01.32Z"/>
          <path   d="m395.1,84.64v-.1c.14.01.25.02.33.02.06,0,.17,0,.34-.02l.47.58c.04.05.37.45.98,1.19,0-.15,0-.26,0-.33,0-.16,0-.46-.02-.89,0-.2,0-.31-.01-.33,0-.02-.02-.04-.04-.05-.02,0-.09-.03-.23-.06-.02,0-.04-.01-.08-.01v-.1c.2.01.35.02.47.02s.26,0,.45-.02v.1s-.05,0-.06.01c-.14.03-.23.05-.24.06-.01.01-.02.01-.03.03,0,.02-.02.05-.02.1,0,.02,0,.17,0,.45,0,.28,0,.54,0,.8,0,.31,0,.56.02.77l-.11.05-.68-.85-.89-1.08v.97c.01.42.02.65.02.68,0,.01.02.03.03.05.03.02.1.04.24.07.02,0,.04,0,.08.01v.1c-.16-.01-.31-.02-.42-.02-.1,0-.27,0-.5.02v-.1s.06-.01.08-.02c.13-.02.21-.04.23-.06.01-.01.02-.03.02-.05.01-.05.02-.24.03-.57,0-.47,0-.74,0-.83,0-.27,0-.42-.01-.46,0-.02-.02-.04-.05-.05-.02-.02-.12-.04-.3-.07-.02,0-.05-.01-.09-.01Z"/>
          <path   d="m399.01,85.01h-.14c.02-.22.05-.45.06-.72.55.01.93.02,1.16.02.19,0,.55,0,1.06-.02.01.27.03.51.05.72h-.14c-.06-.23-.1-.36-.11-.4-.02-.04-.04-.07-.08-.08-.04-.02-.11-.02-.25-.02h-.41c-.01.32-.01.72-.01,1.21,0,.45,0,.75.01.91,0,.03.01.06.03.07.02.02.12.05.3.08.02,0,.05,0,.08.02v.11c-.24,0-.46-.02-.65-.02s-.37.01-.56.02v-.11s.08-.02.1-.02c.17-.03.26-.06.29-.08,0,0,.02-.02.02-.04,0-.02.01-.15.02-.41,0-.39,0-.68,0-.87,0-.34,0-.63,0-.87h-.52c-.07,0-.12,0-.14.02-.03.02-.07.07-.1.13-.02.05-.05.17-.09.35Z"/>
          <path   d="m402.15,84.58h.1c.15.37.32.8.53,1.27l.37.82s.03.05.05.07c.02,0,.1.03.24.08v.09c-.2,0-.34-.02-.42-.02-.1,0-.26,0-.48.02v-.09c.13-.03.2-.05.22-.07.03-.01.03-.03.03-.06,0-.02,0-.06-.03-.11l-.16-.4c-.23,0-.39,0-.49,0h-.43s-.17.41-.17.41c-.03.07-.04.11-.04.13,0,.01,0,.02.02.03.01,0,.08.03.18.05.01,0,.03,0,.06.01v.09c-.19,0-.32-.02-.39-.02-.09,0-.22,0-.36.02v-.09c.09-.02.16-.05.2-.08.03-.02.05-.06.08-.1.06-.11.22-.45.46-1.03l.44-1.03Zm-.42,1.44c.15,0,.26,0,.33,0,.02,0,.17,0,.48,0l-.4-.97-.41.97Z"/>
          <path   d="m405.94,85.66v.09l-.24.06s-.05.01-.06.03-.02.03-.03.06c0,.05,0,.17,0,.37,0,.12,0,.28,0,.47-.04,0-.15.03-.3.06-.2.05-.33.08-.38.08-.1.01-.22.02-.33.02-.38,0-.67-.1-.87-.31-.2-.2-.31-.48-.31-.83,0-.38.12-.69.36-.93.24-.24.56-.36.95-.36.1,0,.19,0,.29.02.02,0,.2.03.55.1v.15c0,.15,0,.31.02.49h-.12c-.05-.18-.1-.31-.14-.36-.05-.06-.12-.11-.22-.14-.13-.05-.27-.08-.41-.08-.34,0-.59.13-.75.38-.12.18-.18.4-.18.66,0,.34.09.59.28.76.18.18.42.26.7.26.17,0,.37-.04.56-.12,0-.12,0-.29,0-.52,0-.12,0-.2-.01-.22,0-.02-.02-.03-.03-.05-.03-.01-.1-.03-.22-.06h-.06v-.1h.55c.13.01.26,0,.42,0Z"/>
        </g>
      </g>
      <g>
        <path   d="m391.53,91.92v-.15c0-.1-.01-.12-.09-.12v-.03h.28v.03c-.08,0-.09.02-.09.12v.35c0,.1.01.13.09.13v.03h-.28v-.03c.08,0,.09-.03.09-.13v-.16h-.3v.16c0,.1.01.13.09.13v.03h-.27v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.01-.12-.09-.12v-.03h.27v.03c-.08,0-.09.02-.09.12v.15h.3Z"/>
        <path   d="m392.23,92.07h-.03c0-.12-.09-.12-.16-.12h-.04v.21c0,.07,0,.09.09.09.16,0,.19-.04.29-.15h.02s-.08.18-.08.18h-.51v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.01-.12-.09-.12v-.03h.51l.06.15-.02.02c-.09-.1-.12-.14-.26-.14-.1,0-.1.01-.1.1v.17h.04c.09,0,.16-.02.16-.11h.03v.26Z"/>
        <path   d="m393.06,91.78h-.02c-.05-.06-.14-.14-.23-.14-.16,0-.2.16-.2.28,0,.13.07.31.23.31.1,0,.17-.07.22-.15h.02c-.06.12-.15.2-.29.2-.19,0-.3-.15-.3-.31,0-.08.03-.17.08-.23.05-.07.13-.13.23-.13.03,0,.06,0,.08.01.02,0,.04.01.06.01.02,0,.05,0,.06-.02l.06.16Z"/>
        <path   d="m393.64,91.92v-.15c0-.1-.01-.12-.09-.12v-.03h.28v.03c-.08,0-.09.02-.09.12v.35c0,.1.01.13.09.13v.03h-.28v-.03c.08,0,.09-.03.09-.13v-.16h-.3v.16c0,.1.01.13.09.13v.03h-.27v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.02-.12-.09-.12v-.03h.27v.03c-.08,0-.09.02-.09.12v.15h.3Z"/>
        <path   d="m394.25,92.29c-.2,0-.31-.17-.31-.34s.11-.34.31-.34.31.17.31.34-.11.34-.31.34Zm-.2-.35c0,.11.04.31.2.31.17,0,.21-.19.21-.31s-.04-.31-.2-.31-.2.19-.2.31Z"/>
        <path   d="m395.36,92.07h-.04c0-.12-.08-.12-.16-.12h-.04v.21c0,.07.01.09.1.09.15,0,.18-.04.28-.15h.02s-.07.18-.07.18h-.52v-.03c.08,0,.09-.03.09-.13v-.35c0-.1,0-.12-.09-.12v-.03h.51l.07.15-.02.02c-.09-.1-.12-.14-.26-.14-.09,0-.1.01-.1.1v.17h.04c.09,0,.15-.02.16-.11h.04v.26Z"/>
        <path   d="m396.15,92.11v-.34c0-.1-.01-.12-.09-.12v-.03h.22v.03c-.08,0-.09.02-.09.12v.51h-.03l-.42-.55v.4c0,.1.01.13.09.13v.03h-.22v-.03c.07,0,.09-.03.09-.13v-.35c0-.1-.02-.12-.09-.12v-.03h.16l.37.49Z"/>
        <path   d="m397.38,92.13c0,.1.02.13.09.13v.03h-.28v-.03c.08,0,.09-.03.09-.13v-.43h0l-.24.59h-.04l-.23-.59h0v.43c0,.1.01.13.09.13v.03h-.23v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.02-.12-.09-.12v-.03h.21l.21.52.22-.52h.2v.03c-.08,0-.09.02-.09.12v.35Z"/>
        <path   d="m397.99,92.07h-.03c0-.12-.08-.12-.16-.12h-.04v.21c0,.07.01.09.1.09.16,0,.19-.04.28-.15h.02s-.07.18-.07.18h-.52v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.01-.12-.09-.12v-.03h.5l.08.15-.03.02c-.08-.1-.12-.14-.26-.14-.1,0-.1.01-.1.1v.17h.04c.09,0,.15-.02.16-.11h.03v.26Z"/>
        <path   d="m398.69,92.15s.06.1.14.1v.03h-.29v-.03s.08,0,.08-.03c0-.02-.03-.06-.04-.07l-.1-.15-.06.09s-.06.09-.06.12c0,.03.05.04.07.04v.03h-.24v-.03c.09,0,.11-.03.15-.1l.13-.19-.13-.2c-.03-.06-.06-.11-.15-.11v-.03h.29v.03s-.06,0-.06.02.02.06.03.08l.08.12.08-.12s.05-.06.05-.08c0-.04-.05-.03-.07-.04v-.03h.24v.03c-.09,0-.12.04-.15.1l-.12.16.15.24Z"/>
        <path   d="m399.05,92.13c0,.1.01.13.09.13v.03h-.27v-.03c.08,0,.09-.03.09-.13v-.35c0-.1-.01-.12-.09-.12v-.03h.27v.03c-.08,0-.09.02-.09.12v.35Z"/>
        <path   d="m399.81,91.78h-.02c-.05-.06-.15-.14-.24-.14-.16,0-.2.16-.2.28,0,.13.07.31.23.31.1,0,.17-.07.22-.15h.02c-.05.12-.15.2-.27.2-.2,0-.31-.15-.31-.31,0-.08.03-.17.08-.23.05-.07.13-.13.23-.13.03,0,.06,0,.08.01.02,0,.04.01.06.01.02,0,.05,0,.06-.02l.06.16Z"/>
        <path   d="m400.24,92.29c-.2,0-.31-.17-.31-.34s.11-.34.31-.34.31.17.31.34-.12.34-.31.34Zm-.2-.35c0,.11.05.31.2.31s.2-.19.2-.31-.04-.31-.2-.31-.2.19-.2.31Z"/>
      </g>
      <path   d="m396.02,90.75v-.08c.07-.16.19-.29.31-.42.09-.1.19-.22.19-.35,0-.12-.07-.22-.19-.22-.07,0-.15.05-.15.13,0,.05.04.08.04.13,0,.04-.04.08-.08.08-.06,0-.09-.06-.09-.11,0-.16.16-.27.31-.27.16,0,.32.11.32.28,0,.16-.12.27-.23.36-.17.13-.2.17-.32.36h.15c.09,0,.18,0,.23-.02.05-.02.09-.07.12-.16h.05s-.07.31-.07.31h-.58Z"/>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagBlusa_3_4
