import { memo,KeyboardEvent } from 'react'
import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'


export interface TextAreaProps {
  name: string
  type?: string
  placeholder?: string
  rows: number
  label?: string
  maxLength?:number
  rules?: Array<any>
  onKeyUp?:(e:KeyboardEvent<HTMLTextAreaElement>) => void
}

const FormTextArea = memo((props: TextAreaProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      
    >
      <TextArea placeholder={props.placeholder} onKeyUp={props.onKeyUp} rows={props.rows} maxLength={props.maxLength} />
    </Form.Item>
  )
})

export default FormTextArea
