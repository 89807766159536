import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Options } from 'types'
import * as services from 'services/books/books'
import * as servicesSchool from 'services/schoolAdminServices/schools'
import { UtilsInterface } from 'services/utilsServices/utils'
import {
  InitUtilsPagination,
  UtilsPagination
} from 'utilities/restructurePagination'
import { ColumnsType } from 'antd/es/table'
import Tag from 'components/tag'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { useDispatch } from 'react-redux'
import { AUTHSERVICE } from 'services/config'
import { useForm } from 'antd/es/form/Form'
import { getNotebooksPaginated } from 'services/notebooks/getListOfNotebooks'
import {
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { getLogs } from 'services/genericServices/genericServices'
import { INotebook } from 'services/notebooks/getNotebookByIdUuid'
import { handleOptionsOutside } from 'redux/reducers/notebooksSlice'

interface level {
  id: number
  name: string
  grades: [
    {
      id: number
      name: string
    }
  ]
}

const statusMap: any = {
  Nuevo: 1,
  'En revisión': 2,
  'Con observaciones': 3,
  Disponible: 4,
  Autorizado: 5,
  Borrador: 6,
  'Solicitud propuesta': 7,
  'Propuesta enviada': 10
}

const statusNumberMap: any = {
  1: 'Nuevo',
  2: 'En revisión',
  3: 'Con observaciones',
  4: 'Disponible',
  5: 'Autorizado',
  6: 'Borrador',
  7: 'Solicitud propuesta',
  8: 'Diseño de propuesta',
  9: 'Diseño propuesto',
  10: 'Propuesta enviada',
  11: 'Revisión de propuesta',
  12: 'Propuesta enviada'
}

export function useManagementNotebooks() {
  const [utilsList, setUtilsList] = useState<UtilsInterface[]>([])
  const [LoadingList, setLoadingList] = useState(true)
  const [pagination, setPagination] =
    useState<UtilsPagination>(InitUtilsPagination)
  const [schoolsCatalog, setSchoolCatalog] = useState<Options[]>([])
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
  const [selectSchool, setSelectSchool] = useState<string>('')
  const [selectStatus, setSelectStatus] = useState<string>('')
  const [selectedLevel, setSelectedLevel] = useState<level>()
  const [tabs, setTabs] = useState([])
  const [levels, setLevels] = useState<any>([])
  const [params, setParams] = useSearchParams()
  const [packages, setPackages] = useState<Array<any>>([])
  const [selectedPackage, setSelectedPackage] = useState<number>(-1)
  const [supplies, setSupplies] = useState<Array<any>>([])
  const [loadingSupplies, setLoadingSupplies] = useState(false)
  const [sidebarFormFilled, setSidebarFormFilled] = useState(false)
  const [packagesMap, setPackagesMap] = useState<any>({})
  const [paginationMap, setPaginationMap] = useState<any>({})
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
  const [itemToDelete, setItemToDelete] = useState<any | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deletingItem, setDeleteingItem] = useState<boolean>(false)
  const [showRequestChangesModal, setShowRequestChangesModal] =
    useState<boolean>(false)
  const [requestingChanges, setRequestingChanges] = useState<boolean>(false)
  const [showUpdatePriceModal, setShowUpdatePriceModal] =
    useState<boolean>(false)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = useForm()

  const donwloadLogs = async () => {
    setLoadingDownload(true)
    try {
      const res: any = await getLogs('notebook')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')
    }
    setLoadingDownload(false)
  }

  const utilsColumns: ColumnsType<UtilsInterface> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Materia',
      dataIndex: 'matter',
      key: 'matter'
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Inventariable',
      dataIndex: 'inventariable',
      key: 'inventariable',
      render: (_, record: any) => (
        <>
          {(() => {
            let status = record.inventariable

            return <p style={{ margin: 0 }}>{status ? 'Si' : 'No'}</p>
          })()}
        </>
      )
    },
    {
      title: 'Indicador de impuestos',
      dataIndex: 'tax',
      key: 'tax'
    },
    {
      title: 'Precio de lista',
      dataIndex: 'price_list',
      key: 'price_list'
    },

    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status',
      render: (_, record: any) => (
        <>
          {(() => {
            let status = record?.status

            const message = (num: number) => {
              if (num === 3 && record.last_comment !== undefined) {
                return statusNumberMap[status] + ` de ${record.last_comment}`
              }
              return statusNumberMap[status]
            }
            return <Tag variation={status} title={message(status) as string} />
          })()}
        </>
      )
    },
    {
      ...(uid.role == 'Ezetera' && {
        title: '',
        key: 'actions',
        render: (_: any, record: any) => (
          <>
            <ClipboardDocumentListIcon
              onClick={(e) => {
                e.stopPropagation()
                //console.log(record)
                dispatch(
                  handleOptionsOutside({
                    id: record.id,
                    id_package: record.id_package,
                    category: record.category,
                    inventariable:
                      record.inventariable !== null
                        ? record.inventariable
                        : false,
                    tax: record.tax,
                    price_list: record.price_list,
                    showModal: true
                  })
                )
              }}
              style={{
                color: 'green',
                cursor: 'pointer',
                width: '1rem',
                height: '1rem'
              }}
            />
          </>
        )
      })
    }
  ]

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      SchoolCatalog()
      StatusCatalog()

      if (!params.has('school')) {
        getUtilsList()
      }
    }
  }, [])

  useEffect(() => {
    if (params.has('action')) return

    const page = parseInt(params.get('page') || '1')
    const search = params.get('search') || ''
    const status = params.get('status')
    const controller = new AbortController()

    if (status) {
      setSelectStatus(status)
    } else {
      setSelectStatus('todos')
    }

    if (params.has('school')) {
      getSchoolData()
      if (schoolsCatalog && schoolsCatalog?.length > 0) {
        const school = schoolsCatalog.find(
          (it: any) => it.value == params.get('school')
        )
        if (school) {
          setSelectSchool(school?.value as string)
        }
      }
    } else {
      setSelectSchool('todos')
      getUtilsList(
        page,
        controller,
        search,
        status ? parseInt(status) : undefined
      )
    }

    return () => {
      controller.abort()
    }
  }, [params])

  useEffect(() => {
    getLevelPackages()
  }, [selectedLevel])

  useEffect(() => {
    setOpenDeleteModal(itemToDelete ? true : false)
  }, [itemToDelete])

  const getSchoolData = async () => {
    try {
      // get levels
      let res = await AUTHSERVICE().get(
        `/level?idSchool=${params.get('school')}`
      )
      const { levels } = res?.data
      setLevels(levels)
      // set tab items
      const tabItems = levels.map((it: any) => ({
        label: it?.name,
        key: it?.id
      }))
      setTabs(tabItems)
      setSelectedLevel(levels[0])
    } catch (error) {}
  }

  const getLevelPackages = async () => {
    try {
      const res = await AUTHSERVICE().get(
        `/notebook-package/level/${selectedLevel?.id}?idSchool=${params.get(
          'school'
        )}`
      )
      const { details } = res?.data
      setPackages(details)
    } catch (error) {}
  }

  const getUtilsList = async (
    page: number = 1,
    controller?: AbortController,
    search = params.get('search') || undefined,
    status?: number
  ) => {
    setLoadingList(true)

    try {
      const result: any = await getNotebooksPaginated(page || 1, search, status)
      setUtilsList(result?.data?.data?.notebook)
      const { total, page: currentPage, totalPages, limit } = result?.data?.data
      setPagination({
        page: currentPage,
        total,
        pages: totalPages,
        limit
      })
    } catch (err: any) {}

    setLoadingList(false)
  }

  const onHandleChangeSchool = (value: string) => {
    if (value == 'todos') {
      setParams({})
      setSelectSchool('todos')
    } else {
      setParams({ school: value })
      setSelectSchool(value)
    }
  }

  const onHandleChangeStatus = (value: string) => {
    const paramsHasSchool = params.has('school')
    if (paramsHasSchool) {
      setSelectStatus(value)
    } else {
      const paramsObject: any = {}

      for (let key of params.keys()) {
        paramsObject[key] = params.get(key)
      }

      if (value == 'todos') {
        delete paramsObject['status']
        setSelectStatus('todos')
      } else {
        paramsObject['status'] = value
        if (params.has('page')) paramsObject['page'] = 1
        setSelectStatus(value)
      }

      setParams(paramsObject)
    }
  }

  const SchoolCatalog = async (): Promise<any> => {
    try {
      const res = await servicesSchool.getCatalogSchools()
      setSchoolCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.schools
      ])
      if (params.has('school')) {
        const school = res?.data?.schools?.find(
          (it: any) => it.value == params.get('school')
        )
        if (school) {
          setSelectSchool(school?.label)
        }
      } else {
        setSelectSchool('todos')
      }
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const StatusCatalog = async (): Promise<any> => {
    try {
      const res = await services.getCatalogStatus()
      //console.log(res?.data?.status)
      const allBookStatus = [
        { label: 'Nuevo', value: 1 },
        { label: 'En revisión', value: 2 },
        { label: 'Con observaciones', value: 3 },
        { label: 'Disponible', value: 4 },
        { label: 'Autorizado', value: 5 },
        { label: 'Borrador', value: 6 },
        { label: 'Solicitud propuesta', value: 7 },
        { label: 'Diseño de propuesta', value: 8 },
        { label: 'Diseño propuesto', value: 9 },
        { label: 'Propuesta enviada', value: 10 },
        { label: 'Revisión de propuesta', value: 11 },
        { label: 'Propuesta enviada', value: 12 }
      ]
      setStatusCatalog([{ label: 'Todos', value: 'todos' }, ...allBookStatus])

      if (params.has('status')) {
        setSelectStatus(statusMap(params.get('status')))
      } else {
        setSelectStatus('todos')
      }
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const onHandleChangePage = (page: number): void => {
    const prev: any = {}
    for (let key of params.keys()) prev[key] = params.get(key)
    setParams({
      ...prev,
      page
    })
  }

  const onRowClicked = (record: any) => {
    // if the package is open, it must go to the preview page
    if (record?.pkg_open === true) {
      navigate(`/cuadernos/preview/${record?.id}?pkg_open=true`)
    } else {
      navigate(`/cuadernos/preview/${record?.id}?pkg_open=false`)
    }
  }

  const onChangeSelectedTab = (value: any) => {
    const nLevel = levels.find((it: any) => it.id == value)
    setSelectedLevel(nLevel)
  }

  const handleCommentsSubmit = async (
    values: any,
    id: number,
    bookId: number,
    id_supply_school?: number,
    elseData?: any
  ) => {
    try {
      await AUTHSERVICE().put(`/notebook`, { id, ...values })
      SuccessToast('La información del cuaderno se actualizó correctamente')
      // reload the data
      if (params.has('school')) {
        updateSupplies(selectedPackage)
      } else {
        const page = parseInt(params.get('page') || '1')
        const search = params.get('search') || ''
        const status = params.get('status')
        const controller = new AbortController()
        getUtilsList(
          page,
          controller,
          search,
          status ? parseInt(status) : undefined
        )
      }
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtalo más tarde.')
    }
  }

  const onCollapseChange = async (flag: boolean, packageId: number) => {
    if (!flag) {
      setSelectedPackage(-1)
      return
    }

    if (!packagesMap[packageId]) {
      setLoadingSupplies(true)

      try {
        setSelectedPackage(packageId)
        const res = await AUTHSERVICE().get(
          `/notebook/package/${packageId}?limit=1000`
        )
        const { success, notebookPackage: details, ...pagData } = res?.data

        if (!details) throw Error('No details in response data')
        const pkg = packages?.find((it: any) => it?.id == packageId)
        const mappedDetails = details?.map((it: any) => ({
          ...it,
          pkg_open: pkg?.open as boolean
        }))

        setSupplies(mappedDetails)
        setPackagesMap((prev: any) => ({
          ...prev,
          [packageId]: mappedDetails
        }))

        setPaginationMap((prev: any) => ({
          ...prev,
          [packageId]: {
            ...pagData
          }
        }))
      } catch (error) {
        ErrorToast('Ocurrió un error. Inténtelo más tarde.')
      }

      setLoadingSupplies(false)
    }
  }
  const onCollapseRecharge = async (packageId: number) => {
    setLoadingSupplies(true)

    try {
      setSelectedPackage(packageId)
      const res = await AUTHSERVICE().get(
        `/notebook/package/${packageId}?limit=1000`
      )
      const { success, notebookPackage: details, ...pagData } = res?.data

      if (!details) throw Error('No details in response data')
      const pkg = packages?.find((it: any) => it?.id == packageId)
      const mappedDetails = details?.map((it: any) => ({
        ...it,
        pkg_open: pkg?.open as boolean
      }))

      setSupplies(mappedDetails)
      setPackagesMap((prev: any) => ({
        ...prev,
        [packageId]: mappedDetails
      }))

      setPaginationMap((prev: any) => ({
        ...prev,
        [packageId]: {
          ...pagData
        }
      }))
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtelo más tarde.')
    }

    setLoadingSupplies(false)
  }

  const updateSupplies = async (packageId: number) => {
    setLoadingSupplies(true)

    try {
      const res = await AUTHSERVICE().get(
        `/notebook/package/${packageId}?limit=1000`
      )
      const { success, notebookPackage: details, ...pagData } = res?.data

      if (!details) throw Error('No details in response data')
      const pkg = packages?.find((it: any) => it?.id == packageId)
      const mappedDetails = details?.map((it: any) => ({
        ...it,
        pkg_open: pkg?.open as boolean
      }))

      setSupplies(mappedDetails)
      setPackagesMap((prev: any) => ({
        ...prev,
        [packageId]: mappedDetails
      }))

      setPaginationMap((prev: any) => ({
        ...prev,
        [packageId]: {
          ...pagData
        }
      }))
    } catch (error) {}

    setLoadingSupplies(false)
  }

  const downloadClicked = async () => {
    setLoadingDownload(true)

    try {
      let url = '/notebook/download'
      if (selectSchool && selectSchool != 'todos') {
        url += `?idSchool=${selectSchool}`
      }
      const res: any = await AUTHSERVICE().get(url)
      const { url: fileUrl } = res?.data

      if (fileUrl) {
        window.open(fileUrl, '__blank')
      }
    } catch (error) {}

    setLoadingDownload(false)
  }

  const handleDeleteItem = async () => {
    setDeleteingItem(true)

    try {
      await AUTHSERVICE().delete(`/notebook/${itemToDelete?.id}`)
      setItemToDelete(null)
      setOpenDeleteModal(false)

      const page = parseInt(params.get('page') || '1')
      const search = params.get('search') || ''
      const status = params.get('status')
      const controller = new AbortController()
      getUtilsList(
        page,
        controller,
        search,
        status ? parseInt(status) : undefined
      )
    } catch (error) {
      ErrorToast('Ocurrió un error, por favor inténtelo de nuevo más tarde.')
    }

    setDeleteingItem(false)
  }

  const updateRequestChanges = async (data: any) => {
    if (!selectedPackage || selectedPackage < 0) {
      ErrorToast('Ocurrió un error. Inténtelo de nuevo más tarde.')
      return
    }

    setRequestingChanges(true)

    const mode = data?.open ? 'accepted' : 'denied'

    try {
      await AUTHSERVICE().put('/notebook-package', {
        id: selectedPackage,
        ...data,
        mode
      })

      // update the package view
      setPackages((prev: Array<any>) => {
        const pkg = prev?.find((it: any) => it?.id == selectedPackage)
        if (pkg) {
          pkg.request_changes = data.request_changes
          pkg.open = data.open
        }
        return prev
      })

      setShowRequestChangesModal(false)
      SuccessToast('El paquete se actualizó correctamente')
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtelo de nuevo más tarde.')
    }

    setRequestingChanges(false)
  }

  const handleNotebooksPriceUpdate = async (values: any, fn?: () => void) => {
    setDeleteingItem(true)

    try {
      await AUTHSERVICE().put('/notebook/price', {
        price_notebook: values
      })
      SuccessToast('El precio se actualizó correctamente')
      setShowUpdatePriceModal(false)
      fn && fn()
    } catch (error) {
      ErrorToast('Ocurrió un error, inténtelo de nuevo')
    }

    setDeleteingItem(false)
  }

  return {
    params,
    schoolsCatalog,
    statusCatalog,
    selectSchool,
    selectStatus,
    utilsList,
    LoadingList,
    pagination,
    utilsColumns,
    tabs,
    selectedLevel,
    packages,
    form,
    supplies,
    loadingSupplies,
    sidebarFormFilled,
    statusMap,
    packagesMap,
    selectedPackage,
    paginationMap,
    loadingDownload,
    openDeleteModal,
    deletingItem,
    statusNumberMap,
    showRequestChangesModal,
    requestingChanges,
    showUpdatePriceModal,
    navigate,
    setParams,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onRowClicked,
    onChangeSelectedTab,
    handleCommentsSubmit,
    onCollapseChange,
    setSidebarFormFilled,
    updateSupplies,
    getUtilsList,
    setSelectedPackage,
    setPaginationMap,
    downloadClicked,
    setOpenDeleteModal,
    setItemToDelete,
    handleDeleteItem,
    setShowRequestChangesModal,
    updateRequestChanges,
    setShowUpdatePriceModal,
    handleNotebooksPriceUpdate,
    donwloadLogs,
    levels,
    onCollapseRecharge
  }
}
