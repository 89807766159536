import React, { memo } from 'react'
import { INPUTS_INITIAL_STATE_PACKAGES } from 'redux/reducers/packages'
import { usePackages } from 'hooks/utils/usePackages'
import { Row } from 'antd'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { ListOfLists } from 'components/ListOfList/ListOfLists'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import EndListModal from 'components/EndListModal/EndListModal'

const UserPackageList = memo(() => {
  const {
    loadingModal,
    tabs,
    selectedLevel,
    selectedTab,
    downloadList,
    handleChangeLevelTab,
    postNewPackage,
    deletePackageById,
    updatePackageById,
    handleGetPackageListByLevel,
    requestChanges,
    handleDownloadUtilsList
  } = usePackages()
  Object.freeze(INPUTS_INITIAL_STATE_PACKAGES)

  return (
    <MainTemplate>
      <button
        style={{ display: 'none' }}
        id='get-packages-again'
        onClick={() => handleGetPackageListByLevel()}
      >
        get packages
      </button>
      <Row align='middle' gutter={[0, 16]}>
        <TitleHeader
          IconName='utilsIcon'
          title='Paquetes'
          buttonProps={[
            {
              isLoading: downloadList,
              label: 'Descargar útiles',
              onClick: () => handleDownloadUtilsList(),
              background: 'white',
              rounded: true,
              size: 'm',
              sector: 'tertiary'
            }
          ]}
        />
        <CommentsComponent />
        <ListOfLists
          requestChanges={requestChanges}
          tabs={tabs}
          selectedTab={selectedTab}
          selectedLevel={selectedLevel}
          handleChangeLevelTab={handleChangeLevelTab}
          loadingModal={loadingModal}
          postNewPackage={postNewPackage}
          deletePackageById={deletePackageById}
          updatePackageById={updatePackageById}
        />
      </Row>
      <EndListModal />
    </MainTemplate>
  )
})
UserPackageList.displayName = 'UserPackageList'
export default UserPackageList
