import React from 'react'

const ShadowsPantsLateral= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
stroke='black'
fill='none'
viewBox='0 0 800 800'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m348.77,483.59s-3.05-44.6-.15-59.22c1.24-6.28,11.56-20.53,13.65-24.32,2.1-3.78,11.59-11.32,11.59-11.32,0,0-20.86,6.75-25.89,11.83-4.31,4.34-7.75,17.06-8.34,23.24-1.43,15.02,9.14,59.79,9.14,59.79Z"/>
      <polygon   points="383.97 585.86 409.47 569.08 436.72 536.21 417.4 574.89 383.97 585.86"/>
      <path   d="m381.74,741.75c5.94,4.56,25.66,10.33,27.14,4.16.56-2.33-5.65.97-6.11-1.62-2.4-13.25-9.9-.71-14.11-3.91-6.52-4.97-24.65-35.59-24.65-35.59,0,0,9.4,30.56,17.74,36.95Z"/>
      <path   d="m315.49,89.85l61.25-22.4,5.37-4.96s-29.17,8.2-31.79,8.35c-2.62.15-34.82,19.01-34.82,19.01Z"/>
      <path   d="m457.97,52.77l-51.95,3.65-5.57-1.86s23.9-3.54,25.85-4.29c1.95-.75,31.68,2.5,31.68,2.5Z"/>
      <polygon   points="437.81 345.81 438.13 315.28 445.6 258.71 447.37 311.95 437.81 345.81"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsPantsLateral