import { Button, Form } from 'antd'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import Input from 'components/inputs/Input'
import { object } from 'prop-types'
import React, { memo, useState } from 'react'
import { cleanSingleUpdatedState } from 'utilities/cleanSingleUpdatedState'

const ComponentsTest = memo(() => {
  const [openComments, setopenComments] = useState(false)
  const openDrawComments = () => {
    setopenComments(true)
  }

  const closeDrawComments = () => {
    setopenComments(false)
  }
  const INPUTS_INITIAL_STATE = {
    email: {
      label: 'Email',
      name: 'email',
      placeholder: 'example@gmail.com',
      value: '',
      type: 'email',
      required: true,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: ''
    },
    password: {
      label: 'Contraseña',
      name: 'password',
      placeholder: '************',
      value: '',
      type: 'password',
      required: true,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: '' // puede ser 'warning' o 'error' segun antd
    }
  }
  const [testValues, settestValues] = useState(INPUTS_INITIAL_STATE)

  const checkDisabled = (): boolean => {
    let disabled = false
    Object.keys(testValues).forEach((res: any) => {
      const objKey = res as keyof typeof INPUTS_INITIAL_STATE
      const objInput = testValues[objKey]

      if (objInput.value === '') disabled = true
    })
    return disabled
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settestValues((prev) => cleanSingleUpdatedState(e, prev))
  }

  const onSubmit = () => {
    console.log('values here', testValues)
  }

  return (
    <div>
      <Form onFinish={onSubmit} layout='vertical' autoComplete='off'>
        {Object.keys(testValues).map((res: any, key) => {
          const objKey = res as keyof typeof INPUTS_INITIAL_STATE
          const objInput = testValues[objKey]
          return (
            <Input
              key={key}
              name={objInput.name}
              placeholder={objInput.placeholder}
              label={objInput.label}
              type={objInput.type}
              value={objInput.value}
              onChange={handleChange}
            />
          )
        })}

        <Form.Item>
          <Button disabled={checkDisabled()} type='primary' htmlType='submit'>
            login
          </Button>
        </Form.Item>
      </Form>

      <Form autoComplete='off'>
        <Input
          name='prueba_input2'
          placeholder='placeholder aca2'
          label='Esto es un label2'
          value=''
        />
      </Form>

      <button onClick={openDrawComments}>comentarios</button>
      <CommentsComponent open={openComments} onClose={closeDrawComments} />
    </div>
  )
})
ComponentsTest.displayName = 'ComponentsTest'
export default ComponentsTest
