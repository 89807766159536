import React, { memo, useEffect, useState } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
// import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import globalStyle from 'styles/global.module.scss'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import BackButton from 'components/buttons/BackButton/BackButton'
import FormBooksModule from 'modules/books/form/FormBooksModule'
import ListBooksModule from 'modules/books/list/ListBooksModule'
import SearchBarModule from 'modules/books/search/SearchBarModule'
// import styles from './books.module.scss'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import {
  BOOKS_TITLE_SEARCH,
  INITIAL_PAGE,
  setStatus,
  useBooks
} from 'hooks/books/useBooks'
import Modals from 'components/modal'
import MassiveModule from 'modules/books/massive/MassiveModule'
import Tag from 'components/tag'
import Button, { ButtonInterface } from 'components/buttons/Button/Button'
import {
  Col,
  Collapse,
  Input,
  List,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd'
import ActionsBookModule from './actions/ActionsBookModule'
import styles from './books.module.scss'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import InfiniteScrolls from './search/InfiniteScroll'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import B from 'components/texts/B/B'
// import FixedFooter from 'components/fixedFooter'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import FormCommentsModule from './formComments/FormCommentsModule'
import { setDataCollege, setIDGrade, setIsBookAuth } from 'redux/reducers/books'
import AuthorizeModule from './authorize/AuthorizeModule'
import ErrorAlert from 'assets/img/errorAlert.svg'
import TabsAdminModule from './tabsAdmin/TabsAdminModule'
import ErrorMassiveModule from './massive/ErrorMassiveModule'
import { Options } from 'types'
import EndListModal from 'components/EndListModal/EndListModal'

const BooksModule = memo(() => {
  const {
    actions,
    idSchool,
    idLevel,
    idGrade,
    loading,
    modalMassive,
    modalSearch,
    modalDelete,
    modalAuthorize,
    modalEndList,
    modalChanges,
    modalErrorsMassive,
    action,
    params,
    form,
    bookColumns,
    books,
    booksGral,
    totalBooks,
    options,
    page,
    pageBooks,
    schoolsCatalog,
    statusCatalog,
    searchGralBook,
    selectSchool,
    selectStatus,
    changeViewAdmin,
    donwloadLogs,
    setSaveAction,
    setPage,
    setActions,
    setIdGrade,
    onNavigate,
    onHandleChangePage,
    onHandleSearch,
    onHandleNewBook,
    setTitle,
    setModalMassive,
    setModalSearch,
    setModalAuthorize,
    setModalErrorsMassive,
    setIdLevel,
    onHandleForm,
    openModalSearch,
    onHandleCancelModal,
    onHandleCancelModalSearch,
    onHandleCancelModalAuthorize,
    onHandleCancelModalEndList,
    onHandleCancelModalChanges,
    onHandleCancelModalErrorsMassive,
    onHandleOpenChanges,
    handleChangeLevelTab,
    handleCollapseBooks,
    handleDeleteBookSchool,
    handleAssignBook,
    handleReviewStatus,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleSearchBooks,
    handleFormComments,
    downloadBooks,
    getBooks,
    onHandleIndividualAuthorize,
    onHandleOpenEndList,
    handleRequestChanges
  } = useBooks()

  const location = useLocation()

  const dispatch = useDispatch()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const [changeViewMode, setChangeViewMode] = useState(false)
  // const colStyle = { cursor: 'pointer' }
  const { booksSchool, levelsBook, IDLevel, IDGrade } = useSelector(
    (state: RootState) => state.books
  )
  // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [innerFormFilled, setInnerFormFilled] = useState<boolean>(false)

  const { Panel } = Collapse

  const { Option } = Select

  const colStyle = { cursor: 'pointer' }

  const renderTitleButtons = () => {
    if (uid.role === 'Ezetera' && action !== 'nuevo' && action !== 'editar') {
      return [
        {
          label: 'Descargar',
          onClick: () => downloadBooks(),
          background: 'white',
          rounded: true,
          size: 'm',
          sector: 'tertiary',
          isLoading: loading
        },
        {
          label: 'Descargar Logs',
          onClick: () => donwloadLogs(),
          background: 'white',
          rounded: true,
          size: 'm',
          sector: 'tertiary',
          isLoading: loading
          /* disabled:true */
        }
      ]
    }

    let buttons: ButtonInterface[] =
      action === 'nuevo' || action === 'editar'
        ? []
        : [
            {
              label: 'Descargar',
              onClick: () => downloadBooks(),
              background: 'white',
              rounded: true,
              size: 'm',
              sector: 'tertiary',
              isLoading: loading
            },
            {
              label: 'Carga masiva',
              onClick: () => setModalMassive(true),
              LeftIconName: 'uploadIcon',
              background: 'white',
              rounded: true,
              size: 'm',
              sector: 'secondary',
              disabled: uid.role !== 'Director' ? false : true
            },
            {
              label: 'Autorizar',
              sector: 'tertiary',

              size: 'm',
              rounded: true,
              onClick: () => {
                setModalAuthorize(true)
                dispatch(setIsBookAuth(false))
              }
            }
          ]

    return buttons
  }

  const openDrawComments = () => {
    dispatch(setopenComments({ openComments: true }))
  }

  const onChange = (key: string | string[]) => {
    key.length > 0 && handleCollapseBooks(+key.slice(-1))
    key.length > 0 && setIdGrade(+key.slice(-1))
    dispatch(setIDGrade(key))
  }

  return (
    <MainTemplate>
      {(params.includes(action as string) || !action) && (
        <>
          {params.includes(action as string) && (
            <BackButton
              onClick={() => {
                onHandleChangePage(INITIAL_PAGE)
                setSaveAction((prev: boolean) => !prev)
                onNavigate('/libros')
              }}
              textDecoration='underline'
            />
          )}

          <TitleHeader
            buttonProps={renderTitleButtons()}
            IconName='book'
            widthIcon={'35'}
            heightIcon={'35'}
            title={`${setTitle(action as string)}`}
            {...(action === 'editar' && {
              additional: (
                <Tag
                  variation={actions.status}
                  title={`${setStatus(actions.status)} ${
                    uid.role === 'Ezetera' &&
                    actions.incomplete &&
                    actions.status === 4
                      ? 'datos incompletos'
                      : ''
                  }`}
                />
              )
            })}
          />

          {uid.role === 'Ezetera' && !params.includes(action as string) && (
            <>
              <div className={styles.filters}>
                <div>
                  <Typography>Colegios</Typography>
                  {/* <Select
                    style={{ width: 200 }}
                    defaultValue={'todos'}
                    placeholder={'Colegios'}
                    onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------
                    options={schoolsCatalog}
                  /> */}
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder='Colegios'
                    optionFilterProp='children'
                    onChange={onHandleChangeSchool}
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {schoolsCatalog?.map((school: Options) => (
                      <Option
                        key={school?.value as number}
                        value={school?.value}
                      >
                        {school?.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <Typography>Estatus</Typography>
                  <Select
                    style={{ width: 200 }}
                    defaultValue={'todos'}
                    placeholder={'Estatus'}
                    onChange={onHandleChangeStatus}
                    options={statusCatalog.filter(
                      (item: any) => item.label !== 'Nuevo'
                    )}
                  />
                </div>
              </div>

              <Input.Search
                placeholder='Buscar'
                onChange={(e: any) => onHandleSearchBooks(e.target.value)}
                value={searchGralBook}
                enterButton
                size='large'
                className={styles.inputSearchGral}
              />
              {changeViewAdmin === 'school' && (
                <div style={{ marginBottom: 16 }}>
                  <Button
                    rounded
                    onClick={() => setChangeViewMode(!changeViewMode)}
                  >
                    Cambiar a modo {changeViewMode ? 'acordeon' : 'tabla'}
                  </Button>
                </div>
              )}

              {changeViewAdmin === 'all' && (
                <ListBooksModule
                  loading={loading}
                  scroll={{ x: 820 }}
                  columns={bookColumns}
                  books={books}
                  onChangePage={onHandleChangePage}
                  onHandleRow={(record: any, _: any) => {
                    return {
                      onClick: (_: any) => {
                        openDrawComments()
                        dispatch(setCommentsObjectInformation(record))
                        setIdGrade(record.idGrade)
                        dispatch(
                          setDataCollege({
                            idSchool: record?.id_school,
                            idLevel: record?.id_level,
                            idGrade: record?.id_grade
                          })
                        )
                      }
                    }
                  }}
                />
              )}

              {changeViewAdmin === 'school' && !changeViewMode && (
                <TabsAdminModule
                  bookColumns={bookColumns}
                  idSchool={idSchool}
                  idLevel={idLevel}
                  idGrade={idGrade}
                  setIdLevel={setIdLevel}
                  setIdGrade={setIdGrade}
                  selectStatus={selectStatus}
                  searchGralBook={searchGralBook}
                />
              )}

              {changeViewAdmin === 'school' && changeViewMode && (
                <ListBooksModule
                  loading={loading}
                  scroll={{ x: 820 }}
                  columns={bookColumns}
                  books={books}
                  onChangePage={onHandleChangePage}
                  onHandleRow={(record: any, _: any) => {
                    return {
                      onClick: (_: any) => {
                        openDrawComments()
                        dispatch(setCommentsObjectInformation(record))

                        dispatch(
                          setDataCollege({
                            idSchool: record.id_school,
                            idLevel: record.id_level,
                            idGrade: record.id_grade
                          })
                        )
                        setIdGrade(record.idGrade)
                      }
                    }
                  }}
                />
              )}
            </>
          )}

          {!params.includes(action as string) && uid.role !== 'Ezetera' && (
            <>
              <Col span={24}>
                <Tabs
                  items={levelsBook}
                  defaultActiveKey={IDLevel}
                  onChange={handleChangeLevelTab}
                />
              </Col>

              <Col span={24}>
                {levelsBook
                  ?.find((level: any) => level.key === IDLevel)
                  ?.grades?.map((res: any) => {
                    // const someNew = booksSchool.filter(
                    //   (item: { idGrade: any }) =>
                    //     item.idGrade === res.id
                    // ).every((book:any)=>book.status===1)

                    // console.log(someNew)

                    const total = booksSchool
                      ?.filter(
                        (item: { idGrade: any }) => item.idGrade === res.id
                      )
                      ?.reduce(
                        (
                          acc: number,
                          cv: {
                            special_price: string | number
                            price: string | number
                          }
                        ) => {
                          let total = cv.price
                          if (
                            cv.special_price !== '0.00' &&
                            cv.special_price !== null
                          )
                            total = cv.special_price
                          return acc + +total
                        },
                        0
                      )
                    return (
                      <Collapse
                        key={res.id}
                        // defaultActiveKey={IDGrade}
                        activeKey={IDGrade}
                        onChange={onChange}
                        bordered={false}
                      >
                        <Panel
                          className={styles.collapsePanel}
                          extra={
                            <>
                              <ActionsBookModule
                                idGrade={res.id}
                                endList={res.endListBooks}
                                setIdGrade={setIdGrade}
                                data={booksSchool}
                                actions={[
                                  handleReviewStatus,
                                  openModalSearch,
                                  onHandleNewBook,
                                  setModalMassive,
                                  onHandleIndividualAuthorize,
                                  onHandleOpenChanges
                                ]}
                              />
                            </>
                          }
                          header={
                            <>
                              <B>
                                {res?.name}{' '}
                                {total ? (
                                  <b style={{ color: '#0F48FF' }}>
                                    {new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                      minimumFractionDigits: 2
                                    }).format(total)}
                                  </b>
                                ) : (
                                  ''
                                )}
                              </B>
                            </>
                          }
                          key={res.id}
                        >
                          {booksSchool.filter(
                            (item: { idGrade: any }) => item.idGrade === res.id
                          )?.length > 0 ? (
                            <>
                              <Table
                                dataSource={booksSchool.filter(
                                  (item: { idGrade: any }) =>
                                    item.idGrade === res.id
                                )}
                                loading={loading}
                                // rowSelection={{ ...rowSelection }}
                                columns={bookColumns}
                                scroll={{ x: 800 }}
                                rowKey={(row) => row.id}
                                className={styles.booksTable}
                                onRow={(record, _) => {
                                  return {
                                    onClick: (_) => {
                                      openDrawComments()
                                      dispatch(
                                        setCommentsObjectInformation(record)
                                      )
                                      setIdGrade(record?.idGrade as number)

                                      dispatch(
                                        setDataCollege({
                                          idSchool: record?.id_school,
                                          idLevel: record?.id_level,
                                          idGrade: record?.id_grade
                                        })
                                      )
                                    }
                                  }
                                }}
                              />
                              {!res.endListBooks && uid.role !== 'Director' && (
                                <div className={styles.endList}>
                                  <Button
                                    label={'Terminar lista'}
                                    rounded
                                    sector='secondary'
                                    background='white'
                                    onClick={() => {
                                      onHandleOpenEndList(res.id)
                                      // handleReviewStatus(res.id)
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <EmptyListMessage
                              iconHeight={40}
                              iconWidth={40}
                              icon='book'
                              text='Aún no has asignado libros'
                              {...(uid.role !== 'Director' && {
                                buttonProps: [
                                  {
                                    label: 'Asignar primer libro',
                                    onClick: () => {
                                      setIdGrade(res.id)
                                      setModalSearch(true)
                                    },

                                    rounded: true,
                                    sector: 'tertiary',
                                    background: 'white',
                                    size: 'm'
                                  }
                                ]
                              })}
                            />
                          )}
                        </Panel>
                      </Collapse>
                    )
                  })}
              </Col>
            </>
          )}

          {params.includes(action as string) && (
            <FormBooksModule
              loading={loading}
              action={action}
              form={form}
              onHandleForm={onHandleForm}
              actions={actions}
              setActions={setActions}
            />
          )}

          <Modals
            title='Carga masiva'
            open={modalMassive}
            onCancel={onHandleCancelModal}
          >
            <MassiveModule
              closeModal={onHandleCancelModal}
              errorMassive={setModalErrorsMassive}
            />
          </Modals>

          <Modals
            title='Eliminar registro'
            open={modalDelete}
            onCancel={onHandleCancelModal}
          >
            <>
              <span>¿Estás seguro de eliminar el registro?</span>
              <div className={styles.button}>
                <Button
                  label={'Eliminar'}
                  rounded
                  sector='primary'
                  onClick={() => handleDeleteBookSchool()}
                />
              </div>
            </>
          </Modals>

          <Modals
            title='Buscar libro'
            open={modalSearch}
            onCancel={onHandleCancelModalSearch}
            width={780}
          >
            <div className={styles.searchContainer}>
              <div className={styles.inputSearch}>
                <Typography>
                  Busca tu libro (Busca por código ISBN para un mejor resultado)
                </Typography>
                <SearchBarModule
                  options={options}
                  id='inputSearchBook'
                  onSearch={onHandleSearch}
                  timer={500}
                />
              </div>

              <InfiniteScrolls
                total={totalBooks}
                source={booksGral}
                setPage={setPage}
                page={page}
              >
                <div className='list'>
                  {booksGral.length > 0 ? (
                    <>
                      <List
                        itemLayout='horizontal'
                        dataSource={BOOKS_TITLE_SEARCH}
                        renderItem={(title: any) => {
                          return (
                            <List.Item style={{ padding: ' 10px 0' }}>
                              <Col
                                className='ListTitles'
                                style={colStyle}
                                span={18}
                              >
                                <B size='Light'>{title.book}</B>
                              </Col>

                              <Col
                                className='ListTitles'
                                style={colStyle}
                                span={4}
                              >
                                <B size='Light'>{title.action}</B>
                              </Col>
                            </List.Item>
                          )
                        }}
                      />
                      <List
                        loading={loading}
                        itemLayout='horizontal'
                        dataSource={booksGral}
                        renderItem={(book: any) => (
                          <List.Item style={{ padding: ' 18px 0' }}>
                            <Col
                              // onClick={() => {
                              //   onHandleEditData(`/libros/editar`, book)
                              // }}
                              // style={colStyle}
                              span={18}
                            >
                              {' '}
                              <B>
                                ISBN:{' '}
                                <b style={{ fontWeight: '600' }}>{book.isbn}</b>
                              </B>
                              <B>{book.name}</B>
                              <B size='Light'>
                                Editorial:{' '}
                                <b style={{ fontWeight: '600' }}>
                                  {book.editorial}
                                </b>{' '}
                                Edición:{' '}
                                <b style={{ fontWeight: '600' }}>
                                  {book.edition}
                                </b>{' '}
                              </B>
                            </Col>
                            <Col style={colStyle} span={4}>
                              <Button
                                type='blue'
                                isLoading={loading}
                                rounded
                                label='Agregar'
                                onClick={() => {
                                  handleAssignBook(book)
                                }}
                              />
                            </Col>
                          </List.Item>
                        )}
                      />
                    </>
                  ) : (
                    <EmptyListMessage
                      icon='emptySchoolListIcon'
                      text='Sin resultado'
                      subtitle='Realiza una búsqueda para ver los libros del catálogo'
                    />
                  )}
                </div>
              </InfiniteScrolls>

              <div className={styles.button}>
                <Button
                  label={'Solicitar nuevo recurso'}
                  rounded
                  sector='primary'
                  onClick={() => {
                    setModalSearch(false)
                    onHandleNewBook(`${location?.pathname}/nuevo`)
                  }}
                />
              </div>
            </div>
          </Modals>

          <Modals
            title='Autorizar libros'
            open={modalAuthorize}
            width={780}
            onCancel={onHandleCancelModalAuthorize}
          >
            <AuthorizeModule closeModal={onHandleCancelModalAuthorize} />
          </Modals>
          <Modals
            title='Terminar lista'
            open={modalEndList}
            onCancel={onHandleCancelModalEndList}
          >
            <>
              <div className={styles.bodyEndList}>
                <img src={ErrorAlert} alt='Terminar lista' />
                <B size='Light'>
                  ¿Estás seguro que deseas terminar esta lista?, No podrás
                  editar o borrar dicha lista, tampoco tendras permitido agregar
                  más libros en ella.
                </B>
              </div>
              <div className={styles.button}>
                <Button
                  rounded
                  onClick={handleReviewStatus}
                  label={'Terminar'}
                  sector='secondary'
                />
              </div>
            </>
          </Modals>
          <Modals
            title='Solicitar modificación'
            open={modalChanges}
            onCancel={onHandleCancelModalChanges}
          >
            <>
              <div className={styles.bodyEndList}>
                <B size='Light'>
                  Al solicitar una modificación, se le solicitará al
                  administrador el permiso para modificar esta lista, por lo que
                  su lista volverá a estar abierta a cambios.
                </B>
              </div>
              <div className={styles.button}>
                <Button
                  rounded
                  onClick={handleRequestChanges}
                  label={'Solicitar modificación'}
                  sector='tertiary'
                />
              </div>
            </>
          </Modals>

          <Modals
            title='Errores carga masiva'
            width={660}
            open={modalErrorsMassive}
            onCancel={onHandleCancelModalErrorsMassive}
          >
            <ErrorMassiveModule />
          </Modals>
        </>
      )}

      <CommentsComponent
        innerFormFilled={innerFormFilled}
        afterBookStatusUpdated={() => {
          getBooks(pageBooks, selectSchool, selectStatus, searchGralBook)
        }}
        moduleForm={
          <FormCommentsModule
            setFormFilledOut={(filled: boolean) => setInnerFormFilled(filled)}
            form={form}
            handleSubmit={handleFormComments}
          />
        }
      />

      {/* {action === 'editar' && (
        <FixedFooter>
          <div className={styles.footerContent}>
          <Button
            label={'Ver comentarios'}
            rounded
            size='m'
            sector='tertiary'
            background='white'
            onClick={() => {
              setModalSearch(false)
              onHandleNewBook(`${location?.pathname}/nuevo`)
            }}
          />
          </div>
        </FixedFooter>
      )} */}
      <EndListModal />
    </MainTemplate>
  )
})

export default BooksModule
