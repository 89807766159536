import { memo } from 'react'
import { Col, Form, Modal, Row, Tooltip } from 'antd'
import style from './../AdminUtilsManagement/AdminUtilsManagement.module.scss'
import style2 from './../CreateUtilsForm/CreateUtilsForm.module.scss'
import { useLocation } from 'react-router-dom'
import UploadInput from 'components/inputs/UploadInput'
import FormInput from 'components/formInputs/input'
import { ActionsMap } from 'hooks/utils/useUtils'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import FormAutoComplete from 'components/formInputs/autocomplete'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import globalStyle from 'styles/global.module.scss'
import Button from 'components/buttons/Button/Button'
import FormSelect from 'components/formInputs/select'
import FormRadio from 'components/formInputs/radio'
import { useAddUtilsForm } from './hook/useAddUtilsForm'

const AddUtilsForm = memo(({ params, selectedUtil, onFinishForm }: any) => {
  const location = useLocation()
  const {
    addUtilForm,
    OptionBrand,
    savingData,
    form,
    modalMake,
    formEditorial,
    loadingPost,
    submit,
    onHandleAutoCompleteMarca,
    setmodalMake,
    onFileChange,
    postNewMakeSupply
  } = useAddUtilsForm({ selectedUtil, onFinishForm })

  const renderCorrectInput2 = (
    key: number,
    objInput: any,
    selectedUtil?: any
  ) => {
    if (objInput.type === 'autocomplete')
      return (
        <FormAutoComplete
          name={objInput.name}
          type={'text'}
          hasFeedback={false}
          label={
            <>
              <span>{objInput.label}</span>
              <Tooltip title={`Agregar ${objInput.label}`}>
                <PlusCircleIcon
                  onClick={() => setmodalMake(true)}
                  className={style2.addMake}
                />
              </Tooltip>
            </>
          }
          placeholder={objInput.placeholder}
          options={OptionBrand}
          rules={objInput.rules}
          onSearch={onHandleAutoCompleteMarca}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'radio')
      return (
        <FormRadio
          name={objInput.name}
          label={objInput.label}
          options={objInput.options}
          initialValue={true}
          optionType='button'
          buttonStyle='solid'
        />
      )
    if (objInput.type === 'select')
      return (
        <FormSelect
          hasFeedback={false}
          name={objInput.name}
          options={objInput.options}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'file')
      return (
        <UploadInput
          hasFeedback={false}
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={selectedUtil?.picture ? selectedUtil.picture : objInput.value}
          onChange={onFileChange}
        />
      )
    return (
      <FormInput
        hasFeedback={false}
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
      />
    )
  }

  return (
    <>
      <TitleHeader
        buttonProps={[
          {
            isLoading: savingData,
            label: 'Guardar',
            rounded: true,
            size: 'm',
            sector: 'secondary',
            onClick: (e: Event) => {
              e.stopPropagation()
              e.preventDefault()
              form.submit()
            }
          }
        ]}
        IconName='utilsIcon'
        widthIcon={'35'}
        heightIcon={'35'}
        title={ActionsMap(params.get('action'))}
      />
      <Form
        onFinish={(values) => submit(location?.state?.id, values)}
        layout='vertical'
        autoComplete='off'
        className={style.CreateSchoolForm}
        form={form}
        disabled={savingData}
        initialValues={selectedUtil}
      >
        <Row gutter={[16, 16]}>
          {Object.keys(addUtilForm).map((res: any, key) => {
            const objKey = res as keyof typeof addUtilForm
            let objInput: any = addUtilForm[objKey]
            if (objInput.span)
              return (
                <Col span={objInput.span}>
                  {renderCorrectInput2(key, objInput, selectedUtil)}
                </Col>
              )
            return (
              <Col span={12}>
                {renderCorrectInput2(key, objInput, selectedUtil)}
              </Col>
            )
          })}
        </Row>
      </Form>
      <Modal
        title='Agregar marca'
        open={modalMake}
        centered
        onCancel={() => {
          formEditorial.resetFields()
          setmodalMake(false)
        }}
        footer={false}
        className={globalStyle.confirmModal}
      >
        <Form
          form={formEditorial}
          layout='vertical'
          onFinish={postNewMakeSupply}
          autoComplete='off'
        >
          <FormInput
            name='name'
            placeholder='Nombre'
            label='Nombre'
            rules={[{ required: true, message: '' }]}
          />
          <div className={globalStyle.confirmModalFooter}>
            <Button
              isLoading={loadingPost}
              buttonType='submit'
              rounded
              sector='primary'
            >
              Agregar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
})

AddUtilsForm.displayName = 'AddUtilsForm'
export default AddUtilsForm
