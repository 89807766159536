import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Components from 'pages/componentsTest/ComponentsTest'
import Login from 'pages/login'
import ChooseSchool from 'pages/ChooseSchool'

import ForgotPassword from 'pages/forgotPassword'
import NewPassword from 'pages/newPassword'
import SchoolDetails from 'pages/adminSchool/SchoolDetails'
import SchoolList from 'pages/adminSchool/SchoolList'
import PackagesList from 'pages/utils/packagesList'
import UtilsList from 'pages/utils/utilsList'
import UtilsDetails from 'pages/utils/UtilsDetails'
import UserSchoolDetails from 'pages/userSchool/SchoolDetails'

import Grades from 'pages/userSchool/Grades'
import UniformListModule from 'pages/uniforms/index'
import CreateNew from 'pages/uniforms/create-new/index'
import EditorModule from 'pages/uniforms/preview/index'

import Notebooks from 'pages/notebooks'
import AddUser from 'pages/adduser'
import Books from 'pages/books'
import Quotas from 'pages/quotas'
import Reports from 'pages/reports'
import NotFound from 'pages/notFound'
import UserList from 'pages/userManagement/userList/index'
import NotebookEditorModule from 'pages/notebooks/editor/index'
import NotebookSelectType from 'pages/notebooks/selecType/index'
import NotebookEditorInsideModule from 'pages/notebooks/editorInside/index'
import PrivateRoute from './PrivateRoute'
import NormalRoute from './NormalRoute'
import PreviewUniformModule from 'modules/uniforms/preview/PreviewUniformModule'
import SchoolStructure from 'pages/userSchool/SchoolStructure'
import NotebooksPreview from 'pages/notebooks/preview'
import HomePage from 'pages/homePage'
import DownloadCovers from 'pages/notebooks/dowload-covers'
import ComisionesColegiosListas from 'pages/comisiones-por-colegio/gestion-lista/index'
import ComisionesColegio from 'pages/comisiones-por-colegio/comisiones/index'
import ModulodePrueba from 'modules/notebooks/editor/components/wordArtInput/ModulodePrueba'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NormalRoute />}>
          <Route path='/' element={<Login />} />

          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path='/inicio' element={<HomePage />} />
          <Route path='/selecciona-una-escuela' element={<ChooseSchool />} />

          {/* ------------------------ Modulo de Cuadernos ------------------------ */}
          <Route path='/cuadernos' element={<Notebooks />} />
          <Route
            path='/cuadernos/select-type/:uuid'
            element={<NotebookSelectType />}
          />
          <Route
            path='/cuadernos/edith-front/:uuid'
            element={<NotebookEditorModule />}
          />
          <Route
            path='/cuadernos/edith-inside/:uuid'
            element={<NotebookEditorInsideModule />}
          />
          <Route
            path='/cuadernos/preview/:uuid'
            element={<NotebooksPreview />}
          />
          <Route
            path='/cuadernos/download-covers/:uuid'
            element={<DownloadCovers />}
          />
          <Route path='/prueba' element={<ModulodePrueba />} />

          {/* ------------------------ Modulo de Uniformes ------------------------ */}
          <Route path='/uniforms' element={<UniformListModule />} />
          <Route path='/uniforms/create-new' element={<CreateNew />} />
          <Route path='/uniforms/edith-selection' element={<EditorModule />} />
          <Route path='/uniforms/preview' element={<PreviewUniformModule />} />

          {/* ------------------------ Modulo de administracion de usuarios ------------------------ */}
          <Route path='/users-management/form-user' element={<AddUser />} />
          <Route path='/users-management' element={<UserList />} />

          {/* ------------------------ Ruta de Administradores ------------------------ */}
          <Route path='/lista-de-colegios' element={<SchoolList />} />
          <Route path='/colegio/:name' element={<SchoolDetails />} />
          <Route path='/crear-colegio' element={<SchoolDetails />} />

          {/*------------------------ ----Gestion de comisiones por colegio -----------------------------*/}
          <Route path='/comisiones' element={<ComisionesColegio />} />
          <Route
            path='/comisiones/convenios-por-colegio/:uuid'
            element={<ComisionesColegiosListas />}
          />

          {/*------------------------ ----Ruta de Usuarios -----------------------------*/}
          <Route path='/estructura' element={<SchoolStructure />} />
          <Route path='/grado/:name' element={<Grades />} />
          <Route path='/mi-colegio' element={<UserSchoolDetails />} />

          {/*------------------------ ----Útiles -----------------------------*/}

          <Route path='/utiles' element={<PackagesList />} />
          <Route path='/utiles/:package' element={<UtilsList />} />
          <Route
            path='/utiles/:package/crear-util'
            element={<UtilsDetails />}
          />
          <Route path='/utiles/:package/:utilName' element={<UtilsDetails />} />

          {/*------------------------ ----Libros -----------------------------*/}
          <Route path='/libros/:action?' element={<Books />} />
          {/*------------------------ ----Cuotas -----------------------------*/}
          <Route path='/cuotas' element={<Quotas />} />
          {/*------------------------ ----Reportes -----------------------------*/}
          <Route path='/reportes' element={<Reports />} />
        </Route>
        <Route path='/dev-components' element={<Components />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
