/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { memo } from 'react'
import { Form, Input } from 'antd'
import { AllIconsInterface, inputTypeInterface } from '../../../types'
import './Input.scss'

interface InputProps {
  label?: string
  name: string
  placeholder?: string
  validations?: any
  disabled?: boolean
  iconName?: AllIconsInterface
  prefix?: JSX.Element
  type?: inputTypeInterface
  form?: string
  value: string
  maxLength?: number
  minLength?: number
  bordered?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: any) => void
  customprops?: any
}
const CInput = memo((props: InputProps) => {
  function validateNumber(event: React.KeyboardEvent<HTMLInputElement>) {
    if (props.type !== 'number') return
    var tecla = event.key
    var regex = /^[0-9]$/
    if (!regex.test(tecla)&& event.key !== "Backspace") {
      event.preventDefault()
    }
  }
  if (props.type === 'password')
    return (
      <Form.Item className='input-component' label={props.label}>
        <Input.Password className='custom' {...props} id={props.name} />
      </Form.Item>
    )
  return (
    <Form.Item
      className='input-component'
      label={props.label}
      {...props.customprops}
    >
      <Input
        className='custom'
        {...props}
        id={props.name}
        onChange={props.onChange}
        min={0}
        onKeyDown={validateNumber}
      />
    </Form.Item>
  )
})

Input.displayName = 'Input'
export default CInput
