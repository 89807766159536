import React, { useState, useEffect, useRef } from 'react'
import style from './notebooksDownload.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PathPortadasExteriores from 'assets/img/notebooks/horizontals/PathPortadasExteriores'
import { setPositionsCovers } from 'redux/reducers/notebooksSlice'
import HorizontalDownloadDragable from './HorizontalDownloadDragable'
import HorizontalDownloadProporsal from './HorizontalDownloadProporsal'
import { useSearchParams } from 'react-router-dom'
import * as htmlToImage from 'html-to-image'
import Button from 'components/buttons/Button/Button'
import { ErrorToast } from 'components/Toasts/Toasts'

const HorizontalPortadasDownload = () => {
  const [params] = useSearchParams()
  const [downloading, setDownloading] = useState(false)
  const { notebookFrontValues, notebookSelected, edithUpload, positionCovers } =
    useSelector((state: RootState) => state.notebooks)
  const dispatch = useDispatch()
  const domEl = useRef(null)
  const { spine_color, binding_type, binding_color, rounded, cover_design } =
    notebookFrontValues

  const downloadImage = async () => {
    setDownloading(true)

    try {
      if (!domEl.current) return
      const dataUrl = await htmlToImage.toPng(domEl.current)

      // download image
      const link = document.createElement('a')
      link.download = 'html-to-img.png'
      link.href = dataUrl
      link.click()
    } catch (error) {
      ErrorToast("Ocurrió un error, inténtelo de nuevo")
    }

    setDownloading(false)
  }

  return (
    <>
      <div className={style['download-button-container']}>
        <Button
          isLoading={downloading}
          rounded
          size='m'
          sector='secondary'
          background='white'
          onClick={downloadImage}>
          Descargar
        </Button>
      </div>
      <div
        ref={domEl}
        className={`${style['div-container-sticky']}`}
        onClick={() => {
          downloadImage()
        }}
      >
        <div className={`${style['div-notebook-svg']}`}>
          {cover_design.value === 'design' && <HorizontalDownloadDragable />}
          {cover_design.value === 'upload' &&
            !edithUpload.left &&
            !edithUpload.right && (
              <HorizontalDownloadProporsal
                setPositions={(dat: { back: number; front: number }) =>
                  dispatch(setPositionsCovers(dat))
                }
                positions={positionCovers}
                edithSides={edithUpload}
                item={(params.get('viewMode') as string) == '1' ? 2 : 1}
              />
            )}
          {/* {[1, 2].map((item: number, index: number) => {
          let viewBox = item === 1 ? '35 0 820 700' : '35 0 820 700'
          return (
            <div
              key={index}
              className={`${style['div-polo-notebook-relative']} 
                  ${item === 1 && style['transform-invert']}`}
            >
              <div className={`${style[`logo-position-`]}`}></div>
              <div className={`${style['div-polo-svg-absolute']}`}>
                <PathPortadasExteriores
                  viewBox={viewBox}
                  showSizes={item}
                  className={`${style['svg']}`}
                  notebookSelected={notebookSelected}
                  colorLomo={spine_color.value as string}
                  rounded={rounded.value as boolean}
                  date={`${style['date1-font-12']}`}
                />
              </div>
              <div className={`${style['div-shadows-svg-absolute']}`}>
                {cover_design.value === 'upload' &&
                  !edithUpload.left &&
                  !edithUpload.right && (
                    <HorizontalDownloadProporsal
                      setPositions={(dat: { back: number; front: number }) =>
                        dispatch(setPositionsCovers(dat))
                      }
                      positions={positionCovers}
                      edithSides={edithUpload}
                      item={item}
                    />
                  )}
              </div>
            </div>
          )
        })} */}
        </div>
      </div>
    </>
  )
}
export default HorizontalPortadasDownload
