import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import {
  createSchoolAdmin,
  deleteSchoolAdmin,
  getSchoolGroupId,
  getSchoolPerssimionsList,
  negateOrAcceptRequest,
  schoolAdminInterface,
  updateSchoolAdmin
} from 'services/schoolAdminServices/schools'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { formatUrlNames } from 'utilities/formatUrlNames'
import { handleErrors } from 'utilities/handleErrors'
import { Form } from 'antd'
import dayjs from 'dayjs'
import { Options } from 'types'
const PHONE_PATTERN = /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/

export function useSchoolForm() {
  const INPUTS_INITIAL_STATE = {
   /*  principal: {
      label: 'Nombre del director',
      name: 'principal',
      placeholder: 'Nombre del director',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    email: {
      label: 'Email del colegio',
      name: 'email',
      placeholder: 'example@gmail.com',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, mail: any) => {
            if (mail.length !== 0) {
              const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
              regex.test(mail)
              if (!regex.test(mail))
                return Promise.reject(
                  new Error('Debe de ingresar una dirección de correo valida')
                )
            }
          }
        }
      ]
    }, */
    name: {
      label: 'Nombre del colegio',
      name: 'name',
      placeholder: 'Nombre del colegio',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    social_reason: {
      label: 'Razón Social',
      name: 'social_reason',
      placeholder: 'Razón Social',
      value: '',
      type: 'text',
      required: true,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: '', // puede ser 'warning' o 'error' segun antd,
      rules: [{ required: true, message: '' }]
    },
    sex: {
      label: 'Tipo de colegio',
      name: 'sex',
      placeholder: 'Tipo de colegio',
      value: '',
      type: 'select',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    group_of_colleges_id: {
      label: 'Grupo Escolar',
      name: 'group_of_colleges_id',
      placeholder: 'Grupo Escolar',
      value: '',
      type: 'select',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    opening_date: {
      label: 'Fecha de apertura',
      name: 'opening_date',
      placeholder: 'Fecha de apertura',
      value: '',
      type: 'date',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    address: {
      label: 'Dirección',
      name: 'address',
      placeholder: 'Dirección',
      value: '',
      type: 'text',
      required: true,
      maxLength: 150, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    referenced_key: {
      label: 'Estado',
      name: 'state',
      placeholder: 'Estado',
      value: '',
      type: 'text',
      required: true,
      maxLength: 150, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    phone: {
      label: 'Teléfono',
      name: 'phone',
      placeholder: 'Teléfono',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        {
          required: true,
          message: '',
          pattern: PHONE_PATTERN
        }
      ]
    },
    id_magento: {
      label: 'ID magento',
      name: 'id_magento',
      placeholder: 'ID magento',
      value: '',
      type: 'text',
      required: true,
      maxLength: 15, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, id_magento: any) => {
            if (id_magento && id_magento.length > 10) {
              return Promise.reject(
                new Error('El id magento no debe ser mayor a 10 dígitos')
              )
            }
          }
        }
      ]
    },
    id_gea: {
      label: 'ID GEA',
      name: 'id_gea',
      placeholder: 'ID GEA',
      value: '',
      type: 'text',
      required: true,
      maxLength: 15, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, id_gea: any) => {
            if (id_gea && id_gea.length > 10) {
              return Promise.reject(
                new Error('El id gea no debe ser mayor a 10 dígitos')
              )
            }
          }
        }
      ]
    },
    quota: {
      label: 'Cuota',
      name: 'quota',
      placeholder: '0.00',
      value: '',
      type: 'text',
      required: true,
      maxLength: 5, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, quote: any) => {
            const regexp = /^\d{0,3}(\.\d{1,2})?$/
            const valid = regexp.test(quote)
            const numericQuote = parseFloat(quote)
            if (numericQuote > 100) {
              return Promise.reject(
                new Error('La cuota no puede ser mayor a 100')
              )
            }
            if (!valid) {
              return Promise.reject(
                new Error(
                  'La cuota debe de poseer máximo dos decimales separados por puntos'
                )
              )
            }
          }
        }
      ]
    },
    agreement: {
      label: 'Convenios',
      name: 'agreement',
      placeholder: '',
      value: '',
      type: 'radioButton',
      required: true,
      maxLength: 5, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
 /*    referenced_key: {
      label: 'Número de referencia',
      name: 'referenced_key',
      placeholder: 'Número de referencia',
      value: '',
      type: 'text',
      required: true,
      maxLength: 150, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    socioeconomic_level: {
      label: 'Nivel socioeconómico',
      name: 'socioeconomic_level',
      placeholder: 'Nivel socioeconómico',
      value: '',
      type: 'text',
      required: true,
      maxLength: 150, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    bilingual: {
      label: 'Bilingüe',
      name: 'bilingual',
      placeholder: '',
      value: '',
      type: 'radioButton',
      required: true,
      maxLength: 5, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    } */
    permissions: {
      label: 'Permisos',
      name: 'permissions',
      placeholder: 'Permisos',
      value: '',
      type: 'checkbox',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      span:24,
      rules: [{ required: true, message: '' },
      {
        validator: async (_: any, quote: any) => {
          if (quote.length === 0) {
            return Promise.reject(
              new Error('Debe seleccionar almenos un permiso')
            )
          }
        }
      }
    ],
      options:[]
    },
  }

  const SCHOOL_TYPES = [
    {
      value: 'u',
      label: 'Mixto'
    },
    {
      value: 'm',
      label: 'Masculino'
    },
    {
      value: 'f',
      label: 'Femenino'
    }
  ]

  const SCHOOL_LENGUAGES: Options[] = [
    { label: 'Si', value: true },
    { label: 'No', value: false }
  ]

  const navigate = useNavigate()

  const [form] = Form.useForm()

  const location = useLocation()
  const [loadingPost, setloadingPost] = useState(false)
  const [loadingDelete, setloadingDelete] = useState(false)
  const [collageGroups, setCollageGroups] = useState([])
  const [permissions, setpermissions] = useState([])
  const [schoolData, setschoolData] = useState(INPUTS_INITIAL_STATE)
  const dateFormat = 'YYYY/MM/DD'

  const getGroups = async () => {
    try {
      const result = await getSchoolGroupId()
      
      setCollageGroups(
        result.data.groups_of_collages.map(
          (res: { id: number; name: string }) => {
            return {
              label: res.name,
              value: res.id
            }
          }
        )
      )
    } catch (err: any) {
      handleErrors(err)
    }
  }
  const getPermissions =async() =>{
    try {
      const result = await getSchoolPerssimionsList()
      setpermissions(result.data.permissions.map((res:any)=>{
        return {label:res.name, value:res.id}
      }))
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPermissions()
    getGroups()
  }, [])
  useEffect(() => {
    let unmounted2 = false
    const stateData = location.state
    if (stateData !== null && collageGroups.length > 0) {
      form.setFieldsValue({
        ...location.state,
        opening_date: dayjs(location.state.opening_date, dateFormat)
      })
    }
    return () => {
      unmounted2 = true
      if (unmounted2) setschoolData(INPUTS_INITIAL_STATE)
    }
  }, [collageGroups])

  const checkDisabled = (): boolean => {
    let disabled = false
    Object.keys(schoolData).forEach((res: any) => {
      const objKey = res as keyof typeof INPUTS_INITIAL_STATE
      const objInput = schoolData[objKey]
      if (objInput.value === '') disabled = true
    })
    return disabled
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setschoolData((prev) => cleanSingleUpdatedState(e, prev))
  }

  const handleChangeSelect = (value: string, name: string) => {
    setschoolData((prev) => cleanSingleSelectUpdatedState(value, name, prev))
  }
  const handleChangeDate = (
    value: string,
    name: string,
    dateString: string
  ) => {
    setschoolData((prev) =>
      cleanSingleSelectUpdatedState(dateString, name, prev)
    )
  }

  const postNewSchool = async (values: any) => {
    setloadingPost(true)
    try {
      await createSchoolAdmin(values)
      setloadingPost(false)
      SuccessToast('El colegio ha sido creado correctamente')
      navigate('/lista-de-colegios')
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const updateSchool = async (values: any, id: number) => {
    setloadingPost(true)
    const data = { ...values, id }
    try {
      await updateSchoolAdmin({ data })
      SuccessToast(`El colegio "${values.name}" fue editado correctamente`)
      setloadingPost(false)
      navigate('/lista-de-colegios')
      /* navigate(`/colegio/${formatUrlNames(values.name)}`, { state: values }) */
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const deleteSchoolById = async (school: any) => {
    setloadingDelete(true)
    try {
      await deleteSchoolAdmin(school.id)
      SuccessToast(
        `El colegio "${school.name}" ha sido eliminado correctamente`
      )
      setloadingDelete(false)
      navigate('/lista-de-colegios')
    } catch (err: any) {
      setloadingDelete(false)
      handleErrors(err)
    }
  }
  const handleResponseChangeRequest = async (school: any, accept: boolean) => {
    setloadingDelete(true)
    try {
      await negateOrAcceptRequest({ idSchool: school.id, accept })
      SuccessToast(
        accept
          ? `Ha sido "Aprobada" la solicitud de modificación de estructura para el colegio "${school.name}"`
          : `Ha sido "Denegada" la solicitud de modificación de estructura para el colegio "${school.name}"`
      )
      navigate('/lista-de-colegios')
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingDelete(false)

    }
  }

  const onSubmit = (id: number, values: any) => {
    if (id) return updateSchool(values, id)
    postNewSchool(values)
  }

  return {
    schoolData,
    loadingPost,
    loadingDelete,
    collageGroups,
    postNewSchool,
    deleteSchoolById,
    onSubmit,
    checkDisabled,
    handleChangeSelect,
    handleChange,
    handleChangeDate,
    handleResponseChangeRequest,
    permissions,
    SCHOOL_TYPES,
    INPUTS_INITIAL_STATE,
    SCHOOL_LENGUAGES,
    form
  }
}
