import { IJsonParameters } from 'interfaces/notebooks-interface'
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setIdTextBack,
  setIdTextFront,
  setImgIdBack,
  setImgIdFront,
  setJsonImgBack,
  setJsonImgFront,
  setJsonTextBack,
  setJsonTextfront,
  setTextPositionNow
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import DraggableCovers from '../editor/components/EditorDiv/DraggableCovers'
import style from './notebooksDownload.module.scss'
import * as htmlToImage from 'html-to-image'
import html2canvas from 'html2canvas'
import { useSearchParams } from 'react-router-dom'

const VerticalDownloadDragable = () => {
  const {
    jsonImgBack,
    jsonImgfront,
    jsonTextBack,
    jsonTextfront,
    TextBackId,
    TextfrontkId,
    textPositionNow,
    ImgBackId,
    ImgfrontkId,
    isCoverOnEdith,
    notebookFrontValues
  } = useSelector((state: RootState) => state.notebooks)
  const leftAbsolute = useRef<HTMLDivElement>(null)
  const rightAbsolute = useRef<HTMLDivElement>(null)
  const [flagReset, setFlagReset] = useState(false)
  const [params] = useSearchParams()
  const dispatch = useDispatch()
  const domEl = useRef(null)

  const { rounded, spine_color } = notebookFrontValues

  const eraseTextBack = (id: number) => {
    let values = jsonTextBack
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonTextBack(values))
  }
  const eraseImgBack = (id: number) => {
    let values = jsonImgBack
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonImgBack(values))
  }

  const eraseTextFront = (id: number) => {
    let values = jsonTextfront
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonTextfront(values))
  }
  const eraseImgFront = (id: number) => {
    let values = jsonImgfront
    values = {
      ...values,
      [`${id}`]: { ...values[id], show: false }
    }
    dispatch(setJsonImgFront(values))
  }

  const handleTextSizeBiIdBack = (id: number) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text }
        }
      })
    )
  }
  const handleImgSizeBiIdBack = (id: number) => {
    dispatch(
      setJsonImgBack({
        ...jsonImgBack,
        [id]: {
          ...textPositionNow,
          img: jsonImgBack[id].img
        }
      })
    )
  }
  const handleTextSizeBiIdFront = (id: number) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text }
        }
      })
    )
  }
  const handleImgSizeBiIdFront = (id: number) => {
    dispatch(
      setJsonImgFront({
        ...jsonImgfront,
        [id]: {
          ...textPositionNow,
          img: jsonImgfront[id].img
        }
      })
    )
  }

  const resetIds = () => {
    dispatch(setIdTextFront(0))
    dispatch(setIdTextBack(0))
    dispatch(setImgIdBack(0))
    dispatch(setImgIdFront(0))
  }

  const downloadImage = async () => {
    if (!domEl.current) return
    const dataUrl = await htmlToImage.toPng(domEl.current)

    // download image
    const link = document.createElement('a')
    link.download = 'html-to-img.png'
    link.href = dataUrl
    link.click()
  }

  const handleDownloadImage = async () => {
    if (!domEl.current) return
    const element = domEl.current
    const canvas = await html2canvas(element)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = 'image.jpg'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }
  }

  useEffect(() => {
    if (TextBackId !== 0) handleTextSizeBiIdBack(TextBackId)
  }, [TextBackId])
  useEffect(() => {
    if (TextfrontkId !== 0) handleTextSizeBiIdFront(TextfrontkId)
  }, [TextfrontkId])

  useEffect(() => {
    if (ImgBackId !== 0) handleImgSizeBiIdBack(ImgBackId)
  }, [ImgBackId])
  useEffect(() => {
    if (ImgfrontkId !== 0) handleImgSizeBiIdFront(ImgfrontkId)
  }, [ImgfrontkId])

  useEffect(() => {
    if (!flagReset) setFlagReset(true)
    if (!isCoverOnEdith) resetIds()
  }, [isCoverOnEdith])

  useEffect(() => {
    if (!flagReset) setFlagReset(true)
  }, [jsonImgBack, jsonImgfront, jsonTextBack, jsonTextfront])

  useEffect(() => {
    if (flagReset) setFlagReset(false)
  }, [flagReset])

  return (
    <div

      className={`${style['ppp']} `}
    >
      {!flagReset && (
        <div className={`${style['dragable-grid']}`}>
          {
            (params.has('viewMode') && params.get('viewMode') == '2') && (
              <div
                ref={leftAbsolute}
                style={{backgroundColor:(spine_color.value as string)}}
                className={`${style['Position-left-draggable-vertical']}
                ${!isCoverOnEdith && style['overflow-hidden']}
                ${!isCoverOnEdith &&
                  rounded.value &&
                  style['border-left-rounded']
                  }
                `}
              >
                <DraggableCovers
                  setpos={() => {
                    // setPositionsImg(jsonImgBack, 'Back')
                    // setPositionsText(jsonTextBack, 'Back')
                  }}
                  customContainerRef={leftAbsolute}
                  jsonImgPositions={jsonImgBack}
                  jsonTextPositions={jsonTextBack}
                  IdEraseText={eraseTextBack}
                  IdEraseImg={eraseImgBack}
                  selectedIdImg={ImgBackId}
                  selectedIdText={TextBackId}
                  setImgId={(dat: number, MyInfo: IJsonParameters) => {
                    if (isCoverOnEdith) {
                      dispatch(setTextPositionNow(MyInfo))
                      dispatch(setImgIdBack(dat))
                      dispatch(setImgIdFront(0))
                      dispatch(setIdTextFront(0))
                      dispatch(setIdTextBack(0))
                    }
                  }}
                  setTextId={(dat: number, MyInfo: IJsonParameters) => {
                    if (isCoverOnEdith) {
                      dispatch(setTextPositionNow(MyInfo))
                      dispatch(setIdTextBack(dat))
                      dispatch(setIdTextFront(0))
                      dispatch(setImgIdFront(0))
                      dispatch(setImgIdBack(0))
                    }
                  }}
                  resetIds={resetIds}
                />
              </div>
            )
          }
          {
            (params.has('viewMode') && params.get('viewMode') == '1') && (
              <div
                ref={rightAbsolute}
                style={{backgroundColor:(spine_color.value as string)}}
                className={`${style['Position-right-draggable-vertical']} 
                ${!isCoverOnEdith && style['overflow-hidden']}
                ${!isCoverOnEdith &&
                  rounded.value &&
                  style['border-right-rounded']
                  }
                `}
              >
                <DraggableCovers
                  customContainerRef={rightAbsolute}
                  setpos={() => {
                    // setPositionsImg(jsonImgfront, 'Front')
                    // setPositionsText(jsonTextfront, 'Front')zj
                  }}
                  jsonImgPositions={jsonImgfront}
                  jsonTextPositions={jsonTextfront}
                  IdEraseText={eraseTextFront}
                  IdEraseImg={eraseImgFront}
                  selectedIdImg={ImgfrontkId}
                  selectedIdText={TextfrontkId}
                  setImgId={(dat: number, MyInfo: IJsonParameters) => {
                    if (isCoverOnEdith) {
                      dispatch(setTextPositionNow(MyInfo))
                      dispatch(setImgIdFront(dat))
                      dispatch(setImgIdBack(0))
                      dispatch(setIdTextBack(0))
                      dispatch(setIdTextFront(0))
                    }
                  }}
                  setTextId={(dat: number, MyInfo: IJsonParameters) => {
                    if (isCoverOnEdith) {
                      dispatch(setTextPositionNow(MyInfo))
                      dispatch(setIdTextFront(dat))
                      dispatch(setIdTextBack(0))
                      dispatch(setImgIdBack(0))
                      dispatch(setImgIdFront(0))
                    }
                  }}
                  resetIds={resetIds}
                />
              </div>
            )
          }
        </div>
      )}
    </div>
  )
}
export default VerticalDownloadDragable