import { ISchoolsApi } from 'interfaces/schools-interface'
import { AUTHSERVICE, SERVICE } from '../config'

export interface schoolAdminInterface {
  name: string
  sex: string
  workgroup?: string
  type?: string
  social_reason: string
  address: string
  phone: string
  id_magento?: string
  id_gea?: string
  id?: string | number
  opening_date?: string
  quota?:string,
  price_notebook?: string
}

export const getSchoolsAdmin = async ({
  page,
  offset = 10,
  controller,
  search = '',
  status
}: {
  page: number
  offset?: number
  controller?: AbortController
  search?: string
  status?: string
}): Promise<any> => {
  let url = '/school/list'
  let signal = {}
  if (controller) signal = { signal: controller.signal }
  if (page) url += `?page=${page}`
  if (offset) url += `&limit=${offset}`
  if (search) url += `&search=${search}`
  if (status) url += `&status=${status}`
  return await AUTHSERVICE().get(url, signal)
}

export const getSchoolsAdminById = async (
  id: string | number
): Promise<any> => {
  return await AUTHSERVICE().get(`/school/id/${id}`)
}

export const downloadSchoolList = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/school/download`)
}

export const createSchoolAdmin = async (
  data: schoolAdminInterface
): Promise<any> => {
  return await AUTHSERVICE().post('/school/', data)
}

export const negateOrAcceptRequest = async (
  data: {
    idSchool: number | string,
    accept: boolean
}
): Promise<any> => {
  return await AUTHSERVICE().put('/school/accept-or-reject-modification', data)
}

export const updateSchoolAdmin = async ({
  data
}: {
  data: schoolAdminInterface
}): Promise<any> => {
  return await AUTHSERVICE().put(`/school/`, data)
}

export const deleteSchoolAdmin = async (id: string | number): Promise<any> => {
  return await AUTHSERVICE().delete(`/school/${id}`)
}

const getSchools = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/school`)
}

export const getAllSchoolsList = async () => {
  const results: ISchoolsApi = await getSchools()
  return results
}

export const getCatalogSchools = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/school/names`)
}


export const getSchoolGroupId = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/school/group-of-collages`)
}

export const getSchoolPerssimionsList = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/roles/all-permissions`)
}