import BackButton from 'components/buttons/BackButton/BackButton'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { UtilsColumns } from '../../../constants'
import { useUtils } from 'hooks/utils/useUtils'
import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import { Table } from 'antd'
import { UtilsListInt } from 'types'
import { formatUrlNames } from 'utilities/formatUrlNames'
import SearchBarModule from 'modules/books/search/SearchBarModule'
import Button from 'components/buttons/Button/Button'
import Modal from 'antd/es/modal/Modal'
import globalStyle from '../../../styles/global.module.scss'
import SearchUtilModalBody from 'modules/Utils/SearchUtilModalBody/SearchUtilModalBody'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setCommentsObjectInformation, setopenComments } from 'redux/reducers/comments'
import { RootState } from 'redux/store/store'
const dummyUtils: UtilsListInt[] = [
  {
    name: 'string',
    brand: 'string',
    id: 0,
    quantity: '1',
    sku: 'string',
    presentation: 'string',
    price: '1251',
    image: '',
    comments: 'asd',
    status: {
      id: 3,
      label: 'Validado'
    }
  }
]

const index = memo(() => {
  const {
    utilsList,
    pagination,
    LoadingList,
    loadingModal,
    OpenSearchModal,
    allUtilsList,
    loadNewUtil,
    addUtil,
    changePage,
    handleOpenModal,
    handleCancelModal,
    onHandleSearch
  } = useUtils()
  const dispatch = useDispatch()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [downloadModal, setdownloadModal] = useState(false)

  const openDrawComments = () => {
    dispatch(setopenComments({ openComments: true }))
  }

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const navigateToCreate = () => {
    navigate(`/utiles/${params.package}/crear-util`)
  }
  const goBack = () => {
    navigate('/utiles')
  }
  return (
    <MainTemplate>
      <BackButton onClick={goBack} textDecoration='underline' />
      <br />
      <br />
      <br />
      <TitleHeader
        buttonProps={[
          {
            LeftIconName: 'downloadIcon',
            label: 'Descargar lista de útiles',
            onClick: () => setdownloadModal(true),
            rounded: true,
            sector: 'secondary',
            background: 'white',
            size: 'm'
          },
          {
            label: 'Asignar nuevo útil',
            onClick: navigateToCreate,
            rounded: true,
            sector: 'secondary',
            background: 'color',
            size: 'm'
          },
          {
            label: 'Asignar recurso',
            onClick: handleOpenModal,
            rounded: true,
            sector: 'tertiary',
            background: 'white',
            size: 'm'
          }
        ]}
        IconName='utilsIcon'
        title={location.state.packageName}
      />
      {utilsList.length > 0 ? (
        <Table
          loading={LoadingList}
          dataSource={utilsList}
          rowKey={'id'}
          columns={UtilsColumns}
          pagination={pagination}
          onChange={changePage}
          onRow={(record, rowIndex) => {
            
            return {
              onClick: () => {
                openDrawComments()
                dispatch(setCommentsObjectInformation(record))
              } // click row
            }
          }}
        />
      ) : (
        <EmptyListMessage icon='utilsIcon' text='Aún no has agregado útiles' />
      )}

      <CommentsComponent />

      <Modal
        title={'Buscar material'}
        open={OpenSearchModal}
        onOk={handleCancelModal}
        onCancel={handleCancelModal}
        confirmLoading={loadingModal}
        className={globalStyle.confirmModal}
        footer={
          <div className={globalStyle.confirmModalFooter}>
            <Button
              type='blue'
              onClick={navigateToCreate}
              rounded
              label={'Solicitar nuevo recurso'}
            />
          </div>
        }
        width={720}
      >
        <SearchUtilModalBody
          allUtilsList={allUtilsList}
          onHandleSearch={onHandleSearch}
          addUtil={addUtil}
          loadNewUtil={loadNewUtil}
        />
      </Modal>
    </MainTemplate>
  )
})
index.displayName = 'UtilsList'
export default index
