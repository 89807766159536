import React, { memo } from 'react'
import STYLE from './B.module.scss'
interface Binterface {
  variation?: '1' | '2'
  size?: 'Bold' | 'Regular' | 'Light'
  children: any
  style?:any
}
const B = memo(
  ({
    variation = '1',
    size = 'Regular',
    children,
    style
  }: Binterface) => {
    return (
      <p
        className={STYLE.B}
        style={style}
        data-variation={variation}
        data-size={size}
      >
        {children}
      </p>
    )
  }
)
B.displayName = 'Body'
export default B
