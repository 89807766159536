import { createSlice } from '@reduxjs/toolkit'

export const initialState: any = {
  quotasSchool: [],
  levelsQuotas: [],
  IDLevel: 0,
  IDGrade: [],
  newStructure: false,
  quotas:[],
  idQuotas:[],
  levelsQuotasAdmin: [],
  quotasSchoolAdmin: [],
}

export const authSlice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    setQuotasSchoolArray: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const add = (object: any) => {
        if (object) {
          if (array.filter((x: any) => x.id === object.id).length === 0) {
            array.push(object)
          }
        }
      }

      action.payload.map((item: any) => add(item))

      return {
        ...state,
        quotasSchool: [...array]
      }
    },
    setQuotasSchoolAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchoolAdmin))

      const add = (object: any) => {
        if (object) {
          if (array.filter((x: any) => x.id === object.id).length === 0) {
            array.push(object)
          }
        }
      }

      action.payload.map((item: any) => add(item))

      return {
        ...state,
        quotasSchoolAdmin: [...array]
      }
    },
    setLevelsQuotas: (state, action): any => {
      return {
        ...state,
        levelsQuotas: action.payload
      }
    },

    deleteQuota: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const arrFilter = array.filter((item: any) => item.id !== action.payload)

      return {
        ...state,
        quotasSchool: [...arrFilter]
      }
    },

    changeStatusQuotas: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const arrStatus = array.map((item: any) => {
        if (action.payload?.idGrade === item.id_grade) {
          item.status = action.payload.status
        }
        return item
      })

      return {
        ...state,
        quotasSchool: [...arrStatus]
      }
    },
    changeEndListQuotas: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const arrStatus = array.map((item: any) => {
        if (action.payload?.idGrade === item.id_grade) {
          item.status = action.payload.status
          item.close = true
        }
        return item
      })

      return {
        ...state,
        quotasSchool: [...arrStatus]
      }
    },

    updateQuotas: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const quotas = array.map((item: any) => {
        if (item.id === action.payload.id) {
          item.name = action.payload.values.name
          item.price = action.payload.values.price
        }

        return item
      })

      return {
        ...state,
        quotasSchool: [...quotas]
      }
    },

    setIDLevelQuotas: (state, action): any => {
      return {
        ...state,
        IDLevel: action.payload
      }
    },
    setIDGrade: (state, action): any => {
      return {
        ...state,
        IDGrade: action.payload
      }
    },

    updateCloseQuota: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const arrStatus = array.map((item: any) => {
        if (item.id_grade === action.payload.idGrade) {
          item.close = action.payload.close
         
        }
        return item
      })

      return {
        ...state,
        quotasSchool: [...arrStatus]
      }
    },
    updateEndList: (state, action): any => {
      try {
        const array = JSON.parse(JSON.stringify(state.levelsQuotas))

        array.map((item: any) => {
          const grade = item.grades.find(
            (i: any) => i.id === action.payload.idGrade
          )

          if (grade) {
            grade.endQuotas = action.payload.status
          }

          return null
        })

        return {
          ...state,
          levelsQuotas: array
        }
      } catch (error) {
        console.log(error)
      }
    },
    setNewStructureQuota: (state, action): any => {
      return {
        ...state,
        newStructure: action.payload
      }
    },
    setListQuotas: (state, action): any => {
      return {
        ...state,
        quotas: action.payload
      }
    },
    setIdQuotas: (state, action): any => {
      return {
        ...state,
        idQuotas: action.payload
      }
    },
    changeMultipleStatusQuotas: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.quotasSchool))

      const arrStatus = array.map((item: any) => {
        if (action.payload?.id.includes(item?.id)) {
          item.status = action.payload.status
        }
        return item
      })

      return {
        ...state,
        quotasSchool: [...arrStatus]
      }
    },
    setLevelsQuotasAdmin: (state, action): any => {
      return {
        ...state,
        levelsQuotasAdmin: action.payload
      }
    },

    updateRequestChanges: (state, action): any => {
      try {
        const array = JSON.parse(JSON.stringify(state.levelsQuotasAdmin))

        array.map((item: any) => {
          const grade = item.grades.find(
            (i: any) => i.id === action.payload.idGrade
          )

          if (grade) {
            grade.listQuotasChange  = action.payload.status
          }

          return null
        })

        return {
          ...state,
          levelsQuotasAdmin: array
        }
      } catch (error) {
        console.log(error)
      }
    },
    setInitialState: (state, action): any => {
      state = initialState
    }
  }
})

// Metodo global para actualizar el state
export const {
  setQuotasSchoolArray,
  deleteQuota,
  changeStatusQuotas,
  changeEndListQuotas,
  updateQuotas,
  setLevelsQuotas,
  setIDLevelQuotas,
  setIDGrade,
  updateEndList,
  updateCloseQuota,
  setNewStructureQuota,
  setInitialState,
  setListQuotas,
  setIdQuotas,
  changeMultipleStatusQuotas,
  setLevelsQuotasAdmin,
  setQuotasSchoolAdmin,
  updateRequestChanges,
} = authSlice.actions

export default authSlice.reducer
