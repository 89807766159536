import { useEffect, useState } from 'react'
import { SessionData, useDecodeToken } from './decodeToken/useDecodeToken'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

const useStructureFinish = () => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [structureFinish, setstructureFinish] = useState(false)
  const { terminateStructure } = useSelector(
    (state: RootState) => state.levelsAndGrades
  )
  useEffect(() => {
    const sessionStructureTerminated = sessionStorage.getItem(
      'terminateStructure'
    )
      ? true
      : false
    const finishStr =
      sessionStructureTerminated ||
      terminateStructure ||
      uid.school_status === 'Autorizado'
    setstructureFinish(finishStr)
  }, [terminateStructure])

  return { structureFinish }
}

export default useStructureFinish
