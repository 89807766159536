import React from 'react'
import { IconProps } from '../../../types'
export const NoteBook = ({
  height = 24,
  width = 24,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 3V21H19.5V3H4.5ZM3.75 1.5H20.25C20.4489 1.5 20.6397 1.57902 20.7803 1.71967C20.921 1.86032 21 2.05109 21 2.25V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H3.75C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z'
        fill='currentColor'
      />
      <path
        d='M15.75 3H17.25V21H15.75V3ZM2.25 4.5H5.25C5.75 4.5 6 4.75 6 5.25C6 5.75 5.75 6 5.25 6H2.25C1.75 6 1.5 5.75 1.5 5.25C1.5 4.75 1.75 4.5 2.25 4.5ZM2.25 9H5.25C5.75 9 6 9.25 6 9.75C6 10.25 5.75 10.5 5.25 10.5H2.25C1.75 10.5 1.5 10.25 1.5 9.75C1.5 9.25 1.75 9 2.25 9ZM2.25 13.5H5.25C5.75 13.5 6 13.75 6 14.25C6 14.75 5.75 15 5.25 15H2.25C1.75 15 1.5 14.75 1.5 14.25C1.5 13.75 1.75 13.5 2.25 13.5ZM2.25 18H5.25C5.75 18 6 18.25 6 18.75C6 19.25 5.75 19.5 5.25 19.5H2.25C1.75 19.5 1.5 19.25 1.5 18.75C1.5 18.25 1.75 18 2.25 18Z'
        fill='currentColor'
      />
    </svg>
  )
}
