import React from 'react'
import { TFormCapitonadoChalecoOptions } from 'interfaces/uniforms-interface'
import { useDispatch, useSelector } from 'react-redux'
import { handleChalecoCapitonadoForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const ChalecoCapitonadoOptions = () => {
  const { chalecoCapitonadoForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormCapitonadoChalecoOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleChalecoCapitonadoForm({
        ...chalecoCapitonadoForm,
        [keyName]: {
          ...chalecoCapitonadoForm[keyName as TFormCapitonadoChalecoOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      <ConstructorFormulary
        handleChange={handleChange}
        form={chalecoCapitonadoForm}
      />
    </div>
  )
}

export default ChalecoCapitonadoOptions
