import React from 'react'
import Button from 'components/buttons/Button/Button'
import style from './TitleHeader.module.scss'
import { AllIconsInterface } from 'types'
import Iconname from 'components/icons'
import H from 'components/texts/H/H'
import Overline from 'components/texts/Overline/Overline'

interface TitleHeader {
  title?: string
  Icon?: any
  IconProps?: any
  IconName?: AllIconsInterface
  buttonProps?: any[]
  buttonProps2?: any
  type?: 'Grade'
  gradeData?: any
  widthIcon?: any
  heightIcon?: any
  additional?: JSX.Element | string
  disabled?: boolean
  variaton?: '3' | '1' | '2' | '4'
}
const TitleHeader = ({
  title = 'vacio',
  Icon,
  IconProps,
  IconName,
  buttonProps,
  type,
  gradeData,
  buttonProps2,
  additional,
  widthIcon,
  heightIcon,
  disabled = false,
  variaton = '3'
}: TitleHeader) => {
  if (type)
    return (
      <div className={style.gradeTitle}>
        <div className={style.gradeInfo}>
          <div className={style.Grade}>
            {IconName && (
              <Iconname name={IconName} width={widthIcon} height={heightIcon} />
            )}
            <div className={style.data}>
              <H variation='3'>{gradeData?.name}</H>
              <Overline size='Light'>{gradeData?.levelName}</Overline>
            </div>
          </div>
          {gradeData?.male_population && (
            <div className={style.Grade}>
              <Iconname name='gradeBoy' />
              <div className={style.data}>
                <H variation='3'>{gradeData?.male_population}</H>
                <Overline size='Light'>Hombres</Overline>
              </div>
            </div>
          )}
          {gradeData?.female_population && (
            <div className={style.Grade}>
              <Iconname name='gradeGirl' />
              <div className={style.data}>
                <H variation='3'>{gradeData?.female_population}</H>
                <Overline size='Light'>Mujeres</Overline>
              </div>
            </div>
          )}
        </div>
        <div className={style.buttonContainer}>
          {buttonProps &&
            buttonProps.map((res: any, key: number) => (
              <Button disabled={disabled} key={key} {...res} />
            ))}
        </div>
      </div>
    )
  return (
    <div className={`${style['container-Title']}`}>
      <div className={`${style['div-Title']}`}>
        {Icon && <Icon {...IconProps} />}
        {IconName && (
          <Iconname width={widthIcon} height={heightIcon} name={IconName} />
        )}
        <H variation={variaton}>{title}</H> {additional}
      </div>
      <div className={style.buttonContainer}>
        {buttonProps &&
          buttonProps.map((res: any, key: number) => (
            <Button disabled={disabled} key={key} {...res} />
          ))}
      </div>
    </div>
  )
}

export default TitleHeader
