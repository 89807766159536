import Icon from 'components/icons'
import styles from './picazoBackground.module.scss'

type Props = {
  children: JSX.Element
}

const PicazoBackground = ({ children }: Props) => {
  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <>
          <Icon name='logo' />
          {children}
        </>
      </div>
    </div>
  )
}

export default PicazoBackground
