import React, { Fragment, useState, useEffect } from 'react'
import style from './notebookoptions.module.scss'
import {
  INotebookInsideForm,
  TFormNotebookInsideOptions
} from 'interfaces/notebooks-interface'
import SelectOption from 'modules/uniforms/components/selectOption/SelectOption'
import SelectedCardsOptionsHOC from 'modules/uniforms/editor/components/selectCardOption/SelectedCardsOptionsHOC'
import { useDispatch, useSelector } from 'react-redux'
import { handleNotebookInsideForm } from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import { scrollToByIdSmooth } from 'utilities/scroll-utilities'

import ModalUploadNotebooks from 'modules/uniforms/editor/components/selectCardOption/ModalUploadNotebooks'
import { IEdithNotebook } from 'services/notebooks/putNotebook'
import { UpdateNotebookInsideLogo } from 'services/notebooks/update-img'
import { ErrorToast } from 'components/Toasts/Toasts'
import ModalWaitImg from 'modules/uniforms/editor/components/selectCardOption/ModalWaitImg'
const NotebookOptionsInsideHidden = ({
  uuid,
  saveChange,
  onlySave
}: {
  uuid: number
  onlySave: (fn?: () => void) => void
  saveChange: (
    data: IEdithNotebook,
    showToast?: boolean | undefined,
    ejecute?: (() => void) | undefined
  ) => void
}) => {
  const { notebookInsideValues, generalOptionsNotebookInside, insideLogo } =
    useSelector((state: RootState) => state.notebooks)

  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [numberSheets, setNumberSheets] = useState(false)
  const [stripesOpacity, setStripesOpacity] = useState(false)
  const [roundedOpacity, setRoundedOpacity] = useState(false)
  const [marginComplete, setMarginComplete] = useState(false)
  const [guidePoint, setGuidePoint] = useState(false)
  const [logoPosition, setLogoPosition] = useState(false)
  const [marginDate, setMarginDate] = useState(false)
  const [dateType, setDateType] = useState(false)

  const {
    number_of_sheets,
    striped,
    rounded,
    magin_complete,
    guide_point,
    logo_position,
    margin_date,
    date_type,
    interior_inks
  } = notebookInsideValues

  const handleChange = (
    keyName: TFormNotebookInsideOptions,
    value: string | boolean
  ) => {
    if (keyName === 'interior_inks') {
      handleInk(value as string)
    }
    dispatch(
      handleNotebookInsideForm({
        ...notebookInsideValues,
        [keyName]: { ...notebookInsideValues[keyName], value: value }
      })
    )
  }
  const handleInk = (value: string) => {
    if (insideLogo !== null && insideLogo.includes('https://')) {
      setWait(true)
      onlySave(() => {
        switch (value) {
          case '#003da6':
            UpdateNotebookInsideLogo(uuid, { color: 2 })
              .then((data) => {
                saveChange(
                  {
                    int_interiorinks: value,
                    int_logo: data.data.url
                  },
                  false,
                  () => setWait(false)
                )
              })
              .catch((e) => {
                console.log(e)
                ErrorToast(
                  'No se pudo cambiar el color a escala de azul, tinta:"2x2"'
                )
                setWait(false)
              })
            break
          case 'black':
            UpdateNotebookInsideLogo(uuid, { color: 1 })
              .then((data) => {
                saveChange(
                  {
                    int_interiorinks: value,
                    int_logo: data.data.url
                  },
                  false,
                  () => setWait(false)
                )
              })
              .catch((e) => {
                console.log(e)
                ErrorToast(
                  'No se pudo cambiar el color a escala de negro, tinta:"1x1"'
                )
                setWait(false)
              })

            break

          default:
            break
        }
      })
    }
  }
  const NotebookForm = ({ objInput }: { objInput: INotebookInsideForm }) => {
    return (
      <div className={`${style['div-input-container']}`}>
        <SelectedCardsOptionsHOC
          onClick={showModal}
          name={objInput.name}
          title={objInput.label}
        >
          {generalOptionsNotebookInside[objInput.type].map(
            ({ name, description, option }, index: number) => {
              const getLabel = () => {
                let label = name
                if (
                  objInput.label === 'Margen completo' &&
                  name === 'Si' &&
                  striped.value === 'blanco'
                ) {
                  label = 'Sin_margen'
                }
                return label
              }
              return (
                <Fragment key={index}>
                  <SelectOption
                    toolTipLabel={description}
                    label={getLabel()}
                    selected={option.value === objInput.value}
                    picture={option.picture}
                    background={option.value as string}
                    help={objInput.showHelp}
                    onClick={() =>
                      handleChange(
                        objInput.name as TFormNotebookInsideOptions,
                        option.value as string | boolean
                      )
                    }
                  />
                </Fragment>
              )
            }
          )}
        </SelectedCardsOptionsHOC>
      </div>
    )
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    onlySave()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    saveChange({}, false)
  }

  useEffect(() => {
    const handleScroll = (id: string) => {
      if (
        !(
          number_of_sheets.value !== '' &&
          striped.value !== '' &&
          rounded.value !== '' &&
          magin_complete.value !== '' &&
          guide_point.value !== '' &&
          logo_position.value !== '' &&
          margin_date.value !== '' &&
          date_type.value !== '' &&
          interior_inks.value !== ''
        )
      )
        scrollToByIdSmooth(id)
    }

    if (number_of_sheets.value !== '') {
      setNumberSheets(true)
      handleScroll('striped')
    }
    if (striped.value !== '') {
      setStripesOpacity(true)
      handleScroll('rounded')
    }
    if (rounded.value !== '') {
      setRoundedOpacity(true)
      handleScroll('magin_complete')
    }
    if (magin_complete.value !== '') {
      setMarginComplete(true)
      handleScroll('guide_point')
    }
    if (guide_point.value !== '') {
      setGuidePoint(true)
      handleScroll('logo_position')
    }
    if (logo_position.value !== '') {
      setLogoPosition(true)
      handleScroll('margin_date')
    }
    if (margin_date.value !== '') {
      setMarginDate(true)
      handleScroll('date_type')
    }
    if (date_type.value !== '') {
      setDateType(true)
      handleScroll('interior_inks')
    }
  }, [
    number_of_sheets,
    striped,
    rounded,
    magin_complete,
    guide_point,
    logo_position,
    margin_date,
    date_type,
    interior_inks
  ])
  useEffect(() => {
    if (
      (striped.value === 'Pautado' || striped.value === 'blanco') &&
      magin_complete.value !== ''
    ) {
      dispatch(
        handleNotebookInsideForm({
          ...notebookInsideValues,
          guide_point: {
            ...notebookInsideValues['guide_point'],
            value: 'sinGuia'
          }
        })
      )
    }
  }, [magin_complete])

  const [wait, setWait] = useState(false)
  return (
    <div>
      {(() => {
        return (
          <div id='number_of_sheets'>
            <NotebookForm objInput={number_of_sheets} />
          </div>
        )
      })()}
      {(() => {
        let showme = number_of_sheets.value !== ''
        return (
          <div
            id='striped'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${numberSheets && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={striped} />
          </div>
        )
      })()}
      {(() => {
        let showme = striped.value !== ''
        return (
          <div
            id='rounded'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${stripesOpacity && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={rounded} />
          </div>
        )
      })()}
      {(() => {
        let showme = rounded.value !== ''
        return (
          <div
            id='magin_complete'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${roundedOpacity && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={magin_complete} />
          </div>
        )
      })()}
      {(() => {
        let showme = magin_complete.value !== ''
        return (
          <div
            id='guide_point'
            className={`${style['durarion']} 
            ${
              striped.value !== 'blanco' &&
              striped.value !== 'Pautado' &&
              showme &&
              style['display-show']
            }
            ${marginComplete && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={guide_point} />
          </div>
        )
      })()}
      {(() => {
        let showme = guide_point.value !== ''
        return (
          <div
            id='logo_position'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${guidePoint && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={logo_position} />
          </div>
        )
      })()}
      {(() => {
        let showme = logo_position.value !== ''
        return (
          <div
            id='margin_date'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${logoPosition && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={margin_date} />
          </div>
        )
      })()}
      {(() => {
        let showme = margin_date.value !== ''
        return (
          <div
            id='date_type'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${marginDate && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={date_type} />
          </div>
        )
      })()}
      {(() => {
        let showme = date_type.value !== ''
        return (
          <div
            id='interior_inks'
            className={`${style['durarion']} 
            ${showme && style['display-show']}
            ${dateType && style['display-full-opacity']}`}
          >
            <NotebookForm objInput={interior_inks} />
          </div>
        )
      })()}

      <ModalUploadNotebooks
        isModalOpen={isModalOpen}
        onlySave={onlySave}
        handleOk={handleOk}
        handleCancel={handleCancel}
        uuid={uuid}
        saveChange={saveChange}
      />
      <ModalWaitImg isModalOpen={wait} />
    </div>
  )
}

export default NotebookOptionsInsideHidden
