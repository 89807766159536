import React from 'react'
import { useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

const useIsAdmin = () => {
    const { globalRoles } = useSelector(
      (state: RootState) => state.userManagement
    )
    const { dataToken } = useDecodeToken()
  
    return dataToken().uid.role === globalRoles[1].label
  }

export default useIsAdmin






