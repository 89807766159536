import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { SchoolAdminListInt } from 'types'
import {
  InitialPagination,
  Pagination,
  restructurePagination
} from 'utilities/restructurePagination'
import { handleErrors } from 'utilities/handleErrors'
import {
  addUtilToPackageById,
  deleteUtilFromPackage,
  getUtilsByPackageId,
  getUtilsPaginated,
  openPackageUpdate
} from 'services/utilsServices/utils'

const INITIAL_PAGINATION_INFO = {
  supplies: [],
  total: 0,
  page: 1,
  limit: 10,
  totalPages: 0
}

export function usePackageItemList(listPackage: any) {
  const [itemsList, setItemsList] = useState<SchoolAdminListInt[]>([])
  const [LoadingList, setLoadingList] = useState(true)
  const [pagination, setPagination] = useState<Pagination>(InitialPagination)

  const [OpenSearchModal, setOpenSearchModal] = useState(false)
  const [loadingModal, setloadingModal] = useState(false)
  const [loadNewUtil, setloadNewUtil] = useState(false)

  const [searchValue, setsearchValue] = useState('')
  const [allUtilsList, setAllUtilsList] = useState<any>([])
  const [actualPageModal, setActualPageModal] = useState(1)
  const [modalPagination, setmodalPagination] = useState(
    INITIAL_PAGINATION_INFO
  )

  useEffect(() => {
    if (!OpenSearchModal) return
    handleGetAllUtilsList(actualPageModal, searchValue, '4')
  }, [actualPageModal, searchValue, OpenSearchModal])

  const changePage = (id: string, e: any) => {
    setLoadingList(true)
    getItemsList(id, e.current)
  }

  const getItemsList = async (
    id: string,
    page: number,
    controller?: AbortController
  ) => {
    try {
      const result = await getUtilsByPackageId(id)
      setItemsList(result.data.details)
      setPagination(restructurePagination(result.data.data))
      setLoadingList(false)
    } catch (err: any) {
      setLoadingList(false)
      handleErrors(err)
    }
  }

  const handleGetAllUtilsList = async (
    page: number,
    search: string,
    status: any
  ) => {
    try {
      const result = await getUtilsPaginated(page, search, status)
      setmodalPagination(result.data.data)
      setAllUtilsList((prev: any) => [...prev, ...result.data.data.supplies])
      setLoadingList(false)
    } catch (err: any) {
      setLoadingList(false)
      handleErrors(err)
    }
  }

  const handleOpenModal = () => {
    setOpenSearchModal(true)
  }

  const handleCancelModal = () => {
    const button = document.getElementById('reset-pagination-util')
    button?.click()
    setmodalPagination(INITIAL_PAGINATION_INFO)
    setAllUtilsList([])
    setOpenSearchModal(false)
  }

  const onHandleSearch = async (value: string): Promise<any> => {
    setAllUtilsList([])
    setsearchValue(value)
    setActualPageModal(1)
  }

  const addUtil = async (util: any, packageData: any) => {
    setloadNewUtil(true)
    const data = {
      id_supply_package: packageData.id,
      id_supply: util.id,
      supply_package_quantity: 1
    }
    const getMoreItemsB = document.getElementById(
      `get-items-list-${packageData.id}`
    )
    const exist = listPackage.filter((item: any) => item.id === util.id)
    if (exist.length > 0){
      setloadNewUtil(false)
      return ErrorToast(
        'Ud ya posee este producto en su paquete, le recomendamos que aumente la cantidad del mismo o agregue otro producto.'
      )
    }
    try {
      await addUtilToPackageById(data)
      SuccessToast('El Útil fue agregado a este paquete satisfactoriamente')
      handleCancelModal()
      getMoreItemsB?.click()
    } catch (err: any) {
      handleErrors(err)
    }finally{
    setloadNewUtil(false)

    }
  }

  const removeItemFromPackage = async (item: any, packageData: any) => {
    const id = item.id_supply_package
    const getMoreItemsB = document.getElementById(
      `get-items-list-${packageData.id}`
    )
    const button = document.getElementById('get-packages-again')
    try {
       const utilsOnPkg = await getUtilsByPackageId(packageData.id)
      await deleteUtilFromPackage(id)
      if(utilsOnPkg.data.details.length===1) await openPackageUpdate(packageData.id)
      SuccessToast('El Útil fue removido satisfactoriamente de la lista')
      getMoreItemsB?.click()
      button?.click()

    } catch (err: any) {
      handleErrors(err)
    }
  }

  return {
    itemsList,
    pagination,
    LoadingList,
    loadingModal,
    OpenSearchModal,
    allUtilsList,
    modalPagination,
    actualPageModal,
    loadNewUtil,
    setActualPageModal,
    getItemsList,
    addUtil,
    changePage,
    handleOpenModal,
    handleCancelModal,
    onHandleSearch,
    removeItemFromPackage
  }
}
