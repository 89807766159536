import React from 'react'

const SacoTraseroSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      stroke='black'
      className={className}
    >
      <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_saco" data-name="Outline saco"   d="m491.89,761.99c-16.17.29-141.62,4.35-182.82.05-24.77-2.58-97.57-15.32-97.57-15.32.9-51.84,9.84-173.94,21.11-273.13,3.72-32.73-2.03-93.5-5.31-135.22-3.22-40.87-17.58-85.41-24.11-124.57-8.76-52.56-24.28-76.03-30.41-95.22,0,0,127.19-33.25,135.65-50.08,21.66-43.05,182.62-32.37,195.41,0,4.66,11.8,123.47,46.21,123.47,46.21-13.33,33.73-27.75,72.04-35.65,118.1-5.9,34.36-7.98,61.99-9.18,92.84-1.68,43.55-11.16,101.16-7.14,142.78,8.34,86.38,21.03,145.35,20.57,278.79,0,0-87.86,14.49-104.02,14.78Z"/>
      <polyline id="Linea_enmedio" data-name="Linea enmedio"   points="397.68 89.69 397.68 589.79 397.68 764"/>
      <path id="Manga_derecha" data-name="Manga derecha"   d="m627.27,114.02s16.21-2.78,26.48,53.75c10.27,56.53,21.38,164.06,24.54,256.14,3.16,92.07,8.63,303.55,8.63,303.55-31.17-2.36-56.94-3.33-86.17,1.45,0,0-4.65-153.28-9.39-220.31-4.74-67.03-10.27-183-5.72-233.48,2.07-23,7.37-51.32,14.04-77.79,7.96-31.61,17.71-60.5,27.6-83.29Z"/>
      <path id="Manga_izquierda" data-name="Manga izquierda"   d="m172.79,118.56s-16.27-2.77-26.53,53.76c-10.27,56.54-18.16,165.56-21.33,257.64-3.16,92.07-11.84,302.05-11.84,302.05,31.17-2.36,54.52-2.35,83.75,2.42,0,0,7.06-154.25,11.81-221.29,4.74-67.03,10.26-183,5.72-233.48-2.07-23-8.2-58.69-14.86-85.16-7.96-31.61-16.83-53.15-26.71-75.94Z"/>
      <path id="Lines_manga_izqueirda" data-name="Lines manga izqueirda"   d="m204,213c-30.41-10.7-39.42,233.59-39.42,517.54"/>
      <path id="Linea_manga_derecha" data-name="Linea manga derecha"   d="m643.06,725.51c0-275.13-15.74-492.71-51.39-492.71"/>
      <path id="Cuello"   d="m306.93,88.09s29.95,16.91,79.15,16.88c42.94-.03,42.22-.34,42.22-.34,0,0,82.32-6.52,95.59-24.45,0,0-25.9-21.12-30.03-31.03-3.79-9.08-55.36-12.46-88.14-13.09-32.78-.63-70.35,4.17-84.85,11.11l-28.49,32.37,14.54,8.56Z"/>
      <g id="Botones">
        <g>
          <path   d="m159.37,703.66c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="152.71" y1="696.96" x2="152.67" y2="710.5"/>
            <g>
              <polygon   points="152.71 696.96 152.87 697.06 152.55 697.06 152.71 696.96"/>
              <path   d="m153.19,697.26c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="153.75 697.66 154.01 697.86 151.4 697.86 151.66 697.66 153.75 697.66"/>
              <polygon   points="154.25 698.06 154.47 698.26 150.94 698.25 151.16 698.05 154.25 698.06"/>
              <polygon   points="154.69 698.46 154.9 698.66 150.51 698.65 150.73 698.45 154.69 698.46"/>
              <polygon   points="155.09 698.86 155.28 699.06 150.13 699.05 150.31 698.85 155.09 698.86"/>
              <polygon   points="155.45 699.26 155.61 699.46 149.8 699.44 149.96 699.25 155.45 699.26"/>
              <polygon   points="155.77 699.66 155.9 699.86 149.5 699.84 149.64 699.64 155.77 699.66"/>
              <path   d="m156.04,700.06c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="156.28 700.46 156.39 700.66 149.01 700.64 149.12 700.44 156.28 700.46"/>
              <polygon   points="156.5 700.86 156.59 701.06 148.8 701.03 148.89 700.84 156.5 700.86"/>
              <polygon   points="156.68 701.26 156.76 701.46 148.63 701.43 148.71 701.23 156.68 701.26"/>
              <polygon   points="156.83 701.66 156.9 701.85 148.49 701.83 148.56 701.63 156.83 701.66"/>
              <path   d="m156.96,702.05c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m157.05,702.45c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m157.12,702.85c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m157.17,703.25c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m157.19,703.65c0,.07,0,.13,0,.2l-9-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m157.18,704.05c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m157.15,704.44c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m157.09,704.84c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m157.01,705.24c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="156.9 705.64 156.83 705.84 148.54 705.81 148.47 705.61 156.9 705.64"/>
              <polygon   points="156.76 706.04 156.68 706.23 148.69 706.21 148.6 706.01 156.76 706.04"/>
              <polygon   points="156.59 706.43 156.5 706.63 148.87 706.61 148.77 706.41 156.59 706.43"/>
              <polygon   points="156.39 706.83 156.27 707.03 149.09 707.01 148.97 706.81 156.39 706.83"/>
              <path   d="m156.16,707.23c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="155.89 707.63 155.76 707.82 149.6 707.81 149.46 707.61 155.89 707.63"/>
              <polygon   points="155.59 708.02 155.43 708.22 149.92 708.21 149.76 708.01 155.59 708.02"/>
              <polygon   points="155.26 708.42 155.08 708.62 150.27 708.6 150.09 708.4 155.26 708.42"/>
              <polygon   points="154.88 708.82 154.66 709.02 150.68 709 150.46 708.8 154.88 708.82"/>
              <polygon   points="154.44 709.21 154.22 709.41 151.12 709.4 150.9 709.2 154.44 709.21"/>
              <polygon   points="153.99 709.61 153.72 709.81 151.62 709.8 151.36 709.6 153.99 709.61"/>
              <path   d="m153.45,710.01c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="152.83 710.4 152.67 710.5 152.51 710.4 152.83 710.4"/>
            </g>
          </g>
          <path   d="m159.37,703.66c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m148.37,706.55l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.04.04,0,.02.01.05.03.09l.06.12-.09.05c-.04-.08-.1-.16-.15-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04s.07.08.09.09c.02,0,.06,0,.11-.01.17-.03.34-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.18-.14.2-.01.03-.02.06-.01.08,0,.02.02.07.06.12l-.08.06c-.06-.1-.12-.2-.19-.29-.06-.08-.13-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.04,0,.05,0,.01-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.4.57.59-.83-.98.26Z"/>
            <path   d="m149.24,708.13c.05.05.09.1.14.15.09.09.17.16.23.21.05.03.09.06.14.09l.27-.18.05.05c-.13.11-.25.21-.36.3-.05-.04-.12-.1-.2-.17l-.36-.37s-.13-.12-.24-.21l.05-.05c.06.02.11.02.14,0,.03-.02.14-.11.3-.29l.51-.52c.19-.19.29-.3.3-.33.02-.03,0-.06-.03-.1l-.09-.08.06-.06c.12.13.2.21.24.25.05.05.12.12.22.21l-.06.06-.09-.09s-.05-.03-.08-.02c-.03.02-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m151.99,707.78c-.05.12-.1.25-.15.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.12-.05-.21-.09-.29-.12-.09-.03-.19-.06-.3-.1l.03-.08.08.03s.08.02.1.01c.02,0,.04-.04.06-.08.04-.1.09-.22.15-.37l.26-.71c.05-.14.1-.27.14-.39l-.11-.04c-.16-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.02.06-.15c.02-.06.05-.14.08-.26.36.14.57.23.64.25l.56.2c-.07.13-.12.27-.17.39l-.07-.02.06-.17c.02-.06.02-.09.02-.11,0-.01-.02-.03-.04-.04-.07-.04-.15-.07-.25-.1l-.08-.03Z"/>
            <path   d="m153.42,709.65h.07c.07-.02.1-.04.11-.09,0-.04-.01-.23-.06-.57l-.06-.44c-.05-.35-.08-.54-.1-.57-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12-.01.24-.02.35-.04.09-.01.18-.03.26-.05v.08s-.07.01-.07.01c-.05,0-.08.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.06.5l.07.45c.05.34.08.53.09.56.02.04.06.04.12.04h.07s.01.07.01.07c-.09,0-.19.02-.29.03-.12.02-.23.03-.32.05l-.02-.08Z"/>
            <path   d="m154.28,707.8l-.04-.07c.12-.08.21-.14.27-.18.06-.04.14-.1.24-.19l.05.07-.07.04c-.06.04-.08.07-.07.11,0,.04.08.15.23.36l.3.42c.11.15.2.26.25.31.06.05.13.08.22.08s.18-.03.27-.1c.1-.07.16-.15.2-.25.02-.07.02-.14,0-.22-.03-.08-.11-.21-.23-.39l-.29-.41c-.14-.19-.22-.29-.25-.3-.03-.02-.08,0-.14.04l-.05.03-.05-.07c.13-.08.2-.14.23-.16.05-.04.12-.09.2-.15l.05.07-.06.04s-.07.07-.05.11c0,.04.08.15.21.33l.29.4c.13.18.21.31.25.39.03.08.04.15.04.23s-.02.16-.07.23c-.04.08-.11.15-.19.21-.09.07-.18.11-.27.13-.09.03-.16.03-.23.02-.06-.02-.12-.05-.18-.09-.06-.05-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.25-.33-.03-.02-.07,0-.12.03l-.07.05Z"/>
            <path   d="m156.9,705.79l-.04.06c-.1-.05-.18-.08-.24-.09-.05-.01-.11,0-.15.01-.05.02-.09.06-.12.1-.05.06-.06.13-.04.21.02.07.07.13.14.18.06.04.12.06.19.06.07,0,.18-.04.32-.11.16-.07.28-.11.38-.1.11.01.2.05.3.11.08.06.15.12.2.21.05.08.07.17.07.26,0,.09-.03.18-.08.26-.04.05-.08.1-.13.14-.05.05-.11.09-.19.14-.1-.08-.23-.18-.4-.29l.04-.07c.12.06.21.09.26.1.06.01.12.01.17-.01.05-.02.1-.06.13-.1.04-.06.06-.14.04-.22-.02-.08-.07-.15-.15-.21-.07-.05-.14-.08-.22-.08-.08,0-.19.03-.34.1-.15.08-.27.11-.36.1-.09,0-.17-.03-.26-.09-.08-.05-.14-.12-.18-.19-.05-.08-.07-.16-.07-.25,0-.09.02-.17.07-.23.06-.08.15-.16.28-.24.1.07.16.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m147.71,700.92l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.19.04-.05.03-.1.08-.12.14-.04.08-.04.16,0,.25.04.08.11.14.2.19.08.04.16.05.24.04.08,0,.2-.07.37-.19.17-.11.31-.17.44-.18.12-.01.25.02.37.07.11.06.2.13.27.22.08.09.12.19.14.3.01.11,0,.22-.05.31-.03.06-.07.13-.12.19-.05.06-.12.13-.21.2-.13-.07-.3-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.13-.01.2-.04.06-.03.11-.08.14-.14.04-.09.04-.17.01-.26-.04-.1-.11-.17-.22-.23-.09-.05-.19-.06-.28-.05-.09,0-.22.07-.39.18-.16.11-.3.17-.4.18-.11.01-.22,0-.33-.06-.1-.05-.18-.11-.25-.2-.07-.09-.11-.19-.12-.29-.02-.1,0-.2.04-.29.05-.11.15-.22.29-.33.14.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m149.28,700l.17-.17c.06-.07.1-.11.11-.13,0-.02,0-.04,0-.06-.02-.02-.04-.07-.1-.15,0,0-.02-.02-.03-.04l.04-.05c.1.11.17.19.23.25.06.06.14.13.24.23l-.05.05c-.11-.08-.16-.12-.18-.13-.02-.01-.04-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.02-.03.02-.06.01-.09,0-.03-.04-.11-.08-.24,0-.01-.01-.03-.02-.06l.05-.05s.1.17.26.44c-.18.16-.34.31-.48.45-.09.1-.2.21-.32.33l-.05-.05.08-.14s.02-.04.02-.05c0-.02-.02-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.02-.1.04l-.1.05-.05-.06c.1-.08.21-.18.33-.3.07-.07.23-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.17-.11-.21-.13-.03-.01-.05-.01-.07,0-.03,0-.06.04-.12.09l-.28.28.29.3.18.19Z"/>
            <path   d="m152.23,697.93l-.2.86c-.08.32-.13.6-.18.84h-.04c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.04.24.06.37.08.4.02.03.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.02-.04,0-.13.02-.26.05l-.02-.08h.07s.08-.03.09-.05.02-.05.02-.09c0-.12-.02-.26-.04-.4l-.1-.75c-.02-.18-.04-.29-.06-.35,0-.03-.02-.05-.04-.06-.02-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13,0,.2-.02.23-.02.04-.01.1-.02.17-.03.12.22.25.47.41.73l.38.67.16-.7c.06-.24.12-.54.2-.88.09,0,.15-.01.19-.02.05,0,.12-.02.23-.04v.08s-.06.01-.06.01c-.06,0-.1.03-.11.06,0,.03,0,.12.03.29l.13.96c.03.21.05.33.07.36.02.03.06.04.13.03h.06s.01.07.01.07c-.1.01-.19.02-.28.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.06,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m154.54,698.17c-.06.11-.12.23-.19.36l-.35.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.07.03-.04.07c-.11-.06-.19-.11-.26-.15-.06-.03-.16-.08-.31-.14l.04-.07.09.04s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.18-.34l.32-.63.13-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.09l-.05-.03.04-.07c.08.04.18.1.3.16.07.04.15.08.24.11.06.03.1.05.13.07.15.08.25.17.28.27.04.1.02.22-.04.33-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1-.1.01-.19,0-.27-.05-.04-.02-.08-.05-.13-.09l.03-.1c.05.05.09.08.13.1.09.04.18.04.26,0,.09-.04.15-.12.22-.23.05-.11.07-.21.05-.3-.02-.09-.09-.16-.2-.22-.07-.03-.14-.05-.23-.06Z"/>
            <path   d="m155.76,699.65l.19.15c.07.06.11.1.13.1.02,0,.03,0,.05,0,.03-.01.07-.05.14-.11.02,0,.03-.02.04-.03l.06.04c-.1.1-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.17.11-.19,0-.02,0-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.5.59.32.27.03.02s.07.06.09.06c.03,0,.06,0,.09,0,.04,0,.11-.04.23-.1,0-.01.03-.02.05-.03l.06.04s-.16.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.23-.19-.36-.29l.05-.06.15.07s.04.01.05,0c.02,0,.05-.03.08-.07l.02-.02.2-.24s.12-.13.23-.27c.16-.19.28-.34.36-.42.08-.09.12-.14.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.1.05-.06c.09.09.2.19.33.3.07.06.25.2.52.42-.1.12-.19.24-.27.36l-.05-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.03-.04-.06-.1-.11l-.3-.26-.28.32-.18.2Z"/>
            <path   d="m157.23,701.6c-.06.12-.13.28-.2.46l-.15.36c-.02.07-.04.1-.04.12,0,.01,0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.1-.08-.13s-.05-.07-.09-.1l.29-.68c.05-.13.1-.26.14-.38l.04-.02s.04.06.05.08c.07.1.16.15.26.16.1.01.2-.02.3-.09.09-.07.15-.14.16-.23.02-.08,0-.17-.07-.25-.04-.06-.1-.11-.18-.15-.12.07-.24.16-.37.25l-.51.38c-.26.18-.39.29-.41.32-.02.03-.02.07.03.12l.05.07-.07.05c-.06-.1-.12-.18-.16-.24-.04-.05-.11-.14-.2-.27l.07-.05.05.06c.04.05.07.07.11.06.03-.01.17-.11.43-.29l.52-.37c.22-.17.34-.26.36-.29s0-.07-.04-.13l-.04-.05.07-.05c.08.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.17.07.1.11.19.13.26,0,.07,0,.14-.03.2-.03.06-.08.12-.15.17-.1.07-.21.11-.34.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
        <g>
          <path   d="m159.69,668.08c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="153.03" y1="661.38" x2="152.99" y2="674.92"/>
            <g>
              <polygon   points="153.03 661.38 153.19 661.48 152.87 661.48 153.03 661.38"/>
              <path   d="m153.51,661.68c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="154.07 662.08 154.33 662.28 151.72 662.27 151.98 662.07 154.07 662.08"/>
              <polygon   points="154.57 662.48 154.79 662.68 151.26 662.67 151.48 662.47 154.57 662.48"/>
              <polygon   points="155.01 662.88 155.22 663.08 150.83 663.06 151.05 662.87 155.01 662.88"/>
              <polygon   points="155.41 663.28 155.6 663.48 150.45 663.46 150.63 663.26 155.41 663.28"/>
              <polygon   points="155.77 663.68 155.93 663.88 150.12 663.86 150.28 663.66 155.77 663.68"/>
              <polygon   points="156.09 664.08 156.22 664.28 149.82 664.26 149.96 664.06 156.09 664.08"/>
              <path   d="m156.36,664.48c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="156.6 664.87 156.71 665.07 149.33 665.05 149.44 664.85 156.6 664.87"/>
              <polygon   points="156.82 665.27 156.91 665.47 149.12 665.45 149.21 665.25 156.82 665.27"/>
              <polygon   points="157 665.67 157.08 665.87 148.95 665.85 149.03 665.65 157 665.67"/>
              <polygon   points="157.15 666.07 157.22 666.27 148.81 666.24 148.88 666.05 157.15 666.07"/>
              <path   d="m157.28,666.47c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m157.37,666.87c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m157.44,667.27c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m157.49,667.66c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m157.51,668.06c0,.07,0,.13,0,.2l-8.99-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m157.5,668.46c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m157.47,668.86c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m157.41,669.26c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m157.33,669.65c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="157.22 670.05 157.15 670.25 148.85 670.23 148.79 670.03 157.22 670.05"/>
              <polygon   points="157.08 670.45 157 670.65 149.01 670.63 148.92 670.43 157.08 670.45"/>
              <polygon   points="156.91 670.85 156.82 671.05 149.19 671.02 149.09 670.82 156.91 670.85"/>
              <polygon   points="156.71 671.25 156.59 671.44 149.41 671.42 149.29 671.22 156.71 671.25"/>
              <path   d="m156.48,671.64c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="156.21 672.04 156.08 672.24 149.92 672.22 149.78 672.02 156.21 672.04"/>
              <polygon   points="155.91 672.44 155.75 672.64 150.24 672.62 150.08 672.42 155.91 672.44"/>
              <polygon   points="155.58 672.84 155.4 673.03 150.59 673.02 150.41 672.82 155.58 672.84"/>
              <polygon   points="155.2 673.23 154.98 673.43 151 673.42 150.78 673.22 155.2 673.23"/>
              <polygon   points="154.76 673.63 154.54 673.83 151.44 673.82 151.22 673.62 154.76 673.63"/>
              <polygon   points="154.31 674.03 154.04 674.22 151.94 674.22 151.68 674.02 154.31 674.03"/>
              <path   d="m153.77,674.42c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="153.15 674.82 152.99 674.92 152.83 674.82 153.15 674.82"/>
            </g>
          </g>
          <path   d="m159.69,668.08c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m148.69,670.96l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.03.04,0,.02,0,.05.03.09l.06.12-.09.05c-.05-.07-.1-.16-.16-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04c.04.05.07.08.09.09.03,0,.06,0,.12,0,.16-.03.33-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.18-.14.2-.01.03-.02.06-.01.08,0,.03.02.07.06.13l-.08.06c-.06-.1-.12-.2-.18-.29-.06-.08-.14-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.03,0,.05,0,.02-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.39.57.59-.83-.98.26Z"/>
            <path   d="m149.57,672.55c.05.05.09.1.14.15.09.09.18.16.23.21.05.03.1.06.15.09l.26-.18.05.05c-.13.11-.25.21-.36.3-.06-.04-.12-.09-.2-.17l-.36-.37c-.05-.05-.13-.12-.24-.21l.05-.05c.06.02.11.01.14,0,.03-.02.13-.12.3-.29l.51-.52c.19-.19.29-.3.3-.33.01-.03,0-.06-.03-.09l-.09-.08.06-.06c.12.13.19.21.23.25.06.05.13.12.22.21l-.06.06-.09-.09s-.06-.03-.08-.02c-.03.02-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m152.31,672.19c-.06.12-.11.25-.16.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.11-.05-.21-.09-.3-.12-.08-.03-.18-.07-.3-.1l.03-.08.09.03s.08.02.09.01c.02-.01.04-.04.06-.08.04-.09.09-.22.15-.37l.26-.71c.05-.14.1-.27.14-.39l-.11-.04c-.16-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.03.05-.14c.02-.06.05-.15.08-.27.36.14.57.23.64.25l.56.2c-.06.13-.12.27-.17.39l-.07-.02.05-.17c.02-.06.02-.09.02-.11,0-.01-.02-.02-.05-.04-.07-.04-.15-.07-.25-.1l-.08-.03Z"/>
            <path   d="m153.74,674.07h.07c.07-.02.1-.04.11-.09.01-.04-.01-.23-.05-.57l-.07-.44c-.05-.35-.08-.54-.1-.57-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12,0,.24-.02.36-.04.09-.01.18-.03.26-.05v.08s-.07.01-.07.01c-.06,0-.09.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.07.5l.06.45c.05.34.08.53.1.56.02.04.06.05.12.04h.07s.01.07.01.07c-.09,0-.18.02-.28.03-.12.02-.23.03-.32.05v-.08Z"/>
            <path   d="m154.6,672.21l-.05-.07c.12-.08.21-.14.26-.18.06-.04.14-.1.24-.19l.05.07-.07.04c-.06.04-.08.08-.07.11,0,.04.08.15.24.36l.3.42c.11.16.2.26.25.31.06.05.13.08.22.08.08,0,.18-.03.27-.1.09-.07.16-.15.2-.24.03-.07.03-.14,0-.22-.02-.08-.1-.21-.23-.39l-.29-.41c-.13-.18-.22-.28-.25-.3-.04-.02-.08,0-.14.04l-.05.03-.05-.07c.12-.08.2-.14.23-.16.05-.04.12-.09.19-.15l.05.07-.06.05s-.06.07-.06.11c.01.04.08.15.21.33l.29.4c.13.19.21.31.24.39.03.08.05.15.04.23,0,.08-.02.16-.07.24-.05.08-.11.15-.2.21-.09.07-.18.11-.27.13-.09.02-.16.03-.23.02-.06-.02-.12-.05-.18-.09-.06-.04-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.26-.33-.03-.02-.07,0-.12.03l-.06.05Z"/>
            <path   d="m157.21,670.21l-.04.06c-.1-.05-.18-.08-.24-.09-.05-.01-.11,0-.15.02-.05.02-.09.05-.12.1-.05.06-.06.13-.04.21.03.07.07.13.14.18.06.04.12.06.19.06.06,0,.17-.04.32-.11.15-.08.28-.11.39-.1.1.01.2.05.29.11.08.06.15.12.2.21.05.08.07.17.07.26,0,.1-.03.18-.08.26-.04.05-.08.1-.13.14-.04.05-.11.09-.19.14-.1-.08-.23-.18-.4-.29l.04-.07c.12.06.21.09.27.1.06.01.11.01.17-.01.06-.02.1-.05.13-.1.04-.06.06-.14.04-.22-.01-.08-.06-.15-.15-.21-.07-.05-.14-.07-.22-.08-.08,0-.19.03-.34.1-.15.07-.27.11-.36.1-.09,0-.17-.03-.26-.09-.07-.05-.13-.11-.18-.19-.04-.08-.07-.16-.07-.25,0-.09.03-.17.08-.23.05-.08.14-.16.27-.24.1.07.17.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m148.03,665.34l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.18.04-.06.03-.1.08-.13.14-.04.08-.04.17,0,.25.04.08.11.14.2.18.08.04.16.05.24.04.08-.01.2-.07.37-.19.17-.11.31-.17.44-.18.13,0,.25.02.38.07.11.06.2.13.27.22.07.09.12.19.13.3.02.11,0,.21-.05.31-.03.07-.07.13-.12.19-.05.06-.12.12-.21.2-.13-.07-.31-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.14-.01.2-.05.06-.03.11-.08.14-.14.04-.09.04-.17.01-.27-.03-.09-.11-.17-.22-.23-.1-.04-.19-.06-.28-.05-.09.01-.22.07-.39.18-.17.11-.3.17-.41.18-.11.01-.21,0-.32-.06-.1-.05-.19-.12-.26-.2-.07-.09-.11-.19-.12-.29-.02-.1,0-.2.05-.29.05-.11.15-.22.28-.33.14.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m149.6,664.41l.17-.17c.07-.07.11-.11.11-.12,0-.02,0-.04,0-.06s-.04-.07-.09-.15c0,0-.02-.02-.03-.04l.05-.04c.1.11.17.19.23.25.05.06.14.13.24.23l-.05.05c-.1-.08-.16-.12-.17-.13-.02-.01-.05-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.01-.03.01-.06,0-.09,0-.03-.03-.11-.08-.23,0-.01-.01-.03-.02-.06l.04-.05s.1.18.26.44c-.18.16-.34.31-.48.45-.1.09-.21.21-.32.33l-.05-.05.08-.15s.02-.04.01-.05c0-.02-.02-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.02-.1.04l-.1.05-.06-.06c.09-.08.21-.18.32-.3.07-.07.23-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.18-.11-.22-.13-.02-.01-.05-.01-.07-.01-.02.01-.06.04-.12.09l-.28.28.29.3.18.19Z"/>
            <path   d="m152.55,662.35l-.2.86c-.08.32-.13.6-.18.84h-.04c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.04.24.06.37.08.4.02.03.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.03-.04,0-.13.02-.27.05v-.08s.06,0,.06,0c.05-.01.08-.02.09-.04.01-.02.02-.05.02-.1,0-.12-.03-.26-.04-.4l-.1-.74c-.03-.18-.05-.3-.06-.36,0-.03-.02-.05-.04-.06-.03-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13,0,.21-.02.24-.02.04,0,.1-.02.16-.03.13.22.26.47.41.73l.38.67.17-.69c.06-.24.12-.54.19-.88.09,0,.15-.01.19-.02.04,0,.11-.02.23-.04v.08s-.06.01-.06.01c-.06,0-.1.03-.11.06,0,.03,0,.12.02.29l.14.96c.03.21.05.33.07.36.02.03.06.04.13.03h.06s.02.07.02.07c-.1.01-.2.02-.29.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.05,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m154.86,662.58c-.07.11-.13.23-.19.36l-.34.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.06.03-.04.07c-.11-.06-.2-.11-.26-.14-.06-.03-.16-.08-.3-.15l.04-.07.09.04s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.19-.34l.32-.63.12-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.09l-.05-.02.04-.07c.07.04.17.1.3.16.07.04.16.08.24.11.06.03.11.05.14.07.15.08.25.17.28.27.03.1.02.22-.04.33-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1-.09.01-.19,0-.27-.05-.04-.02-.08-.05-.13-.09l.02-.1s.09.08.13.1c.09.04.18.04.26,0,.09-.04.16-.12.22-.23.06-.11.07-.21.05-.3-.03-.09-.09-.16-.2-.22-.07-.03-.14-.05-.22-.06Z"/>
            <path   d="m156.08,664.06l.19.15c.07.06.11.1.13.1.02,0,.04,0,.06,0,.02-.01.07-.05.14-.11,0,0,.02-.02.03-.03l.06.04c-.1.11-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.17.11-.19,0-.02.01-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.51.59.32.27.03.02s.08.06.09.06c.03,0,.05.01.09,0,.03,0,.11-.04.23-.1.01,0,.03-.02.06-.02l.05.04s-.17.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.22-.19-.36-.29l.05-.06.15.07s.05.02.06,0c.02,0,.05-.03.08-.07l.02-.02.21-.23s.12-.13.23-.27c.16-.19.28-.33.36-.42.08-.09.12-.14.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.09.06-.06c.08.09.2.19.32.3.07.06.25.2.52.42-.1.12-.19.24-.27.37l-.06-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.03-.04-.06-.1-.1l-.3-.26-.28.32-.17.2Z"/>
            <path   d="m157.55,666.01c-.06.12-.13.28-.2.46l-.15.36c-.03.07-.04.1-.04.12,0,.01,0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.09-.08-.13-.03-.04-.05-.07-.09-.1l.29-.68c.06-.13.1-.26.14-.38l.04-.02s.04.06.05.08c.07.1.15.15.26.16.1.01.2-.02.3-.1.1-.07.15-.14.17-.23.02-.08,0-.17-.07-.26-.05-.06-.1-.11-.18-.15-.11.07-.24.16-.37.25l-.52.38c-.26.18-.39.28-.41.32-.02.03-.01.07.03.12l.05.07-.07.05c-.07-.1-.12-.18-.17-.24-.04-.05-.1-.14-.2-.27l.07-.04.05.06c.04.05.07.07.11.06.03-.01.17-.11.43-.29l.52-.37c.22-.16.34-.26.36-.29.02-.03,0-.07-.03-.13l-.04-.05.07-.05c.07.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.18.07.1.11.18.13.25,0,.07,0,.14-.03.2-.03.06-.08.12-.15.17-.1.07-.21.11-.33.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
        <g>
          <path   d="m660.64,666.85c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="653.99" y1="660.16" x2="653.94" y2="673.7"/>
            <g>
              <polygon   points="653.99 660.16 654.14 660.26 653.83 660.26 653.99 660.16"/>
              <path   d="m654.46,660.46c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="655.03 660.86 655.29 661.06 652.68 661.05 652.94 660.85 655.03 660.86"/>
              <polygon   points="655.53 661.26 655.74 661.46 652.22 661.45 652.44 661.25 655.53 661.26"/>
              <polygon   points="655.96 661.66 656.18 661.86 651.78 661.84 652 661.64 655.96 661.66"/>
              <polygon   points="656.37 662.06 656.55 662.26 651.41 662.24 651.59 662.04 656.37 662.06"/>
              <polygon   points="656.72 662.46 656.88 662.66 651.07 662.64 651.23 662.44 656.72 662.46"/>
              <polygon   points="657.04 662.85 657.18 663.05 650.77 663.03 650.91 662.84 657.04 662.85"/>
              <path   d="m657.31,663.25c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="657.56 663.65 657.67 663.85 650.28 663.83 650.39 663.63 657.56 663.65"/>
              <polygon   points="657.78 664.05 657.87 664.25 650.08 664.23 650.17 664.03 657.78 664.05"/>
              <polygon   points="657.96 664.45 658.04 664.65 649.91 664.63 649.99 664.43 657.96 664.45"/>
              <polygon   points="658.11 664.85 658.18 665.05 649.76 665.02 649.83 664.82 658.11 664.85"/>
              <path   d="m658.23,665.25c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m658.33,665.65c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m658.4,666.04c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m658.44,666.44c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m658.46,666.84c0,.07,0,.13,0,.2l-8.99-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m658.46,667.24c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m658.42,667.64c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m658.37,668.04c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m658.28,668.43c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="658.17 668.83 658.11 669.03 649.81 669.01 649.74 668.81 658.17 668.83"/>
              <polygon   points="658.04 669.23 657.95 669.43 649.96 669.4 649.88 669.2 658.04 669.23"/>
              <polygon   points="657.87 669.63 657.77 669.83 650.14 669.8 650.05 669.6 657.87 669.63"/>
              <polygon   points="657.66 670.02 657.55 670.22 650.36 670.2 650.25 670 657.66 670.02"/>
              <path   d="m657.44,670.42c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="657.17 670.82 657.03 671.02 650.87 671 650.74 670.8 657.17 670.82"/>
              <polygon   points="656.87 671.22 656.71 671.42 651.19 671.4 651.03 671.2 656.87 671.22"/>
              <polygon   points="656.54 671.61 656.35 671.81 651.55 671.8 651.37 671.6 656.54 671.61"/>
              <polygon   points="656.16 672.01 655.94 672.21 651.96 672.2 651.74 672 656.16 672.01"/>
              <polygon   points="655.72 672.41 655.5 672.61 652.4 672.6 652.18 672.4 655.72 672.41"/>
              <polygon   points="655.26 672.8 655 673 652.9 673 652.63 672.8 655.26 672.8"/>
              <path   d="m654.73,673.2c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="654.11 673.6 653.94 673.7 653.78 673.6 654.11 673.6"/>
            </g>
          </g>
          <path   d="m660.64,666.85c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m649.65,669.74l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.04.04,0,.02.01.05.03.09l.06.12-.09.05c-.04-.08-.09-.16-.15-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04c.04.05.07.08.09.09.02,0,.06,0,.11-.01.17-.03.34-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.17-.14.2-.01.03-.02.06-.01.08,0,.03.02.07.06.12l-.08.06c-.06-.1-.12-.2-.19-.29-.06-.08-.13-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.03,0,.05,0,.02-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.39.57.59-.83-.98.26Z"/>
            <path   d="m650.52,671.33c.05.05.09.1.14.15.1.09.18.16.23.21.05.03.09.06.14.09l.26-.18.05.05c-.13.11-.25.21-.36.3-.05-.04-.12-.09-.2-.17l-.36-.37s-.13-.12-.24-.21l.05-.05c.06.02.11.02.14,0,.03-.02.13-.11.3-.29l.51-.52c.19-.19.29-.3.3-.33.02-.03,0-.06-.03-.09l-.09-.08.06-.06c.12.13.2.21.24.25.05.05.12.12.22.21l-.06.06-.09-.09s-.05-.03-.08-.02c-.03.01-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m653.26,670.97c-.05.12-.1.25-.15.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.12-.05-.21-.09-.3-.12-.08-.03-.19-.07-.3-.1l.03-.08.08.03s.08.02.1.01c.02-.01.04-.04.06-.08.04-.1.09-.22.14-.37l.27-.71c.05-.14.1-.27.14-.39l-.11-.04c-.17-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.02.05-.14c.02-.06.05-.15.08-.27.36.14.57.23.64.25l.56.2c-.07.13-.13.27-.17.39l-.07-.02.05-.16c.02-.06.02-.09.02-.11,0-.01-.02-.03-.04-.04-.07-.04-.16-.07-.25-.1l-.08-.03Z"/>
            <path   d="m654.7,672.84h.07c.06-.02.1-.04.11-.09,0-.04-.02-.23-.06-.57l-.06-.44c-.05-.35-.08-.54-.1-.57-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12,0,.24-.02.35-.04.1-.01.18-.03.26-.05v.08s-.07.01-.07.01c-.06,0-.08.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.06.5l.06.45c.05.34.08.53.09.56.02.04.06.04.13.04h.07s.01.07.01.07c-.09,0-.19.02-.29.03-.12.02-.23.03-.32.05v-.08Z"/>
            <path   d="m655.55,670.99l-.04-.07c.12-.08.21-.14.27-.18.06-.04.14-.1.24-.19l.05.07-.07.04c-.06.04-.08.07-.07.11,0,.04.09.15.23.36l.3.42c.11.15.2.26.25.31.06.05.13.08.22.08s.18-.03.27-.1c.1-.07.16-.15.2-.24.02-.07.02-.14,0-.22-.03-.08-.1-.21-.23-.39l-.29-.41c-.14-.19-.22-.29-.25-.3-.03-.02-.08,0-.14.04l-.05.03-.05-.07c.12-.08.2-.14.23-.16.05-.04.12-.09.2-.15l.05.07-.06.04s-.07.07-.05.11c0,.04.08.15.21.33l.29.4c.13.18.21.31.24.39.03.08.05.15.05.23s-.02.16-.07.23c-.04.08-.11.15-.2.21-.09.07-.18.11-.27.13-.09.02-.16.03-.23.01-.06-.01-.12-.05-.18-.09-.06-.05-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.26-.33-.03-.02-.07,0-.12.03l-.07.05Z"/>
            <path   d="m658.17,668.99l-.04.06c-.1-.05-.18-.08-.24-.09-.05-.01-.11,0-.15.01-.05.02-.09.06-.12.1-.05.06-.06.13-.04.21.02.07.07.13.14.18.06.04.12.06.19.06.07,0,.18-.04.32-.11.16-.08.28-.11.38-.11.11.01.2.05.3.11.09.06.15.12.2.21.05.08.07.17.07.26,0,.1-.03.18-.08.26-.04.05-.08.1-.13.14-.05.05-.11.09-.19.14-.1-.08-.23-.18-.4-.29l.04-.06c.12.06.21.09.26.1.06.01.12.01.17-.01.05-.02.1-.05.13-.1.04-.06.06-.14.04-.22-.02-.08-.07-.15-.15-.21-.07-.05-.14-.07-.22-.08-.08,0-.19.03-.34.1-.15.08-.27.11-.36.1-.09,0-.17-.03-.26-.09-.08-.05-.14-.11-.18-.19-.05-.08-.07-.16-.06-.25,0-.09.02-.17.07-.23.06-.08.15-.17.28-.24.1.07.16.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m648.98,664.12l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.19.04-.05.03-.1.08-.12.14-.04.08-.04.16,0,.25.04.08.11.14.2.19.08.04.16.05.24.04.08,0,.2-.07.37-.18.17-.11.31-.18.44-.18.12-.01.25.02.37.07.11.06.2.13.27.22.08.09.12.19.13.3.02.11,0,.22-.05.31-.03.06-.07.13-.12.19-.05.06-.12.12-.21.2-.13-.07-.31-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.13-.01.2-.04.06-.03.11-.08.14-.14.04-.09.04-.18.01-.27-.04-.09-.11-.17-.22-.23-.09-.05-.19-.06-.28-.05-.09,0-.22.07-.39.18-.16.11-.3.17-.41.18-.11.01-.22,0-.33-.06-.1-.05-.18-.11-.25-.2-.07-.09-.11-.19-.12-.29-.02-.1,0-.2.04-.29.05-.11.15-.22.29-.33.13.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m650.55,663.19l.17-.16c.07-.07.11-.11.12-.13,0-.01,0-.04,0-.06-.02-.02-.04-.07-.1-.15,0,0-.02-.02-.03-.04l.05-.05c.1.11.17.19.23.25.06.06.14.13.24.23l-.05.05c-.11-.08-.16-.12-.18-.13-.02-.01-.04-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.02-.03.02-.06.01-.09,0-.03-.04-.11-.08-.24,0-.01-.01-.03-.02-.06l.05-.05s.1.17.26.44c-.18.16-.34.31-.48.45-.09.1-.2.21-.31.33l-.05-.05.08-.14s.01-.04.01-.05c0-.02-.02-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.01-.1.04l-.1.05-.05-.06c.1-.08.21-.18.33-.3.07-.07.23-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.17-.11-.21-.13-.03-.01-.05-.01-.07,0-.03,0-.06.04-.12.09l-.29.28.3.3.18.19Z"/>
            <path   d="m653.51,661.12l-.2.86c-.07.32-.13.6-.18.84h-.04c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.03.24.06.37.08.4.02.03.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.02-.04,0-.13.02-.26.05l-.02-.08h.07s.08-.03.09-.05c.01-.02.02-.05.02-.09,0-.12-.02-.26-.04-.4l-.1-.75c-.02-.18-.04-.29-.06-.35,0-.03-.02-.05-.04-.06-.02-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13,0,.2-.02.23-.02.04-.01.1-.02.17-.03.12.22.26.47.41.73l.38.67.16-.69c.06-.25.13-.54.2-.88.09,0,.15-.01.19-.02.05,0,.12-.02.23-.04v.08s-.06.01-.06.01c-.07,0-.1.03-.11.06,0,.03,0,.12.03.29l.13.96c.03.21.05.33.07.36.02.03.07.04.13.03h.06s.01.07.01.07c-.1.01-.19.02-.28.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.06,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m655.81,661.36c-.06.11-.12.23-.19.36l-.35.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.06.03-.04.07c-.11-.06-.2-.11-.26-.15-.06-.03-.16-.08-.31-.14l.04-.07.09.04s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.18-.34l.32-.63.13-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.09l-.05-.03.04-.07c.08.04.18.1.3.16.07.04.16.08.25.11.06.03.1.05.13.07.15.08.25.17.28.27.04.1.02.22-.04.33-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1s-.19,0-.27-.05c-.04-.02-.08-.05-.13-.09l.03-.1c.05.05.09.08.13.1.09.04.18.04.26,0,.09-.04.15-.12.22-.23.05-.11.07-.21.05-.3-.02-.09-.09-.16-.19-.22-.07-.03-.14-.05-.23-.06Z"/>
            <path   d="m657.03,662.84l.19.15c.07.06.11.1.13.1.01,0,.03,0,.05,0,.03-.01.07-.05.14-.11.01,0,.03-.01.04-.03l.06.04c-.1.1-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.18.11-.19,0-.02,0-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.5.59.32.27.03.02s.07.06.09.06c.03,0,.06.01.09,0,.03,0,.11-.04.23-.1.01-.01.03-.02.05-.02l.06.04s-.17.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.23-.19-.36-.29l.05-.06.15.07s.05.02.06,0c.02,0,.05-.03.08-.07l.02-.02.2-.24s.12-.13.23-.27c.16-.19.28-.34.36-.42.08-.09.12-.14.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.1.05-.06c.09.09.2.19.33.3.07.06.25.2.52.42-.1.12-.19.24-.27.36l-.05-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.03-.04-.06-.1-.11l-.3-.26-.28.32-.17.2Z"/>
            <path   d="m658.5,664.79c-.06.12-.13.28-.2.46l-.15.36c-.02.07-.04.1-.04.12,0,.01,0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.1-.08-.13-.03-.04-.05-.07-.09-.1l.29-.68c.05-.13.1-.26.14-.38l.04-.02s.04.06.05.08c.07.1.16.15.26.16.1.01.2-.02.3-.09.09-.07.14-.14.16-.23.02-.08,0-.17-.07-.25-.05-.06-.1-.11-.18-.15-.12.07-.24.16-.37.25l-.51.37c-.26.18-.39.29-.41.32-.02.03-.02.07.03.12l.05.07-.07.05c-.06-.1-.12-.18-.17-.24-.04-.05-.11-.14-.2-.27l.07-.04.05.06c.04.05.07.07.11.06.03-.01.17-.11.43-.29l.52-.37c.22-.17.34-.26.36-.29.02-.03,0-.07-.04-.13l-.04-.05.07-.05c.08.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.18.07.1.11.19.13.26,0,.07,0,.14-.03.2s-.08.12-.15.17c-.1.07-.21.11-.34.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
        <g>
          <path   d="m660.64,684.68c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="653.99" y1="677.98" x2="653.94" y2="691.52"/>
            <g>
              <polygon   points="653.99 677.98 654.14 678.08 653.83 678.07 653.99 677.98"/>
              <path   d="m654.46,678.28c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="655.03 678.68 655.29 678.88 652.68 678.87 652.94 678.67 655.03 678.68"/>
              <polygon   points="655.53 679.08 655.74 679.28 652.22 679.26 652.44 679.07 655.53 679.08"/>
              <polygon   points="655.96 679.47 656.18 679.67 651.78 679.66 652 679.46 655.96 679.47"/>
              <polygon   points="656.37 679.87 656.55 680.07 651.41 680.06 651.59 679.86 656.37 679.87"/>
              <polygon   points="656.72 680.27 656.88 680.47 651.07 680.46 651.23 680.26 656.72 680.27"/>
              <polygon   points="657.04 680.67 657.18 680.87 650.77 680.85 650.91 680.65 657.04 680.67"/>
              <path   d="m657.31,681.07c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="657.56 681.47 657.67 681.67 650.28 681.65 650.39 681.45 657.56 681.47"/>
              <polygon   points="657.78 681.87 657.87 682.07 650.08 682.05 650.17 681.85 657.78 681.87"/>
              <polygon   points="657.96 682.27 658.04 682.47 649.91 682.44 649.99 682.25 657.96 682.27"/>
              <polygon   points="658.11 682.67 658.18 682.87 649.76 682.84 649.83 682.64 658.11 682.67"/>
              <path   d="m658.23,683.07c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m658.33,683.46c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m658.4,683.86c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m658.44,684.26c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m658.46,684.66c0,.07,0,.13,0,.2l-8.99-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m658.46,685.06c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m658.42,685.46c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m658.37,685.85c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m658.28,686.25c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="658.17 686.65 658.11 686.85 649.81 686.82 649.74 686.63 658.17 686.65"/>
              <polygon   points="658.04 687.05 657.95 687.25 649.96 687.22 649.88 687.02 658.04 687.05"/>
              <polygon   points="657.87 687.45 657.77 687.65 650.14 687.62 650.05 687.42 657.87 687.45"/>
              <polygon   points="657.66 687.84 657.55 688.04 650.36 688.02 650.25 687.82 657.66 687.84"/>
              <path   d="m657.44,688.24c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="657.17 688.64 657.03 688.84 650.87 688.82 650.74 688.62 657.17 688.64"/>
              <polygon   points="656.87 689.04 656.71 689.24 651.19 689.22 651.03 689.02 656.87 689.04"/>
              <polygon   points="656.54 689.43 656.35 689.63 651.55 689.62 651.37 689.42 656.54 689.43"/>
              <polygon   points="656.16 689.83 655.94 690.03 651.96 690.02 651.74 689.82 656.16 689.83"/>
              <polygon   points="655.72 690.23 655.5 690.43 652.4 690.42 652.18 690.22 655.72 690.23"/>
              <polygon   points="655.26 690.62 655 690.82 652.9 690.82 652.63 690.62 655.26 690.62"/>
              <path   d="m654.73,691.02c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="654.11 691.42 653.94 691.52 653.78 691.42 654.11 691.42"/>
            </g>
          </g>
          <path   d="m660.64,684.68c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m649.65,687.56l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.04.04,0,.02.01.05.03.09l.06.12-.09.05c-.04-.07-.09-.16-.15-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04c.04.05.07.08.09.09.02,0,.06,0,.11,0,.17-.03.34-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.18-.14.2-.01.03-.02.06-.01.08,0,.02.02.07.06.12l-.08.06c-.06-.1-.12-.2-.19-.28-.06-.08-.13-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.03,0,.05,0,.02-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.39.57.59-.83-.98.26Z"/>
            <path   d="m650.52,689.15c.05.05.09.1.14.15.1.09.18.16.23.21.05.03.09.06.14.09l.26-.18.05.05c-.13.11-.25.21-.36.3-.05-.04-.12-.1-.2-.17l-.36-.37s-.13-.12-.24-.21l.05-.05c.06.02.11.02.14,0,.03-.02.13-.12.3-.29l.51-.52c.19-.19.29-.3.3-.33.02-.03,0-.06-.03-.1l-.09-.08.06-.06c.12.13.2.21.24.25.05.05.12.12.22.2l-.06.06-.09-.1s-.05-.03-.08-.02c-.03.02-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m653.26,688.79c-.05.12-.1.25-.15.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.12-.05-.21-.09-.3-.12s-.19-.07-.3-.1l.03-.08.08.03s.08.02.1.01c.02,0,.04-.04.06-.08.04-.1.09-.22.14-.37l.27-.71c.05-.14.1-.27.14-.39l-.11-.04c-.17-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.02.05-.15c.02-.06.05-.14.08-.26.36.14.57.23.64.25l.56.2c-.07.13-.13.27-.17.39l-.07-.03.05-.16c.02-.06.02-.09.02-.11,0-.01-.02-.02-.04-.04-.07-.04-.16-.07-.25-.1l-.08-.03Z"/>
            <path   d="m654.7,690.66h.07c.06-.02.1-.04.11-.09,0-.04-.02-.23-.06-.57l-.06-.44c-.05-.35-.08-.54-.1-.58-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12-.01.24-.02.35-.04.1-.01.18-.03.26-.05v.08s-.07.01-.07.01c-.06.01-.08.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.06.5l.06.45c.05.34.08.53.09.56.02.04.06.05.13.04h.07s.01.07.01.07c-.09,0-.19.02-.29.03-.12.01-.23.03-.32.05v-.08Z"/>
            <path   d="m655.55,688.81l-.04-.07c.12-.08.21-.14.27-.18.06-.04.14-.1.24-.19l.05.07-.07.05c-.06.04-.08.07-.07.11s.09.15.23.36l.3.42c.11.15.2.26.25.31.06.05.13.08.22.08s.18-.03.27-.1c.1-.07.16-.15.2-.25.02-.07.02-.14,0-.22-.03-.08-.1-.21-.23-.39l-.29-.41c-.14-.19-.22-.29-.25-.3-.03-.02-.08,0-.14.04l-.05.03-.05-.07c.12-.08.2-.14.23-.16.05-.04.12-.09.2-.15l.05.06-.06.05s-.07.07-.05.11c0,.04.08.15.21.34l.29.4c.13.19.21.31.24.39.03.08.05.15.05.23s-.02.16-.07.24c-.04.08-.11.15-.2.21-.09.07-.18.11-.27.13-.09.03-.16.03-.23.02-.06-.02-.12-.05-.18-.09-.06-.05-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.26-.33-.03-.02-.07,0-.12.03l-.07.05Z"/>
            <path   d="m658.17,686.81l-.04.06c-.1-.05-.18-.08-.24-.09-.05,0-.11,0-.15.02-.05.02-.09.05-.12.1-.05.06-.06.13-.04.21.02.07.07.13.14.18.06.04.12.06.19.06.07,0,.18-.04.32-.11.16-.07.28-.11.38-.1.11.01.2.05.3.11.09.06.15.13.2.21.05.08.07.17.07.26,0,.09-.03.18-.08.26-.04.05-.08.1-.13.14s-.11.09-.19.14c-.1-.08-.23-.18-.4-.29l.04-.07c.12.06.21.09.26.1.06.01.12.01.17-.01.05-.02.1-.05.13-.1.04-.06.06-.14.04-.22-.02-.08-.07-.15-.15-.21-.07-.05-.14-.08-.22-.08-.08,0-.19.03-.34.1-.15.08-.27.11-.36.1-.09,0-.17-.03-.26-.09-.08-.05-.14-.12-.18-.19-.05-.08-.07-.16-.06-.25,0-.09.02-.17.07-.23.06-.08.15-.16.28-.24.1.08.16.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m648.98,681.94l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.19.04-.05.03-.1.08-.12.14-.04.08-.04.16,0,.25.04.08.11.14.2.19.08.04.16.05.24.04.08,0,.2-.07.37-.19.17-.11.31-.17.44-.18.12-.01.25.01.37.07.11.06.2.13.27.21.08.09.12.19.13.3.02.11,0,.22-.05.31-.03.07-.07.13-.12.19-.05.06-.12.13-.21.2-.13-.08-.31-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.13-.01.2-.05.06-.03.11-.08.14-.14.04-.09.04-.17.01-.26-.04-.1-.11-.17-.22-.23-.09-.05-.19-.06-.28-.05-.09,0-.22.07-.39.18-.16.11-.3.17-.41.18-.11.01-.22-.01-.33-.06-.1-.05-.18-.12-.25-.2-.07-.09-.11-.18-.12-.29-.02-.11,0-.2.04-.29.05-.11.15-.22.29-.33.13.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m650.55,681.01l.17-.17c.07-.07.11-.11.12-.12,0-.02,0-.04,0-.06-.02-.02-.04-.07-.1-.15,0,0-.02-.02-.03-.04l.05-.05c.1.11.17.19.23.25.06.06.14.13.24.23l-.05.05c-.11-.08-.16-.12-.18-.13-.02-.01-.04-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.02-.03.02-.06.01-.09,0-.03-.04-.11-.08-.24,0-.01-.01-.03-.02-.06l.05-.05s.1.17.26.44c-.18.16-.34.31-.48.45-.09.1-.2.21-.31.33l-.05-.05.08-.14s.01-.04.01-.06c0-.02-.02-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.02-.1.04l-.1.05-.05-.06c.1-.08.21-.18.33-.3.07-.07.23-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.17-.11-.21-.13-.03-.01-.05-.01-.07,0-.03,0-.06.04-.12.09l-.29.28.3.3.18.19Z"/>
            <path   d="m653.51,678.95l-.2.86c-.07.32-.13.6-.18.84h-.04c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.03.24.06.37.08.4.02.03.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.02-.04,0-.13.02-.26.05l-.02-.08h.07s.08-.03.09-.05c.01-.02.02-.05.02-.09,0-.12-.02-.26-.04-.4l-.1-.74c-.02-.18-.04-.29-.06-.36,0-.03-.02-.05-.04-.06-.02-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13-.01.2-.02.23-.02.04-.01.1-.02.17-.03.12.22.26.47.41.73l.38.67.16-.7c.06-.24.13-.54.2-.88.09,0,.15-.01.19-.02.05,0,.12-.02.23-.04v.08s-.06.01-.06.01c-.07,0-.1.03-.11.06,0,.03,0,.12.03.29l.13.96c.03.22.05.34.07.36.02.03.07.04.13.03h.06s.01.07.01.07c-.1.01-.19.02-.28.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.06,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m655.81,679.18c-.06.11-.12.23-.19.36l-.35.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.06.03-.04.08c-.11-.06-.2-.11-.26-.15-.06-.03-.16-.08-.31-.14l.04-.08.09.05s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.18-.34l.32-.63.13-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.1l-.05-.02.04-.07c.08.04.18.09.3.16.07.04.16.08.25.11.06.03.1.05.13.07.15.08.25.17.28.27.04.11.02.22-.04.34-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1-.1.01-.19,0-.27-.05-.04-.02-.08-.05-.13-.1l.03-.1c.05.05.09.08.13.1.09.04.18.05.26,0,.09-.04.15-.12.22-.23.05-.11.07-.21.05-.3-.02-.09-.09-.16-.19-.22-.07-.03-.14-.05-.23-.06Z"/>
            <path   d="m657.03,680.66l.19.15c.07.06.11.1.13.1.01,0,.03,0,.05,0,.03-.01.07-.05.14-.11.01,0,.03-.02.04-.03l.06.04c-.1.1-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.17.11-.19,0-.02,0-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.5.59.32.27.03.02s.07.06.09.06c.03,0,.06,0,.09,0,.03,0,.11-.04.23-.1.01-.01.03-.02.05-.03l.06.04s-.17.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.23-.18-.36-.28l.05-.06.15.07s.05.02.06,0c.02,0,.05-.03.08-.07l.02-.02.2-.24s.12-.13.23-.27c.16-.19.28-.33.36-.42.08-.09.12-.15.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.1.05-.06c.09.09.2.19.33.3.07.06.25.2.52.42-.1.12-.19.24-.27.36l-.05-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.02-.04-.06-.1-.1l-.3-.26-.28.32-.17.2Z"/>
            <path   d="m658.5,682.61c-.06.13-.13.28-.2.46l-.15.36c-.02.07-.04.11-.04.12s0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.1-.08-.13-.03-.04-.05-.07-.09-.1l.29-.68c.05-.13.1-.26.14-.39l.04-.02s.04.06.05.08c.07.09.16.15.26.16.1.01.2-.02.3-.09.09-.07.14-.14.16-.23.02-.08,0-.17-.07-.26-.05-.06-.1-.11-.18-.15-.12.08-.24.16-.37.25l-.51.38c-.26.18-.39.29-.41.32-.02.03-.02.07.03.12l.05.07-.07.05c-.06-.1-.12-.18-.17-.25-.04-.05-.11-.14-.2-.27l.07-.05.05.06c.04.05.07.07.11.06.03,0,.17-.11.43-.29l.52-.37c.22-.17.34-.26.36-.29.02-.03,0-.07-.04-.13l-.04-.05.07-.05c.08.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.18.07.1.11.19.13.26,0,.07,0,.14-.03.2-.03.06-.08.12-.15.17-.1.07-.21.11-.34.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
        <g>
          <path   d="m158.77,686.48c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="152.11" y1="679.79" x2="152.06" y2="693.33"/>
            <g>
              <polygon   points="152.11 679.79 152.27 679.89 151.95 679.88 152.11 679.79"/>
              <path   d="m152.58,680.09c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="153.15 680.49 153.41 680.69 150.8 680.68 151.06 680.48 153.15 680.49"/>
              <polygon   points="153.65 680.89 153.86 681.09 150.34 681.07 150.56 680.88 153.65 680.89"/>
              <polygon   points="154.08 681.29 154.3 681.48 149.9 681.47 150.12 681.27 154.08 681.29"/>
              <polygon   points="154.49 681.68 154.67 681.88 149.53 681.87 149.71 681.67 154.49 681.68"/>
              <polygon   points="154.84 682.08 155 682.28 149.19 682.27 149.35 682.07 154.84 682.08"/>
              <polygon   points="155.16 682.48 155.3 682.68 148.89 682.66 149.03 682.46 155.16 682.48"/>
              <path   d="m155.43,682.88c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="155.68 683.28 155.79 683.48 148.4 683.46 148.51 683.26 155.68 683.28"/>
              <polygon   points="155.9 683.68 155.99 683.88 148.2 683.86 148.29 683.66 155.9 683.68"/>
              <polygon   points="156.08 684.08 156.16 684.28 148.03 684.25 148.11 684.05 156.08 684.08"/>
              <polygon   points="156.23 684.48 156.3 684.68 147.88 684.65 147.95 684.45 156.23 684.48"/>
              <path   d="m156.35,684.88c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m156.45,685.27c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m156.52,685.67c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m156.56,686.07c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m156.58,686.47c0,.07,0,.13,0,.2l-9-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m156.58,686.87c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m156.54,687.27c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m156.49,687.66c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m156.4,688.06c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="156.29 688.46 156.23 688.66 147.93 688.63 147.86 688.43 156.29 688.46"/>
              <polygon   points="156.16 688.86 156.07 689.06 148.08 689.03 148 688.83 156.16 688.86"/>
              <polygon   points="155.99 689.26 155.89 689.45 148.26 689.43 148.17 689.23 155.99 689.26"/>
              <polygon   points="155.78 689.65 155.67 689.85 148.48 689.83 148.37 689.63 155.78 689.65"/>
              <path   d="m155.56,690.05c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="155.29 690.45 155.15 690.65 148.99 690.63 148.86 690.43 155.29 690.45"/>
              <polygon   points="154.99 690.84 154.83 691.04 149.31 691.03 149.15 690.83 154.99 690.84"/>
              <polygon   points="154.66 691.24 154.47 691.44 149.67 691.43 149.49 691.23 154.66 691.24"/>
              <polygon   points="154.28 691.64 154.06 691.84 150.08 691.83 149.86 691.63 154.28 691.64"/>
              <polygon   points="153.84 692.04 153.62 692.23 150.52 692.23 150.3 692.03 153.84 692.04"/>
              <polygon   points="153.38 692.43 153.12 692.63 151.02 692.62 150.75 692.42 153.38 692.43"/>
              <path   d="m152.85,692.83c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="152.23 693.23 152.06 693.33 151.9 693.22 152.23 693.23"/>
            </g>
          </g>
          <path   d="m158.77,686.48c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m147.77,689.37l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.04.04,0,.02.01.05.03.09l.07.12-.09.05c-.05-.08-.1-.16-.16-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04s.07.08.09.09c.02,0,.06,0,.12-.01.17-.03.34-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.18-.14.2s-.02.06-.01.08c0,.02.02.07.06.12l-.08.06c-.06-.1-.12-.2-.19-.29-.06-.08-.13-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.04,0,.05,0,.02-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.39.57.59-.83-.98.26Z"/>
            <path   d="m148.64,690.96c.05.05.09.1.14.15.09.09.17.16.23.21.05.03.09.06.14.09l.27-.18.05.05c-.13.11-.25.21-.36.3-.05-.04-.12-.1-.2-.17l-.36-.37s-.13-.12-.24-.21l.05-.05c.07.02.11.02.14,0,.03-.02.14-.11.3-.29l.51-.52c.19-.19.29-.3.3-.33.02-.03,0-.06-.03-.1l-.09-.08.06-.06c.12.13.2.21.24.25.05.05.12.12.22.21l-.06.06-.09-.09s-.05-.03-.08-.02c-.03.02-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m151.38,690.6c-.05.12-.1.25-.15.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.12-.05-.21-.09-.3-.12-.08-.03-.19-.06-.3-.1l.03-.08.08.03s.08.02.1.01c.02,0,.04-.04.06-.08.04-.1.09-.22.15-.37l.26-.71c.05-.14.1-.27.14-.39l-.11-.04c-.17-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.02.06-.15c.02-.06.05-.14.08-.26.36.14.57.23.64.25l.56.2c-.07.13-.12.27-.17.39l-.07-.02.06-.16c.01-.06.02-.09.02-.11,0-.01-.02-.03-.04-.04-.07-.04-.15-.07-.25-.1l-.08-.03Z"/>
            <path   d="m152.82,692.47h.07c.07-.02.1-.04.11-.09,0-.04-.01-.23-.06-.57l-.06-.44c-.05-.35-.08-.54-.1-.57-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12-.01.24-.02.35-.04.09-.01.18-.03.26-.05v.08s-.07.01-.07.01c-.05,0-.08.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.06.5l.07.45c.05.34.08.53.09.56.02.04.06.04.12.04h.07s.01.07.01.07c-.09,0-.19.02-.29.03-.12.02-.23.03-.32.05v-.08Z"/>
            <path   d="m153.67,690.62l-.04-.07c.12-.08.21-.14.26-.18.06-.04.14-.1.24-.19l.05.07-.07.04c-.06.04-.08.07-.07.11,0,.04.08.15.23.36l.3.42c.11.15.2.26.25.31.06.05.13.08.22.08s.18-.03.27-.1c.1-.07.17-.15.2-.25.02-.07.02-.14,0-.22-.03-.08-.1-.21-.23-.39l-.29-.41c-.14-.19-.22-.29-.25-.3-.03-.02-.08,0-.14.04l-.05.03-.05-.07c.12-.08.2-.14.23-.16.05-.04.12-.09.2-.15l.05.07-.06.04s-.07.07-.05.11c0,.04.08.15.21.33l.29.4c.13.18.21.31.24.39.03.08.04.15.04.23s-.02.16-.07.23c-.04.08-.11.15-.19.21-.09.07-.18.11-.27.13-.09.03-.17.03-.23.02-.06-.02-.12-.05-.18-.09-.06-.05-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.25-.33-.03-.02-.07,0-.12.03l-.07.05Z"/>
            <path   d="m156.29,688.62l-.04.06c-.1-.05-.18-.08-.24-.09-.05-.01-.11,0-.15.01-.05.02-.09.06-.12.1-.05.06-.06.13-.04.21.02.07.07.13.14.18.06.04.12.06.19.06.07,0,.18-.04.32-.11.16-.07.28-.11.38-.1.11.01.2.05.3.11.09.06.15.12.2.21.05.08.07.17.07.26,0,.09-.03.18-.08.26-.04.05-.08.1-.13.14-.05.05-.11.09-.19.14-.1-.08-.23-.18-.4-.29l.04-.07c.12.06.21.09.26.1.06.01.12.01.17-.01.05-.02.1-.06.13-.1.04-.06.06-.14.04-.22-.02-.08-.07-.15-.15-.21-.07-.05-.14-.08-.22-.08-.08,0-.19.03-.34.1-.15.08-.27.11-.36.1-.09,0-.17-.03-.26-.09-.08-.05-.14-.12-.18-.19-.05-.08-.07-.16-.07-.25,0-.09.02-.17.07-.23.06-.08.15-.17.28-.24.1.07.16.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m147.1,683.75l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.19.04-.05.03-.1.08-.12.14-.04.08-.04.16,0,.25.04.08.11.14.2.19.08.04.16.05.24.04.08,0,.2-.07.37-.19.17-.11.31-.17.44-.18.12-.01.25.02.37.07.11.06.2.13.27.22.08.09.12.19.14.3.01.11,0,.22-.05.31-.03.06-.07.13-.12.19-.05.06-.12.13-.21.2-.13-.07-.3-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.13-.01.2-.04.06-.03.11-.08.14-.14.04-.09.04-.17.01-.26-.04-.1-.11-.17-.22-.23-.09-.05-.19-.06-.28-.05-.09,0-.22.07-.39.18-.16.11-.3.17-.4.18-.11.01-.22,0-.33-.06-.1-.05-.18-.11-.25-.2-.07-.09-.11-.19-.12-.29-.02-.1,0-.2.04-.29.05-.11.15-.22.29-.33.14.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m148.67,682.82l.17-.17c.06-.07.1-.11.11-.13,0-.02,0-.04,0-.06-.02-.02-.04-.07-.1-.15,0,0-.02-.02-.03-.04l.04-.05c.1.11.17.19.23.25.06.06.14.13.24.23l-.05.05c-.11-.08-.16-.12-.18-.13-.02-.01-.04-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.02-.03.02-.06.01-.09,0-.03-.04-.11-.08-.24,0-.01-.01-.03-.02-.06l.05-.05s.1.17.26.44c-.18.16-.34.31-.48.45-.1.1-.21.21-.32.33l-.05-.05.08-.14s.02-.04.02-.05c0-.02-.02-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.02-.1.04l-.1.05-.05-.06c.1-.08.21-.18.33-.3.06-.07.22-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.17-.11-.21-.13-.03-.01-.05-.01-.07,0-.03,0-.06.04-.12.09l-.28.28.29.3.18.19Z"/>
            <path   d="m151.63,680.75l-.2.86c-.07.32-.13.6-.18.84h-.05c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.04.24.06.37.08.4s.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.02-.04,0-.13.02-.26.05l-.02-.08h.07s.08-.03.09-.05c.01-.02.02-.05.02-.09,0-.12-.02-.26-.04-.4l-.1-.75c-.02-.18-.04-.29-.06-.35,0-.03-.02-.05-.04-.06-.02-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13,0,.2-.02.23-.02.04-.01.1-.02.17-.03.12.22.26.47.41.73l.38.67.16-.7c.06-.24.12-.54.2-.88.09,0,.15-.01.19-.02.05,0,.12-.02.23-.04v.08s-.06.01-.06.01c-.06,0-.1.03-.11.06,0,.03,0,.12.03.29l.13.96c.03.21.05.33.07.36.02.03.06.04.13.03h.06s.01.07.01.07c-.1.01-.19.02-.28.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.06,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m153.93,680.99c-.06.11-.12.23-.19.36l-.35.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.06.03-.04.07c-.11-.06-.19-.11-.26-.15-.06-.03-.16-.08-.31-.14l.04-.07.09.04s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.18-.34l.31-.63.13-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.09l-.05-.03.04-.07c.08.04.18.1.3.16.07.04.15.08.24.11.06.03.1.05.13.07.15.08.25.17.28.27.04.1.02.22-.04.33-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1s-.19,0-.27-.05c-.04-.02-.08-.05-.13-.09l.03-.1c.05.05.09.08.13.1.09.04.17.04.26,0,.09-.04.15-.12.22-.23.05-.11.07-.21.05-.3-.02-.09-.09-.16-.2-.22-.07-.03-.14-.05-.23-.06Z"/>
            <path   d="m155.15,682.47l.19.15c.07.06.11.1.13.1.01,0,.03,0,.05,0,.03-.01.07-.05.14-.11.02,0,.03-.02.04-.03l.06.04c-.1.1-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.17.11-.19,0-.02,0-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.5.59.32.27.03.02s.07.06.09.06c.03,0,.06,0,.09,0,.04,0,.11-.04.23-.1,0-.01.03-.02.05-.03l.06.04s-.16.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.23-.19-.36-.29l.05-.06.15.07s.04.01.05,0c.02,0,.05-.03.08-.07l.02-.02.2-.24s.12-.13.23-.27c.16-.19.28-.34.36-.42.08-.09.12-.14.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.1.05-.06c.09.09.2.19.33.3.07.06.25.2.52.42-.1.12-.19.24-.27.36l-.05-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.03-.04-.06-.1-.11l-.3-.26-.28.32-.18.2Z"/>
            <path   d="m156.62,684.42c-.06.12-.13.28-.2.46l-.15.36c-.02.07-.04.1-.04.12,0,.01,0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.1-.08-.13-.03-.04-.05-.07-.09-.1l.29-.68c.05-.13.1-.26.14-.38l.04-.02s.04.06.05.08c.07.1.16.15.26.16.1.01.2-.02.3-.09.09-.07.14-.14.16-.23.02-.08,0-.17-.07-.25-.04-.06-.1-.11-.18-.15-.12.07-.24.16-.37.25l-.51.38c-.26.18-.39.29-.41.32-.02.03-.02.07.03.12l.05.07-.07.05c-.06-.1-.12-.18-.17-.24-.04-.05-.11-.14-.2-.27l.07-.05.05.06c.04.05.07.07.11.06.03-.01.17-.11.43-.29l.52-.37c.22-.17.34-.26.36-.29.02-.03,0-.07-.04-.13l-.04-.05.07-.05c.08.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.18.07.1.11.19.13.26,0,.07,0,.14-.03.2-.03.06-.08.12-.15.17-.1.07-.21.11-.34.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
        <g>
          <path   d="m661.35,702.39c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <line   x1="654.69" y1="695.69" x2="654.65" y2="709.23"/>
            <g>
              <polygon   points="654.69 695.69 654.85 695.79 654.53 695.79 654.69 695.69"/>
              <path   d="m655.16,695.99c.1.07.22.13.3.2h-1.56c.08-.07.2-.14.3-.2h.96Z"/>
              <polygon   points="655.73 696.39 655.99 696.59 653.38 696.58 653.64 696.38 655.73 696.39"/>
              <polygon   points="656.23 696.79 656.44 696.99 652.92 696.98 653.14 696.78 656.23 696.79"/>
              <polygon   points="656.66 697.19 656.88 697.39 652.48 697.38 652.7 697.18 656.66 697.19"/>
              <polygon   points="657.07 697.59 657.25 697.79 652.11 697.77 652.29 697.57 657.07 697.59"/>
              <polygon   points="657.42 697.99 657.58 698.19 651.77 698.17 651.93 697.97 657.42 697.99"/>
              <polygon   points="657.74 698.39 657.88 698.59 651.48 698.57 651.61 698.37 657.74 698.39"/>
              <path   d="m658.01,698.79c.04.07.09.13.13.2l-6.94-.02c.04-.07.09-.13.13-.2l6.68.02Z"/>
              <polygon   points="658.26 699.18 658.37 699.38 650.98 699.36 651.09 699.16 658.26 699.18"/>
              <polygon   points="658.48 699.58 658.57 699.78 650.78 699.76 650.87 699.56 658.48 699.58"/>
              <polygon   points="658.66 699.98 658.74 700.18 650.61 700.16 650.69 699.96 658.66 699.98"/>
              <polygon   points="658.81 700.38 658.88 700.58 650.46 700.55 650.53 700.36 658.81 700.38"/>
              <path   d="m658.93,700.78c.02.07.04.13.05.2l-8.63-.03c.01-.07.04-.13.05-.2l8.53.03Z"/>
              <path   d="m659.03,701.18c.01.07.03.13.04.2l-8.8-.03c0-.07.03-.13.04-.2l8.72.03Z"/>
              <path   d="m659.1,701.58c.01.07.02.13.03.2l-8.92-.03c0-.07.02-.13.03-.2l8.86.03Z"/>
              <path   d="m659.14,701.97c0,.07.01.13.01.2l-8.98-.03c0-.07,0-.13.01-.2l8.95.03Z"/>
              <path   d="m659.16,702.37c0,.07,0,.13,0,.2l-8.99-.03c0-.07,0-.13,0-.2l8.99.03Z"/>
              <path   d="m659.16,702.77c0,.07,0,.13-.01.2l-8.96-.03c0-.07,0-.13-.01-.2l8.98.03Z"/>
              <path   d="m659.12,703.17c0,.07-.01.13-.02.2l-8.87-.03c-.01-.07-.02-.13-.02-.2l8.92.03Z"/>
              <path   d="m659.07,703.57c0,.07-.02.13-.04.2l-8.74-.03c-.02-.07-.03-.13-.04-.2l8.81.03Z"/>
              <path   d="m658.98,703.97c-.01.07-.03.13-.05.2l-8.54-.03c-.02-.07-.04-.13-.05-.2l8.65.03Z"/>
              <polygon   points="658.88 704.36 658.81 704.56 650.51 704.54 650.44 704.34 658.88 704.36"/>
              <polygon   points="658.74 704.76 658.65 704.96 650.66 704.94 650.58 704.74 658.74 704.76"/>
              <polygon   points="658.57 705.16 658.47 705.36 650.84 705.33 650.75 705.14 658.57 705.16"/>
              <polygon   points="658.36 705.56 658.25 705.76 651.06 705.73 650.95 705.53 658.36 705.56"/>
              <path   d="m658.14,705.95c-.03.07-.09.13-.13.2l-6.7-.02c-.04-.07-.1-.13-.13-.2l6.96.02Z"/>
              <polygon   points="657.87 706.35 657.73 706.55 651.58 706.53 651.44 706.33 657.87 706.35"/>
              <polygon   points="657.57 706.75 657.41 706.95 651.9 706.93 651.73 706.73 657.57 706.75"/>
              <polygon   points="657.24 707.15 657.05 707.34 652.25 707.33 652.07 707.13 657.24 707.15"/>
              <polygon   points="656.86 707.54 656.64 707.74 652.66 707.73 652.44 707.53 656.86 707.54"/>
              <polygon   points="656.42 707.94 656.2 708.14 653.1 708.13 652.88 707.93 656.42 707.94"/>
              <polygon   points="655.96 708.34 655.7 708.53 653.6 708.53 653.33 708.33 655.96 708.34"/>
              <path   d="m655.43,708.73c-.08.07-.2.13-.3.2h-.96c-.1-.07-.22-.14-.3-.2h1.57Z"/>
              <polygon   points="654.81 709.13 654.65 709.23 654.48 709.13 654.81 709.13"/>
            </g>
          </g>
          <path   d="m661.35,702.39c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,6.7,3,6.7,6.7Z"/>
          <g>
            <path   d="m650.35,705.27l-.45-.65-.26.07c-.17.05-.28.08-.31.1-.02.02-.03.03-.04.04,0,.02.01.05.03.09l.06.12-.09.05c-.04-.08-.1-.16-.15-.25-.08-.11-.15-.2-.21-.28l.08-.06.03.04s.07.08.09.09c.02,0,.06,0,.12-.01.16-.03.34-.07.52-.12l1.03-.25c.27-.07.47-.12.59-.16l.05.08-1.15,1.64c-.08.11-.12.17-.14.2-.01.03-.02.06-.01.08,0,.02.02.07.06.12l-.08.06c-.06-.1-.12-.2-.19-.29-.06-.08-.13-.19-.24-.32l.08-.06.09.11s.05.05.06.06c.02,0,.04,0,.05,0,.02-.01.05-.05.1-.12l.28-.38Zm-.31-.69l.39.57.59-.83-.98.26Z"/>
            <path   d="m651.22,706.86c.05.05.09.1.14.15.09.09.17.16.23.21.05.03.09.06.14.09l.27-.18.05.05c-.13.11-.25.21-.36.3-.05-.04-.12-.1-.2-.17l-.36-.37s-.13-.12-.24-.21l.05-.05c.07.02.11.02.14,0,.03-.02.14-.11.3-.29l.51-.52c.19-.19.29-.3.3-.33.02-.03,0-.06-.03-.1l-.09-.08.06-.06c.12.13.19.21.24.25.05.05.12.12.22.21l-.06.06-.09-.09s-.05-.03-.08-.02c-.03.02-.11.09-.24.23l-.59.6-.26.27-.06.06Z"/>
            <path   d="m653.96,706.5c-.05.12-.1.25-.15.39l-.26.71c-.1.26-.15.41-.14.44,0,.03.03.06.1.08l.08.03-.03.08c-.12-.05-.21-.09-.3-.12-.08-.03-.19-.06-.3-.1l.03-.08.08.03s.08.02.1.01c.02,0,.04-.04.06-.08.04-.1.09-.22.15-.37l.26-.71c.05-.14.1-.27.14-.39l-.11-.04c-.17-.06-.25-.09-.28-.09-.02,0-.04.04-.07.1l-.08.16-.06-.02.06-.15c.02-.06.05-.14.08-.26.36.14.57.23.64.25l.56.2c-.07.13-.12.27-.17.39l-.07-.02.06-.17c.01-.06.02-.09.02-.11,0-.01-.02-.03-.04-.04-.07-.04-.15-.07-.25-.1l-.08-.03Z"/>
            <path   d="m655.4,708.38h.07c.07-.02.1-.04.11-.09,0-.04-.01-.23-.06-.57l-.06-.44c-.05-.35-.08-.54-.1-.57-.02-.04-.05-.05-.11-.04h-.09s-.01-.07-.01-.07c.12-.01.24-.02.36-.04.09-.01.17-.03.26-.05v.08s-.07.01-.07.01c-.05,0-.08.02-.1.04-.01.02-.01.06,0,.12,0,.09.03.26.06.5l.07.45c.05.34.08.53.09.56.02.04.06.04.12.04h.07s.01.07.01.07c-.09,0-.19.02-.29.03-.12.02-.23.03-.32.05v-.08Z"/>
            <path   d="m656.26,706.52l-.04-.07c.12-.08.21-.14.27-.18.06-.04.14-.1.24-.19l.05.07-.07.04c-.06.04-.08.07-.07.11,0,.04.08.15.23.36l.3.42c.11.15.2.26.25.31.06.05.13.08.22.08s.18-.03.27-.1c.1-.07.17-.15.2-.25.02-.07.02-.14,0-.22-.03-.08-.1-.21-.23-.39l-.29-.41c-.14-.19-.22-.29-.25-.3-.03-.02-.08,0-.14.04l-.05.03-.05-.07c.12-.08.2-.14.23-.16.05-.04.12-.09.2-.15l.05.07-.06.04s-.07.07-.05.11c0,.04.08.15.21.33l.29.4c.13.18.21.31.25.39.03.08.04.15.04.23s-.02.16-.07.23c-.04.08-.11.15-.19.21-.09.07-.18.11-.27.13-.09.03-.17.03-.23.02-.06-.02-.12-.05-.18-.09-.06-.05-.13-.14-.23-.27l-.14-.2-.24-.33c-.14-.2-.23-.31-.25-.33-.03-.02-.07,0-.12.03l-.07.05Z"/>
            <path   d="m658.87,704.52l-.04.06c-.1-.05-.18-.08-.24-.09-.05-.01-.11,0-.15.01-.05.02-.09.06-.12.1-.05.06-.06.13-.04.21.02.07.07.13.14.18.06.04.12.06.19.06.07,0,.18-.04.32-.11.16-.07.28-.11.38-.1.11.01.2.05.3.11.08.06.15.12.2.21.05.08.07.17.07.26,0,.09-.03.18-.08.26-.04.05-.08.1-.13.14-.05.05-.11.09-.19.14-.1-.08-.23-.18-.4-.29l.04-.07c.12.06.21.09.26.1.06.01.12.01.17-.01.05-.02.1-.06.13-.1.05-.06.06-.14.05-.22-.02-.08-.07-.15-.16-.21-.07-.05-.14-.08-.22-.08-.08,0-.19.03-.34.1-.15.08-.27.11-.36.1-.09,0-.17-.03-.26-.09-.08-.05-.14-.12-.18-.19-.05-.08-.07-.16-.07-.25,0-.09.02-.17.07-.23.06-.08.15-.17.28-.24.1.07.16.12.19.13.04.03.1.07.18.12Z"/>
            <path   d="m649.68,699.65l-.04.08c-.13-.04-.23-.06-.3-.07-.07,0-.13.01-.19.04-.05.03-.1.08-.12.14-.04.08-.04.16,0,.25.04.08.11.14.19.19.08.04.16.05.24.04.08,0,.2-.07.37-.19.17-.11.31-.17.44-.18.12-.01.25.02.37.07.11.06.2.13.27.22.08.09.12.19.14.3.01.11,0,.22-.05.31-.03.06-.07.13-.12.19-.05.06-.12.13-.21.2-.13-.07-.3-.17-.53-.28l.04-.08c.16.05.27.07.34.07.07,0,.13-.01.2-.04.06-.03.11-.08.14-.14.04-.09.04-.17.01-.26-.04-.1-.11-.17-.22-.23-.09-.05-.19-.06-.28-.05-.09,0-.22.07-.39.18-.16.11-.29.17-.4.18-.11.01-.22,0-.34-.06-.1-.05-.18-.11-.25-.2-.07-.09-.11-.19-.12-.29-.02-.1,0-.2.04-.29.05-.11.15-.22.29-.33.14.07.22.11.25.13.06.03.14.07.24.11Z"/>
            <path   d="m651.25,698.72l.17-.16c.06-.07.1-.11.11-.13,0-.02,0-.04,0-.06-.02-.02-.04-.07-.1-.15,0,0-.02-.02-.03-.04l.04-.05c.1.11.17.19.23.25.06.06.14.13.24.23l-.05.05c-.11-.08-.16-.12-.18-.13-.02-.01-.04-.01-.06,0-.02,0-.06.04-.11.09l-.2.19.55.55.3-.29.02-.03s.07-.07.07-.08c.02-.03.02-.06.01-.09,0-.03-.04-.11-.08-.24,0-.01-.01-.03-.02-.06l.05-.05s.1.17.26.44c-.18.16-.34.31-.48.45-.09.1-.2.21-.32.33l-.05-.05.08-.14s.02-.04.02-.05c-.01-.02-.03-.05-.06-.09h-.02s-.21-.24-.21-.24c-.04-.05-.13-.13-.25-.25-.18-.18-.31-.31-.39-.4-.08-.08-.13-.13-.15-.14-.01,0-.02,0-.02,0-.02,0-.05.02-.1.04l-.1.05-.06-.06c.1-.08.21-.18.33-.3.07-.07.23-.23.47-.48.11.11.22.21.34.31l-.05.05c-.1-.07-.17-.11-.21-.13-.03-.01-.05-.01-.07,0-.03,0-.06.04-.12.09l-.28.28.29.3.18.19Z"/>
            <path   d="m654.21,696.66l-.21.86c-.07.32-.13.6-.18.84h-.04c-.04-.07-.14-.26-.31-.56l-.52-.92.14,1.01c.04.24.06.37.08.4.02.03.07.04.15.03h.04s.01.08.01.08c-.1,0-.18.02-.24.02-.04,0-.13.02-.26.05l-.02-.08h.07s.08-.03.09-.05c.01-.02.02-.05.02-.09,0-.12-.02-.26-.04-.4l-.1-.75c-.02-.18-.04-.29-.06-.35,0-.03-.02-.05-.04-.06-.02-.01-.06-.02-.11,0h-.06s-.02-.08-.02-.08c.13,0,.2-.02.23-.02.04-.01.1-.02.17-.03.12.22.26.47.41.73l.38.67.16-.7c.06-.24.12-.54.2-.88.09,0,.15-.01.19-.02.05,0,.12-.02.23-.04v.08s-.06.01-.06.01c-.06,0-.1.03-.11.06,0,.03,0,.12.03.29l.13.96c.03.21.05.33.07.36.02.03.06.04.13.03h.06s.01.07.01.07c-.1.01-.19.02-.28.04-.1.01-.2.03-.31.05v-.08s.06-.01.06-.01c.06,0,.09-.02.1-.06,0-.03,0-.16-.04-.39l-.15-1.07Z"/>
            <path   d="m656.51,696.9c-.06.11-.12.23-.19.36l-.35.67c-.12.24-.18.37-.18.41,0,.04.03.07.09.1l.06.03-.04.07c-.11-.06-.19-.11-.26-.15-.06-.03-.16-.08-.31-.14l.04-.07.09.04s.07.03.09.02c.02,0,.04-.03.06-.06.02-.04.08-.15.18-.34l.32-.63.13-.25c.05-.1.07-.17.07-.2,0-.03-.04-.06-.1-.09l-.05-.03.04-.07c.08.04.18.1.3.16.07.04.15.08.24.11.06.03.1.05.13.07.15.08.25.17.28.27.04.1.02.22-.04.33-.05.09-.11.16-.19.22-.08.06-.17.09-.27.1s-.19,0-.27-.05c-.04-.02-.08-.05-.13-.09l.03-.1c.05.05.09.08.13.1.09.04.17.04.26,0,.09-.04.15-.12.22-.23.05-.11.07-.21.05-.3-.02-.09-.09-.16-.2-.22-.07-.03-.14-.05-.23-.06Z"/>
            <path   d="m657.73,698.38l.19.15c.07.06.11.1.13.1.02,0,.03,0,.05,0,.03-.01.07-.05.14-.11.02,0,.03-.02.04-.03l.06.04c-.1.1-.18.19-.23.25-.05.06-.12.15-.21.26l-.05-.04c.07-.11.1-.17.11-.19,0-.02,0-.04,0-.06,0-.02-.04-.05-.1-.11l-.21-.18-.5.59.32.27.03.02s.07.06.09.06c.03,0,.06,0,.09,0,.04,0,.11-.04.23-.1,0-.01.03-.02.05-.03l.06.04s-.16.12-.42.3c-.18-.16-.34-.31-.49-.44-.11-.09-.23-.19-.36-.29l.05-.06.15.07s.04.01.05,0c.02,0,.05-.03.08-.07l.02-.02.2-.24s.12-.13.23-.27c.16-.19.28-.34.36-.42.08-.09.12-.14.12-.16,0-.01,0-.02,0-.03,0-.01-.02-.04-.05-.09l-.06-.1.05-.06c.09.09.2.19.33.3.07.06.25.2.52.42-.1.12-.19.24-.27.36l-.05-.05c.06-.1.09-.18.1-.22.01-.03.01-.05,0-.07-.01-.03-.04-.06-.1-.11l-.3-.26-.28.32-.18.2Z"/>
            <path   d="m659.21,700.33c-.06.12-.13.28-.2.46l-.15.36c-.02.07-.04.1-.04.12,0,.01,0,.03.01.04l.02.02-.07.05c-.03-.05-.06-.1-.08-.13-.03-.04-.05-.07-.09-.1l.29-.68c.05-.13.1-.26.14-.38l.04-.02s.04.06.05.08c.07.1.16.15.26.16.1.01.2-.02.3-.09.09-.07.14-.14.16-.23.02-.08,0-.17-.07-.25-.04-.06-.1-.11-.18-.15-.12.07-.24.16-.37.25l-.51.38c-.26.18-.39.29-.41.32-.02.03-.02.07.03.12l.05.07-.07.05c-.06-.1-.12-.18-.17-.24-.04-.05-.11-.14-.2-.27l.07-.05.05.06c.04.05.07.07.11.06.03-.01.17-.11.43-.29l.52-.37c.22-.17.34-.26.36-.29.02-.03,0-.07-.04-.13l-.04-.05.07-.05c.08.11.13.19.17.25.03.04.07.09.13.16.07.08.11.14.14.18.07.1.11.19.13.26,0,.07,0,.14-.03.2-.03.06-.08.12-.15.17-.1.07-.21.11-.34.1-.12,0-.23-.05-.34-.14Z"/>
          </g>
        </g>
      </g>
      <path   d="m587,266c-25.47,32.11-63.39,109.07-48.47,490.21"/>
      <path   d="m261.47,756.21c.33-8.37.64-16.75.93-25.12s.54-16.14.78-24.22.45-15.55.64-23.33.35-14.98.5-22.46c.14-7.2.27-14.41.37-21.61s.18-13.85.24-20.78.1-13.31.11-19.97.01-12.78,0-19.17-.06-12.26-.13-18.39-.14-11.75-.24-17.63-.21-11.26-.35-16.88-.28-10.77-.45-16.16-.35-10.3-.56-15.45-.41-9.84-.65-14.76-.48-9.39-.75-14.09-.54-8.96-.84-13.43-.59-8.53-.92-12.8-.65-8.12-1.01-12.18-.7-7.72-1.08-11.58-.75-7.33-1.16-10.99-.8-6.95-1.23-10.43-.84-6.59-1.29-9.88c-.43-3.12-.88-6.24-1.36-9.35-.45-2.95-.92-5.89-1.41-8.84-.47-2.78-.96-5.56-1.47-8.34-.48-2.63-.99-5.25-1.52-7.86-.5-2.47-1.02-4.94-1.56-7.4-.51-2.33-1.05-4.65-1.61-6.96-.53-2.19-1.07-4.37-1.64-6.54-.54-2.05-1.1-4.1-1.68-6.13-.55-1.92-1.12-3.84-1.71-5.75-.56-1.8-1.14-3.59-1.73-5.38-.57-1.68-1.15-3.36-1.76-5.02-.57-1.57-1.16-3.13-1.78-4.69-.58-1.47-1.17-2.92-1.79-4.37-.58-1.37-1.18-2.72-1.8-4.07-.58-1.27-1.19-2.54-1.81-3.79-.59-1.18-1.19-2.36-1.81-3.53-.59-1.1-1.19-2.2-1.81-3.28-.59-1.03-1.19-2.05-1.8-3.05-.58-.96-1.18-1.91-1.79-2.84s-1.17-1.78-1.78-2.65-1.16-1.66-1.76-2.48c-1.54-2.11-3.15-4.16-4.62-6.32"/>
      <g id="Pinza">
        <polygon points="534.73 603.37 495.24 616 534.25 601.95 534.73 603.37 534.73 603.37"/>
      </g>
      <g id="Pinza-2" data-name="Pinza">
        <polygon points="265.29 601.95 304.31 616 264.81 603.37 265.29 601.95 265.29 601.95"/>
      </g>
      <path id="Codera_izquierda" data-name="Codera izquierda"   d="m205.14,443.97c-2.1,30.16-19.25,53.54-38.31,52.22-19.06-1.33-36.27-30.78-34.17-60.95,2.1-30.16,18.31-49.69,37.36-48.36,19.06,1.32,37.22,26.92,35.12,57.09Z"/>
      <path id="Costura_codera" data-name="Costura codera"   d="m201.64,444.44c-1.87,26.9-17.17,47.75-34.16,46.57-17-1.18-32.35-27.46-30.48-54.36,1.87-26.9,16.33-44.32,33.32-43.13,17,1.18,33.2,24.01,31.32,50.92Z"/>
      <path id="Codera_derecha" data-name="Codera derecha"   d="m599.46,449.22c4.33,29.93,23.18,51.97,42.08,49.23,18.91-2.74,33.88-33.39,29.55-63.32-4.34-29.92-21.95-48.19-40.85-45.45-18.91,2.73-35.12,29.61-30.78,59.54Z"/>
      <path id="Costura_codera-2" data-name="Costura codera"   d="m667.75,446.22c-1.87,26.9-17.17,47.75-34.16,46.57-17-1.18-32.35-27.46-30.48-54.36,1.87-26.9,16.33-44.32,33.32-43.13,17,1.18,33.2,24.01,31.32,50.92Z"/>
    </g>
  </g>
    </svg>
  )
}

export default SacoTraseroSvg
