import React from 'react'

const LabelPoloFemale = ({
    className,
    stroke = '#305599'
  }: {
    className: string
    stroke: string
  }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 800 800'
        className={className}
      >
        <g id='Capa_2' data-name='Capa 2'>
          <g id='Etiqueta_lateral' data-name='Etiqueta lateral'>
            <g id='Etiqueta'>
              <g id='Forma'>
                <path
                  stroke={stroke}
                  fill={stroke}
                  d="m588.17,647.54c-1.82.39-2.44.97-1.83,3.49,1.67,6.89,3.16,13.82,4.56,20.76.45,2.22,1.12,2.63,2.83,2.24,10.93-2.51,21.88-4.91,32.82-7.33l-5.78-26.35-.47.1c-10.7,2.38-21.41,4.8-32.14,7.09Z"
                />
              </g>
              <g id='Logo'>
                <path
                  stroke='#123c18'
                  fill='#123c18'
                  d="m609.99,663.05c.4-.36.78-.75,1.21-1.07,1.31-.98,1.99-2.54,2.67-4.1.41-.94.23-1.7-.6-2.39-2.55-2.16-5.41-2.81-8.35-3.09-.1-.01-.21-.03-.32-.1,1.16-.32,2.33.13,3.49.17.33,0,.82.31.97-.15.14-.42-.31-.73-.55-1.04-.39-.5-.81-.96-1.16-1.53,1.98,2.01,4.22,3.26,6.71,3.95,1.08.3,1.5-.07,1.69-1.22.32-1.83.65-3.64-.22-5.53,1.59,1.97,3.45,3.74,3.16,6.84.56-.13,1.04-.22,1.52-.34.5-.12.74.29.97.68.36.6-.25.51-.41.72-.87,1.19-2.19,1.87-2.63,3.6-.29,1.17-1.36,1.67-2.29,2.18-1.87,1.02-3.83,1.79-5.86,2.42Z"
                />
              </g>
              <rect stroke='transparent' width='800' height='800' />
            </g>
          </g>
        </g>
      </svg>
    )
  }
  

export default LabelPoloFemale