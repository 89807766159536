import { Col, Form, Modal, Tooltip } from 'antd'
import Button from 'components/buttons/Button/Button'
import React, { memo, useEffect, useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import style from './list.module.scss'
import {
  INPUTS_INITIAL_STATE_PACKAGES,
  setPackagesForm
} from 'redux/reducers/packages'
import { useDispatch, useSelector } from 'react-redux'
import { PackageListInt } from 'types'
import { RootState } from 'redux/store/store'
import Input from 'components/inputs/Input'
import { cleanSingleUpdatedState } from 'utilities/cleanSingleUpdatedState'
import globalStyle from '../../styles/global.module.scss'
import { useNavigate } from 'react-router-dom'
import SearchUtilModalBody from 'modules/Utils/SearchUtilModalBody/SearchUtilModalBody'
import { usePackageItemList } from 'hooks/utils/usePackageItemList'
import B from 'components/texts/B/B'
import Tag from 'components/tag'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const PackageActionsElements = memo(
  ({
    packageData,
    deletePackageById,
    loadingModal,
    updatePackageById,
    listPackage,
    validateIsOneEnable,
    /*  validateIsOneAuto, */
    checkIfAllAreAuto,
    setAuthorizedModal,
    handleRequestChanges
  }: any) => {
    const {
      OpenSearchModal,
      allUtilsList,
      modalPagination,
      addUtil,
      onHandleSearch,
      handleOpenModal,
      handleCancelModal,
      actualPageModal,
      setActualPageModal,
      loadNewUtil
    } = usePackageItemList(listPackage)
    const { packageForm, editPackageId } = useSelector(
      (state: RootState) => state.packages
    )
    const dispatch = useDispatch()
    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const [openDel, setOpenDel] = useState(false)
    const [OpenEdit, setOpenEdit] = useState(false)
    const [packageFormState, setpackageFormState] = useState(packageForm)

    const navigate = useNavigate()

    const navigateToCreate = () => {
      navigate(`/utiles/${packageData.name}/crear-util`, {
        state: { ...packageData, requestUtil: true }
      })
    }

    useEffect(() => {
      if (editPackageId === packageData.id) setOpenEdit(true)
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setpackageFormState(cleanSingleUpdatedState(e, packageFormState))
    }

    const returnGradeCleanData = (data: any) => {
      let goodData = { ...INPUTS_INITIAL_STATE_PACKAGES }
      Object.keys(packageFormState).forEach((element) => {
        const objKey = element as keyof typeof INPUTS_INITIAL_STATE_PACKAGES
        let objInput = goodData[objKey]
        objInput = {
          ...objInput,
          value: data[objKey]
        }
        goodData = { ...goodData, [objKey]: objInput }
      })
      return goodData
    }

    const handleDeletePackage = () => {
      deletePackageById(packageData.id, handleCancel)
    }
    const handleUpdatePackage = (packageData: any) => {
      updatePackageById(packageData.id, handleCancel, packageFormState)
    }

    const updatePackageModal = (pack: PackageListInt) => {
      setOpenEdit(true)
      setpackageFormState(returnGradeCleanData(pack))

      /*  dispatch(
        setPackagesForm({
          packageForm: returnGradeCleanData(pack),
          editPackageId: pack.id
        })
      ) */
    }

    const handleCancel = () => {
      setOpenDel(false)
      setOpenEdit(false)
      dispatch(
        setPackagesForm({
          packageForm: INPUTS_INITIAL_STATE_PACKAGES,
          editPackageId: ''
        })
      )
    }
    console.log(packageData)

    if (uid.role === 'Director')
      return (
        <Col
          style={{
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '0.8rem'
          }}
          span={10}
        >
          <>
            <Tag
              variation={packageData.open ? 2 : 5}
              title={
                checkIfAllAreAuto()
                  ? 'Lista Autorizada'
                  : packageData.open
                  ? 'En construcción'
                  : 'Lista terminada'
              }
            />
            {validateIsOneEnable() && !checkIfAllAreAuto() && (
              <Button
                label={'Autorizar'}
                rounded
                sector='tertiary'
                onClick={() => setAuthorizedModal(true)}
              />
            )}
          </>
        </Col>
      )

    return (
      <Col
        style={{
          padding: '0 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '0.8rem'
        }}
        span={10}
      >
        {packageData.open ? (
          <>
            <Button
              id={`add-items-list-modal-${packageData.id}`}
              label='Agregar recurso'
              rounded
              sector='tertiary'
              background='white'
              onClick={handleOpenModal}
            />
            <Tooltip title='Editar Paquete'>
              <div>
                <PencilSquareIcon
                  className={`${style['iconStyle']} ${style['notebook-icon']}`}
                  onClick={() => updatePackageModal(packageData)}
                />
              </div>
            </Tooltip>
            <Tooltip title='Eliminar paquete'>
              <div>
                <TrashIcon
                  className={`${style['iconStyle']} ${style['trash-icon']}`}
                  onClick={(e) => setOpenDel(true)}
                />
              </div>
            </Tooltip>
            <Tag variation={2} title={'En construcción'} />
            {validateIsOneEnable() && (
              <Button
                label={
                  checkIfAllAreAuto() ? 'Solicitar modificación' : 'Autorizar'
                }
                rounded
                sector='tertiary'
                onClick={() => setAuthorizedModal(true)}
              />
            )}
            {/* {validateIsOneAuto() && (
              <Button
                label='Solicitar modificación'
                rounded
                sector='tertiary'
                onClick={handleRequestChanges}
              />
            )} */}
          </>
        ) : (
          <>
            <Tag
              variation={5}
              title={
                checkIfAllAreAuto() ? 'Lista Autorizada' : 'Lista terminada'
              }
            />
            {validateIsOneEnable() && !checkIfAllAreAuto() && (
              <Button
                label={'Autorizar'}
                rounded
                sector='tertiary'
                onClick={() => setAuthorizedModal(true)}
              />
            )}
            {!packageData.request_changes ? (
              <Button
                label='Solicitar modificación'
                rounded
                sector='tertiary'
                onClick={handleRequestChanges}
              />
            ) : (
              <Tag variation={2} title={'Modificación solicitada'} />
            )}
          </>
        )}
        <Modal
          title={'Editar Paquete'}
          open={OpenEdit}
          onCancel={handleCancel}
          className={style.createPackageModal}
          footer={
            <div className={style.createLevelModalFooter}>
              <Button
                type='blue'
                rounded={true}
                disabled={loadingModal}
                onClick={() => handleUpdatePackage(packageData)}
                label={'Guardar'}
              />
            </div>
          }
        >
          <div className={style.createLevelModalbody}>
            <Form layout='vertical' autoComplete='off'>
              {Object.entries(packageFormState).map(([key, value]) => {
                return (
                  <Input
                    key={key}
                    name={value.name}
                    placeholder={value.placeholder}
                    label={value.label}
                    type={value.type}
                    value={value.value}
                    onChange={handleChange}
                    onClick={(e: any) => e?.stopPropagation()}
                  />
                )
              })}
            </Form>
          </div>
        </Modal>
        <Modal
          title='Eliminar paquete'
          open={openDel}
          onOk={handleDeletePackage}
          confirmLoading={loadingModal}
          onCancel={handleCancel}
        >
          ¿Estas seguro que deseas eliminar este paquete?
        </Modal>

        <Modal
          title={'Buscar material'}
          open={OpenSearchModal}
          onCancel={handleCancelModal}
          confirmLoading={loadingModal}
          className={globalStyle.confirmModal}
          footer={
            <div className={globalStyle.confirmModalFooter}>
              <Button
                type='blue'
                onClick={navigateToCreate}
                rounded
                label={'Solicitar nuevo recurso'}
              />
            </div>
          }
          width={720}
        >
          <SearchUtilModalBody
            actualPageModal={actualPageModal}
            setActualPageModal={setActualPageModal}
            OpenSearchModal={OpenSearchModal}
            packageData={packageData}
            allUtilsList={allUtilsList}
            modalPagination={modalPagination}
            addUtil={addUtil}
            onHandleSearch={onHandleSearch}
            loadNewUtil={loadNewUtil}
          />
        </Modal>
      </Col>
    )
  }
)
PackageActionsElements.displayName = 'PackageActionsElements'
export default PackageActionsElements
