/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { memo, useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { AllIconsInterface, inputTypeInterface } from '../../../types'
import style from './Select.module.scss'
interface selectOptions {
  value: string
  label: string
}
interface InputProps {
  label?: string
  name: string
  placeholder?:string
  disabled?: boolean
  value?: any
  options: selectOptions[]
  onChange?: (value: string, name: string) => void
}
const CSelect = memo(
  (
    /* {
    label,
    name,
    placeholder,
    validations,
    disabled,
    iconName = '',
    type = 'text',
    form = '',
    maxLength,
    minLength
  } */ props: InputProps
  ) => {
    const handleChange = (value: string) => {
      if (props.onChange) props.onChange(value, props.name)
    }
    if (!props.options) return null
    return (
      <Form.Item className='input-component' label={props.label}>
        <Select
        className={style.customSelect}
          {...props}
          allowClear={true}
          options={props.options}
          onChange={handleChange}
        />
      </Form.Item>
    )
  }
)

CSelect.displayName = 'Select'
export default CSelect
