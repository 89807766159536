import Button from 'components/buttons/Button/Button'
import Icon from 'components/icons'
import B from 'components/texts/B/B'
import S from 'components/texts/S/S'
import React, { memo } from 'react'
import { AllIconsInterface } from 'types'
import style from './EmptyListMessage.module.scss'
interface EmptyListI {
  icon: AllIconsInterface
  text: string
  subtitle?: string
  iconWidth?: number
  iconHeight?: number
  buttonProps?: any[]
}
const EmptyListMessage = memo(
  ({
    icon,
    iconWidth,
    iconHeight,
    text,
    subtitle,
    buttonProps
  }: EmptyListI): JSX.Element => {
    return (
      <div className={style.EmptyListMessage}>
        <div className={style.Icon}>
          <Icon name={icon} width={iconWidth} height={iconHeight} />
        </div>
        <S>{text}</S>
        {subtitle && <B size='Light'>{subtitle}</B>}
        {buttonProps &&
          buttonProps.map((res: any, key: number) => (
            <Button key={key} {...res} />
          ))}
      </div>
    )
  }
)
EmptyListMessage.displayName = 'EmptyListMessage'
export default EmptyListMessage
