import React, { useState, useEffect, Fragment } from 'react'
import { IJsonParameters } from 'interfaces/notebooks-interface'
import DraggableItem from './DraggableItem'
import style from './dragable.module.scss'

const DraggableImgCovers = ({
  customContainerRef,
  setpos,
  setImgId,
  IdEraseImg,
  selectedIdImg,
  objInput,
  containerRef,
  resetIds,
  isText
}: {
  customContainerRef: React.RefObject<HTMLDivElement>
  setpos: () => void
  setImgId: (dat: number, myInfo: IJsonParameters) => void
  IdEraseImg: (id: number) => void
  selectedIdImg: number
  containerRef: any
  objInput: any
  resetIds: () => void
  isText: boolean
}) => {
  const [PictureResolution, setPictureResolution] = useState({
    w: objInput.divSize.width,
    h: objInput.divSize.height
  })
  const [flag, setFlag] = useState(true)
  useEffect(() => {
    if (flag) setFlag(false)
  }, [PictureResolution])

  return (
    <Fragment>
      {false ? (
        <img
          onLoad={(e) => {
            setPictureResolution({
              w: e.currentTarget.naturalWidth,
              h: e.currentTarget.naturalHeight
            })
          }}
          draggable={false}
          className={`${style['div-onload']}`}
          src={objInput.img as string}
        />
      ) : (
        <DraggableItem
          isText={isText}
          customContainerRef={customContainerRef}
          resetIds={resetIds}
          maxResolution={PictureResolution}
          setPos={setpos}
          containerRef={containerRef}
          itemInfo={objInput}
          selectedId={selectedIdImg}
          erase={IdEraseImg}
          text={(id: number, myInfo: any) => setImgId && setImgId(id, myInfo)}
          children={
            <img
              draggable={false}
              className={`${style['img-logo-dragable']}`}
              src={objInput.img as string}
            />
          }
        />
      )}
    </Fragment>
  )
}

export default DraggableImgCovers
