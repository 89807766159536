import React, { useRef, Fragment } from 'react'
import {
  IJsonImgContent,
  IJsonParameters,
  IJsonTextContent
} from 'interfaces/notebooks-interface'

import style from './dragable.module.scss'
import fontFamily from '../wordArtInput/art.module.scss'
import DraggableItem from './DraggableItem'
import DraggableImgCovers from './DraggableImgCovers'
import SvgModuleText from '../wordArtInput/SvgModuleText'
import { RootState } from 'redux/store/store'
import {useSelector} from 'react-redux'
const DraggableCovers = ({
  customContainerRef,
  jsonImgPositions,
  setpos,
  jsonTextPositions,
  setTextId,
  setImgId,
  IdEraseText,
  IdEraseImg,
  selectedIdText,
  selectedIdImg,
  resetIds
}: {
  customContainerRef: React.RefObject<HTMLDivElement>
  setpos: () => void
  jsonImgPositions: IJsonImgContent
  jsonTextPositions: IJsonTextContent
  setTextId: (dat: number, myInfo: IJsonParameters) => void
  setImgId: (dat: number, myInfo: IJsonParameters) => void
  IdEraseText: (id: number) => void
  IdEraseImg: (id: number) => void
  selectedIdText: number
  selectedIdImg: number
  resetIds: () => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { layers } = useSelector((state: RootState) => state.notebooks)

  return (
    <div
      ref={containerRef}
      className={`${style['div-container-editor-covers']}`}
      onMouseLeave={setpos}
      onMouseOver={setpos}
    >
      {(layers===1 || layers===2) && Object.keys(jsonImgPositions).length !== 0 &&
        Object.keys(jsonImgPositions).map((item: any, index: number) => {
          const objKey = item as keyof typeof jsonImgPositions
          const objInput = jsonImgPositions[objKey]

          return (
            <Fragment key={index}>
              <DraggableImgCovers
                customContainerRef={customContainerRef}
                resetIds={resetIds}
                setpos={setpos}
                setImgId={setImgId}
                IdEraseImg={IdEraseImg}
                selectedIdImg={selectedIdImg}
                containerRef={containerRef}
                objInput={objInput}
                isText={false}
              />
            </Fragment>
          )
        })}

      {(layers===1 || layers===3) && Object.keys(jsonTextPositions).length !== 0 &&
        Object.keys(jsonTextPositions).map((item: any, index: number) => {
          const objKey = item as keyof typeof jsonTextPositions
          const objInput = jsonTextPositions[objKey]
          return (
            <Fragment key={index}>
              <DraggableItem
                isText
                customContainerRef={customContainerRef}
                setPos={setpos}
                containerRef={containerRef}
                itemInfo={objInput}
                selectedId={selectedIdText}
                erase={IdEraseText}
                resetIds={resetIds}
                text={(id: number, myInfo: any) =>
                  setTextId && setTextId(id, myInfo)
                }
                children={
                  <SvgModuleText
                    fillull={objInput.text.color}
                    interlin={objInput.text.fontSize}
                    words={objInput.text.label}
                    fontFamily={objInput.text.fontFamily}
                  />
                }
              />
            </Fragment>
          )
        })}
    </div>
  )
}
export default DraggableCovers
