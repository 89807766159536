import { SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { deleteSchoolAdmin } from 'services/schoolAdminServices/schools'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { handleErrors } from 'utilities/handleErrors'
import {
  addNewSupplyMake,
  getSupplyMakeList,
  requestNewUtil,
  updateSchoolUtil,
  uploadUtilFile
} from 'services/utilsServices/utils'
import { Options } from 'types'
import { Form } from 'antd'

export function useUtilsForm() {
  const location = useLocation()
  const [OptionBrand, setOptionBrand] = useState<Options[]>([])

  const INPUTS_INITIAL_STATE = {
    name: {
      label: 'Nombre',
      name: 'name',
      placeholder: 'Nombre del material',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    },
    make: {
      label: 'Marca',
      name: 'make',
      placeholder: 'Marca',
      value: '',
      type: 'autocomplete',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      options:[],
      rules: [
        { required: true, message: '' },
        {
          validator: async (algo: any, make: any) => {
            console.log(make, schoolData);
            
            const exist = schoolData.make.options.filter((res:any)=>res.value===make)
            if (exist.length === 0) {
              return Promise.reject(
                new Error('Debe de escoger una opción de la lista, si no se encuentra la que ud. desea, agregue una nueva.')
              )
            }
          }
        }
      ],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    },
    sku: {
      label: 'SKU',
      name: 'sku',
      placeholder: 'SKU',
      value: '',
      type: 'text',
      required: true,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: '', // puede ser 'warning' o 'error' segun antd
      rules: [
        /* { required: true, message: '' }, */
        {
          validator: async (_: any, sku: any) => {
            if (sku && sku.length > 100) {
              return Promise.reject(
                new Error('El sku no debe ser mayor a 100 dígitos')
              )
            }
          }
        }
      ],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    },
    presentation: {
      label: 'Presentación',
      name: 'presentation',
      placeholder: 'Presentación',
      value: '',
      type: 'select',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    },
    quantity: {
      label: 'Cantidad',
      name: 'quantity',
      placeholder: 'Cantidad',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      disabled: location.state?.status > 4 || location.state?.status === 2,
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, cantidad: any) => {
            const regex = /^[0-9]+$/
            if (!regex.test(cantidad)) return Promise.reject(new Error('Solo números permitidos'))
          }
        }
    
      ]
    },

    image: {
      label: 'Imagen del producto',
      name: 'image',
      placeholder: 'Imagen del producto',
      value: undefined,
      type: 'file',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    }
  }
  const [schoolData, setschoolData] = useState(INPUTS_INITIAL_STATE)
  const [modalMake, setmodalMake] = useState(false)
  const SCHOOL_TYPES = [
    {
      value: 'u',
      label: 'Mixto'
    },
    {
      value: 'm',
      label: 'Masculino'
    },
    {
      value: 'f',
      label: 'Femenino'
    }
  ]
  const PRESENTATION_TYPE = [
    {
      value: 'blizter',
      label: 'Blizter'
    },
    {
      value: 'bolsa',
      label: 'Bolsa'
    },
    {
      value: 'bote',
      label: 'Bote'
    },
    {
      value: 'caja',
      label: 'Caja'
    },
    {
      value: 'paquete',
      label: 'Paquete'
    },
    {
      value: 'pieza',
      label: 'Pieza'
    }
  ]

  const [form] = Form.useForm()
  const [formEditorial] = Form.useForm()

  const navigate = useNavigate()

  const [loadingPost, setloadingPost] = useState(false)
  const [loadingDelete, setloadingDelete] = useState(false)

  useEffect(() => {
    let unmounted2 = false
    const stateData = location.state
    getSupplyMake()
    if (!stateData?.requestUtil) {
      setschoolData((prev) => {
        const IMAGE = prev.image
        IMAGE.value = location.state?.picture
        return { ...prev, image: IMAGE }
      })
      form.setFieldsValue({
        ...location.state
      })
    }
    return () => {
      unmounted2 = true
      if (unmounted2) setschoolData(INPUTS_INITIAL_STATE)
    }
  }, [])


  const getSupplyMake = async () => {
    try {
      const result = await getSupplyMakeList()
      const allOptions = result.data.status
      const cleanOptions  = allOptions.map((res: any) => {
        return { label: res.name, value: res.name, id: res.id }
      })
      setOptionBrand(cleanOptions)
      setschoolData((prev) => {
        const MAKE = prev.make
        MAKE.options = cleanOptions
        return { ...prev, make: MAKE }
      })
    } catch (error) {
      handleErrors(error)
    }
  }

  const checkDisabled = (): boolean => {
    let disabled = false
    /* if (!schoolData.image.value) disabled = true */
    /* Object.keys(schoolData).forEach((res: any) => {
      const objKey = res as keyof typeof INPUTS_INITIAL_STATE
      const objInput = schoolData[objKey]
      if (objInput.value === '') disabled = true
    }) */
    return disabled
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setschoolData((prev) => cleanSingleUpdatedState(e, prev))
  }

  const handleChangeSelect = (value: any) => {
    setschoolData((prev) => {
      const IMAGE = prev.image
      IMAGE.value = value
      return { ...prev, image: IMAGE }
    })
  }
  const handleChangeDate = (
    value: string,
    name: string,
    dateString: string
  ) => {
    setschoolData((prev) =>
      cleanSingleSelectUpdatedState(dateString, name, prev)
    )
  }

  const postNewMakeSupply = async (values: any) => {
    setloadingPost(true)
    try {
      await addNewSupplyMake(values)
      await getSupplyMake()
      setmodalMake(false)
      SuccessToast('Se ha agregado una nueva marca')

    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingPost(false)

    }
  }
  const portRequestNewUtil = async (values: any) => {
    const data = {
      ...values,
      supply_package_quantity: 1,
      id_supply_package: location.state?.id
    }
    setloadingPost(true)
    try {
      const result = await requestNewUtil(data)

      if (
        typeof schoolData.image.value !== 'string' &&
        schoolData.image.value !== undefined
      )
        await uploadUtilFile({
          id: result.data.supplyId,
          files: schoolData.image.value
        })
      setloadingPost(false)
      SuccessToast('el util ha sido creado correctamente')
      navigate(-1)
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const updateUtil = async (id: any, values: any) => {
    
    setloadingPost(true)
    let data = { quantity: values.quantity, id }
    if (location.state?.status < 4)
      data = { ...values, quantity: values.quantity, id }
    try {
      const result = await updateSchoolUtil({ data })
      if (
        schoolData.image.value &&
        typeof schoolData.image.value !== 'string'
      ) {
        await uploadUtilFile({
          id: result.data.supplyIdSchool.id_supply,
          files: schoolData.image.value
        })
      }
      SuccessToast(`El útil "${values.name}" fue editado correctamente`)
      navigate(-1)
      setloadingPost(false)
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const deleteSchoolById = async (school: any) => {
    setloadingDelete(true)
    try {
      await deleteSchoolAdmin(school.id)
      SuccessToast(
        `El colegio "${school.name}" ha sido eliminado correctamente`
      )
      setloadingDelete(false)
      navigate('/lista-de-colegios')
    } catch (err: any) {
      setloadingDelete(false)
      handleErrors(err)
    }
  }
  const onSubmit = (values: any) => {
    if (location.state?.requestUtil) return portRequestNewUtil(values)
    updateUtil(location.state?.id_supply_school, values)
  }

  return {
    OptionBrand,
    schoolData,
    loadingPost,
    loadingDelete,
    portRequestNewUtil,
    deleteSchoolById,
    onSubmit,
    checkDisabled,
    handleChangeSelect,
    handleChange,
    handleChangeDate,
    SCHOOL_TYPES,
    INPUTS_INITIAL_STATE,
    PRESENTATION_TYPE,
    form,
    updateUtil,
    formEditorial,
    postNewMakeSupply,
    modalMake, setmodalMake
  }
}
