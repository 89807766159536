import React, { Fragment } from 'react'
import style from '../poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SportShirtSvg from 'assets/img/uniforms/sportOutfit/sportShirt/male/SportShirtSvg'
import ShadowsSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/ShadowsSportShirt'
import LabelSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/LabelSportShirt'
import GreenTagsSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/GreenTagsSportShirt'
import SportShirtFemaleSvg from 'assets/img/uniforms/sportOutfit/sportShirt/female/SportShirtFemaleSvg'
import ShadowsSportShiertFemale from 'assets/img/uniforms/sportOutfit/sportShirt/female/ShadowsSportShiertFemale'
import LabelSportShirtFemale from 'assets/img/uniforms/sportOutfit/sportShirt/female/LabelSportShirtFemale'

const SportShirtsPic = () => {
  const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
    (state: RootState) => state.uniforms
  )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      {!female ? (
        <Fragment>
          <div
            className={`${
              style[`logo-position-${poloValues.logoPosition.value}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SportShirtSvg stroke='black' className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsSportShirt stroke='#000000' className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelSportShirt stroke='#f5e7ca' className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagsSportShirt stroke='green' className={`${style['svg']}`} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div
            className={`${
              style[`logo-position-${poloValuesFemale.logoPosition.value}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SportShirtFemaleSvg className={`${style['svg']}`} stroke='black' />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsSportShiertFemale
              stroke='#000000'
              className={`${style['svg']}`}
            />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelSportShirtFemale
              stroke='#f5e7ca'
              className={`${style['svg']}`}
            />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      )}
    </div>
  )
}

export default SportShirtsPic
