import React, { memo, useState, useEffect } from 'react'
import style from './Collapse.module.scss'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import B from 'components/texts/B/B'
import { RootState } from 'redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedGrade,
  setSelectedPackage
} from 'redux/reducers/utilsSchoolPageSlice'

interface collapseInterface {
  children: any
  customSideElement?: any
  title: any
  id: string | number
  setCollapseBooks?: () => void
  total?: any
}

const Collapse = memo(
  ({
    children,
    customSideElement,
    title,
    id,
    total,
    customStyle,
    customBg,
    isDroped,
    subTitle = '',
    showSubTitle = false,
    collapseType = 'grade'
  }: {
    children?: any
    customSideElement?: any
    title?: string
    id?: number
    total?: any
    customStyle?: string
    showMiddleText?: boolean
    subTitle?: string
    showSubTitle?: boolean
    onClick?: any
    customBg?: string
    isDroped?: (dat: boolean) => void
    collapseType?: 'grade' | 'package'
  }) => {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const { selectedGradeId, selectedpackageId } = useSelector(
      (state: RootState) => state.utilsSchool
    )
    const collapseId = `collapse-${id}`
    const collapseContentId = `content-${id}`
    const collapseIdSelector = `collapse-${collapseType}-${id}`

    useEffect(() => {
      if (selectedGradeId === id && collapseType === 'grade' && !show)
        clickOnTab()
      if (selectedpackageId === id && collapseType === 'package' && !show) {
        clickOnTab()
        const element = document.getElementById(collapseIdSelector)
        const height = element?.getBoundingClientRect().top
        setTimeout(() => {
          if (height) window.scrollTo(0, height - 60)
        }, 500)
      }
    }, [])

    const clickOnTab = () => {
      let ID = id
      if (show) ID = undefined
      if (collapseType === 'grade') dispatch(setSelectedGrade(ID))
      if (collapseType === 'package') dispatch(setSelectedPackage(ID))
      setShow(!show)
      isDroped && isDroped(!show)
    }

    return (
      <>
        <div className={style.containerCollapsible}>
          <div
            className={`${style.collapsible} ${
              customBg ? customBg : style.defaultBg
            }`}
          >
            <div
              id={collapseIdSelector}
              className={style.collapseTitle}
              onClick={clickOnTab}
            >
              <ChevronDownIcon
                className={`${style.chevron} 
                ${show && style['rotate-180']}`}
                id={collapseId}
              />
              <div className={customStyle}>
                <B>
                  {title}{' '}
                  {total ? (
                    <b style={{ color: '#0F48FF' }}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2
                      }).format(total)}
                    </b>
                  ) : null}
                </B>
                {showSubTitle && (
                  <p
                    style={{
                      display: 'flex',
                      margin: 0
                    }}
                  >
                    {subTitle}
                  </p>
                )}
                {/* {showMiddleText && <B>{subTitle} </B>} */}
              </div>
            </div>

            {customSideElement && customSideElement}
          </div>
          <div
            id={collapseContentId}
            className={`${style.collapsibleContent} 
          ${show ? style.showCollapse : style.hideCollapse}`}
          >
            {children}
          </div>
        </div>
      </>
    )
  }
)

export default Collapse
