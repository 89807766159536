import React, { memo } from 'react'
import { Modal } from 'antd'
import B from 'components/texts/B/B'
import globalStyle from 'styles/global.module.scss'
import Button from 'components/buttons/Button/Button'
import { RootState } from 'redux/store/store'
import { useSelector } from 'react-redux'
import { useEndListModal } from './hook/useEndListModal'
import { useLocation } from 'react-router-dom'

const EndListModal = memo(() => {
  const location = useLocation()
  const renderDiferentTextByModule = () => {
    let text = 'libro'
    if (location.pathname.includes('utiles')) text = 'útil'
    if (location.pathname.includes('cuadernos')) text = 'cuaderno'
    if (location.pathname.includes('uniformes')) text = 'uniforme'
    if (location.pathname.includes('cuotas')) text = 'cuota'
    return text
  }

  const closeListItems = [
    `He revisado los precios correspondientes a cada  ${renderDiferentTextByModule()} y a cada grado y estoy de acuerdo.`,
    'Confirmo que esta lista es la definitiva y será la misma que publicaremos desde el colegio.',
    'En caso de realizar algún cambio posterior en la lista, el colegio asumira el costo correspondiente.',
    `Las poblaciones de alumnos magistrados son los registrados.`
  ]
  const { closeSupplyList } = useSelector((state: RootState) => state.closeList)
  const { loading, handleEndList, handleCloseModal } = useEndListModal()
  return (
    <Modal
      title={`Aviso`}
      open={closeSupplyList}
      centered
      className={globalStyle.confirmModal}
      onCancel={handleCloseModal}
      footer={
        <div className={globalStyle.confirmModalFooter}>
          <Button
            rounded
            onClick={handleCloseModal}
            label={'Cancelar'}
            sector='primary'
            background='white'
          />
          <Button
            rounded
            isLoading={loading}
            onClick={handleEndList}
            label={'Aceptar'}
            sector='primary'
          />
        </div>
      }
    >
      <div className={globalStyle.blueModalBody}>
        <B>
          Antes de crear la lista, por favor, confirme los siguientes términos y
          condiciones:
        </B>
        <ul>
          {closeListItems.map((text: string) => {
            return (
              <li>
                <B>{text}</B>
              </li>
            )
          })}
        </ul>
      </div>
    </Modal>
  )
})
EndListModal.displayName = 'EndListModal'
export default EndListModal
