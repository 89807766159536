import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import FaldaShortEditable from './FaldaShortEditable'
import FaldaShortLateralEditable from './FaldaShortLateralEditable'

const ConjuntoFaldaShort = () => {
  const { selectedMenu, female } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('falda short deportivo'))
    const data = []

    data.push(
      {
        label: 'Faldashort',
        selected: 'falda short deportivo',
        click: () => dispatch(setPantalonesMenu('falda short deportivo'))
      },
      {
        label: 'Faldashort lateral',
        selected: 'falda short lateral deportivo',
        click: () =>
          dispatch(setPantalonesMenu('falda short lateral deportivo'))
      }
    )

    dispatch(setLabelsMenu(data))
  }, [female])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'falda short deportivo':
            return <FaldaShortEditable />
          case 'falda short lateral deportivo':
            return <FaldaShortLateralEditable />

          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}

export default ConjuntoFaldaShort
