import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { InputFormConfig, LevelListInt } from 'types'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { addKeyToRows } from 'utilities/addKeyToTableRows'
import {
  createLevel,
  deleteLevel,
  finishLevelStructure,
  getLevels,
  getMagentoLevels,
  requestModifyStructure,
  updateLevel
} from 'services/schoolUserServices/levels'
import { handleErrors } from 'utilities/handleErrors'
import { useDispatch, useSelector } from 'react-redux'
import {
  INPUTS_INITIAL_STATE,
  INPUTS_INITIAL_STATE_GRADES,
  setLevelsForm,
  setTerminateStructure
} from 'redux/reducers/levelsAndGrades'

import {
  createGrade,
  deleteGrade,
  updateGrade
} from 'services/schoolUserServices/grades'

import { closeModalsFunction } from 'utilities/closeModalFunction'
import { RootState } from 'redux/store/store'
import { useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { getSchoolsAdminById } from 'services/schoolAdminServices/schools'
import { setIDLevel, setNewStructureBook } from 'redux/reducers/books'
import { setIDLevelQuotas, setNewStructureQuota } from 'redux/reducers/quotas'

interface LevelInterface {
  name: string
  id?: number | string
  id_magento?: number | string
}

interface GradeInterface {
  name: string
  male_population?: number
  female_population?: number
  id_level?: number | string
  id?: number | string
}

export interface levelsFormI {
  name: InputFormConfig
  id_magento: InputFormConfig
}

export function useLevelsAndGrades() {
  Object.freeze(INPUTS_INITIAL_STATE)
  Object.freeze(INPUTS_INITIAL_STATE_GRADES)
  const dispatch = useDispatch()
  const { levelsForm, gradesForm, magentoGrades } = useSelector(
    (state: RootState) => state.levelsAndGrades
  )
  const { dataToken } = useDecodeToken()
  const [schoolInfo, setschoolInfo] = useState({
    id: 0,
    name: '',
    sex: '',
    phone: '',
    social_reason: '',
    address: '',
    opening_date: '',
    id_magento: '',
    id_gea: '',
    status: ''
  })
  const [levelList, setLevelList] = useState<LevelListInt[]>([])
  const [LoadingList, setLoadingList] = useState(true)
  const [open, setOpen] = useState(false)
  const [openTerminate, setOpenTerminate] = useState(false)
  const [openStartingModal, setOpenStartingModal] = useState(false)
  const [magentoLevels, setMagentoLevels] = useState([])

  const [loadingModal, setloadingModal] = useState(false)

  const getSchoolData = async (): Promise<void> => {
    const { uid } = dataToken()
    try {
      const result = await getSchoolsAdminById(uid?.id_school as number)
      setschoolInfo(result.data.school)
    } catch (err: any) {
      const Error = err?.response?.data?.message
      if (Error) ErrorToast(Error)
    }
  }

  useEffect(() => {
    let unmounted = false
    const controller = new AbortController()
    if (!unmounted) {
      void getMagentoLevelList()
      void getlevelList(controller)
      void getSchoolData()
    }
    return () => {
      controller.abort()
    }
  }, [])

  const cleanDataToPost = (
    structuredForm: any,
    id?: number | string
  ): LevelInterface => {
    let goodData: LevelInterface = {
      name: '',
      id_magento: '',
      id: undefined
    }

    Object.keys(structuredForm).forEach((element) => {
      const objKey = element as keyof typeof INPUTS_INITIAL_STATE
      const objInput = levelsForm[objKey]
      goodData = { ...goodData, [objKey]: objInput?.value }
    })
    if (id) goodData.id = id
    return goodData
  }

  const cleanDataToPostGrade = (
    structuredForm: any,
    id_level?: number | string,
    id?: number | string
  ): GradeInterface => {
    let goodData: GradeInterface = {
      name: '',
      male_population: undefined,
      female_population: undefined,
      id_level: undefined,
      id: undefined
    }
    Object.keys(structuredForm).forEach((element) => {
      const objKey = element as keyof typeof INPUTS_INITIAL_STATE_GRADES
      const objInput = gradesForm[objKey]
      let value: any = objInput?.value as string
      if (objKey !== 'name') value = parseInt(value)
      if (isNaN(value) && objKey !== 'name') value = undefined

      goodData = {
        ...goodData,
        [objKey]: value
      }
    })
    if (id) goodData.id = id
    if (id_level) goodData.id_level = id_level?.toString()

    return goodData
  }

  const AfterSuccess = (successMessage: string) => {
    getlevelList()
    dispatch(setLevelsForm({ levelsForm: INPUTS_INITIAL_STATE }))
    SuccessToast(successMessage)
    dispatch(setNewStructureBook(true))
    dispatch(setNewStructureQuota(true))
    setloadingModal(false)

  }

  /* ----------------------- LEVELS ---------------------------- */

  const getMagentoLevelList = async () => {
    try {
      const result: any = await getMagentoLevels()
      setMagentoLevels(
        result.data.levels_magento.map((res: { id: number; name: string }) => {
          return { label: res.name, value: res.id }
        })
      )
    } catch (err: any) {
      console.log('magento levels', err)
    }
  }

  const getlevelList = async (controller?: AbortController) => {
    try {
      const result = await getLevels({ controller })
      setLevelList(addKeyToRows(result.data.levels))
      dispatch(setIDLevel(result.data.levels?.[0]?.id))
      dispatch(setIDLevelQuotas(result.data.levels?.[0]?.id))
      setLoadingList(false)
    } catch (err: any) {
      const Error = err?.response?.data?.message
      if (Error) ErrorToast(Error)
      setLoadingList(false)
    }
  }

  const postNewLevel = async () => {
    setloadingModal(true)
    let data: any = cleanDataToPost(levelsForm)
    const MAGENTO_GRADE:any = magentoLevels.filter((res: any) => {
      if (parseInt(data.id_magento) === parseInt(res.value)) return res
    })
    data = {
      ...data,
      name_magento: MAGENTO_GRADE[0]?.label || undefined,
      name: data.name === '' ? undefined : data.name

    }
    try {
      await createLevel(data)
      setOpen(false)
      AfterSuccess('El nivel ha sido creado correctamente')
    } catch (err: any) {
      setloadingModal(false)
      handleErrors(err)
    }
  }

  const editLevel = async (closeModal: any) => {
    setloadingModal(true)
    let data: any = cleanDataToPost(levelsForm, levelsForm.name.id)
    const MAGENTO_GRADE:any = magentoLevels.filter((res: any) => {
      if (parseInt(data.id_magento) === parseInt(res.value)) return res
    })
    data = {
      ...data,
      name_magento: MAGENTO_GRADE[0]?.label || undefined,
      name: data.name === '' ? undefined : data.name

    }
    try {
      await updateLevel(data)
      closeModal()
      AfterSuccess('El nivel ha sido editado correctamente')
    } catch (err: any) {
      setloadingModal(false)
      handleErrors(err)
    }
  }

  const deleteLevelById = async (event: any) => {
    event.stopPropagation()
    setloadingModal(true)
    try {
      await deleteLevel(levelsForm.name.id as string)
      AfterSuccess('El nivel se ha borrado correctamente')
    } catch (err: any) {
      setloadingModal(false)
      handleErrors(err)
    }
  }

  /* ----------------------- GRADES ----------------------------- */
  const postNewGrade = async (cancelModal: any) => {
    setloadingModal(true)
    let data: any = cleanDataToPostGrade(gradesForm, levelsForm.name.id)
    const MAGENTO_GRADE = magentoGrades.filter((res: any) => {
      if (parseInt(data.id_magento) === parseInt(res.value)) return res
    })
    data = {
      ...data,
      name_magento: MAGENTO_GRADE[0]?.label || undefined,
      name: data.name === '' ? undefined : data.name

    }
    try {
      await createGrade(data)
      cancelModal()
      AfterSuccess(
        `El grado: "${gradesForm.name.value}", ha sido añadido en el nivel "${levelsForm.name.value}"`
      )
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingModal(false)
    }
  }

  const updateGradeById = async (data: any, cancelModal: any) => {
    setloadingModal(true)
    let dataBody: any = cleanDataToPostGrade(gradesForm, undefined, data.id)
    const MAGENTO_GRADE = magentoGrades.filter((res: any) => {
      if (parseInt(dataBody.id_magento) === parseInt(res.value)) return res
    })
    dataBody = {
      ...dataBody,
      name_magento: MAGENTO_GRADE[0]?.label || undefined,
      name: dataBody.name === '' ? undefined : dataBody.name
    }
    try {
      await updateGrade(dataBody)
      cancelModal()
      AfterSuccess(`El grado: "${gradesForm.name.value}" fue actualizado"`)
    } catch (err: any) {
      setloadingModal(false)
      handleErrors(err)
    }
  }

  const deleteGradeById = async (data: any, level: any) => {
    setloadingModal(true)
    try {
      await deleteGrade(data.id as string)
      AfterSuccess(
        `El grado: "${data.name}", ha sido eliminado de "${level.name}"`
        )
        closeModalsFunction()
      } catch (err: any) {
      handleErrors(err)
    }
  }
  /* ----------------------- SCHOOL ----------------------------- */
  const finishStructure = async () => {
    setloadingModal(true)
    try {
      await finishLevelStructure()
      cancelTerminateModal()
      getSchoolData()
      AfterSuccess('Se ha cerrado la creacion de la estructura')
      dispatch(setTerminateStructure(true))
      sessionStorage.setItem('terminateStructure', "true")
      setOpenStartingModal(true)
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingModal(false)

    }
  }

  const requestModifyStructureToAdmin = async () => {
    setloadingModal(true)
    try {
      await requestModifyStructure()
      SuccessToast(
        'Se ha solicitado la modificación para este colegio, un administrador se encargará de verificar su pedido'
      )
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingModal(false)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setLevelsForm({ levelsForm: cleanSingleUpdatedState(e, levelsForm) })
    )
  }

  const handleChangeSelect = (value: string, name: string) => {
    dispatch(
      setLevelsForm({
        levelsForm: cleanSingleSelectUpdatedState(value, name, levelsForm)
      })
    )
  }

  /* ----------------------- MODALS ----------------------------- */
  const cancelLevelModal = () => {
    dispatch(setLevelsForm({ levelsForm: INPUTS_INITIAL_STATE }))
    setOpen(false)
  }

  const cancelTerminateModal = () => {
    setOpenTerminate(false)
  }

  const cancelStartingModal = () => {
    setOpenStartingModal(false)
  }
  return {
    levelList,
    LoadingList,
    schoolInfo,
    magentoLevels,
    open,
    openTerminate,
    loadingModal,
    openStartingModal,
    setOpen,
    handleChange,
    handleChangeSelect,
    postNewLevel,
    setOpenTerminate,
    setloadingModal,
    deleteLevelById,
    editLevel,
    postNewGrade,
    deleteGradeById,
    updateGradeById,
    cancelLevelModal,
    cancelTerminateModal,
    finishStructure,
    cancelStartingModal,
    requestModifyStructureToAdmin,
  }
}
