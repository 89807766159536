import PathSujecionSpiralOutside from 'assets/img/notebooks/profesional_20x26/PathSujecionSpiralOutside'
import PathPortada from 'assets/img/notebooks/profesional_20x26/PathPortada'
import PathSujecionPortadasVertical from 'assets/img/notebooks/profesional_20x26/PathSujecionPortadasVertical'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './notebooksEitable.module.scss'
import DraggableImgCenterProporsal from '../EditorDiv/DraggableImgCenterProporsal'
import { setPositionsCovers } from 'redux/reducers/notebooksSlice'
import VerticalEditabledraggable from './VerticalEditabledraggable'

type VerticalPortadasEditableProps = {
  showItems?: Array<number>
  previewHalf?: boolean
  viewMode?: number
}

const VerticalPreview = ({
  showItems = [1, 2],
  previewHalf = false,
  viewMode = -1
}: VerticalPortadasEditableProps) => {
  const {
    notebookFrontValues,
    notebookSelected,
    outsidePropuesta,
    outsidePropuestaBack,
    positionCovers,
    edithUpload,
    isCoverOnEdith
  } = useSelector((state: RootState) => state.notebooks)

  const { spine_color, binding_type, binding_color, rounded, cover_design } =
    notebookFrontValues

  const dispatch = useDispatch()

  return (
    <div
      className={
        previewHalf
          ? style['div-notebook-svg-sticky-preview']
          : style['div-notebook-svg-sticky']
      }
    >
      {cover_design.value === 'design' && !previewHalf && viewMode == 0 && (
        <VerticalEditabledraggable viewMode={viewMode} />
      )}
      {cover_design.value === 'design' && viewMode == 1 && (
        <VerticalEditabledraggable viewMode={viewMode} />
      )}
      {cover_design.value === 'design' && viewMode == 2 && (
        <VerticalEditabledraggable viewMode={viewMode} />
      )}
      {showItems.map((item: number, index: number) => {
        let viewBox = item === 1 ? '149 0 620 800' : '149 0 620 800'
        return (
          <div
            key={index}
            className={`${style['div-polo-notebook-relative']} 
                ${item === 1 && style['transform-invert']}`}
          >
            <div className={`${style[`logo-position-`]}`}></div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathPortada
                viewBox={viewBox}
                showSizes={previewHalf ? 1 : item}
                className={`${style['svg']}`}
                notebookSelected={notebookSelected}
                colorLomo={spine_color.value as string}
                rounded={rounded.value as boolean}
              />
            </div>
            <div
              className={`${
                viewMode == 0
                  ? style['div-shadows-svg-absolute']
                  : style['div-img-upload-2']
              }`}
              style={{ marginTop: '-8.5%', marginLeft: '-1%' }}
            >
              {cover_design.value === 'upload' &&
                !edithUpload.left &&
                !edithUpload.right && (
                  <DraggableImgCenterProporsal
                    setPositions={(dat: { back: number; front: number }) =>
                      dispatch(setPositionsCovers(dat))
                    }
                    positions={positionCovers}
                    edithSides={edithUpload}
                    item={item}
                    onlyShow
                  />
                )}
            </div>
            {isCoverOnEdith && (
              <div
                className={`${style['div-shadows-svg-absolute']}`}
                style={{ zIndex: 8 }}
              >
                {binding_type.value === 'Espiral' ? (
                  <PathSujecionSpiralOutside
                    className={`${style['svg']}`}
                    viewBox={viewBox}
                    show={2}
                    spiralColor={binding_color.value as string}
                  />
                ) : (
                  <PathSujecionPortadasVertical
                    color={binding_color.value as string}
                    viewBox={viewBox}
                    className={`${style['svg']}`}
                    show={1}
                  />
                )}
              </div>
            )}
          </div>
        )
      })}
      <div className={`${style['portadas-sujecion-preview']}`}>
        {viewMode === 0 && (
          <div className={`${style['relative-sujecion']}`}>
            {[1, 2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '-480 2 615 800' : '695 2 615 800'
              if (binding_type.value === 'Espiral')
                viewBox = item === 1 ? '110 0 620 800' : '140 0 620 800'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} `}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <PathSujecionSpiralOutside
                        className={`${style['svg']}`}
                        viewBox={viewBox}
                        show={item}
                        spiralColor={binding_color.value as string}
                      />
                    ) : (
                      <PathSujecionPortadasVertical
                        color={binding_color.value as string}
                        viewBox={viewBox}
                        className={`${style['svg']}`}
                        show={item}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {viewMode === 1 && (
          <div className={`${style['relative-sujecion']}`}>
            {[2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '-450 0 620 800' : '530 115 480 570'
              if (binding_type.value === 'Espiral') viewBox = '10 0 420 800'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} `}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <PathSujecionSpiralOutside
                        className={`${style['svg']}`}
                        viewBox={viewBox}
                        show={item}
                        spiralColor={binding_color.value as string}
                      />
                    ) : (
                      <PathSujecionPortadasVertical
                        color={binding_color.value as string}
                        viewBox={viewBox}
                        className={`${style['svg']}`}
                        show={item}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {viewMode === 2 && (
          <div
            className={`${style['relative-sujecion']}`}
          >
            {[1, 2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '450 0 620 800' : '530 115 482 570'
              if (binding_type.value === 'Espiral') viewBox = '-20 100 480 600'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} ${style['transform-invert']}`}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <PathSujecionSpiralOutside
                        className={`${style['svg']}`}
                        viewBox={viewBox}
                        show={item}
                        spiralColor={binding_color.value as string}
                      />
                    ) : (
                      <PathSujecionPortadasVertical
                        color={binding_color.value as string}
                        viewBox={viewBox}
                        className={`${style['svg']}`}
                        show={item}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default VerticalPreview
