import React from 'react'

const LabelFaldaShort = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path d="m506.17,295.44c-.83.21-1.12.47-.82,1.49.81,2.79,1.53,5.6,2.21,8.42.22.9.53,1.05,1.31.84,5-1.35,10.02-2.65,15.03-3.96l-2.8-10.69-.21.06c-4.9,1.28-9.8,2.59-14.72,3.84Z"/>
      <path d="m514.25,302.14c.18-.16.36-.33.55-.47.6-.44.9-1.1,1.2-1.76.18-.4.09-.71-.29-.97-1.18-.82-2.5-1.01-3.85-1.03-.05,0-.1,0-.14-.03.53-.16,1.07-.01,1.6-.03.15,0,.38.11.44-.09.06-.18-.15-.29-.26-.41-.18-.2-.38-.38-.54-.6.92.77,1.96,1.22,3.1,1.44.5.09.69-.07.77-.55.14-.76.28-1.52-.13-2.27.74.77,1.6,1.44,1.49,2.73.26-.07.48-.12.7-.18.23-.07.34.1.45.25.17.24-.11.22-.18.31-.39.52-1,.83-1.19,1.56-.13.49-.61.73-1.04.96-.85.47-1.75.85-2.68,1.16Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelFaldaShort
