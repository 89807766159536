/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { memo } from 'react'
import {Form, DatePicker } from 'antd'
import style from './Date.module.scss'
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

interface InputProps {
  label?: string
  name: string
  placeholder?: string
  validations?: any
  disabled?: boolean
  form?: string
  value: any
  bordered?: boolean
  onChange?: (value:string,name:string,dateString:string) => void
  onClick?: (e: any) => void
}

const dateFormat = 'YYYY/MM/DD';

const CDate = memo((props: InputProps) => {
  let VALUE = props.value

  if(VALUE!=='') VALUE = dayjs(props.value, dateFormat)


  const handleChange = (date:any, dateString:string) => {
    if (props.onChange) props.onChange(props.value, props.name, dateString)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
  };

  return (
    <Form.Item  label={props.label}>
      <DatePicker className={style.inputDate} value={VALUE} disabledDate={disabledDate} onKeyDown={e=>e.preventDefault()} style={{ width: '100%' }} id={props.name} onChange={handleChange} />
    </Form.Item>
  )
})

CDate.displayName = 'CDate'
export default CDate
