import React from 'react'

const SueterSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Contorno_sueter" data-name="Contorno sueter"   d="m557.1,204.7s-6.32,63.56-1.49,83.38c6.87,28.19,13.27,55.73,13.27,55.73,0,0-9.18,79.02-4.11,112.43,10.19,67.23-4.51,223.28-4.51,223.28,0,0-92.47,5.93-161.49,6-89.59.09-157.07-7.59-157.07-7.59h0s-15.42-147.67-9.46-228.98c2.74-37.45-1.13-107.04-1.13-107.04,0,0,7.65-30.38,16.17-82.49,5.52-33.74-12.92-135.73-12.92-135.73,0,0,6.77-4.25,12.23-7.9,21.99-14.68,77.98-34.8,77.98-34.8,0,0,17.25,12.2,74.23,11.57,63.25-.7,76.21-11.57,76.21-11.57,0,0,52.39,16.44,87.58,44.84l-5.49,78.87Z"/>
      <g id="Manga_izquierda" data-name="Manga izquierda">
        <path id="Manga_izquierda-2" data-name="Manga izquierda"   d="m234.36,123.7s-43.27,4.61-75.82,119.01c-12.51,43.97-24.47,74.2-31.13,102.72-18.51,79.26-24.57,126.16-24.57,126.16,0,0-.3,64.01-.79,146.91-.12,21.57,4.21,50.26,4.21,50.26l3.46,43.84s29.09,3.6,31.79,3.6c4.82,0,31.84-3.85,31.84-3.85l3.26-43.38s4.53-20.5,3.24-39.85c-1.84-27.79-.59-23.86.09-42.24,1.55-41.6,4.03-88.2,7.79-111.71,12.52-78.32,43.37-134.15,43.37-134.15,0,0,17.25-69.6,17.27-97.15.03-45.48-14.02-120.16-14.02-120.16Z"/>
        <path id="Manga_izquierda-3" data-name="Manga izquierda"   d="m105.64,664.29s24.25-3.75,35.49-2.78c11.24.97,36.23,3.65,36.23,3.65"/>
        <path id="Linea_1_puño_izquierda" data-name="Linea 1 puño izquierda"   d="m107.84,695.44s24.25-3.75,35.49-2.78c11.24.97,31.82,3.18,31.82,3.18"/>
        <path id="Linea_2_puño_izquierda" data-name="Linea 2 puño izquierda"   d="m107.84,684.21s24.25-3.75,35.49-2.78c11.24.97,31.82,3.18,31.82,3.18"/>
      </g>
      <g id="Manga_derecha" data-name="Manga derecha">
        <path id="Manga_derecha-2" data-name="Manga derecha"   d="m562.59,125.84s46.32,5.27,78.87,119.67c12.51,43.97,24.47,74.2,31.13,102.72,18.51,79.26,24.57,126.16,24.57,126.16,0,0,.31,64.01.79,146.91.12,21.57-4.21,50.26-4.21,50.26l-3.46,43.84s-29.09,3.6-31.79,3.6c-4.82,0-31.84-3.85-31.84-3.85l-3.26-43.38s-4.53-20.5-3.24-39.85c1.84-27.79.59-23.86-.09-42.24-1.56-41.6-4.03-88.2-7.8-111.71-12.52-78.32-43.37-134.15-43.37-134.15,0,0-12.53-36.49-14.58-64.82-2.71-37.54,8.28-153.15,8.28-153.15Z"/>
        <path id="Manga_derecha-3" data-name="Manga derecha"   d="m623.5,670.5s25.3-4.15,36.54-3.18c11.24.97,34.09,3.65,34.09,3.65"/>
        <path id="Linea_1_puño_derecha" data-name="Linea 1 puño derecha"   d="m625.68,698.88s24.25-3.75,35.49-2.78c9.46.81,25.53,2.51,30.39,3.03"/>
        <path id="Linea_2_puño_derecha" data-name="Linea 2 puño derecha"   d="m624.55,686.8s24.25-3.75,35.49-2.78c11.24.97,32.46,3.48,32.46,3.48"/>
      </g>
      <g id="Cuello">
        <path id="Cuello_interior" data-name="Cuello interior"   d="m468.5,108.42c-.75,2.01-6.72,4.01-9.54,4.54-14.1,2.65-28.41,4.21-42.96,5.19-11.71.79-23.26.26-34.83-.23-11.81-.5-23.46-2.5-35.2-3.57-2.68-.24-5.34-.63-7.97-1.16-1.16-.23-2.55-.37-3.61-.9-1-.5-1.86-1.3-2.88-1.79"/>
        <g id="Cuello_izquierda" data-name="Cuello izquierda">
          <path   d="m399.5,256.19c-4.93-2.99-33.4-21.39-57.47-61.43-17.34-28.84-35.64-99.5-37.24-105.72l19.84-7.37c1.54,7.09,17.32,78.03,35.41,103.64,18.05,25.54,37.51,44.38,39.45,46.24v24.64Z"/>
          <path d="m324.27,82.34c.98,4.46,4.8,21.49,10.33,40.8,8.62,30.13,17.05,51.15,25.04,62.46,17.67,25.01,36.7,43.61,39.36,46.17v23.53c-6.56-4.11-33.56-22.55-56.54-60.79-16.97-28.22-34.88-96.64-37.07-105.15l18.87-7.02m.73-1.34l-20.8,7.73s19.1,75.84,37.4,106.29c26.7,44.42,58.4,62.04,58.4,62.04v-25.73s-20.43-19.26-39.54-46.32c-19.12-27.05-35.46-104.02-35.46-104.02h0Z"/>
        </g>
        <path id="Cuello_derecha" data-name="Cuello derecha"   d="m400,231.34l-.07,25.4s32.21-17.62,58.91-62.04c0,0,33.15-54.55,37.41-105.87,0,0-21.27-7.82-21.27-7.82-6.71,31.62-13.02,64.42-28.66,93.09-11.71,21.47-29.49,39.71-46.32,57.25"/>
        <path id="Linea_1_cuello_izquierda" data-name="Linea 1 cuello izquierda"   d="m310.11,86.67s10.13,55.91,38.61,105.9c16.54,29.03,50.95,55.87,50.95,55.87"/>
        <path id="Linea_2_cuello_izquierda" data-name="Linea 2 cuello izquierda"   d="m318.19,83.86s5.68,50.67,34.17,100.66c16.54,29.03,47.32,54.97,47.32,54.97"/>
        <path id="Linea_1_cuello_derecha" data-name="Linea 1 cuello derecha"   d="m489.23,86.67s-8.54,55.91-37.02,105.9c-16.54,29.03-52.7,55.93-52.7,55.93"/>
        <path id="Linea_2_cuello_derecha" data-name="Linea 2 cuello derecha"   d="m481.65,83.32s-7.59,48.88-31.44,98.7c-14.43,30.13-50.61,57.46-50.61,57.46"/>
      </g>
      <g id="Cintura">
        <path id="Cintura-2" data-name="Cintura"   d="m239.39,643.41s101.13,10.3,160.49,8.74c77.31-2.03,163.33-8.74,163.33-8.74"/>
        <path id="Linea_1_cintura" data-name="Linea 1 cintura"   d="m240.43,664.26s93.67,9.62,155.47,9.62c75.84,0,166.26-9.62,166.26-9.62"/>
        <path id="Linea_2_cintura" data-name="Linea 2 cintura"   d="m240.43,655.16s93.67,9.62,155.47,9.62c75.84,0,166.26-9.62,166.26-9.62"/>
      </g>
      <g id="Ubicación_logo" data-name="Ubicación logo">
        <circle   cx="284.91" cy="266.91" r="25.21"/>
        <circle   cx="514.91" cy="266.91" r="25.21"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default SueterSvg
