import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SportShirtSvg from 'assets/img/uniforms/sportOutfit/sportShirt/male/SportShirtSvg'
import ShadowsSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/ShadowsSportShirt'
import LabelSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/LabelSportShirt'
import GreenTagsSportShirt from 'assets/img/uniforms/sportOutfit/sportShirt/male/GreenTagsSportShirt'
import SportShirtFemaleSvg from 'assets/img/uniforms/sportOutfit/sportShirt/female/SportShirtFemaleSvg'
import ShadowsSportShiertFemale from 'assets/img/uniforms/sportOutfit/sportShirt/female/ShadowsSportShiertFemale'
import LabelSportShirtFemale from 'assets/img/uniforms/sportOutfit/sportShirt/female/LabelSportShirtFemale'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const SportShirtEditable = () => {
  const { pictureLogo, female, sportShirtMale, sportShirtFemale } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Playera deportiva',
        description: 'Descripcion de la playera deportiva'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        {female ? (
          <Fragment>
            {sportShirtFemale.logoPosition.value !== '' && (
              <div
                className={`${
                  style[
                    `logo-position-shirtdepo-fema-${sportShirtFemale.logoPosition.value}`
                  ]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}
            <div className={`${style['div-polo-svg-absolute']}`}>
              <SportShirtFemaleSvg
                fill={sportShirtFemale.color.value as string}
                stroke='black'
                className={`${style['svg']}`}
                neckType={sportShirtFemale.neckType.value as string}
                neckColor={sportShirtFemale.neckColor.value as string}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsSportShiertFemale
                stroke='#000000'
                fill={sportShirtFemale.color.value as string}
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelSportShirtFemale
                stroke='#f5e7ca'
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}></div>
          </Fragment>
        ) : (
          <Fragment>
            {sportShirtMale.logoPosition.value !== '' && (
              <div
                className={`${
                  style[
                    `logo-position-shirtdepo-${sportShirtMale.logoPosition.value}`
                  ]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}
            <div className={`${style['div-polo-svg-absolute']}`}>
              <SportShirtSvg
                fill={sportShirtMale.color.value as string}
                stroke='black'
                className={`${style['svg']}`}
                neckType={sportShirtMale.neckType.value as string}
                neckColor={sportShirtMale.neckColor.value as string}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsSportShirt
                fill={sportShirtMale.color.value as string}
                stroke='#000000'
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelSportShirt stroke='#f5e7ca' className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsSportShirt
                stroke='green'
                className={`${style['svg']}`}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default SportShirtEditable
