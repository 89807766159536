import React from 'react'

const Acotaciones = ({
  className,
  notebookType,
  viewBox = '0 0 800 800',
  show
}: {
  className: string
  notebookType: number
  viewBox?: string
  show: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
    >
      <title>Path acotaciones</title>
      <g id='Capa_2' data-name='Capa 2' stroke='black'>
        <g id='Path_acotaciones' data-name='Path acotaciones'>
          <g id='Path_acotaciones-2' data-name='Path acotaciones'>
            <g id='portada_contraportada' data-name='portada contraportada'>
              <rect x='150.2' y='80.5' width='527.8' height='682' />
              <path d='M677.5,81V762H150.7V81H677.5m1-1H149.7V763H678.5V80Z' />
            </g>
            {show === 2 && (
              <g>
                <g fill='black' stroke='transparent'>
                  {(() => {
                    switch (notebookType) {
                      case 1:
                        return (
                          <g id='Profesional'>
                            <text transform='translate(156.88 33.42)'>
                              20 cm
                            </text>
                            <g id='_26_cm' data-name='26 cm'>
                              <text transform='translate(723.88 105.42)'>
                                26 cm
                              </text>
                            </g>
                          </g>
                        )
                      case 7:
                        return (
                          <g id='College'>
                            <text
                              id='_19.5_cm'
                              data-name='19.5 cm'
                              transform='translate(156.88 33.42)'
                            >
                              1
                              <tspan x='6.14' y='0'>
                                9
                              </tspan>
                              <tspan x='13.12' y='0'>
                                .5 cm
                              </tspan>
                            </text>
                            <g id='_25_cm' data-name='25 cm'>
                              <text transform='translate(723.88 105.42)'>
                                25 cm
                              </text>
                            </g>
                          </g>
                        )
                      case 5:
                        return (
                          <g id='Frances'>
                            <text
                              id='_16_cm'
                              data-name='16 cm'
                              transform='translate(156.88 33.42)'
                            >
                              16 cm
                            </text>
                            <g id='_21_cm' data-name='21 cm'>
                              <text transform='translate(723.88 105.42)'>
                                21 cm
                              </text>
                            </g>
                          </g>
                        )
                      case 2:
                        return (
                          <g id='Block_carta' data-name='Block carta'>
                            <text
                              id='_21_cm-2'
                              data-name='21 cm'
                              transform='translate(156.88 33.42)'
                            >
                              21 cm
                            </text>
                            <g id='_27_cm' data-name='27 cm'>
                              <text transform='translate(723.88 105.42)'>
                                27 cm
                              </text>
                            </g>
                          </g>
                        )

                      case 3:
                        return (
                          <g id='Block_esquela' data-name='Block esquela'>
                            <text
                              id='_14_cm'
                              data-name='14 cm'
                              transform='translate(156.88 33.42)'
                            >
                              <tspan>1</tspan>
                              <tspan x='5.95' y='0'>
                                4 cm
                              </tspan>
                            </text>
                            <g id='_21_cm-3' data-name='21 cm'>
                              <text transform='translate(723.88 105.42)'>
                                21 cm
                              </text>
                            </g>
                          </g>
                        )
                      default:
                        return <></>
                    }
                  })()}
                </g>

                <g>
                  <g>
                    <g id='Medida_ancho' data-name='Medida ancho'>
                      <line x1='149.7' y1='33.48' x2='149.7' y2='50.69' />
                      <line x1='678.7' y1='33' x2='678.7' y2='50.21' />
                      <line x1='149.7' y1='42.09' x2='678.5' y2='42.09' />
                    </g>
                    <g id='Medida_alto' data-name='Medida alto'>
                      <line x1='714.96' y1='81.35' x2='714.96' y2='762.74' />
                      <line x1='723.56' y1='81.35' x2='706.36' y2='81.35' />
                      <line x1='724.05' y1='763' x2='706.84' y2='763' />
                    </g>
                  </g>
                </g>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Acotaciones
