import React, { useState } from 'react'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import style from './modals.module.scss'
import { useNavigate } from 'react-router-dom'

import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { createNewNotebook } from 'services/notebooks/newNotebookServices'

const CreateNewNotebookModal = ({
  create,
  show,
  onOk,
  onCancel,
  buttonClick,
  data
}: {
  create: boolean
  show: boolean
  onCancel: () => void
  onOk: () => void
  buttonClick: () => void
  data: any
}) => {
  const navigate = useNavigate()
  const [matter, setMatter] = useState('')

  const newNotebook = () => {
    createNewNotebook({
      matter,
      id_package: data.id
    })
      .then((data) => {
        SuccessToast(`El cuaderno ${matter} se creó con éxito`)
        navigate(`/cuadernos/select-type/${data.data.notebookId}`)
      })
      .catch(() => {
        ErrorToast('El cuaderno no pudo ser creado error')
      })
  }
  return (
    <Modal
      title={`Crear nuevo cuaderno para 
      "${data !== null ? data.name + '" id:' + data.id : ''}`}
      centered
      open={show}
      onOk={onOk}
      width={800}
      onCancel={onCancel}
      footer={[
        <div className={`${style['footer-style']}`}>
          <Button
            onClick={onCancel}
            rounded
            sector='tertiary'
            background='white'
            label='Cancelar'
          />
          <Button onClick={newNotebook} rounded label='Crear' />
        </div>
      ]}
    >
      <div className={`${style['div-container']}`}>
        <p className={`${style['label-modal']}`}>
          Nombre para guardar el cuaderno
        </p>

        <input
          className={`${style['modal-input']}`}
          value={matter}
          onChange={(e) => setMatter(e.target.value)}
          placeholder='Nombre de la materia'
        />
      </div>
    </Modal>
  )
}

export default CreateNewNotebookModal
