import { AUTHSERVICE, AUTHSERVICE_FORMDATA } from '../config'
import { IDataPackages } from 'interfaces/utils-interface'

export interface UtilsInterface {
  image: string
  price: string
  special_price?: string
  refund_percent?: string
  on_stock_time?: string
  provider?: string
  name: string
  supply_make_name: string
  id?: number
  quantity: string
  sku: string
  presentation: string
  comments: string
  make?: string
  status?: string | number
  refill_time?: string
  return_percentage?: string
}

export interface UpdateUtilsStatusInterface {
  status: number
  id: number[]
}
export const getPackagesByLevel = async ({
  id
}: {
  id: string | number
}): Promise<any> => {
  let url = `/supply-package/level/${id}`
  return await AUTHSERVICE().get(url)
}

export const createPackage = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply-package', data)
}

export const updatePackage = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/supply-package', data)
}

export const deletePackage = async (id: any): Promise<any> => {
  return await AUTHSERVICE().delete(`/supply-package/${id}`)
}

export const getUtilsByPackageId = async (
  id: number | string
): Promise<any> => {
  let url = `/supply-school/package/${id}`
  return await AUTHSERVICE().get(url)
}
export const OLDgetUtilsByPackageIdOLD = async (
  id: number | string
): Promise<any> => {
  let url = `supply-package/detail/package/${id}`
  return await AUTHSERVICE().get(url)
}
//hector--------
export const getPagackeUtilsById = async (idOfPackage: number) => {
  const results: IDataPackages = await getUtilsByPackageId(idOfPackage)
  return results
}
//hector--------

export const addUtilToPackageById = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply-school', data)
}
export const deleteUtilFromPackage = async (id: string): Promise<any> => {
  return await AUTHSERVICE().delete(`/supply-package/detail/${id}`)
}
export const OLDaddUtilToPackageByIdOLD = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply-package/detail', data)
}
export const getUtilsPaginated = async (
  page: number,
  search: string = '',
  status?: number
): Promise<any> => {
  let url = `supply?page=${page}&sort=desc&order=id&limit=10`
  if (search!== '') url += `&search=${search}`
  if (status) url += `&status=${status}`
  return await AUTHSERVICE().get(url)
}

export const getUtilsPaginatedAdmin = async (
  page: number,
  search: string = '',
  status?: number,
  idSchool?: number
): Promise<any> => {
  let url = `supply-school?page=${page}&sort=desc&order=id&&limit=10`
  if (search !== '') url += `&search=${search}`
  if (status) url += `&status=${status}`
  if (idSchool) url += `&idschool=${idSchool}`

  return await AUTHSERVICE().get(url)
}

export const getSupplyMakeList = async (): Promise<any> => {
  return await AUTHSERVICE().get('/supply/make')
}

export const createUtils = async (data: UtilsInterface): Promise<any> => {
  return await AUTHSERVICE().post('/school/', data)
}

export const downloadUtilsList = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/supply/download`)
}

export const addNewSupplyMake = async (data: {
  name: string
}): Promise<any> => {
  return await AUTHSERVICE().post('/supply/make', data)
}

export const requestNewUtil = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply-school/new', data)
}

export const ODLrequestNewUtilOLD = async (data: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply-package/detail-new', data)
}

export const uploadUtilFile = async (data: any): Promise<any> => {
  return await AUTHSERVICE_FORMDATA().post('/supply/loadfile', data)
}

export const updateUtils = async ({ data }: { data: any }): Promise<any> => {
  return await AUTHSERVICE().put(`/supply-school/`, data)
}

export const updateUtilsAdmin = async ({
  data
}: {
  data: any
}): Promise<any> => {
  return await AUTHSERVICE().put(`/supply/school`, data)
}

export const updateUtilsAdminSuggest = async ({
  data
}: {
  data: any
}): Promise<any> => {
  return await AUTHSERVICE().put(`/suggestions/authorization/supply`, data)
}

export const updateSchoolUtil = async ({
  data
}: {
  data: { quantity: string; id: string }
}): Promise<any> => {
  return await AUTHSERVICE().put(`/supply-school/`, data)
}

export const updateUtilsStatus = async ({
  data
}: {
  data: UpdateUtilsStatusInterface
}): Promise<any> => {
  return await AUTHSERVICE().put(`/supply-school/status`, data)
}

export const addUtilToCatalog = async (data: {
  id: number
  is_active: boolean
}): Promise<any> => {
  return await AUTHSERVICE().put(`/supply-school/approve`, data)
}

export const terminatePackageUpdate = async (id: string): Promise<any> => {
  return await AUTHSERVICE().put(`supply-package/close/${id}`)
}
export const openPackageUpdate = async (id: string): Promise<any> => {
  return await AUTHSERVICE().put(`supply-package/open/${id}`)
}
export const deleteUtils = async (id: string | number): Promise<any> => {
  return await AUTHSERVICE().delete(`/school/${id}`)
}

export const getSchoolsAdminById = async (
  id: string | number
): Promise<any> => {
  return await AUTHSERVICE().get(`/school/id/${id}`)
}

export const downloadSchoolList = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/school/download`)
}

export const downloadExcelFormat = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/supply/download-format`)
}

export const uploadBooks = async (file: any): Promise<any> => {
  return await AUTHSERVICE().post('/supply/upload-supply', file)
}
