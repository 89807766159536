import { memo } from 'react'
// import { AutoComplete, Input, Select, SelectProps } from 'antd'

import styles from './search-books.module.scss'
import './reset.scss'
import { Input, SelectProps, Space } from 'antd'

// const opt = [
//   {
//     value: 1,
//     label: 'American Bookstore'
//   },
//   {
//     value: 2,
//     label: 'Amazon'
//   },
//   {
//     value: 3,
//     label: 'Base de datos'
//   }
// ]

interface SearchBooksProps {
  options?: SelectProps<object>['options']
  onSearch: (search: string) => Promise<any>
  // onAutoComplete?: (search: string) => Promise<any>
  id?:string
  timer?: number
  placeholder?: string
  
}

const SearchBarModule = memo((props: SearchBooksProps) => {


  let timeOutId: ReturnType<typeof setTimeout>

  // const handleSearch = (value: string) => {
  //   if (timeOutId) {
  //     clearTimeout(timeOutId)
  //   }
  //   timeOutId = setTimeout(() => {
  //     props.onAutoComplete(value)
  //   }, props.timer)
  // }

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(() => {
      props.onSearch(e.target.value)
     
    }, props.timer)
  }

  // const onSelect = (value: string) => {
  //   props.onSearch(value)
  // }

  // const onHandleChange = (value:string) =>{
  //   console.log(value)
  //   props.onSearch(value)
  // }

  return (
    <Space.Compact className={styles.searchContainer}>
      {/* <Select
        placeholder='Editoriales'
        size={'large'}
        className='selectSearchBook'
        options={opt}
      /> */}

      {/* <AutoComplete
        className={styles.autoComplete}
        dropdownMatchSelectWidth={1}
        filterOption={true}
        options={props.options}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Input.Search
          className={styles.autoComplete}
          // onSearch={props.onSearch}
          size='large'
          placeholder='Buscar libros'
          enterButton
        />
      </AutoComplete> */}

      <Input
      id={props.id}
        className={styles.autoComplete}
        // value={props.value}
        onChange={(e:any) => onHandleSearch(e)}
        size='large'
        placeholder={props.placeholder}
      />
    </Space.Compact>
  )
})

export default SearchBarModule
