import style from './notebooksEitable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PathPortadasExteriores from 'assets/img/notebooks/horizontals/PathPortadasExteriores'
import PathSujecionSpiralHorizontalInside from 'assets/img/notebooks/horizontals/PathSujecionSpiralHorizontalInside'
import PathEngomadoCocidoEoutside from 'assets/img/notebooks/horizontals/PathEngomadoCocidoEoutside'
import VerticalEditabledraggable from './VerticalEditabledraggable'
import HorizontalEditableDraggable from './HorizontalEditableDraggable'
import DraggableImgCenterProporsalHorizontal from '../EditorDiv/DraggableImgCenterProporsalHorizontal'
import { setPositionsCovers } from 'redux/reducers/notebooksSlice'

type HorizontalPortadasEditableProps = {
  showItems?: Array<number>
  previewHalf?: boolean
  viewMode?: number
}

const HorizontalPreview = ({
  showItems = [1, 2],
  previewHalf = false,
  viewMode = -1
}: HorizontalPortadasEditableProps) => {
  const {
    notebookFrontValues,
    notebookSelected,
    edithUpload,
    positionCovers,
    isCoverOnEdith
  } = useSelector((state: RootState) => state.notebooks)
  const { spine_color, binding_type, binding_color, rounded } =
    notebookFrontValues

  const { cover_design } = notebookFrontValues

  const dispatch = useDispatch()

  return (
    <div
      className={
        previewHalf
          ? style['div-notebook-svg-sticky-preview']
          : style['div-notebook-svg-sticky']
      }
    >
      {cover_design.value === 'design' && !previewHalf && viewMode == 0 && (
        <HorizontalEditableDraggable viewMode={viewMode} />
      )}
      {cover_design.value === 'design' && viewMode == 1 && (
        <HorizontalEditableDraggable viewMode={viewMode} />
      )}
      {cover_design.value === 'design' && viewMode == 2 && (
        <HorizontalEditableDraggable viewMode={viewMode} />
      )}
      {showItems.map((item: number, index: number) => {
        let viewBox = '35 0 820 700'
        if (previewHalf) viewBox = '0 0 920 650'

        return (
          <div
            key={index}
            className={`${style['div-polo-notebook-relative']} 
                  ${item === 1 && style['transform-invert']}`}
          >
            <div className={`${style[`logo-position-`]}`}></div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathPortadasExteriores
                viewBox={viewBox}
                showSizes={previewHalf ? 1 : item}
                className={`${style['svg']}`}
                notebookSelected={notebookSelected}
                colorLomo={spine_color.value as string}
                rounded={rounded.value as boolean}
                date={`${style['date1-font-12']}`}
              />
            </div>
            <div
              className={`${
                viewMode == 0
                  ? style['div-shadows-svg-absolute']
                  : style['div-img-upload-4']
              }`}
            >
              {cover_design.value === 'upload' &&
                !edithUpload.left &&
                !edithUpload.right && (
                  <DraggableImgCenterProporsalHorizontal
                    setPositions={(dat: { back: number; front: number }) =>
                      dispatch(setPositionsCovers(dat))
                    }
                    positions={positionCovers}
                    edithSides={edithUpload}
                    item={item}
                  />
                )}
            </div>
            {isCoverOnEdith && (
              <div
                className={`${style['div-shadows-svg-absolute']}`}
                style={{ zIndex: 8 }}
              >
                {binding_type.value === 'Espiral' ? (
                  <>
                    <PathSujecionSpiralHorizontalInside
                      className={`${style['svg']}`}
                      viewBox={viewBox}
                      show={2}
                      spiralColor={binding_color.value as string}
                    />
                  </>
                ) : (
                  <>
                    <PathEngomadoCocidoEoutside
                      color={binding_color.value as string}
                      viewBox={viewBox}
                      className={`${style['svg']}`}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )
      })}
      <div className={`${style['portadas-sujecion-preview']}`}>
        {viewMode === 0 && (
          <div className={`${style['relative-sujecion']}`}>
            {[1, 2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '-760 -60 820 820' : '10 -60 820 820'
              if (binding_type.value === 'Espiral')
                viewBox = item === 1 ? '110 0 620 800' : '140 0 620 800'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} `}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <>
                        <PathSujecionSpiralHorizontalInside
                          className={`${style['svg']}`}
                          viewBox={viewBox}
                          show={2}
                          spiralColor={binding_color.value as string}
                        />
                      </>
                    ) : (
                      <>
                        <PathEngomadoCocidoEoutside
                          color={binding_color.value as string}
                          viewBox={viewBox}
                          className={`${style['svg']}`}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {viewMode === 1 && (
          <div className={`${style['relative-sujecion']}`}>
            {[2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '-100 0 620 800' : '0 96 459 459'
              if (binding_type.value === 'Espiral') viewBox = '10 0 420 800'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} `}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <>
                        <PathSujecionSpiralHorizontalInside
                          className={`${style['svg']}`}
                          viewBox={viewBox}
                          show={2}
                          spiralColor={binding_color.value as string}
                        />
                      </>
                    ) : (
                      <>
                        <PathEngomadoCocidoEoutside
                          color={binding_color.value as string}
                          viewBox={viewBox}
                          className={`${style['svg']}`}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {viewMode === 2 && (
          <div className={`${style['relative-sujecion']}`}>
            {[1, 2].map((item: number, index: number) => {
              let viewBox = item === 1 ? '450 0 620 800' : '0 96 459 459'
              if (binding_type.value === 'Espiral') viewBox = '-20 100 480 600'
              return (
                <div
                  key={index}
                  className={`${style['div-polo-notebook-sujecion']} ${style['transform-invert']}`}
                >
                  <div
                    className={`${style['div-shadows-svg-absolute']}`}
                    style={{ zIndex: 8 }}
                  >
                    {binding_type.value === 'Espiral' ? (
                      <>
                        <PathSujecionSpiralHorizontalInside
                          className={`${style['svg']}`}
                          viewBox={viewBox}
                          show={2}
                          spiralColor={binding_color.value as string}
                        />
                      </>
                    ) : (
                      <>
                        <PathEngomadoCocidoEoutside
                          color={binding_color.value as string}
                          viewBox={viewBox}
                          className={`${style['svg']}`}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default HorizontalPreview
