import { memo } from 'react'
import PicazoBackground from 'components/picazoBackground/PicazoBackground'
import ForgotPwdModule from 'modules/forgotPwd/ForgotPwdModule'

const index = memo(() => {
  return (
    <PicazoBackground>
      <ForgotPwdModule />
    </PicazoBackground>
  )
})
index.displayName = 'ForgotPassword'
export default index
