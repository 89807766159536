import React from 'react'

const PantalonTraseraSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Contorno_pantalón" data-name="Contorno pantalón"   d="m375.52,398.78c0-6.9.47-5.54.73-15.24.53-20.11,16.83-135.64,24-140.68,7.17-5.04,21.75,145.84,22.35,153.52.54,6.94-.19,3.46,1,13.83.69,6.02,6.78,331.21,6.78,331.21,68.39,3.8,36.77,5.57,68.39,3.8,12.95-.72,41.22-3.77,41.22-3.77l-.1-252.3.11-22.83-4.02-62.66s.99-61.52,1.93-144.62c.97-85.03-9.82-170.57-9.82-170.57,0,0,.19-17.74-2.59-27.48-1.42-4.95-74.77-7.64-74.77-7.64,0,0-22.21.7-87.73-.26-65.62-.97-85.6,5.04-85.6,5.04,0,0-2.98,11.46-5.51,29.94-4.46,32.69-9.64,81.59-9.69,86.84-.04,3.67-2.39,140.13,0,145.97,2.39,5.84-.81,69.24.56,73.12v57.15l-2.78,39.78,2.78,250.18,25.6,5.87h36.76c33.6,0,42.38-4.58,43.97-5.19,1.59-.61,4.8-330.54,4.8-330.54.63-7.32,1.62-12.49,1.62-12.49Z"/>
      <path id="Bolsa"   d="m292.4,152.24v-9.33h73.54v9.33s-23.75,1.67-35.19,1.57c-11.44-.1-38.35-1.57-38.35-1.57Z"/>
      <path id="Bolsa-2" data-name="Bolsa"   d="m432.25,154.39v-9.33h73.54v9.33s-23.75,1.68-35.19,1.57c-11.44-.1-38.35-1.57-38.35-1.57Z"/>
      <path id="Cintura"   d="m527.83,88.57s-16.47-4.92-87.98-3.77c-69.77,1.12-104.51-1.3-104.51-1.3,0,0-52.58-1.25-63.71,4.65"/>
      <path id="Cinturon"   d="m284.57,56.99l13.32-1.69s-3.73,40.72-3.63,44.08h-13.02s2.13-42.6,3.34-42.39Z"/>
      <rect id="Cinturon-2" data-name="Cinturon"   x="392.89" y="53.64" width="12.57" height="45.68"/>
      <path id="Cinturon-3" data-name="Cinturon"   d="m512.18,57.36l-11.63-1.06s4.14,27.05,4.11,43.63h11.33s-1.52-42.3-3.82-42.57Z"/>
      <path id="Boton"   d="m327.4,165.79c-2.48-.07-4.43-2.06-4.35-4.44.07-2.38,2.14-4.26,4.62-4.19,2.48.07,4.43,2.06,4.36,4.44-.07,2.38-2.14,4.26-4.62,4.19Z"/>
      <line id="Linea_enmedio" data-name="Linea enmedio"   x1="399.94" y1="99.93" x2="400.58" y2="242.93"/>
      <line id="Bolsa_izquierda" data-name="Bolsa izquierda"   x1="335.33" y1="83.5" x2="329.17" y2="142.91"/>
      <line id="Bolsa_derecha" data-name="Bolsa derecha"   x1="462.78" y1="85.37" x2="469.02" y2="145.06"/>
      <g id="Costuras">
        <path   d="m286.21,58.24l9.82-1.07s-3.46,37.45-3.37,40.81l-9.12-.27s1.47-39.67,2.67-39.47Z"/>
        <path   d="m511.27,58.69l-8.66-.8s3.96,37.26,3.87,40.62h8.04s-2.04-40.02-3.25-39.82Z"/>
        <path   d="m394.85,55.5h8.76s-.09,39.03,0,42.39h-8.85l.09-42.39Z"/>
        <path   d="m290.25,154.3v-13.55l77.75.59v12.96s-25.82,1.77-37.26,1.67c-11.44-.1-40.5-1.67-40.5-1.67Z"/>
        <path   d="m430.15,156.46v-13.55l77.75.59v12.96s-25.82,1.77-37.26,1.67c-11.44-.11-40.49-1.67-40.49-1.67Z"/>
        <line   x1="396.18" y1="99.93" x2="396.83" y2="242.93"/>
        <path   d="m263.24,726.47s34.16,11.84,104.54,2.33"/>
        <path   d="m539.76,726.85s-39.36,10.87-109.73,1.37"/>
        <polyline   points="294.61 142.37 294.49 142.81 294.32 142.42 294.19 142.85 294.03 142.45 293.88 142.88 293.74 142.47 293.57 142.9 293.45 142.49 293.26 142.91 293.16 142.49 292.96 142.9 292.86 142.48 292.65 142.89 292.57 142.46 292.34 142.86 292.28 142.42 292.03 142.82 292 142.37 291.72 142.75 291.72 142.31 291.45 142.68 291.42 142.24 291.19 142.64 291.12 142.21 290.92 142.62 290.81 142.21 290.66 142.64 290.5 142.24 290.4 142.68 290.19 142.31"/>
        <polyline   points="294.04 153.27 293.93 153.71 293.76 153.32 293.62 153.76 293.46 153.36 293.31 153.78 293.17 153.37 293 153.81 292.88 153.39 292.69 153.81 292.59 153.39 292.39 153.81 292.3 153.39 292.08 153.79 292 153.36 291.77 153.76 291.72 153.33 291.46 153.72 291.43 153.28 291.16 153.66 291.15 153.21 290.88 153.59 290.86 153.15 290.62 153.54 290.55 153.11 290.35 153.53 290.25 153.11 290.09 153.54 289.94 153.14 289.83 153.58 289.62 153.22"/>
        <polyline   points="368.01 141.9 367.89 142.33 367.72 141.95 367.58 142.38 367.43 141.98 367.27 142.41 367.13 142 366.96 142.43 366.84 142.01 366.65 142.44 366.55 142.02 366.35 142.43 366.26 142.01 366.04 142.42 365.97 141.99 365.73 142.38 365.68 141.95 365.42 142.35 365.39 141.9 365.12 142.28 365.11 141.84 364.84 142.21 364.82 141.77 364.58 142.17 364.52 141.74 364.32 142.15 364.21 141.73 364.06 142.17 363.9 141.77 363.8 142.21 363.58 141.84"/>
        <polyline   points="368.01 152.48 367.89 152.92 367.72 152.53 367.58 152.96 367.43 152.56 367.27 152.99 367.13 152.58 366.96 153.01 366.84 152.6 366.65 153.02 366.55 152.6 366.35 153.01 366.26 152.59 366.04 153 365.97 152.57 365.73 152.97 365.68 152.53 365.42 152.93 365.39 152.48 365.12 152.86 365.11 152.42 364.84 152.8 364.82 152.35 364.58 152.75 364.52 152.32 364.32 152.73 364.21 152.32 364.06 152.75 363.9 152.35 363.8 152.79 363.58 152.42"/>
        <polyline   points="433.15 143.07 433.03 143.5 432.86 143.12 432.72 143.55 432.56 143.15 432.41 143.58 432.27 143.17 432.1 143.6 431.98 143.18 431.79 143.61 431.69 143.19 431.49 143.6 431.4 143.18 431.18 143.59 431.11 143.16 430.87 143.55 430.82 143.12 430.56 143.52 430.53 143.07 430.26 143.45 430.25 143.01 429.98 143.38 429.96 142.94 429.72 143.34 429.66 142.91 429.45 143.32 429.35 142.9 429.19 143.34 429.04 142.94 428.93 143.38 428.72 143.01"/>
        <polyline   points="433.15 154.6 433.03 155.04 432.86 154.65 432.72 155.08 432.56 154.68 432.41 155.11 432.27 154.7 432.1 155.13 431.98 154.72 431.79 155.14 431.69 154.72 431.49 155.13 431.4 154.71 431.18 155.12 431.11 154.69 430.87 155.09 430.82 154.65 430.56 155.05 430.53 154.6 430.26 154.98 430.25 154.54 429.98 154.91 429.96 154.47 429.72 154.87 429.66 154.44 429.45 154.85 429.35 154.43 429.19 154.87 429.04 154.47 428.93 154.91 428.72 154.54"/>
        <polyline   points="508 144.52 507.88 144.96 507.71 144.57 507.57 145.01 507.42 144.61 507.27 145.03 507.13 144.63 506.96 145.06 506.83 144.64 506.65 145.06 506.54 144.64 506.34 145.06 506.25 144.64 506.03 145.04 505.96 144.61 505.72 145.01 505.67 144.58 505.41 144.97 505.39 144.53 505.11 144.91 505.1 144.46 504.83 144.84 504.81 144.4 504.57 144.79 504.51 144.36 504.31 144.78 504.2 144.36 504.05 144.79 503.89 144.39 503.79 144.83 503.58 144.47"/>
        <polyline   points="508 154.67 507.88 155.1 507.71 154.72 507.57 155.15 507.42 154.75 507.27 155.18 507.13 154.77 506.96 155.2 506.83 154.78 506.65 155.21 506.54 154.79 506.34 155.2 506.25 154.78 506.03 155.19 505.96 154.76 505.72 155.15 505.67 154.72 505.41 155.12 505.39 154.67 505.11 155.05 505.1 154.61 504.83 154.98 504.81 154.54 504.57 154.94 504.51 154.51 504.31 154.92 504.2 154.5 504.05 154.94 503.89 154.54 503.79 154.98 503.58 154.61"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default PantalonTraseraSvg
