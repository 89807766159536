import React, { Fragment, useState } from 'react'
import style from './comisiones.module.scss'

import { Input, Select, Skeleton, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { IUserTableDataAnt } from 'interfaces/user-management-interface'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import { CalculatorIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { DownloadIcon } from 'components/icons/svg'
import InputComponent from './InputComponent'

const compareNumbers = (a: any, b: any) => {
  return a.id - b.id
}

interface IFakeData {
  id: number
  sku: string
  description: string
  format: string
  editorial: string
  proveedor: string
  poblation: number
  price: number
  descount: number
  comision: number
  moneyComision: number
}
const fakeData: IFakeData[] = [
  {
    id: 1,
    sku: '123451234',
    description: 'esto sirve para algo pero no esta claro',
    format: 'Fisico',
    editorial: '0xford',
    proveedor: 'Learnex',
    poblation: 67,
    price: 100,
    descount: 20,
    comision: 10,
    moneyComision: 2
  },
  {
    id: 2,
    sku: '5433214321',
    description: 'para aprender a contar',
    format: 'Fisico',
    editorial: 'santillana',
    proveedor: '',
    poblation: 38,
    price: 167,
    descount: 22.2,
    comision: 8,
    moneyComision: 126
  },
  {
    id: 3,
    sku: '5432154321',
    description: 'los tres cochinitos',
    format: 'Digital',
    editorial: 'santillana',
    proveedor: '',
    poblation: 50,
    price: 234,
    descount: 2.2,
    comision: 123,
    moneyComision: 678
  },
  {
    id: 4,
    sku: '54332154321',
    description: 'Caperuza y el lobo',
    format: 'Fisico',
    editorial: 'santillana',
    proveedor: '',
    poblation: 63,
    price: 291,
    descount: 12.2,
    comision: 678,
    moneyComision: 264
  }
]

const ComisionesColegioModule = () => {
  const [data, setdata] = useState<IFakeData[] | null>(fakeData)
  const [data2222, setdata2222] = useState<IFakeData[] | null>(fakeData)

  const chanteMyComission = (dat: number, me: IFakeData) => {
    if (data !== null) {
      const allData: IFakeData[] = data.filter((it) => me.id !== it.id)
      const priceWithoutDiscount = me.price * (me.descount / 100)
      const moneyComission = (priceWithoutDiscount * (dat / 100)).toFixed(2)

      let save: IFakeData[] = []
      if (!Number.isNaN(dat)) {
        save = [
          ...allData,
          { ...me, comision: dat, moneyComision: parseFloat(moneyComission) }
        ]
      } else save = [...allData, { ...me, comision: dat, moneyComision: 0 }]
      setdata(
        save.sort((a: any, b: any) => {
          return a.id - b.id
        })
      )
      //console.log(save, dat)
    }
  }

  const columns: ColumnsType<IFakeData> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (text) => <p className={`${style['p-text']}`}>{text}</p>
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Formato',
      dataIndex: 'format',
      key: 'format'
    },
    {
      title: 'Editorial',
      dataIndex: 'editorial',
      key: 'editorial'
    },
    {
      title: 'Proveedor',
      dataIndex: 'proveedor',
      key: 'proveedor'
    },
    {
      title: 'Poblacion',
      dataIndex: 'poblation',
      key: 'poblation'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.price}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Descuento',
      dataIndex: 'descount',
      key: 'descount',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>{items.descount}%</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Comisión',
      dataIndex: 'comision',
      key: 'comision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    value={items.comision}
                    type='number'
                    className={`${style['input-field']}`}
                    onChange={(e) =>
                      chanteMyComission(parseFloat(e.target.value), items)
                    }
                  />
                  {' %'}
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Dinero comisión',
      dataIndex: 'moneyComision',
      key: 'moneyComision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.moneyComision}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: '',
      key: '',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <ArrowUpTrayIcon
                  onClick={() => undefined}
                  className={`${style['size-icons']} ${style['edith-color']}`}
                />
              </Fragment>
            )
          })()}
        </div>
      )
    }
  ]

  const columnsOpt2: ColumnsType<IFakeData> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (text) => <p className={`${style['p-text']}`}>{text}</p>
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Formato',
      dataIndex: 'format',
      key: 'format'
    },
    {
      title: 'Editorial',
      dataIndex: 'editorial',
      key: 'editorial'
    },
    {
      title: 'Proveedor',
      dataIndex: 'proveedor',
      key: 'proveedor'
    },
    {
      title: 'Poblacion',
      dataIndex: 'poblation',
      key: 'poblation'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.price}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Descuento',
      dataIndex: 'descount',
      key: 'descount',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>{items.descount}%</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Comisión',
      dataIndex: 'comision',
      key: 'comision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    value={items.comision}
                    type='number'
                    className={`${style['input-field']}`}
                    onChange={(e) =>
                      chanteMyComission(parseFloat(e.target.value), items)
                    }
                  />
                  {' %'}
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Dinero comisión',
      dataIndex: 'moneyComision',
      key: 'moneyComision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.moneyComision}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: '% de comisión trasladado al papá',
      dataIndex: 'comision',
      key: 'comision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    value={items.comision}
                    type='number'
                    className={`${style['input-field']}`}
                    onChange={(e) =>
                      chanteMyComission(parseFloat(e.target.value), items)
                    }
                  />
                  {' %'}
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Dinero trasladado al papá',
      dataIndex: 'moneyComision',
      key: 'moneyComision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.moneyComision}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Dinero nueva comisión',
      dataIndex: 'moneyComision',
      key: 'moneyComision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.moneyComision}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: 'Precio final papás',
      dataIndex: 'moneyComision',
      key: 'moneyComision',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>${items.price}</label>
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    },
    {
      title: '',
      key: '',
      render: (_, items) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            return (
              <Fragment>
                <div style={{ display: 'flex' }}>
                  <ArrowUpTrayIcon
                    onClick={() => undefined}
                    className={`${style['size-icons']} ${style['edith-color']}`}
                  />
                </div>
              </Fragment>
            )
          })()}
        </div>
      )
    }
  ]
  return (
    <MainTemplate>
      <TitleHeader
        IconName='UserIcons'
        widthIcon={'2rem'}
        heightIcon={'2rem'}
        title='Gestion de comisiones por colegio'
        variaton='4'
        buttonProps={[
          {
            label: 'Reporte en Excel',
            onClick: () => undefined,

            background: 'white',
            rounded: true,
            size: 'm',
            sector: 'tertiary',
            isLoading: false
            /* disabled:true */
          }
        ]}
      />

      <Space.Compact>
        <Select
          showSearch
          style={{ minWidth: 200 }}
          placeholder='Selecciona una escuela'
          optionFilterProp='children'
          size='large'
          allowClear
          onChange={() => undefined}
          filterOption={(input: any, option: any) => {
            return (
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) > 0
            )
          }}
        ></Select>
        <Input.Search
          placeholder='buscar por nombre, apellido, correo...'
          onSearch={() => undefined}
          onChange={(e) => undefined}
          value={''}
          style={{ width: 'auto' }}
          size='large'
        />
      </Space.Compact>
      <Skeleton loading={false} active>
        {true && (
          <Table
            className={''}
            showSorterTooltip={{ title: 'Clic para ordenar' }}
            dataSource={data as IFakeData[]}
            style={{ marginTop: '2.5rem' }}
            columns={columns}
            rowKey={(record) => record.sku}
            loading={false}
            onRow={(_, __) => {
              return {
                onClick: () => undefined
              }
            }}
          />
        )}

        {true && (
          <Table
            className={''}
            showSorterTooltip={{ title: 'Clic para ordenar' }}
            dataSource={fakeData}
            style={{ marginTop: '2.5rem' }}
            columns={columnsOpt2}
            rowKey={(record) => record.sku}
            loading={false}
            onRow={(_, __) => {
              return {
                onClick: () => undefined
              }
            }}
          />
        )}
      </Skeleton>
    </MainTemplate>
  )
}

export default ComisionesColegioModule
