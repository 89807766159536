import BackButton from 'components/buttons/BackButton/BackButton'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import CreateUtilsForm from 'modules/Utils/CreateUtilsForm/CreateUtilsForm'
import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
const index = memo(() => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const renderTitle = () =>{
    if (location.state.status > 4) return 'Útil'
    if(location.state.requestUtil) return "Solicitar nuevo útil"
    return 'Editar Útil'
  }
  return (
    <MainTemplate>
      <BackButton onClick={goBack} textDecoration='underline' />
      <br/>
      <br/>
      <br/>

      <TitleHeader
        IconName='utilsIcon'
        title={renderTitle()}
      />
      <CreateUtilsForm
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </MainTemplate>
  )
})
index.displayName = 'SchoolDetails'
export default index
