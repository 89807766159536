import React, { Fragment } from 'react'
import style from './thiscomponents.module.scss'

import { IResponsabilities } from 'interfaces/user-management-interface'
import NewGroupCheck from './NewGroupCheck'

const NewSelectLevels = ({
  levels,
  levelsLenght,
  onHandleCheck,
  idSchool
}: {
  levels: IResponsabilities[]
  levelsLenght: number
  onHandleCheck: (
    idSchool: number,
    levelName: string,
    idGrade: number,
    all: null | boolean
  ) => void
  idSchool: number
}) => {
  return (
    <Fragment>
      {levels.map((item, index: number) => {
        return (
          <Fragment key={index}>
            <NewGroupCheck
              onHandleCheck={onHandleCheck}
              name={item.name}
              grades={item.grades}
              idSchool={idSchool}
            />
            {levelsLenght !== index + 1 && (
              <hr className={`${style['hr-line']}`} />
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default NewSelectLevels
