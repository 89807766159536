import {
  IClothesOptions,
  IInputsBaseForm,
  IInputsBlusa3_4Form,
  IInputsCamisaDeportivaForm,
  IInputsCamisaMlMcForm,
  IInputsCapitonadosChalecoForm,
  IInputsCapitonadosChamarraForm,
  IInputsChamarraDeportivaForm,
  IInputsCorbataForm,
  IInputsFaldaForm,
  IInputsFaldaShortDeportivoForm,
  IInputsHoodieForm,
  IInputsJumperForm,
  IInputsPantalonForm,
  IInputsPantsForm,
  IInputsPoloFemaleForm,
  IInputsPoloMaleForm,
  IInputsSacoForm,
  IInputsSacoTraseroForm,
  IInputsShortDeportivoForm,
  IInputsSportShirtForm,
  IInputsSueterFemaleForm,
  IInputsSueterMaleForm,
  IInputsTShirtForm,
  IInputsTrajeBanoFemale2pzaForm,
  IInputsTrajeBanoFemaleForm,
  IInputsTrajeBanoMaleForm,
  TFormSportShirtOptions
} from 'interfaces/uniforms-interface'
import liso from 'assets/img/uniforms/optionsSelect/liso.png'
import lineas from 'assets/img/uniforms/optionsSelect/lineas.png'

import kid from 'assets/img/uniforms/kid-uniform.png'

import abertura from 'assets/img/uniforms/optionsSelect/conA.png'
import noAbertura from 'assets/img/uniforms/optionsSelect/sin.png'

import bordado from 'assets/img/uniforms/optionsSelect/bordado.png'
import estampado from 'assets/img/uniforms/optionsSelect/estampado.png'

import ligero from 'assets/img/uniforms/optionsSelect/ligero.png'
import plus from 'assets/img/uniforms/optionsSelect/plus.png'
import microPique from 'assets/img/uniforms/optionsSelect/microPique.png'

export const optionsGeneralPolos: IClothesOptions = {
  fabric: [
    {
      name: 'Ligero',
      option: { label: 'Tipo', picture: ligero, value: 'ligero' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Plus',
      option: { label: 'Tipo', picture: plus, value: 'plus' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'micro-piqué',
      option: { label: 'Tipo', picture: microPique, value: 'micro-pique' },
      description:
        '100% Poliéster. Al seleccionarlo en automatico el color de la tela se vuelve blanca y no se puede cambiar el color'
    }
  ],
  fabric_saco: [
    {
      name: 'Viscosa/poliester',
      option: { label: 'Tipo', picture: ligero, value: 'ligero' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_capitonado: [
    {
      name: '100%_poliester',
      option: { label: 'Tipo', picture: ligero, value: 'poliester' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_hoodie: [
    {
      name: 'Algodon/Poliester',
      option: {
        label: 'Tipo',
        picture: ligero,
        value: '50% algodón / 50% poliéster'
      },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_corbatas: [
    {
      name: 'Poliester 100%',
      option: { label: 'Tipo', picture: ligero, value: 'poliester' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_tshirt: [
    {
      name: 'Ligero',
      option: { label: 'Tipo', picture: ligero, value: 'ligero' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Plus',
      option: { label: 'Tipo', picture: plus, value: 'plus' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_blusa34: [
    {
      name: 'Ligero',
      option: { label: 'Tipo', picture: ligero, value: 'ligero' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Algodón',
      option: { label: 'Tipo', picture: plus, value: 'algodón' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_jumper: [
    {
      name: 'Acrilico/poliester',
      option: { label: 'Tipo', picture: ligero, value: 'Acrilico/poliester' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fabric_sueter: [
    {
      name: 'Acrilico',
      option: { label: 'Tipo', picture: ligero, value: 'Acrilico' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Free pilling',
      option: { label: 'Tipo', picture: ligero, value: 'free pilling' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  logo: [
    {
      name: 'Bordado',
      option: { label: 'Estilo', picture: bordado, value: 'bordado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Estampado',
      option: { label: 'Estilo', picture: estampado, value: 'estampado' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  logo_hoodie: [
    {
      name: 'Bordado',
      option: { label: 'Estilo', picture: bordado, value: 'bordado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Estampado',
      option: { label: 'Estilo', picture: estampado, value: 'estampado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'HT',
      option: { label: 'Estilo', picture: estampado, value: 'ht' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  logo_blusa34: [
    {
      name: 'Bordado',
      option: { label: 'Estilo', picture: bordado, value: 'bordado' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  linedDesign: [
    {
      name: 'Liso',
      option: { picture: liso, value: false, label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Líneas',
      option: { picture: lineas, value: true, label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  aperture: [
    {
      name: `Con abertura`,
      option: { label: 'Abertura', picture: abertura, value: true },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin abertura',
      option: { label: 'Abertura', picture: noAbertura, value: false },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul_Claro',
      option: { picture: null, label: 'Color', value: '#4A94FF' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Naranja',
      option: { picture: null, label: 'Color', value: '#CF0076' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris Obscuro',
      option: { picture: null, label: 'Color', value: '#3B3B3B' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_hoodie: [
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Naranja',
      option: { picture: null, label: 'Color', value: '#CF0076' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_capitonado: [
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_corbata: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul_Claro',
      option: { picture: null, label: 'Color', value: '#4A94FF' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Naranja',
      option: { picture: null, label: 'Color', value: '#CF0076' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris Obscuro',
      option: { picture: null, label: 'Color', value: '#3B3B3B' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  color_outline_sueter: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Amarillo',
      option: { picture: null, label: 'Color', value: '#FFFF00' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_saco: [
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  color_coderas_saco: [
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  color_pretinas_sueter: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Amarillo',
      option: { picture: null, label: 'Color', value: '#FFFF00' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_tshirt: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color_blusa34: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#ffffff' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Gris_Claro',
      option: { picture: null, label: 'Color', value: '#BDBDBD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Azul',
      option: { picture: null, label: 'Color', value: '#002D72' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Negro',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Centro',
      option: { picture: kid, label: 'Ubicación', value: 'center' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Espalda',
      option: { picture: kid, label: 'Ubicación', value: 'back' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_tshirt: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },

    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_capi_chale: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },

    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_sueter: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },

    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_blusa34: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga derecha',
      option: { picture: kid, label: 'Ubicación', value: 'm-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'm-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_capi_chama: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga derecha',
      option: { picture: kid, label: 'Ubicación', value: 'm-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'm-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_jumper: [
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Falda derecha',
      option: { picture: kid, label: 'Ubicación', value: 'f-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Falda izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'f-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_falda: [
    {
      name: 'Derecha inferior',
      option: { picture: kid, label: 'Ubicación', value: 'i-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda inferior',
      option: { picture: kid, label: 'Ubicación', value: 'i-left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha superior',
      option: { picture: kid, label: 'Ubicación', value: 's-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda superior',
      option: { picture: kid, label: 'Ubicación', value: 's-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_camisa_ml_mc: [
    {
      name: 'Bolsa',
      option: { picture: kid, label: 'Ubicación', value: 'bolsa' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga derecha',
      option: { picture: kid, label: 'Ubicación', value: 'm-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga  izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'm-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_traje_male: [
    {
      name: 'Izqierda superior',
      option: { picture: kid, label: 'Ubicación', value: 's-left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha superior',
      option: { picture: kid, label: 'Ubicación', value: 's-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Izquierda inferior',
      option: { picture: kid, label: 'Ubicación', value: 'i-left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha inferior',
      option: { picture: kid, label: 'Ubicación', value: 'i-right' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_traje_female: [
    {
      name: 'Izqierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Centro',
      option: { picture: kid, label: 'Ubicación', value: 'center' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  position_traje_female_2pza: [
    {
      name: 'Izqierda',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Centro',
      option: { picture: kid, label: 'Ubicación', value: 'center' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Inferior derecha',
      option: { picture: kid, label: 'Ubicación', value: 'i-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Inferior izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'i-left' },
      description: '50% algodón / 50% poliéster'
    }
  ],

  position_hoodie: [
    {
      name: 'Manga derecha',
      option: { picture: kid, label: 'Ubicación', value: 'm-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Manga izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'm-left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Pecho derecha',
      option: { picture: kid, label: 'Ubicación', value: 'p-right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Pecho izquierda',
      option: { picture: kid, label: 'Ubicación', value: 'p-left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Centro',
      option: { picture: kid, label: 'Ubicación', value: 'centro' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Espalda',
      option: { picture: kid, label: 'Ubicación', value: 'espalda' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  neckStyle: [
    {
      name: 'En V',
      option: { picture: liso, value: 'V', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Redondo',
      option: { picture: lineas, value: 'redondo', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  pinzas_blusa34: [
    {
      name: 'Con',
      option: { picture: liso, value: true, label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: false, label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  pinzas_falda: [
    {
      name: '2_pinzas',
      option: { picture: liso, value: '2 pinzas', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '4_pinzas',
      option: { picture: lineas, value: '4 pinzas', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '2_tablones',
      option: { picture: liso, value: '2 tablones', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '4_tablones',
      option: { picture: lineas, value: '4 tablones', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  fist_blusa34: [
    {
      name: 'Lisos',
      option: { picture: liso, value: 'lisos', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Color',
      option: { picture: lineas, value: 'color', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  pretinas_falda: [
    {
      name: 'Con_resorte',
      option: { picture: liso, value: 'con resorte', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin_resorte',
      option: { picture: lineas, value: 'sin resorte', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  pretinas_sueter: [
    {
      name: 'Lineas',
      option: { picture: liso, value: 'lineas', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Color',
      option: { picture: lineas, value: 'color', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  bolsas_sueter_type: [
    {
      name: 'Vivo',
      option: { picture: liso, value: 'vivo', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Ocultas',
      option: { picture: lineas, value: 'ocultas', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  solapa_saco: [
    {
      name: 'Ancha',
      option: { picture: liso, value: 'ancha', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Delgada',
      option: { picture: lineas, value: 'delgada', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  coderas_saco: [
    {
      name: 'Con',
      option: { picture: liso, value: 'con', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: 'sin', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  apertura_espalda_saco: [
    {
      name: '1',
      option: { picture: liso, value: '1', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '2',
      option: { picture: lineas, value: '2', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  num_botones_saco: [
    {
      name: '1',
      option: { picture: liso, value: '1', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '2',
      option: { picture: lineas, value: '2', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '3',
      option: { picture: lineas, value: '3', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  mono_prehecho: [
    {
      name: 'Con',
      option: { picture: liso, value: 'con', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: 'sin', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  con_logo: [
    {
      name: 'Con',
      option: { picture: liso, value: 'con', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: 'sin', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  type_corbata: [
    {
      name: 'Lisa',
      option: { picture: liso, value: 'lisa', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Fantasia',
      option: { picture: lineas, value: 'fantasia', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  seams_hoodie: [
    {
      name: 'Con',
      option: { picture: liso, value: 'con seams', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: 'sin seams', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  punos_capitonado: [
    {
      name: 'lisas',
      option: { picture: liso, value: 'lisas', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin',
      option: { picture: lineas, value: 'sin', label: 'Diseño' },
      description: '50% algodón / 50% poliéster'
    }
  ]
}

export const INPUTS_SHORT_DEPORTIVA: IInputsShortDeportivoForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_FALDASHORT_DEPORTIVA: IInputsFaldaShortDeportivoForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_CAMISA_DEPORTIVA: IInputsCamisaDeportivaForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}

export const INPUTS_PANTS: IInputsPantsForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_CHAMARRA_DEPORTIVA: IInputsChamarraDeportivaForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_TRAJE_BANO_FEMALE_2PZ: IInputsTrajeBanoFemale2pzaForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_traje_female_2pza',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_TRAJE_BANO_FEMALE_1PZ: IInputsTrajeBanoFemaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_traje_female',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_TRAJE_BANO_MALE: IInputsTrajeBanoMaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_traje_male',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_PANTALON_FRONTAL: IInputsPantalonForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}

export const INPUTS_CHALECO: IInputsBaseForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_CAMISA_ML_MC: IInputsCamisaMlMcForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_camisa_ml_mc',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_CAPITONADO_CHAMARRA: IInputsCapitonadosChamarraForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_capi_chama',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  punos: {
    label: 'Tipo de puños',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'punos',
    showHelp: true,
    value: '',
    type: 'punos_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_CAPITONADO_CHALECO: IInputsCapitonadosChalecoForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color del hoodie',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color_capitonado',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_capi_chale',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_HOODIE: IInputsHoodieForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_hoodie',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color del hoodie',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color_hoodie',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_hoodie',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Posicion del logotipo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_hoodie',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  fullColorMangasBolsas: {
    label: 'Color en manga y bolsa',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fullColorMangasBolsas',
    showHelp: true,
    value: '',
    type: 'full_color_hoodie',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_POLO_MALE: IInputsPoloMaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'position_tshirt',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckDesign: {
    label: 'Diseño de cuello',
    topLabel: 'Diseño',
    selectedLabel: '',
    name: 'neckDesign',
    showHelp: true,
    value: false,
    type: 'linedDesign',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckColor: {
    label: 'Color de cuello',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'neckColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  buttonColor: {
    label: 'Color de boton',
    topLabel: 'Pegado',
    selectedLabel: '',
    name: 'buttonColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  fistDesign: {
    label: 'Diseño de puños',
    topLabel: 'Diseño',
    selectedLabel: '',
    name: 'fistDesign',
    showHelp: true,
    value: false,
    type: 'linedDesign',
    group: 'Puños',
    required: true,
    enabled: true,
    showThisOption: true
  },
  fistColor: {
    label: 'Color de puños',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fistColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Puños',
    required: true,
    enabled: true,
    showThisOption: true
  },
  aperture: {
    label: 'Abertura',
    topLabel: 'Abertura',
    selectedLabel: '',
    name: 'aperture',
    showHelp: true,
    value: true,
    type: 'aperture',
    group: 'Abertura',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_POLO_FEMALE: IInputsPoloFemaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'position_tshirt',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckStyle: {
    label: 'Estilo de cuello',
    topLabel: 'Diseño',
    selectedLabel: '',
    name: 'neckStyle',
    showHelp: true,
    value: false,
    type: 'neckStyle',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckDesign: {
    label: 'Diseño de cuello',
    topLabel: 'Diseño',
    selectedLabel: '',
    name: 'neckDesign',
    showHelp: true,
    value: false,
    type: 'linedDesign',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckColor: {
    label: 'Color de cuello',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'neckColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  buttonColor: {
    label: 'Color de boton',
    topLabel: 'Pegado',
    selectedLabel: '',
    name: 'buttonColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    showThisOption: true
  },
  aperture: {
    label: 'Abertura',
    topLabel: 'Abertura',
    selectedLabel: '',
    name: 'aperture',
    showHelp: true,
    value: true,
    type: 'aperture',
    group: 'Abertura',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_SPORT_SHIRT: IInputsSportShirtForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: "position_tshirt",
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckType: {
    label: 'Tipo de cuello',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'neckType',
    showHelp: true,
    value: '',
    type: 'neckStyle',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckColor: {
    label: 'Color del cuello',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'neckColor',
    showHelp: true,
    value: 'right',
    type: 'full_color',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  fistColor: {
    label: 'Color de puños',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fistColor',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color',
    group: 'Puños',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_T_SHIRT: IInputsTShirtForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color_tshirt',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'position_tshirt',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckType: {
    label: 'Tipo de cuello',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'neckType',
    showHelp: true,
    value: '',
    type: 'neckStyle',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}

export const INPUTS_BLUSA_3_4: IInputsBlusa3_4Form = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric_blusa_34',
    showHelp: true,
    value: '',
    type: 'fabric_blusa34',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color_blusa34',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },

  neckColor: {
    label: 'Color del cuello',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'neckColor',
    showHelp: true,
    value: '',
    type: 'full_color_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  fistType: {
    label: 'Tipo de puños',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'fistType',
    showHelp: true,
    value: '',
    type: 'fist_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pinzas: {
    label: 'Pinzas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pinzas',
    showHelp: true,
    value: '',
    type: 'pinzas_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_JUMPER: IInputsJumperForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric_jumper',
    showHelp: true,
    value: '',
    type: 'fabric_jumper',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color_blusa34',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_jumper',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  neckType: {
    label: 'Tipo de cuello',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'neckType',
    showHelp: true,
    value: 'right',
    type: 'neckStyle',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas: {
    label: 'Pretinas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas',
    showHelp: true,
    value: 'right',
    type: 'pinzas_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pinzas: {
    label: 'Pinzas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pinzas',
    showHelp: true,
    value: '',
    type: 'pinzas_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_FALDA: IInputsFaldaForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_jumper',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color_blusa34',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_falda',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  bolsas: {
    label: 'Bolsas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'bolsas',
    showHelp: true,
    value: 'right',
    type: 'pinzas_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas: {
    label: 'Pretinas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas',
    showHelp: true,
    value: 'right',
    type: 'pretinas_falda',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pinzas: {
    label: 'Pinzas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pinzas',
    showHelp: true,
    value: '',
    type: 'pinzas_falda',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_SUETER_MALE: IInputsSueterMaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_sueter',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'color_outline_sueter',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: '',
    type: 'position_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  outline_color: {
    label: 'Color del contorno',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'outline_color',
    showHelp: true,
    value: 'right',
    type: 'color_outline_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas: {
    label: 'Pretinas y puños',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas',
    showHelp: true,
    value: 'right',
    type: 'pretinas_falda',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas_color: {
    label: 'Color de pretinas y puños',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas_color',
    showHelp: true,
    value: '',
    type: 'color_outline_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_SUETER_FEMALE: IInputsSueterFemaleForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_sueter',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'color_outline_sueter',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'position_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  outline_color: {
    label: 'Color del contorno',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'outline_color',
    showHelp: true,
    value: 'right',
    type: 'color_outline_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  bolsas: {
    label: 'Bolsas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'bolsas',
    showHelp: true,
    value: 'right',
    type: 'pinzas_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  bolsasType: {
    label: 'Tipo de bolsas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'bolsas',
    showHelp: true,
    value: 'right',
    type: 'bolsas_sueter_type',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas: {
    label: 'Pretinas y puños',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas',
    showHelp: true,
    value: 'right',
    type: 'pretinas_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  pretinas_color: {
    label: 'Color de pretinas y puños',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'pretinas_color',
    showHelp: true,
    value: '',
    type: 'color_outline_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_SACO_FRONTAL: IInputsSacoForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'full_color_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo_blusa34',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logoPosition: {
    label: 'Ubicación del logotipo',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'position_sueter',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  },
  solapaSize: {
    label: 'Tamaño de la solapa',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'logoPosition',
    showHelp: true,
    value: 'right',
    type: 'solapa_saco',
    group: 'Logotipo',
    required: true,
    enabled: true,
    showThisOption: true
  }
}
export const INPUTS_SACO_TRASERO: IInputsSacoTraseroForm = {
  coderas: {
    label: 'Coderas',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'coderas',
    showHelp: true,
    value: '#ffffff',
    type: 'coderas_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },

  color: {
    label: 'Color de las coderas',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '#ffffff',
    type: 'color_coderas_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  numDeBotones: {
    label: 'Cantidad de botones',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'numDeBotones',
    showHelp: true,
    value: '#ffffff',
    type: 'num_botones_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  aperturasEspalda: {
    label: 'Aperturas en la espalda',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'aperturasEspalda',
    showHelp: true,
    value: '#ffffff',
    type: 'apertura_espalda_saco',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}

export const INPUTS_CORBATA: IInputsCorbataForm = {
  fabric: {
    label: 'Tipo de tela',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'fabric',
    showHelp: true,
    value: '',
    type: 'fabric_corbatas',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  color: {
    label: 'Color de la corbata',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'color',
    showHelp: true,
    value: '',
    type: 'full_color_corbata',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  logo: {
    label: 'Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'logo',
    showHelp: true,
    value: '',
    type: 'logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  con_logo: {
    label: 'Con Logo',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'con_logo',
    showHelp: true,
    value: '',
    type: 'con_logo',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  },
  type: {
    label: 'Tipo dec corbata',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'type',
    showHelp: true,
    value: '',
    type: 'type_corbata',
    group: 'Tela',
    required: true,
    enabled: true,
    showThisOption: true
  }
}

export const getCorrectInformation = (
  getThis: 'label' | 'picture',
  value: string | boolean,
  type: string,
  description: true | false | null
) => {
  let search: any = ''
  Object.keys(optionsGeneralPolos).forEach((item) => {
    const objKey = item as keyof typeof optionsGeneralPolos
    const objInput = optionsGeneralPolos[objKey]

    if (type === item)
      objInput.forEach((content) => {
        if (value === content.option.value && description === null) {
          search = content.option[getThis]
        } else if (value === content.option.value && description !== null)
          search = !description ? content.name : content.description
      })
  })
  return search
}
