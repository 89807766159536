import { Col, List } from 'antd'
import Collapse from 'components/Collapse/Collapse'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import Tags from 'components/tag'
import B from 'components/texts/B/B'
import React, { memo } from 'react'
import UniformPrev from '../../../../assets/img/uniformPrev.svg'
const colStyle = { cursor: 'pointer' }
const notebookTitle = [
  {
    1: 'Preview',
    2: 'Nombre del cuaderno',
    3: 'Descripción',
    4: 'Estatus'
  }
]

const dummyPAck = [
  {
    id: '1',
    name: 'PAckete',
    price: '00000'
  }
]

const notebookDummy = [
  {
    prev: <img src={UniformPrev} alt='nombre' />,
    name: 'Nombre del cuaderno',
    description: 'Descripción',
    status: {
      id: 1,
      label: 'En revisión'
    }
  }
]

const RenderPacks = (Packs: any): any => {
  return Packs.map((res: any, key: number) => {
    return (
      <Collapse key={key + res.id} id={res.id} title={res.name + res.price}>
        <>
          <List
            itemLayout='horizontal'
            dataSource={notebookTitle}
            renderItem={(title: any) => (
              <List.Item style={{ padding: ' 10px 0' }}>
                {Object.keys(title).map((res, key) => {
                  return (
                    <Col
                      key={key}
                      className='ListTitles'
                      style={colStyle}
                      span={6}
                    >
                      <B size='Light'>{title[res]}</B>
                    </Col>
                  )
                })}
              </List.Item>
            )}
          />
          <List
            itemLayout='horizontal'
            dataSource={notebookDummy}
            renderItem={(grade: any) => (
              <List.Item style={{ padding: ' 10px 0' }}>
                <Col style={colStyle} span={6}>
                  <B size='Light'>{grade.prev}</B>
                </Col>
                <Col style={colStyle} span={6}>
                  {' '}
                  <B size='Light'>{grade.name}</B>
                </Col>
                <Col style={colStyle} span={6}>
                  {' '}
                  <B size='Light'>{grade.description}</B>
                </Col>
                <Col style={colStyle} span={6}>
                  {' '}
                  <Tags
                    variation={grade.status.id}
                    title={grade.status.label}
                  />
                </Col>
              </List.Item>
            )}
          />
        </>
      </Collapse>
    )
  })
}

const NotebookModule = memo(() => {
  return (
    <div>
      {notebookDummy.length === 0 ? (
        <EmptyListMessage
          icon='book'
          iconWidth={34}
          iconHeight={40}
          text='Aún no has asignado libros a esta lista'
        />
      ) : (
        RenderPacks(dummyPAck)
      )}
    </div>
  )
})
NotebookModule.displayName = 'NotebookModule'
export default NotebookModule
