import { AuthData } from 'redux/reducers/auth'
import { AUTHSERVICE } from '../config'
import axios from 'axios'

/* export const getSchoolEditorials = async (
  id: number
): Promise<AuthData | undefined> => {
  return await AUTHSERVICE().get(`/klaar-sales/editorials/${id}`)
}
export const getSchoolArticles = async (
  id: number
): Promise<AuthData | undefined> => {
  return await AUTHSERVICE().get(`/klaar-sales/articles/${id}`)
} */
export const getSchoolSelectors = async (
  id: number
): Promise<AuthData | undefined> => {
  return await AUTHSERVICE().get(`/klaar-sales/catalog/${id}`)
}
export const getSchoolGrades = async (
  id: number
): Promise<AuthData | undefined> => {
  return await AUTHSERVICE().get(`/klaar-sales/grades/${id}`)
}

export interface SellsBySchool {
  idcolegio: string | number
  grupoarticulo: string
  schoolname: string
  dateini: string
  seccion: string
  alumno: string
  editorial: string
  datefin: string
  grado: string
  id?:string
}

export const getSellsBySchool = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/by-school?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const getTotalSells = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/totals?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const downReportSells = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/download-sales?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const downReportAccess = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio, id } = props
  let url = `/klaar-sales/download-access?id=${id}&idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio'&&key !== 'id' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const getSellsByDay = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/per-day?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const getSellsByArticle = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/by-articles?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}

export const getSellsPerDay = async (
  props: SellsBySchool
): Promise<AuthData | undefined> => {
  const { idcolegio } = props
  let url = `/klaar-sales/total-per-day?idcolegio=${idcolegio}`
  Object.entries(props).forEach(([key, value]) => {
    if (key !== 'idcolegio' && value !== undefined)
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  })
  return await AUTHSERVICE().get(url)
}
