const PoloSvg = ({
  className,
  stroke = '#305599',
  poloColor,
  neckColor,
  sleevesColor,
  sleevesLines,
  neckLines,
  aperture
}: {
  className: string
  stroke: string
  poloColor: string
  neckColor: string
  sleevesColor: string
  sleevesLines: boolean
  neckLines: boolean
  aperture: boolean
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g>
      <g id="Manga_izqueirda" data-name="Manga izqueirda" stroke="black" >
        <path id="Puño_izquierdo" data-name="Puño izquierdo" fill={sleevesColor}  d="m54.42,352.01l-6.95,20.95s-1.54,3.22,0,5.58c2.55,3.9,8.71,6.55,12.58,8.83,10.39,6.11,21.04,10.42,32.31,14.58,10.5,3.87,21.05,7.77,31.84,10.76.75.21,1.51.41,2.26.61,17.7,4.54,33.85,8,33.85,8,0,0,7.14,2.53,10.13-2.56,4.07-6.93,10.7-19.77,10.7-19.77,0,0-4.65,3.51-20.79.04-7.25-1.56-50.89-15.38-51.88-15.64-14.99-3.89-54.96-26.34-54.06-31.38Z"/>
        <path id="Manga_izquierda" data-name="Manga izquierda" fill={poloColor}  d="m166.08,129.17c-10.07,4.47-26.1,27.97-36.55,46.24-10.44,18.28-32.82,74.96-44.76,103.31-11.93,28.34-27.23,63.03-29.46,68.25-2.24,5.22.05,7.07,3.36,9.7,18.28,14.54,38.83,22.84,49.83,26.84,2.97,1.08,31.48,9.34,44.53,13.44,13.05,4.1,19.76,4.48,24.61,3.35,4.85-1.12,6.34-3.35,8.58-7.83,2.23-4.48,8.95-19.02,14.92-26.48,5.97-7.46,6.71-10.44,8.58-16.04,1.87-5.59,7.46-16.41,22-29.09,14.54-12.68,44.38-36.18,4.85-122.33-39.53-86.15-60.42-73.84-70.49-69.37Z"/>
      </g>
      <g id="Manga_derecha" data-name="Manga derecha" stroke="black" >
        <path id="Puño_derecho" data-name="Puño derecho" fill={sleevesColor}  d="m741.96,347.48l10.86,21.43s1.89,3.61.73,6.18c-1.93,4.27-20.42,16.81-20.42,16.81,0,0-33.9,19.78-49.94,26.65-16.8,7.19-32.24,13.07-32.24,13.07,0,0-6.67,3.59-10.41-.99-5.08-6.23-13.04-18.13-13.04-18.13,0,0,4.68,2.27,15-3,6.6-3.37,47.06-23.28,51.22-25.45,6.78-3.55,47.78-23.55,48.25-36.56Z"/>
        <path id="Manga_derecha-2" data-name="Manga derecha" fill={poloColor}  d="m622.54,403.88c3.23,9.69,5.74,9.69,10.77,9.33s24.41-11.12,39.48-18.67c15.07-7.53,46.3-23.32,59.22-33.37,12.92-10.05,10.05-14.36,7.89-18.31-2.15-3.95-10.05-20.46-21.89-43.43-11.84-22.97-30.51-64.96-38.05-89.36-7.53-24.41-20.73-55.63-34.09-72.86-13.64-17.59-21.2-3.19-32.3,9.69-8.97,10.41-38.4,40.56-52.4,81.47-14,40.91,13.53,83.03,22.14,95.23,8.64,12.24,14.55,26.36,21.61,39.53,6.97,13,12.02,26.94,17.56,40.62l.05.13Z"/>
      </g>
      <g id="Playera_Polo" data-name="Playera Polo" stroke="black" fill={poloColor}>
        {aperture&&
        <path id="Abertura"   d="m200.12,739.5c-.62-3.73-4.24-8.49-10.37-8.18-4.47.22,11.14-63.18,13.99-63.17l-3.62,71.35Z"/>
        }
        <path id="Borde_playera_polo" data-name="Borde playera polo"   d="m342.23,113.66c5.6,9.32,19.45,15.07,31.67,25.8,12.94,11.36,24.56,27.81,24.56,27.81,0,0,18.56-17.12,28.29-23.75,6.35-4.33,19.51-6.93,30.7-15.5,11.19-8.58,20.49-24.9,23.05-34.52,4-15,2-36,2-36,0,0,42,19,56,22,11.71,2.51,21.67,8.72,31.4,15.4,18.85,12.94,41.78,19.76,61.9,30.64,8.1,4.38,9.32,5.6,8.58,18.27-.75,12.68-9.7,61.91-18.27,93.24-8.58,31.33-17.16,53.33-19.02,72.72-1.87,19.4-9.32,46.25-9.32,46.25,0,0-.37,29.46-1.87,54.82-1.49,25.36,5.34,127.37,5.34,161.68s-1.26,48.88-1.12,58.18c.13,8.34,1.41,24.95,2.52,32.79,1.12,7.83,7.62,75.05,5.76,83.63-1.86,8.58-25.19,10.93-47.19,18.02-22.01,7.09-47.37,13.05-84.66,14.17-37.3,1.12-59.67,4.11-81.3,4.48-21.63.37-33.94,3.35-76.08-2.24-42.14-5.6-105.02-20.07-111.74-29.02-6.71-8.95-1.91-28.55-1.53-49.06.37-20.51,1.57-35.63,2.69-55.02,1.12-19.4,6.11-75.43,8.36-95.19,2.24-19.76.46-32.49,3.76-62.61,3.42-31.2-5.22-83.17-5.97-102.94-.75-19.76.37-29.84-2.24-36.55-2.61-6.71-4.47-24.24-5.59-36.55-1.12-12.31-9.32-64.88-16.78-95.1-7.46-30.21-12.31-59.3-16.78-68.62-4.48-9.32-8.85-17.41-8.85-17.41,0,0,6.99-9.44,21.53-15.79,14.54-6.34,36.16-17.76,56.71-26.81l3.85-1.99,8.93-4.61,10.11-5.22,7.38-3.81c.31-.16.59-.42.93-.48,1.56-.29,2.55-.1,3.56-.29,1.18-.22,4.82-.82,6-1,2.52-.39,2.24-.68,4.6-1.67l6.76-2.84,9.64-4.06,9.9-4.17,7.53-3.17,2.57-1.08,21.73,57.16"/>
        
        <path id="Solapa_botón_derecho" data-name="Solapa botón derecho"   d="m452.58,153.72l13.22-25.84c2.57-4.27-27.82,8.67-32.13,11.57-4.16,2.81-25.89,13.52-33.7,25.91-1.7,2.68-8.43,9.32-11.44,16.7-3.01,7.38-5.13,32.31-5.13,32.31l-.62,30,24.68.1s1.95-49.18,3.93-57.38c2.27-9.37,8.34-14.06,16.35-20.57l5.64-4.58c1.58-1.28,3.35-2.3,5.25-3.01l13.94-5.21Z"/>
        <path id="Solapa_botón_izquierdo" data-name="Solapa botón izquierdo"   d="m385.43,199c-.72-52.52-29.6-53.03-29.6-53.03-3.09-15.07-8.86-21.62-14.01-34.01,5.82,3.67,32.97,21.9,42.17,31.2,8.45,7.16,12.31,17.63,14.47,24.12,0,0-8.17,6.99-13.02,31.72Z"/>
        <path id="Solapa"   d="m385.74,198.43c-3.47-14.55,22.48-58.89,82.32-72.76-.02.07-14.66,26.78-14.68,26.85-8.55,3.95-11.88,4.97-18.07,6.49-10.01,2.68-37.99,11.9-49.57,39.42Z"/>
      
      </g>
      <g id="Cuello" stroke="black" fill={neckColor}>        
        <path id="Cuello_interno" data-name="Cuello interno"   d="m456.06,128.88l11.83-3.8c3.21-7.74,6.65-15.39,9.69-23.2,1.44-3.7,2.99-7.47,3.93-11.34,1.02-4.23,1.41-8.57,1.83-12.89.49-5.18.87-10.38.46-15.57-.15-1.84-.55-3.58-1.5-5.19-3.13-5.35-10.12-7.34-15.78-8.45-.29-.06-.59-.11-.88-.16-20.7-3.71-66.01-2.36-66.01-2.36-6.71-.24-54.3,2.42-54.3,2.42,0,0-26.54,2.53-17.77,27.47,8.74,24.86,14.74,36.63,14.74,36.63l9.24,4.85s-9.37-18.4-11.88-24.43c-2.51-6.02-13.06-28.25-4.07-33.73,8.6-5.23,26.1-5.58,26.1-5.58,0,0,41.69.33,45.73.26,3.96-.07,45.74,1.41,47.76,1.46,2.08.05,8.14.31,8.15.31,3.85.16,7.92,1.45,10.91,3.93,5.62,4.66,4.39,12.59,2.64,18.8-2.22,7.87-4.95,15.69-7.6,23.42-1.97,5.74-4.03,11.46-6.48,17.01-1.33,3-2.98,7.86-5.91,9.75-.26.17-.52.33-.83.39Z"/>
        <path id="Cuello-2" data-name="Cuello"   d="m538.81,79.51s-17.37-4.23-26.69-11.65c-5-3.98-8.94-9.31-12.48-14.59-1.64-2.44-3.16-4.96-4.66-7.49-2.41-4.1-5.14-7.93-9.51-10.1-14.68-7.28-31.11-6.84-47.04-6.63-11.37.15-22.72.58-34.08.92-11.79.36-62.16-.6-66.88.83-4.2,1.27-20.77-.17-30.06,16.33-3.73,6.62-6.62,13.4-15.4,20.12-9.19,7.04-21.35,7.58-21.35,7.58,0,0,3.13,30.65,14.24,55.9,4.27,9.71,19.67,33.92,19.67,33.92,0,0,12.14-6.89,22.21-14.56,5.37-4.09,25.71-15.6,25.71-15.6.65-1.61-21.58-46.7-22.97-50.59-1.38-3.88-10.23-29.26,8.81-32.59,32.06-5.61,106.25-4.53,125.62-1.5,20.03,3.13,18.5,9.28,19.2,21.75,1.16,20.92-21.37,69.9-23.23,68.97-.43-.21,6.96,5.71,17.54,13.01,12.29,8.49,25.12,20.03,25.12,20.03,0,0,10.13-12.75,12.38-16.25,3.8-5.91,6.87-13.16,9.72-18.91,2.66-5.37,7.17-18.41,7.92-21.42,1.69-6.8,5.12-22.82,6.2-37.5Z"/>
      </g>
      
      {neckLines &&
      <g>
      <g id="Lineas_cuello_izquierdo" data-name="Lineas cuello izquierdo" stroke="black">
        <path id="Linea_1_cuello_izquierda" data-name="Linea 1 cuello izquierda"   d="m274.56,74.59s2.2,30.2,12.87,52.3c10.66,22.09,21.08,35.61,21.08,35.61"/>
        <path id="Linea_2_cuello_izquierda" data-name="Linea 2 cuello izquierda"   d="m278.5,73.5s1.9,30.3,12.56,52.39c10.66,22.09,21.44,33.61,21.44,33.61"/>
        <path id="Linea_3_cuello_izquierda" data-name="Linea 3 cuello izquierda"   d="m282.5,72.5s1.9,30.3,12.56,52.39c10.66,22.09,20.44,32.61,20.44,32.61"/>
      </g>
      <g id="Lineas_cuello_derecho" data-name="Lineas cuello derecho" stroke="black">
        <path id="Linea_1_cuello_derecha" data-name="Linea 1 cuello derecha"   d="m534.5,78.5s-2.69,34.46-13.35,56.55c-10.66,22.09-21.65,35.45-21.65,35.45"/>
        <path id="Linea_2_cuello_derecha" data-name="Linea 2 cuello derecha"   d="m496.5,168.5s10.35-12.35,21.01-34.45c10.66-22.09,13.99-56.55,13.99-56.55"/>
        <path id="Linea_3_cuello_derecha" data-name="Linea 3 cuello derecha"   d="m493.5,165.5s10.35-12.35,21.01-34.45c9.17-19,12.92-47.16,13.78-54.66"/>
      </g>
      </g>}
      {sleevesLines && <g>

      <g id="Lineas_manga_derecha" data-name="Lineas manga derecha" stroke="black">
        <path id="Linea_1_manga_derecha" data-name="Linea 1 manga derecha"   d="m752.5,368.5c-19.39,17.58-59.18,41.09-111.77,61.46"/>
        <path id="Linea_2_manga_derecha" data-name="Linea 2 manga derecha"   d="m637.98,426.66l.52-.16c52.81-18.56,92.61-43.42,112-61l.42-.37"/>
        <path id="Linea_3_manga_derecha" data-name="Linea 3 manga derecha"   d="m749.5,361.5c-19.39,17.58-61.19,43.44-114,62"/>
      </g>
      <g id="Lineas_manga_izquierda" data-name="Lineas manga izquierda" stroke="black">
        <path id="Linea_1_manga_izquierda" data-name="Linea 1 manga izquierda"   d="m47.47,372.96s46.79,32.83,123.91,44.19"/>
        <path id="Linea_2_manga_izquierda" data-name="Linea 2 manga izquierda"   d="m48.45,370s46.69,30.85,124.87,43.68"/>
        <path id="Linea_3_manga_izquierda" data-name="Linea 3 manga izquierda"   d="m50.01,365.32s41.82,30.8,125.16,44.99"/>
      </g>
      </g> }
      
      <g id="costuras" stroke="#d9d9d9">
      <path id="Costura_manga_izquierda" data-name="Costura manga izquierda"   d="m56.58,345.25c21.73,18.7,59.22,37.49,126.69,50.77"/>
      <path id="Costura_abajo_1" data-name="Costura abajo 1"   d="m201.02,737.41c139.04,28.31,215.3,45.07,403.4-3.84"/>
      <path id="Costura_abajo_2" data-name="Costura abajo 2"   d="m201.02,731.56c139.04,28.31,214.69,44.68,402.79-4.24"/>
      <line id="Pretina_izquierda" data-name="Pretina izquierda"   x1="203.93" y1="668.15" x2="210.24" y2="668.15"/>
      {aperture&&
        <g>
      <path id="Costura_izquierda" data-name="Costura izquierda"   d="m204.33,652.96c2.64,1.88,5.42,6.27,6.89,8.98,1.29,2.38,2.78,5.25,2.9,13.34.21,14.07-4.29,68.58-4.29,81.52"/>
      <path id="Costura_derecha" data-name="Costura derecha"   d="m598.26,652.96c-2.64,1.88-5.42,6.27-6.89,8.98-1.29,2.38-2.78,5.25-2.9,13.34-.21,14.07,4.29,68.58,4.29,81.52"/>
      <path id="Costura_abertura" data-name="Costura abertura"   d="m190.96,729.34c0-11.16,6.04-39.81,12.16-57.7"/>
      </g>}
      <path id="Costura_manga_dercha" data-name="Costura manga dercha"   d="m741.23,345.16c-20.75,23.07-59.81,41.95-113.04,65.22"/>
      </g>

      <g stroke="black">
      <g id="Escudo_derecho" data-name="Escudo derecho" >
        <circle  cx="517" cy="275" r="29.5"/>
        <path d="m517,246c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
      </g>
      <g id="Escudo_izquierdo" data-name="Escudo izquierdo">
        <circle  cx="299" cy="275" r="29.5"/>
        <path d="m299,246c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
      </g>
      </g>
      </g>
    </svg>
  )
}

export default PoloSvg

