import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare10mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
      stroke='black'
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 10mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_10mm_Completo'
              data-name='Path Cuadro 10mm Completo'
            >
              <g
                id='Path_Cuadro_10mm_Completo-2'
                data-name='Path Cuadro 10mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='226.13' y1='104.42' x2='226.13' y2='737.01' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='622.23' y1='104.42' x2='622.23' y2='737.01' />
                  </g>
                  <line x1='648.16' y1='129.87' x2='200.01' y2='129.87' />
                  <line x1='648.16' y1='156.06' x2='200.01' y2='156.06' />
                  <line x1='648.16' y1='182.7' x2='200.01' y2='182.7' />
                  <line x1='648.16' y1='209.07' x2='200.01' y2='209.07' />
                  <line x1='648.16' y1='235.45' x2='200.01' y2='235.45' />
                  <line x1='648.16' y1='261.86' x2='200.01' y2='261.86' />
                  <line x1='648.16' y1='288.39' x2='200.01' y2='288.39' />
                  <line x1='648.16' y1='314.59' x2='200.01' y2='314.59' />
                  <line x1='648.16' y1='341.23' x2='200.01' y2='341.23' />
                  <line x1='648.16' y1='367.6' x2='200.01' y2='367.6' />
                  <line x1='648.16' y1='393.97' x2='200.01' y2='393.97' />
                  <line x1='648.16' y1='420.38' x2='200.01' y2='420.38' />
                  <line x1='648.16' y1='446.59' x2='200.01' y2='446.59' />
                  <line x1='648.16' y1='472.79' x2='200.01' y2='472.79' />
                  <line x1='648.16' y1='499.43' x2='200.01' y2='499.43' />
                  <line x1='648.16' y1='525.8' x2='200.01' y2='525.8' />
                  <line x1='648.16' y1='552.17' x2='200.01' y2='552.17' />
                  <line x1='648.16' y1='578.58' x2='200.01' y2='578.58' />
                  <line x1='648.16' y1='605.12' x2='200.01' y2='605.12' />
                  <line x1='648.16' y1='631.31' x2='200.01' y2='631.31' />
                  <line x1='648.16' y1='657.95' x2='200.01' y2='657.95' />
                  <line x1='648.16' y1='684.32' x2='200.01' y2='684.32' />
                  <line x1='648.16' y1='710.69' x2='200.01' y2='710.69' />
                  <line x1='252.77' y1='104.42' x2='252.77' y2='737.01' />
                  <line x1='279.14' y1='104.42' x2='279.14' y2='737.01' />
                  <line x1='305.51' y1='104.42' x2='305.51' y2='737.01' />
                  <line x1='331.92' y1='104.42' x2='331.92' y2='737.01' />
                  <line x1='358.45' y1='104.42' x2='358.45' y2='737.01' />
                  <line x1='384.65' y1='104.42' x2='384.65' y2='737.01' />
                  <line x1='411.29' y1='104.42' x2='411.29' y2='737.01' />
                  <line x1='437.66' y1='104.42' x2='437.66' y2='737.01' />
                  <line x1='464.03' y1='104.42' x2='464.03' y2='737.01' />
                  <line x1='490.44' y1='104.42' x2='490.44' y2='737.01' />
                  <line x1='516.66' y1='104.42' x2='516.66' y2='737.01' />
                  <line x1='542.85' y1='104.42' x2='542.85' y2='737.01' />
                  <line x1='569.49' y1='104.42' x2='569.49' y2='737.01' />
                  <line x1='595.86' y1='104.42' x2='595.86' y2='737.01' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m635.49,119.63c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,145.35c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,168.83c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,224.43c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,199.42c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,247.9c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,303.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,278.49c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,326.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,382.57c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,357.56c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,406.05c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,461.64c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,436.64c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,485.12c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,540.71c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,515.71c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,564.19c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,619.79c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,594.78c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,643.26c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,698.86c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,673.85c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,722.33c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m635.49,171.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,194.73c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,119.63c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,142.01c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,224.64c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,247.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,277.35c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,300.16c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,330.07c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,352.87c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,382.78c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,405.58c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,435.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,458.3c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,488.21c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,511.01c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,540.93c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,563.73c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,593.64c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,616.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,646.36c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,669.16c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,699.07c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,721.87c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M213.77,119.63a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,145.35a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,168.83a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,224.43a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,199.42a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,247.9a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,303.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,278.49a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,327a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,382.57a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,357.56a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,406.05a18,18,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,461.64a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,436.64a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,485.12a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,540.71a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,515.71a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,564.19a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,619.79a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,594.78a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,643.26a18.34,18.34,0,0,0-2.68,3.42l-.19-.43Q211.47,645,211,645a1.84,1.84,0,0,0-1.23.71,1.53,1.53,0,0,1,.72.54,6.29,6.29,0,0,1,.68,1.28l.18.4a8.38,8.38,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,698.86a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,673.85a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,722.33a18.07,18.07,0,0,0-2.68,3.43l-.19-.44Q211.47,724,211,724a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M213.77,171.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,194.73a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.39-.87-.74-1.3-1.05-1.3a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,119.63a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,142a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,224.64a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,247.44a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,277.35a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,300.16a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,330.07a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,352.87a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,382.78a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,405.58a18.07,18.07,0,0,0-2.68,3.43l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,435.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,458.3a18.34,18.34,0,0,0-2.68,3.42l-.19-.43Q211.47,460,211,460a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,488.21a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,511a18.07,18.07,0,0,0-2.68,3.43l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,540.93a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,563.73a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,593.64a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,616.44a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.53,1.53,0,0,1,.72.54,6.29,6.29,0,0,1,.68,1.28l.18.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,646.36a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,669.16a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,699.07a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,721.87a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.18,15.18,0,0,0-3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 10mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_10mm_Datos' data-name='Path Cuadro 10mm Datos'>
              <g
                id='Path_Cuadro_10mm_Datos-2'
                data-name='Path Cuadro 10mm Datos'
              >
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='226.13' y1='148.56' x2='226.13' y2='729.4' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='622.23' y1='148.56' x2='622.23' y2='729.4' />
                  </g>
                  <line x1='648.16' y1='174.01' x2='200.01' y2='174.01' />
                  <line x1='648.16' y1='200.21' x2='200.01' y2='200.21' />
                  <line x1='648.16' y1='226.85' x2='200.01' y2='226.85' />
                  <line x1='648.16' y1='253.22' x2='200.01' y2='253.22' />
                  <line x1='648.16' y1='279.59' x2='200.01' y2='279.59' />
                  <line x1='648.16' y1='306' x2='200.01' y2='306' />
                  <line x1='648.16' y1='332.54' x2='200.01' y2='332.54' />
                  <line x1='648.16' y1='358.73' x2='200.01' y2='358.73' />
                  <line x1='648.16' y1='385.37' x2='200.01' y2='385.37' />
                  <line x1='648.16' y1='411.74' x2='200.01' y2='411.74' />
                  <line x1='648.16' y1='438.11' x2='200.01' y2='438.11' />
                  <line x1='648.16' y1='464.53' x2='200.01' y2='464.53' />
                  <line x1='648.16' y1='490.74' x2='200.01' y2='490.74' />
                  <line x1='648.16' y1='516.93' x2='200.01' y2='516.93' />
                  <line x1='648.16' y1='543.57' x2='200.01' y2='543.57' />
                  <line x1='648.16' y1='569.94' x2='200.01' y2='569.94' />
                  <line x1='648.16' y1='596.32' x2='200.01' y2='596.32' />
                  <line x1='648.16' y1='622.73' x2='200.01' y2='622.73' />
                  <line x1='648.16' y1='649.26' x2='200.01' y2='649.26' />
                  <line x1='648.16' y1='675.46' x2='200.01' y2='675.46' />
                  <line x1='648.16' y1='702.09' x2='200.01' y2='702.09' />
                  <line x1='252.77' y1='148.56' x2='252.77' y2='729.4' />
                  <line x1='279.14' y1='148.56' x2='279.14' y2='729.4' />
                  <line x1='305.51' y1='148.56' x2='305.51' y2='729.4' />
                  <line x1='331.92' y1='148.56' x2='331.92' y2='729.4' />
                  <line x1='358.45' y1='148.56' x2='358.45' y2='729.4' />
                  <line x1='384.65' y1='148.56' x2='384.65' y2='729.4' />
                  <line x1='411.29' y1='148.56' x2='411.29' y2='729.4' />
                  <line x1='437.66' y1='148.56' x2='437.66' y2='729.4' />
                  <line x1='464.03' y1='148.56' x2='464.03' y2='729.4' />
                  <line x1='490.44' y1='148.56' x2='490.44' y2='729.4' />
                  <line x1='516.66' y1='148.56' x2='516.66' y2='729.4' />
                  <line x1='542.85' y1='148.56' x2='542.85' y2='729.4' />
                  <line x1='569.49' y1='148.56' x2='569.49' y2='729.4' />
                  <line x1='595.86' y1='148.56' x2='595.86' y2='729.4' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m635.49,164.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,190.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,213.7c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,269.29c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,244.29c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,292.77c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,348.37c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,323.36c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,371.84c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,427.44c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,402.43c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,450.92c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,506.51c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,481.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,529.99c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,585.58c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,560.58c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,609.06c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,664.65c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m635.49,639.65c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,688.13c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,718.72c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m635.49,216.79c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,239.59c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,164.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,186.88c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,269.51c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,292.31c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,322.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,345.02c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,374.94c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,397.74c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,427.65c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,450.45c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,480.37c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,503.17c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,533.08c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,555.88c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,585.79c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,608.6c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,638.51c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,661.31c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m635.49,691.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m634.33,714.03c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M213.77,164.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,190.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,213.7a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,269.29a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,244.29a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,292.77a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,348.37a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,323.36a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,371.84a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,427.44a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,402.43a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,450.92a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.26,15.26,0,0,0-3.2,2.56' />
                                    <path d='M213.77,506.51a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,481.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,530a17.71,17.71,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,585.58a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,560.58a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,609.06a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,664.65a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M213.77,639.65a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,688.13a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,718.72a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M213.77,216.79a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,239.59a18.07,18.07,0,0,0-2.68,3.43l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,164.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,186.88a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,269.51a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,292.31a18.34,18.34,0,0,0-2.68,3.42l-.19-.43Q211.47,294,211,294a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,322.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,345a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,374.94a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,397.74a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,427.65a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,450.45a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M213.77,480.37a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,503.17a17.71,17.71,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,533.08a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,555.88a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.59-1.29-1.05-1.29a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,585.79a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,608.6a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.39-.87-.74-1.3-1.05-1.3a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,638.51a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,661.31a18.34,18.34,0,0,0-2.68,3.42l-.19-.43Q211.47,663,211,663a1.82,1.82,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M213.77,691.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M214.93,714a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.59-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 10mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_10mm_Completo'
                data-name='Margenes Cuadro 10mm Completo'
              >
                <g
                  id='Margenes_Cuadro_10mm_Completo-2'
                  data-name='Margenes Cuadro 10mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V723a13.08,13.08,0,0,1-13.08,13.08H214A13.09,13.09,0,0,1,200.93,723V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V723A14.08,14.08,0,0,0,214,737.09H634.16A14.08,14.08,0,0,0,648.24,723V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56V736.09H200.93V104.56H647.24m1-1H199.93V737.09H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro 10mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_10mm_Datos'
                data-name='Margenes Cuadro 10mm Datos'
              >
                <g
                  id='Margenes_Cuadro_10mm_Datos-2'
                  data-name='Margenes Cuadro 10mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V715.32a13.09,13.09,0,0,1-13.08,13.08H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V715.32A14.08,14.08,0,0,0,214,729.4H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56V728.4H200.93V149.56H647.24m1-1H199.93V729.4H648.24V148.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare10mmVertical
