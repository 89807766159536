import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

import PantsFrontalSvg from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/PantsFrontalSvg'
import ShadowsFrontalPants from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/ShadowsFrontalPants'
import LabelFrontalPants from 'assets/img/uniforms/sportOutfit/pantsDeportivo/frente/LabelFrontalPants'

const PantsDeportivosPics = () => {
  const { poloValues, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      <Fragment>
        <div className={`${style[`logo-position-${'left'}`]}`}>
          <img className={`${style['svg-logo']}`} src={pictureLogo} />
        </div>
        <div className={`${style['div-polo-svg-absolute']}`}>
          <PantsFrontalSvg className={`${style['svg']}`} />
        </div>

        <div className={`${style['div-label-svg-absolute']}`}>
          <ShadowsFrontalPants className={`${style['svg']}`} />
        </div>
        <div className={`${style['div-tags-svg-absolute']}`}>
          <LabelFrontalPants className={`${style['svg']}`} />
        </div>
      </Fragment>
    </div>
  )
}
export default PantsDeportivosPics
