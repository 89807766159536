import React from 'react'

const ShadowsPantalon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Sombras" stroke='black'>
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m267.93,463.41s15.15,7.7,26.97,7.25c11.82-.44,20.76,5.17,20.76,5.17,0,0,4.7,3.63-2.28,4.28-6.97.66-45.91-11.65-45.45-16.71Z"/>
      <path   d="m305.41,702.78s1.37,8.64,16.52,12.73c15.15,4.09,31.52-10.91,33.33-10.91s8.49-5.3,14.09-6.06c5.6-.76-40.15,8.64-43.34,7.88-3.18-.76-12.73,2.88-20.61-3.64Z"/>
      <path   d="m490.76,721.2s13.33,3.84,25.45,1.62c12.12-2.22,16.97-6.06,16.97-6.06,0,0,4.85-4.44-2.58-3.73-7.42.71-20.76,8.78-39.85,8.17Z"/>
      <path   d="m470.68,485.5s7.2-2.34,22.66-1.54c15.47.81,30.23-20.07,30.23-20.07,0,0,6.34-16.08,7.71-16.65,1.38-.58-.43-6.87-3.47-5.79-3.04,1.08-12.92,20.67-21.23,25.66-9.43,5.66-34.85,11.13-35.9,18.39Z"/>
      <path   d="m424.45,361.85s-.21,19.6,3.8,26.76c4,7.17,5.27,8.06,5.06,10.46-.21,2.39,12.22,9.77,15.59,10.19,3.37.42-6.95-3.37-8.43-14.96-1.48-11.59-7.59-14.76-9.06-15.18-1.47-.42-7.17-12.43-6.96-17.28Z"/>
      <path   d="m279.15,94.69s7.44,1.89,18.92,4.83c11.48,2.94,22.14,4.96,22.14,4.96,0,0-.71,6.18-.57,9.36.07,1.63-.85,2.63,2.48,3.45,19.31,4.76,16.15-11.5,16.15-11.5,0,0,5.44,2.08,16.43,2.2,17.36.18,41.47.07,41.47.07,0,0-24.91,3.7-25.97,3.68-41.7-1.13-26.49,11.32-37.71,10.25-8.34-.79-14.24-1.2-24.33-3.21-17.56-3.51-4.28-3.88,4.66-7.97,8.93-4.1-33.67-16.1-33.67-16.1Z"/>
      <path   d="m406.21,108.36s52.57-.23,55.03,0c2.47.24,3.4,7.1,3.4,7.1l7.31,1.77,9.37,2.05s17.2,2.64,0,2.76c-17.2.12-19.26-6.71-26.66-6.58-7.4.12-42.29-2.69-48.46-7.1Z"/>
      <path   d="m269.6,280.18s1.65-52.54,1.98-55c.32-2.46,7.22-3.14,7.22-3.14l2.03-7.24,2.39-9.29s2.81-58.29,2.76.1c-.02,17.2-7.39,19.01-7.53,26.41-.14,7.4-4.2,42.17-8.83,48.17Z"/>
      <path   d="m486.53,243.15s18.47,2.13,21.39,1.53c7.86-1.6,4.06-17.01,9.49-21.35,5.43-4.34,14.93-10,12.8,25.34-.96,15.9-.72,45.36-.72,45.36,0,0-7.14-39.25-42.96-50.89Z"/>
      <path   d="m365.09,254.74c12.11-5.1,42.64-17.95,54.33-22.85,6.05-2.51,12.14-4.92,18.24-7.3.78-.18,3.4-.87,3.05.63-1.24,2-3.56,3.05-5.42,4.38,0,0-.06-.08-.06-.08,1.65-1.24,3.38-2.43,4.72-4,.14-.19.42-.58.19-.63-.61-.17-1.74.16-2.24.29-6.01,2.61-12.03,5.17-18.1,7.65-11.62,4.58-42.83,17.44-54.72,21.91h0Z"/>
      <polygon   points="418.46 91.13 423.68 91.63 418.46 92.13 418.46 91.13 418.46 91.13"/>
    </g>
  </g>
    </svg>
  )
}

export default ShadowsPantalon
