import React, { memo, useEffect } from 'react'
import { Modal, List, Row, Col, Form } from 'antd'
import Select from 'components/inputs/Select'
import { useNavigate } from 'react-router-dom'
import LevelActions from 'modules/UserSchool/Dashboard/LevelActions/LevelActions'
import GradesActions from 'modules/UserSchool/Dashboard/GradesActions/GradesActions'
import Input from 'components/inputs/Input'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { useLevelsAndGrades } from 'hooks/userSchool/useLevelsAndGrades'
import { LevelListInt } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import Button from 'components/buttons/Button/Button'
import B from 'components/texts/B/B'
import { RootState } from 'redux/store/store'
import { formatUrlNames } from 'utilities/formatUrlNames'
import style from './Structure.module.scss'
import globalStyle from '../../../styles/global.module.scss'
import Icon from 'components/icons'
import Collapse from 'components/Collapse/Collapse'
import Caption from 'components/texts/Caption/Caption'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import {
  setGradesForm,
  setLevelsForm
} from 'redux/reducers/levelsAndGrades'

const redirectOptions = [
  {
    icon: 'book',
    title: 'Libro',
    url: '/libros',
    description: 'Crea listas de libros'
  },
  {
    icon: 'notebook',
    title: 'Cuadernos',
    url: '/cuadernos',
    description: 'Crea paquetes de cuadernos'
  },
  {
    icon: 'uniform',
    title: 'Uniformes',
    url: '/uniforms',
    description: 'Crea uniformes'
  },
  {
    icon: 'utilsIcon',
    title: 'Útiles',
    url: '/utiles?tab=0',
    description: 'Crea paquetes de útiles'
  }
]
const colStyle = { cursor: 'pointer', padding: '0 20px' }

 const INPUTS_INITIAL_STATE = {
  id_magento: {
    label: 'Selecciona el nivel',
    name: 'id_magento',
    placeholder: 'Selecciona el nivel',
    value: null,
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: false
  },
  name: {
    label: 'Renombra el nivel',
    name: 'name',
    placeholder: 'Renombra el nivel',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: '',
    id: undefined
  }
}
 const INPUTS_INITIAL_STATE_GRADES = {
  name: {
    label: 'Renombra el grado',
    name: 'name',
    placeholder: 'Renombra el grado',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: ''
  },
  male_population: {
    label: 'Población masculina proyectada',
    name: 'male_population',
    placeholder: '000',
    value: '',
    type: 'number',
    required: true,
    minLenght: 0,
    maxLength: 6, //maxLength solo funciona para tipo text
    status: '' // puede ser 'warning' o 'error' segun antd
  },
  female_population: {
    label: 'Población femenina proyectada',
    name: 'female_population',
    placeholder: '000',
    value: '',
    type: 'number',
    required: true,
    minLenght: 0,
    maxLength: 6, //maxLength solo funciona para tipo text
    status: '' // puede ser 'warning' o 'error' segun antd
  },
  id_magento: {
    label: 'Selecciona el grado',
    name: 'id_magento',
    placeholder: 'Selecciona el grado',
    value: null,
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    disabled: false
  }
}

const index = memo(() => {
  const { levelsForm } = useSelector(
    (state: RootState) => state.levelsAndGrades
  )
  const {
    levelList,
    magentoLevels,
    open,
    openTerminate,
    schoolInfo,
    loadingModal,
    openStartingModal,
    setOpen,
    setOpenTerminate,
    handleChange,
    handleChangeSelect,
    postNewLevel,
    deleteLevelById,
    editLevel,
    postNewGrade,
    deleteGradeById,
    updateGradeById,
    cancelLevelModal,
    cancelTerminateModal,
    finishStructure,
    cancelStartingModal,
    requestModifyStructureToAdmin
  } = useLevelsAndGrades()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLevelsForm(INPUTS_INITIAL_STATE))
    dispatch(setGradesForm(INPUTS_INITIAL_STATE_GRADES))

  }, [])

  const navigate = useNavigate()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const RenderGradesList = (gradeList: any) => {
    let schoolType = schoolInfo.sex
    let gradeTitles: any = [
      {
        1: 'Nombre del grado',
        2: 'Hombres',
        3: 'Mujeres',
        4: ''
      }
    ]
    if (schoolType === 'f')
      gradeTitles = [
        {
          1: 'Nombre del grado',
          2: 'Mujeres',
          3: ''
        }
      ]
    if (schoolType === 'm')
      gradeTitles = [
        {
          1: 'Nombre del grado',
          2: 'Hombres',
          3: ''
        }
      ]
    const redirectToGrade = (grade: any) => {
      const path = {
        pathname: `/grado/${formatUrlNames(grade.name)}`,
        search: '?module=libros'
      }
      const state = { state: { ...grade, level: gradeList } }
      navigate(path, state)
    }

    if (gradeList.grades.length === 0)
      return (
        <EmptyListMessage icon='emptyIcons' text='Aún no has creado grados' />
      )

    return (
      <>
        <List
          itemLayout='horizontal'
          dataSource={gradeTitles}
          renderItem={(title: any) => (
            <List.Item style={{ padding: ' 10px 0' }}>
              {Object.keys(title).map((res, key) => {
                return (
                  <Col
                    key={key}
                    className='ListTitles'
                    style={colStyle}
                    span={6}
                  >
                    <B size='Light'>{title[res]}</B>
                  </Col>
                )
              })}
            </List.Item>
          )}
        />
        <List
          itemLayout='horizontal'
          dataSource={gradeList.grades}
          renderItem={(grade: any) => (
            <List.Item style={{ padding: ' 10px 0' }}>
              <Col
                style={colStyle}
                span={6}
                onClick={() => {
                  redirectToGrade(grade)
                }}
              >
                <B size='Light'>{grade.name}</B>
              </Col>
              {(schoolInfo.sex === 'm' || schoolInfo.sex === 'u') && (
                <Col
                  style={colStyle}
                  span={6}
                  onClick={() => {
                    redirectToGrade(grade)
                  }}
                >
                  {' '}
                  <B size='Light'>{grade.male_population}</B>
                </Col>
              )}
              {(schoolInfo.sex === 'f' || schoolInfo.sex === 'u') && (
                <Col
                  style={colStyle}
                  span={6}
                  onClick={() => {
                    redirectToGrade(grade)
                  }}
                >
                  {' '}
                  <B size='Light'>{grade.female_population}</B>
                </Col>
              )}

              <Col
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end'
                }}
                span={6}
              >
                {schoolInfo.status !== 'Autorizado' && (
                  <GradesActions
                    schoolInfo={schoolInfo}
                    data={grade}
                    level={gradeList}
                    deleteGradeById={deleteGradeById}
                    loadingModal={loadingModal}
                    updateGradeById={updateGradeById}
                  />
                )}
              </Col>
            </List.Item>
          )}
        />
      </>
    )
  }

  const RenderLevelList = (Levels: LevelListInt[]): any => {
    return Levels.map((res: any, key: number) => {
      return (
        <Collapse
          key={key + res.id}
          id={res.id}
          title={res.name}
          customSideElement={
            schoolInfo.status !== 'Autorizado' ? (
              <LevelActions
                magentoLevels={magentoLevels}
                data={res}
                schoolInfo={schoolInfo}
                loadingModal={loadingModal}
                handleChangeLevel={handleChange}
                handleChangeSelectLevel={handleChangeSelect}
                deleteLevelById={deleteLevelById}
                editLevel={editLevel}
                postNewGrade={postNewGrade}
              />
            ) : null
          }
        >
          {RenderGradesList(res)}
        </Collapse>
      )
    })
  }

const openCreateNewModal = () =>{
  dispatch(setLevelsForm({levelsForm:INPUTS_INITIAL_STATE}))
  dispatch(setGradesForm({gradesForm:INPUTS_INITIAL_STATE_GRADES}))
  setTimeout(() => {
    setOpen(true)
  });
}

  const buttonsProp = () => {
    let haveOne = true
    if (levelList.length === 0) {
      haveOne = false
    } else {
      levelList.forEach((level) => {
        if (level.grades.length === 0) haveOne = false
      })
    }

    const props: any = []

    if (
      uid.role === 'Administrador' &&
      schoolInfo.status !== 'Autorizado' &&
      haveOne
    ) {
      props.unshift({
        label: 'Terminar estructura',
        onClick: () => setOpenTerminate(true),
        rounded: true,
        sector: 'secondary',
        background: 'white',
        size: 'm'
      })
    }
    if (
      uid.role === 'Administrador' &&
      schoolInfo.status === 'Autorizado' &&
      haveOne
    ) {
      props.unshift({
        label: 'Solicitar modificación',
        onClick: requestModifyStructureToAdmin,
        rounded: true,
        sector: 'terciary',
        background: 'white',
        size: 'm'
      })
    }

    if (uid.role === 'Administrador') {
      props.push({
        label: 'Crear nuevo nivel',
        onClick: openCreateNewModal,
        rounded: true,
        sector: 'secondary',
        background: 'color',
        size: 'm',
        disabled: schoolInfo.status === 'Autorizado'
      })
    }
    return props
  }

  return (
    <Row align='middle' gutter={[0, 16]}>
      <TitleHeader
        buttonProps={buttonsProp()}
        IconName='estructure'
        title='Creación de secciones y grados escolares'
      />
      <Col span={24}>
        {levelList.length > 0 ? (
          RenderLevelList(levelList)
        ) : (
          <EmptyListMessage
            icon='structureIcon'
            text='Aún no has creado niveles'
          />
        )}
      </Col>

      <Modal
        title='Crear nuevo nivel'
        open={open}
        confirmLoading={loadingModal}
        onCancel={cancelLevelModal}
        className={style.createLevelModal}
        footer={
          <div className={style.createLevelModalFooter}>
            <Button
              type='blue'
              rounded
              disabled={
                levelsForm?.id_magento?.value === null ||
                levelsForm?.id_magento?.value === undefined
              }
              onClick={postNewLevel}
              isLoading={loadingModal}
              label='Crear'
            />
          </div>
        }
      >
        <div className={style.createLevelModalbody}>
          <Form layout='vertical' autoComplete='off'>
            {Object.entries(levelsForm).map(([key, value]) => {
              if (value.type === 'select')
                return (
                  <Select
                    key={key}
                    name={value.name}
                    placeholder={value.placeholder}
                    label={value.label}
                    value={value.value}
                    onChange={handleChangeSelect}
                    options={magentoLevels}
                    disabled={value.disabled}
                  />
                )
              return (
                <Input
                  key={key}
                  name={value.name}
                  placeholder={value.placeholder}
                  label={value.label}
                  type={value.type}
                  value={value.value}
                  onChange={handleChange}
                  onClick={(e: any) => e?.stopPropagation()}
                />
              )
            })}
          </Form>
        </div>
      </Modal>

      <Modal
        title='Terminar estructura'
        open={openTerminate}
        onCancel={cancelTerminateModal}
        className={globalStyle.confirmModal}
        footer={
          <div className={globalStyle.confirmModalFooter}>
            <Button
              isLoading={loadingModal}
              onClick={finishStructure}
              rounded
              label='Terminar'
            />
          </div>
        }
      >
        <div className={globalStyle.confirmModalBody}>
          <Icon name='estructure' width={116} height={116} />
          <B size='Light'>
            El proceso de terminación de una estructura conlleva a que no se
            podrán agregar nuevos grados ni paquetes ¿deseas continuar?.
          </B>
        </div>
      </Modal>

      <Modal
        title='Bienvenido a Ezetera'
        open={openStartingModal}
        onCancel={cancelStartingModal}
        className={globalStyle.confirmModal}
        footer={<></>}
      >
        <div className={globalStyle.blueModalBody}>
          <B size='Light'>Elige con qué te gustaría comenzar.</B>

          {redirectOptions.map((res, key) => {
            return (
              <div
                key={`${key}-firts-time-redirect`}
                className={style.redirectOptions}
                onClick={() => navigate(res.url)}
              >
                <div className={style.left}>
                  <Icon width={33} height={33} name={res.icon} />
                  <div className={style.text}>
                    <B>{res.title}</B>
                    <Caption size='Light'>{res.description}</Caption>
                  </div>
                </div>
                <div className={style.right}>
                  <Icon name='rightArrow' />
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
    </Row>
  )
})
index.displayName = 'Structure'
export default index
