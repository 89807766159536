import { Modal, Space, Tooltip } from 'antd'
import React, { memo, useState } from 'react'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import style from '../../../modules/UserSchool/Dashboard/LevelActions/LevelActions.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUrlNames } from 'utilities/formatUrlNames'
const UtilListActions = memo(({ utilsData }: any) => {
  const navigate = useNavigate()
  const params = useParams()

  const [loadingModal, setloadingModal] = useState(false)

  const [openDel, setOpenDel] = useState(false)

  const handleCancel = (e: any) => {
    setOpenDel(false)
  }

  const showDeleteModal = (event: any, data: any) => {
    event.stopPropagation()
    setOpenDel(true)
  }
  return (
    <Space>
      <button
        style={{ opacity: '0', position: 'absolute' }}
        id='closeAntdModal'
        onClick={handleCancel}
      ></button>
      <Tooltip title='Editar Nivel'>
        <PencilSquareIcon
          className={`${style['iconStyle']} ${style['iconStyleFill']}`}
          onClick={(event) => {
            navigate(
              `/utiles/${params.package}/${formatUrlNames(utilsData.name)}`,
              {
                state: utilsData
              }
            )
          }}
        />
      </Tooltip>
      <Tooltip title='Eliminar Nivel'>
        <TrashIcon
          className={`${style['iconStyle']} ${style['red']}`}
          onClick={(event) => showDeleteModal(event, utilsData)}
        />
      </Tooltip>

      <Modal
        title='Eliminar Nivel'
        open={openDel}
        onOk={handleCancel}
        confirmLoading={loadingModal}
        onCancel={handleCancel}
      >
        Estas seguro que deseas eliminar este util '{utilsData.name}'
      </Modal>
    </Space>
  )
})
UtilListActions.displayName = 'UtilListActions'
export default UtilListActions
