import React, { memo } from 'react'
import style from './Overline.module.scss'
interface OverlineInter {
  variation?: '1'
  size?: 'Bold' | 'Regular' | 'Light'
  children: string
}
const Overline = memo(
  ({ variation = '1', size = 'Regular', children }: OverlineInter) => {
    return (
      <span
        className={style.Overline}
        data-variation={variation}
        data-size={size}
      >
        {children}
      </span>
    )
  }
)
Overline.displayName = 'Overline'
export default Overline
