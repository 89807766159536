import { AUTHSERVICE } from '../config'

interface ICreatePackageData {
  name: string
  description: string
  id_level: number
  id_grade: number
  quantity: number
}

const newPackage = async (data: ICreatePackageData): Promise<any> => {
  return await AUTHSERVICE().post(`/notebook-package`, data)
}

const createPackageNotebook = async (data: ICreatePackageData) => {
  const results = await newPackage(data)
  return results
}

export { createPackageNotebook }
