import React from 'react'

const PathPortadasExteriores = ({
  className,
  showSizes,
  viewBox = '0 0 800 800',
  notebookSelected,
  rounded,
  colorLomo,
  date
}: {
  className: string
  showSizes: number
  viewBox?: string
  notebookSelected: number
  rounded: boolean
  colorLomo: string
  date: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      <g id='Portada_contraportada' data-name='Portada contraportada'>
        <g
          id='Path_portada_contraportada'
          data-name='Path portada contraportada'
        >
          <g fill={colorLomo}>
            {rounded ? (
              <g id='portada_contraportada-2' data-name='portada contraportada'>
                <path d='m35.5,640V128.5h663.88c9.99,0,18.12,8.13,18.12,18.12v475.27c0,9.99-8.13,18.12-18.12,18.12H35.5Z' />
                <path d='m699.38,129c9.71,0,17.62,7.9,17.62,17.62v475.27c0,9.71-7.9,17.62-17.62,17.62H36V129h663.38m0-1H35v512.5h664.38c10.28,0,18.62-8.33,18.62-18.62V146.62c0-10.28-8.33-18.62-18.62-18.62h0Z' />
              </g>
            ) : (
              <g id='portada_contraportada' data-name='portada contraportada'>
                <rect x='35.5' y='128.5' width='682' height='511.5' />
                <path d='m717,129v510.5H36V129h681m1-1H35v512.5h683V128h0Z' />
              </g>
            )}
          </g>
          {showSizes !== 1 && (
            <g id='Medida_ancho' data-name='Medida ancho'>
              <line x1='716.65' y1='676.96' x2='35.26' y2='676.96' />
              <line x1='716.65' y1='685.56' x2='716.65' y2='668.36' />
              <line x1='35' y1='686.05' x2='35' y2='668.84' />
            </g>
          )}
          {showSizes !== 1 && (
            <g>
              {(() => {
                switch (notebookSelected) {
                  case 4:
                    return (
                      <g>
                        <g
                          id='College_horizontal_ancho_26_cm'
                          data-name='College horizontal ancho 26 cm'
                        >
                          <text
                            className={date}
                            fill='black'
                            stroke='transparent'
                            transform='translate(353.61 696.53)'
                          >
                            <tspan x='0' y='0'>
                              2
                            </tspan>
                            <tspan x='6.85' y='0'>
                              4
                            </tspan>
                            <tspan x='14.11' y='0'>
                              .5 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          className={date}
                          fill='black'
                          stroke='transparent'
                          id='College_horizontal_alto_19.5_cm'
                          data-name='College horizontal alto 19.5 cm'
                          transform='translate(765.23 362.48) rotate(90)'
                        >
                          <tspan x='0' y='0'>
                            1
                          </tspan>
                          <tspan x='6.14' y='0'>
                            9
                          </tspan>
                          <tspan x='13.12' y='0'>
                            .5 cm
                          </tspan>
                        </text>
                      </g>
                    )
                  case 6:
                    return (
                      <g>
                        <g
                          id='Italiano_ancho_21_cm'
                          data-name='Italiano ancho 21 cm'
                        >
                          <text
                            className={date}
                            fill='black'
                            stroke='transparent'
                            transform='translate(359.07 696.53)'
                          >
                            <tspan x='0' y='0'>
                              21 cm
                            </tspan>
                          </text>
                          <text
                            className={date}
                            fill='black'
                            stroke='transparent'
                            id='Italiano_alto_16_cm'
                            data-name='Italiano alto 16 cm'
                            transform='translate(765.23 367.3) rotate(90)'
                          >
                            <tspan x='0' y='0'>
                              16 cm
                            </tspan>
                          </text>
                        </g>
                      </g>
                    )
                  case 8:
                    return (
                      <g>
                        <g
                          id='Marquilla_ancho_32_cm'
                          data-name='Marquilla ancho 32 cm'
                        >
                          <text
                            className={date}
                            fill='black'
                            stroke='transparent'
                            transform='translate(358.7 696.53)'
                          >
                            <tspan x='0' y='0'>
                              32 cm
                            </tspan>
                          </text>
                        </g>
                        <text
                          className={date}
                          fill='black'
                          stroke='transparent'
                          id='Marquilla_alto_24_cm'
                          data-name='Marquilla alto 24 cm'
                          transform='translate(765.23 366.84) rotate(90)'
                        >
                          <tspan x='0' y='0'>
                            2
                          </tspan>
                          <tspan x='6.85' y='0'>
                            4 cm
                          </tspan>
                        </text>
                      </g>
                    )

                  default:
                    ;<></>
                }
              })()}

              <g id='Medida_alto' data-name='Medida alto'>
                <line x1='764.52' y1='127.7' x2='747.31' y2='127.7' />
                <line x1='765' y1='640.7' x2='747.79' y2='640.7' />
                <line x1='755.91' y1='128' x2='755.91' y2='640.5' />
              </g>
            </g>
          )}
        </g>
      </g>
    </svg>
  )
}

export default PathPortadasExteriores
