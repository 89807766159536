import {
  ArrowPathRoundedSquareIcon,
  ArrowTopRightOnSquareIcon,
  EyeSlashIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import {
  IJsonImgParameters,
  IJsonParameters,
  IJsonText
} from 'interfaces/notebooks-interface'
import React, { useRef, useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './dragable.module.scss'

const DraggableItem = ({
  children,
  customContainerRef,
  containerRef,
  itemInfo,
  selectedId,
  text,
  erase,
  setPos,
  maxResolution,
  resetIds,
  isText
}: {
  children?: JSX.Element
  customContainerRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  itemInfo: IJsonImgParameters | IJsonText
  selectedId: number
  text?: (id: number, MyInfo: IJsonParameters) => void
  erase: (id: number) => void
  setPos: () => void
  maxResolution?: { h: number; w: number }
  resetIds: () => void
  isText: boolean
}) => {
  const { jsonImgfront, jsonImgBack } = useSelector(
    (state: RootState) => state.notebooks
  )

  const dragableDivRef = useRef<HTMLDivElement>(null)
  const rotableRef = useRef<HTMLDivElement>(null)
  const scaleRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef<HTMLDivElement>(null)
  const saveRef = useRef<HTMLDivElement>(null)

  const isClickedDrag = useRef<boolean>(false)
  const isClickedRotate = useRef<boolean>(false)
  const isClickedScale = useRef<boolean>(false)
  const isClickedClose = useRef<boolean>(false)
  const isClickedSave = useRef<boolean>(false)
  const coord = useRef<{
    startX: number
    startY: number
    lastX: number
    lastY: number
  }>({
    startX: itemInfo.position.left || 0,
    startY: itemInfo.position.top || 0,
    lastX: itemInfo.position.left || 0,
    lastY: itemInfo.position.top || 0
  })
  const wSize = useRef<{
    width: number,
    height: number
  }>({
    width: window.innerWidth || 0,
    height: window.innerHeight || 0
  })

  const [index, setIndex] = useState(false)
  const [flag, setFlag] = useState(false)

  useEffect(() => { }, [jsonImgfront, jsonImgBack])
  useEffect(() => { }, [itemInfo])

  let angle = itemInfo.angle || 0

  useEffect(() => {
    if (
      !containerRef.current ||
      !dragableDivRef.current ||
      !rotableRef.current ||
      !scaleRef.current ||
      !closeRef.current ||
      !customContainerRef.current
    )
      return

    const inSquare = dragableDivRef.current
    const container = customContainerRef.current

    if (itemInfo) {
      const width = itemInfo.divSize.width
      const height = itemInfo.divSize.height
      const left = itemInfo.position.left
      const top = itemInfo.position.top

      inSquare.style.left = `${left}%`
      inSquare.style.top = `${top}%`

      inSquare.style.width = `${width + 400 / container.clientWidth}%`
      inSquare.style.height = `${height + 400 / container.clientHeight}%`
      inSquare.style.transform = `rotate(${itemInfo.angle * -1}deg)`

      coord.current.startX = (left / 100) * container.clientWidth
      coord.current.startY = (top / 100) * container.clientHeight
      coord.current.lastX = (left / 100) * container.clientWidth
      coord.current.lastY = (top / 100) * container.clientHeight
    }
  }, [itemInfo])

  useEffect(() => {
    if (flag && itemInfo.id === selectedId) {
      text && text(itemInfo.id as number, myInfo())
      setFlag(false)
    }
  }, [flag])

  const myInfo = () => {
    return {
      id: itemInfo.id || 0,
      show: itemInfo.show,
      angle:
        -1 *
        parseInt(
          dragableDivRef.current?.style.transform
            .replace('rotate(', '')
            .replace('deg)', '') || ''
        ),
      position: {
        left: ((dragableDivRef.current!.offsetLeft /
          customContainerRef.current!.clientWidth) *
          100) as number,
        top: ((dragableDivRef.current!.offsetTop /
          customContainerRef.current!.clientHeight) *
          100) as number
      },
      divSize: {
        height: ((dragableDivRef.current!.clientHeight /
          customContainerRef.current!.clientHeight) *
          100) as number,
        width: ((dragableDivRef.current!.clientWidth /
          customContainerRef.current!.clientWidth) *
          100) as number
      }
    }
  }

  useEffect(() => {
    if (
      !containerRef.current ||
      !dragableDivRef.current ||
      !rotableRef.current ||
      !scaleRef.current ||
      !closeRef.current ||
      !customContainerRef.current ||
      !saveRef.current
    )
      return

    const container = containerRef.current
    const custom = customContainerRef.current
    const inSquare = dragableDivRef.current
    const inRotate = rotableRef.current
    const inScale = scaleRef.current
    const inClose = closeRef.current
    const inSave = saveRef.current

    const styles = window.getComputedStyle(inSquare)
    let width = parseInt(styles.width, 10)
    let height = parseInt(styles.height, 10)

    let xxx = 0
    let yyy = 0

    const onMouseDownSquare = (e: MouseEvent) => {
      isClickedDrag.current = true
      coord.current.startX = e.clientX
      coord.current.startY = e.clientY
      inSquare.style.background = '#d9d9d9'
    }
    const onMouseUpSquare = (_e: MouseEvent) => {
      isClickedDrag.current = false
      coord.current.lastX = inSquare.offsetLeft
      coord.current.lastY = inSquare.offsetTop
      inSquare.style.background = 'transparent'
      text && text(itemInfo.id as number, myInfo())
    }
    const onMouseUpContainer = (_e: MouseEvent) => {
      isClickedRotate.current = false
      isClickedScale.current = false
      isClickedDrag.current = false
      isClickedClose.current = false
      setFlag(true)
    }
    const onMouseLeaveSquare = () => {
      isClickedDrag.current = false
      coord.current.lastX = inSquare.offsetLeft
      coord.current.lastY = inSquare.offsetTop
      inSquare.style.background = 'transparent'
    }
    const onMouseLeaveContainer = () => {
      isClickedDrag.current = false
      //isClickedRotate.current = false
      isClickedScale.current = false
      coord.current.lastX = inSquare.offsetLeft
      coord.current.lastY = inSquare.offsetTop
      inSquare.style.background = 'transparent'
      //setFlag(true)
    }

    const onMouseRotateDown = (e: MouseEvent) => {
      e.stopPropagation()
      isClickedRotate.current = true
    }
    const onMouseRotateUp = (e: MouseEvent) => {
      e.stopPropagation()
      isClickedRotate.current = false
    }
    const onMouseScaleDown = (e: MouseEvent) => {
      e.stopPropagation()
      isClickedScale.current = true

      xxx = e.clientX
      inSquare.style.left = styles.left
      inSquare.style.right = 'null'
      yyy = e.clientY
      inSquare.style.top = styles.top
      inSquare.style.bottom = 'null'
    }
    const onMouseScaleUp = (e: MouseEvent) => {
      e.stopPropagation()
      text && text(itemInfo.id as number, myInfo())
      isClickedScale.current = false
    }

    const onMouseCloseDown = (e: MouseEvent) => {
      e.stopPropagation()
      erase(itemInfo?.id || 0)
      isClickedClose.current = true
    }
    const onMouseCloseUp = (e: MouseEvent) => {
      e.stopPropagation()
      isClickedClose.current = false
    }
    const onMouseSaveDown = (e: MouseEvent) => {
      e.stopPropagation()
      text && text(itemInfo.id as number, myInfo())
      isClickedSave.current = true
    }
    const onMouseSaveeUp = (e: MouseEvent) => {
      e.stopPropagation()
      isClickedSave.current = false
      text && text(itemInfo.id as number, myInfo())
      resetIds()
    }
    const onMouseMoveContainer = (e: MouseEvent) => {
      if (isClickedRotate.current) {
        const mousePosition = {
          x: e.clientX - custom.offsetLeft,
          y: (e.clientY - custom.offsetTop -50) * -1
        }

        const posCenterInSquare = {
          x: inSquare.offsetLeft + inSquare.clientWidth / 2,
          y: (inSquare.offsetTop + inSquare.clientHeight / 2) * -1
        }

        const pendienteCentroMouse =
          (mousePosition.y - posCenterInSquare.y) /
          (mousePosition.x - posCenterInSquare.x)

        const co = (inSquare.clientHeight + inRotate.clientHeight) / 2
        const ca = (inSquare.clientWidth + inRotate.clientWidth) / 2

        const myAngle = Math.round(Math.atan(co / ca) * 57.2958)

        const ang =
          Math.round(Math.atan(pendienteCentroMouse) * 57.2958) - myAngle

        const getAngle = () => {
          let response = 0
          if (mousePosition.x < posCenterInSquare.x) {
            response = ang + 180
          } else if (ang < 0) response = 360 + ang
          else response = ang
          return response
        }

        angle = getAngle()

        inSquare.style.transform = `rotate(${angle * -1}deg)`
      }

      if (isClickedDrag.current && selectedId === itemInfo.id) {
        const nextX = e.clientX - coord.current.startX + coord.current.lastX
        const nextY = e.clientY - coord.current.startY + coord.current.lastY

        const XPercent = (nextX / container.clientWidth) * 100
        const YPercent = (nextY / container.clientHeight) * 100

        if (
          nextX > -12 &&
          nextX + inSquare.clientWidth <= container.clientWidth + 40
        ) {
          inSquare.style.left = `${XPercent}%`
        } else {
          if (XPercent < parseInt(inSquare.style.left) && XPercent > -10) {
            inSquare.style.left = `${XPercent}%`
          }
        }

        if (
          nextY > -12 &&
          nextY + inSquare.clientHeight <= container.clientHeight + 40
        ) {
          inSquare.style.top = `${YPercent}%`
        } else {
          if (YPercent < parseInt(inSquare.style.top) && YPercent > -10) {
            inSquare.style.top = `${YPercent}%`
          }
        }
      }

      if (isClickedScale.current) {
        const { innerWidth, innerHeight } = window
        const { width: wS, height: hS } = wSize.current
        let deltaX=1.0, deltaY=1.0
        
        if (innerWidth != 0 && innerHeight != 0 && wS != 0 && hS != 0) {
          deltaX = innerWidth / wS
          deltaY = innerHeight / hS
          wSize.current = { width:innerWidth, height:innerHeight }
        }

        const dx = e.clientX - xxx
        xxx = e.clientX
        width = width + dx
        width *= deltaX
        const widthPercent = (width / container.clientWidth) * 100
        if (widthPercent < 110 && widthPercent > 10) {
          inSquare.style.width = `${widthPercent}%`
        }

        const dy = e.clientY - yyy
        height = height + dy
        height *= deltaY
        yyy = e.clientY
        
        const heigtPercent = (height / container.clientHeight) * 100
        if (heigtPercent < 110 && heigtPercent > 10) {
          inSquare.style.height = `${heigtPercent}%`
        }
      }
    }

    const handleClickOutside = (event: any) => {
      event.stopPropagation()
      if (
        !isText &&
        container &&
        !container.contains(event.target) &&
        itemInfo.id === selectedId
      ) {
        isClickedRotate.current = false
        setFlag(true)
      }
    }

    inSquare.addEventListener('mousedown', onMouseDownSquare)
    inSquare.addEventListener('mouseup', onMouseUpSquare)
    container.addEventListener('mousemove', onMouseMoveContainer)
    container.addEventListener('mouseleave', onMouseLeaveContainer)
    container.addEventListener('mouseup', onMouseUpContainer)
    inSquare.addEventListener('mouseleave', onMouseLeaveSquare)
    inRotate.addEventListener('mousedown', onMouseRotateDown)
    inRotate.addEventListener('mouseup', onMouseRotateUp)
    inScale.addEventListener('mousedown', onMouseScaleDown)
    inScale.addEventListener('mouseup', onMouseScaleUp)
    inClose.addEventListener('mousedown', onMouseCloseDown)
    inClose.addEventListener('mouseup', onMouseCloseUp)
    inSave.addEventListener('mousedown', onMouseSaveDown)
    inSave.addEventListener('mouseup', onMouseSaveeUp)
    document.addEventListener('mouseup', handleClickOutside)

    const cleanup = () => {
      inSquare.removeEventListener('mousedown', onMouseDownSquare)
      inSquare.removeEventListener('mouseup', onMouseUpSquare)
      container.removeEventListener('mousemove', onMouseMoveContainer)
      container.removeEventListener('mouseleave', onMouseLeaveContainer)
      container.removeEventListener('mouseup', onMouseUpContainer)
      inSquare.removeEventListener('mouseleave', onMouseLeaveSquare)
      inRotate.removeEventListener('mousedown', onMouseRotateDown)
      inRotate.removeEventListener('mouseup', onMouseRotateUp)
      inScale.removeEventListener('mousedown', onMouseScaleDown)
      inScale.removeEventListener('mouseup', onMouseScaleUp)
      inClose.removeEventListener('mousedown', onMouseCloseDown)
      inClose.removeEventListener('mouseup', onMouseCloseUp)
      inSave.removeEventListener('mousedown', onMouseSaveDown)
      inSave.removeEventListener('mouseup', onMouseSaveeUp)
      document.removeEventListener('mouseup', handleClickOutside)
    }
    return cleanup
  }, [])

  return (
    <Fragment>
      {itemInfo.show && (
        <div
          ref={dragableDivRef}
          className={`${style['div-movible']} 
          ${itemInfo.id !== selectedId && style['div-movile-notdotted']}
          ${index && style['style-z-index']}`}
          onClick={(e) => {
            e.stopPropagation()
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
            setIndex(true)
          }}
          onMouseUp={(e) => {
            e.stopPropagation()

            setIndex(false)
          }}
          onMouseLeave={(e) => {
            e.stopPropagation()
            setIndex(false)
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={`${style['div-interno-dragable']} `}
          >
            {children}
            <div
              ref={rotableRef}
              className={`${style['div-rotate-me']} ${itemInfo.id !== selectedId && style['none-disp']
                }`}
            >
              <ArrowPathRoundedSquareIcon
                className={`${style['icon-action']}`}
              />
            </div>
            <div
              ref={scaleRef}
              className={`${style['div-scale-me']} ${itemInfo.id !== selectedId && style['none-disp']
                }`}
            >
              <ArrowTopRightOnSquareIcon
                className={`${style['icon-action']}`}
              />
            </div>
            <div
              ref={closeRef}
              className={`${style['div-close-me']} ${itemInfo.id !== selectedId && style['none-disp']
                }`}
            >
              <TrashIcon className={`${style['icon-action']}`} />
            </div>
            <div
              ref={saveRef}
              onClick={() => {
                text && text(itemInfo.id as number, myInfo())
              }}
              className={`${style['div-save-me']} ${itemInfo.id !== selectedId && style['none-disp']
                }`}
            >
              <EyeSlashIcon className={`${style['icon-action']}`} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default DraggableItem
