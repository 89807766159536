import { SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useRef, useState } from 'react'
import { deleteSchoolAdmin } from 'services/schoolAdminServices/schools'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { handleErrors } from 'utilities/handleErrors'
import {
  addNewSupplyMake,
  getSupplyMakeList,
  updateUtilsAdmin
} from 'services/utilsServices/utils'
import { AUTHSERVICE_FORMDATA } from 'services/config'
import { Form } from 'antd'
import { Options } from 'types'
import { ONLY_NUMBERS } from '../../constants'
import { PRESENTATION_TYPE } from 'modules/Utils/AddUtilsForm/hook/useAddUtilsForm'
import { getCategoriesForCreateItemsForms, getOptionsListForProducts } from 'services/genericServices/genericServices'

const optionsFee: Options[] = [
  { label: 'Si', value: true },
  { label: 'No', value: false }
]

const SCHOOL_TYPES = [
  {
    value: 'u',
    label: 'Mixto'
  },
  {
    value: 'm',
    label: 'Masculino'
  },
  {
    value: 'f',
    label: 'Femenino'
  }
]

export function useRequireUtilsForm() {
  const makeListRef = useRef<Array<Options>>([])
  const location = useLocation()

  const INPUTS_INITIAL_STATE = {
    name: {
      label: 'Nombre',
      name: 'name',
      placeholder: 'Nombre del material',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    make: {
      label: 'Marca',
      name: 'make',
      placeholder: 'Marca',
      value: '',
      type: 'autocomplete',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      options: [],
      rules: [
        { required: true, message: '' },
        {
          validator: async (algo: any, make: any) => {
            const found = makeListRef.current.find((it) => it.value == make)
            return found ? Promise.resolve() : Promise.reject()
          }
        }
      ]
    },
    sku: {
      label: 'SKU',
      name: 'sku',
      placeholder: 'SKU',
      value: '',
      type: 'text',
      required: false,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: '', // puede ser 'warning' o 'error' segun antd
      rules: [{ required: true, message: '' }]
    },
    presentation: {
      label: 'Presentación',
      name: 'presentation',
      placeholder: 'Presentación',
      value: '',
      type: 'select',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      options: PRESENTATION_TYPE,
      rules: [{ required: true, message: '' }]
    },
    quantity: {
      label: 'Cantidad',
      name: 'quantity',
      placeholder: 'Cantidad',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, cantidad: any) => {
            const regex = /^[0-9]+$/
            if (!regex.test(cantidad))  return Promise.reject(new Error('Solo números permitidos'))
          }
        }
      ]
    },
    picture: {
      label: 'Imagen del producto',
      name: 'picture',
      placeholder: 'Imagen del producto',
      value: '',
      type: 'file',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    price: {
      label: 'Precio',
      name: 'price',
      placeholder: 'Precio',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    special_price: {
      label: 'Precio especial',
      name: 'special_price',
      placeholder: 'Precio especial',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
      // rules: [{ required: true, message: '' }]
    },
    refill_time: {
      label: 'Tiempo de resurtido',
      name: 'refill_time',
      placeholder: '0',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      onkeyPress: (e: any) => ONLY_NUMBERS(e),
      rules: [
        {
          required: true,
          message: ''
        },
        {
          pattern: new RegExp(/^(\d+(\.\d+)?)$/),
          message: 'Escribe solo números'
        }
      ]
    },
    return_percentage: {
      label: 'Porcentaje de devolución',
      name: 'return_percentage',
      placeholder: 'Porcentaje de devolución',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
      // rules: [{ required: true, message: '' }]
    },
    provider: {
      label: 'Proveedor',
      name: 'provider',
      placeholder: 'Proveedor',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
      //  rules: [{ required: true, message: '' }]
    },
    provider_fee: {
      label: 'Cuota de proveedor',
      name: 'provider_fee',
      placeholder: 'Cuota de proveedor',
      value: location?.state?.provider_free,
      type: 'radio',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      span: 6,
      options: optionsFee
      //  rules: [{ required: true, message: '' }]
    },
    resource_free: {
      label: 'Recurso gratuito',
      name: 'resource_free',
      placeholder: 'Recurso gratuito',
      value: location?.state?.resource_free,
      type: 'radio',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      span: 6,
      options: optionsFee
      //  rules: [{ required: true, message: '' }]
    },

    category: {
      label: 'Categoria',
      name: 'category',
      placeholder: 'Categoria',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: 'v0',
          label: 'v0'
        },
        {
          value: 'v4',
          label: 'v4'
        }
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
    },
    tax: {
      label: 'Impuesto',
      name: 'tax',
      placeholder: 'Impuesto',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: 'v0',
          label: 'v0'
        },
        {
          value: 'v4',
          label: 'v4'
        }
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
    },
    price_list: {
      label: 'Precio de lista',
      name: 'price_list',
      placeholder: 'Precio de lista',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '4',
          label: '4'
        },
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
    },
    store: {
      label: 'Tienda',
      name: 'store',
      placeholder: 'Tienda',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '4',
          label: '4'
        },
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
    },
    inventariable : {
      label: 'Inventariable',
      name: 'inventariable',
      placeholder: 'Inventariable',
      value: '',
      type: 'radio',
      maxLength: 100, //maxLength solo funciona para tipo text
      options: optionsFee,
    },
  }
  
  const [loadingPost, setloadingPost] = useState(false)
  const [loadingDelete, setloadingDelete] = useState(false)
  const [imageFile, setImageFile] = useState<any>(null)
  const [schoolData, setschoolData] = useState(INPUTS_INITIAL_STATE)
  const [modalMake, setmodalMake] = useState(false)
  const [loadingMake, setloadingMake] = useState(false)
  const [makeCompleteList, setMakeCompleteList] = useState<Array<Options>>([])
  const [OptionBrand, setOptionBrand] = useState<Options[]>([])


  const [form] = Form.useForm()
  const [formEditorial] = Form.useForm()

  const navigate = useNavigate()
  const { pathname } = location
  const isInUtiles = pathname?.includes('utiles') || false
  const data = location.state
  const isInReview =
    (isInUtiles && data && data?.status && data.status == 2) || false
  const isStatusGT4 =
    (isInUtiles && data && data?.status && data.status > 4) || false
  const isStatusET4 =
    (isInUtiles && data && data?.status && data.status == 4) || false

  useEffect(() => {
    let unmounted2 = false
    const stateData = location.state
    if (stateData !== null) {
      let newObject = { ...INPUTS_INITIAL_STATE }
      Object.keys(schoolData).forEach((res: any, key) => {
        const objKey = res as keyof typeof INPUTS_INITIAL_STATE
        const objInput = schoolData[objKey]
        objInput.value = stateData[objKey]
        newObject = { ...newObject, [objKey]: objInput }
      })
      setschoolData(newObject)
    }
    getSupplyMake()
    getOptionsForInputs()
    return () => {
      unmounted2 = true
      if (unmounted2) setschoolData(INPUTS_INITIAL_STATE)
    }
  }, [location])

  const checkDisabled = (): boolean => {
    let disabled = false
    Object.keys(schoolData).forEach((res: any) => {
      const objKey = res as keyof typeof INPUTS_INITIAL_STATE
      const objInput = schoolData[objKey]
      if (objInput.value === '') disabled = true
    })
    return disabled
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setschoolData((prev) => cleanSingleUpdatedState(e, prev))
  }

  const handleFileChange = (file: File, name: string) => {
    setImageFile(file ? file : null)
  }

  const handleChangeDate = (
    value: string,
    name: string,
    dateString: string
  ) => {
    setschoolData((prev) =>
      cleanSingleSelectUpdatedState(dateString, name, prev)
    )
  }

  const updateSchool = async (
    id: any,
    values: any,
    updatedFinished?: () => void
  ) => {
    setloadingPost(true)

    try {
      // add the image first
      let image = null
      if (imageFile) {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('files', imageFile)
        const res = await AUTHSERVICE_FORMDATA().post(
          '/supply/loadfile',
          formData
        )
        const { url } = res?.data
        image = url
      }

      const data = {
        id: location.state?.id_supply_school,
        id_supply: id,
        id_supply_school: location.state?.id_supply_school,
        ...values
      }

      if (image) data['picture'] = image

      await updateUtilsAdmin({ data })
      SuccessToast(`El útil "${data.name}" fue editado correctamente`)
      navigate(-1)

      updatedFinished && updatedFinished()
    } catch (err: any) {
      handleErrors(err)
    }

    setloadingPost(false)
  }

  const deleteSchoolById = async (school: any) => {
    setloadingDelete(true)
    try {
      await deleteSchoolAdmin(school.id)
      SuccessToast(
        `El colegio "${school.name}" ha sido eliminado correctamente`
      )
      setloadingDelete(false)
      navigate('/lista-de-colegios')
    } catch (err: any) {
      setloadingDelete(false)
      handleErrors(err)
    }
  }

  const onHandleAutoCompleteMarca = async (value: string): Promise<any> => {
    setOptionBrand(
      value
        ? makeCompleteList.filter((it) =>
            it.label?.toLocaleLowerCase().match(value?.toLocaleLowerCase())
          )
        : makeCompleteList
    )
  }

  const getSupplyMake = async () => {
    setloadingMake(true)
    try {
      const result = await getSupplyMakeList()
     /*  const resul2 = await getCategoriesForCreateItemsForms('Útiles') */
      const allOptions = result.data.status
     /*  const allOptions2 = resul2.data.categories */
      const cleanOptions = allOptions.map((res: any) => {
        return { label: res.name, value: res.name, id: res.id }
      })

     /*  const cleanOptions2 = allOptions2.map((res: any) => {
        return { label: res, value: res }
      })   */
      makeListRef.current = cleanOptions
      setMakeCompleteList(cleanOptions)
      setOptionBrand(cleanOptions)
      /* setschoolData(prev=>{
        const categoryobject = prev.category
        return {...prev,  category: {...categoryobject, options:cleanOptions2 }}
      }) */
    } catch (error) {
      //handleErrors(error)
    }

    setloadingMake(false)
  }

  const postNewMakeSupply = async (values: any) => {
    setloadingPost(true)
    try {
      await addNewSupplyMake(values)
      await getSupplyMake()
      setmodalMake(false)
      SuccessToast('Se ha agregado una nueva marca')
    } catch (err: any) {}

    setloadingPost(false)
  }

  const getOptionsForInputs = async () => {
    try {
      const result = await getOptionsListForProducts('Útiles')
      const listdata = result.data
      delete listdata.success
      const createLabelOptions = (customArray:any) =>{
        return customArray.map((res: any) => {
          return { label: res, value: res, id: res }
        })
      }
      setschoolData(prev => {
        const category = {...prev.category}
        const tax = {...prev.tax}
        const price_list = {...prev.price_list}
        const store = {...prev.store}
        return {
          ...prev,
          category:{...category,
            options: createLabelOptions(listdata.categories)
          },
          tax:{...tax,
            options: createLabelOptions(listdata.tax)
          },
          store:{...store,
            options: createLabelOptions(listdata.store)
          },
          price_list:{...price_list,
            options: createLabelOptions(listdata.price_list)
          },
        }
      })

    } catch (error) {
      //handleErrors(error)
    }
  }

  return {
    schoolData,
    loadingPost,
    loadingDelete,
    deleteSchoolById,
    onSubmit: updateSchool,
    checkDisabled,
    handleFileChange,
    handleChange,
    handleChangeDate,
    SCHOOL_TYPES,
    INPUTS_INITIAL_STATE,
    PRESENTATION_TYPE,
    form,
    optionsFee,
    isStatusGT4,
    isStatusET4,
    isInReview,
    modalMake,
    setmodalMake,
    OptionBrand,
    onHandleAutoCompleteMarca,
    formEditorial,
    postNewMakeSupply,
    loadingMake
  }
}
