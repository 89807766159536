import { Fragment } from 'react'
import style from './../../../components/ListOfList/list.module.scss'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { Col, List, Tabs } from 'antd'
import { ILevelGradesWithPackages } from 'interfaces/utils-interface'
import B from 'components/texts/B/B'
import Collapse from 'components/Collapse/Collapse'
import NotebooksItemsTable from './NotebooksItemsTable'
const packageTitle = ['Nombre del paquete', 'Cantidad de paquetes']
const colStyle = { cursor: 'pointer', padding: '0 20px' }

const NotebooksItemsList = ({
  tabs,
  selectedLevel,
  setSelectedLevel,
  handleChangeLevelTab,
  RightActionButtons,
  RightActionButtonsProps,
  addButtonInPackage,
  edithButtonInPackage,
  eraseButtonInPackage
}: {
  tabs: any
  selectedLevel: any
  setSelectedLevel?: (data: any) => void
  handleChangeLevelTab: any
  RightActionButtons: any
  RightActionButtonsProps: { onClick: (dat: any) => void; moreProps: any }
  addButtonInPackage: { onClick: (dat: any) => void; moreProps: any }
  edithButtonInPackage: { onClick: (dat: any) => void; moreProps: any }
  eraseButtonInPackage: { onClick: (dat: any) => void; moreProps: any }
}) => {
  const RenderPackageList = ({
    name,
    LevelGrades,
    res
  }: {
    LevelGrades: ILevelGradesWithPackages[]
    name?: string
    res: any
  }) => {
    return (
      <Fragment>
        <List className={`${style['padding-inline']}`} itemLayout='horizontal'>
          {(() => {
            let value: ILevelGradesWithPackages[] = LevelGrades
            value = value.filter((contents) => contents.name === name)

            return (
              <Fragment>
                {value[0].packages.length !== 0 ? (
                  <Fragment>
                    <List.Item
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '25%25%25%25%',
                        padding: ' 10px 0',
                        background: '#F3F7FF80',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {packageTitle.map((item, index) => {
                        return (
                          <Col
                            key={index}
                            className='ListTitles'
                            style={colStyle}
                            span={8}
                          >
                            <B
                              size='Light'
                              style={{
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {item}
                            </B>
                          </Col>
                        )
                      })}
                    </List.Item>

                    {value[0].packages.map((item, index) => {
                      return (
                        <Fragment key={index + item.name + item.updatedAt}>
                          <NotebooksItemsTable
                            name={item.name}
                            id={item.id}
                            packageData={item}
                            selectedLevel={selectedLevel}
                            setSelectedLevel={setSelectedLevel}
                            quantity={item.quantity}
                            subtitle={`${item.quantity}`}
                            colStyle={colStyle}
                            addButtonInPackage={addButtonInPackage}
                            edithButtonInPackage={edithButtonInPackage}
                            eraseButtonInPackage={eraseButtonInPackage}
                            data={res}
                          />
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ) : (
                  <EmptyListMessage
                    iconHeight={40}
                    iconWidth={40}
                    icon='utilsIcon'
                    text='Aún no has creado paquetes'
                    buttonProps={[
                      {
                        label: 'Crear primer paquete',
                        onClick: () => {
                          RightActionButtonsProps.onClick(res)
                        },
                        rounded: true,
                        sector: 'tertiary',
                        background: 'white',
                        size: 'm'
                      }
                    ]}
                  />
                )}
              </Fragment>
            )
          })()}
        </List>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Col span={24}>
        <Tabs items={tabs} onChange={handleChangeLevelTab} />
      </Col>
      <Col span={24}>
        {selectedLevel?.grades?.map((res: any) => {
          return (
            <div key={res.id + res.name + res}>
              <Collapse
                id={res.id}
                title={res.name}
                customSideElement={
                  <RightActionButtons
                    {...{
                      ...RightActionButtonsProps.moreProps,
                      onClick: () => RightActionButtonsProps.onClick(res)
                    }}
                  />
                }
              >
                <RenderPackageList
                  LevelGrades={selectedLevel.grades}
                  name={res.name}
                  res={res}
                />
              </Collapse>
            </div>
          )
        })}
      </Col>
    </Fragment>
  )
}

export default NotebooksItemsList
