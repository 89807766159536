import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import PreBuyFooter from 'components/PreBuyFooter/PreBuyFooter'
import bkgPreBuyUniforms from '../../assets/img/preBuyBackgrounds/bkgPreBuyUniforms.svg'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import AdminUniformsManagement from 'modules/uniforms/AdminUniformsManagement/AdminUniformsManagement'
import { Row } from 'antd'
import UniformPackageList from 'modules/uniforms/uniformList/UniformPackageList'
import { useValidatePermission } from 'hooks/utils/useValidatePermission'

const acquired = true

const Index = () => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  useValidatePermission()

  if (uid.role === 'Ezetera')
  return (
    <MainTemplate>
      <Row align='middle' gutter={[0, 16]}>
        <AdminUniformsManagement />
      </Row>
    </MainTemplate>
  )
  if (acquired) return <UniformPackageList />

  return (
    <MainTemplate preBuyPage={bkgPreBuyUniforms}>
      <PreBuyFooter
        title='Uniformes'
        imgTitle={{
          name: 'uniform',
          height: 20,
          width: 20
        }}
        subTitle='Maecenas eget condimentum velit, sit amet feugiat lectus.'
        description={[
          'Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a',
          ' , mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.'
        ]}
        topics={[
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. '
        ]}
        buttonProps={[
          {
            label: 'Contratar este módulo',
            onClick: () => alert('click'),
            sector: 'tertiary',
            rounded: true,
            size: 'm'
          }
        ]}
      />
    </MainTemplate>
  )
}
Index.displayName = 'UniformList'
export default Index
