import BackButton from 'components/buttons/BackButton/BackButton'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { ErrorToast } from 'components/Toasts/Toasts'
import CreateSchoolForm from 'modules/School/CreateSchoolForm/CreateSchoolForm'
import React, { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDetailedLog } from 'services/genericServices/genericServices'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
const index = memo(() => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [buttonProps, setbuttonProps] = useState<any>(undefined)
  const [loadingDownload, setloadingDownload] = useState(false)
  const [openAceptRequestModal, setOpenAceptRequestModal] = useState(false)

  const donwloadLogs = async () => {
    setloadingDownload(true)
    try {
      const res: any = await getDetailedLog({
        type: 'school',
        id: location.state.id
      })
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = 'html-to-img.png'
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('Ocurrió un error, inténtelo de nuevo')
    }
    setloadingDownload(false)
  }

  useEffect(() => {
    const buttons: any = [
      {
        label: 'Descargar Logs',
        onClick: () => donwloadLogs(),
        background: 'white',
        rounded: true,
        size: 'm',
        sector: 'tertiary',
        isLoading: loadingDownload
        /* disabled:true */
      },
      {
        label: 'Eliminar un colegio',
        onClick: () => setOpenDeleteModal(true),
        sector: 'secondary',
        rounded: true,
        size: 'm'
      }
    ]
    if (location?.state?.status === 'Solicitud de Modificación')
      buttons.unshift({
        label: 'Aceptar Solicitud de cambio',
        onClick: () => setOpenAceptRequestModal(true),
        background: 'white',
        rounded: true,
        size: 'm',
        sector: 'tertiary',
        isLoading: loadingDownload
      })
    if (location.state !== null) {
      setbuttonProps(buttons)
    }
  }, [location.state])
  const goBack = () => {
    navigate('/lista-de-colegios')
  }
  return (
    <MainTemplate>
      <BackButton onClick={goBack} textDecoration='underline' />
      <br />
      <br />
      <br />

      <TitleHeader
        buttonProps={buttonProps}
        IconName='schoolIcon'
        title={location.state !== null ? 'Editar Colegio' : 'Crear Colegio'}
      />
      <CreateSchoolForm
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        openAceptRequestModal={openAceptRequestModal}
        setOpenAceptRequestModal={setOpenAceptRequestModal}
      />
    </MainTemplate>
  )
})
index.displayName = 'SchoolDetails'
export default index
