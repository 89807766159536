import React, { Fragment, useEffect } from 'react'
import Navbar from 'components/Navbar/Navbar'
import style from './createnew.module.scss'
import UniformIcon from 'assets/img/uniforms/UniformIcon'
import CardUniformSelect from './components/CardUniformSelect'
import kid from 'assets/img/uniforms/kid-uniform.png'
import FixedInfoFooter from '../components/fixedInfoFooter/FixedInfoFooter'

import { useDispatch, useSelector } from 'react-redux'
import { setPictureLogo, setSelectedClothe } from 'redux/reducers/uniformsSlice'
import PolosPics from '../editor/components/clothing/pics/PolosPics'
import TShirtPic from '../editor/components/clothing/pics/TShirtPic'
import JumperPic from '../editor/components/clothing/pics/JumperPic'
import BlusaPic from '../editor/components/clothing/pics/BlusaPic'
import FaldaPics from '../editor/components/clothing/pics/FaldaPics'
import SueterPics from '../editor/components/clothing/pics/SueterPics'
import CamisaMCMLPics from '../editor/components/clothing/pics/CamisaMCMLPics'
import ChalecoPics from '../editor/components/clothing/pics/ChalecoPics'
import PantalonPics from '../editor/components/clothing/pics/PantalonPics'
import SacoPics from '../editor/components/clothing/pics/SacoPics'
import HoodiePics from '../editor/components/clothing/pics/HoodiePics'
import ChalecoCapitonadoPics from '../editor/components/clothing/pics/ChalecoCapitonadoPics'
import CorbatPics from '../editor/components/clothing/pics/CorbatPics'
import TrajeBanoMasPics from '../editor/components/clothing/pics/TrajeBanoMasPics'
import TrajeBanoFem1pPics from '../editor/components/clothing/pics/TrajeBanoFem1pPics'
import TrajeBanoFem2pzaPics from '../editor/components/clothing/pics/TrajeBanoFem2pzaPics'
import SliderPicsHOC from '../editor/components/clothing/pics/SliderPicsHOC'
import JacketPics from '../editor/components/clothing/pics/JacketPics'
import PantsDeportivosPics from '../editor/components/clothing/pics/PantsDeportivosPics'
import SportShirtsPic from '../editor/components/clothing/pics/SportShirtsPic'
import ShortsDeportivosPics from '../editor/components/clothing/pics/ShortsDeportivosPics'
import FaldaShortPics from '../editor/components/clothing/pics/FaldaShortPics'
import { RootState } from 'redux/store/store'
import ChamarraCapitonadaPics from '../editor/components/clothing/pics/ChamarraCapitonadaPics'

const CreateNewModule = () => {
  const { female, clotheSelected } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  const json = [
    {
      id: 1,
      name: 'Playera tipo polo',
      picture: kid,
      svgPicture: <PolosPics />
    },
    { id: 3, name: 'T-Shirt', picture: kid, svgPicture: <TShirtPic /> },
    {
      id: 4,
      name: 'Chaqueta deportiva',
      picture: kid,
      svgPicture: <JacketPics />
    },
    {
      id: 11,
      name: 'Pants deportivos',
      picture: kid,
      svgPicture: <PantsDeportivosPics />
    },
    {
      id: 12,
      name: 'Sport shirt deportivo',
      picture: kid,
      svgPicture: <SportShirtsPic />
    },
    {
      id: 13,
      name: 'Falda short deportivo',
      picture: kid,
      svgPicture: <FaldaShortPics />
    },
    {
      id: 14,
      name: 'Shorts deportivos',
      picture: kid,
      svgPicture: <ShortsDeportivosPics />
    },
    {
      id: 6,
      name: 'Blusa 3/4',
      picture: kid,
      svgPicture: <BlusaPic />
    },
    {
      id: 8,
      name: 'Jumper',
      picture: kid,
      svgPicture: <JumperPic />
    },
    { id: 9, name: 'Falda', picture: kid, svgPicture: <FaldaPics /> },
    {
      id: 10,
      name: 'Suéter',
      picture: kid,
      svgPicture: <SueterPics />
    },
    {
      id: 17,
      name: 'Camisa manga larga/corta',
      picture: kid,
      svgPicture: <CamisaMCMLPics />
    },
    { id: 18, name: 'Chaleco', picture: kid, svgPicture: <ChalecoPics /> },
    { id: 19, name: 'Pantalón', picture: kid, svgPicture: <PantalonPics /> },
    { id: 28, name: 'Saco', picture: kid, svgPicture: <SacoPics /> },
    { id: 30, name: 'Hoodie', picture: kid, svgPicture: <HoodiePics /> },
    {
      id: 32,
      name: 'Chaleco capitonado',
      picture: kid,
      svgPicture: <ChalecoCapitonadoPics />
    },
    {
      id: 15,
      name: 'Chamarra capitonado',
      picture: kid,
      svgPicture: <ChamarraCapitonadaPics />
    },
    { id: 34, name: 'Corbata', picture: kid, svgPicture: <CorbatPics /> },
    {
      id: 35,
      name: 'Traje de baño masculino',
      picture: kid,
      svgPicture: <TrajeBanoMasPics />
    },
    {
      id: 36,
      name: 'Traje de baño femenino',
      picture: kid,
      svgPicture: <TrajeBanoFem1pPics />
    },
    {
      id: 38,
      name: 'Traje de baño femenino 2pza ',
      picture: kid,
      svgPicture: <TrajeBanoFem2pzaPics />
    }
  ]
  const selectClothe = (id: number) => {
    dispatch(setSelectedClothe(id))
  }

  useEffect(() => {
    dispatch(setPictureLogo(''))
  }, [])

  return (
    <div className={`${style['body-div']}`}>
      <FixedInfoFooter />
      <Navbar complete />
      <div className={`${style['div-Title']}`}>
        <div className={`${style['div-Title']}`}>
          <UniformIcon
            stroke='#305599'
            className={`${style['uniform-icon']}`}
          />
          <p className={`${style['P-title']}`}>
            Selecciona el tipo de prenda a crear
          </p>
        </div>
      </div>
      <div className={`${style['div-cards']}`}>
        {json.map(({ name, picture, id, svgPicture }, index: number) => {
          return (
            <Fragment key={index}>
              <CardUniformSelect
                onClick={() => selectClothe(id)}
                selected={clotheSelected === id}
                name={name}
                picture={picture}
                children={svgPicture}
              />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default CreateNewModule
