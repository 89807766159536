import { Input, Select, Table, Typography } from 'antd'
import React, { memo } from 'react'
import { useUtilsTable } from './hook/useUtilsTable'

const UtilsTable = memo(() => {
  const {
    utils,
    loading,
    pagination,
    UtilsColumns,
    statusCatalog,
    search,
    onHandleChangeStatus,
    handleSearch
  } = useUtilsTable()
  return (
    <div>
        <div>
          <Typography>Estatus</Typography>
          <Select
            style={{ width: 200 }}
            defaultValue={'todos'}
            placeholder={'Estatus'}
            onChange={onHandleChangeStatus}
            options={statusCatalog.filter(
              (item: any) => item.label !== 'Nuevo'
            )}
          />
          <br/>
        <br/>

        <Input.Search
          placeholder='Buscar'
          onChange={(e: any) => handleSearch(e.target.value)}
          value={search}
          enterButton
          size='large'
        />
        </div>
      <Table
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        dataSource={utils}
        style={{ marginTop: '2.5rem' }}
        scroll={{x:1400}}
        columns={UtilsColumns}
        loading={loading}
        pagination={pagination}
        onRow={() => {
          return {
            onClick: () => undefined
          }
        }}
      />
    </div>
  )
})
UtilsTable.displayName = 'UtilsTable'
export default UtilsTable
