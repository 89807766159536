import React from 'react'

const PathPautadoHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  viewBox = '0 0 800 800',
  ink
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  viewBox?: string
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Pautado Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Pautado_Completo' data-name='Path Pautado Completo'>
              <g id='Path_Pautado_Completo-2' data-name='Path Pautado Completo'>
                <g
                  id='Rayado_Completo'
                  data-name='Rayado Completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='180.73'
                    x2='85.21'
                    y2='180.73'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='169.58'
                    x2='686.94'
                    y2='169.58'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='191.88'
                    x2='85.21'
                    y2='191.88'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='203.04'
                    x2='85.21'
                    y2='203.04'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='214.19'
                    x2='85.21'
                    y2='214.19'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='258.79'
                    x2='85.21'
                    y2='258.79'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='247.64'
                    x2='686.94'
                    y2='247.64'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='269.94'
                    x2='85.21'
                    y2='269.94'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='281.1'
                    x2='85.21'
                    y2='281.1'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='292.25'
                    x2='85.21'
                    y2='292.25'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='336.85'
                    x2='85.21'
                    y2='336.85'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='325.7'
                    x2='686.94'
                    y2='325.7'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='348.01'
                    x2='85.21'
                    y2='348.01'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='359.16'
                    x2='85.21'
                    y2='359.16'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='370.31'
                    x2='85.21'
                    y2='370.31'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='414.92'
                    x2='85.21'
                    y2='414.92'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='403.76'
                    x2='686.94'
                    y2='403.76'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='426.07'
                    x2='85.21'
                    y2='426.07'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='437.22'
                    x2='85.21'
                    y2='437.22'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='448.37'
                    x2='85.21'
                    y2='448.37'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='492.98'
                    x2='85.21'
                    y2='492.98'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='481.83'
                    x2='686.94'
                    y2='481.83'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='504.13'
                    x2='85.21'
                    y2='504.13'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='515.28'
                    x2='85.21'
                    y2='515.28'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='526.43'
                    x2='85.21'
                    y2='526.43'
                  />
                  <line
                    id='Hoja_esq._recta-26'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='571.04'
                    x2='85.21'
                    y2='571.04'
                  />
                  <line
                    id='Hoja_esq._recta-27'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='559.89'
                    x2='686.94'
                    y2='559.89'
                  />
                  <line
                    id='Hoja_esq._recta-28'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='582.19'
                    x2='85.21'
                    y2='582.19'
                  />
                  <line
                    id='Hoja_esq._recta-29'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='593.34'
                    x2='85.21'
                    y2='593.34'
                  />
                  <line
                    id='Hoja_esq._recta-30'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='604.49'
                    x2='85.21'
                    y2='604.49'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Pautado Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Pautado_Datos' data-name='Path Pautado Datos'>
              <g id='Path_Pautado_Datos-2' data-name='Path Pautado Datos'>
                <g
                  id='Rayado_Datos'
                  data-name='Rayado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='227.11'
                    x2='85.21'
                    y2='227.11'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='215.96'
                    x2='686.94'
                    y2='215.96'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='238.26'
                    x2='85.21'
                    y2='238.26'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='249.41'
                    x2='85.21'
                    y2='249.41'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='260.56'
                    x2='85.21'
                    y2='260.56'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='308.9'
                    x2='85.21'
                    y2='308.9'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='297.75'
                    x2='686.94'
                    y2='297.75'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='320.05'
                    x2='85.21'
                    y2='320.05'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='331.2'
                    x2='85.21'
                    y2='331.2'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='342.35'
                    x2='85.21'
                    y2='342.35'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='390.69'
                    x2='85.21'
                    y2='390.69'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='379.54'
                    x2='686.94'
                    y2='379.54'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='401.84'
                    x2='85.21'
                    y2='401.84'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='412.99'
                    x2='85.21'
                    y2='412.99'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='424.14'
                    x2='85.21'
                    y2='424.14'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='472.48'
                    x2='85.21'
                    y2='472.48'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='461.33'
                    x2='686.94'
                    y2='461.33'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='483.63'
                    x2='85.21'
                    y2='483.63'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='494.78'
                    x2='85.21'
                    y2='494.78'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='505.93'
                    x2='85.21'
                    y2='505.93'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='554.27'
                    x2='85.21'
                    y2='554.27'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='85.21'
                    y1='543.12'
                    x2='686.94'
                    y2='543.12'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='565.42'
                    x2='85.21'
                    y2='565.42'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='576.57'
                    x2='85.21'
                    y2='576.57'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='686.94'
                    y1='587.72'
                    x2='85.21'
                    y2='587.72'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Pautado Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Pautado_Completo'
                data-name='Margenes Pautado Completo'
              >
                <g
                  id='Margenes_Pautado_Completo-2'
                  data-name='Margenes Pautado Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Pautado Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g id='Margenes_Pautado_Datos' data-name='Margenes Pautado Datos'>
                <g
                  id='Margenes_Pautado_Datos-2'
                  data-name='Margenes Pautado Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='409.56'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V593a13.1,13.1,0,0,1-13.08,13.08H99.31A13.1,13.1,0,0,1,86.23,593V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V593a14.08,14.08,0,0,0,14.08,14.08H672.84A14.08,14.08,0,0,0,686.92,593V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='409.56'
                      />
                      <path d='M685.92,197.56V606.12H86.23V197.56H685.92m1-1H85.23V607.12H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathPautadoHorizontal
