import { useEffect, useState } from 'react'
import style from './fixedinfofooter.module.scss'
import Button from 'components/buttons/Button/Button'
import OptionStatusBar from './OptionStatusBar'
import { RootState } from 'redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { setStep1, setStep2, setStep3 } from 'redux/reducers/uniformsSlice'
import useNotebooks from 'hooks/notebooks/useNotebooks'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { Form, Input, Modal } from 'antd'
import { creteNewComment } from 'services/commentsServices/comments'

const FixedInfoFooter = ({
  save,
  next,
  uuidNotebook: uuid
}: {
  save?: () => void
  next?: (dat?: number, fn?: () => void) => void
  uuidNotebook?: string | undefined
}) => {
  const [label, setLabel] = useState('')
  const [loading, setLoading] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { statusBar } = useSelector((state: RootState) => state.uniforms)
  const { step1, step2, step3, step4 } = statusBar
  const { saveSendDataNotebookChange, notebook } = useNotebooks({
    uuid: parseInt(uuid || '0')
  })

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [form] = Form.useForm()

  const isEzetera = uid.role == 'Ezetera'
  const isInPreview = location.pathname.includes('cuadernos/preview')
  const isInFrontEdit = location.pathname.includes('cuadernos/edith-front')
  const isInInsideEdit = location.pathname.includes('cuadernos/edith-inside')
  const isInSelectType = location.pathname.includes('cuadernos/select-type')

  const isInUniforms = location.pathname.includes('uniforms')
  const isInSelectTypeUniforms = location.pathname.includes(
    'uniforms/create-new'
  )

  const isInStatus23 =
    isEzetera &&
    isInPreview &&
    [2, 3].includes((notebook?.status || 0) as number)
  const isInStatus8 = isEzetera && notebook?.status == '8'
  const isInStatus11 = isEzetera && notebook?.status == '11'

  const notebookInInStatus23 =
    !isEzetera &&
    isInPreview &&
    [2, 3].includes((notebook?.status || 0) as number)
  const notebookIsInStatus6 =
    !isEzetera && notebook?.status == '6' && isInPreview
  const notebookIsInStatus8 =
    !isEzetera && notebook?.status == '8' && isInPreview
  const notebookIsInStatus9 =
    !isEzetera && notebook?.status == '9' && isInPreview
  const notebookIsInStatus10 = !isEzetera && notebook?.status == '10'

  const notebookIsInStatus5 = notebook?.status == '5' && isInPreview
  const notebookIsInStatus4 = notebookIsInStatus5
    ? notebookIsInStatus5
    : notebook?.status == '4' && isInPreview

  useEffect(() => {
    getLabelButton()

    if (isInStatus8 || isInStatus11) {
      openComments()
    }

    return () => {
      getLabelButton()
    }
  }, [notebook])

  const openComments = () => {
    dispatch(setopenComments(true))
    dispatch(setCommentsObjectInformation(notebook))
  }

  const goToAction = () => {
    let thisPath = location.pathname

    setLoading(true)
    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')

      switch (path[2]) {
        case 'select-type':
          next &&
            next(1, () => {
              setLoading(false)
              navigate(`/cuadernos/edith-front/${uuid}`)
            })
          break
        case 'edith-front':
          if (isInStatus8) {
            save && save()

            saveSendDataNotebookChange(
              {
                status: 9,
                step: 2
              },
              false,
              () => navigate(-1)
            )
          } else if (notebookIsInStatus10) {
            save && save()

            saveSendDataNotebookChange(
              {
                status: 11,
                step: 3
              },
              false,
              () => navigate(-1)
            )
          } else {
            next &&
              next(2, () => {
                setLoading(false)
                navigate(`/cuadernos/edith-inside/${uuid}`)
              })
          }
          break
        case 'edith-inside':
          next &&
            next(3, () => {
              setLoading(false)
              navigate(`/cuadernos/preview/${uuid}`)
            })
          break
        case 'preview':
          let status = 1
          if (notebook?.status == '9') status = 4

          saveSendDataNotebookChange(
            {
              status
            },
            false,
            () => {
              navigate('/cuadernos')
            }
          )

          break
        default:
          break
      }
    }
    if (thisPath.includes('uniforms')) {
      let path = thisPath.split('/')

      switch (path[2]) {
        case 'create-new':
          setLoading(false)
          navigate(`/uniforms/edith-selection`)
          break

        default:
          break
      }
    }
  }

  const getLabelButton = () => {
    let thisPath = location.pathname

    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'select-type':
          dispatch(setStep1())
          setLabel('Continuar')
          break
        case 'edith-front':
          dispatch(setStep2())
          setLabel(isInStatus8 ? 'Terminar' : 'Continuar')
          break
        case 'edith-inside':
          dispatch(setStep2())
          setLabel('Continuar')
          break
        case 'preview':
          dispatch(setStep3())
          setLabel('Terminar')
          break
        default:
          break
      }
    }
    if (thisPath.includes('uniforms')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'create-new':
          //dispatch(setStep1())
          setLabel('Continuar')
          break
        case 'edith-selection':
          //dispatch(setStep1())
          setLabel('Continuar')
          break

        default:
          break
      }
    }
  }

  const handleRejectRequest = async (values: any) => {
    setLoading(true)

    try {
      const body = {
        content: values.comment,
        comment_type: 'notebook',
        id_module: uuid
      }

      await creteNewComment(body)
      saveSendDataNotebookChange(
        {
          status: 8,
          step: 1,
          front_cover_img: '',
          back_cover_img: '',
          college_proposal: false,
          ezetera_proposal: true
        },
        false,
        () => {
          setLoading(false)
          navigate('/cuadernos')
        }
      )
    } catch (error) {}
  }
  return (
    <div className={`${style['bar-fixed']}`}>
      {!notebookIsInStatus9 && !isInUniforms && (
        <div className={`${style['div-flex']}`}>
          <OptionStatusBar
            step={1}
            success={step1.success}
            onCurse={step1.onCurse}
            title='Elige un cuaderno'
          />
          <OptionStatusBar
            step={2}
            success={step2.success}
            onCurse={step2.onCurse}
            title='Diseña tu cuaderno'
          />
          <OptionStatusBar
            step={3}
            success={notebookIsInStatus4 ? true : step3.success}
            onCurse={notebookIsInStatus4 ? true : step3.onCurse}
            title='Revisión'
          />
          <OptionStatusBar
            step={4}
            success={notebookIsInStatus5 ? true : step4.success}
            onCurse={notebookIsInStatus4 ? true : step4.success}
            title='Autorización'
            showChevron={false}
          />
        </div>
      )}
      {isInUniforms && (
        <div className={`${style['div-flex']}`}>
          <OptionStatusBar
            step={1}
            success={step1.success}
            onCurse={step1.onCurse}
            title='Elige una prenda'
          />
          <OptionStatusBar
            step={2}
            success={step2.success}
            onCurse={step2.onCurse}
            title='Diseña tu prenda'
          />
          <OptionStatusBar
            step={3}
            success={step3.success}
            onCurse={step3.onCurse}
            title='Revisión'
          />
          <OptionStatusBar
            step={4}
            success={step4.success}
            onCurse={step4.onCurse}
            title='Autorización'
            showChevron={false}
          />
        </div>
      )}
      <div
        className={`${style['div-container-buttons']} ${
          notebookIsInStatus8 ? style['in-status-8'] : ''
        }`}
      >
        <div className={`${style['div-price-container']}`}>
          {/*
          <p className={`${style['p-margin']} ${style['price-title']}`}>
            Precioss
          </p>
          <p className={`${style['p-margin']} ${style['price-numbers']}`}>
            {uid?.price_notebook || 'sin definir'}
          </p>
          */}
        </div>
        {(isInStatus23 || notebookInInStatus23 || isInStatus8) && (
          <div>
            <Button
              size='m'
              label='Comentarios'
              type='grey'
              background='white'
              rounded
              loaderSize='x'
              onClick={openComments}
            />
          </div>
        )}
        {notebookIsInStatus9 && (
          <div style={{ display: 'flex', columnGap: '4px' }}>
            <Button
              onClick={() => setOpenRejectModal(true)}
              isLoading={loading}
              size='m'
              label={'Rechazar'}
              rounded
              sector='secondary'
            />
            <Button
              size='m'
              label='Autorizar'
              rounded
              loaderSize='x'
              onClick={goToAction}
            />
          </div>
        )}
        {(isInFrontEdit ||
          isInInsideEdit ||
          isInSelectType ||
          notebookIsInStatus6) && (
          <>
            <Button
              size='m'
              label='Guardar'
              type='grey'
              background='white'
              rounded
              loaderSize='x'
              onClick={save}
            />
            <Button
              onClick={goToAction}
              size='m'
              label={label}
              rounded
              sector='secondary'
            />
          </>
        )}

        {isInUniforms && (
          <>
            <Button
              size='m'
              label='Guardar'
              type='grey'
              background='white'
              rounded
              loaderSize='x'
              onClick={save}
            />
            <Button
              onClick={goToAction}
              size='m'
              label={label}
              rounded
              sector='secondary'
            />
          </>
        )}
      </div>
      <CommentsComponent />
      <Modal
        open={openRejectModal}
        onCancel={() => setOpenRejectModal(false)}
        title='Rechazar solicitud'
        footer={null}
      >
        <Form onFinish={handleRejectRequest} form={form}>
          <div style={{ marginTop: '12px', marginBottom: '12px' }}>
            Agrega un comentario para rechazar la solicitud
          </div>
          <Form.Item name='comment'>
            <Input.TextArea
              placeholder='Agrega un comentario'
              maxLength={100}
              required={true}
            />
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Form.Item>
              <Button
                isLoading={loading}
                label='Rechazar solicitud'
                rounded
                sector='secondary'
                buttonType='submit'
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default FixedInfoFooter
