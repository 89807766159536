import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import GreenTagSueter from 'assets/img/uniforms/sueter/male/GreenTagSueter'
import SueterSvg from 'assets/img/uniforms/sueter/male/SueterSvg'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SueterFemaleSvg from 'assets/img/uniforms/sueter/female/SueterFemaleSvg'
import GreenTagSueterFemale from 'assets/img/uniforms/sueter/female/GreenTagSueterFemale'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const SueterEditable = () => {
  const { sueterMaleForm, sueterFemaleForm, pictureLogo, female } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Sueter',
        description: 'Descripcion del sueter'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        {!female ? (
          <Fragment>
            {sueterMaleForm.logoPosition.value !== '' && (
              <div
                className={`${
                  style[
                    `logo-position-sueter-${sueterMaleForm.logoPosition.value}`
                  ]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}
            <div className={`${style['div-polo-svg-absolute']}`}>
              <SueterSvg className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagSueter className={`${style['svg']}`} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {sueterFemaleForm.logoPosition.value !== '' && (
              <div
                className={`${
                  style[
                    `logo-position-sueter-fem-${sueterFemaleForm.logoPosition.value}`
                  ]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}

            <div className={`${style['div-polo-svg-absolute']}`}>
              <SueterFemaleSvg
                className={`${style['svg']}`}
                bolsas={sueterFemaleForm.bolsas.value as boolean}
              />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagSueterFemale className={`${style['svg']}`} />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default SueterEditable
