import { memo } from 'react'
import { INPUTS_INITIAL_STATE_PACKAGES } from 'redux/reducers/packages'
import { Row } from 'antd'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import PreBuyFooter from 'components/PreBuyFooter/PreBuyFooter'
import BkgPreBuyUtils from '../../../assets/img/preBuyBackgrounds/bkgPreBuyUtils.svg'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import UserPackageList from 'modules/Utils/UserPackageList/UserPackageList'
import AdminUtilsCatalog from 'modules/Utils/AdminUtilsCatalog/AdminUtilsCatalog'
import AdminUtilsManagement from 'modules/Utils/AdminUtilsManagement/AdminUtilsManagement'
import { useSelector } from 'react-redux'
import { useValidatePermission } from 'hooks/utils/useValidatePermission'

const acquired = true

const PacakagesList = memo(() => {
  Object.freeze(INPUTS_INITIAL_STATE_PACKAGES)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const utilsAdminPage = useSelector((state:any) => (state?.utilsAdmin?.value || "management"))
  useValidatePermission()

  if (!acquired)
    return (
      <MainTemplate preBuyPage={BkgPreBuyUtils}>
        <PreBuyFooter
          title='Útiles'
          imgTitle={{
            name: 'utilsIcon',
            height: 20,
            width: 20
          }}
          subTitle='Maecenas eget condimentum velit, sit amet feugiat lectus.'
          description={[
            'Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a',
            ' , mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.'
          ]}
          topics={[
            'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
            'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
            'Vorem ipsum dolor sit amet, consectetur adipiscing elit. '
          ]}
          buttonProps={[
            {
              label: 'Contratar este módulo',
              onClick: () => alert('click'),
              sector: 'tertiary',
              rounded: true,
              size: 'm'
            }
          ]}
        />
      </MainTemplate>
    )

  if (uid.role !== 'Ezetera') return <UserPackageList />

  if (utilsAdminPage === 'catalog')
    return (
      <MainTemplate>
        <Row align='middle' gutter={[0, 16]}>
          <AdminUtilsCatalog />
        </Row>
      </MainTemplate>
    )

  return (
    <MainTemplate>
      <Row align='middle' gutter={[0, 16]}>
        <AdminUtilsManagement />
      </Row>
    </MainTemplate>
  )
})

PacakagesList.displayName = 'PacakagesList'
export default PacakagesList
