import { memo } from 'react'
import styles from './listBooks.module.scss'
import { Table } from 'antd'
import { Books, BooksTable } from 'interfaces/books-interface'
import { ScrollTable } from 'types'
import { ColumnsType } from 'antd/es/table'

interface ListProps {
  loading: boolean
  scroll?: ScrollTable
  columns: ColumnsType<BooksTable>
  books: Books
  onHandleRow: any
  onChangePage: (page: number) => void
}

const ListBooksModule = memo((props: ListProps) => {
  const { books, total, page } = props?.books

  return (
    <>
      <Table
        className={styles.booksTable}
        dataSource={books}
        columns={props.columns}
        scroll={props.scroll}

        pagination={{
          total,
          current: page,
          onChange: (pageNumber) => props.onChangePage(pageNumber),
          showSizeChanger: false
        }}
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        rowKey={(record) => record.id}
        loading={props.loading}
        onRow={props.onHandleRow}
      />
    </>
  )
})

export default ListBooksModule
