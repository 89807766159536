import { memo } from 'react'
import { Col, Form, FormInstance } from 'antd'
import styles from './formQuotas.module.scss'
import FormInput from 'components/formInputs/input'
import Button from 'components/buttons/Button/Button'

interface IFormQuotas {
  actions: any
  form: FormInstance<any>
  loading: boolean
  quotaSchool: any
  onHandleForm: (values: any) => void
}

const FormQuotasModule = memo(
  ({ actions, form, loading, onHandleForm, quotaSchool }: IFormQuotas) => {
    return (
      <Form
        className={styles.formQuota}
        form={form}
        layout='vertical'
        onFinish={onHandleForm}
        autoComplete='off'
      >
        <Col span={24}>
          <FormInput
            name='name'
            placeholder='Nombre'
            label='Nombre'
            rules={[{ required: true, message: '' }]}
          />
        </Col>
        <Col span={24}>
          <FormInput
            name='price'
            placeholder='$0.00'
            rules={[
              { required: true, message: '' },
              {
                pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                message: 'Escribe solo números'
              }
            ]}
            label={
              <>
                <span>Precio</span>&nbsp;&nbsp;{' '}
                <span className={styles.labelPrice}>
                  {`Al precio de la cuota se le aumentará el ${quotaSchool}%`}
                </span>{' '}
              </>
            }
          />
        </Col>

        <div className={styles.button}>
          <Button
            isLoading={loading}
            sector='primary'
            buttonType='submit'
            rounded
          >
            {actions.action === 'edit'
              ? 'Guardar cambios'
              : 'Crear nueva cuota'}
          </Button>
        </div>
      </Form>
    )
  }
)

export default FormQuotasModule
