import { createSlice } from '@reduxjs/toolkit'
import { InputFormConfig } from 'types'

export interface packagesFormI {
  name: InputFormConfig
 /*  description: InputFormConfig */
}


export interface packagesAndUtilsForm {
  packageForm: packagesFormI
  editPackageId:string
}

export const INPUTS_INITIAL_STATE_PACKAGES = {
  name: {
    label: 'Nombre del nuevo paquete',
    name: 'name',
    placeholder: 'Nombre del paquete',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: '',
    id: undefined
  }/* ,
  description: {
    label: 'Comentarios',
    name: 'description',
    placeholder: 'Comentarios',
    value: '',
    type: 'text',
    required: true,
    maxLength: 150, //maxLength solo funciona para tipo text
    status: '',
    id: undefined
  } */
}

const initialState: packagesAndUtilsForm = {
  packageForm:INPUTS_INITIAL_STATE_PACKAGES,
  editPackageId:''
}

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setPackagesForm: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  
  }
})

// Metodo global para actualizar el state
export const { setPackagesForm } = packagesSlice.actions

export default packagesSlice.reducer
