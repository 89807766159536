import React, { memo, useState } from 'react'
import { Space, Modal, Row, Col } from 'antd'
import { Tooltip } from 'antd'
import { Form } from 'antd'
import Input from 'components/inputs/Input'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { cleanSingleUpdatedState } from 'utilities/cleanSingleUpdatedState'
import { LevelListInt } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLevelsForm,
  setGradesForm,
  INPUTS_INITIAL_STATE_GRADES,
  INPUTS_INITIAL_STATE
} from 'redux/reducers/levelsAndGrades'

import style from './LevelActions.module.scss'
import Button from 'components/buttons/Button/Button'
import { RootState } from 'redux/store/store'
import { setPackagesForm } from 'redux/reducers/packages'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

interface LevelActionsI {
  data: LevelListInt
  loadingModal: boolean
  postNewPackage: (e: any, func: any, data: any) => void
}

const LevelActionsPackage = memo(
  ({ data, loadingModal, postNewPackage }: LevelActionsI): JSX.Element | null => {
    Object.freeze(INPUTS_INITIAL_STATE)
    Object.freeze(INPUTS_INITIAL_STATE_GRADES)
    const dispatch = useDispatch()
    const { dataToken } = useDecodeToken() 
    const { uid }: SessionData = dataToken()
    const { packageForm } = useSelector((state: RootState) => state.packages)

    const [open, setOpen] = useState(false)

    const returnLevelCleanData = (data: LevelListInt) => {
      const { name } = { ...packageForm }
      let updateData = { ...name }
      updateData.value = data.name
      updateData.id = data.id
      return updateData
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPackagesForm({
          packageForm: cleanSingleUpdatedState(e, packageForm)
        })
      )
    }

    const handleCancel = (e: any) => {
      setOpen(false)
      dispatch(setLevelsForm({ packageForm: INPUTS_INITIAL_STATE }))
    }

    const handleChangeGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setGradesForm({ gradesForm: cleanSingleUpdatedState(e, packageForm) })
      )
    }

    const showModal = (event: any, data: LevelListInt) => {
      dispatch(
        setLevelsForm({ levelsForm: { name: returnLevelCleanData(data) } })
      )
      event.stopPropagation()
      setOpen(true)
    }

    const handlePostGrade = () => {
      postNewPackage(data, handleCancel, packageForm)
    }

    if (uid.role === 'Director') return null

    return (
      <Space>
        <button
          style={{ opacity: '0', position: 'absolute' }}
          id='closeAntdModal'
          onClick={handleCancel}
        ></button>
        <Tooltip title='Agrega nuevo paquete al grado'>
          <button
            style={{ background: 'transparent', border: 'none' }}
            id={`add-package-grade-${data.id}`}
            onClick={(event) => showModal(event, data)}
          >
            <PlusCircleIcon className={`${style['iconStyle']} }`} />
          </button>
        </Tooltip>
        <Modal
          title='Crear nuevo paquete'
          open={open}
          onCancel={handleCancel}
          className={style.CreateGradeModal}
          footer={
            <div className={style.createGradeFooter}>
              <Button
                type='blue'
                rounded={true}
                disabled={loadingModal}
                onClick={handlePostGrade}
                label={'Crear'}
              />
            </div>
          }
          width={500}
        >
          <div className={style.createGradeBody}>
            <Form layout='vertical' autoComplete='off'>
              {Object.entries(packageForm).map(([key, value]) => {
                return (
                  <Input
                    key={key}
                    name={value.name}
                    placeholder={value.placeholder}
                    label={value.label}
                    type={value.type}
                    value={value.value}
                    onChange={handleChange}
                    onClick={(e: any) => e?.stopPropagation()}
                  />
                )
              })}
            </Form>
          </div>
        </Modal>
      </Space>
    )
  }
)

LevelActionsPackage.displayName = 'LevelActionsPackage'
export default LevelActionsPackage
