import Button from 'components/buttons/Button/Button'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './labels.module.scss'

const LabelsUniforms = () => {
  const { conjuntoLabelstMenu, selectedMenu } = useSelector(
    (state: RootState) => state.uniforms
  )
  return (
    <Fragment>
      {conjuntoLabelstMenu !== null && (
        <div className={`${style['labels-cotainer']}`}>
          {conjuntoLabelstMenu.length !== 0 &&
            conjuntoLabelstMenu.map(
              (
                item: { label: string; click: () => void; selected: string },
                index: number
              ) => {
                return (
                  <Fragment key={index}>
                    {selectedMenu === item.selected ? (
                      <Button
                        rounded
                        size='sm'
                        sector='secondary'
                        background='white'
                        onClick={item.click}
                        label={item.label}
                      />
                    ) : (
                      <Button
                        rounded
                        size='sm'
                        onClick={item.click}
                        label={item.label}
                      />
                    )}
                  </Fragment>
                )
              }
            )}
        </div>
      )}
    </Fragment>
  )
}

export default LabelsUniforms
