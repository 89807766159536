import React, { useState, Fragment } from 'react'
import { ArrowUpOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import Button from 'components/buttons/Button/Button'
import style from './selectedcardsoption.module.scss'
import './ant-modal.scss'

import { useDispatch, useSelector } from 'react-redux'

import {
  setInsiseLogo,
  setOutsidePropuesta,
  setOutsidePropuestaBack,
  setShowModalUploadPortada
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import { handleModalPicture } from 'redux/reducers/uniformsSlice'

const SelectedCardsOptionsHOC = ({
  children,
  title = 'vacio',
  name,
  showError = false,
  gridArea = '',
  edith = true,
  onClick,
  valueOption,
  reset
}: {
  children?: JSX.Element[]
  title?: string
  name?: string | 'logo'
  showError?: boolean
  gridArea?: string
  edith?: boolean
  onClick?: () => void
  valueOption?: string | boolean
  reset?: () => void
}) => {
  const dispatch = useDispatch()
  const { college_proposal } = useSelector(
    (state: RootState) => state.notebooks
  )
  const { pictureLogo } = useSelector((state: RootState) => state.uniforms)

  const [pantoneColors, setPantoneColors] = useState<string[]>([])

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader()
      reader.onload = (e: any) => {
        dispatch(setInsiseLogo(e.target.result as string))
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const openModalUploadProporsal = () => {
    if (!college_proposal) {
      dispatch(setOutsidePropuesta(''))
      dispatch(setOutsidePropuestaBack(''))
    }
    dispatch(setShowModalUploadPortada(true))
  }

  return (
    <div
      style={{ gridArea: gridArea }}
      className={`${style['div-option-container']}`}
    >
      <p
        className={`${style['description-title']} 
        ${style[edith ? 'description-title-p200' : 'description-title-p400']}`}
      >
        {title}
        {showError && (
          <span className={`${style['span-error']}`}>
            * Selecciona una opción
          </span>
        )}
      </p>
      {name !== 'cover_design' && (
        <div className={`${style['div-types-flex']}`}>
          {children}
          {name === 'logo_position' && (
            <Button
              LeftIcon={ArrowUpOnSquareIcon}
              background='white'
              size='m'
              rounded
              label='Sube tu logotipo'
              onClick={() => onClick && onClick()}
            />
          )}
          {name === 'logo' && (
            <Button
              LeftIcon={ArrowUpOnSquareIcon}
              background='white'
              size='m'
              rounded
              label='Sube tu logotipo'
              onClick={() => dispatch(handleModalPicture(true))}
              rightIconColor=''
            />
          )}
          {name === 'logo' && pictureLogo !== '' && (
            <div className ={`${style['logo-clothe']}`}>
              <img className ={`${style['img-logo-clothe']}`} src={pictureLogo} />
            </div>
          )}
        </div>
      )}

      {name === 'cover_design' && (
        <div className={`${style['div-types-flex']}`}>
          {children}
          {valueOption === 'upload' && (
            <Fragment>
              <Button
                LeftIcon={ArrowUpOnSquareIcon}
                background='white'
                size='m'
                rounded
                label='Sube tu diseño'
                onClick={openModalUploadProporsal}
              />
              {college_proposal && (
                <Button
                  LeftIcon={TrashIcon}
                  background='white'
                  size='sm'
                  rounded
                  label='Borrar propuesta'
                  sector='secondary'
                  onClick={() => reset && reset()}
                />
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}

export default SelectedCardsOptionsHOC
