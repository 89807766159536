/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { isAuthenticated } from 'utilities/userSession'

function NormalRoute(): JSX.Element {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  if (!isAuthenticated()) return <Outlet />
  if (uid.role === 'Ezetera') return <Navigate to={'/lista-de-colegios'} />
  return <Navigate to={'/estructura'} />
}
NormalRoute.displayName = 'NormalRoute'
export default NormalRoute
