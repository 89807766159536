import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleChalecoForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'
import { TFormBaseOptions } from 'interfaces/uniforms-interface'

const ChalecoOptionsEditable = () => {
  const { chalecoForm } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (keyName: TFormBaseOptions, value: string | boolean) => {
    dispatch(
      handleChalecoForm({
        ...chalecoForm,
        [keyName]: {
          ...chalecoForm[keyName as TFormBaseOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary
            handleChange={handleChange}
            form={chalecoForm}
          />
        )
      })()}
    </div>
  )
}

export default ChalecoOptionsEditable
