import { memo } from 'react'
import DescagaPic from 'assets/img/descarga.svg'
import styles from './massive.module.scss'
import B from 'components/texts/B/B'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Typography, Upload } from 'antd'
import Btn from 'components/buttons/Button/Button'
import { useMassive } from 'hooks/books/massive/useMassive'
import { EXTENSIONS } from '../../../constants'

interface IMassiveModule{
  closeModal?:any
  errorMassive?:any
}

const MassiveModule = memo((props:IMassiveModule) => {
  const { loading,showList, downloadFormatBooks, beforeUploadBooks, uploadBooks } =
    useMassive(props.errorMassive)

    const dummyRequest = ({ _, onSuccess }:any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };

  return (
    <>
      <div className={styles.uploadMassive}>
        <img src={DescagaPic} alt='descargar' />
        <div className={styles.text}>
          <B size='Light'>
            {' '}
            Cargar el archivo con el formato correcto para logotipo, el cual puede ser un .xls o un .csv.
          </B>
        </div>
        <div className={styles.uploadContainer}>
          <Typography className={styles.label}>
            <span className={styles.asterik}>*</span> <b>Sube tu lista:</b>
          </Typography>
          <Upload
            customRequest={dummyRequest}
            className={styles.upload}
            listType='picture'
            maxCount={1}
            accept={EXTENSIONS.accept}
              showUploadList={showList}
            beforeUpload={(file) => {
              beforeUploadBooks(file)
            }}
           
          >
            <Button
              className={styles.button}
              icon={<UploadOutlined className={styles.iconUpload} />}
            >
              {' '}
              <b>Click para subir archivo</b>
            </Button>
          </Upload>
        </div>
        <div className={styles.formats}>
          <small>Formatos compatibles en .xls o .csv</small>
        </div>

        <div className={styles.template}>
          <B size='Light'>
            {' '}
            Puedes descargar la plantilla para carga masiva desde el siguiente
            link
          </B>
          <Button
            onClick={downloadFormatBooks}
            loading={loading}
            className={styles.btn}
            type='link'
          >
            Descargar plantilla
          </Button>
        </div>
      </div>
      <div className={styles.btnUpload}>
        <Btn
          label={'Subir lista de libros'}
          isLoading={loading}
          onClick={() => {
            uploadBooks(props?.closeModal)
           
          
          }}
          rounded
          sector='primary'
        />
      </div>
    </>
  )
})

export default MassiveModule
