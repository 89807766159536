import BackButton from 'components/buttons/BackButton/BackButton'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useUtils } from 'hooks/utils/useUtils'
import { memo } from 'react'
import { Input, Table } from 'antd'
import Modals from 'components/modal'
import MassiveModule from 'modules/books/massive/MassiveModule'
import Button from 'components/buttons/Button/Button'
import styles from './utils.module.scss'
import AddUtilsForm from '../AddUtilsForm/AddUtilsForm'
import { useNavigate } from 'react-router-dom'

const AdminUtilsCatalog = memo(() => {
  const {
    LoadingList,
    params,
    action,
    utilsColumns,
    openDeleteModal,
    utilsList,
    deletingItem,
    pagination,
    itemToEdit,
    openMassiveModal,
    downloadingCatalog,
    onHandleChangePage,
    setOpenDeleteModal,
    getUtilsList,
    handleDeleteItem,
    setParams,
    setItemToEdit,
    setOpenMassiveModal,
    handleDownloadCatalog
  } = useUtils()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const navigate = useNavigate()

  const renderTitleButtons = () => {
    if (uid.role === 'Ezetera' && action !== 'nuevo' && action !== 'editar') {
      return [
        {
          label: 'Agregar nuevo útil',
          onClick: () => {
            setItemToEdit(null)
            navigate('?action=nuevo')
          },
          rounded: true,
          size: 'm',
          sector: 'secondary'
        },
        {
          label: 'Carga masiva',
          onClick: (e: Event) => {
            e.stopPropagation()
            e.preventDefault()
            setOpenMassiveModal(true)
          },
          background: 'white',
          rounded: true,
          LeftIconName: 'uploadIcon',
          size: 'm',
          sector: 'secondary'
        },
        {
          label: 'Descargar',
          background: 'white',
          onClick: (e: Event) => {
            e?.stopPropagation()
            e?.preventDefault()
            handleDownloadCatalog()
          },
          rounded: true,
          size: 'm',
          sector: 'tertiary',
          isLoading: downloadingCatalog
        }
      ]
    }
  }

  return (
    <>
      {params.has('action') &&
      ['editar', 'nuevo'].includes(params.get('action') || '') ? (
        <>
          <BackButton
            onClick={() => {
              navigate('/utiles')
              getUtilsList(1)
            }}
            textDecoration='underline'
          />
          <AddUtilsForm
            params={params}
            navigate={navigate}
            selectedUtil={
              itemToEdit
                ? {
                    ...itemToEdit,
                    supply_make_name: itemToEdit?.make
                  }
                : null
            }
            onFinishForm={() => {
              setItemToEdit(null)
              navigate('/utiles')
              getUtilsList()
            }}
          />
        </>
      ) : (
        <>
          <TitleHeader
            buttonProps={renderTitleButtons()}
            IconName='utilsIcon'
            widthIcon={'35'}
            heightIcon={'35'}
            title='Catálogo de útiles'
          />
          <Input.Search
            placeholder='Buscar'
            enterButton
            size='large'
            allowClear={true}
            onSearch={(e: string) => {
              setParams((prev) => ({
                ...prev,
                search: e
              }))
            }}
          />
          <Table
            dataSource={utilsList}
            loading={LoadingList}
            columns={utilsColumns}
            className={styles.utilstable}
            scroll={{ x: 1400 }}
            rowKey={'id'}
            pagination={{
              showSizeChanger: false,
              total: pagination?.total,
              pageSize: pagination?.limit,
              onChange: (page: number, pageSize: number) => {
                onHandleChangePage(page)
              }
            }}
          />
        </>
      )}
      {/* Massive charge modal */}
      <Modals
        title='Carga masiva'
        open={openMassiveModal}
        onCancel={() => setOpenMassiveModal(false)}
      >
        <MassiveModule closeModal={() => setOpenMassiveModal(false)} />
      </Modals>
      {/* Delete item modal */}
      <Modals
        title='Eliminar registro'
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
      >
        <>
          <span>¿Estás seguro de eliminar el registro?</span>
          <div>
            <Button
              isLoading={deletingItem}
              label={'Eliminar'}
              rounded
              sector='primary'
              onClick={() => handleDeleteItem()}
            />
          </div>
        </>
      </Modals>
    </>
  )
})

AdminUtilsCatalog.displayName = 'AdminUtilsCatalog'
export default AdminUtilsCatalog
