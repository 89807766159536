import React from 'react'

const ShadowsPantalonTrasero = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Sombras" stroke='black'>
    <g id="Sombras-2" data-name="Sombras">
      <path  d="m329.26,248.66s50.78-.42,59.62-2.86c8.84-2.44,5.24-.06,3.93,2.86-1.31,2.92-8.01,34.52-11.37,41.72-3.37,7.2-18.56,45.45-19.48,54.68-.92,9.24,7.92-39.09,8.91-51.95.98-12.86,2.62-33.35,5.9-35.87,3.28-2.52,7.86-5.36-9.17-3.15-17.03,2.21-38.33-5.44-38.33-5.44Z"/>
      <path  d="m291.4,407.35s9.71,2.4,18.87,0c9.16-2.4,23.31-5.34,26.09,0,2.78,5.34,26.92,10.88,26.92,10.88l-16.37,1.08s-8.63-8.11-23.87-9.77c-15.24-1.65-32.2,4.75-31.64-2.19Z"/>
      <path  d="m437.45,406.73s7.51,5.12,19.72,4.83c12.21-.29,29.14-2.66,33.3-2.41,4.16.25,18.6,1.85,20.81-2.42,2.22-4.27,6.14,6.67-15.54,5.18-21.68-1.49-19.99,4.17-24.15,5.51-4.16,1.33-34.99.8-34.15-10.68Z"/>
      <path  d="m297.89,86.65s46.89-2.06,58.54-.97c12.26,1.15,33.68,2.31,33.68,2.31,0,0-.73,6.17-.59,9.35.08,1.62-.89,2.67,2.58,3.45,21.15,4.75,24.5-12.23,24.5-12.23,0,0,4.24-2.22,15.66-2.1,18.04.19,70.36.97,70.36.97,0,0-59.46,7.84-60.57,7.81-43.34-1.14-27.53,11.32-39.18,10.25-8.67-.79-14.79-1.2-25.27-3.21,0,0-4.45-3.87,4.84-7.97,9.28-4.1-84.54-7.64-84.54-7.64Z"/>
      <path  d="m262.57,465.92s15.75,7.69,28.02,7.25c12.28-.44,21.57,5.16,21.57,5.16,0,0,4.88,3.63-2.36,4.29-7.24.66-47.7-11.65-47.23-16.7Z"/>
      <path  d="m491.67,452.09s13.85,3.84,26.45,1.62c12.6-2.22,17.63-6.05,17.63-6.05,0,0,5.04-4.44-2.68-3.73-7.71.71-21.57,8.78-41.4,8.17Z"/>
      <path  d="m289.58,716.05s1.42,8.64,17.16,12.72c15.74,4.09,32.75-10.9,34.64-10.9s8.82-5.3,14.64-6.06c5.82-.76-41.72,8.63-45.03,7.87-3.3-.76-13.22,2.88-21.41-3.64Z"/>
      <path  d="m466.05,728.87s13.85,3.84,26.45,1.62c12.6-2.22,17.63-6.05,17.63-6.05,0,0,5.04-4.44-2.68-3.73-7.71.7-21.57,8.78-41.4,8.17Z"/>
      <polygon points="327.95 165.83 327.33 169.63 326.95 165.81 327.95 165.83 327.95 165.83"/>
      <path d="m339.33,243.89c25.84-.99,51.68-1.82,77.54-2.25,12.93-.2,25.86-.44,38.81.21,2.03.17,4.11.25,6.11.8.72.17,1.42.91.53,1.52-.76.41-1.54.68-2.33,1-9.35,2.88-19.29,2.81-28.96,2.46,9.7-.42,20.44.02,29.77-3.69.25-.1.97-.42,1.07-.5,0,.02-.03.11,0,.17-1.73-.54-4.23-.64-6.26-.76-38.68-1.34-77.79.5-116.28,1.05h0Z"/>
    </g>
  </g>
    </svg>
  )
}

export default ShadowsPantalonTrasero
