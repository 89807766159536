import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import PantsDeportivoEditable from './PantsDeportivoEditable'
import PantsDeportivosLateralEditable from './PantsDeportivosLateralEditable'

const ConjuntoPantsDeportivos = () => {
  const { selectedMenu, female } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('pants deportivo'))
    const data = [
      {
        label: 'Pants',
        selected: 'pants deportivo',
        click: () => dispatch(setPantalonesMenu('pants deportivo'))
      },
      {
        label: 'Pants lateral',
        selected: 'pants lateral deportivo',
        click: () => dispatch(setPantalonesMenu('pants lateral deportivo'))
      }
    ]

    dispatch(setLabelsMenu(data))
  }, [female])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'pants deportivo':
            return <PantsDeportivoEditable />
          case 'pants lateral deportivo':
            return <PantsDeportivosLateralEditable />
          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}
export default ConjuntoPantsDeportivos
