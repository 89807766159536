/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from 'react'
import { Button, Form } from 'antd'
import styles from './forgotPwd.module.scss'
import { useNavigate } from 'react-router-dom'
import { InputForgot, useForgotPwd } from 'hooks/forgotPwd/useForgotPwd'
import { recoverPasswordData } from 'services/auth/users'
import FormInput from 'components/formInputs/input'

const ForgotPwdModule = memo(() => {
  const { loading, form, handleForgotPwd } = useForgotPwd()
  const navigate = useNavigate()

  const onSubmit = (value: recoverPasswordData) => {
    handleForgotPwd(value)
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        className={styles.formRecovery}
        layout='vertical'
        autoComplete='off'
      >
        <FormInput {...InputForgot} />

        <Button
          className={styles.btn}
          type='primary'
          loading={loading}
          htmlType='submit'
        >
          Enviar Correo
        </Button>
      </Form>
      <Button
        className={styles.btnInit}
        type='link'
        onClick={() => navigate(`/`)}
      >
        Regresar al inicio
      </Button>
    </>
  )
})

export default ForgotPwdModule
