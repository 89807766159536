import React, { Fragment, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import style from './art.module.scss'

const HoverOptions = ({
  label,
  familys,
  setFont,
  selected,
  click
}: {
  label: string
  familys: string[]
  setFont: (dat: string) => void
  selected: string
  click: () => void
}) => {
  const [show, setShow] = useState(false)
  
  return (
    <div
      className={`${style['div-fonts']}`}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      {familys.length === 1 ? (
        <p
          onClick={() => {
            setFont(label)
            click()
          }}
          className={`${style['p-option']} 
              ${style[label]} 
              ${style['p-cursos-click']} 
              ${label === selected && style['selected-font']}`}
        >
          {label}
        </p>
      ) : (
        <p
          className={`${style['p-option']} 
                ${style[label]} 
                ${selected.includes(label) && style['selected-font']}`}
        >
          {label} <ChevronRightIcon className={`${style['hero-icon']}`} />
        </p>
      )}
      {show && familys.length > 1 && (
        <div className={`${style['div-fonts-container']}`}>
          {familys.map((item: string, index: number) => {
            return (
              <p
                onClick={() => {
                  setFont(item)
                  click()
                }}
                key={index}
                className={`${style['p-fonts']} 
                    ${style[item]} 
                    ${item === selected && style['selected-font']}`}
              >
                {item}
              </p>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default HoverOptions
