import React, { Fragment } from 'react'
import {
  ArrowLeftOnRectangleIcon,
  ArrowUpTrayIcon,
  LanguageIcon,
  LockClosedIcon,
  LockOpenIcon
} from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEdithUpload,
  setIdTextBack,
  setIdTextFront,
  setImgIdBack,
  setImgIdFront,
  setIsCoverOnEdith,
  setJsonTextBack,
  setJsonTextfront,
  setShowModalBucket
} from 'redux/reducers/notebooksSlice'

import { RootState } from 'redux/store/store'
import style from './notebooksEitable.module.scss'
import TextEditablePanel from '../wordArtInput/TextEditablePanel'
import DropdownHOC from 'components/dropdowns/DropdownHOC'
import Button from 'components/buttons/Button/Button'
import LayersPanel from '../wordArtInput/LayersPanel'
import FloatingLayers from '../wordArtInput/FloatingLayers'

const emptyTextJson = (id: number) => {
  return {
    [id]: {
      id,
      angle: 0,
      show: true,
      text: {
        label: 'Tu texto',
        fontSize: 1,
        fontFamily: 'Arial',
        color: '#000000'
      },
      divSize: {
        width: 30,
        height: 20
      },
      position: {
        left: 10,
        top: 10
      }
    }
  }
}

const EditablePanel = () => {
  const {
    jsonTextBack,
    jsonTextfront,
    notebookFrontValues,
    edithUpload,
    outsidePropuesta,
    outsidePropuestaBack,
    isCoverOnEdith
  } = useSelector((state: RootState) => state.notebooks)

  const dispatch = useDispatch()
  const { cover_design } = notebookFrontValues

  const getEmptyTextBack = () => {
    let value = 1
    for (value; value - 1 < Object.keys(jsonTextBack).length; value++) {
      if (!Object.keys(jsonTextBack).includes(`${value}`)) break
    }
    return emptyTextJson(value)
  }
  const getEmptyTextFront = () => {
    let value = 1
    for (value; value - 1 < Object.keys(jsonTextfront).length; value++) {
      if (!Object.keys(jsonTextfront).includes(`${value}`)) break
    }
    return emptyTextJson(value)
  }

  const addNewTextBack = () => {
    dispatch(setJsonTextBack({ ...jsonTextBack, ...getEmptyTextBack() }))
  }
  const addNewTextfront = () => {
    dispatch(setJsonTextfront({ ...jsonTextfront, ...getEmptyTextFront() }))
  }
  let dropdown: any
  const resetIds = () => {
    dispatch(setIdTextFront(0))
    dispatch(setIdTextBack(0))
    dispatch(setImgIdBack(0))
    dispatch(setImgIdFront(0))
  }

  return (
    <div className={`${style['container-editable-panel']}`}>
      {cover_design.value === 'design' && (
        <Fragment>
          <div
            onMouseOver={resetIds}
            className={`${style['flex-container-buttons']}`}
          >
            <DropdownHOC triggerRef={(el) => (dropdown = el)}>
              {React.createElement(() => (
                <button
                  disabled={!isCoverOnEdith}
                  className={`${style['button-action']}`}
                  onClick={resetIds}
                >
                  <LanguageIcon className={`${style['icon-button-action']}`} />
                  Texto
                </button>
              ))}
              {React.createElement(() => (
                <Fragment>
                  {isCoverOnEdith && (
                    <div className={`${style['son-custom']}`}>
                      <Button
                        label='Trasera'
                        rounded
                        onClick={() => {
                          addNewTextBack()
                          dropdown.click()
                        }}
                      />
                      <Button
                        label='Frontal'
                        rounded
                        onClick={() => {
                          addNewTextfront()
                          dropdown.click()
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              ))}
            </DropdownHOC>

            <button
              onClick={() => {
                dispatch(setShowModalBucket(true))
              }}
              className={`${style['button-action']}`}
              disabled={!isCoverOnEdith}
            >
              <ArrowUpTrayIcon className={`${style['icon-button-action']}`} />
              Img
            </button>

            <div
              className={`${style['cover-on-edith']} ${
                isCoverOnEdith && style['cover-on-edith-color']
              }`}
            >
              <button
                onClick={() => dispatch(setIsCoverOnEdith(!isCoverOnEdith))}
                className={`${style['button-action']}`}
              >
                {isCoverOnEdith ? (
                  <LockOpenIcon className={`${style['icon-button-action']}`} />
                ) : (
                  <LockClosedIcon
                    className={`${style['icon-button-action']}`}
                  />
                )}
                Edith
              </button>
            </div>
          </div>
          {false && <LayersPanel />}
          <TextEditablePanel />
        </Fragment>
      )}
      {cover_design.value === 'upload' &&
        (outsidePropuesta !== '' || outsidePropuestaBack !== '') && (
          <div className={`${style['flex-container-buttons']}`}>
            {outsidePropuestaBack !== '' && (
              <div
                className={`${style['border-selected']} ${
                  edithUpload.left && style['border-color']
                }`}
              >
                <button
                  onClick={() => {
                    dispatch(
                      setEdithUpload({
                        ...edithUpload,
                        left: !edithUpload.left
                      })
                    )
                  }}
                  className={`${style['button-action']}`}
                >
                  <ArrowLeftOnRectangleIcon
                    className={`${style['icon-button-action']}`}
                  />
                  Trasera
                </button>
              </div>
            )}
            {outsidePropuesta !== '' && (
              <div
                className={`${style['border-selected']} ${
                  edithUpload.right && style['border-color']
                }`}
              >
                <button
                  onClick={() => {
                    dispatch(
                      setEdithUpload({
                        ...edithUpload,
                        right: !edithUpload.right
                      })
                    )
                  }}
                  className={`${style['button-action']}`}
                >
                  <ArrowLeftOnRectangleIcon
                    className={`${style['icon-button-action']} ${style['transform-invert']}`}
                  />
                  Frontal
                </button>
              </div>
            )}
            {!edithUpload.left &&
              !edithUpload.right &&
              (outsidePropuesta !== '' || outsidePropuestaBack !== '') && (
                <p>
                  Selecciona un boton para editar el acomodo de tu(s) portada(s)
                  cargada(s).
                </p>
              )}
          </div>
        )}
    </div>
  )
}

export default EditablePanel
