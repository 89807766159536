import React from 'react'

const JumperSvg = ({
  className,
  neckType = 'redondo',
  pretinas = true,
  pinzas = true
}: {
  className: string
  neckType?: string
  pretinas?: boolean
  pinzas?: boolean
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Path' stroke='black'>
        <g id='Path-2' data-name='Path'>
          <path
            id='Espalda'
            d='m332.92,45.02c9.32,4.24,47.16,6.41,80.98,7.09,2.75.05-.28,9.7,2.49,9.73,3.31.04,1.43-9.57,4.72-9.56,32.32.04,69.94-1.61,79.39-4.77'
          />
          <g id='Cierre'>
            <path d='m421.76,54.26c-.28,0-.53-.04-.67-.06-.13-.02-.22-.24-.2-.47.03-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.08-.17-.07-.45.03-.61.1-.16.25-.15.34.03.29.62.21,1.12.14,1.35-.27.91-1.23,1.09-1.93,1.07h0Z' />
            <path d='m420.97,55.56c-.28,0-.53-.04-.67-.07-.13-.02-.23-.22-.21-.46.01-.23.14-.41.27-.39,1.29.21,2.02-.19,2.13-.53.03-.12-.06-.32-.09-.4-.09-.18-.07-.46.02-.61.1-.16.26-.14.34.04.3.61.22,1.1.15,1.35-.28.92-1.23,1.08-1.93,1.07h0Z' />
            <path d='m420.18,56.86c-.28,0-.53-.03-.67-.06-.14-.03-.23-.24-.21-.47.02-.24.14-.42.26-.39,1.3.22,2.03-.18,2.13-.53.03-.11-.06-.31-.09-.39-.09-.17-.08-.45.03-.61.11-.16.26-.14.34.04.3.61.22,1.1.14,1.35-.28.91-1.23,1.08-1.93,1.06h0Z' />
            <path d='m419.74,58.15c-.29,0-.54-.04-.68-.06-.13-.02-.23-.23-.2-.47.01-.23.14-.41.26-.38,1.3.21,2.01-.18,2.13-.54.03-.11-.06-.31-.1-.39-.08-.17-.07-.45.04-.61.1-.16.25-.15.33.03.3.62.23,1.11.14,1.36-.27.9-1.22,1.07-1.92,1.07h0Z' />
            <path d='m419.36,59.48c-.28,0-.53-.04-.67-.07-.13-.03-.23-.23-.21-.46.02-.24.13-.42.27-.4,1.3.22,2.02-.18,2.13-.53.03-.11-.06-.31-.1-.39-.09-.18-.07-.46.02-.61.1-.16.25-.14.34.04.29.61.21,1.11.13,1.35-.26.9-1.22,1.07-1.92,1.07h0Z' />
            <path d='m418.88,60.77c-.28,0-.52-.03-.67-.05-.14-.02-.23-.24-.21-.48.02-.23.14-.41.27-.38,1.3.22,2.02-.18,2.12-.54.04-.11-.05-.3-.09-.39-.09-.17-.07-.44.03-.6.11-.16.26-.14.33.03.3.62.22,1.12.15,1.35-.27.91-1.23,1.08-1.94,1.06h0Z' />
            <path d='m418.53,62.09c-.28,0-.52-.04-.67-.07-.13-.02-.23-.23-.21-.47.02-.24.14-.41.26-.39,1.3.21,2.02-.19,2.13-.53.03-.11-.05-.31-.09-.4-.08-.17-.07-.44.03-.6.1-.16.25-.14.34.03.29.62.22,1.12.13,1.36-.26.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m418.5,63.4c-.28,0-.52-.04-.66-.07-.14-.02-.23-.23-.21-.46.02-.24.13-.42.26-.39,1.3.22,2.03-.18,2.13-.53.03-.11-.05-.31-.1-.4-.08-.17-.07-.45.03-.6s.25-.14.34.03c.29.62.22,1.11.14,1.36-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m418.47,64.7c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.25.13-.42.26-.39,1.29.22,2.02-.19,2.13-.53.04-.11-.05-.31-.09-.4-.09-.18-.08-.44.02-.61.1-.15.25-.14.34.03.28.63.22,1.12.14,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.62,68.63c-.28,0-.52-.04-.66-.07-.13-.02-.23-.23-.21-.46.02-.23.14-.41.27-.39,1.29.21,2.02-.19,2.13-.54.03-.12-.05-.3-.09-.39-.08-.18-.07-.44.03-.6.1-.16.25-.14.33.04.29.6.21,1.11.14,1.35-.27.92-1.22,1.08-1.94,1.07h0Z' />
            <path d='m417.59,69.94c-.28,0-.53-.04-.67-.07-.13-.02-.22-.23-.21-.47.02-.23.14-.4.27-.38,1.29.21,2.02-.19,2.13-.53.03-.11-.05-.32-.09-.39-.08-.18-.08-.46.03-.61.09-.16.25-.14.33.03.29.62.21,1.11.14,1.36-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m417.55,71.25c-.28,0-.52-.04-.66-.06-.13-.03-.22-.23-.21-.47.03-.23.14-.41.27-.38,1.29.21,2.02-.18,2.13-.54.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.02-.6.1-.16.25-.15.34.02.29.62.21,1.12.14,1.36-.28.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.52,72.55c-.28,0-.52-.03-.66-.06-.13-.02-.23-.23-.21-.46.02-.24.14-.42.27-.4,1.29.21,2.02-.18,2.13-.52.03-.12-.05-.32-.09-.4-.09-.18-.08-.45.03-.61.1-.15.25-.15.34.03.29.62.21,1.11.15,1.36-.27.91-1.23,1.07-1.95,1.06h0Z' />
            <path d='m417.5,73.86c-.28,0-.53-.03-.67-.06-.13-.02-.23-.23-.21-.47.02-.24.13-.41.26-.39,1.3.22,2.02-.17,2.14-.52.03-.12-.05-.31-.09-.4-.08-.17-.08-.45.03-.6.1-.16.25-.15.34.03.29.61.21,1.1.14,1.35-.27.92-1.22,1.08-1.93,1.06h0Z' />
            <path d='m417.47,75.18c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.47.02-.24.14-.42.27-.39,1.29.21,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.4-.08-.17-.08-.45.03-.6.1-.16.25-.14.34.04.29.61.21,1.11.14,1.35-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m417.43,76.49c-.28,0-.52-.04-.67-.06-.13-.02-.22-.23-.21-.48.03-.22.15-.41.27-.37,1.29.2,2.02-.2,2.13-.54.03-.11-.05-.31-.09-.4-.09-.17-.08-.43.03-.6.1-.16.25-.14.34.03.29.62.21,1.12.14,1.36-.26.92-1.22,1.07-1.93,1.06h0Z' />
            <path d='m417.41,77.8c-.29,0-.53-.04-.67-.06-.13-.02-.23-.24-.21-.47.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.03-.61.11-.15.25-.14.34.03.29.62.21,1.11.15,1.36-.28.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m417.37,79.11c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.09-.18-.07-.45.03-.6.1-.16.25-.15.34.02.29.62.22,1.12.15,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.34,80.42c-.28-.01-.53-.03-.67-.06-.13-.03-.22-.23-.21-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.12-.54.03-.12-.05-.32-.09-.39-.09-.18-.08-.45.03-.62.1-.15.25-.14.33.04.29.61.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m417.31,81.73c-.28,0-.52-.04-.67-.06-.13-.03-.23-.23-.21-.47.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.17-.08-.44.03-.6.1-.15.25-.14.33.04.28.61.21,1.11.14,1.35-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m417.28,83.04c-.28,0-.52-.04-.67-.06-.12-.02-.23-.23-.21-.47.02-.24.14-.41.27-.38,1.29.21,2.02-.18,2.13-.54.03-.11-.05-.31-.09-.4-.09-.16-.08-.44.03-.6.1-.16.25-.13.34.03.29.62.21,1.11.14,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.25,84.35c-.28,0-.53-.04-.67-.06-.13-.02-.22-.24-.21-.47.02-.24.14-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.4-.09-.18-.08-.45.03-.61.1-.15.25-.14.34.04.29.61.21,1.12.14,1.36-.27.92-1.22,1.07-1.93,1.06h0Z' />
            <path d='m417.22,85.66c-.28,0-.53-.03-.67-.06-.12-.02-.22-.24-.21-.47.02-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.06-.31-.1-.4-.09-.18-.08-.45.03-.61.1-.16.25-.15.34.03.29.61.21,1.11.14,1.36-.27.91-1.22,1.08-1.93,1.06h0Z' />
            <path d='m417.19,86.98c-.28,0-.52-.03-.67-.06-.12-.02-.23-.23-.21-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.13-.53.03-.12-.05-.33-.09-.4-.09-.17-.08-.44.03-.6.1-.16.25-.15.34.03.29.61.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m417.16,88.28c-.28,0-.52-.04-.67-.06-.12-.01-.22-.23-.21-.46.02-.24.14-.41.27-.39,1.29.21,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.08-.17-.08-.45.03-.6.1-.17.25-.15.34.03.29.61.21,1.1.14,1.35-.27.92-1.23,1.08-1.94,1.07h0Z' />
            <path d='m417.13,89.6c-.28,0-.52-.04-.67-.07-.13-.02-.22-.22-.21-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.17-.07-.45.03-.61.1-.16.25-.14.34.04.3.61.22,1.11.15,1.35-.28.92-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.1,90.91c-.28,0-.53-.04-.67-.07-.13-.01-.22-.24-.21-.47.02-.23.14-.4.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.03-.61.1-.16.25-.14.33.03.29.61.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m417.06,92.22c-.28,0-.52-.03-.67-.06-.12-.03-.23-.24-.21-.47.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.4-.09-.18-.08-.44.03-.6.11-.16.25-.15.34.03.29.61.21,1.12.14,1.35-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m417.03,93.53c-.28,0-.52-.04-.67-.07-.12-.02-.23-.23-.21-.47.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.12-.52.03-.12-.05-.31-.08-.4-.09-.17-.08-.44.02-.6.11-.16.25-.15.34.03.29.61.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m417,94.84c-.28,0-.53-.03-.67-.06-.13-.03-.23-.23-.21-.47.02-.25.14-.41.26-.39,1.29.21,2.02-.19,2.13-.53.03-.12-.05-.33-.09-.4-.08-.17-.08-.44.03-.6.1-.15.25-.14.34.04.29.61.22,1.11.14,1.35-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m416.98,96.15c-.28,0-.53-.04-.67-.06-.13-.02-.23-.24-.21-.46.02-.25.14-.42.27-.39,1.29.2,2.02-.19,2.13-.54.03-.12-.06-.32-.09-.4-.09-.17-.08-.45.03-.61.1-.15.25-.13.34.04.29.61.22,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.94,97.46c-.28,0-.52-.03-.67-.06-.12-.02-.22-.23-.21-.47.02-.23.15-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.38-.09-.18-.08-.45.03-.61.1-.15.25-.14.34.04.29.61.21,1.11.14,1.35-.27.92-1.22,1.08-1.93,1.06h0Z' />
            <path d='m416.91,98.77c-.28,0-.52-.03-.67-.06-.12-.03-.22-.24-.21-.47.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.03-.61.1-.15.25-.15.34.04.29.61.21,1.12.14,1.36-.27.9-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.88,100.09c-.28,0-.52-.04-.67-.07-.12,0-.23-.23-.2-.47.02-.23.14-.41.26-.39,1.29.21,2.02-.18,2.13-.53.03-.12-.04-.31-.08-.39-.09-.18-.08-.45.02-.6.1-.16.25-.15.33.03.29.61.22,1.11.14,1.36-.27.9-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.85,101.4c-.28,0-.53-.04-.67-.06-.13-.02-.23-.23-.21-.47.02-.24.14-.41.27-.38,1.29.2,2.02-.19,2.12-.53.03-.12-.05-.32-.09-.41-.09-.17-.08-.45.03-.6.1-.16.25-.15.33.03.28.61.21,1.11.14,1.36-.27.91-1.22,1.09-1.94,1.07h0Z' />
            <path d='m416.82,102.71c-.28,0-.53-.04-.67-.06-.13-.02-.22-.22-.21-.47.02-.22.14-.41.27-.38,1.28.21,2.02-.19,2.12-.54.03-.11-.05-.32-.09-.4-.09-.17-.08-.45.03-.6.1-.16.25-.14.33.02.29.62.21,1.12.14,1.36-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m416.79,104.01c-.28,0-.52-.03-.67-.06-.13-.01-.23-.22-.21-.46.03-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.08-.18-.08-.45.03-.61.11-.16.25-.13.34.04.29.61.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.06h0Z' />
            <path d='m416.76,105.33c-.28-.01-.53-.05-.67-.07-.13-.02-.23-.23-.21-.46.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.08-.17-.07-.45.03-.61.1-.15.25-.13.34.04.29.61.21,1.12.14,1.36-.27.9-1.23,1.07-1.94,1.07h0Z' />
            <path d='m416.73,106.64c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.24.14-.42.26-.4,1.29.21,2.03-.18,2.13-.53.03-.12-.05-.31-.09-.39-.08-.18-.08-.45.03-.61.1-.15.25-.15.34.03.29.62.21,1.11.14,1.36-.27.9-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.7,107.94c-.28,0-.52-.03-.67-.06-.13-.03-.22-.23-.21-.46.03-.24.14-.42.27-.39,1.29.21,2.02-.18,2.14-.54.02-.11-.06-.32-.1-.4-.08-.17-.07-.44.03-.6.1-.15.25-.14.34.04.29.61.21,1.1.14,1.35-.27.91-1.22,1.08-1.93,1.06h0Z' />
            <path d='m416.66,109.26c-.28,0-.52-.04-.67-.06-.13-.02-.22-.23-.21-.47.02-.24.14-.41.27-.39,1.29.21,2.02-.18,2.13-.54.03-.11-.05-.31-.09-.39-.09-.17-.08-.45.02-.61.1-.15.25-.14.34.03.29.62.21,1.12.14,1.36-.27.91-1.22,1.09-1.94,1.07h0Z' />
            <path d='m416.63,110.57c-.28,0-.52-.04-.66-.06-.13-.02-.23-.23-.21-.47.02-.24.14-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.12-.05-.32-.09-.4-.09-.18-.08-.45.03-.61.1-.15.25-.13.33.04.29.61.21,1.11.15,1.36-.28.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.6,111.88c-.28,0-.52-.04-.67-.06-.13-.02-.22-.24-.21-.47.02-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.09-.18-.08-.45.03-.61.1-.16.25-.14.34.04.29.61.21,1.1.14,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.57,113.19c-.28,0-.52-.03-.67-.06-.12-.02-.22-.23-.21-.47.02-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.08-.18-.08-.45.03-.6.1-.17.25-.15.33.02.29.62.21,1.11.14,1.35-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.54,114.5c-.28,0-.52-.04-.66-.06-.13-.03-.23-.24-.21-.47.02-.24.14-.41.27-.39,1.29.21,2.02-.19,2.13-.53.03-.11-.05-.31-.09-.4-.09-.17-.08-.44.03-.59.1-.17.25-.15.33.03.29.61.21,1.1.14,1.35-.27.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m416.51,115.81c-.29,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.18,2.13-.54.04-.11-.05-.31-.09-.39-.08-.17-.08-.45.03-.61.1-.15.25-.14.34.04.29.61.21,1.1.14,1.35-.27.92-1.22,1.08-1.94,1.06h0Z' />
            <path d='m416.48,117.13c-.29,0-.53-.05-.67-.06-.13-.02-.22-.23-.21-.48.02-.24.14-.4.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.39-.08-.18-.07-.44.03-.61.1-.16.25-.14.33.03.29.61.21,1.11.14,1.36-.27.9-1.22,1.07-1.93,1.07h0Z' />
            <path d='m416.45,118.43c-.28,0-.52-.03-.67-.06-.12-.02-.22-.23-.21-.47.02-.23.15-.41.27-.39,1.29.21,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.08-.17-.08-.44.02-.6.1-.16.25-.14.34.03.29.61.21,1.11.14,1.36-.26.91-1.22,1.06-1.93,1.06h0Z' />
            <path d='m416.42,119.74c-.29,0-.53-.03-.67-.06-.12-.02-.23-.23-.21-.46.02-.24.14-.41.27-.39,1.28.22,2.01-.18,2.12-.53.03-.11-.05-.31-.09-.4-.09-.17-.08-.44.03-.61.1-.16.25-.14.33.03.29.62.21,1.12.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.39,121.05c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.23.14-.41.27-.39,1.29.22,2.02-.19,2.13-.53.03-.12-.05-.31-.09-.4-.09-.17-.08-.44.03-.61.1-.16.25-.14.33.04.29.61.21,1.11.15,1.35-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.36,122.37c-.28-.01-.53-.04-.67-.05-.13-.03-.22-.24-.21-.48.02-.24.14-.41.27-.39,1.29.21,2.02-.19,2.12-.53.03-.13-.04-.31-.09-.4-.08-.17-.07-.44.03-.6.1-.16.25-.15.33.02.29.62.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.06h0Z' />
            <path d='m416.33,123.68c-.28,0-.53-.04-.67-.06-.12-.03-.22-.23-.2-.48.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.12-.05-.32-.09-.39-.08-.18-.08-.45.03-.6.1-.16.25-.14.33.03.29.61.21,1.1.14,1.35-.27.92-1.22,1.08-1.94,1.07h0Z' />
            <path d='m416.29,124.98c-.28,0-.52-.04-.67-.06-.12-.02-.22-.24-.21-.47.03-.24.14-.41.27-.39,1.29.21,2.02-.19,2.13-.53.03-.11-.05-.32-.09-.39-.09-.17-.08-.45.03-.61.1-.16.25-.15.33.03.29.62.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.06h0Z' />
            <path d='m416.27,126.3c-.29,0-.52-.04-.67-.06-.12-.02-.22-.24-.2-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.13-.53.03-.12-.05-.32-.09-.39-.08-.18-.08-.45.03-.61.1-.16.25-.15.34.03.29.61.21,1.11.14,1.36-.27.9-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.24,127.61c-.28,0-.52-.04-.67-.07-.13-.02-.23-.24-.21-.46.02-.24.14-.42.26-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.31-.09-.39-.08-.18-.08-.45.03-.62.1-.15.25-.14.33.04.29.61.22,1.11.14,1.35-.27.92-1.22,1.08-1.93,1.07h0Z' />
            <path d='m416.21,128.91c-.28,0-.52-.03-.67-.06-.13-.03-.23-.23-.21-.46.02-.23.14-.41.27-.39,1.29.21,2.02-.19,2.13-.54.03-.12-.06-.31-.09-.39-.08-.18-.08-.44.03-.6.1-.16.25-.14.34.04.29.61.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m416.17,130.23c-.28-.01-.52-.04-.67-.06-.13-.03-.22-.23-.21-.47.03-.24.14-.41.27-.38,1.29.2,2.02-.2,2.13-.54.03-.11-.05-.31-.09-.39-.08-.17-.08-.45.03-.61.1-.15.25-.13.34.04.29.62.21,1.12.14,1.36-.27.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m416.14,131.54c-.28,0-.52-.04-.66-.06-.13-.02-.23-.23-.21-.47.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.02-.61.11-.16.26-.15.34.03.29.62.21,1.11.14,1.36-.28.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m416.11,132.85c-.28,0-.52-.03-.66-.06-.13-.02-.23-.23-.21-.47.02-.23.14-.41.26-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.08-.39-.09-.18-.08-.45.03-.61.1-.16.25-.15.33.03.29.62.22,1.11.15,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m416.08,134.17c-.28,0-.53-.04-.67-.07-.13-.02-.23-.22-.21-.46.02-.23.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.09-.17-.08-.45.03-.6.1-.16.25-.15.33.03.29.62.21,1.12.15,1.35-.27.92-1.23,1.08-1.94,1.07h0Z' />
            <path d='m416.05,135.48c-.28,0-.52-.04-.66-.07-.13-.03-.23-.23-.21-.47.02-.23.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.31-.09-.4-.09-.17-.08-.44.03-.6.1-.16.25-.14.33.04.29.62.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m416.02,136.79c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.23.15-.41.27-.4,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.09-.17-.08-.45.03-.6.1-.16.25-.14.34.03.29.61.21,1.11.14,1.35-.27.92-1.22,1.07-1.94,1.07h0Z' />
            <path d='m415.99,138.1c-.29,0-.53-.05-.67-.06-.13-.02-.23-.23-.21-.47.02-.23.14-.4.26-.39,1.3.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.08-.17-.08-.44.03-.6.1-.16.25-.14.33.03.29.62.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.07h0Z' />
            <path d='m415.96,139.4c-.28,0-.53-.03-.67-.05-.13-.02-.22-.24-.21-.47.03-.23.14-.4.27-.39,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.39-.09-.17-.08-.45.03-.61.1-.16.25-.14.33.03.29.62.21,1.12.14,1.35-.27.91-1.22,1.08-1.93,1.06h0Z' />
            <path d='m415.93,140.72c-.28,0-.52-.03-.67-.07-.13-.01-.22-.22-.21-.46.02-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.31-.09-.39-.08-.17-.08-.46.03-.61.1-.16.25-.14.33.04.29.61.21,1.11.14,1.35-.27.91-1.23,1.08-1.94,1.07h0Z' />
            <path d='m415.9,142.03c-.28-.01-.52-.04-.67-.06-.13-.03-.22-.23-.2-.47.02-.23.14-.41.26-.38,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.32-.09-.4-.09-.17-.08-.45.02-.61.11-.16.25-.14.34.04.29.61.22,1.12.14,1.36-.27.9-1.22,1.08-1.94,1.06h0Z' />
            <path d='m415.87,143.34c-.28,0-.52-.04-.67-.06-.12-.02-.22-.23-.2-.46.02-.25.13-.42.27-.4,1.29.21,2.02-.19,2.13-.53.03-.11-.05-.31-.09-.4-.09-.17-.08-.45.02-.6.1-.16.26-.14.34.02.29.62.21,1.12.14,1.37-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m415.84,144.64c-.28,0-.52-.04-.67-.05-.12-.02-.22-.24-.21-.47.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.32-.09-.4-.09-.17-.08-.45.03-.6.1-.16.25-.14.34.03.29.62.21,1.12.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m415.8,145.96c-.28,0-.52-.04-.67-.06-.13-.02-.23-.24-.21-.46.02-.24.14-.43.27-.4,1.29.21,2.02-.18,2.13-.53.03-.11-.06-.31-.09-.39-.09-.17-.08-.45.02-.61.1-.16.25-.15.33.03.29.61.21,1.1.14,1.36-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m415.77,147.27c-.28,0-.52-.03-.67-.06-.13-.02-.23-.23-.21-.46.02-.24.14-.42.27-.4,1.29.22,2.02-.18,2.13-.53.03-.12-.05-.31-.09-.39-.08-.18-.08-.46.03-.61.1-.16.25-.14.34.04.29.61.21,1.11.14,1.35-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m415.74,148.59c-.28,0-.52-.04-.67-.07-.13-.02-.23-.23-.21-.46.02-.24.13-.42.26-.39,1.29.21,2.02-.18,2.13-.53.03-.12-.05-.32-.1-.39-.08-.18-.08-.45.03-.62.11-.16.25-.14.34.04.29.62.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.07h0Z' />
            <path d='m415.72,149.89c-.29,0-.53-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.25.14-.41.26-.39,1.29.21,2.03-.19,2.13-.54.03-.11-.05-.31-.09-.39-.08-.18-.08-.45.03-.6.1-.16.25-.15.34.02.29.62.21,1.12.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m415.68,151.21c-.28,0-.52-.04-.67-.06-.13-.02-.22-.23-.21-.47.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.39-.08-.18-.08-.46.03-.62.1-.16.25-.13.34.04.29.62.21,1.11.14,1.36-.26.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m415.65,152.51c-.28,0-.52-.04-.67-.07-.13-.01-.23-.22-.21-.46.02-.24.14-.41.26-.39,1.3.21,2.02-.18,2.13-.54.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.02-.61.11-.16.25-.14.34.04.29.61.21,1.12.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m415.62,153.83c-.28,0-.52-.04-.67-.06-.13-.02-.23-.23-.21-.46.02-.24.14-.42.27-.39,1.29.22,2.02-.18,2.13-.53.03-.12-.04-.31-.09-.4-.09-.18-.08-.45.03-.61.1-.15.25-.14.33.04.29.61.21,1.11.14,1.36-.27.91-1.22,1.08-1.94,1.06h0Z' />
            <path d='m415.59,155.14c-.28-.01-.52-.04-.67-.06-.13-.03-.23-.24-.21-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.4-.09-.17-.08-.45.03-.61.1-.15.25-.14.33.03.29.62.21,1.11.14,1.36-.27.92-1.22,1.07-1.94,1.06h0Z' />
            <path d='m415.56,156.45c-.29,0-.53-.04-.67-.06-.13-.03-.23-.23-.21-.47.02-.24.15-.41.27-.38,1.29.21,2.02-.19,2.13-.54.03-.11-.05-.31-.09-.39-.09-.18-.08-.44.03-.6.1-.16.25-.14.33.03.29.61.21,1.11.14,1.35-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m415.53,157.76c-.28,0-.52-.04-.67-.07-.12-.02-.23-.23-.2-.47.02-.23.14-.4.27-.39,1.29.21,2.02-.17,2.13-.53.03-.11-.05-.31-.09-.39-.09-.18-.08-.45.03-.61.1-.16.25-.14.34.03.29.62.21,1.12.14,1.36-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m415.5,159.06c-.28,0-.52-.03-.67-.06-.13-.02-.23-.23-.21-.47.02-.24.14-.41.27-.39,1.29.21,2.02-.18,2.13-.53.03-.12-.05-.31-.09-.4-.08-.18-.08-.45.03-.61.1-.16.25-.14.33.03.29.61.21,1.11.14,1.36-.27.91-1.22,1.07-1.93,1.06h0Z' />
            <path d='m415.47,160.38c-.28,0-.52-.03-.67-.07-.13-.02-.23-.22-.21-.46.02-.24.14-.41.27-.39,1.29.22,2.02-.18,2.13-.53.03-.11-.05-.31-.09-.4-.08-.17-.08-.45.03-.6.1-.16.25-.14.34.03.29.62.21,1.11.14,1.35-.27.91-1.22,1.07-1.94,1.06h0Z' />
            <path d='m415.44,161.69c-.28,0-.53-.04-.67-.06-.13-.03-.23-.23-.21-.46.02-.24.14-.42.27-.39,1.29.21,2.03-.18,2.13-.53.03-.11-.05-.31-.09-.4-.08-.17-.08-.45.03-.61.1-.16.25-.14.34.04.28.61.21,1.11.13,1.35-.27.91-1.22,1.08-1.93,1.07h0Z' />
            <path d='m415.4,163c-.28,0-.52-.03-.67-.06-.12-.02-.22-.23-.2-.47.02-.23.14-.41.27-.38,1.29.21,2.02-.19,2.13-.53.02-.11-.05-.32-.09-.4-.09-.18-.08-.44.02-.6.1-.16.26-.14.34.04.29.6.21,1.11.14,1.34-.27.92-1.22,1.08-1.94,1.07h0Z' />
            <g>
              <path d='m413.19,54.13c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.12-.73.2-1.35.1-.17.25-.18.34-.02.1.16.1.43,0,.6-.05.08-.13.28-.11.4.08.35.8.77,2.1.6.13-.03.24.16.25.4,0,.23-.09.44-.23.46-.15.01-.39.05-.67.04h0Z' />
              <path d='m413.67,55.45c-.71-.02-1.66-.21-1.88-1.12-.06-.24-.12-.74.2-1.34.09-.18.24-.19.34-.02.09.15.09.43,0,.61-.04.08-.14.27-.1.38.08.37.8.78,2.09.61.13-.02.24.16.25.39,0,.24-.1.44-.23.47-.15.01-.39.04-.67.04h0Z' />
              <path d='m414.3,56.77c-.71-.02-1.66-.2-1.88-1.13-.06-.24-.12-.74.2-1.34.09-.17.25-.19.34-.03.1.16.1.44,0,.61-.04.08-.14.28-.1.39.09.35.79.77,2.09.6.12-.02.24.16.25.41,0,.23-.1.43-.24.46-.14,0-.39.05-.67.04h0Z' />
              <path d='m414.73,58.11c-.72,0-1.66-.21-1.89-1.12-.06-.25-.11-.74.2-1.35.1-.18.25-.18.34-.02.09.16.09.43,0,.6-.04.08-.14.27-.11.39.09.36.8.77,2.09.61.14-.02.26.15.26.39.01.24-.1.45-.22.47-.15,0-.39.04-.66.04h0Z' />
              <path d='m415,59.41c-.7,0-1.65-.22-1.88-1.12-.06-.25-.11-.75.21-1.35.1-.18.25-.19.34-.03.09.16.09.43,0,.61-.04.08-.13.28-.11.39.08.35.8.77,2.1.6.12-.02.24.16.24.4.01.23-.1.44-.22.46-.15.01-.39.05-.68.04h0Z' />
              <path d='m415.26,60.73c-.71,0-1.65-.2-1.88-1.12-.06-.25-.12-.74.2-1.35.09-.17.25-.18.34-.02.09.16.09.44,0,.61-.04.08-.13.28-.11.39.1.35.8.78,2.1.6.13-.02.25.16.25.4,0,.24-.09.44-.23.46-.15.02-.39.04-.67.04h0Z' />
              <path d='m415.7,62.04c-.71-.01-1.65-.21-1.88-1.12-.06-.25-.12-.75.21-1.35.09-.16.25-.18.34-.02.09.17.09.43,0,.61-.04.08-.14.28-.1.39.09.35.79.77,2.09.6.13-.01.25.16.25.4,0,.23-.1.44-.23.46-.15.02-.39.04-.68.04h0Z' />
              <path d='m415.67,63.35c-.72-.02-1.65-.21-1.88-1.13-.06-.24-.12-.74.2-1.35.09-.17.25-.18.34-.02.1.15.1.43,0,.6-.04.09-.14.27-.1.39.08.35.79.78,2.09.6.13-.02.24.16.25.4,0,.24-.1.44-.23.46-.15.02-.39.05-.68.05h0Z' />
              <path d='m416.21,72.54c-.71-.02-1.65-.21-1.87-1.12-.07-.25-.12-.75.2-1.35.1-.18.24-.18.34-.02.09.16.09.44,0,.6-.04.08-.13.28-.1.4.09.35.8.77,2.1.6.13-.01.25.15.25.4,0,.23-.1.44-.23.46-.14.03-.39.04-.67.04h0Z' />
              <path d='m416.18,73.84c-.71-.01-1.65-.2-1.88-1.12-.06-.24-.11-.74.2-1.35.1-.17.25-.19.35-.02.09.16.09.43,0,.61-.04.08-.13.27-.11.39.09.35.81.77,2.1.59.13,0,.25.16.25.39,0,.24-.1.44-.23.46-.14.02-.39.04-.67.04h0Z' />
              <path d='m416.15,75.16c-.7-.01-1.65-.21-1.87-1.13-.06-.24-.11-.75.2-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.61-.04.09-.13.27-.11.39.09.35.8.77,2.1.6.13-.02.25.16.25.4,0,.23-.1.44-.23.46-.14.01-.4.04-.68.04h0Z' />
              <path d='m416.12,76.46c-.7,0-1.65-.2-1.87-1.12-.06-.24-.12-.74.2-1.34.1-.18.24-.19.34-.02.09.15.09.43,0,.6-.03.08-.13.28-.1.4.09.35.8.78,2.09.61.14-.03.25.16.25.39,0,.24-.1.44-.23.46-.14.02-.39.04-.68.03h0Z' />
              <path d='m416.09,77.78c-.71,0-1.65-.21-1.88-1.13-.06-.25-.12-.74.21-1.34.1-.17.24-.18.34-.02.08.16.08.43,0,.6-.03.08-.13.28-.11.39.09.35.8.78,2.1.6.13-.01.25.16.25.4,0,.23-.1.44-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m416.06,79.09c-.71-.01-1.65-.21-1.87-1.12-.06-.25-.12-.75.2-1.36.1-.17.23-.18.34-.02.09.16.09.43,0,.6-.04.08-.13.28-.11.4.09.35.8.77,2.1.6.13-.03.25.16.25.39.01.24-.1.44-.22.46-.15.03-.4.05-.68.05h0Z' />
              <path d='m416.03,80.4c-.71-.01-1.65-.21-1.88-1.13-.07-.24-.12-.74.21-1.35.09-.17.24-.18.34-.02.1.17.09.44,0,.61-.04.08-.13.27-.1.39.09.35.8.77,2.09.6.14-.02.25.15.25.39,0,.23-.1.44-.23.46-.15.01-.39.04-.68.04h0Z' />
              <path d='m416,81.71c-.7-.01-1.65-.2-1.88-1.13-.06-.24-.12-.75.21-1.35.09-.17.24-.18.34-.03.1.17.09.44,0,.61-.04.07-.13.27-.1.39.09.35.8.78,2.09.6.13-.02.25.16.25.4,0,.24-.1.44-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m415.97,83.02c-.71,0-1.66-.2-1.88-1.11-.07-.25-.12-.75.2-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.6-.04.08-.14.28-.11.39.09.36.8.78,2.1.6.13-.03.25.14.25.39,0,.24-.09.44-.22.46-.14.02-.4.05-.67.04h0Z' />
              <path d='m415.94,84.33c-.71,0-1.65-.2-1.88-1.12-.06-.25-.12-.75.21-1.35.09-.17.24-.19.34-.03.09.16.09.44,0,.61-.04.08-.13.27-.11.39.09.35.8.77,2.1.6.14-.02.25.16.25.4.01.23-.09.44-.22.46-.14.02-.4.05-.67.04h0Z' />
              <path d='m415.9,85.65c-.71,0-1.65-.21-1.88-1.12-.07-.25-.12-.75.2-1.35.09-.17.24-.18.34-.02.1.16.09.43,0,.6-.03.08-.13.28-.11.4.09.35.8.77,2.1.59.13-.02.25.16.25.4.01.23-.09.44-.23.46-.14.02-.39.05-.67.04h0Z' />
              <path d='m415.88,86.96c-.71-.01-1.65-.21-1.88-1.12-.06-.25-.12-.74.21-1.35.09-.17.24-.18.34-.02.1.15.09.42,0,.6-.03.08-.13.28-.1.4.09.35.8.77,2.09.6.13-.02.25.15.25.39.01.23-.1.45-.22.46-.15.03-.39.04-.67.04h0Z' />
              <path d='m415.84,88.27c-.7-.01-1.65-.21-1.88-1.13-.06-.24-.12-.74.2-1.35.1-.17.24-.18.35-.02.09.16.08.43,0,.61-.03.08-.13.27-.11.39.09.35.8.77,2.1.6.13-.02.25.15.25.39,0,.24-.1.46-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m415.81,89.57c-.71,0-1.65-.2-1.88-1.12-.06-.24-.12-.75.21-1.35.1-.17.24-.19.34-.02.09.16.09.43,0,.6-.04.08-.13.27-.1.39.09.36.8.78,2.1.6.13-.02.24.16.24.39.01.25-.09.45-.22.46-.15.02-.4.04-.68.04h0Z' />
              <path d='m415.78,90.89c-.71,0-1.65-.21-1.88-1.12-.06-.25-.12-.75.2-1.35.1-.17.24-.19.34-.02.09.16.09.43,0,.6-.04.08-.13.28-.1.39.08.36.8.77,2.09.6.14-.01.25.17.25.4.01.23-.1.45-.23.46-.14.02-.39.06-.67.05h0Z' />
              <path d='m415.75,92.2c-.71,0-1.65-.2-1.88-1.12-.07-.23-.12-.73.21-1.34.1-.18.24-.19.34-.02.1.16.09.42,0,.59-.04.08-.14.28-.11.4.09.35.8.77,2.1.6.13-.01.25.16.25.39,0,.24-.1.44-.23.47-.14.02-.39.04-.67.03h0Z' />
              <path d='m415.72,93.51c-.71-.02-1.66-.21-1.88-1.13-.06-.24-.12-.74.21-1.35.1-.17.24-.19.34-.02.09.16.09.43,0,.6-.04.09-.13.28-.11.39.09.36.8.77,2.1.6.13-.02.24.16.25.39,0,.24-.1.45-.23.47-.15.02-.39.04-.67.04h0Z' />
              <path d='m415.69,94.82c-.7-.01-1.65-.21-1.87-1.13-.07-.24-.12-.74.2-1.35.1-.17.25-.18.35-.02.09.16.09.44,0,.61-.04.08-.13.27-.11.39.09.35.8.77,2.1.6.14-.02.25.15.25.39,0,.23-.1.44-.23.46-.14.01-.4.04-.68.04h0Z' />
              <path d='m415.66,96.13c-.7,0-1.65-.21-1.88-1.12-.06-.25-.11-.75.2-1.35.1-.17.24-.18.34-.03.09.16.09.44,0,.61-.04.07-.13.28-.11.39.09.36.8.78,2.1.61.13-.03.25.15.25.39,0,.23-.1.44-.23.46-.14.02-.39.05-.68.04h0Z' />
              <path d='m415.63,97.44c-.71,0-1.65-.2-1.88-1.12-.06-.24-.12-.75.21-1.35.09-.18.24-.19.34-.02.09.16.09.44,0,.6-.04.07-.13.28-.11.38.09.37.8.78,2.09.61.14-.02.25.16.25.4.01.23-.1.43-.23.46-.15.02-.39.04-.67.04h0Z' />
              <path d='m415.6,98.75c-.71,0-1.65-.21-1.88-1.12-.06-.25-.12-.75.2-1.35.1-.18.25-.18.34-.02.09.16.09.44,0,.61-.04.08-.13.27-.11.39.1.36.8.77,2.1.6.14-.02.24.15.25.39.01.23-.1.44-.22.47-.15.02-.4.04-.68.04h0Z' />
              <path d='m415.57,100.07c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.12-.74.2-1.35.1-.17.24-.18.34-.02.09.16.09.43,0,.61-.04.08-.13.27-.1.4.09.35.8.77,2.1.59.13-.02.24.16.24.39.01.24-.09.45-.22.46-.15.02-.4.05-.68.05h0Z' />
              <path d='m415.53,101.38c-.71-.01-1.65-.22-1.88-1.13-.06-.24-.12-.74.2-1.34.1-.17.24-.18.34-.02.09.16.09.42,0,.6-.04.08-.13.27-.1.39.08.36.8.78,2.09.6.13-.03.25.15.25.39,0,.23-.1.44-.22.47-.15,0-.4.05-.68.04h0Z' />
              <path d='m415.51,102.69c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.11-.74.21-1.35.09-.17.24-.18.34-.02.09.16.09.43,0,.6-.04.08-.13.29-.11.4.09.35.8.78,2.1.61.13-.02.25.16.25.39,0,.25-.1.44-.23.46-.14.02-.4.05-.67.05h0Z' />
              <path d='m415.47,103.99c-.71,0-1.65-.2-1.88-1.12-.07-.24-.12-.75.21-1.35.09-.17.24-.18.34-.02.09.16.09.44,0,.6-.04.08-.13.29-.11.4.09.36.8.77,2.09.59.14-.01.25.16.25.4,0,.23-.1.43-.22.45-.15.03-.4.06-.68.05h0Z' />
              <path d='m415.45,105.31c-.71,0-1.65-.21-1.88-1.12-.07-.25-.12-.75.2-1.35.1-.17.24-.19.34-.03.09.16.09.45,0,.61-.04.08-.13.28-.11.39.09.36.8.77,2.1.6.14-.01.25.16.25.39,0,.23-.1.45-.23.46-.14.02-.4.05-.67.05h0Z' />
              <path d='m415.41,106.61c-.71,0-1.65-.2-1.88-1.12-.06-.25-.11-.74.21-1.35.09-.18.24-.18.34-.02.09.17.09.43,0,.61-.04.08-.13.27-.11.39.09.35.8.77,2.1.6.13-.02.24.16.24.4,0,.23-.1.44-.23.46-.14.02-.39.05-.67.04h0Z' />
              <path d='m415.38,107.92c-.71-.01-1.65-.2-1.88-1.12-.06-.25-.12-.74.21-1.34.1-.18.24-.18.34-.02.09.16.09.43,0,.6-.04.08-.13.28-.11.39.09.35.8.78,2.1.6.13-.03.24.16.25.39.01.24-.1.44-.23.47-.15,0-.4.04-.68.03h0Z' />
              <path d='m415.35,109.24c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.12-.74.21-1.35.1-.16.23-.18.34-.02.09.17.09.44,0,.61-.04.08-.13.28-.11.39.09.36.8.78,2.09.6.14-.02.25.16.25.39,0,.24-.1.45-.22.46-.14.02-.4.05-.68.05h0Z' />
              <path d='m415.32,110.55c-.71,0-1.65-.2-1.88-1.12-.06-.25-.12-.74.2-1.35.1-.17.24-.19.34-.02.09.16.08.44-.01.61-.04.08-.13.27-.11.39.09.36.8.78,2.1.61.13-.03.25.14.25.39,0,.24-.09.44-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m415.29,111.86c-.71,0-1.65-.21-1.87-1.12-.06-.25-.12-.75.2-1.35.1-.18.24-.19.34-.03.09.17.09.44,0,.62-.04.07-.13.26-.1.39.08.35.8.77,2.09.6.14-.01.25.15.25.4.01.24-.1.44-.23.46-.14.02-.39.05-.67.04h0Z' />
              <path d='m415.26,113.17c-.7-.01-1.65-.2-1.88-1.11-.06-.25-.11-.75.21-1.35.09-.17.24-.19.34-.02.09.16.09.44,0,.61-.04.08-.13.27-.1.39.08.35.8.77,2.09.6.14-.01.25.16.26.39,0,.23-.1.44-.23.46-.15.01-.39.04-.67.04h0Z' />
              <path d='m415.23,114.48c-.7-.02-1.65-.21-1.87-1.13-.06-.24-.12-.74.2-1.34.1-.17.24-.18.35-.02.09.16.08.43,0,.6-.04.09-.13.28-.11.4.08.35.8.78,2.1.6.13-.02.25.16.25.39,0,.24-.11.44-.23.47-.14.01-.4.04-.68.03h0Z' />
              <path d='m415.2,115.79c-.71-.01-1.65-.21-1.88-1.12-.07-.25-.12-.75.2-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.61-.04.08-.13.28-.11.39.09.36.8.77,2.1.6.14-.01.25.17.25.4,0,.23-.1.44-.22.46-.14.02-.4.05-.67.04h0Z' />
              <path d='m415.17,117.1c-.7,0-1.65-.21-1.88-1.12-.06-.25-.12-.74.21-1.35.09-.17.24-.18.34-.01.09.16.08.43,0,.6-.04.08-.14.28-.11.39.09.36.8.78,2.1.61.14-.02.25.14.25.39,0,.24-.1.44-.23.46-.14.02-.39.05-.68.04h0Z' />
              <path d='m415.13,118.41c-.71-.01-1.65-.21-1.88-1.12-.06-.24-.12-.74.2-1.34.09-.18.24-.19.34-.02.09.16.09.43,0,.6-.04.08-.13.28-.1.39.09.35.8.77,2.09.59.14-.01.25.17.25.41.01.24-.1.44-.23.45-.14.02-.39.04-.67.04h0Z' />
              <path d='m415.11,119.73c-.71-.02-1.65-.21-1.88-1.12-.06-.24-.11-.75.21-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.61-.03.08-.13.27-.1.39.09.34.8.77,2.09.6.13-.01.25.16.25.39,0,.24-.1.44-.22.47-.15.02-.4.04-.68.04h0Z' />
              <path d='m415.08,121.04c-.71-.01-1.65-.21-1.88-1.12-.06-.24-.11-.74.21-1.35.1-.18.24-.19.34-.03.09.17.09.44,0,.61-.04.08-.13.28-.11.39.09.35.8.78,2.1.6.14-.02.25.16.25.39.01.23-.1.44-.22.46-.15.02-.4.05-.68.05h0Z' />
              <path d='m415.04,122.34c-.71,0-1.65-.2-1.87-1.12-.07-.25-.12-.74.2-1.36.1-.17.24-.17.34-.01.09.16.09.43,0,.6-.04.09-.13.29-.11.4.09.35.8.77,2.09.6.14-.02.25.15.25.39.01.24-.09.44-.22.47-.15,0-.39.03-.67.03h0Z' />
              <path d='m415.01,123.65c-.7,0-1.65-.2-1.88-1.12-.06-.25-.12-.74.21-1.34.09-.18.24-.18.34-.02.1.16.09.43,0,.6-.04.08-.13.28-.11.39.09.36.8.78,2.09.6.14-.02.25.17.25.4.01.24-.1.44-.22.46-.15.02-.4.04-.68.03h0Z' />
              <path d='m414.98,124.96c-.71,0-1.65-.2-1.88-1.12-.06-.25-.12-.74.21-1.35.1-.18.24-.18.34-.02.1.16.09.44,0,.61-.04.08-.13.28-.1.39.09.35.8.77,2.09.6.13-.01.25.16.26.4,0,.24-.1.44-.23.46-.15.02-.4.04-.67.03h0Z' />
              <path d='m414.95,126.28c-.71-.01-1.65-.21-1.88-1.12-.06-.25-.12-.75.21-1.35.1-.17.24-.18.34-.02.09.17.09.44,0,.61-.04.07-.13.27-.11.39.09.34.8.77,2.09.59.14-.02.25.16.25.39,0,.24-.1.45-.22.47-.15.02-.4.04-.67.04h0Z' />
              <path d='m414.92,127.59c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.11-.74.2-1.35.1-.18.24-.18.35-.03.09.17.09.44,0,.62-.04.08-.13.27-.11.39.09.35.8.78,2.1.6.13-.02.25.15.25.4,0,.23-.09.44-.23.45-.14.02-.4.05-.68.05h0Z' />
              <path d='m414.89,128.89c-.71,0-1.65-.2-1.88-1.11-.06-.24-.12-.74.2-1.36.09-.17.24-.18.34-.02.09.16.09.43,0,.61-.04.08-.13.28-.11.39.08.36.8.78,2.1.61.13-.03.24.16.24.39,0,.24-.09.44-.22.46-.15.02-.39.04-.68.03h0Z' />
              <path d='m414.86,130.2c-.71,0-1.65-.2-1.88-1.12-.07-.24-.12-.74.21-1.35.1-.17.24-.18.34-.02.09.16.08.44,0,.61-.04.08-.13.28-.11.4.09.35.8.77,2.09.6.13-.02.25.16.25.4.01.23-.1.43-.22.46-.15.02-.4.04-.68.03h0Z' />
              <path d='m414.83,131.52c-.71-.01-1.65-.2-1.88-1.12-.06-.25-.11-.75.2-1.35.1-.18.24-.18.34-.02.09.16.09.43,0,.61-.04.08-.13.27-.1.39.09.36.8.78,2.09.59.13-.01.24.18.25.41,0,.24-.1.44-.23.46-.15.02-.4.04-.67.04h0Z' />
              <path d='m414.8,132.83c-.71-.01-1.65-.21-1.87-1.12-.06-.25-.12-.75.2-1.35.1-.18.24-.19.34-.02.09.16.09.43,0,.6-.04.08-.13.27-.11.39.09.35.8.77,2.1.6.13-.01.24.16.24.39,0,.24-.1.44-.23.46-.14.02-.39.05-.68.04h0Z' />
              <path d='m414.76,134.15c-.7-.02-1.65-.21-1.88-1.13-.06-.24-.12-.74.2-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.6-.04.08-.13.28-.11.39.09.35.8.78,2.1.6.13-.02.25.17.25.4,0,.24-.1.45-.23.46-.14.03-.39.05-.68.05h0Z' />
              <path d='m414.74,135.46c-.71-.01-1.65-.21-1.88-1.13-.07-.24-.12-.74.2-1.35.1-.17.24-.18.34-.02.09.17.09.44,0,.6-.04.09-.13.29-.11.4.09.36.8.77,2.1.6.13-.02.24.16.25.4,0,.24-.1.44-.23.46-.15.02-.4.04-.67.04h0Z' />
              <path d='m414.71,136.77c-.71-.01-1.65-.2-1.87-1.13-.07-.24-.12-.74.2-1.35.1-.17.25-.19.34-.02.09.16.08.44,0,.6-.04.08-.13.29-.11.39.09.35.8.78,2.09.59.14-.01.25.17.25.41,0,.24-.1.44-.23.46-.14.02-.4.05-.67.05h0Z' />
              <path d='m414.68,138.07c-.71,0-1.65-.2-1.88-1.12-.06-.24-.12-.75.2-1.35.09-.18.24-.19.34-.02.09.16.09.43,0,.6-.03.08-.13.28-.11.39.09.36.8.78,2.1.6.13-.02.25.16.25.4,0,.23-.09.43-.22.46-.14.02-.4.05-.68.04h0Z' />
              <path d='m414.64,139.38c-.71,0-1.65-.2-1.88-1.12-.06-.25-.12-.75.21-1.35.1-.17.24-.18.34-.02.09.16.09.42,0,.6-.04.08-.13.28-.11.39.09.36.8.77,2.1.6.13-.02.24.16.24.39,0,.24-.1.44-.23.46-.14.02-.39.04-.67.03h0Z' />
              <path d='m414.61,140.7c-.71-.02-1.65-.21-1.88-1.13-.06-.24-.11-.74.21-1.35.1-.17.24-.19.34-.02.09.16.09.44,0,.61-.03.08-.13.27-.11.39.09.36.8.78,2.09.6.14-.02.25.16.25.4,0,.23-.1.44-.22.45-.15.03-.39.05-.68.05h0Z' />
              <path d='m414.58,142.01c-.71-.01-1.65-.21-1.88-1.12-.06-.24-.12-.75.2-1.35.1-.17.24-.19.34-.03.09.16.09.43,0,.61-.03.07-.13.27-.11.39.1.35.8.78,2.1.6.13-.02.25.16.25.39,0,.24-.09.44-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m414.55,143.33c-.71-.02-1.65-.21-1.87-1.13-.06-.24-.12-.74.2-1.35.1-.18.24-.18.34-.02.09.16.09.44,0,.61-.04.09-.13.28-.11.4.09.35.8.77,2.1.6.13-.02.25.16.24.4.01.23-.09.44-.22.45-.15.02-.39.05-.68.05h0Z' />
              <path d='m414.52,144.62c-.7,0-1.65-.2-1.88-1.11-.06-.24-.12-.74.21-1.35.1-.17.24-.18.34-.02.1.16.09.43,0,.6-.04.08-.13.27-.11.39.09.35.81.78,2.09.6.14-.02.25.17.26.41.01.23-.1.43-.23.46-.14.02-.39.04-.67.03h0Z' />
              <path d='m414.49,145.94c-.7,0-1.65-.21-1.88-1.12-.06-.26-.11-.75.21-1.35.1-.18.24-.19.34-.02.1.16.09.43,0,.6-.04.08-.13.28-.1.39.09.36.8.77,2.09.6.13-.02.24.16.25.41,0,.22-.1.43-.23.45-.14.02-.4.04-.68.04h0Z' />
              <path d='m414.46,147.25c-.71-.01-1.66-.21-1.88-1.13-.06-.24-.12-.74.21-1.34.1-.18.24-.19.34-.02.09.16.09.42,0,.61-.04.08-.13.27-.11.39.09.35.8.77,2.1.6.13-.02.24.16.25.4,0,.24-.1.44-.23.46-.15.02-.4.04-.67.04h0Z' />
              <path d='m414.43,148.57c-.71-.01-1.65-.22-1.88-1.13-.06-.24-.12-.74.2-1.35.1-.18.24-.18.34-.02.09.17.09.43,0,.62-.03.08-.13.27-.11.39.09.35.81.77,2.1.6.13-.03.24.16.24.39,0,.24-.1.45-.22.46-.14.02-.39.05-.67.05h0Z' />
              <path d='m414.4,149.88c-.71-.01-1.65-.21-1.88-1.12-.06-.24-.12-.74.2-1.35.1-.17.24-.18.34-.02.09.16.09.44,0,.61-.04.08-.13.28-.1.39.09.36.8.77,2.09.6.13-.02.25.16.25.4,0,.23-.1.44-.23.46-.14.02-.39.05-.68.04h0Z' />
              <path d='m414.36,151.19c-.7-.01-1.65-.21-1.88-1.12-.06-.25-.11-.74.21-1.35.1-.18.24-.2.34-.03.1.16.09.43,0,.61-.04.08-.13.28-.1.39.08.35.8.78,2.09.6.13-.02.25.17.26.41,0,.24-.1.44-.23.46-.14.02-.39.05-.68.04h0Z' />
              <path d='m414.34,152.49c-.7-.01-1.65-.21-1.88-1.12-.06-.25-.11-.75.2-1.35.1-.18.25-.19.35-.03.09.16.08.43,0,.61-.04.09-.14.28-.11.4.09.35.8.77,2.1.6.13-.01.24.16.25.4,0,.24-.1.44-.22.45-.15.02-.4.05-.68.05h0Z' />
              <path d='m414.31,153.81c-.71,0-1.65-.21-1.87-1.12-.06-.25-.12-.75.2-1.36.1-.17.24-.18.34-.02.09.16.09.43,0,.61-.04.08-.13.28-.11.39.08.36.8.78,2.1.6.13-.02.25.16.25.4,0,.23-.1.44-.22.46-.15.02-.39.05-.68.04h0Z' />
              <path d='m414.27,155.12c-.71-.02-1.65-.2-1.87-1.12-.07-.24-.12-.75.2-1.35.1-.17.24-.18.34-.02.09.16.09.43,0,.61-.03.09-.13.28-.11.39.09.36.8.77,2.09.6.14-.02.25.16.25.39,0,.24-.1.44-.23.47-.14.02-.4.04-.68.04h0Z' />
              <path d='m414.25,156.43c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.11-.75.2-1.35.09-.17.24-.18.34-.03.1.16.08.44,0,.61-.04.08-.14.27-.1.39.09.35.8.77,2.09.6.13-.03.25.15.25.39,0,.24-.1.44-.23.46-.15.02-.4.04-.68.04h0Z' />
              <path d='m414.21,157.74c-.71,0-1.65-.2-1.88-1.12-.06-.24-.12-.74.2-1.35.1-.17.25-.19.34-.02.1.15.09.42,0,.6-.04.08-.13.27-.1.39.09.36.8.78,2.1.59.13-.01.24.17.25.4,0,.24-.1.44-.23.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m414.19,159.05c-.71,0-1.65-.2-1.88-1.12-.06-.25-.11-.76.21-1.35.1-.17.24-.18.34-.02.09.16.09.43,0,.6-.04.08-.13.28-.11.4.09.35.8.77,2.1.59.14-.01.25.16.25.41,0,.23-.1.43-.23.45-.14.02-.4.04-.67.04h0Z' />
              <path d='m414.15,160.36c-.71,0-1.65-.21-1.88-1.12-.06-.25-.12-.75.2-1.36.1-.17.24-.18.34-.02.09.16.09.43,0,.6-.03.09-.13.28-.1.4.08.35.8.77,2.09.59.13,0,.25.16.25.4,0,.23-.1.44-.23.46-.14.02-.39.04-.67.03h0Z' />
              <path d='m414.12,161.67c-.71-.01-1.65-.21-1.88-1.13-.06-.24-.11-.74.21-1.34.1-.18.24-.19.34-.03.1.16.09.44,0,.61-.03.09-.13.28-.1.4.09.35.8.77,2.09.59.13-.02.25.16.25.4.01.24-.1.44-.23.46-.14.02-.39.05-.67.04h0Z' />
              <path d='m414.09,162.98c-.7-.01-1.65-.2-1.88-1.12-.06-.24-.11-.75.21-1.35.1-.18.24-.19.34-.02.1.16.09.43,0,.6-.03.08-.13.28-.11.39.1.35.8.78,2.1.6.14-.02.25.16.25.39.01.24-.1.44-.22.46-.15.02-.4.05-.68.04h0Z' />
              <path d='m417.78,61.33c-.28,0-.53-.02-.67-.05-.12-.02-.22-.16-.21-.32.02-.16.14-.28.27-.26,1.29.15,2.02-.11,2.13-.35.02-.07-.05-.21-.1-.26-.08-.12-.08-.31.03-.41.1-.1.25-.1.34.03.29.42.21.76.15.92-.27.61-1.22.72-1.93.7h0Z' />
              <path d='m417.75,62.21c-.28,0-.53-.02-.67-.04-.12-.02-.22-.16-.21-.32.02-.16.13-.28.26-.27,1.29.16,2.02-.11,2.13-.34.03-.08-.06-.22-.09-.27-.09-.12-.08-.3.02-.41.1-.11.25-.1.34.03.29.42.22.75.15.91-.27.62-1.21.72-1.93.7h0Z' />
              <path d='m415.73,60.41c-.71-.02-1.66-.15-1.89-.77-.06-.17-.12-.5.2-.92.1-.12.24-.12.33-.01.1.11.1.29,0,.41-.04.06-.13.19-.1.27.08.23.8.52,2.1.42.13-.01.24.11.25.27,0,.16-.09.3-.23.31-.15.01-.39.03-.67.02h0Z' />
              <path d='m416.46,61.31c-.71-.02-1.65-.15-1.89-.77-.06-.17-.12-.51.2-.91.09-.12.24-.12.34-.02.09.11.09.29,0,.41-.03.05-.13.19-.1.26.1.24.81.53,2.1.42.13,0,.25.11.25.27.01.17-.1.3-.22.31-.15.02-.39.03-.67.02h0Z' />
              <path d='m416.44,62.19c-.71-.01-1.65-.15-1.89-.77-.06-.16-.12-.5.2-.91.09-.12.24-.13.34-.02.09.12.09.3,0,.42-.03.06-.12.19-.1.27.09.24.8.53,2.1.41.13-.01.25.11.25.27.01.16-.09.3-.22.31-.15,0-.4.03-.67.02h0Z' />
              <path d='m416.42,63.08c-.71-.02-1.64-.15-1.88-.78-.06-.16-.12-.5.19-.91.1-.12.24-.12.34-.02.1.11.09.29,0,.4-.03.06-.13.19-.1.27.09.24.8.53,2.1.42.13-.01.25.11.25.27.01.16-.09.3-.22.31-.15.01-.4.03-.68.03h0Z' />
              <path d='m416.39,63.97c-.7-.01-1.65-.15-1.88-.77-.07-.17-.12-.5.2-.91.09-.12.24-.12.33-.01.1.11.09.29,0,.41-.04.05-.13.18-.1.27.09.24.81.53,2.1.42.13-.01.25.11.25.27.01.16-.1.3-.22.31-.15.01-.4.03-.68.02h0Z' />
              <path d='m416.38,64.86c-.71-.01-1.66-.15-1.89-.77-.07-.17-.12-.5.2-.91.09-.12.24-.12.34,0,.09.1.09.29,0,.4-.04.06-.13.19-.1.27.09.24.8.53,2.1.42.13-.01.25.11.25.27,0,.16-.1.29-.22.31-.15.01-.4.03-.67.02h0Z' />
            </g>
          </g>
          <g id='Cierre-2' data-name='Cierre'>
            <path d='m419.34,64.03v-.33s.02-.66.04-1.32c.02-.67.37-.85.78-1.17.19-.16.28-.41.31-.62l.03-.2c.04-1.62-1.51-2.96-3.46-2.99-1.86-.04-3.42,1.16-3.57,2.68,0,0-.1.69.28,1.02.39.33.74.53.72,1.2-.02.65-.03,1.3-.03,1.3v.35s-.14.4.45.41c.59.01,3.98.07,3.98.07,0,0,.48-.04.49-.4Z' />
            <g>
              <path d='m419.65,60.08l-2.33-.04h-.73s-2.28-.05-2.28-.05c0,0-.3-.05-.31.28,0,.33.61.55.59,1.52-.02.98-.08,3.26-.08,3.26l-.03,1.06s.15,1.27.29,2.14c.17,1.2.8,4.11,1.37,4.12h1.03c.57.03,1.35-2.86,1.59-4.05.17-.86.38-2.13.38-2.13l.03-1.06s.06-2.28.08-3.25c.02-.97.66-1.17.67-1.51.01-.33-.28-.29-.28-.29Z' />
              <path d='m419.65,60.08l-2.33-.04h-.73s-2.28-.05-2.28-.05c0,0-.3-.05-.31.28,0,.33.61.55.59,1.52-.02.98-.08,3.26-.08,3.26l-.03,1.06s.15,1.27.29,2.14c.17,1.2.8,4.11,1.37,4.12h1.03c.57.03,1.35-2.86,1.59-4.05.17-.86.38-2.13.38-2.13l.03-1.06s.06-2.28.08-3.25c.02-.97.66-1.17.67-1.51.01-.33-.28-.29-.28-.29Z' />
            </g>
            <path d='m415.97,63.82c-.01.3.24.56.56.56l.69.02c.32,0,.57-.24.58-.55l.14-5.89c.01-.3-.23-.55-.55-.56h-.69c-.32-.02-.58.23-.59.53l-.14,5.89Z' />
            <g>
              <path d='m419.65,60.08l-2.33-.04h-.73s-2.28-.05-2.28-.05c0,0-.3-.05-.31.28,0,.33.61.55.59,1.52-.02.98-.08,3.26-.08,3.26l-.03,1.06s.15,1.27.29,2.14c.17,1.2.8,4.11,1.37,4.12h1.03c.57.03,1.35-2.86,1.59-4.05.17-.86.38-2.13.38-2.13l.03-1.06s.06-2.28.08-3.25c.02-.97.66-1.17.67-1.51.01-.33-.28-.29-.28-.29Zm-2.45,11.2s-.03.07-.08.07l-.89-.02s-.07-.03-.07-.07l-.35-1.36s.02-.07.06-.07l1.73.03s.06.04.06.08l-.46,1.34Zm1.06-6.86s.03.46-.55.44c-.12,0-.23,0-.33,0l-1.22-.02c-.06,0-.12,0-.18,0-.59-.01-.54-.46-.54-.46,0,0,.08-3.5.09-3.76,0-.25.52-.18.52-.18h1.83s.48-.03.48.23c0,.26-.09,3.75-.09,3.75Z' />
              <path d='m419.65,60.08l-2.33-.04h-.73s-2.28-.05-2.28-.05c0,0-.3-.05-.31.28,0,.33.61.55.59,1.52-.02.98-.08,3.26-.08,3.26l-.03,1.06s.15,1.27.29,2.14c.17,1.2.8,4.11,1.37,4.12h1.03c.57.03,1.35-2.86,1.59-4.05.17-.86.38-2.13.38-2.13l.03-1.06s.06-2.28.08-3.25c.02-.97.66-1.17.67-1.51.01-.33-.28-.29-.28-.29Zm-2.45,11.2s-.03.07-.08.07l-.89-.02s-.07-.03-.07-.07l-.35-1.36s.02-.07.06-.07l1.73.03s.06.04.06.08l-.46,1.34Zm1.06-6.86s.03.46-.55.44c-.12,0-.23,0-.33,0l-1.22-.02c-.06,0-.12,0-.18,0-.59-.01-.54-.46-.54-.46,0,0,.08-3.5.09-3.76,0-.25.52-.18.52-.18h1.83s.48-.03.48.23c0,.26-.09,3.75-.09,3.75Z' />
            </g>
            <path d='m414.99,65.28c0,.15,0,.4,0,.4v.44s.14,1.28.27,2.14c.17,1.2.68,3.71,1.24,3.72h.14s.06,0,.06,0h.14c.57.01,1.19-2.47,1.44-3.66.16-.86.38-2.12.38-2.12v-.45s.02-.25.02-.39c0,0,.02-.16-.25-.17-.28,0-3.21-.05-3.21-.05,0,0-.23.01-.23.16Z' />
          </g>
          <g id='Tablones'>
            <path
              id='Contorno_falda'
              data-name='Contorno falda'
              d='m201.49,727.75l47.41-284.56c107.51,28.82,220.6,27.31,316.35,4.81,0,0,24.73,275.46,33.23,282.8,1.85,1.59-74.03,12.85-74.03,12.85l.52,2.23-67.02,6.11-.15,4.01c-28.7-.75-117.74-2.02-117.74-2.02l-.04-3.52s-49.54-7.72-66.02-9.57v-1.53s-70.22-11.19-72.53-11.61Z'
            />
            <g id='Tablones-2' data-name='Tablones'>
              <line x1='274.01' y1='739.36' x2='301' y2='457.06' />
              <line x1='340.07' y1='753.98' x2='363.5' y2='462.5' />
              <line x1='524.98' y1='745.88' x2='512.35' y2='460.15' />
              <line x1='457.81' y1='756' x2='449.28' y2='464.98' />
            </g>
            <line x1='207.21' y1='699.64' x2='276.51' y2='713.21' />
            <line x1='276.39' y1='714.46' x2='342.23' y2='725.35' />
            <line x1='342.24' y1='726.79' x2='457' y2='728.35' />
            <line x1='457.81' y1='727.19' x2='523.87' y2='720.85' />
            <line x1='524.98' y1='717.49' x2='593.26' y2='708.5' />
          </g>
          {(() => {
            switch (pinzas) {
              case true:
                return (
                  <g id='Pinzas'>
                    <path
                      id='Contorno_falda_pinzas'
                      data-name='Contorno falda pinzas'
                      d='m201.37,728.15l47.14-284.93c106.9,28.66,227.63,30.69,316.54,4.78,0,0,24.59,275.89,33.04,283.19,1.84,1.58-47.98,12.8-47.98,12.8l-50.21,4.49-9.49.63c-28.53-.75-165.38,0-165.38,0l-8.11-2.06-54.35-7.32s-58.91-11.15-61.21-11.57Z'
                    />
                    <line x1='206.5' y1='699.5' x2='277.83' y2='713.86' />
                    <line x1='277.71' y1='715.1' x2='316.35' y2='721.49' />
                    <line x1='316.83' y1='724.9' x2='486.67' y2='726.77' />
                    <line x1='493.39' y1='724.9' x2='593.5' y2='708.5' />
                    <polyline points='486.67 726.77 493.39 724.9 486.67 726.77' />
                    <line x1='332.07' y1='725.84' x2='325.38' y2='727.03' />
                    <g id='Pinza'>
                      <image
                        width='88'
                        height='286'
                        transform='translate(279 499)'
                        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAEeCAYAAAAO49LxAAAACXBIWXMAAAsSAAALEgHS3X78AAACS0lEQVR4nO3dQYrCQBBA0WLuf+fMTtQxjpuP6eS9jS7Dp5BO0eAMAAAAAAAAAAAAAAAAPNq+/QDPfr79AGd3psCHm96ZcwU+JIFjAscE5iPb0+dhmOCYwDGBYwLHBOYj2xzwBHEWhz2izfiJyAkcEzgmcEzgmMC8te18PwwTHBM4JnBM4JjAMYHZ9Xwsc0y7IoFjAscEjgkcEzgmcExgXnr1UuFF44oEjgkcEzgmcEzgmMAxgflj74XCi8YVCRwTOCZwTOCYwDGBYwLHBI4JHBOYB+/2DXYRVyRwTGAeLLULnjHBOYFjAscEjgkcEzgmcEzgmMAxgblZblU5Y4JzAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwNwsdy9txgTnBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTmJtl/v3lngmOCczMLHonYsYE5wSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmMDOz6KWTGROcE5h1L53MmOCcwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgm8MUt+TeT90xwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAl/Yf9sy2zQEzgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwBf1ya7XPhiBcwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwOxa4mYPAAAAAAAAAAAAAAAAHN4vMWQoXO7bgoAAAAAASUVORK5CYII='
                      />
                    </g>
                    <g id='Pinza-2' data-name='Pinza'>
                      <image
                        width='88'
                        height='284'
                        transform='translate(447 502)'
                        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAEcCAYAAABDK3P6AAAACXBIWXMAAAsSAAALEgHS3X78AAACS0lEQVR4nO3bQU7DQBQFwRHi/lc2+4hAQDT2+FdtyNJqPZnEmawFAAAAAAAAAAAAAAAA/Mpx9gX8lbezL4D/dzz83ZoFxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4IGOJ6+3ZMExgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCBHg9db30I24JjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgm8ECfHVfd9girBQ91m0PYFhwTOCZwTOCYwDGBYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCTzUbc6nWXBM4JjAMYFjAscEjgkcEzgmcEzgmMAxgWMCxwSOCRwTOCZwTOCYwDGBYwLHBI4JHBM4JvBQz07xbHe6x4JjAscEjgkcEzgmcEzgmMCD3eJnBBYcEzgmcEzgmMAxgWMCxwSOCRwTOCbwYLf44tOCYwLHBI4JHBM4JnBM4JjAMYFjAscEjgkcEzgmcEzgwb567rvNM2ELjgk83PZfG1lwTOCYwDGBYwLHBI4JPNz2H5ctOCZwTOCYwDGBYwLHBGb/Z8JXt/WHDbeImMAxgWMCxwTm23cKl34nYcExgWMCxwRmrbXxPzoLjgnMWuu1W8AlbxMWzFrr9XVebsXvZ1/AD1wu3p2ICwAAAAAAAAAAAAAAMNgHxSgoWgaqIF4AAAAASUVORK5CYII='
                      />
                    </g>
                    <line
                      id='Pinza-3'
                      data-name='Pinza'
                      x1='330.07'
                      y1='548.59'
                      x2='331.5'
                      y2='460'
                    />
                    <line
                      id='Pinza-4'
                      data-name='Pinza'
                      x1='484.45'
                      y1='541'
                      x2='482.5'
                      y2='461.5'
                    />
                  </g>
                )
              case false:
                return <></>
              default:
                return <></>
            }
          })()}

          {(() => {
            switch (neckType) {
              case 'V':
                return (
                  <g id='Cuello_v' data-name='Cuello v'>
                    <path
                      id='Cuello_V'
                      data-name='Cuello V'
                      d='m500.44,47.12l45.53,19.14c-15.68,61.98-40.52,143.33,6.49,179.53,0,0-7.19,31.32-7.25,91.71-.06,60.06,20.04,110.49,20.04,110.49-107.18,25.75-211.14,21.31-316.35-4.81,0,0,23.56-58.58,24.66-107.81,1.24-55.5-1.64-94.38-1.64-94.38,40.79-11.1,37.34-110.46,14.21-179.68l46.57-17.32,77.82,116.86c1.59,2.36,5.05,2.42,6.72.11l83.19-113.85Z'
                    />
                    <path
                      id='Cuello_V-2'
                      data-name='Cuello V'
                      d='m330.59,45.02l78.18,118.33c2.17,3.21,6.86,3.29,9.14.16l84.46-115.58'
                    />
                  </g>
                )
              case 'redondo':
                return (
                  <g id='Cuello_redondo' data-name='Cuello redondo'>
                    <path
                      id='Cuello_redondo-2'
                      data-name='Cuello redondo'
                      d='m248.9,443.19s23.56-58.58,24.66-107.81c1.24-55.5-1.64-94.38-1.64-94.38,40.79-11.1,37.34-110.46,14.21-179.68l46.57-17.32s13.12,83.95,81.17,82.42c75.55-1.7,74.11-63.56,86.56-79.3l45.53,19.14c-15.68,61.98-40.52,143.33,6.49,179.53,0,0-7.19,31.32-7.25,91.71-.06,60.06,20.04,110.49,20.04,110.49-107.18,25.75-211.14,21.31-316.35-4.81Z'
                    />
                    <path
                      id='Costura'
                      d='m329.26,45.84c2.68,11.97,21.84,86.29,84.49,84.59,75.82-2.06,77.62-63.94,89.86-81.83'
                    />
                  </g>
                )
              default:
                return (
                  <g id='Cuello_redondo' data-name='Cuello redondo'>
                    <path
                      id='Cuello_redondo-2'
                      data-name='Cuello redondo'
                      d='m248.9,443.19s23.56-58.58,24.66-107.81c1.24-55.5-1.64-94.38-1.64-94.38,40.79-11.1,37.34-110.46,14.21-179.68l46.57-17.32s13.12,83.95,81.17,82.42c75.55-1.7,74.11-63.56,86.56-79.3l45.53,19.14c-15.68,61.98-40.52,143.33,6.49,179.53,0,0-7.19,31.32-7.25,91.71-.06,60.06,20.04,110.49,20.04,110.49-107.18,25.75-211.14,21.31-316.35-4.81Z'
                    />
                    <path
                      id='Costura'
                      d='m329.26,45.84c2.68,11.97,21.84,86.29,84.49,84.59,75.82-2.06,77.62-63.94,89.86-81.83'
                    />
                  </g>
                )
            }
          })()}

          {(() => {
            switch (pretinas) {
              case true:
                return (
                  <g id='Pretina'>
                    <path
                      id='Pretina-2'
                      data-name='Pretina'
                      d='m554.75,414.07c-11.96,3.81-23.8,6.94-36.24,8.7-5.66.8-11.35,1.49-17.04,2.06-3.39.34-6.79.63-10.19.88-1.72.12-9.12-.5-10.19.59-1.29,1.32-13.26,16-16.15,20.45.73,1,15.51,12.56,21.99,16.4,18.89-2.23,27.85-3.42,32.24-4.16,7.57-1.37,18.56-2.71,25.82-4.99,1.54-.48,20.21-5.53,20.21-5.53-2.82-10.32-7.95-21.64-10.46-34.4Z'
                    />
                    <g id='Boton'>
                      <polyline points='495.03 444.4 494.54 441.02 496.8 444.39 496.28 440.34 498.54 443.44 499.21 441.7 500.05 442.89 500.39 442.03 501.45 442.61 501.94 441.89 503.12 442.07' />
                      <g>
                        <path d='m484.5,454.25c-.2,0-.4,0-.6-.01-3.17-.16-6.07-1.46-7.96-3.59-1.48-1.67-2.21-3.73-2.07-5.8.33-4.7,5-8.39,10.63-8.39.2,0,.4,0,.61.01,3.17.15,6.06,1.46,7.96,3.59,1.48,1.67,2.22,3.73,2.08,5.81-.33,4.7-5,8.39-10.64,8.39Z' />
                        <path d='m484.5,436.7c.2,0,.4,0,.6.01,5.74.28,10.12,4.37,9.8,9.13-.32,4.61-4.92,8.16-10.39,8.16-.2,0-.39,0-.59-.01-5.74-.28-10.12-4.36-9.79-9.13.33-4.6,4.91-8.15,10.38-8.16m0-.5v.5-.5h0c-5.76,0-10.54,3.79-10.88,8.62-.15,2.14.61,4.27,2.13,5.99,1.93,2.18,4.9,3.52,8.13,3.68.21,0,.41.01.62.01,5.77,0,10.56-3.79,10.89-8.62.15-2.14-.61-4.27-2.14-5.99-1.94-2.18-4.9-3.51-8.13-3.67-.21,0-.42-.01-.62-.01h0Z' />
                      </g>
                      <g>
                        <path d='m477.41,444.81c.06-.08.13-.21.15-.35.04-.21-.03-.34-.17-.37-.12-.02-.21.05-.32.25-.12.24-.26.38-.45.35-.21-.03-.32-.26-.27-.57.03-.16.08-.27.13-.34l.13.08s-.09.15-.12.29c-.04.21.05.31.14.33.12.02.2-.06.31-.27.13-.25.27-.37.46-.34.2.03.35.24.28.61-.03.15-.1.31-.16.39l-.13-.07Z' />
                        <path d='m477.57,442.76l-.23.58.45.17.26-.64.14.05-.34.83-1.26-.47.32-.8.14.05-.25.61.4.15.23-.58.13.05Z' />
                        <path d='m478.56,441.33c-.16-.1-.35-.22-.48-.32h0c.09.14.19.29.29.47l.36.61-.09.13-.67-.16c-.2-.05-.38-.1-.53-.15h0c.13.11.31.25.46.38l.44.37-.12.16-1.01-.87.16-.21.68.16c.17.04.32.08.46.13h0c-.09-.12-.17-.25-.26-.41l-.35-.61.16-.21,1.14.7-.12.16-.5-.31Z' />
                        <path d='m478.54,440.32c.07-.08.16-.17.29-.28.16-.13.3-.2.42-.21.11,0,.21.03.29.12.08.09.11.19.09.3-.02.14-.13.3-.28.43-.05.04-.09.08-.13.1l.34.41-.16.13-.85-1.01Zm.57.36s.09-.06.14-.1c.19-.16.24-.34.12-.48-.11-.13-.28-.1-.46.04-.07.06-.12.11-.14.14l.33.4Z' />
                        <path d='m480.72,439.61l-.54.32.24.42.6-.35.07.12-.78.46-.67-1.16.75-.44.07.12-.57.34.21.37.54-.32.07.12Z' />
                        <path d='m480.82,438.79c.09-.06.23-.12.36-.17.21-.08.35-.1.47-.07.1.03.17.09.21.19.06.17-.02.32-.16.43h0c.13,0,.23.05.31.17.12.16.2.26.25.3l-.2.08s-.11-.12-.21-.26c-.11-.15-.22-.19-.39-.12l-.18.07.21.54-.19.08-.47-1.23Zm.41.49l.2-.08c.2-.08.3-.23.24-.37-.06-.16-.23-.17-.43-.1-.09.04-.16.07-.18.09l.17.46Z' />
                        <path d='m484.58,438.86l-.18.42h-.21s.58-1.34.58-1.34h.25s.5,1.36.5,1.36h-.22s-.16-.43-.16-.43h-.57Zm.53-.13l-.14-.39c-.03-.09-.05-.17-.08-.25h0c-.03.08-.05.16-.09.24l-.17.39h.48Z' />
                        <path d='m485.92,438l.2.03-.21,1.18.67.11-.03.14-.87-.14.24-1.33Z' />
                        <path d='m487.17,438.38l-.46-.12.04-.14,1.13.31-.04.14-.47-.13-.34,1.16-.2-.05.34-1.16Z' />
                        <path d='m488.33,438.62l-.51,1.25-.19-.07.51-1.25.19.07Z' />
                        <path d='m488.95,438.88l-.4.7c-.15.26-.08.45.11.55.21.11.4.07.55-.19l.4-.7.18.1-.39.69c-.21.36-.51.39-.81.23-.28-.15-.42-.41-.21-.78l.39-.7.18.1Z' />
                        <path d='m489.47,440.52c.04.09.12.21.22.3.16.13.32.14.4.03.08-.1.06-.21-.06-.4-.15-.22-.2-.41-.08-.56.14-.16.39-.16.63.04.12.1.19.2.23.27l-.14.08c-.02-.05-.08-.15-.19-.24-.17-.14-.3-.1-.36-.03-.08.1-.05.21.08.4.15.24.18.41.06.56-.13.16-.39.19-.68-.05-.12-.1-.22-.23-.26-.32l.13-.08Z' />
                      </g>
                      <path d='m484.56,440.6c-2.94.06-5.27,2.24-5.19,4.85.08,2.6,2.55,4.66,5.5,4.59,2.96-.05,5.28-2.23,5.2-4.85-.08-2.61-2.54-4.67-5.51-4.6Zm-3.29,3.2c-.02-.59.55-1.08,1.26-1.1.72-.02,1.31.45,1.32,1.05.02.59-.53,1.09-1.25,1.1-.72.02-1.31-.45-1.33-1.05Zm1.95,4.72c-.72.01-1.32-.45-1.33-1.05-.02-.6.55-1.09,1.26-1.11.71-.02,1.31.44,1.33,1.05.02.59-.54,1.09-1.26,1.11Zm2.16-4.72c-.02-.6.55-1.09,1.27-1.1.72-.02,1.32.45,1.33,1.05.02.59-.55,1.08-1.26,1.1-.72.02-1.32-.45-1.34-1.05Zm1.88,4.24c-.72.02-1.32-.45-1.34-1.06-.01-.59.56-1.09,1.28-1.1.71-.02,1.31.46,1.33,1.06.02.59-.55,1.09-1.27,1.1Z' />
                    </g>
                    <path
                      id='Pretina-3'
                      data-name='Pretina'
                      d='m261.02,409.04c2.21.78,18.29,5.6,23.34,7.5,0,0,23.03,5.19,49.66,7.24,1.23,1.36,12.46,16.44,15.12,20.99-.79.98-16.13,12.02-22.79,15.64-18.76-2.87-27.66-4.38-32.01-5.26-7.49-1.62-18.41-3.34-25.55-5.87-1.51-.54-19.92-6.22-19.92-6.22,3.34-10.21,9.01-21.35,12.15-34.01Z'
                    />
                    <g id='Boton-2' data-name='Boton'>
                      <polyline points='316.42 441.62 317.09 438.15 314.6 441.55 315.34 437.39 312.86 440.51 312.27 438.69 311.34 439.88 311.04 438.99 309.92 439.55 309.45 438.78 308.23 438.93' />
                      <g>
                        <path d='m327.19,452.14c-5.94,0-10.86-4.05-10.96-9.02-.04-2.15.83-4.26,2.45-5.92,2.05-2.11,5.08-3.35,8.32-3.39h.17c5.94,0,10.86,4.05,10.96,9.02.04,2.15-.82,4.25-2.43,5.92-2.05,2.12-5.09,3.36-8.34,3.4h-.17Z' />
                        <path d='m327.17,434.05c5.83,0,10.61,3.89,10.71,8.78.1,4.92-4.62,8.98-10.52,9.06-.06,0-.11,0-.17,0-5.83,0-10.61-3.9-10.71-8.78-.09-4.93,4.63-8.98,10.52-9.06.05,0,.11,0,.16,0m0-.5h-.17c-3.31.05-6.41,1.31-8.5,3.47-1.66,1.72-2.56,3.88-2.52,6.1.1,5.11,5.13,9.27,11.21,9.27h.17c3.32-.05,6.42-1.31,8.51-3.48,1.66-1.72,2.55-3.88,2.5-6.1-.11-5.11-5.14-9.27-11.21-9.27h0Z' />
                      </g>
                      <g>
                        <path d='m321.76,447.57c-.03-.1-.11-.22-.21-.31-.15-.14-.31-.15-.4-.05-.09.09-.07.2.04.4.14.23.18.42.05.56-.14.16-.4.13-.62-.07-.12-.11-.18-.21-.21-.28l.14-.07c.02.05.07.15.18.25.16.15.29.12.36.05.09-.09.06-.2-.06-.4-.14-.24-.16-.42-.03-.56.14-.15.4-.17.67.09.11.1.21.24.24.33l-.14.07Z' />
                        <path d='m320.1,446.39l.38.48.38-.3-.42-.54.12-.09.55.7-1.06.83-.53-.67.12-.09.4.51.34-.26-.38-.48.11-.09Z' />
                        <path d='m319.33,444.84c-.17.09-.37.2-.52.26h0c.17,0,.35-.02.55-.02l.71-.02.06.14-.48.5c-.14.15-.28.28-.4.39h0c.16-.06.37-.14.56-.21l.55-.2.08.18-1.28.46-.11-.24.49-.52c.12-.13.24-.24.35-.33h0c-.14.01-.3.02-.48.03h-.71s-.11-.23-.11-.23l1.19-.65.08.18-.53.28Z' />
                        <path d='m318.45,444.37c-.03-.09-.06-.22-.08-.38-.03-.2-.01-.35.04-.46.05-.1.14-.16.26-.18.12-.02.22,0,.3.08.11.09.19.26.22.46,0,.06.02.12.01.17l.53-.08.03.2-1.31.21Zm.61-.3s0-.1-.01-.17c-.04-.25-.16-.38-.34-.35-.18.03-.24.19-.2.41.01.09.03.15.04.19l.52-.08Z' />
                        <path d='m319.04,442.16l-.04.62.49.02.04-.69h.15s-.05.9-.05.9l-1.35-.07.05-.85h.15s-.04.66-.04.66l.43.02.04-.62h.14Z' />
                        <path d='m318.43,441.57c.01-.1.04-.25.08-.39.06-.21.13-.34.23-.41.08-.06.18-.08.28-.05.17.05.25.21.25.38h0c.07-.11.18-.15.33-.15.2,0,.33,0,.39-.02l-.06.2s-.17.02-.34.02c-.19,0-.28.06-.34.24l-.05.19.56.16-.06.2-1.28-.35Zm.65-.03l.06-.2c.06-.21,0-.37-.15-.41-.17-.05-.28.08-.34.28-.03.1-.04.17-.04.2l.47.13Z' />
                        <path d='m320.95,438.73l.18.42-.15.15-.55-1.33.18-.17,1.33.58-.16.15-.42-.18-.4.39Zm.28-.46l-.38-.17c-.09-.04-.16-.08-.23-.12h0c.04.08.08.15.11.23l.16.39.34-.33Z' />
                        <path d='m321.34,437.16l.17-.12.67.99.56-.38.08.12-.73.5-.75-1.11Z' />
                        <path d='m322.52,436.6l-.43.22-.07-.13,1.05-.54.07.13-.43.22.55,1.06-.18.09-.55-1.06Z' />
                        <path d='m323.5,435.99l.49,1.25-.19.07-.49-1.25.19-.07Z' />
                        <path d='m324.17,435.75l.19.78c.07.29.26.38.46.33.23-.06.34-.22.27-.51l-.19-.78.2-.05.19.76c.1.4-.11.63-.44.71-.32.08-.59-.01-.69-.42l-.19-.77.2-.05Z' />
                        <path d='m325.66,436.58c.1.04.23.07.37.05.21-.02.32-.13.31-.26-.01-.13-.1-.19-.32-.24-.26-.05-.43-.15-.45-.34-.02-.21.17-.38.48-.41.16-.02.28,0,.35.03l-.04.15c-.05-.02-.16-.05-.3-.03-.22.02-.29.14-.28.23.01.12.11.18.34.23.27.06.42.16.44.36.02.21-.15.4-.52.44-.15.01-.32,0-.41-.05l.04-.15Z' />
                      </g>
                      <path d='m327.37,438.07c-3.05-.17-5.68,1.87-5.9,4.55-.22,2.7,2.06,5.02,5.09,5.18,3.03.18,5.67-1.86,5.89-4.54.22-2.7-2.06-5.02-5.08-5.19Zm-3.16,7.59c-.73-.04-1.29-.57-1.23-1.19.05-.61.69-1.08,1.41-1.04.74.04,1.3.57,1.26,1.19-.05.61-.68,1.08-1.44,1.04Zm.72-3.27c-.73-.04-1.28-.57-1.24-1.18.05-.62.69-1.08,1.43-1.04.74.04,1.3.57,1.25,1.19-.06.62-.69,1.08-1.43,1.04Zm3.41,3.9c-.74-.05-1.28-.57-1.24-1.19.05-.62.69-1.07,1.42-1.03.73.04,1.29.57,1.24,1.18-.04.61-.68,1.08-1.42,1.04Zm.83-3.77c-.75-.04-1.29-.57-1.24-1.18.05-.62.69-1.08,1.42-1.03.73.04,1.29.57,1.24,1.18-.05.62-.69,1.08-1.42,1.04Z' />
                    </g>
                    <path d='m260.32,411.69c2.21.8,14.7,6.04,21.62,7.27,0,0,23.47,5.56,50.1,7.62,1.23,1.36,7.39,13.52,10.04,18.07-.78.97-9.55,8.81-16.22,12.43-18.75-2.87-26.92-4.32-31.28-5.21-7.49-1.63-17-3.88-25.27-6.33-1.55-.46-20.37-5.35-20.37-5.35' />
                    <path d='m553.9,417.91c-2.21.8-19.21,5.63-26.13,6.85,0,0-18.28,3.86-45.09,4.28-1.23,1.36-11.38,12.77-14.04,17.32.78.97,12.68,10.27,19.35,13.88,18.75-2.87,25.14-3.47,29.5-4.35,7.49-1.63,14.72-2.13,22.99-4.59,1.55-.46,21.99-5.98,21.99-5.98' />
                  </g>
                )
              case false:
                return <></>
              default:
                return (
                  <g id='Pretina'>
                    <path
                      id='Pretina-2'
                      data-name='Pretina'
                      d='m554.75,414.07c-11.96,3.81-23.8,6.94-36.24,8.7-5.66.8-11.35,1.49-17.04,2.06-3.39.34-6.79.63-10.19.88-1.72.12-9.12-.5-10.19.59-1.29,1.32-13.26,16-16.15,20.45.73,1,15.51,12.56,21.99,16.4,18.89-2.23,27.85-3.42,32.24-4.16,7.57-1.37,18.56-2.71,25.82-4.99,1.54-.48,20.21-5.53,20.21-5.53-2.82-10.32-7.95-21.64-10.46-34.4Z'
                    />
                    <g id='Boton'>
                      <polyline points='495.03 444.4 494.54 441.02 496.8 444.39 496.28 440.34 498.54 443.44 499.21 441.7 500.05 442.89 500.39 442.03 501.45 442.61 501.94 441.89 503.12 442.07' />
                      <g>
                        <path d='m484.5,454.25c-.2,0-.4,0-.6-.01-3.17-.16-6.07-1.46-7.96-3.59-1.48-1.67-2.21-3.73-2.07-5.8.33-4.7,5-8.39,10.63-8.39.2,0,.4,0,.61.01,3.17.15,6.06,1.46,7.96,3.59,1.48,1.67,2.22,3.73,2.08,5.81-.33,4.7-5,8.39-10.64,8.39Z' />
                        <path d='m484.5,436.7c.2,0,.4,0,.6.01,5.74.28,10.12,4.37,9.8,9.13-.32,4.61-4.92,8.16-10.39,8.16-.2,0-.39,0-.59-.01-5.74-.28-10.12-4.36-9.79-9.13.33-4.6,4.91-8.15,10.38-8.16m0-.5v.5-.5h0c-5.76,0-10.54,3.79-10.88,8.62-.15,2.14.61,4.27,2.13,5.99,1.93,2.18,4.9,3.52,8.13,3.68.21,0,.41.01.62.01,5.77,0,10.56-3.79,10.89-8.62.15-2.14-.61-4.27-2.14-5.99-1.94-2.18-4.9-3.51-8.13-3.67-.21,0-.42-.01-.62-.01h0Z' />
                      </g>
                      <g>
                        <path d='m477.41,444.81c.06-.08.13-.21.15-.35.04-.21-.03-.34-.17-.37-.12-.02-.21.05-.32.25-.12.24-.26.38-.45.35-.21-.03-.32-.26-.27-.57.03-.16.08-.27.13-.34l.13.08s-.09.15-.12.29c-.04.21.05.31.14.33.12.02.2-.06.31-.27.13-.25.27-.37.46-.34.2.03.35.24.28.61-.03.15-.1.31-.16.39l-.13-.07Z' />
                        <path d='m477.57,442.76l-.23.58.45.17.26-.64.14.05-.34.83-1.26-.47.32-.8.14.05-.25.61.4.15.23-.58.13.05Z' />
                        <path d='m478.56,441.33c-.16-.1-.35-.22-.48-.32h0c.09.14.19.29.29.47l.36.61-.09.13-.67-.16c-.2-.05-.38-.1-.53-.15h0c.13.11.31.25.46.38l.44.37-.12.16-1.01-.87.16-.21.68.16c.17.04.32.08.46.13h0c-.09-.12-.17-.25-.26-.41l-.35-.61.16-.21,1.14.7-.12.16-.5-.31Z' />
                        <path d='m478.54,440.32c.07-.08.16-.17.29-.28.16-.13.3-.2.42-.21.11,0,.21.03.29.12.08.09.11.19.09.3-.02.14-.13.3-.28.43-.05.04-.09.08-.13.1l.34.41-.16.13-.85-1.01Zm.57.36s.09-.06.14-.1c.19-.16.24-.34.12-.48-.11-.13-.28-.1-.46.04-.07.06-.12.11-.14.14l.33.4Z' />
                        <path d='m480.72,439.61l-.54.32.24.42.6-.35.07.12-.78.46-.67-1.16.75-.44.07.12-.57.34.21.37.54-.32.07.12Z' />
                        <path d='m480.82,438.79c.09-.06.23-.12.36-.17.21-.08.35-.1.47-.07.1.03.17.09.21.19.06.17-.02.32-.16.43h0c.13,0,.23.05.31.17.12.16.2.26.25.3l-.2.08s-.11-.12-.21-.26c-.11-.15-.22-.19-.39-.12l-.18.07.21.54-.19.08-.47-1.23Zm.41.49l.2-.08c.2-.08.3-.23.24-.37-.06-.16-.23-.17-.43-.1-.09.04-.16.07-.18.09l.17.46Z' />
                        <path d='m484.58,438.86l-.18.42h-.21s.58-1.34.58-1.34h.25s.5,1.36.5,1.36h-.22s-.16-.43-.16-.43h-.57Zm.53-.13l-.14-.39c-.03-.09-.05-.17-.08-.25h0c-.03.08-.05.16-.09.24l-.17.39h.48Z' />
                        <path d='m485.92,438l.2.03-.21,1.18.67.11-.03.14-.87-.14.24-1.33Z' />
                        <path d='m487.17,438.38l-.46-.12.04-.14,1.13.31-.04.14-.47-.13-.34,1.16-.2-.05.34-1.16Z' />
                        <path d='m488.33,438.62l-.51,1.25-.19-.07.51-1.25.19.07Z' />
                        <path d='m488.95,438.88l-.4.7c-.15.26-.08.45.11.55.21.11.4.07.55-.19l.4-.7.18.1-.39.69c-.21.36-.51.39-.81.23-.28-.15-.42-.41-.21-.78l.39-.7.18.1Z' />
                        <path d='m489.47,440.52c.04.09.12.21.22.3.16.13.32.14.4.03.08-.1.06-.21-.06-.4-.15-.22-.2-.41-.08-.56.14-.16.39-.16.63.04.12.1.19.2.23.27l-.14.08c-.02-.05-.08-.15-.19-.24-.17-.14-.3-.1-.36-.03-.08.1-.05.21.08.4.15.24.18.41.06.56-.13.16-.39.19-.68-.05-.12-.1-.22-.23-.26-.32l.13-.08Z' />
                      </g>
                      <path d='m484.56,440.6c-2.94.06-5.27,2.24-5.19,4.85.08,2.6,2.55,4.66,5.5,4.59,2.96-.05,5.28-2.23,5.2-4.85-.08-2.61-2.54-4.67-5.51-4.6Zm-3.29,3.2c-.02-.59.55-1.08,1.26-1.1.72-.02,1.31.45,1.32,1.05.02.59-.53,1.09-1.25,1.1-.72.02-1.31-.45-1.33-1.05Zm1.95,4.72c-.72.01-1.32-.45-1.33-1.05-.02-.6.55-1.09,1.26-1.11.71-.02,1.31.44,1.33,1.05.02.59-.54,1.09-1.26,1.11Zm2.16-4.72c-.02-.6.55-1.09,1.27-1.1.72-.02,1.32.45,1.33,1.05.02.59-.55,1.08-1.26,1.1-.72.02-1.32-.45-1.34-1.05Zm1.88,4.24c-.72.02-1.32-.45-1.34-1.06-.01-.59.56-1.09,1.28-1.1.71-.02,1.31.46,1.33,1.06.02.59-.55,1.09-1.27,1.1Z' />
                    </g>
                    <path
                      id='Pretina-3'
                      data-name='Pretina'
                      d='m261.02,409.04c2.21.78,18.29,5.6,23.34,7.5,0,0,23.03,5.19,49.66,7.24,1.23,1.36,12.46,16.44,15.12,20.99-.79.98-16.13,12.02-22.79,15.64-18.76-2.87-27.66-4.38-32.01-5.26-7.49-1.62-18.41-3.34-25.55-5.87-1.51-.54-19.92-6.22-19.92-6.22,3.34-10.21,9.01-21.35,12.15-34.01Z'
                    />
                    <g id='Boton-2' data-name='Boton'>
                      <polyline points='316.42 441.62 317.09 438.15 314.6 441.55 315.34 437.39 312.86 440.51 312.27 438.69 311.34 439.88 311.04 438.99 309.92 439.55 309.45 438.78 308.23 438.93' />
                      <g>
                        <path d='m327.19,452.14c-5.94,0-10.86-4.05-10.96-9.02-.04-2.15.83-4.26,2.45-5.92,2.05-2.11,5.08-3.35,8.32-3.39h.17c5.94,0,10.86,4.05,10.96,9.02.04,2.15-.82,4.25-2.43,5.92-2.05,2.12-5.09,3.36-8.34,3.4h-.17Z' />
                        <path d='m327.17,434.05c5.83,0,10.61,3.89,10.71,8.78.1,4.92-4.62,8.98-10.52,9.06-.06,0-.11,0-.17,0-5.83,0-10.61-3.9-10.71-8.78-.09-4.93,4.63-8.98,10.52-9.06.05,0,.11,0,.16,0m0-.5h-.17c-3.31.05-6.41,1.31-8.5,3.47-1.66,1.72-2.56,3.88-2.52,6.1.1,5.11,5.13,9.27,11.21,9.27h.17c3.32-.05,6.42-1.31,8.51-3.48,1.66-1.72,2.55-3.88,2.5-6.1-.11-5.11-5.14-9.27-11.21-9.27h0Z' />
                      </g>
                      <g>
                        <path d='m321.76,447.57c-.03-.1-.11-.22-.21-.31-.15-.14-.31-.15-.4-.05-.09.09-.07.2.04.4.14.23.18.42.05.56-.14.16-.4.13-.62-.07-.12-.11-.18-.21-.21-.28l.14-.07c.02.05.07.15.18.25.16.15.29.12.36.05.09-.09.06-.2-.06-.4-.14-.24-.16-.42-.03-.56.14-.15.4-.17.67.09.11.1.21.24.24.33l-.14.07Z' />
                        <path d='m320.1,446.39l.38.48.38-.3-.42-.54.12-.09.55.7-1.06.83-.53-.67.12-.09.4.51.34-.26-.38-.48.11-.09Z' />
                        <path d='m319.33,444.84c-.17.09-.37.2-.52.26h0c.17,0,.35-.02.55-.02l.71-.02.06.14-.48.5c-.14.15-.28.28-.4.39h0c.16-.06.37-.14.56-.21l.55-.2.08.18-1.28.46-.11-.24.49-.52c.12-.13.24-.24.35-.33h0c-.14.01-.3.02-.48.03h-.71s-.11-.23-.11-.23l1.19-.65.08.18-.53.28Z' />
                        <path d='m318.45,444.37c-.03-.09-.06-.22-.08-.38-.03-.2-.01-.35.04-.46.05-.1.14-.16.26-.18.12-.02.22,0,.3.08.11.09.19.26.22.46,0,.06.02.12.01.17l.53-.08.03.2-1.31.21Zm.61-.3s0-.1-.01-.17c-.04-.25-.16-.38-.34-.35-.18.03-.24.19-.2.41.01.09.03.15.04.19l.52-.08Z' />
                        <path d='m319.04,442.16l-.04.62.49.02.04-.69h.15s-.05.9-.05.9l-1.35-.07.05-.85h.15s-.04.66-.04.66l.43.02.04-.62h.14Z' />
                        <path d='m318.43,441.57c.01-.1.04-.25.08-.39.06-.21.13-.34.23-.41.08-.06.18-.08.28-.05.17.05.25.21.25.38h0c.07-.11.18-.15.33-.15.2,0,.33,0,.39-.02l-.06.2s-.17.02-.34.02c-.19,0-.28.06-.34.24l-.05.19.56.16-.06.2-1.28-.35Zm.65-.03l.06-.2c.06-.21,0-.37-.15-.41-.17-.05-.28.08-.34.28-.03.1-.04.17-.04.2l.47.13Z' />
                        <path d='m320.95,438.73l.18.42-.15.15-.55-1.33.18-.17,1.33.58-.16.15-.42-.18-.4.39Zm.28-.46l-.38-.17c-.09-.04-.16-.08-.23-.12h0c.04.08.08.15.11.23l.16.39.34-.33Z' />
                        <path d='m321.34,437.16l.17-.12.67.99.56-.38.08.12-.73.5-.75-1.11Z' />
                        <path d='m322.52,436.6l-.43.22-.07-.13,1.05-.54.07.13-.43.22.55,1.06-.18.09-.55-1.06Z' />
                        <path d='m323.5,435.99l.49,1.25-.19.07-.49-1.25.19-.07Z' />
                        <path d='m324.17,435.75l.19.78c.07.29.26.38.46.33.23-.06.34-.22.27-.51l-.19-.78.2-.05.19.76c.1.4-.11.63-.44.71-.32.08-.59-.01-.69-.42l-.19-.77.2-.05Z' />
                        <path d='m325.66,436.58c.1.04.23.07.37.05.21-.02.32-.13.31-.26-.01-.13-.1-.19-.32-.24-.26-.05-.43-.15-.45-.34-.02-.21.17-.38.48-.41.16-.02.28,0,.35.03l-.04.15c-.05-.02-.16-.05-.3-.03-.22.02-.29.14-.28.23.01.12.11.18.34.23.27.06.42.16.44.36.02.21-.15.4-.52.44-.15.01-.32,0-.41-.05l.04-.15Z' />
                      </g>
                      <path d='m327.37,438.07c-3.05-.17-5.68,1.87-5.9,4.55-.22,2.7,2.06,5.02,5.09,5.18,3.03.18,5.67-1.86,5.89-4.54.22-2.7-2.06-5.02-5.08-5.19Zm-3.16,7.59c-.73-.04-1.29-.57-1.23-1.19.05-.61.69-1.08,1.41-1.04.74.04,1.3.57,1.26,1.19-.05.61-.68,1.08-1.44,1.04Zm.72-3.27c-.73-.04-1.28-.57-1.24-1.18.05-.62.69-1.08,1.43-1.04.74.04,1.3.57,1.25,1.19-.06.62-.69,1.08-1.43,1.04Zm3.41,3.9c-.74-.05-1.28-.57-1.24-1.19.05-.62.69-1.07,1.42-1.03.73.04,1.29.57,1.24,1.18-.04.61-.68,1.08-1.42,1.04Zm.83-3.77c-.75-.04-1.29-.57-1.24-1.18.05-.62.69-1.08,1.42-1.03.73.04,1.29.57,1.24,1.18-.05.62-.69,1.08-1.42,1.04Z' />
                    </g>
                    <path d='m260.32,411.69c2.21.8,14.7,6.04,21.62,7.27,0,0,23.47,5.56,50.1,7.62,1.23,1.36,7.39,13.52,10.04,18.07-.78.97-9.55,8.81-16.22,12.43-18.75-2.87-26.92-4.32-31.28-5.21-7.49-1.63-17-3.88-25.27-6.33-1.55-.46-20.37-5.35-20.37-5.35' />
                    <path d='m553.9,417.91c-2.21.8-19.21,5.63-26.13,6.85,0,0-18.28,3.86-45.09,4.28-1.23,1.36-11.38,12.77-14.04,17.32.78.97,12.68,10.27,19.35,13.88,18.75-2.87,25.14-3.47,29.5-4.35,7.49-1.63,14.72-2.13,22.99-4.59,1.55-.46,21.99-5.98,21.99-5.98' />
                  </g>
                )
            }
          })()}

          <g id='Costuras'>
            <path d='m542.23,65.17c-23.18,81.01-38.36,152.19,9.43,184.89' />
            <path d='m272.34,248.85c48.56-14.97,41.05-119.4,17.92-188.62' />
            <path d='m334.26,49.04c24.35,8.31,140.91,8.99,163.14,2.34' />
          </g>
          <g id='Ubicacion_logo' data-name='Ubicacion logo'>
            <circle cx='562.47' cy='689.93' r='15.86' />
            <circle cx='231.47' cy='682.93' r='15.86' />
            <circle cx='494.47' cy='186.93' r='15.86' />
            <circle cx='334.47' cy='186.93' r='15.86' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default JumperSvg
