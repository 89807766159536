import React, { useEffect, useState, Fragment } from 'react'
import { Form, Select } from 'antd'
import Input from 'components/inputs/Input'
import Collapse from 'components/Collapse/Collapse'
import style from './add-user.module.scss'
import { cleanSingleUpdatedState } from 'utilities/cleanSingleUpdatedState'

import {
  userManagementGetRolesPermissions,
  userManagementaddNewUser
} from 'services/userManagement/addNewUser'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { setRole, setSchoolId } from 'redux/reducers/usersManagementSlice'
import {
  IPermissions,
  IPermissionsRoles
} from 'interfaces/user-management-interface'
import { userManagementEdithUserById } from 'services/userManagement/edithUser'

import { getAllSchoolsList } from 'services/schoolAdminServices/schools'
import { ISchools } from 'interfaces/schools-interface'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import BackButton from 'components/buttons/BackButton/BackButton'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { IpermisionDinamic } from 'interfaces/permisions-interface'
import CheckOption from './components/checkOption/CheckOption'
import useIsAdminEzetera from 'hooks/isAdminEzetera/useIsAdminEzetera'
import useIsAdmin from 'hooks/isAdminEzetera/useIsAdmin'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { getDetailedLog } from 'services/genericServices/genericServices'
import NewSelectLevels from 'modules/uniforms/uniformList/components/NewSelectLevels'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'

const AddUserModule = () => {
  const {
    formUserValues,
    role,
    schools,
    isOnEdith,
    deletedUser,
    userId,
    schoolId,
    globalRoles,
    thisUserIdShcool
  } = useSelector((state: RootState) => state.userManagement)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [allPermissions, setAllPermissions] = useState<IpermisionDinamic[]>([])
  const [formValues, setFormValues] = useState(formUserValues)
  const [SchoolsList, setSchoolsList] = useState<ISchools[]>([])
  const [loading, setLoading] = useState(false)
  const [
    permissionsResponsabilitiesAdmin,
    setpermissionsResponsabilitiesAdmin
  ] = useState<null | IPermissionsRoles[]>(null)

  const isAdmin = useIsAdminEzetera()
  const isNormalAdmin = useIsAdmin()

  let getOut = true
  if (isAdmin) getOut = false
  else if (isNormalAdmin) getOut = false
  if (getOut) navigate('/users-management')

  const getLabelSchools = (data: ISchools[]) => {
    let arr: ISchools[] = []
    data.forEach((item: ISchools) => {
      arr.push({ ...item, label: item.name })
    })
    setSchoolsList(arr)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prev: any) => cleanSingleUpdatedState(e, prev, true))
  }

  const validateEmail = (mail: string) => {
    if (mail.length !== 0) {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return regex.test(mail)
    }
    return true
  }

  const getOnlyIds = () => {
    if (
      permissionsResponsabilitiesAdmin !== null &&
      permissionsResponsabilitiesAdmin.length !== 0
    ) {
      let data: any = []
      permissionsResponsabilitiesAdmin.forEach((scho) => {
        const arrPermisions: any = []
        scho.permissions.forEach((items) => {
          if (items.check) arrPermisions.push(items.id)
        })
        let arrRespon: any = []
        scho.responsabilities.forEach((items) => {
          items.grades.forEach((grads) => {
            if (grads.check) arrRespon.push(grads.id)
          })
        })
        data.push({
          ...scho,
          permissions: arrPermisions,
          responsabilities: arrRespon
        })
      })
      return data
    }
  }

  const onSubmit = () => {
    setLoading(true)
    const { name, mail, first_surname, second_surname, position, description } =
      formValues
    let ids = []
    if (uid.role === 'Ezetera') {
      ids = schoolId.map((res: any) => {
        if (res.value) return res.value
        if (res.id) return res.id
      })
    } else {
      ids.push(SchoolsList[0].id)
    }

    let userInfo: any = {
      name: name.value,
      first_surname: first_surname.value,
      second_surname: second_surname.value,
      mail: mail.value,
      role: role,
      position: position.value,
      description: description.value,
      schools: getOnlyIds()
    }
    if (isOnEdith) {
      userInfo['is_deleted'] = deletedUser

      userManagementEdithUserById(userId, userInfo)
        .then(() => {
          setLoading(false)
          SuccessToast(`El usuario ${name.value} fue editado exitosamente.`)
          navigate('/users-management')
        })
        .catch(() => {
          ErrorToast('Ups!. Algo salio mal')
          setLoading(false)
        })
    } else
      userManagementaddNewUser(userInfo)
        .then(() => {
          setLoading(false)
          SuccessToast(`El usuario ${name.value} fue creado exitosamente.`)
          navigate('/users-management')
        })
        .catch((e) => {
          switch (e.response.data.error) {
            case 'Duplicate mail':
              ErrorToast(
                'Ups! El correo ya existe en el sistema, por favor verificarlo o cambiar de correo.'
              )
              break
            default:
              ErrorToast(e.response.data.error)
              break
          }
          setLoading(false)
        })
  }

  const checkDisabled = (): boolean => {
    let disabled = false
    let words: string[] = []
    Object.keys(formValues).forEach((res: any) => {
      const objKey = res as keyof typeof formUserValues
      const objInput = formValues[objKey]
      words.push(objInput.value.trim())
      if (objInput.name === 'mail' && objInput.value.trim() !== '')
        disabled = !validateEmail(objInput.value)
    })

    words.forEach((item) => {
      if (item.length < 3) disabled = true
    })
    if (words.includes('')) disabled = true
    if (role === null) disabled = true
    if (role !== globalRoles[0].label) {
      if (schoolId === null) disabled = true
    }

    return disabled
  }

  const disabledMail = (name: string) => {
    if (isOnEdith && name === 'mail') return true
    else return false
  }

  const handleServices = (dat: IpermisionDinamic) => {
    let per: IpermisionDinamic[] = allPermissions

    if (per.includes(dat))
      per = [
        ...per.filter((item) => item.id !== dat.id),
        { ...dat, checked: !dat.checked }
      ]

    setAllPermissions(per.sort((a, b) => a.id - b.id))
  }

  const onGenderChange = (value: string) => {
    dispatch(setRole(value))
  }

  const onSchoolChange = (options: any[]) => {
    const newOptions = options.map((optionId: any, key: number) => {
      const selected: any = SchoolsList.filter((res) => optionId === res.id)
      return { label: selected[0].name, id: selected[0].id }
    })
    dispatch(setSchoolId(newOptions))
  }

  const getCustomProps = (name: string, value: string) => {
    const valueSatus = (sentence: string) => {
      return value.length === 0 ? '' : value.length < 3 ? sentence : ''
    }

    switch (name) {
      case 'name':
        return {
          help: valueSatus('Escribe un nombre valido'),
          validateStatus: valueSatus('error')
        }
      case 'first_surname':
        return {
          help: valueSatus('Escribe un apellido valido'),
          validateStatus: valueSatus('error')
        }
      case 'second_surname':
        return {
          help: valueSatus('Escribe un apellido valido'),
          validateStatus: valueSatus('error')
        }
      case 'mail':
        return validateEmail(value)
          ? {}
          : {
              help: 'Escribe una direccion de correo valida',
              validateStatus: 'error'
            }

      case 'position':
        return {
          help: valueSatus('Escribe un puesto valido'),
          validateStatus: valueSatus('error')
        }
      case 'description':
        return {
          help: valueSatus('Escribe una descripcion valida'),
          validateStatus: valueSatus('error')
        }

      default:
        return {}
    }
  }

  const donwloadLogs = async () => {
    setLoading(true)
    try {
      const res: any = await getDetailedLog({ type: 'user', id: userId })
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = 'html-to-img.png'
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('Ocurrió un error, inténtelo de nuevo')
    }
    setLoading(false)
  }

  const handlePermisions = (idSchool: number, permision: number) => {
    let val: any = []
    permissionsResponsabilitiesAdmin?.forEach((item) => {
      if (item.id === idSchool) {
        let perms: any = []
        item.permissions.forEach((pers) => {
          if (pers.id === permision) {
            perms.push({ ...pers, check: !pers.check })
          } else perms.push(pers)
        })
        val.push({ ...item, permissions: perms })
      } else val.push(item)
    })

    setpermissionsResponsabilitiesAdmin(
      val.sort(function (a: { id: number }, b: { id: number }) {
        return a.id - b.id
      })
    )
  }
  const handleResponsabilities = (
    idSchool: number,
    levelName: string,
    idGrade: number,
    all: null | boolean
  ) => {
    let val: any = []
    permissionsResponsabilitiesAdmin?.forEach((item) => {
      if (item.id === idSchool) {
        let responsa: any = []
        item.responsabilities.forEach((respons) => {
          let gradesss: any = []
          if (respons.name === levelName) {
            respons.grades.forEach((gra) => {
              if (all === null) {
                if (gra.id === idGrade) {
                  gradesss.push({ ...gra, check: !gra.check })
                } else {
                  gradesss.push(gra)
                }
              } else {
                gradesss.push({ ...gra, check: all })
              }
            })
            responsa.push({ ...respons, grades: gradesss })
          } else responsa.push(respons)
        })
        val.push({ ...item, responsabilities: responsa })
      } else val.push(item)
    })

    setpermissionsResponsabilitiesAdmin(
      val.sort(function (a: { id: number }, b: { id: number }) {
        return a.id - b.id
      })
    )
  }

  useEffect(() => {
    getAllSchoolsList().then((dat) => getLabelSchools(dat.data.schools))
  }, [])

  useEffect(() => {
    if (
      isOnEdith &&
      isAdmin &&
      SchoolsList.length !== 0 &&
      schools.length !== 0
    ) {
      dispatch(
        setSchoolId(
          schools.map((res: any) => {
            return { label: res.name, id: res.id_school }
          })
        )
      )
    }
    if (!isAdmin) {
      let name = ''
      SchoolsList.forEach((item) => {
        if (item.id === thisUserIdShcool) name = item.name
      })
      dispatch(setSchoolId([{ label: name, id: thisUserIdShcool }]))
    }
  }, [SchoolsList, schools])

  useEffect(() => {
    const handlePermisions = (value: boolean) => {
      let myPermissions = allPermissions
      myPermissions.forEach((content: IpermisionDinamic) => {
        if (myPermissions.includes(content))
          myPermissions = [
            ...myPermissions.filter((item) => item.id !== content.id),
            { ...content, checked: value }
          ]
      })
    }

    if (role === globalRoles[1].label || role === globalRoles[0].label) {
      handlePermisions(true)
    } else {
      handlePermisions(false)
    }
  }, [role])

  useEffect(() => {
    if (schoolId.length !== 0) {
      const ids = schoolId.map((item) => item.id)

      userManagementGetRolesPermissions(ids)
        .then((s) => {
          let addChecks: IPermissionsRoles[] = []
          s.data.data.forEach((item: IPermissionsRoles) => {
            let permi: any = []
            let respons: any = []
            if (
              isOnEdith &&
              schools.length !== 0 &&
              schools.map((it: any) => it.id_school).includes(item.id)
            ) {
              schools.forEach(
                (edit: {
                  id_school: number
                  permissions: {
                    id: number
                    name: string
                    school_name: string
                    check: boolean
                  }
                  responsabilities: any
                }) => {
                  if (item.id === edit?.id_school) {
                    permi = edit.permissions
                    respons = edit.responsabilities
                  }
                }
              )
            } else {
              item.permissions.forEach((itms) => {
                permi.push({ ...itms, check: false })
              })
              item.responsabilities.forEach((its) => {
                let grados = its.grades.map((grads) => {
                  return { ...grads, check: false }
                })
                respons.push({ ...its, grades: grados })
              })
            }
            //--------------------------

            addChecks.push({
              ...item,
              permissions: permi,
              responsabilities: respons
            })
          })
          setpermissionsResponsabilitiesAdmin(
            addChecks.sort(function (a: { id: number }, b: { id: number }) {
              return a.id - b.id
            })
          )
        })
        .catch((w) => console.log(w))
    } else setpermissionsResponsabilitiesAdmin(null)
  }, [schoolId])

  return (
    <MainTemplate>
      <div className={`${style['module-container']}`}>
        <BackButton
          onClick={() => {
            navigate('/users-management')
          }}
          textDecoration='underline'
        />
        <TitleHeader
          IconName='schoolIcon'
          widthIcon={'2rem'}
          heightIcon={'2rem'}
          title='Nuevo usuario'
          buttonProps={[
            {
              label: 'Descargar Logs',
              onClick: () => donwloadLogs(),
              background: 'white',
              rounded: true,
              size: 'm',
              sector: 'tertiary',
              isLoading: loading
              /* disabled:true */
            },
            {
              label: `${isOnEdith ? 'Editar ' : 'Agregar '} usuario`,
              rounded: true,
              size: 'm',
              sector: 'secondary',
              disabled: checkDisabled(),
              isLoading: loading,
              onClick: onSubmit
            }
          ]}
        />

        <Form onFinish={onSubmit} layout='vertical' autoComplete='off'>
          <div className={`${style['form-user']}`}>
            {Object.keys(formValues).length !== 0 &&
              Object.keys(formValues).map((res: any, key) => {
                const objKey = res as keyof typeof formUserValues
                const objInput = formValues[objKey]

                const props = getCustomProps(objInput.name, objInput.value)
                return (
                  <div className={`${style['div-input-container']}`} key={key}>
                    <Input
                      key={key}
                      name={objInput.name}
                      placeholder={objInput.placeholder}
                      label={objInput.label}
                      type={objInput.type}
                      value={objInput.value}
                      onChange={handleChange}
                      disabled={disabledMail(objInput.name)}
                      customprops={props}
                    />
                  </div>
                )
              })}
          </div>
          <div className={`${style['form-user']}`}>
            <div className={`${style['div-input-container']}`}>
              <Form.Item
                name='gender'
                label='Rol del usuario'
                rules={[{ required: true }]}
                initialValue={role}
              >
                <Select
                  placeholder='Selecciona un rol'
                  size='large'
                  onChange={onGenderChange}
                  allowClear
                >
                  {globalRoles.length !== 0 &&
                    globalRoles.map((item, index) => {
                      let show = index !== 0 ? true : isAdmin
                      return (
                        <Fragment key={`${item.label + index}`}>
                          {show && (
                            <Select.Option value={item.label}>
                              {item.label}
                            </Select.Option>
                          )}
                        </Fragment>
                      )
                    })}
                </Select>
              </Form.Item>
            </div>
            {role !== globalRoles[0].label && (
              <div className={`${style['div-input-container']}`}>
                {isAdmin ? (
                  <Form.Item
                    name='School'
                    label={`Escuela(s)`}
                    rules={[{ required: true }]}
                    initialValue={schools.map((res: any) => {
                      return { label: res.name, value: res.id_school }
                    })}
                  >
                    <Select
                      mode='multiple'
                      showSearch
                      placeholder='Selecciona una escuela'
                      optionFilterProp='children'
                      size='large'
                      allowClear
                      onChange={onSchoolChange}
                      filterOption={(input: any, option: any) => {
                        if (
                          option.label
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        )
                          return option
                      }}
                      options={SchoolsList.map((res) => {
                        return { label: res.name, value: res.id }
                      })}
                    ></Select>
                  </Form.Item>
                ) : (
                  <p className={`${style['p-text-title']}`}>
                    Escuela: {SchoolsList.length > 0 && SchoolsList[0].name}
                  </p>
                )}
              </div>
            )}
          </div>
          <br />
          {allPermissions.length !== 0 &&
            role !== globalRoles[1].label &&
            role !== globalRoles[0].label && (
              <Fragment>
                <p className={`${style['p-text-title']}`}>Permisos</p>
                <div
                  className={`${
                    role !== globalRoles[3].label
                      ? style['div-permisions-g6']
                      : style['div-permisions-g5']
                  }`}
                >
                  {allPermissions.length !== 0 &&
                    allPermissions.map((item: IpermisionDinamic) => {
                      return (
                        <Fragment key={item.id}>
                          {role !== globalRoles[3].label ? (
                            <CheckOption
                              onHandleCheck={() => handleServices(item)}
                              label={item.name}
                              id={item.id}
                              checked={item.checked}
                            />
                          ) : (
                            <Fragment>
                              {item.name !==
                                'Construcción de la Estructura' && (
                                <CheckOption
                                  onHandleCheck={() => handleServices(item)}
                                  label={item.name}
                                  id={item.id}
                                  checked={item.checked}
                                />
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )
                    })}
                </div>
              </Fragment>
            )}

          <div>
            {role !== globalRoles[1].label &&
              role !== globalRoles[0].label &&
              permissionsResponsabilitiesAdmin !== null &&
              permissionsResponsabilitiesAdmin.length !== 0 &&
              permissionsResponsabilitiesAdmin.map(
                (item: IPermissionsRoles, index: number) => {
                  return (
                    <Fragment key={index}>
                      <Collapse
                        title={item.name}
                        customStyle={`${style['descriptionstyle']}`}
                        customBg={`${style['custom-bg']}`}
                        showMiddleText
                        showSubTitle
                        isDroped={() => undefined}
                        id={33333}
                      >
                        <div className={style['collapse-div']}>
                          <Fragment>
                            <p className={`${style['p-text-title']}`}>
                              Permisos
                            </p>
                            <div
                              className={`${
                                role !== globalRoles[3].label
                                  ? style['div-permisions-g6']
                                  : style['div-permisions-g5']
                              }`}
                            >
                              {item.permissions.length !== 0 ? (
                                item.permissions.map(
                                  (itemPerm: IPermissions) => {
                                    return (
                                      <Fragment key={itemPerm.id}>
                                        {role !== globalRoles[3].label ? (
                                          <CheckOption
                                            onHandleCheck={() =>
                                              handlePermisions(
                                                item.id,
                                                itemPerm.id
                                              )
                                            }
                                            label={itemPerm.name}
                                            id={itemPerm.id}
                                            checked={itemPerm.check as boolean}
                                          />
                                        ) : (
                                          <Fragment>
                                            {item.name !==
                                              'Construcción de la Estructura' && (
                                              <CheckOption
                                                onHandleCheck={() =>
                                                  handlePermisions(
                                                    item.id,
                                                    itemPerm.id
                                                  )
                                                }
                                                label={itemPerm.name}
                                                id={itemPerm.id}
                                                checked={
                                                  itemPerm.check as boolean
                                                }
                                              />
                                            )}
                                          </Fragment>
                                        )}
                                      </Fragment>
                                    )
                                  }
                                )
                              ) : (
                                <EmptyListMessage
                                  iconHeight={40}
                                  iconWidth={40}
                                  icon='estructure'
                                  text='No hay permisos añadidos al colegio.'
                                />
                              )}
                            </div>
                          </Fragment>
                          <p className={`${style['p-text-title']}`}>
                            Responsabilidades
                          </p>
                          {item.responsabilities.length !== 0 ? (
                            <NewSelectLevels
                              onHandleCheck={handleResponsabilities}
                              levelsLenght={item.responsabilities.length}
                              levels={item.responsabilities}
                              idSchool={item.id}
                            />
                          ) : (
                            <EmptyListMessage
                              iconHeight={40}
                              iconWidth={40}
                              icon='emptyIcons'
                              text='No hay responsabilidades añadidas al colegio.'
                            />
                          )}
                        </div>
                      </Collapse>
                    </Fragment>
                  )
                }
              )}
          </div>
        </Form>
      </div>
    </MainTemplate>
  )
}

export default AddUserModule
