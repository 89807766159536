import { AUTHSERVICE } from '../config'

export interface IEdithNotebook {
  id?: number
  id_package?: number

  id_format?: number
  matter?: string
  quantity?: number
  ext_picture?: string
  ext_color?: string
  ext_text?: string
  ext_logo?: string
  ext_binding?: string
  ext_coverfinish?: string
  ext_spinecolor?: string
  ext_rountips?: boolean

  int_sheets?: string
  int_title?: string
  int_striped?: string
  int_guidepoint?: string
  int_positionlogos?: string
  int_interiorinks?: string
  int_roundtips?: boolean
  int_logo?: string
  status?: number
  step?: number

  price?: number
  special_price?: number
  refill_time?: number
  return_percentage?: number
  provider?: string
  provider_fee?: boolean
  resource_free?: boolean

  ezetera_proposal?: boolean
  college_proposal?: boolean
  front_cover_img?: string
  back_cover_img?: string
  ext_cover_desing?: string
  request_changes?: boolean

  require_cover_desing?: boolean
  require_cover_img?: boolean
  require_cover_text?: boolean

  jsonUploadPosition?: any

  jsonImgBack?: any
  jsonImgFront?: any
  jsonTextBack?: any
  jsonTextFront?: any
}

interface IResults {
  status: number
  statusText: string
  data: {
    notebookId: number
    categories: string[]
    success: boolean
  }
}

const putNotebook = async (data: IEdithNotebook): Promise<any> => {
  return await AUTHSERVICE().put(`/notebook`, data)
}

const edithNotebook = async (data: IEdithNotebook) => {
  const results = await putNotebook(data)
  return results as IResults
}

const getNotebookCategory = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/klaar/category?group=Cuadernos`)
}

const notebookCategory = async () => {
  const results = await getNotebookCategory()
  return results as IResults
}

const putOpenPackageNotebook = async (id: number): Promise<any> => {
  return await AUTHSERVICE().put('/notebook-package', {
    id,
    open: true,
    mode: 'end-list'
  })
}

const OpenPackageNotebookBiId = async (id: number) => {
  const results = await putOpenPackageNotebook(id)
  return results as IResults
}

export { edithNotebook, OpenPackageNotebookBiId, notebookCategory }
