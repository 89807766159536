import { Avatar, Drawer, Input, Space, Form, Empty, Modal } from 'antd'
import { memo, useEffect, useState } from 'react'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import Button from 'components/buttons/Button/Button'
import './CommentsComponent.scss'
import B from 'components/texts/B/B'
import Caption from 'components/texts/Caption/Caption'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { Divider } from 'antd'
import { useComments } from 'hooks/comments/useComments'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import RenderCheck from './RenderCheck'
import { openPackageUpdate, updatePackage } from 'services/utilsServices/utils'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { handleErrors } from 'utilities/handleErrors'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import * as services from 'services/books/books'
import {
  updateSchoolBookAdmin2,
  updateSchoolBookStatusAdmin,
  updateSchoolBookStatusCommentsAdmin
} from 'redux/reducers/books'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { edithNotebook } from 'services/notebooks/putNotebook'
import Icon from 'components/icons'
import ModalUploadOutside from 'modules/uniforms/editor/components/selectCardOption/ModalUploadOutside'
import useNotebooks from 'hooks/notebooks/useNotebooks'
import {
  setOutsidePropuesta,
  setOutsidePropuestaBack
} from 'redux/reducers/notebooksSlice'
import RenderSchoolList from './RenderSchoolList'
import CommentsSection from './CommentsSection'
import RenderFooter from './RenderFooter'
import { updateStatusProduct } from 'services/commentsServices/comments'
import RenderSuggestions from './RenderSuggestions'
import AddSupToCatModal from 'components/AddSupToCatModal/AddSupToCatModal'

dayjs.extend(relativeTime)

export const disableValidateButton = (data: any) => {
  let disabled = false
  if (data.status >= 4) disabled = true
  if (data?.pkg_open) disabled = true

  return disabled
}

export const disableValidCommentButton = (
  data: any,
  comments: any,
  innerFormFilled?: boolean
) => {
  if (innerFormFilled !== undefined && !innerFormFilled) return true
  let disabled = disableValidateButton(data)
  /*  if (comments.length > 0) {
    for (let x = 0; x < comments.length; x++) {
      if (!comments[x].approved) disabled = true
      break
    }
  } */

  return disabled
}

const CommentsComponent = memo(
  ({
    drawerTitle = 'Comentarios',
    moduleForm,
    afterBookStatusUpdated,
    innerFormFilled
  }: any) => {
    const {
      form,
      loadingPost,
      loadingDownload,
      handleNavigate,
      postNewComment,
      closeDrawComments,
      updateComment,
      updateItem,
      navigate,
      donwloadLogs
    } = useComments()

    const [requestChanges, setrequestChanges] = useState(false)
    const [designProposed, setDesignProposed] = useState(false)
    const [imagesUploaded, setImagesUploaded] = useState(false)
    const [openUploadModal, setOpenUploadModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [loading, setloading] = useState(false)
    const [params, setSearchParams] = useSearchParams()

    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const { pathname } = useLocation()
    const { uuid } = useParams()
    const dispatch = useDispatch()

    let { openComments, comments, data } = useSelector(
      (state: RootState) => state.comments
    )

    const { idBookAdmin, idBookSchoolAdmin } = useSelector(
      (state: RootState) => state.books
    )

    const { saveSendDataNotebookChange, getNotebookData } = useNotebooks({
      uuid: parseInt(uuid || '0')
    })

    const isEzetera = uid.role === 'Ezetera'
    const isInPreview = pathname.includes('cuadernos/preview')
    const isStatus9 = isEzetera && isInPreview && data?.status === '9'
    const isNotebookStatus9 = !isEzetera && isInPreview && data?.status === '9'

    useEffect(() => {
      if (Object.keys(data).length > 0) {
        setrequestChanges(data.request_changes)
        const isNotebookPackageClosed =
          data?.pkg_open === false || params.get('pkg_open') === 'false'
        const isDesignProposal =
          data?.ezetera_proposal === true ||
          data?.ext_cover_desing === 'proporsal'
        const isDesignUpload =
          data?.college_proposal === true || data?.ext_cover_desing === 'upload'
        const isProposalStatus = [2, 8].includes(data?.status as number)
        const isUploadStatus = [2, 11].includes(data?.status as number)

        setDesignProposed(
          isDesignProposal && isNotebookPackageClosed && isProposalStatus
        )
        setImagesUploaded(
          isDesignUpload && isNotebookPackageClosed && isUploadStatus
        )
      }
    }, [data])

    const sentNewComment = async (values: any) => {
      await postNewComment(values)
      if (afterBookStatusUpdated && (data.status === 2 || data.status === 3)) {
        try {
          if (pathname.includes('libros')) {
            dispatch(
              updateSchoolBookStatusCommentsAdmin({
                id: idBookSchoolAdmin,
                status: 3
              })
            )
          } else {
            await afterBookStatusUpdated()
            dispatch(setCommentsObjectInformation({ ...data, status: 3 }))
            //dispatch(setopenComments(true))
          }
        } catch (error) {}
      }
    }

    const onValidate = async () => {
      await updateItem(4)
      if (afterBookStatusUpdated) {
        try {
          afterBookStatusUpdated()
          dispatch(updateSchoolBookStatusAdmin({ id: idBookAdmin, status: 4 }))
        } catch (error) {}
      }
    }

    const acceptUploadedImages = async () => {
      setloading(true)
      try {
        await edithNotebook({
          id: data.id,
          status: 4,
          step: 3
        })
        if (afterBookStatusUpdated) {
          afterBookStatusUpdated()
        }
        SuccessToast('Solicitud autorizada')
        navigate(-1)
      } catch (error) {
        handleErrors(error)
      }

      setloading(false)
    }

    const aceptRequestChangesBooks = async () => {
      setloading(true)
      try {
        await services.endListBooks({ id_grade: data.id_grade, close: false })
        await services.requestChangesBooks({
          id_grade: data.id_grade,
          id_school: data.id_school,
          request_changes: false,
          status: 'accepted'
        })
        if (afterBookStatusUpdated) {
          afterBookStatusUpdated()
        }
        dispatch(updateSchoolBookAdmin2({ id: data.id_book }))
        //  dispatch(updateEndList({ idGrade:data.id_grade, status: false }))
        SuccessToast('Fue aceptada la petición de cambio')
      } catch (err: any) {
        handleErrors(err)
      } finally {
        setloading(false)
        setrequestChanges(false)
      }
    }

    const negateRequestChangesBooks = async () => {
      setloading(true)
      try {
        await services.requestChangesBooks({
          id_grade: data.id_grade,
          id_school: data.id_school,
          request_changes: false,
          status: 'denied'
        })
        if (afterBookStatusUpdated) {
          afterBookStatusUpdated()
        }
        dispatch(updateSchoolBookAdmin2({ id: data.id_book }))
        SuccessToast('No fue aceptada la petición de cambio')
      } catch (err: any) {
        handleErrors(err)
      } finally {
        setloading(false)
        setrequestChanges(false)
      }
    }

    const handleImagesUploaded = async () => {
      setloading(true)

      try {
        await edithNotebook({
          id: data.id,
          status: 9,
          step: 3
        })
        dispatch(setOutsidePropuesta(''))
        dispatch(setOutsidePropuestaBack(''))
        setOpenUploadModal(false)
        navigate(-1)
      } catch (error) {
        ErrorToast('Ocurrió un error. Inténtelo más tarde.')
      }

      setloading(false)
    }

    const handleRejectRequest = async (values: any) => {
      setloading(true)
      try {
        await sentNewComment(values)
        await edithNotebook({
          id: data.id,
          status: data?.status == 8 ? 7 : 10,
          step: 1,
          ezetera_proposal: false,
          college_proposal: false,
          ext_cover_desing: 'design',
          back_cover_img: '',
          front_cover_img: ''
        })
        if (afterBookStatusUpdated) {
          afterBookStatusUpdated()
        }
        SuccessToast('Solicitud rechazada')
        setOpenRejectModal(false)
        dispatch(setopenComments(false))
      } catch (error) {
        ErrorToast('Ocurrió un error. Inténtelo más tarde.')
      }
      setloading(false)
    }

    const handleUpdateStatusModule = async () => {
      setloading(true)
      let id = ''
      let type = ''
      if (pathname.includes('libros')) {
        id = data.id
        type = 'book'
      }
      if (pathname.includes('utiles')) {
        id = data.id_supply_school
        type = 'supply'
      }
      const body = {
        id,
        type
      }
      try {
        await updateStatusProduct(body)

        if (afterBookStatusUpdated) {
          afterBookStatusUpdated()
          setOpenRejectModal(false)
          dispatch(setopenComments(false))
          dispatch(
            updateSchoolBookStatusCommentsAdmin({ id: data.id, status: 2 })
          )
        }

        SuccessToast('El estatus del producto se devolvio a en revisión')
      } catch (error) {
        ErrorToast('Ocurrió un error. Inténtelo más tarde.')
      }
      setloading(false)
    }

    return (
      <Drawer
        title={(() => {
          if (pathname.includes('cuadernos/preview')) return 'Comentarios'
          return uid.role === 'Ezetera'
            ? 'Información de validación'
            : 'Comentarios'
        })()}
        placement='right'
        onClose={closeDrawComments}
        open={openComments}
        key='right'
        extra={
          <Space>
            <Avatar
              style={{
                backgroundColor: '#F0F5FF',
                verticalAlign: 'middle',
                color: '#061D47'
              }}
              size='large'
            >
              {uid?.name?.charAt(0).toLocaleUpperCase() as string}
            </Avatar>
          </Space>
        }
        footer={
          <RenderFooter
            requestChanges={requestChanges}
            negateRequestChangesBooks={negateRequestChangesBooks}
            aceptRequestChangesBooks={aceptRequestChangesBooks}
            onValidate={onValidate}
            innerFormFilled={innerFormFilled}
          />
        }
        className={`custom-comment-drawer ${
          uid.role === 'Ezetera' ? 'admin' : ''
        }`}
      >
        {pathname.includes('cuadernos/preview') ? null : (
          <div className='see-details'>
            <p onClick={handleNavigate}>Ver detalle</p>
            {uid.role === 'Ezetera' && (
              <Button
                disabled={data?.status <= 2}
                onClick={handleUpdateStatusModule}
                rounded
                sector='secondary'
                label='Devolver a en revisión'
              />
            )}
          </div>
        )}

        {uid.role === 'Ezetera' && <RenderSchoolList />}

        <br />
        {uid.role === 'Ezetera' && (
          <Button
            sector='tertiary'
            rounded
            background='white'
            label='Descargar Logs'
            onClick={donwloadLogs}
            isLoading={loadingDownload}
            /* disabled={true} */
          />
        )}

        {uid.role === 'Ezetera' && moduleForm}

        <RenderSuggestions
          data={data}
          afterBookStatusUpdated={afterBookStatusUpdated}
          updateItem={updateItem}
        />

        {designProposed && isEzetera && (
          <>
            <div>
              <B>
                El colegio solicitó una propuesta personalizada en la portada de
                sus librerías
              </B>
            </div>
            <div style={{ marginTop: '16px', marginBottom: '16px' }}>
              <b>Requerimientos:</b>
              <ul>
                {data?.require_cover_text && <li>La portada requiere texto</li>}
                {data?.require_cover_img && (
                  <li>La portada requiere una imagen adicional</li>
                )}
                {data?.require_cover_design && (
                  <li>La portada requiere un diseño especial</li>
                )}
              </ul>
            </div>
            <div className='comments-footer-button' style={{ gap: '16px' }}>
              <Button
                onClick={() => setOpenRejectModal(true)}
                size='m'
                label='No autorizar'
                rounded
                sector='secondary'
              />
              <Button
                onClick={() => setOpenUploadModal(true)}
                size='m'
                label='Diseñar'
                rounded
                sector='primary'
              />
            </div>
          </>
        )}
        {imagesUploaded && isEzetera && (
          <>
            <B>El colegio envió una propuesta personalizada de sus librerías</B>
            <div>
              <div
                className='uploaded-images-container'
                onClick={() => {
                  window.open(data?.front_cover_img, '__blank')
                }}
              >
                <div className='icon-container'>
                  <Icon name='file' width={22} height={30} />
                </div>
                <div>
                  <div>Portada frontal</div>
                  <div>Descargar archivo con diseño</div>
                </div>
              </div>
              <div
                className='uploaded-images-container'
                onClick={() => {
                  window.open(data?.back_cover_img, '__blank')
                }}
              >
                <div className='icon-container'>
                  <Icon name='file' width={22} height={30} />
                </div>
                <div>
                  <div>Portada trasera</div>
                  <div>Descargar archivo con diseño</div>
                </div>
              </div>
            </div>
            <div className='comments-footer-button' style={{ gap: '16px' }}>
              <Button
                onClick={() => setOpenRejectModal(true)}
                size='m'
                label='No autorizar'
                rounded
                sector='secondary'
              />
              <Button
                onClick={acceptUploadedImages}
                isLoading={loading}
                size='m'
                label='Autorizar'
                rounded
                sector='primary'
              />
            </div>
          </>
        )}
        {comments.length > 0 ? (
          <CommentsSection
            comments={comments}
            isStatus9={isStatus9}
            isNotebookStatus9={isNotebookStatus9}
            sentNewComment={sentNewComment}
            form={form}
            loadingPost={loadingPost}
          />
        ) : (
          <div
            className='tickets-container'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {uid.role === 'Ezetera' ? (
              <CommentsSection
                comments={comments}
                isStatus9={isStatus9}
                isNotebookStatus9={isNotebookStatus9}
                sentNewComment={sentNewComment}
                form={form}
              />
            ) : (
              <>
                {isNotebookStatus9 ? (
                  <CommentsSection
                    comments={comments}
                    isStatus9={isStatus9}
                    isNotebookStatus9={isNotebookStatus9}
                    sentNewComment={sentNewComment}
                    form={form}
                  />
                ) : (
                  <Empty description='Sin comentarios' />
                )}
              </>
            )}
          </div>
        )}
        <AddSupToCatModal />
        <ModalUploadOutside
          isModalOpen={openUploadModal}
          handleCancel={() => {
            dispatch(setOutsidePropuesta(''))
            dispatch(setOutsidePropuestaBack(''))
            getNotebookData()
            setOpenUploadModal(false)
          }}
          uuid={data?.id}
          saveChange={saveSendDataNotebookChange}
          handleOk={handleImagesUploaded}
        />
        <Modal
          open={openRejectModal}
          onCancel={() => setOpenRejectModal(false)}
          title='Rechazar solicitud'
          footer={null}
        >
          <Form onFinish={handleRejectRequest} form={form}>
            <div style={{ marginTop: '12px', marginBottom: '12px' }}>
              Agrega un comentario para rechazar la solicitud
            </div>
            <Form.Item name='comment'>
              <Input.TextArea
                placeholder='Agrega un comentario'
                maxLength={100}
                required={true}
              />
            </Form.Item>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Form.Item>
                <Button
                  isLoading={loading}
                  label='Rechazar solicitud'
                  rounded
                  sector='secondary'
                  buttonType='submit'
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </Drawer>
    )
  }
)

CommentsComponent.displayName = 'CommentsComponent'
export default CommentsComponent
