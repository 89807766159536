import React, { Fragment,useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

import FaldaShortLateralSvg from 'assets/img/uniforms/sportOutfit/faldaShort/lateral/FaldaShortLateralSvg'
import LabelFaldaShortLateral from 'assets/img/uniforms/sportOutfit/faldaShort/lateral/LabelFaldaShortLateral'
import { handleClotheName } from 'redux/reducers/uniformsSlice'

const FaldaShortLateralEditable = () => {
  const { poloValues, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Falda short deportiva lateral',
        description: 'Descripcion de la Falda short deportiva'
      })
    )
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div className={`${style[`logo-position-${'left'}`]}`}></div>
          <div className={`${style['div-polo-svg-absolute']}`}></div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <FaldaShortLateralSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <LabelFaldaShortLateral className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default FaldaShortLateralEditable
