import React from 'react'

const PathSujecionSpiralInside = ({
  className,
  viewBox = '0 0 800 800',
  spiralColor,
  show
}: {
  className: string
  viewBox?: string
  spiralColor: string
  show: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
    >
      <g id='Path_Espiral' data-name='Path Espiral'>
        <g id='Path_Espiral-2' data-name='Path Espiral'>
          {(() => {
            switch (show) {
              case 2:
                return (
                  <g id='Espiral_izquierdo' data-name='Espiral izquierdo'>
                    <g id='Espiral_Izquierdo' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='156.75'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,154.39h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-2' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='209.03'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,206.67h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-3' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='261.32'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,258.96h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-4' data-name='Espiral Izquierdo'>
                      <g>
                        <circle fill='black' cx='173.83' cy='313.6' r='12.31' />
                        <path
                          fill={spiralColor}
                          d='m107.31,311.24h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-5' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='365.88'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,363.53h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-6' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='418.17'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,415.81h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-7' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='470.45'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,468.09h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-8' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='522.74'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,520.38h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-9' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='575.02'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,572.66h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-10' data-name='Espiral Izquierdo'>
                      <g>
                        <circle fill='black' cx='173.83' cy='627.3' r='12.31' />
                        <path
                          fill={spiralColor}
                          d='m107.31,624.95h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-11' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='173.83'
                          cy='679.59'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m107.31,677.23h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                        />
                      </g>
                    </g>
                  </g>
                )
              case 1:
                return (
                  <g id='Espiral_derecho' data-name='Espiral derecho'>
                    <g id='Espiral_Izquierdo-12' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='679.59'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,677.23h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 1359.18) rotate(-180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-13' data-name='Espiral Izquierdo'>
                      <g>
                        <circle fill='black' cx='664.36' cy='627.3' r='12.31' />
                        <path
                          fill={spiralColor}
                          d='m666.72,624.95h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 1254.61) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-14' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='575.02'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,572.66h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 1150.04) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-15' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='522.74'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,520.38h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 1045.47) rotate(-180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-16' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='470.45'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,468.09h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 940.9) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-17' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='418.17'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,415.81h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 836.34) rotate(-180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-18' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='365.88'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,363.53h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 731.77) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-19' data-name='Espiral Izquierdo'>
                      <g>
                        <circle fill='black' cx='664.36' cy='313.6' r='12.31' />
                        <path
                          fill={spiralColor}
                          d='m666.72,311.24h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 627.2) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-20' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='261.32'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,258.96h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 522.63) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-21' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='209.03'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,206.67h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 418.06) rotate(180)'
                        />
                      </g>
                    </g>
                    <g id='Espiral_Izquierdo-22' data-name='Espiral Izquierdo'>
                      <g>
                        <circle
                          fill='black'
                          cx='664.36'
                          cy='156.75'
                          r='12.31'
                        />
                        <path
                          fill={spiralColor}
                          d='m666.72,154.39h66.52v4.72h-66.52c-1.3,0-2.36-1.06-2.36-2.36h0c0-1.3,1.06-2.36,2.36-2.36Z'
                          transform='translate(1397.6 313.5) rotate(-180)'
                        />
                      </g>
                    </g>
                  </g>
                )

              default:
                break
            }
          })()}
        </g>
      </g>
    </svg>
  )
}
export default PathSujecionSpiralInside
