import {
  PlusCircleIcon,
  MagnifyingGlassIcon,
  ArchiveBoxIcon,
  CheckBadgeIcon
} from '@heroicons/react/24/outline'
import { Space, Tag, Tooltip } from 'antd'
import { memo } from 'react'
import styles from './actionsBooks.module.scss'
import { useLocation } from 'react-router-dom'
import Tags from 'components/tag'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import Button from 'components/buttons/Button/Button'

interface ActionsBooks {
  idGrade: number
  endList: boolean
  setIdGrade: (value: number) => void
  actions: Array<Function>
  data?: []
}

const ActionsBookModule = memo((props: ActionsBooks) => {
  const location = useLocation()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  return (
    <Space>
      {/* <Tooltip title='Cambiar a revisión'>
        <CheckBadgeIcon
          onClick={() => props.actions[0](props.idGrade)}
          className={`${styles.icons} ${styles.iconsSearch}`}
        />
      </Tooltip> */}
      {!props.endList && uid.role !== 'Director' && (
        <Tooltip title='Asignar libro'>
          {/* <PlusCircleIcon
            onClick={(e) => {
              e.stopPropagation()
              props.setIdGrade(props.idGrade)
              props.actions[1](true)
            }}
            className={`${styles.icons} ${styles.iconsSearch}`}
          /> */}
           <Tag
            onClick={(e: any) => {
              e.stopPropagation()
              props.setIdGrade(props.idGrade)
              props.actions[1](true)
            }}
            className={styles.tag}
          >
            <small className={styles.status} data-variation={7}>
              Asignar libro
            </small>
          </Tag>
        </Tooltip>
      )}

      {!props.endList && <Tags variation={2} title={'En construcción'} />}

      {props.endList && (
        <Tags
          variation={5}
          title={
            props?.data
              ?.filter(
                (item: { idGrade: any }) => item.idGrade === props.idGrade
              )
              .every((book: any) => book.status === 5) && props?.data
              ?.filter(
                (item: { idGrade: any }) => item.idGrade === props.idGrade
              ).length > 0
              ? 'Lista autorizada'
              : 'Lista terminada'
          }
        />
      )}

      {props?.data
        ?.filter((item: any) => item.idGrade === props.idGrade)
        ?.some((item: any) => item.status === 4) &&
        props.endList && (
          <Tag
            onClick={(e: any) => {
              e.stopPropagation()
              props.actions[4](props.idGrade)
            }}
            className={styles.tag}
          >
            <small className={styles.status} data-variation={6}>
              Autorizar
            </small>
          </Tag>
        )}

      {(((props?.data
        ?.filter((item: any) => item.idGrade === props.idGrade)
        ?.length || 0) > 0) &&
        props.endList &&
        uid.role !== 'Director') && (
          <Tag
            onClick={(e: any) => {
              e.stopPropagation()
              props.actions[5](props.idGrade)
            }}
            className={styles.tag}
          >
            <small className={styles.status} data-variation={6}>
              Solicitar modificación
            </small>
          </Tag>
        )}
      {/*       
      <Tooltip title='Asignar libro'>
        <PlusCircleIcon
          id='btn-asignar'
          onClick={(e) => {
            e.stopPropagation()
            props.setIdGrade(props.idGrade)
            props.actions[2](`${location?.pathname}/nuevo`)
          }}
          className={styles.icons}
        />
      </Tooltip> */}

      {/* <Tooltip title='Carga masiva'>
        <ArchiveBoxIcon
          onClick={(e) => {
            e.stopPropagation()
            props.actions[3](true)
          }}
          className={styles.icons}
        />
      </Tooltip> */}
    </Space>
  )
})

export default ActionsBookModule
