import React from 'react'

const FaldaShortLateralSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_falda_short" data-name="Outline falda short"   d="m302.95,300.33l1.99-29.37s63.7-12.8,82.79-11.91c19.09.89,71.47-4,71.47-4l26.01-2.07,15,31.71s13.6,60.25,2.7,118.55c-5.83,31.16,4.26,102.82,4.26,102.82,0,0,1.52,32.37,2.41,36.36.89,4-191.76,26.95-218.39-8.11l11.77-233.99Z"/>
      <path id="Resorte"   d="m302.41,308.05s60.15-24.93,108.53-26.23c48.38-1.3,62.16.83,62.16.83l27.09,2.05-15-31.71s-52.16-6.47-83.54-5.52c-25.79.78-65.56,12.36-65.56,12.36l-31.16,11.13-2.52,37.09Z"/>
      <g>
        <path   d="m307.42,275.16s-3.04,7.05-.28,6.12"/>
        <path   d="m314.66,279.99c.6-1.23.99-2.38,1.41-3.72"/>
        <path   d="m319.28,279.13c.83-2.11.61-2.9.61-4.74,0-1.33,1.18-3.87.72-4.8"/>
        <path   d="m323.15,282.22c.9-1.13.97-4.48,1.12-6.2"/>
        <path   d="m322.68,287.68c.33-1.25.44-3.22.3-4.43"/>
        <path   d="m324.44,285.15c-.12,1.49-1.02,2.34-1.16,3.66"/>
        <path   d="m329.86,283.83c.16-1.38.83-4.5.13-5.33-.66,1.03-.61,4.05-.73,5.5"/>
        <path   d="m326.17,285.54c.4-1.74.87-4.66.25-6.16-1.01.8-1.38,5.88-1.12,7.29"/>
        <path   d="m331.39,280.02c-.08,1.23.21,2.37-.06,3.63-.13.69-1,2.01-.54,2.55"/>
        <path   d="m334.25,277.37c-.05,2.12-.97,3.97-1.18,5.9"/>
        <path   d="m346.66,283.07c.33-1.31,1.2-1.57,1.21-3.2.02-.87-.87-2.87-.05-3.47"/>
        <path   d="m349.49,282.28c.1-1.09-.12-3.21.17-4.11"/>
        <path   d="m349.15,271.37c1.99,2.63-1.52,5.91-.87,8.25"/>
        <path   d="m353.4,280.3c-.05.57-.1,1.16-.14,1.72.16.07.31.3.48.32"/>
        <path   d="m356.45,288.63c.12-1.46.56-3.03,1.41-3.52"/>
        <path   d="m356.88,280.19c.6.61.02,1.99-.08,3.01"/>
        <path   d="m357.95,280.64c.59-1.36.68-4.87.81-6.51"/>
        <path   d="m362.39,274.84c-.17,2.08-.1,3.97-1.3,5.13.18-1.96.89-3.88,1.04-6.08"/>
        <path   d="m364.08,287.04c.08-1.52-.04-3.76.92-4.67-.18,1.87-.5,3.38-.78,5.14"/>
        <path   d="m366.96,279.74c.14-1.5.24-3.13.48-4.55.2-1.19.03-1.84.17-3,.08,1.69-.12,5.26-.58,6.93"/>
        <path   d="m305.39,283.17c1.03.12,1.5.02,2.18-2.22"/>
        <path   d="m311.36,270.1c-.98,1.76-2.22,3.54-2.72,5.67-.49,2.07.06,3.34-1.02,5.52.67-1.39,1.14-2.74,1.7-4.56"/>
        <path   d="m309.81,284.5c.27-.57.67-1.03,1.04-1.28.02.89-.54,2.2-.82,3.21.11.06.21.06.31.08"/>
        <path   d="m309.58,282.51c.37-1.23.18-2.2.48-3.38.4-1.65.7-1.59,1.6-2.54"/>
        <path   d="m312.85,275.3c-.46,1.53-1.21,3.94-1.22,5.3,1.02-.75,2.38-3.41,3.15-4.91"/>
        <path   d="m309.49,294.89c.22-.73,1.51-4.07,1.07-4.42-.45-.35-1.54,2.29-1.73,3.11"/>
        <path   d="m310.47,292.99c1.09-2.74,2.01-5.38,2.9-8.25.28-.92.87-2.3.63-2.94-1.07,1.12-2.16,4.45-3.02,6.17-.63,1.21-1.26,2.47-1.86,3.66"/>
        <path   d="m316.7,301.99c.47-1.15.92-2.54,1.21-3.73-.65.04-1.47,1.22-1.79,2.44"/>
        <path   d="m313.21,292.57c1.02-3.31,2-6.55,2.84-9.84.34-1.32,1.36-4.75,2.2-3.98,1.19,1.06-1.59,3.96-2.06,5.54-.33,1.15-.16,2.14-.42,3.31-.36,1.74-1.11,3.68-1.64,5.42"/>
        <path   d="m319.55,293.03c.35-2.81-.06-6.03.06-8.86.08-1.69-.57-2.55-.51-4.06-.21,1.39.04,2.73,0,4.08-.04.99-.14,2.02-.17,2.97-.08,2.24-.15,3.93-.6,6.07"/>
        <path   d="m321.12,288.06c.13-2.06.94-5.55.27-7.26-.33,1.13-.44,2.37-.7,3.51-.22.93-.53,1.74-.75,2.69"/>
        <path   d="m325.4,297.76c.1-1.26.98-1.69,1.1-2.91.1-.9-.37-2.48-.58-3.25-.47-1.78-.82-2.37-.64-4.64-.26,3.95-.73,8.31-1.19,12.23.13.04.28.06.42,0"/>
        <path   d="m327.86,298.07c.14-.9.54-1.64.7-2.34-.1.14-.34.3-.45.39.09.07.21,0,.28.04-.14.06-.37.04-.52.02.38-.82.24-2,.74-2.87.62-1.06,1.51-.4,1.99-1.65.71-1.85-.15-5.14-.82-6.1-.32,1.23-.16,2.55-.45,3.77-.25,1.14-.72,1.36-1.09,2.08-.8,1.57-.77,4.06-.94,6.05"/>
        <path   d="m326.91,289.19c.07-.86.48-1.56.64-2.4.11-.64.13-1.42.17-2.05-.6.86-1.13,2.95-1.46,4.15"/>
        <path   d="m332.79,296.35c.21-2.59.1-5.12.24-7.68.07-1.3.62-3.35.11-4.26-.55,1.88-.52,4.86-.69,6.89-.18,2.1-.4,3.09-1.09,4.93"/>
        <path   d="m335.44,295.47c.21-2.34.37-4.75.5-7.06-.79,1.76-.34,6.09-1.83,6.61"/>
        <path   d="m335.29,287.01c.75-2.03.32-3.61-.07-5.17-.31-1.2-.15-2.42,0-3.9-.3,1.53-.57,2.66-.71,4.23-.12,1.47-.21,2.96-.85,4.11-.02.17.04.21.15.14"/>
        <path   d="m338.04,294.35c.26-2.58.09-5.49-.39-7.74-.62,1.35-.14,3.62-.33,5.27-.13,1.05-.35,2.03-.44,3.12"/>
        <path   d="m337.64,285.67c.18-2.18.63-4.82.1-6.6-.33-1.17-.83-1.67-.62-3.32.02,1.85-.22,3.63-.3,5.53-.06,1.57,0,2.95-.13,4.59"/>
        <path   d="m340.42,283.8c-.5,2.5-.06,4.62-.08,7.1,0,.95-.15,1.58-.45,2.48-.26.83-.26,1.47.34.66.38-.54.5-1.58.73-2.35.29-.94.46-1.92.31-2.91-.1-.58-.32-1.06-.36-1.68-.05-.66.07-1.45.13-2.15"/>
        <path   d="m339.58,285.06c-.27-2.2-.51-4.12-.31-6.49.1-1.27.35-2.56.04-3.53-.06,0-.1,0-.15-.02-.58,1.77-.44,3.69-.17,5.36.28,1.73.43,3.74.33,5.65"/>
        <path   d="m342.75,282.06c-.21,2.54-.34,4.98-.25,7.42.03.81,0,1.57-.2,2.37-.1.46-.29.87-.32,1.36.92-.03,1.09-4.54,1.15-5.57.07-1.17-.07-2.27-.26-3.29-.11-.48-.29-.91-.23-1.5"/>
        <path   d="m340.66,275.19c-.2,2.37-.31,4.17.24,6.15.14.53.32,1.08.29,1.73-.03.69-.28,1.28-.28,1.98,1.27-.25.74-3.27.52-4.53-.15-.81-.38-1.14-.32-2.02.04-.69.11-1.38.17-2.07"/>
        <path   d="m342.64,277.29c-.1,1.19-.21,2.37-.34,3.51.5-.81.48-2.6.58-3.68"/>
        <path   d="m344.87,283.47c-.16,1.95.35,3.01.54,4.72.16,1.43-.12,3.01-.25,4.52"/>
        <path   d="m350.9,279.1c-.45-3.01-.25-6.26-.04-9.52.03,1.02-.04,1.88-.04,2.89-.02.96-.14,2.25-.09,3.2.04.76.29,1.23.28,2.01,0,.67-.12,1.46-.18,2.14"/>
        <path   d="m353.07,289.57c.07-1.36.48-2.19.65-3.3-.07.86-.14,1.71-.22,2.56"/>
        <path   d="m352.15,281.11c.13-1.56.4-3.2.46-4.73-.54,1.3-.86,3.14-.96,4.63"/>
        <path   d="m354.18,281.64c1.09-1.69,1.08-3.61,1.27-5.84"/>
        <path   d="m355.64,280.79c-.58,2.81-1.09,6.35-.16,8.55.24-2.01-.12-3.11-.16-4.94-.02-.97.05-2.59.42-3.38"/>
        <path   d="m359.88,277.68c-.07,1.11.12,2.14.04,3.32-.05.97-.19,1.96-.25,2.94-.1,1.68-.26,3.41-.36,5.11.7.37.63-1.51.57-2.13-.11-1.22-.11-2.34-.02-3.66.09-1.47.76-4.5.26-5.36"/>
        <path   d="m362.9,277.12c-.19,2.33.02,4.41-.12,6.71-.08,1.36-.4,2.64-.45,3.96.87.95.5-1.74.5-2.28.01-1.63.03-3.54.19-5.19"/>
        <path   d="m367.42,284.58c.07-.65.03-1.47-.2-1.91-.21-.39-.59-.11-.59-.81"/>
        <path   d="m365.84,287.19c.09-1.1.19-2.21.28-3.33"/>
        <path   d="m384.3,296.17c-.08.31-.16.64-.2.95"/>
        <path   d="m371.34,280.26c.36,1.07-.09,4.13-.21,5.57"/>
        <path   d="m367.03,284.77c.17.48.39.88.46,1.4"/>
        <path   d="m372.93,285.4c.5-1.14.51-3.68.49-4.98-.02-1.03-.5-1.92-.4-2.97"/>
        <path   d="m374.29,284.93c.06-.75.11-1.5.2-2.24.27-.17.54-.5.8-.78-.33.5-.67,1.42-.74,2.18"/>
        <path   d="m370.59,280.04c-.07.8-.5.53-.83.82.05-.72.29-1.76-.08-2.09"/>
        <path   d="m368.56,281.79c-.44.69-.29,1.61-.08,2.26-.42.65-.63-.29-.23-.86.3-.45,1.32-.82.53-1.65"/>
        <path   d="m305.57,284c-.42,1.04-.3,2.44.07,2.79"/>
        <path   d="m304.6,292.52c.45-.84,1-1.07,1.45-1.71"/>
        <path   d="m304.74,296.45c-.6.89-.52,1.55-.41,2.24.11.64.1,1.63-.08,2.54"/>
        <path   d="m304.89,294.48c-.08.3-.17.57-.28.87.74-.17,1.12-1.14,1.71-1.94"/>
        <path   d="m305.36,289.6c.27-.81.31-1.82-.23-1.58-.26.61.04,1.45.05,1.98.14-.07.18-.22.25-.39"/>
        <path   d="m306.11,297.08c-.7,2.15-.39,3.86-.95,5.83-.3,1.06-.74,1.52-1.11,2.54-.23.58-.67,1.95-.09,1.65.12-.46.72-2.05,1-2.48.02,0,.08.02.12.01-.09.59-.52,1.27-.58,1.77-.1.91.63-.19.87-.81.48-1.3.25-2.74.21-3.72-.03-.63.2-1.22.21-1.83.02-.72-.14-.78.21-1.57.5-1.15,1.12-2.06.75-3.06-.16.28-.29.65-.38,1"/>
        <path   d="m308.13,283.85c-.45,1.38-.34,2.45-.66,3.71-.39,1.51-.85,2.98-.84,4.29,0,.54.03.9-.1,1.49-.12.55-.47,1.14-.59,1.61-.39,1.66.59.34.97-.21.02.34-.02.71-.12,1.09.77-.86.33-4.17.31-4.98,0-.81.04-1.69.2-2.6.15-.88.29-.86.61-1.58.53-.3,1.03-1.32.97-2.02-.3-.17-.52.66-.77.47-.15-.12-.03-.94.04-1.27"/>
        <path   d="m306.69,306.04c.71-1.17.91-2.3,1.07-3.52.16-1.28-.17-2.32.02-3.49.22-1.33,2.06-3.66,1.22-4.62-.62.61-.79,2.24-1.02,3.11-.25.98-.81,2.2-.96,3.16-.18,1.08.21,1.54.09,2.65-.09.91-.35,1.7-.73,2.68"/>
        <path   d="m308.77,290.7c.26-.84,1.21-3.18.54-3.22-.55,1.13-.72,2.78-1.34,3.67"/>
        <path   d="m308.91,304.67c.31-.99.68-2.06.88-3.04.22-1.03.13-1.85.32-2.86.23-1.26.68-1.41,1-2.55.23-.83.12-1.4-.41-1.2-.48,1.09-.13,1.7-.38,2.7-.23.88-.72,1.55-.89,2.47-.17,1-.07,1.55-.36,2.65-.21.82-.49,1.63-.74,2.44"/>
        <path   d="m310.94,303.08c.84-2.35,2.17-4.37,2.01-6.52-.1-1.3-.23-1.9.4-3.61.07.48.09,1.07.26,1.4.28-.38.53-.93.67-1.41.98-.29-.49,1.84-.67,2.27-.42.98-.41,2.43-.6,3.49-.29,1.68-.87,5.61-2.26,6.07"/>
        <path   d="m313.65,303.96c.68-.11,1.14-1.76,1.45-2.73.45-1.37.58-2.22.71-3.44.1-.91.17-2.04.41-2.98.2-.78.68-1.85.32-2.24-.17.38-.28.79-.4,1.19-.27-.02-.54-.35-.42-.94.14-.63.54-.74.76-1.22.4-.83.21-1.87-.18-1.94-.24.62-.36,1.32-.52,1.95-.11.41-.21.66-.23,1.07-.04.41.25,1.02.14,1.39-.21.74-.92,1.26-1.12,2.22-.21,1.01.13.58.44.86,1.08.97-.69,4.44-1.13,6.3"/>
        <path   d="m319.21,300.13c.06-.71.28-1.83.23-2.49-.02-.63-.26-1.19-.36-1.71-.16-.99-.35-2.2-.21-3.36-.88,1.81-.6,3.1-.14,4.5.39,1.18.34,2.2.22,3.66"/>
        <path   d="m320.63,300.37c1.07-.89,1.23-3.44.85-4.86-.33-1.24-.13-3.17-.72-4.06-.24,1.11.26,1.92.37,2.86.09.72.04,1.29-.03,2.11-.08.99.02,1.45.07,2.27.04.71,0,1.29-.39,1.57"/>
        <path   d="m322.28,298.91c.1-1.18.57-2.32.57-3.67.01-1.21-.33-1.73-.54-2.84-.07-.44-.47-2.01-.35-2.5.21-.91.82-.39.94.2.15.76-.18,1.82-.16,2.62.02.75.26,1.31.28,2.14.01.71-.03,1.75-.16,2.49-.12.69-.74,1.73-.45,2.26"/>
        <path   d="m346.53,291.76c.2-2.2.39-3.83.15-5.91-.09-.7-.45-1.55-.38-2.32.08-.09.16-.15.25-.18.39.87.38,2.22.38,3.4.22-.06.45-.2.65-.36.23,1.35-.17,3.39-.26,4.86"/>
        <path   d="m345.2,283.41c1.31-3.69-1.19-4.57-.62-8.06.13.03.27.16.36.31-.12.2-.17.56-.25.71.22.16.41.36.62.45"/>
        <path   d="m349.03,291.17c.19-2.21.39-3.84.14-5.91-.08-.71-.44-1.55-.38-2.33.08-.09.16-.15.24-.18.4.87.38,2.21.38,3.39.22-.06.45-.19.65-.36.23,1.35-.16,3.39-.25,4.86"/>
        <path   d="m351.2,290.55c.3-1.76.41-4.3.17-5.95-.11-.86-.46-2.13.3-2.6,0,.78-.04,1.57-.05,2.34.38-.3.72-.92.7-1.63.49,1.53-.22,3.04-.47,4.52-.18,1.1.11,2.37-.39,3.37"/>
        <path   d="m376.17,271.6c-.17,1.95.34,3.01.53,4.72.16,1.43-.11,3.01-.25,4.52"/>
        <path   d="m385.46,282.41c-1.22-2.82-1.87-6.03-2.51-9.24.3.99.45,1.83.71,2.82.23.93.45,2.21.75,3.13.23.74.6,1.13.79,1.89.18.66.26,1.44.38,2.12"/>
        <path   d="m384.84,274.22c.18,2.86.6,6.4,2.07,8.34-.28-2.01-.93-3-1.43-4.76-.28-.94-.63-2.52-.46-3.37"/>
        <path   d="m388.87,282.37c-.75-1.52-.96-3.16-1.29-4.96"/>
        <path   d="m388.54,270.93c.22,1.1.67,2.05.9,3.21.2.96.33,1.94.53,2.91.34,1.65.63,3.37.98,5.04.77.21.22-1.6,0-2.19-.42-1.15-.71-2.25-.97-3.55-.3-1.45-.43-4.54-1.14-5.27"/>
        <path   d="m391.57,271.61c.42,2.3,1.16,4.28,1.62,6.54.27,1.34.29,2.65.59,3.94,1.09.73.04-1.8-.11-2.33-.41-1.59-.89-3.45-1.17-5.08"/>
        <path   d="m410.98,280.63c-.16-1.15-.55-2.18-.9-3.22-.53-1.59-.94-3.09-1.37-4.77-.26-1.04-1.22-3.15-.49-3.82.8,1.67.92,3.77,1.71,5.55.91,2.05,1.12,4.2,1.62,6.47"/>
        <path   d="m383.33,283.44c-.27-1.54-.44-3.2-.78-4.7-.19,1.38-.02,3.24.27,4.71"/>
        <path   d="m377.64,284.31c.47-1.49.49-3.15.51-4.74.22,1.75.38,3.27.33,5.12"/>
        <path   d="m381.19,283.35c-.17-1.78-.73-4.27-1.38-5.82-.34-.81-1.01-1.97-.38-2.58.2.75.37,1.53.56,2.29.3-.38.46-1.05.26-1.74.87,1.37.58,3.01.71,4.49.11,1.11.73,2.29.5,3.36"/>
        <path   d="m484.86,282.11c.02-1.92-1.04-4.44-.21-6.24"/>
        <path   d="m481.8,280.05c-.21-1.33-1.05-1.66-.91-3.28.06-.87,1.13-2.79.36-3.46"/>
        <path   d="m479.94,282.3c0-1.09.42-3.19.21-4.11"/>
        <path   d="m478.4,283.15c-.15-1.66-.43-3.21.02-4.81.43-1.53.73-1.64.64-3.53"/>
        <path   d="m476.64,274.24c-2.22,2.48.98,6,.12,8.29"/>
        <path   d="m474.49,279.67c.07-1.5-.19-3,.06-4.46.23-1.29.76-2.49.26-4.09"/>
        <path   d="m472.91,275.24c0,1.36-.05,2.73-.03,4.09.02.88.33,2.13.07,2.43"/>
        <path   d="m473.48,277.23c0,.57,0,1.17-.02,1.72-.17.06-.34.27-.51.28"/>
        <path   d="m469.81,278.87c.09.82.04,1.77.49,2.27"/>
        <path   d="m469.34,281.9c0-1.47-.28-3.07-1.08-3.62"/>
        <path   d="m453.11,280.46c-.81-1.8-.03-4.9-.37-7.04"/>
        <path   d="m459.35,274.26c.16,1.26,1.99,6.75.89,7.08"/>
        <path   d="m451.76,273.14c-.01,2.23-.36,5.58.29,7.51"/>
        <path   d="m442.89,280.97c0-.95.23-4.05-.14-4.47"/>
        <path   d="m437.36,274.53c-.02,2.09-.27,3.96.82,5.22,0-1.97-.53-3.93-.48-6.14"/>
        <path   d="m435.64,280.04c-.28-1.53.44-5.03-.47-5.97-.25,1.25.71,6.11-.38,6.19"/>
        <path   d="m456.46,280.85c-.07-1.95.24-3.87.2-5.84-.04,0-.19-.01-.24-.03-.19.93-.09,2.1-.14,3.08-.08-.67-.05-1.39-.05-2.08-.58,1.06-.47,2.84-.44,4.3.35-.4.25-1.27.6-1.83"/>
        <path   d="m462.59,275.22c.15,2.02-.63,4.4.38,6.31.04-.91-.3-1.47-.34-2.29-.03-.76.19-1.23.19-1.91.02-1.21-.12-3.33-.3-4.49"/>
        <path   d="m489.68,279.69c-.56-2.08.02-3.62.55-5.15.41-1.16.37-2.4.36-3.88.16,1.54.33,2.69.32,4.26-.01,1.48-.06,2.96.48,4.17,0,.17-.06.21-.17.13"/>
        <path   d="m487.19,282.62c-.03-2.59.41-5.48,1.09-7.68.49,1.4-.19,3.62-.16,5.28.04,1.06.17,2.05.16,3.14"/>
        <path   d="m485.9,280.92c.02-2.19-.18-4.85.5-6.59.44-1.14.98-1.6.93-3.27-.19,1.84-.11,3.64-.2,5.54-.09,1.57-.28,2.94-.29,4.58"/>
        <path   d="m483.12,282.2c-.01-.88-.54-1.01-.62-1.71-.09-.72.21-1.54.29-2.19"/>
        <path   d="m447.32,280.6c.17-.94.15-1.68-.26-2.45-.38-.72-.32-1.05-.03-1.88"/>
        <path   d="m471.99,271.1c.27,2.52-.37,4.61-.57,7.08-.08.95,0,1.58.22,2.5.18.84.12,1.48-.4.63-.33-.57-.36-1.61-.52-2.39-.2-.96-.28-1.95-.04-2.92.15-.58.41-1.04.51-1.65.11-.65.06-1.45.06-2.15"/>
        <path   d="m430.54,269.37c-.03,2.54-.11,4.99-.43,7.42-.1.8-.14,1.56-.02,2.38.06.46.2.89.19,1.38-.91-.1-.68-4.6-.64-5.63.05-1.17.28-2.26.57-3.26.15-.47.38-.89.36-1.48"/>
        <path   d="m427.68,270.53c-.01,2.37-.07,4.18-.8,6.11-.18.52-.41,1.05-.44,1.71-.03.68.17,1.29.1,2-1.24-.35-.45-3.31-.1-4.55.22-.8.48-1.11.5-1.99.02-.69.02-1.38.02-2.08"/>
        <path   d="m425.77,270.17c-.02,1.96-.62,2.98-.96,4.66-.3,1.41-.16,3-.17,4.52"/>
        <path   d="m402.66,280.56c.72-2.96.82-6.22.91-9.48-.12,1.02-.13,1.88-.23,2.89-.07.95-.06,2.25-.2,3.2-.1.76-.4,1.2-.46,1.98-.06.67-.01,1.46-.02,2.15"/>
        <path   d="m399.54,272.61c.32,2.84.51,6.4-.62,8.53-.06-2.03.4-3.1.61-4.91.11-.96.19-2.58-.12-3.4"/>
        <path   d="m397.22,272.23c-.02,2.4-.09,4.84.08,7.19.07.95-.14,2.29-.46.88-.18-.85.08-2.55.09-3.45"/>
        <path   d="m395.6,270.35c-.02,2.33-.42,4.39-.5,6.69-.04,1.36.17,2.67.09,3.98-.96.87-.34-1.77-.29-2.32.14-1.63.29-3.53.29-5.18"/>
        <path   d="m441.89,278.26c-.16-2.21-.44-4.51-.77-5.3-.14,2.83-.57,4.77-.45,8.3"/>
        <path   d="m422.24,280.08c0-.83,0-1.67.02-2.5"/>
        <path   d="m495.71,283.12c-.34-1.52-.2-3.17-.08-4.76-.37,1.73-.67,3.22-.79,5.08"/>
        <path   d="m491.14,283.17c-.33-1.51-.19-3.17-.07-4.76-.37,1.74-.67,3.23-.8,5.09"/>
        <path   d="m493.49,283.27c-.13-1.77,0-4.31.38-5.93.19-.84.65-2.09-.07-2.61-.07.77-.1,1.56-.17,2.33-.34-.32-.63-.97-.54-1.68-.63,1.49-.06,3.06.06,4.54.08,1.12-.32,2.37.08,3.38"/>
        <path   d="m420.48,280.36c-.97-3.77,1.6-4.46,1.36-7.98-.13.02-.28.14-.39.29.1.21.11.57.18.72-.24.15-.44.33-.66.39"/>
        <path   d="m412.62,279.36c-.14-1.78-.01-4.32.37-5.94.19-.84.66-2.09-.07-2.61-.07.78-.1,1.57-.16,2.34-.36-.33-.63-.97-.55-1.68-.63,1.48-.06,3.05.06,4.54.09,1.11-.32,2.36.09,3.39"/>
        <path   d="m449.01,278.9c-.04-1.16.17-2.23.34-3.3.26-1.66.4-3.19.55-4.91.08-1.07.66-3.29-.17-3.84-.5,1.76-.27,3.86-.75,5.73-.54,2.16-.39,4.31-.49,6.62"/>
        <path   d="m466.15,281.24c-.14-1.77-.02-4.32.37-5.93.19-.84.66-2.09-.06-2.61-.08.76-.1,1.56-.17,2.32-.36-.33-.64-.97-.55-1.66-.63,1.48-.05,3.04.05,4.54.09,1.11-.31,2.37.09,3.38"/>
        <path   d="m433.18,278.9c-.03-1.16.17-2.23.34-3.3.26-1.66.4-3.2.55-4.91.09-1.07.67-3.29-.17-3.84-.5,1.76-.27,3.86-.74,5.72-.55,2.16-.39,4.31-.5,6.62"/>
        <path   d="m390.06,269.14c-.16.47-.29,1.27-.35,1.92.08-.33.59-1.92.89-1.62"/>
        <path   d="m391.69,270.32c.15-1.35.59-2.37.77-3.71.14-.95.26-1.94.36-2.94"/>
        <path   d="m385.07,270.73c-.15,1.7.58,4-.29,5.54"/>
        <path   d="m387.16,270.5c.09,1.19.8,1.54.56,2.96-.12.76-1.2,2.4-.58,3.04"/>
        <path   d="m399.08,269.69c-.51,1.42-1.21,3.37-1.29,5.06"/>
        <path   d="m388.36,266.53c-.08.97-.61,2.8-.49,3.63"/>
        <path   d="m399.67,261.65c0,1.48.13,2.87-.37,4.26-.5,1.32-.76,1.41-.83,3.09"/>
        <path   d="m399.25,274.01c2.13-2.04-.4-5.38.52-7.35"/>
        <path   d="m401.71,261.65c-.18,1.32-.06,2.66-.38,3.94-.29,1.13-.85,2.16-.53,3.61"/>
        <path   d="m401.49,274.24c.1-1.2.25-2.41.33-3.62.05-.78-.13-1.91.12-2.16"/>
        <path   d="m402.89,267.54c-.47,1.15-.13,1.89-.35,3.07"/>
        <path   d="m403.76,274.44c.05-.5.1-1.03.15-1.52.15-.04.32-.22.46-.21"/>
        <path   d="m394.8,266.02c-.02-.74.1-1.57-.26-2.04"/>
        <path   d="m406.14,262.52c-.12,1.3.02,2.73.68,3.27"/>
        <path   d="m405.49,265.73c-.85.83.35,3.13-.11,4.42"/>
        <path   d="m404.1,269.76c.14.49.04.99-.13,1.41"/>
        <path   d="m406.61,273.04c.62-.45.33-1.75.4-2.65"/>
        <path   d="m406.48,267.21c-.13.5-.13.94.05,1.15"/>
        <path   d="m407.3,267.94c.89-1.19.46-4.04.96-5.54"/>
        <path   d="m408.18,267.99c.57,1.64-.34,4.34-.21,6.25"/>
        <path   d="m363.87,274.73c-.05-1.13-1.24-6.11-.25-6.33"/>
        <path   d="m362.58,274.38c.3,1.27-.16,4.36-.3,5.81"/>
        <path   d="m363.87,277.33c.18-1.97.73-4.91.3-6.67"/>
        <path   d="m365.3,275.27c-.13,1.43-.48,3.28-.11,4.55"/>
        <path   d="m366.38,272.01c-.07.84-.51,3.57-.21,3.97"/>
        <path   d="m313.35,273.9c.17-1.85.53-3.49-.33-4.67-.15,1.75.17,3.52-.04,5.47"/>
        <path   d="m368.1,273.54c-.24-.61-.42-1.73-.45-2.52"/>
        <path   d="m314.87,274.4c.15-1.64.25-3.03.03-4.57.69-.27.43,1.1.37,1.94"/>
        <path   d="m369.22,268.69c.13,1.37-.77,4.43-.03,5.32.31-1.09-.17-5.46.8-5.46"/>
        <path   d="m317,270.53c-.17,1.35-.62,3.3.07,4.22.14-1.67.09-3.03.12-4.61"/>
        <path   d="m372.58,268.25c-.06.74-.79,5.31-.35,5.35.77.07.57-4.4.63-5.15"/>
        <path   d="m319.48,268.11c-.41,1.49-1.2,4.71-.22,5.56.04.32.11.39.18.65.27-1.9-.78-3.04-.25-4.7"/>
        <path   d="m375.03,268.84c-.2,1.75-.62,3.39-.67,5.15.87.34.93-3.53.84-4.41"/>
        <path   d="m323.28,266.93c.16,1.33.31,2.78.56,4.03.21,1.04.09,1.64.24,2.66.02-1.51-.27-4.68-.73-6.14"/>
        <path   d="m324.35,270.63c.25,2.09.41,4.11.66,6.15,1.22.33-.16-3.29-.21-4.2"/>
        <path   d="m325.17,266.2c.27,1.71.22,3.44.47,5.17.04,0,.18,0,.21,0,.06-.84-.16-1.86-.22-2.73.15.58.2,1.22.28,1.83.39-1.01.09-2.56-.09-3.85-.27.4-.08,1.15-.32,1.68"/>
        <path   d="m327.34,274.5c-.36-1.76.06-3.95-1.04-5.52.08.8.42,1.26.56,1.97.11.67-.03,1.11.04,1.71.12,1.07.47,2.92.77,3.92"/>
        <path   d="m328.88,270.93c.15,1.28.1,4.28.49,5.13.36-1.52.19-2.51-.02-4.3"/>
        <path   d="m386.52,269.69c-.06.78.39.92.42,1.55.02.64-.3,1.34-.42,1.92"/>
        <path   d="m403.38,261.82c-.15.58-.25,1.15-.32,1.8-.04.5,0,.94-.18,1.31-.29.67-.78.86-.82,1.87"/>
        <path   d="m404.44,268.43c0,.31-.13.55-.26.7-.14-.18-.43-.73-.48-1.07-.07-.6.24-1.5.29-2.12"/>
        <path   d="m405.65,271.61c.15,0,.26.15.32.38-.03.03-.1.05-.14.05-.01.05-.02.1,0,.14.1-.12.05.01.1-.19-.17.35-.14.84-.18,1.3-.05.58-.15.81.19,1.01"/>
        <path   d="m411.97,261.92c-.22.82-.25,1.48.04,2.18.28.66.2.95-.12,1.67"/>
        <path   d="m320.69,269.59c.08.88.56,1.57.29,2.52-.25.89-.64,1.38-.6,2.48"/>
        <path   d="m388.07,262.84c-.36.67-.66,1.33-.65,2.3.42-.5.67-1.21.68-2.1"/>
        <path   d="m371.02,272.97c.2-.47.38-1.07.48-1.62"/>
        <path   d="m393.34,270.4c-.05-.49-.17-.91-.07-1.44.59.09,1.03-1.68,1.17-2.51.09-.47.11-1.03.22-1.47.13-.44.37-.54.4-1.11-.19,1.31-.37,2.77-.66,3.99-.29,1.19-1.06,1.27-.82,2.75"/>
        <path   d="m397.35,263.04c.58,3.39-1.74,3.84-1.79,6.98.11-.01.26-.12.36-.23-.08-.2-.06-.51-.11-.65.21-.13.41-.26.6-.32"/>
        <path   d="m379.62,269.54c-.01,1.58-.31,3.83-.77,5.23-.23.73-.73,1.81-.15,2.32.12-.67.21-1.38.32-2.05.29.32.49.9.35,1.51.66-1.27.28-2.69.3-4.02,0-.98.46-2.08.18-3"/>
        <path   d="m379.25,269.84c-.05-.49-.17-.9-.07-1.44.58.09,1.03-1.68,1.17-2.51.09-.47.11-1.03.22-1.47.12-.46.37-.54.4-1.12-.19,1.31-.37,2.78-.66,3.99-.29,1.19-1.06,1.27-.82,2.74"/>
        <path   d="m382.58,269.15c.58,3.41-1.74,3.85-1.79,6.98.12-.02.27-.11.36-.22-.07-.19-.05-.52-.1-.66.21-.12.4-.26.61-.31"/>
        <path   d="m382.59,263.53c.17,1.36-.07,2.82-.29,4.21.46-1.51.83-2.81,1.08-4.46"/>
        <path   d="m384.38,266.01c-.16,1.96-.25,3.42-.78,5.2-.18.61-.63,1.3-.69,1.99.06.09.12.15.19.19.48-.71.67-1.9.86-2.93.18.08.36.23.5.41.41-1.15.38-2.99.53-4.3"/>
        <path   d="m386.43,262.84c-.02,1.57-.32,3.82-.77,5.23-.24.74-.73,1.81-.14,2.32.11-.68.21-1.37.32-2.06.28.31.48.9.35,1.52.67-1.28.28-2.7.29-4.03,0-.99.46-2.08.18-3"/>
        <path   d="m463.9,271.35s1.69-3.64.69-3.61"/>
        <path   d="m462.99,270.03s1.28-5.43-.57-4.46"/>
        <path   d="m461.5,272.86c.18-1.45.2-2,.11-3.2-.09-1.22,0-1.5.58-2.46"/>
        <path   d="m460.67,269.19c.38-1.25.31-2.08-.05-2.7.22-.37.35-.86.29-1.29"/>
        <path   d="m458.44,271.9c.12-.93.21-1.88.35-2.8.03.04.06.07.1.06-.31-.9.31-1.98.33-2.69"/>
        <path   d="m458.71,264.62c-.27.96-.4,1.83-.53,2.85"/>
        <path   d="m455.95,263.94c-.33,1.62-.06,2.17.17,3.5.18.96-.35,2.93.09,3.55"/>
        <path   d="m457.16,266.55c-.22,1.83-.31,3.54-.59,5.28"/>
        <path   d="m455.08,267.83c-.11.44-.35.77-.38,1.26-.02.32.17.57.15.92-.03.63-.06,1.01-.44,1.26"/>
        <path   d="m453.8,268.62c-.03-.97.26-1.67.18-2.76-.06-.8-.24-1.53.05-2.14"/>
        <path   d="m435.61,256.86c.6.74.7,1.96.93,3.12"/>
        <path   d="m435.32,257.72c-.18-.89-.64-2.45-.59-3.31.03-.55.96-1.61.1-1.99"/>
        <path   d="m433.92,255.61c-.4.96.22,3.34.47,4.59"/>
        <path   d="m433.23,251.89c.02.95.34,2.36.69,3.19"/>
        <path   d="m432.93,257.58c-.21-1.07.24-1.84.07-2.8"/>
        <path   d="m432.48,253.35c-.6.34-.94,1.87-.71,3.12.09.47,1.02,2.52.13,2.29-.46-.13-.65-2.1-.79-2.83-.24-1.21-.54-1.98-.84-2.91"/>
        <path   d="m426.42,250.1c.17,1.01.32,3.33.99,3.79.26-.85-.39-2.98-.6-4.02"/>
        <path   d="m428.14,254.13c.08.93.69,5.54,1.29,5.67,1.06.25-.61-4.33-.69-4.88"/>
        <path   d="m428.48,251.71c.07,1.3.33,3.45,1.07,4.4.55-.75-.22-4.41-.69-5.36"/>
        <path   d="m427.27,256.13c.22,1.13.16,2.18.63,3.2"/>
        <path   d="m426.18,254.91c-.19-.89-.63-1.64-.69-2.58-.05-.52.3-1.61-.14-1.9"/>
        <path   d="m424.74,254.48c.25,1.53,2.08,3.56,1.18,4.98"/>
        <path   d="m424.39,255.3c-.27-1.34-.3-1.85-.28-3.04.02-.66-.03-2.22-.53-2.29"/>
        <path   d="m423.33,255.56c.2.98.77,2.72.2,3.22"/>
        <path   d="m423,253.58c-.39-1.51-.12-2.98-.37-4.39"/>
        <path   d="m421.94,254.83c.27,1.36.91,3.42.35,4.39"/>
        <path   d="m421.34,249.5c.16.85.6,2.04.43,2.79"/>
        <path   d="m421.31,253.23c0,.26.05.7.14,1.05"/>
        <path   d="m421.25,259.8c-.24-1.09-.69-1.9-.95-2.98-.2-.77-.38-1.57-.55-2.39"/>
        <path   d="m410.87,248.34c.27,1.38-.13,3.34.71,4.52"/>
        <path   d="m408.97,250.22c.03.99-.52,1.33-.2,2.48.16.62,1.19,1.87.73,2.45"/>
        <path   d="m408.45,255.24c.54,1.12,1.28,2.65,1.49,4.05"/>
        <path   d="m407.32,248.83c.15.79.74,2.25.71,2.94"/>
        <path   d="m406.7,251.33c.12,1.21.14,2.37.67,3.47.52,1.05.74,1.09.95,2.47"/>
        <path   d="m406.51,254.36c-1.93-1.5-.13-4.45-1.06-5.99"/>
        <path   d="m404.23,250.73c.26,1.07.27,2.18.65,3.21.34.9.89,1.7.75,2.92"/>
        <path   d="m403.14,254.15c-.36-1.16-.76-2.3-1.09-3.47-.21-.76-.28-1.89-.58-2.08"/>
        <path   d="m404.71,257.21c.48.91.26,1.55.56,2.5"/>
        <path   d="m403.76,256.66c-.15-.48-.31-.99-.46-1.46-.16-.01-.36-.15-.5-.11"/>
        <path   d="m400.62,250.85c-.13-.73-.43-1.52-.18-2.05"/>
        <path   d="m399.43,248.39c.39,1.25.56,2.68.03,3.34"/>
        <path   d="m399.52,252.74c1.01.63.31,3.13,1.04,4.3"/>
        <path   d="m404.14,258.18c-.06.42.05.81.23,1.15"/>
        <path   d="m399.17,251.13c-.7-.31-.69-1.63-.94-2.51"/>
        <path   d="m398.42,251.63c.22.46.32.89.2,1.14"/>
        <path   d="m401.5,260.56c-1.13-.97-1.3-3.85-2.11-5.22"/>
        <path   d="m397.28,249.41c-.21,1.72,1.25,4.16,1.52,6.06"/>
        <path   d="m398.2,261.22c-.2-1.11-.08-6.23-1.1-6.24"/>
        <path   d="m394.99,249.64c-.02,1.31,1.09,4.23,1.53,5.62"/>
        <path   d="m397.02,259.6c-.59-1.88-1.76-4.65-1.71-6.46"/>
        <path   d="m393.37,249.84c.42,1.37,1.16,3.11,1.07,4.43"/>
        <path   d="m393.58,254.43c.25.81,1.26,3.38,1.05,3.84"/>
        <path   d="m392.84,256.83c-.56-1.77-1.26-3.3-.66-4.64.52,1.67.57,3.47,1.19,5.34"/>
        <path   d="m394.25,261.4c.1-.64.05-1.78-.09-2.55"/>
        <path   d="m393.14,260.83c-.5-1.58-.88-2.91-1-4.47-.73-.12-.19,1.17.06,1.97"/>
        <path   d="m390.96,257.76c.17,1.37,1.69,4.17,1.16,5.19-.54-1-.99-5.37-1.94-5.17"/>
        <path   d="m389.06,250.29c.45,1.28,1.3,3.09.82,4.13-.49-1.6-.73-2.94-1.09-4.48"/>
        <path   d="m388.49,254.06c.22.71,1.89,5.02,1.47,5.15-.74.23-1.49-4.18-1.71-4.9"/>
        <path   d="m388,256.9c.72,1.37,2.17,4.35,1.4,5.39.03.32-.02.41-.04.68-.67-1.8.12-3.14-.75-4.64"/>
        <path   d="m385.62,249.63c.56,1.67,1.32,3.18,1.74,4.89-.78.51-1.65-3.26-1.75-4.13"/>
        <path   d="m385.86,254.69c.39,1.28.86,2.65,1.14,3.9.24,1.04.59,1.53.87,2.53-.63-1.37-1.67-4.38-1.85-5.9"/>
        <path   d="m383.87,256.9c.63,2.02,1.31,3.92,1.91,5.89-.97.8-1.2-3.07-1.53-3.92"/>
        <path   d="m381.51,253.05c.45,1.67,1.21,3.22,1.69,4.91-.03,0-.16.07-.19.09-.4-.74-.62-1.76-.92-2.58.1.59.33,1.2.5,1.78-.76-.76-1.13-2.29-1.49-3.55.41.25.54,1.02.98,1.4"/>
        <path   d="m382.27,260.67c-.4-1.75-1.68-3.58-1.32-5.46.26.76.13,1.31.3,2.02.17.66.48,1,.66,1.57.33,1.03.77,2.86.91,3.89"/>
        <path   d="m378.37,249.91c.39,1.23,1.67,3.94,1.66,4.89-.95-1.24-1.21-2.22-1.75-3.93"/>
        <path   d="m473.87,269.71c-.75.03-1.08.15-1.28,1.84"/>
        <path   d="m471.24,271.99c.47-1.38,1.14-2.8,1.23-4.4.08-1.55-.47-2.41.02-4.11-.3,1.08-.47,2.1-.64,3.49"/>
        <path   d="m470.97,265.74c-.12.44-.35.82-.58,1.04-.13-.64.11-1.65.18-2.41-.08-.03-.16-.02-.24-.02"/>
        <path   d="m470.84,267.46c-.11.93.15,1.61.08,2.5-.08,1.24-.3,1.23-.82,2.01"/>
        <path   d="m469.12,271.7c.13-1.16.37-2.98.2-3.97-.63.65-1.27,2.73-1.63,3.9"/>
        <path   d="m468.94,263.9c-.07.55-.56,3.11-.2,3.31.37.21.81-1.83.85-2.44"/>
        <path   d="m466.54,262.99c-.42,2.1-.75,4.11-1.02,6.29-.08.7-.33,1.76-.08,2.2.62-.93.98-3.46,1.38-4.8.29-.95.59-1.93.86-2.86"/>
        <path   d="m483.99,264.66c-.2.88-.34,1.94-.39,2.83.46-.1.9-1.04.97-1.96"/>
        <path   d="m482.96,267.27c-.31,2.51-.59,4.96-.78,7.43-.08,1-.37,3.59-1.07,3.12-.99-.64.65-3.03.77-4.23.09-.87-.16-1.57-.12-2.44.04-1.3.33-2.79.48-4.1"/>
        <path   d="m477.43,267.13c.11,2.08.81,4.36,1.09,6.42.17,1.23.74,1.78.89,2.88-.03-1.03-.38-1.97-.53-2.95-.1-.72-.16-1.47-.26-2.17-.23-1.63-.39-2.86-.35-4.46"/>
        <path   d="m447.05,265.38c.17,1.51.04,4.12.73,5.29.09-.85.01-1.77.05-2.61.04-.7.16-1.32.2-2.03"/>
        <path   d="m445.74,266.09c.09.92-.48,1.33-.41,2.22.04.66.58,1.76.82,2.29.57,1.24.89,1.62,1.05,3.29-.32-2.89-.54-6.1-.71-8.99-.09-.02-.21-.02-.3.03"/>
        <path   d="m443.73,263.86c.02.66-.18,1.25-.2,1.77.05-.11.21-.25.27-.33-.07-.04-.15.03-.2,0,.08-.06.26-.06.36-.07-.17.64.09,1.48-.16,2.16-.31.84-1.03.46-1.22,1.41-.27,1.42.77,3.71,1.37,4.33.07-.93-.21-1.87-.16-2.78.03-.85.35-1.06.52-1.63.37-1.23.03-3.02-.1-4.49"/>
        <path   d="m442.42,263.04c.06.63-.15,1.18-.15,1.81,0,.47.09,1.04.14,1.5.32-.69.43-2.26.51-3.17"/>
        <path   d="m440.71,263.35c.18,1.9.59,3.72.81,5.58.12.95-.02,2.49.47,3.1.15-1.42-.25-3.57-.39-5.06-.14-1.54-.11-2.28.16-3.69"/>
        <path   d="m439.03,262.74c.15,1.72.34,3.48.54,5.16.33-1.36-.54-4.45.46-4.99"/>
        <path   d="m437.42,264.65c-.28,1.56.24,2.65.71,3.74.37.83.42,1.74.5,2.82.02-1.14.07-1.99-.03-3.14-.1-1.08-.23-2.17.09-3.07,0-.12-.05-.15-.13-.09"/>
        <path   d="m427.26,264.46c.14,1.9.64,3.99,1.27,5.57.27-1.05-.36-2.64-.44-3.86-.04-.78,0-1.51-.08-2.31"/>
        <path   d="m426.43,265.77c.15,1.6.17,3.56.77,4.8.39.81.81,1.12.87,2.34-.25-1.33-.3-2.66-.49-4.04-.16-1.15-.38-2.13-.49-3.33"/>
        <path   d="m409.61,248.5c.11.63-.24.79-.21,1.31.03.53.36,1.08.51,1.54"/>
        <path   d="m406.43,255.25c.18.47.3.93.42,1.46.08.41.09.77.26,1.06.3.52.72.64.83,1.47"/>
        <path   d="m401.21,254.07c.07.31.23.51.4.63.09-.21.27-.81.24-1.14-.06-.61-.55-1.42-.73-2.02"/>
        <path   d="m402.34,257.42c-.15.03-.22.2-.23.44.04.03.11.03.14.03.03.04.05.09.03.13-.12-.1-.05.02-.14-.16.24.3.32.78.45,1.23.17.55.32.75.03,1.02"/>
        <path   d="m395.47,257.97c.4.75.56,1.39.42,2.14-.14.71,0,.97.46,1.61"/>
        <path   d="m385.94,258.08c.11.87-.21,1.65.25,2.53.43.82.91,1.21,1.1,2.3"/>
        <path   d="m383.24,261.72c.15.14.41.4.53.64.19.4.05.4.15.83-.07-.04-.1,0-.18-.07.05.07.09.14.15.2,0-.37-.2-.36-.28-.62-.07-.19-.04-.43-.11-.65"/>
        <path   d="m426.43,270.84c.04-1.86-.55-3.35-.85-5.15-.12-.69-.09-1.16,0-1.84.08-.63,0-1.09-.33-.44-.2.43-.16,1.2-.22,1.78-.08.71-.08,1.44.15,2.14.15.41.36.73.48,1.18.12.47.13,1.06.18,1.57"/>
        <path   d="m423.05,264.72c.48,1.56.89,2.93,1.05,4.67.09.94.07,1.89.42,2.57h.11c.19-1.35-.16-2.72-.57-3.9-.42-1.22-.79-2.66-.95-4.05"/>
        <path   d="m422.57,270.59c-.18-1.86-.39-3.65-.77-5.41-.12-.58-.2-1.13-.16-1.74.02-.34.09-.66.06-1.02-.65.12-.2,3.4-.11,4.15.11.86.35,1.64.61,2.36.14.34.33.63.36,1.06"/>
        <path   d="m421.8,271.8c-.16-1.73-.31-3.05-.96-4.43-.17-.37-.36-.75-.43-1.22-.06-.5.04-.96-.05-1.47-.88.32-.12,2.44.21,3.33.21.57.41.79.48,1.43.06.5.1,1.01.14,1.52"/>
        <path   d="m420.11,271.53c-.08-.87-.15-1.73-.2-2.58-.25.64-.01,1.93.06,2.73"/>
        <path   d="m420.11,268.86c-.13-1.43-.63-2.14-.98-3.36-.3-1.02-.31-2.19-.4-3.3"/>
        <path   d="m431.98,264.54c.71,2.13.98,4.5,1.24,6.89-.15-.74-.21-1.36-.34-2.1-.11-.69-.19-1.64-.34-2.33-.12-.55-.37-.86-.46-1.42-.08-.48-.1-1.07-.14-1.57"/>
        <path   d="m431.62,264.87c.12.99-.06,1.64-.05,2.46-.06-.63-.11-1.25-.17-1.88"/>
        <path   d="m431.68,269.26c.3.55.55,1.1.54,1.9-.35-.41-.55-1-.56-1.73"/>
        <path   d="m430.65,266.47c.1,1.14.12,2.36.27,3.47.22-1,.22-2.37.1-3.45"/>
        <path   d="m392.82,251.1c-.22-.48-.54-.94-.85-1.06,0,.68-.31,1.23-.08,2.02"/>
        <path   d="m390.72,252.05c-.04-.28-.09-.57-.16-.85"/>
        <path   d="m391.64,255.17c-.3-.42-.6-.96-.81-1.49"/>
        <path   d="m387.57,255.93c-.23-.4-.64-.86-.9-.97.07.59.34,1.23.52,1.82"/>
        <path   d="m384.29,252.69c.22.71.45,1.41.67,2.12"/>
        <path   d="m381.43,261.91c.25.42.56,1.18.75,1.8-.15-.3-.98-1.76-1.21-1.39"/>
        <path   d="m383.98,255.25c.13-.7-.3-1.42-.69-1.89.12-.67.61.04.47.63-.09.47-.81,1.09.12,1.53"/>
        <path   d="m474.74,271.27c.17-.8-.1-1.8-.41-2.01"/>
        <path   d="m490.95,267.57c-.21.66-.58.88-.83,1.39"/>
        <path   d="m485.06,255.93c-.07.42.05,1.12-.08,1.48-.08.22-.35.31-.49.41-.11.57.16,1.34.37,1.52"/>
        <path   d="m490.99,266.07c.32-.71.17-1.18,0-1.66-.16-.45-.28-1.17-.26-1.85"/>
        <path   d="m490.06,265.43c.02-.22.05-.44.09-.67-.5.21-.65.95-.98,1.6"/>
        <path   d="m489.28,267.62c-.09.62,0,1.35.37,1.12.11-.47-.21-1.04-.29-1.43-.09.07-.1.18-.13.31"/>
        <path   d="m482.18,262.48c.23-1.63-.21-2.85-.06-4.33.08-.8.33-1.18.48-1.96.09-.45.23-1.49-.15-1.21-.03.35-.26,1.56-.4,1.91-.02,0-.06,0-.09,0,0-.43.21-.98.19-1.35-.04-.66-.43.21-.53.69-.17.99.17,2.01.32,2.72.1.45.02.9.09,1.34.08.52.2.55.05,1.16-.21.89-.54,1.62-.15,2.3.08-.22.13-.51.14-.77"/>
        <path   d="m487.57,269.49c.14-1.04-.07-1.82,0-2.76.08-1.13.23-2.25.05-3.2-.07-.39-.14-.65-.12-1.09.01-.41.2-.88.21-1.23.07-1.25-.46-.18-.67.25-.06-.24-.08-.51-.05-.8-.44.71.3,3.06.41,3.65.11.58.19,1.23.19,1.9,0,.65-.1.66-.24,1.21-.34.27-.56,1.07-.43,1.57.24.09.29-.54.49-.42.12.07.14.68.13.92"/>
        <path   d="m479.39,254.42c-.36.93-.36,1.77-.31,2.67.05.95.42,1.66.43,2.53,0,.99-1.01,2.87-.29,3.48.37-.51.28-1.71.33-2.36.06-.73.31-1.68.29-2.39,0-.8-.35-1.1-.4-1.91-.05-.67.04-1.27.18-2.02"/>
        <path   d="m485.69,265.66c-.08.64-.46,2.43.03,2.39.25-.88.16-2.09.49-2.8"/>
        <path   d="m484.58,261.6c-.09.75-.22,1.56-.24,2.29-.03.77.14,1.36.13,2.11,0,.93-.31,1.09-.39,1.96-.06.63.09,1.03.45.83.21-.84-.13-1.25-.07-2,.05-.67.31-1.2.32-1.89,0-.74-.15-1.13-.08-1.96.04-.61.14-1.24.21-1.84"/>
        <path   d="m477.07,255.15c-.3,1.79-.99,3.41-.61,4.94.24.93.4,1.35.17,2.66-.11-.34-.2-.76-.37-.99-.15.31-.26.73-.3,1.1-.66.31.11-1.39.19-1.72.18-.76-.01-1.8-.02-2.6,0-1.25-.09-4.16.85-4.64"/>
        <path   d="m474.74,255.76c-.47.15-.59,1.4-.69,2.13-.15,1.04-.13,1.67-.07,2.57.04.67.14,1.5.08,2.2-.04.58-.25,1.41.05,1.65.08-.3.11-.6.14-.9.19-.02.44.19.42.63-.02.48-.29.59-.39.97-.18.65.09,1.38.38,1.38.09-.48.09-1,.12-1.47.02-.31.06-.5.03-.8-.03-.3-.31-.71-.27-1,.05-.55.5-1.01.52-1.73.02-.76-.18-.4-.43-.57-.9-.58-.08-3.3,0-4.68"/>
        <path   d="m472.29,262.11c-.03-.83.31-.78.37-1.41.06-.76-.43-1.33-.64-1.82-.43-1-.24-2.28-.34-3.45-.04-.38-.22-1.28.05-1.44.11-.08.33.28.36.54.03.28-.1.52-.1.81-.03.86.3,1.89.57,2.53.2.51.58,1.01.65,1.65.05.51-.1,1.13-.07,1.67.01.37.24,1.37-.14,1.18,0-.04.03-.03.03-.02-.06-.3-.07-.55-.2-.75-.12.31-.38.75-.58.41"/>
        <path   d="m481.19,261.37c.05.52.04,1.36.15,1.83.1.46.34.83.48,1.21.25.7.54,1.56.58,2.41.4-1.41.03-2.31-.47-3.28-.43-.81-.53-1.55-.63-2.63"/>
        <path   d="m468.37,253.73c.15,1.66.29,2.57.85,3.87.31.72.71,1.73.39,2.5-.46-.47-.53-.85-.63-1.7-.09-.76-.19-1.07-.43-1.7-.22-.55-.37-1.13-.4-1.85-.03-.55.35-2.05.22-2.19"/>
        <path   d="m479.08,260.62c-.65.76-.44,2.62.02,3.62.4.86.5,2.28,1.04,2.87.03-.83-.43-1.36-.63-2.04-.15-.51-.19-.93-.25-1.53-.07-.73-.2-1.05-.34-1.64-.12-.51-.16-.93.08-1.18"/>
        <path   d="m465.91,253.2c.13,1.44.19,1.9.73,2.85.29.52.8,1.64.35,2.23-.03-.87-.1-.93-.43-1.13-.19-.12-.26.12-.43-.1-.13-.16-.27-.76-.32-1.02-.24-1.08.04-2.49-.27-3.4"/>
        <path   d="m465.58,256.03c.07.86-.11,1.74.07,2.72.14.88.46,1.21.74,2,.11.31.6,1.41.58,1.77-.04.68-.54.37-.7-.04-.2-.54-.11-1.34-.22-1.92-.11-.54-.35-.92-.47-1.52-.1-.52-.21-1.27-.21-1.82,0-.51.31-1.33.03-1.69"/>
        <path   d="m464.66,254.44s.23.59.28,1.01"/>
        <path   d="m464.05,254.27c-.1.39-.08.89-.02,1.33"/>
        <path   d="m464.61,253.63c-.06-.39-.04-.76-.06-1.15"/>
        <path   d="m463.88,256.51c.39,1.01.62,1.57.78,2.87.06.52.12,1.02.26,1.47.12.38.32.52.36.98-.19.12-.71.04-.88-.17-.48-.6-.36-1.41-.32-2.05.05-.93-.13-1.85-.21-2.81"/>
        <path   d="m462.52,258.19c.07.44.51.75.74.48.41-.47-.03-1.24-.27-1.73-.3-.6-.48-.93-.6-1.73-.16-.91-.1-1.81-.59-2.45-.09.64.09,1.8.23,2.48.09.46.34.81.45,1.26.12.54.1,1.09.14,1.66"/>
        <path   d="m461.19,260.19c.06-.4.18-.72.12-1.16-.48-.03-.77-1.54-.86-2.25-.04-.39-.03-.84-.11-1.23-.07-.38-.28-.5-.28-.98.1,1.1.18,2.32.36,3.36.18,1.01.81,1.21.54,2.37"/>
        <path   d="m445.5,262.98c.1-.52-.1-.85-.38-.63,0,.14.01.36.05.5"/>
        <path   d="m460.52,253.35c0-.17-.05-.33-.1-.51-.24,0-.29.44-.15.74"/>
        <path   d="m443.89,260.3c.23.31.49.89.3,1.36"/>
        <path   d="m458.6,251.58c-.63,2.68,1.26,3.44,1.15,5.99-.1-.03-.21-.13-.28-.25.06-.14.07-.41.11-.51-.17-.14-.33-.28-.48-.36"/>
        <path   d="m458.75,256.18c-.21,1.09-.07,2.31.05,3.49-.3-1.31-.56-2.44-.68-3.8"/>
        <path   d="m456.79,251.26c.04,1.62.04,2.82.4,4.37.12.52.45,1.16.48,1.74-.06.07-.1.11-.16.13-.37-.67-.46-1.65-.57-2.53-.15.04-.31.13-.44.24-.28-1-.17-2.49-.23-3.58"/>
        <path   d="m455.84,253.26c-.06,1.29.08,3.17.4,4.39.16.64.52,1.59,0,1.9-.07-.57-.11-1.16-.16-1.73-.26.21-.45.65-.37,1.18-.49-1.15-.1-2.24-.05-3.32.04-.82-.28-1.78,0-2.48"/>
        <path   d="m454.6,251.09c.34,1.64.22,3.1.14,4.67"/>
        <path   d="m454.38,259.47c-.15-.82-.39-1.53-.54-2.29-.12-.62-.11-.9-.41-1.43-.11-.17-.3-.34-.36-.5-.27-.63-.26-1.34-.34-2.13-.1-1.01-.21-.93-.74-.84"/>
        <path   d="m451.73,251.8c-.23.95.53,2.9.73,3.83.07.35.42,1.56.3,1.9-.19.61-.45-.03-.54-.39-.11-.49-.02-1.03-.09-1.52-.07-.5-.27-.94-.37-1.44-.23-1.11-.47-2.28-.56-3.43.11.25.24.63.25,1.01"/>
        <path   d="m450.56,253.33c.09.93.11,2.03.31,2.91.11.51.49,1.05.57,1.53.05.39.04.73-.16.83-.39.19-.4-.82-.44-1.28-.13-1.37-.65-2.8-.52-4.22"/>
        <path   d="m449.63,254.22c-.1-1.11.09-2.4,0-3.59.48,1.51.38,2.28.34,3.72"/>
        <path   d="m439.94,253.63c.56.98.72,1.51.82,2.89-.35-.86-1.06-2.22-1.22-3.18"/>
        <path   d="m431.54,262.56c.05.46.03.94.23,1.27.01-.28,0-.58-.04-.86"/>
        <path   d="m440.11,254.69c-.08-.91.04-1.56.01-2.4-.03-.82-.35-1.8-.56-2.48,1.21.4.47,3.64,1.2,4.53-.09-.2-.17-.33-.26-.52"/>
        <path   d="m438.76,251.68c.53.57-.1,1.67-.14,2.34-.07.85.46,1.64.29,2.25"/>
        <path   d="m437.76,256.92c-.03-.27-.05-.53-.08-.78"/>
        <path   d="m437.6,250.25c.2.73.75,2.2.61,3.09-.15.91-.77-.14-.78-.92.98.21-.24-2.03-.28-2.44"/>
        <path   d="m436.88,256.5c-.27-.35-.67-1.14-.57-1.84.03-.27.24-.34.27-.71.02-.25-.06-.64-.11-.85.1.68.38,1.21.45,1.91.06.52.07,1.06.12,1.58"/>
        <path   d="m433.18,262.78c.1,1.14.13,2.36.27,3.48.22-1,.22-2.37.1-3.46"/>
        <path   d="m446.96,250.52c.08.94.11,2.02.31,2.91.12.51.5,1.05.56,1.53.06.39.05.73-.15.82-.4.2-.4-.81-.45-1.28-.13-1.38-.65-2.8-.53-4.21"/>
        <path   d="m447.02,257.56c.03-.41.14-.75.05-1.19-.48.07-.85-1.38-.97-2.08-.06-.39-.08-.84-.18-1.22-.09-.36-.3-.44-.33-.92.16,1.09.31,2.3.55,3.3.23.99.87,1.05.67,2.27"/>
        <path   d="m444.24,250.22c-.47,2.8,1.44,3.17,1.47,5.77-.09,0-.21-.09-.29-.2.06-.15.05-.42.09-.53-.18-.1-.33-.22-.5-.26"/>
        <path   d="m444.56,254.17c-.14,1.13.06,2.33.25,3.49-.38-1.26-.69-2.34-.89-3.68"/>
        <path   d="m442.5,250.16c.14,1.62.21,2.82.65,4.3.15.5.52,1.08.57,1.64-.05.08-.1.12-.15.17-.4-.6-.55-1.57-.71-2.43-.15.07-.29.2-.42.33-.33-.94-.31-2.47-.43-3.54"/>
        <path   d="m441.71,251.16c0,1.3.26,3.15.63,4.32.19.6.6,1.48.11,1.9-.1-.55-.18-1.14-.26-1.7-.24.26-.4.75-.29,1.25-.55-1.05-.23-2.21-.24-3.33,0-.81-.38-1.7-.15-2.47"/>
        <path   d="m420.44,254.15c0-.41.06-.76-.07-1.19-.47.12-.99-1.29-1.18-1.96-.11-.38-.18-.84-.31-1.19-.14-.35-.35-.41-.42-.88.27,1.06.54,2.25.88,3.22.34.95.98.95.9,2.19"/>
        <path   d="m417.93,254.56c-.18,2.84,1.76,3,2.07,5.58-.09,0-.22-.07-.32-.16.05-.17,0-.43.03-.54-.18-.09-.35-.18-.52-.21"/>
        <path   d="m417.5,249.18c-.03,1.14.29,2.31.6,3.44-.5-1.2-.93-2.24-1.27-3.56"/>
        <path   d="m415.89,251.7c.3,1.6.5,2.79,1.08,4.21.2.48.63,1.02.74,1.58-.03.08-.08.13-.13.17-.45-.55-.71-1.5-.95-2.34-.14.09-.28.23-.38.38-.44-.91-.56-2.42-.8-3.48"/>
        <path   d="m414.68,253.56c.15,1.29.58,3.12,1.08,4.23.26.58.76,1.42.32,1.89-.15-.54-.29-1.11-.44-1.66-.21.28-.32.78-.16,1.27-.66-.99-.46-2.18-.59-3.28-.09-.81-.55-1.67-.4-2.45"/>
        <path   d="m415.09,253.69c0-.4.07-.76-.07-1.18-.47.12-.99-1.29-1.18-1.96-.11-.38-.18-.83-.31-1.19-.14-.36-.35-.41-.42-.88.27,1.06.54,2.25.88,3.22.34.95.98.95.9,2.18"/>
        <path   d="m412.34,253.81c-.18,2.85,1.76,3.01,2.07,5.58-.1,0-.23-.07-.32-.16.04-.16,0-.43.03-.55-.19-.08-.36-.18-.53-.2"/>
        <path   d="m412.63,249.14c-.03,1.14.3,2.31.61,3.44-.51-1.21-.93-2.24-1.27-3.57"/>
        <path   d="m410.91,251.78c.3,1.59.5,2.78,1.09,4.21.2.48.63,1.01.74,1.58-.04.07-.08.13-.14.17-.45-.54-.71-1.5-.96-2.33-.14.08-.27.22-.38.38-.44-.91-.57-2.43-.8-3.49"/>
        <path   d="m410.22,253.45c.15,1.29.59,3.11,1.08,4.23.26.58.76,1.42.31,1.89-.15-.55-.29-1.11-.44-1.66-.2.28-.32.78-.16,1.28-.66-1-.46-2.2-.59-3.29-.09-.82-.56-1.67-.41-2.45"/>
        <path   d="m418.92,270.6c.04-.4.14-.75.06-1.19-.48.07-.85-1.39-.98-2.08-.06-.38-.08-.84-.18-1.21-.1-.37-.31-.45-.33-.93.16,1.09.31,2.3.55,3.29.24.99.87,1.05.68,2.28"/>
        <path   d="m416.01,265.29c-.47,2.81,1.44,3.18,1.48,5.77-.1-.01-.22-.09-.3-.19.06-.16.05-.42.09-.54-.18-.09-.34-.22-.51-.26"/>
        <path   d="m416.92,261.95c-.14,1.14.06,2.34.25,3.5-.38-1.25-.69-2.34-.9-3.68"/>
        <path   d="m414.72,263.25c.14,1.62.21,2.82.65,4.3.15.51.52,1.08.57,1.65-.05.08-.1.12-.15.16-.39-.59-.55-1.56-.71-2.42-.14.06-.3.19-.42.33-.34-.95-.32-2.47-.44-3.55"/>
        <path   d="m413.86,264.31c.01,1.31.26,3.16.64,4.33.19.61.61,1.49.11,1.91-.1-.56-.17-1.14-.26-1.7-.24.26-.4.74-.29,1.26-.54-1.05-.23-2.23-.24-3.33,0-.82-.38-1.71-.15-2.48"/>
        <path   d="m448.72,252.1c-.23.96.53,2.89.73,3.83.06.34.42,1.56.3,1.9-.2.61-.45-.03-.54-.39-.11-.49-.03-1.03-.09-1.52-.08-.49-.27-.94-.37-1.43-.22-1.12-.46-2.29-.56-3.43.1.25.23.63.25,1"/>
        <path   d="m379.45,255.97c.31,1.01.99,2.38.88,3.31"/>
        <path   d="m378.52,254.64c.02.32.15.83.31,1.24"/>
        <path   d="m380.08,263.41c-.43-1.28-1.08-2.19-1.54-3.46-.34-.9-.67-1.84-.97-2.8"/>
        <path   d="m366.33,260.16c.51,1.62.28,4.03,1.45,5.35"/>
        <path   d="m364.23,260.37c.17,1.19-.46,1.67.08,3.02.28.72,1.68,2.09,1.21,2.85"/>
        <path   d="m362.23,261.69c.81,1.28,1.89,3.03,2.33,4.67"/>
        <path   d="m362.22,256.75c.29.93,1.18,2.61,1.24,3.44"/>
        <path   d="m359.95,253.96c.3,1.44.48,2.83,1.27,4.08.76,1.19,1.04,1.21,1.47,2.84"/>
        <path   d="m362.98,265.94c-2.52-1.55-.74-5.34-2.07-7.07"/>
        <path   d="m357.95,254.39c.45,1.26.62,2.59,1.21,3.78.52,1.05,1.29,1.93,1.29,3.41"/>
        <path   d="m360.84,266.65c-.36-1.16-.76-2.3-1.09-3.47-.21-.75-.28-1.89-.58-2.08"/>
        <path   d="m358.04,260.39c.71,1.03.53,1.83,1,2.93"/>
        <path   d="m358.66,267.32c-.15-.48-.31-.99-.47-1.46-.16-.01-.36-.15-.5-.11"/>
        <path   d="m353.82,256.17c.39,1.25.56,2.68.03,3.34"/>
        <path   d="m355.13,259.18c1.01.63.31,3.13,1.04,4.29"/>
        <path   d="m357.34,262.82c-.03.51.17.97.43,1.35"/>
        <path   d="m355.58,266.56c-.7-.31-.69-1.64-.94-2.5"/>
        <path   d="m354.48,260.83c.22.46.32.89.2,1.14"/>
        <path   d="m353.91,263.57c-1.12-.98-1.29-3.85-2.09-5.22"/>
        <path   d="m353.06,263.81c-.22,1.72,1.23,4.17,1.5,6.07"/>
        <path   d="m350.98,265.52c-.19-1.11-.06-6.23-1.07-6.25"/>
        <path   d="m352.18,264.92c-.03,1.31,1.07,4.23,1.51,5.62"/>
        <path   d="m351.53,268.07c-.58-1.89-1.73-4.66-1.69-6.47"/>
        <path   d="m349.7,266.35c.42,1.37,1.15,3.11,1.06,4.43"/>
        <path   d="m347.97,263.39c.25.81,1.24,3.39,1.04,3.84"/>
        <path   d="m348.36,270.23c-.55-1.78-1.25-3.31-.65-4.64.51,1.68.56,3.48,1.17,5.34"/>
        <path   d="m346.61,265.24c.1-.64.05-1.78-.08-2.55"/>
        <path   d="m346.98,271.03c-.49-1.58-.87-2.92-.98-4.47-.73-.13-.19,1.16.05,1.97"/>
        <path   d="m344.5,260.73c.16,1.37,1.67,4.17,1.14,5.2-.53-1-.97-5.37-1.92-5.17"/>
        <path   d="m344.09,267.7c.44,1.28,1.29,3.1.8,4.14-.48-1.6-.72-2.94-1.07-4.48"/>
        <path   d="m341.13,261.01c.21.71,1.87,5.02,1.45,5.16-.74.23-1.47-4.18-1.69-4.91"/>
        <path   d="m341.15,265.84c.71,1.38,2.15,4.36,1.37,5.39.02.32-.02.4-.04.67-.66-1.81.13-3.14-.73-4.64"/>
        <path   d="m338.86,262.09c.55,1.67,1.31,3.19,1.72,4.9-.78.51-1.64-3.27-1.73-4.14"/>
        <path   d="m337.48,263.01c.39,1.28.85,2.66,1.13,3.91.23,1.04.59,1.53.87,2.53-.64-1.37-1.66-4.38-1.84-5.91"/>
        <path   d="m338.01,266.82c.62,2.02,1.29,3.92,1.89,5.89-.97.8-1.19-3.06-1.51-3.92"/>
        <path   d="m335.47,263.11c.44,1.68,1.2,3.23,1.68,4.92-.04,0-.16.06-.2.09-.39-.74-.61-1.77-.91-2.59.1.59.32,1.2.49,1.78-.76-.76-1.13-2.3-1.48-3.56.4.25.54,1.02.98,1.41"/>
        <path   d="m336.86,271.58c-.39-1.75-1.67-3.59-1.3-5.46.26.77.13,1.32.29,2.03.17.65.48,1,.65,1.58.33,1.02.76,2.86.89,3.89"/>
        <path   d="m334,268.94c.38,1.23,1.64,3.95,1.63,4.89-.95-1.24-1.2-2.22-1.73-3.93"/>
        <path   d="m364.68,259.44c.21.75-.19.99-.08,1.61.11.63.57,1.25.82,1.79"/>
        <path   d="m356.36,254.9c.27.54.49,1.08.69,1.7.15.48.21.91.45,1.24.43.6.95.68,1.2,1.66"/>
        <path   d="m356.73,261.59c.08.3.24.51.4.63.09-.21.27-.81.24-1.14-.06-.61-.55-1.41-.73-2.02"/>
        <path   d="m356.21,264.95c-.15.03-.22.2-.23.44.04.02.11.03.14.02.03.04.05.09.03.13-.12-.1-.04.03-.14-.16.24.31.32.79.46,1.23.17.55.31.76.03,1.03"/>
        <path   d="m340.29,267.54c.11.88-.22,1.65.24,2.53.43.82.91,1.22,1.1,2.3"/>
        <path   d="m343.63,265.3c-.29-.42-.6-.97-.81-1.49"/>
        <path   d="m378.49,263.83c-.05-.49-.03-.93-.24-1.43-.56.2-1.37-1.42-1.68-2.21-.18-.44-.32-.98-.52-1.39-.22-.41-.48-.45-.63-1,.47,1.23.95,2.63,1.48,3.76.53,1.1,1.3,1.01,1.38,2.51"/>
        <path   d="m373.01,257.49c.16,3.44,2.52,3.38,3.23,6.44-.12.01-.28-.06-.41-.15.04-.21-.05-.51-.03-.66-.24-.08-.45-.17-.66-.18"/>
        <path   d="m371.01,251.16c.57,1.88.97,3.29,1.86,4.92.31.55.89,1.15,1.1,1.81-.03.1-.08.17-.14.22-.62-.6-1.06-1.71-1.46-2.68-.15.12-.3.31-.41.5-.64-1.04-1-2.84-1.42-4.08"/>
        <path   d="m371.4,257.84c.35,1.54,1.12,3.67,1.86,4.95.38.67,1.1,1.61.63,2.23-.26-.63-.5-1.3-.75-1.94-.21.37-.28.98-.02,1.55-.92-1.1-.84-2.57-1.15-3.87-.21-.96-.88-1.94-.81-2.89"/>
        <path   d="m371.82,258.05c-.06-.49-.02-.92-.24-1.42-.55.21-1.36-1.42-1.68-2.2-.18-.44-.32-.98-.53-1.39-.21-.42-.48-.45-.63-1.01.46,1.24.95,2.64,1.49,3.76.53,1.09,1.3,1.02,1.38,2.5"/>
        <path   d="m368.42,258.09c.16,3.45,2.52,3.39,3.23,6.45-.12,0-.28-.05-.4-.15.03-.2-.05-.52-.04-.66-.24-.08-.45-.17-.66-.18"/>
        <path   d="m367.22,252.59c.12,1.37.67,2.74,1.18,4.06-.76-1.39-1.41-2.58-2-4.13"/>
        <path   d="m366,255.39c.57,1.88.97,3.29,1.86,4.92.31.55.9,1.14,1.1,1.8-.04.1-.08.17-.14.22-.61-.59-1.05-1.71-1.46-2.68-.16.12-.3.3-.41.5-.65-1.04-1-2.85-1.42-4.09"/>
        <path   d="m363.33,252.74c.35,1.54,1.12,3.67,1.86,4.95.39.67,1.1,1.61.63,2.23-.26-.64-.49-1.3-.75-1.94-.2.36-.28.98-.02,1.56-.93-1.11-.85-2.58-1.14-3.88-.22-.97-.89-1.94-.81-2.9"/>
        <path   d="m377.92,256.29c-.06-.49-.03-.92-.24-1.42-.55.2-1.36-1.42-1.69-2.22-.17-.43-.31-.97-.52-1.38-.21-.41-.47-.45-.63-1.01.47,1.25.96,2.64,1.49,3.75.53,1.11,1.3,1.02,1.38,2.52"/>
        <path   d="m373.09,251.04c.16,3.45,2.51,3.39,3.23,6.45-.12.01-.28-.05-.4-.15.03-.21-.05-.51-.03-.66-.24-.07-.46-.17-.66-.18"/>
        <path   d="m333.65,263.28c.56,1.67,1.31,3.19,1.73,4.9-.78.51-1.64-3.27-1.73-4.13"/>
        <path   d="m332.48,263.18c.39,1.28.85,2.66,1.13,3.9.23,1.04.59,1.53.86,2.53-.63-1.37-1.66-4.38-1.83-5.9"/>
        <path   d="m331.71,267.56c.62,2.02,1.3,3.92,1.89,5.89-.97.8-1.19-3.07-1.52-3.92"/>
        <path   d="m330.67,264.59c.44,1.67,1.2,3.23,1.67,4.91-.03.01-.16.07-.19.09-.39-.75-.61-1.77-.91-2.58.1.59.32,1.19.49,1.77-.76-.76-1.13-2.3-1.48-3.55.4.25.54,1.01.97,1.4"/>
        <path   d="m331.35,272.29c-.39-1.75-1.66-3.59-1.3-5.47.26.77.13,1.32.29,2.03.17.66.47,1,.65,1.58.33,1.02.76,2.85.9,3.89"/>
        <path   d="m328.49,269.42c-.39-1.75-1.66-3.59-1.3-5.47.26.77.13,1.32.29,2.03.17.66.47,1,.65,1.58.33,1.02.76,2.85.9,3.89"/>
      </g>
      <path id="División"   d="m419.79,281.43s.51,13.98.65,18.03c.8,22.55,0,59.51,0,59.51-1.26,64.03-.75,128.13-1.43,192.18v.21"/>
      <path id="Linea_2_lateral" data-name="Linea 2 lateral"   d="m415.27,281.71s-3.44,231.67-3.44,271.27"/>
      <path id="Linea_1_lateral" data-name="Linea 1 lateral"   d="m407.17,281.71s-3.44,231.67-3.44,271.27"/>
      <path id="Short"   d="m460.36,550.43l-.42,5.25s-35.39,3.21-47.56,3.63c-12.17.42-57.18-1.56-57.18-1.56l.3-5.32"/>
      <g id="Costuras">
        <path   d="m305.6,284.26s75.33-21.5,97.19-22.61c24.12-1.23,87.45,2.46,87.45,2.46"/>
        <path   d="m305.26,297.28s76.88-22.51,98.74-23.62c24.12-1.23,92.34,3.19,92.34,3.19"/>
        <path   d="m295.45,526.22s17.86,5.76,38.03,11.37c20.17,5.62,80.75,2.54,80.75,2.54,0,0,42.86-2.06,48.49-1.72,5.62.33,44.45-6.62,44.45-6.62"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default FaldaShortLateralSvg
