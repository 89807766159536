import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import SacoEditable from './SacoEditable'
import SacoTraseroEditable from './SacoTraseroEditable'

const ConjuntoSacoEditable = () => {
  const { selectedMenu } = useSelector((state: RootState) => state.uniforms)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('saco'))
    dispatch(
      setLabelsMenu([
        {
          label: 'saco',
          selected: 'saco',
          click: () => dispatch(setPantalonesMenu('saco'))
        },
        {
          label: 'saco trasero',
          selected: 'saco trasero',
          click: () => dispatch(setPantalonesMenu('saco trasero'))
        }
      ])
    )
  }, [])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'saco':
            return <SacoEditable />
          case 'saco trasero':
            return <SacoTraseroEditable />
          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}
export default ConjuntoSacoEditable
