import { createSlice } from '@reduxjs/toolkit'

export interface ApliedFiltersI {
  date?: string
  editorial?: string
  grado?: string
  grupoarticulo?: string
  seccion?: string
}

export interface FilterData {
  filters: any
  inputFilters: any
  loadingFilter: true
  apliedFilters: any
  totalSells:any[]
  LevelsAndGrades:any[]

}
export const INPUTS_INITIAL_STATE = {
  date: {
    label: 'Periodo de ventas',
    name: 'date',
    placeholder: ['Fecha de inicio', 'Fecha de fin'],
    value: '',
    type: 'date',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: [],
    defaultValue:[]
  },
  seccion: {
    label: 'Niveles',
    name: 'seccion',
    placeholder: 'Niveles',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  },
  grado: {
    label: 'Grados',
    name: 'grado',
    placeholder: 'Grados',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  },
  editorial: {
    label: 'Editorial',
    name: 'editorial',
    placeholder: 'Editorial',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  },
  grupoarticulo: {
    label: 'Grupo de articulos',
    name: 'grupoarticulo',
    placeholder: 'Grupo de articulos',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  }
}
const initialState: FilterData = {
  filters: {},
  inputFilters: INPUTS_INITIAL_STATE,
  loadingFilter: true,
  apliedFilters: {
    date: undefined,
    editorial: undefined,
    grado: undefined,
    grupoarticulo: undefined,
    seccion: undefined
  },
  totalSells:[],
  LevelsAndGrades:[]

}

export const authSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload
      }
    },
    setInputFilters: (state, action) => {
      return {
        ...state,
        inputFilters: action.payload
      }
    },
    setloadingFilter: (state, action) => {
      return {
        ...state,
        loadingFilter: action.payload
      }
    },
    setAppliedFilters: (state, action) => {
      return {
        ...state,
        apliedFilters: action.payload
      }
    },
    settotalSells: (state, action) => {
      return {
        ...state,
        totalSells: action.payload
      }
    },
    setLevelsAndGrades: (state, action) => {
      return {
        ...state,
        LevelsAndGrades: action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setFilters, setInputFilters, setloadingFilter, setAppliedFilters, settotalSells, setLevelsAndGrades } =
  authSlice.actions

export default authSlice.reducer
