interface GradesI {
  id: number
  name: string
  male_population?: number
  female_population?: number
  id_magento: number
  endListBooks: boolean
  listBooksChange: boolean
  endQuotas: boolean
  listQuotasChange: boolean
}

interface LevelsI {
  id: number
  name: string
  id_magento?: number
  grades: GradesI[]
}
interface ResponsabilitiesI {
  id: number
  name: string
}

const filterlevelsByPermission = (
  levels: LevelsI[],
  responsability: ResponsabilitiesI[],
  role:string
) => {
  if (role === 'Administrador') return levels
  const responsabilityIds = responsability.map((grade) => grade.id)
  const filteredLevels = levels.map((nivel) => {
    const filteredGrades = nivel.grades.filter((grade) =>
      responsabilityIds.includes(grade.id)
    )
    return { ...nivel, grades: filteredGrades }
  })
  const finalLevels = filteredLevels.filter(
    (nivel) => nivel.grades.length > 0
  )
  return finalLevels
}

export default filterlevelsByPermission
