import React, { Fragment, useEffect, useState } from 'react'
import style from './preview.module.scss'
import Navbar from 'components/Navbar/Navbar'
import FixedInfoFooter from '../components/fixedInfoFooter/FixedInfoFooter'

import PoloEditable from '../editor/components/clothing/PoloEditable'
import { RootState } from 'redux/store/store'
import { useSelector } from 'react-redux'

import SelectOption from '../components/selectOption/SelectOption'
import { IUniformsForm } from 'interfaces/uniforms-interface'
import SelectedCardsOptionsHOC from '../editor/components/selectCardOption/SelectedCardsOptionsHOC'
import { getCorrectInformation } from 'utilities/uniforms-utilities'

interface Iprueba {
  [key: string]: IUniformsForm[]
}

const PreviewUniformModule = () => {
  const { poloValues } = useSelector((state: RootState) => state.uniforms)

  const [showClotheInfo, setShowClotheInfo] = useState<Iprueba>({})

  const agroupInfo = () => {
    let groups: string[] = []
    let groupedArr: any = {}
    Object.keys(poloValues).forEach((item, key: number) => {
      const objKey = item as keyof typeof poloValues
      const objInput = poloValues[objKey]
      if (!groups.includes(objInput.group)) groups.push(objInput.group)
    })
    groups.forEach((thisGroup: string) => {
      let cleanArr: any = []
      Object.keys(poloValues).forEach((item, key: number) => {
        const objKey = item as keyof typeof poloValues
        const objInput = poloValues[objKey]
        if (objInput.group === thisGroup) cleanArr.push(objInput)
      })
      groupedArr = { ...groupedArr, [thisGroup]: cleanArr }
      cleanArr = []
    })
    setShowClotheInfo(groupedArr)
  }

  useEffect(() => {
    agroupInfo()
  }, [])

  return (
    <div className={`${style['div-container']}`}>
      <Navbar complete />
      <FixedInfoFooter />
      <div className={`${style['div-grid']}`}>
        <div className={`${style['div-left']}`}>
          <PoloEditable />
        </div>
        <div className={`${style['div-select-options']}`}>
          <div className={`${style['div-title-type']}`}>
            <h3 className={`${style['title-h']}`}>Playera tipo “Polo”</h3>
          </div>
          <div className={`${style['polo-div-grid']}`}>
            {Object.keys(showClotheInfo).map((item, key: number) => {
              const objKey = item as keyof typeof showClotheInfo
              const objInput = showClotheInfo[objKey]
              return (
                <Fragment key={key}>
                  <SelectedCardsOptionsHOC
                    edith={false}
                    title={item}
                    gridArea={item.replaceAll(' ', '_')}
                  >
                    {objInput.map(({ topLabel, value, type }, key: number) => {
                      return (
                        <Fragment key={key}>
                          <SelectOption
                            toolTipLabel={getCorrectInformation(
                              'picture',
                              value,
                              type,
                              true
                            )}
                            topTitle={topLabel}
                            label={getCorrectInformation(
                              'picture',
                              value,
                              type,
                              false
                            )}
                            selected={true}
                            picture={getCorrectInformation(
                              'picture',
                              value,
                              type,
                              null
                            )}
                            background={value as string}
                            help={true}
                          />
                        </Fragment>
                      )
                    })}
                  </SelectedCardsOptionsHOC>
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewUniformModule
