import { TrashIcon } from '@heroicons/react/24/outline'
import { Space, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import Tabs from 'antd/es/tabs'
import Tag from 'components/tag'
import B from 'components/texts/B/B'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { usePackages } from 'hooks/utils/usePackages'
import { memo, useEffect, useState } from 'react'
import style from '../../../../components/ListOfList/list.module.scss'

export const UniformsList = memo(({}) => {
  const {
    tabs,
    selectedLevel,
    selectedTab,

    handleChangeLevelTab
  } = usePackages()

  const [levelData, setLevelData] = useState<Array<any>>([])
  const [loadingLevelData, setLoadingLevelData] = useState(false)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  useEffect(() => {}, [selectedLevel])

  const columns: ColumnsType<any> = [
    {
      title: 'Preview',
      dataIndex: 'picture',
      key: 'picture',
      render: (_, { picture }) => (
        <div>
          <img width={120} height={120} src={picture} />
        </div>
      )
    },
    {
      title: 'Nombre de la prenda',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <B>{text}</B>
    },
    {
      title: 'Status',
      key: 'action',
      render: (_, { status }) => {
        return (
          <Space size='middle'>
            <Tag variation={status} title={status} />
          </Space>
        )
      }
    },
    {
      title: 'Precio',
      key: 'price',
      render: (text: string, field: any) => {
        let money = field.price
        if (field.special_price !== '0.00' && field.special_price !== null)
          money = field.special_price
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(money)
      }
    },
    {
      render: (_, item) => {
        if (uid.role === 'Director') return null
        /*  if (parseInt(item.status) === 1 || parseInt(item.status) === 3) */
        return (
          <Tooltip title='Eliminar útil'>
            <div>
              <TrashIcon
                className={`${style['iconStyle']} ${style['trash-icon']}`}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </div>
          </Tooltip>
        )
      }
    }
  ]

  return (
    <div className={style['table-size']}>
      <Tabs
        defaultActiveKey={selectedTab as unknown as string}
        activeKey={selectedTab as unknown as string}
        items={tabs}
        onChange={handleChangeLevelTab}
      />
      <div>
        <Table
          onRow={(dat) => {
            return {
              onClick: () => {}
            }
          }}
          className={style['table-size']}
          columns={columns}
          dataSource={levelData}
          loading={loadingLevelData}
        />
      </div>
    </div>
  )
})
