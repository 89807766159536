import { AuthData } from 'redux/reducers/auth'
import { AUTHSERVICE, AUTHSERVICERECOVERY, SERVICE } from '../config'
export interface recoverPasswordData {
  mail: string
}
export interface changePasswordData {
  password: string
}
export interface loginData {
  mail: string
  password: string
}

export const login = async (data: loginData): Promise<AuthData | undefined> => {
  return await SERVICE().post('/auth', data)
}

export const chooseSchool = async (id: string): Promise<AuthData | undefined> => {
  return await AUTHSERVICE().post(`/users/change-school/${id}`)
}

export const loggout = async (data: loginData): Promise<any> => {
  return await AUTHSERVICE().post('/users/loggout', data)
}

export const recoverPassword = async (
  data: recoverPasswordData
): Promise<any> => {
  return await SERVICE().post('/auth/recovery', data)
}
export const changePassword = async (
  data: changePasswordData,
  token: string
): Promise<any> => {
  return await AUTHSERVICERECOVERY(token).put(
    `/auth/recovery?token=${token}`,
    data
  )
}
