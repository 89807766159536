import { AUTHSERVICE } from '../config'
import { IAddNewUser } from 'interfaces/user-management-interface'

const edithUser = async (id: number, data: IAddNewUser): Promise<any> => {
  return await AUTHSERVICE().put(`/users/${id}`, data)
}

const userManagementEdithUserById = async (id: number, data: IAddNewUser) => {
  const results: { success: boolean } = await edithUser(id, data)
  return results
}

export { userManagementEdithUserById }
