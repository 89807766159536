import useNotebooks from 'hooks/notebooks/useNotebooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/store/store'
import HorizontalPortadasDownload from './HorizontalPortadasDownload'
import style from './notebooksDownload.module.scss'
import VerticalPortadasDownload from './VerticalPortadasDownload'

const DownloadCovers = () => {
  const { uuid } = useParams()

  const {
    saveNotebookChange,
    saveSendDataNotebookChange,
    getNotebookData,
    notebook
  } = useNotebooks({
    uuid: parseInt(uuid || '0')
  })

  const { notebookSelected } = useSelector(
    (state: RootState) => state.notebooks
  )

  const calcNotebook = () => {
    //console.log(50 * 37.795280352161)
  }

  calcNotebook()
  return (
    <div className={`${style['download-container']} ${style['selected-'+notebookSelected]}`}>
      {(() => {
        {
          //horizontal 4,6,8
          //vertical 1,2,3,5,7
          switch (notebookSelected) {
            case 1:
              return <VerticalPortadasDownload />
            case 2:
              return <VerticalPortadasDownload />
            case 3:
              return <VerticalPortadasDownload />
            case 5:
              return <VerticalPortadasDownload />
            case 7:
              return <VerticalPortadasDownload />
            case 4:
              return <HorizontalPortadasDownload />
            case 6:
              return <HorizontalPortadasDownload />
            case 8:
              return <HorizontalPortadasDownload />

            default:
              return <></>
          }
        }
      })()}
    </div>
  )
}

export default DownloadCovers
