import { AUTHSERVICE } from '../config'
import {
  IAddNewUser,
  IresponseAddNewUser,
  IresponsePermissionsRoles
} from 'interfaces/user-management-interface'

const addNewUser = async (data: IAddNewUser): Promise<any> => {
  return await AUTHSERVICE().post(`/users/singup`, data)
}

const userManagementaddNewUser = async (data: IAddNewUser) => {
  const results: IresponseAddNewUser = await addNewUser(data)
  return results
}

const postRolesPermisions = async (schools: number[]): Promise<any> => {
  return await AUTHSERVICE().post(`/roles/by-schools?`, { schools })
}

const userManagementGetRolesPermissions = async (ids: number[]) => {
  const results: IresponsePermissionsRoles = await postRolesPermisions(ids)
  return results
}

export { userManagementaddNewUser, userManagementGetRolesPermissions }
