import React, { Fragment } from 'react'
import GreenTagsPolo from 'assets/img/uniforms/polo/GreenTagsPolo'
import LabelPolo from 'assets/img/uniforms/polo/LabelPolo'
import PoloSvg from 'assets/img/uniforms/polo/PoloSvg'
import ShadowsPolo from 'assets/img/uniforms/polo/ShadowsPolo'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'
import PoloSvgFemale from 'assets/img/uniforms/polo/polo-female/PoloSvgFemale'
import ShadowsPoloFemale from 'assets/img/uniforms/polo/polo-female/ShadowsPoloFemale'
import LabelPoloFemale from 'assets/img/uniforms/polo/polo-female/LabelPoloFemale'
import GreenTagsPoloFemale from 'assets/img/uniforms/polo/polo-female/GreenTagsPoloFemale'


const PolosPics = () => {
    const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
        {!female ? (
          <Fragment>
            <div
              className={`${
                style[`logo-position-${poloValues.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PoloSvg
                poloColor={poloValues.color.value as string}
                neckColor={poloValues.neckColor.value as string}
                sleevesColor={poloValues.fistColor.value as string}
                sleevesLines={poloValues.fistDesign.value as boolean}
                neckLines={poloValues.neckDesign.value as boolean}
                aperture={poloValues.aperture.value as boolean}
                stroke='black'
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsPolo
                fill={poloValues.color.value as string}
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelPolo stroke='#f5e7ca' className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsPolo
                styledFont={`${style['font-polo']}`}
                stroke='green'
                aperture={poloValues.aperture.value as boolean}
                className={`${style['svg']}`}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={`${
                style[`logo-position-${poloValuesFemale.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PoloSvgFemale
                poloColor={poloValuesFemale.color.value as string}
                neckColor={poloValuesFemale.neckColor.value as string}
                roundedNeck={poloValuesFemale.neckStyle.value as boolean}
                neckLines={poloValuesFemale.neckDesign.value as boolean}
                aperture={poloValuesFemale.aperture.value as boolean}
                stroke='black'
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsPoloFemale
                fill={poloValuesFemale.color.value as string}
                className={`${style['svg']}`}
              />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelPoloFemale stroke='#f5e7ca' className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsPoloFemale
                styledFont={`${style['font-polo']}`}
                stroke='green'
                aperture={poloValuesFemale.aperture.value as boolean}
                className={`${style['svg']}`}
              />
            </div>
          </Fragment>
        )}
      </div>
  )
}

export default PolosPics