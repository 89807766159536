import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useValidatePermission = () => {
  const location = useLocation()
  const { pathname } = location
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const navigate = useNavigate()

const PERMISSIONS = [
    {
        url:'cuadernos',
        permission:3
    },
    {
        url:'libros',
        permission:2
    },
    {
        url:'uniforms',
        permission:5
    },
    {
        url:'utiles',
        permission:4
    },
    {
        url:'cuotas',
        permission:6
    },
    {
        url:'reportes',
        permission:7
    }
]

  const checkPermission = () => {
    let valid = false
    if (uid.role === 'Ezetera' || uid.role === 'Administrador') return true

    for (let x = 0; x < PERMISSIONS.length; x++) {
        const url = PERMISSIONS[x].url
        const access = PERMISSIONS[x].permission
        if(pathname.includes(url)){
            valid = uid.permissions.some((permission) => access === permission.id)
            break
        }
    }
/* 
    if (pathname.includes('cuadernos'))
      valid = uid.permissions.some((permission) => 3 === permission.id)
    if (pathname.includes('libros'))
      valid = uid.permissions.some((permission) => 2 === permission.id)
    if (pathname.includes('uniforms'))
      valid = uid.permissions.some((permission) => 5 === permission.id)
    if (pathname.includes('utiles'))
      valid = uid.permissions.some((permission) => 4 === permission.id)
    if (pathname.includes('cuotas'))
      valid = uid.permissions.some((permission) => 6 === permission.id)
    if (pathname.includes('reportes'))
      valid = uid.permissions.some((permission) => 7 === permission.id) */
    return valid
  }

  useEffect(() => {
    const valid = checkPermission()
    if (!valid) return navigate('/inicio')
  }, [])
}
