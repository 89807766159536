import React from 'react'

const ShadowsJacket = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
        <g id="Sombras" stroke='black'>
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m237.44,460.46s-.06-51.42,2.84-49.49c2.9,1.93,21.29,32.11,21.29,32.11,0,0,.83-8.09,0-10.61-3.3-10.04-15.99-38.86-21.29-48.01-5.63-9.71-27.63-37.05-22.14-27.26,8.23,14.65,12.58,26.39,12.58,26.39l6.72,76.87Z"/>
      <path   d="m439.94,644.74l90.71-19.3,10.61,2.89s-29.92,11.58-33.77,12.55c-3.86.96-67.55,3.86-67.55,3.86Z"/>
      <path   d="m250.62,629.12c10.5-13.94,34.5-11.78,67.18,1.65,14.16,5.81,25.88-12.53,45.9,11.08.8.94-24.95-12.85-25.04-11.62-.91,13.18-83.1-15.74-87.86,1.97-.2.75-.64-2.45-.18-3.07Z"/>
      <path   d="m541.77,402.45c-1.91-7.18,13.23-17.96,17.37-24.13,8.25-12.28,19.3-55.97,19.3-55.97,0,0,4.42,68.07-7.72,85.89-1.22,1.78-5.56,3.74-7.72,3.86-5.82.31-19.73-4.02-21.23-9.65Z"/>
      <path   d="m159.13,343.77c-.49,6.57,7.9,51.54,7.47,56.9-.23,2.81-2.87-5.29-10.51-35.57-1.64-6.49-10.01,38.27-9.5,27.88.62-12.68,12.89-54.17,12.54-49.21Z"/>
      <polygon   points="93.83 435.51 101.07 469.28 125.88 511.74 90.73 475.07 93.83 435.51"/>
      <path   d="m78.33,654.46c.28,5.65,3.73,20.64,7.63,23.73,6,4.77,29.8,21.69,24.91,15.77-4.85-5.87,12.74-6.58,16.62-6.73,7.63-.28-27.14-.74-33.9-5.08-6.04-3.86-15.61-34.84-15.26-27.68Z"/>
      <path   d="m102.18,627.37c.28,8.54-4.21-16.12-12.28-13.32-6.5,2.27-12.99-32.2-13.78-40.72-.61-6.56,9.66,17.24,12.54,23.16,3.7,7.57,11.69-23.11,13.52,30.88Z"/>
      <path   d="m648.28,276.87c-5.79,16.19-13.67,26.23-11.69,36.48,2.14,11.09,7.33,18.84,17.72,33.43,4.62,6.5-26.88-27.44-28.42-35.25-2.3-11.7,26.4-45.89,22.39-34.66Z"/>
      <path   d="m573.2,204.38s-19.1-46.66-29.4-60c-4.42-5.73-22.23-15.01-26.29-17.78-4.07-2.78-17.68-5.68-17.68-5.68,0,0,25.01-3.59,32.77-.97,6.63,2.24,16.56,13.4,20.24,19.35,8.98,14.49,20.36,65.07,20.36,65.07Z"/>
      <path   d="m649.08,511.41c17.76-29.66,12.84-28.15,33.78-18.33,2.07.97,4.36-6.93,2.9-8.68-4.2-5.02-17.03-33.01-22.34-29.21-5.54,3.97-14.34,56.22-14.34,56.22Z"/>
      <polygon   points="658.76 676.74 707.01 681.57 685.78 684.46 656.82 685.42 658.76 676.74"/>
      <path   d="m275.57,129.58c8.46,7.84-13.54,44.34-13.54,44.34,0,0,16.48-37.91,8.74-40.01-12.43-3.39-45.5,48.49-45.5,48.49,0,0,18.28-35.23-.6-26.56-10.5,4.82,42.57-33.96,50.89-26.26Z"/>
      
    </g>
  </g>
    </svg>
  )
}

export default ShadowsJacket
