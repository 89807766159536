/* eslint-disable react-hooks/exhaustive-deps */
import { Options } from 'components/formInputs/select'
import { ColumnsType } from 'antd/es/table'
import Tags from 'components/tag'
import { useEffect, useState } from 'react'
import { getCatalogStatus } from 'services/books/books'
import { handleErrors } from 'utilities/handleErrors'
import { IListNotebookPackage } from 'services/notebooks/getListOfNotebooks'
import { getAllNotebookList } from 'services/schoolUserServices/listOfElements'

export function useNotebookTable() {
  const NotebookColumn: ColumnsType<IListNotebookPackage> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
  },
  {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity'
  },
  {
      title: 'No. hojas',
      dataIndex: 'int_sheets',
      key: 'int_sheets'
  },
  {
      title: 'Rayado',
      dataIndex: 'int_striped',
      key: 'int_striped'
  },
  {
      title: 'Materia',
      dataIndex: 'matter',
      key: 'matter'
  },
  {
      title: 'Estatus',
      dataIndex: 'status_name',
      key: 'status',
      render: (status: any) => {
        return <Tags variation={status} title={status} />
      }
  },
  ]
  const [status, setStatus] = useState('')
  const [notebooks, setNotebooks] = useState([])
  const [loading, setloading] = useState(true)
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])

  const handleChangePage = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: e
      }
    })
  }
  const [pagination, setpagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    onChange: handleChangePage
  })

  useEffect(() => {
    hangleGetStatusCatalog()
  }, [])

  useEffect(() => {
    hangleGetbooks(status, pagination.current)
  }, [status, pagination.current])

  const hangleGetbooks = async (status: string, page: number) => {
    setloading(true)
    try {
      const result = await getAllNotebookList({ status, page })
      setNotebooks(result.data.data.notebooks)
      setpagination((prev) => {
        return {
          ...prev,
          current: result.data.data.page,
          pageSize: 20,
          total: result.data.data.total
        }
      })
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false)
    }
  }

  const hangleGetStatusCatalog = async (): Promise<any> => {
    try {
      const res = await getCatalogStatus()
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])
    } catch (error) {
      handleErrors(error)
    }
  }
  
  const onHandleChangeStatus = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: 1
      }
    })
    if (e === 'todos') return setStatus('')
    return setStatus(e)
  }

  return {
    notebooks,
    status,
    loading,
    pagination,
    NotebookColumn,
    statusCatalog,
    setStatus,
    onHandleChangeStatus
  }
}
