import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare14mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro aleman 14mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_aleman_14mm_Completo'
              data-name='Path Cuadro aleman 14mm Completo'
            >
              <g
                id='Path_Cuadro_aleman_14mm_Completo-2'
                data-name='Path Cuadro aleman 14mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='237.27' y1='103.1' x2='237.27' y2='736.92' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='609.79' y1='103.1' x2='609.79' y2='736.92' />
                  </g>
                  <line x1='648.1' y1='140.73' x2='200.01' y2='140.73' />
                  <line x1='648.1' y1='178.07' x2='200.01' y2='178.07' />
                  <line x1='648.1' y1='215.41' x2='200.01' y2='215.41' />
                  <line x1='648.1' y1='252.75' x2='200.01' y2='252.75' />
                  <line x1='648.1' y1='290.09' x2='200.01' y2='290.09' />
                  <line x1='648.1' y1='327.14' x2='200.01' y2='327.14' />
                  <line x1='648.1' y1='364.19' x2='200.01' y2='364.19' />
                  <line x1='648.1' y1='401.53' x2='200.01' y2='401.53' />
                  <line x1='648.1' y1='438.87' x2='200.01' y2='438.87' />
                  <line x1='648.1' y1='476.21' x2='200.01' y2='476.21' />
                  <line x1='648.1' y1='513.55' x2='200.01' y2='513.55' />
                  <line x1='648.1' y1='550.6' x2='200.01' y2='550.6' />
                  <line x1='648.1' y1='587.64' x2='200.01' y2='587.64' />
                  <line x1='648.1' y1='624.98' x2='200.01' y2='624.98' />
                  <line x1='648.1' y1='662.32' x2='200.01' y2='662.32' />
                  <line x1='648.1' y1='699.66' x2='200.01' y2='699.66' />
                  <line x1='274.61' y1='103.1' x2='274.61' y2='736.92' />
                  <line x1='311.95' y1='103.1' x2='311.95' y2='736.92' />
                  <line x1='349.29' y1='103.1' x2='349.29' y2='736.92' />
                  <line x1='386.34' y1='103.1' x2='386.34' y2='736.92' />
                  <line x1='423.39' y1='103.1' x2='423.39' y2='736.92' />
                  <line x1='460.73' y1='103.1' x2='460.73' y2='736.92' />
                  <line x1='498.07' y1='103.1' x2='498.07' y2='736.92' />
                  <line x1='535.41' y1='103.1' x2='535.41' y2='736.92' />
                  <line x1='572.75' y1='103.1' x2='572.75' y2='736.92' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m627.87,195.93c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m629.01,163.19c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,128.15c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,239.97c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.87,307.88c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m629.01,275.14c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,351.92c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.87,419.82c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m629.01,387.08c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,463.86c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.87,531.76c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m629.01,499.03c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,575.81c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.87,643.71c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m629.01,610.97c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,687.75c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m629.01,722.92c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m628.98,128.15c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,158.16c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,201.09c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,232.71c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,275.63c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,307.25c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,350.18c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,381.8c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,424.73c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,456.35c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,499.28c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,530.9c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,573.82c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,605.44c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,648.37c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.84,679.99c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.98,722.92c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M220.16,194.93a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M219,162.19a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,127.15a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,239a3.24,3.24,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,239' />
                                    <path d='M220.16,306.88a28.63,28.63,0,0,0-4.27,5.43l-.3-.68c-.62-1.37-1.17-2.05-1.68-2.05a2.88,2.88,0,0,0-1.94,1.11,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a23.56,23.56,0,0,0-5.08,4.06' />
                                    <path d='M219,274.14a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,350.92a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,418.82a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M219,386.08a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,462.86a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,530.76a29,29,0,0,0-4.27,5.44l-.3-.68c-.62-1.37-1.17-2.06-1.68-2.06a2.9,2.9,0,0,0-1.94,1.12,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4' />
                                    <path d='M219,498a3.25,3.25,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,498' />
                                    <path d='M219,574.81a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,642.71a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10.2,10.2,0,0,1,1.1,2l.27.64a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.5a24.19,24.19,0,0,0-5.08,4.06' />
                                    <path d='M219,610a3.24,3.24,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,610' />
                                    <path d='M219,686.75a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,721.92a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M219,127.15a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,157.16a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2-1.68-2A2.9,2.9,0,0,0,212,161a2.43,2.43,0,0,1,1.14.87,9.62,9.62,0,0,1,1.09,2l.28.63c.28.7.47,1.21.56,1.52a10.55,10.55,0,0,1,1.22-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.5a24.23,24.23,0,0,0-5.09,4.06' />
                                    <path d='M219,200.09a3.25,3.25,0,1,0-3.24-3.25,3.25,3.25,0,0,0,3.24,3.25' />
                                    <path d='M220.18,231.71a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.21.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a33.14,33.14,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.91,23.91,0,0,0-5.09,4.06' />
                                    <path d='M219,274.63a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,306.25a29.34,29.34,0,0,0-4.26,5.44l-.3-.68c-.62-1.37-1.18-2.06-1.68-2.06a2.94,2.94,0,0,0-1.95,1.12,2.53,2.53,0,0,1,1.14.88,9.5,9.5,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4.05' />
                                    <path d='M219,349.18a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,380.8a29,29,0,0,0-4.26,5.44l-.3-.69c-.62-1.36-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.57,2.57,0,0,1,1.14.87,9.62,9.62,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a10.55,10.55,0,0,1,1.22-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4.05' />
                                    <path d='M219,423.73a3.25,3.25,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,455.35a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.21.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.5a24.23,24.23,0,0,0-5.09,4.06' />
                                    <path d='M219,498.28A3.25,3.25,0,1,0,215.8,495a3.25,3.25,0,0,0,3.24,3.25' />
                                    <path d='M220.18,529.9a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2-1.68-2a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a33.14,33.14,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.91,23.91,0,0,0-5.09,4.06' />
                                    <path d='M219,572.82a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,604.44a29,29,0,0,0-4.26,5.44l-.3-.68c-.62-1.37-1.18-2.06-1.68-2.06a2.94,2.94,0,0,0-1.95,1.12,2.53,2.53,0,0,1,1.14.88,9.5,9.5,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4.05' />
                                    <path d='M219,647.37a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,679a29,29,0,0,0-4.26,5.44l-.3-.69c-.62-1.36-1.18-2-1.68-2a2.9,2.9,0,0,0-1.95,1.12,2.5,2.5,0,0,1,1.14.87,9.62,9.62,0,0,1,1.09,2l.28.63c.28.71.47,1.21.56,1.52a10.55,10.55,0,0,1,1.22-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4' />
                                    <path d='M219,721.92a3.25,3.25,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro aleman 14mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_aleman_14mm_Datos'
              data-name='Path Cuadro aleman 14mm Datos'
            >
              <g
                id='Path_Cuadro_aleman_14mm_Datos-2'
                data-name='Path Cuadro aleman 14mm Datos'
              >
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='237.27' y1='149.18' x2='237.27' y2='744.97' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='609.79' y1='149.18' x2='609.79' y2='744.97' />
                  </g>
                  <line x1='648.1' y1='186.81' x2='200.01' y2='186.81' />
                  <line x1='648.1' y1='224.15' x2='200.01' y2='224.15' />
                  <line x1='648.1' y1='261.49' x2='200.01' y2='261.49' />
                  <line x1='648.1' y1='298.83' x2='200.01' y2='298.83' />
                  <line x1='648.1' y1='336.17' x2='200.01' y2='336.17' />
                  <line x1='648.1' y1='373.22' x2='200.01' y2='373.22' />
                  <line x1='648.1' y1='410.27' x2='200.01' y2='410.27' />
                  <line x1='648.1' y1='447.61' x2='200.01' y2='447.61' />
                  <line x1='648.1' y1='484.95' x2='200.01' y2='484.95' />
                  <line x1='648.1' y1='522.29' x2='200.01' y2='522.29' />
                  <line x1='648.1' y1='559.63' x2='200.01' y2='559.63' />
                  <line x1='648.1' y1='596.68' x2='200.01' y2='596.68' />
                  <line x1='648.1' y1='633.73' x2='200.01' y2='633.73' />
                  <line x1='648.1' y1='671.07' x2='200.01' y2='671.07' />
                  <line x1='648.1' y1='708.41' x2='200.01' y2='708.41' />
                  <line x1='274.61' y1='149.18' x2='274.61' y2='744.97' />
                  <line x1='311.95' y1='149.18' x2='311.95' y2='744.97' />
                  <line x1='349.29' y1='149.18' x2='349.29' y2='744.97' />
                  <line x1='386.34' y1='149.18' x2='386.34' y2='744.97' />
                  <line x1='423.39' y1='149.18' x2='423.39' y2='744.97' />
                  <line x1='460.73' y1='149.18' x2='460.73' y2='744.97' />
                  <line x1='498.07' y1='149.18' x2='498.07' y2='744.97' />
                  <line x1='535.41' y1='149.18' x2='535.41' y2='744.97' />
                  <line x1='572.75' y1='149.18' x2='572.75' y2='744.97' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m627.52,239.79c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.66,207.05c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,172.01c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,283.83c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.52,351.74c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.66,319c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,395.78c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.52,463.68c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.66,430.94c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,507.72c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.52,575.62c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.66,542.89c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,619.67c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.52,687.57c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.66,654.83c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m628.66,729.2c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m628.63,172.01c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,202.02c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,244.95c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,276.57c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,319.49c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,351.11c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,394.04c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,425.66c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,468.59c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,500.21c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,543.14c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,574.76c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,617.68c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,649.3c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                    <path d='m628.63,692.23c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24,3.24,1.45,3.24,3.24-1.45,3.24-3.24,3.24' />
                                    <path d='m627.49,723.22c1.73,1.76,3.15,3.57,4.26,5.44l.3-.69c.62-1.37,1.17-2.05,1.68-2.05.59,0,1.24.37,1.94,1.12-.42.14-.8.44-1.14.87-.34.44-.7,1.12-1.09,2.03l-.27.64c-.29.7-.47,1.2-.56,1.51-.22-.2-.63-.51-1.23-.93l-.73-.49c-.9-1.9-2.21-3.95-3.93-6.15-1.71-2.19-3.27-3.82-4.67-4.87l.34-.49c1.66.94,3.35,2.29,5.09,4.05' />
                                  </g>
                                )
                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M220.16,239.79a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2-1.68-2a2.86,2.86,0,0,0-1.94,1.12,2.43,2.43,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4' />
                                    <path d='M219,207.05a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,172a3.24,3.24,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,172' />
                                    <path d='M219,283.83a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,351.74a28.63,28.63,0,0,0-4.27,5.43l-.3-.68c-.62-1.37-1.17-2.05-1.68-2.05a2.88,2.88,0,0,0-1.94,1.11,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,27.27,27.27,0,0,1,4.67-4.87l-.34-.49a23.56,23.56,0,0,0-5.08,4.06' />
                                    <path d='M219,319a3.25,3.25,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,319' />
                                    <path d='M219,395.78a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,463.68a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05A2.86,2.86,0,0,0,212,467.5a2.36,2.36,0,0,1,1.13.87,10,10,0,0,1,1.1,2l.27.63a13.49,13.49,0,0,1,.56,1.52,12,12,0,0,1,1.23-.94l.72-.49a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4.05' />
                                    <path d='M219,430.94a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,507.72a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M220.16,575.62a29,29,0,0,0-4.27,5.44l-.3-.68c-.62-1.37-1.17-2.06-1.68-2.06a2.9,2.9,0,0,0-1.94,1.12,2.45,2.45,0,0,1,1.13.88,9.92,9.92,0,0,1,1.1,2l.27.64a13.53,13.53,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93l.72-.49a33.87,33.87,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.8,23.8,0,0,0-5.08,4' />
                                    <path d='M219,542.89a3.25,3.25,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,619.67a3.25,3.25,0,1,0-3.24-3.25,3.25,3.25,0,0,0,3.24,3.25' />
                                    <path d='M220.16,687.57a28.37,28.37,0,0,0-4.27,5.44l-.3-.69c-.62-1.37-1.17-2.05-1.68-2.05a2.86,2.86,0,0,0-1.94,1.12,2.36,2.36,0,0,1,1.13.87,10.2,10.2,0,0,1,1.1,2l.27.64a14.32,14.32,0,0,1,.56,1.51,14.11,14.11,0,0,1,1.23-.93L217,695a34.17,34.17,0,0,1,3.93-6.14,26.6,26.6,0,0,1,4.67-4.87l-.34-.5a24.19,24.19,0,0,0-5.08,4.06' />
                                    <path d='M219,654.83a3.24,3.24,0,1,0-3.24-3.24,3.25,3.25,0,0,0,3.24,3.24' />
                                    <path d='M219,729.2a3.24,3.24,0,1,0-3.24-3.24A3.25,3.25,0,0,0,219,729.2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M219,172a3.24,3.24,0,1,0-3.24-3.24A3.24,3.24,0,0,0,219,172' />
                                    <path d='M220.18,202a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.62,9.62,0,0,1,1.09,2l.28.63c.28.7.47,1.21.56,1.52a10.55,10.55,0,0,1,1.22-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.5a24.23,24.23,0,0,0-5.09,4.06' />
                                    <path d='M219,245a3.25,3.25,0,1,0-3.24-3.25A3.25,3.25,0,0,0,219,245' />
                                    <path d='M220.18,276.57a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93L217,284a33.14,33.14,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.91,23.91,0,0,0-5.09,4.06' />
                                    <path d='M219,319.49a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,351.11a29.34,29.34,0,0,0-4.26,5.44l-.3-.68c-.62-1.37-1.18-2.06-1.68-2.06a2.94,2.94,0,0,0-1.95,1.12,2.53,2.53,0,0,1,1.14.88,9.5,9.5,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4.05' />
                                    <path d='M219,394a3.24,3.24,0,1,0-3.24-3.24A3.24,3.24,0,0,0,219,394' />
                                    <path d='M220.18,425.66a29,29,0,0,0-4.26,5.44l-.3-.69c-.62-1.36-1.18-2.05-1.68-2.05a2.9,2.9,0,0,0-1.95,1.12,2.57,2.57,0,0,1,1.14.87,9.62,9.62,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a10.55,10.55,0,0,1,1.22-.94l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4.05' />
                                    <path d='M219,468.59a3.25,3.25,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,500.21a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2-1.68-2A2.9,2.9,0,0,0,212,504a2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.21.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a33.47,33.47,0,0,1,3.93-6.15,26.49,26.49,0,0,1,4.67-4.86l-.34-.5a24.23,24.23,0,0,0-5.09,4.06' />
                                    <path d='M219,543.14a3.25,3.25,0,1,0-3.24-3.25,3.25,3.25,0,0,0,3.24,3.25' />
                                    <path d='M220.18,574.76a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2-1.68-2a2.9,2.9,0,0,0-1.95,1.12,2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.93,26.93,0,0,1,4.67-4.87l-.34-.49a23.91,23.91,0,0,0-5.09,4.06' />
                                    <path d='M219,617.68a3.24,3.24,0,1,0-3.24-3.24,3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,649.3a29,29,0,0,0-4.26,5.44l-.3-.68c-.62-1.37-1.18-2.06-1.68-2.06a2.94,2.94,0,0,0-1.95,1.12,2.53,2.53,0,0,1,1.14.88,9.5,9.5,0,0,1,1.09,2l.28.64c.28.7.47,1.2.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a32.81,32.81,0,0,1,3.93-6.15,26.6,26.6,0,0,1,4.67-4.87l-.34-.49a23.84,23.84,0,0,0-5.09,4' />
                                    <path d='M219,692.23A3.24,3.24,0,1,0,215.8,689a3.24,3.24,0,0,0,3.24,3.24' />
                                    <path d='M220.18,723.22a28.7,28.7,0,0,0-4.26,5.44l-.3-.69c-.62-1.37-1.18-2.05-1.68-2.05A2.9,2.9,0,0,0,212,727a2.43,2.43,0,0,1,1.14.87,9.76,9.76,0,0,1,1.09,2l.28.64c.28.7.47,1.21.56,1.51a12.17,12.17,0,0,1,1.22-.93l.73-.49a33.09,33.09,0,0,1,3.93-6.14,26.27,26.27,0,0,1,4.67-4.87l-.34-.5a24.23,24.23,0,0,0-5.09,4.06' />
                                  </g>
                                )
                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro aleman 14mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_aleman_14mm_Completo'
                data-name='Margenes Cuadro aleman 14mm Completo'
              >
                <g
                  id='Margenes_Cuadro_aleman_14mm_Completo-2'
                  data-name='Margenes Cuadro aleman 14mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V723a13.08,13.08,0,0,1-13.08,13.08H214A13.09,13.09,0,0,1,200.93,723V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V723A14.08,14.08,0,0,0,214,737.12H634.16A14.08,14.08,0,0,0,648.24,723V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56V736.12H200.93V104.56H647.24m1-1H199.93V737.12H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            {' '}
            <title>Margenes Cuadro aleman 14mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_aleman_14mm_Datos'
                data-name='Margenes Cuadro aleman 14mm Datos'
              >
                <g
                  id='Margenes_Cuadro_aleman_14mm_Datos-2'
                  data-name='Margenes Cuadro aleman 14mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V730.89A13.09,13.09,0,0,1,634.16,744H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V730.89A14.08,14.08,0,0,0,214,745H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56V744H200.93V149.56H647.24m1-1H199.93V745H648.24V148.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare14mmVertical
