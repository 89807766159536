import React from 'react'

const SportShirtSvg = ({
  className,
  stroke = '#000000',
  fill,
  neckType,
  neckColor
}: {
  className: string
  stroke: string
  fill?: string
  neckType?: string
  neckColor?: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Path' stroke={stroke}>
        <g id='Path-2' data-name='Path'>
          <g id='Manga_derecha' fill={fill} data-name='Manga derecha'>
            <path
              id='Manga_derecha-2'
              data-name='Manga derecha'
              d='m760.9,326.72c-2.26-4.15-10.57-21.5-23.01-45.65-12.45-24.15-32.07-68.29-39.99-93.93-7.92-25.65-23.02-57.59-35.84-76.58-15.56-23.05-22.27-3.36-33.94,10.18-9.44,10.95-40.37,42.63-55.08,85.64-14.71,43.01,3.01,95.83,12.07,108.65,8.41,11.91,18.17,23.04,27.94,33.84,1.83,2.03,3.72,4.22,5.88,5.89,2.34,1.81,4.3,3.48,5.86,6.06,2.84,4.7,4.35,10.32,6.63,15.32,2.19,4.83,4.45,9.67,6.13,14.71,3.4,10.18,6.04,10.18,11.32,9.82,5.28-.39,25.65-11.7,41.49-19.63,15.84-7.92,48.67-24.52,62.25-35.07,13.58-10.57,10.57-15.1,8.3-19.25Z'
            />
            <path
              id='Línea_1_manga_derecha'
              data-name='Línea 1 manga derecha'
              d='m641.06,398.32s98.24-44.39,119.84-71.61'
            />
            <path
              id='Línea_2_manga_derecha'
              data-name='Línea 2 manga derecha'
              d='m637.06,390.32s98.24-44.39,119.84-71.61'
            />
          </g>
          <g id='Manga_izquierda' fill={fill} data-name='Manga izquierda'>
            <path
              id='Manga_izquierda-2'
              data-name='Manga izquierda'
              d='m154.49,95.99c-10.58,4.71-27.44,29.4-38.42,48.61-10.98,19.21-34.5,78.8-47.04,108.59-12.55,29.78-28.62,66.25-30.96,71.74-2.35,5.49.05,7.43,3.53,10.2,19.21,15.28,42.61,26.48,59.19,29.79,17.65,3.53,26.27,8.24,39.98,12.54,13.73,4.32,20.78,4.71,25.88,3.53,5.1-1.17,6.67-3.53,9.01-8.23,2.36-4.71,9.43-19.99,15.69-27.83,6.27-7.84,7.05-10.98,9.01-16.86,1.98-5.88,7.85-17.25,23.13-30.57,15.3-13.32,46.65-38.03,5.1-128.58-41.55-90.56-63.5-77.63-74.09-72.92Z'
            />
            <path
              id='Línea_1_manga_izquierda'
              data-name='Línea 1 manga izquierda'
              d='m173.5,376.5s-108.71-31.3-133.94-55.18'
            />
            <path
              id='Línea_2_manga_izquierda'
              data-name='Línea 2 manga izquierda'
              d='m177.5,368.5s-109.77-31.11-135-55'
            />
          </g>
          <path
            fill={fill}
            id='Contorno_playera'
            data-name='Contorno playera'
            d='m160.23,92.91c-8.51,4.6-9.8,5.88-9.02,19.21.79,13.33,10.2,65.07,19.21,98.01,9.02,32.92,18.03,56.06,20,76.43,1.96,20.39,9.81,48.61,9.81,48.61,0,0,.38,30.98,1.96,57.64,1.56,26.64-5.61,133.89-5.61,169.95s1.18,61.14,2.74,69.77c1.57,8.62,3.92,18.43,2.74,26.66-1.18,8.23-6.65,78.79-4.71,87.81,1.97,9.01,18.16,10.77,41.29,18.22,23.14,7.44,49.79,13.72,89,14.89,39.2,1.2,62.71,4.32,85.45,4.71,22.74.4,35.67,3.54,79.98-2.35,44.29-5.88,124.84-20.87,131.9-30.28,7.05-9.41-.76-31.65-1.15-53.2-.51-28.02-3.68-33.82-5.24-60.7-1.17-20.37-8.22-70.41-10.57-91.19-2.36-20.77-13.41-37.46-11.45-70.4,1.96-32.93,5.5-87.43,6.28-108.2.78-20.77-.4-31.36,2.35-38.41,2.75-7.06,4.71-25.48,5.88-38.42,1.18-12.95,9.8-68.22,17.64-99.97,7.85-31.75,12.93-62.34,17.64-72.13,4.7-9.81,8.62-17.26,8.62-17.26,0,0-6.66-10.97-21.95-17.64-15.29-6.66-28.89-12.26-48.87-24.8-20-12.54-57.15-31.28-78.83-45.88-4.82.65-15.34,1.81-31.8,3.5-5.77.59-5,0-19.71,1.31-30.89,2.74-61.23,3.12-92.18,1.46-14.8-.79-47.32-5.85-47.32-5.85,0,0-32.54,19.3-54.08,30.28-21.57,10.98-38.82,21.96-54.1,27.06-15.28,5.09-31.36,13.33-45.86,21.16Z'
          />
          <g id='Costuras'>
            <path d='m49.32,303.04c22.83,19.65,62.24,39.41,133.16,53.37' />
            <path d='m748.67,306.36c-23.99,20.86-48.49,41.65-119.45,67.16' />
            <path d='m328.88,26.97c76.71,16.08,141.05,6.83,176.15-1.89' />
            <path d='m752.45,309.24c-21.81,24.24-46.02,39.61-121.42,66.83' />
            <path d='m198.04,713.85l92.43,24.71s.78-1.05,4.11,0c23.1,7.17,50.64,9.14,50.64,9.14,0,0,74.74.92,87.55,3.66,12.82,2.74,101.57-15.55,110.26-16.46,8.69-.93,81.79-15.59,81.79-15.59' />
            <path d='m48.32,305.01c22.84,19.65,62.26,39.41,133.17,53.37' />
            <path d='m198.54,707.72l92.43,24.7s.79-1.03,4.11,0c23.1,7.17,50.63,9.15,50.63,9.15,0,0,74.74.91,87.55,3.66,12.82,2.74,101.57-15.56,110.26-16.48,8.69-.92,81.79-15.58,81.79-15.58' />
          </g>
          <path
            id='Cuello_interno'
            data-name='Cuello interno'
            d='m505.5,25.5c-30.84,5.09-66.95,9.29-98.24,9.65-15.61.18-31.19-1.01-46.71-2.58-14.72-1.48-32.71-3.18-47.05-7.07'
          />
          <g>
            {(() => {
              switch (neckType) {
                case 'V':
                  return (
                    <g id='Cuello_V' data-name='Cuello V'>
                      <g id='Cuello_V_derecha' data-name='Cuello V derecha'>
                        <path
                          fill={neckColor}
                          id='Cuello_V_derecha-2'
                          data-name='Cuello V derecha'
                          d='m521.5,24.5l-17-10c-10.06,34.97-22.89,67.11-35.62,87.48-15.19,24.3-33.04,39.33-44.6,48.08-8.28,6.27-11.2,7.21-11.2,7.21l.06,20.81s26.35-17.27,50.44-41.72c18.28-18.56,30.95-42.43,37.43-56.73,19.86-43.92,20.49-55.13,20.49-55.13Z'
                        />
                        <path
                          id='Linea_1_cuello_V_derecha'
                          data-name='Linea 1 cuello V derecha'
                          d='m517.5,22.5s-21.96,66.6-41,91c-33.63,43.1-63.39,58.56-63.39,58.56'
                        />
                        <path
                          id='Linea_2_cuello_V_derecha'
                          data-name='Linea 2 cuello V derecha'
                          d='m511.5,18.5s-17.74,63.94-36.78,88.35c-33.63,43.1-61.55,57.56-61.55,57.56'
                        />
                      </g>
                      <g id='Cuello_V_izquierda' data-name='Cuello V izquierda'>
                        <path
                          id='Cuello_V_izquierda-2'
                          fill={neckColor}
                          data-name='Cuello V izquierda'
                          d='m298.97,23.65l14.68-8.56s11.78,57.12,35.24,86.58c17.86,22.41,37.79,42.57,50.49,49.23,12.7,6.66,13.64,6.16,13.64,6.16l.05,20.8s-32.96-17.27-57.05-41.72c-18.28-18.57-30.95-42.43-37.42-56.73-19.86-43.92-19.63-55.76-19.63-55.76Z'
                        />
                        <g>
                          <path
                            id='Linea_1_cuello_V_izquierda'
                            data-name='Linea 1 cuello V izquierda'
                            d='m302.5,21.5s19.83,62.45,38.87,86.86c33.63,43.1,71.74,63.71,71.74,63.71'
                          />
                          <path
                            id='Linea_2_cuello_V_izquierda'
                            data-name='Linea 2 cuello V izquierda'
                            d='m307.5,19.5s14.89,56.79,33.93,81.2c33.63,43.1,71.74,63.71,71.74,63.71'
                          />
                        </g>
                        <path
                          id='Cuello_V_izquierda-4'
                          data-name='Cuello V izquierda'
                          d='m298.97,23.65l14.68-8.56s11.78,57.12,35.24,86.58c17.86,22.41,37.79,42.57,50.49,49.23,12.7,6.66,13.64,6.16,13.64,6.16l.05,20.8s-32.96-17.27-57.05-41.72c-18.28-18.57-30.95-42.43-37.42-56.73-19.86-43.92-19.63-55.76-19.63-55.76Z'
                        />
                      </g>
                    </g>
                  )
                case 'redondo':
                  return (
                    <g id='Cuello_Redondo' data-name='Cuello Redondo'>
                      <path
                        id='Cuello_redondo'
                        fill={neckColor}
                        data-name='Cuello redondo'
                        d='m298.1,23.83l14.8-8.67s12.46,98.94,96.65,95.33c77.94-3.34,96.56-96.43,96.56-96.43l15.88,10.76s-31.42,110.09-114.12,107.13c-97.59-3.49-109.78-108.11-109.78-108.11Z'
                      />
                      <path
                        id='Linea_1_cuello_redondo'
                        data-name='Linea 1 cuello redondo'
                        d='m303.5,20.5s11.85,103.2,100.97,104.49c89.62,1.3,112.03-104.49,112.03-104.49'
                      />
                      <path
                        id='Linea_2_cuello_redondo'
                        data-name='Linea 2 cuello redondo'
                        d='m308.5,17.5s10.89,98.29,95.85,99.49c85.43,1.2,107.15-99.49,107.15-99.49'
                      />
                    </g>
                  )

                default:
                  return (
                    <g id='Cuello_Redondo' data-name='Cuello Redondo'>
                      <path
                        id='Cuello_redondo'
                        fill={neckColor}
                        data-name='Cuello redondo'
                        d='m298.1,23.83l14.8-8.67s12.46,98.94,96.65,95.33c77.94-3.34,96.56-96.43,96.56-96.43l15.88,10.76s-31.42,110.09-114.12,107.13c-97.59-3.49-109.78-108.11-109.78-108.11Z'
                      />
                      <path
                        id='Linea_1_cuello_redondo'
                        data-name='Linea 1 cuello redondo'
                        d='m303.5,20.5s11.85,103.2,100.97,104.49c89.62,1.3,112.03-104.49,112.03-104.49'
                      />
                      <path
                        id='Linea_2_cuello_redondo'
                        data-name='Linea 2 cuello redondo'
                        d='m308.5,17.5s10.89,98.29,95.85,99.49c85.43,1.2,107.15-99.49,107.15-99.49'
                      />
                    </g>
                  )
              }
            })()}
          </g>

          <g id='Escudo'>
            <g id='Escudo_derecho' data-name='Escudo derecho'>
              <circle cx='527' cy='245' r='29.5' />
              <path d='m527,216c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z' />
            </g>
            <g id='Escudo_izquierdo' data-name='Escudo izquierdo'>
              <circle cx='289' cy='245' r='29.5' />
              <path d='m289,216c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SportShirtSvg
