import Blusa_3_4Svg from 'assets/img/uniforms/blusa3_4/Blusa_3_4Svg'
import GreenTagBlusa_3_4 from 'assets/img/uniforms/blusa3_4/GreenTagBlusa_3_4'
import React,{Fragment} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'

const BlusaPic = () => {
    const { pictureLogo} = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
<div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div
            className={`${
              style[`logo-position-${"right"}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <Blusa_3_4Svg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <GreenTagBlusa_3_4 className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
  )
}

export default BlusaPic