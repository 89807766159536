import { AUTHSERVICE } from '../config'

const getPackage = async (id: number): Promise<any> => {
  return await AUTHSERVICE().get(`/notebook-package/level/${id}`)
}

const getNotebookPackageByLevel = async (id: number) => {
  const results = await getPackage(id)
  return results
}

export { getNotebookPackageByLevel }
