import { List } from 'antd'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import styles from './massive.module.scss'

const ErrorMassiveModule = memo(() => {
  const { errorMassive } = useSelector((state: RootState) => state.books)

  //   {
  //     "linea": 1,
  //     "name": "cuarto Y",
  //     "level": "Primaria",
  //     "grade": "Cuarto A",
  //     "error": "No existe el Nivel en el Colegio"
  // }

  return (
    <>
      <List
        itemLayout='horizontal'
        dataSource={errorMassive}
        renderItem={(item: any) => {
          return (
            <List.Item style={{ padding: ' 20px 0' }}>
              <div className={styles.contentErrors}>
                <ExclamationCircleIcon className={styles.iconError} />
                <span className={styles.spanError}>{`La línea ${item.linea}  ${item.level} - ${item.grade} : ${item.error}`}</span>
              </div>
            </List.Item>
          )
        }}
      />
    </>
  )
})

export default ErrorMassiveModule
