import React, { memo } from 'react'

const index = memo(() => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src='https://blog.thomasnet.com/hs-fs/hubfs/shutterstock_774749455.jpg?width=900&name=shutterstock_774749455.jpg'
        alt='not found'
      />
    </div>
  )
})

index.displayName = 'NotFound'
export default index
