import React from 'react'

const PathEngomadoCocidoEoutside = ({
  className,
  viewBox = '0 0 800 800',
  color
}: {
  className: string
  viewBox?: string
  color: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      viewBox={viewBox}
      stroke={'black'}
      className={className}
    >
      <g id='Path_lomo' data-name='Path lomo'>
        <g id='Path_lomo-2' data-name='Path lomo'>
          <g id='Path_lomo-3' data-name='Path lomo'>
            <g id='Lomo_izquierdo' data-name='Lomo izquierdo'>
              <rect
                id='Encuadernado_Engomado_Cosido_Izquierdo'
                data-name='Encuadernado Engomado/Cosido Izquierdo'
                x='6.19'
                y='128'
                width='57.62'
                height='512.7'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PathEngomadoCocidoEoutside
