import React from 'react'

const PathSujecionSpiralHorizontalInside = ({
  className,
  viewBox = '0 0 800 800',
  spiralColor = 'red',
  show
}: {
  className: string
  viewBox?: string
  spiralColor?: string
  show: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      <g id='Path_Espiral' data-name='Path Espiral'>
        <g id='Path_espiral' data-name='Path espiral'>
        <g id="Espiral_izquierdo" data-name="Espiral izquierdo">
        <g id="Espiral_Izquierdo" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="179.16" r="12.31"/>
            <path fill={spiralColor} d="m2.36,176.81h66.52v4.72H2.36C1.06,181.52,0,180.47,0,179.16H0C0,177.86,1.06,176.81,2.36,176.81Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-2" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="231.45" r="12.31"/>
            <path fill={spiralColor} d="m2.36,229.09h66.52v4.72H2.36C1.06,233.81,0,232.75,0,231.45H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-3" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="283.73" r="12.31"/>
            <path fill={spiralColor} d="m2.36,281.38h66.52v4.72H2.36C1.06,286.09,0,285.03,0,283.73H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-4" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="336.02" r="12.31"/>
            <path fill={spiralColor} d="m2.36,333.66h66.52v4.72H2.36C1.06,338.37,0,337.32,0,336.02H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-5" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="388.3" r="12.31"/>
            <path fill={spiralColor} d="m2.36,385.94h66.52v4.72H2.36C1.06,390.66,0,389.6,0,388.3H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-6" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="440.58" r="12.31"/>
            <path fill={spiralColor} d="m2.36,438.23h66.52v4.72H2.36C1.06,442.94,0,441.89,0,440.58H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-7" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="492.87" r="12.31"/>
            <path fill={spiralColor} d="m2.36,490.51h66.52v4.72H2.36C1.06,495.23,0,494.17,0,492.87H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-8" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="545.15" r="12.31"/>
            <path fill={spiralColor} d="m2.36,542.8h66.52v4.72H2.36C1.06,547.51,0,546.45,0,545.15H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
        <g id="Espiral_Izquierdo-9" data-name="Espiral Izquierdo">
          <g>
            <circle fill="black" cx="68.87" cy="597.44" r="12.31"/>
            <path fill={spiralColor} d="m2.36,595.08h66.52v4.72H2.36C1.06,599.79,0,598.74,0,597.44H0c0-1.3,1.06-2.36,2.36-2.36Z"/>
          </g>
        </g>
      </g>
        </g>
      </g>
    </svg>
  )
}

export default PathSujecionSpiralHorizontalInside
