import { AUTHSERVICE, AUTHSERVICE_FORMDATA } from '../config'

export interface UtilsInterface {
  image: string
  price: string
  name: string
  supply_make_name: string
  id?: number
  quantity: string
  sku: string
  presentation: string
  comments: string
}

export const getBookCommentsById = async ({
  id,
  route
}: {
  id: string | number
  route: 'book'
}): Promise<any> => {
  let url = `/comment/${route}/${id}`
  return await AUTHSERVICE().get(url)
}

export const creteNewComment = async (data: any): Promise<any> => {
  return await AUTHSERVICE_FORMDATA().post('/comment', data)
}

export const changeCommentStatus = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/comment', data)
}

export const getSchoolListBySku = async ({
  sku,
  id_package
}: {
  sku: string | number,
  id_package?: number
}): Promise<any> => {
  let url = `/school/supply?search=${sku}`
  if (id_package) url += `&id_package=${id_package}`
  return await AUTHSERVICE().get(url)
}

export const getSchoolListByIsbn = async ({
  isbn,
  id_package
}: {
  isbn: string | number,
  id_package?: number
}): Promise<any> => {
  let url = `/school/books?search=${isbn}`
  if (id_package) url += `&id_package=${id_package}`
  return await AUTHSERVICE().get(url)
}


export const updateStatusProduct = async (data: any): Promise<any> => {
  return await AUTHSERVICE().put('/custom-modules', data)
}

