import { Fragment, memo } from 'react'
import PieChart from './components/PieChart/PieChart'
import BarChart from './components/BarChart/BarChart'

const COLOR_SCALE = [
  'FireBrick',
  'MediumVioletRed',
  'Tomato',
  'Yellow',
  'Purple',
  'LimeGreen',
  'Turquoise',
  'RoyalBlue',
  'Wheat',
  'DarkSlateGray',
  "Goldenrod",
  "DimGray"
]

const ArticleSells = memo(({ maxHeight }: { maxHeight: number }) => {
  return (
    <Fragment>
      <PieChart maxHeight={maxHeight} COLOR_SCALE={COLOR_SCALE} />
      <BarChart maxHeight={maxHeight} COLOR_SCALE={COLOR_SCALE} />
    </Fragment>
  )
})
ArticleSells.displayName = 'ArticleSells'
export default ArticleSells
