import React from 'react'

const ChamaraCapitonado= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Manga_izquierda" data-name="Manga izquierda"   d="m198.25,352.4c44.22-71.2,27.26-199.2,15.25-230.9-23.75,10.02-59.31,34.24-75.3,70.37-33.47,75.63-65.18,185.82-75.44,228.56-15.7,73.8-8.48,225.05,10.6,317.7.25,1.21,5.15,5.31,6.19,5.39,35.22,2.57,50.94,4.52,81.35-6.83.96-.36,3.15-6.6,3.12-7.78-2.26-81.04-4.35-178.22,3.5-258.44l30.74-118.06Z"/>
      <path id="Linea_manga_izquierda" data-name="Linea manga izquierda"   d="m72.26,730.09s36.51,11.07,89.53-5.53"/>
      <path id="outline_capitonado" data-name="outline capitonado"   d="m600.76,732.04c-4.29-244.24,1.76-383.18,1.76-383.18-25.53-13.37-27.98-182.56-13.17-227.35l-106.48-34.73s-61.26,19.4-79.3,19.4l-83.57-19.4-106.96,34.73c14.19,38.43,19.46,184.99-15.54,230.99,0,0,10.95,193.05,6.05,378.87-.62,23.43,82.17,25.82,197.69,25.82s175.02-16.64,199.51-25.15"/>
      <path id="Linea_cintura" data-name="Linea cintura"   d="m203.99,713.02c21.95,8.26,80.13,25.08,195.65,25.08s175.02-16.64,199.51-25.15"/>
      <g id="Cierre_derecha" data-name="Cierre derecha">
        <path d="m558.91,548.3c0-1.51-1.58-2.73-3.55-2.75-1.98-.02-3.59,1.19-3.6,2.68,0,.81.45,1.53,1.17,2.03h-.08l-.05,4.86,4.92.04.05-4.85h-.08c.73-.5,1.2-1.21,1.21-2.01Z"/>
        <path d="m555.38,549.29s-7.2,9.17,0,20.9c0,0,5.4-12.63,0-20.9Z"/>
      </g>
      <g id="CIerre_izquierda" data-name="CIerre izquierda">
        <path d="m249.23,548.3c.01-1.51-1.57-2.73-3.54-2.75-1.98-.02-3.59,1.19-3.61,2.68,0,.81.46,1.53,1.17,2.03h-.08l-.04,4.86,4.92.04.05-4.85h-.07c.73-.5,1.19-1.21,1.2-2.01Z"/>
        <path d="m245.7,549.29s-7.21,9.17,0,20.9c0,0,5.41-12.63,0-20.9Z"/>
      </g>
      <path id="Manga_derecha" data-name="Manga derecha"   d="m601.75,352.4c-44.22-71.2-24.27-197.98-12.25-229.68,23.75,10.02,56.31,33.02,72.3,69.15,33.47,75.63,65.18,185.82,75.44,228.56,15.7,73.8,8.48,225.05-10.6,317.7-.25,1.21-5.15,5.31-6.19,5.39-35.22,2.57-50.94,4.52-81.35-6.83-.96-.36-3.15-6.6-3.12-7.78,2.26-81.04,4.35-178.22-3.5-258.44l-30.74-118.06Z"/>
      <path id="Manga_derecha-2" data-name="Manga derecha"   d="m727.74,728.67s-36.51,11.07-89.53-5.53"/>
      <path id="Linea_izquierda" data-name="Linea izquierda"   d="m245.99,724.56c1.18-70.76,3.29-346.62-33.81-404.3"/>
      <path id="Linea_derecha" data-name="Linea derecha"   d="m554.21,723.17c-1.18-72.9-3.03-345.6,33.83-402.91"/>
      <g id="Costuras">
        <path   d="m61.36,660.74s36.86,15.42,101.78-8.93"/>
        <path   d="m57.64,596.77s38.5,13.17,103.97-6.81"/>
        <path   d="m54.17,529.35s39.32,13.9,108.54,2.16"/>
        <path   d="m79.58,358.12c6.28,6.02,50.81,37.65,104.41,47.28"/>
        <path   d="m99.77,297.55c41.32,32.46,47.37,27.59,101.23,44.56"/>
        <path   d="m120.74,237.75c11.2,7.96,55.73,38.15,102.3,49.63"/>
        <path   d="m73.07,734.6s33.67,10.46,90.94-7.11"/>
        <path   d="m185.14,137.64c8.94,5.73,21.04,12.95,34.96,19.93"/>
        <path   d="m204.86,655.35c21.95,8.26,79.27,21.45,194.78,21.45s175.02-12.26,199.51-20.77"/>
        <path   d="m204.56,592.35c21.95,8.26,79.57,21.45,195.09,21.45s175.02-12.26,199.51-20.77"/>
        <path   d="m204.56,530.29c21.95,8.26,79.57,20.52,195.09,20.52s175.02-12.27,199.51-20.77"/>
        <path   d="m201.96,466.35c21.95,8.26,82.17,21.45,197.69,21.45s175.02-12.26,199.51-20.77"/>
        <path   d="m218.81,286.1c32.73,6.54,101.86,12.69,180.83,12.69s147.19-5.79,181.12-12.18"/>
        <path   d="m224.55,224.19c34.02,6.12,100.55,11.61,175.09,11.61s144.48-5.51,178.52-11.71"/>
        <path   d="m220.3,159.31c32.34,6.82,97.2,13.49,179.35,13.49s145.79-6.24,179.46-12.9"/>
        <path   d="m271.12,102.88c31.69,3.98,73.94,6.92,128.53,6.92,28.58,0,53.93-.79,76.23-2.1,20.07-1.19,37.69-2.79,52.99-4.65"/>
        <path   d="m738.64,659.32s-36.86,15.42-101.78-8.93"/>
        <path   d="m742.36,595.36s-38.65,13.22-104.33-6.92"/>
        <path   d="m745.83,527.93s-39.48,13.96-108.94,2.09"/>
        <path   d="m740.56,443.21s-39.91,22.2-109.26,20.72"/>
        <path   d="m720.42,356.71c-6.3,6.04-51.15,37.9-105.03,47.38"/>
        <path   d="m700.23,296.13c-41.21,32.38-47.34,27.62-100.8,44.42l-3.59,1.26"/>
        <path   d="m679.16,236.4c-11.22,7.96-54.62,37.34-100.4,49.1"/>
        <path   d="m726.93,733.18s-33.66,10.46-90.94-7.12"/>
        <path   d="m614.06,136.73c-8.45,5.38-19.58,12.01-32.31,18.49"/>
        <path   d="m59.44,444.62s39.91,22.2,109.26,20.72"/>
        <path   d="m201.96,403.35c21.95,8.27,82.17,21.45,197.69,21.45s175.02-12.26,199.51-20.77"/>
        <path   d="m201.34,343.64c27.81,8.06,97.23,18.16,198.3,18.16s168.07-9.2,198.25-17.18"/>
        <path   d="m654.19,178.47c-7.39,5.6-39.92,29.31-79.33,43.65"/>
        <path   d="m145.32,179.51c6.2,4.74,38.39,28.55,77.89,43.31"/>
        <path   d="m203.9,717.37c21.95,8.26,80.23,25.11,195.74,25.11s175.02-16.64,199.51-25.15"/>
      </g>
      <g id="Cuello">
        <path   d="m327.5,46.5s39.11-4.5,75.5-4.5,73.75,4.39,73.75,4.39c0,0-7.35,44.66-73.75,44.66s-74.78-44.66-74.78-44.66"/>
        <path   d="m327.22,53.2s38.98-4.4,75.92-4.4,74.88,4.4,74.88,4.4"/>
        <path   d="m402.62,137.23c50.71,0,78.83-33.63,84.19-42.78,0,0,1.25-3.83-1.57-13.61-2.83-9.78-8.54-29.77-7.22-34.45,0,0-17.08,38.66-73.13,39.13-.19,0-.95,9.3-1.75,9.3-.87,0-1.77-9.3-1.98-9.3-56.97,0-73.94-39.13-73.94-39.13,1.32,4.68-4.4,24.67-7.22,34.45-2.82,9.78-1.57,13.61-1.57,13.61,5.36,9.15,33.48,42.78,84.19,42.78"/>
        <path   d="m382.8,118.54s14.73,2.44,41.54,0"/>
        <path   d="m318.43,89.11c5.36,9.15,33.48,45.45,84.19,45.45s78.83-36.3,84.19-45.45"/>
      </g>
      <line   x1="417.59" y1="85.98" x2="417.59" y2="758"/>
      <line   x1="389.36" y1="85.98" x2="389.36" y2="758"/>
      <line id="Linea_cierre" data-name="Linea cierre"   x1="395.93" y1="86.27" x2="395.93" y2="758"/>
      <line id="Linea_cierre-2" data-name="Linea cierre"   x1="410.41" y1="86.27" x2="410.41" y2="758"/>
      <line id="Linea_centro_cierre" data-name="Linea centro cierre"   x1="402.62" y1="94.83" x2="402.62" y2="758"/>
      <g id="Cierre">
        <path   d="m409.3,100.45c.02-2.31-2.39-4.22-5.39-4.23-3-.03-5.43,1.81-5.46,4.12,0,1.24.67,2.35,1.76,3.12h-.1l-.08,7.47,7.47.07.08-7.46h-.13c1.11-.76,1.83-1.85,1.85-3.09Z"/>
        <path   d="m409.16,100.08c.02-2.31-2.39-4.2-5.38-4.23-3-.04-5.44,1.81-5.47,4.12,0,1.24.68,2.34,1.78,3.12h-.13s-.07,7.48-.07,7.48l7.47.06.08-7.47h-.12c1.1-.76,1.81-1.85,1.83-3.09"/>
        <path   d="m409.16,100.08c.02-2.31-2.39-4.2-5.38-4.23-3-.04-5.44,1.81-5.47,4.12,0,1.24.68,2.34,1.78,3.12h-.13s-.07,7.48-.07,7.48l7.47.06.08-7.47h-.12c1.1-.76,1.81-1.85,1.83-3.09Z"/>
        <rect   x="397.05" y="106.27" width="13.75" height="4.1" transform="translate(292.21 511.33) rotate(-89.52)"/>
        <polygon   points="401.72 101.19 405.82 101.23 409.9 123.45 397.79 123.38 401.72 101.19"/>
        <polygon   points="402.93 98.65 404.29 98.67 404.25 103.03 402.89 103.02 402.93 98.65"/>
        <polygon   points="402.93 98.65 404.29 98.67 404.25 103.03 402.89 103.02 402.93 98.65"/>
        <path   d="m400.31,120.47c0-.42,1-3.78,2.19-3.76l3.09.03c1.21,0,2.19,3.38,2.19,3.8,0,.44-.98.77-2.19.76l-3.09-.03c-1.21-.01-2.19-.36-2.19-.8"/>
        <path   d="m400.31,120.47c0-.42,1-3.78,2.19-3.76l3.09.03c1.21,0,2.19,3.38,2.19,3.8,0,.44-.98.77-2.19.76l-3.09-.03c-1.21-.01-2.19-.36-2.19-.8Z"/>
        <polyline   points="402.57 102.33 402.57 103.22 404.45 103.24 404.46 102.34"/>
      </g>
      <g id="Ubicacion_logo" data-name="Ubicacion logo">
        <circle   cx="497.27" cy="261.59" r="21.37"/>
        <circle   cx="658.27" cy="286.59" r="21.37"/>
        <circle   cx="138.27" cy="287.59" r="21.37"/>
        <circle   cx="289.27" cy="261.59" r="21.37"/>
      </g>
    </g>
  </g>
</svg>
)
}

export default ChamaraCapitonado