import { ISchoolsLevelsApi } from 'interfaces/schools-interface'
import { AUTHSERVICE, SERVICE } from '../config'

export interface levelInterface {
  name: string
}

export const getLevels = async ({
  controller
}: {
  controller?: AbortController
}): Promise<ISchoolsLevelsApi> => {
  let url = '/level/'
  let signal = {}
  if (controller) signal = { signal: controller.signal }
  return (await AUTHSERVICE().get(url, signal))
}

export const getMagentoLevels = async (): Promise<ISchoolsLevelsApi> => {
  let url = '/level/magento'
  return (await AUTHSERVICE().get(url))
}
export const getMagentoGrades = async (id:number): Promise<ISchoolsLevelsApi> => {
  let url = `/grade/magento/${id}`
  return (await AUTHSERVICE().get(url))
}
export const createLevel = async (data: levelInterface): Promise<any> => {
  return await AUTHSERVICE().post('/level/', data)
}

export const updateLevel = async (data: levelInterface): Promise<any> => {
  return await AUTHSERVICE().put(`/level/`, data)
}

export const finishLevelStructure = async (): Promise<any> => {
  return await AUTHSERVICE().put(`/school/finished-structure`)
}

export const requestModifyStructure = async (): Promise<any> => {
  return await AUTHSERVICE().put(`/school/modification-request`)
}

export const deleteLevel = async (id: string | number): Promise<any> => {
  return await AUTHSERVICE().delete(`/level/${id}`)
}
export const getLevelsBySchool = async (idSchool: string | number): Promise<any> => {
  return await AUTHSERVICE().get(`/level?idSchool=${idSchool}`)
}