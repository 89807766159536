import React from 'react'
import { IconProps } from '../../../types'
export const RightArrow = ({
  height = 22,
  width = 26,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 1L25 11M25 11L15 21M25 11H1'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
