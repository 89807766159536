import React from 'react'
import Button from 'components/buttons/Button/Button'

import style from '../uniformlist.module.scss'

const TitleHeader = ({
  title = 'vacio',
  Icon,
  IconProps,
  buttonProps,
  showButton = true
}: {
  title?: string
  Icon?: any
  IconProps?: any
  buttonProps?: any
  showButton?: boolean
}) => {
  return (
    <div className={`${style['div-Title']}`}>
      <div className={`${style['div-Title']}`}>
        {Icon && <Icon {...IconProps} />}

        <p className={`${style['P-title']}`}>{title}</p>
      </div>
      {showButton && <Button {...buttonProps} />}
    </div>
  )
}

export default TitleHeader
