import React from 'react'
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import style from './fixedinfofooter.module.scss'

const OptionStatusBar = ({
  title = 'vacio',
  step = 0,
  success = false,
  onCurse = false,
  showChevron = true
}: {
  title?: string
  step?: number
  success?: boolean
  onCurse?: boolean
  showChevron?: boolean
}) => {
  return (
    <div className={`${style['div-option-status']}`}>
      <p
        className={`${style['span-icon']} 
      ${
        success
          ? style['span-green']
          : onCurse
          ? style['span-blue']
          : style['span-stay']
      }`}
      >
        {!success ? step : <CheckIcon className={`${style['check-icon']}`} />}
      </p>
      <p
        className={`${style['p-title']}
        ${
          success
            ? style['p-green']
            : onCurse
            ? style['p-blue']
            : style['p-grey']
        }`}
      >
        {title}
      </p>
      {showChevron && (
        <ChevronRightIcon className={`${style['hero-chevron']}`} />
      )}
    </div>
  )
}

export default OptionStatusBar
