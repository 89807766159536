/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnsType } from 'antd/es/table'
import {
  ErrorToast,
  SuccessToast,
  WarningToast
} from 'components/Toasts/Toasts'
import { setStatus } from 'hooks/books/useBooks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeMultipleStatusQuotas,
  deleteQuota,
  setIDLevelQuotas,
  setIdQuotas,
  setLevelsQuotas,
  setLevelsQuotasAdmin,
  setListQuotas,
  setQuotasSchoolArray,
  updateCloseQuota,
  updateEndList,
  updateQuotas
} from 'redux/reducers/quotas'
import {
  getLevels,
  getLevelsBySchool
} from 'services/schoolUserServices/levels'
import Tag from 'components/tag'
import { RootState } from 'redux/store/store'
import { Form, Tooltip } from 'antd'
import * as services from 'services/quotas/quotas'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Options } from 'types'
import * as servicesSchool from 'services/schoolAdminServices/schools'
import {
  getGeneralModulesStatus,
  getLogs
} from 'services/genericServices/genericServices'
import React from 'react'
import lodash from 'lodash'
import { useLocation } from 'react-router'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import filterlevelsByPermission from 'utilities/filterlevelsByPermission'

const INITIAL_STATE_QUOTA = {
  id: 0,
  name: '',
  action: 'new',
  toast: 'Cuota creada exitosamente.'
}
export const INITIAL_PAGE: number = 1
const INITIAL_STATE_QUOTAS: any = {
  total: 0,
  page: 1,
  limit: 10,
  totalPages: 0,
  quotas: []
}

export const quotaAuthColumns: ColumnsType<any> = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',

    render: (text: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(text)
    }
  }
]

export const useQuotas = () => {
  const [actions, setActions] = useState(INITIAL_STATE_QUOTA)
  const [idGrade, setIdGrade] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [openModalQuota, setOpenModalQuota] = useState<boolean>(false)
  const [modalEndList, setModalEndList] = useState<boolean>(false)
  const [modalAuthorize, setModalAuthorize] = useState<boolean>(false)
  const [modalRequestChanges, setModalRequestChanges] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [schoolsCatalog, setSchoolCatalog] = useState<Options[]>([])
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
  const [changeViewAdmin, setChangeViewAdmin] = useState<any>('all')
  const [quotas, setQuotas] = useState<any>(INITIAL_STATE_QUOTAS)
  const [pageQuotas, setPageQuotas] = useState<number>(INITIAL_PAGE)
  const [selectSchool, setSelectSchool] = useState<string>('')
  const [selectStatus, setSelectStatus] = useState<string>('')
  const [searchGralQuotas, setSearchGralQuotas] = useState<string>('')
  const [selectedLevel, setSelectedLevel] = useState<any>(undefined)
  const [idLevel, setIdLevel] = useState<any>()
  const [idSchool, setIdSchool] = useState<any>()
  const [loadingDowload, setloadingDowload] = useState(false)
  const [quotaSchool, setQuotaSchool] = useState<any>()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const [form] = Form.useForm()

  const { levelsQuotas, IDLevel, newStructure, idQuotas, levelsQuotasAdmin } =
    useSelector((state: RootState) => state.quotas)

  const dispatch = useDispatch()

  const onHandleOpenDeleteQuota = (
    id: number,
    name: string,
    id_grade: number
  ) => {
    setActions({
      id,
      name,
      action: 'delete',
      toast: 'Cuota eliminada exitosamente.'
    })

    setOpenModalQuota(true)
    setIdGrade(id_grade)
  }
  const onHandleOpenEditQuota = (values: any) => {
    setOpenModalQuota(true)
    const price = (+values.price).toFixed(2)
    const parseValues: any = { name: values.name, price }
    form.setFieldsValue(parseValues)
    setActions({
      id: values.id,
      name: values.name,
      action: 'edit',
      toast: `Cuota ${values.name} actualizada exitosamente.`
    })
    setIdGrade(values.id_grade)
  }

  const onHandleOpenModalEndList = (idGrade: number) => {
    setModalEndList(true)
    setIdGrade(idGrade)
  }

  const onHandleCancelModalEndList = () => {
    setModalEndList(false)
  }

  const quotaColumns: ColumnsType<any> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '80%'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      render: (text: number, field: any) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(text)
      }
    },

    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 150,

      render: (_, { status }) => (
        <>
          {(() => {
            const title = setStatus(status)

            return <Tag variation={status} title={title} />
          })()}
        </>
      )
    },
    {
      ...(uid.role !== 'Ezetera' &&
        uid.role !== 'Director' && {
          title: '',
          key: 'actions',
          width: 150,
          fixed: 'right',
          align: 'center',

          render: (_: any, record) => (
            <>
              {!record.close && record.status < 5 && (
                <Tooltip title='Editar cuota'>
                  <PencilSquareIcon
                    style={{
                      color: '#0F48FF',
                      cursor: 'pointer',
                      width: '18px'
                    }}
                    onClick={async (e) => {
                      e.stopPropagation()
                      onHandleOpenEditQuota(record)
                    }}
                  />
                </Tooltip>
              )}

              {!record.close && (
                <>
                  &nbsp;&nbsp;
                  <Tooltip title='Eliminar cuota'>
                    <TrashIcon
                      onClick={async (e) => {
                        e.stopPropagation()
                        onHandleOpenDeleteQuota(
                          record?.id,
                          record?.name,
                          record?.id_grade
                        )
                      }}
                      style={{
                        color: '#EF5D60',
                        cursor: 'pointer',
                        width: '18px'
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          )
        })
    }
  ]

  useEffect(() => {
    if (uid.role !== 'Ezetera') {
      try {
        if (levelsQuotas.length === 0 || newStructure) {
          const getSchoolBooks = async (): Promise<any> => {
            const data = await getLevels({})
            return data
          }

          getSchoolBooks().then((res) => {
            const filteredLevelsByPermision = filterlevelsByPermission(
              res.data.levels,
              uid.responsabilities,
              uid.role
            )
            const TabsByLevels = filteredLevelsByPermision.map((res: any) => {
              return {
                key: res.id,
                label: res.name,
                grades: res.grades
              }
            })

            dispatch(setLevelsQuotas(TabsByLevels))
            dispatch(setIDLevelQuotas(TabsByLevels[0]?.key))
          })
        }
      } catch (error) {
        ErrorToast('Ups. Algo salio mal.')
      }
    }
  }, [])

  const donwloadLogs = async () => {
    setloadingDowload(true)
    try {
      const res: any = await getLogs('quota')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')
    }
    setloadingDowload(false)
  }

  const getQuotasAdmin = async (
    page: number,
    school: string,
    status: string,
    search: string
  ): Promise<any> => {
    try {
      setLoading(true)
      const res = await services.getQuotasAdmin(page, school, status, search)
      setQuotas(res?.data?.data)
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      getQuotasAdmin(pageQuotas, selectSchool, selectStatus, searchGralQuotas)
    }
  }, [pageQuotas, selectSchool, selectStatus])

  const SchoolCatalog = async (): Promise<any> => {
    try {
      const res = await servicesSchool.getCatalogSchools()

      setSchoolCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.schools
      ])
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const StatusCatalog = (): void => {
    try {
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        { label: 'Disponible', value: 4 },
        { label: 'Autorizado', value: 5 }
      ])
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      SchoolCatalog()
      StatusCatalog()
    }
  }, [])

  const getQuotaSchool = async (): Promise<void> => {
    try {
      const res = await services.getQuotaSchool(+uid.id_school)

      setQuotaSchool(res?.data?.quota)
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  useEffect(() => {
    if (uid.role !== 'Ezetera') {
      getQuotaSchool()
    }
  }, [])

  const onHandleChangePage = (page: number): void => {
    setPageQuotas(page)
  }

  const handleCloseModalQuota = () => {
    setOpenModalQuota(false)
    form.resetFields()
  }
  const handleOpenModalQuota = (idGrade: number) => {
    setIdGrade(idGrade)
    setActions(INITIAL_STATE_QUOTA)
    setOpenModalQuota(true)
  }

  const handleChangeLevelTab = (key: string) => {
    dispatch(setIDLevelQuotas(key))
  }

  const onHandleForm = async (values: any): Promise<void> => {
    try {
      setLoading(true)

      const quota: number = +quotaSchool

      const PVP: number = (+values.price / (1 - quota / 100)) * 1.16

      // 250 / (1 - 13/100) * 1.16 =  333.33296

      if (actions.action === 'new') {
        const sendValues = {
          name: values.name,
          price: PVP.toFixed(2),
          id_school: uid.id_school,
          id_level: IDLevel,
          id_grade: idGrade,
          original_price: +values.price
        }

        await services.createQuota(sendValues)
      }
      if (actions.action === 'edit') {
        const sendValues = {
          id: actions.id,
          compare_name: actions.name,
          name: values.name,
          price: PVP.toFixed(2),
          id_school: uid.id_school,
          id_level: IDLevel,
          id_grade: idGrade,
          original_price: +values.price
        }

        await services.updateQuota(sendValues)

        dispatch(
          updateQuotas({
            id: actions.id,
            values: { name: values.name, price: PVP.toFixed(2) }
          })
        )
      }

      handleCollapseQuotas(+idGrade)

      form.resetFields()

      SuccessToast(actions.toast)
      setOpenModalQuota(false)
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  const onHandleDeleteQuota = async (): Promise<void> => {
    try {
      setLoading(true)

      await services.deleteQuota(actions.id)

      // handleCollapseQuotas(+idGrade)

      dispatch(deleteQuota(actions.id))

      SuccessToast(actions.toast)
      setOpenModalQuota(false)
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  const handleCollapseQuotas = async (idGrade: number): Promise<void> => {
    try {
      setLoading(true)
      const response = await services.getQuotas(
        +uid.id_school,
        IDLevel,
        idGrade
      )

      const saveResponse = response?.data?.quotas.map((quota: any) => {
        return {
          ...quota,
          idGrade
        }
      })

      dispatch(setQuotasSchoolArray(saveResponse))
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  const { pathname } = useLocation()

  const getPDFListLogic = async () => {
    try {
      const result = await getGeneralModulesStatus()
      if (
        pathname.includes('cuotas') &&
        result.data.quotas_status === 'Completado'
      )
        dispatch(setCloseSupplyList(true))
    } catch (error) {
      console.log('error get pdf list', error)
    }
  }
  const handleEndList = async (): Promise<void> => {
    try {
      setLoading(true)

      await services.updateOpenListQuota({ id_grade: idGrade, close: true })

      dispatch(updateCloseQuota({ idGrade, close: true }))
      dispatch(updateEndList({ idGrade, status: true }))
      setModalEndList(false)
      getPDFListLogic()
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenAuth = async (idGrade: number): Promise<void> => {
    setModalAuthorize(true)
    setSelectedRowKeys([])

    try {
      setLoading(true)

      const fetchQuotas = await services.getAvailableQuotas(
        +uid.id_school,
        IDLevel,
        idGrade
      )
      const allIdQuotas = fetchQuotas?.data?.quotas?.map((item: any) => item.id)

      dispatch(setListQuotas(fetchQuotas?.data?.quotas))
      dispatch(setIdQuotas(allIdQuotas))
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }
  const handleCancelAuth = async (): Promise<void> => {
    dispatch(setListQuotas([]))
    setModalAuthorize(false)
  }

  const handleAllAuthorize = async (): Promise<void> => {
    try {
      setLoading(true)

      if (idQuotas.length <= 0) {
        WarningToast('No exista nada para autorizar')
        return
      }

      await services.authorizeQuota({ id: idQuotas })
      dispatch(changeMultipleStatusQuotas({ id: idQuotas, status: 5 }))
      SuccessToast('Cuotas autorizadas correctamente')
      setModalAuthorize(false)
      dispatch(setListQuotas([]))
      setSelectedRowKeys([])
      getPDFListLogic()
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }
  const handleAuthorize = async (id: any): Promise<void> => {
    try {
      setLoading(true)
      if (id.length <= 0) {
        WarningToast('Selecciona una o más cuotas para autorizar.')
        return
      }
      await services.authorizeQuota({ id })
      dispatch(changeMultipleStatusQuotas({ id, status: 5 }))
      SuccessToast('Cuotas autorizadas correctamente')
      setModalAuthorize(false)
      dispatch(setListQuotas([]))
      setSelectedRowKeys([])
      getPDFListLogic()
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenRequestChanges = (idGrade: number) => {
    setIdGrade(idGrade)
    setModalRequestChanges(true)
  }
  const handleCancelRequestChanges = () => {
    setModalRequestChanges(false)
  }

  const handleRequestChanges = async (): Promise<any> => {
    try {
      await services.requestChangesQuotas({
        id_grade: idGrade,
        request_changes: true,
        mode: 'sent'
      })

      setModalRequestChanges(false)
      SuccessToast(
        'Se ha solicitado la modificación para esta lista, un administrador se encargará de verificar su pedido'
      )
    } catch (error) {
      ErrorToast('Ups. Algo salio mal')
    }
  }

  const onHandleChangeSchool = async (value: string): Promise<any> => {
    setPageQuotas(INITIAL_PAGE)
    setSelectSchool(value === 'todos' ? '' : value)

    setChangeViewAdmin(value === 'todos' ? 'all' : 'school')

    if (value !== 'todos') {
      try {
        setLoading(true)

        const getSchoolQuotas = async (): Promise<any> => {
          const data = await getLevelsBySchool(value)
          return data
        }

        getSchoolQuotas().then((res) => {
          const TabsByLevels = res.data.levels.map((res: any) => {
            return {
              key: res.id,
              label: res.name,
              grades: res.grades
            }
          })

          dispatch(setLevelsQuotasAdmin(TabsByLevels))

          setSelectedLevel(levelsQuotasAdmin[0])
          setIdLevel(TabsByLevels[0]?.key)
          setIdGrade(TabsByLevels[0]?.grades?.[0]?.id)
          setIdSchool(value)
        })
      } catch (error) {
        ErrorToast('Ups! Algo salio mal.')
      } finally {
        setLoading(false)
      }
    }
  }

  const onHandleChangeStatus = (value: string) => {
    setPageQuotas(INITIAL_PAGE)
    setSelectStatus(value === 'todos' ? '' : value)
  }

  const debouncedSearch = React.useRef(
    lodash.debounce(async ({ selectSchool, selectStatus, value }: any) => {
      const res = await services.getQuotasAdmin(
        1,
        selectSchool,
        selectStatus,
        value ? value : ''
      )
      setQuotas(res?.data?.data)
    }, 300)
  ).current
  const onHandleSearchQuotas = (value: string) => {
    setPageQuotas(INITIAL_PAGE)
    setSearchGralQuotas(value ? value : '')
    debouncedSearch({
      selectSchool,
      selectStatus,
      value
    })
  }

  const downloadQuotas = async (): Promise<any> => {
    try {
      setLoading(true)

      const res = await services.downloadQuotas()
      const { url } = res?.data

      if (url) {
        window.open(url, '__blank')
      }
      // SuccessToast('Libros Descargados con éxito.')
    } catch (error: any) {
      switch (error?.response?.data.message) {
        case 'No data':
          ErrorToast('No hay elementos que descargar.')
          break

        default:
          ErrorToast('Ups. Algo salio mal.')

          break
      }
    } finally {
      setLoading(false)
    }
  }
  //producto pendiente de comision
  return {
    actions,
    loading,
    idGrade,
    idSchool,
    idLevel,
    quotaColumns,
    openModalQuota,
    form,
    modalEndList,
    modalAuthorize,
    selectedRowKeys,
    modalRequestChanges,
    schoolsCatalog,
    statusCatalog,
    changeViewAdmin,
    quotas,
    selectStatus,
    searchGralQuotas,
    quotaSchool,
    loadingDowload,
    setIdLevel,
    handleOpenModalQuota,
    handleCloseModalQuota,
    setIdGrade,
    handleChangeLevelTab,
    onHandleForm,
    handleCollapseQuotas,
    onHandleDeleteQuota,
    onHandleOpenModalEndList,
    onHandleCancelModalEndList,
    handleEndList,
    handleOpenAuth,
    handleCancelAuth,
    handleAllAuthorize,
    handleAuthorize,
    setSelectedRowKeys,
    handleOpenRequestChanges,
    handleCancelRequestChanges,
    handleRequestChanges,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onHandleSearchQuotas,
    downloadQuotas,
    donwloadLogs
  }
}
