import React from 'react'
import { useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const useIdSchoolUser = () => {
  const { dataToken } = useDecodeToken()

  return dataToken().uid.id_school as number
}

export default useIdSchoolUser
