import { Select, Table, Typography } from 'antd'
import React, { memo } from 'react'
import { useQuotasTable } from './hook/useQuotasTable'

const OddsTable = memo(() => {
  const {
    quotas,
    loading,
    pagination,
    quotasColumns,
    statusCatalog,
    onHandleChangeStatus,
  } = useQuotasTable()
  return (
    <div>
        <div>
          <Typography>Estatus</Typography>
          <Select
            style={{ width: 200 }}
            defaultValue={'todos'}
            placeholder={'Estatus'}
            onChange={onHandleChangeStatus}
            options={statusCatalog.filter(
              (item: any) => item.label !== 'Nuevo'
            )}
          />
        </div>
      <Table
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        dataSource={quotas}
        style={{ marginTop: '2.5rem' }}
        columns={quotasColumns}
        loading={loading}
        pagination={pagination}
        onRow={() => {
          return {
            onClick: () => undefined
          }
        }}
      />
    </div>
  )
})
OddsTable.displayName = 'OddsTable'
export default OddsTable
