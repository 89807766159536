import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import JumperSvg from 'assets/img/uniforms/jumper/JumperSvg'
import LabelJunper from 'assets/img/uniforms/jumper/LabelJunper'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const JumperEditable = () => {
  const { jumperForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Jumper',
        description: 'Descripcion del Jumper'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {jumperForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[`logo-position-jumper-${jumperForm.logoPosition.value}`]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <JumperSvg
              className={`${style['svg']}`}
              neckType={jumperForm.neckType.value as string}
              pretinas={jumperForm.pretinas.value as boolean}
              pinzas={jumperForm.pinzas.value as boolean}
            />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <LabelJunper className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default JumperEditable
