import React, { useState, useEffect } from 'react'
import style from './notebooksEitable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PathPortadasExteriores from 'assets/img/notebooks/horizontals/PathPortadasExteriores'
import PathSujecionSpiralHorizontalInside from 'assets/img/notebooks/horizontals/PathSujecionSpiralHorizontalInside'
import PathEngomadoCocidoEoutside from 'assets/img/notebooks/horizontals/PathEngomadoCocidoEoutside'
import DraggableImgCenterProporsalHorizontal from '../EditorDiv/DraggableImgCenterProporsalHorizontal'
import EditablePanel from './EditablePanel'
import { setPositionsCovers } from 'redux/reducers/notebooksSlice'
import HorizontalEditableDraggable from './HorizontalEditableDraggable'

const HorizontalPortadasEditable = () => {
  const {
    notebookFrontValues,
    notebookSelected,
    edithUpload,
    positionCovers,
    isCoverOnEdith
  } = useSelector((state: RootState) => state.notebooks)
  const dispatch = useDispatch()
  const { spine_color, binding_type, binding_color, rounded, cover_design } =
    notebookFrontValues

  return (
    <div className={`${style['div-container-sticky']}`}>
      <EditablePanel />

      <div className={`${style['div-notebook-svg']}`}>
        {cover_design.value === 'design' && <HorizontalEditableDraggable />}
        {[1, 2].map((item: number, index: number) => {
          let viewBox = item === 1 ? '35 0 820 700' : '35 0 820 700'
          return (
            <div
              key={index}
              className={`${style['div-polo-notebook-relative']} 
                  ${item === 1 && style['transform-invert']}`}
            >
              <div className={`${style[`logo-position-`]}`}></div>
              <div className={`${style['div-polo-svg-absolute']}`}>
                <PathPortadasExteriores
                  viewBox={viewBox}
                  showSizes={item}
                  className={`${style['svg']}`}
                  notebookSelected={notebookSelected}
                  colorLomo={spine_color.value as string}
                  rounded={rounded.value as boolean}
                  date={`${style['date1-font-12']}`}
                />
              </div>
              <div className={`${style['div-shadows-svg-absolute']}`}>
                {cover_design.value === 'upload' &&
                  !edithUpload.left &&
                  !edithUpload.right && (
                    <DraggableImgCenterProporsalHorizontal
                      setPositions={(dat: { back: number; front: number }) =>
                        dispatch(setPositionsCovers(dat))
                      }
                      positions={positionCovers}
                      edithSides={edithUpload}
                      item={item}
                    />
                  )}
              </div>
              {isCoverOnEdith && (
                <div className={`${style['div-tags-svg-absolute']}`}>
                  {binding_type.value === 'Espiral' ? (
                    <>
                      <PathSujecionSpiralHorizontalInside
                        className={`${style['svg']}`}
                        viewBox={viewBox}
                        show={2}
                        spiralColor={binding_color.value as string}
                      />
                    </>
                  ) : (
                    <>
                      <PathEngomadoCocidoEoutside
                        color={binding_color.value as string}
                        viewBox={viewBox}
                        className={`${style['svg']}`}
                      />
                    </>
                  )}
                </div>
              )}

              <div className={`${style['div-sujecion-svg-absolute']}`}>
                {cover_design.value === 'upload' &&
                  (edithUpload.left || edithUpload.right) && (
                    <DraggableImgCenterProporsalHorizontal
                      setPositions={(dat: { back: number; front: number }) =>
                        dispatch(setPositionsCovers(dat))
                      }
                      positions={positionCovers}
                      edithSides={edithUpload}
                      item={item}
                    />
                  )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default HorizontalPortadasEditable
