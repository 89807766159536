import React, { useState, useEffect } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import style from './layers.module.scss'

const OptionFloating = ({
  text,
  img,
  showMe,
  clickMe,
  id
}: {
  text?: string
  img?: string
  showMe: boolean
  clickMe: (dat: boolean, num: number) => void
  id: number
}) => {
  const [show, setshow] = useState(true)
  const [tooltip, setTooltip] = useState(false)

  useEffect(() => {
    setshow(showMe)
  }, [showMe])

  return (
    <div className={`${style['flex-component']}`}>
      <button
        onClick={() => {
          setshow(!show)
          clickMe(!show, id)
        }}
        className={`${style['eye-button']}`}
      >
        {show ? (
          <EyeIcon className={`${style['icons-hero']}`} />
        ) : (
          <EyeSlashIcon className={`${style['icons-hero']}`} />
        )}
      </button>
      {text && <div className={`${style['text-icon']}`}>T</div>}
      {img && (
        <div
          onMouseOver={() => setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
          className={`${style['img-little']}`}
        >
          <img className={`${style['img-show']}`} src={img} />
          {tooltip && (
            <div className={`${style['tooltip-my']}`}>
              <img className={`${style['img-show']}`} src={img} />
            </div>
          )}
        </div>
      )}
      {img && <p className={`${style['p-title']}`}>img: {id}</p>}
      {text && <p className={`${style['p-title']}`}>{text}</p>}
    </div>
  )
}

export default OptionFloating
