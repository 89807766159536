import React from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { store } from './redux/store/store'
import './styles/global.scss'
import reportWebVitals from './reportWebVitals'
import Router from './router/Router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import locale from 'antd/locale/es_ES'
const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <ConfigProvider locale={locale}>
    <Provider store={store}>
      <div className='app'>
        <Router />
      </div>
      <ToastContainer />
    </Provider>
  </ConfigProvider>
)

reportWebVitals()
