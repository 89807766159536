import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import CamisaSvg from 'assets/img/uniforms/camisa/CamisaSvg'
import GreenTagCamisa from 'assets/img/uniforms/camisa/GreenTagCamisa'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const CamisaMcMlEditable = () => {
  const { pictureLogo, camisaMlMcForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Camisa manga larga/ manga corta',
        description: 'Descripcion de la camisa manga larga/ manga corta'
      })
    )
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {camisaMlMcForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[`logo-position-MlMc-${camisaMlMcForm.logoPosition.value}`]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <CamisaSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <GreenTagCamisa className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default CamisaMcMlEditable
