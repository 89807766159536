import { Form, Input } from 'antd'
import { memo, KeyboardEvent, ChangeEvent } from 'react'

export interface InputFormProps {
  name: string
  type?: string
  placeholder: string
  label?: string | JSX.Element
  maxLength?: number
  rules?: Array<any>
  prefix?: JSX.Element
  suffix?: JSX.Element
  disabled?:boolean
  onkeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void,
  onChange?: (e:ChangeEvent<HTMLInputElement>) => void,
  hasFeedback?: boolean
  min?:number
}

const FormInput = memo((props: InputFormProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      hasFeedback={props?.hasFeedback != undefined ? props.hasFeedback: true}
    >
      {props.type === 'password' ? (
        <Input.Password
          prefix={props.prefix}
          suffix={props.suffix}
          type={props.type}
          placeholder={props.placeholder}
          onKeyPress={props.onkeyPress}
          maxLength={props.maxLength}
          size='large'
        />
      ) : (
        <Input
          prefix={props.prefix}
          suffix={props.suffix}
          type={props.type}
          min={props.min}
          placeholder={props.placeholder}
          onKeyPress={props.onkeyPress}
          maxLength={props.maxLength}
          size='large'
          disabled={props.disabled}
          onChange={props.onChange}
        />
      )}
    </Form.Item>
  )
})

export default FormInput
