import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import FaldaSvg from 'assets/img/uniforms/falda/FaldaSvg'
import LabelFalda from 'assets/img/uniforms/falda/LabelFalda'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const FaldaEditable = () => {
  const { pictureLogo, faldaForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
    dispatch(
      handleClotheName({
        name: 'Falda',
        description: 'Descripcion de la falda'
      })
    )
  }, [])

  const { bolsas } = faldaForm

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {faldaForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[`logo-position-falda-${faldaForm.logoPosition.value}`]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <FaldaSvg
              className={`${style['svg']}`}
              bolsas={bolsas.value as boolean}
            />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <LabelFalda className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default FaldaEditable
