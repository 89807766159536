import React from 'react'
import { IconProps } from '../../../types'
export const UploadIcon = ({
  height = 22,
  width = 16,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 7.25H3.5C2.25736 7.25 1.25 8.25736 1.25 9.5V18.5C1.25 19.7426 2.25736 20.75 3.5 20.75H12.5C13.7426 20.75 14.75 19.7426 14.75 18.5V9.5C14.75 8.25736 13.7426 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25L8 14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
