/* eslint-disable react-hooks/exhaustive-deps */
import { Options } from 'components/formInputs/select'
import Tags from 'components/tag'
import { useEffect, useState } from 'react'
import { getCatalogStatus } from 'services/books/books'
import { getAllOddsList } from 'services/schoolUserServices/listOfElements'
import { handleErrors } from 'utilities/handleErrors'
export function useQuotasTable() {
  const quotasColumns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => {
        return <Tags variation={status} />
      }
    }
  ]
  const [status, setStatus] = useState('')
  const [quotas, setQuotas] = useState([])
  const [loading, setloading] = useState(true)
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])

  const handleChangePage = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: e
      }
    })
  }
  const [pagination, setpagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    onChange: handleChangePage
  })

  useEffect(() => {
    hangleGetStatusCatalog()
  }, [])

  useEffect(() => {
    hangleGetUtils(status, pagination.current)
  }, [status, pagination.current])

  const hangleGetUtils = async (status: string, page: number) => {
    setloading(true)
    try {
      const result = await getAllOddsList({ status, page })
      setQuotas(result.data.data.quotas)
      setpagination((prev) => {
        return {
          ...prev,
          current: result.data.data.page,
          pageSize: 20,
          total: result.data.data.total
        }
      })
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false)
    }
  }

  const hangleGetStatusCatalog = async (): Promise<any> => {
    try {
      const res = await getCatalogStatus()
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])
    } catch (error) {
      handleErrors(error)
    }
  }
  
  const onHandleChangeStatus = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: 1
      }
    })
    if (e === 'todos') return setStatus('')
    return setStatus(e)
  }

  return {
    quotas,
    status,
    loading,
    pagination,
    quotasColumns,
    statusCatalog,
    setStatus,
    onHandleChangeStatus
  }
}
