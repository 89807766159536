import { memo, useState } from 'react'
import style from './Sidebar.module.scss'
import { Avatar, Modal } from 'antd'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import S from 'components/texts/S/S'
import B from 'components/texts/B/B'
import Birrete from 'assets/img/birrete.svg'
import { useDispatch } from 'react-redux'
import {
  setThisUserIdSchool,
  setThisUserRole
} from 'redux/reducers/usersManagementSlice'

import RenderMenu from './components/RenderMenu'

interface navbarInterface {
  complete?: boolean
}

const Sidebar = memo(({ complete }: navbarInterface) => {
  const [firstTimeModal, setFirstTimeModal] = useState(false)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const dispatch = useDispatch()

  dispatch(setThisUserRole(dataToken().uid.role))
  dispatch(setThisUserIdSchool(dataToken().uid.id_school))

  return (
    <div className={style.sidebar} data-variation={complete ? 'complete' : ''}>
      <div className={style.top}>
        <Avatar
          style={{ backgroundColor: '#F0F5FF', verticalAlign: 'middle' }}
          size='large'
        >
          {uid?.name?.charAt(0).toLocaleUpperCase() as string}
        </Avatar>
        <div className={style.userInfo}>
          <S variation='2'>{(uid?.name as string) ?? ''}</S>
          <B size='Light'>{(uid?.mail as string) ?? ''} </B>
        </div>
      </div>

      <RenderMenu />

      <Modal
        title='Vertically centered modal dialog'
        centered
        open={firstTimeModal}
        onOk={() => setFirstTimeModal(false)}
        onCancel={() => setFirstTimeModal(false)}
      >
        <img src={Birrete} alt='Birrete' />
      </Modal>
    </div>
  )
})

export default Sidebar
