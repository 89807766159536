import React from 'react'

const PathSujecionPortadasVertical = ({
  className,
  viewBox = '0 0 800 800',
  show,
  color
}: {
  className: string
  viewBox?: string
  show: number
  color: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
      viewBox={viewBox}
      stroke={"black"}
      className={className}
    >
      <g id='Path_lomo' data-name='Path lomo'>
        <g id='Path_lomo-2' data-name='Path lomo'>
          <g id='Path_lomo-3' data-name='Path lomo'>
            {(() => {
              switch (show) {
                case 1:
                  return (
                    <g>
                      <g id='Lomo_izquierdo' data-name='Lomo izquierdo'>
                        <rect
                          x='86.75'
                          y='80.5'
                          width='91.26'
                          height='681.74'
                        />
                        <path d='m177.51,81v680.74h-90.26V81h90.26m1-1h-92.26v682.74h92.26V80h0Z' />
                      </g>
                    </g>
                  )
                case 2:
                  return (
                    <g>
                      <g id='Lomo_derecho' data-name='Lomo derecho'>
                        <rect
                          x='655.56'
                          y='80.5'
                          width='91.26'
                          height='681.74'
                        />
                        <path d='m746.33,81v680.74h-90.26V81h90.26m1-1h-92.26v682.74h92.26V80h0Z' />
                      </g>
                    </g>
                  )

                default:
                  return <></>
              }
            })()}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PathSujecionPortadasVertical
