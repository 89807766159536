import React from 'react'

const LabelsTShirts = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral" stroke='black'>
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path stroke='#f5e7ca' fill='#f5e7ca' d="m621.57,659.78c-.98.07-1.33.42-1.13,2.39.55,5.37.98,10.73,1.39,16.1.13,1.72.46,2.11,1.37,2.01,5.85-.56,11.7-1.03,17.55-1.54l-1.75-20.43-.24.03c-5.73.48-11.46,1.02-17.19,1.44Z"/>
      <path stroke='green' fill='green' d="m630.13,676.26c.28-.27.56-.57.85-.81.91-.72,1.44-2.05,1.98-3.4.32-.81.25-1.53-.24-2.3-1.52-2.38-3.34-3.42-5.23-4.11-.06-.03-.13-.06-.2-.13.77-.14,1.51.46,2.25.67.22.05.51.41.64,0,.11-.37-.16-.72-.3-1.05-.22-.53-.47-1.03-.66-1.6,1.16,2.17,2.54,3.66,4.11,4.66.68.45.97.15,1.16-.87.32-1.64.64-3.27.2-5.14.91,2.06,2,3.97,1.64,6.79.37-.02.68-.04,1.01-.08.33-.05.46.37.58.76.2.61-.19.43-.31.6-.64.98-1.53,1.41-1.92,2.95-.27,1.03-.99,1.35-1.62,1.68-1.27.66-2.59,1.08-3.95,1.36Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelsTShirts
