import React from 'react'

const GreenTagsPolo = ({
  className,
  stroke = '#305599',
  styledFont,
  aperture
}: {
  className: string
  stroke: string
  styledFont?: string
  aperture: boolean
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
 <g id="Green_Tag" data-name="Green Tag" stroke='green'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <g id="Green_Tag-3" data-name="Green Tag">
        <g id="Green_Tag-4" data-name="Green Tag">
          <g>
            <path   d="m419.92,84.66s2.19-1,2.59-1.21c0,0,.05-.02,0-.08-.04-.02,0-.1.28-.35,0,0,.88-.65,1.24-1.26.33-.59.42-.82.15-1.15-.27-.34-1.05-1.25-2.08-1.83-1.03-.61-1.99-1.08-2.87-1.34-.85-.29-1.47-.38-1.96-.47-.5-.11-.19-.17.27-.15.44,0,1.47.27,2.32.54.84.27,1.41.31,1.41.31,0,0-.1-.58-.57-1.2-.45-.59-1.1-1.56-1.62-2.18-.55-.61-.82-.89-.75-.94.05-.07.15-.05,1.08,1.07.93,1.08,1.24,1.61,1.76,2.17.52.57.93,1.08,1.72,1.54.75.44,1.86,1.07,2.87,1.33,0,0,.68-.66,1-1.6.32-.93.3-.82.33-.77.02.05.08.16.08-.38,0-.5-.08-1.47-.11-1.63-.02-.15,0-.18.07-.19.08-.02.25.29.45.64.2.37.6,1.07.89,1.83.26.79.57,1.49-.02,2.55,0,0-.05.04.03.04.07,0,.52.02.71-.02.19,0,.61-.18.84.04.22.21.28.57.28.57,0,0,.19.11.23.15.05.05.02.05-.07.08-.08.04-.34.07-.64.25-.32.18-1.17.79-1.97.99,0,0-.1.83-1.06,1.36-.94.52-1.77.74-3.52,1.02-1.76.32-2.67.43-3.29.46,0,0-.46.08-.06-.15Z"/>
            <path   d="m378.23,82.15c-.5.06-.52.11-.54.6v.7c0,.28.03.55.06.77-.51.14-1.18.3-1.84.31-1.84-.01-3.05-1.15-3.04-2.79,0-1.9,1.55-2.81,3.15-2.83.7,0,1.34.19,1.6.25.02.31.08.74.13,1.29l-.22.03c-.2-1.01-.82-1.26-1.61-1.27-1.28,0-2.22.92-2.23,2.43.02,1.21.71,2.6,2.3,2.58.29.02.55-.03.75-.13.15-.08.25-.19.25-.53v-.71c0-.59-.05-.67-.88-.73v-.22s2.13.01,2.13.01v.23Z"/>
            <path   d="m384.09,84.47h-.27c-.57-.05-.89-.2-1.23-.69-.27-.37-.54-.85-.77-1.2-.12-.23-.26-.31-.65-.32h-.23s0,1.22,0,1.22c0,.63.07.68.67.73v.2h-1.98v-.2c.62-.06.68-.1.68-.75v-3.01c0-.66-.06-.69-.66-.75v-.22s1.89.02,1.89.02c.55,0,.93.06,1.21.25.31.19.51.55.52,1,0,.66-.42,1.03-.97,1.29.13.24.44.75.68,1.1.27.38.41.59.61.76.19.24.39.33.55.37l-.03.2Zm-2.79-2.46c.4,0,.64-.03.81-.21.3-.23.43-.52.43-.93,0-.83-.54-1.12-1.11-1.11-.21-.02-.34,0-.42.06-.05.05-.08.16-.08.36v1.85s.36-.01.36-.01Z"/>
            <path   d="m389.07,83.23c-.04.24-.2.96-.27,1.19l-3.65-.02v-.19c.69-.08.75-.14.75-.75l.02-2.99c-.02-.68-.07-.71-.68-.78l.02-.21h3.38c0,.19.05.71.06,1.1l-.22.05c-.06-.32-.14-.49-.24-.66-.11-.17-.3-.21-.87-.22l-.56-.02c-.25,0-.26.04-.25.26v1.72s.7,0,.7,0c.68-.02.73-.05.82-.58h.22v1.43s-.22.02-.22.02c-.09-.54-.15-.58-.82-.58h-.7v1.43c-.02.34.02.51.17.61.15.09.4.11.78.1.59,0,.8-.06.97-.24.12-.13.26-.43.38-.71l.22.04Z"/>
            <path   d="m393.77,83.23c-.05.24-.19.97-.28,1.17l-3.66.03v-.22c.7-.06.76-.12.75-.74v-3c0-.68-.05-.71-.63-.78v-.19s3.38,0,3.38,0c0,.17.04.69.06,1.09l-.22.04c-.07-.31-.13-.48-.23-.65-.11-.17-.32-.19-.86-.19h-.57c-.24-.03-.27,0-.26.23v1.73s.72-.03.72-.03c.7.02.73-.06.82-.56h.21v1.46h-.21c-.12-.53-.16-.59-.82-.6l-.73.02v1.42c0,.34.04.52.19.6.12.12.4.1.78.12.57,0,.8-.06.96-.26.13-.17.28-.41.37-.7l.23.03Z"/>
            <path   d="m400.11,79.74c-.42,0-.61.1-.62.44-.05.22-.05.57-.05,1.26v3.04s-.25.01-.25.01l-3.22-3.99h0s-.02,1.93-.02,1.93c0,.69.03,1.06.06,1.26.03.38.2.51.72.52v.21h-1.8v-.22c.44-.03.63-.12.66-.48.02-.23.05-.61.05-1.31v-1.66c0-.55,0-.58-.15-.77-.17-.17-.34-.24-.67-.26v-.2s1.12-.02,1.12-.02l3.18,3.78h.02v-1.83c-.01-.69-.03-1.04-.06-1.26-.04-.33-.2-.44-.73-.47v-.22s1.79.02,1.79.02v.22Z"/>
            <path   d="m407.18,80.35c-.07-.39-.16-.59-.23-.73-.13-.21-.29-.29-.9-.26l-.59-.02v4.09c0,.62.06.73.85.77l-.03.23h-2.35v-.23c.73-.06.81-.13.8-.77l.02-4.08h-.53c-.68,0-.85.03-.96.26-.11.14-.15.37-.27.72l-.22.02c.02-.51.07-1.03.07-1.48h.18c.1.18.21.19.44.19h3.28c.24.02.29-.06.41-.21h.19c0,.39.05,1.01.08,1.48h-.26Z"/>
            <path   d="m410.33,84.45l-.02-.21c.48-.07.56-.13.46-.42-.1-.29-.25-.72-.43-1.17h-1.6c-.13.34-.24.65-.34.95-.16.51-.1.56.49.62v.2s-1.67,0-1.67,0v-.21c.5-.07.59-.1.85-.77l1.57-3.98.25-.06c.45,1.29.97,2.74,1.46,4.05.24.65.34.71.83.78v.22s-1.85,0-1.85,0Zm-.76-4.06h0c-.25.66-.47,1.31-.7,1.92l1.39.02-.68-1.94Z"/>
            <path   d="m417.09,82.37c-.46.06-.49.1-.49.52v.68c0,.27,0,.5.04.7-.47.13-1.08.28-1.7.29-1.65,0-2.79-1.06-2.79-2.52.03-1.77,1.43-2.62,2.91-2.63.64,0,1.23.18,1.47.22.03.27.07.7.12,1.2l-.19.03c-.2-.91-.78-1.17-1.5-1.16-1.16-.01-2.02.84-2.01,2.23,0,1.11.65,2.38,2.11,2.38.25,0,.48-.06.66-.15.15-.06.22-.16.25-.49v-.63c-.01-.56-.06-.61-.79-.64v-.24s1.9.01,1.9.01l.02.21Z"/>
          </g>
        </g>
        <g id="Green_Tag-5" data-name="Green Tag">
          <path   d="m392.27,90.03h-.2c.03-.3.07-.64.09-1.01.77.02,1.31.03,1.63.03.27,0,.77,0,1.49-.03.03.39.05.71.09,1.01h-.2c-.08-.31-.13-.5-.16-.56-.03-.05-.06-.09-.1-.12-.04-.03-.16-.03-.36-.03h-.57c-.02.46-.02,1.03-.02,1.7,0,.63,0,1.06.02,1.27,0,.06,0,.08.03.1.04.02.18.07.43.11.02,0,.07.02.11.02v.16c-.34-.03-.64-.03-.91-.03-.25,0-.52,0-.79.03v-.16c.07-.01.11-.02.14-.03.23-.04.37-.07.41-.11.01-.01.02-.02.03-.05,0-.03.01-.22.02-.58,0-.55.01-.96.01-1.22,0-.46,0-.87-.01-1.22h-.73c-.1,0-.16,0-.2.03-.05.04-.1.09-.13.19-.03.07-.07.23-.13.49Z"/>
          <path   d="m396.06,89.54v-.13c.34-.14.59-.24.73-.31.07-.03.15-.08.25-.14l.08.06c-.04.5-.06,1.14-.06,1.91,0,.66,0,1.03,0,1.12,0,.17.02.28.05.33,0,.03.03.04.05.06.02,0,.15.04.4.11v.15c-.36-.03-.62-.03-.78-.03-.19,0-.41,0-.65.03v-.15c.24-.06.38-.1.41-.13.03,0,.04-.04.05-.05.01-.04.03-.15.03-.34,0-.06,0-.41,0-1.03v-1.03s-.01-.24-.01-.24c0-.1-.02-.17-.04-.19-.02-.05-.05-.06-.11-.06s-.19.02-.4.06Z"/>
        </g>
        <g id="Green_Tag-6" data-name="Green Tag">
          <path   d="m380.46,96.8v-.45s0-.3,0-.3c0-.05,0-.08-.02-.09-.02-.02-.05-.03-.13-.04l-.22-.05v-.09c.25,0,.43.02.54.02.12,0,.29,0,.5-.02v.09c-.17.04-.28.06-.29.07-.02.02-.04.02-.04.02-.01.03-.02.1-.02.19v1.62s.02.08.03.1c.02.03.14.05.33.08v.09c-.29,0-.48-.01-.56-.01-.12,0-.29,0-.48.01v-.09l.29-.05s.03-.03.05-.05c.01-.01.03-.05.03-.12v-.75c-.28,0-.5,0-.65,0-.16,0-.34,0-.52,0v.77c.01.06.01.1.01.1.02.02.05.04.09.05,0,0,.11.02.26.05v.09c-.25,0-.44-.01-.54-.01-.14,0-.3,0-.49.01v-.09c.11-.03.2-.04.26-.05.05,0,.07-.02.08-.03.01-.02.02-.06.02-.12,0-.2,0-.49,0-.86v-.8s0-.06,0-.06c0-.04,0-.06-.02-.07-.02-.02-.04-.02-.09-.04l-.26-.05v-.09c.22,0,.4.02.54.02s.32,0,.5-.02v.09l-.27.06s-.05,0-.07.03c0,.02-.02.04-.02.09v.75c.16.01.34.01.52.01.23,0,.45,0,.64-.01Z"/>
          <path   d="m381.93,96.78h.39c.15,0,.23,0,.25-.01.03,0,.06-.01.07-.05.02-.03.03-.09.06-.21,0-.02.01-.03.01-.06h.1c0,.18,0,.31,0,.41,0,.1,0,.23,0,.41h-.1c-.03-.15-.05-.25-.06-.27,0-.01-.03-.04-.06-.05-.02-.01-.11-.02-.24-.02h-.44v.94h.74c.1,0,.15,0,.18-.02.04,0,.07-.03.11-.05.03-.03.08-.13.17-.28,0-.02.01-.04.03-.07h.12s-.08.23-.19.59c-.4,0-.75-.01-1.08-.01-.22,0-.47,0-.75.01v-.09l.27-.05s.06-.02.07-.04c.02-.01.02-.06.02-.12v-.41c.01-.07.01-.22.01-.43v-.68c0-.15-.01-.24-.03-.25,0-.02-.01-.03-.01-.03-.02-.02-.07-.03-.16-.05l-.18-.04v-.09c.21,0,.45.02.73.02.16,0,.52,0,1.1-.02,0,.18,0,.36.03.55h-.12c-.03-.15-.07-.25-.1-.29-.01-.04-.03-.05-.06-.06-.03,0-.11-.02-.23-.02h-.65v.52s-.01.31-.01.31Z"/>
          <path   d="m385.6,97.66l.07.12c-.18.12-.34.19-.5.24-.16.06-.33.08-.49.08-.21,0-.41-.05-.58-.15-.18-.1-.31-.23-.41-.41-.09-.18-.13-.37-.13-.6,0-.35.12-.64.35-.87.23-.22.54-.34.93-.34.24,0,.5.05.79.15,0,.08,0,.16,0,.2,0,.14,0,.29.02.44h-.14c-.04-.19-.08-.32-.12-.39-.04-.07-.12-.14-.22-.17-.1-.04-.22-.07-.37-.07-.3,0-.55.12-.7.34-.12.17-.18.38-.18.65,0,.3.08.54.26.73.16.18.4.27.68.27.12,0,.24-.02.35-.05.11-.03.25-.09.42-.18Z"/>
          <path   d="m387.68,96.8v-.75c0-.05-.01-.08-.03-.09,0-.02-.06-.03-.12-.04l-.22-.05v-.09c.24,0,.43.02.53.02.12,0,.29,0,.5-.02v.09c-.18.04-.27.06-.3.07-.02.02-.02.02-.05.03,0,.02,0,.09,0,.18,0,.15,0,.44,0,.82v.76s0,.04,0,.04c0,.05,0,.08.03.1.02.03.14.05.34.08v.09c-.3,0-.49-.01-.57-.01-.13,0-.29,0-.48.01v-.09l.29-.05s.04-.03.04-.05c.02-.01.03-.05.03-.12v-.75c-.28,0-.5,0-.64,0-.17,0-.34,0-.52,0v.77c0,.06,0,.1,0,.1.03.02.06.04.1.05,0,0,.1.02.26.05v.09c-.26,0-.45-.01-.56-.01-.13,0-.29,0-.49.01v-.09c.12-.03.2-.04.26-.05.05,0,.07-.02.08-.03.02-.02.03-.06.03-.12,0-.2,0-.49,0-.86v-.8s0-.06,0-.06c0-.04,0-.06-.03-.07,0-.02-.03-.02-.07-.04l-.27-.05v-.09c.23,0,.41.02.55.02s.32,0,.5-.02v.09l-.27.06s-.05,0-.06.03c-.02.02-.03.04-.03.09v.75c.17.01.35.01.53.01.24,0,.45,0,.64-.01Z"/>
          <path   d="m388.58,96.93c0-.35.1-.63.33-.86.23-.23.52-.34.88-.34.33,0,.59.09.8.3.19.2.29.47.29.81,0,.39-.11.69-.33.92-.23.22-.52.33-.88.33-.34,0-.6-.1-.8-.3-.2-.21-.29-.49-.29-.87Zm.35-.04c0,.35.07.61.23.79.15.17.35.26.59.26.17,0,.29-.04.42-.13.13-.07.21-.2.27-.35.07-.16.1-.35.1-.53,0-.24-.04-.44-.12-.6-.08-.15-.18-.27-.29-.34-.11-.06-.25-.1-.39-.1-.17,0-.31.05-.43.13-.14.08-.22.2-.28.36-.06.17-.09.34-.09.52Z"/>
          <path   d="m392.6,96.78h.39c.15,0,.23,0,.27-.01.02,0,.04-.01.07-.05,0-.03.03-.09.06-.21,0-.02,0-.03.01-.06h.1c-.01.18-.02.31-.02.41,0,.1,0,.23.02.41h-.1c-.03-.15-.05-.25-.06-.27,0-.01-.03-.04-.06-.05-.02-.01-.1-.02-.24-.02h-.44l.02.94h.73c.09,0,.15,0,.17-.02.04,0,.08-.03.11-.05.03-.03.08-.13.17-.28,0-.02.02-.04.03-.07h.12s-.07.23-.2.59c-.39,0-.75-.01-1.08-.01-.22,0-.46,0-.73.01v-.09l.25-.05s.06-.02.09-.04c0-.01.02-.06.02-.12v-.41c0-.07,0-.22,0-.43v-.68c0-.15,0-.24-.03-.25,0-.02,0-.03-.03-.03-.01-.02-.05-.03-.14-.05l-.17-.04v-.09c.19,0,.43.02.71.02.16,0,.52,0,1.1-.02,0,.18.01.36.03.55h-.11c-.04-.15-.07-.25-.1-.29-.02-.04-.04-.05-.06-.06-.03,0-.12-.02-.24-.02h-.65v.52s-.02.31-.02.31Z"/>
          <path   d="m394.07,95.87v-.09c.14,0,.24.02.32.02.06,0,.18,0,.33-.02l.47.57c.04.05.37.44.97,1.17v-.33c0-.15,0-.45-.02-.87,0-.19,0-.3-.01-.32,0,0,0-.03-.03-.04-.01-.01-.09-.04-.22-.06,0,0-.04-.01-.08-.02v-.09c.19,0,.34.02.45.02s.27,0,.46-.02v.09s-.05.01-.06.02c-.14.03-.22.05-.24.05,0,.02-.01.02-.02.03-.01.02-.02.06-.03.1v.44c0,.26,0,.53,0,.78,0,.3,0,.56.02.76l-.11.05-.67-.83-.88-1.06v.95c0,.41.02.63.02.66,0,.03.02.03.03.05.02.02.1.04.23.06,0,0,.05,0,.09.01v.09c-.17,0-.32-.01-.42-.01s-.27,0-.5.01v-.09s.06-.01.09-.01c.13-.02.2-.04.23-.06,0-.01.01-.03.01-.05.02-.05.03-.24.03-.55,0-.46,0-.73,0-.81,0-.27,0-.42,0-.45,0-.02-.03-.04-.05-.05-.02-.02-.13-.04-.31-.07,0,0-.04-.01-.08-.02Z"/>
          <path   d="m397.61,95.87v-.09c.18,0,.31.02.41.02.11,0,.22,0,.34-.02.05.11.08.19.11.25.03.09.17.39.42.9.1.22.22.45.32.66l.69-1.45c.03-.08.08-.21.15-.36.13,0,.25.02.34.02.06,0,.18,0,.35-.02v.09s-.06.02-.06.02c-.16.03-.25.05-.27.07-.03.02-.04.07-.04.16h0s0,.53,0,.53c0,.64,0,1.02.02,1.16,0,.03,0,.04.02.06.01.02.1.03.27.07,0,0,.04,0,.06.01v.09c-.19,0-.37-.01-.51-.01s-.32,0-.53.01v-.09s.08-.01.1-.01c.14-.04.21-.04.22-.06.03-.01.04-.03.05-.05,0-.03.02-.12.02-.25v-1.41c-.1.19-.32.65-.67,1.39-.1.2-.17.36-.22.46h-.08c-.26-.52-.55-1.14-.89-1.85l.02,1.49c0,.12.01.18.03.21.02.02.1.03.24.06.02,0,.05,0,.08.01v.09c-.19,0-.34-.01-.46-.01s-.27,0-.45.01v-.09s.05-.01.05-.01c.15-.02.24-.05.25-.07.03-.04.04-.11.04-.23v-.36c.01-.33.02-.64.02-.96v-.19c0-.07,0-.12-.02-.15,0-.02-.02-.03-.04-.03-.04-.02-.14-.04-.28-.06,0,0-.04-.01-.08-.02Z"/>
          <path   d="m401.53,96.78h.39c.15,0,.24,0,.26-.01.03,0,.05-.01.07-.05.02-.03.03-.09.06-.21,0-.02,0-.03.01-.06h.1c-.01.18-.02.31-.02.41,0,.1,0,.23.02.41h-.1c-.03-.15-.05-.25-.06-.27,0-.01-.03-.04-.06-.05-.02-.01-.1-.02-.23-.02h-.44v.94h.74c.1,0,.15,0,.18-.02.04,0,.07-.03.1-.05.04-.03.1-.13.18-.28,0-.02.03-.04.03-.07h.12s-.09.23-.2.59c-.39,0-.75-.01-1.08-.01-.22,0-.47,0-.74.01v-.09l.26-.05s.07-.02.08-.04c.01-.01.03-.06.03-.12v-1.51c0-.15,0-.24-.02-.25,0-.02-.01-.03-.03-.03-.01-.02-.06-.03-.15-.05l-.17-.04v-.09c.19,0,.44.02.73.02.15,0,.52,0,1.09-.02,0,.18.02.36.04.55h-.12c-.04-.15-.08-.25-.1-.29-.02-.04-.04-.05-.07-.06-.04,0-.11-.02-.23-.02h-.65l-.02.52v.31Zm.27-1.21h-.08c.06-.2.14-.38.19-.56h.33l.02.03c-.15.15-.3.34-.46.53Z"/>
          <path   d="m403.09,95.87v-.09c.17,0,.32.02.46.02s.32,0,.54-.02v.09c-.13.03-.21.06-.24.07t-.02.04s.02.05.05.11c.14.23.27.43.38.57.17-.24.33-.44.47-.63.02-.04.03-.07.03-.07,0,0-.01-.02-.02-.03-.01,0-.09-.04-.21-.06v-.09c.18,0,.31.02.41.02.12,0,.25,0,.39-.02v.09c-.13.02-.22.06-.27.09-.04.03-.13.1-.22.23-.2.24-.35.46-.47.63.09.15.21.33.36.54l.32.47s.03.03.06.05c.04.03.13.05.25.08v.09c-.19,0-.34-.01-.46-.01-.1,0-.27,0-.49.01v-.09c.14-.04.2-.05.22-.06,0,0,.01-.02.01-.04,0-.03-.01-.05-.04-.11-.17-.25-.31-.45-.45-.65-.12.15-.29.37-.49.65-.03.05-.06.09-.06.09,0,.03.02.05.03.07.01,0,.07.02.17.04,0,0,.03,0,.05.01v.09c-.17,0-.31-.01-.45-.01s-.26,0-.37.01v-.09c.13-.03.2-.05.25-.08.04-.03.13-.13.26-.29.23-.3.39-.53.49-.66-.1-.16-.26-.4-.49-.72-.09-.13-.15-.21-.19-.23-.05-.02-.14-.06-.31-.11Z"/>
          <path   d="m406.59,95.78v.09s-.06.02-.06.02c-.17.02-.25.05-.27.07-.02.03-.03.07-.03.14v.71s0,.93,0,.93c0,.07.02.12.04.14.02.02.1.03.22.06,0,0,.06,0,.11.01v.09c-.17,0-.34-.01-.49-.01-.17,0-.35,0-.55.01v-.09s.05-.01.07-.01c.15-.02.23-.04.25-.07.03-.02.04-.07.04-.16v-.08s0-.59,0-.59c0-.6,0-.94,0-1,0-.04,0-.06-.03-.09-.02-.02-.1-.04-.24-.07,0,0-.05-.01-.08-.02v-.09c.23,0,.42.02.55.02.15,0,.31,0,.49-.02Z"/>
          <path   d="m408.87,97.66l.07.12c-.17.12-.34.19-.5.24-.15.06-.31.08-.48.08-.22,0-.42-.05-.6-.15-.17-.1-.3-.23-.4-.41-.09-.18-.14-.37-.14-.6,0-.35.12-.64.36-.87.23-.22.54-.34.92-.34.24,0,.49.05.79.15v.2c0,.14,0,.29.03.44h-.14c-.04-.19-.08-.32-.13-.39-.04-.07-.12-.14-.22-.17-.11-.04-.23-.07-.38-.07-.31,0-.54.12-.7.34-.12.17-.19.38-.19.65,0,.3.08.54.26.73.17.18.39.27.67.27.13,0,.24-.02.36-.05.12-.03.25-.09.41-.18Z"/>
          <path   d="m409.2,96.93c0-.35.11-.63.34-.86.23-.23.52-.34.88-.34.32,0,.58.09.79.3.2.2.29.47.29.81,0,.39-.11.69-.33.92-.23.22-.51.33-.88.33-.34,0-.6-.1-.8-.3-.2-.21-.3-.49-.3-.87Zm.35-.04c0,.35.07.61.23.79.15.17.35.26.59.26.16,0,.29-.04.41-.13.13-.07.22-.2.27-.35.06-.16.1-.35.1-.53,0-.24-.03-.44-.12-.6-.08-.15-.18-.27-.29-.34-.11-.06-.25-.1-.39-.1-.16,0-.3.05-.44.13-.12.08-.21.2-.28.36-.05.17-.09.34-.09.52Z"/>
        </g>
      </g>
      <g id="Green_Tag-7" data-name="Green Tag">
        <g id="Green_Tag-8" data-name="Green Tag">
          <path   d="m365.83,52.06c.11-.04.22-.09.34-.11.35-.1.55-.33.77-.55.13-.14.1-.28-.1-.43-.57-.49-1.25-.75-1.99-.94-.01,0-.05-.02-.07-.03.29,0,.57.15.85.21.1.02.19.1.24.01.05-.06-.06-.13-.1-.19-.1-.1-.19-.2-.26-.32.44.44.97.76,1.57.99.27.12.37.08.45-.11.13-.28.27-.57.09-.93.34.41.75.8.61,1.31.14,0,.27.02.39.02.12,0,.18.09.21.16.08.11-.06.07-.11.1-.25.15-.61.19-.75.46-.11.18-.38.22-.63.25-.49.09-.99.1-1.52.1Z"/>
        </g>
        <g id="Green_Tag-9" data-name="Green Tag">
          <path   d="m336.94,54.42l.2.41s.02.04.02.05c.01.03,0,.05-.04.06-.04.01-.07,0-.11-.02-.16-.12-.35-.21-.57-.25-.22-.04-.45-.02-.69.05-.31.1-.53.26-.65.49-.12.23-.14.47-.07.71.08.27.25.46.5.57.25.11.5.13.77.04.11-.04.22-.09.33-.16.1-.07.17-.13.19-.18.02-.05.02-.11,0-.19-.05-.17-.09-.27-.13-.3-.03-.04-.07-.05-.13-.06s-.15.02-.29.05c-.06.01-.09,0-.09-.02,0-.03.01-.05.06-.07l.33-.1,1.01-.33s.07,0,.08.02c0,.02,0,.03-.02.04-.02.01-.07.04-.17.09-.07.04-.12.07-.13.1-.01.03.02.19.09.46.02.06.02.1.02.1,0,0-.08.07-.23.18-.15.11-.31.21-.48.3-.17.09-.35.16-.52.21-.3.09-.58.14-.84.13-.26,0-.49-.06-.68-.16-.19-.1-.32-.25-.38-.45-.09-.29-.02-.58.2-.88.23-.3.59-.53,1.09-.68.18-.06.34-.09.5-.11.15-.02.33-.02.53-.03.04,0,.07,0,.1-.01.03,0,.05-.03.05-.06,0-.04.03-.07.06-.08.04-.01.07.01.1.07Z"/>
          <path   d="m339.58,54.63c.2-.03.38-.03.52,0,.15.03.27.13.38.31.1.16.2.26.28.3.08.04.19.05.32.02.07-.02.11,0,.12.02s-.05.06-.16.08l-.86.19c-.1-.04-.19-.13-.26-.28-.09-.19-.17-.32-.23-.39s-.15-.11-.24-.13c-.1-.02-.21,0-.32.02-.03,0-.08.02-.14.04.08.36.14.57.16.66s.06.13.12.16c.05.03.14.03.27,0,.07-.01.1,0,.11.02s-.02.05-.08.06l-.69.15c-.09.02-.21.05-.35.09-.13.04-.21.06-.25.07-.06.02-.09,0-.1-.02s.02-.05.07-.06c.08-.02.16-.05.22-.1.06-.05.07-.17.02-.38l-.07-.28-.13-.54c-.07-.28-.13-.45-.18-.5-.05-.05-.16-.06-.32-.03-.06.01-.1,0-.1-.02,0-.03.03-.05.11-.07.42-.1.77-.19,1.06-.28.13-.04.24-.07.35-.09.34-.08.61-.1.83-.05.21.04.34.14.37.29.02.1,0,.2-.07.3-.07.1-.17.19-.3.26s-.28.13-.46.19Zm-.62.08s.07-.02.09-.02c.13-.03.26-.08.38-.14.12-.07.2-.14.24-.23s.05-.17.03-.25c-.03-.13-.11-.21-.24-.26s-.28-.04-.45,0c-.07.02-.15.05-.24.09l.19.81Z"/>
          <path   d="m343.44,52.79c.08.15.16.3.24.45,0,.03-.01.05-.06.06-.02,0-.05,0-.09-.03-.18-.12-.35-.19-.48-.22-.14-.02-.33-.01-.57.04-.14.03-.26.06-.39.1,0,.18.04.38.08.6,0,.04.02.1.04.18l.17-.03c.11-.02.2-.05.27-.09.07-.03.13-.09.17-.17.04-.07.06-.12.07-.14,0-.04.03-.06.06-.06.04,0,.07,0,.07.02,0,0,0,.02,0,.03,0,.07.02.26.09.57,0,.04.02.08.04.12,0,0,0,.02.01.03,0,.03-.02.05-.06.06-.03,0-.06-.02-.09-.06-.05-.09-.13-.15-.25-.18-.12-.03-.29-.02-.52.03.03.17.05.28.06.34.05.23.08.37.1.41.02.04.06.07.14.08s.19,0,.34-.03c.57-.12.92-.31,1.05-.6.02-.04.04-.06.07-.07.04,0,.07,0,.07.02,0,.02,0,.04,0,.06l-.06.47-.81.16c-.23.04-.44.08-.64.12-.2.03-.36.06-.47.08-.13.03-.31.07-.57.13-.03,0-.05.01-.07.02-.07.01-.11,0-.12-.03,0-.03.02-.05.08-.06.15-.03.24-.07.28-.14.04-.06.04-.18.01-.34l-.14-.79c-.05-.27-.09-.43-.12-.49s-.06-.1-.1-.11c-.05-.01-.13-.01-.25,0-.05,0-.07,0-.08-.03,0-.03.02-.05.07-.06l.54-.1,1.82-.36Z"/>
          <path   d="m346.47,52.26c.08.15.15.3.23.45,0,.03-.02.05-.06.06-.03,0-.05,0-.09-.03-.18-.13-.34-.2-.48-.23s-.33-.02-.57.02c-.14.02-.27.05-.39.09,0,.18.02.39.06.6,0,.04.02.1.04.18l.17-.03c.11-.02.2-.04.27-.08.07-.03.13-.09.18-.16.04-.07.07-.12.07-.14,0-.04.03-.06.06-.06.04,0,.07,0,.07.03,0,0,0,.02,0,.03,0,.07.02.26.07.57,0,.04.02.08.04.12,0,0,0,.02,0,.03,0,.03-.02.05-.06.05-.03,0-.06-.02-.09-.07-.05-.09-.13-.15-.24-.18-.12-.03-.29-.03-.52.01.02.17.04.29.05.34.04.23.07.37.09.41s.06.07.13.09c.07.02.19.01.34-.02.57-.1.93-.29,1.07-.57.02-.04.04-.06.07-.07.04,0,.07,0,.07.03,0,.02,0,.04,0,.07l-.07.47-.82.14c-.23.04-.45.07-.65.1-.2.03-.36.05-.47.07-.13.02-.32.06-.57.11-.03,0-.05.01-.07.02-.07.01-.11,0-.12-.03,0-.03.02-.05.08-.06.15-.03.24-.07.28-.13.04-.06.05-.18.02-.34l-.12-.79c-.05-.27-.08-.44-.1-.5-.02-.06-.05-.1-.1-.11-.05-.02-.13-.02-.25,0-.05,0-.07,0-.08-.03,0-.03.02-.05.08-.06l.55-.08,1.82-.31Z"/>
          <path   d="m347.92,52.02c.81.45,1.48.81,2.01,1.08,0-.07-.01-.12-.02-.14l-.09-.61c-.02-.14-.05-.24-.08-.3-.03-.06-.09-.1-.18-.12-.08-.02-.18-.03-.28-.02-.06,0-.09,0-.09-.04,0-.03.02-.05.07-.06.03,0,.07,0,.11-.01.09,0,.21-.02.38-.05.21-.03.37-.06.47-.08.05-.01.08-.02.12-.02.06,0,.09,0,.09.03,0,.03-.03.05-.11.07-.09.02-.18.06-.25.12-.08.06-.1.16-.08.3l.14.87.08.66c0,.06-.01.09-.06.1-.01,0-.03,0-.05,0s-.13-.06-.34-.17-.52-.28-.94-.51-.71-.4-.88-.5c0,.03.04.29.11.78.02.15.05.27.08.35s.1.14.2.16.2.03.28.02c.05,0,.08,0,.08.03,0,.03-.02.05-.07.06-.04,0-.08,0-.13.01-.09,0-.21.02-.35.04-.23.03-.39.06-.49.09-.06.01-.11.02-.14.03-.06,0-.09,0-.09-.04,0-.03.04-.05.14-.07.17-.03.27-.09.3-.17.03-.08.04-.18.02-.3-.08-.53-.13-.85-.16-.97-.03-.12-.1-.21-.21-.29-.12-.08-.24-.12-.39-.12-.06,0-.09-.01-.09-.04,0-.03.02-.06.08-.06.02,0,.04,0,.07,0,.04,0,.1-.01.19-.03.2-.03.38-.05.55-.08Z"/>
          <path   d="m353.22,51.27c.79-.08,1.38-.14,1.75-.18.29-.03.6-.07.95-.13.12-.02.22-.03.3-.04.03,0,.08-.01.15-.02.02.04.04.13.07.26l.06.24s-.02.06-.07.07c-.04,0-.08-.02-.12-.06-.07-.08-.15-.14-.25-.19-.1-.05-.21-.08-.33-.08-.12,0-.32,0-.59.04,0,.16,0,.36.03.6.03.24.06.48.1.7.04.22.07.36.1.4.02.04.06.07.11.09.05.02.16.02.34,0,.06,0,.09,0,.09.03,0,.03-.02.05-.07.05h-.06s-.47.05-.47.05c-.37.04-.59.07-.67.08l-.29.04s-.07,0-.14.01c-.04,0-.06-.01-.06-.03,0-.03.02-.05.08-.06.14-.02.24-.05.3-.09.06-.05.1-.11.11-.2,0-.09,0-.27-.04-.54-.05-.44-.11-.79-.18-1.06-.25.03-.43.06-.54.08-.11.03-.21.08-.31.15s-.17.17-.23.27c-.02.04-.05.07-.08.07-.05,0-.08,0-.08-.04,0,0,0-.08,0-.21,0-.13.01-.24.03-.32Z"/>
          <path   d="m357.75,50.72l.16-.02c.23.27.56.71.99,1.31.2.27.33.44.4.49.07.06.17.08.31.08.05,0,.07.01.07.03,0,.03-.03.05-.08.06-.03,0-.12,0-.25.02-.16,0-.29.02-.4.03-.1.01-.24.03-.41.06-.13.02-.21.03-.25.03-.07,0-.11,0-.11-.03,0-.02.02-.04.07-.05.18-.03.27-.09.26-.18,0-.04-.04-.12-.1-.22s-.14-.2-.21-.28c-.1,0-.18.02-.23.02-.31.03-.55.06-.71.08-.11.26-.16.42-.15.5,0,.06.04.1.11.12.07.02.14.04.22.03.06,0,.09.01.09.04,0,.03-.03.05-.09.05-.02,0-.09,0-.19.01-.13,0-.23.01-.29.02-.12.01-.23.03-.34.05-.03,0-.05.01-.07.01-.06,0-.09,0-.09-.04,0-.02.02-.04.05-.05.09-.03.16-.06.21-.09.05-.03.1-.09.15-.17s.11-.2.18-.37c.15-.38.38-.9.69-1.55Zm-.11.56l-.32.73.81-.09c-.05-.08-.13-.18-.23-.31-.11-.14-.2-.25-.25-.33Z"/>
          <path   d="m362.58,50.47l.11.44s0,.04,0,.05c0,.03-.02.05-.06.05-.04,0-.07-.01-.1-.05-.13-.16-.3-.28-.51-.36-.2-.09-.43-.12-.69-.09-.33.03-.57.15-.74.35-.17.2-.24.43-.21.68.03.28.15.5.37.66s.47.23.74.2c.12-.01.23-.04.35-.09.12-.05.19-.09.22-.14s.04-.11.04-.18c-.02-.17-.04-.28-.06-.32-.03-.04-.06-.07-.11-.08-.05-.01-.15-.02-.29-.02-.06,0-.09-.01-.09-.04,0-.03.02-.05.08-.05l.35-.03,1.05-.11s.07,0,.07.03c0,.02,0,.03-.02.04-.02,0-.08.02-.19.05-.08.02-.13.04-.15.07s-.02.18-.01.47c0,.06,0,.1,0,.11,0,0-.09.05-.26.13-.17.08-.35.14-.54.19-.18.05-.37.08-.56.1-.31.03-.6.01-.85-.05s-.46-.16-.63-.3c-.17-.14-.26-.31-.28-.52-.03-.3.1-.57.38-.81s.69-.39,1.21-.44c.19-.02.36-.02.51,0,.15.02.33.04.52.08.04,0,.07.01.1,0,.03,0,.05-.02.07-.05.02-.04.05-.06.08-.07.04,0,.07.03.08.09Z"/>
          <path   d="m392.56,48.93l.08.45s0,.04,0,.05c0,.03-.02.05-.06.05-.04,0-.07-.02-.1-.05-.12-.16-.28-.3-.48-.4-.2-.1-.42-.15-.68-.14-.33,0-.58.11-.76.3s-.27.41-.26.67c0,.28.12.51.32.69s.45.26.73.25c.12,0,.24-.03.36-.06.12-.04.2-.08.23-.13.03-.04.05-.1.05-.18,0-.17-.02-.28-.04-.33s-.06-.07-.1-.09c-.05-.02-.15-.03-.29-.04-.06,0-.08-.02-.09-.04,0-.03.03-.05.08-.05h.35s1.06-.05,1.06-.05c.05,0,.07.01.07.04,0,.02,0,.03-.03.03-.02,0-.08.02-.19.04-.08.01-.14.03-.16.06s-.04.18-.04.47c0,.06,0,.1,0,.11,0,0-.09.05-.27.11-.18.07-.36.12-.55.15s-.38.06-.56.06c-.31,0-.6-.03-.85-.1-.25-.08-.45-.19-.61-.34s-.24-.33-.24-.54c0-.3.14-.56.44-.79s.71-.34,1.24-.36c.19,0,.36,0,.51.03.15.03.32.07.51.12.04.01.07.02.1.01.03,0,.05-.02.07-.05.02-.04.05-.06.08-.06.04,0,.07.03.08.1Z"/>
          <path   d="m395.01,49.73c.21.02.38.06.51.12.13.06.24.19.31.38.07.18.14.3.21.36.07.06.17.09.3.09.07,0,.11.02.11.04,0,.03-.06.05-.17.05h-.88c-.09-.06-.16-.17-.19-.33-.05-.2-.09-.35-.14-.43s-.12-.14-.21-.18c-.09-.03-.2-.05-.32-.05-.04,0-.08,0-.14,0,0,.37.01.59.02.68,0,.08.03.14.08.18.05.04.13.06.26.06.07,0,.1.02.1.04,0,.03-.03.04-.09.04h-.7c-.09,0-.22,0-.36.02-.14,0-.22.01-.26.01-.06,0-.09-.01-.09-.04,0-.03.03-.04.08-.04.08,0,.16-.02.24-.05.07-.03.1-.15.1-.37v-.29s-.02-.56-.02-.56c0-.29-.03-.46-.07-.53s-.14-.09-.3-.09c-.06,0-.1-.02-.1-.04s.04-.05.13-.05c.43,0,.8-.02,1.1-.04.13-.01.25-.02.36-.02.35,0,.62.03.82.13.2.09.3.21.3.36,0,.1-.04.19-.13.28s-.21.15-.35.19-.3.07-.49.08Zm-.62-.05h.09c.13,0,.27-.02.4-.06.14-.04.23-.09.28-.17.05-.08.08-.16.08-.24,0-.13-.06-.23-.17-.3-.12-.07-.26-.1-.44-.1-.08,0-.16.01-.25.03l.02.83Z"/>
          <path   d="m399.07,48.67l.15.48s-.02.05-.07.05c-.02,0-.05-.01-.08-.04-.16-.15-.3-.25-.44-.3s-.32-.07-.57-.07c-.14,0-.27.01-.4.03-.02.18-.04.39-.03.6,0,.04,0,.1,0,.19h.17c.11,0,.2-.02.28-.04.08-.02.15-.07.2-.13.05-.07.08-.11.09-.13.01-.03.04-.05.07-.05.04,0,.06.01.06.04,0,0,0,.02,0,.03-.02.06-.02.26-.02.58,0,.05,0,.09.02.12,0,.01,0,.02,0,.03,0,.03-.02.04-.07.04-.03,0-.06-.03-.08-.08-.03-.1-.1-.17-.21-.22-.11-.05-.28-.07-.52-.07,0,.17,0,.29,0,.34,0,.24.01.38.02.42s.05.08.12.11c.07.03.18.04.34.04.58,0,.96-.14,1.14-.39.03-.04.05-.05.08-.05.04,0,.07.01.07.04,0,.02,0,.04-.01.06l-.14.45h-.83c-.23.01-.45.01-.66.01s-.36,0-.48,0c-.13,0-.32.01-.58.03-.03,0-.06,0-.07,0-.07,0-.11-.02-.11-.05,0-.03.03-.04.09-.04.15,0,.25-.03.3-.09s.07-.17.07-.34v-.8c0-.27-.01-.44-.02-.5-.01-.06-.04-.1-.08-.13-.04-.02-.12-.04-.24-.05-.05,0-.07-.02-.07-.04,0-.03.03-.05.08-.05h.55s1.85-.02,1.85-.02Z"/>
          <path   d="m402.13,48.63c.05.16.1.32.15.48,0,.03-.02.05-.07.05-.02,0-.05-.01-.08-.04-.16-.15-.3-.25-.44-.3-.13-.05-.32-.07-.57-.07-.14,0-.27.01-.4.03-.03.18-.04.38-.04.6,0,.04,0,.1,0,.19h.17c.11,0,.2-.01.28-.03.08-.02.15-.06.2-.13.05-.07.09-.11.09-.13.01-.03.04-.05.07-.05.04,0,.06.01.06.04,0,0,0,.02,0,.03-.02.06-.02.26-.02.58,0,.04,0,.08.02.12,0,.01,0,.02,0,.03,0,.03-.02.04-.07.04-.03,0-.06-.03-.08-.08-.03-.1-.1-.17-.21-.22-.11-.05-.28-.07-.52-.07,0,.17,0,.29,0,.34,0,.24,0,.38.02.42.01.04.05.08.12.11.07.03.18.04.34.04.58,0,.96-.14,1.15-.39.03-.04.05-.05.08-.05.04,0,.07.01.07.04,0,.02,0,.04-.01.06l-.15.45h-.83c-.23,0-.45,0-.66,0-.2,0-.36,0-.48,0-.13,0-.32,0-.58.02-.03,0-.06,0-.07,0-.07,0-.11-.01-.11-.05,0-.03.03-.04.09-.05.15,0,.25-.03.3-.08.05-.05.07-.17.07-.33v-.8c0-.27,0-.44-.01-.5-.01-.06-.04-.1-.08-.13s-.12-.04-.24-.05c-.05,0-.07-.02-.07-.04,0-.03.03-.05.08-.05h.55s1.85,0,1.85,0Z"/>
          <path   d="m403.62,48.61c.73.56,1.35,1.01,1.84,1.35,0-.07,0-.12,0-.14v-.62c0-.14-.01-.25-.04-.31s-.08-.11-.16-.15c-.08-.04-.17-.06-.28-.06-.06,0-.08-.02-.08-.05,0-.03.03-.05.08-.05.03,0,.07,0,.11,0,.09,0,.21,0,.38,0,.21,0,.37,0,.48-.01.05,0,.08,0,.12,0,.06,0,.09.01.09.04,0,.03-.04.05-.12.05-.1,0-.19.03-.27.08s-.12.15-.12.29l.02.88v.66c-.01.06-.04.09-.09.09-.01,0-.03,0-.05-.01-.02,0-.13-.08-.32-.21-.19-.14-.48-.35-.86-.63-.38-.29-.65-.49-.8-.62,0,.03,0,.29,0,.78,0,.15.01.27.03.36.02.09.08.15.18.19.1.04.19.06.27.06.05,0,.08.01.08.04,0,.03-.03.05-.08.05-.04,0-.08,0-.13,0-.09,0-.21-.01-.35-.01-.23,0-.39,0-.5.02-.06,0-.11,0-.14,0-.06,0-.09-.02-.09-.05,0-.03.05-.04.14-.05.17-.01.28-.05.33-.12.04-.07.06-.17.06-.29,0-.53,0-.86-.02-.98,0-.12-.06-.23-.17-.32-.1-.09-.23-.15-.37-.17-.06,0-.09-.02-.09-.05,0-.03.03-.05.09-.05.02,0,.04,0,.07,0,.04,0,.1,0,.19,0,.2,0,.38,0,.56,0Z"/>
          <path   d="m407.66,48.58c.79.02,1.38.03,1.76.03.29,0,.61,0,.95-.02.12,0,.23,0,.3,0,.03,0,.08,0,.15,0,.01.04.03.13.04.26l.03.25s-.02.06-.07.06c-.04,0-.07-.03-.11-.07-.06-.08-.13-.16-.22-.22s-.2-.1-.32-.12-.32-.03-.6-.03c-.02.16-.04.36-.04.6s0,.48.02.71c.01.23.03.36.05.41.02.05.05.08.1.1s.16.04.34.05c.06,0,.09.02.09.04,0,.03-.03.04-.08.04h-.06s-.48-.01-.48-.01c-.37,0-.6,0-.68,0h-.29s-.07,0-.14,0c-.04,0-.05-.02-.05-.04,0-.03.03-.05.09-.05.14,0,.24-.02.31-.06.07-.04.11-.1.13-.18.02-.08.03-.27.03-.55,0-.44-.02-.8-.06-1.08-.25,0-.44,0-.55.02s-.22.05-.33.12c-.1.06-.19.14-.26.24-.03.04-.06.06-.09.06-.05,0-.08-.02-.08-.05,0,0,.01-.08.03-.2.02-.13.04-.23.07-.32Z"/>
          <path   d="m412.19,48.55h.16c.2.3.48.77.85,1.41.17.29.28.47.35.53s.17.1.3.12c.05,0,.07.02.07.04,0,.03-.03.05-.09.05-.03,0-.12,0-.25-.01-.16-.01-.29-.02-.4-.02-.11,0-.24,0-.41.01-.13,0-.21,0-.25,0-.07,0-.1-.02-.1-.05,0-.02.02-.04.07-.04.19-.01.28-.06.28-.15,0-.04-.03-.12-.08-.23s-.11-.21-.17-.3c-.1,0-.18,0-.23,0-.31,0-.55,0-.72,0-.13.24-.2.41-.2.49,0,.06.03.1.1.13.06.03.14.05.21.06.06,0,.09.02.09.05,0,.03-.03.04-.09.04-.02,0-.09,0-.19-.01-.13,0-.23-.01-.29-.01-.12,0-.24,0-.34.01-.03,0-.05,0-.07,0-.06,0-.08-.02-.08-.05,0-.02.02-.04.06-.04.09-.02.16-.04.21-.06s.11-.07.17-.15.13-.19.22-.35c.19-.36.48-.85.86-1.47Zm-.16.54l-.4.69h.81c-.04-.08-.11-.2-.19-.34-.09-.15-.17-.27-.21-.35Z"/>
          <path   d="m417.08,48.81l.06.45s0,.04,0,.05c0,.03-.02.05-.06.05s-.07-.02-.1-.06c-.12-.17-.27-.3-.47-.41-.19-.11-.42-.16-.67-.16-.33,0-.58.09-.77.27-.19.18-.28.4-.28.66,0,.28.1.51.3.7.2.18.44.28.72.28.12,0,.24-.02.36-.05s.2-.07.24-.12c.04-.04.05-.1.05-.18,0-.17,0-.28-.03-.33-.02-.04-.05-.07-.1-.09s-.15-.03-.29-.05c-.06,0-.08-.02-.08-.05,0-.03.03-.05.08-.04h.35s1.06,0,1.06,0c.05,0,.07.01.07.04,0,.02,0,.03-.03.03s-.08.02-.19.03c-.08.01-.14.03-.16.05-.02.03-.04.18-.06.47,0,.06,0,.1-.01.11,0,0-.09.04-.27.1-.18.06-.36.11-.55.14-.19.03-.38.04-.56.04-.31,0-.6-.05-.84-.14-.25-.09-.45-.21-.6-.36-.15-.15-.22-.34-.22-.55,0-.3.16-.56.46-.77.3-.21.72-.32,1.25-.31.19,0,.36.02.51.05.15.03.32.08.51.14.04.01.07.02.1.02.03,0,.05-.02.07-.04.02-.04.05-.06.09-.06.04,0,.07.03.07.1Z"/>
          <path   d="m445.86,49.82l.04.45s0,.04,0,.05c0,.03-.02.05-.06.04-.04,0-.07-.02-.09-.06-.11-.17-.26-.32-.45-.44-.19-.12-.41-.18-.66-.19-.33-.02-.59.06-.78.23-.19.18-.3.39-.31.64-.02.28.07.52.27.71.19.19.43.29.71.31.12,0,.24,0,.36-.03.12-.03.2-.07.24-.1.04-.04.06-.1.06-.18.01-.17,0-.28-.01-.33-.02-.04-.05-.08-.1-.09-.05-.02-.14-.04-.29-.06-.06,0-.08-.02-.08-.05,0-.03.03-.04.08-.04l.35.02,1.06.04s.07.02.07.04c0,.02-.01.03-.03.03s-.08.01-.19.02c-.08,0-.14.02-.16.05-.02.02-.05.18-.08.47,0,.06-.01.1-.02.11,0,0-.09.04-.28.09-.18.05-.37.09-.56.11-.19.02-.38.02-.56.01-.31-.02-.59-.08-.84-.17s-.44-.23-.58-.39c-.14-.16-.21-.35-.2-.56.02-.3.18-.55.5-.75.31-.2.74-.29,1.26-.26.19.01.35.04.5.07s.32.09.5.16c.04.01.07.02.1.02.03,0,.06-.01.07-.04.02-.04.05-.06.09-.05.04,0,.06.04.07.1Z"/>
          <path   d="m448.2,50.82c.2.03.37.09.5.16.13.07.22.21.28.41.05.18.11.31.18.37.07.06.17.1.3.11.07,0,.1.03.1.05,0,.03-.06.04-.18.03l-.88-.07c-.09-.07-.14-.18-.17-.35-.03-.21-.07-.35-.1-.44-.04-.09-.11-.15-.19-.19-.09-.04-.19-.07-.31-.07-.04,0-.08,0-.14,0-.02.37-.04.59-.04.67s.02.15.06.19c.04.04.13.06.26.08.07,0,.1.03.1.05,0,.03-.03.04-.09.04l-.7-.06c-.09,0-.21-.01-.36-.02-.14,0-.22,0-.26,0-.06,0-.09-.02-.09-.05,0-.03.03-.04.08-.04.09,0,.17,0,.24-.03.07-.03.12-.15.13-.36l.02-.29.04-.56c.02-.29.01-.46-.02-.53-.03-.07-.13-.11-.29-.12-.06,0-.09-.02-.09-.05,0-.03.05-.04.13-.04.43.03.8.05,1.1.05.13,0,.25,0,.36.01.35.02.62.09.81.19.19.11.28.24.27.39,0,.1-.06.19-.16.26s-.22.13-.36.16-.31.05-.5.04Zm-.62-.11s.07,0,.09,0c.13,0,.27,0,.41-.03s.24-.07.3-.14c.06-.07.09-.15.1-.23,0-.13-.04-.23-.15-.31-.11-.08-.25-.13-.43-.14-.07,0-.16,0-.26.01l-.05.83Z"/>
          <path   d="m452.31,50.11c.04.16.07.33.11.49,0,.03-.03.04-.07.04-.02,0-.05-.02-.08-.05-.15-.17-.28-.28-.41-.34-.13-.06-.31-.1-.56-.11-.14-.01-.27-.01-.4,0-.04.18-.07.38-.08.6,0,.04,0,.1,0,.19h.17c.11.02.2.02.28,0,.08-.01.15-.05.21-.11.06-.06.09-.1.1-.12.02-.03.04-.05.07-.05.04,0,.06.02.06.04,0,0,0,.02,0,.03-.02.06-.05.25-.07.57,0,.04,0,.08,0,.12,0,.01,0,.02,0,.03,0,.03-.03.04-.08.04-.03,0-.06-.03-.07-.09-.02-.1-.09-.18-.19-.24s-.27-.1-.51-.12c-.02.17-.03.29-.04.34-.02.24-.02.38-.02.42,0,.05.04.08.11.12.06.03.18.05.34.07.58.04.97-.06,1.17-.29.03-.03.06-.05.09-.05.04,0,.06.02.06.04,0,.02,0,.04-.02.06l-.18.44-.83-.06c-.23-.02-.45-.04-.65-.06-.2-.02-.36-.03-.47-.04-.13,0-.32-.02-.58-.03-.03,0-.06,0-.07,0-.07,0-.1-.02-.1-.06,0-.03.03-.04.09-.04.15.01.25-.01.3-.06.05-.05.09-.16.1-.33l.07-.8c.02-.27.03-.44.02-.5,0-.06-.03-.11-.07-.13s-.12-.05-.24-.07c-.05,0-.07-.02-.07-.05,0-.03.03-.04.09-.04l.55.05,1.85.14Z"/>
          <path   d="m455.42,50.34c.04.17.07.33.1.5,0,.03-.03.04-.07.04-.02,0-.05-.02-.08-.05-.15-.17-.28-.28-.41-.34-.13-.06-.31-.1-.56-.12-.14-.01-.27-.01-.4,0-.04.18-.07.38-.09.6,0,.04,0,.1-.01.19l.17.02c.11,0,.2,0,.28,0,.08-.01.15-.05.21-.11.06-.06.09-.1.1-.12.02-.03.04-.05.07-.04.04,0,.06.02.06.04,0,0,0,.02,0,.02-.02.06-.05.25-.07.57,0,.04,0,.09,0,.12,0,.01,0,.02,0,.03,0,.03-.03.04-.08.04-.03,0-.06-.03-.07-.09-.02-.1-.09-.18-.19-.24-.1-.06-.27-.1-.51-.12-.02.17-.03.29-.04.34-.02.24-.02.38-.02.42s.04.08.11.12c.06.03.18.06.34.07.58.05.97-.05,1.18-.28.03-.03.06-.05.09-.05.04,0,.06.02.06.04,0,.02,0,.04-.02.06l-.18.43-.83-.07c-.23-.02-.45-.04-.65-.06s-.36-.04-.47-.05c-.13-.01-.32-.02-.58-.03-.03,0-.06,0-.07,0-.07,0-.11-.03-.1-.06,0-.03.03-.04.09-.04.15.01.25,0,.3-.06.06-.05.09-.16.1-.33l.08-.8c.02-.27.03-.44.03-.5,0-.06-.03-.11-.07-.13-.04-.03-.12-.05-.24-.07-.05,0-.07-.03-.07-.05,0-.03.03-.04.09-.04l.55.06,1.85.15Z"/>
          <path   d="m456.78,50.44c.68.63,1.25,1.13,1.71,1.52,0-.07.01-.12.01-.14l.06-.61c.01-.14,0-.25-.01-.31s-.07-.12-.14-.16c-.08-.04-.17-.07-.27-.09-.06-.01-.08-.03-.08-.06,0-.03.03-.04.08-.04.03,0,.07,0,.11.02.09.01.21.03.38.04.21.02.37.03.48.03.05,0,.08,0,.12,0,.06,0,.08.02.08.05,0,.03-.04.04-.12.04-.1,0-.19.02-.28.06-.09.04-.14.13-.15.28l-.07.88-.07.66c0,.06-.03.08-.08.08-.01,0-.03,0-.05-.01-.02,0-.12-.09-.29-.24-.18-.15-.45-.39-.8-.71-.35-.32-.6-.55-.74-.69,0,.03-.03.29-.07.78-.01.15-.01.27,0,.36.01.09.06.16.16.2s.18.07.27.08c.05,0,.08.02.07.05,0,.03-.03.04-.09.04-.04,0-.08,0-.13-.02-.09-.02-.21-.03-.35-.04-.23-.02-.39-.03-.49-.03-.06,0-.11,0-.14,0-.06,0-.09-.03-.08-.06,0-.02.05-.04.15-.03.17,0,.28-.03.33-.09.05-.07.08-.16.09-.29.05-.53.07-.86.07-.98,0-.12-.04-.23-.14-.34-.09-.1-.21-.17-.35-.2-.06-.01-.09-.03-.08-.06,0-.03.04-.05.1-.04.02,0,.04,0,.07,0,.03,0,.1.01.19.02.2.02.38.04.55.05Z"/>
          <path   d="m460.84,50.8c.79.1,1.37.16,1.75.2.29.03.61.06.95.08.12,0,.23.02.3.03.03,0,.08,0,.15.02,0,.04.01.13.02.27v.25s-.02.06-.07.05c-.04,0-.07-.03-.1-.09-.05-.09-.12-.17-.2-.24-.08-.07-.19-.12-.3-.15s-.31-.06-.59-.09c-.04.16-.07.35-.1.59-.03.24-.04.48-.05.7s0,.37,0,.41c.02.05.04.09.09.11.04.03.15.05.33.08.06,0,.09.03.08.05,0,.03-.03.04-.08.04h-.06s-.47-.07-.47-.07c-.37-.04-.59-.06-.67-.07l-.29-.02s-.07,0-.14-.02c-.04-.01-.05-.02-.05-.05,0-.03.03-.04.09-.04.14.01.24,0,.31-.03s.12-.09.15-.17c.03-.08.05-.26.08-.54.05-.44.06-.8.05-1.08-.25-.03-.43-.04-.55-.04-.11,0-.23.03-.34.08-.11.05-.2.12-.28.21-.03.04-.06.05-.09.05-.05,0-.08-.02-.07-.05,0-.01.02-.08.05-.2s.06-.23.1-.31Z"/>
          <path   d="m465.45,51.25l.15.02c.17.32.4.81.69,1.49.13.31.23.5.29.57.06.07.15.12.29.15.04.01.07.03.06.05,0,.03-.04.04-.09.04-.03,0-.11-.02-.25-.04-.16-.03-.29-.05-.4-.06-.1-.01-.24-.02-.41-.04-.13,0-.21-.02-.25-.02-.07,0-.1-.03-.1-.06,0-.02.03-.03.08-.03.19.01.29-.03.3-.12,0-.04-.01-.12-.06-.24-.04-.12-.09-.23-.14-.32-.1-.01-.18-.02-.23-.03-.3-.04-.54-.06-.71-.08-.16.23-.24.38-.25.46,0,.06.02.1.08.14.06.04.13.07.2.08.06.01.08.03.08.06,0,.03-.04.04-.1.03-.02,0-.09-.01-.19-.03-.13-.02-.23-.04-.29-.04-.12-.02-.24-.02-.34-.03-.03,0-.05,0-.07,0-.06,0-.08-.02-.08-.06,0-.02.02-.04.06-.04.09,0,.17-.02.22-.04s.12-.06.18-.13c.07-.07.15-.17.26-.33.23-.34.57-.79,1.01-1.36Zm-.22.52l-.48.64.81.08c-.03-.08-.09-.2-.16-.36-.08-.16-.14-.29-.17-.37Z"/>
          <path   d="m470.23,52.07v.46s0,.04,0,.05c0,.03-.03.05-.07.04-.04,0-.07-.03-.09-.07-.1-.18-.24-.34-.41-.47s-.39-.21-.65-.24c-.33-.04-.59.02-.8.18-.21.16-.33.37-.36.62-.04.28.03.52.21.73.18.2.4.33.68.36.12.02.24.01.36,0s.21-.05.25-.09.06-.09.07-.17c.02-.17.03-.28.01-.33s-.04-.08-.09-.1c-.04-.02-.14-.05-.28-.08-.06-.01-.08-.03-.08-.06,0-.03.03-.04.08-.03l.35.04,1.05.12s.07.02.07.05c0,.02-.01.03-.03.03s-.08,0-.19,0c-.08,0-.14.01-.16.03-.03.02-.06.17-.11.46-.01.06-.02.1-.02.1,0,0-.1.03-.28.07-.18.04-.37.06-.56.07-.19,0-.38,0-.56-.03-.31-.04-.59-.12-.82-.24-.23-.12-.42-.26-.55-.43s-.18-.36-.15-.57c.04-.3.22-.53.55-.71s.76-.23,1.28-.16c.19.03.35.06.5.11.15.05.31.12.49.2.04.02.07.03.1.03s.05,0,.08-.04c.03-.04.06-.05.09-.05.04,0,.06.04.06.11Z"/>
        </g>
        <g id="Green_Tag-10" data-name="Green Tag">
          <path   d="m420.5,50.67c.12-.04.24-.08.35-.1.37-.09.61-.31.86-.53.15-.13.14-.27-.03-.42-.52-.49-1.19-.76-1.9-.96-.02-.02-.05-.02-.06-.03.29,0,.56.14.85.21.08.02.18.1.23.02.05-.06-.04-.13-.08-.2-.07-.1-.15-.2-.21-.31.39.44.88.76,1.45,1.02.25.1.38.07.47-.11.17-.28.34-.56.23-.92.28.41.66.8.44,1.29.14.01.27.02.4.03.11,0,.16.09.19.16.06.12-.09.07-.14.1-.27.15-.63.19-.82.45-.13.17-.41.19-.66.23-.52.06-1.03.08-1.56.07Z"/>
        </g>
        <g id="Green_Tag-11" data-name="Green Tag">
          <path   d="m473.16,55.1c.12-.03.22-.08.35-.1.37-.08.61-.31.85-.53.14-.13.14-.26-.03-.43-.51-.49-1.19-.75-1.89-.96-.03,0-.04-.01-.07-.03.29.02.56.15.85.22.07.03.19.1.24.03.06-.06-.03-.13-.08-.2-.08-.1-.15-.2-.21-.31.38.44.87.77,1.44,1.02.26.11.37.07.49-.11.16-.28.34-.56.21-.91.29.4.65.8.45,1.29.14.01.26.02.39.03.12,0,.16.09.2.16.06.12-.09.07-.14.1-.27.15-.64.19-.82.44-.13.18-.42.2-.67.23-.51.06-1.02.08-1.55.07Z"/>
        </g>
      </g>
      {aperture&&
      <g>
      <g id="Green_Tag-12" data-name="Green Tag" >
        <path id="G"   d="m193.81,723.94l.53-.04s.04,0,.06,0c.04,0,.05.02.05.06,0,.04-.02.07-.07.09-.2.11-.37.25-.5.43-.13.18-.2.4-.21.64-.01.32.08.57.29.76s.46.29.76.3c.33.02.6-.07.82-.26.22-.19.33-.42.35-.68,0-.11-.01-.23-.05-.35-.04-.12-.08-.2-.13-.23-.05-.04-.12-.05-.21-.06-.2,0-.33,0-.38.01-.05.02-.09.05-.11.09-.02.05-.04.14-.06.28,0,.05-.03.08-.06.08-.04,0-.05-.03-.05-.08v-.34s.04-1.02.04-1.02c0-.05.02-.07.05-.07.02,0,.03.01.04.03s.02.08.03.19c.01.08.03.13.06.16.03.02.21.05.54.08.07,0,.12.01.12.01,0,0,.05.09.11.27.06.18.11.36.14.54.03.18.04.37.03.55-.01.3-.08.57-.18.81-.11.24-.25.42-.44.56-.18.14-.4.2-.65.19-.35-.02-.64-.18-.88-.48-.24-.31-.35-.71-.33-1.22,0-.18.03-.34.08-.49.04-.14.1-.31.18-.49.01-.04.02-.07.02-.09,0-.03-.01-.05-.05-.07-.04-.02-.07-.05-.06-.08,0-.04.04-.06.12-.07Z"/>
        <path id="R"   d="m194.97,721.68c.04-.2.1-.36.19-.48.09-.12.24-.21.47-.25.21-.04.36-.1.43-.16.07-.06.12-.16.13-.28,0-.07.03-.1.06-.1.03,0,.05.06.04.17l-.09.85c-.08.08-.21.13-.4.15-.24.02-.41.05-.51.09-.1.04-.18.1-.23.18-.05.08-.08.19-.09.3,0,.03,0,.08,0,.14.42.03.69.05.79.06.1,0,.17-.01.22-.05.05-.04.08-.12.09-.25,0-.07.03-.1.06-.1.03,0,.05.03.04.09l-.07.68c0,.09-.01.21-.02.35,0,.13,0,.22-.01.25,0,.06-.02.09-.06.09-.03,0-.05-.03-.04-.08,0-.08,0-.16-.03-.23-.03-.07-.17-.12-.42-.14l-.34-.02-.65-.05c-.33-.03-.54-.02-.62,0s-.12.12-.14.28c0,.06-.03.09-.06.09-.04,0-.05-.04-.04-.13.03-.42.05-.77.06-1.06,0-.13,0-.25.02-.35.03-.34.1-.59.23-.78.12-.18.28-.26.45-.25.12,0,.22.06.31.16s.15.22.18.35c.04.14.05.3.05.48Zm-.12.6s0-.07,0-.09c0-.13,0-.26-.03-.39-.03-.13-.08-.23-.17-.29-.08-.06-.17-.09-.27-.1-.15-.01-.27.03-.37.14-.09.1-.15.24-.16.41,0,.07,0,.15.01.25l.97.08Z"/>
        <path id="E"   d="m194.2,717.59c.19-.03.39-.05.58-.08.03,0,.05.03.05.07,0,.02-.02.05-.06.07-.2.13-.33.26-.4.38-.07.12-.12.3-.14.54-.01.13-.02.26-.01.39.21.05.44.09.7.11.04,0,.12,0,.22.02l.02-.17c.01-.1,0-.2,0-.27-.01-.08-.06-.15-.13-.21-.07-.06-.12-.1-.14-.11-.04-.02-.05-.04-.05-.07,0-.04.02-.06.05-.06,0,0,.02,0,.03,0,.07.03.29.06.67.1.05,0,.1,0,.14,0,.01,0,.02,0,.03,0,.03,0,.05.03.04.08,0,.03-.04.06-.1.07-.11.02-.21.08-.28.18-.07.1-.12.26-.14.49.2.03.33.05.4.05.27.03.44.04.49.04.05,0,.1-.04.14-.1.04-.06.07-.17.08-.32.06-.56-.05-.95-.32-1.15-.04-.03-.06-.06-.05-.09,0-.04.02-.06.05-.06.02,0,.04,0,.07.02l.5.2-.08.8c-.02.23-.05.43-.08.63-.03.19-.05.35-.06.46-.01.12-.03.31-.04.56,0,.03,0,.05,0,.07,0,.07-.03.1-.07.1-.03,0-.05-.03-.04-.09.02-.14,0-.24-.07-.3-.06-.06-.18-.09-.38-.11l-.93-.11c-.32-.03-.51-.05-.59-.05-.07,0-.12.02-.16.06-.03.04-.06.11-.08.23,0,.05-.03.07-.06.06-.04,0-.05-.03-.05-.09l.07-.53.19-1.78Z"/>
        <path id="E-2" data-name="E"   d="m194.56,714.59c.19-.02.39-.05.58-.07.03,0,.05.03.04.07,0,.02-.02.05-.06.07-.2.13-.33.25-.41.37-.07.12-.12.3-.15.53-.02.13-.02.26-.02.39.21.05.44.09.69.12.04,0,.12.01.22.02l.02-.17c.01-.1.01-.2,0-.27-.01-.08-.06-.15-.13-.21s-.11-.1-.14-.11c-.04-.02-.05-.04-.05-.08,0-.04.02-.06.05-.06,0,0,.02,0,.03,0,.07.03.29.06.67.11.05,0,.1,0,.14,0,.01,0,.02,0,.03,0,.03,0,.05.03.04.08,0,.03-.04.05-.11.06-.12.01-.21.07-.28.17-.07.1-.12.26-.15.48.2.03.33.05.4.06.27.03.44.05.49.04.05,0,.1-.04.14-.1.04-.06.07-.17.09-.32.07-.56-.03-.95-.3-1.16-.04-.03-.05-.06-.05-.09,0-.04.02-.06.05-.06.02,0,.04,0,.07.02l.5.21-.1.8c-.03.22-.06.43-.09.63-.03.19-.05.35-.07.46-.02.12-.03.31-.05.56,0,.03,0,.05,0,.07,0,.07-.03.1-.07.1-.03,0-.05-.03-.04-.09.02-.14,0-.24-.06-.3-.06-.06-.18-.1-.38-.12l-.93-.13c-.32-.04-.51-.06-.59-.06-.07,0-.13.02-.16.06-.03.04-.06.11-.09.22-.01.05-.03.07-.06.06-.04,0-.05-.03-.04-.09l.08-.53.22-1.78Z"/>
        <path id="N"   d="m194.72,713.23c.75-.61,1.36-1.12,1.82-1.53-.08-.01-.13-.02-.16-.02l-.71-.1c-.17-.02-.29-.03-.37-.02-.08.01-.14.05-.19.12-.05.07-.09.16-.11.26-.01.05-.03.08-.07.07-.03,0-.05-.03-.04-.08,0-.03.01-.06.02-.11.02-.08.04-.2.06-.37.03-.2.05-.36.05-.46,0-.04,0-.08.01-.11,0-.06.03-.08.06-.08.03,0,.05.04.04.12,0,.09.01.18.06.27.05.09.15.14.32.17l1.02.13.76.12c.07,0,.1.04.09.09,0,.01,0,.03-.02.04,0,.02-.11.11-.29.27-.19.16-.47.4-.86.71s-.66.54-.83.66c.03,0,.33.05.9.13.18.03.32.04.42.03.1,0,.18-.05.24-.14.06-.09.09-.17.1-.25,0-.05.02-.07.06-.07.04,0,.05.03.04.09,0,.03-.01.08-.03.13-.02.09-.04.2-.06.33-.03.22-.05.38-.05.48,0,.06,0,.11-.01.13,0,.06-.03.08-.07.08-.03,0-.04-.05-.03-.15.01-.17-.02-.28-.1-.33-.08-.05-.19-.09-.33-.11-.62-.09-1-.14-1.14-.15-.14-.01-.27.02-.4.1-.13.08-.21.19-.25.33-.02.06-.04.08-.07.08-.04,0-.06-.04-.05-.1,0-.01,0-.04.01-.06,0-.03.02-.09.03-.18.03-.19.05-.37.08-.53Z"/>
        <path id="T"   d="m195.48,708.1c.15-.75.25-1.31.31-1.68.05-.28.09-.58.14-.91.01-.12.03-.22.04-.29,0-.03.01-.08.03-.15.05,0,.15,0,.31.01l.29.02s.06.04.06.08c0,.04-.04.07-.1.09-.1.04-.2.1-.28.17-.08.08-.15.17-.19.28-.04.11-.09.3-.13.56.18.05.41.11.68.15.28.05.55.09.82.12.26.03.42.04.48.03.06,0,.1-.04.13-.08.03-.04.07-.14.11-.31.01-.06.04-.08.07-.08.03,0,.05.03.04.08v.06s-.1.45-.1.45c-.06.35-.1.57-.11.65l-.04.28s-.01.07-.03.13c-.01.03-.03.05-.05.04-.04,0-.05-.04-.04-.09.02-.13.02-.23-.02-.3-.03-.07-.1-.13-.19-.16-.09-.03-.3-.08-.62-.13-.51-.09-.92-.14-1.25-.15-.04.24-.06.42-.06.53,0,.11.02.22.08.33.06.11.14.21.24.29.04.03.06.07.05.1,0,.05-.03.07-.07.07-.01,0-.09-.02-.23-.07-.14-.04-.26-.08-.35-.12Z"/>
        <path id="A"   d="m196.2,703.69l.03-.15c.38-.13.96-.3,1.76-.52.37-.1.59-.17.67-.22.08-.05.14-.14.18-.27.01-.04.03-.06.06-.06.04,0,.05.04.04.09,0,.03-.02.11-.06.24-.04.15-.07.28-.09.38-.02.1-.04.23-.06.4-.01.12-.03.2-.03.24-.01.07-.04.1-.07.09-.03,0-.04-.03-.03-.08.02-.18-.02-.28-.12-.3-.05,0-.14,0-.28.03-.14.03-.26.06-.38.1-.02.09-.03.17-.04.22-.05.29-.09.52-.12.69.26.18.43.27.52.29.06.01.12,0,.17-.07.05-.06.08-.12.1-.19.02-.05.04-.08.07-.07.03,0,.04.04.03.1,0,.02-.02.08-.04.18-.03.13-.05.22-.06.28-.02.11-.03.23-.04.33,0,.03,0,.05,0,.07,0,.05-.03.08-.07.07-.03,0-.04-.02-.04-.06,0-.09-.02-.16-.04-.22-.02-.06-.07-.12-.14-.19-.07-.07-.2-.17-.37-.28-.38-.26-.9-.63-1.54-1.12Zm.6.27l.73.53.13-.77c-.1.02-.24.06-.42.12-.19.06-.34.1-.44.13Z"/>
        <path id="G-2" data-name="G"   d="m197.32,699.15l.53.04s.04,0,.06,0c.04,0,.05.03.04.07,0,.04-.03.06-.08.08-.21.08-.4.19-.55.36-.16.16-.26.36-.31.6-.06.31,0,.58.18.79.18.22.41.35.7.41.32.06.61.02.85-.14.24-.15.39-.36.44-.62.02-.11.02-.23,0-.35-.02-.12-.05-.21-.09-.25-.04-.04-.11-.07-.2-.09-.2-.04-.33-.05-.38-.04-.05,0-.09.04-.12.08-.03.04-.06.13-.1.27-.02.05-.04.08-.07.07-.03,0-.05-.04-.04-.09l.06-.33.18-1.01s.03-.06.06-.06c.02,0,.03.01.03.03,0,.02,0,.08,0,.19,0,.08.01.13.03.16s.2.08.53.15c.07.02.11.03.12.03,0,0,.03.1.07.28.04.18.06.37.06.55,0,.19-.02.37-.05.55-.06.3-.16.56-.3.77-.14.22-.32.38-.52.49-.2.11-.43.14-.67.09-.34-.07-.61-.27-.8-.61-.19-.34-.24-.76-.14-1.26.03-.18.08-.33.15-.47.06-.14.15-.29.25-.46.02-.03.03-.06.04-.09,0-.03,0-.05-.04-.08-.04-.03-.06-.06-.05-.09,0-.04.05-.05.13-.05Z"/>
      </g>
      <g id="gaviota">
        <path id="gaviota-2" data-name="gaviota"   d="m200.02,696.11c0-.12-.03-.24-.02-.36,0-.37-.17-.67-.34-.97-.1-.18-.26-.22-.49-.11-.69.31-1.17.84-1.6,1.43-.02.02-.03.04-.06.05.1-.27.33-.46.48-.71.05-.06.16-.14.1-.21-.05-.07-.16,0-.25.01-.13.03-.26.07-.41.09.6-.21,1.1-.55,1.54-1,.19-.2.19-.32.02-.48-.27-.25-.53-.5-.96-.51.54-.13,1.08-.34,1.57.03.05-.13.1-.24.14-.35.04-.11.14-.12.24-.13.15-.02.05.1.07.15.09.3.03.65.27.91.16.18.11.45.07.7-.07.49-.19.98-.35,1.46Z"/>
      </g>      
      </g>      
      }
    </g>
  </g>
    </svg>
  )
}

export default GreenTagsPolo
