import React, { memo } from 'react'

import { Modal } from 'antd'
import B from 'components/texts/B/B'
import globalStyle from 'styles/global.module.scss'
import Button from 'components/buttons/Button/Button'
import { RootState } from 'redux/store/store'
import {  useSelector } from 'react-redux'

import { useAddSupToCatModal } from './hook/useAddSupToCatModal'

const AddSupToCatModal = memo(() => {
  const { openModal } = useSelector((state: RootState) => state.supplyToCatModal)
  const { loading, handleEndList, handleCloseModal } = useAddSupToCatModal()
  return (
    <Modal
      title={`Aviso`}
      open={openModal}
      centered
      className={globalStyle.confirmModal}
      onCancel={handleCloseModal}
      footer={
        <div className={globalStyle.confirmModalFooter}>
          <Button
            rounded
            onClick={handleCloseModal}
            label={'Cancelar'}
            sector='primary'
            background='white'
          />
          <Button
            rounded
            isLoading={loading}
            onClick={handleEndList}
            label={'Aceptar'}
            sector='primary'
          />
        </div>
      }
    >
      <div className={globalStyle.confirmModalBody}>
        <B>
          Acabas de validar un útil, deseas agregarlo al catalogo de útiles de Ezetera?
        </B>
      </div>
    </Modal>
  )
})
AddSupToCatModal.displayName = 'AddSupToCatModal'
export default AddSupToCatModal
