import { memo } from 'react'
import { Col, Form, Modal, Row } from 'antd'
import Button from 'components/buttons/Button/Button'
import style from './CreateSchoolForm.module.scss'
import { useSchoolForm } from 'hooks/adminSchool/useSchoolForm'
import { useLocation } from 'react-router-dom'
import B from 'components/texts/B/B'
import ErrorAlert from 'assets/img/errorAlert.svg'
import FormInput from 'components/formInputs/input'
import FormSelect from 'components/formInputs/select'
import FormDate from 'components/formInputs/Date'
import FormRadio from 'components/formInputs/radio'
import FormCheckbox from 'components/formInputs/checkbox'

const CreateSchoolForm = memo(
  ({ openDeleteModal, setOpenDeleteModal, openAceptRequestModal, setOpenAceptRequestModal }: any) => {
    const {
      schoolData,
      loadingPost,
      loadingDelete,
      onSubmit,
      deleteSchoolById,
      handleResponseChangeRequest,
      SCHOOL_TYPES,
      collageGroups,
      INPUTS_INITIAL_STATE,
      SCHOOL_LENGUAGES,
      form,
      permissions
    } = useSchoolForm()

    const location = useLocation()

    const renderCorrectInput2 = (key: number, objInput: any) => {
      if (objInput.type === 'checkbox')
      return (
        <FormCheckbox
          name={objInput.name}
          label={objInput.label}
          options={permissions}
          rules={objInput.rules}
        />
      )
      if (objInput.type === 'radioButton')
        return (
          <FormRadio
            name={objInput.name}
            label={objInput.label}
            options={SCHOOL_LENGUAGES}
            /* initialValue={location?.state['provider_free']} */
            optionType='button'
            buttonStyle='solid'
            rules={[
              {
                required: true,
                message: 'Selecciona una opción'
              }
            ]}
          />
        )
      if (objInput.type === 'date')
        return (
          <FormDate
            key={key}
            name={objInput.name}
            label={objInput.label}
            placeholder={objInput.placeholder}
            rules={objInput.rules}
            hasFeedback={false}
          />
        )
      if (objInput.type === 'select')
        return (
          <FormSelect
            key={key}
            name={objInput.name}
            options={objInput.name==='sex'?SCHOOL_TYPES : collageGroups}
            placeholder={objInput.placeholder}
            label={objInput.label}
            rules={objInput.rules}
            hasFeedback={false}
          />
        )
      return (
        <FormInput
          key={key}
          name={objInput.name}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          hasFeedback={false}
        />
      )
    }

    return (
      <Form
        onFinish={(values) => onSubmit(location?.state?.id, values)}
        layout='vertical'
        autoComplete='off'
        className={style.CreateSchoolForm}
        form={form}
      >
        <Row gutter={[16, 16]}>
          {Object.keys(schoolData).map((res: any, key) => {
            const objKey = res as keyof typeof INPUTS_INITIAL_STATE
            const objInput:any = schoolData[objKey]
            if(objInput.span) return <Col span={objInput.span}>{renderCorrectInput2(key, objInput)}</Col>
            return <Col span={12}>{renderCorrectInput2(key, objInput)}</Col>
          })}
        </Row>

        <Form.Item>
          {location.state !== null ? (
            <Button
              isLoading={loadingPost}
              /* disabled={checkDisabled()} */
              buttonType='submit'
              type='blue'
              sector='primary'
              rounded={true}
              size='m'
            >
              Guardar Cambios
            </Button>
          ) : (
            <Button
              isLoading={loadingPost}
              /* disabled={checkDisabled()} */
              buttonType='submit'
              type='blue'
              sector='primary'
              rounded={true}
              size='m'
            >
              Crear Colegio
            </Button>
          )}
        </Form.Item>

        <Modal
          title={`Aceptar solicitud`}
          open={openAceptRequestModal}
          centered
          className={style.deletedModal}
          onCancel={(_) => setOpenAceptRequestModal(false)}
          footer={
            <div className={style.deleteModalFooter} style={{gap:16}}>
              <Button
                isLoading={loadingDelete}
                onClick={() => handleResponseChangeRequest(location.state,false)}
                rounded
                sector='secondary'
                label={'Denegar'}
              />
              <Button
                isLoading={loadingDelete}
                onClick={() => handleResponseChangeRequest(location.state,true)}
                rounded
                sector='tertiary'
                label={'Aceptar'}
              />
            </div>
          }
        >
          <div className={style.deleteModalBody}>
            <img src={ErrorAlert} alt='descargar' />
            <B size='Light'>
              ¿Estas seguro que deseas devolver es estado de: "
              <strong>{location?.state?.name}</strong>", a "en construcción".
            </B>
          </div>
        </Modal>

        <Modal
          title={`Eliminar colegio`}
          open={openDeleteModal}
          centered
          className={style.deletedModal}
          onCancel={(_) => setOpenDeleteModal(false)}
          footer={
            <div className={style.deleteModalFooter}>
              <Button
                isLoading={loadingDelete}
                onClick={() => deleteSchoolById(location.state)}
                rounded
                sector='secondary'
                label={'Eliminar'}
              />
            </div>
          }
        >
          <div className={style.deleteModalBody}>
            <img src={ErrorAlert} alt='descargar' />
            <B size='Light'>
              ¿Estas seguro que quieres eliminar a: "
              <strong>{location?.state?.name}</strong>" ?, toda la informacion
              del mismo se perdera con este proceso
            </B>
          </div>
        </Modal>
      </Form>
    )
  }
)
CreateSchoolForm.displayName = 'CreateSchoolForm'
export default CreateSchoolForm
