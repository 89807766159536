/* eslint-disable react-hooks/exhaustive-deps */
import { memo, KeyboardEvent, useState, useEffect } from 'react'
import { Col, Form, FormInstance, Row, Tooltip } from 'antd'
import FormInput from 'components/formInputs/input'
import styles from './formBooks.module.scss'
import './reset.scss'
import FormRadio from 'components/formInputs/radio'
import FormSelect from 'components/formInputs/select'
import FormTextArea from 'components/formInputs/textArea'
import Button from 'components/buttons/Button/Button'
import { ONLY_NUMBERS } from '../../../constants'
import { DataBook } from 'interfaces/books-interface'
import { BOOK_TYPES } from 'hooks/books/useBooks'
import FormAutoComplete from 'components/formInputs/autocomplete'
import {
  optionsHeritable,
  optionsLanguage,
  optionsProviderFee,
  optionsPurpose,
  useEditorials
} from 'hooks/books/editorial/useEditorials'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Modals from 'components/modal'
import { useLocation } from 'react-router-dom'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useDispatch } from 'react-redux'
import { setISBN } from 'redux/reducers/books'
import B from 'components/texts/B/B'
import ErrorAlert from 'assets/img/errorAlert.svg'
import { getOptionsListForProducts } from 'services/genericServices/genericServices'

interface FormProps {
  loading: boolean
  form: FormInstance<any>
  onHandleForm: (values: any) => Promise<any>
  actions: DataBook
  action: any
  setActions: (values: DataBook) => void
}

const FormBooksModule = memo((props: FormProps) => {
  const [letters, setLetters] = useState<number>(props.actions.countLetters)
  const [checkISBN, setCheckISBN] = useState<boolean>(false)
  const [modalStatusFive, setModalStatusFive] = useState<boolean>(false)
  const [infoAuthorize, setInfoAuthorize] = useState<any>(null)

const [inputOptions, setinputOptions] = useState({
  categories:[],
  tax:[],
  store:[],
  price_list:[]
});

  const location = useLocation()
  const dispatch = useDispatch()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()


  const getOptionsForInputs = async () => {
    try {
      const result = await getOptionsListForProducts('Libros')
      const listdata = result.data
      delete listdata.success

      const createLabelOptions = (customArray:any) =>{
        return customArray.map((res: any) => {
          return { label: res, value: res, id: res }
        })
      }

      setinputOptions({
        categories:createLabelOptions(listdata.categories),
        tax:createLabelOptions(listdata.tax),
        store:createLabelOptions(listdata.store),
        price_list:createLabelOptions(listdata.price_list)
      })

    } catch (error) {
      //handleErrors(error)
    }
  }

  useEffect(() => {
    if (props.action === 'editar') {
      props.form.setFieldsValue(location?.state)
      dispatch(setISBN(location?.state?.isbn))
      props.setActions({
        id: location?.state?.id_book,
        name: location?.state?.name,
        status: location?.state?.status,
        action: 'edit',
        countLetters: location?.state?.comments?.length,
        toast: 'Libro actualizado exitosamente.',
        id_school_book: location?.state?.id,
        incomplete: location?.state?.edition ? false : true
      })
      setLetters(location?.state?.comments?.length)
    } else {
      props.form.resetFields()
    }
    getOptionsForInputs()
  }, [])

  const {
    loading,
    optionEditorial: options,
    optionProvider,
    modalEditorial,
    form: formEditorial,
    categoryOptions,
    onHandleAutoCompleteEditorials,
    onHandleAutoCompleteProviders,
    setModalEditorial,
    handleNewEditorial
  } = useEditorials()

  const acceptSendAuthorize = () => {
    props.onHandleForm(infoAuthorize)
    setModalStatusFive(false)
  }

  const onSubmit = (values: any) => {
    if (location?.state?.status === 5) {
      setModalStatusFive(true)
      setInfoAuthorize(values)
    } else {
      console.log('on submit')
      props.onHandleForm(values)
    }
  }

  const onCountLetters = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    setLetters((e.target as HTMLTextAreaElement).value.length)
  }

  const onChangeISBN = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckISBN(true)
      props.form.setFieldValue('isbn', undefined)
    } else {
      setCheckISBN(false)
    }
  }

  return (
    <>
      <Form
        className={styles.formBook}
        form={props.form}
        layout='vertical'
        onFinish={onSubmit}
        autoComplete='off'
      >
        <Row gutter={32}>
          <Col span={12}>
            <FormInput
              name='name'
              placeholder='Título'
              label='Título'
              rules={[{ required: true, message: '' }]}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name='author'
              placeholder='Autor'
              rules={[{ required: true, message: '' }]}
              label='Autor'
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormInput
              onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                ONLY_NUMBERS(e)
              }
              name='edition'
              placeholder='Edición'
              label='Edición'
              rules={[
                { required: true, message: '' },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: 'Escribe solo números'
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <FormAutoComplete
              name='editorial'
              type={'text'}
              label={
                <>
                  <span>Editorial</span>&nbsp;
                  <Tooltip title='Agregar Editorial'>
                    <PlusCircleIcon
                      onClick={() => setModalEditorial(true)}
                      className={styles.addEditorial}
                    />
                  </Tooltip>
                </>
              }
              placeholder='Editorial'
              options={options}
              rules={[{ required: true, message: '' }]}
              onSearch={onHandleAutoCompleteEditorials}
            />
          </Col>
          {/* <Col span={8}>
            <FormInput
              name='matter'
              placeholder='Materia'
              label='Materia'
              rules={[{ required: true, message: '' }]}
            />
          </Col> */}
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormSelect
              name='format'
              options={BOOK_TYPES}
              placeholder='Formato'
              label='Formato'
              rules={[{ required: true, message: '' }]}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name='serie'
              placeholder='Serie'
              // rules={[
              //   {
              //     required: true,
              //     message: ''
              //   }
              // ]}
              label='Serie'
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormInput
              onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                ONLY_NUMBERS(e)
              }
              name='isbn'
              disabled={checkISBN ? true : false}
              placeholder='0000000000000'
              label={
                <>
                  <span>ISBN</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Tooltip title='ISBN pendiente'>
                    <Checkbox onChange={onChangeISBN}>Pendiente</Checkbox>
                  </Tooltip>
                </>
              }
              maxLength={13}
              {...(!checkISBN && {
                rules: [
                  { required: true, message: '' },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: 'Escribe solo números'
                  }
                ]
              })}
            />
          </Col>
          <Col span={12}>
            {/* <FormInput
              onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                ONLY_NUMBERS(e)
              }
              rules={[
                {
                  pattern: new RegExp(/^\d+$/),
                  message: 'Escribe solo números'
                },
                { required: true, message: '' }
              ]}
              name='sku'
              placeholder='0000000000000'
              label='SKU'
              maxLength={13}
            /> */}
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormRadio
              name='language'
              label='Idioma'
              options={optionsLanguage}
              optionType='button'
              initialValue={'Español'}
              buttonStyle='solid'
            />
          </Col>
          <Col span={12}>
            <Row justify='space-between'>
              <FormRadio
                name='heritable_book'
                label='Libro heredable'
                options={optionsHeritable}
                initialValue={true}
                optionType='button'
                buttonStyle='solid'
              />
              <FormRadio
                name='new_entry'
                label='Nuevo ingreso'
                options={optionsHeritable}
                initialValue={true}
                optionType='button'
                buttonStyle='solid'
              />
              <FormRadio
                name='first_use'
                label='Es el primer año de uso'
                options={optionsHeritable}
                initialValue={true}
                optionType='button'
                buttonStyle='solid'
              />
              <FormRadio
                name='purpose_book'
                label='Consulta o de trabajo'
                options={optionsPurpose}
                initialValue={1}
                optionType='button'
                buttonStyle='solid'
              />
            </Row>
          </Col>
        </Row>

        {uid.role === 'Ezetera' && (
          <>
            <Row gutter={32}>
              <Col span={6}>
                <FormInput
                  // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  //   ONLY_NUMBERS(e)
                  // }
                  name='price'
                  placeholder='$0.00'
                  label='Precio'
                  maxLength={13}
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                      message: 'Escribe solo números'
                    }
                  ]}
                />
              </Col>

              <Col span={6}>
                <FormInput
                  // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  //   ONLY_NUMBERS(e)
                  // }
                  name='special_price'
                  placeholder='$0.00'
                  label='Precio especial'
                  maxLength={13}
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                      message: 'Escribe solo números'
                    }
                  ]}
                />
              </Col>

              <Col span={6}>
                <FormInput
                  // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  //   ONLY_NUMBERS(e)
                  // }
                  name='discount'
                  placeholder='%0'
                  label='% de descuento'
                  maxLength={13}
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                      message: 'Escribe solo números'
                    }
                  ]}
                />
              </Col>
              <Col span={6}>
                <FormInput
                  // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  //   ONLY_NUMBERS(e)
                  // }
                  name='return_percentage'
                  placeholder='0%'
                  label='% de devolución'
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                      message: 'Escribe solo números'
                    }
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <FormInput
                  // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  //   ONLY_NUMBERS(e)
                  // }
                  name='refill_time'
                  placeholder='0'
                  label='Tiempo de resurtido'
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: 'Escribe solo números'
                    }
                  ]}
                />
              </Col>
              <Col span={12}>
                {/* <FormInput
                  name='provider'
                  placeholder='Proveedor'
                  label='Proveedor'
                  rules={[{ required: true, message: '' }]}
                /> */}

                <FormAutoComplete
                  name='provider'
                  type={'text'}
                  label='Proveedor'
                  placeholder='Proveedor'
                  options={optionProvider}
                  rules={[{ required: true, message: '' }]}
                  onSearch={onHandleAutoCompleteProviders}
                />
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={12}>
                <FormSelect
                  name='category'
                  options={inputOptions.categories}
                  placeholder='Categoria'
                  label='Categoria'
                  rules={[{ required: true, message: '' }]}
                />

                {/* <FormInput
                  name='category'
                  placeholder='Categoria'
                  label='Categoria'
                  rules={[{ required: true, message: '' }]}
                /> */}
              </Col>
              <Col span={12}>
                <FormSelect
                  name='tax'
                  options={inputOptions.tax}
                  placeholder='Impuesto'
                  label='Impuesto'
                  rules={[{ required: true, message: '' }]}
                />
              </Col>
            </Row>

            <Row gutter={32}>
              
              <Col span={12}>
                <FormSelect
                  name='price_list'
                  options={inputOptions.price_list}
                  placeholder='Precio de lista'
                  label='Precio de lista'
                  rules={[{ required: true, message: '' }]}
                />
              </Col>
              <Col span={12}>
                <FormSelect
                  name='store'
                  options={inputOptions.store}

                  placeholder='Tienda'
                  label='Tienda'
                  rules={[{ required: true, message: '' }]}
                />
              </Col>
              <Col span={12}>
                <FormRadio
                  name='inventariable'
                  label='Inventariable'
                  options={optionsProviderFee}
                  initialValue={true}
                  optionType='button'
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Col>
            </Row>

            <Col span={12}>
              <Row justify='space-between'>
                <FormRadio
                  name='provider_fee'
                  label='Cuota de proveedor'
                  options={optionsProviderFee}
                  initialValue={true}
                  optionType='button'
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />

                <FormRadio
                  name='resource_free'
                  label='Recurso gratuito'
                  options={optionsProviderFee}
                  initialValue={true}
                  optionType='button'
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Row>
            </Col>
          </>
        )}
        <FormTextArea
          name='comments'
          label='Comentarios'
          rows={4}
          placeholder='Agrega comentarios aqui'
          maxLength={100}
          onKeyUp={onCountLetters}
          // rules={[
          //   {
          //     required: true,
          //     message: ''
          //   }
          // ]}
        />
        <div className={styles.countLetters}>{letters ?? 0}/100</div>

        {(!location?.state ||
          location?.state?.status === 1 ||
          (location?.state?.status === 2 && uid.role === 'Ezetera') ||
          location?.state?.status === 3 ||

          /* (props?.actions?.incomplete &&
            location?.state?.status === 4 &&
            uid.role === 'Ezetera')  */
            
            (location?.state?.status === 4 && uid.role === 'Ezetera')||
          (location?.state?.status === 5 && uid.role === 'Ezetera')) &&
          uid.role !== 'Director' && (
            <Button
              isLoading={props.loading}
              sector='primary'
              size='m'
              buttonType='submit'
              rounded
            >
              {props.actions.action === 'edit'
                ? 'Guardar cambios'
                : 'Solicitar libro'}
            </Button>
          )}
      </Form>

      <Modals
        title='Agregar editorial'
        open={modalEditorial}
        centered
        onCancel={() => {
          formEditorial.resetFields()
          setModalEditorial(false)
        }}
      >
        <Form
          className={styles.formEditorial}
          form={formEditorial}
          layout='vertical'
          onFinish={(value) => handleNewEditorial(value, props.form)}
          autoComplete='off'
        >
          <FormInput
            name='name'
            placeholder='Nombre'
            label='Nombre'
            rules={[{ required: true, message: '' }]}
          />
          <div className={styles.btn}>
            <Button
              isLoading={loading}
              buttonType='submit'
              rounded
              sector='primary'
            >
              Agregar
            </Button>
          </div>
        </Form>
      </Modals>
      <Modals
        title='Aviso'
        open={modalStatusFive}
        centered
        onCancel={() => {
          setModalStatusFive(false)
        }}
      >
        <>
          <div className={styles.bodyEndList}>
            <img src={ErrorAlert} alt='Terminar lista' />
            <B size='Light'>
              ¿Estás seguro que deseas actualizar la información a un libro
              autorizado?
            </B>
          </div>
          <div className={styles.button}>
            <Button
              rounded
              onClick={acceptSendAuthorize}
              label={'Aceptar'}
              sector='secondary'
            />
          </div>
        </>
      </Modals>
    </>
  )
})

export default FormBooksModule
