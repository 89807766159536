import { Form, Input, Modal, Skeleton } from 'antd'
import Button from 'components/buttons/Button/Button'
import Caption from 'components/texts/Caption/Caption'
import S from 'components/texts/S/S'
import { ErrorToast } from 'components/Toasts/Toasts'
import dayjs from 'dayjs'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  changeAction,
  updateSchoolBookStatusCommentsAdmin
} from 'redux/reducers/books'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { RootState } from 'redux/store/store'
import {
  bookSugestionService,
  changeBookStatus,
  getBooks,
  searchBook,
  updateBook
} from 'services/books/books'
import { AUTHSERVICE } from 'services/config'
import {
  updateUtilsAdmin,
  updateUtilsAdminSuggest
} from 'services/utilsServices/utils'
import { SuggestionsForm as BooksForm } from './books/SuggestionsForm'
import { SuggestionsForm as UtilsForm } from './utils/SuggestionsForm'

const RenderSuggestions = memo(
  ({ data, afterBookStatusUpdated, updateItem }: any) => {
    const [loading, setLoading] = useState(true)
    const [suggestions, setSuggestions] = useState<Array<any>>([])
    const [pendingSuggestion, setPendingSuggestion] = useState<any | null>(null)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [savingData, setSavingData] = useState(false)

    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const location = useLocation()
    const { pathname } = location
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { sendAction, booksSchool } = useSelector(
      (state: RootState) => state.books
    )

    const isEzetera = uid.role == 'Ezetera'
    const isInStatus23 = data?.status == 2 || data?.status == 3
    const isInUtils = pathname.includes('utiles')
    const isInBooks = pathname.includes('libros')

    useEffect(() => {
      loadSuggestions()
    }, [data])

    const loadSuggestions = async () => {
      if (!(data?.id || data?.id_supply_school)) return

      setLoading(true)

      try {
        let url = '/suggestions/'
        if (isInUtils) url += `supply/${data?.id_supply_school}`
        else if (isInBooks) url += `book/${data?.id}`

        const res = await AUTHSERVICE().get(url)
        const { suggestions: resData } = res.data
        setPendingSuggestion(resData?.find((it: any) => it?.status == 'Activa'))
        setSuggestions(
          isEzetera
            ? resData
            : resData?.filter((it: any) => it?.status != 'Activa')
        )
      } catch (error) {}

      setLoading(false)
    }

    const handleRejectRequest = async (values: any) => {
      setSavingData(true)

      try {
        await AUTHSERVICE().put('/suggestions/status', {
          id: pendingSuggestion?.id,
          status: 'Rechazada',
          message: values?.comment
        })

        if (updateItem) {
          await updateItem(3)
        }

        setOpenRejectModal(false)
        loadSuggestions()

        dispatch(changeAction(!sendAction))
      } catch (error) {
        ErrorToast('Ocurrió un error, inténtelo más tarde')
      }

      setSavingData(false)
    }

    const handleAcceptRequest = async () => {
      setSavingData(true)

      try {
        await AUTHSERVICE().put('/suggestions/status', {
          id: pendingSuggestion?.id,
          status: 'Autorizada'
        })

        // update the items
        if (isInUtils) {
          await updateUtilsAdminSuggest({
            data: {
              id_supply_school: data?.id_supply_school,
              name: pendingSuggestion?.name,
              sku: pendingSuggestion?.sku,
              price: pendingSuggestion?.price,
              picture: pendingSuggestion?.picture,
              id_package: data?.id_package
            }
          })

          const nData = { ...data }
          ;['name', 'sku', 'price', 'picture'].forEach((key: string) => {
            if (pendingSuggestion[key]) nData[key] = pendingSuggestion[key]
          })
          dispatch(setCommentsObjectInformation(nData))

          afterBookStatusUpdated && afterBookStatusUpdated()
        } else if (isInBooks) {
          const { id_grade, id_level, id_school } = data
          let nData = {
            id_book_school: data?.id,
            dataCollege: {
              idLevel: id_level,
              idGrade: id_grade,
              idSchool: id_school
            },
            name: pendingSuggestion?.name,
            isbn: pendingSuggestion?.sku,
            price: pendingSuggestion?.price,
            format: '',
            editorial: '',
            edition: '',
            author: '',
            serie: '',
            language: '',
            comments: '',
            heritable_book: false,
            discount: 0,
            delivery_time: '0',
            return_percentage: 0,
            provider: '',
            new_entry: true,
            purpose_book: 0,
            refill_time: 0,
            provider_fee: false,
            special_price: 0,
            first_use: true,
            resource_free: false,
            sku: ''
          }

          await bookSugestionService({
            id_book_school: data?.id,
            name: pendingSuggestion?.name,
            sku: pendingSuggestion?.sku,
            price: pendingSuggestion?.price,
            id_grade: data?.id_grade
          })
          dispatch(setCommentsObjectInformation(nData))
          dispatch(setopenComments(false))
          afterBookStatusUpdated && afterBookStatusUpdated()
          /* const { id_grade, id_level, id_school } = data
                // check if the book with the isbn exists
                const first = booksSchool.find((it:any) => (it?.isbn == pendingSuggestion?.sku && it?.id_grade == id_grade))
                if (first) {
                    ErrorToast("ISBN ya se encuentra registrado en el nivel y grado.")
                    await AUTHSERVICE().put('/suggestions/status', {
                        id: pendingSuggestion?.id,
                        status: 'Rechazada',
                        message: 'ISBN ya se encuentra registrado en el nivel y grado.'
                    })
                } else {
                    let nData = {
                        id_book_school: data?.id,
                        dataCollege: { idLevel: id_level, idGrade: id_grade, idSchool: id_school },
                        name: pendingSuggestion?.name,
                        isbn: pendingSuggestion?.sku,
                        price: pendingSuggestion?.price,
                        format: "",
                        editorial: "",
                        edition: "",
                        author: "",
                        serie: "",
                        language: "",
                        comments: "",
                        heritable_book: false,
                        discount: 0,
                        delivery_time: "0",
                        return_percentage: 0,
                        provider: "",
                        new_entry: true,
                        purpose_book: 0,
                        refill_time: 0,
                        provider_fee: false,
                        special_price: 0,
                        first_use: true,
                        resource_free: false,
                        sku: ""
                    }
                    const res2 = await getBooks(1, '', '', pendingSuggestion?.sku)
                    const { data:resData2 } = res2.data
                    const { books:details2 } = resData2
                    const first2 = details2[0]
                    await updateBook(data?.id_book, nData)
                    await changeBookStatus(data?.id, {
                        status: 4,
                        id_book: first2?.id_book
                    })

                    dispatch(setCommentsObjectInformation(nData))
                    dispatch(setopenComments(false))
                    afterBookStatusUpdated && afterBookStatusUpdated()
                } */
        }

        dispatch(changeAction(!sendAction))
        setOpenRejectModal(false)
        loadSuggestions()
      } catch (error) {
        const e = error as {
          response: { data: { error: string; success: boolean } }
        }
        switch (e.response.data.error) {
          case 'duplicate id book':
            ErrorToast(
              `El ISBN:${pendingSuggestion?.sku} ya existe, favor de verificar o agregar uno diferente.`
            )
            break
          default:
            ErrorToast('Ocurrió un error, inténtelo más tarde')
            break
        }
      }

      setSavingData(false)
    }

    if (uid.role !== 'Ezetera' && pendingSuggestion === undefined) return null
    if (uid.role === 'Ezetera' && !isInStatus23) return null

    return loading ? (
      <Skeleton active />
    ) : (
      <div>
        <div style={{ margin: '12px 0px' }}>
          <S variation='2'>Sugerencias</S>
        </div>
        {uid.role == 'Ezetera' && (
          <div>
            {isInUtils ? (
              <UtilsForm
                data={data}
                onSubmitFinished={() => {
                  if (afterBookStatusUpdated) afterBookStatusUpdated()
                  loadSuggestions()
                }}
              />
            ) : isInBooks ? (
              <BooksForm
                data={data}
                onSubmitFinished={() => {
                  loadSuggestions()
                  dispatch(
                    updateSchoolBookStatusCommentsAdmin({
                      id: data.id,
                      status: 3
                    })
                  )
                }}
              />
            ) : null}
          </div>
        )}

        {uid.role != 'Ezetera' && (
          <div>
            {pendingSuggestion && (
              <>
                <div style={{ marginBottom: '16px' }}>
                  Ezetera envió la siguiente sugerencia para la actualización
                  del {isInUtils ? 'útil' : 'libro'}
                </div>
                <div className='ticket-card'>
                  <div className='title'>
                    <div className='user'>
                      <Caption>{pendingSuggestion?.user}</Caption>{' '}
                      <div style={{ marginBottom: '16px' }}>
                        <small>
                          {dayjs(pendingSuggestion?.createdAt).fromNow()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className='description'>
                    <div>
                      <b>Nombre: </b>
                      <span>{pendingSuggestion?.name}</span>
                    </div>
                    <div>
                      <b>{isInUtils ? 'SKU' : 'ISBN'}: </b>
                      <span>{pendingSuggestion?.sku}</span>
                    </div>
                    <div>
                      <b>Precio: </b>
                      <span>{pendingSuggestion?.price}</span>
                    </div>
                    {pendingSuggestion?.message && (
                      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                        {pendingSuggestion?.message}
                      </div>
                    )}
                    {pendingSuggestion?.picture && (
                      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <img
                          src={pendingSuggestion?.picture}
                          style={{ height: '120px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='comments-footer-button' style={{ gap: '16px' }}>
                  <Button
                    onClick={() => setOpenRejectModal(true)}
                    size='m'
                    label='Rechazar'
                    rounded
                    sector='secondary'
                  />
                  <Button
                    onClick={() => handleAcceptRequest()}
                    isLoading={savingData}
                    size='m'
                    label='Aceptar'
                    rounded
                    sector='primary'
                  />
                </div>
              </>
            )}
            <Modal
              open={openRejectModal}
              onCancel={() => setOpenRejectModal(false)}
              title='Rechazar solicitud'
              footer={null}
            >
              <Form onFinish={handleRejectRequest} form={form}>
                <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                  Agrega un comentario para rechazar la sugerencia
                </div>
                <Form.Item name='comment'>
                  <Input.TextArea
                    placeholder='Agrega un comentario'
                    maxLength={100}
                    required={true}
                  />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Form.Item>
                    <Button
                      isLoading={savingData}
                      label='Rechazar sugerencia'
                      rounded
                      sector='secondary'
                      buttonType='submit'
                    />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div>
        )}

        {suggestions?.length > 0 && (
          <div
            style={{
              maxHeight: '640px',
              overflowY: 'auto',
              marginTop: '8px',
              marginBottom: '8px'
            }}
          >
            <div style={{ marginTop: '12px', marginBottom: '12px' }}>
              Sugerencias anteriores
            </div>
            {suggestions.map((it: any, ix: number) => (
              <div
                key={ix}
                style={{
                  marginTop: '16px',
                  paddingBottom: '12px',
                  borderBottom: '1px solid rgb(220,221,222)'
                }}
              >
                <div className='title'>
                  <div
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    <i>{it?.status}</i>
                  </div>
                  <div className='user'>
                    <Caption>{it?.user}</Caption>{' '}
                    <Caption size='Light'>
                      {dayjs(it?.createdAt).fromNow()}
                    </Caption>
                  </div>
                </div>
                <div className='description'>
                  <div>
                    <b>Nombre: </b>
                    <span>{it?.name}</span>
                  </div>
                  <div>
                    <b>{isInUtils ? 'SKU' : 'ISBN'}: </b>
                    <span>{it?.sku}</span>
                  </div>
                  <div>
                    <b>Precio: </b>
                    <span>{it?.price}</span>
                  </div>
                </div>
                {it?.message && (
                  <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                    {it.message}
                  </div>
                )}
                {it?.picture && (
                  <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                    <img src={it?.picture} style={{ height: '120px' }} />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
)

RenderSuggestions.displayName = 'RenderSuggestions'
export default RenderSuggestions
