import { memo } from 'react'
import {
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd'
import bookStyle from '../../../modules/books/books.module.scss'
import style from './AdminUniformsManagement.module.scss'

import utilsStyles from './AdminUniformsManagement.module.scss'
import Button from 'components/buttons/Button/Button'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { useLocation } from 'react-router-dom'
import BackButton from 'components/buttons/BackButton/BackButton'
import { useRequireUtilsForm } from 'hooks/utils/useRequireUtilsForm'
import UploadInput from 'components/inputs/UploadInput'
import FormInput from 'components/formInputs/input'

import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import Collapse from 'components/Collapse/Collapse'
import B from 'components/texts/B/B'
import FormRadio from 'components/formInputs/radio'
import FormSelect from 'components/formInputs/select'
import { useManagementUniforms } from '../../../hooks/uniforms/useManagementUniforms'
import { Options } from 'types'
import { PRESENTATION_TYPE } from 'modules/Utils/AddUtilsForm/hook/useAddUtilsForm'
const colStyle = { cursor: 'pointer', padding: '0 20px' }

const AdminUniformsManagement = memo(() => {
  const {
    params,
    schoolsCatalog,
    statusCatalog,
    utilsList,
    LoadingList,
    pagination,
    utilsColumns,
    tabs,
    selectedLevel,
    packages,
    loadingSupplies,
    selectStatus,
    packagesMap,
    selectedPackage,
    loadingDownload,
    navigate,
    setParams,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onRowClicked,
    onChangeSelectedTab,
    onCollapseChange,
    updateSupplies,
    getUtilsList,
    setSelectedPackage,
    downloadClicked,
    donwloadLogs
  } = useManagementUniforms()

  const {
    schoolData,
    INPUTS_INITIAL_STATE,
    form,
    optionsFee,
    handleFileChange,
    onSubmit,
    isStatusGT4,
    isStatusET4,
    isInReview
  } = useRequireUtilsForm()

  const location = useLocation()
  const { Option } = Select

  const renderCorrectInput2 = (
    key: number,
    objInput: any,
    disable: boolean
  ) => {
    if (objInput.type === 'select')
      return (
        <FormSelect
          name={objInput.name}
          options={PRESENTATION_TYPE}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'file')
      return (
        <UploadInput
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleFileChange}
          disabled={disable}
        />
      )
    return (
      <FormInput
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
        disabled={disable}
      />
    )
  }

  return (
    <>
      {params.has('action') &&
      (params.get('action') == 'nuevo' || params.get('action') == 'editar') ? (
        <div className={style.AdminUtilsManagement}>
          <BackButton
            onClick={() => {
              navigate(-1)
            }}
            textDecoration='underline'
          />
          <TitleHeader IconName='utilsIcon' title={location.state?.name} />
          <Form
            onFinish={(values) =>
              onSubmit(
                location?.state?.id || location?.state?.id_supply,
                values
              )
            }
            layout='vertical'
            autoComplete='off'
            initialValues={schoolData}
            className={style.CreateSchoolForm}
            form={form}
            disabled={isStatusGT4 || isInReview}
          >
            <Row gutter={[16, 16]}>
              {Object.keys(schoolData).map((res: any, key) => {
                const objKey = res as keyof typeof INPUTS_INITIAL_STATE
                const objInput = schoolData[objKey]
                const disableInput =
                  (isStatusET4 && objKey != 'quantity') ||
                  isStatusGT4 ||
                  isInReview
                return (
                  <Col span={12}>
                    {renderCorrectInput2(key, objInput, disableInput)}
                  </Col>
                )
              })}
              <Col span={6}>
                <FormRadio
                  name='provider_fee'
                  label='Cuota de proveedor'
                  options={optionsFee}
                  initialValue={location?.state['provider_free']}
                  optionType='button'
                  disabled={isStatusET4 || isInReview}
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Col>
              <Col span={6}>
                <FormRadio
                  name='resource_free'
                  label='Recurso gratuito'
                  options={optionsFee}
                  initialValue={location?.state['resource_free']}
                  optionType='button'
                  disabled={isStatusET4 || isInReview}
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Col>
            </Row>
            <Button
              size='m'
              rounded={true}
              sector='primary'
              onClick={() => {
                form.submit()
              }}
              disabled={isStatusGT4 || isInReview}
            >
              Actualizar
            </Button>
          </Form>
        </div>
      ) : (
        <div className={style.AdminUtilsManagement}>
          <CommentsComponent
            /*  innerFormFilled={sidebarFormFilled} */
            afterBookStatusUpdated={() => {
              if (params.has('school')) {
                updateSupplies(selectedPackage)
              } else {
                const page = parseInt(params.get('page') || '1')
                const search = params.get('search') || ''
                const status = params.get('status')
                const controller = new AbortController()
                getUtilsList(
                  page,
                  controller,
                  search,
                  status ? parseInt(status) : undefined
                )
              }
            }}
            /*  moduleForm={
                <FormCommentsModule
                  form={form}
                  handleSubmit={handleCommentsSubmit}
                  fromUtils={true}
                  setFormFilledOut={(flag: boolean) => { setSidebarFormFilled(flag) }} />
              }  */
          />
          <TitleHeader IconName='utilsIcon' title='Uniformes' />
          <div className={bookStyle.filters}>
            <div>
              <Typography>Colegios</Typography>
              {/* <Select
                  value={selectSchool}
                  style={{ width: 200 }}
                  defaultValue={'todos'}
                  placeholder={'Colegios'}
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  onChange={onHandleChangeSchool}----------------------------------------------------------------------------------------------------------------
                  options={schoolsCatalog}
                /> */}

              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Colegios'
                optionFilterProp='children'
                onChange={onHandleChangeSchool}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                {schoolsCatalog?.map((school: Options) => (
                  <Option key={school?.value as number} value={school?.value}>
                    {school?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Typography>Estatus</Typography>
              <Select
                value={selectStatus}
                style={{ width: 200 }}
                defaultValue={'todos'}
                placeholder={'Estatus'}
                onChange={onHandleChangeStatus}
                options={statusCatalog.filter(
                  (item: any) => item.label !== 'Nuevo'
                )}
              />
            </div>
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar'
              onClick={downloadClicked}
              isLoading={loadingDownload}
            />
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar Logs'
              onClick={donwloadLogs}
              isLoading={loadingDownload}
              disabled={true}
            />
          </div>
          <Input.Search
            placeholder='Buscar'
            enterButton
            size='large'
            allowClear={true}
            onSearch={(e: string) => {
              setParams({
                ...(e ? { search: e } : {})
              })
            }}
          />
          <>
            {params.has('school') ? (
              <div>
                <Tabs items={tabs} onChange={onChangeSelectedTab} />
                <div>
                  {selectedLevel?.grades?.map((res: any) => {
                    return (
                      <div key={`grade-${res.id}`} className={`${style['']}`}>
                        <Collapse id={res.id} title={res.name}>
                          <List.Item
                            style={{
                              padding: ' 10px 0',
                              background: '#F3F7FF80'
                            }}
                          >
                            <Col
                              className='ListTitles'
                              style={colStyle}
                              span={8}
                            >
                              <B size='Light'>Nombre del paquete</B>
                            </Col>
                          </List.Item>
                          {(() => {
                            const items = packages?.filter(
                              (it: any, ix: number) => res.id == it.id_grade
                            )
                            return (
                              <>
                                {items?.map((pck: any) => (
                                  <div key={`pck-div-${pck.id}`}>
                                    <Collapse
                                      id={pck.id}
                                      title={pck.name}
                                      customBg={
                                        utilsStyles['custom-collapse-bg']
                                      }
                                      isDroped={(flag: boolean) => {
                                        onCollapseChange(flag, pck.id)
                                      }}
                                    >
                                      <Table
                                        dataSource={
                                          selectStatus &&
                                          selectStatus != 'todos'
                                            ? packagesMap[pck.id]?.filter(
                                                (it: any) =>
                                                  it.status == selectStatus
                                              )
                                            : packagesMap[pck.id]
                                        }
                                        loading={loadingSupplies}
                                        columns={utilsColumns}
                                        /* className={styles.utilstable} */
                                        scroll={{ x: 800 }}
                                        rowKey={'id'}
                                        pagination={(() => {
                                          let filtered = packagesMap[pck.id]

                                          if (
                                            selectStatus &&
                                            selectStatus != 'todos'
                                          ) {
                                            filtered = packagesMap[
                                              pck.id
                                            ]?.filter(
                                              (it: any) =>
                                                it.status == selectStatus
                                            )
                                          }

                                          return {
                                            total: filtered?.length,
                                            pageSize: 10,
                                            showSizeChanger: false
                                          }
                                        })()}
                                        onRow={(record: any, _: any) => ({
                                          onClick: (_: any) => {
                                            setSelectedPackage(pck.id)
                                            onRowClicked(record)
                                          }
                                        })}
                                      ></Table>
                                    </Collapse>
                                  </div>
                                ))}
                              </>
                            )
                          })()}
                        </Collapse>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              <div>
                <div /* className={styles['table-container']} */>
                  <Table
                    dataSource={utilsList}
                    loading={LoadingList}
                    columns={utilsColumns}
                    /*  className={styles.utilstable} */
                    scroll={{ x: 800 }}
                    rowKey={'id'}
                    onRow={(record: any, _: any) => ({
                      onClick: (_: any) => {
                        onRowClicked(record)
                      }
                    })}
                    pagination={{
                      showSizeChanger: false,
                      total: pagination?.total,
                      pageSize: pagination?.limit,
                      onChange: (page: number, pageSize: number) => {
                        onHandleChangePage(page)
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </>
        </div>
      )}
    </>
  )
})
AdminUniformsManagement.displayName = 'AdminUniformsManagement'
export default AdminUniformsManagement
