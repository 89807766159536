import { Fragment, useState } from 'react'
import TitleHeader from 'modules/uniforms/uniformList/components/TitleHeader'
import { Skeleton } from 'antd'
import style from './notebooks.module.scss'
import { NoteBook } from 'components/icons/svg'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import AddNewPackageModal from './components/Modals/AddNewPackageModal'
import EraseSometingModal from './components/Modals/EraseSometingModal'
import CreateNewNotebookModal from './components/Modals/CreateNewNotebookModal'
import { useNotebooksPackages } from 'hooks/notebooks/useNotebooksPackages'
import NotebooksItemsList from './NotebooksItemsList'
import EndListModal from 'components/EndListModal/EndListModal'

const NotebooksListModule = () => {
  const {
    tabs,
    selectedLevel,
    handleChangeLevelTab,
    handleReload,
    LoadingList,
    setSelectedLevel
  } = useNotebooksPackages()

  const [showModalNewPackage, setShowModalNewPackage] = useState(false)
  const [showModalNewNotebook, setShowModalNewNotebook] = useState(false)
  const [showModalErase, setShowModalErase] = useState(false)
  const [dataOfNewPackage, setdataOfNewPackage] = useState<null | any>(null)
  const [dataofNewNotebook, setDataofNewNotebook] = useState<null | any>(null)
  const [dataErase, setDataErase] = useState<null | any>(null)
  const [createPackage, setCreatePackage] = useState(true)
  const [packageForm, setPackageForm] = useState({ quanty: 1, name: '' })
  const [edithPackageId, setEdithPackageId] = useState(0)

  const handleOkNewPackage = () => {
    handleReload()
    resetForm()
    setShowModalNewPackage(false)
    setEdithPackageId(0)
  }

  const handleCancelNewPackage = () => {
    resetForm()
    setShowModalNewPackage(false)
    setEdithPackageId(0)
  }

  const handleOkNewNotebook = () => {
    setShowModalNewNotebook(false)
  }

  const handleCancelNewNotebook = () => {
    setShowModalNewNotebook(false)
  }

  const handleOkErase = () => {
    handleReload()
    setShowModalErase(false)
  }

  const handleCancelErase = () => {
    setShowModalErase(false)
  }

  const resetForm = () => {
    setPackageForm({ name: '', quanty: 1 })
  }

  return (
    <Fragment>
      <CommentsComponent />
      <TitleHeader
        IconProps={{ className: `${style['notebook-icon']}` }}
        Icon={NoteBook}
        title='Paquetes'
        showButton={false}
      />

      <Skeleton loading={LoadingList} active>
        <NotebooksItemsList
          tabs={tabs}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          handleChangeLevelTab={handleChangeLevelTab}
          RightActionButtons={PlusCircleIcon}
          RightActionButtonsProps={{
            moreProps: { className: `${style['plus-circle-icon']}` },
            onClick: (some: string) => {
              setShowModalNewPackage(true)
              setCreatePackage(true)
              resetForm()
              setdataOfNewPackage(some)
            }
          }}
          addButtonInPackage={{
            moreProps: {},
            onClick: (some: string) => {
              setDataofNewNotebook(some)
              setShowModalNewNotebook(true)
            }
          }}
          edithButtonInPackage={{
            moreProps: {},
            onClick: (some: {
              packageInfo: { name: string; id: number; quanty: number }
              data: any
              packageId: number
            }) => {
              setCreatePackage(false)
              setPackageForm({
                quanty: some.packageInfo.quanty,
                name: some.packageInfo.name
              })
              setEdithPackageId(some.packageId)
              setdataOfNewPackage(some.data)
              setShowModalNewPackage(true)
            }
          }}
          eraseButtonInPackage={{
            moreProps: {},
            onClick: (some: string) => {
              setShowModalErase(true)
              setDataErase(some)
            }
          }}
        />
      </Skeleton>
      <AddNewPackageModal
        create={createPackage}
        show={showModalNewPackage}
        onOk={handleOkNewPackage}
        onCancel={handleCancelNewPackage}
        form={packageForm}
        setForm={setPackageForm}
        data={dataOfNewPackage}
        idLevel={selectedLevel?.id}
        edithPackageId={edithPackageId}
      />
      <CreateNewNotebookModal
        create={createPackage}
        show={showModalNewNotebook}
        onOk={handleOkNewNotebook}
        onCancel={handleCancelNewNotebook}
        buttonClick={handleOkNewNotebook}
        data={dataofNewNotebook}
      />
      <EraseSometingModal
        show={showModalErase}
        onOk={handleOkErase}
        onCancel={handleCancelErase}
        data={dataErase}
        buttonText='Continuar'
      />
      <EndListModal/>
    </Fragment>
  )
}

export default NotebooksListModule
