
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { getSellsPerDay } from 'services/sells/sells'
import { useGetInitialParams } from './useGetInitialParams'
import TranslateDateToSpanish from 'utilities/translateDatesToSpanish'
import dayjs from 'dayjs'


export default function useSells() {
const [loading, setloading] = useState(true)
  const { apliedFilters, loadingFilter } = useSelector((state: RootState) => state.sells)
  const [sells, setSellPerDay] = useState([])
  const { schoolIDGea } = useGetInitialParams()

  const parsedDataByDay = (sells: any) => {
    let sellByDate: any = {}
    sells.forEach((res: any) => {
      const key = res.fecha.replace(/ /g, '_')
      if (!sellByDate[key]) {
        sellByDate = {
          ...sellByDate,
          [key]: {
            fecha: TranslateDateToSpanish(dayjs(res.fecha).format('DD MMM YYYY')),
            total: parseFloat(res.total)
          }
        }
      } else {
        const newObject = {
          ...sellByDate[key],
          total: parseFloat(res.total) + parseFloat(sellByDate[key].total)
        }
        sellByDate = { ...sellByDate, [key]: newObject }
      }
    })
    return sellByDate
  }

  const getSells = async () => {
    !loading && setloading(true)
    try {
      const sellsPerDay: any = await getSellsPerDay({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      setSellPerDay(parsedDataByDay(sellsPerDay.data.data))
      
    } catch (error) {
      console.log(error)
    }finally{
      setloading(false)
    }
  }

  useEffect(() => {
   !loadingFilter && getSells()
  }, [apliedFilters,loadingFilter])

  return { sells, loading }
}
