import { TFormCapitonadoChamarraOptions } from 'interfaces/uniforms-interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleChamarraCapitonadoForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const ChamarraCapitonadaOptions = () => {
  const { chamarraCapitonadoForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormCapitonadoChamarraOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleChamarraCapitonadoForm({
        ...chamarraCapitonadoForm,
        [keyName]: {
          ...chamarraCapitonadoForm[keyName as TFormCapitonadoChamarraOptions],
          value: value
        }
      })
    )
  }
  return (
    <div>
      <ConstructorFormulary
        handleChange={handleChange}
        form={chamarraCapitonadoForm}
      />
    </div>
  )
}

export default ChamarraCapitonadaOptions
