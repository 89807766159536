import React, { useState, useEffect } from 'react'
import InputArt from './InputArt'
import style from './art.module.scss'
import DropdownHOC from 'components/dropdowns/DropdownHOC'
import HoverOptions from './HoverOptions'
import Button from 'components/buttons/Button/Button'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import {
  setJsonImgBack,
  setJsonImgFront,
  setJsonTextBack,
  setJsonTextfront
} from 'redux/reducers/notebooksSlice'
import { Tooltip } from 'antd'

const fonts = {
  Arial: ['Arial'],
  Alkatra: [
    'AlkatraRegular',
    'AlkatraMedium',
    'AlkatraSemibold',
    'AlkatraBold'
  ],
  'Courier New': ['Courier'],
  'Franklin Gothic Medium': ['Franklin'],
  'Gill Sans': ['Gill'],
  'Lucida Sans': ['Lucida'],
  'Concert One': ['Concert-One'],
  'Segoe UI': ['Segoe'],
  'Times New Roman': ['Times'],
  Cambria: ['Cambria'],
  'Trebuchet MS': ['Trebuchet'],
  Georgia: ['Georgia'],
  Impact: ['Impact'],
  fantasy: ['fantasy'],
  cursive: ['cursive'],
  'Open sans': [
    'OpenSans-Light',
    'OpenSans-Regular',
    'OpenSans-Medium',
    'OpenSans-SemiBold',
    'OpenSans-Bold'
  ],
  monospace: ['monospace'],
  Roboto: [
    'Roboto-Thin',
    'Roboto-Light',
    'Roboto-Regular',
    'Roboto-Medium',
    'Roboto-Bold'
  ],
  'sans serif': ['sans-serif'],
  serif: ['serif'],
  'system-ui': ['system-ui'],
  Verdana: ['Verdana']
}

const TextEditablePanel = () => {
  const {
    jsonTextBack,
    jsonTextfront,
    TextBackId,
    TextfrontkId,
    textPositionNow,
    jsonImgBack,
    jsonImgfront,
    ImgBackId,
    ImgfrontkId
  } = useSelector((state: RootState) => state.notebooks)

  const dispatch = useDispatch()

  const [size, setSize] = useState<number>(12)
  const [text, setText] = useState('Text')
  const [color, setColor] = useState('#000000')
  const [fontFamily, setFontFamily] = useState('Arial')
  let dropdown: any

  const handleTextBiIdBack = (id: number, texxt: string) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text, label: texxt }
        }
      })
    )
  }
  const handleTextSizeBiIdBack = (id: number, fsize: number) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text, fontSize: fsize }
        }
      })
    )
  }
  const handleTextFamilyBiIdBack = (id: number, family: string) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text, fontFamily: family }
        }
      })
    )
  }
  const handleTextColorBiIdBack = (id: number, fontColor: string) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextBack[id].text, color: fontColor }
        }
      })
    )
  }
  //------------------------------------
  const handleTextBiIdFront = (id: number, texxt: string) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text, label: texxt }
        }
      })
    )
  }
  const handleTextSizeBiIdFront = (id: number, fsize: number) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text, fontSize: fsize }
        }
      })
    )
  }
  const handleTextFamilyBiIdFront = (id: number, family: string) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text, fontFamily: family }
        }
      })
    )
  }
  const handleTextColorBiIdFront = (id: number, fontColor: string) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: { ...jsonTextfront[id].text, color: fontColor }
        }
      })
    )
  }
  const handleTextBackSave = (id: number) => {
    dispatch(
      setJsonTextBack({
        ...jsonTextBack,
        [id]: {
          ...textPositionNow,
          text: jsonTextBack[id].text
        }
      })
    )
  }
  const handleTextFrontSave = (id: number) => {
    dispatch(
      setJsonTextfront({
        ...jsonTextfront,
        [id]: {
          ...textPositionNow,
          text: jsonTextfront[id].text
        }
      })
    )
  }
  const handleImgBackSave = (id: number) => {
    dispatch(
      setJsonImgBack({
        ...jsonImgBack,
        [id]: {
          ...textPositionNow,
          img: jsonImgBack[id].img
        }
      })
    )
  }
  const handleImgFrontkSave = (id: number) => {
    dispatch(
      setJsonImgFront({
        ...jsonImgfront,
        [id]: {
          ...textPositionNow,
          img: jsonImgfront[id].img
        }
      })
    )
  }

  useEffect(() => {
    if (TextBackId !== 0) {
      setText(jsonTextBack[`${TextBackId}`].text.label)
      setSize(jsonTextBack[`${TextBackId}`].text.fontSize)
      setFontFamily(jsonTextBack[`${TextBackId}`].text.fontFamily)
      setColor(jsonTextBack[`${TextBackId}`].text.color)
    } else if (TextfrontkId !== 0) {
      setText(jsonTextfront[`${TextfrontkId}`].text.label)
      setSize(jsonTextfront[`${TextfrontkId}`].text.fontSize)
      setFontFamily(jsonTextfront[`${TextfrontkId}`].text.fontFamily)
      setColor(jsonTextfront[`${TextfrontkId}`].text.color)
    }
  }, [TextBackId, TextfrontkId])

  useEffect(() => {
    if (TextfrontkId !== 0) handleTextFrontSave(TextfrontkId)
    else if (TextBackId !== 0) handleTextBackSave(TextBackId)
    else if (ImgBackId !== 0) handleImgBackSave(ImgBackId)
    else if (ImgfrontkId !== 0) handleImgFrontkSave(ImgfrontkId)
  }, [textPositionNow])

  useEffect(() => {
    TextBackId !== 0 && handleTextSizeBiIdBack(TextBackId, size)
    TextfrontkId !== 0 && handleTextSizeBiIdFront(TextfrontkId, size)
  }, [size])

  return (
    <div className={`${style['div-container-external']}`}>
      <textarea
        style={{ resize: 'none', outline: 'none' }}
        onChange={(e) => {
          setText(e.target.value || '')
          TextBackId !== 0 &&
            handleTextBiIdBack(TextBackId, e.target.value || '')
          TextfrontkId !== 0 &&
            handleTextBiIdFront(TextfrontkId, e.target.value || '')
        }}
        spellCheck={false}
        value={text}
      />
      <div>
        <div className={`${style['div-options-container']}`}>
          <div className={`${style['div-optionsize-container']}`}>
            <Button
              customStyle={style['custom-button']}
              RightIcon={MinusIcon}
              rounded
              onClick={() => {
                setSize(size - 1)
              }}
              background='white'
            />
            <Tooltip title="Interlineado">
              <input
                value={size}
                className={`${style['input-size']}`}
                type='number'
                onChange={(e) => setSize(parseFloat(e.target.value))}
              />
            </Tooltip>

            <Button
              customStyle={style['custom-button']}
              RightIcon={PlusIcon}
              rounded
              onClick={() => {
                setSize(size + 1)
              }}
              background='white'
            />
          </div>

          <input
            className={`${style['input-color']}`}
            type='color'
            value={color}
            onChange={(e) => {
              setColor(e.target.value)
              TextBackId !== 0 &&
                handleTextColorBiIdBack(TextBackId, e.target.value)
              TextfrontkId !== 0 &&
                handleTextColorBiIdFront(TextfrontkId, e.target.value)
            }}
          />

          <div className={`${style[``]}`}>
            <DropdownHOC triggerRef={(el) => (dropdown = el)}>
              {React.createElement(() => (
                <p
                  onClick={() => console.log(jsonTextBack, TextBackId)}
                  className={`${style[fontFamily]} ${style['font-fam-p']}`}
                >
                  {fontFamily}
                </p>
              ))}
              {React.createElement(() => (
                <div className={`${style['son-custom']}`}>
                  {Object.keys(fonts).map((res: any, key) => {
                    const objKey = res as keyof typeof fonts
                    const objInput = fonts[objKey]

                    return (
                      <div
                        className={`${style['div-input-container']}`}
                        key={key}
                      >
                        <HoverOptions
                          setFont={(dat: string) => {
                            setFontFamily(dat)
                            TextBackId !== 0 &&
                              handleTextFamilyBiIdBack(TextBackId, dat)
                            TextfrontkId !== 0 &&
                              handleTextFamilyBiIdFront(TextfrontkId, dat)
                          }}
                          label={objKey}
                          familys={objInput}
                          selected={fontFamily}
                          click={() => dropdown.click()}
                        />
                      </div>
                    )
                  })}
                </div>
              ))}
            </DropdownHOC>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextEditablePanel
