import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathRayado7mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Rayado Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Rayado_Completo' data-name='Path Rayado Completo'>
              <g id='Path_Rayado_Completo-2' data-name='Path Rayado Completo'>
                <g
                  id='Rayado_Completo'
                  data-name='Rayado Completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line x1='686.41' y1='171.16' x2='85.23' y2='171.16' />
                  <line x1='686.41' y1='190.76' x2='85.23' y2='190.76' />
                  <line x1='686.41' y1='210.35' x2='85.23' y2='210.35' />
                  <line x1='686.41' y1='229.95' x2='85.23' y2='229.95' />
                  <line x1='686.41' y1='249.55' x2='85.23' y2='249.55' />
                  <line x1='686.41' y1='269.15' x2='85.23' y2='269.15' />
                  <line x1='686.41' y1='288.74' x2='85.23' y2='288.74' />
                  <line x1='686.41' y1='308.34' x2='85.23' y2='308.34' />
                  <line x1='686.41' y1='327.94' x2='85.23' y2='327.94' />
                  <line x1='686.41' y1='347.53' x2='85.23' y2='347.53' />
                  <line x1='686.41' y1='367.13' x2='85.23' y2='367.13' />
                  <line x1='686.41' y1='386.73' x2='85.23' y2='386.73' />
                  <line x1='686.41' y1='406.33' x2='85.23' y2='406.33' />
                  <line x1='686.41' y1='425.92' x2='85.23' y2='425.92' />
                  <line x1='686.41' y1='445.52' x2='85.23' y2='445.52' />
                  <line x1='686.41' y1='465.12' x2='85.23' y2='465.12' />
                  <line x1='686.41' y1='484.72' x2='85.23' y2='484.72' />
                  <line x1='686.41' y1='504.31' x2='85.23' y2='504.31' />
                  <line x1='686.41' y1='523.91' x2='85.23' y2='523.91' />
                  <line x1='686.41' y1='543.51' x2='85.23' y2='543.51' />
                  <line x1='686.41' y1='563.1' x2='85.23' y2='563.1' />
                  <line x1='686.41' y1='582.7' x2='85.23' y2='582.7' />
                  <line x1='686.41' y1='602.3' x2='85.23' y2='602.3' />
                </g>

                <g
                  fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                >
                  {page === 2 && (
                    <line x1='111.56' y1='151.56' x2='111.56' y2='622.51' />
                  )}
                  {page === 1 && (
                    <line
                      id='_Trazado_espejo_'
                      data-name='&amp;lt;Trazado espejo&amp;gt;'
                      x1='659.26'
                      y1='151.56'
                      x2='659.26'
                      y2='622.51'
                    />
                  )}
                  {(() => {
                    switch (page) {
                      case 1:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='m671.85,164.21c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,183.38c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,198.7c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,223.25c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,242.2c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,257.52c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,283.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,301.03c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,316.35c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,341.92c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,359.85c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,375.17c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,400c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,418.68c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,434c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,458.54c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,477.5c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,492.82c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,516.82c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,536.32c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,551.64c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,576.22c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,595.15c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,609.26c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='m671.85,203.28c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m671.85,164.26c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,218.46c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m670.95,179.25c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,242.49c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,257.67c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,281.7c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,296.88c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,320.91c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,336.09c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,360.12c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,375.3c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,399.33c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,414.52c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,438.54c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,453.73c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,477.76c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,492.94c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,516.97c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,532.15c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,556.18c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,571.36c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m671.85,595.39c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m670.95,609.02c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )
                      case 2:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='M99,164.21a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,164.21' />
                                      <path d='M99,183.38a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,183.38' />
                                      <path d='M99.86,198.7A22,22,0,0,0,96.53,203l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68A7.88,7.88,0,0,1,95.2,204l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,223.25a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,223.25' />
                                      <path d='M99,242.2a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,242.2' />
                                      <path d='M99.86,257.52a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,283.1a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,283.1' />
                                      <path d='M99,301a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,301' />
                                      <path d='M99.86,316.35a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18c.17-.15.49-.4,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,341.92a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,341.92' />
                                      <path d='M99,359.85a2.54,2.54,0,1,0-2.54-2.54A2.53,2.53,0,0,0,99,359.85' />
                                      <path d='M99.86,375.17a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.89,1.89,0,0,1,.89.68,7.73,7.73,0,0,1,.86,1.6l.21.49a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,400a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,400' />
                                      <path d='M99,418.68a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,418.68' />
                                      <path d='M99.86,434a21.7,21.7,0,0,0-3.33,4.25l-.24-.53c-.48-1.08-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,458.54A2.54,2.54,0,1,0,96.43,456,2.53,2.53,0,0,0,99,458.54' />
                                      <path d='M99,477.5A2.54,2.54,0,1,0,96.43,475,2.54,2.54,0,0,0,99,477.5' />
                                      <path d='M99.86,492.82a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,516.82a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,516.82' />
                                      <path d='M99,536.32a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,536.32' />
                                      <path d='M99.86,551.64a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,576.22a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,576.22' />
                                      <path d='M99,595.15a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,595.15' />
                                      <path d='M99.86,609.26a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a25.92,25.92,0,0,1,3.07-4.81,20.89,20.89,0,0,1,3.66-3.82l-.27-.38a19,19,0,0,0-4,3.17' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='M99,203.28a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,203.28' />
                                      <path d='M99,164.26a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,164.26' />
                                      <path d='M99.86,218.46a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99.86,179.25a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,242.49A2.54,2.54,0,1,0,96.43,240,2.53,2.53,0,0,0,99,242.49' />
                                      <path d='M99.86,257.67a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.85,1.85,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,281.7a2.54,2.54,0,1,0-2.54-2.54A2.53,2.53,0,0,0,99,281.7' />
                                      <path d='M99.86,296.88a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.85,1.85,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.39a26.21,26.21,0,0,1,3.07-4.8,20.89,20.89,0,0,1,3.66-3.82l-.27-.38a19,19,0,0,0-4,3.17' />
                                      <path d='M99,320.91a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,320.91' />
                                      <path d='M99.86,336.09a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,360.12a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,360.12' />
                                      <path d='M99.86,375.3a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,399.33a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,399.33' />
                                      <path d='M99.86,414.52a21.7,21.7,0,0,0-3.33,4.25l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,438.54A2.54,2.54,0,1,0,96.43,436,2.53,2.53,0,0,0,99,438.54' />
                                      <path d='M99.86,453.73A21.7,21.7,0,0,0,96.53,458l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68A7.35,7.35,0,0,1,95.2,459l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,477.76a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,477.76' />
                                      <path d='M99.86,492.94a21.7,21.7,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,517a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,517' />
                                      <path d='M99.86,532.15a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,556.18a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,556.18' />
                                      <path d='M99.86,571.36a22.3,22.3,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18c.17-.15.49-.4,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,595.39a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,595.39' />
                                      <path d='M99.86,609a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Rayado Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Rayado_Datos' data-name='Path Rayado Datos'>
              <g id='Path_Rayado_Datos-2' data-name='Path Rayado Datos'>
                <g
                  id='Rayado_Datos'
                  data-name='Rayado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line x1='686.41' y1='216.16' x2='85.23' y2='216.16' />
                  <line x1='686.41' y1='235.76' x2='85.23' y2='235.76' />
                  <line x1='686.41' y1='255.35' x2='85.23' y2='255.35' />
                  <line x1='686.41' y1='274.95' x2='85.23' y2='274.95' />
                  <line x1='686.41' y1='294.55' x2='85.23' y2='294.55' />
                  <line x1='686.41' y1='314.15' x2='85.23' y2='314.15' />
                  <line x1='686.41' y1='333.74' x2='85.23' y2='333.74' />
                  <line x1='686.41' y1='353.34' x2='85.23' y2='353.34' />
                  <line x1='686.41' y1='372.94' x2='85.23' y2='372.94' />
                  <line x1='686.41' y1='392.53' x2='85.23' y2='392.53' />
                  <line x1='686.41' y1='412.13' x2='85.23' y2='412.13' />
                  <line x1='686.41' y1='431.73' x2='85.23' y2='431.73' />
                  <line x1='686.41' y1='451.33' x2='85.23' y2='451.33' />
                  <line x1='686.41' y1='470.92' x2='85.23' y2='470.92' />
                  <line x1='686.41' y1='490.52' x2='85.23' y2='490.52' />
                  <line x1='686.41' y1='510.12' x2='85.23' y2='510.12' />
                  <line x1='686.41' y1='529.72' x2='85.23' y2='529.72' />
                  <line x1='686.41' y1='549.31' x2='85.23' y2='549.31' />
                  <line x1='686.41' y1='568.91' x2='85.23' y2='568.91' />
                  <line x1='686.41' y1='588.51' x2='85.23' y2='588.51' />
                </g>

                <g
                  fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                  stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                >
                  {page === 2 && (
                    <line x1='111.56' y1='196.56' x2='111.56' y2='608.37' />
                  )}
                  {page === 1 && (
                    <line
                      id='_Trazado_espejo_'
                      data-name='&amp;lt;Trazado espejo&amp;gt;'
                      x1='660.31'
                      y1='196.56'
                      x2='660.31'
                      y2='608.37'
                    />
                  )}
                  {(() => {
                    switch (page) {
                      case 1:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='m672.9,209.4c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,228.57c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,243.89c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,268.44c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,287.39c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,302.71c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,328.29c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,346.22c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,361.54c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,387.11c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,405.04c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,420.36c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,445.19c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,463.86c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,479.18c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,503.73c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,522.69c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,538.01c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,562.01c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,581.51c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,596.13c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='m672.9,248.47c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.9,209.44c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,263.65c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.01,224.44c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,287.68c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,302.86c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,326.89c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,342.07c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,366.1c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,381.28c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,405.31c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,420.49c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,444.52c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,459.7c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,483.73c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,498.91c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,522.94c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,538.12c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,562.15c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                      <path d='m672.01,577.34c1.36,1.38,2.47,2.8,3.33,4.26l.24-.54c.48-1.07.92-1.61,1.31-1.61.46,0,.97.29,1.52.88-.33.11-.62.34-.89.68-.26.34-.55.87-.86,1.59l-.22.5c-.23.55-.37.94-.44,1.18-.17-.16-.49-.4-.96-.73l-.57-.38c-.71-1.49-1.73-3.09-3.07-4.81-1.34-1.72-2.56-2.99-3.66-3.81l.27-.38c1.3.74,2.62,1.8,3.98,3.17' />
                                      <path d='m672.9,601.36c-1.4,0-2.54-1.14-2.54-2.54s1.14-2.54,2.54-2.54,2.54,1.14,2.54,2.54-1.13,2.54-2.54,2.54' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )
                      case 2:
                        return (
                          <g>
                            {(() => {
                              switch (puntoGuia) {
                                case 'PuntoPuntoPaloma':
                                  return (
                                    <g id='Punto_guia' data-name='Punto guia'>
                                      <path d='M99,209.4a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,209.4' />
                                      <path d='M99,228.57A2.54,2.54,0,1,0,96.43,226,2.54,2.54,0,0,0,99,228.57' />
                                      <path d='M99.86,243.89a22,22,0,0,0-3.33,4.25l-.24-.53c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,268.44a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,268.44' />
                                      <path d='M99,287.39a2.54,2.54,0,1,0-2.54-2.54A2.53,2.53,0,0,0,99,287.39' />
                                      <path d='M99.86,302.71A22,22,0,0,0,96.53,307l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.73,7.73,0,0,1,.86,1.6l.21.49a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,328.29a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,328.29' />
                                      <path d='M99,346.22a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,346.22' />
                                      <path d='M99.86,361.54a21.7,21.7,0,0,0-3.33,4.25l-.24-.53c-.48-1.08-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,387.11a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,387.11' />
                                      <path d='M99,405a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,405' />
                                      <path d='M99.86,420.36a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,445.19a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,445.19' />
                                      <path d='M99,463.86a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,463.86' />
                                      <path d='M99.86,479.18a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,503.73a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,503.73' />
                                      <path d='M99,522.69a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,522.69' />
                                      <path d='M99.86,538a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18c.17-.15.49-.4,1-.73l.57-.38a26.59,26.59,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,562a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,562' />
                                      <path d='M99,581.51A2.54,2.54,0,1,0,96.43,579,2.53,2.53,0,0,0,99,581.51' />
                                      <path d='M99.86,596.13a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                    </g>
                                  )
                                case 'PuntoPaloma':
                                  return (
                                    <g id='Punto_guia-2' data-name='Punto guia'>
                                      <path d='M99,248.47a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,248.47' />
                                      <path d='M99,209.44a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,209.44' />
                                      <path d='M99.86,263.65a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a11.2,11.2,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99.86,224.44a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.26,2.26,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.6,7.6,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10.42,10.42,0,0,1,1-.72l.57-.39a26.59,26.59,0,0,1,3.07-4.81,20.79,20.79,0,0,1,3.66-3.81l-.27-.38a18.33,18.33,0,0,0-4,3.17' />
                                      <path d='M99,287.68a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,287.68' />
                                      <path d='M99.86,302.86a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,8.56,8.56,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,326.89a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,326.89' />
                                      <path d='M99.86,342.07a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.88,7.88,0,0,1,.86,1.59l.21.5a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,366.1a2.54,2.54,0,1,0-2.54-2.54A2.54,2.54,0,0,0,99,366.1' />
                                      <path d='M99.86,381.28a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.61-1.32-1.61a2.31,2.31,0,0,0-1.52.88,1.89,1.89,0,0,1,.89.68,7.73,7.73,0,0,1,.86,1.6l.21.49a12.09,12.09,0,0,1,.44,1.19,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,405.31a2.54,2.54,0,1,0-2.54-2.54A2.53,2.53,0,0,0,99,405.31' />
                                      <path d='M99.86,420.49a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.85,1.85,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.39a26.59,26.59,0,0,1,3.07-4.81,21.12,21.12,0,0,1,3.66-3.81l-.27-.38a18.64,18.64,0,0,0-4,3.17' />
                                      <path d='M99,444.52A2.54,2.54,0,1,0,96.43,442,2.53,2.53,0,0,0,99,444.52' />
                                      <path d='M99.86,459.7A22,22,0,0,0,96.53,464l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69A7.35,7.35,0,0,1,95.2,465l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a25.92,25.92,0,0,1,3.07-4.81,20.89,20.89,0,0,1,3.66-3.82l-.27-.38a19,19,0,0,0-4,3.17' />
                                      <path d='M99,483.73a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,483.73' />
                                      <path d='M99.86,498.91a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a11.26,11.26,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,522.94a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,522.94' />
                                      <path d='M99.86,538.12a22,22,0,0,0-3.33,4.26l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,1.92,1.92,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a19,19,0,0,0-4,3.17' />
                                      <path d='M99,562.15a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,562.15' />
                                      <path d='M99.86,577.34a21.7,21.7,0,0,0-3.33,4.25l-.24-.54c-.48-1.07-.92-1.6-1.32-1.6a2.28,2.28,0,0,0-1.52.87,2,2,0,0,1,.89.69,7.35,7.35,0,0,1,.86,1.59l.21.5a12.17,12.17,0,0,1,.44,1.18,10,10,0,0,1,1-.73l.57-.38a26.25,26.25,0,0,1,3.07-4.81,20.47,20.47,0,0,1,3.66-3.81l-.27-.39a18.71,18.71,0,0,0-4,3.18' />
                                      <path d='M99,601.36a2.54,2.54,0,1,0-2.54-2.53A2.53,2.53,0,0,0,99,601.36' />
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        )

                      default:
                        return <></>
                    }
                  })()}
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Rayado Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Rayado_Completo'
                data-name='Margenes Rayado Completo'
              >
                <g
                  id='Margenes_Rayado_Completo-2'
                  data-name='Margenes Rayado Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g>
                      <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                        <rect
                          x='85.73'
                          y='152.06'
                          width='600.69'
                          height='469.95'
                        />
                        <path d='m685.92,152.56v468.95H86.23V152.56h599.69m1-1H85.23v470.95h601.69V151.56h0Z' />
                      </g>
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Rayado Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g id='Margenes_Rayado_Datos' data-name='Margenes Rayado Datos'>
                <g
                  id='Margenes_Rayado_Datos-2'
                  data-name='Margenes Rayado Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.81'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V594.29a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V594.29a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.81'
                      />
                      <path d='M685.92,197.56V607.37H86.23V197.56H685.92m1-1H85.23V608.37H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathRayado7mmHorizontal
