import React, { memo } from 'react'
import * as icons from './svg'
import { AllIconsInterface } from '../../types'

import { UserGroupIcon, UserPlusIcon } from '@heroicons/react/24/outline'

interface ItemProps {
  name?: AllIconsInterface
  height?: number
  width?: number
}

const Icon = memo(({ name, height, width }: ItemProps) => {
  if (name === 'mail') return <icons.Mail height={height} width={width} />
  if (name === 'logo') return <icons.Logo height={height} width={width} />
  if (name === 'book') return <icons.Book height={height} width={width} />
  if (name === 'uniform') return <icons.Uniform height={height} width={width} />
  if (name === 'estructure')
    return <icons.Estructure height={height} width={width} />
  if (name === 'notebook')
    return <icons.NoteBook height={height} width={width} />
  if (name === 'gradeBoy')
    return <icons.GradeBoy height={height} width={width} />
  if (name === 'gradeGirl')
    return <icons.GradeGirl height={height} width={width} />
  if (name === 'structureIcon')
    return <icons.StructureIcon height={height} width={width} />
  if (name === 'emptySchoolListIcon')
    return <icons.EmptySchoolListIcon height={height} width={width} />
  if (name === 'schoolIcon')
    return <icons.SchoolIcon height={height} width={width} />
  if (name === 'downloadIcon')
    return <icons.DownloadIcon height={height} width={width} />
  if (name === 'uploadIcon')
    return <icons.UploadIcon height={height} width={width} />
  if (name === 'utilsIcon')
    return <icons.UtilsIcon height={height} width={width} />
  if (name === 'emptyIcons')
    return <icons.EmptyIcon height={height} width={width} />
  if (name === 'UserIcons')
    return <UserGroupIcon stroke='currentColor' height={height} width={width} />
  if (name === 'rightArrow')
    return <icons.RightArrow height={height} width={width} />
  if (name === 'addUserIcons')
    return <UserPlusIcon stroke='currentColor' height={'1.5rem'} width={'1.5rem'} />
  if (name === 'chevronRight')
      return <icons.ChevronRight height={height} width={width} />
  if (name === 'chevronLeft')
    return <icons.ChevronLeft height={height} width={width} />
  if (name === 'file')
    return <icons.File height={height} width={width} />
  if (name === 'search')
    return <icons.Search height={height} width={width} />
  return null
})
Icon.displayName = 'Icon'
export default Icon
