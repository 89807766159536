import { AUTHSERVICE } from '../config'
import { INotebook } from './getNotebookByIdUuid'

interface IResults {
  status: number
  statusText: string
  data: {
    notebookPackage: IListNotebookPackage[]
    data: { notebook: INotebook[] }
    success: boolean
  }
}
export interface IListNotebookPackage {
  id: 10
  id_format: 1
  id_package: 8
  matter: ''
  quantity: null
  ext_picture: null
  ext_color: null
  ext_text: null
  ext_logo: null
  ext_binding: null
  ext_coverfinish: null
  ext_spinecolor: null
  int_sheets: null
  int_title: null
  int_striped: null
  int_guidepoint: null
  int_positionlogos: null
  int_interiorinks: null
  int_roundtips: null
  price: '0.00'
  special_price: '0.00'
  refill_time: 0
  return_percentage: 0
  provider: null
  provider_fee: false
  resource_free: false
  status: 1
  is_deleted: false
  createdAt: '2023-04-03T17:21:06.546Z'
  updatedAt: null
  status_name: 'Borrador'
}
const getPackageList = async (id: number): Promise<any> => {
  return await AUTHSERVICE().get(`/notebook/package/${id}`)
}

const getNotebookListInPackage = async (id: number) => {
  const results = await getPackageList(id)
  return results as IResults
}

const getPackageListNotebook = async (id: number): Promise<any> => {
  return await AUTHSERVICE().get(`/notebook/admin/`, {
    params: { idschool: id }
  })
}

const getNotebookListBySchoolId = async (id: number) => {
  const results = await getPackageListNotebook(id)
  return results as IResults
}

const getNotebooksPaginated = async (
  page: number,
  search: string = '',
  status?: number
): Promise<any> => {
  let url = `notebook/admin?page=${page}&sort=desc&order=id&search=${search}&limit=10`
  if (status) url += `&status=${status}`

  return await AUTHSERVICE().get(url)
}

export {
  getNotebookListInPackage,
  getNotebooksPaginated,
  getNotebookListBySchoolId
}
