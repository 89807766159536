import React from 'react'

const PantsLateralSvg= ({ className }: { className: string }) => {
return (
<svg
stroke='black'
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_pants" data-name="Outline pants"   d="m313.99,59.08s32.48-21.18,54.83-27.46c23.04-6.48,41.71-8.31,41.71-8.31,0,0,36.45-1.77,36.45-1.09s29.17,45.02,29.17,45.02c0,0,32.66,38.5,4.67,130.66-20.5,67.47-20.68,78.98-19.3,80.3,1.04,1,1.46,2.49,1.46,3.92.01,4.09-.65,9.51-4.33,8.9-5.83-.97,4.67,13.03-5.83,42.78-10.5,29.75-4.67,73.85-4.67,73.85l-1.17,70.8s-1.75,15.17.59,17.5c2.33,2.34,1.75,8.76,0,9.92-1.75,1.16-2.33,9.33,2.33,9.33s0,12.84,0,14.59-1.17,7.58,1.75,7c2.92-.59,2.33,233.89-3.5,240.9-5.83,7-75.83-4.08-75.83-4.08-3.5-11.08-6.41-219.32-11.67-230.4-5.25-11.08-3.5-16.91-1.75-20.41,1.75-3.5-2.92-25.67-6.41-29.75l-3.5-4.08s-12.25-43.16-11.08-51.91c1.17-8.75-9.91-233.9-9.91-233.9,0,0-13.71-143.17-14-144.06Z"/>
      <path id="Pretina"   d="m315.93,87.61s41.84-27.84,78-34.84c36.16-7,46.8-6.94,46.8-6.94l20.62-1.64-15.52-24.2s-40.05,1.09-63.48,5.71c-19.27,3.8-47.59,18.19-47.59,18.19l-21.92,12.96,3.08,30.75Z"/>
      <path id="División"   d="m384.2,55.09c3.33,11.6,17.44,65.16,13.31,125.99-4.67,68.83-6,179.27-6,179.27,0,0,5.34,108.88,6.84,138.04,1.49,29.17,11.99,171.49,11.99,171.49v107.38"/>
      <path id="Línea_1_lateral" data-name="Línea 1 lateral"   d="m391.09,52.77s18.67,59.5,14,128.32c-4.67,68.83-6,179.27-6,179.27,0,0,5.34,108.88,6.84,138.04,1.49,29.17,11.99,171.49,11.99,171.49v107.38"/>
      <path id="Línea_2_lateral" data-name="Línea 2 lateral"   d="m398.8,51.84c2.94,10.11,17.71,64.59,13.5,126.64-4.67,68.83-6,179.27-6,179.27,0,0,5.34,108.88,6.84,138.04,1.49,29.17,11.99,171.49,11.99,171.49v110.51"/>
      <g id="Costuras">
        <path   d="m314.74,67.01s53.7-26.89,69.97-30.48c17.96-3.97,66.02-8.7,66.02-8.7"/>
        <path   d="m316.23,77.74s54.73-27.9,71-31.49c17.95-3.97,69.78-8.7,69.78-8.7"/>
        <path   d="m370.71,755.9s38.71,4.62,42.91,4.34c4.2-.28,27.15,1.47,36.66-1.89"/>
        <path   d="m370.71,760.44s38.71,4.62,42.91,4.34c4.2-.28,27.15,1.47,36.66-1.89"/>
        <path   d="m388.11,70.44l-9.16,3.89s10.78,39.78,8.49,100.41c-.09,2.49-.29,12.81-.29,12.81l8.85.24"/>
      </g>
    </g>
  </g>
</svg>
)
}

export default PantsLateralSvg