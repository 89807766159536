import { Input, Select, Table, Typography } from 'antd'
import React, { memo } from 'react'
import { useBookTable } from './hook/useBookTable'
const BookTable = memo(() => {
  const {
    books,
    search,
    loading,
    pagination,
    BookColumn,
    statusCatalog,
    handleSearch,
    onHandleChangeStatus
  } = useBookTable()

  return (
    <div>
      <div>
        <Typography>Estatus</Typography>
        <Select
          style={{ width: 200 }}
          defaultValue={'todos'}
          placeholder={'Estatus'}
          onChange={onHandleChangeStatus}
          options={statusCatalog.filter((item: any) => item.label !== 'Nuevo')}
        />
        <br/>
        <br/>

        <Input.Search
          placeholder='Buscar'
          onChange={(e: any) => handleSearch(e.target.value)}
          value={search}
          enterButton
          size='large'
        />
      </div>
      <Table
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        dataSource={books}
        style={{ marginTop: '2.5rem' }}
        columns={BookColumn}
        loading={loading}
        pagination={pagination}
        onRow={() => {
          return {
            onClick: () => undefined
          }
        }}
      />
    </div>
  )
})
BookTable.displayName = 'BookTable'
export default BookTable
