import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare6mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 6mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_6mm_Completo'
              data-name='Path Cuadro 6mm Completo'
            >
              <g
                id='Path_Cuadro_6mm_Completo-2'
                data-name='Path Cuadro 6mm Completo'
              >
                <g
                  id='Cuadrado_completo'
                  data-name='Cuadrado completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='102.45' y1='152.43' x2='102.45' y2='622.51' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='670.06' y1='152.43' x2='670.06' y2='622.51' />
                  </g>
                  <line x1='119.11' y1='152.43' x2='119.11' y2='622.51' />
                  <line x1='135.77' y1='152.43' x2='135.77' y2='622.51' />
                  <line x1='152.43' y1='152.43' x2='152.43' y2='622.51' />
                  <line x1='169.29' y1='152.43' x2='169.29' y2='622.51' />
                  <line x1='185.92' y1='152.43' x2='185.92' y2='622.51' />
                  <line x1='202.6' y1='152.43' x2='202.6' y2='622.51' />
                  <line x1='219.26' y1='152.43' x2='219.26' y2='622.51' />
                  <line x1='235.92' y1='152.43' x2='235.92' y2='622.51' />
                  <line x1='252.58' y1='152.43' x2='252.58' y2='622.51' />
                  <line x1='269.44' y1='152.43' x2='269.44' y2='622.51' />
                  <line x1='286.1' y1='152.43' x2='286.1' y2='622.51' />
                  <line x1='302.75' y1='152.43' x2='302.75' y2='622.51' />
                  <line x1='319.41' y1='152.43' x2='319.41' y2='622.51' />
                  <line x1='336.07' y1='152.43' x2='336.07' y2='622.51' />
                  <line x1='352.73' y1='152.43' x2='352.73' y2='622.51' />
                  <line x1='369.59' y1='152.43' x2='369.59' y2='622.51' />
                  <line x1='386.22' y1='152.43' x2='386.22' y2='622.51' />
                  <line x1='402.92' y1='152.43' x2='402.92' y2='622.51' />
                  <line x1='419.58' y1='152.43' x2='419.58' y2='622.51' />
                  <line x1='436.24' y1='152.43' x2='436.24' y2='622.51' />
                  <line x1='452.9' y1='152.43' x2='452.9' y2='622.51' />
                  <line x1='469.76' y1='152.43' x2='469.76' y2='622.51' />
                  <line x1='486.39' y1='152.43' x2='486.39' y2='622.51' />
                  <line x1='503.07' y1='152.43' x2='503.07' y2='622.51' />
                  <line x1='519.73' y1='152.43' x2='519.73' y2='622.51' />
                  <line x1='536.39' y1='152.43' x2='536.39' y2='622.51' />
                  <line x1='553.05' y1='152.43' x2='553.05' y2='622.51' />
                  <line x1='569.91' y1='152.43' x2='569.91' y2='622.51' />
                  <line x1='586.56' y1='152.43' x2='586.56' y2='622.51' />
                  <line x1='603.22' y1='152.43' x2='603.22' y2='622.51' />
                  <line x1='619.88' y1='152.43' x2='619.88' y2='622.51' />
                  <line x1='636.54' y1='152.43' x2='636.54' y2='622.51' />
                  <line x1='653.2' y1='152.43' x2='653.2' y2='622.51' />
                  <line x1='85.23' y1='606.3' x2='686.92' y2='606.3' />
                  <line x1='85.23' y1='589.52' x2='686.92' y2='589.52' />
                  <line x1='85.23' y1='572.74' x2='686.92' y2='572.74' />
                  <line x1='85.23' y1='555.76' x2='686.92' y2='555.76' />
                  <line x1='85.23' y1='539' x2='686.92' y2='539' />
                  <line x1='85.23' y1='522.2' x2='686.92' y2='522.2' />
                  <line x1='85.23' y1='505.42' x2='686.92' y2='505.42' />
                  <line x1='85.23' y1='488.64' x2='686.92' y2='488.64' />
                  <line x1='85.23' y1='471.86' x2='686.92' y2='471.86' />
                  <line x1='85.23' y1='454.88' x2='686.92' y2='454.88' />
                  <line x1='85.23' y1='438.1' x2='686.92' y2='438.1' />
                  <line x1='85.23' y1='421.32' x2='686.92' y2='421.32' />
                  <line x1='85.23' y1='404.54' x2='686.92' y2='404.54' />
                  <line x1='85.23' y1='387.76' x2='686.92' y2='387.76' />
                  <line x1='85.23' y1='370.98' x2='686.92' y2='370.98' />
                  <line x1='85.23' y1='354' x2='686.92' y2='354' />
                  <line x1='85.23' y1='337.24' x2='686.92' y2='337.24' />
                  <line x1='85.23' y1='320.43' x2='686.92' y2='320.43' />
                  <line x1='85.23' y1='303.65' x2='686.92' y2='303.65' />
                  <line x1='85.23' y1='286.87' x2='686.92' y2='286.87' />
                  <line x1='85.23' y1='270.09' x2='686.92' y2='270.09' />
                  <line x1='85.23' y1='253.1' x2='686.92' y2='253.1' />
                  <line x1='85.23' y1='236.35' x2='686.92' y2='236.35' />
                  <line x1='85.23' y1='219.55' x2='686.92' y2='219.55' />
                  <line x1='85.23' y1='202.77' x2='686.92' y2='202.77' />
                  <line x1='85.23' y1='185.99' x2='686.92' y2='185.99' />
                  <line x1='85.23' y1='169.21' x2='686.92' y2='169.21' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m679.07,162.83c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,178.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,193.21c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,228.79c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,213.04c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,243.42c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,279c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,263.25c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,293.63c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,329.21c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,313.46c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,343.84c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,379.42c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,363.67c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,394.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,429.63c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,413.89c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,444.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,479.85c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,464.1c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,494.48c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,530.06c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,514.31c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,544.69c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,580.27c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m679.07,564.52c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,594.9c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,614.73c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m679.07,196.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,210.46c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,162.83c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,176.97c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,229.8c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,243.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,263.29c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,277.43c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,296.77c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,310.91c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,330.26c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,344.4c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,363.74c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,377.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,397.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,411.37c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,430.71c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,444.85c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,464.2c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,478.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,497.68c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,511.82c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,531.17c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,545.31c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,564.65c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,578.79c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m679.07,598.14c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.22,611.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M94.46,162.83A1.49,1.49,0,1,0,93,161.34a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,178.58A1.49,1.49,0,1,0,93,177.09a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,193.21a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,228.79A1.49,1.49,0,1,0,93,227.3a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,213A1.49,1.49,0,1,0,93,211.55,1.49,1.49,0,0,0,94.46,213' />
                                    <path d='M95.31,243.42a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22A15.54,15.54,0,0,1,95.66,244a12.63,12.63,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,279A1.49,1.49,0,1,0,93,277.51,1.49,1.49,0,0,0,94.46,279' />
                                    <path d='M94.46,263.25A1.49,1.49,0,1,0,93,261.76a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,293.63a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83A12.18,12.18,0,0,1,97.8,292l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,329.21A1.49,1.49,0,1,0,93,327.72a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,313.46A1.49,1.49,0,1,0,93,312a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,343.84a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,379.42A1.49,1.49,0,1,0,93,377.93a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,363.67A1.49,1.49,0,1,0,93,362.18a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,394.06a12.72,12.72,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a10.76,10.76,0,0,0-2.34,1.87' />
                                    <path d='M94.46,429.63A1.49,1.49,0,1,0,93,428.14a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,413.89a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M95.31,444.27a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,479.85a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M94.46,464.1A1.5,1.5,0,1,0,93,462.61a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,494.48a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,530.06A1.5,1.5,0,1,0,93,528.57a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,514.31A1.49,1.49,0,1,0,93,512.82a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,544.69a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,580.27A1.49,1.49,0,1,0,93,578.78a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,564.52A1.49,1.49,0,1,0,93,563a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,594.9a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,614.73A1.49,1.49,0,1,0,93,613.24a1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M94.46,196.32A1.5,1.5,0,1,0,93,194.83a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,210.46a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,162.83A1.49,1.49,0,1,0,93,161.34a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,177a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23A11,11,0,0,0,95.31,177' />
                                    <path d='M94.46,229.8A1.49,1.49,0,1,0,93,228.31a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,243.94a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,263.29A1.5,1.5,0,1,0,93,261.8a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,277.43a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22A15.54,15.54,0,0,1,95.66,278a12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,296.77A1.49,1.49,0,1,0,93,295.28a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,310.91a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,330.26A1.5,1.5,0,1,0,93,328.77a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,344.4a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22A15.54,15.54,0,0,1,95.66,345a12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,363.74A1.49,1.49,0,1,0,93,362.25a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,377.88a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,397.23A1.5,1.5,0,1,0,93,395.74a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,411.37a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22A15.54,15.54,0,0,1,95.66,412a12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,430.71A1.49,1.49,0,1,0,93,429.22a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,444.85a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,464.2A1.5,1.5,0,1,0,93,462.71a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,478.34a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,497.68A1.49,1.49,0,1,0,93,496.19a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,511.82a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,531.17A1.5,1.5,0,1,0,93,529.68a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,545.31a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,564.65A1.49,1.49,0,1,0,93,563.16a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,578.79a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,598.14A1.5,1.5,0,1,0,93,596.65a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,611.27a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.18,12.18,0,0,1,2.14-2.23l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 6mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_6mm_Datos' data-name='Path Cuadro 6mm Datos'>
              <g id='Path_Cuadro_6mm_Datos-2' data-name='Path Cuadro 6mm Datos'>
                <g
                  id='Cuadrado_Datos'
                  data-name='Cuadrado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='102.45' y1='196.56' x2='102.45' y2='616.83' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='670.06' y1='196.56' x2='670.06' y2='616.83' />
                  </g>
                  <line x1='119.11' y1='196.56' x2='119.11' y2='616.83' />
                  <line x1='135.77' y1='196.56' x2='135.77' y2='616.83' />
                  <line x1='152.43' y1='196.56' x2='152.43' y2='616.83' />
                  <line x1='169.29' y1='196.56' x2='169.29' y2='616.83' />
                  <line x1='185.92' y1='196.56' x2='185.92' y2='616.83' />
                  <line x1='202.6' y1='196.56' x2='202.6' y2='616.83' />
                  <line x1='219.26' y1='196.56' x2='219.26' y2='616.83' />
                  <line x1='235.92' y1='196.56' x2='235.92' y2='616.83' />
                  <line x1='252.58' y1='196.56' x2='252.58' y2='616.83' />
                  <line x1='269.44' y1='196.56' x2='269.44' y2='616.83' />
                  <line x1='286.1' y1='196.56' x2='286.1' y2='616.83' />
                  <line x1='302.75' y1='196.56' x2='302.75' y2='616.83' />
                  <line x1='319.41' y1='196.56' x2='319.41' y2='616.83' />
                  <line x1='336.07' y1='196.56' x2='336.07' y2='616.83' />
                  <line x1='352.73' y1='196.56' x2='352.73' y2='616.83' />
                  <line x1='369.59' y1='196.56' x2='369.59' y2='616.83' />
                  <line x1='386.22' y1='196.56' x2='386.22' y2='616.83' />
                  <line x1='402.92' y1='196.56' x2='402.92' y2='616.83' />
                  <line x1='419.58' y1='196.56' x2='419.58' y2='616.83' />
                  <line x1='436.24' y1='196.56' x2='436.24' y2='616.83' />
                  <line x1='452.9' y1='196.56' x2='452.9' y2='616.83' />
                  <line x1='469.76' y1='196.56' x2='469.76' y2='616.83' />
                  <line x1='486.39' y1='196.56' x2='486.39' y2='616.83' />
                  <line x1='503.07' y1='196.56' x2='503.07' y2='616.83' />
                  <line x1='519.73' y1='196.56' x2='519.73' y2='616.83' />
                  <line x1='536.39' y1='196.56' x2='536.39' y2='616.83' />
                  <line x1='553.05' y1='196.56' x2='553.05' y2='616.83' />
                  <line x1='569.91' y1='196.56' x2='569.91' y2='616.83' />
                  <line x1='586.56' y1='196.56' x2='586.56' y2='616.83' />
                  <line x1='603.22' y1='196.56' x2='603.22' y2='616.83' />
                  <line x1='619.88' y1='196.56' x2='619.88' y2='616.83' />
                  <line x1='636.54' y1='196.56' x2='636.54' y2='616.83' />
                  <line x1='653.2' y1='196.56' x2='653.2' y2='616.83' />
                  <line x1='85.23' y1='599.89' x2='686.92' y2='599.89' />
                  <line x1='85.23' y1='583.13' x2='686.92' y2='583.13' />
                  <line x1='85.23' y1='566.33' x2='686.92' y2='566.33' />
                  <line x1='85.23' y1='549.55' x2='686.92' y2='549.55' />
                  <line x1='85.23' y1='532.77' x2='686.92' y2='532.77' />
                  <line x1='85.23' y1='515.99' x2='686.92' y2='515.99' />
                  <line x1='85.23' y1='499.01' x2='686.92' y2='499.01' />
                  <line x1='85.23' y1='482.23' x2='686.92' y2='482.23' />
                  <line x1='85.23' y1='465.45' x2='686.92' y2='465.45' />
                  <line x1='85.23' y1='448.67' x2='686.92' y2='448.67' />
                  <line x1='85.23' y1='431.89' x2='686.92' y2='431.89' />
                  <line x1='85.23' y1='415.11' x2='686.92' y2='415.11' />
                  <line x1='85.23' y1='398.13' x2='686.92' y2='398.13' />
                  <line x1='85.23' y1='381.38' x2='686.92' y2='381.38' />
                  <line x1='85.23' y1='364.56' x2='686.92' y2='364.56' />
                  <line x1='85.23' y1='347.78' x2='686.92' y2='347.78' />
                  <line x1='85.23' y1='331' x2='686.92' y2='331' />
                  <line x1='85.23' y1='314.22' x2='686.92' y2='314.22' />
                  <line x1='85.23' y1='297.24' x2='686.92' y2='297.24' />
                  <line x1='85.23' y1='280.48' x2='686.92' y2='280.48' />
                  <line x1='85.23' y1='263.68' x2='686.92' y2='263.68' />
                  <line x1='85.23' y1='246.9' x2='686.92' y2='246.9' />
                  <line x1='85.23' y1='230.12' x2='686.92' y2='230.12' />
                  <line x1='85.23' y1='213.34' x2='686.92' y2='213.34' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m678.74,207.96c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,223.71c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,238.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,273.92c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,258.17c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,288.55c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,324.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,308.38c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,338.76c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,374.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,358.59c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,388.97c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,424.55c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,408.8c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,439.18c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,474.76c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,459.01c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,489.39c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,524.97c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,509.22c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,539.6c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,575.18c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m678.74,559.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,589.81c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,609.64c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m678.74,241.44c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,255.59c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,207.96c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,222.1c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,274.93c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,289.07c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,308.41c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,322.56c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,341.9c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,356.04c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,375.38c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,389.53c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,408.87c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,423.01c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,442.35c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,456.5c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,475.84c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,489.98c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,509.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,523.47c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,542.81c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,556.95c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,576.29c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.89,590.44c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m678.74,609.78c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M94.46,208A1.49,1.49,0,1,0,93,206.47,1.49,1.49,0,0,0,94.46,208' />
                                    <path d='M94.46,223.71A1.49,1.49,0,1,0,93,222.22a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,238.34a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.29a6.43,6.43,0,0,1,.26.7,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,273.92A1.49,1.49,0,1,0,93,272.43a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,258.17A1.49,1.49,0,1,0,93,256.68a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,288.55a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69,5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,324.13A1.49,1.49,0,1,0,93,322.64a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,308.38A1.49,1.49,0,1,0,93,306.89a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,338.76a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,374.34A1.49,1.49,0,1,0,93,372.85a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,358.59A1.49,1.49,0,1,0,93,357.1a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,389a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22A10.69,10.69,0,0,0,95.31,389' />
                                    <path d='M94.46,424.55A1.49,1.49,0,1,0,93,423.06a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,408.8A1.49,1.49,0,1,0,93,407.31a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,439.18a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,474.76A1.49,1.49,0,1,0,93,473.27a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,459A1.49,1.49,0,1,0,93,457.52,1.49,1.49,0,0,0,94.46,459' />
                                    <path d='M95.31,489.39a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22A15.54,15.54,0,0,1,95.66,490a12.63,12.63,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,525A1.49,1.49,0,1,0,93,523.48,1.49,1.49,0,0,0,94.46,525' />
                                    <path d='M94.46,509.22A1.49,1.49,0,1,0,93,507.73a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,539.6a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.12.29a5.77,5.77,0,0,1,.26.69,6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83A12.18,12.18,0,0,1,97.8,538l-.15-.23a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,575.18A1.49,1.49,0,1,0,93,573.69a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M94.46,559.43A1.49,1.49,0,1,0,93,557.94a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,589.81a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.12.29a5.18,5.18,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11,11,0,0,0-2.34,1.86' />
                                    <path d='M94.46,609.64A1.49,1.49,0,1,0,93,608.15a1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M94.46,241.44A1.49,1.49,0,1,0,93,240a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,255.59a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82A12,12,0,0,1,97.8,254l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,208A1.49,1.49,0,1,0,93,206.47,1.49,1.49,0,0,0,94.46,208' />
                                    <path d='M95.31,222.1a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,274.93A1.49,1.49,0,1,0,93,273.44a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,289.07a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22a10.69,10.69,0,0,0-2.34,1.86' />
                                    <path d='M94.46,308.41A1.49,1.49,0,1,0,93,306.92a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,322.56a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,341.9A1.49,1.49,0,1,0,93,340.41a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,356a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22A10.69,10.69,0,0,0,95.31,356' />
                                    <path d='M94.46,375.38A1.49,1.49,0,1,0,93,373.89a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,389.53a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,408.87A1.49,1.49,0,1,0,93,407.38a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,423a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22A10.69,10.69,0,0,0,95.31,423' />
                                    <path d='M94.46,442.35A1.49,1.49,0,1,0,93,440.86a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,456.5a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,475.84A1.49,1.49,0,1,0,93,474.35a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,490a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3a5.77,5.77,0,0,1,.26.69c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22A10.69,10.69,0,0,0,95.31,490' />
                                    <path d='M94.46,509.32A1.49,1.49,0,1,0,93,507.83a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,523.47a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,542.81A1.49,1.49,0,1,0,93,541.32a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,557a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.12.3A5.77,5.77,0,0,1,93,561c.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.14-2.24l-.15-.22A10.69,10.69,0,0,0,95.31,557' />
                                    <path d='M94.46,576.29A1.49,1.49,0,1,0,93,574.8a1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M95.31,590.44a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.12.29a5.74,5.74,0,0,1,.26.7,4.46,4.46,0,0,1,.57-.43l.33-.23A15.84,15.84,0,0,1,95.66,591a12,12,0,0,1,2.14-2.24l-.15-.23a11.07,11.07,0,0,0-2.34,1.87' />
                                    <path d='M94.46,609.78A1.49,1.49,0,1,0,93,608.29a1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 6mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_6mm_Completo'
                data-name='Margenes Cuadro 6mm Completo'
              >
                <g
                  id='Margenes_Cuadro_6mm_Completo-2'
                  data-name='Margenes Cuadro 6mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro 6mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_6mm_Datos'
                data-name='Margenes Cuadro 6mm Datos'
              >
                <g
                  id='Margenes_Cuadro_6mm_Datos-2'
                  data-name='Margenes Cuadro 6mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='419.27'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V602.75a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V602.75a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='419.27'
                      />
                      <path d='M685.92,197.56V615.83H86.23V197.56H685.92m1-1H85.23V616.83H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare6mmHorizontal
