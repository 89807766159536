import { AUTHSERVICE } from '../config'

export interface levelInterface {
  name: string
}

export const getAllUtilsList = async ({
  status,
  page = 1,
  search=''
}: {
  status?: string
  page?:number
  search?:string
}): Promise<any> => {
  let url = `/supply/general-list?limit=20&page=${page}`
  if (status) url += `&status=${status}`
  if (search) url += `&search=${search}`

  return await AUTHSERVICE().get(url)
}

export const getAllBooksList = async ({
  status,
  page = 1,
  search=''
}: {
  status?: string
  page?:number
  search?:string
}): Promise<any> => {
  let url = `/books/general-list?limit=20&page=${page}`
  if (status) url += `&status=${status}`
  if (search) url += `&search=${search}`

  return await AUTHSERVICE().get(url)
}

export const getAllNotebookList = async ({
  status,
  page = 1
}: {
  status?: string
  page?:number
}): Promise<any> => {
  let url = `/notebook/general-list?limit=20&page=${page}`
  if (status) url += `&status=${status}`
  return await AUTHSERVICE().get(url)
}

export const getAllOddsList = async ({
  status,
  page = 1
}: {
  status?: string
  page?:number
}): Promise<any> => {
  let url = `/quotas/general-list?limit=20&page=${page}`
  if (status) url += `&status=${status}`
  return await AUTHSERVICE().get(url)
}