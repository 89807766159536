import { getShadow } from 'pages/utils/uniforms/getShadows'
import React from 'react'

const ShadowsSportShirt = ({
  className,
  stroke = '#000000',
  fill = '#000000'
}: {
  className: string
  stroke: string
  fill?:string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Sombras' stroke={getShadow(fill)} fill={getShadow(fill)}>
        <g id='Sombras'>
          <path d='m46,314c14.7,10.9,23.08,14.49,37,19-1.11-73.1,22.2-95.23,35.42-164.21,13.36-69.74-38.55,64.31-72.42,145.21Z' />
          <path d='m164.76,205.8c-3.68-17.28-1.1-7.93,1.36,32.75,1.16,19.16,8.01,48.57,8.6,58.62,1.24,21.16-19.97-56.33-22.34-91.47-2.37-35.13-7.56-27.22-10.92-3.83-3.33,23.04,1.89,74.1,7.59,114.16,5.93,41.74.49,32.01-7.13,18.11-9.03-16.47-12.34-40.06-12.83-74.1-.48-34.07-2.84-30.74-6.17-8.78-3.11,20.5-13.69,60.22-2.81,97.23,3.52,1.51,7.08,3.02,11.15,4.51,11.88,4.3,19.48,3.71,24.04,4.09,6.81.57,18.66,12.6,20.7,7.89,2.82-6.52,5.76-9.98,10.54-16.87,5.43-7.84,5.69-10.75,7.39-16.63,1.69-5.88-5.5-17.88-7.02-34.73-.24-2.61-4.05-14.21-3.7-19.58-1.69-25.32-16.09-60.28-18.45-71.38Z' />
          <path d='m643.82,384.21c5.13-.36,24.55-11.08,40.19-18.96-11.42-18.72-4.88-19.78-8.75-46.89-5.17-36.14-10.46-16.73-10.46,14.64s-2.09,32.41-8.36,7.31c-6.27-25.09-5.22-49.13-4.18-74.22,1.04-25.09,6.27-47.04-2.09-33.45-8.36,13.6-12.55,32.41-14.63,55.41-2.09,22.99-7.32,24.05-10.45,11.49-5.62-22.45,13.89-19.64,1.34-42.64-8.07-14.8-5.01-1.61-1.86-36.1l3.91-18.64c-4.47,23.02-12.83,56.71-15.48,82.83-1.61,22.16-9.03,49.02-6,54,8.18,13.39,13.26,13.94,15.14,14.69,1.88.76,5.28,1.14,5.28,4.91s1.68,5.61,5.07,15.79c3.39,10.19,6.04,10.19,11.32,9.82Z' />
          <path d='m235.2,763.15c15.03,4.84,31.55,9.16,52.01,11.92,28.58-90.24,63.83-254.88,61.05-298.48-3.66-57.61-8.78,34.27-43.08,108.63-17.81,38.63-13.73,23.69-10.37-3.42,3.36-27.09,11.35-131.53,9.15-175.44-2.26-45.02-6.01-35.72-11.53,7.01-5.53,42.73-42.43,190.31-69.35,255.86-28.03,68.27-3.72,57.28,17.24,24.02,28.75-45.6,23.19-28.14-6.25,69.52.39.14.76.25,1.13.38Z' />
          <path d='m181.91,205.6c-22.99-70.04-12.54-58.54,0-29.27,12.51,29.17,20.55,71.15,44.53,108.72,14.02,21.95,4.29-3.37.43-18.82-4.18-16.72,2.67-10.35,8.37,1.05,6.27,12.54,22.47,41.03,32.4,48.09,32.41,22.99,28.98,28.99,5.22,19.85-27.17-10.45-25.08,5.23-29.27,67.95-4.39,65.93-27.18,189.22-20.91,78.41,6.27-110.81-9.81-181.7-40.77-275.98Z' />
          <path d='m252.8,413.1c1.04-53.32,7.98-36.38,10.45-10.45,6.28,65.86-5.23,152.62-26.14,206.98-20.9,54.35-37.22,25.31-18.42-30.1,18.83-55.4,33.51-135.83,34.11-166.42Z' />
          <path d='m579.57,726.93c-5.38-11.44-35.55-57.83-70.03-159.98-28.45-84.26-44.31-118.15-71.42-153.96-32.45-42.86-28.68-26.61-9.15,19.65,33.24,78.75,51.7,142.59,59.27,200.57,7.58,57.99,13.93,105.53,25.69,133.18,1.6,3.79,3.69,8.2,5.07,11.61,2-2,53.03-6.71,57-12,4.4-5.87,11-24,4.69-36.69-.37-.79-.74-1.57-1.12-2.38Z' />
          <path d='m599.68,535.28c-38.95-77.6-78.84-112.2-101.3-131.42-34.82-29.82-62.73-45-17.27,7.58,24.49,28.34,55.63,56.74,125.36,214.51-1.45-24.05-4-63.75-6-81.39-.36-3.14-.61-6.22-.79-9.27Z' />
          <path d='m632.98,145.64c14.46-40.26,27.13-61.5,4.9-24.62-22.24,36.89-38.65,96.26-44.46,127.48-10.19,54.8-18.36,76.91-42.11,86.88-20.78,8.74-25.09,12.54-11.69,17.92,24.74,9.91,30.59,13.88,34.12,18.68,23.31,31.75,18.37-12.17,23.76-57.24,5.38-45.07,19.04-123.39,35.48-169.12Z' />
        </g>
      </g>
    </svg>
  )
}

export default ShadowsSportShirt
