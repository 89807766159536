export interface UtilsPagination {
  total?:number,
  pages?:number,
  page?:number,
  limit?:number
}

export const InitUtilsPagination = {
  total:1,
  pages:1,
  page:1,
  limit:10
}

export interface Pagination {
  current: number
  pageSize: number
  total: number
  totalPages: number
}

export const InitialPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  totalPages: 1,
  showSizeChanger: false,
}

export const restructurePagination = (paginateInfo: any) => {
  let pagination = { ...InitialPagination }
  pagination.current = paginateInfo.page
  pagination.pageSize = paginateInfo.limit
  pagination.total = paginateInfo.total
  pagination.totalPages = paginateInfo.totalPages
  return pagination
}
