import React from 'react'
import { TFormFaldaOptions } from 'interfaces/uniforms-interface'
import { useDispatch, useSelector } from 'react-redux'
import { handleFaldaForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const FaldaOptionsEditable = () => {
  const { faldaForm } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormFaldaOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleFaldaForm({
        ...faldaForm,
        [keyName]: {
          ...faldaForm[keyName as TFormFaldaOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary handleChange={handleChange} form={faldaForm} />
        )
      })()}
    </div>
  )
}

export default FaldaOptionsEditable
