/* eslint-disable react-hooks/exhaustive-deps */
import { memo, KeyboardEvent, useEffect, useState } from 'react'
import { Col, Form, FormInstance, Row } from 'antd'
import FormInput from 'components/formInputs/input'
import styles from './formComments.module.scss'
import './reset.scss'
import Button from 'components/buttons/Button/Button'
import { ONLY_NUMBERS } from '../../../constants'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { Options } from 'types'
import FormRadio from 'components/formInputs/radio'
import { useLocation } from 'react-router-dom'
import FormAutoComplete from 'components/formInputs/autocomplete'
import { useEditorials } from 'hooks/books/editorial/useEditorials'
import Modals from 'components/modal'
import B from 'components/texts/B/B'
import ErrorAlert from 'assets/img/errorAlert.svg'

interface FormProps {
  loading?: boolean
  form: FormInstance<any>
  setFormFilledOut?: (flag: boolean) => void
  handleSubmit: (
    values: any,
    id: number,
    idBook: number,
    id_supply_school?: number,
    elseData?: any
  ) => Promise<any>
  fromUtils?: boolean
}

export const optionsFee: Options[] = [
  { label: 'Si', value: true },
  { label: 'No', value: false }
]

const FormCommentsModule = memo((props: FormProps) => {
  const { data } = useSelector((state: RootState) => state.comments)
  const { pathname } = useLocation()
  const [modalStatusFive, setModalStatusFive] = useState<boolean>(false)
  const [infoAuthorize, setInfoAuthorize] = useState<any>(null)
  // const isInUtiles = pathname?.includes('utiles') || false
  // const isInBooks = pathname?.includes('libros') || false
  const isStatusLT4 = (data && data?.status && data.status >= 4) || false
  const mustDisableForm = isStatusLT4 || false

  const {
    optionProvider,

    onHandleAutoCompleteProviders
  } = useEditorials()

  useEffect(() => {
    props?.form?.setFieldsValue(data)
    onFieldsChange()
  }, [data])

  const acceptSendAuthorize = () => {
    props.handleSubmit(
      infoAuthorize,
      data?.id || data?.id_supply,
      data.id_book,
      data?.id_supply_school,
      data
    )

    setModalStatusFive(false)
  }

  const onSubmit = async (values: any): Promise<any> => {
    if (data?.status === 5) {
      setModalStatusFive(true)
      setInfoAuthorize(values)
    } else {
      props.handleSubmit(
        values,
        data?.id || data?.id_supply,
        data.id_book,
        data?.id_supply_school,
        data
      )
    }
  }

  const onFieldsChange = () => {
    let formErrors: number = 0
    props.form.getFieldsError().forEach((it) => {
      formErrors += it.errors.length
    })
    let filledInputs = true
    const values = props.form.getFieldsValue()

    Object.entries(values).forEach((res) => {
      const value = res[1] as string
      const atribute = res[0] as string
      if (value === null || value === '') filledInputs = false
      if (atribute === 'price') {
        if (parseInt(value) < 0) filledInputs = false
      }
    })

    if (props.setFormFilledOut) props?.setFormFilledOut(filledInputs)
  }

  return (
    <>
      <Form
        className={styles.formBookComments}
        form={props?.form}
        layout='vertical'
        onFinish={onSubmit}
        onFieldsChange={onFieldsChange}
        autoComplete='off'
        disabled={mustDisableForm}
      >
        <Row gutter={32}>
          <Col span={12}>
            <FormInput
              hasFeedback={false}

              // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
              //   ONLY_NUMBERS(e)
              // }
              name='price'
              placeholder='$0.00'
              label='Precio'
              maxLength={13}
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                  message: 'Escribe solo números'
                }
              ]}
            />
          </Col>

          <Col span={12}>
            <FormInput
              // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
              //   ONLY_NUMBERS(e)
              // }
              hasFeedback={false}
              name={'special_price'}
              placeholder='$0.00'
              label='Precio especial'
              maxLength={13}
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                  message: 'Escribe solo números'
                }
              ]}
            />
          </Col>
        </Row>
        {!props.fromUtils && (
          <Row gutter={32}>
            <Col span={12}>
              <FormInput
              hasFeedback={false}

                // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                //   ONLY_NUMBERS(e)
                // }
                name='discount'
                placeholder='0%'
                label='% de descuento'
                maxLength={13}
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                    message: 'Escribe solo números'
                  }
                ]}
              />
            </Col>

            <Col span={12}>
              <FormInput
              hasFeedback={false}

                // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                //   ONLY_NUMBERS(e)
                // }
                name='return_percentage'
                placeholder='0%'
                label='% de devolución'
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                    message: 'Escribe solo números'
                  }
                ]}
              />
            </Col>
          </Row>
        )}
        <Row gutter={32}>
          <Col span={12}>
            <FormInput
              hasFeedback={false}

              onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                ONLY_NUMBERS(e)
              }
              name='refill_time'
              placeholder='0'
              label='Tiempo de resurtido'
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: 'Escribe solo números'
                }
              ]}
            />
          </Col>
          {props.fromUtils && (
            <Col span={12}>
              <FormInput
              hasFeedback={false}

                // onkeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                //   ONLY_NUMBERS(e)
                // }
                name='return_percentage'
                placeholder='0%'
                label='% de devolución'
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    pattern: new RegExp(/^(\d+(\.\d+)?)$/),
                    message: 'Escribe solo números'
                  }
                ]}
              />
            </Col>
          )}
          <Col span={12}>
            {pathname?.includes('utiles') ? (
              <FormInput
              hasFeedback={false}

                name='provider'
                placeholder='Proveedor'
                label='Proveedor'
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (_: any, value: string) => {
                      if (!value) return Promise.reject()
                      if (!value.trim()) return Promise.reject()
                      return Promise.resolve()
                    }
                  }
                ]}
              />
            ) : (
              <FormAutoComplete
              hasFeedback={false}

                name='provider'
                type={'text'}
                label='Proveedor'
                placeholder='Proveedor'
                options={optionProvider}
                rules={[{ required: true, message: '' }]}
                onSearch={onHandleAutoCompleteProviders}
              />
            )}
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormRadio
              name='provider_fee'
              label='Cuota de proveedor'
              options={optionsFee}
              initialValue={true}
              optionType='button'
              buttonStyle='solid'
              rules={[
                {
                  required: true,
                  message: 'Selecciona una opción'
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <FormRadio
              name='resource_free'
              label='Recurso gratuito'
              options={optionsFee}
              initialValue={true}
              optionType='button'
              buttonStyle='solid'
              rules={[
                {
                  required: true,
                  message: 'Selecciona una opción'
                }
              ]}
            />
          </Col>
        </Row>
        <div className={styles.btn}>
          <Button
            //   isLoading={props.loading}
            id={'save-books-comment-info'}
            sector='primary'
            size='m'
            buttonType='submit'
            rounded
            disabled={mustDisableForm}
          >
            Guardar
          </Button>
        </div>
      </Form>

      <Modals
        title='Aviso'
        open={modalStatusFive}
        centered
        onCancel={() => {
          setModalStatusFive(false)
        }}
      >
        <>
          <div className={styles.bodyEndList}>
            <img src={ErrorAlert} alt='Terminar lista' />
            <B size='Light'>
              ¿Estás seguro que deseas actualizar la información a un libro
              autorizado?
            </B>
          </div>
          <div className={styles.button}>
            <Button
              rounded
              onClick={acceptSendAuthorize}
              label={'Aceptar'}
              sector='secondary'
            />
          </div>
        </>
      </Modals>
    </>
  )
})

export default FormCommentsModule
