import React from 'react'
import { IconProps } from '../../../types'
export const UtilsIcon = ({
  height = 23,
  width = 23,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4 5.69277C14.4 5.31394 14.5984 4.97152 14.8279 4.67011C15.0635 4.36068 15.2 3.99364 15.2 3.60004C15.2 2.49547 14.1255 1.60004 12.8 1.60004C11.4745 1.60004 10.4 2.49547 10.4 3.60004C10.4 3.99364 10.5364 4.36068 10.772 4.67011C11.0015 4.97152 11.2 5.31394 11.2 5.69277V5.69277C11.2 6.07813 10.8844 6.38933 10.4992 6.37824C9.00199 6.33512 7.52087 6.22771 6.05853 6.0587C6.25732 7.77898 6.37084 9.52523 6.39476 11.2931C6.40005 11.6835 6.0831 12 5.69271 12V12C5.31388 12 4.97146 11.8016 4.67005 11.5721C4.36062 11.3365 3.99357 11.2 3.59998 11.2C2.49541 11.2 1.59998 12.2746 1.59998 13.6C1.59998 14.9255 2.49541 16 3.59998 16C3.99357 16 4.36062 15.8636 4.67005 15.628C4.97146 15.3985 5.31388 15.2 5.69271 15.2V15.2C6.02251 15.2 6.28431 15.4782 6.26014 15.8071C6.1259 17.6333 5.89589 19.4328 5.5751 21.2007C7.19419 21.4034 8.83679 21.5305 10.4992 21.5784C10.8845 21.5895 11.2001 21.2782 11.2001 20.8928V20.8928C11.2001 20.514 11.0016 20.1716 10.7721 19.8702C10.5365 19.5607 10.4001 19.1937 10.4001 18.8001C10.4001 17.6955 11.4746 16.8001 12.8001 16.8001C14.1256 16.8001 15.2001 17.6955 15.2001 18.8001C15.2001 19.1937 15.0637 19.5607 14.828 19.8702C14.5986 20.1716 14.4001 20.514 14.4001 20.8928V20.8928C14.4001 21.2479 14.6967 21.5314 15.0511 21.5106C17.0125 21.3953 18.9442 21.1697 20.8401 20.8397C21.1275 19.1882 21.3358 17.5096 21.4609 15.8079C21.4851 15.4786 21.223 15.2001 20.8928 15.2001V15.2001C20.514 15.2001 20.1716 15.3985 19.8702 15.628C19.5607 15.8636 19.1937 16.0001 18.8001 16.0001C17.6955 16.0001 16.8001 14.9256 16.8001 13.6001C16.8001 12.2746 17.6955 11.2001 18.8001 11.2001C19.1937 11.2001 19.5607 11.3365 19.8702 11.5721C20.1716 11.8016 20.514 12.0001 20.8928 12.0001V12.0001C21.2836 12.0001 21.6009 11.6833 21.5956 11.2926C21.5694 9.35911 21.436 7.45147 21.2012 5.57548C19.1896 5.94047 17.137 6.18793 15.0509 6.31049C14.6965 6.33132 14.4 6.04782 14.4 5.69277V5.69277Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
