import { SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSupplyToCatModal } from 'redux/reducers/addSupplyModal'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { RootState } from 'redux/store/store'
import { addUtilToCatalog } from 'services/utilsServices/utils'
import { handleErrors } from 'utilities/handleErrors'

export function useAddSupToCatModal() {
  useEffect(() => {
    return () => {
      dispatch(setSupplyToCatModal(false))
    }
  }, [])

  const dispatch = useDispatch()
  const { data } = useSelector((state: RootState) => state.comments)
  const closeSidebar = () => {
    dispatch(setopenComments(false))
    dispatch(setCommentsObjectInformation({}))
  }
  const [loading, setloading] = useState(false)

  const handleCloseModal = () => {
    dispatch(setSupplyToCatModal(false))
    closeSidebar()
  }

  const handleEndList = async () => {
    setloading(true)
    const id = data.id_supply_school ?? data.id
    try {
      const body2 = {
        id,
        is_active: true
      }
      await addUtilToCatalog(body2)
      SuccessToast('El útil fue agregado al catalogo satisfactoriamente')
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloading(false)
      setTimeout(() => {
        handleCloseModal()
      }, 500)
    }
  }

  return {
    loading,
    handleEndList,
    handleCloseModal
  }
}
