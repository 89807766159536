import React, { MouseEventHandler, useEffect, useState } from 'react'
import style from './list.module.scss'
import {
  Col,
  List,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Checkbox,
  Tooltip
} from 'antd'
import Button from 'components/buttons/Button/Button'
import Collapse from 'components/Collapse/Collapse'
import {
  getUtilsByPackageId,
  terminatePackageUpdate,
  updateUtilsStatus,
  UpdateUtilsStatusInterface
} from 'services/utilsServices/utils'
import { IPackageDetails } from 'interfaces/utils-interface'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { ColumnsType } from 'antd/es/table'
import B from 'components/texts/B/B'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import PackageActionsElements from './PackageActionsElements'
import { handleErrors } from 'utilities/handleErrors'
import { SuccessToast } from 'components/Toasts/Toasts'
import ErrorAlert from 'assets/img/errorAlert.svg'
import globalStyle from 'styles/global.module.scss'
import { setStatus } from 'hooks/books/useBooks'
import Tag from 'components/tag'
import { TrashIcon } from '@heroicons/react/24/outline'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import UpdateInput from 'components/formInputs/UpdateInput/UpdateInput'
import { RootState } from 'redux/store/store'
import { getGeneralModulesStatus } from 'services/genericServices/genericServices'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import { useLocation } from 'react-router-dom'

const ItemsTable = ({
  packageData,
  requestChanges,
  deletePackageById,
  loadingModal,
  updatePackageById,
  removeItemFromPackage
}: {
  requestChanges?: any
  packageData: any
  deletePackageById: any
  loadingModal: boolean
  updatePackageById: any
  itemsList: any
  getItemsList: any
  LoadingList: boolean
  removeItemFromPackage: any
}) => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [listPackage, setListPackage] = useState<IPackageDetails[]>([])
  const [autolistPackage, setAutoListPackage] = useState<IPackageDetails[]>([])
  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setopenDeleteModal] = useState(false)
  const [AuthorizedModal, setAuthorizedModal] = useState(false)
  const [loadingTerminate, setLoadingTerminate] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState<any|null>(null)
  const [showImageModal, setShowImageModal] = useState(false)

  const { sendAction } = useSelector((state: RootState) => state.books)
  const { name, id, description } = packageData

  const utilsTitle = [
    {
      0: (
        <Checkbox
          onChange={(e) =>
            checkAllToAuto(e)
          } /* indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}  */
        />
      ),
      1: 'Nombre del material',
      2: 'Marca',
      3: 'Cantidad',
      4: 'Precio'
    }
  ]

  const columns: ColumnsType<IPackageDetails> = [
    {
      title: 'Imagen',
      dataIndex: 'picture',
      key: 'picture',
      render: (_, record: any) => (
        <span style={{cursor:'pointer'}}
          onClick={(e:any) => {
            e?.stopPropagation()
            setSelectedRecord(record)
          }}>
          <img width='90' height='90' src={record?.picture} />
        </span>
      )
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <B>{text}</B>
    },
    {
      title: 'Marca',
      dataIndex: 'make',
      key: 'make'
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: string, field: any) => {
        return <UpdateInput data={field} packageId={id} />
      }
    },
    {
      title: 'Presentacion',
      dataIndex: 'presentation',
      key: 'presentation'
    },
    {
      title: 'Precio',
      key: 'price',
      render: (text: string, field: any) => {
        let money = field.price
        if (field.special_price !== '0.00' && field.special_price !== null)
          money = field.special_price
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(money)
      }
    },
    {
      title: 'Status',
      key: 'action',
      render: (_, {status, last_comment, edition}:any) => {
        let title = setStatus(status)
            if (title === 'Con observaciones' && last_comment)
              title = `${title} de ${last_comment}`

            if (uid.role === 'Ezetera' && !edition && status === 4)
              title += ' datos incompletos'

        
        return (
          <Space size='middle'>
            <Tag variation={status} title={title} />
          </Space>
        )
      }
    },
    {
      render: (_, item) => {
        if (uid.role === 'Director') return null
        if (!packageData.open && parseInt(item.status) !== 3) return null
        /*  if (parseInt(item.status) === 1 || parseInt(item.status) === 3) */
        return (
          <Tooltip title='Eliminar útil'>
            <div>
              <TrashIcon
                className={`${style['iconStyle']} ${style['trash-icon']}`}
                onClick={(e) => {
                  e.stopPropagation()
                  removeItemFromPackage(item, packageData)
                }}
              />
            </div>
          </Tooltip>
        )
      }
    }
  ]

  useEffect(() => {
    if (listPackage.length == 0) return
    const packageId = listPackage[0]?.id_package
    if (!packageId) return
    getUtilsByPackage(packageId)
  }, [sendAction])

  useEffect(()=>{
    setShowImageModal(selectedRecord != null)
  }, [selectedRecord])

  const getUtilsByPackage = (id: number) => {
    setLoading(true)
    getUtilsByPackageId(id)
      .then((data) => {
        const list = data.data.details
        setListPackage(list)
        const enableList = list.filter((res: any) => parseInt(res.status) === 4)
        setAutoListPackage(
          enableList.map((res: IPackageDetails) => {
            return { ...res, check: false }
          })
        )
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        handleErrors(error)
      })
  }

const getPDFListLogic = async() =>{
  try {
    const result = await getGeneralModulesStatus()
    if (pathname.includes('utiles') && result.data.supplies_status === 'Completado')
    dispatch(setCloseSupplyList(true))
  } catch (error) {
    console.log('error get pdf list',error);
  }
}

  const updateUtilsStatusOnTerminate = async () => {
    setLoadingTerminate(true)
    const data: { id: number[]; status: number } = {
      // eslint-disable-next-line array-callback-return
      id: listPackage.map((res: any) => {
        if (res.status === 1) return res.id_supply_school
      }),
      status: 2
    }
    try {
      if (data.id.length > 0) await updateUtilsStatus({ data })
      await terminatePackageUpdate(id)
      const button = document.getElementById('get-packages-again')
      button?.click()
      SuccessToast('El paquete se ha cerrado correctamente')
      getPDFListLogic()
    } catch (err: any) {
      //console.log('err', err)
      handleErrors(err)
    } finally {
      setLoadingTerminate(false)
    }
  }

  const openDrawComments = (record: any) => {
    dispatch(setopenComments({ openComments: true }))
    dispatch(setCommentsObjectInformation(record))
  }

  const validateIsOneEnable = () => {
    let available = false
    available = listPackage.some((res) => parseInt(res.status) > 3)
    if (packageData.open) available = false
    return available
  }
 /*  const validateIsOneAuto = () => {
    let available = false
    available = listPackage.some((res) => parseInt(res.status) === 5)
    if (packageData.open) available = false
    return available
  } */
  //console.log("list",listPackage);

  const checkIfAllAreAuto = () => {
    let allAuto = true
    if (listPackage.length === 0) allAuto = false

    listPackage.forEach((res) => {
      if (parseInt(res.status) !== 5) allAuto = false
    })
    if (packageData.open) allAuto = false
    return allAuto
  }
  const checkSupplyToAuto = (key: number) => {
    const newArray = structuredClone(autolistPackage)
    newArray[key].check = !autolistPackage[key].check
    setAutoListPackage(newArray)
  }

  const checkAllToAuto = (e: any) => {
    const newArray = autolistPackage.map((res) => {
      return { ...res, check: e.target.checked }
    })
    setAutoListPackage(newArray)
  }

  const authorizeUtils = async (data: UpdateUtilsStatusInterface) => {
    try {
      await updateUtilsStatus({ data })
      const button = document.getElementById('get-packages-again')
      button?.click()
      SuccessToast('Los utiles fueron autorizados correctamente')
      getPDFListLogic()
    } catch (error) {
      handleErrors(error)
    }
  }

  const sentToAuthorize = (type?: string) => {
    let ids: number[] = []
    if (type === 'all') {
      autolistPackage.forEach((res) => {
        if (res.id_supply_school) ids.push(res.id_supply_school)
      })
    } else {
      autolistPackage.forEach((res) => {
        if (res.check && res.id_supply_school) ids.push(res.id_supply_school)
      })
    }
    authorizeUtils({
      status: 5,
      id: ids
    })
  }

  const handleCloseAutoModal = () => {
    setAuthorizedModal(false)
  }

  const totalPackageValue = () => {
    let total = listPackage.reduce((accumulator, currentValue: any) => {
      let price = currentValue.price
      if (parseFloat(currentValue.special_price) > 0)
        price = currentValue.special_price
      return accumulator + parseFloat(price) * parseInt(currentValue.quantity)
    }, 0)

    return total.toFixed(2)
  }
  const handleRequestChanges = () => {
    requestChanges(packageData.id, handleCloseAutoModal)
  }
  return (
    <Collapse
      id={id}
      title={name}
      customStyle={`${style['descriptionstyle']}`}
      customBg={`${style['custom-bg']}`}
      showMiddleText
      subTitle={description}
      isDroped={(dat) => {
        getUtilsByPackage(id)
      }}
      collapseType='package'
      customSideElement={
        <PackageActionsElements
          packageData={packageData}
          deletePackageById={deletePackageById}
          loadingModal={loadingModal}
          updatePackageById={updatePackageById}
          listPackage={listPackage}
          validateIsOneEnable={validateIsOneEnable}
          checkIfAllAreAuto={checkIfAllAreAuto}
          setAuthorizedModal={setAuthorizedModal}
          /* validateIsOneAuto={validateIsOneAuto} */
          handleRequestChanges={handleRequestChanges}
        />
      }
    >
      <button
        style={{ display: 'none' }}
        id={`get-items-list-${id}`}
        onClick={() => getUtilsByPackage(id)}
      >
        getItemList
      </button>
      {loading ? (
        <div className={`${style['spin-center']}`}>
          <Spin tip='Cargando' />
        </div>
      ) : (
        <>
          {listPackage.length !== 0 ? (
            <div className={`${style['padding-inline']}`}>
              <br></br>
              <Row justify='space-between' align={'middle'}>
                <Col>
                  <B>
                    Valor total del paquete:{' '}
                    <strong>$ {totalPackageValue()}</strong>
                  </B>
                </Col>
                {/* {validateIsOneEnable() && (
                  <Col>
                    <Button
                      label={
                        checkIfAllAreAuto()
                          ? 'Solicitar modificación'
                          : 'Autorizar'
                      }
                      rounded
                      size='m'
                      sector='tertiary'
                      onClick={() => setAuthorizedModal(true)}
                    />
                  </Col>
                )} */}
              </Row>
              <br></br>

              <Table
                onRow={(dat) => {
                  return {
                    onClick: () => openDrawComments(dat)
                  }
                }}
                columns={columns}
                dataSource={listPackage}
              />
              {packageData.open && uid.role !== 'Director' && (
                <div className={`${style['footer-bar']}`}>
                  <Button
                    label='Terminar paquete'
                    rounded
                    sector='secondary'
                    background='white'
                    onClick={() => setopenDeleteModal(true)}
                  />
                </div>
              )}
            </div>
          ) : (
            <EmptyListMessage
              iconHeight={40}
              iconWidth={40}
              icon='utilsIcon'
              text='Aún no has agregado utiles a la lista'
              buttonProps={[
                {
                  label: 'Asignar recurso',
                  onClick: () => {
                    const button = document.getElementById(
                      `add-items-list-modal-${packageData.id}`
                    )
                    button?.click()
                  },
                  rounded: true,
                  sector: 'tertiary',
                  background: 'white',
                  size: 'm'
                }
              ]}
            />
          )}
        </>
      )}

      <Modal
        title={`Terminar Paquete`}
        open={openDeleteModal}
        centered
        className={globalStyle.confirmModal}
        onCancel={() => setopenDeleteModal(false)}
        footer={
          <div className={globalStyle.confirmModalFooter}>
            <Button
              rounded
              isLoading={loadingTerminate}
              onClick={updateUtilsStatusOnTerminate}
              label={'Terminar'}
              sector='secondary'
            />
          </div>
        }
      >
        <div className={globalStyle.confirmModalBody}>
          <img src={ErrorAlert} alt='descargar' />
          <B size='Light'>
            ¿Estás seguro que deseas terminar este paquete?, No podrás editar o
            borrar dicho paquete, tampoco tendras permitido agregar más útiles
            en el.
          </B>
        </div>
      </Modal>

      <Modal
        title='Imagen del recurso'
        open={showImageModal}
        centered
        className={globalStyle.confirmModal}
        onCancel={() => {setShowImageModal(false); setSelectedRecord(null)}}
        width={600}
        footer={
          <div
            style={{ gap: '6px' }}
            className={globalStyle.confirmModalFooter}
          >
            <Button
              rounded
              isLoading={loadingTerminate}
              onClick={() => {setShowImageModal(false); setSelectedRecord(null)}}
              label='Cerrar'
              sector='tertiary'
            />
          </div>
        }
      >
        <div>
          <img src={selectedRecord?.picture} style={{width:'100%', height:'100%'}} />
        </div>
      </Modal>

      <Modal
        title={
          checkIfAllAreAuto() ? 'Solicitar modificación' : `Autorizar paquete`
        }
        open={AuthorizedModal}
        centered
        className={globalStyle.confirmModal}
        onCancel={() => setAuthorizedModal(false)}
        width={700}
        footer={
          !checkIfAllAreAuto() && (
            <div
              style={{ gap: '6px' }}
              className={globalStyle.confirmModalFooter}
            >
              <Button
                rounded
                isLoading={loadingTerminate}
                onClick={sentToAuthorize}
                label={'Autorizar'}
                sector='tertiary'
              />
              <Button
                rounded
                isLoading={loadingTerminate}
                onClick={() => sentToAuthorize('all')}
                label={'Autorizar todo'}
                sector='tertiary'
              />
            </div>
          )
        }
      >
        <br />

        <Row justify='space-between' align={'middle'}>
          <Col span={15}>
            <B>
              {checkIfAllAreAuto()
                ? `Al solicitar una modificación, se le solicitará al administrador el permiso para modificar este paquete, por lo que su paquete volverá a estar abierto a cambios`
                : `Al autorizar este paquete se cerrara definitivamente y no habrá
              posibilidad de modificarse posteriormente`}
            </B>
          </Col>
          <Col span={8}>
            <Button
              label='Solicitar modificación'
              rounded
              sector='tertiary'
              onClick={() =>
                requestChanges(packageData.id, handleCloseAutoModal)
              }
            />
          </Col>
        </Row>
        <br />
        {!checkIfAllAreAuto() && (
          <>
            <List
              itemLayout='horizontal'
              dataSource={utilsTitle}
              renderItem={(title: any) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  {Object.keys(title).map((res, key) => {
                    return (
                      <Col
                        key={key}
                        className='ListTitles'
                        span={key === 0 ? 1 : 4}
                      >
                        <B size='Light'>{title[res]}</B>
                      </Col>
                    )
                  })}
                </List.Item>
              )}
            />
            <div style={{ maxHeight: 400, overflow: 'auto' }}>
              <List
                itemLayout='horizontal'
                dataSource={autolistPackage}
                renderItem={(util: any, key: number) => (
                  <List.Item style={{ padding: ' 10px 0' }}>
                    <Col span={1}>
                      <Checkbox
                        checked={util.check}
                        onClick={() => checkSupplyToAuto(key)}
                      />
                    </Col>
                    <Col span={4}>
                      <B size='Light'>{util.name}</B>
                    </Col>
                    <Col span={4}>
                      {' '}
                      <B size='Light'>{util.make}</B>
                    </Col>
                    <Col span={4}>
                      {' '}
                      <B size='Light'>{util.quantity}</B>
                    </Col>
                    <Col span={4}>
                      {' '}
                      <B size='Light'>{util.price}</B>
                    </Col>
                  </List.Item>
                )}
              />
            </div>
          </>
        )}
      </Modal>
    </Collapse>
  )
}

export default ItemsTable
