import { AUTHSERVICE } from '../config'

interface IResults {
  status: number
  statusText: string
  data: {
    success: boolean
    url: string
  }
}
interface IResultsCovers {
  status: number
  statusText: string
  data: {
    success: boolean
    url_front?: string
    url_back?: string
  }
}

const uploadImg = async (id: number, data: any): Promise<any> => {
  return await AUTHSERVICE().post(`/notebook/upload-logo/${id}`, data, {
    headers: {
      'Content-Type': 'imageFile.type'
    }
  })
}

const UploadNotebookInsideLogo = async (id: number, data: any) => {
  const results = await uploadImg(id, data)
  return results as IResults
}

const uploadCoverImgs = async (id: number, data: any): Promise<any> => {
  return await AUTHSERVICE().post(`/notebook/upload-cover/${id}`, data, {
    headers: {
      'Content-Type': 'imageFile.type'
    }
  })
}

const UploadNotebookCovers = async (id: number, data: any) => {
  const results = await uploadCoverImgs(id, data)
  return results as IResultsCovers
}

export { UploadNotebookInsideLogo, UploadNotebookCovers }
