import React from 'react'

const PathBlancoHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  viewBox = '0 0 800 800',
  ink
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  viewBox?: string
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g></g>
      ) : (
        <g>
          <title>Margenes Cuadro 5mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Cuadro_5mm_Datos'
              data-name='Margenes Cuadro 5mm Datos'
            >
              <g
                id='Margenes_Cuadro_5mm_Datos-2'
                data-name='Margenes Cuadro 5mm Datos'
              >
                <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='414.26'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08v387.1a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08v387.1a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='414.26'
                      />
                      <path d='M685.92,197.56V610.82H86.23V197.56H685.92m1-1H85.23V611.82H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}

export default PathBlancoHorizontal
