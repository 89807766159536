import { createSlice } from '@reduxjs/toolkit'
import { log } from 'console'

export const initialState: any = {
  booksSchool: [],
  bookDetail: {},
  sendAction: false,
  levelsBook: [],
  IDLevel: 0,
  IDGrade: [],
  oldISBN: false,
  reloadUpload: false,
  isBookAuth: false,
  IDGradeAuth: 0,
  newStructure: false,
  levelsBooksAdmin: [],
  booksSchoolAdmin: [],
  idBookAdmin: 0,
  idBookSchoolAdmin: 0,
  errorMassive: [],
  dataCollege: {
    idSchool: 0,
    idLevel: 0,
    idGrade: 0
  }
}

export const authSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    setBooksSchoolArray: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const add = (object: any) => {
        if (object) {
          const found = array.find((x:any) => x.id === object.id)
          if (found) {
            Object.keys(found).forEach((key:string)=>found[key]=(object[key]!=undefined?object[key]:found[key]))
          } else {
            array.push(object)
          }
          // if (array.filter((x: any) => x.id === object.id).length === 0) {
          //   array.push(object)
          // }
        }
      }

      action.payload.map((item: any) => add(item))

      return {
        ...state,
        booksSchool: [...array]
      }
    },
    setBooksSchoolAdmin: (state, action): any => {
      const array: Array<any> = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const add = (object: any) => {
        if (object) {
          const found = array.find((x:any) => x.id === object.id)
          if (found) {
            Object.keys(found).forEach((key:string)=>found[key]=(object[key]!=undefined?object[key]:found[key]))
          } else {
            array.push(object)
          }
          // if (array.filter((x: any) => x.id === object.id).length === 0) {
          //   array.push(object)
          // }
        }
      }

      action.payload.map((item: any) => add(item))

      return {
        ...state,
        booksSchoolAdmin: [...array]
      }
    },

    setBookDetail: (state, action): any => {
      return {
        ...state,
        bookDetail: action.payload
      }
    },

    deleteBook: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const arrFilter = array.filter((item: any) => item.id !== action.payload)

      return {
        ...state,
        booksSchool: [...arrFilter]
      }
    },
    deleteBookAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrFilter = array.filter((item: any) => item.id !== action.payload)

      return {
        ...state,
        booksSchoolAdmin: [...arrFilter]
      }
    },

    changeStatusBook: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const arrStatus = array.map((item: any) => {
        if (action.payload?.id.includes(item?.id)) {
          item.status = action.payload.status
        }
        return item
      })

      return {
        ...state,
        booksSchool: [...arrStatus]
      }
    },

    updateBook: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const arrStatus = array.map((item: any) => {
        if (item.id_book === action.payload.id) {
          item.isbn = action.payload.values.isbn
          item.author = action.payload.values.author
          item.serie = action.payload.values.serie
          item.name = action.payload.values.name
          item.editorial = action.payload.values.editorial
          item.edition = action.payload.values.edition
          item.format = action.payload.values.format
          item.language = action.payload.values.language
          item.comments = action.payload.values.comments
          item.heritable_book = action.payload.values.heritable_book
          item.new_entry = action.payload.values.new_entry
          item.first_use = action.payload.values.first_use
          item.purpose_book = action.payload.values.purpose_book
        }
        return item
      })

      return {
        ...state,
        booksSchool: [...arrStatus]
      }
    },
    updateFromBook: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const arrStatus = array.map((item: any) => {
        if (item.id === action.payload.id) {
          item.isbn = action.payload.values.isbn
          item.author = action.payload.values.author
          item.serie = action.payload.values.serie
          item.name = action.payload.values.name
          item.editorial = action.payload.values.editorial
          item.edition = action.payload.values.edition
          item.format = action.payload.values.format
          item.language = action.payload.values.language
          item.comments = action.payload.values.comments
          item.heritable_book = action.payload.values.heritable_book
          item.new_entry = action.payload.values.new_entry
          item.first_use = action.payload.values.first_use
          item.purpose_book = action.payload.values.purpose_book
          item.status = action.payload.values.status
          item.price = action.payload.values.price
          item.special_price = action.payload.values.special_price
        }
        return item
      })

      return {
        ...state,
        booksSchool: [...arrStatus]
      }
    },
    updateFromBookAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrStatus = array.map((item: any) => {
        if (item.id === action.payload.id) {
          item.isbn = action.payload.values.isbn
          item.author = action.payload.values.author
          item.serie = action.payload.values.serie
          item.name = action.payload.values.name
          item.editorial = action.payload.values.editorial
          item.edition = action.payload.values.edition
          item.format = action.payload.values.format
          item.language = action.payload.values.language
          item.comments = action.payload.values.comments
          item.heritable_book = action.payload.values.heritable_book
          item.new_entry = action.payload.values.new_entry
          item.first_use = action.payload.values.first_use
          item.purpose_book = action.payload.values.purpose_book
          item.status = action.payload.values.status
          item.price = action.payload.values.price
          item.special_price = action.payload.values.special_price
        }
        return item
      })

      return {
        ...state,
        booksSchoolAdmin: [...arrStatus]
      }
    },
    updateCloseBook: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchool))

      const arrStatus = array.map((item: any) => {
        if (item.id_grade === action.payload.idGrade) {
          item.close = action.payload.close
        }
        return item
      })

      return {
        ...state,
        booksSchool: [...arrStatus]
      }
    },

    setIdBookAdmin: (state, action): any => {
      return {
        ...state,
        idBookAdmin: action.payload
      }
    },
    setIdBookSchoolAdmin: (state, action): any => {
      return {
        ...state,
        idBookSchoolAdmin: action.payload
      }
    },

    updateSchoolBookAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrStatus = array.map((item: any) => {
        if (item.id_book === action.payload.id) {
          item.price = action.payload.values.price
          item.special_price = action.payload.values.special_price
          item.discount = action.payload.values.discount
          item.return_percentage = action.payload.values.return_percentage
          item.refill_time = action.payload.values.refill_time
          item.provider = action.payload.values.provider
          item.provider_fee = action.payload.values.provider_fee
          item.resource_free = action.payload.values.resource_free
        }
        return item
      })

      return {
        ...state,
        booksSchoolAdmin: [...arrStatus]
      }
    },

    updateSchoolBookAdmin2: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrStatus = array.map((item: any) => {
        if (item.id_book === action.payload.id) {
          item.request_changes = false
        }
        return item
      })

      return {
        ...state,
        booksSchoolAdmin: [...arrStatus]
      }
    },

    updateSchoolBookStatusAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrStatus = array.map((item: any) => {
        if (item.id_book === action.payload.id) {
          item.status = action.payload.status
        }
        return item
      })

      return {
        ...state,
        booksSchoolAdmin: [...arrStatus]
      }
    },
    updateSchoolBookStatusCommentsAdmin: (state, action): any => {
      const array = JSON.parse(JSON.stringify(state.booksSchoolAdmin))

      const arrStatus = array.map((item: any) => {
        if (item.id === action.payload.id) {
          item.status = action.payload.status
        }
        return item
      })

      return {
        ...state,
        booksSchoolAdmin: [...arrStatus]
      }
    },
    changeAction: (state, action): any => {
      return {
        ...state,
        sendAction: action.payload
      }
    },
    changeReloadUpload: (state, action): any => {
      return {
        ...state,
        reloadUpload: action.payload
      }
    },
    setLevelsBooks: (state, action): any => {
      return {
        ...state,
        levelsBook: action.payload
      }
    },
    setLevelsBooksAdmin: (state, action): any => {
      return {
        ...state,
        levelsBooksAdmin: action.payload
      }
    },
    setIDLevel: (state, action): any => {
      return {
        ...state,
        IDLevel: action.payload
      }
    },
    setIDGrade: (state, action): any => {
      return {
        ...state,
        IDGrade: action.payload
      }
    },
    updateEndList: (state, action): any => {
      try {
        const array = JSON.parse(JSON.stringify(state.levelsBook))

        array.map((item: any) => {
          const grade = item.grades.find(
            (i: any) => i.id === action.payload.idGrade
          )

          if (grade) {
            grade.endListBooks = action.payload.status
          }

          return null
        })

        return {
          ...state,
          levelsBook: array
        }
      } catch (error) {
        console.log(error)
      }
    },

    updateRequestChanges: (state, action): any => {
      try {
        const array = JSON.parse(JSON.stringify(state.levelsBooksAdmin))

        array.map((item: any) => {
          const grade = item.grades.find(
            (i: any) => i.id === action.payload.idGrade
          )

          if (grade) {
            grade.listBooksChange = action.payload.status
          }

          return null
        })

        return {
          ...state,
          levelsBooksAdmin: array
        }
      } catch (error) {
        console.log(error)
      }
    },
    setISBN: (state, action): any => {
      return {
        ...state,
        oldISBN: action.payload
      }
    },
    setIsBookAuth: (state, action): any => {
      return {
        ...state,
        isBookAuth: action.payload
      }
    },
    setIDGradeAuth: (state, action): any => {
      return {
        ...state,
        IDGradeAuth: action.payload
      }
    },
    setNewStructureBook: (state, action): any => {
      return {
        ...state,
        newStructure: action.payload
      }
    },
    setErrorMassive: (state, action): any => {
      return {
        ...state,
        errorMassive: action.payload
      }
    },
    setDataCollege: (state, action): any => {
      return {
        ...state,
        dataCollege: action.payload
      }
    },
   
  }
})

// Metodo global para actualizar el state
export const {
  setBooksSchoolArray,
  setBookDetail,
  deleteBook,
  deleteBookAdmin,
  changeStatusBook,
  updateBook,
  updateFromBook,
  updateFromBookAdmin,
  changeAction,
  changeReloadUpload,
  setLevelsBooks,
  setIDLevel,
  setIDGrade,
  updateEndList,
  setISBN,
  setIDGradeAuth,
  setIsBookAuth,
  setNewStructureBook,
  setLevelsBooksAdmin,
  setBooksSchoolAdmin,
  setIdBookAdmin,
  setIdBookSchoolAdmin,
  updateSchoolBookAdmin,
  updateSchoolBookStatusAdmin,
  updateSchoolBookStatusCommentsAdmin,
  updateSchoolBookAdmin2,
  setErrorMassive,
  updateRequestChanges,
  updateCloseBook,
  setDataCollege
} = authSlice.actions

export default authSlice.reducer
