import jwtDecode from 'jwt-decode'
export interface permissionsI {
  id: number
  name: string
}
export interface schoolI {
  id_school: number
  name: string
}
export interface UserData {
  id: number
  mail: string
  name: string
  id_school: number | string
  id_gea?: number | string
  role: string
  permissions: permissionsI[]
  school_permissions:permissionsI[]
  responsabilities: any[]
  schools: schoolI[]
  quota?: number | string
  price_notebook: string
  opening_date?:string
  school_status?: "Nuevo"| "En Construcción" | "Solicitud de Modificación" | "Autorizado"
}
export interface SessionData {
  exp: number
  iat: number
  uid: UserData
}

const INITIAL_STATE: SessionData = {
  exp: 0,
  iat: 0,
  uid: {
    id: 0,
    mail: '',
    name: '',
    id_school: 0,
    role: '',
    permissions: [],
    school_permissions: [],
    responsabilities: [],
    schools: [],
    quota: 0,
    price_notebook: ''
  }
}
interface Functions {
  dataToken: () => SessionData
}

export const useDecodeToken = (): Functions => {
  const token: string = sessionStorage.getItem('ezeteraToken') as string

  const dataToken = (): SessionData => {
    if (!token) {
      return INITIAL_STATE
    }
    return jwtDecode(token)
  }

  return {
    dataToken
  }
}
