import React from 'react'

const GreenTagChaleco = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
        <g id="Green_Tag" data-name="Green Tag" stroke='black'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <rect   x="400.38" y="129.56" width="27.55" height="31.62" transform="translate(266.48 558.71) rotate(-89.68)"/>
      <rect   x="399.94" y="133.38" width="28.46" height="23.67"/>
      <g>
        <path   d="m411.06,137.71s.23,0,.63.13c.4.14.85.34,1.19.52.33.18.69.46.8.58.12.12.37.46.39.53.02.07,0,.25-.09.39-.09.14-.31.4-.41.5-.09.09-.18.16-.21.17-.03,0-.05.04-.05.04,0,0,0,.02,0,.04,0,.01.02.05.02.05,0,0-.24.14-.47.26-.22.12-.4.2-.49.25-.08.04-.16.1-.16.1,0,0-.02.05,0,.05s.41-.04,1.16-.18c.75-.14,1.2-.25,1.49-.38.29-.13.42-.31.49-.39.07-.08.09-.16.12-.24.02-.08.04-.13.04-.13,0,0,.21-.06.35-.13.13-.07.32-.22.39-.27.07-.05.18-.09.24-.11.05-.02.15-.04.15-.04,0,0-.02-.06-.04-.06-.01-.01-.09-.1-.09-.16s-.11-.2-.18-.2c-.07,0-.19,0-.27.01-.07.01-.19.03-.23.03-.05,0-.08,0-.08,0,0,0,0-.05.02-.08.02-.02.17-.36.15-.52-.03-.17-.14-.61-.27-.88-.12-.27-.32-.64-.38-.73-.06-.09-.09-.12-.1-.08-.02.04-.01.2,0,.35.02.16.03.27.04.3,0,.03,0,.16,0,.18,0,.02-.02.09-.02.09,0,0,.02.04.02.04l-.08.02s-.01.08-.03.13c-.01.05-.07.22-.15.4-.08.18-.16.35-.21.43-.05.08-.1.14-.12.14-.03,0-.25-.1-.51-.23-.27-.12-.47-.26-.75-.45-.28-.19-.64-.66-.69-.73-.05-.07-.2-.28-.35-.49-.15-.21-.37-.51-.45-.61-.08-.1-.2-.21-.22-.23-.02-.03-.09-.08-.1-.07-.02.01,0,.04.02.07.01.04.26.34.39.53.13.19.42.59.49.72.07.13.21.36.25.45.05.09.06.22.06.22,0,0-.04,0-.19-.03-.14-.04-.69-.23-.8-.26-.12-.03-.42-.1-.49-.1-.08,0-.12,0-.16,0-.04,0-.14.02-.09.08Z"/>
        <g>
          <path   d="m406.54,144.06v.11l-.28.08s-.05.02-.06.04c-.01.01-.02.04-.03.07,0,.06-.01.21-.01.46,0,.15,0,.34.01.59-.06.01-.17.04-.34.09-.23.06-.38.09-.44.1-.12.01-.24.02-.37.02-.43,0-.75-.13-.98-.38-.23-.25-.34-.6-.34-1.03,0-.48.13-.86.4-1.15.27-.3.63-.45,1.07-.45.11,0,.22,0,.32.02.02,0,.23.04.62.12,0,.07,0,.13,0,.18,0,.18,0,.38.03.62h-.14c-.05-.23-.11-.39-.16-.46-.05-.07-.13-.13-.24-.18-.15-.06-.3-.09-.47-.09-.38,0-.66.16-.85.48-.13.22-.2.5-.2.83,0,.42.11.73.31.95.21.21.47.32.79.32.2,0,.41-.05.63-.14,0-.15.01-.36.01-.64,0-.16,0-.25-.01-.27,0-.03-.02-.05-.04-.06-.02-.02-.11-.05-.25-.08l-.07-.02v-.11l.62.02c.14,0,.29,0,.47-.02Z"/>
          <path   d="m406.77,143.16v-.11c.3.01.54.02.72.02.11,0,.2,0,.29,0,.12,0,.2,0,.25,0,.22,0,.39.06.5.17.11.11.17.26.17.45,0,.13-.03.25-.07.34-.05.1-.13.18-.22.25-.07.05-.17.09-.3.13l.57.78c.07.1.12.15.14.17.02.03.06.05.11.06.06.03.17.06.32.1v.1c-.18-.01-.31-.02-.39-.02-.09,0-.2,0-.29.02-.05-.09-.12-.21-.21-.34-.09-.13-.14-.22-.16-.24,0-.01-.02-.03-.03-.04l-.38-.55c-.1,0-.2-.01-.31-.02v.91s.03.06.05.08c.03.02.11.05.26.08.02,0,.05.01.09.02v.1c-.21-.01-.39-.02-.54-.02-.17,0-.36,0-.57.02v-.1l.07-.02c.16-.03.26-.06.28-.09.02-.02.03-.05.04-.11,0-.04,0-.34,0-.93v-.85c0-.13-.01-.2-.02-.22,0-.02-.02-.04-.03-.04-.06-.03-.14-.06-.27-.08-.02,0-.05,0-.08-.01Zm.71,1.1c.12.01.21.02.28.02.21,0,.36-.05.46-.15.1-.1.15-.24.15-.41,0-.16-.04-.27-.13-.36-.09-.09-.22-.13-.39-.13-.06,0-.18,0-.36.02v1Z"/>
          <path   d="m412.32,144.18h.39c.15,0,.24,0,.27-.01.02,0,.04-.03.06-.05.02-.03.04-.11.06-.24,0-.01,0-.03.01-.06h.11c0,.2-.02.35-.02.46s0,.26.02.45h-.11c-.03-.18-.05-.27-.06-.29,0-.03-.03-.05-.06-.06-.02-.01-.1-.02-.24-.02h-.44v1.06h.74c.09,0,.15,0,.17-.01.04-.01.08-.04.11-.08.03-.04.09-.14.17-.31,0-.02.03-.04.04-.07h.11s-.08.26-.2.67c-.39-.01-.75-.02-1.07-.02-.23,0-.48,0-.75.02v-.1l.26-.06s.06-.03.08-.04c.02-.02.02-.07.02-.14v-.46c0-.08,0-.24,0-.48,0-.35,0-.6,0-.76,0-.16,0-.26-.01-.28,0-.01-.01-.03-.03-.04-.02,0-.06-.02-.15-.04l-.17-.04v-.11c.2.01.44.02.72.02.15,0,.52,0,1.09-.02,0,.21.02.41.04.61h-.12c-.04-.16-.08-.27-.1-.32-.02-.04-.04-.06-.07-.07-.03-.02-.11-.02-.24-.02h-.65v.58s-.01.35-.01.35Z"/>
          <path   d="m410.04,144.18h.39c.15,0,.24,0,.26-.01.03,0,.05-.03.07-.05.01-.03.03-.11.06-.24,0-.01,0-.03.02-.06h.1c0,.2-.02.35-.02.46s.01.26.02.45h-.1c-.03-.18-.05-.27-.06-.29-.01-.03-.03-.05-.06-.06-.03-.01-.11-.02-.24-.02h-.44v1.06h.74c.09,0,.15,0,.17-.01.04-.01.08-.04.11-.08.03-.04.09-.14.17-.31,0-.02.02-.04.04-.07h.11s-.08.26-.2.67c-.4-.01-.75-.02-1.07-.02-.23,0-.47,0-.75.02v-.1l.26-.06s.07-.03.08-.04c.01-.02.02-.07.02-.14v-.46c0-.08,0-.24,0-.48,0-.35,0-.6,0-.76,0-.16,0-.26-.02-.28,0-.01-.01-.03-.03-.04-.01,0-.07-.02-.15-.04l-.17-.04v-.11c.2.01.44.02.73.02.15,0,.52,0,1.1-.02,0,.21,0,.41.03.61h-.11c-.04-.16-.08-.27-.1-.32-.02-.04-.04-.06-.07-.07-.03-.02-.12-.02-.24-.02h-.65v.58s-.02.35-.02.35Z"/>
          <path   d="m413.93,143.11v-.11c.14.01.25.02.32.02.06,0,.18,0,.33-.02l.46.64c.04.05.36.49.97,1.31,0-.17,0-.29,0-.37,0-.18,0-.5-.02-.98,0-.22-.01-.34-.01-.36,0-.02-.02-.04-.04-.05-.02-.02-.1-.04-.23-.06-.02,0-.04,0-.07-.02v-.11c.19.01.34.02.45.02s.26,0,.46-.02v.11s-.05.01-.07.02c-.14.03-.22.06-.24.06-.01,0-.02.02-.03.03-.01.02-.02.06-.02.11,0,.02,0,.19,0,.49,0,.3,0,.6,0,.88,0,.34,0,.62.02.85l-.11.06-.67-.93-.88-1.19v1.07c0,.47.01.71.03.75,0,.02.01.04.03.05.02.02.1.05.24.08,0,0,.04,0,.07.02v.1c-.16-.01-.3-.02-.41-.02-.1,0-.26,0-.5.02v-.1s.06-.02.09-.02c.13-.02.2-.05.23-.07,0-.01.02-.03.02-.05,0-.06.02-.27.02-.63,0-.52,0-.82,0-.91,0-.3,0-.47,0-.5,0-.02-.02-.04-.04-.06-.02-.02-.13-.04-.3-.07-.02,0-.05,0-.09-.02Z"/>
          <path   d="m417.8,143.53h-.14c.02-.23.04-.5.06-.79.54.02.92.02,1.15.02.19,0,.54,0,1.05-.02.02.3.03.56.05.79h-.14c-.06-.24-.1-.39-.11-.43-.01-.05-.04-.07-.07-.09-.03-.02-.11-.03-.24-.03h-.41c0,.36-.01.8-.01,1.33,0,.49,0,.82.01,1,0,.04,0,.07.02.08.02.02.13.05.3.09.01,0,.04,0,.08.02v.12c-.24-.01-.46-.02-.65-.02-.18,0-.36,0-.55.02v-.12s.07-.02.09-.02c.17-.03.27-.06.29-.08,0-.01.02-.03.02-.05,0-.02,0-.17.01-.45,0-.43,0-.75,0-.96,0-.36,0-.68,0-.96h-.52c-.07,0-.11.01-.14.03-.04.02-.06.07-.09.14-.02.06-.05.19-.1.38Z"/>
          <path   d="m420.9,143.05h.1c.14.41.31.88.52,1.4l.37.91s.03.05.06.07c0,0,.09.04.23.08v.1c-.19-.01-.33-.02-.42-.02s-.25,0-.47.02v-.1c.12-.03.2-.05.22-.07.03-.02.04-.04.04-.06,0-.03-.01-.07-.04-.13l-.16-.43c-.22,0-.38,0-.49,0h-.43s-.17.45-.17.45c-.03.07-.04.12-.04.13s0,.03.02.04c0,0,.07.03.18.06.01,0,.02,0,.05.01v.1c-.18-.01-.31-.02-.38-.02-.1,0-.21,0-.36.02v-.1c.1-.03.16-.05.19-.08.03-.02.06-.06.09-.12.06-.12.21-.49.46-1.13l.43-1.13Zm-.42,1.59c.15,0,.25,0,.31,0,.02,0,.18,0,.49,0l-.41-1.06-.4,1.06Z"/>
          <path   d="m424.66,144.24v.1l-.24.07s-.05.02-.06.03c0,0-.02.03-.02.06,0,.05-.01.19-.01.4,0,.13,0,.31.01.53-.05,0-.14.03-.3.07-.2.05-.33.08-.38.09-.11.01-.21.02-.33.02-.38,0-.66-.11-.86-.34-.21-.22-.3-.53-.3-.92,0-.42.12-.76.35-1.02.23-.26.55-.39.94-.39.1,0,.19,0,.28.02.02,0,.2.04.55.11,0,.07,0,.12,0,.16,0,.16,0,.34.02.54h-.12c-.05-.21-.09-.34-.14-.4-.04-.07-.11-.12-.21-.16-.13-.06-.27-.08-.42-.08-.33,0-.58.14-.74.42-.11.2-.17.44-.17.73,0,.37.09.65.28.84.18.19.41.29.7.29.18,0,.36-.04.55-.13,0-.13,0-.32,0-.57,0-.14,0-.22-.01-.24,0-.03-.02-.04-.03-.06-.02-.02-.1-.04-.22-.07l-.06-.02v-.1h.55c.12.01.26,0,.41,0Z"/>
        </g>
      </g>
      <g>
        <path   d="m405.67,154.38h-.68v.36c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.03v.05h-.58v-.05h.03c.05,0,.1,0,.12-.02.02-.01.03-.04.04-.08,0-.03.01-.11.01-.24v-.73c0-.12,0-.2-.01-.23,0-.03-.02-.06-.04-.07-.02-.01-.06-.02-.12-.02h-.02v-.06h.58v.06h-.02c-.06,0-.1,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.29h.68v-.29c0-.12,0-.2,0-.23,0-.03-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.03v-.06h.59v.06h-.02c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.73c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.03v.05h-.59v-.05h.04c.05,0,.1,0,.12-.02.02-.01.03-.04.04-.08,0-.03,0-.11,0-.24v-.36Z"/>
        <path   d="m407.23,154.67h.04l-.02.46h-1.08v-.05h.02c.07,0,.11,0,.13-.03.02-.02.03-.04.03-.07,0-.03,0-.11,0-.23v-.73c0-.12,0-.19,0-.23,0-.04-.01-.06-.03-.08-.02-.01-.06-.02-.13-.02v-.06h.93l.03.38h-.05c-.02-.11-.04-.18-.07-.22s-.06-.07-.1-.08c-.04-.01-.11-.02-.2-.02h-.18v.62h.14c.06,0,.11,0,.13-.02.03-.01.04-.03.05-.06,0-.02.01-.08.01-.15h.04v.51h-.04c0-.08,0-.14-.02-.17-.01-.03-.03-.04-.05-.05-.02-.01-.06-.01-.12-.01h-.14v.35c0,.18,0,.28.03.31.02.03.07.04.16.04h.1c.07,0,.13,0,.17-.03.05-.02.09-.06.13-.13.04-.06.07-.15.09-.25Z"/>
        <path   d="m408.58,154.57h.04v.52h-.04c0-.06-.03-.11-.06-.16-.12.15-.27.22-.44.22-.19,0-.34-.07-.46-.2-.12-.14-.18-.32-.18-.54,0-.15.03-.29.09-.42.06-.13.13-.23.23-.29.1-.06.2-.09.32-.09.1,0,.18.02.27.06.08.05.14.1.18.16.02-.06.04-.13.05-.2h.04v.57s-.03,0-.03,0c-.03-.2-.09-.34-.17-.42-.09-.08-.19-.12-.3-.12-.14,0-.24.06-.32.19-.08.12-.12.31-.12.54s.05.4.13.51c.09.12.19.18.33.18.07,0,.14-.02.21-.06.07-.04.13-.11.17-.2.04-.09.07-.18.07-.27Z"/>
        <path   d="m409.88,154.38h-.68v.36c0,.12,0,.2,0,.23,0,.04.02.06.04.08.02.02.06.03.12.03h.03v.05h-.59v-.05h.04c.05,0,.09,0,.12-.02.02-.01.03-.04.04-.08,0-.03,0-.11,0-.24v-.73c0-.12,0-.2,0-.23,0-.03-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.02v-.06h.59v.06h-.02c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.29h.68v-.29c0-.12,0-.2,0-.23,0-.03-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.03v-.06h.59v.06h-.02c-.06,0-.11,0-.13.02-.02,0-.03.03-.03.07,0,.03,0,.11,0,.24v.73c0,.12,0,.2,0,.23,0,.04.01.06.04.08.02.02.07.03.12.03h.03v.05h-.59v-.05h.04c.06,0,.09,0,.11-.02.02-.01.04-.04.04-.08,0-.03,0-.11,0-.24v-.36Z"/>
        <path   d="m410.42,154.37c0-.23.06-.42.2-.56.13-.14.3-.21.5-.21s.37.06.51.21c.13.14.2.32.2.56s-.07.42-.19.56c-.13.14-.3.21-.51.21s-.37-.07-.51-.21c-.13-.14-.2-.34-.2-.57Zm.22,0c0,.24.04.42.13.54.08.12.21.18.38.18.14,0,.26-.06.34-.18.09-.12.13-.3.13-.54s-.04-.41-.12-.53c-.08-.13-.21-.19-.37-.19s-.28.06-.36.18c-.08.12-.13.3-.13.53Z"/>
        <path   d="m413.55,154.67h.05l-.03.46h-1.07v-.05h.03c.06,0,.1,0,.12-.03.02-.02.04-.04.04-.07,0-.03,0-.11,0-.23v-.73c0-.12,0-.19,0-.23,0-.04-.01-.06-.04-.08-.02-.01-.06-.02-.12-.02v-.06h.93l.03.38h-.04c-.02-.11-.05-.18-.08-.22-.02-.04-.06-.07-.11-.08-.03-.01-.1-.02-.19-.02h-.17v.62h.13c.07,0,.11,0,.13-.02.03-.01.04-.03.05-.06.01-.02.01-.08.01-.15h.04v.51h-.04c0-.08,0-.14-.02-.17-.01-.03-.03-.04-.05-.05-.02-.01-.06-.01-.12-.01h-.14v.35c0,.18,0,.28.02.31.02.03.07.04.16.04h.09c.07,0,.13,0,.17-.03.04-.02.09-.06.12-.13.04-.06.07-.15.09-.25Z"/>
        <path   d="m414.86,155.16l-.92-1.4v1.04c0,.09,0,.14.01.17,0,.03.03.06.06.08.03.03.08.03.16.03v.05h-.49v-.05c.06,0,.11-.02.14-.04.03-.02.05-.04.06-.07,0-.03,0-.08,0-.18v-.79c0-.12,0-.19-.01-.23,0-.04-.02-.06-.04-.08-.02,0-.07,0-.14,0v-.06h.38l.77,1.17v-.89c0-.07,0-.11-.02-.14-.01-.03-.04-.05-.07-.06-.03-.02-.08-.02-.13-.02v-.06h.47v.06c-.06,0-.1.02-.13.03-.03.02-.05.04-.06.07,0,.02,0,.07,0,.14v1.24h-.04Z"/>
        <path   d="m416.53,154.87l.47-1.24h.4v.06h-.05c-.06,0-.11,0-.12.02-.02,0-.03.03-.04.07,0,.03-.01.11-.01.24v.73c0,.12,0,.2,0,.23,0,.04.01.06.03.08.02.02.07.03.12.03h.05v.05h-.62v-.05h.04c.06,0,.09,0,.12-.02.02-.01.03-.04.04-.08,0-.03,0-.11,0-.24v-.95l-.51,1.34h-.05l-.52-1.34v.88c0,.12,0,.2,0,.24,0,.05.03.08.06.12.03.03.08.05.14.05v.05h-.47v-.05c.06,0,.1-.02.14-.05.04-.03.06-.07.07-.11,0-.05,0-.13,0-.25v-.65c0-.12,0-.2,0-.23-.01-.03-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.04v-.06h.4l.48,1.24Z"/>
        <path   d="m418.55,154.67h.05l-.03.46h-1.07v-.05h.02c.07,0,.1,0,.13-.03.01-.02.02-.04.03-.07,0-.03,0-.11,0-.23v-.73c0-.12,0-.19,0-.23,0-.04-.02-.06-.04-.08-.02-.01-.06-.02-.13-.02v-.06h.93l.03.38h-.04c-.02-.11-.05-.18-.07-.22-.03-.04-.07-.07-.11-.08-.04-.01-.11-.02-.2-.02h-.18v.62h.14c.07,0,.11,0,.13-.02.02-.01.04-.03.05-.06,0-.02,0-.08,0-.15h.04v.51h-.04c0-.08,0-.14-.02-.17,0-.03-.03-.04-.05-.05-.02-.01-.06-.01-.12-.01h-.14v.35c0,.18.01.28.03.31.02.03.07.04.16.04h.09c.07,0,.13,0,.17-.03.05-.02.09-.06.13-.13.04-.06.07-.15.09-.25Z"/>
        <path   d="m419.47,154.3l.34.47c.12.17.2.26.23.28.03.02.07.03.13.03v.05h-.64v-.05c.07,0,.11,0,.14-.02.03-.01.04-.03.04-.07,0-.02,0-.04-.02-.07,0-.02-.04-.07-.09-.13l-.23-.33-.2.29c-.05.06-.08.11-.09.14-.02.03-.03.06-.03.08,0,.08.05.11.15.11v.05h-.5v-.05c.05,0,.1-.02.15-.04.04-.03.08-.06.12-.1.04-.04.09-.12.16-.21l.22-.3-.34-.48-.11-.15s-.04-.06-.08-.08c-.03-.02-.06-.03-.11-.03v-.06h.55v.06h-.02c-.07,0-.11.02-.11.07,0,.02,0,.04.02.06,0,.02.04.06.08.12l.24.33.21-.31c.05-.07.07-.1.08-.12s.02-.03.02-.05c0-.03-.02-.06-.04-.07-.03-.01-.07-.02-.13-.02v-.06h.48v.06c-.08,0-.15.03-.21.08-.05.06-.12.13-.19.23l-.2.3Z"/>
        <path   d="m420.22,153.69v-.06h.59v.06h-.03c-.06,0-.11,0-.13.02-.02,0-.03.03-.04.07,0,.03,0,.11,0,.24v.73c0,.12,0,.2,0,.23,0,.04.02.06.03.08.02.02.07.03.12.03h.04v.05h-.59v-.05h.04c.05,0,.09,0,.11-.02.02-.01.04-.04.04-.08,0-.03,0-.11,0-.24v-.73c0-.12,0-.2,0-.23,0-.03-.02-.06-.04-.07-.02-.01-.06-.02-.13-.02h-.03Z"/>
        <path   d="m422.11,154.57h.04v.52h-.04c0-.06-.03-.11-.06-.16-.12.15-.27.22-.44.22-.19,0-.34-.07-.46-.2-.12-.14-.18-.32-.18-.54,0-.15.02-.29.09-.42.05-.13.13-.23.23-.29.1-.06.21-.09.32-.09.1,0,.18.02.27.06.08.05.14.1.18.16.02-.06.04-.13.05-.2h.04v.57s-.03,0-.03,0c-.03-.2-.09-.34-.17-.42-.09-.08-.19-.12-.3-.12-.13,0-.24.06-.32.19-.08.12-.12.31-.12.54s.04.4.13.51c.09.12.2.18.32.18.07,0,.14-.02.22-.06.07-.04.13-.11.17-.2.04-.09.07-.18.07-.27Z"/>
        <path   d="m422.38,154.37c0-.23.07-.42.2-.56.14-.14.3-.21.51-.21s.37.06.51.21c.14.14.2.32.2.56s-.07.42-.19.56c-.13.14-.3.21-.52.21s-.38-.07-.51-.21c-.13-.14-.2-.34-.2-.57Zm.22,0c0,.24.05.42.13.54.08.12.21.18.38.18.14,0,.26-.06.34-.18.08-.12.13-.3.13-.54s-.04-.41-.12-.53c-.09-.13-.21-.19-.37-.19s-.28.06-.36.18c-.08.12-.13.3-.13.53Z"/>
      </g>
      <path   d="m413.42,150.32l-.02-.18c.19-.41.47-.74.75-1.07.21-.25.44-.55.44-.91,0-.29-.17-.57-.45-.57-.17,0-.36.11-.36.31,0,.13.1.19.1.32,0,.11-.09.21-.19.21-.15,0-.23-.14-.23-.29,0-.42.38-.69.73-.69.37,0,.76.28.76.72,0,.41-.29.69-.55.92-.39.34-.48.45-.75.92h.35c.22,0,.42,0,.54-.05.11-.05.21-.18.29-.41l.11.03-.16.74h-1.36Z"/>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagChaleco
