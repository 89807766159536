import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleCamisaMlMcForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'
import { TFormCamisaMlMcOptions } from 'interfaces/uniforms-interface'

const CamisaMlMcOptionsEditable = () => {
  const { camisaMlMcForm } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormCamisaMlMcOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleCamisaMlMcForm({
        ...camisaMlMcForm,
        [keyName]: {
          ...camisaMlMcForm[keyName as TFormCamisaMlMcOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary
            handleChange={handleChange}
            form={camisaMlMcForm}
          />
        )
      })()}
    </div>
  )
}

export default CamisaMlMcOptionsEditable
