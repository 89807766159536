const UniformIcon = ({
  className,
  stroke = '#305599'
}: {
  className: string
  stroke: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 32 32'
      className={className}
    >
      <path
        d='M24.6666 11.3333V24.6666M24.6666 24.6666V29.3333H7.33329V24.6666M24.6666 24.6666H29.3333V11.3333C29.3333 9.33329 28 6.99996 26 5.33329C24 3.66663 21.3333 2.66663 21.3333 2.66663M7.33329 24.6666H2.66663V11.3333C2.66663 9.33329 3.99996 6.99996 5.99996 5.33329C7.99996 3.66663 10.6666 2.66663 10.6666 2.66663M7.33329 24.6666V11.3333M10.6666 2.66663H21.3333M10.6666 2.66663L16 11.3333M21.3333 2.66663L16 11.3333M16 11.3333V29.3333'
        stroke={stroke}
        strokeWidth='2'
      />
    </svg>
  )
}

export default UniformIcon
