import React, { Fragment } from 'react'
import style from './poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import HoodieSvg from 'assets/img/uniforms/hoodie/HoodieSvg'
import ShadowsHoodie from 'assets/img/uniforms/hoodie/ShadowsHoodie'

const HoodieEditable = () => {
  const { pictureLogo, hoodieForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {hoodieForm.logoPosition.value !== '' &&
            hoodieForm.logoPosition.value !== 'espalda' && (
              <div
                className={`${
                  style[`logo-position-hoodie-${hoodieForm.logoPosition.value}`]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <HoodieSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsHoodie className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      </div>
    </div>
  )
}

export default HoodieEditable
