import React from 'react'
import { IconProps } from '../../../types'
export const StructureIcon = ({
  height = 36,
  width = 43,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 43 36'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.25 21.75V12L21.5 2.25L2.75 12L21.5 21.75L31.25 17.25V27.75C31.25 30.75 26.75 33.75 21.5 33.75C16.25 33.75 11.75 30.75 11.75 27.75V17.25'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
