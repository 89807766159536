import { useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

const useIsAdminEzetera = () => {
  const { globalRoles } = useSelector(
    (state: RootState) => state.userManagement
  )
  const { dataToken } = useDecodeToken()

  return dataToken().uid.role === globalRoles[0].label
}

export default useIsAdminEzetera
