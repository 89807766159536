import { createSlice } from '@reduxjs/toolkit'

const initialState:any = {
  data:{},
  openComments:false,
  comments:[]
}

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setCommentsObjectInformation: (state, action) => {
      return {
        ...state,
        data:action.payload
      }
    },
    setopenComments: (state, action) => {
      return {
        ...state,
        openComments:action.payload
      }
    },
    setCommentList: (state, action) => {
      return {
        ...state,
        comments:action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setCommentsObjectInformation,setopenComments, setCommentList } = commentsSlice.actions

export default commentsSlice.reducer
