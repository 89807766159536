import React from 'react'

const CamisaSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="División_manga_derecha" data-name="División manga derecha"   d="m593.78,330.33c-2.12-1.13-3.99-2.67-5.61-4.43-1.79-1.96-3.26-4.19-4.5-6.53-1.42-2.68-2.54-5.51-3.47-8.39-1.06-3.28-1.87-6.64-2.52-10.02-.73-3.78-1.26-7.59-1.65-11.42-.43-4.18-.7-8.38-.85-12.58-.16-4.5-.2-9.01-.13-13.52.07-4.74.25-9.49.51-14.22s.64-9.8,1.08-14.7.98-9.97,1.57-14.94,1.26-9.97,1.98-14.95c.72-4.92,1.49-9.83,2.32-14.73s1.67-9.53,2.58-14.28,1.79-9.07,2.76-13.59c.91-4.24,1.86-8.46,2.87-12.68.92-3.86,1.88-7.7,2.9-11.53.9-3.4,1.85-6.79,2.85-10.16.86-2.87,1.75-5.72,2.73-8.55.78-2.26,1.6-4.51,2.53-6.71.66-1.58,1.37-3.16,2.25-4.64.53-.9,1.16-1.76,2.02-2.38.57-.41,2.26-1.46,2.88-.7"/>
      <path id="División_manga_izquierda" data-name="División manga izquierda"   d="m199.76,328.47c42.22-35.62-7.24-212.49-13.27-225.55"/>
      <line id="Hombro_izquierdo" data-name="Hombro izquierdo"   x1="195.5" y1="131.5" x2="308.72" y2="82.93"/>
      <line id="Hombro_derecho" data-name="Hombro derecho"   x1="483.87" y1="81.75" x2="595.5" y2="130.5"/>
      <path id="Cuello_interno" data-name="Cuello interno"   d="m342.4,64.14c32.59-11.74,62.86-12.5,108.91-3.53"/>
      <g id="Botones">
        <path id="Botones-2" data-name="Botones"   d="m404.89,740.02c.72-2.71-.95-6.81-.43-9.94.73-4.32.38-8.64.38-12.93.03-169.97.02-339.93.01-509.89v-108.99c-6.55,6.12-12.71,5.89-19.4-.67v5.34c-.14,131.67-.32,263.33-.42,395-.06,75.66,0,151.33.03,227,0,8.85.01,13.28,1.38,15.46.12.19.25.36.39.52l18.06-.9Z"/>
        <polyline   points="397.83 112.86 397.95 110.99 398.68 112.99 398.86 110.77 399.62 112.62 400.12 111.75 400.4 112.45 400.65 112.02 401.1 112.43 401.41 112.08 401.95 112.27"/>
        <path   d="m390.28,111.02c.6-2.17,3.03-3.5,5.46-2.97,2.42.53,3.9,2.73,3.31,4.9-.6,2.17-3.04,3.49-5.46,2.96-2.42-.53-3.9-2.72-3.31-4.89Z"/>
        <g>
          <path d="m393.46,115.97c-.05-.06-.15-.13-.25-.16-.16-.05-.28,0-.32.11-.04.1,0,.18.15.29.18.13.26.26.21.41-.06.17-.27.25-.5.17-.12-.04-.21-.09-.25-.14l.09-.1s.1.09.21.13c.17.05.26-.02.29-.09.04-.1-.02-.18-.16-.29-.18-.14-.25-.26-.19-.42.06-.17.26-.27.54-.18.12.04.23.11.28.17l-.08.11Z"/>
          <path d="m391.79,115.68l.43.23.22-.37-.48-.26.06-.11.63.33-.6,1.02-.6-.32.06-.11.46.24.19-.32-.43-.23.06-.11Z"/>
          <path d="m390.73,114.75c-.11.13-.24.28-.35.38h0c.13-.07.27-.14.43-.21l.57-.27.09.08-.25.55c-.07.16-.14.31-.22.43h0c.11-.1.25-.24.39-.36l.38-.35.11.1-.9.8-.15-.14.25-.56c.06-.14.12-.26.19-.38h0c-.11.06-.23.12-.38.2l-.56.26-.15-.14.77-.91.12.11-.34.4Z"/>
          <path d="m389.84,114.68c-.05-.06-.11-.14-.18-.25-.08-.14-.11-.26-.09-.35.01-.09.07-.17.16-.22.09-.06.18-.07.27-.05.12.03.23.13.31.26.02.04.05.08.06.12l.41-.25.08.14-1.02.61Zm.41-.44s-.03-.07-.06-.12c-.1-.17-.24-.22-.38-.14-.14.08-.14.22-.05.38.04.06.07.1.09.12l.4-.24Z"/>
          <path d="m389.68,112.81l.15.46.41-.14-.16-.52.12-.04.21.67-1.13.38-.2-.65.12-.04.15.49.36-.12-.15-.46.12-.04Z"/>
          <path d="m389,112.54c-.02-.08-.04-.2-.04-.31-.01-.17.01-.29.07-.37.05-.07.13-.11.22-.12.16-.02.27.08.32.21h0c.03-.1.11-.17.23-.21.16-.06.27-.1.32-.13v.17s-.12.06-.27.11c-.16.05-.22.13-.21.27v.15s.52-.05.52-.05v.16s-1.16.12-1.16.12Zm.53-.22v-.17c-.02-.17-.12-.27-.25-.26-.15.02-.21.14-.2.31,0,.08.02.13.02.16l.43-.04Z"/>
          <path d="m392.6,107.76v.39s-.17.06-.17.06l.03-1.23.19-.07.8.91-.17.06-.25-.29-.43.17Zm.36-.26l-.23-.26c-.05-.06-.09-.12-.13-.17h0c0,.07,0,.14,0,.22v.36s.36-.14.36-.14Z"/>
          <path d="m393.44,106.67l.17-.03.13,1.01.55-.09.02.12-.72.12-.15-1.14Z"/>
          <path d="m394.56,106.67h-.39s0-.12,0-.12l.96-.02v.13s-.4,0-.4,0l-.02,1.02h-.17s.02-1.02.02-1.02Z"/>
          <path d="m395.59,106.59l-.2,1.15-.17-.02.2-1.15.17.02Z"/>
          <path d="m396.17,106.69l-.22.65c-.08.25,0,.39.17.44.18.05.34-.02.42-.27l.22-.65.16.05-.22.64c-.12.34-.36.42-.63.34-.25-.07-.4-.26-.28-.6l.22-.65.16.05Z"/>
          <path d="m396.84,107.94c.04.07.12.15.22.2.15.08.28.05.34-.05.06-.1.03-.18-.09-.32-.15-.16-.21-.3-.13-.45.09-.16.31-.2.52-.09.11.06.18.13.22.18l-.1.09s-.08-.11-.18-.16c-.15-.08-.26-.03-.3.04-.06.1-.02.18.1.32.15.17.2.3.11.45-.09.16-.3.23-.56.09-.11-.06-.21-.15-.25-.22l.1-.09Z"/>
        </g>
        <path d="m394.8,112.77c.05-.26.34-.45.65-.41.31.04.51.31.47.58-.05.28-.34.47-.65.42-.31-.03-.52-.29-.47-.58Z"/>
        <path d="m393.35,112.79c.05-.28.33-.47.64-.43.3.05.51.31.46.58-.04.28-.33.47-.64.43-.31-.05-.52-.31-.47-.58Z"/>
        <path d="m394.79,111.13c.06-.27.35-.46.66-.41.31.04.51.29.47.58-.05.27-.34.47-.65.42-.31-.04-.52-.3-.48-.58Z"/>
        <path d="m393.43,111.04c.05-.27.34-.46.64-.41.31.03.52.29.47.57-.05.27-.34.46-.65.42-.3-.04-.51-.3-.47-.58Z"/>
        <g id="Botones-3" data-name="Botones">
          <path   d="m397,111.97c0,1.2-1.04,2.18-2.32,2.18s-2.32-.97-2.32-2.18,1.03-2.17,2.32-2.17,2.32.97,2.32,2.17Z"/>
          <g>
            <polyline   points="394.19 185.24 396.04 185.41 394.02 186.09 396.25 186.32 394.36 187.03 395.24 187.56 394.52 187.82 394.95 188.08 394.54 188.52 394.88 188.84 394.67 189.38"/>
            <path   d="m396.19,177.73c2.15.65,3.43,3.12,2.84,5.53-.6,2.4-2.82,3.83-4.98,3.19-2.15-.65-3.42-3.13-2.83-5.53.58-2.4,2.81-3.84,4.97-3.19Z"/>
            <g>
              <path d="m391.17,180.79c.06-.05.13-.14.17-.25.05-.16,0-.28-.1-.33-.1-.04-.18,0-.29.14-.13.17-.26.26-.42.2-.17-.07-.24-.27-.16-.51.04-.12.1-.2.14-.25l.1.09s-.09.1-.13.21c-.06.16.01.26.09.29.1.04.18-.01.29-.16.14-.18.27-.24.42-.18.17.07.27.26.17.55-.04.12-.11.23-.17.28l-.1-.09Z"/>
              <path d="m391.52,179.13l-.25.43.36.23.27-.48.11.07-.35.62-1-.63.34-.59.11.07-.26.45.32.2.24-.43.11.07Z"/>
              <path d="m392.46,178.09c-.12-.11-.27-.25-.37-.36h0c.07.14.13.28.2.44l.25.57-.09.09-.54-.26c-.16-.08-.3-.15-.42-.23h0c.1.11.23.26.35.4l.33.39-.11.11-.77-.92.14-.15.55.26c.14.07.26.13.37.2h0c-.06-.11-.12-.24-.19-.39l-.24-.57.14-.15.88.8-.11.11-.39-.35Z"/>
              <path d="m392.54,177.21c.06-.05.15-.11.26-.17.14-.08.26-.1.36-.09.09.02.17.07.22.17.05.09.07.19.04.27-.03.12-.13.23-.27.3-.04.02-.08.04-.12.05l.24.42-.14.08-.58-1.03Zm.43.42s.08-.03.12-.06c.17-.1.23-.23.15-.38-.08-.14-.22-.15-.37-.06-.06.04-.1.07-.13.09l.23.41Z"/>
              <path d="m394.43,177.09l-.47.13.13.41.52-.15.04.12-.68.19-.36-1.14.65-.19.04.12-.49.14.11.36.47-.14.04.12Z"/>
              <path d="m394.7,176.42c.08-.02.2-.03.31-.04.17,0,.29.02.37.08.07.05.11.13.12.22.01.16-.08.26-.21.31h0c.1.03.16.11.2.24.05.16.09.28.12.32h-.17s-.06-.13-.11-.27c-.05-.16-.12-.22-.27-.22h-.15s.04.52.04.52h-.16s-.09-1.16-.09-1.16Zm.2.53h.17c.17-.01.28-.11.27-.24-.01-.15-.13-.21-.31-.21-.08,0-.13.01-.16.02l.03.43Z"/>
              <path d="m399.4,180.13h-.39s-.06-.18-.06-.18l1.23.06.07.19-.93.78-.06-.17.29-.25-.15-.44Zm.25.37l.27-.23c.06-.05.12-.09.17-.13h0c-.07,0-.14,0-.22,0l-.36-.02.13.37Z"/>
              <path d="m400.48,181l.02.17-1.02.11.08.56h-.12s-.1-.71-.1-.71l1.14-.12Z"/>
              <path d="m400.45,182.11v-.39s.13,0,.13,0v.96s-.13,0-.13,0v-.4s-1.02-.05-1.02-.05v-.17s1.02.05,1.02.05Z"/>
              <path d="m400.5,183.14l-1.14-.22.02-.16,1.14.22-.02.17Z"/>
              <path d="m400.38,183.75l-.65-.24c-.25-.09-.39,0-.44.16-.06.18.01.34.26.43l.65.24-.05.16-.64-.23c-.34-.12-.41-.37-.33-.64.08-.25.27-.39.61-.27l.65.24-.05.16Z"/>
              <path d="m399.12,184.37c-.07.04-.15.12-.21.21-.08.15-.06.27.04.34.09.06.18.03.32-.08.16-.14.31-.2.45-.11.15.1.19.31.07.53-.06.11-.13.18-.19.21l-.08-.11s.11-.08.17-.18c.09-.15.04-.26-.03-.3-.09-.06-.18-.02-.32.1-.17.14-.31.19-.45.09-.15-.1-.22-.31-.07-.57.06-.11.15-.2.22-.24l.09.1Z"/>
            </g>
            <path d="m394.34,182.21c.26.05.44.35.4.65-.05.31-.32.51-.6.46-.28-.05-.45-.35-.4-.65.04-.31.31-.52.59-.45Z"/>
            <path d="m394.36,180.76c.28.05.46.34.41.65-.05.3-.31.51-.59.45-.28-.06-.46-.33-.41-.65.05-.31.32-.51.59-.45Z"/>
            <path d="m395.97,182.24c.27.06.45.35.4.66-.05.31-.31.51-.59.46-.27-.06-.46-.35-.41-.65.05-.31.32-.51.6-.46Z"/>
            <path d="m396.1,180.88c.27.06.45.35.4.66-.05.3-.31.51-.59.45-.26-.06-.45-.34-.4-.66.04-.31.31-.51.59-.46Z"/>
          </g>
          <g>
            <polyline   points="394.33 274.96 396.19 275.14 394.17 275.81 396.39 276.05 394.52 276.75 395.39 277.29 394.67 277.55 395.1 277.8 394.69 278.25 395.03 278.56 394.82 279.11"/>
            <path   d="m396.34,267.46c2.15.65,3.43,3.11,2.84,5.52-.58,2.41-2.81,3.83-4.96,3.2-2.17-.65-3.43-3.12-2.84-5.53.58-2.4,2.81-3.83,4.97-3.19Z"/>
            <g>
              <path d="m391.32,270.53c.06-.05.13-.14.17-.25.05-.16.01-.28-.1-.33-.1-.04-.18,0-.29.14-.13.17-.26.26-.42.2-.17-.07-.24-.27-.16-.51.04-.12.1-.2.14-.25l.1.09s-.09.1-.13.21c-.06.17.01.26.09.29.1.04.18-.01.29-.16.14-.18.27-.24.42-.18.17.07.27.26.17.55-.04.12-.11.23-.17.28l-.1-.09Z"/>
              <path d="m391.66,268.86l-.24.43.36.22.27-.48.11.07-.35.62-1.01-.62.33-.6.11.07-.25.45.32.2.24-.43.11.07Z"/>
              <path d="m392.6,267.82c-.12-.11-.27-.25-.38-.35h0c.07.13.14.28.21.44l.25.57-.08.09-.54-.26c-.16-.08-.3-.15-.43-.23h0c.1.11.24.26.35.4l.34.39-.11.11-.78-.91.14-.15.56.26c.14.07.26.13.37.2h0c-.06-.11-.12-.24-.19-.39l-.25-.57.14-.15.89.79-.11.11-.39-.35Z"/>
              <path d="m392.69,266.94c.06-.05.15-.11.26-.17.14-.08.26-.11.35-.09.09.01.17.07.22.16.05.09.07.19.04.27-.03.12-.13.23-.27.3-.04.02-.08.04-.12.05l.24.42-.14.08-.58-1.03Zm.43.42s.08-.03.12-.06c.17-.1.23-.23.15-.38-.08-.14-.22-.15-.37-.06-.06.03-.1.07-.13.09l.23.41Z"/>
              <path d="m394.56,266.82l-.47.14.13.41.52-.15.04.12-.68.19-.36-1.14.65-.19.04.12-.49.14.11.36.47-.14.04.12Z"/>
              <path d="m394.85,266.15c.08-.02.2-.03.31-.04.17,0,.29.02.37.08.07.05.11.13.12.22.01.16-.08.27-.21.31h0c.1.03.16.11.2.24.05.16.09.28.12.32h-.17s-.06-.13-.11-.27c-.05-.16-.12-.22-.27-.22h-.15s.04.52.04.52h-.16s-.09-1.16-.09-1.16Zm.2.53h.17c.17-.01.28-.11.27-.24-.01-.15-.14-.21-.31-.21-.08,0-.14.01-.16.02l.03.43Z"/>
              <path d="m399.55,269.85h-.39s-.06-.18-.06-.18l1.23.06.07.19-.93.78-.06-.17.29-.25-.15-.44Zm.25.37l.27-.23c.06-.05.12-.09.17-.13h0c-.07,0-.14,0-.22,0l-.36-.02.13.37Z"/>
              <path d="m400.63,270.75l.02.17-1.02.11.08.55h-.12s-.1-.71-.1-.71l1.14-.12Z"/>
              <path d="m400.6,271.86v-.39s.13,0,.13,0v.96s-.13,0-.13,0v-.4s-1.02-.05-1.02-.05v-.17s1.03.05,1.03.05Z"/>
              <path d="m400.65,272.87l-1.14-.22.02-.17,1.14.22-.02.16Z"/>
              <path d="m400.53,273.47l-.65-.24c-.25-.09-.39,0-.44.16-.06.18.01.34.26.43l.65.24-.05.16-.64-.23c-.34-.12-.41-.37-.33-.64.08-.25.27-.39.61-.27l.65.24-.05.16Z"/>
              <path d="m399.27,274.09c-.07.04-.15.12-.21.21-.08.15-.06.27.04.34.09.06.18.03.32-.08.16-.14.31-.2.45-.11.16.1.19.31.07.53-.06.11-.13.18-.19.21l-.08-.1s.11-.08.17-.18c.09-.15.04-.26-.03-.3-.09-.06-.18-.02-.32.1-.17.14-.31.19-.45.1-.15-.1-.22-.31-.07-.57.06-.11.16-.2.22-.24l.09.1Z"/>
            </g>
            <path d="m394.48,271.94c.27.06.45.35.41.66-.05.3-.32.51-.59.45-.28-.05-.46-.35-.4-.66.04-.3.31-.51.59-.45Z"/>
            <path d="m394.5,270.49c.28.05.47.34.42.65-.06.3-.32.51-.59.44-.28-.05-.45-.33-.41-.65.06-.31.32-.51.59-.45Z"/>
            <path d="m396.11,271.97c.28.07.45.35.4.66-.05.31-.31.51-.59.45-.27-.06-.46-.35-.41-.65.05-.31.32-.51.59-.46Z"/>
            <path d="m396.25,270.61c.27.05.45.35.4.65-.05.31-.32.52-.59.46-.27-.06-.46-.35-.4-.65.04-.31.31-.51.59-.45Z"/>
          </g>
          <g>
            <polyline   points="394.35 357.21 396.21 357.28 394.23 358.07 396.47 358.18 394.64 358.99 395.52 359.47 394.82 359.77 395.27 360.01 394.87 360.47 395.24 360.77 395.06 361.32"/>
            <path   d="m395.96,349.61c2.19.53,3.59,2.94,3.12,5.37-.45,2.44-2.61,3.98-4.79,3.45-2.19-.53-3.59-2.94-3.13-5.38.46-2.43,2.61-3.97,4.8-3.45Z"/>
            <g>
              <path d="m391.11,352.93c.06-.06.12-.15.15-.26.05-.16,0-.28-.12-.32-.1-.04-.18.01-.29.16-.12.18-.25.27-.41.22-.17-.06-.26-.26-.19-.5.04-.13.09-.21.13-.25l.1.08s-.09.1-.12.21c-.05.17.02.26.1.29.1.04.18-.02.28-.17.13-.18.25-.26.41-.2.17.06.28.25.2.54-.03.12-.1.24-.16.29l-.11-.08Z"/>
              <path d="m391.36,351.25l-.22.44.37.2.25-.49.11.06-.32.64-1.03-.57.31-.61.11.06-.23.46.33.18.22-.44.11.06Z"/>
              <path d="m392.24,350.16c-.13-.11-.28-.23-.39-.34h0c.08.13.15.27.23.43l.28.56-.08.09-.55-.24c-.16-.07-.31-.14-.44-.21h0c.11.11.25.25.37.38l.36.37-.1.12-.82-.88.14-.15.57.23c.14.06.27.12.38.18h0c-.07-.11-.14-.23-.21-.38l-.28-.56.14-.16.93.76-.1.12-.41-.33Z"/>
              <path d="m392.29,349.28c.06-.05.14-.12.25-.19.14-.09.25-.12.35-.11.09.01.17.06.23.15.06.09.08.18.06.27-.03.12-.12.23-.25.32-.04.03-.08.05-.12.06l.26.41-.14.09-.64-1Zm.45.4s.07-.03.12-.06c.16-.1.22-.25.13-.38-.08-.13-.22-.14-.38-.04-.06.04-.1.07-.12.09l.25.39Z"/>
              <path d="m394.15,349.06l-.46.16.15.4.51-.18.05.12-.67.23-.42-1.12.64-.22.04.12-.49.17.13.35.46-.16.04.12Z"/>
              <path d="m394.41,348.37c.08-.02.2-.04.31-.05.17-.02.29,0,.38.06.07.05.12.12.13.21.02.16-.07.27-.2.32h0c.1.03.17.11.22.23.06.16.11.27.14.31l-.17.02s-.07-.13-.12-.27c-.06-.16-.13-.21-.28-.21h-.15s.07.52.07.52l-.16.02-.15-1.16Zm.23.52l.17-.02c.17-.02.27-.12.25-.25-.02-.15-.15-.21-.32-.19-.08,0-.13.02-.16.03l.06.43Z"/>
              <path d="m399.3,351.83h-.39s-.07-.16-.07-.16h1.23s.08.18.08.18l-.89.83-.07-.17.28-.26-.18-.43Zm.27.35l.26-.24c.06-.05.11-.1.17-.14h0c-.07,0-.14,0-.22.01h-.36s.15.37.15.37Z"/>
              <path d="m400.41,352.65l.03.17-1.01.16.11.55-.12.02-.14-.72,1.13-.18Z"/>
              <path d="m400.45,353.77l-.02-.39h.13s.04.96.04.96h-.13s-.02-.4-.02-.4h-1.03s0-.17,0-.17h1.03Z"/>
              <path d="m400.55,354.79l-1.15-.16.02-.17,1.15.16v.17Z"/>
              <path d="m400.46,355.39l-.66-.2c-.25-.08-.39.01-.43.18-.05.19.03.34.28.41l.66.2-.04.16-.65-.2c-.34-.11-.43-.35-.36-.62.07-.25.25-.41.59-.3l.66.2-.04.16Z"/>
              <path d="m399.23,356.08c-.07.04-.15.12-.2.22-.08.15-.05.28.06.33.1.05.18.02.31-.1.15-.15.3-.22.44-.14.16.09.21.3.1.52-.06.12-.13.19-.18.22l-.09-.1s.11-.08.16-.19c.08-.15.02-.26-.05-.3-.1-.05-.18-.01-.31.11-.16.15-.3.2-.45.12-.16-.09-.23-.3-.1-.56.06-.11.14-.21.21-.25l.09.1Z"/>
            </g>
            <path d="m394.34,354.18c.27.04.47.33.44.63-.03.31-.29.53-.56.49-.28-.03-.47-.33-.44-.63.03-.31.28-.53.56-.49Z"/>
            <path d="m394.29,352.73c.28.03.48.32.44.63-.03.3-.28.52-.56.48-.28-.03-.47-.32-.44-.63.04-.31.3-.53.57-.48Z"/>
            <path d="m395.98,354.12c.27.05.47.33.43.64-.03.32-.28.53-.56.49-.27-.04-.47-.32-.43-.62.03-.31.28-.53.57-.5Z"/>
            <path d="m396.03,352.76c.27.05.47.33.43.64-.02.3-.28.52-.56.48-.27-.04-.48-.31-.44-.63.04-.31.29-.52.57-.49Z"/>
          </g>
          <g>
            <polyline   points="394.29 460.98 396.14 461.12 394.14 461.83 396.36 462.03 394.5 462.76 395.39 463.28 394.67 463.56 395.1 463.81 394.69 464.25 395.05 464.56 394.84 465.11"/>
            <path   d="m396.15,453.44c2.17.6,3.49,3.05,2.94,5.48-.55,2.42-2.74,3.89-4.91,3.29-2.17-.61-3.48-3.06-2.95-5.48.55-2.41,2.75-3.89,4.91-3.28Z"/>
            <g>
              <path d="m391.19,456.59c.06-.06.13-.15.16-.25.05-.16,0-.28-.1-.32-.1-.04-.18,0-.29.15-.13.17-.26.26-.41.2-.17-.06-.25-.27-.17-.5.04-.12.09-.21.14-.25l.1.09s-.09.1-.13.21c-.05.17.02.26.09.29.1.04.18-.02.29-.16.14-.18.26-.25.42-.19.17.06.27.26.18.54-.04.12-.11.23-.17.28l-.11-.08Z"/>
              <path d="m391.5,454.92l-.23.43.37.22.26-.48.11.07-.34.63-1.01-.6.32-.6.11.07-.25.46.32.19.23-.43.11.06Z"/>
              <path d="m392.42,453.86c-.12-.11-.28-.24-.38-.35h0c.07.13.14.28.22.44l.26.57-.08.09-.55-.25c-.16-.08-.31-.15-.43-.22h0c.1.11.24.26.36.39l.34.39-.11.11-.79-.91.14-.15.56.25c.14.07.27.13.37.19h0c-.06-.11-.13-.24-.2-.38l-.26-.57.14-.15.91.79-.11.12-.4-.35Z"/>
              <path d="m392.49,452.98c.06-.05.14-.11.26-.18.14-.08.26-.11.35-.09.09.01.17.07.22.16.06.09.07.18.05.27-.03.12-.13.23-.26.31-.04.02-.08.05-.12.06l.25.41-.14.08-.6-1.02Zm.43.42s.08-.03.12-.06c.17-.1.22-.24.14-.38-.08-.14-.22-.14-.37-.05-.06.04-.1.07-.12.09l.24.4Z"/>
              <path d="m394.37,452.83l-.47.14.14.41.52-.16.04.12-.67.21-.38-1.13.65-.2.04.12-.49.15.12.36.47-.14.04.12Z"/>
              <path d="m394.64,452.15c.08-.02.2-.04.31-.04.17,0,.29.01.37.08.07.05.11.13.12.22.01.16-.08.27-.21.32h0c.1.03.17.11.21.23.06.16.1.27.13.32h-.17s-.06-.12-.11-.27c-.05-.16-.13-.22-.27-.22h-.15s.05.52.05.52h-.16s-.11-1.16-.11-1.16Zm.21.53h.17c.17-.02.28-.12.26-.25-.01-.15-.14-.21-.31-.2-.08,0-.13.02-.16.02l.04.43Z"/>
              <path d="m399.41,455.77h-.39s-.06-.17-.06-.17l1.23.03.07.19-.92.8-.06-.17.29-.25-.16-.44Zm.26.36l.27-.23c.06-.05.12-.09.17-.13h0c-.07,0-.14,0-.22,0h-.36s.14.36.14.36Z"/>
              <path d="m400.5,456.65l.03.17-1.01.13.09.55-.12.02-.11-.72,1.14-.14Z"/>
              <path d="m400.49,457.78v-.4s.12,0,.12,0v.96s-.12,0-.12,0v-.4s-1.04-.04-1.04-.04v-.17s1.03.04,1.03.04Z"/>
              <path d="m400.56,458.75l-1.14-.2.02-.17,1.15.2-.02.17Z"/>
              <path d="m400.45,459.36l-.65-.23c-.25-.09-.39,0-.44.17-.05.18.02.34.26.42l.65.23-.05.16-.64-.22c-.34-.12-.42-.37-.34-.63.07-.25.26-.4.6-.28l.65.22-.05.16Z"/>
              <path d="m399.2,460.01c-.07.04-.15.12-.21.22-.08.15-.06.27.04.34.09.06.18.03.31-.09.16-.15.3-.21.45-.12.16.1.2.31.08.53-.06.11-.13.18-.18.22l-.08-.1s.11-.08.16-.18c.08-.15.03-.26-.04-.3-.09-.06-.18-.02-.31.1-.17.15-.3.19-.45.1-.16-.09-.22-.3-.08-.57.06-.11.15-.21.22-.24l.09.1Z"/>
            </g>
            <path d="m394.38,457.96c.27.05.46.34.41.64-.03.3-.3.52-.58.46-.28-.05-.46-.33-.41-.64.04-.31.31-.52.58-.46Z"/>
            <path d="m394.38,456.5c.28.05.47.34.42.64-.05.3-.3.52-.58.46-.28-.05-.46-.33-.42-.65.05-.31.32-.51.58-.45Z"/>
            <path d="m396.01,457.95c.28.06.46.35.41.66-.04.31-.29.52-.58.46-.28-.05-.47-.33-.42-.64.04-.31.3-.52.58-.48Z"/>
            <path d="m396.11,456.59c.28.05.46.34.41.65-.05.31-.3.52-.57.47-.28-.05-.47-.33-.42-.65.05-.31.3-.51.58-.47Z"/>
          </g>
          <g>
            <polyline   points="394.71 553.31 396.56 553.45 394.56 554.16 396.78 554.36 394.92 555.1 395.8 555.62 395.09 555.88 395.52 556.14 395.11 556.58 395.46 556.89 395.27 557.44"/>
            <path   d="m396.57,545.77c2.17.61,3.48,3.05,2.94,5.48-.54,2.41-2.74,3.89-4.91,3.29-2.16-.61-3.48-3.06-2.95-5.48.55-2.42,2.75-3.89,4.91-3.29Z"/>
            <g>
              <path d="m391.61,548.93c.06-.06.13-.15.16-.25.05-.16,0-.28-.11-.32-.1-.04-.18,0-.29.15-.13.17-.26.26-.41.2-.17-.06-.25-.27-.17-.5.04-.12.09-.21.14-.25l.1.09s-.09.1-.13.21c-.05.17.02.26.09.29.1.04.18-.02.29-.16.14-.18.26-.25.42-.19.17.06.27.26.18.54-.04.12-.11.23-.17.28l-.11-.08Z"/>
              <path d="m391.91,547.26l-.23.43.37.22.26-.48.11.07-.34.63-1.02-.6.32-.6.11.07-.25.46.32.19.23-.43.11.06Z"/>
              <path d="m392.84,546.2c-.13-.11-.28-.24-.38-.35h0c.07.13.14.28.22.44l.26.57-.08.09-.55-.25c-.16-.07-.3-.15-.43-.22h0c.1.11.24.26.36.39l.34.38-.11.11-.8-.9.14-.15.56.25c.14.07.26.13.37.19h0c-.06-.11-.13-.24-.2-.38l-.26-.57.14-.15.91.78-.11.11-.4-.34Z"/>
              <path d="m392.92,545.32c.06-.05.14-.11.26-.18.14-.08.26-.11.35-.09.09.01.17.07.22.16.06.09.07.18.05.27-.03.12-.13.23-.26.31-.04.02-.08.04-.12.06l.24.41-.14.08-.6-1.02Zm.43.42s.08-.03.12-.06c.17-.1.23-.24.14-.38-.08-.14-.22-.14-.37-.05-.06.04-.1.07-.12.09l.24.4Z"/>
              <path d="m394.79,545.17l-.47.14.14.41.52-.16.04.12-.67.21-.38-1.13.65-.2.04.12-.49.15.12.36.47-.14.04.12Z"/>
              <path d="m395.06,544.49c.08-.02.2-.04.31-.04.17-.01.29.01.37.07.07.05.11.13.12.22.01.16-.08.27-.21.32h0c.1.03.17.11.21.23.06.16.1.28.13.32h-.17s-.06-.12-.11-.27c-.05-.16-.13-.22-.27-.22h-.15s.05.52.05.52h-.16s-.11-1.16-.11-1.16Zm.21.53h.17c.17-.02.28-.12.26-.25-.01-.15-.14-.21-.31-.2-.08,0-.13.01-.16.02l.04.43Z"/>
              <path d="m399.83,548.11h-.39s-.06-.17-.06-.17l1.23.03.07.19-.92.8-.06-.17.29-.25-.16-.43Zm.26.36l.26-.23c.06-.05.12-.09.17-.13h0c-.07,0-.14,0-.22,0h-.36s.14.36.14.36Z"/>
              <path d="m400.91,548.96l.02.17-1.01.13.09.55h-.12s-.11-.71-.11-.71l1.14-.14Z"/>
              <path d="m400.91,550.12v-.4s.12,0,.12,0v.96s-.12,0-.12,0v-.4s-1.03-.04-1.03-.04v-.17s1.03.04,1.03.04Z"/>
              <path d="m400.98,551.09l-1.15-.2.02-.16,1.14.2-.02.17Z"/>
              <path d="m400.87,551.69l-.65-.23c-.25-.08-.39,0-.44.17-.05.18.02.34.26.42l.65.23-.05.16-.64-.22c-.34-.12-.42-.36-.34-.63.07-.25.26-.4.6-.28l.65.22-.05.16Z"/>
              <path d="m399.62,552.35c-.07.04-.15.12-.21.22-.08.15-.05.28.05.34.1.06.18.03.31-.09.16-.15.3-.21.44-.12.16.09.2.31.08.52-.06.11-.13.18-.18.22l-.08-.1s.11-.08.16-.18c.08-.15.03-.26-.04-.3-.1-.06-.18-.02-.31.11-.17.15-.3.2-.45.11-.16-.09-.22-.3-.08-.56.06-.11.15-.21.22-.25l.09.1Z"/>
            </g>
            <path d="m394.8,550.29c.28.05.46.33.41.64-.03.31-.3.52-.58.47-.28-.05-.46-.34-.42-.65.04-.31.31-.52.58-.46Z"/>
            <path d="m394.8,548.83c.28.04.46.34.42.64-.04.3-.3.51-.57.46-.28-.05-.46-.32-.42-.65.05-.31.31-.51.58-.45Z"/>
            <path d="m396.43,550.29c.27.06.46.35.41.65-.04.32-.3.52-.58.47-.27-.05-.46-.34-.42-.65.05-.31.3-.52.58-.47Z"/>
            <path d="m396.53,548.92c.27.05.46.34.41.64-.04.31-.29.52-.57.47-.28-.05-.47-.33-.42-.65.05-.31.3-.51.58-.46Z"/>
          </g>
          <g>
            <polyline   points="394.44 646.85 396.3 646.99 394.29 647.71 396.51 647.9 394.65 648.64 395.54 649.16 394.82 649.43 395.25 649.68 394.84 650.13 395.2 650.43 394.99 650.99"/>
            <path   d="m396.3,639.31c2.16.6,3.48,3.06,2.94,5.48-.54,2.41-2.74,3.89-4.91,3.28-2.17-.61-3.48-3.05-2.94-5.48.55-2.41,2.74-3.89,4.91-3.29Z"/>
            <g>
              <path d="m391.34,642.46c.06-.06.13-.15.16-.25.05-.16,0-.28-.1-.32-.1-.04-.18,0-.29.15-.13.17-.26.26-.41.2-.17-.06-.25-.27-.17-.5.04-.12.09-.21.14-.25l.1.09s-.09.1-.13.21c-.05.17.02.26.09.29.1.04.18-.02.29-.16.14-.18.26-.25.42-.19.17.06.27.26.18.54-.04.12-.11.23-.17.28l-.11-.08Z"/>
              <path d="m391.64,640.8l-.23.43.37.22.26-.48.11.07-.34.63-1.02-.6.32-.6.11.07-.24.46.32.19.23-.43.11.07Z"/>
              <path d="m392.57,639.74c-.13-.11-.28-.24-.38-.35h0c.07.13.14.27.22.44l.26.57-.08.09-.55-.25c-.16-.08-.31-.15-.43-.22h0c.1.11.24.26.36.39l.34.38-.11.11-.8-.9.14-.15.56.25c.14.07.26.13.37.19h0c-.06-.11-.13-.24-.2-.38l-.26-.57.14-.15.91.78-.11.12-.4-.34Z"/>
              <path d="m392.65,638.86c.06-.05.14-.11.26-.18.14-.08.26-.11.35-.09.09.01.17.07.22.16.06.09.07.18.05.27-.03.12-.13.23-.26.31-.04.03-.08.05-.12.06l.25.41-.14.08-.6-1.02Zm.43.42s.08-.03.12-.06c.17-.1.22-.24.14-.38-.08-.14-.22-.14-.37-.05-.06.04-.1.07-.12.09l.24.4Z"/>
              <path d="m394.52,638.71l-.47.14.14.41.52-.16.04.12-.67.21-.38-1.13.65-.2.04.12-.49.15.12.36.47-.14.04.12Z"/>
              <path d="m394.79,638.03c.08-.02.2-.04.31-.04.17-.01.29.01.37.08.07.05.11.13.12.22.01.16-.08.27-.21.32h0c.1.03.17.11.21.23.06.16.1.27.13.32h-.17s-.06-.12-.11-.27c-.05-.16-.13-.22-.27-.21h-.15s.05.52.05.52h-.16s-.11-1.16-.11-1.16Zm.21.53h.17c.17-.02.28-.12.26-.25-.01-.15-.14-.21-.31-.2-.08,0-.13.01-.16.02l.04.43Z"/>
              <path d="m399.56,641.65h-.39s-.06-.17-.06-.17l1.23.03.07.19-.92.8-.06-.17.29-.25-.16-.43Zm.26.36l.26-.23c.06-.05.12-.09.17-.13h0c-.07,0-.14,0-.22,0h-.36s.14.36.14.36Z"/>
              <path d="m400.64,642.5l.03.17-1.01.13.09.55h-.12s-.11-.71-.11-.71l1.14-.14Z"/>
              <path d="m400.64,643.62v-.39s.12,0,.12,0v.96s-.11,0-.11,0v-.4s-1.03-.03-1.03-.03v-.17s1.02.03,1.02.03Z"/>
              <path d="m400.71,644.63l-1.14-.2.02-.16,1.14.2-.02.17Z"/>
              <path d="m400.61,645.21l-.65-.23c-.25-.08-.39,0-.44.17-.05.18.02.34.26.42l.65.23-.05.16-.64-.22c-.34-.12-.42-.36-.34-.63.07-.25.26-.4.6-.28l.65.22-.05.16Z"/>
              <path d="m399.35,645.89c-.07.04-.15.12-.21.22-.08.15-.06.27.04.34.09.05.18.03.32-.09.16-.15.3-.21.45-.12.16.09.2.31.08.52-.06.11-.13.18-.18.22l-.08-.1s.11-.08.16-.18c.08-.15.03-.26-.04-.3-.09-.06-.18-.02-.32.11-.17.15-.3.19-.45.11-.16-.09-.22-.3-.08-.56.06-.11.15-.21.22-.25l.09.1Z"/>
            </g>
            <path d="m394.53,643.83c.27.05.46.34.41.65-.03.3-.3.51-.58.46-.27-.04-.46-.33-.41-.64.04-.31.31-.52.58-.46Z"/>
            <path d="m394.53,642.38c.28.05.46.34.41.64-.04.31-.29.51-.57.47-.28-.05-.46-.33-.43-.65.05-.3.32-.52.58-.45Z"/>
            <path d="m396.16,643.83c.28.06.46.35.42.65-.04.31-.3.51-.58.47-.27-.05-.46-.33-.42-.64.04-.32.31-.52.58-.48Z"/>
            <path d="m396.26,642.46c.27.05.46.34.41.64-.05.31-.3.52-.58.47-.28-.06-.46-.33-.42-.65.05-.31.3-.51.58-.47Z"/>
          </g>
        </g>
      </g>
      <path   d="m383.74,114.76s9.61,6.4,23.48-.62"/>
      <path id="Bolsa"   d="m493.86,269.44c11.59,0,23.17.22,34.75-.11,5.36-.15,7.65,1.58,7.56,8.11-.28,22.19-.24,44.38-.1,66.57.05,5.19-1.32,8.31-5.82,10.42-10.89,5.09-21.61,10.67-32.3,16.3-2.8,1.47-5.15,1.7-8.02.27-11.21-5.61-22.5-11.04-33.81-16.41-3.19-1.51-4.58-3.6-4.55-7.81.15-23.53.06-47.05-.11-70.57-.03-5.13,1.5-7.02,6.22-6.88,12.06.35,24.11.12,36.17.11Z"/>
      <path id="outline_camisa_manga_corta" data-name="outline camisa manga corta"   d="m200.5,328.27c-.04-.15-.22-.2-.28-.05-6.99,17.92-12.38,25.38-20.82,48.19-1.11,3-6.3,9.21-8.06,7.35-39.46,1.4-96.57-27.52-115.32-44.69-4.94-4.52,29.78-83.95,33.65-90.18,40.85-88.18,47.33-103.21,83.49-137.56,13.78-13.09,34.31-15.25,51.64-22.53,20.64-8.68,41.56-16.75,62.55-24.58,8.11-3.03,12.06-10.6,16.5-16.16,6.57-8.24,9.65-11.56,13.65-15.56,3.47-3.47,8.48-4.65,24.73-6.94,42.43-6.01,82.28-3.91,124.27,2.94,3.71.61,6,1,9,4,10.75,10.75,18.21,25.17,31.22,33.59,10.22,6.62,22.53,8.14,34.14,10.86,12.64,2.95,24.25,8.64,36.16,13.65,9.9,4.16,19.78,8.39,29.74,12.42,16.96,6.86,29.6,19.96,43.9,30.58,14.56,10.81,24.41,31.58,31.6,47.55,14.46,32.12,23.52,66.46,39.09,98.09,9.62,19.55,16.45,40.08,25.12,60.04-11.16,19.08-95.68,48.7-103.77,50.61-6.14,1.45-9.3,2-13.99-1.49-6.86-5.1-6.57-9.01-10.82-17.57-7.39-12.23-18.93-35.17-23.27-40.33-.38-.45-1.11-.18-1.11.41-.02,20.04-.39,49.72,0,68.59.82,39.94,3.67,80.2,4.73,120.15,1.53,57.6.07,84.19,1.6,141.79.22,8.4.17,16.8.61,25.18.25,4.82-1.17,7.55-6.43,8.39-54.73,8.78-95.87,27.77-149.76,39.46-51.02,11.09-102.27,8.28-152.05-10.11-32.89-12.15-60.23-23.22-95.07-29.05-6.15-1.02-7.26-4.24-7.1-9.64,1.25-44.85.31-58.73,1.38-103.6.91-37.64-2.29-75.27-1.36-112.91.97-39.66,10.46-87.99,11.45-127.66.08-2.95,0-9-1-13.23Z"/>
      <g id="outline_camisa" data-name="outline camisa">
        <polygon id="Puño_izquierdo" data-name="Puño izquierdo"   points="122.66 779 51.73 779 50.4 739.82 122.46 738.81 122.66 779"/>
        <polygon id="Puño_derecho" data-name="Puño derecho"   points="677.99 779 753.32 779 754.73 739.82 678.21 738.81 677.99 779"/>
        <path id="outline_camisa-2" data-name="outline camisa"   d="m199.76,328.47c-10.09,17.71-52.23,118.67-61.31,178.9-1,6.64-12.08,237.73-16,231.44-37.88,1.35-51.12,1.26-72.06,1.01-6.43-.08-14.04-235.92-9.13-239.6C62.93,352.72,118.62,151.11,181.14,106.75c14.88-10.56,32.94-14.64,49.57-21.62,19.81-8.33,39.89-16.08,60.04-23.59,7.78-2.9,11.1-10.58,15.83-15.51,13.92-14.52,5.64-17.19,36.83-21.6,40.73-5.76,81.07-3.69,121.48,2.24,6.33.93,9.7,4.91,13.62,9.85,6.33,7.98,12.75,16.46,20,22,8.01,6.12,17.02,9.04,27.57,12.62,24.12,8.18,47.63,18.09,71.23,27.64,16.27,6.58,28.41,19.16,42.13,29.35,34.44,25.54,103.74,250.64,118.8,361.63,5.08,3.78-.44,245.15-3.5,250.07-24.56,1.22-69.28-.83-76.52-1.01-6.06-.15-15.22-227.11-16.07-232.66-14.3-92.75-37.83-118.51-52.82-150.85-7.83-12.96-12.64-23.74-15.54-24.97,0,19.24-.31,34.75.07,53.29.79,38.34,1.94,76.68,2.96,115.03,1.47,55.28,2.95,110.56,4.42,165.85.22,8.06.17,16.12.58,24.17.24,4.63-1.12,7.25-6.18,8.06-52.52,8.43-102.57,26.65-154.29,37.87-48.97,10.64-98.17,7.95-145.94-9.7-31.57-11.66-63.57-22.29-97.01-27.88-5.9-.98-6.96-4.08-6.82-9.26,1.2-43.05,2.21-86.12,3.24-129.19.87-36.13,1.64-72.25,2.53-108.37.93-38.07,1.94-76.14,2.89-114.21.08-3.01-.47-3.24-.47-7.51Z"/>
      </g>
      <g id="Cuello">
        <path id="Solapa_izquierda" data-name="Solapa izquierda"   d="m395.92,100.89c-.84-3.36-4.09-3.46-6.41-4.28-23.78-8.48-42.47-23.39-56.55-44.26-3.79-5.62-7.69-11.19-11.68-16.67-1.25-1.69-2.52-5.41-5.33-1.64-5.91,7.9-13.67,15.93-19.34,24.01-1.74,2.48.78,5.05,2.15,7.82,13.96,28,33.16,51.92,56.26,72.81,1.83,1.66,2.72,3.22,6.38.32,12.94-10.26,21.79-23.98,32.78-35.83.62-.67,1.11-1.45,1.74-2.28Z"/>
        <path id="Solapa_derecha" data-name="Solapa derecha"   d="m475.29,32.74c-.6-.76-1.75-.76-2.35,0-1.23,1.55-2.25,2.79-3.12,4.11-7.88,11.92-16.74,23.44-27.1,33.33-5.79,5.52-12.13,10.47-18.95,14.65-3.44,2.11-6.99,4.02-10.65,5.73-4.22,1.97-8.7,3.32-12.95,5.23-1.54.69-4.39,1.61-5.27,3.18s.54,3.28,1.65,4.38c11.05,10.94,21.65,22.31,32.17,33.71,3.28,3.56,5.57,3.44,9.11.31,22.83-20.14,41.47-42.7,55.26-69.05,1.23-2.35,6.19-7.97,2-12.68-6.38-7.14-13.49-14.88-19.81-22.9Z"/>
      </g>
      <g id="Costuras">
        <g>
          <path   d="m388.94,98.08c0-.17,0-.35,0-.54.1.15.21.3.31.45"/>
          <path   d="m389.79,98.73c.73.95,1.45,1.72,2.17,2.32"/>
          <path   d="m392.31,101.33c2.98,2.27,5.87,1.49,8.86-2.33"/>
          <path   d="m401.45,98.65c.11-.14.21-.29.32-.44v.54"/>
          <path   d="m401.77,99.82v3.76"/>
          <path   d="m401.77,104.12v102.33c0,168.82.02,337.64,0,506.45,0,4.27.23,8.55-.25,12.84-.43,3.86,1.36,9.18-.38,11.53-1.36,1.86-3.46.6-5.24.67-7.18.29-7.21.28-7.21-17.3-.03-75.15-.06-150.31-.02-225.47.07-130.77.19-261.55.28-392.33,0-.99,0-1.98,0-4.22"/>
        </g>
        <g>
          <path   d="m201.58,334.89c.14-.11.28-.22.42-.32"/>
          <path   d="m202.82,333.9c.95-.79,1.86-1.63,2.74-2.53"/>
          <path   d="m205.93,330.99c42.72-44.84-5.52-209.57-12.61-228.64"/>
          <path   d="m193.13,101.85c-.07-.18-.14-.35-.2-.49"/>
        </g>
        <g>
          <line   x1="194.14" y1="127" x2="194.63" y2="126.79"/>
          <line   x1="195.62" y1="126.37" x2="199.07" y2="124.89"/>
          <line   x1="199.56" y1="124.68" x2="305.42" y2="79.25"/>
          <line   x1="305.92" y1="79.04" x2="306.41" y2="78.82"/>
        </g>
        <g>
          <line   x1="487.57" y1="78.82" x2="488.07" y2="79.04"/>
          <line   x1="489.08" y1="79.48" x2="492.62" y2="81.01"/>
          <line   x1="493.13" y1="81.23" x2="595.82" y2="125.74"/>
          <line   x1="596.32" y1="125.96" x2="596.82" y2="126.17"/>
        </g>
        <g>
          <path   d="m592.74,335.75c-.16-.07-.33-.13-.49-.2"/>
          <path   d="m591.28,335.11c-1.13-.53-2.21-1.14-3.26-1.82"/>
          <path   d="m587.58,332.99c-48.68-33.02-1.53-223.53,15.88-230.31"/>
          <path   d="m603.97,102.53c.18-.03.36-.04.53-.03"/>
        </g>
        <g>
          <path   d="m298.59,58.05c.08.16.16.33.24.49"/>
          <path   d="m299.31,59.51c.57,1.14,1.14,2.27,1.73,3.41"/>
          <path   d="m301.29,63.4c13.39,25.95,31.07,51.76,55.02,71.28,1.8,1.64,2.24,1.77,5.84-1.1,12.4-9.91,20.03-22.63,30.44-34.14"/>
          <path   d="m392.95,99.04c.12-.13.25-.27.37-.4"/>
        </g>
        <g>
          <path   d="m396.55,97.6c.13.13.26.25.39.38"/>
          <path   d="m397.71,98.74c.88.87,1.78,1.77,2.68,2.68"/>
          <path   d="m400.77,101.81c9.75,9.88,20.52,21.97,29.46,31.49,3.2,3.41,3.63,1.69,7.08-1.3,22.25-19.3,39.39-42.16,52.81-67.41,1.13-2.11,4.79-5.74,1.89-9.9"/>
          <path   d="m491.68,54.24c-.11-.14-.23-.28-.36-.42"/>
        </g>
        <path   d="m338.67,58.05c31.49-13.64,80.51-14.22,115.61-2.42"/>
        <path   d="m600.16,683.79c.24,4.62-1.12,7.24-6.17,8.05-52.53,8.43-102.57,26.65-154.29,37.88-48.97,10.64-98.16,7.95-145.93-9.7-31.57-11.66-63.58-22.29-97.02-27.87-5.89-.99-5.17-4.08-5.04-9.26"/>
        <path   d="m534.05,271.6c-.28,21.72-.23,50.36-.05,72.08.05,4.78-1.4,7.28-5.28,9.08-7.94,3.66-16.08,7.12-23.38,12.1-8.38,5.72-15.72,4.61-23.85-.21-7.16-4.24-14.79-7.49-22.31-10.9-3.75-1.7-5.46-4.04-5.41-9,.18-15.94.2-35.95.13-54.21-.03-7.29-.06-12.57-.11-18.93h6s-5.89,18.93-5.89,18.93l79.99-.57-6.3-18.37h6.46Z"/>
        <line   x1="50.59" y1="745.48" x2="122.48" y2="743.92"/>
        <line   x1="51.56" y1="774.19" x2="122.48" y2="774.19"/>
        <line   x1="678.18" y1="743.96" x2="753.5" y2="745.55"/>
        <line   x1="678.18" y1="773.93" x2="753.5" y2="773.93"/>
      </g>
      <g id="Escudo">
        <circle   cx="493.1" cy="323.74" r="25.77"/>
        <circle   cx="685.1" cy="319.74" r="25.77"/>
        <circle   cx="109.1" cy="319.74" r="25.77"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default CamisaSvg
