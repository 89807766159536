import { memo } from 'react'
import { Form, Radio } from 'antd'

export interface RadioFormProps {
  name: string
  type?: string
  initialValue?: string | number | boolean
  options: any
  label?: string
  optionType?: any
  buttonStyle?: any
  rules?: Array<any>,
  disabled?:boolean
}

const FormRadio = memo((props: RadioFormProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      initialValue={props.initialValue}
      hasFeedback
    >
      <Radio.Group
        disabled={props.disabled}
        options={props.options}
        optionType={props.optionType}
        buttonStyle={props.buttonStyle}
      />
    </Form.Item>
  )
})

export default FormRadio
