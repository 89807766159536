import { Checkbox, Table } from 'antd'
import { quotaAuthColumns } from 'hooks/quotas/useQuotas'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import styles from './auth.module.scss'
import Button from 'components/buttons/Button/Button'

interface IQuotasAuth {
  loading: boolean
  handleAllAuthorize: any
  handleAuthorize: any
  selectedRowKeys: any
  setSelectedRowKeys: any
}

const QuotasAuthorizeModule = memo(
  ({
    loading,
    handleAllAuthorize,
    handleAuthorize,
    selectedRowKeys,
    setSelectedRowKeys
  }: IQuotasAuth) => {
    const { quotas } = useSelector((state: RootState) => state.quotas)

    const handleSelect = (record: any, selected: any) => {
      if (selected) {
        setSelectedRowKeys((keys: any) => [...keys, record.id])
      } else {
        setSelectedRowKeys((keys: any) => {
          const index = keys.indexOf(record.id)
          return [...keys.slice(0, index), ...keys.slice(index + 1)]
        })
      }
    }

    const toggleSelectAll = () => {
      setSelectedRowKeys((keys: any) =>
        keys.length === quotas.length ? [] : quotas.map((r: any) => r.id)
      )
    }

    const headerCheckbox = (
      <Checkbox
        checked={selectedRowKeys.length}
        indeterminate={
          selectedRowKeys.length > 0 && selectedRowKeys.length < quotas.length
        }
        onChange={toggleSelectAll}
      />
    )

    const rowSelection = {
      selectedRowKeys,
      onSelect: handleSelect,
      columnTitle: headerCheckbox
    }

    return (
      <>
        <div className={styles.container}>
          <div className={styles.authTable}>
            <Table
              dataSource={quotas}
              loading={loading}
              columns={quotaAuthColumns}
              rowSelection={rowSelection}
              rowKey={(row) => row.id}
            />
          </div>
        </div>

        <div className={styles.button}>
          <Button
            label={'Autorizar'}
            isLoading={loading}
            rounded
            sector='tertiary'
            onClick={() => {
              handleAuthorize(selectedRowKeys)
            }}
          />
          <Button
            label={'Autorizar todo'}
            rounded
            isLoading={loading}
            sector='tertiary'
            onClick={() => {
              handleAllAuthorize()
            }}
          />
        </div>
      </>
    )
  }
)

export default QuotasAuthorizeModule
