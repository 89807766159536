import React from 'react'

const ShadowsShortDeportivoFrontal = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Sombras">
  <g id="Sombra">
    <path d="m352.33,508.99c16.65-10.92,36.31-23.64,52.54-34.85,3.88-2.68,7.82-5.29,11.48-8.23,3.86-4.24,8.65-8.4,14.62-8.89,2.14-.13,4.36.58,5.87,2.09l-.07.07c-5.31-4.33-12.32.19-16.54,4-1.58,1.42-2.71,3.25-4.48,4.48-20.5,14.64-42.21,28.13-63.42,41.33h0Z"/>
  </g>
</g>
    </svg>
  )
}

export default ShadowsShortDeportivoFrontal
