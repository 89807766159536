import React from 'react'
import ConstructorFormulary from './ConstructorFormulary'
import { handleTrajeBanoFemaleForm1pz } from 'redux/reducers/uniformsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { TFormTrajeBanoFemaleOptions } from 'interfaces/uniforms-interface'

const TrajeFem1pzaOptionsEditable = () => {
    const { trajeBanoFemaleForm1pz } = useSelector(
        (state: RootState) => state.uniforms
      )
      const dispatch = useDispatch()
    
      const handleChange = (
        keyName: TFormTrajeBanoFemaleOptions,
        value: string | boolean
      ) => {
        dispatch(
          handleTrajeBanoFemaleForm1pz({
            ...trajeBanoFemaleForm1pz,
            [keyName]: {
              ...trajeBanoFemaleForm1pz[keyName as TFormTrajeBanoFemaleOptions],
              value: value
            }
          })
        )
      }
    
      return (
        <div>
          {(() => {
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={trajeBanoFemaleForm1pz}
              />
            )
          })()}
        </div>
      )
    }

export default TrajeFem1pzaOptionsEditable