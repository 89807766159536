import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  closeBookList: false,
  closeSupplyList: false
}

export const closeListsSlice = createSlice({
  name: 'CloseLists',
  initialState,
  reducers: {
    setCloseBookList: (state, action) => {
      return {
        ...state,
        closeBookList: action.payload
      }
    },
    setCloseSupplyList: (state, action) => {
      return {
        ...state,
        closeSupplyList: action.payload
      }
    }
  }
})

// Metodo global para actualizar el state
export const { setCloseBookList, setCloseSupplyList } = closeListsSlice.actions

export default closeListsSlice.reducer
