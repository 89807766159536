import dayjs from 'dayjs'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useGetInitialParams = () => {
  const { state } = useLocation()
  const dateFormat = 'YYYY/MM/DD'
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [schoolIDGea, setschoolIDGea] = useState(state?.id_gea)
  const [schoolId, setschoolId] = useState(state?.id)
  const [initialDate, setinitialDate] = useState('')

  useEffect(() => {
    
    if (uid.role !== 'Ezetera') {
      setschoolIDGea(uid?.id_gea)
      setschoolId(uid?.id_school)
      setinitialDate(dayjs(uid?.opening_date).format(dateFormat))
    }else{
      setinitialDate(dayjs(state?.opening_date).format(dateFormat))
    }
  }, [uid])

  return { schoolIDGea, schoolId, initialDate }
}
