import React, { useState } from 'react'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  setOutsidePropuesta,
  setOutsidePropuestaBack
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import style from './selectedcardsoption.module.scss'

import { IEdithNotebook } from 'services/notebooks/putNotebook'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { UploadNotebookCovers } from 'services/notebooks/uploadImg'
const ModalUploadOutside = ({
  isModalOpen,
  handleOk,
  handleCancel,
  uuid,
  saveChange
}: {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  uuid: number
  saveChange: (
    data: IEdithNotebook,
    showToast?: boolean | undefined,
    ejecute?: (() => void) | undefined
  ) => void
}) => {
  const { outsidePropuesta, outsidePropuestaBack, college_proposal } =
    useSelector((state: RootState) => state.notebooks)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [fileFront, setFileFront] = useState<any | null>(null)
  const [fileBack, setFileBack] = useState<any | null>(null)

  const onImageChangeFront = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const files: any[] = e.target.files
      setFileFront(files[0])
      let reader = new FileReader()
      reader.onload = (e: any) => {
        dispatch(setOutsidePropuesta(e.target.result as string))
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const onImageChangeBack = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const files: any[] = e.target.files
      setFileBack(files[0])
      let reader = new FileReader()
      reader.onload = (e: any) => {
        dispatch(setOutsidePropuestaBack(e.target.result as string))
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImg = () => {
    if (outsidePropuesta !== '') {
      setLoading(true)
      let dat = new FormData()
      if (fileFront !== null) dat.append('frontCover', fileFront, 'image.png')
      if (fileBack !== null) dat.append('backCover', fileBack, 'image.png')

      UploadNotebookCovers(uuid, dat)
        .then((data) => {
          const { url_back, url_front } = data.data
          let dat = {}
          dat = {
            ...dat,
            college_proposal: true,
            ext_cover_desing: 'upload',
            status: 10
          }
          if (url_front) dat = { ...dat, front_cover_img: url_front }
          if (url_back) dat = { ...dat, back_cover_img: url_back }
          else dat = { ...dat, back_cover_img: 'vacio' }
          saveChange(dat, false, () => {
            SuccessToast('Imagen subida correctamente')
            setLoading(false)
            handleOk && handleOk()
          })
        })
        .catch((e) => {
          console.log(e)
          ErrorToast('Ha ocurrido un error al subir la(s) imagen(es)')
          setLoading(false)
        })
    }
  }

  const getDisabledState = () => {
    let disabled = false
    if (fileFront === null && fileBack === null) disabled = true
    else if (fileFront === null && fileBack !== null && !college_proposal)
      disabled = true
    else if (fileFront !== null && fileBack === null) disabled = false

    return disabled
  }

  const disabledUploadFront = () => {
    let disabled = false
    if (college_proposal && outsidePropuesta !== '') disabled = true
    return disabled
  }
  const disabledUploadBack = () => {
    let disabled = false
    if (
      college_proposal &&
      outsidePropuestaBack !== '' &&
      outsidePropuestaBack !== 'vacio'
    )
      disabled = true
    return disabled
  }
  return (
    <Modal
      title='Subir diseño para portada del cuaderno'
      className='modal-in-upload-img-uniforms'
      centered
      destroyOnClose
      afterClose={() => {
        setFileBack(null)
        setFileFront(null)
      }}
      bodyStyle={{ background: '#F3F7FF' }}
      keyboard
      open={isModalOpen}
      onOk={() => {
        setFileFront(null)
        setFileBack(null)
        handleOk()
      }}
      onCancel={() => {
        setFileFront(null)
        setFileBack(null)
        handleCancel()
      }}
      width={572}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={uploadImg}
            disabled={getDisabledState()}
            isLoading={loading}
            rounded
            label='Subir'
          />
        </div>
      ]}
    >
      <div className={`${style['div-container-modal']}`}>
        <p className={`${style['p-modal']}`}>
          Sube la propuesta para el diseño de tus portadas (delantera y
          trasera), esta sera evaluada.
        </p>
        <div className={`${style['div-portadas-exteriores-container']}`}>
          <div className={`${style['div-cover-white-portadas']}`}>
            {outsidePropuestaBack !== '' &&
              outsidePropuestaBack !== 'vacio' && (
                <div className={`${style['container-notebook-logo']}`}>
                  <img
                    className={`${style['notebook-logo']}`}
                    src={outsidePropuestaBack}
                  />
                </div>
              )}
            <p className={`${style['p-title-text']}`}>* Portada trasera: </p>
            <label
              className={`${
                disabledUploadBack()
                  ? style['disabled-button-upload']
                  : style['button-upload']
              }`}
            >
              <ArrowUpTrayIcon className={`${style['arrow-svg']}`} />
              Subir archivo
              <input
                className={`${style['input-archive-file']} ${
                  !disabledUploadBack() ? style['pointer'] : style['pointer-no']
                }`}
                type='file'
                accept='image/*'
                disabled={disabledUploadBack()}
                onChange={onImageChangeBack}
              />
            </label>

            <p className={`${style['p-description']}`}>
              Resolución recomendada de 640 X 640 px
            </p>
          </div>
          <div className={`${style['div-cover-white-portadas']}`}>
            {outsidePropuesta !== '' && (
              <div className={`${style['container-notebook-logo']}`}>
                <img
                  className={`${style['notebook-logo']}`}
                  src={outsidePropuesta}
                />
              </div>
            )}
            <p className={`${style['p-title-text']}`}>* Portada delantera: </p>
            <label
              className={`${
                disabledUploadFront()
                  ? style['disabled-button-upload']
                  : style['button-upload']
              }`}
            >
              <ArrowUpTrayIcon className={`${style['arrow-svg']}`} />
              Subir archivo
              <input
                className={`${style['input-archive-file']} ${
                  !disabledUploadFront()
                    ? style['pointer']
                    : style['pointer-no']
                }`}
                type='file'
                accept='image/*'
                disabled={disabledUploadFront()}
                onChange={onImageChangeFront}
              />
            </label>

            <p className={`${style['p-description']}`}>
              Resolución recomendada de 640 X 640 px
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploadOutside