import { memo } from 'react'
import { Checkbox, Col, Form, Radio, Row } from 'antd'

export interface RadioFormProps {
  name: string
  type?: string
  initialValue?: string | number | boolean
  options: any
  label?: string
  optionType?: any
  buttonStyle?: any
  rules?: Array<any>
  disabled?: boolean
}

const FormCheckbox = memo((props: RadioFormProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      initialValue={props.initialValue}
      hasFeedback
    >
      <Checkbox.Group>
        <Row gutter={[24,16]}>
          {props.options.map((res: { label: string; value: string }) => {
            return (
              <Col span={24 / props.options.length}>
                <Checkbox value={res.value}>{res.label}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
})

export default FormCheckbox
