import decode from 'jwt-decode'

interface Ijwt {
  exp: number
  iat: number
}

const isAuthenticated = () => {
  const token = sessionStorage.getItem('ezeteraToken')
  let isValid = false
  if (token !== null) {
    const deco: Ijwt = decode(token)
    const now = Math.round(new Date().getTime() / 1000)
    isValid = now < deco.exp
  }
  return isValid
}

export { isAuthenticated }
