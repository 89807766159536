/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { isAuthenticated } from 'utilities/userSession'

function PrivateRoute(): JSX.Element {
  

  if (isAuthenticated() ) return <Outlet />
  return <Navigate to={'/'} />
}
PrivateRoute.displayName = 'PrivateRoute'
export default PrivateRoute
