import { IUserListApi } from 'interfaces/user-management-interface'
import { AUTHSERVICE } from '../config'

const getUsers = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/users`)
}

const userManagementGetUsersList = async () => {
  const results: IUserListApi = await getUsers()
  return results
}
const getUsersBySchool = async (idSchool: number): Promise<any> => {
  return await AUTHSERVICE().get(`/users/school/${idSchool}`)
}

const userManagementGetUsersListBySchool = async (idSchool: number) => {
  const results: IUserListApi = await getUsersBySchool(idSchool)
  return results
}

export { userManagementGetUsersList, userManagementGetUsersListBySchool }
