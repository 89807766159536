import React, { Fragment } from 'react'
import style from '../poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SacoFemeninoSvg from 'assets/img/uniforms/saco/femenino/SacoFemeninoSvg'
import SacoMasculinoSvg from 'assets/img/uniforms/saco/masculino/SacoMasculinoSvg'

const SacoPics = () => {
  const { pictureLogo, female } = useSelector(
    (state: RootState) => state.uniforms
  )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      {female ? (
        <Fragment>
          <div className={`${style[`logo-position-${'left'}`]}`}>
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SacoFemeninoSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={`${style[`logo-position-${'left'}`]}`}>
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <SacoMasculinoSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      )}
    </div>
  )
}

export default SacoPics
