import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './poloeditable.module.scss'
import SacoMasculinoSvg from 'assets/img/uniforms/saco/masculino/SacoMasculinoSvg'
import SacoFemeninoSvg from 'assets/img/uniforms/saco/femenino/SacoFemeninoSvg'

const SacoEditable = () => {
  const { sacoForm, pictureLogo, female } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        {female ? (
          <Fragment>
            {sacoForm.logoPosition.value !== '' && (
              <div
                className={`${
                  style[`logo-position-sacosaco-${sacoForm.logoPosition.value}`]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}

            <div className={`${style['div-polo-svg-absolute']}`}>
              <SacoFemeninoSvg className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}></div>
            <div className={`${style['div-label-svg-absolute']}`}></div>
            <div className={`${style['div-tags-svg-absolute']}`}></div>
          </Fragment>
        ) : (
          <Fragment>
            {sacoForm.logoPosition.value !== '' && (
              <div
                className={`${
                  style[`logo-position-sacosaco-${sacoForm.logoPosition.value}`]
                }`}
              >
                {pictureLogo !== '' && (
                  <img className={`${style['svg-logo']}`} src={pictureLogo} />
                )}
              </div>
            )}
            <div className={`${style['div-polo-svg-absolute']}`}>
              <SacoMasculinoSvg className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}></div>
            <div className={`${style['div-label-svg-absolute']}`}></div>
            <div className={`${style['div-tags-svg-absolute']}`}></div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default SacoEditable
