import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setImgBucket,
  setJsonImgBack,
  setJsonImgFront
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import { getImgsBucket } from 'services/notebooks/getBucketImgs'
import { UploadBucketImg } from 'services/notebooks/uploadBucketImgs'
import style from './selectedcardsoption.module.scss'

const emptyImgJson = (img: string, id: number) => {
  return {
    [id]: {
      id,
      angle: 0,
      show: true,
      img,
      divSize: {
        width: 20,
        height: 20
      },
      position: {
        left: 10,
        top: 10
      }
    }
  }
}

const ModalUploadImgCovers = ({
  isModalOpen,
  handleOk,
  handleCancel,
  id
}: {
  isModalOpen: boolean
  id: number
  handleOk: () => void
  handleCancel: () => void
}) => {
  const { uploadImgBucket, jsonImgBack, jsonImgfront } = useSelector(
    (state: RootState) => state.notebooks
  )

  const dispatch = useDispatch()
  const [file, setFile] = useState<any | null>(null)
  const [disable, setDisable] = useState(false)
  const [selectedCover, setSelectedCover] = useState({
    back: false,
    front: false
  })
  const [selected, setSelected] = useState('')
  const [imgs, setImgs] = useState<
    | {
        img: string
      }[]
    | null
  >(null)

  const onImageChange = (e: any) => {
    setFile(null)
    if (e.target.files && e.target.files[0]) {
      const files: any[] = e.target.files
      setFile(files[0])
      let reader = new FileReader()
      reader.onload = (e: any) => {
        dispatch(setImgBucket(e.target.result as string))
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImg = () => {
    setDisable(true)
    if (uploadImgBucket !== '' && selected === '') {
      let dat = new FormData()
      dat.append('idbook', `${id}`)
      dat.append('files', file, 'image.png')

      UploadBucketImg(dat)
        .then((data) => {
          SuccessToast('La imagen ha sido subida con exito al servidor')
          setDisable(false)
          if (selectedCover.back) addNewImgBack(data.data.url)
          if (selectedCover.front) addNewImgfront(data.data.url)
          onClose()
          handleOk()
        })
        .catch((e) => {
          console.log(e)
          ErrorToast(
            'No se ha podido subir la imagen, contactar con soporte o verificar el formato del archivo.'
          )
          setDisable(false)
        })
    } else {
      if (selectedCover.back) addNewImgBack(selected)
      if (selectedCover.front) addNewImgfront(selected)
      setDisable(false)
      handleOk()
    }
  }

  const onClose = () => {
    setFile(null)
    setSelected('')
    setSelectedCover({
      back: false,
      front: false
    })
    dispatch(setImgBucket(''))
    setDisable(false)
  }

  const getDisableAction = () => {
    let state = true
    if (selected !== '') {
      if (selectedCover.back || selectedCover.front) state = false
    } else {
      if (selectedCover.back || selectedCover.front)
        state = file === null && !disable
    }

    return state
  }

  const getEmptyImgBack = (img: string) => {
    let value = 1
    for (value; value - 1 < Object.keys(jsonImgBack).length; value++) {
      if (!Object.keys(jsonImgBack).includes(`${value}`)) break
    }
    return emptyImgJson(img, value)
  }
  const getEmptyImgFront = (img: string) => {
    let value = 1
    for (value; value - 1 < Object.keys(jsonImgfront).length; value++) {
      if (!Object.keys(jsonImgfront).includes(`${value}`)) break
    }
    return emptyImgJson(img, value)
  }

  const addNewImgBack = (img: string) => {
    dispatch(setJsonImgBack({ ...jsonImgBack, ...getEmptyImgBack(img) }))
  }
  const addNewImgfront = (img: string) => {
    dispatch(setJsonImgFront({ ...jsonImgfront, ...getEmptyImgFront(img) }))
  }

  useEffect(() => {
    getImgsBucket(id).then((dat) => {
      setImgs(dat.data.images)
    })
  }, [isModalOpen])
  return (
    <Modal
      title='Subir logotipo para cuadernos'
      className='modal-in-upload-img-uniforms'
      centered
      bodyStyle={{ background: '#F3F7FF' }}
      keyboard
      destroyOnClose
      open={isModalOpen}
      onOk={() => {
        onClose()
        handleOk()
      }}
      onCancel={() => {
        onClose()
        handleCancel()
      }}
      width={650}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              columnGap: '0.5rem',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() =>
                setSelectedCover({
                  ...selectedCover,
                  back: !selectedCover.back
                })
              }
              sector='secondary'
              background={selectedCover.back ? 'color' : 'white'}
              rounded
              label='Trasera'
            />
            <Button
              onClick={() =>
                setSelectedCover({
                  ...selectedCover,
                  front: !selectedCover.front
                })
              }
              sector='secondary'
              background={selectedCover.front ? 'color' : 'white'}
              rounded
              label='Delantera'
            />
            {!selectedCover.front && !selectedCover.back && (
              <p className={`${style['p-select-cover']}`}>
                *{'<'}-Selecciona una portada
              </p>
            )}
          </div>
          <Button
            onClick={uploadImg}
            disabled={getDisableAction()}
            rounded
            label={selected !== '' ? 'Utilizar' : 'Subir'}
            isLoading={disable}
          />
        </div>
      ]}
    >
      <div
        className={`${
          imgs !== null && imgs.length !== 0
            ? style['div-modal-upload-editable']
            : style['div-modal-upload-editable-normal']
        }`}
      >
        {imgs !== null && imgs.length !== 0 && (
          <div className={`${style['container-uploaded-img']}`}>
            {imgs.map((item, index: number) => {
              return (
                <Fragment key={index}>
                  <img
                    onClick={() => {
                      if (selected === item.img) {
                        setSelected('')
                        dispatch(setImgBucket(''))
                        setFile(null)
                      } else {
                        setSelected(item.img)
                        dispatch(setImgBucket(item.img))
                        setFile(null)
                      }
                    }}
                    src={item.img}
                    className={`${style['inserver-img-cover']} ${
                      selected === item.img &&
                      style['inserver-img-cover-selected']
                    }`}
                  />
                </Fragment>
              )
            })}
          </div>
        )}
        <div style={{ paddingLeft: '1rem' }}>
          <p className={`${style['p-modal']}`}>
            Sube un logotipo para las portadas de tus cuadernos, de preferencia
            una imagen con trasparencia del tipo archivo.png con una reslucion
            aceptable.
          </p>
          <div className={`${style['div-cover-white']}`}>
            {uploadImgBucket !== '' && (
              <div className={`${style['container-notebook-logo']}`}>
                <img
                  className={`${style['notebook-logo']}`}
                  src={uploadImgBucket}
                />
              </div>
            )}
            <p className={`${style['p-title-text']}`}>* Sube tu imagen: </p>
            <label
              className={`${
                selected !== ''
                  ? style['disabled-button-upload']
                  : style['button-upload']
              }`}
            >
              <ArrowUpTrayIcon className={`${style['arrow-svg']}`} />
              Click para subir archivo
              <input
                className={`${style['input-archive-file']} `}
                type='file'
                accept='image/*'
                onChange={onImageChange}
                disabled={selected !== ''}
              />
            </label>

            <p className={`${style['p-description']}`}>
              Resolución recomendada de 640 X 640 px
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploadImgCovers
