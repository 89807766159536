import { SuccessToast } from 'components/Toasts/Toasts'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import {
  getGeneralModulesStatus,
  sendEndListPDF
} from 'services/genericServices/genericServices'
import { handleErrors } from 'utilities/handleErrors'

export function useEndListModal() {
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)
  const { pathname } = useLocation()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const openModalByModule = (status: any) => {
    if (uid.role === 'Ezetera') return
    if (uid.role === 'Responsable de listas') return
    if (
      pathname.includes('utiles') &&
      status.supplies_status === 'Completado' &&
      !status.pdfsupply
    )
      dispatch(setCloseSupplyList(true))
    if (
      pathname.includes('uniforms') &&
      status.uniforms_status === 'Completado' &&
      !status?.pdfuniform
    )
      dispatch(setCloseSupplyList(true))
    if (
      pathname.includes('libros') &&
      status.books_status === 'Completado' &&
      !status.pdfbook
    )
      dispatch(setCloseSupplyList(true))
    if (
      pathname.includes('cuadernos') &&
      status.notebooks_status === 'Completado' &&
      !status.pdfnotebook
    )
      dispatch(setCloseSupplyList(true))
    if (
      pathname.includes('cuotas') &&
      status.quotas_status === 'Completado' &&
      !status.pdfquotas
    )
      dispatch(setCloseSupplyList(true))
  }

  const checkIfListAreDone = async () => {
    if (uid.role === 'Ezetera') return
    setloading(true)
    try {
      const result = await getGeneralModulesStatus()
      openModalByModule(result.data)
    } catch (err: any) {
      console.log(err)
    } finally {
      setloading(false)
    }
  }
  useEffect(() => {
    checkIfListAreDone()
    return () => {
      dispatch(setCloseSupplyList(false))
    }
  }, [])

  const handleCloseModal = () => {
    dispatch(setCloseSupplyList(false))
  }

  const handleEndList = async () => {
    if (uid.role === 'Ezetera') return
    setloading(true)
    let pdfType = 'books'
    if (pathname.includes('utiles')) pdfType = 'supply'
    if (pathname.includes('uniforms')) pdfType = 'uniforms'
    if (pathname.includes('cuadernos')) pdfType = 'notebook'
    if (pathname.includes('cuotas')) pdfType = 'quotas'

    try {
      await sendEndListPDF(pdfType)
      SuccessToast('Un pdf con la lista fue enviado a su correo')
      handleCloseModal()
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloading(false)
    }
  }

  return {
    loading,
    handleEndList,
    handleCloseModal
  }
}
