import React, { memo, useState } from 'react'
import PicazoBackground from 'components/picazoBackground/PicazoBackground'
import S from 'components/texts/S/S'
import FormSelect from 'components/formInputs/select'
import { Form, Row } from 'antd'
import { chooseSchool } from 'services/auth/users'
import { handleErrors } from 'utilities/handleErrors'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { setUserSession } from 'redux/reducers/auth'
import { useDispatch } from 'react-redux'
import Button from 'components/buttons/Button/Button'

const index = memo((): any => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [loading, setloading] = useState(false)
  const [form] = Form.useForm()

  const onSubmit = async (values: any) => {
    setloading(true)
    try {
      const result = await chooseSchool(values.school)
      const token: string = result?.data.token ?? ''
      sessionStorage.setItem('ezeteraToken', token)
      dispatch(setUserSession(result?.data))
      navigate('/inicio')
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false)
    }
  }
  return (
    <PicazoBackground>
      <div>
        <br></br>
        <S variation='2'> En que colegio deseas trabajar</S>
        <br></br>
        <Form
          onFinish={onSubmit}
          layout='vertical'
          autoComplete='off'
          /* className={style.CreateSchoolForm} */
          form={form}
        >
          <FormSelect
            name={'school'}
            options={uid.schools.map((res) => {
              return { label: res.name, value: res.id_school }
            })}
            placeholder={'Escoge un colegio'}
            /* label={'Colegios'} */
            rules={[{ required: true, message: '' }]}
          />
          <Row style={{justifyContent:'center'}}>
            <Button
              rounded
              sector='secondary'
              buttonType='submit'
              isLoading={loading}
              label='Vamos'
            />
          </Row>
        </Form>
      </div>
    </PicazoBackground>
  )
})
index.displayName = 'ChooseSchool'
export default index
