import { getShadow } from 'pages/utils/uniforms/getShadows'
import React from 'react'

const ShadowsSportShiertFemale = ({
  className,
  stroke = '#000000',
  fill="#000000"
}: {
  className: string
  stroke: string
  fill?: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Sombras' stroke={getShadow(fill)} fill={getShadow(fill)}>
        <g id='Sombras-2' data-name='Sombras'>
          <path d='m55,176c16.36-19.76,45-58.12,53-69,16.39-22.26,23.79-38.82,44.38-50.6-.82,4.95.02,9.53-.01,14.59-1.45,1.1-7.41,3.77-8.78,5.02-21.15,19.98-25.54,45.23-45.52,65.21-21.15,22.33-30.17,37.61-3.13,61.12,24.67,23.49,28.15,39.31,59.8,65.31,19.22-65.55-3.4-104.48-6.53-149.93-.88-7.89-1.13-30.1,4.96-38.84.52,9.81,1.66,15.4,2.83,25.12,2.04,16.9,5.93,31.32,10.48,47.96,3.66,13.41,5.33,23.77,9.52,37.04,2.09,6.64,3.08,12.98,3,18-.84,10.22-.93,10.91-6,21-1.65,5.85-3.87,17.82-6,24-1,4-3.31,22.6-8,32-3.5,0-7.08-.83-10.49-1.53-1.77-.36-3.53-.75-5.28-1.18-1.37-.34-2.86-.53-4.08-1.29-1.11-.69-2.12-1.53-3.17-2.31s-2.2-1.5-3.33-2.21c-2.13-1.32-4.26-2.62-6.31-4.06-1.51-1.06-2.98-2.18-4.34-3.42-5.76-5.21-11.64-8.71-18.14-14.36-10.76-9.37-27.18-30.51-38.86-39.64-12.17-9.51-19.67-22.64-17-30' />
          <path d='m736,219c-3.36,3.22-18.25,21.63-29,31-6.51,5.65-14.24,11.78-20,17-5.74,5.22-17,12-24,17-4.62,3.57-13.46,3.97-19,2-4.7-9.4-8.82-21.83-11.42-31.48-2.13-6.18-4.92-12.67-6.58-18.52-5.07-10.08-3.16-5.78-4-16-.09-5.02,2.91-17.36,5-24,4.18-13.27,8.34-28.6,12-42,4.56-16.64,6.95-33.1,9-50,1.18-9.72,1.61-20.31,2.14-30.12,6.09,8.75,4.42,37.81,3.53,45.71-3.13,45.44-25.73,84.38-6.52,149.92,31.64-25.99,35.13-41.81,59.8-65.31,27.03-23.5,18.02-38.79-3.13-61.11-19.97-19.97-31.51-51.05-52.66-71.03-1.37-1.25.07-.15-1.37-1.25-.04-5.06,1.07-9.94.25-14.89,8.97,2.44,34.89,32.83,47.35,49.76,8.01,10.88,42.26,58.58,58.62,78.33,0,0,4,12-20,35Z' />
          <path d='m173.62,228.7c.65-5.38.14-11.72-3.63-16.9-3.53-5.14-13.42-10.07-25.63-7.8,20.85,1.68,29.97,17.4,29.26,24.7Z' />
          <path d='m626.06,735.51c.84-6.57-2.7-29.12-3.63-36.52-4.64-37.08-4.96-14.24-18.18-55.11-7.33-22.67-17.57-42.16-24.23-81.47-2.11-12.51-1.4-27.19-3.46-36.74-3.75-17.45-7.41-23.24-6.66-31.68.27-3.03-1.72-5.45-1.59-11.72.1-5.56,1.3-14.97,2.13-22.09.34-2.98,1.56-7.78,2.13-10.92,1.69-9.24,1.54-27.1,2.13-30.88.85-5.42,3.34-6.98,4.52-11.98,14.96-44.75,34.61-186.41,48.42-221.37,2.21-6.92,4.48-14.08,6.64-21.63,2.18-7.56,3.09-16.88,4.71-25.39,4-21,5.97-37.77,8.64-58.69.01-.23.03-.45.03-.67.18-6.96.38-18.11.1-25.73-2.81-.71-5.83-2.2-8.53-2.91-4.41,38.38-10.91,73.24-16.76,96.71-2.93,11.73-5.67,21.77-7.89,30.33-.75,18.08-25.61,24.86-23.35,33.9,1.04,51.78-1.19,124.93-62.43,183.1,18.08,9.04,26.26,6.77,41.04,20.99.79,2.73-.54,7.67-3.14,13.58-7.77,17.87-27.83,41.89-33.48,49.8,27.12,16.58-18.84,45.21-18.84,66.31,36.16-15.07,28.63,70.81,33.15,91.91,4.1,21.54,39.12,20.94,41.44,48.22,6.78,79.86-157.66,67.47-226.97,64.46-91.92-6.03-179.11-47.88-114.32-118.7,28.36-31.01-4.38-81.67,14.32-119.04,6.77-13.56,5.08-30.52-3.82-54.04-2.97-7.84-6.74-16.41-11.26-25.82-1.51-24.11,8.2-27.23,26.28-38.53-51.99-62.82-55.42-142.74-57.69-181.36-11.3-6.78-8.52-3.12-17.56-.86-2.26-9.04,4.52-15.82,5.97-23.63,2.65-9.98,2.32-20.81-6.73-17.81-13.55,3.02-5.28,23.03-11.01,25.01,0,0-.75-3.29-2.45-9.36-1.81-6.19-4.34-14.87-7.24-24.81-11.61-39.75-22.13-97.87-22.13-97.87-.65,14.73,2.03,28.24,4.64,42.52,3.4,18.59,7.48,38.07,12,59,1.36,6.31,9.68,34.49,10,46,.14,5.13-2,15-1,21,4.7,28.22,41.47,138.49,43.58,196.43.14,3.82,2.67,13.54,4.37,22.78.57,3.14-.59,7.39-.25,10.37.82,7.12,1.87,13.94,1.97,19.5.12,6.26-.26,11.05,0,14.07.76,8.44-2.79,15-6.91,32.34-2.31,9.68-3.05,21.21-5.16,33.72-6.66,39.3-18.77,62.16-26.09,84.88-10.93,33.96-16.06,62.95-21,81.2-1.66,6.12,3.09,7.18,5.69,12.42,20.85,9.08,76.16,27.06,121.05,36.13,23.55,4.76,36.65,8.1,59.53,8.1,10.97,0,24.46.95,36.03.95.41,0,10.17-.67,10.58-.67,11.49,0,20.66-.94,31.56-2.12,23.39-2.52,48.47-4.81,72.77-7.05,65.39-6.04,100.36-20.59,118.33-38.56Z' />
          <path d='m177.4,226.76c1-7.83,1.12-14.67-1.22-19.78-2.33-5.11-7.63-9.43-17.43-12.45,5.27,6.03,9.89,10.84,12.93,15.43,3.04,4.58,5.05,9.6,5.71,16.8Z' />
          <path d='m622.18,227.48c-8.37-17.55-10.63-40.15-26.45-55.98,0,15.82,13.56,27.13,19.05,39.61,3.21,5.61,5.88,11.26,7.4,16.36Z' />
          <path d='m625.49,233.62c-1.09-8.42-1.8-17.72-.57-25.92,1.24-8.19,5.89-17.23,12.67-24,0,12.43-4.62,20.27-7.73,26.56-3.11,6.29-5.65,12.02-4.37,23.36Z' />
          <path d='m626.3,234.27c-.64-5.38-.14-11.72,3.63-16.91,3.52-5.14,13.42-10.07,25.63-7.8-20.85,1.68-29.98,17.41-29.26,24.7Z' />
          <path d='m626.07,238.88c-6.81-23.99-27.15-44.33-52.01-53.37,18.08,9.04,33.9,20.34,43.75,38.31,3.43,5.32,6.21,10.53,8.27,15.06Z' />
          <path d='m177.34,226.76c8.87-15.95,13.4-34.02,31.48-45.33-4.51,6.78-9.04,15.83-15.72,20.13-5.93,6.87-11.64,14.72-15.75,25.2Z' />
          <path d='m178.96,228.7c10.32-25.81,28.4-46.16,55.53-52.93-13.57,9.04-29.39,13.56-39.39,27.63-7.53,8.67-11.77,16.57-16.14,25.3Z' />
        </g>
      </g>
    </svg>
  )
}

export default ShadowsSportShiertFemale
