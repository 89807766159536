import React from 'react'

const ShadowsChamarraCapitonada= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m226.86,458.34s-.06-53.68,2.97-51.66c3.01,2.01,22.23,33.52,22.23,33.52,0,0,.86-8.44,0-11.08-3.45-10.49-16.7-40.57-22.23-50.12-5.88-10.14-28.86-38.68-23.12-28.46,8.59,15.3,13.13,27.55,13.13,27.55l7.02,80.26Z"/>
      <path   d="m474.75,723.02l94.71-20.14,11.09,3.02s-31.24,12.09-35.26,13.1c-4.03,1.01-70.53,4.03-70.53,4.03Z"/>
      <path   d="m342.5,718.55c-11.9,13.8-36.74,9.89-69.87-6.35-14.35-7.03-27.83,11.26-47.06-14.71-.76-1.03,25.11,15.11,25.29,13.84,1.86-13.67,85.49,22.13,91.67,4,.26-.77.5,2.6-.03,3.21Z"/>
      <path   d="m554.82,424.32c-2-7.5,13.81-18.75,18.14-25.2,8.61-12.83,20.15-58.44,20.15-58.44,0,0,4.62,71.08-8.06,89.67-1.27,1.86-5.81,3.91-8.06,4.03-6.08.33-20.6-4.19-22.17-10.07Z"/>
      <polygon   points="156.87 424.2 149.82 459.47 125.64 503.8 159.89 465.52 156.87 424.2"/>
      <path   d="m641.72,201.37c.53-5.88-.89-21.88-4.48-25.63-5.52-5.79-27.7-26.7-23.49-19.89,4.17,6.77-14.11,4.98-18.15,4.57-7.93-.81,27.96,4.67,34.33,10.13,5.69,4.86,11.14,38.28,11.8,30.82Z"/>
      <path   d="m668.5,468.04c-6.04,16.9-14.27,27.39-12.2,38.09,2.23,11.59,7.65,19.68,18.49,34.91,4.83,6.78-28.05-28.64-29.67-36.8-2.4-12.22,27.56-47.91,23.38-36.19Z"/>
      <path   d="m150.46,253.85s7.83-52.05,14.99-68.13c3.07-6.9,18.84-20.72,22.28-24.54,3.44-3.82,16.53-10.12,16.53-10.12,0,0-26.25,2.54-33.47,7.12-6.17,3.9-13.48,17.69-15.75,24.64-5.53,16.91-4.56,71.04-4.56,71.04Z"/>
      <polygon   points="78.4 716.56 128.77 721.59 106.6 724.62 76.38 725.62 78.4 716.56"/>
      <polygon   points="719.28 716.16 668.91 721.23 691.08 724.24 721.31 725.22 719.28 716.16"/>
      <path   d="m386.37,53.2c16.11,1.07-36.94,3.82-35.39,7.88,1.68,4.4,15.47,13.65,22.24,17.85,5.38,3.35-32.6-17.96-33.79-21.07-1.79-4.63,43.03-4.92,46.94-4.66Z"/>
      <path   d="m424.34,89.29c4.47-6.34-5.74,27.42,10.68,29.26,15.35,1.71,48.01-29.43,48.01-29.43,0,0-36.18,39.05-58.69,38.91-2.25-.01,0-7.22,0-9.48,0-14.95-1.78-26.73,0-29.26Z"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsChamarraCapitonada