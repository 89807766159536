import React, { Fragment } from 'react'
import style from './art.module.scss'

const SvgModuleText = ({
  words,
  fontFamily,
  interlin,
  fillull
}: {
  words: string
  fontFamily: string
  interlin: number
  fillull: string
}) => {
  const ModulodePrueba = ({
    fontFam,
    text,
    interlineado = 0.8
  }: {
    fontFam: string
    text: string
    interlineado: number
  }) => {
    return (
      <tspan x={0} y={`${interlineado}em`} className={`${style[fontFam]}`}>
        {text}
      </tspan>
    )
  }

  const SvgBody = ({ viewBox, fill }: { viewBox: string; fill: string }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox}
        fill={fill}
        className={`${style['svg']}`}
      >
        <text fontSize={10} x={0} y={0} className={`${style['text']}  `}>
          {words.split('\n').map((item: string, index: number) => {
            return (
              <Fragment key={index}>
                <ModulodePrueba
                  fontFam={fontFamily}
                  text={item}
                  interlineado={interlin * (index + 1)}
                />
              </Fragment>
            )
          })}
        </text>
      </svg>
    )
  }

  return (
    <Fragment>
      {(() => {
        const getViweBox = () => {
          let view = '0 0 '
          let x = 5
          let y = 10
          let xLength = 0
          let forInter = interlin / 0.8

          const newArr = words.split('\n')
          newArr.forEach((item: string) => {
            if (item.length > xLength) xLength = item.length
          })

          return view + ` ${x * xLength} ` + `${y * newArr.length * forInter}`
        }
        return <SvgBody fill={fillull} viewBox={getViweBox()} />
      })()}
    </Fragment>
  )
}

export default SvgModuleText
