import { Form } from 'antd'
import Button from 'components/buttons/Button/Button'
import FormInput from 'components/formInputs/input'
import Icon from 'components/icons'
import { ErrorToast } from 'components/Toasts/Toasts'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeAction } from 'redux/reducers/books'
import { RootState } from 'redux/store/store'
import { searchBook } from 'services/books/books'
import { AUTHSERVICE } from 'services/config'
import style from '../../../modules/Utils/AddUtilsForm/AddUtilsForm.module.scss'
import ButtonStyles from '../../buttons/Button/button.module.scss'

type FormParams = {
  data: any
  onSubmitFinished: () => void
}

export const SuggestionsForm = ({ data, onSubmitFinished }: FormParams) => {
  const [showForm, setShowForm] = useState(false)
  const [savingData, setSavingData] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [skuValue, setSkuValue] = useState<string | null>(null)
  const [disableForm, setDisableForm] = useState(false)

  const location = useLocation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { sendAction } = useSelector((state: RootState) => state.books)

  const submit = async (id: number, values: any) => {
    setSavingData(true)

    try {
      // create the suggestion
      await AUTHSERVICE().post('/suggestions', {
        ...values,
        id_book_school: data?.id,
        id_grade: data?.id_grade
      })
      // update the book status
      await AUTHSERVICE().put('/books/school/all', {
        id: [data?.id],
        status: 3
      })
      onSubmitFinished()
      dispatch(changeAction(!sendAction))
      window.location.reload()
    } catch (error: any) {
      if (error?.response?.data?.error)
        return ErrorToast(error.response.data.error)

      ErrorToast('Ocurrió un error, inténtelo más tarde.')
    } finally {
      setSavingData(false)
    }
  }

  const handleSearchSku = async () => {
    if (!skuValue) return

    form.resetFields(['name', 'price'])
    setLoadingData(true)

    try {
      const res = await searchBook(skuValue, 1)
      const { data } = res.data
      const { books: details } = data
      const first = details[0]

      if (first) {
        setDisableForm(true)
        const { name, price, picture } = first
        form.setFieldValue('name', name)
        form.setFieldValue('price', !price ? '0.0' : price)
      } else {
        setDisableForm(false)
      }
    } catch (error) {
      ErrorToast('No se encontró información para el ISBN.')
    }

    setLoadingData(false)
  }

  return (
    <div>
      {showForm ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              onClick={() => {
                setDisableForm(false)
                form?.resetFields(['name', 'price'])
              }}
              buttonType='submit'
              size='sm'
              rounded
              background='white'
              sector='primary'
            >
              Limpiar
            </Button>
          </div>
          <Form
            onFinish={(values) => submit(location?.state?.id, values)}
            layout='vertical'
            autoComplete='off'
            className={style.CreateSchoolForm}
            form={form}
            disabled={savingData}
            initialValues={{}}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <FormInput
                  name='sku'
                  placeholder='ISBN'
                  label='ISBN'
                  maxLength={13}
                  type='text'
                  disabled={disableForm}
                  onChange={(e) =>
                    setSkuValue(e.target.value ? e.target.value : null)
                  }
                  rules={[{ required: true, message: '' }]}
                  hasFeedback={false}
                />
              </div>
              {!disableForm && (
                <>
                  {loadingData ? (
                    <div
                      style={{ margin: '4px' }}
                      className={`${ButtonStyles[`button-loader-m`]}`}
                    ></div>
                  ) : (
                    <span
                      style={{
                        margin: '4px',
                        cursor: 'pointer',
                        ...(skuValue ? {} : { opacity: 0.3 })
                      }}
                      onClick={handleSearchSku}
                    >
                      <Icon name='search' width={16} height={16} />
                    </span>
                  )}
                </>
              )}
            </div>
            <FormInput
              name='name'
              placeholder='Nombre del libro'
              label='Nombre'
              maxLength={100}
              type='text'
              disabled={disableForm}
              rules={[{ required: true, message: '' }]}
              hasFeedback={false}
            />
            <FormInput
              name='price'
              placeholder='Precio'
              label='Precio'
              maxLength={100}
              type='text'
              disabled={disableForm}
              rules={[
                { required: true, message: '' },
                {
                  validator: async (_: any, value: string) => {
                    if (!value) return Promise.reject('')
                    if (!value.match(/^\d*\.?\d*$/)) return Promise.reject('')
                    return Promise.resolve()
                  }
                }
              ]}
              hasFeedback={false}
            />
            <div>
              <Button
                isLoading={savingData}
                buttonType='submit'
                size='m'
                rounded
                customStyle={ButtonStyles['w-100']}
                sector='primary'
              >
                Agregar
              </Button>
            </div>
            <div style={{ marginTop: '12px' }}>
              <Button
                buttonType='submit'
                size='m'
                rounded
                customStyle={ButtonStyles['w-100']}
                background='white'
                sector='primary'
                onClick={() => setShowForm(false)}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <div>
          <Button
            disabled={savingData}
            buttonType='submit'
            size='m'
            rounded
            customStyle={ButtonStyles['w-100']}
            background='white'
            sector='primary'
            onClick={() => setShowForm(true)}
          >
            Agregar sugerencia
          </Button>
        </div>
      )}
    </div>
  )
}
