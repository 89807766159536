import React, { Fragment,useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'

import ShortDeportivoFrontalSvg from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/ShortDeportivoFrontalSvg'
import ShadowsShortDeportivoFrontal from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/ShadowsShortDeportivoFrontal'
import LabelShortDeportivoFrontal from 'assets/img/uniforms/sportOutfit/ShortDeportivo/shortDeportivoFrontal/LabelShortDeportivoFrontal'
import { handleClotheName } from 'redux/reducers/uniformsSlice'

const ShortDeportivosEditable = () => {
  const { shortDeportivaForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      handleClotheName({
        name: 'Short deportivo',
        description: 'Descripcion del short deportivo'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {shortDeportivaForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-shortdepo-${shortDeportivaForm.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <ShortDeportivoFrontalSvg className={`${style['svg']}`} />
          </div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <ShadowsShortDeportivoFrontal className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <LabelShortDeportivoFrontal className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}
export default ShortDeportivosEditable
