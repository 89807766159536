import { memo } from 'react'
import { Row } from 'antd'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import PreBuyFooter from 'components/PreBuyFooter/PreBuyFooter'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import HomeModule from 'modules/home/homeModule/homeModule'

const HomePage = memo(() => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  if (uid.role !== 'Ezetera') return <HomeModule />
  return (
    <MainTemplate>
      <Row align='middle' gutter={[0, 16]}>
        empty
      </Row>
    </MainTemplate>
  )
})

HomePage.displayName = 'HomePage'
export default HomePage
