import { TFormTShirtOptions } from 'interfaces/uniforms-interface'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleTShirtFemaleForm,
  handleTShirtMaleForm
} from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import styled from './clotheoptions.module.scss'
import ConstructorFormulary from './ConstructorFormulary'

const TShirtOptionEditable = () => {
  const { female, TShirtFemale, TShirtMale } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormTShirtOptions,
    value: string | boolean
  ) => {
    if (female)
      dispatch(
        handleTShirtFemaleForm({
          ...TShirtFemale,
          [keyName]: {
            ...TShirtFemale[keyName as TFormTShirtOptions],
            value: value
          }
        })
      )
    else
      dispatch(
        handleTShirtMaleForm({
          ...TShirtMale,
          [keyName]: {
            ...TShirtMale[keyName as TFormTShirtOptions],
            value: value
          }
        })
      )
  }

  return (
    <div>
      {(() => {
        return (
          <Fragment>
            {female ? (
              <ConstructorFormulary
                handleChange={handleChange}
                form={TShirtFemale}
              />
            ) : (
              <ConstructorFormulary
                handleChange={handleChange}
                form={TShirtMale}
              />
            )}
          </Fragment>
        )
      })()}
    </div>
  )
}
export default TShirtOptionEditable
