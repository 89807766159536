import React from 'react'

const ShadowsCapitonados= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path d="m253.16,249.33s-3.55,58.03-.73,56.01c2.81-2.01,21.95-35.05,21.95-35.05,0,0,.22,9.17-.72,11.98-3.75,11.15-17.49,42.97-23.03,53-5.89,10.64-28.19,40.27-22.42,29.53,8.64-16.08,13.48-29.08,13.48-29.08l11.47-86.39Z"/>
      <path d="m434.73,687.83l111.97-17.95,13.11,2.69s-36.93,10.78-41.69,11.67c-4.77.9-83.39,3.59-83.39,3.59Z"/>
      <path d="m365.32,685.02c-12.24,12.19-38.11,8.45-72.72-6.35-15-6.42-28.83,9.76-49.08-13.58-.81-.92,26.26,13.72,26.43,12.59,1.8-12.17,89.12,20.57,95.37,4.47.26-.68.55,2.32,0,2.87Z"/>
      <path d="m551.02,196.83c0,15.71,6.3,73.38,5.35,80.23-5.16,37.12,2.16,69.82,14.17,77.47,0,0-15.51-4.47-19.52-17.52-.59-1.92-11.78-40.65-5.93-96.73.57-5.4,5.93-48.87,5.93-43.45Z"/>
      <path d="m386.8,90.98c14.35.95-32.92,3.4-31.54,7.02,1.5,3.92,13.79,12.16,19.82,15.91,4.8,2.98-29.05-16.01-30.11-18.77-1.6-4.13,38.34-4.39,41.83-4.15Z"/>
      <path d="m420.63,123.14c3.99-5.65-5.12,24.44,9.51,26.07,13.68,1.53,42.79-26.22,42.79-26.22,0,0-32.24,34.79-52.3,34.66-2.01-.01,0-6.43,0-8.44,0-13.32-1.59-23.82,0-26.07Z"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsCapitonados