import S from 'components/texts/S/S'
import { useRenderList } from 'hooks/comments/useRenderList'
import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Skeleton } from 'antd'
import Caption from 'components/texts/Caption/Caption'

const RenderSchoolList = memo(() => {
  const { pathname } = useLocation()
  const { schoolList, loading } = useRenderList()
  const nombre = () => {
    if (pathname.includes('libros')) return 'libro'
    return 'útil'
  }

  if (!pathname.includes('utiles') && !pathname.includes('libros')) return null
  if (loading) return <Skeleton active />
  if (schoolList.length > 0)
    return (
      <div>
        <br></br>
        <S variation='2'>Las unidad(es) educativa(s):</S>
        <ul>
          {schoolList.map((res) => {
            return (
              <li key={`${res.id_school}-${res.name_school}`}>
                <span>{res.name_school}</span>
                {
                  (schoolList?.length > 1) && (
                    <span style={{ color: 'rgb(120,121,122)' }}>
                      {res?.origin ? ' ->(Poseedor)' : ''}
                    </span>
                  )
                }
              </li>
            )
          })}
        </ul>
        <Caption>
          Poseen este {nombre()}, por lo qué, al ser validado acá, también lo será
          en las opciones mostradas.
        </Caption>
      </div>
    )
  return (
    <div>
      <br></br>
      <Caption>Este útil, no se encuentra en otras unidades educativas</Caption>
    </div>
  )
})
RenderSchoolList.displayName = 'RenderSchoolList'
export default RenderSchoolList
