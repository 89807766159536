import { Col, Form, Modal, Row, Tooltip } from 'antd'
import Button from 'components/buttons/Button/Button'
import { useRequireUtilsForm } from 'hooks/utils/useRequireUtilsForm'
import { useLocation } from 'react-router-dom'
import style from './AdminUtilsManagement.module.scss'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import style2 from './../CreateUtilsForm/CreateUtilsForm.module.scss'
import UploadInput from 'components/inputs/UploadInput'
import FormSelect from 'components/formInputs/select'
import FormAutoComplete from 'components/formInputs/autocomplete'
import FormInput from 'components/formInputs/input'
import FormRadio from 'components/formInputs/radio'
import Modals from 'components/modal'
import ErrorAlert from 'assets/img/errorAlert.svg'
import styles from '../../books/formComments/formComments.module.scss'
import B from 'components/texts/B/B'
import { useState } from 'react'
import globalStyle from 'styles/global.module.scss'

const ManagementUtilsForm = ({
  updateSupplies
}: {
  updateSupplies?: (id: number) => void
}) => {
  const {
    loadingPost,
    schoolData,
    formEditorial,
    INPUTS_INITIAL_STATE,
    form,
    handleFileChange,
    onSubmit,
    isStatusGT4,
    modalMake,
    setmodalMake,
    OptionBrand,
    postNewMakeSupply,
    onHandleAutoCompleteMarca
  } = useRequireUtilsForm()

  const location = useLocation()

  const [openModal, setopenModal] = useState(false)
  const renderCorrectInput2 = (
    key: number,
    objInput: any,
    disable: boolean
  ) => {
    if (objInput.type === 'autocomplete')
      return (
        <FormAutoComplete
          name={objInput.name}
          type={'text'}
          label={
            <>
              <span>{objInput.label}</span>
              <Tooltip title={`Agregar ${objInput.label}`}>
                <PlusCircleIcon
                  onClick={() => setmodalMake(true)}
                  className={style2.addMake}
                />
              </Tooltip>
            </>
          }
          placeholder={objInput.placeholder}
          options={OptionBrand}
          rules={objInput.rules}
          onSearch={onHandleAutoCompleteMarca}
          disabled={disable}
        />
      )
    if (objInput.type === 'select')
      return (
        <FormSelect
          name={objInput.name}
          options={objInput.options}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          disabled={disable}
        />
      )
    if (objInput.type === 'radio')
      return (
        <FormRadio
          name={objInput.name}
          label={objInput.label}
          options={objInput.options}
          initialValue={objInput.value}
          optionType='button'
          disabled={disable}
          buttonStyle='solid'
        />
      )
    if (objInput.type === 'file')
      return (
        <UploadInput
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleFileChange}
          disabled={disable}
        />
      )
    return (
      <FormInput
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
        disabled={disable}
      />
    )
  }

  return (
    <>
      <Form
        onFinish={(values) =>
          onSubmit(
            location?.state?.id || location?.state?.id_supply,
            values,
            () => {
              updateSupplies && updateSupplies(location?.state?.id_package)
            }
          )
        }
        layout='vertical'
        autoComplete='off'
        initialValues={location?.state}
        className={style.CreateSchoolForm}
        form={form}
        disabled={isStatusGT4}
      >
        <Row gutter={[16, 16]}>
          {Object.keys(schoolData).map((res: any, key) => {
            const objKey = res as keyof typeof INPUTS_INITIAL_STATE
            const objInput: any = schoolData[objKey]
            /*  let disableInput =
              (isStatusET4 && objKey != 'quantity') || isStatusGT4
            if (isStatusGT4) disableInput = false */

            if (objInput.span)
              return (
                <Col span={objInput.span}>
                  {renderCorrectInput2(key, objInput, false)}
                </Col>
              )
            return (
              <Col span={12}>{renderCorrectInput2(key, objInput, false)}</Col>
            )
          })}
        </Row>
        <Button
          size='m'
          rounded={true}
          sector='primary'
          onClick={() => {
            if (isStatusGT4) return setopenModal(true)
            form.submit()
          }}
          isLoading={loadingPost}
          /* disabled={isStatusGT4} */
        >
          Actualizar
        </Button>
      </Form>

      <Modals
        title='Aviso'
        open={openModal}
        centered
        onCancel={() => {
          setopenModal(false)
        }}
      >
        <>
          <div className={styles.bodyEndList}>
            <img src={ErrorAlert} alt='Terminar lista' />
            <B size='Light'>
              ¿Estás seguro que deseas actualizar la información a un útil
              autorizado?
            </B>
          </div>
          <div className={styles.button}>
            <Button
              rounded
              onClick={() => {
                form.submit()
              }}
              label={'Aceptar'}
              sector='secondary'
            />
          </div>
        </>
      </Modals>

      <Modal
        title='Agregar marca'
        open={modalMake}
        centered
        onCancel={() => {
          formEditorial.resetFields()
          setmodalMake(false)
        }}
        footer={false}
        className={globalStyle.confirmModal}
      >
        <Form
          form={formEditorial}
          layout='vertical'
          onFinish={postNewMakeSupply}
          autoComplete='off'
        >
          <FormInput
            name='name'
            placeholder='Nombre'
            label='Nombre'
            rules={[{ required: true, message: '' }]}
          />
          <div className={globalStyle.confirmModalFooter}>
            <Button
              isLoading={loadingPost}
              buttonType='submit'
              rounded
              sector='primary'
            >
              Agregar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default ManagementUtilsForm
