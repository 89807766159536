import React, { Fragment } from 'react'
import style from './poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import TrajeBanoFemenino1pza from 'assets/img/uniforms/trajes-de-baño/femenino/TrajeBanoFemenino1pza'

const TrajebanoFemenino1pzaFrontalEditable = () => {
  const { pictureLogo, trajeBanoFemaleForm1pz: trajeBanoFemaleForm1pz } =
    useSelector((state: RootState) => state.uniforms)
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {trajeBanoFemaleForm1pz.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-banoFema-${trajeBanoFemaleForm1pz.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}

          <div className={`${style['div-polo-svg-absolute']}`}>
            <TrajeBanoFemenino1pza className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      </div>
    </div>
  )
}

export default TrajebanoFemenino1pzaFrontalEditable
