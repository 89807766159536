import React from 'react'
import { IconProps } from '../../../types'
export const SchoolIcon = ({
  height = 32,
  width = 32,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.88889 24.8889C9.39259 24.8889 9.81511 24.7182 10.1564 24.3769C10.4966 24.0367 10.6667 23.6148 10.6667 23.1111C10.6667 22.6074 10.4966 22.1849 10.1564 21.8436C9.81511 21.5034 9.39259 21.3333 8.88889 21.3333C8.38519 21.3333 7.96267 21.5034 7.62133 21.8436C7.28119 22.1849 7.11111 22.6074 7.11111 23.1111C7.11111 23.6148 7.28119 24.0367 7.62133 24.3769C7.96267 24.7182 8.38519 24.8889 8.88889 24.8889ZM8.88889 17.7778C9.39259 17.7778 9.81511 17.6071 10.1564 17.2658C10.4966 16.9256 10.6667 16.5037 10.6667 16C10.6667 15.4963 10.4966 15.0738 10.1564 14.7324C9.81511 14.3923 9.39259 14.2222 8.88889 14.2222C8.38519 14.2222 7.96267 14.3923 7.62133 14.7324C7.28119 15.0738 7.11111 15.4963 7.11111 16C7.11111 16.5037 7.28119 16.9256 7.62133 17.2658C7.96267 17.6071 8.38519 17.7778 8.88889 17.7778ZM8.88889 10.6667C9.39259 10.6667 9.81511 10.496 10.1564 10.1547C10.4966 9.81452 10.6667 9.39259 10.6667 8.88889C10.6667 8.38519 10.4966 7.96267 10.1564 7.62133C9.81511 7.28119 9.39259 7.11111 8.88889 7.11111C8.38519 7.11111 7.96267 7.28119 7.62133 7.62133C7.28119 7.96267 7.11111 8.38519 7.11111 8.88889C7.11111 9.39259 7.28119 9.81452 7.62133 10.1547C7.96267 10.496 8.38519 10.6667 8.88889 10.6667ZM14.2222 24.8889H24.8889V21.3333H14.2222V24.8889ZM14.2222 17.7778H24.8889V14.2222H14.2222V17.7778ZM14.2222 10.6667H24.8889V7.11111H14.2222V10.6667ZM3.55556 32C2.57778 32 1.74044 31.6521 1.04356 30.9564C0.347852 30.2596 0 29.4222 0 28.4444V3.55556C0 2.57778 0.347852 1.74044 1.04356 1.04356C1.74044 0.347852 2.57778 0 3.55556 0H28.4444C29.4222 0 30.2596 0.347852 30.9564 1.04356C31.6521 1.74044 32 2.57778 32 3.55556V28.4444C32 29.4222 31.6521 30.2596 30.9564 30.9564C30.2596 31.6521 29.4222 32 28.4444 32H3.55556ZM3.55556 28.4444H28.4444V3.55556H3.55556V28.4444Z'
        fill='currentColor'
      />
    </svg>
  )
}
