import React, { Fragment } from 'react'
import style from './poloeditable.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PantalonSvg from 'assets/img/uniforms/pantalon/PantalonSvg'
import ShadowsPantalon from 'assets/img/uniforms/pantalon/ShadowsPantalon'

const PantalonEditable = () => {
  const { pantalonFrontalForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {pantalonFrontalForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[
                  `logo-position-pantalon-${pantalonFrontalForm.logoPosition.value}`
                ]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <PantalonSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsPantalon className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}
export default PantalonEditable
