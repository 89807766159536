import {
  IInputsBaseForm,
  IInputsBlusa3_4Form,
  IInputsCamisaMlMcForm,
  IInputsCapitonadosChalecoForm,
  IInputsCapitonadosChamarraForm,
  IInputsCorbataForm,
  IInputsFaldaForm,
  IInputsFaldaShortDeportivoForm,
  IInputsHoodieForm,
  IInputsJumperForm,
  IInputsPantalonForm,
  IInputsPoloFemaleForm,
  IInputsPoloMaleForm,
  IInputsSacoForm,
  IInputsSacoTraseroForm,
  IInputsShortDeportivoForm,
  IInputsSportShirtForm,
  IInputsSueterFemaleForm,
  IInputsSueterMaleForm,
  IInputsTShirtForm,
  IInputsTrajeBanoFemale2pzaForm,
  IInputsTrajeBanoFemaleForm,
  IInputsTrajeBanoMaleForm,
  TFormPoloMaleOptions
} from 'interfaces/uniforms-interface'
import SelectOption from 'modules/uniforms/components/selectOption/SelectOption'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import SelectedCardsOptionsHOC from '../selectCardOption/SelectedCardsOptionsHOC'
import styled from './clotheoptions.module.scss'

const ConstructorFormulary = ({
  form,
  handleChange
}: {
  handleChange: (name: any, value: string | boolean) => void
  form:
    | IInputsPoloMaleForm
    | IInputsPoloFemaleForm
    | IInputsSportShirtForm
    | IInputsTShirtForm
    | IInputsBlusa3_4Form
    | IInputsJumperForm
    | IInputsFaldaForm
    | IInputsSueterFemaleForm
    | IInputsSueterMaleForm
    | IInputsSacoForm
    | IInputsSacoTraseroForm
    | IInputsCorbataForm
    | IInputsHoodieForm
    | IInputsCapitonadosChalecoForm
    | IInputsCapitonadosChamarraForm
    | IInputsCamisaMlMcForm
    | IInputsPantalonForm
    | IInputsTrajeBanoMaleForm
    | IInputsBaseForm
    | IInputsTrajeBanoFemaleForm
    | IInputsFaldaShortDeportivoForm
    | IInputsShortDeportivoForm
    | IInputsTrajeBanoFemale2pzaForm
    | null
}) => {
  const { generalOptionsClothes } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <Fragment>
      {form !== null &&
        Object.keys(form).map((item: string, key: number) => {
          const objKey = item as keyof typeof form
          const objInput = form[objKey]
          return (
            <Fragment>
              {objInput.showThisOption && (
                <div className={`${styled['div-input-container']}`} key={key}>
                  <SelectedCardsOptionsHOC
                    name={objInput.name}
                    title={objInput.label}
                  >
                    {generalOptionsClothes[objInput.type].map(
                      ({ name, description, option }, index: number) => {
                        let prueba = option.value === objInput.value
                        return (
                          <Fragment key={index}>
                            <SelectOption
                              toolTipLabel={description}
                              label={name}
                              selected={prueba as boolean}
                              picture={option.picture}
                              background={option.value as string}
                              help={objInput.showHelp}
                              onClick={() =>
                                handleChange(
                                  objInput.name as TFormPoloMaleOptions,
                                  option.value as string | boolean
                                )
                              }
                            />
                          </Fragment>
                        )
                      }
                    )}
                  </SelectedCardsOptionsHOC>
                </div>
              )}
            </Fragment>
          )
        })}
    </Fragment>
  )
}
export default ConstructorFormulary
