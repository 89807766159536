import React from 'react'
import { IconProps } from '../../../types'
export const EmptySchoolListIcon = ({
  height = 36,
  width = 43,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 43 36'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 21H9.71885C11.4233 21 12.9815 21.963 13.7438 23.4875L14.2562 24.5125C15.0185 26.037 16.5767 27 18.2812 27H24.7188C26.4233 27 27.9815 26.037 28.7438 24.5125L29.2562 23.4875C30.0185 21.963 31.5767 21 33.2812 21H41M2 21.6767V30C2 32.4853 4.01472 34.5 6.5 34.5H36.5C38.9853 34.5 41 32.4853 41 30V21.6767C41 21.2281 40.9329 20.782 40.801 20.3533L35.9774 4.67661C35.3964 2.78845 33.6519 1.5 31.6764 1.5H11.3236C9.34806 1.5 7.60355 2.78845 7.02258 4.67661L2.19899 20.3533C2.06707 20.782 2 21.2281 2 21.6767Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
