import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathDobleRayaHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Doble raya Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Doble_raya_Completo'
              data-name='Path Doble raya Completo'
            >
              <g
                id='Path_Doble_raya_Completo-2'
                data-name='Path Doble raya Completo'
              >
                <g
                  id='Rayado_completo'
                  data-name='Rayado completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g>
                    <line
                      id='Hoja_esq._recta'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='151.9'
                      x2='85.42'
                      y2='151.9'
                    />
                    <line
                      id='Hoja_esq._recta-2'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='175.43'
                      x2='85.42'
                      y2='175.43'
                    />
                    <line
                      id='Hoja_esq._recta-3'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='189.18'
                      x2='85.42'
                      y2='189.18'
                    />
                    <line
                      id='Hoja_esq._recta-4'
                      data-name='Hoja esq. recta'
                      x1='85.42'
                      y1='175.24'
                      x2='686.73'
                      y2='175.24'
                    />
                    <line
                      id='Hoja_esq._recta-5'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='212.71'
                      x2='85.42'
                      y2='212.71'
                    />
                    <line
                      id='Hoja_esq._recta-6'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='226.46'
                      x2='85.42'
                      y2='226.46'
                    />
                    <line
                      id='Hoja_esq._recta-7'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='249.99'
                      x2='85.42'
                      y2='249.99'
                    />
                    <line
                      id='Hoja_esq._recta-8'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='263.74'
                      x2='85.42'
                      y2='263.74'
                    />
                    <line
                      id='Hoja_esq._recta-9'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='287.27'
                      x2='85.42'
                      y2='287.27'
                    />
                    <line
                      id='Hoja_esq._recta-10'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='301.02'
                      x2='85.42'
                      y2='301.02'
                    />
                    <line
                      id='Hoja_esq._recta-11'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='324.55'
                      x2='85.42'
                      y2='324.55'
                    />
                    <line
                      id='Hoja_esq._recta-12'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='338.31'
                      x2='85.42'
                      y2='338.31'
                    />
                    <line
                      id='Hoja_esq._recta-13'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='361.83'
                      x2='85.42'
                      y2='361.83'
                    />
                    <line
                      id='Hoja_esq._recta-14'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='375.59'
                      x2='85.42'
                      y2='375.59'
                    />
                    <line
                      id='Hoja_esq._recta-15'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='399.11'
                      x2='85.42'
                      y2='399.11'
                    />
                    <line
                      id='Hoja_esq._recta-16'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='412.87'
                      x2='85.42'
                      y2='412.87'
                    />
                    <line
                      id='Hoja_esq._recta-17'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='436.39'
                      x2='85.42'
                      y2='436.39'
                    />
                    <line
                      id='Hoja_esq._recta-18'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='450.15'
                      x2='85.42'
                      y2='450.15'
                    />
                    <line
                      id='Hoja_esq._recta-19'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='473.67'
                      x2='85.42'
                      y2='473.67'
                    />
                    <line
                      id='Hoja_esq._recta-20'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='487.43'
                      x2='85.42'
                      y2='487.43'
                    />
                    <line
                      id='Hoja_esq._recta-21'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='510.95'
                      x2='85.42'
                      y2='510.95'
                    />
                    <line
                      id='Hoja_esq._recta-22'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='524.71'
                      x2='85.42'
                      y2='524.71'
                    />
                    <line
                      id='Hoja_esq._recta-23'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='548.23'
                      x2='85.42'
                      y2='548.23'
                    />
                    <line
                      id='Hoja_esq._recta-24'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='561.99'
                      x2='85.42'
                      y2='561.99'
                    />
                    <line
                      id='Hoja_esq._recta-25'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='585.51'
                      x2='85.42'
                      y2='585.51'
                    />
                    <line
                      id='Hoja_esq._recta-26'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='599.27'
                      x2='85.42'
                      y2='599.27'
                    />
                    {page === 2 && (
                      <g
                        fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                      >
                        <line x1='111.56' y1='151.9' x2='111.56' y2='622.41' />
                      </g>
                    )}
                  </g>
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          <line id="_Trazado_espejo_" data-name="&amp;lt;Trazado espejo&amp;gt;" x1="660.2" y1="151.9" x2="660.2" y2="622.41"/>
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m672.75,165.7c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,184.24c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,199.3c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,240.08c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,221.47c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,255.26c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,296.05c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,277.44c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,311.11c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,351.89c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,333.28c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,366.95c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,407.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,389.12c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,422.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,463.77c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,445.15c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,478.83c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,519.76c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,501c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,534.82c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,575.6c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,556.99c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,590.66c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,612.83c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m672.75,202.93c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,217.99c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,165.7c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,180.76c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,240.16c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,255.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,277.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,292.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671.59,329.79c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,351.96c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.75,314.61c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,367.02c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,389.19c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,404.52c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,426.69c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,441.75c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,463.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,478.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,501.15c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,516.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,538.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,553.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,575.61c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.59,590.89c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.75,613.06c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M99,165.7a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,184.24a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,199.3a17.71,17.71,0,0,0-2.68,3.42l-.19-.43c-.39-.86-.74-1.3-1.05-1.3a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,240.08a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,221.47a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,255.26a18.07,18.07,0,0,0-2.68,3.43l-.19-.44Q96.71,257,96.24,257a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,296.05a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,277.44a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,311.11a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,351.89a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,333.28a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,367a18.07,18.07,0,0,0-2.68,3.43l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,407.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,389.12a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,423a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.53,1.53,0,0,1,.72.54,6.29,6.29,0,0,1,.68,1.28l.18.4a8.38,8.38,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,463.77a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,445.15a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,478.83a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,519.76a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,501a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,534.82a17.71,17.71,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,575.6a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,557a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,590.66a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,612.83a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M99,202.93a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,218a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,165.7a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,180.76a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,240.16a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,255.21a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,277.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,292.44a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M100.16,329.79a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,352a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,314.61a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,367a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,389.19a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,404.52a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,426.69a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,441.75a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,463.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,479a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,501.15a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,516.21a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,538.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,553.44a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,575.61a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,590.89a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.26,15.26,0,0,0-3.2,2.56' />
                                    <path d='M99,613.06a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Doble raya Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Doble_raya_Datos' data-name='Path Doble raya Datos'>
              <g id='Path_Doble_raya_Datos-2' data-name='Path Doble raya Datos'>
                <g
                  id='Rayado_Datos'
                  data-name='Rayado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g>
                    <line
                      id='Hoja_esq._recta-2'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='220.43'
                      x2='85.42'
                      y2='220.43'
                    />
                    <line
                      id='Hoja_esq._recta-3'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='234.18'
                      x2='85.42'
                      y2='234.18'
                    />
                    <line
                      id='Hoja_esq._recta-4'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='257.71'
                      x2='85.42'
                      y2='257.71'
                    />
                    <line
                      id='Hoja_esq._recta-5'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='271.46'
                      x2='85.42'
                      y2='271.46'
                    />
                    <line
                      id='Hoja_esq._recta-6'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='294.99'
                      x2='85.42'
                      y2='294.99'
                    />
                    <line
                      id='Hoja_esq._recta-7'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='308.74'
                      x2='85.42'
                      y2='308.74'
                    />
                    <line
                      id='Hoja_esq._recta-8'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='332.27'
                      x2='85.42'
                      y2='332.27'
                    />
                    <line
                      id='Hoja_esq._recta-9'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='346.02'
                      x2='85.42'
                      y2='346.02'
                    />
                    <line
                      id='Hoja_esq._recta-10'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='369.55'
                      x2='85.42'
                      y2='369.55'
                    />
                    <line
                      id='Hoja_esq._recta-11'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='383.31'
                      x2='85.42'
                      y2='383.31'
                    />
                    <line
                      id='Hoja_esq._recta-12'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='406.83'
                      x2='85.42'
                      y2='406.83'
                    />
                    <line
                      id='Hoja_esq._recta-13'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='420.59'
                      x2='85.42'
                      y2='420.59'
                    />
                    <line
                      id='Hoja_esq._recta-14'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='444.11'
                      x2='85.42'
                      y2='444.11'
                    />
                    <line
                      id='Hoja_esq._recta-15'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='457.87'
                      x2='85.42'
                      y2='457.87'
                    />
                    <line
                      id='Hoja_esq._recta-16'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='481.39'
                      x2='85.42'
                      y2='481.39'
                    />
                    <line
                      id='Hoja_esq._recta-17'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='495.15'
                      x2='85.42'
                      y2='495.15'
                    />
                    <line
                      id='Hoja_esq._recta-18'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='518.67'
                      x2='85.42'
                      y2='518.67'
                    />
                    <line
                      id='Hoja_esq._recta-19'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='532.43'
                      x2='85.42'
                      y2='532.43'
                    />
                    <line
                      id='Hoja_esq._recta-20'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='555.95'
                      x2='85.42'
                      y2='555.95'
                    />
                    <line
                      id='Hoja_esq._recta-21'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='569.71'
                      x2='85.42'
                      y2='569.71'
                    />
                    <line
                      id='Hoja_esq._recta-22'
                      data-name='Hoja esq. recta'
                      x1='686.73'
                      y1='593.23'
                      x2='85.42'
                      y2='593.23'
                    />
                    {page === 2 && (
                      <g
                        fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                      >
                        <line x1='111.56' y1='196.56' x2='111.56' y2='605.41' />
                      </g>
                    )}
                  </g>
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          <line
                            id='_Trazado_espejo_'
                            data-name='&amp;lt;Trazado espejo&amp;gt;'
                            x1='660.38'
                            y1='196.56'
                            x2='660.38'
                            y2='606.41'
                          />
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m672.93,210.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,229.46c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,244.52c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,285.31c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,266.69c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,300.49c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,341.27c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,322.66c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,356.33c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,397.12c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,378.5c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,412.18c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,453.15c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,434.35c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,468.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,508.99c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,490.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,524.05c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,564.98c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,546.22c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,580.04c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,602.21c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m672.93,248.15c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,263.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,210.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,225.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,285.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,300.43c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,322.6c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,337.66c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m671.77,375.01c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,397.18c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m672.93,359.83c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,412.24c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,434.41c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,449.75c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,471.92c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,486.98c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,509.15c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,524.21c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,546.38c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,561.44c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                    <path d='m672.93,583.61c-1.13,0-2.04-.91-2.04-2.04s.91-2.04,2.04-2.04,2.04.91,2.04,2.04-.91,2.04-2.04,2.04' />
                                    <path d='m671.77,598.34c1.09,1.11,1.98,2.25,2.68,3.42l.19-.43c.39-.86.74-1.29,1.06-1.29.37,0,.78.23,1.22.7-.26.09-.5.27-.71.55-.21.28-.44.7-.69,1.28l-.17.4c-.18.44-.3.76-.35.95-.14-.13-.39-.32-.77-.59l-.46-.31c-.57-1.2-1.39-2.49-2.47-3.87-1.08-1.38-2.06-2.4-2.94-3.06l.22-.31c1.04.59,2.11,1.44,3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M99,210.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,229.46a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,244.52a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,285.31a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,266.69a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,300.49a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,341.27a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,322.66a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,356.33a18.07,18.07,0,0,0-2.68,3.43l-.19-.44Q96.71,358,96.24,358a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.9,5.9,0,0,1,.68,1.27l.18.41a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a20.68,20.68,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,397.12a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,378.5a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,412.18a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,8.6,8.6,0,0,1,.77-.59l.46-.3a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,453.15a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,434.35a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,468.21a18,18,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,8.6,8.6,0,0,1,.77-.59l.46-.31a21.35,21.35,0,0,1,2.47-3.86,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,509a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,490.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,524.05a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,565a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,546.22a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,580a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35,1,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,602.21a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M99,248.15a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,263.21a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a8.38,8.38,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,210.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,226a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.53,1.53,0,0,1,.72.54,6.29,6.29,0,0,1,.68,1.28l.18.4a8.38,8.38,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,285.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,300.43a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a8.38,8.38,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M99,322.6a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,337.66a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.88,1.88,0,0,0-1.23.7,1.62,1.62,0,0,1,.72.55,6.29,6.29,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                    <path d='M100.16,375a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,9.08,9.08,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,397.18a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M99,359.83a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,412.24a18.34,18.34,0,0,0-2.68,3.42l-.19-.43q-.58-1.29-1.05-1.29a1.84,1.84,0,0,0-1.23.7,1.55,1.55,0,0,1,.72.55,6,6,0,0,1,.68,1.28l.18.4a9.2,9.2,0,0,1,.35.95,7.31,7.31,0,0,1,.77-.58l.46-.31a21.06,21.06,0,0,1,2.47-3.87,16.77,16.77,0,0,1,2.94-3.06l-.22-.31a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,434.41a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,449.75a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,471.92a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,487a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.3a14.87,14.87,0,0,0-3.2,2.55' />
                                    <path d='M99,509.15a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,524.21a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.26,15.26,0,0,0-3.2,2.56' />
                                    <path d='M99,546.38a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,561.44a17.71,17.71,0,0,0-2.68,3.42l-.19-.44q-.58-1.29-1.05-1.29a1.86,1.86,0,0,0-1.23.71,1.49,1.49,0,0,1,.72.55,5.78,5.78,0,0,1,.68,1.28l.18.4a8.41,8.41,0,0,1,.35.95,7,7,0,0,1,.77-.59l.46-.31a21,21,0,0,1,2.47-3.86,17.21,17.21,0,0,1,2.94-3.07l-.22-.31a15.26,15.26,0,0,0-3.2,2.56' />
                                    <path d='M99,583.61a2,2,0,1,0-2-2,2,2,0,0,0,2,2' />
                                    <path d='M100.16,598.34a18.34,18.34,0,0,0-2.68,3.42l-.19-.43Q96.71,600,96.24,600a1.86,1.86,0,0,0-1.23.71,1.53,1.53,0,0,1,.72.54,6.29,6.29,0,0,1,.68,1.28l.18.4a9.14,9.14,0,0,1,.35,1,7,7,0,0,1,.77-.59l.46-.31a21.06,21.06,0,0,1,2.47-3.87,17.1,17.1,0,0,1,2.94-3.06l-.22-.31a15.51,15.51,0,0,0-3.2,2.55' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Doble raya Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Doble_raya_Completo'
                data-name='Margenes Doble raya Completo'
              >
                <g
                  id='Margenes_Doble_raya_Completo-2'
                  data-name='Margenes Doble raya Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Doble raya Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Doble_raya_Datos'
                data-name='Margenes Doble raya Datos'
              >
                <g
                  id='Margenes_Doble_raya_Datos-2'
                  data-name='Margenes Doble raya Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='409.56'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V593a13.1,13.1,0,0,1-13.08,13.08H99.31A13.1,13.1,0,0,1,86.23,593V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V593a14.08,14.08,0,0,0,14.08,14.08H672.84A14.08,14.08,0,0,0,686.92,593V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='409.56'
                      />
                      <path d='M685.92,197.56V606.12H86.23V197.56H685.92m1-1H85.23V607.12H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathDobleRayaHorizontal
