import { Tag, Tooltip } from 'antd'
import Tags from 'components/tag'
import { memo } from 'react'
import styles from './actions.module.scss'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

interface IQuotaActions {
  handleAssign: any
  handleAuthorize: any
  handleOpenRequestChanges:any
  endQuotas: boolean
  idGrade: number
  dataQuotas?: []
}

const QuotasActionsModule = memo(
  ({
    handleAssign,
    handleAuthorize,
    handleOpenRequestChanges,
    endQuotas,
    idGrade,
    dataQuotas
  }: IQuotaActions) => {
    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()

    return (
      <>
        {!endQuotas && uid.role !== 'Director' && (
          <Tooltip title='Asignar cuota'>
            <Tag
              onClick={(e: any) => {
                e.stopPropagation()
                handleAssign(idGrade)
              }}
              className={styles.tag}
            >
              <small className={styles.status} data-variation={7}>
                Asignar cuota
              </small>
            </Tag>
          </Tooltip>
        )}

        {!endQuotas && <Tags variation={2} title={'En construcción'} />}

        {endQuotas && (
          <Tags
            variation={5}
            title={
              dataQuotas
                ?.filter((item: { idGrade: any }) => item.idGrade === idGrade)
                .every((book: any) => book.status === 5) && dataQuotas
                ?.filter((item: { idGrade: any }) => item.idGrade === idGrade).length > 0
                ? 'Lista autorizada'
                : 'Lista terminada'
            }
          />
        )}

        {dataQuotas
          ?.filter((item: any) => item.idGrade === idGrade)
          ?.some((item: any) => item.status === 4) &&
          endQuotas && (
            <Tag
              onClick={(e: any) => {
                e.stopPropagation()
                handleAuthorize(idGrade)
              }}
              className={styles.tag}
            >
              <small className={styles.status} data-variation={6}>
                Autorizar
              </small>
            </Tag>
          )}

        {((dataQuotas
          ?.filter((item: any) => item.idGrade === idGrade)
          ?.length || 0) > 0) &&
          endQuotas &&
          uid.role !== 'Director' && (
            <Tag
              onClick={(e: any) => {
                e.stopPropagation()
                handleOpenRequestChanges(idGrade)
              }}
              className={styles.tag}
            >
              <small className={styles.status} data-variation={6}>
                Solicitar modificación
              </small>
            </Tag>
          )}
      </>
    )
  }
)

export default QuotasActionsModule
