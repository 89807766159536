import React from 'react'

const ShadowsFrontalPants= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path d="m344.05,286.97s82.5-47.09,86.27-51.61c3.77-4.52,17.34-14.32,25.62-7.54"/>
      <path d="m471.73,744.22c6.66,4.8,29.94,9.38,32.49,1.72.97-2.89-6.98,1.87-7.24-1.21-1.29-15.77-11.94.36-16.68-3.01-7.32-5.23-25.61-40.16-25.61-40.16,0,0,7.69,35.92,17.04,42.66Z"/>
      <polygon points="449.77 517.28 474.49 501.02 500.9 469.16 482.17 506.64 449.77 517.28"/>
      <path d="m287.15,727.66c1.8,4.52,8.72,15.77,12.69,17.21,6.11,2.24,29.75,9.51,24.21,6.03-5.5-3.46,8.29-8.82,11.35-10,5.98-2.31-21.8,6.8-28.39,5.11-5.88-1.5-22.14-24.08-19.86-18.35Z"/>
      <path d="m297.02,596.59c7.39-28.6,3.77-26.23,23.01-22.93,1.9.33,1.89-6.75.29-7.86-4.57-3.17-21.54-23.38-24.93-19.01-3.55,4.56,1.63,49.79,1.63,49.79Z"/>
      <path d="m523.6,549.34c-17.66-1.08-29.91-5.88-39.39-1.15-10.27,5.15-16.35,12.28-27.58,26.33-5,6.25,18.93-33.48,26.17-37.13,10.82-5.46,53.06,12.69,40.81,11.94Z"/>
      <path d="m431.62,338.85s-2.96-43.22-.14-57.39c1.2-6.09,11.2-19.9,13.23-23.57,2.04-3.66,11.23-10.98,11.23-10.98,0,0-20.22,6.54-25.09,11.47-4.18,4.2-7.51,16.54-8.08,22.52-1.39,14.56,8.85,57.95,8.85,57.95Z"/>
      <path d="m401.51,78.35l85.03-2.37,8.93,2.48s-39.27,3.34-42.52,4.18c-3.25.83-51.44-4.28-51.44-4.28Z"/>
      <path d="m393.17,82.69c-9.44,8.28-32.15,10.51-58.95-.03-11.61-4.56-22.28,6.45-37.99-9.82-.63-.65,20.35,9.7,20.47,8.92,1.37-8.36,73.02,9.25,77.82-1.78.21-.46-.92,2.33-1.35,2.7Z"/>
      <path d="m344,454.04c4.79,4.21-.59,19.02-.51,25.36.16,12.63,13.45,48.98,13.45,48.98,0,0-35.82-46.45-35.91-64.86-.01-1.84,2.08-5.29,3.52-6.38,3.91-2.96,15.69-6.42,19.44-3.1Z"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsFrontalPants