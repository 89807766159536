import React from 'react'

const PathBlancoVertical = ({
  className,
  marginComplete,
  roundedMargin,
  viewBox = '0 0 800 800',
  ink
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  viewBox?: string
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Margenes Cuadro 5mm Completo</title>
        </g>
      ) : (
        <g>
          <title>Margenes Cuadro 5mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Margenes_Cuadro_5mm_Datos'
              data-name='Margenes Cuadro 5mm Datos'
            >
              <g
                stroke={`${ink !== 'black' ? ink : 'black'}`}
                id='Margenes_Cuadro_5mm_Datos-2'
                data-name='Margenes Cuadro 5mm Datos'
              >
                {roundedMargin ? (
                  //aqui esta el cuadrado normal
                  <rect
                    id='Hoja_esq._redondeada'
                    data-name='Hoja esq. redondeada'
                    x='199.93'
                    y='148.56'
                    width='448.31'
                    height='593.48'
                    rx='14.08'
                  />
                ) : (
                  //aqui estan las esquinas redondeadas
                  <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                    <path d='M647.24,149.56V741H200.93V149.56H647.24m1-1H199.93V742H648.24V148.56Z' />
                  </g>
                )}
              </g>
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}

export default PathBlancoVertical
