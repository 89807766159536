import React, { useState, useEffect } from 'react'
import style from './selectedcardsoption.module.scss'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import { setPictureLogo } from 'redux/reducers/uniformsSlice'

const AddImgUnifotmsModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  thisPicture
}: {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  thisPicture: string
}) => {
  const dispatch = useDispatch()
  const [picture, setPicture] = useState<null | string>(null)
  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader()
      reader.onload = (e: any) => {
        setPicture(e.target.result as string)
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }
  useEffect(() => {
    if (thisPicture !== '') {
      setPicture(thisPicture)
    }
  }, [isModalOpen])

  return (
    <Modal
      title='Crear nueva prenda'
      className='modal-in-upload-img-uniforms'
      centered
      bodyStyle={{ background: '#F3F7FF' }}
      keyboard
      open={isModalOpen}
      onOk={() => {
        setPicture(null)
        handleOk()
      }}
      onCancel={() => {
        setPicture(null)
        handleCancel()
      }}
      destroyOnClose
      width={572}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            disabled={picture === null}
            rounded
            label='Subir imagen'
            onClick={() => {
              dispatch(setPictureLogo(picture))
              setPicture(null)
              handleOk()
            }}
          />
        </div>
      ]}
    >
      <div className={`${style['div-container-modal']}`}>
        <p className={`${style['p-modal']}`}>
          Sube una imagen que tenga fondo transparente para una mejor
          visualizacion del logotipo sobre tu prenda.
        </p>
        <div className={`${style['div-cover-white']}`}>
          <p className={`${style['p-title-text']}`}>* Sube tu logotipo : </p>
          {picture !== null && (
            <div className={`${style['container-notebook-logo']}`}>
              <img className={`${style['notebook-logo']}`} src={picture} />
            </div>
          )}
          <label className={`${style['button-upload']}`}>
            <ArrowUpTrayIcon className={`${style['arrow-svg']}`} />
            Click para subir{' '}
            {picture !== null ? 'y remplazar actual.' : 'archivo.'}
            <input
              className={`${style['input-archive-file']}`}
              type='file'
              accept='image/png'
              onChange={onImageChange}
            />
          </label>

          <p className={`${style['p-description']}`}>
            Resolución recomendada de 640 X 640 px
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default AddImgUnifotmsModal
