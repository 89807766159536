import { TFormPantsOptions } from 'interfaces/uniforms-interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handlePantsDeportivaForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const PantsDeportivosOptions = () => {
  const {
    pantsDeportivaForm,
    selectedMenu
  } = useSelector((state: RootState) => state.uniforms)
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormPantsOptions,
    value: string | boolean
  ) => {
    switch (selectedMenu) {
      case 'pants deportivo':
        dispatch(
          handlePantsDeportivaForm({
            ...pantsDeportivaForm,
            [keyName]: {
              ...pantsDeportivaForm[keyName as TFormPantsOptions],
              value: value
            }
          })
        )
        break

      default:
        break
    }
  }

  return (
    <div>
      {(() => {
        switch (selectedMenu) {
          case 'pants deportivo':
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={pantsDeportivaForm}
              />
            )
          default:
            break
        }
      })()}
    </div>
  )
}
export default PantsDeportivosOptions
