import Navbar from 'components/Navbar/Navbar'
import FixedInfoFooter from 'modules/uniforms/components/fixedInfoFooter/FixedInfoFooter'
import style from './notebook.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import {
  allNotebooks,
  DisableFlag,
  handleHideBack,
  setIsCoverOnEdith
} from 'redux/reducers/notebooksSlice'
import { Fragment, useEffect, useState } from 'react'
import SelectOption from 'modules/uniforms/components/selectOption/SelectOption'
import VerticalNotebookEditable from './components/notebookEditables/VerticalNotebookEditable'
import HorizontalNotebookEditable from './components/notebookEditables/HorizontalNotebookEditable'
import Button from 'components/buttons/Button/Button'
import Icon from 'components/icons'
import VerticalPreview from './components/notebookEditables/VerticalPreview'
import HorizontalPreview from './components/notebookEditables/HorizontalPreview'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import useNotebooks from 'hooks/notebooks/useNotebooks'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const viewModeMap = {
  0: 0,
  1: 2,
  2: 1,
  3: 3
}

const NotebooksPreviewModule = () => {
  const [viewMode, setViewMode] = useState<number>(0)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { saveNotebookChange, saveSendDataNotebookChange, notebook } =
    useNotebooks({
      uuid: parseInt(uuid || '0')
    })
  const {
    notebookSelected,
    notebookFrontValues,
    notebookInsideValues,
    generalOptionsNotebookFront,
    generalOptionsNotebookInside
  } = useSelector((state: RootState) => state.notebooks)

  const isEzetera = uid.role == 'Ezetera'
  const isInPreview = location.pathname.includes('cuadernos/preview')
  const isADraft =
    [1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(
      (notebook?.status || 0) as number
    ) &&
    isInPreview &&
    isEzetera
  const notebookIsInStatus7 =
    !isEzetera && notebook?.status == '7' && isInPreview
  const notebookIsInStatus8 =
    !isEzetera && notebook?.status == '8' && isInPreview
  const notebookIsInStatus9 =
    !isEzetera && notebook?.status == '9' && isInPreview
  const notebookIsInStatus10 =
    !isEzetera && notebook?.status == '10' && isInPreview

  const notebookIsInStatus4 =
    !isEzetera && notebook?.status == '4' && isInPreview
  const notebookIsInStatus5 =
    !isEzetera && notebook?.status == '5' && isInPreview

  useEffect(() => {
    dispatch(DisableFlag())
  }, [])

  const goBack = () => {
    let thisPath = window.location.pathname
    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'select-type':
          saveSendDataNotebookChange({ step: 0 }, false, () =>
            navigate(`/cuadernos`)
          )
          break
        case 'edith-front':
          saveSendDataNotebookChange({ step: 0 }, false, () =>
            navigate(`/cuadernos/select-type/${uuid}`)
          )
          break
        case 'edith-inside':
          saveSendDataNotebookChange({ step: 1 }, false, () =>
            navigate(`/cuadernos/edith-front/${uuid}`)
          )
          break
        case 'preview':
          saveSendDataNotebookChange({ step: 2 }, false, () =>
            navigate(`/cuadernos/edith-inside/${uuid}`)
          )
          break
        default:
          break
      }
    }
  }

  const resetIds = () => {
    dispatch(setIsCoverOnEdith(false))
  }

  useEffect(() => {
    resetIds()
  }, [])

  useEffect(() => {
    if (notebook !== null) {
      if (`${notebook.status}` === '4' || `${notebook.status}` === '5') {
        dispatch(handleHideBack(true))
      } else {
        dispatch(handleHideBack(false))
      }
    }
  }, [notebook])

  return (
    <div className={`${style['body-div']}`}>
      <Navbar onClick={goBack} complete />
      <FixedInfoFooter
        uuidNotebook={uuid}
        save={() => {
          dispatch(DisableFlag())
          saveNotebookChange()
        }}
      />
      <div className={`${style['div-grid']}`}>
        <div className={`${style['div-left-2']}`}>
          <div className={`${style['edit-button-container']}`}>
            <Button
              disabled={
                isADraft ||
                notebookIsInStatus7 ||
                notebookIsInStatus8 ||
                notebookIsInStatus9 ||
                notebookIsInStatus10 ||
                notebookIsInStatus4 ||
                notebookIsInStatus5
              }
              rounded
              size='m'
              sector='secondary'
              background='white'
              onClick={() => {
                navigate(`/cuadernos/select-type/${uuid}`)
              }}
            >
              Editar
            </Button>
            {(viewMode == 1 || viewMode == 2) && (
              <Button
                rounded
                size='m'
                sector='secondary'
                background='white'
                onClick={() => {
                  navigate(
                    `/cuadernos/download-covers/${uuid}?viewMode=${viewMode}`
                  )
                }}
              >
                Descargar portada
              </Button>
            )}
          </div>

          <div className={style['book-items-container']}>
            <div
              className={`${style['book-item']} ${
                viewMode == 0 ? style['show'] : ''
              }`}
            >
              {(() => {
                switch (notebookSelected) {
                  case 1:
                    return <VerticalPreview viewMode={viewMode} />
                  case 2:
                    return <VerticalPreview viewMode={viewMode} />
                  case 3:
                    return <VerticalPreview viewMode={viewMode} />
                  case 5:
                    return <VerticalPreview viewMode={viewMode} />
                  case 7:
                    return <VerticalPreview viewMode={viewMode} />
                  case 4:
                    return <HorizontalPreview viewMode={viewMode} />
                  case 6:
                    return <HorizontalPreview viewMode={viewMode} />
                  case 8:
                    return <HorizontalPreview viewMode={viewMode} />

                  default:
                    return <></>
                }
              })()}
            </div>

            <div
              className={`${style['book-item']} ${
                viewMode == 1 ? style['show'] : ''
              }`}
            >
              {(() => {
                switch (notebookSelected) {
                  case 1:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 2:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 3:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 5:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 7:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 4:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 6:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )
                  case 8:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[2]}
                        viewMode={viewMode}
                      />
                    )

                  default:
                    return <></>
                }
              })()}
            </div>

            <div
              className={`${style['book-item']} ${
                viewMode == 2 ? style['show'] : ''
              }`}
            >
              {(() => {
                switch (notebookSelected) {
                  case 1:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 2:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 3:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 5:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 7:
                    return (
                      <VerticalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 4:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 6:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )
                  case 8:
                    return (
                      <HorizontalPreview
                        previewHalf
                        showItems={[1]}
                        viewMode={viewMode}
                      />
                    )

                  default:
                    return <></>
                }
              })()}
            </div>

            <div
              className={`${style['book-item']} ${
                viewMode == 3 ? style['show'] : ''
              }`}
            >
              {(() => {
                //horizontal 4,6,8
                //vertical 1,2,3,5,7
                switch (notebookSelected) {
                  case 1:
                    return <VerticalNotebookEditable />
                  case 2:
                    return <VerticalNotebookEditable />
                  case 3:
                    return <VerticalNotebookEditable />
                  case 5:
                    return <VerticalNotebookEditable />
                  case 7:
                    return <VerticalNotebookEditable />
                  case 4:
                    return <HorizontalNotebookEditable />
                  case 6:
                    return <HorizontalNotebookEditable />
                  case 8:
                    return <HorizontalNotebookEditable />

                  default:
                    return <></>
                }
              })()}
            </div>
          </div>

          <div className={style['carrousel-container']}>
            <button
              className={style['arrow-button']}
              onClick={() => {
                setViewMode((prev) => (prev + 3) % 4)
              }}
            >
              <Icon name='chevronLeft' height={24} width={24} />
            </button>
            <div>
              {[0, 1, 2, 3].map((it, ix) => (
                <span
                  key={`tab-${ix}`}
                  className={`${style['tab-item']} ${
                    it == viewMode ? style['selected'] : ''
                  }`}
                ></span>
              ))}
            </div>
            <button
              className={style['arrow-button']}
              onClick={() => {
                setViewMode((prev) => (prev + 1) % 4)
              }}
            >
              <Icon name='chevronRight' height={24} width={24} />
            </button>
          </div>
        </div>

        <div className={`${style['div-select-options']}`}>
          <div className={`${style['div-title-type']}`}>
            <p className={`${style['p-label']}`}>Resumen de</p>
            <h3 className={`${style['title-h']}`}>
              {allNotebooks.map((item) => {
                if (item.id === notebookSelected) return item.label
              })}
            </h3>
            <p className={`${style['description-title']}`}></p>
          </div>
          {(notebookIsInStatus7 || notebookIsInStatus8) && (
            <div
              style={{
                margin: '48px 0px',
                backgroundColor: 'rgb(220,221,222)',
                color: 'rgb(120,121,122)',
                borderRadius: '16px',
                padding: '16px'
              }}
            >
              A la espera del diseño de la portada por parte de Ezetera
            </div>
          )}
          {notebookIsInStatus10 && (
            <div
              style={{
                margin: '48px 0px',
                backgroundColor: 'rgb(220,221,222)',
                color: 'rgb(120,121,122)',
                borderRadius: '16px',
                padding: '16px'
              }}
            >
              A la espera de aprobación por parte de Ezetera
            </div>
          )}
          <div style={{ margin: '48px 0px' }}>
            <div>
              <p className={`${style['p-label']}`}>Exterior</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'stretch' }}>
              {Object.keys(notebookFrontValues).map((key, idx) => {
                const objKey = key as keyof typeof notebookFrontValues
                const objInput = notebookFrontValues[objKey]

                const genInput = generalOptionsNotebookFront[
                  objInput.type
                ].find((it) => it.option.value == objInput.value)

                if (genInput) {
                  const { name, description, option } = genInput
                  return (
                    <div
                      key={`div-cont-${idx}`}
                      className={style['preview-container']}
                    >
                      <div className={style['preview-container-title']}>
                        {objInput.label}
                      </div>
                      <Fragment key={idx}>
                        <SelectOption
                          toolTipLabel={description}
                          label={name}
                          selected={option.value === objInput.value}
                          picture={option.picture}
                          background={option.value as string}
                          help={objInput.showHelp}
                          onClick={() => {}}
                        />
                      </Fragment>
                    </div>
                  )
                }

                return null
              })}
            </div>
          </div>
          <div style={{ margin: '48px 0px' }}>
            <div>
              <p className={`${style['p-label']}`}>Interior</p>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {Object.keys(notebookInsideValues).map((key, idx) => {
                const objKey = key as keyof typeof notebookInsideValues
                const objInput = notebookInsideValues[objKey]

                const genInput = generalOptionsNotebookInside[
                  objInput.type
                ].find((it) => it.option.value == objInput.value)

                if (genInput) {
                  const { name, description, option } = genInput
                  return (
                    <div
                      key={`div-cont-${idx}`}
                      className={style['preview-container']}
                    >
                      <div className={style['preview-container-title']}>
                        {objInput.label}
                      </div>
                      <Fragment key={idx}>
                        <SelectOption
                          toolTipLabel={description}
                          label={name}
                          selected={option.value === objInput.value}
                          picture={option.picture}
                          background={option.value as string}
                          help={objInput.showHelp}
                          onClick={() => {}}
                        />
                      </Fragment>
                    </div>
                  )
                }

                return null
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NotebooksPreviewModule.displayName = 'NotebooksPreview'
export default NotebooksPreviewModule
