import { Form } from "antd";
import Button from "components/buttons/Button/Button";
import FormInput from "components/formInputs/input"
import Icon from "components/icons";
import UploadInput from "components/inputs/UploadInput"
import { ErrorToast } from "components/Toasts/Toasts";
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { AUTHSERVICE, AUTHSERVICE_FORMDATA } from "services/config";
import { getUtilsPaginated, getUtilsPaginatedAdmin } from "services/utilsServices/utils";
import style from '../../../modules/Utils/AddUtilsForm/AddUtilsForm.module.scss';
import ButtonStyles from '../../buttons/Button/button.module.scss';

type FormParams = {
    data: any,
    onSubmitFinished: () => void
}

export const SuggestionsForm = ({ data, onSubmitFinished }: FormParams) => {
    const [showForm, setShowForm] = useState(false)
    const [fileData, setFileData] = useState<File | null>(null)
    const [savingData, setSavingData] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [skuValue, setSkuValue] = useState<string | null>(null)
    const [foundImage, setFoundImage] = useState<string | null>(null)
    const [disableForm, setDisableForm] = useState(false)

    const location = useLocation()
    const [form] = Form.useForm()

    const onFileChange = (file: File, name?: string) => {
        if (file) setFileData(file)
        else setFileData(null)
    }

    const submit = async (id: number, values: any) => {
        setSavingData(true)

        try {
            // create the suggestion
            const suggestion = await AUTHSERVICE().post('/suggestions', {
                ...values,
                id_supply_school: data?.id_supply_school,
                id_package: data?.id_package,
               
                ...(foundImage ? { picture: foundImage } : {})
            })

            if (fileData) {
                const { suggestionsId } = suggestion?.data
                if (suggestionsId) {
                    const formData = new FormData()
                    formData.append('files', fileData)
                    formData.append('id', suggestionsId)

                    await AUTHSERVICE_FORMDATA().post('/suggestions/loadfile', formData)
                    setFileData(null)
                }
            }

            // update the book status
            await AUTHSERVICE().put('/supply-school/status', {
                id: [data?.id_supply_school],
                status: 3
            })
            onSubmitFinished()
        } catch (error:any) {
            if(error?.response?.data?.error) return ErrorToast(error.response.data.error)
            ErrorToast("Ocurrió un error, inténtelo más tarde.")
        }finally{
        setSavingData(false)

        }

    }

    const handleSearchSku = async () => {
        if (!skuValue) return

        setFoundImage(null)
        form.resetFields(['name', 'price', 'picture'])
        setLoadingData(true)

        try {
            const res = await getUtilsPaginated(1, skuValue)
            const { supplies: details } = res.data?.data
            const first = details[0]

            if (first) {
                setDisableForm(true)
                const { name, price, picture } = first
                form.setFieldValue('name', name)
                form.setFieldValue('price', price)
                form.setFieldValue('picture', picture)
                setFoundImage(picture)
            } else {
                setDisableForm(false)
            }
        } catch (error) {
            ErrorToast("No se encontró información para el SKU.")
        }

        setLoadingData(false)
    }

    return (
        <div>
            {
                (showForm) ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                onClick={() => {
                                    setDisableForm(false)
                                    setFoundImage(null)
                                    form?.resetFields(['name', 'price', 'picture'])
                                }}
                                buttonType='submit'
                                size="sm"
                                rounded
                                background="white"
                                sector='primary'>
                                Limpiar
                            </Button>
                        </div>
                        <Form
                            onFinish={(values) => submit(location?.state?.id, values)}
                            layout='vertical'
                            autoComplete='off'
                            className={style.CreateSchoolForm}
                            form={form}
                            disabled={savingData || loadingData}
                            initialValues={{}}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <FormInput
                                        name='sku'
                                        placeholder='SKU'
                                        label='SKU'
                                        maxLength={100}
                                        type='text'
                                        disabled={disableForm}
                                        onChange={(e) => setSkuValue(e.target.value ? e.target.value : null)}
                                        rules={[{ required: true, message: '' }]}
                                        hasFeedback={false}
                                    />
                                </div>
                                {
                                    (!disableForm) && (
                                        <>
                                            {
                                                (loadingData) ? (
                                                    <div style={{ margin: '4px' }} className={`${ButtonStyles[`button-loader-m`]}`}></div>
                                                ) : (
                                                    <span style={{
                                                        margin: '4px',
                                                        cursor: 'pointer',
                                                        ...(skuValue ? {} : { opacity: 0.3 })
                                                    }}
                                                        onClick={handleSearchSku}>
                                                        <Icon name="search" width={16} height={16} />
                                                    </span>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </div>
                            <FormInput
                                name='name'
                                placeholder='Nombre del material'
                                label='Nombre'
                                maxLength={100}
                                type='text'
                                rules={[{ required: true, message: '' }]}
                                hasFeedback={false}
                                disabled={disableForm}
                            />
                            <FormInput
                                name='price'
                                placeholder='Precio'
                                label='Precio'
                                maxLength={100}
                                type='text'
                                disabled={disableForm}
                                rules={[{ required: true, message: '' },
                                {
                                    validator: async (_: any, value: string) => {
                                        if (!value) return Promise.reject("");
                                        if (!value.match(/^\d*\.?\d*$/)) return Promise.reject("");
                                        return Promise.resolve()
                                    }
                                }
                                ]}
                            />
                            <UploadInput
                                name='picture'
                                label='Imagen del producto'
                                placeholder='Imagen del producto'
                                onChange={onFileChange}
                                value={foundImage}
                                disabled={disableForm}
                            />
                            <div>
                                <Button isLoading={savingData}
                                    buttonType='submit'
                                    size="m"
                                    rounded
                                    customStyle={ButtonStyles['w-100']}
                                    sector='primary'>
                                    Agregar
                                </Button>
                            </div>
                            <div style={{ marginTop: '12px' }}>
                                <Button
                                    buttonType='submit'
                                    size="m"
                                    rounded
                                    customStyle={ButtonStyles['w-100']}
                                    background='white'
                                    sector='primary'
                                    onClick={() => {
                                        setShowForm(false)
                                        setFoundImage(null)
                                        setDisableForm(false)
                                    }}>
                                    Cancelar
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <div>
                        <Button
                            buttonType='submit'
                            size="m"
                            rounded
                            customStyle={ButtonStyles['w-100']}
                            background='white'
                            sector='primary'
                            onClick={() => setShowForm(true)}>
                            Agregar sugerencia
                        </Button>
                    </div>
                )
            }

        </div>
    )
}