import React, { Fragment, useState, useEffect } from 'react'
import SelectOption from 'modules/uniforms/components/selectOption/SelectOption'
import SelectedCardsOptionsHOC from 'modules/uniforms/editor/components/selectCardOption/SelectedCardsOptionsHOC'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './notebookoptions.module.scss'
import {
  handleNotebookForm,
  setOutsidePropuesta,
  setOutsidePropuestaBack,
  setRequireCoverDesing,
  setRequireCoverImg,
  setRequireCoverText,
  setShowModalUploadPortada
} from 'redux/reducers/notebooksSlice'
import {
  INotebookForm,
  TFormNotebookOptions
} from 'interfaces/notebooks-interface'
import { scrollToByIdSmooth } from 'utilities/scroll-utilities'
import ModalUploadOutside from 'modules/uniforms/editor/components/selectCardOption/ModalUploadOutside'
import Button from 'components/buttons/Button/Button'
import { IEdithNotebook } from 'services/notebooks/putNotebook'
import { SuccessToast } from 'components/Toasts/Toasts'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const NotebookOptionsHidden = ({
  uuid,
  save,
  reset,
  resetCovers
}: {
  uuid: number

  save: (
    data: IEdithNotebook,
    showToast?: boolean,
    ejecute?: () => void
  ) => void
  reset: () => void
  resetCovers: () => void
}) => {
  const {
    notebookFrontValues,
    generalOptionsNotebookFront,
    showModalUploadPortada,
    college_proposal,
    ezetera_proposal,
    require_cover_desing,
    require_cover_img,
    require_cover_text
  } = useSelector((state: RootState) => state.notebooks)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const dispatch = useDispatch()

  const [bindingTypeOpacity, setBindingTypeOpacity] = useState(false)
  const [bindingColorOpacity, setBindingColorOpacity] = useState(false)
  const [roundedOpacity, setRoundedOpacity] = useState(false)
  const [spineColor, setSpineColor] = useState(false)
  const [coverDesign, setCoverDesign] = useState(false)

  const {
    binding_type,
    binding_color,
    rounded,
    spine_color,
    cover_finishing,
    cover_design
  } = notebookFrontValues

  const handleChange = (
    keyName: TFormNotebookOptions,
    value: string | boolean | []
  ) => {
    if (keyName === 'binding_type') {
      dispatch(
        handleNotebookForm({
          ...notebookFrontValues,
          binding_color: {
            ...notebookFrontValues['binding_color'],
            label:
              value === 'Espiral' ? 'Color de la espiral' : 'Color de la cinta',
            type: value === 'Espiral' ? 'spiral_color' : 'cintas_color',
            value: ''
          },
          [keyName]: { ...notebookFrontValues[keyName], value: value }
        })
      )
    } else
      dispatch(
        handleNotebookForm({
          ...notebookFrontValues,
          [keyName]: { ...notebookFrontValues[keyName], value: value }
        })
      )
  }

  const NotebookOptionsHidden = ({ objInput }: { objInput: INotebookForm }) => {
    return (
      <div className={`${style['div-input-container']}`}>
        <SelectedCardsOptionsHOC
          valueOption={objInput.value as string}
          name={objInput.name}
          title={objInput.label}
          reset={resetCovers}
        >
          {generalOptionsNotebookFront[objInput.type].map(
            ({ name, description, option }, index: number) => {
              const handleClick = () => {
                if (objInput.multiselection) {
                  let values: string[] = objInput.value as string[]

                  if (values.includes(option.value as string)) {
                    values = values.filter(
                      (contents) => contents !== option.value
                    )
                  } else {
                    if (values.length < 3)
                      values = [...values, option.value as string]
                  }
                  handleChange(
                    objInput.name as TFormNotebookOptions,
                    values as string | boolean | []
                  )
                } else
                  handleChange(
                    objInput.name as TFormNotebookOptions,
                    option.value as string | boolean
                  )
              }
              const isSelected = () => {
                if (objInput.multiselection) {
                  let values: string[] = objInput.value as string[]
                  return values.includes(option.value as string)
                } else return option.value === objInput.value
              }
              return (
                <Fragment key={index}>
                  <SelectOption
                    toolTipLabel={description}
                    label={name}
                    selected={isSelected()}
                    picture={option.picture}
                    background={option.value as string}
                    help={objInput.showHelp}
                    onClick={() => objInput.enabled && handleClick()}
                  />
                </Fragment>
              )
            }
          )}
        </SelectedCardsOptionsHOC>
      </div>
    )
  }

  useEffect(() => {
    const handleScroll = (id: string) => {
      if (
        !(
          binding_type.value !== '' &&
          binding_color.value !== '' &&
          rounded.value !== '' &&
          rounded.value !== '' &&
          spine_color.value !== '' &&
          cover_finishing.value !== '' &&
          cover_finishing.value !== '' &&
          cover_design.value !== ''
        )
      )
        scrollToByIdSmooth(id)
    }
    if (cover_design.value !== '') {
      setCoverDesign(true)
      handleScroll('binding_type')
    }

    if (binding_type.value !== '') {
      setBindingTypeOpacity(true)
      handleScroll('binding_color')
    }
    if (binding_color.value !== '') {
      setBindingColorOpacity(true)
      handleScroll('rounded')
    }
    if (cover_design.value === 'design') {
      if (rounded.value !== '') {
        setRoundedOpacity(true)
        handleScroll('spine_color')
      }
      if (spine_color.value !== '') {
        setSpineColor(true)
        handleScroll('cover_finishing')
      }
    }
  }, [
    binding_type,
    binding_color,
    rounded,
    spine_color,
    cover_finishing,
    cover_design
  ])

  const handleOk = () => {
    reset()
    dispatch(setShowModalUploadPortada(false))
  }

  const handleCancel = () => {
    reset()
    if (!college_proposal) {
      dispatch(setOutsidePropuesta(''))
      dispatch(setOutsidePropuestaBack(''))
    }
    dispatch(setShowModalUploadPortada(false))
  }

  const changeCoverColor = (namem: TFormNotebookOptions, myalue: string) => {
    dispatch(
      handleNotebookForm({
        ...notebookFrontValues,
        [namem]: { ...notebookFrontValues[namem], value: myalue }
      })
    )
  }
  return (
    <div>
      {uid.role != 'Ezetera' && (
        <>
          {(() => {
            return (
              <div
                id='cover_design'
                className={`${style['durarion']} ${style['display-full-opacity']} ${style['display-show']}`}
              >
                <NotebookOptionsHidden objInput={cover_design} />
              </div>
            )
          })()}
          {college_proposal && (
            <div className={`${style['upload-message-container']}`}>
              <p className={`${style['upload-message-p']}`}>
                Tu portada personalizada pasara a un proceso de revisión dentro
                de Etzetera, si hubiese comentarios te los haremos llegar, en
                caso contrario se procederá a su proceso de producción de la
                manera normal.
              </p>
            </div>
          )}
          {cover_design.value === 'proporsal' && (
            <Fragment>
              <div className={`${style['upload-message-container']}`}>
                <p className={`${style['upload-message-p']}`}>
                  Nuestro equipo de diseño de Etzetera mandara una propuesta
                  para tu portada, en base a las siguientes preguntas. La cual
                  estará disponible en 3-6 días hábiles, en la cual podrás
                  aceptar o rechazar dicha propuesta.
                </p>
              </div>
              <label className={`${style['label-propuesta']}`}>
                <input
                  onChange={() =>
                    dispatch(setRequireCoverText(!require_cover_text))
                  }
                  checked={require_cover_text}
                  disabled={ezetera_proposal}
                  type='checkbox'
                />
                ¿Requiere texto la portada?
              </label>
              <label className={`${style['label-propuesta']}`}>
                <input
                  onChange={() =>
                    dispatch(setRequireCoverImg(!require_cover_img))
                  }
                  checked={require_cover_img}
                  disabled={ezetera_proposal}
                  type='checkbox'
                />
                ¿Requiere una imagen adicional la portada?
              </label>
              <label className={`${style['label-propuesta']}`}>
                <input
                  onChange={() =>
                    dispatch(setRequireCoverDesing(!require_cover_desing))
                  }
                  checked={require_cover_desing}
                  disabled={ezetera_proposal}
                  type='checkbox'
                />
                ¿Requiere un diseño especial la portada?
              </label>

              <Button
                customStyle={style['margin-top-button']}
                disabled={ezetera_proposal}
                rounded
                label='Solicitar propuesta'
                sector='tertiary'
                size='m'
                onClick={() =>
                  save(
                    {
                      ezetera_proposal: true,
                      ext_cover_desing: 'proporsal',
                      status: 7,
                      step: 2,
                      require_cover_desing,
                      require_cover_img,
                      require_cover_text
                    },
                    false,
                    () => {
                      SuccessToast(
                        'La solicitud de propuesta se ha mandado de forma exitosa'
                      )
                    }
                  )
                }
              />
            </Fragment>
          )}
        </>
      )}

      {(() => {
        let showme = cover_design.value !== ''
        return (
          <div
            id='binding_type'
            className={`${style['durarion']} 
                ${showme && style['display-show']}
                ${coverDesign && style['display-full-opacity']}`}
          >
            <NotebookOptionsHidden objInput={binding_type} />
          </div>
        )
      })()}

      {(() => {
        let showme = binding_type.value !== ''
        return (
          <div
            id='binding_color'
            className={`${style['durarion']} 
                ${showme && style['display-show']}
                ${bindingTypeOpacity && style['display-full-opacity']}`}
          >
            <NotebookOptionsHidden objInput={binding_color} />
          </div>
        )
      })()}
      {(() => {
        let showme = binding_color.value !== ''
        return (
          <div
            id='rounded'
            className={`${style['durarion']} 
                ${showme && style['display-show']}
                ${bindingColorOpacity && style['display-full-opacity']}`}
          >
            <NotebookOptionsHidden objInput={rounded} />
          </div>
        )
      })()}
      {cover_design.value === 'design' && (
        <Fragment>
          {(() => {
            let showme = rounded.value !== ''
            return (
              <div
                id='spine_color'
                className={`${style['durarion']} 
          ${showme && style['display-show']}
          ${roundedOpacity && style['display-full-opacity']}`}
              >
                <NotebookOptionsHidden objInput={spine_color} />
                {cover_design.value === 'design' && (
                  <div>
                    <div className={`${style['div-types-flex']}`}>
                      <label className={`${style['label-spine-color']}`}>
                        Selecciona un color para tu portada
                        <input
                          onChange={(e) => {
                            e.stopPropagation()
                            changeCoverColor('spine_color', e.target.value)
                          }}
                          value={spine_color.value as string}
                          type='color'
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )
          })()}

          {(() => {
            let showme = spine_color.value !== ''
            return (
              <div
                id='cover_finishing'
                className={`${style['durarion']} 
          ${showme && style['display-show']}
          ${spineColor && style['display-full-opacity']}`}
              >
                <NotebookOptionsHidden objInput={cover_finishing} />
              </div>
            )
          })()}
        </Fragment>
      )}
      <ModalUploadOutside
        isModalOpen={showModalUploadPortada}
        handleOk={handleOk}
        handleCancel={handleCancel}
        uuid={uuid}
        saveChange={save}
      />
    </div>
  )
}

export default NotebookOptionsHidden
