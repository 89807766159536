import { useValidatePermission } from 'hooks/utils/useValidatePermission'
import QuotasModule from 'modules/quotas'
import{ memo } from 'react'

const index = memo(() => {
  useValidatePermission()

  return (
    <QuotasModule/>
  )
})

export default index