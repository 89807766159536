import React from 'react'

const GreenTagsTShirtFemale = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='green'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Green_Tag' data-name='Green Tag' stroke='black'>
        <g id='Green_Tag-2' data-name='Green Tag'>
          <g stroke='green'>
            <path d='m344.57,40.97c.11-.08.19-.17.3-.24.33-.23.49-.57.65-.91.09-.21.02-.37-.21-.5-.7-.42-1.47-.53-2.27-.53-.02,0-.05,0-.07-.02.29-.07.62,0,.92-.01.09,0,.23.05.26-.05.01-.1-.1-.15-.17-.2-.11-.11-.23-.2-.33-.31.55.39,1.17.62,1.84.73.3.05.4-.03.43-.28.05-.39.11-.79-.15-1.18.46.4.99.75.95,1.41.15-.03.27-.07.41-.1.12-.03.19.05.26.13.11.13-.06.11-.09.16-.21.27-.55.43-.64.81-.06.25-.33.38-.57.5-.48.25-.98.44-1.52.6Z' />
            <path d='m400.61,43.64c.13-.05.23-.11.36-.14.38-.12.64-.4.9-.67.15-.17.13-.35-.05-.54-.55-.62-1.25-.94-2-1.19-.02-.02-.04-.03-.06-.04.31,0,.58.18.89.27.09.02.19.11.24.02.08-.07-.04-.17-.08-.25-.07-.13-.16-.25-.21-.4.41.55.92.96,1.51,1.27.27.14.4.09.52-.14.16-.36.34-.71.21-1.17.31.51.7,1.01.47,1.63.16.02.28.02.42.03.12,0,.17.11.21.2.05.15-.1.09-.15.12-.27.19-.67.24-.85.58-.13.22-.44.26-.7.3-.53.09-1.07.12-1.63.12Z' />
            <path d='m457.67,40.71c.13-.05.24-.1.37-.14.38-.12.64-.4.89-.67.15-.17.13-.35-.04-.54-.55-.62-1.25-.95-2-1.19-.02-.02-.04-.02-.06-.04.31.01.58.19.88.26.09.02.2.12.25.02.07-.07-.04-.16-.08-.25-.08-.13-.17-.25-.22-.39.41.55.92.95,1.52,1.26.27.14.4.09.5-.14.17-.36.35-.71.22-1.17.31.51.7,1.01.48,1.63.16.02.28.02.42.03.12,0,.17.1.2.2.06.15-.09.09-.14.12-.27.19-.66.24-.85.57-.14.23-.43.26-.7.31-.53.08-1.08.11-1.64.11Z' />
            <g stroke='green' >
              <path d='m314.23,34.44l-.06.57s0,.05,0,.06c0,.04-.03.05-.08.05-.04,0-.07-.04-.08-.09-.08-.23-.2-.44-.37-.61-.17-.17-.39-.29-.65-.35-.33-.08-.62-.02-.86.17-.24.19-.4.44-.47.75-.08.35-.04.66.12.93.16.27.38.44.66.5.12.03.25.03.38.02s.23-.05.27-.09.08-.11.1-.21c.05-.22.07-.35.06-.41s-.04-.1-.08-.13c-.04-.03-.14-.07-.29-.12-.06-.02-.08-.04-.07-.08,0-.04.04-.05.09-.04l.36.08,1.09.23s.07.03.06.07c0,.02-.02.03-.04.03-.02,0-.09,0-.21,0-.09,0-.15,0-.18.03-.03.03-.09.21-.19.57-.02.08-.03.12-.04.13,0,0-.11.03-.31.07-.2.04-.4.05-.6.04-.2,0-.4-.03-.59-.07-.32-.07-.6-.19-.83-.35-.23-.16-.4-.35-.51-.58-.11-.22-.14-.47-.08-.73.08-.37.31-.65.68-.85.37-.2.83-.24,1.37-.11.19.04.36.1.5.17s.31.17.49.28c.04.02.07.04.1.04.03,0,.06,0,.09-.04.03-.04.07-.06.1-.05.04,0,.06.06.05.14Z' />
              <path d='m316.36,35.98c.21.07.37.16.49.26.12.11.19.29.21.54.02.24.06.4.12.49.06.09.16.15.29.18.07.02.11.05.1.08,0,.04-.07.04-.19.02l-.9-.2c-.08-.1-.12-.25-.11-.45,0-.26,0-.45-.03-.57-.03-.11-.08-.2-.17-.27-.09-.06-.19-.11-.31-.13-.04,0-.09-.02-.15-.02-.09.45-.15.73-.16.84-.02.11,0,.19.03.24.04.06.12.1.25.13.07.02.1.04.09.08,0,.03-.04.05-.1.03l-.72-.16c-.1-.02-.22-.04-.38-.07-.14-.02-.23-.04-.27-.04-.06-.01-.09-.04-.09-.08,0-.04.04-.05.1-.04.09.02.17.02.26,0,.08-.02.15-.17.21-.44l.07-.36.14-.69c.07-.36.1-.58.08-.67s-.12-.15-.29-.19c-.07-.01-.09-.04-.09-.08,0-.04.06-.05.14-.03.44.09.82.16,1.13.21.14.02.26.04.37.06.36.07.63.19.8.35s.25.33.21.52c-.03.13-.1.23-.21.31-.12.08-.25.13-.41.15-.15.02-.33.02-.53-.01Zm-.63-.21s.07.02.09.02c.14.03.28.04.43.02.15-.01.26-.06.34-.14.08-.08.12-.17.15-.28.03-.16,0-.3-.1-.41-.1-.11-.24-.19-.42-.23-.08-.02-.17-.02-.27-.02l-.21,1.03Z' />
              <path d='m320.82,35.55c.01.21.02.42.04.63,0,.04-.03.05-.08.04-.02,0-.05-.03-.07-.07-.13-.22-.25-.38-.38-.47s-.31-.15-.57-.2c-.14-.03-.28-.04-.42-.05-.07.22-.13.47-.18.74,0,.05-.02.12-.04.23l.18.04c.11.02.21.03.3.02.09,0,.17-.05.24-.12.07-.07.11-.12.13-.14.02-.04.05-.05.08-.05.04,0,.06.03.06.06,0,0,0,.02-.01.03-.03.08-.09.31-.16.71-.01.06-.01.11,0,.15,0,.01,0,.02,0,.03,0,.04-.04.05-.09.04-.03,0-.06-.05-.06-.12,0-.13-.06-.23-.17-.31-.1-.08-.27-.15-.51-.2-.05.22-.08.36-.09.43-.06.29-.08.47-.08.53,0,.06.03.11.1.16.06.05.18.09.34.12.6.11,1.03.03,1.27-.25.04-.04.07-.06.1-.05.05,0,.07.03.06.06,0,.02-.01.05-.03.08l-.26.53-.86-.16c-.24-.05-.47-.09-.67-.14-.21-.05-.37-.08-.49-.1-.13-.02-.33-.06-.6-.09-.03,0-.06,0-.07,0-.07-.01-.11-.04-.1-.08,0-.04.04-.05.1-.04.15.03.26.01.33-.04.07-.06.12-.19.16-.4l.2-.99c.06-.34.1-.55.1-.63s-.01-.14-.05-.17c-.04-.04-.12-.07-.24-.11-.05-.01-.07-.04-.07-.07,0-.04.04-.05.1-.04l.57.12,1.91.36Z' />
              <path d='m323.96,36.1c.02.21.03.42.05.63,0,.04-.03.05-.08.04-.02,0-.05-.03-.08-.07-.13-.22-.26-.38-.38-.46-.12-.09-.31-.15-.57-.19-.14-.02-.28-.04-.42-.04-.07.22-.13.47-.17.75,0,.05-.02.12-.04.24l.18.03c.11.02.21.02.3.01s.16-.05.24-.13c.07-.07.11-.12.12-.14.02-.04.05-.06.08-.05.04,0,.06.03.06.06,0,0,0,.02-.01.03-.03.08-.08.32-.15.72,0,.06-.01.11,0,.15,0,.01,0,.02,0,.03,0,.04-.03.05-.09.04-.03,0-.06-.04-.06-.12-.01-.13-.07-.23-.17-.31s-.27-.15-.52-.19c-.05.22-.07.36-.09.43-.05.29-.08.47-.07.53,0,.06.03.11.1.16.06.05.18.08.34.11.6.1,1.03.02,1.27-.27.03-.04.07-.06.1-.05.05,0,.07.03.06.06,0,.02-.01.05-.03.08l-.25.53-.86-.15c-.24-.04-.47-.09-.68-.13-.21-.04-.37-.08-.49-.1-.13-.02-.33-.05-.6-.08-.03,0-.06,0-.07,0-.07-.01-.11-.04-.1-.08,0-.04.04-.05.1-.04.15.03.26,0,.33-.05.06-.06.11-.19.15-.4l.19-1c.06-.34.09-.55.09-.63,0-.08-.02-.14-.05-.17s-.12-.07-.24-.11c-.05-.01-.07-.04-.07-.07,0-.04.04-.05.1-.04l.57.11,1.91.33Z' />
              <path d='m325.37,36.31c.65.83,1.19,1.5,1.63,2,.02-.09.03-.15.03-.17l.12-.77c.03-.18.04-.31.02-.4-.01-.08-.06-.15-.13-.21-.08-.06-.17-.1-.28-.13-.06-.01-.08-.04-.08-.07,0-.04.04-.05.09-.04.03,0,.07.01.12.03.09.02.22.05.4.08.22.03.39.06.5.06.05,0,.09,0,.12.01.06,0,.09.03.08.07,0,.04-.05.05-.13.05-.1,0-.2.01-.3.06s-.16.16-.19.34l-.16,1.1-.15.83c-.01.07-.04.1-.1.09-.01,0-.03,0-.05-.02-.02-.01-.11-.12-.28-.32-.17-.2-.43-.52-.76-.94-.33-.42-.57-.73-.7-.91,0,.03-.06.36-.16.98-.03.19-.04.34-.04.45,0,.11.05.2.15.26.1.07.19.1.27.12.05,0,.08.03.07.06,0,.04-.04.05-.09.04-.04,0-.08-.02-.14-.03-.09-.03-.21-.05-.36-.07-.24-.04-.41-.06-.52-.06-.07,0-.12,0-.14-.01-.06,0-.09-.04-.08-.08,0-.03.06-.04.16-.03.18.02.3-.02.36-.1.06-.08.1-.2.13-.36.11-.67.17-1.08.18-1.23.01-.15-.02-.29-.11-.43-.09-.14-.2-.23-.35-.28-.06-.02-.09-.04-.08-.08,0-.04.04-.06.11-.05.02,0,.04,0,.07.02.04,0,.1.02.2.04.21.03.4.06.58.1Z' />
              <path d='m330.83,37.12c.82.13,1.43.23,1.83.28.3.04.63.08,1,.12.13.01.24.02.32.04.03,0,.08.01.16.02,0,.05,0,.17,0,.34v.31c-.01.05-.04.07-.1.07-.04,0-.07-.04-.1-.11-.05-.11-.11-.21-.2-.3-.08-.09-.19-.15-.31-.2s-.33-.08-.61-.12c-.05.2-.1.44-.14.74-.04.31-.08.6-.1.89-.02.29-.03.46-.02.52.01.06.04.11.09.14s.16.07.34.1c.06.01.09.03.09.07,0,.04-.03.05-.09.04h-.07s-.49-.09-.49-.09c-.39-.05-.62-.08-.7-.09l-.3-.03s-.07-.01-.15-.02c-.04-.01-.05-.03-.05-.06,0-.04.04-.05.1-.04.14.02.25.01.33-.03.08-.04.13-.11.16-.21.03-.1.07-.33.12-.68.08-.55.12-1.01.12-1.36-.26-.04-.45-.05-.57-.05-.12,0-.24.03-.36.1-.12.06-.22.15-.31.27-.03.05-.07.07-.1.06-.05,0-.08-.03-.07-.07,0-.01.02-.1.07-.25s.08-.29.12-.39Z' />
              <path d='m335.57,37.71l.16.02c.16.4.38,1.02.66,1.87.13.39.22.63.28.72.06.09.16.15.3.18.05.01.07.03.07.06,0,.04-.04.06-.1.05-.03,0-.12-.02-.26-.05-.16-.03-.3-.06-.42-.07-.11-.01-.25-.03-.43-.04-.13,0-.22-.02-.26-.02-.07,0-.11-.03-.1-.07,0-.03.03-.04.08-.04.2.01.3-.04.32-.15,0-.05,0-.15-.05-.3s-.08-.28-.13-.4c-.1-.01-.18-.03-.24-.03-.32-.04-.57-.07-.75-.09-.18.29-.27.48-.28.58,0,.07.02.13.08.18s.13.08.21.1c.06.01.09.04.08.07,0,.04-.04.05-.1.04-.03,0-.09-.02-.2-.04-.14-.03-.24-.04-.3-.05-.13-.02-.25-.03-.36-.03-.03,0-.05,0-.07,0-.06,0-.09-.03-.08-.07,0-.03.03-.04.06-.05.1,0,.18-.03.23-.05s.12-.08.2-.16.17-.22.28-.41c.26-.43.63-1.01,1.12-1.74Zm-.26.66l-.53.82.85.1c-.03-.11-.08-.26-.15-.45-.08-.21-.13-.36-.17-.47Z' />
              <path d='m340.51,38.59v.58s0,.05,0,.06c0,.04-.03.06-.07.05-.04,0-.07-.03-.09-.08-.1-.23-.24-.42-.43-.57-.19-.16-.41-.25-.68-.28-.34-.04-.62.04-.84.25-.22.21-.35.47-.39.79-.04.35.03.66.21.91.19.25.42.4.71.43.12.01.25,0,.38-.02.13-.03.22-.07.26-.12.04-.05.07-.12.08-.22.03-.22.03-.36.02-.41s-.05-.1-.09-.12-.15-.06-.3-.09c-.06-.01-.09-.03-.08-.07,0-.04.03-.05.09-.05l.36.04,1.11.11s.07.03.07.06c0,.02-.01.03-.03.04s-.09.01-.21.02c-.09,0-.15.02-.17.05-.03.03-.07.22-.13.58-.01.08-.02.12-.03.13,0,0-.1.04-.3.1-.2.06-.39.09-.6.11-.2.02-.4.01-.59-.01-.33-.04-.62-.13-.86-.26s-.44-.31-.57-.52-.19-.45-.15-.72c.05-.38.24-.68.59-.92s.8-.32,1.35-.25c.19.02.37.06.52.12.15.06.32.13.51.23.04.02.07.03.1.03.03,0,.06-.01.08-.05.03-.05.06-.07.1-.06.04,0,.06.05.06.13Z' />
              <path d='m371.77,41.21l.04.57s0,.05,0,.06c0,.04-.03.06-.07.06-.04,0-.07-.03-.1-.07-.11-.22-.27-.4-.46-.54-.2-.14-.43-.22-.69-.24-.34-.02-.62.08-.83.31-.21.22-.32.5-.34.82-.02.36.07.65.27.9.2.24.45.37.74.38.12,0,.25,0,.38-.05.13-.04.21-.08.25-.14.04-.05.06-.13.07-.23.01-.22,0-.36,0-.41s-.05-.1-.1-.12-.15-.05-.3-.07c-.06,0-.09-.03-.09-.06,0-.04.03-.06.09-.05l.36.02,1.11.04s.07.02.07.06c0,.02-.01.03-.03.04-.02,0-.09.02-.2.03-.09.01-.14.03-.17.06-.03.03-.06.23-.09.59,0,.08-.01.13-.02.14s-.1.05-.29.12c-.19.07-.39.12-.59.14s-.4.04-.59.02c-.33-.02-.62-.09-.88-.21s-.45-.28-.6-.49-.21-.44-.2-.71c.02-.38.2-.7.53-.95s.78-.37,1.33-.34c.2.01.37.04.53.09s.33.11.53.2c.04.02.07.03.1.03.03,0,.06-.02.08-.05.03-.05.06-.07.09-.07.04,0,.07.05.07.13Z' />
              <path d='m374.17,42.42c.21.04.39.1.53.19.14.09.23.26.29.51.06.23.12.39.19.46.07.08.18.12.31.14.08,0,.11.03.11.06,0,.04-.06.05-.19.05l-.92-.07c-.09-.08-.15-.23-.18-.43-.03-.26-.07-.45-.11-.56-.04-.11-.11-.19-.2-.24-.09-.05-.2-.08-.33-.09-.04,0-.09,0-.15,0-.02.46-.04.75-.04.85,0,.11.02.19.07.23.05.05.14.08.27.09.07,0,.11.03.11.06,0,.04-.03.05-.1.05l-.74-.05c-.1,0-.23,0-.38,0-.14,0-.23,0-.27,0-.07,0-.1-.02-.1-.06,0-.04.03-.05.09-.05.09,0,.17,0,.25-.04.08-.03.12-.19.14-.46l.02-.37.04-.71c.02-.36.01-.59-.02-.67-.04-.08-.14-.13-.31-.14-.07,0-.1-.03-.1-.06,0-.04.05-.05.14-.05.45.02.84.04,1.15.04.14,0,.27,0,.38,0,.36.02.65.09.85.22s.3.29.29.48c0,.13-.06.24-.16.34-.1.1-.23.17-.38.21-.15.04-.32.07-.52.07Zm-.65-.12s.07,0,.09,0c.14,0,.28,0,.43-.04.14-.04.25-.1.31-.19.06-.09.1-.19.1-.3,0-.16-.04-.29-.16-.39-.11-.1-.26-.15-.45-.16-.08,0-.17,0-.27.02l-.05,1.05Z' />
              <path d='m378.53,41.36c.04.21.08.41.12.62,0,.04-.03.06-.07.05-.03,0-.05-.02-.08-.06-.16-.2-.3-.34-.44-.41-.13-.07-.33-.11-.59-.12-.14,0-.28,0-.42.01-.04.23-.07.48-.08.76,0,.05,0,.13,0,.24h.18c.11.02.21,0,.3-.01.08-.02.16-.07.22-.15.06-.08.1-.13.11-.16.02-.04.04-.06.08-.06.04,0,.07.02.06.05,0,0,0,.02,0,.03-.02.08-.04.32-.06.73,0,.06,0,.11.01.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.05-.04,0-.06-.04-.08-.11-.03-.12-.1-.22-.21-.29s-.29-.11-.54-.12c-.02.22-.03.37-.03.43-.01.3-.02.48,0,.53,0,.06.05.1.12.14.07.04.19.06.36.07.61.03,1.02-.11,1.23-.42.03-.04.06-.06.09-.06.05,0,.07.02.07.05,0,.02,0,.05-.02.08l-.18.56-.87-.04c-.25-.01-.47-.03-.69-.04-.21-.02-.38-.03-.5-.03-.13,0-.34,0-.61,0-.03,0-.06,0-.07,0-.08,0-.11-.03-.11-.07,0-.04.03-.05.09-.05.16,0,.26-.02.32-.09.06-.07.09-.21.1-.42l.06-1.01c.02-.35.02-.56.01-.64,0-.08-.03-.13-.08-.17-.04-.03-.13-.06-.25-.08-.05,0-.08-.03-.07-.06,0-.04.03-.06.09-.05l.58.04,1.94.09Z' />
              <path d='m381.76,41.5c.04.21.09.41.13.62,0,.04-.03.06-.07.05-.03,0-.05-.02-.08-.06-.16-.2-.3-.34-.44-.41-.13-.07-.33-.11-.59-.12-.14,0-.28,0-.42.01-.04.23-.06.48-.07.76,0,.05,0,.13,0,.24h.18c.11.01.21,0,.3-.02.08-.02.16-.07.22-.16s.1-.13.1-.16c.02-.04.04-.06.08-.06.04,0,.07.02.07.05,0,0,0,.02,0,.03-.02.08-.04.32-.06.73,0,.06,0,.11.01.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.05-.04,0-.06-.04-.08-.11-.03-.12-.1-.22-.21-.29-.11-.07-.29-.11-.54-.12-.02.22-.03.36-.03.43-.01.3-.01.48,0,.53,0,.06.05.1.12.14s.19.06.36.07c.61.02,1.02-.12,1.23-.43.03-.04.06-.06.09-.06.05,0,.07.02.07.05,0,.02,0,.05-.02.08l-.18.56-.87-.04c-.25-.01-.47-.02-.69-.04-.21-.02-.38-.03-.5-.03-.13,0-.34,0-.61,0-.03,0-.06,0-.07,0-.08,0-.11-.02-.11-.07,0-.04.03-.05.09-.05.16,0,.26-.02.32-.09.06-.07.09-.21.1-.42l.06-1.01c.01-.35.02-.56.01-.64,0-.08-.03-.13-.08-.17-.04-.03-.13-.05-.25-.07-.05,0-.08-.03-.07-.06,0-.04.03-.06.09-.06l.58.04,1.94.08Z' />
              <path d='m383.21,41.53c.74.74,1.36,1.34,1.86,1.79,0-.09,0-.15,0-.17l.03-.78c0-.18,0-.32-.02-.4-.02-.08-.08-.14-.16-.19-.08-.05-.18-.08-.29-.09-.06,0-.09-.03-.09-.06,0-.04.03-.06.08-.05.03,0,.07,0,.12.01.09.01.23.02.4.03.22,0,.39,0,.51,0,.05,0,.09,0,.12,0,.06,0,.09.02.09.06,0,.04-.04.06-.12.06-.1,0-.2.04-.29.09-.09.06-.14.18-.14.36l-.03,1.11-.05.84c0,.07-.03.11-.09.11-.01,0-.03,0-.05-.01-.02,0-.13-.1-.32-.28-.2-.18-.49-.46-.87-.84-.38-.38-.65-.65-.81-.82,0,.04-.02.37-.04.99,0,.19,0,.34.01.46.02.11.08.19.18.25.1.05.2.08.28.08.05,0,.08.02.08.05,0,.04-.03.06-.09.06-.04,0-.08,0-.14-.01-.1-.01-.22-.02-.37-.03-.24,0-.41,0-.52,0-.07,0-.12,0-.14,0-.06,0-.09-.02-.09-.07,0-.03.05-.05.15-.05.18,0,.3-.05.35-.14.05-.09.08-.21.08-.37.02-.68.03-1.09.03-1.24,0-.15-.06-.29-.16-.42s-.23-.2-.38-.23c-.06-.01-.09-.03-.09-.06,0-.04.04-.06.1-.06.02,0,.04,0,.07,0,.04,0,.1,0,.2.01.21,0,.4.02.58.03Z' />
              <path d='m387.44,41.65c.83.04,1.45.07,1.85.08.3,0,.64,0,1,0,.13,0,.24,0,.32,0,.03,0,.09,0,.16,0,0,.05.02.17.03.33l.03.31c0,.05-.03.08-.08.07-.04,0-.08-.03-.11-.1-.06-.11-.13-.2-.23-.28-.09-.08-.2-.13-.33-.16-.13-.03-.33-.05-.62-.05-.03.2-.05.45-.06.75,0,.31,0,.61,0,.89,0,.29.02.46.04.52.02.06.05.1.1.13.05.03.17.05.35.07.06,0,.09.02.09.06,0,.04-.03.05-.08.05h-.07s-.5-.03-.5-.03c-.39-.01-.62-.02-.71-.01h-.3s-.07,0-.15,0c-.04,0-.05-.02-.05-.05,0-.04.03-.06.09-.05.14,0,.25-.02.32-.06.07-.05.12-.12.14-.23.02-.1.03-.33.04-.69.01-.56,0-1.01-.03-1.36-.26,0-.46,0-.57.01s-.23.06-.34.14c-.11.08-.2.18-.27.3-.03.05-.06.07-.1.07-.05,0-.08-.02-.08-.06,0-.01.01-.1.04-.26.02-.16.05-.29.08-.4Z' />
              <path d='m392.23,41.71h.16c.2.38.49.98.86,1.8.17.37.29.6.35.68s.17.13.32.15c.05,0,.07.02.07.05,0,.04-.03.06-.1.06-.03,0-.12,0-.26-.02-.17-.02-.31-.02-.42-.03-.11,0-.26,0-.43,0-.13,0-.22,0-.26,0-.07,0-.11-.02-.11-.06,0-.03.03-.04.08-.05.2-.01.3-.07.3-.18,0-.05-.03-.15-.08-.29-.05-.14-.11-.27-.18-.39-.1,0-.19,0-.24,0-.32,0-.58-.01-.76-.01-.14.31-.22.51-.22.61,0,.07.03.13.1.17.07.04.14.07.22.07.06,0,.09.03.09.06,0,.04-.03.05-.1.05-.03,0-.09,0-.2-.01-.14-.01-.24-.02-.31-.02-.13,0-.25,0-.36.01-.03,0-.05,0-.07,0-.06,0-.09-.02-.09-.06,0-.03.02-.05.06-.06.1-.02.17-.05.23-.08.06-.03.12-.09.18-.18s.14-.24.24-.44c.21-.46.52-1.07.93-1.85Zm-.18.68l-.44.87h.85c-.04-.1-.11-.24-.2-.42-.1-.2-.17-.35-.22-.45Z' />
              <path d='m397.25,42.07l.06.57s0,.05,0,.06c0,.04-.02.06-.07.06-.04,0-.07-.02-.1-.07-.12-.21-.28-.39-.49-.52s-.44-.21-.7-.21c-.34,0-.61.11-.81.34-.2.23-.3.51-.3.83,0,.36.1.65.31.88.21.23.46.35.75.35.12,0,.25-.02.38-.06.13-.04.21-.09.25-.15.04-.05.06-.13.06-.23,0-.22,0-.36-.03-.41-.02-.05-.06-.09-.11-.11-.05-.02-.15-.04-.3-.06-.06,0-.09-.03-.09-.06,0-.04.03-.06.09-.06h.36s1.11,0,1.11,0c.05,0,.07.02.07.05,0,.02-.01.03-.03.04-.02,0-.09.02-.2.04-.09.01-.14.04-.17.07-.02.03-.05.23-.06.59,0,.08,0,.13-.01.14,0,0-.1.05-.29.13s-.38.13-.58.17c-.2.04-.4.05-.59.05-.33,0-.63-.06-.88-.17-.26-.11-.47-.26-.62-.46-.16-.2-.23-.43-.23-.7,0-.38.17-.7.49-.97.32-.27.76-.4,1.31-.39.2,0,.37.02.53.07.16.04.34.1.54.17.04.02.07.02.1.02.03,0,.06-.02.08-.06.02-.05.05-.07.09-.07.04,0,.07.04.08.12Z' />
              <path d='m427.29,41.59l.09.57s0,.05,0,.06c0,.04-.02.06-.06.06-.04,0-.08-.02-.1-.06-.13-.21-.31-.37-.52-.49-.21-.12-.45-.18-.71-.17-.34.01-.61.14-.79.39-.18.24-.27.53-.25.85.02.36.14.64.36.86.22.22.48.32.77.31.12,0,.25-.03.37-.09.12-.05.21-.11.24-.16.03-.05.05-.13.04-.23,0-.22-.03-.36-.05-.41-.02-.05-.06-.09-.11-.11-.05-.02-.15-.03-.31-.04-.06,0-.09-.02-.09-.05,0-.04.03-.06.08-.06l.36-.02,1.11-.07s.08.01.08.05c0,.02,0,.03-.03.04-.02,0-.08.02-.2.05-.09.02-.14.05-.16.08-.02.03-.03.23-.03.6,0,.08,0,.13,0,.14,0,0-.1.06-.28.15-.18.09-.37.16-.57.2-.2.05-.39.08-.59.08-.33.01-.63-.03-.89-.12-.26-.1-.48-.24-.65-.42-.17-.19-.26-.41-.27-.68-.02-.38.13-.71.43-1,.31-.29.74-.45,1.29-.47.2,0,.37,0,.53.04.16.03.34.08.54.14.04.01.08.02.1.02.03,0,.06-.02.07-.06.02-.05.05-.08.09-.08.04,0,.07.04.08.12Z' />
              <path d='m429.79,42.55c.22.01.4.06.54.13.14.08.26.23.34.47.08.23.16.37.24.44.08.07.19.1.32.1.08,0,.11.02.12.05,0,.04-.06.06-.18.07l-.93.03c-.1-.07-.17-.21-.22-.41-.06-.26-.11-.44-.17-.54s-.13-.18-.23-.22-.21-.06-.34-.05c-.04,0-.09,0-.15.01.02.46.04.75.05.85,0,.11.04.18.09.23s.14.07.28.06c.07,0,.11.02.11.05,0,.04-.03.06-.09.06l-.74.02c-.1,0-.23.01-.38.03-.14.01-.23.02-.27.02-.07,0-.1-.01-.1-.05,0-.04.03-.06.08-.06.09,0,.17-.03.25-.07.07-.04.1-.2.09-.47l-.02-.37-.03-.71c-.02-.36-.05-.59-.09-.67-.04-.08-.15-.11-.32-.11-.07,0-.1-.01-.1-.05,0-.04.04-.06.13-.06.45-.02.83-.05,1.15-.08.14-.02.26-.03.38-.03.36-.02.65.03.87.13s.32.26.33.45c0,.13-.04.25-.13.35-.09.11-.21.19-.36.25-.14.06-.31.1-.51.12Zm-.66-.05s.07,0,.09,0c.14,0,.28-.04.42-.09.14-.05.24-.12.29-.22.05-.1.08-.2.07-.3,0-.16-.08-.29-.2-.37-.12-.09-.28-.12-.47-.11-.08,0-.17.02-.26.05l.05,1.05Z' />
              <path d='m434.03,41.05c.06.2.13.4.19.6,0,.04-.02.06-.07.06-.03,0-.06-.01-.09-.05-.18-.19-.34-.31-.48-.36-.14-.05-.34-.07-.6-.06-.14,0-.28.03-.42.05-.01.23-.01.49,0,.76,0,.05,0,.13.02.24h.18c.11-.02.21-.03.29-.06.08-.03.15-.09.2-.18s.08-.14.09-.17c.01-.04.04-.06.07-.07.04,0,.07.01.07.04,0,0,0,.02,0,.03-.01.08,0,.33.02.73,0,.06.01.11.03.15,0,.01,0,.02,0,.03,0,.04-.02.06-.07.06-.04,0-.07-.03-.09-.1-.04-.12-.12-.21-.24-.27-.12-.06-.3-.08-.55-.07,0,.22,0,.37.01.44.02.3.03.48.05.53.01.06.06.1.13.13.07.03.19.04.36.03.61-.04,1-.22,1.18-.55.02-.05.05-.07.08-.07.05,0,.07.01.07.04,0,.02,0,.05,0,.08l-.12.57-.87.05c-.25.01-.47.02-.69.03s-.38.01-.5.02c-.13,0-.34.03-.61.06-.03,0-.06,0-.07,0-.08,0-.11-.01-.12-.05,0-.04.03-.06.09-.06.16,0,.26-.05.31-.12.05-.07.07-.21.06-.43l-.05-1.01c-.02-.35-.04-.56-.05-.64-.01-.08-.05-.13-.09-.16-.05-.03-.13-.04-.26-.05-.05,0-.08-.02-.08-.05,0-.04.03-.06.08-.06l.58-.02,1.94-.11Z' />
              <path d='m437.23,40.83c.07.2.13.4.19.6,0,.04-.02.06-.07.06-.03,0-.06-.01-.09-.05-.18-.18-.34-.3-.48-.36-.14-.05-.34-.07-.6-.05-.14,0-.28.03-.42.06-.01.23,0,.49,0,.76,0,.05,0,.13.02.24h.18c.11-.02.21-.04.29-.07.08-.03.15-.09.2-.18.05-.09.08-.14.09-.17.01-.04.04-.07.07-.07.04,0,.07.01.07.04,0,0,0,.02,0,.03-.01.08,0,.33.02.73,0,.06.01.11.03.15,0,.01,0,.02,0,.03,0,.04-.02.06-.07.06-.04,0-.07-.03-.09-.1-.04-.12-.12-.21-.24-.26-.12-.06-.3-.08-.55-.06,0,.22.01.37.02.44.02.3.04.48.05.53.01.06.06.1.13.13.07.03.19.04.36.03.61-.04,1-.23,1.17-.56.02-.05.05-.07.08-.07.05,0,.07.01.07.04,0,.02,0,.05,0,.08l-.12.57-.87.06c-.25.02-.47.03-.69.03-.21,0-.38.01-.5.02-.13,0-.34.03-.61.06-.03,0-.06,0-.07,0-.08,0-.11-.01-.12-.05,0-.04.03-.06.09-.06.16-.01.26-.05.31-.12.05-.07.07-.21.05-.43l-.05-1.01c-.02-.35-.04-.56-.06-.64-.02-.08-.05-.13-.09-.16-.05-.03-.13-.04-.26-.05-.05,0-.08-.02-.08-.05,0-.04.03-.06.08-.06l.58-.03,1.94-.13Z' />
              <path d='m438.66,40.7c.82.65,1.51,1.18,2.05,1.57,0-.09,0-.15-.01-.17l-.06-.78c-.01-.18-.04-.31-.07-.39-.03-.08-.09-.14-.18-.17-.09-.04-.19-.06-.3-.06-.06,0-.09-.02-.09-.05,0-.04.02-.06.08-.06.03,0,.07,0,.12,0,.09,0,.23,0,.4-.02.22-.02.39-.04.5-.05.05,0,.09-.01.12-.02.06,0,.09.01.1.05,0,.04-.04.06-.12.07-.1.02-.19.06-.27.13-.08.07-.12.19-.1.37l.1,1.11.05.84c0,.07-.02.11-.07.11-.01,0-.03,0-.05,0-.02,0-.14-.09-.35-.25-.21-.16-.53-.4-.96-.74-.42-.33-.72-.57-.9-.72,0,.04.02.37.07.99.02.19.04.34.07.45.03.11.1.18.21.22s.21.06.29.05c.05,0,.08.01.09.04,0,.04-.02.06-.08.07-.04,0-.08,0-.14,0-.1,0-.22,0-.37.01-.24.02-.41.04-.52.06-.07.01-.12.02-.14.02-.06,0-.1-.01-.1-.06,0-.03.05-.05.15-.07.18-.03.29-.09.33-.18.04-.09.05-.22.04-.37-.05-.68-.09-1.09-.11-1.24s-.09-.28-.21-.4c-.12-.11-.25-.18-.4-.19-.06,0-.1-.02-.1-.05,0-.04.03-.07.09-.07.02,0,.04,0,.07,0,.04,0,.1,0,.2-.01.21-.02.4-.03.58-.04Z' />
              <path d='m442.86,40.35c.83-.06,1.45-.11,1.85-.14.3-.03.63-.07,1-.11.13-.02.24-.03.32-.04.03,0,.09,0,.16-.01.02.05.04.16.07.33l.07.31c0,.05-.02.08-.07.08-.04,0-.08-.02-.12-.08-.07-.1-.16-.18-.26-.25s-.22-.11-.34-.12-.34,0-.63.02c0,.2,0,.45.03.76.03.31.06.6.1.89.04.28.07.46.1.51.03.06.07.1.12.12.05.02.17.03.36.02.06,0,.1.01.1.05,0,.04-.02.06-.08.06h-.07s-.5.04-.5.04c-.39.04-.62.06-.71.07l-.3.04s-.07,0-.15.01c-.04,0-.06-.02-.06-.04,0-.04.03-.06.09-.07.14-.01.25-.05.31-.1s.1-.14.11-.24,0-.34-.04-.69c-.05-.56-.12-1.01-.19-1.35-.26.02-.45.05-.57.08-.12.03-.22.09-.33.18-.1.09-.18.2-.24.33-.02.05-.05.08-.09.08-.05,0-.08-.01-.09-.05,0-.01,0-.1,0-.26,0-.16.02-.3.03-.41Z' />
              <path d='m447.62,39.83l.16-.02c.25.35.61.91,1.08,1.67.22.35.36.56.44.63.08.07.19.11.33.11.05,0,.07.01.08.04,0,.04-.02.06-.09.07-.03,0-.12,0-.26.01-.17,0-.31.01-.42.03-.11.01-.25.03-.43.06-.13.02-.22.03-.26.04-.07,0-.11,0-.12-.05,0-.03.02-.05.07-.06.2-.03.29-.11.28-.22,0-.05-.04-.15-.12-.28-.07-.13-.15-.26-.22-.36-.1,0-.19.02-.24.02-.32.03-.57.06-.75.08-.11.32-.15.53-.14.63,0,.07.05.12.12.16.07.03.15.05.23.05.06,0,.09.02.1.05,0,.04-.03.06-.09.06-.03,0-.09,0-.2.01-.14,0-.24.01-.31.02-.13.01-.25.03-.36.06-.03,0-.05.01-.07.01-.06,0-.09-.01-.09-.05,0-.03.01-.05.05-.06.09-.03.17-.07.22-.11s.1-.11.16-.21c.05-.1.11-.25.18-.47.15-.48.38-1.13.69-1.95Zm-.1.7l-.32.92.85-.1c-.06-.1-.14-.23-.25-.4-.12-.18-.21-.32-.27-.42Z' />
              <path d='m452.62,39.55l.14.56s.01.05.01.06c0,.04-.01.06-.06.07-.04,0-.08-.01-.11-.06-.15-.2-.33-.35-.55-.46-.22-.11-.46-.15-.72-.12-.34.04-.59.19-.76.44s-.23.54-.19.86c.04.35.18.63.42.83s.5.29.79.25c.12-.01.25-.05.37-.11.12-.06.2-.12.23-.18.03-.06.04-.13.03-.23-.03-.22-.05-.35-.08-.41-.03-.05-.07-.08-.12-.1-.05-.02-.16-.02-.31-.02-.06,0-.09-.01-.1-.05,0-.04.02-.06.08-.07l.36-.04,1.1-.15s.08,0,.08.04c0,.02,0,.03-.02.04-.02,0-.08.03-.2.06-.08.03-.14.06-.16.09s-.02.23.01.6c0,.08,0,.13,0,.14,0,.01-.09.07-.27.17s-.36.18-.55.24c-.19.06-.39.1-.58.13-.33.04-.63.02-.9-.06-.27-.08-.5-.2-.68-.37-.18-.17-.29-.39-.32-.66-.04-.38.08-.72.36-1.03.29-.31.7-.5,1.25-.56.19-.02.37-.02.53,0,.16.02.35.05.55.1.04,0,.08.01.11,0,.03,0,.05-.03.07-.07.02-.05.04-.08.08-.08.04,0,.07.03.09.11Z' />
            </g>
          </g>
          <g>
          <g stroke='green'>
              <path d='m416.65,81.8s1.96-.89,2.32-1.08c0,0,.04-.02,0-.07-.03-.03,0-.11.24-.33,0,0,.79-.57,1.11-1.12.3-.54.38-.75.14-1.03-.25-.31-.94-1.12-1.86-1.64-.92-.54-1.77-.97-2.57-1.19-.76-.27-1.3-.35-1.75-.43-.44-.1-.16-.15.24-.15.4.01,1.32.24,2.08.5.75.23,1.25.27,1.25.27,0,0-.08-.53-.51-1.08-.4-.53-.96-1.39-1.44-1.96-.49-.55-.73-.8-.67-.84.04-.07.14-.05.96.95.83.98,1.11,1.44,1.58,1.95.47.51.83.98,1.53,1.37.68.41,1.66.97,2.56,1.2,0,0,.61-.59.89-1.44.29-.83.27-.75.3-.68.02.04.07.13.07-.35s-.07-1.32-.09-1.47c-.02-.12,0-.15.07-.17.06-.02.21.25.39.58.18.33.54.94.8,1.63.24.72.51,1.34-.01,2.29,0,0-.06.03.02.03.06,0,.46.02.62-.01.18,0,.55-.16.75.03.2.19.25.52.25.52,0,0,.19.09.22.12.04.06.02.06-.07.08-.07.03-.3.06-.57.22-.28.16-1.05.71-1.76.89,0,0-.1.74-.93,1.22-.85.45-1.6.65-3.16.91-1.56.28-2.37.38-2.93.42,0,0-.41.06-.05-.14Z' />
              <path d='m379.44,79.56c-.45.05-.46.1-.49.54v.62c0,.26.02.5.06.7-.46.12-1.05.27-1.64.28-1.65-.02-2.74-1.04-2.74-2.51,0-1.71,1.39-2.52,2.83-2.55.63,0,1.19.17,1.43.23.01.27.06.66.12,1.15l-.21.02c-.17-.9-.73-1.13-1.43-1.13-1.15-.01-1.99.82-1.99,2.18.02,1.09.64,2.33,2.06,2.32.25.01.49-.04.65-.12.14-.08.23-.17.23-.49v-.62c0-.54-.04-.6-.79-.66l.02-.18h1.89v.21Z' />
              <path d='m384.67,81.62c-.07.01-.16,0-.24,0-.51-.03-.79-.17-1.1-.62-.23-.33-.47-.75-.68-1.08-.11-.2-.23-.27-.58-.29l-.21.02v1.08c-.01.57.06.61.59.65v.19h-1.77v-.19c.55-.05.61-.09.62-.66v-2.71c0-.6-.06-.62-.6-.67v-.19s1.7.01,1.7.01c.48,0,.81.05,1.06.24.28.16.47.48.48.88,0,.59-.39.94-.88,1.17.11.22.39.66.61.97.24.35.37.55.54.71.18.21.35.28.49.33l-.02.16Zm-2.49-2.2c.34.02.58-.03.72-.19.27-.21.39-.47.39-.83,0-.75-.48-1.01-.99-.99-.19-.02-.31,0-.37.04-.04.05-.08.14-.06.32l-.02,1.67.33-.02Z' />
              <path d='m389.12,80.51c-.03.22-.19.86-.24,1.07h-3.26s0-.18,0-.18c.6-.06.66-.13.66-.67l.02-2.68c-.02-.61-.07-.63-.61-.69l.03-.19h3.01c0,.17.03.63.06.99l-.2.04c-.06-.27-.13-.43-.22-.59-.1-.16-.26-.19-.76-.2l-.51-.02c-.22.02-.22.03-.22.23v1.55s.62-.01.62-.01c.62-.01.65-.03.74-.52h.19v1.29s-.19,0-.19,0c-.09-.48-.13-.51-.73-.52h-.63s-.01,1.29-.01,1.29c0,.3.04.46.16.54.13.08.36.1.71.09.52,0,.7-.06.85-.21.11-.13.24-.39.34-.65l.19.03Z' />
              <path d='m393.32,80.51c-.05.22-.18.88-.23,1.06l-3.28.02v-.2c.62-.05.68-.11.66-.66v-2.69c0-.61-.05-.63-.57-.69v-.18s3.03,0,3.03,0c0,.15.03.63.05.99l-.2.02c-.06-.27-.12-.42-.2-.58-.1-.16-.29-.17-.78-.18h-.52c-.21-.02-.23,0-.22.21v1.55l.64-.02c.62.02.65-.05.73-.5h.2s0,1.31,0,1.31h-.19c-.11-.48-.14-.53-.73-.53h-.66v1.29c0,.31.03.46.18.54.12.1.35.09.69.1.52,0,.71-.06.86-.22.12-.16.25-.38.33-.63h.21Z' />
              <path d='m398.97,77.38c-.38,0-.54.1-.55.39-.05.2-.06.52-.06,1.14v2.73s-.22.01-.22.01l-2.88-3.59h0s-.01,1.74-.01,1.74c0,.62.02.94.05,1.13.02.35.18.45.64.47v.19h-1.61v-.19c.39-.03.57-.11.59-.43.02-.21.04-.55.04-1.17v-1.5c.01-.49,0-.53-.13-.69-.14-.15-.3-.21-.6-.23l-.02-.19h1.01s2.84,3.38,2.84,3.38h.01v-1.64c0-.62-.03-.93-.04-1.14-.04-.29-.19-.38-.65-.41l-.02-.21,1.61.02v.19Z' />
              <path d='m405.29,77.92c-.07-.34-.15-.52-.21-.64-.13-.18-.27-.26-.81-.23l-.52-.02v3.67c-.01.56.04.65.75.69v.21h-2.12v-.2c.66-.06.74-.11.72-.7l.02-3.66h-.47c-.61,0-.76.03-.85.23-.1.12-.14.33-.24.64l-.21.02c.03-.46.07-.94.07-1.34h.17c.09.17.19.18.39.18h2.92c.21.02.26-.05.37-.19h.17c0,.35.04.9.06,1.33h-.21Z' />
              <path d='m408.09,81.61v-.19c.43-.06.48-.13.39-.38-.08-.27-.21-.66-.37-1.05h-1.43c-.11.31-.22.58-.31.86-.14.44-.09.5.43.56v.18h-1.49l.02-.18c.44-.06.53-.1.74-.69l1.41-3.57.22-.07c.41,1.17.87,2.47,1.31,3.64.2.58.3.64.74.69l.02.2h-1.68Zm-.66-3.64h-.02c-.22.58-.42,1.16-.62,1.72h1.24s-.6-1.72-.6-1.72Z' />
              <path d='m414.12,79.75c-.41.06-.43.09-.43.47v.6c0,.24,0,.45.04.63-.41.11-.96.25-1.52.25-1.48,0-2.48-.95-2.49-2.26.02-1.58,1.28-2.35,2.6-2.37.57.02,1.08.17,1.31.21.02.23.05.62.11,1.07l-.18.04c-.18-.83-.68-1.06-1.34-1.05-1.03-.01-1.8.75-1.79,2,0,.99.58,2.14,1.88,2.14.24,0,.44-.06.59-.14.13-.05.2-.14.22-.43v-.57c-.01-.5-.04-.55-.69-.58v-.21s1.69.01,1.69.01v.19Z' />
            </g>

            <g stroke='green'>
              <path d='m391.97,86.61h-.18c.03-.26.06-.57.08-.91.68.02,1.16.03,1.46.03.25,0,.68-.01,1.32-.03.02.35.04.64.07.91h-.17c-.07-.28-.12-.45-.14-.49-.02-.06-.04-.09-.09-.11-.05-.02-.15-.03-.31-.03h-.52c-.01.41-.02.91-.02,1.52,0,.57,0,.95.02,1.15,0,.04.02.07.03.09.02.02.16.05.39.11,0,0,.05,0,.09,0v.14c-.3-.02-.58-.02-.82-.02-.22,0-.45,0-.7.02v-.14c.05,0,.11-.02.13-.02.2-.04.32-.06.36-.09.02-.01.03-.02.04-.05,0-.02,0-.19,0-.51,0-.5.01-.87.01-1.1,0-.42,0-.78-.01-1.1h-.65c-.09,0-.15,0-.17.02-.05.03-.09.09-.12.18-.02.05-.07.21-.12.43Z' />
              <path d='m395.35,86.18v-.13c.3-.12.53-.22.64-.28.07-.03.15-.07.24-.12l.06.04c-.04.46-.06,1.03-.06,1.72,0,.59,0,.92,0,1.01.01.15.01.25.03.29.02.02.03.04.05.05,0,0,.13.04.36.1v.13c-.32-.02-.55-.02-.7-.02-.17,0-.37,0-.59.02v-.13c.23-.05.35-.1.37-.11.02-.01.03-.03.05-.04.02-.03.02-.13.02-.3v-2.07c0-.1-.02-.15-.04-.19-.02-.02-.05-.04-.1-.04-.06,0-.17.02-.36.06Z' />
            </g>

            <g stroke='green'>
              <path d='m381.43,92.7v-.68s-.01-.06-.02-.07c-.02,0-.05-.03-.11-.03l-.2-.05v-.08c.22,0,.38.01.48.01.11,0,.26,0,.46-.01v.08c-.16.04-.25.06-.27.06-.01.02-.03.02-.03.03-.01.02-.02.07-.02.16v1.43s0,.03,0,.03c0,.05,0,.07.03.09.02.02.12.05.3.08v.08c-.27-.01-.43-.01-.5-.01-.12,0-.27,0-.43.01v-.08l.27-.06s.02-.02.03-.04c.02-.02.02-.05.02-.1v-.68c-.24,0-.43,0-.57,0s-.3,0-.45,0v.7s0,.09.01.09c.01.01.04.03.07.04,0,0,.1.02.24.05v.08c-.24-.01-.4-.01-.5-.01-.12,0-.26,0-.43.01v-.08c.1-.03.18-.04.23-.05.03,0,.07-.01.07-.03,0-.02.02-.05.02-.11v-1.5s-.01-.05-.01-.05c0-.02,0-.05-.01-.07,0,0-.03-.02-.06-.03l-.24-.05v-.08c.18,0,.36.01.48.01.13,0,.29,0,.44-.01v.08l-.24.05s-.05.02-.06.03c0,.02-.02.04-.02.07v.68c.15.01.31.01.46.01.22,0,.41,0,.58-.01Z' />
              <path d='m382.74,92.69h.34c.14,0,.21,0,.23-.02.03,0,.04-.02.08-.03,0-.03.01-.09.04-.19,0-.02,0-.03,0-.05h.1c0,.15,0,.27,0,.36s0,.21,0,.36h-.1c-.02-.14-.04-.22-.04-.23,0-.02-.03-.04-.05-.05-.03-.01-.1-.02-.22-.02h-.39v.84h.66c.08,0,.14,0,.15-.01.05,0,.07-.03.11-.05.03-.03.07-.12.16-.25,0,0,.01-.03.02-.05h.1s-.07.22-.18.54c-.35-.01-.67-.01-.96-.01-.2,0-.41,0-.67.01v-.08l.24-.05s.06-.02.07-.03c.01-.02.02-.06.02-.12v-.37c.01-.07.01-.19.01-.38,0-.28,0-.48-.01-.6,0-.14,0-.21-.01-.22-.01-.01-.01-.03-.02-.03-.02-.02-.06-.03-.14-.04l-.16-.04v-.08c.19,0,.41.01.65.01.14,0,.47,0,.98-.01,0,.16.02.33.04.49h-.1c-.03-.13-.07-.22-.09-.26-.02-.02-.04-.04-.06-.05-.03-.02-.1-.02-.21-.02h-.58v.46s-.01.28-.01.28Z' />
              <path d='m386.01,93.48l.06.11c-.16.1-.3.17-.44.21-.13.05-.28.08-.44.08-.19,0-.37-.05-.52-.13-.16-.1-.27-.22-.36-.39-.08-.16-.11-.33-.11-.53,0-.31.1-.58.3-.78.21-.21.48-.31.82-.31.22,0,.45.04.71.13v.18c0,.13,0,.26.02.39h-.13c-.04-.17-.07-.28-.12-.35-.03-.06-.09-.11-.19-.16-.09-.03-.2-.05-.33-.05-.28,0-.49.1-.63.3-.12.15-.16.34-.16.58,0,.27.07.5.23.65.16.16.35.24.6.24.11,0,.21-.02.32-.04.1-.03.23-.08.37-.15Z' />
              <path d='m387.88,92.7v-.09s0-.31,0-.31v-.28s-.01-.06-.02-.07c-.03,0-.05-.03-.12-.03l-.2-.05v-.08c.22,0,.39.01.48.01.12,0,.27,0,.46-.01v.08c-.16.04-.25.06-.27.06-.01.02-.03.02-.04.03-.01.02-.01.07-.01.16,0,.14,0,.39,0,.74v.69s0,.03,0,.03c0,.05,0,.07.02.09.03.02.13.05.31.08v.08c-.27-.01-.43-.01-.51-.01-.12,0-.26,0-.43.01v-.08l.27-.06s.02-.02.03-.04c.02-.02.03-.05.03-.1v-.68c-.24,0-.44,0-.57,0-.15,0-.29,0-.46,0v.7s.01.09.02.09c.01.01.03.03.06.04,0,0,.09.02.23.05v.08c-.23-.01-.39-.01-.49-.01-.12,0-.26,0-.44.01v-.08c.1-.03.18-.04.23-.05.04,0,.06-.01.08-.03,0-.02.02-.05.02-.11,0-.18,0-.45,0-.78l-.02-.71v-.05s0-.05,0-.07c0,0-.04-.02-.07-.03l-.24-.05v-.08c.2,0,.36.01.5.01s.29,0,.44-.01v.08l-.24.05s-.04.02-.05.03c-.02.02-.03.04-.03.07v.68c.15.01.31.01.47.01.21,0,.4,0,.58-.01Z' />
              <path d='m388.66,92.82c0-.31.1-.57.31-.77.2-.21.46-.31.78-.31.3,0,.53.09.71.27.18.18.26.42.26.73,0,.35-.1.62-.3.82-.19.2-.45.3-.78.3-.3,0-.54-.09-.72-.27-.18-.19-.27-.44-.27-.77Zm.32-.05c0,.32.06.56.21.71.13.16.32.24.52.24.14,0,.27-.03.36-.1.12-.07.2-.18.26-.33.04-.15.08-.29.08-.46,0-.22-.04-.4-.11-.55-.07-.14-.17-.24-.27-.3-.11-.06-.22-.09-.35-.09s-.27.03-.38.11c-.11.08-.2.18-.26.33-.04.14-.07.29-.07.45Z' />
              <path d='m392.27,92.69h.35c.13,0,.2,0,.23-.02.02,0,.05-.02.06-.03.02-.03.03-.09.05-.19.01-.02.01-.03.01-.05h.1c0,.15-.02.27-.02.36s.02.21.02.36h-.1c-.02-.14-.04-.22-.04-.23-.01-.02-.03-.04-.06-.05-.01-.01-.09-.02-.21-.02h-.4v.84h.67c.09,0,.15,0,.16-.01.03,0,.06-.03.09-.05.03-.03.08-.12.16-.25,0,0,.02-.03.03-.05h.1s-.07.22-.18.54c-.35-.01-.67-.01-.96-.01-.2,0-.43,0-.67.01v-.08l.24-.05s.06-.02.06-.03c.01-.02.02-.06.02-.12v-.37c.01-.07.01-.19.01-.38v-.6c-.01-.14-.01-.21-.02-.22,0-.01-.01-.03-.02-.03-.02-.02-.06-.03-.14-.04l-.16-.04v-.08c.19,0,.4.01.66.01.13,0,.46,0,.97-.01,0,.16.02.33.03.49h-.1c-.03-.13-.06-.22-.09-.26-.02-.02-.03-.04-.05-.05-.04-.02-.1-.02-.22-.02h-.58v.46s-.01.28-.01.28Z' />
              <path d='m393.58,91.87v-.08c.13,0,.21.01.27.01s.17,0,.31-.01l.42.51s.32.39.87,1.04v-.29c0-.14,0-.41-.02-.78,0-.18,0-.27,0-.28,0-.03-.02-.04-.04-.04-.02-.02-.08-.04-.2-.05,0,0-.03-.02-.06-.02v-.08c.17,0,.31.01.41.01.09,0,.23,0,.41-.01v.08s-.05.01-.06.02c-.13.01-.2.03-.22.04,0,0-.02,0-.02.02-.02.02-.02.05-.02.09v.4c0,.24,0,.47,0,.7,0,.27,0,.51,0,.68l-.09.04-.6-.74-.8-.95v.85c.01.37.03.57.03.6.01.02.01.02.02.04.02.02.1.04.21.05.02,0,.05.02.08.02v.08c-.15-.01-.27-.01-.38-.01-.07,0-.23,0-.44.01v-.08s.06-.02.06-.02c.13-.02.19-.04.22-.05,0,0,.01-.02.02-.04,0-.05.02-.22.02-.5v-.73c0-.24,0-.38,0-.4-.01-.02-.02-.03-.04-.05-.03-.02-.11-.03-.27-.05,0,0-.04-.02-.08-.02Z' />
              <path d='m396.73,91.87v-.08c.16,0,.28.01.37.01s.19,0,.3-.01c.04.11.07.17.09.22.04.08.16.35.37.81.1.2.19.39.29.58l.62-1.29c.03-.09.08-.19.12-.33.14,0,.24.01.3.01s.17,0,.32-.01v.08s-.06.02-.06.02c-.14.02-.22.03-.24.05-.03.02-.03.07-.03.15v.47c-.01.57-.01.92,0,1.03,0,.04,0,.05.02.06.01.02.1.03.25.06.02,0,.03.02.03.02l.02.08c-.17-.01-.33-.01-.46-.01-.13,0-.28,0-.47.01v-.08s.06-.02.06-.02c.14-.03.2-.04.22-.05.02,0,.02-.02.04-.03,0-.04.02-.11.02-.24v-1.25c-.09.17-.28.58-.59,1.24-.1.18-.15.32-.2.42h-.08c-.22-.48-.46-1.03-.78-1.66l.02,1.34c0,.11,0,.16.02.18.01.02.09.03.22.05.01,0,.05.02.05.02v.08c-.14-.01-.28-.01-.39-.01-.09,0-.23,0-.4.01v-.08s.04-.02.04-.02c.15-.02.22-.04.23-.06.02-.02.04-.1.04-.2v-.32c0-.3.02-.58.02-.87v-.16c0-.08-.01-.12-.02-.14,0-.01-.02-.03-.04-.03-.04-.02-.12-.04-.25-.05,0,0-.03-.01-.08-.02Z' />
              <path d='m400.24,92.69h.34c.14,0,.22,0,.24-.02.02,0,.05-.02.06-.03.02-.03.03-.09.05-.19,0-.02.01-.03.01-.05h.11c-.02.15-.02.27-.02.36s0,.21.02.36h-.11c-.02-.14-.04-.22-.04-.23-.02-.02-.03-.04-.06-.05-.02-.01-.09-.02-.2-.02h-.4v.84h.66c.08,0,.14,0,.16-.01.04,0,.06-.03.09-.05.02-.03.08-.12.15-.25.01,0,.02-.03.03-.05h.11s-.08.22-.18.54c-.35-.01-.67-.01-.96-.01-.2,0-.42,0-.66.01v-.08l.22-.05s.06-.02.06-.03c.02-.02.03-.06.03-.12v-1.35c0-.14,0-.21-.01-.22,0-.01-.02-.03-.02-.03-.01-.02-.06-.03-.13-.04l-.15-.04v-.08c.18,0,.39.01.64.01.14,0,.46,0,.98-.01,0,.16.02.33.03.49h-.11c-.03-.13-.07-.22-.09-.26-.02-.02-.04-.04-.05-.05-.04-.02-.11-.02-.21-.02h-.59v.46s0,.28,0,.28Zm.23-1.08h-.08c.05-.18.13-.35.18-.51h.16s.06.01.14,0l.02.04c-.13.14-.27.3-.41.47Z' />
              <path d='m401.63,91.87v-.08c.16,0,.28.01.41.01s.29,0,.48-.01v.08c-.12.02-.18.05-.21.06-.02.02-.02.02-.02.03,0,.03.02.05.06.1.13.21.25.38.34.51.15-.2.29-.39.42-.57.02-.04.03-.06.03-.06,0,0,0-.02-.01-.03-.02-.01-.08-.02-.19-.05v-.08c.16,0,.28.01.37.01.1,0,.22,0,.36-.01v.08c-.12.02-.2.05-.25.07-.04.03-.11.1-.2.21-.18.22-.32.4-.41.56.06.14.18.29.31.48l.29.42s.03.03.06.04c.04.02.11.05.22.08v.08c-.17-.01-.31-.01-.41-.01-.1,0-.24,0-.44.01v-.08c.12-.04.19-.06.2-.06.01-.01.02-.02.02-.03,0-.02-.02-.05-.04-.1-.14-.22-.26-.41-.39-.59-.12.15-.26.34-.43.59-.03.05-.06.09-.06.1,0,.02.02.02.02.04.02,0,.06.03.15.04.02.01.03.01.03.01l.03.08c-.15-.01-.28-.01-.4-.01-.14,0-.24,0-.34.01v-.08c.11-.03.19-.05.22-.08.04-.02.12-.11.23-.26.21-.27.35-.46.44-.59-.09-.14-.23-.36-.43-.64-.07-.12-.13-.18-.17-.21-.03-.02-.12-.05-.27-.09Z' />
              <path d='m404.76,91.79v.08s-.06.02-.06.02c-.15.01-.21.03-.23.05-.02.03-.03.07-.03.14v.63s0,.84,0,.84c0,.06.02.12.03.13.02.01.08.03.2.04,0,0,.05.02.1.02v.08c-.15-.01-.31-.01-.45-.01s-.3,0-.48.01v-.08l.06-.02c.13-.02.21-.04.22-.06.03-.02.04-.06.04-.14v-.07s0-.53,0-.53c0-.54,0-.84-.01-.91,0-.02,0-.05-.03-.07-.02-.02-.08-.03-.22-.05,0,0-.04-.01-.07-.02v-.08c.2,0,.38.01.48.01.14,0,.29,0,.45-.01Z' />
              <path d='m406.79,93.48l.06.11c-.15.1-.3.17-.44.21-.15.05-.29.08-.44.08-.2,0-.37-.05-.53-.13-.15-.1-.27-.22-.35-.39-.08-.16-.13-.33-.13-.53,0-.31.11-.58.31-.78.22-.21.5-.31.83-.31.21,0,.45.04.7.13,0,.08,0,.14,0,.18,0,.13,0,.26.02.39h-.11c-.04-.17-.08-.28-.11-.35-.05-.06-.11-.11-.2-.16-.1-.03-.21-.05-.34-.05-.27,0-.49.1-.63.3-.11.15-.16.34-.16.58,0,.27.08.5.22.65.16.16.37.24.62.24.11,0,.21-.02.31-.04.11-.03.23-.08.38-.15Z' />
              <path d='m407.09,92.82c0-.31.09-.57.3-.77.21-.21.46-.31.79-.31.29,0,.53.09.7.27.17.18.27.42.27.73,0,.35-.1.62-.3.82-.19.2-.45.3-.78.3-.3,0-.54-.09-.71-.27-.18-.19-.27-.44-.27-.77Zm.32-.05c0,.32.06.56.2.71.15.16.32.24.53.24.13,0,.26-.03.37-.1.11-.07.18-.18.24-.33.06-.15.08-.29.08-.46,0-.22-.02-.4-.11-.55-.06-.14-.16-.24-.26-.3-.11-.06-.22-.09-.35-.09s-.26.03-.38.11c-.12.08-.2.18-.25.33-.05.14-.07.29-.07.45Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GreenTagsTShirtFemale
