import React, { memo } from 'react'
import style from './H.module.scss'
interface Hinterface {
  variation?: '1' | '2' | '3' | '4'
  size?: 'Bold' | 'Regular' | 'Light'
  children: string | any
}
const H = memo(
  ({ variation = '1', size = 'Regular', children }: Hinterface) => {
    if (variation === '4')
      return (
        <h4 className={style.H} data-variation={variation} data-size={size}>
          {children}
        </h4>
      )
    if (variation === '3')
      return (
        <h3 className={style.H} data-variation={variation} data-size={size}>
          {children}
        </h3>
      )
    if (variation === '2')
      return (
        <h2 className={style.H} data-variation={variation} data-size={size}>
          {children}
        </h2>
      )
    return (
      <h1 className={style.H} data-variation={variation} data-size={size}>
        {children}
      </h1>
    )
  }
)
H.displayName = 'H'
export default H
