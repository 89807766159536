import { Col, Empty, Row } from 'antd'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import Icon from 'components/icons'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { memo, useEffect, useState } from 'react'
import style from './homeStyle.module.scss'
import { useNavigate } from 'react-router-dom'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import B from 'components/texts/B/B'
import { getGeneralStatus } from 'services/homePage/homaPage'
import { handleErrors } from 'utilities/handleErrors'
import FirstTimeComponent from 'components/FirstTimeComponent/FirstTimeComponent'
import useStructureFinish from 'hooks/useStructureFinish'

const INITIAL_MENU_STATE = {
  books_status: {
    url: '/libros',
    label: 'Libros',
    icon: <Icon height={75} width={75} name='book' />,
    status: 'Sin iniciar',
    variation: 1,
    permission: 2,
    role: undefined
  },
  notebooks_status: {
    url: '/cuadernos',
    label: 'Cuadernos',
    icon: <Icon height={75} width={75} name='notebook' />,
    status: 'Sin iniciar',
    variation: 1,
    permission: 3,
    role: undefined
  },
  uniforms_status: {
    url: '/uniforms',
    label: 'Uniformes',
    icon: <Icon height={75} width={75} name='uniform' />,
    status: 'Sin iniciar',
    variation: 1,
    permission: 5,
    role: undefined
  },
  supplies_status: {
    url: '/utiles?tab=0',
    label: 'Útiles',
    icon: <Icon height={75} width={75} name='utilsIcon' />,
    status: 'Sin iniciar',
    variation: 1,
    permission: 4,
    role: undefined
  },
  quotas_status: {
    url: '/cuotas',
    label: 'Cuotas',
    icon: <Icon height={75} width={75} name='rightArrow' />,
    status: 'Sin iniciar',
    variation: 1,
    permission: 6,
    role: undefined
  }
}

const HomeModule = memo(() => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const navigate = useNavigate()

  const [menu, setMenu] = useState<any>([])

  const convertVariation = (type: string) => {
    if (type === 'En revisión' || type === 'En Construcción') return 2
    if (type === 'Completado') return 4
    return 1
  }

  const GetGeneralStatus = async () => {
    try {
      const { data } = await getGeneralStatus()
      const newMenu = Object.entries(INITIAL_MENU_STATE).map(([key, value]) => {
        const newValue = { ...value }
        newValue.status = data[key]
        newValue.variation = convertVariation(data[key])
        return newValue
      })

      setMenu(newMenu)
    } catch (error) {
      handleErrors(error)
    }
  }
  useEffect(() => {
    void GetGeneralStatus()
  }, [])

  const redirect = (url: string) => {
    navigate(url)
  }

  const renderMenu = () => {
    const RENDER_MENU = menu.map((res: any, key: number) => {

      if (res.permission && uid.role === 'Administrador') {
        // Aca adentro por lo general siempre se mostrara las primeras tres opciones en el sidebar: "inicio", Colegio, Estructura Escolar
        const exist = uid.school_permissions.some(
          (permission) => res.permission === permission.id
        )
        if (!exist) return null
        return (
          <div
          key={`card-${key}`}
          onClick={() => redirect(res.url)}
          className={style.card}
          data-variation={res.variation}
        >
          <B>{res.status}</B>
          {res.icon}
          <B>{res.label}</B>
        </div>
        )
      }


      if (res.permission && uid.role !== 'Administrador') {
        const exist = uid.permissions.some(
          (permission) => res.permission === permission.id
        )
        if (!exist) return null

        return (
          <div
            key={`card-${key}`}
            onClick={() => redirect(res.url)}
            className={style.card}
            data-variation={res.variation}
          >
            <B>{res.status}</B>
            {res.icon}
            <B>{res.label}</B>
          </div>
        )
      }

      return (
        <div
          key={`card-${key}`}
          onClick={() => redirect(res.url)}
          className={style.card}
          data-variation={res.variation}
        >
          <B>{res.status}</B>
          {res.icon}
          <B>{res.label}</B>
        </div>
      )
    })
    function validarNull(arr: any) {
      return arr.every(function (valor: any) {
        return valor === null
      })
    }
    if (validarNull(RENDER_MENU))
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
            width: '100%'
          }}
        >
          <Empty description='No posee permisos para los modulos actualmente' />
        </div>
      )

    return RENDER_MENU
  }

  const { structureFinish } = useStructureFinish()

  return (
    <MainTemplate>
      <Row align='middle' gutter={[0, 16]}>
        <Col span={24}>
          <TitleHeader IconName='estructure' title='Inicio' />
        </Col>

        {structureFinish ? (
          <Col span={24}>
            <div className={style.CardsContainer}>{renderMenu()}</div>
          </Col>
        ) : (
          <Col span={24}>
            <FirstTimeComponent />
          </Col>
        )}
      </Row>
    </MainTemplate>
  )
})

HomeModule.displayName = 'HomeModule'
export default HomeModule
