/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react'
import { Col, Input, List, Space } from 'antd'
import globalStyle from 'styles/global.module.scss'
import B from 'components/texts/B/B'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import UniformPrev from '../../../assets/img/uniformPrev.svg'
import Button from 'components/buttons/Button/Button'
import SearchBarModule from 'modules/books/search/SearchBarModule'

const { Search } = Input

const dummyUtils = [
  {
    pic: <img src={UniformPrev} alt='nombre' />,
    name: 'Nombre del cuaderno',
    ISBN: 'Descripción',
    id: 1
  }
]

const utilsTitle = [
  {
    1: 'Foto',
    2: 'Nombre',
    3: 'Marca',
    4: ''
  }
]
const colStyle = { cursor: 'pointer' }

const SearchUtilModalBody = memo(
  ({
    packageData,
    allUtilsList,
    onHandleSearch,
    addUtil,
    modalPagination,
    actualPageModal,
    setActualPageModal,
    loadNewUtil
  }: any) => {
    
    const divScroll = document.getElementById(
      `search-list-${packageData.id}`
    ) as HTMLInputElement | null

    useEffect(() => {
      if (allUtilsList.length > 0)
        divScroll?.addEventListener('scroll', onScroll)
      return () => {
        divScroll?.removeEventListener('scroll', onScroll)
      }
    }, [allUtilsList])

    const onScroll = (e: any) => {
      const scrollTop: any = divScroll?.scrollTop
      const scrollHeight: any = divScroll?.scrollHeight
      const clientHeight: any = divScroll?.clientHeight
      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        if (modalPagination.totalPages > actualPageModal) {
          setActualPageModal(actualPageModal + 1)
        }
      }
    }

    return (
      <div className={globalStyle.blueModalBody}>
        <button
          style={{ display: 'none' }}
          id='reset-pagination-util'
          onClick={() => setActualPageModal(1)}
        >
          a
        </button>
        <B size='Light'>Busca tu material</B>
        <SearchBarModule
          placeholder='Nombre del material'
          onSearch={onHandleSearch}
          timer={500}
        />
        <div
          id={`search-list-${packageData.id}`}
          className='list'
          style={{ maxHeight: '268px', overflow: 'auto' }}
        >
          {allUtilsList.length > 0 ? (
            <>
              <List
                itemLayout='horizontal'
                dataSource={utilsTitle}
                renderItem={(title: any) => (
                  <List.Item style={{ padding: ' 10px 0' }}>
                    {Object.keys(title).map((res, key) => {
                      return (
                        <Col
                          key={key}
                          className='ListTitles'
                          style={colStyle}
                          span={6}
                        >
                          <B size='Light'>{title[res]}</B>
                        </Col>
                      )
                    })}
                  </List.Item>
                )}
              />
              
              <List
                className='algo'
                itemLayout='horizontal'
                dataSource={allUtilsList}
                renderItem={(util: any) => (
                  <List.Item style={{ padding: ' 10px 0' }}>
                    <Col style={colStyle} span={6}>
                      {/* <B size='Light'>{util.pic}</B> */}
                      {util.picture && <img style={{width:40,height:40}} src={util.picture} alt={util.name} />}
                    </Col>
                    <Col style={colStyle} span={6}>
                      {' '}
                      <B size='Light'>{util.name}</B>
                    </Col>
                    <Col style={colStyle} span={6}>
                      {' '}
                      <B size='Light'>{util.make}</B>
                    </Col>
                    <Col style={colStyle} span={6}>
                      <Button
                        type='blue'
                        rounded
                        label='Asignar'
                        onClick={() => addUtil(util, packageData)}
                        isLoading={loadNewUtil}
                      />
                    </Col>
                  </List.Item>
                )}
              />
            </>
          ) : (
            <EmptyListMessage
              icon='emptySchoolListIcon'
              text='Sin resultado'
              subtitle='Realiza una búsqueda para ver los útiles del catálogo'
            />
          )}
        </div>
      </div>
    )
  }
)
SearchUtilModalBody.displayName = 'SearchUtilModalBody'
export default SearchUtilModalBody
