import { createSlice } from '@reduxjs/toolkit'

import kid from 'assets/img/uniforms/kid-uniform.png'
import {
  IJsonImgParameters,
  IInputsNotebookForm,
  IInputsNotebookInsideForm,
  IJsonParameters,
  IJsonText,
  IJsonTextContent,
  INotebookInsideOptions,
  INotebookOptions,
  IJsonImgContent
} from 'interfaces/notebooks-interface'

export const allNotebooks = [
  { id: 1, label: 'Cuaderno Profesional 20x26cm' },
  { id: 2, label: 'Block Carta 21x27cm' },
  { id: 3, label: 'Block Esquela 14x21cm' },
  { id: 5, label: 'Cuaderno Francés 16x21cm' },
  { id: 7, label: 'Cuadernos College 19.5x25cm' },
  { id: 4, label: 'Cuaderno College horizontal 24.5x19.5cm' },
  { id: 6, label: 'Cuaderno Italiano 21x16cm' },
  { id: 8, label: 'Cuaderno de dibujo 32x24cm' }
]

export type TPoints = 'PuntoPaloma' | 'PuntoPuntoPaloma' | 'sinGuia'
export type TVerticalNotebook =
  | 'Profesional'
  | 'College'
  | 'Frances'
  | 'Block_carta'
  | 'Block_esquela'
export type THorizontalNotebook =
  | 'College_horizontal'
  | 'Italiano_ancho'
  | 'Marquilla_ancho'

export type TRayadosInside =
  | 'blanco'
  | '5mm'
  | '6mm'
  | '7mm'
  | '10mm'
  | '14mm'
  | 'Pautado'
  | 'rayado'
  | 'rayado 7mm'

const optionsGeneral: INotebookOptions = {
  notebook_type: [
    {
      name: 'Block_carta',
      option: { label: 'Tipo', picture: kid, value: 'Block_carta' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Block_Esquela',
      option: { label: 'Tipo', picture: kid, value: 'Block_Esquela' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'College',
      option: { label: 'Tipo', picture: kid, value: 'College' },
      description:
        '100% Poliéster. Al seleccionarlo en automatico el color de la tela se vuelve blanca y no se puede cambiar el color'
    },
    {
      name: 'College_horizontal',
      option: { label: 'Tipo', picture: kid, value: 'College_horizontal' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Francés',
      option: { label: 'Tipo', picture: kid, value: 'Francés' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Italiano',
      option: { label: 'Tipo', picture: kid, value: 'Italiano' },
      description:
        '100% Poliéster. Al seleccionarlo en automatico el color de la tela se vuelve blanca y no se puede cambiar el color'
    },
    {
      name: 'Italiano_grande',
      option: { label: 'Tipo', picture: kid, value: 'Italiano_grande' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Marquilla',
      option: { label: 'Tipo', picture: kid, value: 'Marquilla' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Profesional',
      option: { label: 'Tipo', picture: kid, value: 'Profesional' },
      description:
        '100% Poliéster. Al seleccionarlo en automatico el color de la tela se vuelve blanca y no se puede cambiar el color'
    }
  ],
  logo: [
    {
      name: 'Con_logotipo',
      option: { label: 'Estilo', picture: kid, value: 'bordado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin_logotipo',
      option: { label: 'Estilo', picture: kid, value: 'estampado' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  binding_type: [
    {
      name: 'Cosido',
      option: { picture: kid, value: 'Cosido', label: 'Cosido' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Engomado',
      option: { picture: kid, value: 'Engomado', label: 'Engomado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Espiral',
      option: { picture: kid, value: 'Espiral', label: 'Espiral' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  cover_finishing: [
    {
      name: 'Brillantes',
      option: { label: 'Abertura', picture: kid, value: 'brillantes' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Mates',
      option: { label: 'Abertura', picture: kid, value: 'mates' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  full_color: [],
  spiral_color: [
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#FFFFFF' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_MARINO 281 C',
      option: { picture: null, label: 'Color', value: '#00205C' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE 347 C',
      option: { picture: null, label: 'Color', value: '#009845' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'ROSA_MEXICANO 233 C',
      option: { picture: null, label: 'Color', value: '#CB007B' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AMARILLO_REX 110 C',
      option: { picture: null, label: 'Color', value: '#DAA900' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'ROJO_FERRARI 186 C',
      option: { picture: null, label: 'Color', value: '#CE0E2D' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_CIELO 2985 C',
      option: { picture: null, label: 'Color', value: '#54C0E8' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_REFLEX BLUE C',
      option: { picture: null, label: 'Color', value: '#001689' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'NARANJA 151 C',
      option: { picture: null, label: 'Color', value: '#FF8300' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'CAFÉ 476',
      option: { picture: null, label: 'Color', value: '#503629' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AMARILLO CANARIO_103',
      option: { picture: null, label: 'Color', value: '#C5A900' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE_NEÓN',
      option: { picture: null, label: 'Color', value: '#5CFF00' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'NEGRO',
      option: { picture: null, label: 'Color', value: '#000000' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE_3305',
      option: { picture: null, label: 'Color', value: '#004D43' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'PLATA_428',
      option: { picture: null, label: 'Color', value: '#C1C5C8' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'MORADO_2587',
      option: { picture: null, label: 'Color', value: '#8347AD' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VINO_229',
      option: { picture: null, label: 'Color', value: '#682145' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  cintas_color: [
    {
      name: 'AZUL_REFLEX 287 C',
      option: { picture: null, label: 'Color', value: '#002F87' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_MARINO 281',
      option: { picture: null, label: 'Color', value: '#00205C' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_CLARO 285 C',
      option: { picture: null, label: 'Color', value: '#0071CE' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'AZUL_CIELO 305 C',
      option: { picture: null, label: 'Color', value: '#54C8E8' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'CAFÉ 476',
      option: { picture: null, label: 'Color', value: '#503629' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'FIUSHA/RHODAMINA RED C',
      option: { picture: null, label: 'Color', value: '#E50695' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'LILA/PURPLE_U',
      option: { picture: null, label: 'Color', value: '#C028B9' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'MORADO_CLARO 807 C',
      option: { picture: null, label: 'Color', value: '#EF2AC1' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE_CLARO 361',
      option: { picture: null, label: 'Color', value: '#3DAE2B' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Blanco',
      option: { picture: null, label: 'Color', value: '#FFFFFF' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'NEGRO/BLACK',
      option: { picture: null, label: 'Color', value: '#2D2A26' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'DORADO 111C',
      option: { picture: null, label: 'Color', value: '#AB8900' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'NARANJA 165 C',
      option: { picture: null, label: 'Color', value: '#FF671D' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VINO 202',
      option: { picture: null, label: 'Color', value: '#8A2432' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE_FUERTE 348 C',
      option: { picture: null, label: 'Color', value: '#00833E' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'VERDE_MEDIO 340 C',
      option: { picture: null, label: 'Color', value: '#00945E' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'YELLOW C',
      option: { picture: null, label: 'Color', value: '#FFDD00' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'ROJO 1788',
      option: { picture: null, label: 'Color', value: '#F32735' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'GRIS 424 (PLATA)',
      option: { picture: null, label: 'Color', value: '#6F7271' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'MORADO F 2627 C',
      option: { picture: null, label: 'Color', value: '#3D1152' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'MOSTAZA 131 C',
      option: { picture: null, label: 'Color', value: '#CF8A00' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'ROSA 218 C',
      option: { picture: null, label: 'Color', value: '#E96BB0' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  cover_design: [
    {
      name: 'Subir_archivo',
      option: { picture: kid, label: 'Ubicación', value: 'upload' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Solicitar propuesta',
      option: { picture: kid, label: 'Ubicación', value: 'proporsal' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Diseñar_portada',
      option: { picture: kid, label: 'Ubicación', value: 'design' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  appear_on_the_cover: [
    {
      name: 'Fotografías',
      option: { picture: kid, label: 'Ubicación', value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Geometría',
      option: { picture: kid, label: 'Ubicación', value: 'center' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Ilustración',
      option: { picture: kid, label: 'Ubicación', value: 'left' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  rounded: [
    {
      name: 'Si',
      option: { label: 'Tipo', picture: kid, value: true },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'No',
      option: { label: 'Tipo', picture: kid, value: false },
      description: '50% algodón / 50% poliéster'
    }
  ]
}
const optionsGeneralInside: INotebookInsideOptions = {
  number_of_sheets: [
    {
      name: '50',
      option: { label: 'Tipo', picture: kid, value: '50' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '100',
      option: { label: 'Tipo', picture: kid, value: '100' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  rounded: [
    {
      name: 'Si',
      option: { label: 'Tipo', picture: kid, value: true },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'No',
      option: { label: 'Tipo', picture: kid, value: false },
      description: '50% algodón / 50% poliéster'
    }
  ],
  magin_complete: [
    {
      name: 'No',
      option: { label: 'Tipo', picture: kid, value: false },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Si',
      option: { label: 'Tipo', picture: kid, value: true },
      description: '50% algodón / 50% poliéster'
    }
  ],
  striped: [
    {
      name: 'Blanco',
      option: { label: 'Estilo', picture: kid, value: 'blanco' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Cuadro_5mm',
      option: { label: 'Estilo', picture: kid, value: '5mm' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Cuadro_6mm',
      option: { label: 'Estilo', picture: kid, value: '6mm' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Cuadro_7mm',
      option: { label: 'Estilo', picture: kid, value: '7mm' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Cuadro_10mm',
      option: { label: 'Estilo', picture: kid, value: '10mm' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Cuadro aleman_14mm',
      option: { label: 'Estilo', picture: kid, value: '14mm' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Pautado',
      option: { label: 'Estilo', picture: kid, value: 'Pautado' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Doble_raya',
      option: { label: 'Estilo', picture: kid, value: 'rayado' },
      description: '50% algodón / 50% poliéster'
    },

    {
      name: 'Rayado_7mm',
      option: { label: 'Estilo', picture: kid, value: 'rayado 7mm' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  guide_point: [
    {
      name: 'Sin_punto guía',
      option: {
        picture: kid,
        value: 'sinGuia',
        label: 'Sin_punto guía'
      },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Punto_paloma',
      option: { picture: kid, value: 'PuntoPaloma', label: 'Punto_paloma' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Punto_punto paloma',
      option: {
        picture: kid,
        value: 'PuntoPuntoPaloma',
        label: 'Punto_punto paloma'
      },
      description: '50% algodón / 50% poliéster'
    }
  ],
  interior_inks: [
    {
      name: '1x1',
      option: { label: 'Abertura', picture: null, value: 'black' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: '2x2',
      option: { label: 'Abertura', picture: null, value: '#003da6' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  logo_position: [
    {
      name: 'Izquierda',
      option: { label: 'Abertura', picture: kid, value: 'left' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Derecha',
      option: { label: 'Abertura', picture: kid, value: 'right' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Extremos',
      option: { label: 'Abertura', picture: kid, value: 'extremos' },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin_logo',
      option: { label: 'Abertura', picture: kid, value: 'none' },
      description: '50% algodón / 50% poliéster'
    }
  ],
  margin_date: [
    {
      name: 'Con_margen',
      option: { label: 'Abertura', picture: kid, value: true },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin_margen',
      option: { label: 'Abertura', picture: kid, value: false },
      description: '50% algodón / 50% poliéster'
    }
  ],
  date_type: [
    {
      name: 'Español',
      option: { label: 'Abertura', picture: kid, value: 1 },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Ingles',
      option: { label: 'Abertura', picture: kid, value: 3 },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Combinado',
      option: { label: 'Abertura', picture: kid, value: 2 },
      description: '50% algodón / 50% poliéster'
    },
    {
      name: 'Sin_datos',
      option: { label: 'Abertura', picture: kid, value: 4 },
      description: '50% algodón / 50% poliéster'
    }
  ]
}
const INPUTS_OUTSIDE: IInputsNotebookForm = {
  binding_type: {
    label: 'Tipo de encuadernación',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'binding_type',
    showHelp: true,
    value: '',
    type: 'binding_type',
    group: 'Tela',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  },
  binding_color: {
    label: 'Color de Sujeción',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'binding_color',
    showHelp: true,
    value: '',
    type: 'spiral_color',
    group: 'Tela',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  },
  rounded: {
    label: 'Esquina redonda',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'rounded',
    showHelp: true,
    value: '',
    type: 'rounded',
    group: 'Tela',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  },

  spine_color: {
    label: 'Color de portada',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'spine_color',
    showHelp: true,
    value: '',
    type: 'full_color',
    group: 'Logotipo',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  },
  cover_finishing: {
    label: 'Acabado de portadas',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'cover_finishing',
    showHelp: true,
    value: '',
    type: 'cover_finishing',
    group: 'Logotipo',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  },
  cover_design: {
    label: 'Diseño de portadas',
    topLabel: 'Diseño',
    selectedLabel: '',
    name: 'cover_design',
    showHelp: true,
    value: '',
    type: 'cover_design',
    group: 'Diseño de cuello',
    required: true,
    enabled: true,
    multiselection: false,
    maxOptions: 0
  }
}
const INPUTS_INSIDE: IInputsNotebookInsideForm = {
  number_of_sheets: {
    label: 'Número de hojas',
    topLabel: 'Tipo',
    selectedLabel: '',
    name: 'number_of_sheets',
    showHelp: true,
    value: '',
    type: 'number_of_sheets',
    group: 'number_of_sheets',
    required: true,
    enabled: true
  },
  striped: {
    label: 'Rayados',
    topLabel: 'Color',
    selectedLabel: '',
    name: 'striped',
    showHelp: true,
    value: '',
    type: 'striped',
    group: 'striped',
    required: true,
    enabled: true
  },
  rounded: {
    label: 'Margen redondo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'rounded',
    showHelp: true,
    value: '',
    type: 'rounded',
    group: 'guide_point',
    required: true,
    enabled: true
  },
  magin_complete: {
    label: 'Margen completo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'magin_complete',
    showHelp: true,
    value: '',
    type: 'magin_complete',
    group: 'guide_point',
    required: true,
    enabled: true
  },
  guide_point: {
    label: 'Punto guía',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'guide_point',
    showHelp: true,
    value: '',
    type: 'guide_point',
    group: 'guide_point',
    required: true,
    enabled: true
  },
  logo_position: {
    label: 'Posicion del logotipo',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'logo_position',
    showHelp: true,
    value: '',
    type: 'logo_position',
    group: 'guide_point',
    required: true,
    enabled: true
  },
  margin_date: {
    label: 'Margen de datos',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'margin_date',
    showHelp: true,
    value: '',
    type: 'margin_date',
    group: 'guide_point',
    required: true,
    enabled: true
  },

  date_type: {
    label: 'Tipo de fecha',
    topLabel: 'Estilo',
    selectedLabel: '',
    name: 'date_type',
    showHelp: true,
    value: '',
    type: 'date_type',
    group: 'guide_point',
    required: true,
    enabled: true
  },
  interior_inks: {
    label: 'Tintas interiores',
    topLabel: 'Ubicación',
    selectedLabel: '',
    name: 'interior_inks',
    showHelp: true,
    value: '',
    type: 'interior_inks',
    group: 'interior_inks',
    required: true,
    enabled: true
  }
}

export const getCorrectInformation = (
  getThis: 'label' | 'picture',
  value: string | boolean,
  type: string,
  description: true | false | null
) => {
  let search: any = ''
  Object.keys(optionsGeneral).forEach((item) => {
    const objKey = item as keyof typeof optionsGeneral
    const objInput = optionsGeneral[objKey]

    if (type === item)
      objInput.forEach((content) => {
        if (value === content.option.value && description === null) {
          search = content.option[getThis]
        } else if (value === content.option.value && description !== null)
          search = !description ? content.name : content.description
      })
  })
  return search
}

const notebooksSlice = createSlice({
  name: 'uniformsSlice',
  initialState: {
    generalOptionsNotebookFront: optionsGeneral,
    generalOptionsNotebookInside: optionsGeneralInside,
    notebookFrontValues: INPUTS_OUTSIDE as IInputsNotebookForm,
    notebookInsideValues: INPUTS_INSIDE as IInputsNotebookInsideForm,
    pictureLogo: kid,
    notebookSelected: 0,
    chargeDataFlag: false,
    insideLogo: '',
    outsidePropuesta: '',
    outsidePropuestaBack: '',
    isOnEdith: false,
    showModalUploadPortada: false,
    ezetera_proposal: false,
    college_proposal: false,
    require_cover_desing: false,
    require_cover_img: false,
    require_cover_text: false,
    stepNotebook: 0,
    jsonImgBack: {} as IJsonImgContent,
    jsonImgfront: {} as IJsonImgContent,
    jsonTextBack: {} as IJsonTextContent,
    jsonTextfront: {} as IJsonTextContent,
    TextBackId: 0,
    TextfrontkId: 0,
    ImgBackId: 0,
    ImgfrontkId: 0,
    textPositionNow: {} as IJsonParameters,
    uploadImgBucket: '',
    uploadBucketModal: false,
    edithUpload: { left: false, right: false },
    positionCovers: { back: 0, front: 0 },
    isCoverOnEdith: false,
    layers: 1,
    edithOutside4specs: {
      id: 0,
      id_package: 0,
      category: '',
      inventariable: false,
      tax: '',
      price_list: '',
      showModal: false
    },
    hiddeBack: false,
    refreshComent: false
  },
  reducers: {
    handleHideBack: (state, action) => {
      state.hiddeBack = action.payload
    },
    handleRefresComent: (state) => {
      state.refreshComent = !state.refreshComent
    },
    handleOptionsOutside: (state, action) => {
      state.edithOutside4specs = action.payload
    },
    setLayerOnView: (state, action) => {
      state.layers = action.payload
    },
    setIsCoverOnEdith: (state, action) => {
      state.isCoverOnEdith = action.payload
    },
    setPositionsCovers: (state, action) => {
      state.positionCovers = action.payload
    },
    setEdithUpload: (state, action) => {
      state.edithUpload = action.payload
    },
    addImgCoverBack: (state, action) => {
      state.jsonImgBack = action.payload
    },
    setImgIdFront: (state, action) => {
      state.ImgfrontkId = action.payload
    },
    setImgIdBack: (state, action) => {
      state.ImgBackId = action.payload
    },
    addImgCoverFront: (state, action) => {
      state.jsonImgfront = action.payload
    },
    setSelectedNotebook: (state, action) => {
      state.notebookSelected = action.payload
    },
    setShowModalBucket: (state, action) => {
      state.uploadBucketModal = action.payload
    },
    setImgBucket: (state, action) => {
      state.uploadImgBucket = action.payload
    },
    setIdTextFront: (state, action) => {
      state.TextfrontkId = action.payload
    },
    setTextPositionNow: (state, action) => {
      state.textPositionNow = action.payload
    },
    setIdTextBack: (state, action) => {
      state.TextBackId = action.payload
    },
    setJsonTextBack: (state, action) => {
      state.jsonTextBack = action.payload
    },
    setJsonTextfront: (state, action) => {
      state.jsonTextfront = action.payload
    },
    setJsonImgBack: (state, action) => {
      state.jsonImgBack = action.payload
    },
    setJsonImgFront: (state, action) => {
      state.jsonImgfront = action.payload
    },
    setEzeteraProposal: (state, action) => {
      state.ezetera_proposal = action.payload
    },
    setRequireCoverDesing: (state, action) => {
      state.require_cover_desing = action.payload
    },
    setRequireCoverImg: (state, action) => {
      state.require_cover_img = action.payload
    },
    setRequireCoverText: (state, action) => {
      state.require_cover_text = action.payload
    },
    setCollegeProposal: (state, action) => {
      state.college_proposal = action.payload
    },
    setShowModalUploadPortada: (state, action) => {
      state.showModalUploadPortada = action.payload
    },
    setOutsidePropuesta: (state, action) => {
      state.outsidePropuesta = action.payload
    },
    setOutsidePropuestaBack: (state, action) => {
      state.outsidePropuestaBack = action.payload
    },
    setStepNotebook: (state, action) => {
      state.stepNotebook = action.payload
    },
    setInsiseLogo: (state, action) => {
      state.insideLogo = action.payload
    },
    EnableFlag: (state) => {
      state.chargeDataFlag = true
    },
    DisableFlag: (state) => {
      state.chargeDataFlag = false
    },
    handleNotebookForm: (state, action) => {
      state.notebookFrontValues = action.payload
    },
    resetFormValues: (state) => {
      state.notebookFrontValues = INPUTS_OUTSIDE as IInputsNotebookForm
      state.notebookInsideValues = INPUTS_INSIDE as IInputsNotebookInsideForm
      state.jsonImgBack = {}
      state.jsonImgfront = {}
      state.jsonTextBack = {}
      state.jsonTextfront = {}
    },
    handleNotebookInsideForm: (state, action) => {
      state.notebookInsideValues = action.payload
    },
    setPictureLogo: (state, action) => {
      state.pictureLogo = action.payload
    }
  }
})

export const {
  handleNotebookInsideForm,
  setPictureLogo,
  setSelectedNotebook,
  handleNotebookForm,
  EnableFlag,
  DisableFlag,
  setStepNotebook,
  setInsiseLogo,
  setShowModalUploadPortada,
  setOutsidePropuesta,
  setOutsidePropuestaBack,
  setCollegeProposal,
  setEzeteraProposal,
  resetFormValues,
  setRequireCoverImg,
  setRequireCoverDesing,
  setRequireCoverText,
  setJsonImgFront,
  setJsonImgBack,
  setJsonTextBack,
  setJsonTextfront,
  setIdTextBack,
  setTextPositionNow,
  setIdTextFront,
  setImgBucket,
  setShowModalBucket,
  addImgCoverBack,
  addImgCoverFront,
  setImgIdBack,
  setImgIdFront,
  setEdithUpload,
  setPositionsCovers,
  setIsCoverOnEdith,
  setLayerOnView,
  handleOptionsOutside,
  handleHideBack,
  handleRefresComent
} = notebooksSlice.actions

export const notebooksReducer = notebooksSlice.reducer
