import Button from 'components/buttons/Button/Button';
import B from 'components/texts/B/B';
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';
import { disableValidCommentButton } from './CommentsComponent';
import { useLocation } from 'react-router-dom';

const RenderFooter = memo(({requestChanges, negateRequestChangesBooks, aceptRequestChangesBooks, onValidate, innerFormFilled}:any) => {
    let {  comments, data } = useSelector(
        (state: RootState) => state.comments
      )
    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const { pathname } = useLocation()

    if (uid.role === 'Director') return null
      if (data?.status >= 7) return null
      if (uid.role !== 'Ezetera') return null
      if (requestChanges && pathname.includes('libros'))
        return (
          <>
            <B>
              El cliente ha solicitado modificar esta lista de libros, ¿ deseas
              aceptar su pedido ?
            </B>
            <div className='comments-footer-button' style={{ gap: '16px' }}>
              <Button
                onClick={negateRequestChangesBooks}
                size='m'
                label='No'
                rounded
                sector='secondary'
              />
              <Button
                onClick={aceptRequestChangesBooks}
                size='m'
                label='Si'
                rounded
                sector='primary'
              />
            </div>
          </>
        )
      return (
        <div className='comments-footer-button'>
          <Button
            onClick={onValidate}
            size='m'
            label='Validar'
            rounded
            sector='primary'
            disabled={disableValidCommentButton(
              data,
              comments,
              innerFormFilled
            )}
          />
        </div>
      )
});
RenderFooter.displayName='RenderFooter'
export default RenderFooter;