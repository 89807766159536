import React, { memo, useEffect, useState } from 'react'
import './UpdateInput.scss'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { handleErrors } from 'utilities/handleErrors'
import { useLocation } from 'react-router-dom'
import { SuccessToast } from 'components/Toasts/Toasts'
import { updateSchoolUtil } from 'services/utilsServices/utils'
interface UpdateInputI {
  data: any
  packageId: number
}
const UpdateInput = memo(({ data, packageId }: UpdateInputI) => {
  const [ChangeMode, setChangeMode] = useState(false)
  const [value, setValue] = useState(data.quantity)
  const [loadingPost, setloadingPost] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (data.quantity) setValue(data.quantity)
  }, [data])

  const handleChangeMode = (e: any) => {
    e.stopPropagation()
    setChangeMode(!ChangeMode)
  }

  const updateUtil = async (id: any, values: any) => {
    setloadingPost(true)
    let data = { quantity: values.quantity, id }
    if (location.state?.status < 4)
      data = { ...values, quantity: values.quantity, id }
    const button = document.getElementById(`get-items-list-${packageId}`)
    try {
      await updateSchoolUtil({ data })
      SuccessToast(`El útil "${values.name}" fue editado correctamente`)
      button?.click()
      setloadingPost(false)
    } catch (err: any) {
      setloadingPost(false)
      handleErrors(err)
    }
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    setValue(value)
  }

  const sendValue = async () => {
    try {
      await updateUtil(data.id_supply_school, { ...data, quantity: value })
      setChangeMode(!ChangeMode)
    } catch (error) {
      handleErrors(error)
    }
  }

  const handleKeys = (e: any) => {
    const regex = new RegExp(/([0-9])/g)
    const valid = regex.test(e.key)
    if (e.key === 'Enter') return sendValue()
    if (e.code !== 'Backspace' && !valid) return e.preventDefault()
  }

  return (
    <div className='update-input'>
      {ChangeMode ? (
        <div
          className='dinamic-input-container'
          onClick={(e) => e.stopPropagation()}
        >
          <input value={value} onChange={handleChange} onKeyDown={handleKeys} />
          <XCircleIcon onClick={handleChangeMode} />
        </div>
      ) : (
        <input
          id={data.id}
          className='static-input'
          onClick={handleChangeMode}
          value={value}
        />
      )}
    </div>
  )
})
UpdateInput.displayName = 'UpdateInput'
export default UpdateInput
