import { Input, Modal, Row, Table, Typography, Select, Tooltip } from 'antd'
import { memo } from 'react'
import type { TablePaginationConfig } from 'antd/es/table'
import { Link, useNavigate } from 'react-router-dom'
import { useSchoolList } from 'hooks/adminSchool/useSchoolList'
import { formatUrlNames } from 'utilities/formatUrlNames'
import style from './SchoolList.module.scss'
import globalStyle from '../../../styles/global.module.scss'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import DescagaPic from 'assets/img/descarga.svg'
import Button from 'components/buttons/Button/Button'
import B from 'components/texts/B/B'
import dayjs from 'dayjs'
import Tag from 'components/tag'

interface TableParams {
  pagination?: TablePaginationConfig
}

const index = memo(() => {
  const {
    schoolList,
    pagination,
    LoadingList,
    changePage,
    searchSchool,
    onHandleSearchSchool,
    navigateToCreate,
    setdownloadModal,
    downloadModal,
    confirmDownload,
    cancelDownload,
    loadingDowload,
    onHandleChangeStatus,
    SCHOOL_STATUS,
    donwloadLogs
  } = useSchoolList()
  const navigate = useNavigate()

  const SchoolAdminListColumns = [
    {
      title: 'Nombre del Colegio',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200,
      render: (name: string) => {
        return (
          <Tooltip title={name}>
            <span>{name}</span>
          </Tooltip>
        )
      }
    },

    {
      title: 'Razón Social',
      dataIndex: 'social_reason',
      key: 'social_reason',
      ellipsis: true,
      width: 150
    },
    {
      title: 'Tipo de Colegio',
      dataIndex: 'sex',
      key: 'sex',
      ellipsis: true,

      render: (text: any) => {
        let label = 'Mixto'
        if (text === 'f') label = 'Femenino'
        if (text === 'm') label = 'Masculino'
        return (
          <Tooltip title={label}>
            <span>{label}</span>
          </Tooltip>
        )
      }
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      width: 150,
      render: (address: string) => {
        return (
          <Tooltip title={address}>
            <span>{address}</span>
          </Tooltip>
        )
      }
    },
    {
      title: 'Fecha de apertura',
      dataIndex: 'opening_date',
      key: 'opening_date',
      ellipsis: true,

      render: (text: string) => {
        const date = text.split('T')
        const parseDate = dayjs(date[0]).format('DD/MM/YYYY')
        return (
          <Tooltip title={parseDate}>
            <span>{parseDate}</span>
          </Tooltip>
        )
      }
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      render: (phone: string) => {
        return (
          <Tooltip title={phone}>
            <span>{phone}</span>
          </Tooltip>
        )
      }
    },
    /* {
      title: 'ID Magento',
      dataIndex: 'id_magento',
      key: 'id_magento',
      width: 100,
      ellipsis: true,
  
    },
    {
      title: 'ID GEA',
      dataIndex: 'id_gea',
      key: 'id_gea',
      width: 100,
      ellipsis: true,
    }, */
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status',
      width: 200,
      render: (_: any, record: any) => <Tag variation={record?.status} />
    },
    /*  {
      title: 'Detalles',
      dataIndex: 'status_name',
      key: 'status',
      width: 200,
      render: (_: any, record: any) => (
        <Link to={`/colegio/${formatUrlNames(record.name)}`} state={record}>
          {' '}
          Ver detalle{' '}
        </Link>
      )
    }, */
    {
      title: '',
      dataIndex: 'status_name',
      key: 'status',
      width: 350,
      render: (_: any, record: any) => (
        <>
          <Link to={`/colegio/${formatUrlNames(record.name)}`} state={record}>
            Ver detalle
          </Link>
          {' '} |{' '}
          <Link to={`/reportes`} state={record}>
            Ver reportes
          </Link>
        </>
      )
    }
  ]
  return (
    <MainTemplate>
      <div className={style.schoolList}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <TitleHeader
            buttonProps={[
              {
                label: 'Descargar Logs',
                onClick: () => donwloadLogs(),
                background: 'white',
                rounded: true,
                size: 'm',
                sector: 'tertiary',
                isLoading: loadingDowload
              },
              {
                label: 'Crear un Colegio',
                onClick: navigateToCreate,
                rounded: true,
                type: 'red',
                sector: 'secondary',
                background: 'color',
                size: 'm'
              },
              {
                LeftIconName: 'downloadIcon',
                label: 'Descargar lista de colegios',
                onClick: () => setdownloadModal(true),
                type: 'transparent',
                rounded: true,
                sector: 'secondary',
                background: 'white',
                size: 'm'
              }
            ]}
            IconName='estructure'
            title='Colegios'
          />
        </Row>

        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Typography>Estatus</Typography>
          <Select
            style={{ width: 200 }}
            defaultValue={'Todos'}
            placeholder={'Estatus'}
            onChange={onHandleChangeStatus}
            options={SCHOOL_STATUS}
          />
        </div>
        <br></br>
        <Input.Search
          placeholder='Buscar'
          onChange={(e: any) => onHandleSearchSchool(e.target.value)}
          value={searchSchool}
          enterButton
          size='large'
          /*   className={styles.inputSearchGral} */
        />
        <br></br>
        <br></br>

        {schoolList.length > 0 ? (
          <Table
            className={style.schoolAdminTable}
            loading={LoadingList}
            rowKey={'id'}
            scroll={{ x: 1400 }}
            dataSource={schoolList}
            columns={SchoolAdminListColumns}
            pagination={pagination}
            onChange={changePage}
            /*  onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log(record)

                  navigate(`/colegio/${formatUrlNames(record.name)}`, {
                    state: record
                  })
                } // click row
              }
            }} */
          />
        ) : (
          <EmptyListMessage
            icon='emptySchoolListIcon'
            text='Aún no has creado colegios'
          />
        )}
      </div>

      <Modal
        title='Confirmar descarga'
        open={downloadModal}
        onOk={confirmDownload}
        confirmLoading={loadingDowload}
        onCancel={cancelDownload}
        className={globalStyle.confirmModal}
        footer={
          <div className={globalStyle.confirmModalFooter}>
            <Button
              isLoading={loadingDowload}
              onClick={confirmDownload}
              rounded
              label={'Descargar'}
            />
          </div>
        }
      >
        <div className={globalStyle.confirmModalBody}>
          <img src={DescagaPic} alt='descargar' />
          <B size='Light'>
            {' '}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra.
          </B>
        </div>
      </Modal>
    </MainTemplate>
  )
})
index.displayName = 'SchoolList'
export default index
