import React, { Fragment, memo, useEffect, useState } from 'react'
import style from '../Sidebar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import B from 'components/texts/B/B'
import Icon from 'components/icons'
import {
  moveToCatalog,
  moveToManagement
} from 'redux/reducers/utilsAdminPageSlice'
import { AnyAction } from '@reduxjs/toolkit'
import {
  moveToElementList,
  moveToStructure
} from 'redux/reducers/levelsAndGrades'
import { RootState } from 'redux/store/store'
import useStructureFinish from 'hooks/useStructureFinish'

const SIDEBAR_MENU_ADMIN = [
  {
    url: '/users-management',
    label: 'Usuarios',
    icon: <Icon name='UserIcons' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/lista-de-colegios',
    label: 'Lista de colegios',
    icon: <Icon name='estructure' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/libros',
    label: 'Libros',
    icon: <Icon name='book' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/cuadernos',
    label: 'Cuadernos personalizados',
    icon: <Icon name='notebook' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/uniforms',
    label: 'Uniformes',
    icon: <Icon name='uniform' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/utiles',
    label: 'Útiles',
    icon: <Icon name='utilsIcon' />,
    permission: undefined,
    role: undefined,
    render: ({
      res = {
        url: '/utiles',
        label: 'Útiles',
        icon: <Icon name='utilsIcon' />
      },
      url = '',
      goTo = (param: string) => {},
      key = null,
      location = { pathname: ['undefined'] },
      showMenu = false,
      setShowMenu = (flag: any) => {},
      dispatch = (action: AnyAction) => {},
      utilsAdminPage = 'management'
    }) => (
      <div>
        <div
          key={`${key}-sidebarOpt-header`}
          data-variation={location?.pathname.includes(url) ? 'active' : ''}
          className={style.sidebarOptions}
          onClick={(_) =>
            /*goTo(res.url)*/ setShowMenu((prev: boolean) => !prev)
          }
        >
          <div className={style.icon}>{res.icon}</div>
          <B size='Bold'>{res.label}</B>
        </div>
        <div className={style.utilsMenu} data-show={true}>
          <div
            className={`${style.button} ${
              utilsAdminPage == 'catalog' &&
              location.pathname.includes('utiles')
                ? style.selected
                : ''
            }`}
            onClick={() => {
              dispatch(moveToCatalog())
              goTo(res.url)
            }}
          >
            Catálogo de útiles
          </div>
          <div
            className={`${style.button} ${
              utilsAdminPage == 'management' &&
              location.pathname.includes('utiles')
                ? style.selected
                : ''
            }`}
            onClick={() => {
              dispatch(moveToManagement())
              goTo(res.url)
            }}
          >
            Manejo de catálogos
          </div>
        </div>
      </div>
    )
  },
  {
    url: '/cuotas',
    label: 'Cuotas',
    icon: <Icon name='rightArrow' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/comisiones',
    label: 'Comisiones por colegio',
    icon: <Icon name='file' />,
    permission: undefined,
    role: undefined
  }
]

const SIDEBAR_MENU = [
  {
    url: '/users-management',
    label: 'Usuarios',
    icon: <Icon name='UserIcons' />,
    permission: undefined,
    role: 'Administrador'
  },
  {
    url: '/inicio',
    label: 'Inicio',
    icon: <Icon name='estructure' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/mi-colegio',
    label: 'Colegio',
    icon: <Icon height={19} width={19} name='schoolIcon' />,
    permission: undefined,
    role: undefined
  },
  {
    url: '/estructura',
    label: 'Estructura Escolar',
    icon: <Icon name='estructure' />,
    permission: undefined,
    role: undefined,
    render: ({
      res = {
        url: '/estructura',
        label: 'Estructura',
        icon: <Icon name='estructure' />
      },
      url = '',
      goTo = (param: string) => {},
      key = null,
      location = { pathname: ['undefined'] },
      showMenu = false,
      setShowMenu = (flag: any) => {},
      dispatch = (action: AnyAction) => {},
      levelsAndGrades = 'structure'
    }) => (
      <div>
        <div
          key={`${key}-sidebarOpt-header`}
          data-variation={location?.pathname.includes(url) ? 'active' : ''}
          className={style.sidebarOptions}
          onClick={(_) =>
            /*goTo(res.url)*/ setShowMenu((prev: boolean) => !prev)
          }
        >
          <div className={style.icon}>{res.icon}</div>
          <B size='Bold'>{res.label}</B>
        </div>
        <div className={style.utilsMenu} data-show={true}>
          <div
            className={`${style.button} ${
              levelsAndGrades == 'structure' &&
              location.pathname.includes('estructura')
                ? style.selected
                : ''
            }`}
            onClick={() => {
              dispatch(moveToStructure())
              goTo(res.url)
            }}
          >
            Creación de secciones y grados escolares
          </div>
          <div
            className={`${style.button} ${
              levelsAndGrades == 'element_list' &&
              location.pathname.includes('estructura')
                ? style.selected
                : ''
            }`}
            onClick={() => {
              dispatch(moveToElementList())
              goTo(res.url)
            }}
          >
            Listado de elementos escolares
          </div>
        </div>
      </div>
    )
  },
  {
    url: '/libros',
    label: 'Libros',
    icon: <Icon name='book' />,
    permission: 2,
    role: undefined
  },

  {
    url: '/cuadernos',
    label: 'Cuadernos',
    icon: <Icon name='notebook' />,
    permission: 3,
    role: undefined
  },
  {
    url: '/uniforms',
    search: `?tab=0`,
    label: 'Uniformes',
    icon: <Icon name='uniform' />,
    permission: 5,
    role: undefined
  },
  {
    url: '/utiles',
    search: `?tab=0`,
    label: 'Útiles',
    icon: <Icon name='utilsIcon' />,
    permission: 4,
    role: undefined
  },
  {
    url: '/cuotas',
    label: 'Cuotas',
    icon: <Icon name='rightArrow' />,
    permission: 6,
    role: undefined
  },
  {
    url: '/reportes',
    label: 'Reportes',
    icon: <Icon name='rightArrow' />,
    permission: 7,
    role: undefined
  }
]

const RenderMenu = memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showUtilsMenu, setShowUtilsMenu] = useState(false)
  const [sidebarMenu, setsidebarMenu] = useState<any[]>([])
  const { structureFinish } = useStructureFinish()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      setsidebarMenu(SIDEBAR_MENU_ADMIN)
    } else {
      setsidebarMenu(SIDEBAR_MENU)
    }
  }, [uid])

  const utilsAdminPage = useSelector(
    (state: any) => state?.utilsAdmin?.value || 'management'
  )
  const levelsAndGrades = useSelector(
    (state: any) => state?.levelsAndGrades?.page_type || 'structure'
  )

  const goTo = (url: any): void => {
    navigate(url)
  }
  return (
    <div className={style.menu}>
      {sidebarMenu.map((res: any, key: any) => {
        const url = res.url.replaceAll('/', '')
        if (res.render)
          return (
            <Fragment key={res.label}>
              {(() => {
                return res.render({
                  res,
                  url,
                  goTo,
                  key,
                  location,
                  showMenu: showUtilsMenu,
                  setShowMenu: setShowUtilsMenu,
                  dispatch,
                  utilsAdminPage,
                  levelsAndGrades
                })
              })()}
            </Fragment>
          )

        const path = {
          pathname: res.url,
          search: res.search ?? ''
        }

        if (res.role && res.role !== uid.role) return null
        if (uid.role === 'Administrador' && !structureFinish) {
          if (key > 3) return null
        }

        if (res.permission && uid.role === 'Administrador') {
          // Aca adentro por lo general siempre se mostrara las primeras tres opciones en el sidebar: "inicio", Colegio, Estructura Escolar
          const exist = uid.school_permissions.some(
            (permission) => res.permission === permission.id
          )
          if (!exist) return null
          return (
            <div
              key={`${key}-sidebarOpt`}
              data-variation={location.pathname.includes(url) ? 'active' : ''}
              className={style.sidebarOptions}
              onClick={(_) => goTo(path)}
            >
              <div className={style.icon}>{res.icon}</div>
              <B size='Bold'>{res.label}</B>
            </div>
          )
        }

        if (res.permission && uid.role !== 'Administrador') {
          // Aca adentro por lo general siempre se mostrara las primeras tres opciones en el sidebar: "inicio", Colegio, Estructura Escolar
          const exist = uid.permissions.some(
            (permission) => res.permission === permission.id
          )
          if (!exist) return null
          if (
            uid.role !== 'Ezetera' &&
            uid.school_status !== 'Autorizado' &&
            !structureFinish
          )
            return null

          return (
            <div
              key={`${key}-sidebarOpt`}
              data-variation={location.pathname.includes(url) ? 'active' : ''}
              className={style.sidebarOptions}
              onClick={(_) => goTo(path)}
            >
              <div className={style.icon}>{res.icon}</div>
              <B size='Bold'>{res.label}</B>
            </div>
          )
        }

        return (
          <div
            key={`${key}-sidebarOpt`}
            data-variation={location.pathname.includes(url) ? 'active' : ''}
            className={style.sidebarOptions}
            onClick={(_) => goTo(path)}
          >
            <div className={style.icon}>{res.icon}</div>
            <B size='Bold'>{res.label}</B>
          </div>
        )
      })}
    </div>
  )
})

export default RenderMenu
