import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { getSellsByArticle, getSellsByDay } from 'services/sells/sells'
import formatNumber from 'utilities/numberFormater'
import { useGetInitialParams } from './useGetInitialParams'
import TranslateDateToSpanish from 'utilities/translateDatesToSpanish'

export default function useBarChart() {
  const { apliedFilters, loadingFilter } = useSelector(
    (state: RootState) => state.sells
  )
  const [loading, setloading] = useState(true)
  const [SellsByArticle, setSellsByArticle] = useState({})
  const { schoolIDGea } = useGetInitialParams()

  function ordenarPorFechas(arrayFechas: any) {
    arrayFechas.sort(function (a: any, b: any) {
      const fechaA = dayjs(a.x, 'DD MM YYYY')
      const fechaB = dayjs(b.x, 'DD MM YYYY')
      return fechaA.diff(fechaB)
    })
    return arrayFechas
  }

  const parsedSellByArticle = (sells: any) => {
    let ParsedObject: any = {}
    Object.entries(sells).forEach(([key, value]: any) => {
      
      const newSellsArray = value.ventas.map((res: any) => {
        return {
          x: res.Fecha,
          /* y: res.Total / 1000, */
          y: parseFloat(res.Total),
          label: `${TranslateDateToSpanish(dayjs(res.Fecha).format('DD MMM YYYY'))} - ${key}: $ ${formatNumber(res.Total)}`
        }
      })
      ParsedObject = { ...ParsedObject, [key]: ordenarPorFechas(newSellsArray) }
    })
    setSellsByArticle(ParsedObject)
  }

  const parsedDataByDay = (sells: any) => {
    let sellByDate: any = {}
    sells.forEach((res: any) => {
      const key = res.Fecha.replace(/ /g, '_')
      if (!sellByDate[key]) {
        sellByDate = { ...sellByDate, [key]: [] }
      } else {
        const newArray = [...sellByDate[key]]
        newArray.push(res)
        sellByDate = { ...sellByDate, [key]: newArray }
      }
    })
  }

  const getArticleSellsInfo = async () => {
    !loading && setloading(true)
    try {
      const sellsByArticle: any = await getSellsByArticle({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })

      const sellsByDay: any = await getSellsByDay({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      parsedSellByArticle(sellsByArticle.data.data)
      parsedDataByDay(sellsByDay.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getArticleSellsInfo()
  }, [apliedFilters, loadingFilter])

  /*   const startDate = new Date(2023, 1, 1) // Inicio del mes (febrero de 2023)
  const numMonths = 5 // Número de meses que quieres mostrar

  const data: any = []
  for (let i = 0; i < numMonths; i++) {
    const month = startDate.getMonth() + i
    const year = startDate.getFullYear()
    const numDays = new Date(year, month + 1, 0).getDate() // Número de días en el mes
    const monthData = Array.from({ length: numDays }, (_, index) => {
      const date = new Date(year, month, index + 1)
      const value = Math.floor(Math.random() * 100000)
      return { x: date, y: value, label: `$ ${formatNumber(value.toString())}` }
    })
    data.push(...monthData)
  }
  console.log(data)

  const data2: any = []
  for (let i = 0; i < 1; i++) {
    const month = startDate.getMonth() + i
    const year = startDate.getFullYear()
    const numDays = new Date(year, month + 1, 0).getDate() // Número de días en el mes
    const monthData = Array.from({ length: numDays }, (_, index) => {
      const date = new Date(year, month, index + 1)
      const value = Math.floor(Math.random() * 20000)
      return { x: date, y: value, label: `$ ${formatNumber(value.toString())}` }
    })
    data2.push(...monthData)
  } */

  const tickFormat = (date: any) => {
    /* if (date.getDate() === 15) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    } */
    return ''
  }

  /* const tickValues = data
    .filter(({ x }: any) => x.getDate() === 15)
    .map(({ x }: any) => x) */

  return { tickFormat, SellsByArticle, loading }
}
