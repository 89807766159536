import React from 'react'

const CorbataSvg= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path   d="m366.13,180.89s-9.93,12.51-12.19,17.1c-2.26,4.59-36.01-26.13-36.01-26.13l-.61-4.63,4.53-22.53s1.25-7.45,2.76-8.34c8.1-4.77,44.06-10.31,75.16-10.34,43.95-.04,78.06,12.42,78.06,12.42,0,0,6.64-.6,4.38,30.08-.36,4.88-33.23,27.86-33.23,27.86l-14.45-16.92,23.59-18.82c3.22-4.21,1.73-7.24-.83-8.07-15.36-4.98-21.21-3.61-59.21-3.88-68.41-.49-53.85,13.5-53.85,13.5l21.9,18.71Z"/>
      <path   d="m353.94,197.98l12.19-17.1c4.74.29,67.05-4.12,68.4-1.43,1.95,3.86,14.45,16.92,14.45,16.92,0,0-14.68,67.38-25.74,70.82-11.06,3.44-22.8,4.87-38.6,2.29-15.81-2.58-30.7-71.5-30.7-71.5Z"/>
      <path   d="m381.9,268.38l-37.56,336.98s-.96,8.66,3.18,15.37c5.68,9.19,46.41,51.88,46.41,51.88,0,0,6.67,7.64,14.85,0,10.7-10,44.56-45.59,44.56-45.59,0,0,7.18-6.98,7.16-19.99l-3.73-38.93-33.53-300.91-41.35,1.19Z"/>
      <path   d="m410.91,142.21c0,5-4.12,9.06-9.22,9.06h0c-5.09,0-9.22-4.05-9.22-9.06v-9.16c0-5,4.13-9.06,9.22-9.06h0c5.09,0,9.22,4.05,9.22,9.06v9.16Z"/>
      <polygon   points="344.24 607.79 345.21 597.5 411.09 670.4 405.81 674.79 344.24 607.79"/>
      <polygon   points="348.86 564.79 349.6 558.12 431.81 649.43 429.34 651.99 348.86 564.79"/>
      <polygon   points="353.06 527.1 354.1 517.72 453.34 627.01 448.02 632.59 353.06 527.1"/>
      <polygon   points="354.33 515.7 355.36 506.47 458.33 618.86 457.19 621.39 456.31 623 454.26 625.98 354.33 515.7"/>
      <polygon   points="358.93 474.43 359.51 469.21 457.45 575.15 458.07 581.61 358.93 474.43"/>
      <polygon   points="362.85 439.28 363.93 429.56 452.08 525.96 453.23 536.24 362.85 439.28"/>
      <polygon   points="364.2 427.1 365.14 418.67 450.23 509.36 451.54 521.06 364.2 427.1"/>
      <polygon   points="368.9 385.6 369.42 380.31 444.95 461.97 445.71 468.8 368.9 385.6"/>
      <polygon   points="372.73 350.64 373.63 342.55 439.4 412.14 440.73 424.05 372.73 350.64"/>
      <polygon   points="374.05 338.79 374.97 330.49 437.67 396.57 438.94 408.01 374.05 338.79"/>
      <polygon   points="378.48 299 379.05 293.93 432.51 350.29 433.18 356.34 378.48 299"/>
      <polygon   points="387.14 268.5 396.41 268.48 427.15 302.19 428.34 312.91 387.14 268.5"/>
      <polygon   points="401.5 268.5 408.56 268.08 425.44 286.83 426.62 297.42 401.5 268.5"/>
      <polygon   points="429.65 178.66 426.53 178.59 423.94 178.58 369.42 249.24 371.21 253.25 429.65 178.66"/>
      <polygon   points="396.9 179.49 386.68 179.99 358.44 216.12 361.21 225.85 396.9 179.49"/>
      <polygon   points="384.01 180.13 376 180.54 355.7 205.5 357.8 213.74 384.01 180.13"/>
      <polygon   points="441.58 225.88 444.98 213.13 393.5 267.5 401.5 267.5 441.58 225.88"/>
      <polygon   points="388.02 268.2 446.25 208.04 448.61 195.97 380.68 267.19 383.52 268.37 388.02 268.2"/>
      <polygon   points="449.76 167.3 455.25 162.92 470.72 180.13 465.4 184.32 449.76 167.3"/>
      <polygon   points="464.27 134.37 471.9 136.95 482.49 148.72 482.49 154.17 464.27 134.37"/>
      <polygon   points="318.84 159.69 320.53 151.27 357.94 191.61 353.61 198.32 318.84 159.69"/>
      <polygon   points="321.04 148.75 322.7 140.72 363.54 184.22 362.06 186.15 360.9 187.69 358.86 190.46 321.04 148.75"/>
      <polygon   points="347.54 130.33 352.28 129.59 370.66 149.48 365.73 149.94 347.54 130.33"/>
      <polygon   points="441.65 173.87 447.19 169.45 462.57 186.47 457.02 190.6 441.65 173.87"/>
      <path d="m353.55,170.08c-3.09-2.46-6.11-4.99-8.92-7.79-.61-.65-1.29-1.25-1.74-2.12-1.28-2.91-3.26-6.17-1.79-9.5,1.35-2.44,4.66-3.05,7.08-3.92,0,0,.07.19.07.19-1.2.52-2.38,1.08-3.5,1.71-4.63,2.12-1.25,7.2-.22,10.66.05.17.05.25.18.46.99,1.57,2.38,3.01,3.64,4.42,1.76,1.92,3.55,3.84,5.35,5.74,0,0-.13.15-.13.15h0Z"/>
      <path d="m449.7,167.36c2.67-2.86,5.41-5.67,7.91-8.67.48-.64,1.07-1.24,1.26-1.95,1.03-3.46,4.4-8.55-.22-10.66-1.12-.63-2.31-1.19-3.5-1.71l.07-.19c2.42.87,5.73,1.48,7.08,3.92,1.47,3.33-.51,6.58-1.79,9.5-1.22,1.95-3.02,3.29-4.63,4.84-1.96,1.76-3.98,3.43-6.03,5.06l-.13-.15h0Z"/>
      <circle   cx="405.25" cy="531.12" r="12.78"/>
    </g>
  </g>
</svg>
)
}

export default CorbataSvg