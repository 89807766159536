import React from 'react'

const LabelFrontalPants= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
className={className}
>
<g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path d="m511.9,72.94c-.81.16-1.09.42-.83,1.56.71,3.13,1.34,6.28,1.93,9.43.19,1.01.48,1.2,1.24,1.04,4.86-1.03,9.73-2.01,14.6-3l-2.43-11.96-.21.04c-4.76.97-9.53,1.97-14.3,2.9Z"/>
      <path d="m519.53,81.01c.18-.16.35-.33.54-.47.59-.43.9-1.13,1.2-1.82.19-.42.11-.77-.25-1.09-1.12-1-2.38-1.32-3.69-1.48-.05,0-.1-.02-.14-.05.52-.13,1.03.08,1.55.11.15,0,.36.15.43-.06.06-.19-.13-.33-.24-.48-.17-.23-.36-.44-.51-.71.87.93,1.86,1.51,2.96,1.85.48.14.67-.02.76-.54.15-.82.31-1.64-.07-2.5.69.91,1.51,1.72,1.37,3.12.25-.05.46-.09.68-.14.22-.05.33.14.42.31.16.28-.11.23-.19.32-.39.53-.98.82-1.19,1.6-.13.53-.61.74-1.03.97-.83.44-1.71.77-2.61,1.04Z"/>
    </g>
  </g>
</svg>
)
}

export default LabelFrontalPants