import { AUTHSERVICE } from '../config'

interface IResults {
  status: number
  statusText: string
  data: {
    success: boolean
    url: string
  }
}

interface IData {
  color: number
}

const updateImg = async (id: number, data: IData): Promise<any> => {
  return await AUTHSERVICE().put(`/notebook/update-logo/${id}`, data)
}

const UpdateNotebookInsideLogo = async (id: number, data: IData) => {
  const results = await updateImg(id, data)
  return results as IResults
}

export { UpdateNotebookInsideLogo }
