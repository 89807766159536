import React, { Fragment } from 'react'
import style from './carduniform.module.scss'
import kid from 'assets/img/uniforms/kid-uniform.png'

const CardUniformSelect = ({
  name = 'vacio',
  picture = kid,
  onClick,
  children,
  selected
}: {
  name?: string
  picture?: string
  onClick: () => void
  children?: JSX.Element | null
  selected: boolean
}) => {
  return (
    <div
      onClick={() => onClick()}
      className={`${style['div-card-container']} ${
        selected && style['border-selected']
      }`}
    >
      <div className={`${style['div-img']}`}>
        {children && children !== null ? (
          <Fragment>{children}</Fragment>
        ) : (
          <img className={`${style['img-uniform']}`} src={picture} />
        )}
      </div>
      <div className={`${style['name-type']}`}>
        <p className={`${style['p-name']}`}>{name}</p>
      </div>
    </div>
  )
}

export default CardUniformSelect
