import React, { Fragment, useEffect, useState } from 'react'
import Navbar from 'components/Navbar/Navbar'
import FixedInfoFooter from 'modules/uniforms/components/fixedInfoFooter/FixedInfoFooter'

import style from './notebook.module.scss'
import NootebookOptions from './components/notebooksOptions/NootebookOptions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import {
  allNotebooks,
  DisableFlag,
  setOutsidePropuesta,
  setOutsidePropuestaBack,
  setShowModalBucket
} from 'redux/reducers/notebooksSlice'
import VerticalPortadasEitable from './components/notebookEditables/VerticalPortadasEitable'
import HorizontalPortadasEditable from './components/notebookEditables/HorizontalPortadasEditable'
import NotebookOptionsHidden from './components/notebooksOptions/NotebookOptionsHidden'
import { useNavigate, useParams } from 'react-router-dom'
import useNotebooks from 'hooks/notebooks/useNotebooks'
import ModalUploadImgCovers from 'modules/uniforms/editor/components/selectCardOption/ModalUploadImgCovers'
import { SuccessToast } from 'components/Toasts/Toasts'
import FloatingLayers from './components/wordArtInput/FloatingLayers'
import ShowSpin from '../notebooksList/components/showSpin/ShowSpin'

const NotebookEditorModule = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [show, setshow] = useState(false)

  const {
    saveNotebookChange,
    saveSendDataNotebookChange,
    getNotebookData,
    notebook
  } = useNotebooks({
    uuid: parseInt(uuid || '0')
  })
  const { notebookSelected, uploadBucketModal, notebookFrontValues } =
    useSelector((state: RootState) => state.notebooks)

  const { cover_design } = notebookFrontValues

  const goBack = () => {
    let thisPath = window.location.pathname
    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'select-type':
          saveSendDataNotebookChange({ step: 0 }, false, () =>
            navigate(`/cuadernos`)
          )
          break
        case 'edith-front':
          saveSendDataNotebookChange({ step: 1 }, false, () =>
            navigate(`/cuadernos/select-type/${uuid}`)
          )

          break
        case 'edith-inside':
          saveSendDataNotebookChange({ step: 2 }, false, () =>
            navigate(`/cuadernos/edith-front/${uuid}`)
          )
          break
        default:
          break
      }
    }
  }

  const reset = () => {
    dispatch(setOutsidePropuesta(''))
    dispatch(setOutsidePropuestaBack(''))
    getNotebookData()
  }

  useEffect(() => {
    dispatch(DisableFlag())
  }, [])

  useEffect(() => {
    if (notebook !== null) {
      if (`${notebook.status}` === '4' || `${notebook.status}` === '5') {
        navigate(`/cuadernos/preview/${uuid}`)
      } else {
        setshow(true)
      }
    }
  }, [notebook])

  return (
    <div className={`${style['body-div']}`}>
      {show ? (
        <Fragment>
          <Navbar complete onClick={goBack} />

          <FixedInfoFooter
            save={() => {
              saveNotebookChange()
            }}
            next={(step?: number, fn?: () => void) => {
              saveNotebookChange()
              saveSendDataNotebookChange(
                { step: step || 0 },
                false,
                () => fn && fn()
              )
            }}
            uuidNotebook={uuid}
          />
          <div className={`${style['div-grid']}`}>
            <div className={`${style['div-left']}`}>
              {(() => {
                {
                  //horizontal 4,6,8
                  //vertical 1,2,3,5,7
                  switch (notebookSelected) {
                    case 1:
                      return <VerticalPortadasEitable />
                    case 2:
                      return <VerticalPortadasEitable />
                    case 3:
                      return <VerticalPortadasEitable />
                    case 5:
                      return <VerticalPortadasEitable />
                    case 7:
                      return <VerticalPortadasEitable />
                    case 4:
                      return <HorizontalPortadasEditable />
                    case 6:
                      return <HorizontalPortadasEditable />
                    case 8:
                      return <HorizontalPortadasEditable />

                    default:
                      return <></>
                  }
                }
              })()}
            </div>
            <div className={`${style['div-select-options']}`}>
              {cover_design.value === 'design' && (
                <div
                  style={{ position: 'fixed', top: '4.5rem', zIndex: 999999 }}
                >
                  {!false && <FloatingLayers />}
                </div>
              )}

              <div
                onClick={() => {
                  /*
              localStorage.clear()
              saveSendDataNotebookChange(
                {
                  college_proposal: false,
                  ezetera_proposal: false,
                  ext_cover_desing: '',
                  back_cover_img: '',
                  front_cover_img: '',
                  require_cover_desing: false,
                  require_cover_img: false,
                  require_cover_text: false,
                  jsonUploadPosition: { back: 0, front: 0 },
                  jsonImgBack: {},
                  jsonImgFront: {},
                  jsonTextBack: {},
                  jsonTextFront: {}
                },
                true
              )*/
                }}
                className={`${style['div-title-type']}`}
              >
                <p className={`${style['p-label']}`}>
                  Diseña el exterior del cuaderno y sus acabados
                </p>
                <h3 className={`${style['title-h']}`}>
                  {allNotebooks.map((item) => {
                    if (item.id === notebookSelected) return item.label
                  })}
                </h3>
                <p className={`${style['description-title']}`}></p>
              </div>

              <NotebookOptionsHidden
                save={saveSendDataNotebookChange}
                uuid={parseInt(uuid || '0')}
                reset={reset}
                resetCovers={() =>
                  saveSendDataNotebookChange(
                    {
                      college_proposal: false,
                      ezetera_proposal: false,
                      ext_cover_desing: '',
                      back_cover_img: '',
                      front_cover_img: '',
                      require_cover_desing: false,
                      require_cover_img: false,
                      require_cover_text: false,
                      jsonUploadPosition: { back: 0, front: 0 },
                      jsonImgBack: {},
                      jsonImgFront: {},
                      jsonTextBack: {},
                      jsonTextFront: {}
                    },
                    false,
                    () =>
                      SuccessToast(
                        'Se eliminaron las portadas propuestas por el colegio'
                      )
                  )
                }
              />
            </div>
          </div>
          <ModalUploadImgCovers
            isModalOpen={uploadBucketModal}
            handleOk={() => dispatch(setShowModalBucket(false))}
            handleCancel={() => dispatch(setShowModalBucket(false))}
            id={parseInt(uuid || '0')}
          />
        </Fragment>
      ) : (
        <Fragment>
          <ShowSpin />
        </Fragment>
      )}
    </div>
  )
}

export default NotebookEditorModule
