import {
  TFormSueterFemaleOptions,
  TFormSueterMaleOptions
} from 'interfaces/uniforms-interface'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleSueterFemaleForm,
  handleSueterMaleForm
} from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const SueterOptionsEditable = () => {
  const { sueterFemaleForm, sueterMaleForm, female } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormSueterMaleOptions | TFormSueterFemaleOptions,
    value: string | boolean
  ) => {
    if (female)
      dispatch(
        handleSueterFemaleForm({
          ...sueterFemaleForm,
          [keyName]: {
            ...sueterFemaleForm[keyName as TFormSueterFemaleOptions],
            value: value
          }
        })
      )
    else
      dispatch(
        handleSueterMaleForm({
          ...sueterMaleForm,
          [keyName]: {
            ...sueterMaleForm[keyName as TFormSueterMaleOptions],
            value: value
          }
        })
      )
  }

  return (
    <div>
      {(() => {
        return (
          <Fragment>
            {female ? (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sueterFemaleForm}
              />
            ) : (
              <ConstructorFormulary
                handleChange={handleChange}
                form={sueterMaleForm}
              />
            )}
          </Fragment>
        )
      })()}
    </div>
  )
}

export default SueterOptionsEditable
