import { Col, Row } from 'antd'
import { memo } from 'react'
import { VictoryPie, VictoryTooltip } from 'victory'
import usePieChart from '../../../../../hooks/reports/usePieChart'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import CLoader from 'components/CLoader/CLoader'
import formatNumber from 'utilities/numberFormater'

const PieChart = memo(({ COLOR_SCALE, maxHeight }: any) => {
  const { loadingFilter } = useSelector((state: RootState) => state.sells)
  const { articlePie, articleLeyendPie, loading } = usePieChart()
  if (loadingFilter || loading)
    return (
      <Col span={12} >
        <Row style={{height:maxHeight, alignItems:'center', justifyContent:'center', display:'flex'}}>
          <CLoader />
        </Row>
      </Col>
    )

  return (
    <>
      <Col
        span={5}
        style={{
          maxHeight,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        
        {Object.entries(articleLeyendPie).map(([key, values]: any, number) => {

          return (
            <div
              key={key + number}
              style={{
                display: 'flex',
                marginBottom: '4px',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  background: COLOR_SCALE[number],
                  borderRadius: '4px',
                  width: 40,
                  height: 20
                }}
              ></div>
              <span style={{ fontSize: 12 }}>
                {values.label==='NaN' ? '0' : values.label }: {parseInt(values.total)===0? '0' :values.percentage}
              </span>
            </div>
          )
        })}
      </Col>
      <Col span={5} style={{ maxHeight }}>
        <VictoryPie
          colorScale={COLOR_SCALE}
          labels={({ datum }) => {
            return `${datum.x}: $${formatNumber(datum.y)}`
          }}
          labelComponent={<VictoryTooltip  cornerRadius={2} />}
          data={Object.entries(articlePie).map(([key, values]: any) => {
            return { y: parseFloat(values.total), x: values.label }
          })}
        />
      </Col>
    </>
  )
})
PieChart.displayName = 'PieChart'
export default PieChart
