import React, { memo, useEffect, useState } from 'react'
import { Space, Modal, Row, Col } from 'antd'
import { Tooltip } from 'antd'
import { Form } from 'antd'
import Input from 'components/inputs/Input'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
  cleanSingleSelectUpdatedState,
  cleanSingleUpdatedState
} from 'utilities/cleanSingleUpdatedState'
import { LevelListInt } from 'types'
import Select from 'components/inputs/Select'

import { useDispatch, useSelector } from 'react-redux'
import {
  setLevelsForm,
  setGradesForm,
  INPUTS_INITIAL_STATE_GRADES,
  INPUTS_INITIAL_STATE,
  setMagentoGrades
} from 'redux/reducers/levelsAndGrades'

import style from './LevelActions.module.scss'
import Button from 'components/buttons/Button/Button'
import { RootState } from 'redux/store/store'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { getMagentoGrades } from 'services/schoolUserServices/levels'
export const disabledCreateGradeButton = (gradesForm:any, schoolInfo:any,loadingModal:boolean) => {
  let invalid = false
  const schoolType = schoolInfo.sex
  const female = gradesForm.female_population?.value
  const male = gradesForm.male_population?.value
  if (
    female &&
    parseInt(female) <= 0 &&
    (schoolType === 'f' || schoolType === 'u')
  )
    invalid = true
  if (
    male &&
    parseInt(male) <= 0 &&
    (schoolType === 'm' || schoolType === 'u')
  )
    invalid = true
  if (female === '' && (schoolType === 'f' || schoolType === 'u'))
    invalid = true
  if (male === '' && (schoolType === 'm' || schoolType === 'u'))
    invalid = true
  if (loadingModal) invalid = true
  if(gradesForm.id_magento.value===null||gradesForm.id_magento.value===undefined) invalid= true
  return invalid
}
interface LevelActionsI {
  data: LevelListInt
  loadingModal: boolean
  deleteLevelById: (e: any) => void
  editLevel: (e: any) => void
  handleChangeLevel: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeSelectLevel: (value: string, name: string) => void
  postNewGrade: (e: any) => void
  schoolInfo: any
  magentoLevels: any
}

const LevelActions = memo(
  ({
    data,
    loadingModal,
    deleteLevelById,
    editLevel,
    handleChangeLevel,
    handleChangeSelectLevel,
    postNewGrade,
    schoolInfo,
    magentoLevels
  }: LevelActionsI): JSX.Element | null => {
    Object.freeze(INPUTS_INITIAL_STATE)
    Object.freeze(INPUTS_INITIAL_STATE_GRADES)
    const dispatch = useDispatch()

    const { levelsForm, gradesForm, magentoGrades } = useSelector(
      (state: RootState) => state.levelsAndGrades
    )

    const { dataToken } = useDecodeToken()
    const { uid }: SessionData = dataToken()
    const [open, setOpen] = useState(false)
    const [openDel, setOpenDel] = useState(false)
    const [OpenEdit, setOpenEdit] = useState(false)

    const getGradesByLevelId = async () => {
      try {
        const result: any = await getMagentoGrades(
          parseInt(levelsForm?.id_magento?.value)
        )
        const MAGENTO_GRADES = result.data.levels_magento.map(
          (res: { id: number; name: string }) => {
            return { label: res.name, value: res.id }
          }
        )
        dispatch(setMagentoGrades(MAGENTO_GRADES))
      } catch (err: any) {
        console.log('magento levels', err)
      }
    }

    useEffect(() => {
      if (open) getGradesByLevelId()
    }, [open])

    const returnLevelCleanData = (data: LevelListInt) => {
      const { name, id_magento } = { ...levelsForm }
      let updateData = { ...name }
      let updateData2 = { ...id_magento }
      updateData.value = data.name
      updateData.id = data.id
      updateData2.value = data.id_magento
      return { name: updateData, id_magento: updateData2 }
    }

    const handleCancel = (e: any) => {
      setOpen(false)
      setOpenDel(false)
      setOpenEdit(false)
      dispatch(setLevelsForm({ levelsForm: INPUTS_INITIAL_STATE }))
      dispatch(setGradesForm({ gradesForm: INPUTS_INITIAL_STATE_GRADES }))
    }

    const handleEditLevel = () => {
      editLevel(handleCancel)
    }

    const handleChangeGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setGradesForm({ gradesForm: cleanSingleUpdatedState(e, gradesForm) })
      )
    }
    const handleChangeSelectGrades = (e: any, name: string) => {
      dispatch(
        setGradesForm({
          gradesForm: cleanSingleSelectUpdatedState(e, name, gradesForm)
        })
      )
    }

    const showModal = (event: any, data: LevelListInt) => {
      dispatch(setLevelsForm({ levelsForm: returnLevelCleanData(data) }))
      event.stopPropagation()
      setOpen(true)
    }

    const showEditModal = (event: any, data: LevelListInt) => {
      dispatch(setLevelsForm({ levelsForm: returnLevelCleanData(data) }))
      setOpenEdit(true)
    }

    const showDeleteModal = (event: any, data: LevelListInt) => {
      dispatch(setLevelsForm({ levelsForm: returnLevelCleanData(data) }))
      event.stopPropagation()
      setOpenDel(true)
    }

    const handlePostGrade = () => {
      postNewGrade(handleCancel)
    }

    

    
    if (uid.role !== 'Administrador') return null

    return (
      <Space>
        <button
          style={{ opacity: '0', position: 'absolute' }}
          id='closeAntdModal'
          onClick={handleCancel}
        ></button>
        <Tooltip title='Crear Grado'>
          <PlusCircleIcon
            className={`${style['iconStyle']} }`}
            onClick={(event) => showModal(event, data)}
          />
        </Tooltip>
        <Tooltip title='Editar Nivel'>
          <PencilSquareIcon
            className={`${style['iconStyle']} ${style['iconStyleFill']}`}
            onClick={(event) => showEditModal(event, data)}
          />
        </Tooltip>
        <Tooltip title='Eliminar Nivel'>
          <TrashIcon
            className={`${style['iconStyle']} ${style['red']}`}
            onClick={(event) => showDeleteModal(event, data)}
          />
        </Tooltip>

        <Modal
          title={`Crear nuevo grado`}
          open={open}
          onOk={postNewGrade}
          confirmLoading={loadingModal}
          onCancel={handleCancel}
          className={style.CreateGradeModal}
          footer={
            <div className={style.createGradeFooter}>
              <Button
                type='blue'
                rounded={true}
                disabled={disabledCreateGradeButton(gradesForm,schoolInfo,loadingModal)}
                onClick={handlePostGrade}
                label='Crear'
              />
            </div>
          }
          width={500}
        >
          <div className={style.createGradeBody}>
            <Form layout='vertical' autoComplete='off'>
              <Row gutter={[16, 0]}>
              <Col span={12}>
                  <Select
                    name={gradesForm.id_magento.name}
                    placeholder={gradesForm.id_magento.placeholder}
                    label={gradesForm.id_magento.label}
                    value={gradesForm.id_magento.value}
                    onChange={(e) =>
                      handleChangeSelectGrades(e, gradesForm.id_magento.name)
                    }
                    options={magentoGrades}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name={gradesForm.name.name}
                    placeholder={gradesForm.name.placeholder}
                    label={gradesForm.name.label}
                    type={gradesForm.name.type}
                    value={gradesForm.name.value}
                    onChange={handleChangeGrades}
                    onClick={(e: any) => e?.stopPropagation()}
                  />
                </Col>
                
                {gradesForm.male_population &&
                  (schoolInfo.sex === 'm' || schoolInfo.sex === 'u') && (
                    <Col span={12}>
                      <Input
                        name={gradesForm.male_population.name}
                        placeholder={gradesForm.male_population.placeholder}
                        label={gradesForm.male_population.label}
                        type={gradesForm.male_population.type}
                        value={gradesForm.male_population.value}
                        onChange={handleChangeGrades}
                        onClick={(e: any) => e?.stopPropagation()}
                      />
                    </Col>
                  )}

                {gradesForm.female_population &&
                  (schoolInfo.sex === 'f' || schoolInfo.sex === 'u') && (
                    <Col span={12}>
                      <Input
                        name={gradesForm.female_population.name}
                        placeholder={gradesForm.female_population.placeholder}
                        label={gradesForm.female_population.label}
                        type={gradesForm.female_population.type}
                        value={gradesForm.female_population.value}
                        onChange={handleChangeGrades}
                        onClick={(e: any) => e?.stopPropagation()}
                      />
                    </Col>
                  )}
              </Row>
            </Form>
          </div>
        </Modal>

        <Modal
          title='Editar nivel'
          open={OpenEdit}
          onOk={handleEditLevel}
          cancelButtonProps={{ id: `closeEditLevelButton-${data.id}` }}
          okButtonProps={{disabled:levelsForm?.id_magento?.value ===null || levelsForm?.id_magento?.value ===undefined}}
          confirmLoading={loadingModal}
          onCancel={handleCancel}
        >
          <Form layout='vertical' autoComplete='off'>
            {Object.entries(levelsForm).map(([key, value]) => {
              if (value.type === 'select')
                return (
                  <Select
                    key={key}
                    name={value.name}
                    placeholder={value.placeholder}
                    label={value.label}
                    value={value.value}
                    onChange={handleChangeSelectLevel}
                    options={magentoLevels}
                    disabled={value.disabled}
                  />
                )
              return (
                <Input
                  key={key}
                  name={value.name}
                  placeholder={value.placeholder}
                  label={value.label}
                  type={value.type}
                  value={value.value}
                  onChange={handleChangeLevel}
                  onClick={(e: any) => e?.stopPropagation()}
                />
              )
            })}
          </Form>
        </Modal>

        <Modal
          title='Eliminar Nivel'
          open={openDel}
          onOk={deleteLevelById}
          confirmLoading={loadingModal}
          onCancel={handleCancel}
        >
          Estas seguro que deseas eliminar el nivel '{data.name}'
        </Modal>
      </Space>
    )
  }
)

LevelActions.displayName = 'LevelActions'
export default LevelActions
