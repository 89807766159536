import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Options } from 'types'
import * as services from 'services/books/books'
import * as servicesSchool from 'services/schoolAdminServices/schools'
import {
  getUniformsPaginatedAdmin,
  UtilsInterface
} from 'services/uniformServices/uniform'
import {
  InitUtilsPagination,
  UtilsPagination
} from 'utilities/restructurePagination'
import { ColumnsType } from 'antd/es/table'
import Tag from 'components/tag'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import { useDispatch } from 'react-redux'
import { AUTHSERVICE } from 'services/config'
import { useForm } from 'antd/es/form/Form'
import { getLogs } from 'services/genericServices/genericServices'

interface level {
  id: number
  name: string
  grades: [
    {
      id: number
      name: string
    }
  ]
}

const statusMap: any = {
  Nuevo: 1,
  'En revisión': 2,
  'Con observaciones': 3,
  Disponible: 4,
  Autorizado: 5
}

const utilsColumns: ColumnsType<UtilsInterface> = [
  {
    title: 'Imagen',
    dataIndex: 'picture',
    key: 'picture',
    render: (_, record: any) => (
      <img
        src={record?.picture}
        style={{
          minWidth: 60,
          maxWidth: 60,
          minHeight: 60,
          maxHeight: 60
        }}
      />
    )
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Marca',
    dataIndex: 'make',
    key: 'make'
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'Presentación',
    dataIndex: 'presentation',
    key: 'presentation'
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    render: (_, { price, special_price }) => (
      <>
        {(() => {
          if (special_price) {
            const n_price = Number.parseFloat(special_price)
            if (!isNaN(n_price) && n_price > 0.0) return n_price.toFixed(2)
          }
          return price
        })()}
      </>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status',
    render: (_, record: any) => (
      <>
        {(() => {
          let status = record?.status
          if (!Object.keys(statusMap).includes(status)) {
            status = record?.status_name
          }
          return (
            <Tag
              variation={statusMap[status as string]}
              title={status as string}
            />
          )
        })()}
      </>
    )
  }
]

export function useManagementUniforms() {
  const [utilsList, setUtilsList] = useState<UtilsInterface[]>([])
  const [LoadingList, setLoadingList] = useState(true)
  const [pagination, setPagination] =
    useState<UtilsPagination>(InitUtilsPagination)
  const [schoolsCatalog, setSchoolCatalog] = useState<Options[]>([])
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
  const [selectSchool, setSelectSchool] = useState<string>('')
  const [selectStatus, setSelectStatus] = useState<string>('')
  const [selectedLevel, setSelectedLevel] = useState<level>()
  const [tabs, setTabs] = useState([])
  const [levels, setLevels] = useState([])
  const [loadingSchoolData, setLoadingSchoolData] = useState(false)
  const [params, setParams] = useSearchParams()
  const [loadingPackages, setLoadingPackages] = useState(false)
  const [packages, setPackages] = useState<Array<any>>([])
  const [selectedPackage, setSelectedPackage] = useState<number>(-1)
  const [supplies, setSupplies] = useState<Array<any>>([])
  const [loadingSupplies, setLoadingSupplies] = useState(false)
  const [sidebarFormFilled, setSidebarFormFilled] = useState(false)
  const [packagesMap, setPackagesMap] = useState<any>({})
  const [paginationMap, setPaginationMap] = useState<any>({})
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = useForm()

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      SchoolCatalog()
      StatusCatalog()

      if (!params.has('school')) {
        getUtilsList()
      }
    }
  }, [])

  useEffect(() => {
    if (params.has('action')) return

    const page = parseInt(params.get('page') || '1')
    const search = params.get('search') || ''
    const status = params.get('status')
    const controller = new AbortController()

    if (status) {
      setSelectStatus(status)
    } else {
      setSelectStatus('todos')
    }

    if (params.has('school')) {
      getSchoolData()
      if (schoolsCatalog && schoolsCatalog?.length > 0) {
        const school = schoolsCatalog.find(
          (it: any) => it.value == params.get('school')
        )
        if (school) {
          setSelectSchool(school?.value as string)
        }
      }
    } else {
      setSelectSchool('todos')
      getUtilsList(
        page,
        controller,
        search,
        status ? parseInt(status) : undefined
      )
    }

    return () => {
      controller.abort()
    }
  }, [params])

  useEffect(() => {
    getLevelPackages()
  }, [selectedLevel])


  const donwloadLogs = async () => {
    setLoadingDownload(true)
    try {
      const res: any = await getLogs('uniform')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = 'html-to-img.png'
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')

    }
    setLoadingDownload(false)
  }

  const getSchoolData = async () => {
    setLoadingSchoolData(true)

    try {
      // get levels
      let res = await AUTHSERVICE().get(
        `/level?idSchool=${params.get('school')}`
      )
      const { levels } = res?.data
      setLevels(levels)
      // set tab items
      const tabItems = levels.map((it: any) => ({
        label: it?.name,
        key: it?.id
      }))
      setTabs(tabItems)
      setSelectedLevel(levels[0])
    } catch (error) {}

    setLoadingSchoolData(false)
  }

  const getLevelPackages = async () => {
    setLoadingPackages(true)

    try {
      const res = await AUTHSERVICE().get(
        `/supply-package/level/${selectedLevel?.id}?idSchool=${params.get(
          'school'
        )}`
      )
      const { details } = res?.data
      setPackages(details)
    } catch (error) {}

    setLoadingPackages(false)
  }

  const getUtilsList = async (
    page: number = 1,
    controller?: AbortController,
    search = params.get('search') || undefined,
    status?: number
  ) => {
    setLoadingList(true)
    try {
      const result: any = await getUniformsPaginatedAdmin(
        page || 1,
        search,
        status
      )
      setUtilsList(result?.data?.details)
      const { total, page: currentPage, totalPages, limit } = result?.data
      setPagination({
        page: currentPage,
        total,
        pages: totalPages,
        limit
      })
    } catch (err: any) {}
    setLoadingList(false)
  }

  const onHandleChangeSchool = (value: string) => {
    if (value == 'todos') {
      setParams({})
      setSelectSchool('todos')
    } else {
      setParams({ school: value })
      setSelectSchool(value)
    }
  }

  const onHandleChangeStatus = (value: string) => {
    const paramsHasSchool = params.has('school')
    if (paramsHasSchool) {
      setSelectStatus(value)
    } else {
      const paramsObject: any = {}

      for (let key of params.keys()) {
        paramsObject[key] = params.get(key)
      }

      if (value == 'todos') {
        delete paramsObject['status']
        setSelectStatus('todos')
      } else {
        paramsObject['status'] = value
        if (params.has('page')) paramsObject['page'] = 1
        setSelectStatus(value)
      }

      setParams(paramsObject)
    }
  }

  const SchoolCatalog = async (): Promise<any> => {
    try {
      const res = await servicesSchool.getCatalogSchools()
      setSchoolCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.schools
      ])
      if (params.has('school')) {
        const school = res?.data?.schools?.find(
          (it: any) => it.value == params.get('school')
        )
        if (school) {
          setSelectSchool(school?.label)
        }
      } else {
        setSelectSchool('todos')
      }
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const StatusCatalog = async (): Promise<any> => {
    try {
      const res = await services.getCatalogStatus()
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])

      if (params.has('status')) {
        setSelectStatus(statusMap(params.get('status')))
      } else {
        setSelectStatus('todos')
      }
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const onHandleChangePage = (page: number): void => {
    const prev: any = {}
    for (let key of params.keys()) prev[key] = params.get(key)
    setParams({
      ...prev,
      page
    })
  }

  const onRowClicked = (record: any) => {
    dispatch(setopenComments(true))
    dispatch(setCommentsObjectInformation(record))
  }

  const onChangeSelectedTab = (value: any) => {
    const nLevel = levels.find((it: any) => it.id == value)
    setSelectedLevel(nLevel)
  }

  const handleCommentsSubmit = async (
    values: any,
    id: number,
    bookId: number,
    id_supply_school?: number
  ) => {
    const data = {
      id,
      //id_supply_school,
      ...values
    }

    try {
      await AUTHSERVICE().put(`/supply`, data)
      SuccessToast('La información del útil se actualizó correctamente')
      // reload the data
      if (params.has('school')) {
        updateSupplies(selectedPackage)
      } else {
        const page = parseInt(params.get('page') || '1')
        const search = params.get('search') || ''
        const status = params.get('status')
        const controller = new AbortController()
        getUtilsList(
          page,
          controller,
          search,
          status ? parseInt(status) : undefined
        )
      }
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtalo más tarde.')
    }
  }

  const onCollapseChange = async (flag: boolean, packageId: number) => {
    if (!flag) {
      setSelectedPackage(-1)
      return
    }

    if (!packagesMap[packageId]) {
      setLoadingSupplies(true)

      try {
        setSelectedPackage(packageId)
        const res = await AUTHSERVICE().get(
          `/supply-school/${packageId}?limit=1000`
        )
        const { success, details, ...pagData } = res?.data
        if (details) setSupplies(details)
        setPackagesMap((prev: any) => ({
          ...prev,
          [packageId]: details
        }))

        setPaginationMap((prev: any) => ({
          ...prev,
          [packageId]: {
            ...pagData
          }
        }))
      } catch (error) {}

      setLoadingSupplies(false)
    }
  }

  const updateSupplies = async (packageId: number) => {
    setLoadingSupplies(true)

    try {
      const res = await AUTHSERVICE().get(
        `/supply-school/${packageId}?limit=1000`
      )
      const { success, details, ...pagData } = res?.data
      if (details) setSupplies(details)
      setPackagesMap((prev: any) => ({
        ...prev,
        [packageId]: details
      }))

      setPaginationMap((prev: any) => ({
        ...prev,
        [packageId]: {
          ...pagData
        }
      }))
    } catch (error) {}

    setLoadingSupplies(false)
  }

  const downloadClicked = async () => {
    setLoadingDownload(true)

    try {
      let url = '/supply/download'
      if (selectSchool && selectSchool != 'todos') {
        url += `?idSchool=${selectSchool}`
      }
      const res: any = await AUTHSERVICE().get(url)
      const { url: fileUrl } = res?.data

      if (fileUrl) {
        window.open(fileUrl, '__blank')
      }
    } catch (error) {}

    setLoadingDownload(false)
  }

  return {
    params,
    schoolsCatalog,
    statusCatalog,
    selectSchool,
    selectStatus,
    utilsList,
    LoadingList,
    pagination,
    utilsColumns,
    tabs,
    selectedLevel,
    packages,
    form,
    supplies,
    loadingSupplies,
    sidebarFormFilled,
    statusMap,
    packagesMap,
    selectedPackage,
    paginationMap,
    loadingDownload,
    navigate,
    setParams,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onRowClicked,
    onChangeSelectedTab,
    handleCommentsSubmit,
    onCollapseChange,
    setSidebarFormFilled,
    updateSupplies,
    getUtilsList,
    setSelectedPackage,
    setPaginationMap,
    downloadClicked,
    donwloadLogs
  }
}
