import { useEffect, useState } from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import {
  getSellsByArticle,
} from 'services/sells/sells'
import formatNumber from 'utilities/numberFormater'
import { useGetInitialParams } from './useGetInitialParams'

export default function usePieChart() {
  const [loading, setloading] = useState(true);
  const { apliedFilters, loadingFilter } = useSelector((state: RootState) => state.sells)
  const { schoolIDGea } = useGetInitialParams()

  const [articlePie, setArticlePie] = useState({})
  const [articleLeyendPie, setArticleLeyendPie] = useState({})

  const parsedPieLeyendData = (PieObject: any) => {
    let newObject = { ...PieObject }
    const TOTAL = Object.entries(PieObject)
      .reduce(
        (acc: number, [key, values]: any) => acc + parseFloat(values.total),
        0
      )
      .toFixed(2)
    Object.entries(PieObject).forEach(([key, values]: any) => {
      const percentage = (parseFloat(values.total) * 100) / parseFloat(TOTAL)
      newObject[key] = {
        ...newObject[key],
        percentage: `${percentage.toFixed(2)} %`
      }
    })
    setArticleLeyendPie(newObject)
  }

  const parsedSellByArticle = (sells: any) => {
    let ParsedObject: any = {}
    let PieObject = {}
    Object.entries(sells).forEach(([key, value]: any) => {
      const newSellsArray = value.ventas.map((res: any) => {
        return {
          x: res.Fecha,
          y: parseFloat(res.Total) ,
          label: `${res.Fecha}: $ ${formatNumber(res.Total)}`
        }
      })
      ParsedObject = { ...ParsedObject, [key]: newSellsArray }
      PieObject = {
        ...PieObject,
        [key]: {
          total: newSellsArray
            .reduce((acc: number, cur: any) => acc + cur.y, 0)
            .toFixed(2),
          label: key
        }
      }
    })

    parsedPieLeyendData(PieObject)
    setArticlePie(PieObject)
  }

  const getArticleSellsInfo = async () => {
    !loading && setloading(true)
    try {
      const sellsByArticle: any = await getSellsByArticle({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      parsedSellByArticle(sellsByArticle.data.data)
    } catch (error) {
      console.log(error)
    }finally{
      setloading(false)
    }
  }

  useEffect(() => {
    !loadingFilter && getArticleSellsInfo()
  }, [apliedFilters, loadingFilter])

  return { articlePie, articleLeyendPie, loading }
}
