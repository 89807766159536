import { Col, Collapse, Table, Tabs, Tag, Tooltip } from 'antd'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { memo, useState } from 'react'
import styles from './tabsAdmin.module.scss'
import { BooksTable } from 'interfaces/books-interface'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import B from 'components/texts/B/B'
import { RootState } from 'redux/store/store'
import * as services from 'services/books/books'
import {
  setBooksSchoolAdmin,
  setDataCollege,
  setIdBookAdmin,
  setIdBookSchoolAdmin,
  updateRequestChanges
} from 'redux/reducers/books'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import Modals from 'components/modal'
import globalStyle from 'styles/global.module.scss'
import ErrorAlert from 'assets/img/errorAlert.svg'
import Button from 'components/buttons/Button/Button'

interface ITabsAdmin {
  selectStatus: string | number
  searchGralBook: string
  bookColumns: ColumnsType<BooksTable>
  idSchool: any
  idLevel: any
  idGrade: any
  setIdLevel: any
  setIdGrade: any
}

const TabsAdminModule = memo(
  ({
    selectStatus,
    searchGralBook,
    idSchool,
    idLevel,
    bookColumns,
    setIdLevel,
    setIdGrade
  }: ITabsAdmin) => {
    const { Panel } = Collapse

    const { booksSchoolAdmin, levelsBooksAdmin } = useSelector(
      (state: RootState) => state.books
    )

    const columns = booksSchoolAdmin?.[0] && Object.keys(booksSchoolAdmin?.[0])

    const [loading, setLoading] = useState<boolean>(false)
    const [modalChanges, setModalChanges] = useState<boolean>(false)
    const [IDGrade, setIDgrade] = useState<number>(0)

    const dispatch = useDispatch()

    const handleChangeLevelTab = (key: string) => {
      setIdLevel(key)
    }

    const handleCollapseBooks = async (idGrade: number): Promise<void> => {
      setLoading(true)

      try {
        const response = await services.getSchoolBooks(
          idSchool,
          idLevel,
          idGrade
        )

        const saveResponse = response?.data?.books.map((book: any) => {
          return {
            ...book,
            idGrade
          }
        })

        dispatch(setBooksSchoolAdmin(saveResponse))
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
    }

    const handleOpenModalChanges = (idGrade: number) => {
      setModalChanges(true)
      setIDgrade(idGrade)
    }
    const handleCancelModalChanges = () => {
      setModalChanges(false)
    }

    const negateRequestChanges = async (): Promise<void> => {
      setLoading(true)
      try {
        await services.requestChangesBooks({
          id_grade: IDGrade,
          id_school: idSchool,
          request_changes: false,
          status: 'denied'
        })

        dispatch(updateRequestChanges({ idGrade: IDGrade, status: false }))
        SuccessToast('No fue aceptada la petición de cambio.')
        setModalChanges(false)
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
    }
    const aceptRequestChanges = async (): Promise<void> => {
      setLoading(true)
      try {
        await services.endListBooks({ id_grade: IDGrade, close: false })
        await services.requestChangesBooks({
          id_grade: IDGrade,
          id_school: idSchool,
          request_changes: false,
          status: 'accepted'
        })

        dispatch(updateRequestChanges({ idGrade: IDGrade, status: false }))

        SuccessToast('Fue aceptada la petición de cambio.')
        setModalChanges(false)
      } catch (error) {
        ErrorToast('Ups Algo salio mal')
      } finally {
        setLoading(false)
      }
    }

    const onChange = (key: string | string[]) => {
      key.length > 0 && handleCollapseBooks(+key.slice(-1))
      key.length > 0 && setIdGrade(+key.slice(-1))
    }
    return (
      <>
        <Col span={24}>
          <Tabs
            items={levelsBooksAdmin}
            defaultActiveKey={idLevel}
            activeKey={idLevel}
            onChange={handleChangeLevelTab}
          />
        </Col>

        <Col span={24}>
          {levelsBooksAdmin
            ?.find((level: any) => level.key === idLevel)
            ?.grades?.map((res: any) => {
              const total = booksSchoolAdmin
                ?.filter(
                  (item: any) => item.idGrade === res.id && item.status > 1
                )
                ?.reduce(
                  (
                    acc: number,
                    cv: {
                      special_price: string | number
                      price: string | number
                    }
                  ) => {
                    let total = cv.price
                    if (
                      cv.special_price !== '0.00' &&
                      cv.special_price !== null
                    )
                      total = cv.special_price
                    return acc + +total
                  },
                  0
                )
              return (
                <Collapse key={res.id} onChange={onChange} bordered={false}>
                  <Panel
                    className={styles.collapsePanel}
                    header={
                      <>
                        <B>
                          {res?.name}{' '}
                          {total ? (
                            <b style={{ color: '#0F48FF' }}>
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2
                              }).format(total)}
                            </b>
                          ) : (
                            ''
                          )}
                        </B>
                      </>
                    }
                    key={res.id}
                    extra={
                      res.listBooksChange && (
                        <>
                          <Tooltip title='Cambios solicitados'>
                            <Tag
                              onClick={(e: any) => {
                                e.stopPropagation()
                                handleOpenModalChanges(res.id)
                              }}
                              className={styles.tag}
                            >
                              <small
                                className={styles.status}
                                data-variation={8}
                              >
                                Cambios solicitados
                              </small>
                            </Tag>
                          </Tooltip>
                        </>
                      )
                    }
                  >
                    {booksSchoolAdmin
                      .filter(
                        (item: { idGrade: any; status: any }) =>
                          item.idGrade === res.id && item.status !== 1
                      )
                      ?.filter((item: any) =>
                        item.status.toString().includes(selectStatus)
                      )
                      ?.filter(
                        (item: any) =>
                          item.name
                            .toString()
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace('‘', '')
                            .includes(
                              searchGralBook
                                .trim()
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .replace("'", '')
                            ) ||
                          item.isbn
                            .toString()
                            .toLowerCase()
                            .includes(searchGralBook.trim().toLowerCase())
                      )?.length > 0 ? (
                      <>
                        <Table
                          dataSource={booksSchoolAdmin
                            .filter(
                              (item: { idGrade: any; status: any }) =>
                                item.idGrade === res.id && item.status !== 1
                            )
                            ?.filter((item: any) =>
                              item.status.toString().includes(selectStatus)
                            )
                            ?.filter(
                              (item: any) =>
                                item.name
                                  .toString()
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .replace('‘', '')
                                  .includes(
                                    searchGralBook
                                      .trim()
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .replace("'", '')
                                  ) ||
                                item.isbn
                                  .toString()
                                  .toLowerCase()
                                  .includes(searchGralBook.trim().toLowerCase())
                            )}
                          loading={loading}
                          columns={bookColumns}
                          scroll={{ x: 800 }}
                          rowKey={(row) => row.id}
                          className={styles.booksTable}
                          onRow={(record, _) => {
                            return {
                              onClick: (_) => {
                                dispatch(
                                  setopenComments({ openComments: true })
                                )
                                dispatch(setCommentsObjectInformation(record))
                                setIdGrade(record?.idGrade as number)
                                dispatch(setIdBookAdmin(record?.id_book))
                                dispatch(setIdBookSchoolAdmin(record?.id))
                                dispatch(
                                  setDataCollege({
                                    idSchool: record?.id_school,
                                    idLevel: record?.id_level,
                                    idGrade: record?.id_grade
                                  })
                                )
                              }
                            }
                          }}
                        />
                      </>
                    ) : (
                      <EmptyListMessage
                        iconHeight={40}
                        iconWidth={40}
                        icon='book'
                        text='Sin Libros'
                      />
                    )}
                  </Panel>
                </Collapse>
              )
            })}
        </Col>

        <Modals
          title='Solicitud de cambio'
          open={modalChanges}
          onCancel={handleCancelModalChanges}
        >
          <div className={globalStyle.confirmModal}>
            <div className={globalStyle.confirmModalBody}>
              <img src={ErrorAlert} alt='lista alerta' />
              <B size='Light'>
                ¿Estás seguro que deseas permitir la modificación de la lista?,
                Al hacer esto, el estatus de la lista pasará a "En Construcción"
                y el usuario podra hacer cambios sobre la misma.
              </B>
            </div>
            <br></br>

            <div className={globalStyle.confirmModalFooter}>
              <Button
                rounded
                onClick={negateRequestChanges}
                label={'No'}
                sector='secondary'
                isLoading={loading}
              />
              <Button
                isLoading={loading}
                rounded
                onClick={aceptRequestChanges}
                label={'Si'}
              />
            </div>
          </div>
        </Modals>
      </>
    )
  }
)

export default TabsAdminModule
