import React from 'react'

const SacoMasculinoSvg= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Espalda"   d="m509.95,763.84l-207.96.44s10.11-689.9,30.62-711.89c20.5-21.98,117.42-25,138.41-.75,18.86,21.78,38.92,712.2,38.92,712.2Z"/>
      <line id="Division_medio" data-name="Division medio"   x1="401.73" y1="75.1" x2="401.73" y2="764.16"/>
      <path id="Saco_izquierda" data-name="Saco izquierda"   d="m310.57,73.63c-53.65,27.15-92.05,32.12-137.03,49.33,0,0,25.25,81.76,40.69,178.08,11.64,72.56,16,156.21,9.37,203.62-1.56,11.2-3.07,22.71-4.51,34.34-12.7,102.86-14.36,215.34-14.36,215.34,0,0,129.28,21.87,151.48,3.98,22.19-17.9,77.17-217.69,66.56-286.28-4.39-28.4-28.53-98.83-50.76-175.11-31.48-107.94-61.44-223.3-61.44-223.3Z"/>
      <path id="Saco_derecha" data-name="Saco derecha"   d="m489.55,71.74c52.99,27.15,89.32,33.44,133.75,50.66,0,0-24.95,81.76-40.19,178.08-11.5,72.56-15.81,156.21-9.25,203.62,1.72,12.45,3.36,25.26,4.92,38.21,12.25,101.79,13.15,211.96,13.15,211.96,0,0-127.13,21.38-149.05,3.5-10.01-8.18-26.79-54.35-41.06-108.62-17.27-65.68-23.11-192.73-18.94-208.75,2.53-7.94,13.93-27.2,20.02-47.3,8.85-29.24,22.76-61.86,32.95-97.22,28.66-81.37,53.71-224.13,53.71-224.13Z"/>
      <path id="Manha_derecha" data-name="Manha derecha"   d="m622.99,122.21s16.19-2.78,26.45,53.7c10.26,56.48,18.15,165.39,21.3,257.37,3.16,91.98,11.84,301.74,11.84,301.74-31.14-2.36-56.87-3.32-86.08,1.45,0,0-4.65-153.13-9.38-220.09-4.73-66.97-10.25-182.81-5.72-233.24,2.07-22.99,7.37-51.28,14.03-77.72,7.94-31.58,17.69-60.43,27.56-83.21Z"/>
      <path id="Manga_izquierda" data-name="Manga izquierda"   d="m177.07,122.2s-16.26-2.77-26.51,53.71c-10.26,56.48-18.15,165.39-21.31,257.37-3.15,91.98-11.83,301.74-11.83,301.74,31.14-2.36,54.47-2.34,83.67,2.42,0,0,7.05-154.1,11.79-221.06,4.73-66.97,10.25-182.81,5.71-233.24-2.07-22.99-7.37-51.28-14.03-77.72-7.95-31.58-17.63-60.45-27.5-83.22Z"/>
      <path id="Bolsa_derecha" data-name="Bolsa derecha"   d="m506.38,568.71c28.73,3.95,61.34,7.77,77.9,13.54l-5.81-43.6c-17.95-6.12-42.99-10.35-72.09-13.75v43.8Z"/>
      <path id="Bolsa_izquierda" data-name="Bolsa izquierda"   d="m293.5,568.71c-28.72,3.95-64.44,8.02-81,13.79l6-43c17.96-6.12,45.91-11.19,75-14.59v43.8Z"/>
      <path id="Abertura"   d="m517.37,382.49c-4.12,71.95-2.22,71.94-.25,143.89"/>
      <path id="Abertura-2" data-name="Abertura"   d="m283.63,378.49c3.95,71.95,1.28,75.94-.86,147.88"/>
      <path id="Solapa_izquierda" data-name="Solapa izquierda"   d="m334.5,137.5s17.53,91.12,27.52,122.49c10.59,33.26,40.63,127.49,40.63,127.49l3.33,19.07s-32.58-37.67-39.33-44.84c-32.27-34.23-70.43-109.22-88.23-159.11l-8.07-26.99,34.16-19.12,30-19Z"/>
      <g id="Solapa">
        <path id="Cuello_interior" data-name="Cuello interior"   d="m339.62,84.04c39.09-12.29,78.8-13.26,125.46-.07"/>
        <path id="Cuello_izquierda" data-name="Cuello izquierda"   d="m335.13,139.1l-33.34,18.86-33.5-18.86s13.58-51.45,18.41-55.72c11.71-10.36,46.01-30.87,49.81-33.88.16-.13-2.59,37.72-2.59,37.72l1.22,51.88Z"/>
        <path id="Cuello_derecha" data-name="Cuello derecha"   d="m471.38,139.21l33.34,18.86,33.5-18.86s-13.58-51.45-18.41-55.72c-11.71-10.36-47.76-29.08-51.3-34.22.39.57.59,15.38,1.02,18.43,3.38,23.97,2.41,47.37,1.84,71.5Z"/>
      </g>
      <path id="Solapa_derecha" data-name="Solapa derecha"   d="m471.18,138.75s-13.65,86.65-23.25,118.18c-10.17,33.42-42.98,129.22-42.98,129.22l-22.08,54.24s54.49-68.25,60.98-75.45c31-34.39,64.96-113.6,82.07-163.72l7.75-27.12-62.49-35.35Z"/>
      <g id="Botones">
        <line   x1="417.35" y1="532.32" x2="389.3" y2="532.32"/>
        <g>
          <path   d="m406.97,532.23c0,4.55-3.69,8.24-8.24,8.24s-8.24-3.69-8.24-8.24,3.69-8.24,8.24-8.24,8.24,3.69,8.24,8.24Z"/>
          <g>
            <line   x1="398.78" y1="523.99" x2="398.73" y2="540.65"/>
            <g>
              <polygon   points="398.78 523.99 398.97 524.09 398.59 524.09 398.78 523.99"/>
              <polygon   points="399.31 524.29 399.64 524.49 397.92 524.48 398.25 524.29 399.31 524.29"/>
              <polygon   points="399.93 524.69 400.22 524.89 397.34 524.88 397.62 524.68 399.93 524.69"/>
              <path   d="m400.5,525.09c.1.07.19.13.27.2h-3.99c.08-.08.18-.14.27-.21h3.45Z"/>
              <path   d="m401.02,525.48c.08.07.17.13.24.2h-4.98c.07-.08.16-.15.24-.21h4.49Z"/>
              <polygon   points="401.49 525.88 401.71 526.08 395.84 526.06 396.06 525.87 401.49 525.88"/>
              <polygon   points="401.91 526.28 402.11 526.48 395.43 526.46 395.63 526.26 401.91 526.28"/>
              <path   d="m402.31,526.68c.06.07.12.13.17.2l-7.42-.02c.06-.07.11-.13.18-.2l7.07.02Z"/>
              <polygon   points="402.65 527.08 402.83 527.27 394.71 527.25 394.89 527.05 402.65 527.08"/>
              <polygon   points="402.98 527.47 403.12 527.67 394.41 527.65 394.56 527.45 402.98 527.47"/>
              <path   d="m403.27,527.87c.05.07.1.13.14.2l-9.29-.03c.04-.07.09-.13.14-.2l9,.03Z"/>
              <path   d="m403.54,528.27c.04.07.08.13.12.2l-9.78-.03c.04-.07.08-.13.12-.2l9.54.03Z"/>
              <path   d="m403.77,528.66c.04.07.07.13.11.2l-10.23-.03c.03-.07.07-.13.11-.2l10.01.03Z"/>
              <path   d="m403.98,529.06c.03.07.07.13.09.2l-10.62-.03c.03-.07.06-.13.09-.2l10.43.03Z"/>
              <path   d="m404.16,529.46c.03.07.06.13.08.2l-10.96-.03c.02-.07.05-.13.08-.2l10.79.03Z"/>
              <path   d="m404.31,529.86c.02.07.05.13.07.2l-11.25-.03c.02-.07.05-.13.07-.2l11.1.03Z"/>
              <polygon   points="404.44 530.25 404.5 530.45 393.02 530.42 393.08 530.22 404.44 530.25"/>
              <polygon   points="404.55 530.65 404.6 530.85 392.92 530.81 392.97 530.61 404.55 530.65"/>
              <polygon   points="404.64 531.05 404.67 531.24 392.85 531.21 392.88 531.01 404.64 531.05"/>
              <polygon   points="404.7 531.44 404.72 531.64 392.8 531.61 392.82 531.41 404.7 531.44"/>
              <polygon   points="404.74 531.84 404.74 532.04 392.77 532 392.78 531.8 404.74 531.84"/>
              <path   d="m404.75,532.24c0,.07,0,.13,0,.2l-11.99-.04c0-.07,0-.13,0-.2l11.99.04Z"/>
              <polygon   points="404.74 532.63 404.74 532.83 392.77 532.8 392.76 532.6 404.74 532.63"/>
              <polygon   points="404.72 533.03 404.7 533.23 392.81 533.19 392.79 532.99 404.72 533.03"/>
              <polygon   points="404.67 533.43 404.64 533.62 392.87 533.59 392.83 533.39 404.67 533.43"/>
              <polygon   points="404.6 533.82 404.55 534.02 392.95 533.99 392.9 533.79 404.6 533.82"/>
              <path   d="m404.5,534.22c-.01.07-.04.13-.06.2l-11.39-.03c-.02-.07-.04-.13-.06-.2l11.51.03Z"/>
              <path   d="m404.38,534.61c-.02.07-.04.13-.07.2l-11.13-.03c-.03-.07-.05-.13-.07-.2l11.27.03Z"/>
              <path   d="m404.24,535.01c-.02.07-.05.13-.08.2l-10.82-.03c-.03-.07-.05-.13-.08-.2l10.98.03Z"/>
              <path   d="m404.07,535.41c-.03.07-.06.13-.09.2l-10.46-.03c-.04-.07-.06-.13-.09-.2l10.65.03Z"/>
              <path   d="m403.87,535.8c-.03.07-.07.13-.11.2l-10.05-.03c-.04-.07-.07-.13-.11-.2l10.26.03Z"/>
              <path   d="m403.65,536.2c-.04.07-.08.13-.12.2l-9.58-.03c-.04-.07-.08-.13-.12-.2l9.82.03Z"/>
              <path   d="m403.4,536.59c-.04.07-.09.13-.14.2l-9.04-.03c-.05-.07-.1-.13-.14-.2l9.32.03Z"/>
              <polygon   points="403.11 536.99 402.97 537.19 394.51 537.16 394.37 536.96 403.11 536.99"/>
              <polygon   points="402.81 537.39 402.64 537.58 394.84 537.56 394.67 537.36 402.81 537.39"/>
              <polygon   points="402.47 537.78 402.29 537.98 395.19 537.96 395.01 537.76 402.47 537.78"/>
              <polygon   points="402.09 538.18 401.89 538.37 395.58 538.36 395.38 538.16 402.09 538.18"/>
              <polygon   points="401.69 538.57 401.46 538.77 396.01 538.75 395.79 538.55 401.69 538.57"/>
              <path   d="m401.23,538.97c-.07.07-.16.13-.24.2h-4.51c-.08-.08-.17-.15-.24-.21l5,.02Z"/>
              <path   d="m400.74,539.36c-.08.07-.17.13-.27.2h-3.47c-.1-.08-.19-.14-.27-.21h4.01Z"/>
              <polygon   points="400.18 539.76 399.9 539.95 397.57 539.95 397.28 539.75 400.18 539.76"/>
              <polygon   points="399.6 540.15 399.26 540.35 398.2 540.35 397.86 540.15 399.6 540.15"/>
              <polygon   points="398.92 540.55 398.73 540.65 398.54 540.54 398.92 540.55"/>
            </g>
          </g>
          <path   d="m406.97,532.23c0,4.55-3.69,8.24-8.24,8.24s-8.24-3.69-8.24-8.24,3.69-8.24,8.24-8.24,8.24,3.69,8.24,8.24Z"/>
          <g>
            <path   d="m393.45,535.78l-.55-.8-.33.08c-.21.06-.34.1-.38.13-.02.02-.03.03-.04.05,0,.02,0,.05.03.11l.07.15-.1.07c-.05-.09-.11-.2-.19-.31-.09-.13-.18-.25-.26-.35l.1-.07.03.04c.04.06.08.1.11.11.02,0,.07,0,.14,0,.2-.04.42-.09.64-.14l1.27-.31c.34-.08.58-.15.72-.19l.06.09-1.41,2.02c-.09.13-.15.21-.17.25-.01.03-.02.07-.02.09,0,.03.03.08.07.15l-.1.07c-.07-.12-.15-.24-.23-.35-.06-.1-.16-.23-.29-.4l.1-.07.11.13s.06.06.07.07c.02,0,.04,0,.06,0,.02-.02.06-.06.12-.14l.34-.47Zm-.38-.84l.48.7.72-1.02-1.21.32Z"/>
            <path   d="m394.52,537.73c.06.06.12.12.18.18.11.11.21.2.28.26.06.04.12.08.18.11l.32-.22.06.06c-.16.13-.31.26-.44.38-.07-.04-.15-.12-.25-.21l-.44-.45c-.06-.06-.16-.14-.3-.26l.06-.06c.08.02.14.02.18,0,.03-.02.16-.14.37-.35l.62-.64c.23-.23.36-.37.38-.4.02-.04,0-.07-.04-.12l-.11-.1.08-.07c.14.15.24.25.29.3.07.06.16.15.28.25l-.07.07-.11-.12s-.07-.04-.1-.02c-.03.02-.13.11-.3.28l-.73.74-.32.33-.07.07Z"/>
            <path   d="m397.89,537.29c-.07.15-.13.31-.19.48l-.32.87c-.12.33-.18.51-.18.54,0,.04.04.07.12.1l.09.03-.03.09c-.14-.06-.26-.11-.37-.15-.1-.04-.23-.08-.37-.12l.03-.1.1.04c.06.02.09.02.12.02.02,0,.05-.04.07-.09.05-.12.12-.27.18-.46l.33-.87c.06-.17.12-.33.17-.48l-.13-.05c-.2-.07-.32-.11-.34-.1-.02,0-.05.04-.09.12l-.09.2-.08-.03.07-.18c.02-.07.06-.18.1-.32.45.17.71.28.78.31l.69.24c-.08.16-.15.33-.21.49l-.08-.03.07-.2c.02-.07.02-.11.02-.13,0-.02-.02-.03-.05-.04-.09-.04-.19-.08-.3-.13l-.1-.04Z"/>
            <path   d="m399.66,539.6h.08c.08-.02.13-.05.13-.1.01-.05-.02-.28-.06-.7l-.08-.55c-.06-.43-.1-.66-.12-.71-.02-.04-.06-.06-.13-.05l-.1.02-.02-.1c.14-.01.29-.03.43-.05.11-.02.22-.03.32-.06v.1s-.09.02-.09.02c-.06,0-.1.02-.11.04-.01.02-.02.07-.01.14.01.11.04.32.08.62l.08.56c.05.42.09.65.12.69.02.04.07.05.15.05h.09s0,.09,0,.09c-.11,0-.23.02-.35.04-.14.02-.28.04-.39.07l-.02-.1Z"/>
            <path   d="m400.72,537.32l-.06-.09c.15-.09.26-.17.33-.22.07-.05.17-.13.3-.23l.06.08-.08.06c-.06.05-.09.09-.08.14,0,.04.1.19.29.44l.37.52c.14.19.24.32.31.38.06.06.16.09.26.09s.22-.04.34-.12c.12-.08.2-.19.25-.3.03-.08.03-.17,0-.27-.03-.1-.12-.26-.29-.49l-.36-.5c-.16-.23-.27-.35-.31-.38-.04-.02-.09,0-.17.05l-.06.04-.06-.08c.15-.1.25-.17.29-.19.06-.05.15-.11.24-.18l.06.08-.07.05c-.06.04-.08.08-.06.14,0,.05.09.18.26.41l.36.5c.16.23.26.39.3.48.04.09.06.19.05.29,0,.1-.03.19-.08.29-.05.09-.13.18-.24.26-.11.08-.22.13-.33.16-.11.03-.2.03-.28.02s-.15-.06-.22-.11c-.07-.05-.17-.17-.29-.34l-.18-.25-.29-.4c-.18-.25-.28-.39-.32-.41-.03-.02-.08,0-.14.04l-.07.06Z"/>
            <path   d="m403.93,534.85l-.05.08c-.12-.06-.22-.1-.29-.11-.06-.01-.13,0-.19.02-.06.02-.11.06-.14.12-.06.08-.07.16-.04.25.03.08.08.16.16.22.08.05.15.08.23.07.08,0,.22-.04.4-.13.19-.09.35-.13.47-.12.13.01.25.05.36.13.1.07.18.15.25.25.06.1.09.21.09.32,0,.12-.03.22-.1.31-.04.06-.09.12-.16.17-.06.05-.14.11-.23.17-.12-.1-.28-.22-.49-.36l.05-.08c.15.07.26.11.33.12.07.02.14.01.2-.01.07-.02.12-.07.16-.13.05-.08.07-.17.05-.27-.02-.1-.08-.18-.19-.26-.08-.06-.17-.09-.27-.09-.1,0-.24.04-.42.13-.18.09-.33.13-.44.13-.11,0-.22-.04-.32-.11-.09-.06-.17-.14-.22-.24-.05-.1-.08-.2-.08-.31,0-.11.03-.2.09-.29.07-.1.18-.2.34-.29.12.09.2.14.23.16.05.04.13.09.22.15Z"/>
            <path   d="m392.63,528.86l-.05.1c-.16-.05-.29-.08-.37-.08-.08,0-.16.01-.23.05-.06.04-.12.1-.16.17-.05.1-.05.2,0,.3.05.1.13.18.24.23.1.05.2.07.29.05.1-.01.25-.09.46-.23.2-.14.38-.21.54-.22.15-.01.3.02.46.09.13.07.25.16.34.26.09.11.15.23.17.37.02.13,0,.27-.06.39-.04.08-.09.16-.15.23-.06.07-.14.15-.26.24-.16-.09-.38-.2-.65-.34l.05-.1c.19.06.33.09.42.09.08,0,.16-.02.24-.05.07-.04.13-.1.17-.17.05-.11.05-.21.01-.33-.04-.12-.13-.21-.28-.28-.11-.06-.23-.08-.35-.06-.11,0-.27.08-.48.22-.2.14-.37.21-.5.23-.13.01-.26-.01-.4-.08-.12-.06-.23-.14-.31-.25-.09-.11-.14-.22-.15-.36-.02-.13,0-.25.05-.36.06-.13.18-.27.35-.41.17.09.27.14.31.16.07.03.17.08.3.14Z"/>
            <path   d="m394.56,527.72l.21-.2c.08-.08.13-.13.14-.15,0-.02,0-.04,0-.07,0-.03-.04-.09-.12-.19,0,0-.02-.02-.03-.05l.05-.05c.12.13.21.23.28.3.07.07.17.16.3.28l-.06.06c-.13-.09-.2-.15-.22-.16-.02-.01-.04-.01-.07,0-.02,0-.07.04-.14.11l-.24.23.67.67.37-.36.03-.03s.08-.08.08-.1c.02-.03.02-.07.01-.11,0-.04-.04-.14-.1-.29,0-.02-.02-.04-.02-.07l.06-.06s.13.21.32.54c-.22.2-.42.38-.59.56-.12.12-.26.25-.39.41l-.07-.07.1-.18s.01-.05.01-.07c0-.02-.03-.06-.07-.1l-.02-.02-.26-.28c-.05-.05-.15-.16-.3-.31-.22-.22-.38-.39-.48-.49-.1-.1-.16-.16-.18-.17-.02,0-.03,0-.04,0-.02,0-.05.02-.11.05l-.12.06-.06-.07c.11-.1.25-.22.4-.37.08-.08.27-.28.58-.6.13.13.27.26.41.38l-.06.06c-.13-.08-.22-.13-.26-.15-.03-.02-.06-.02-.08-.01-.03,0-.07.04-.14.11l-.35.35.36.38.22.23Z"/>
            <path   d="m398.19,525.18l-.25,1.06c-.09.39-.16.74-.22,1.03h-.05c-.04-.08-.17-.32-.38-.69l-.64-1.13.17,1.25c.04.29.07.46.1.49.02.04.08.05.18.03h.05s.02.1.02.1c-.12,0-.22.02-.3.03-.05,0-.17.02-.33.06l-.02-.1h.08c.06-.02.1-.03.11-.06.01-.02.02-.06.02-.11,0-.15-.03-.32-.05-.49l-.13-.92c-.03-.22-.05-.37-.07-.44,0-.03-.03-.06-.05-.08-.03-.02-.07-.02-.14,0h-.06s-.02-.09-.02-.09c.16-.01.25-.02.29-.02.05,0,.12-.02.21-.04.15.27.31.57.5.9l.47.83.2-.85c.07-.3.15-.66.24-1.08.11,0,.19-.01.23-.02.05,0,.14-.02.28-.05v.1s-.07.01-.07.01c-.07,0-.12.03-.13.07-.01.03,0,.15.02.36l.17,1.18c.03.26.06.41.08.44.02.04.08.05.16.04h.07s.01.09.01.09c-.12,0-.23.02-.35.04-.12.01-.25.04-.38.06v-.1s.07-.02.07-.02c.07,0,.11-.03.12-.07,0-.04,0-.2-.05-.48l-.18-1.31Z"/>
            <path   d="m401.03,525.48c-.07.13-.15.28-.23.44l-.42.83c-.15.29-.22.46-.22.5,0,.04.03.08.11.12l.08.04-.04.09c-.14-.08-.24-.14-.32-.18-.07-.03-.2-.09-.38-.18l.05-.09.1.06s.09.03.11.02c.02,0,.05-.03.07-.08.03-.04.1-.18.23-.41l.39-.77.15-.3c.06-.13.09-.21.08-.25,0-.04-.05-.07-.13-.12l-.05-.03.04-.09c.1.05.22.12.37.2.09.04.19.09.3.14.08.03.13.06.17.08.19.1.3.21.35.34.04.13.03.27-.05.41-.05.11-.13.19-.23.27-.1.07-.21.11-.33.13-.12.01-.23,0-.34-.06-.05-.02-.1-.06-.16-.12l.03-.12c.06.05.11.1.16.12.11.05.21.06.32,0,.1-.05.19-.14.26-.28.07-.14.09-.26.06-.37-.03-.11-.11-.2-.25-.27-.08-.04-.17-.06-.28-.07Z"/>
            <path   d="m402.53,527.3l.22.19c.09.07.14.11.16.12.02,0,.04,0,.07,0,.02-.01.08-.05.18-.13,0,0,.02-.02.04-.03l.06.05c-.12.13-.22.23-.28.31-.06.07-.14.18-.25.32l-.06-.05c.09-.14.13-.21.14-.23.01-.02.01-.05,0-.07-.01-.02-.05-.06-.13-.13l-.25-.22-.62.73.39.33.03.03c.06.04.09.07.11.07.03,0,.07.01.11,0,.04,0,.13-.05.28-.13.02,0,.04-.02.07-.03l.06.05s-.2.14-.52.37c-.22-.2-.42-.38-.6-.54-.13-.11-.28-.23-.44-.35l.06-.07.19.08s.05.02.06.01c.02,0,.06-.03.1-.08l.02-.02.25-.29c.05-.06.14-.17.28-.33.2-.24.35-.41.45-.52.09-.11.14-.18.15-.2,0-.01,0-.02,0-.04,0-.02-.02-.05-.06-.1l-.07-.12.06-.07c.11.11.24.23.4.37.09.08.3.25.65.52-.12.15-.23.29-.34.45l-.06-.05c.07-.13.12-.23.13-.28,0-.03,0-.06,0-.08-.01-.03-.05-.07-.12-.13l-.37-.32-.34.4-.21.24Z"/>
            <path   d="m404.34,529.69c-.08.16-.16.35-.25.57l-.18.45c-.03.08-.04.12-.04.14s0,.03.02.05l.02.02-.08.06c-.04-.06-.07-.12-.1-.16-.03-.04-.06-.08-.1-.12l.36-.83c.07-.16.12-.31.17-.47l.06-.02s.04.08.06.1c.09.12.19.18.32.2.12.01.25-.03.38-.12.11-.08.18-.18.2-.28.02-.1,0-.21-.08-.32-.05-.07-.12-.14-.21-.19-.15.09-.3.19-.46.31l-.64.46c-.31.22-.48.35-.5.39-.03.04-.02.09.03.15l.06.08-.08.06c-.08-.12-.15-.22-.2-.3-.05-.06-.13-.17-.25-.33l.08-.06.06.08c.04.06.08.08.13.07.04-.01.22-.13.53-.36l.63-.46c.27-.2.42-.32.44-.35.02-.03,0-.09-.04-.16l-.04-.06.08-.06c.09.13.16.24.21.31.03.05.09.11.16.2.08.1.13.17.17.22.09.12.14.23.15.32.02.09,0,.17-.03.25-.03.07-.1.14-.18.21-.12.09-.26.13-.41.12-.15,0-.28-.06-.42-.17Z"/>
          </g>
        </g>
        <line   x1="414.85" y1="455.99" x2="386.81" y2="455.99"/>
        <g>
          <path   d="m403.85,456.49c0,4.55-3.69,8.24-8.24,8.24s-8.24-3.69-8.24-8.24,3.69-8.24,8.24-8.24,8.24,3.69,8.24,8.24Z"/>
          <g>
            <line   x1="395.66" y1="448.25" x2="395.61" y2="464.91"/>
            <g>
              <polygon   points="395.66 448.25 395.85 448.35 395.47 448.35 395.66 448.25"/>
              <polygon   points="396.19 448.55 396.52 448.75 394.8 448.75 395.13 448.55 396.19 448.55"/>
              <polygon   points="396.81 448.95 397.1 449.15 394.22 449.14 394.5 448.95 396.81 448.95"/>
              <path   d="m397.38,449.35c.1.07.19.13.27.2h-3.99c.08-.08.18-.14.27-.21h3.45Z"/>
              <path   d="m397.9,449.75c.08.07.17.13.24.2l-4.98-.02c.07-.07.16-.13.24-.2h4.49Z"/>
              <polygon   points="398.36 450.15 398.59 450.35 392.72 450.33 392.94 450.13 398.36 450.15"/>
              <polygon   points="398.79 450.55 398.99 450.74 392.31 450.72 392.51 450.53 398.79 450.55"/>
              <path   d="m399.19,450.94c.06.07.12.13.17.2l-7.42-.02c.06-.07.11-.13.18-.2l7.07.02Z"/>
              <polygon   points="399.53 451.34 399.71 451.54 391.59 451.51 391.77 451.32 399.53 451.34"/>
              <polygon   points="399.86 451.74 400 451.94 391.29 451.91 391.44 451.71 399.86 451.74"/>
              <path   d="m400.15,452.14c.05.07.1.13.14.2l-9.29-.03c.04-.07.09-.13.14-.2l9,.03Z"/>
              <path   d="m400.42,452.53c.04.07.08.13.12.2l-9.78-.03c.04-.07.08-.13.12-.2l9.54.03Z"/>
              <path   d="m400.65,452.93c.04.07.07.13.11.2l-10.23-.03c.03-.07.07-.13.11-.2l10.01.03Z"/>
              <path   d="m400.86,453.33c.03.07.07.13.09.2l-10.62-.03c.03-.07.06-.13.09-.2l10.43.03Z"/>
              <path   d="m401.04,453.72c.03.07.06.13.08.2l-10.96-.03c.02-.07.05-.13.08-.2l10.79.03Z"/>
              <path   d="m401.19,454.12c.02.07.05.13.07.2l-11.25-.04c.02-.07.05-.13.07-.2l11.1.03Z"/>
              <polygon   points="401.32 454.52 401.38 454.72 389.9 454.68 389.96 454.48 401.32 454.52"/>
              <polygon   points="401.43 454.92 401.48 455.11 389.8 455.08 389.85 454.88 401.43 454.92"/>
              <polygon   points="401.51 455.31 401.55 455.51 389.73 455.47 389.76 455.28 401.51 455.31"/>
              <polygon   points="401.58 455.71 401.6 455.91 389.68 455.87 389.7 455.67 401.58 455.71"/>
              <polygon   points="401.62 456.11 401.62 456.3 389.65 456.27 389.65 456.07 401.62 456.11"/>
              <path   d="m401.63,456.5c0,.07,0,.13,0,.2l-11.99-.04c0-.07,0-.13,0-.2l11.99.04Z"/>
              <polygon   points="401.62 456.9 401.62 457.1 389.65 457.06 389.64 456.86 401.62 456.9"/>
              <polygon   points="401.6 457.29 401.58 457.49 389.69 457.46 389.67 457.26 401.6 457.29"/>
              <polygon   points="401.55 457.69 401.51 457.89 389.75 457.85 389.71 457.65 401.55 457.69"/>
              <polygon   points="401.48 458.09 401.43 458.29 389.83 458.25 389.78 458.05 401.48 458.09"/>
              <path   d="m401.38,458.48c-.01.07-.04.13-.06.2l-11.39-.04c-.02-.07-.04-.13-.06-.2l11.51.04Z"/>
              <path   d="m401.26,458.88c-.02.07-.04.13-.07.2l-11.13-.03c-.03-.07-.05-.13-.07-.2l11.27.04Z"/>
              <path   d="m401.12,459.28c-.02.07-.05.13-.08.2l-10.82-.03c-.03-.07-.05-.13-.08-.2l10.98.03Z"/>
              <path   d="m400.95,459.67c-.03.07-.06.13-.09.2l-10.46-.03c-.04-.07-.06-.13-.09-.2l10.65.03Z"/>
              <path   d="m400.75,460.07c-.03.07-.07.13-.11.2l-10.05-.03c-.04-.07-.07-.13-.11-.2l10.26.03Z"/>
              <path   d="m400.53,460.46c-.04.07-.08.13-.12.2l-9.58-.03c-.04-.07-.08-.13-.12-.2l9.82.03Z"/>
              <path   d="m400.28,460.86c-.04.07-.09.13-.14.2l-9.04-.03c-.05-.07-.1-.13-.14-.2l9.32.03Z"/>
              <polygon   points="399.99 461.26 399.84 461.45 391.39 461.43 391.25 461.23 399.99 461.26"/>
              <polygon   points="399.69 461.65 399.52 461.85 391.72 461.82 391.54 461.63 399.69 461.65"/>
              <polygon   points="399.34 462.05 399.17 462.24 392.06 462.22 391.89 462.02 399.34 462.05"/>
              <polygon   points="398.97 462.44 398.77 462.64 392.46 462.62 392.26 462.42 398.97 462.44"/>
              <polygon   points="398.56 462.84 398.34 463.03 392.89 463.02 392.66 462.82 398.56 462.84"/>
              <path   d="m398.11,463.23c-.07.07-.16.13-.24.2h-4.51c-.08-.08-.17-.15-.24-.21l5,.02Z"/>
              <path   d="m397.62,463.63c-.08.07-.17.13-.27.2h-3.47c-.1-.08-.19-.14-.27-.21h4.01Z"/>
              <polygon   points="397.06 464.02 396.77 464.22 394.45 464.21 394.16 464.01 397.06 464.02"/>
              <polygon   points="396.48 464.42 396.14 464.61 395.08 464.61 394.74 464.41 396.48 464.42"/>
              <polygon   points="395.8 464.81 395.61 464.91 395.42 464.81 395.8 464.81"/>
            </g>
          </g>
          <path   d="m403.85,456.49c0,4.55-3.69,8.24-8.24,8.24s-8.24-3.69-8.24-8.24,3.69-8.24,8.24-8.24,8.24,3.69,8.24,8.24Z"/>
          <g>
            <path   d="m390.33,460.04l-.55-.8-.33.08c-.21.06-.34.1-.38.13-.02.02-.04.03-.04.05,0,.02,0,.05.03.11l.08.15-.1.07c-.05-.09-.12-.2-.19-.31-.09-.13-.18-.25-.26-.35l.1-.07.03.04c.05.06.08.1.11.11.02,0,.07,0,.14,0,.2-.04.42-.09.64-.14l1.27-.31c.33-.08.57-.15.72-.19l.06.09-1.41,2.01c-.09.13-.15.22-.17.25-.01.04-.02.07-.01.09,0,.03.02.08.07.15l-.11.07c-.07-.12-.15-.24-.23-.35-.07-.1-.16-.23-.29-.4l.1-.07.11.13s.06.07.08.07c.02,0,.04,0,.06,0,.02-.02.06-.06.12-.14l.34-.47Zm-.39-.84l.48.7.72-1.02-1.21.32Z"/>
            <path   d="m391.4,461.99c.06.06.11.12.17.18.12.11.22.2.29.26.06.04.12.08.18.11l.32-.22.06.06c-.16.13-.31.25-.44.38-.07-.04-.15-.12-.25-.21l-.44-.45c-.06-.06-.16-.15-.3-.26l.06-.06c.08.02.14.02.18,0,.03-.02.16-.14.37-.35l.63-.64c.23-.23.36-.37.37-.4.02-.04,0-.07-.04-.12l-.11-.1.08-.07c.14.15.24.25.28.3.07.06.16.15.28.25l-.07.07-.12-.12s-.07-.04-.1-.02c-.03.02-.13.11-.3.28l-.73.74-.32.33-.07.07Z"/>
            <path   d="m394.77,461.56c-.07.15-.13.31-.19.48l-.32.87c-.12.33-.18.51-.18.54,0,.04.04.07.12.1l.09.03-.03.09c-.14-.06-.26-.11-.37-.15-.1-.04-.23-.08-.37-.12l.03-.1.1.04c.06.02.09.02.11.02.02,0,.05-.05.07-.09.05-.12.12-.27.18-.46l.33-.87c.06-.17.12-.33.17-.48l-.13-.05c-.2-.07-.32-.11-.34-.1-.02,0-.05.04-.09.12l-.09.2-.08-.03.07-.18c.02-.07.06-.18.1-.33.45.18.71.28.78.31l.69.24c-.08.16-.15.33-.21.49l-.08-.03.07-.2c.02-.07.02-.11.02-.13,0-.02-.02-.03-.05-.04-.09-.04-.19-.08-.3-.13l-.1-.04Z"/>
            <path   d="m396.54,463.86h.08c.08-.02.13-.05.13-.1.01-.05-.01-.28-.07-.7l-.08-.55c-.06-.43-.1-.66-.12-.71-.02-.04-.06-.06-.13-.05h-.1s-.02-.09-.02-.09c.15,0,.29-.02.44-.04.11-.02.22-.03.32-.06v.1s-.09.02-.09.02c-.06,0-.1.02-.11.04-.01.02-.02.07-.01.14.01.11.04.32.08.62l.08.56c.06.42.09.65.12.69.02.04.07.05.15.04h.09s0,.09,0,.09c-.11,0-.23.02-.35.04-.15.02-.28.04-.4.07l-.02-.1Z"/>
            <path   d="m397.59,461.58l-.06-.09c.15-.1.26-.17.33-.22.07-.05.17-.13.3-.23l.06.08-.08.06c-.06.05-.09.09-.08.14,0,.04.1.19.29.44l.37.52c.14.19.24.32.31.38.07.06.16.09.27.09s.22-.04.34-.12c.12-.08.2-.19.25-.3.03-.08.03-.17,0-.27-.03-.1-.12-.26-.28-.49l-.36-.5c-.16-.23-.27-.35-.31-.37-.03-.02-.09,0-.17.05l-.06.04-.06-.08c.15-.1.25-.16.29-.19.06-.05.15-.11.24-.18l.06.08-.07.05c-.05.04-.07.08-.06.14,0,.05.09.18.26.41l.35.5c.16.23.26.39.3.48.04.09.06.19.05.29,0,.1-.03.19-.08.29-.05.09-.13.18-.24.26-.11.08-.22.13-.33.16-.11.03-.2.03-.28.02-.07-.02-.15-.06-.22-.11-.07-.05-.17-.17-.29-.34l-.18-.25-.29-.4c-.18-.25-.28-.39-.32-.41-.03-.02-.08,0-.15.04l-.07.06Z"/>
            <path   d="m400.81,459.12l-.05.07c-.12-.06-.22-.09-.29-.11-.06-.01-.13,0-.19.02-.06.02-.11.06-.15.12-.06.08-.07.16-.04.25.03.08.08.16.17.22.07.05.15.08.23.07.08,0,.21-.04.4-.13.18-.09.34-.13.47-.12.13,0,.25.05.36.13.1.07.18.15.25.25.06.1.08.21.08.32,0,.12-.03.22-.1.31-.04.06-.09.12-.15.17-.06.05-.14.11-.23.17-.12-.1-.28-.22-.49-.36l.05-.08c.14.07.25.11.33.12.07.02.14,0,.2-.02.07-.02.12-.07.16-.12.05-.08.07-.17.05-.27-.02-.1-.08-.18-.19-.26-.08-.06-.17-.09-.27-.09-.09,0-.24.04-.42.13-.18.09-.33.13-.44.13-.11,0-.22-.04-.32-.11-.09-.06-.17-.14-.22-.24-.06-.1-.08-.2-.08-.31,0-.11.03-.2.09-.29.06-.1.18-.2.34-.29.13.09.2.14.23.16.05.04.13.09.22.15Z"/>
            <path   d="m389.51,453.13l-.05.1c-.16-.05-.28-.08-.37-.08-.08,0-.16.01-.23.05-.06.04-.12.1-.16.17-.05.1-.05.2,0,.3.04.1.12.18.24.23.1.05.19.07.29.05.1-.01.25-.09.46-.23.2-.14.39-.21.54-.22.15-.01.3.02.46.09.13.07.24.15.33.26.09.11.15.23.17.37.01.13,0,.27-.06.39-.04.08-.09.16-.15.23-.06.07-.15.16-.26.24-.16-.09-.38-.2-.66-.34l.05-.1c.19.06.33.09.42.09.08,0,.17-.01.24-.05.07-.04.13-.1.17-.17.05-.11.05-.21.01-.33-.04-.12-.13-.21-.27-.28-.11-.06-.23-.08-.35-.06-.11,0-.27.08-.48.22-.2.14-.37.21-.5.23-.13.01-.26-.01-.4-.08-.12-.06-.23-.14-.31-.25-.09-.11-.14-.22-.15-.36-.02-.13,0-.25.05-.36.06-.13.18-.27.35-.41.17.09.27.14.31.16.07.03.17.08.3.14Z"/>
            <path   d="m391.44,451.98l.21-.2c.08-.08.13-.13.13-.15,0-.02,0-.04,0-.07,0-.03-.04-.09-.11-.19,0,0-.02-.02-.03-.04l.06-.06c.12.13.21.23.28.31.07.07.17.16.3.28l-.06.06c-.13-.09-.2-.15-.22-.16-.02-.01-.05-.01-.07,0-.02,0-.07.04-.14.11l-.24.23.67.68.37-.36.03-.03s.08-.08.08-.1c.02-.03.02-.07.01-.11,0-.04-.04-.14-.1-.29,0-.01-.02-.04-.02-.07l.06-.06s.12.21.32.54c-.22.2-.41.38-.59.56-.12.12-.26.25-.39.41l-.07-.07.1-.18s.02-.05.01-.07c0-.02-.03-.06-.07-.1l-.02-.02-.26-.28c-.05-.05-.15-.16-.3-.31-.22-.22-.38-.39-.48-.49s-.16-.16-.18-.17c-.02,0-.03,0-.04,0-.01,0-.05.02-.11.05l-.12.06-.07-.07c.12-.1.25-.22.4-.37.08-.08.27-.28.58-.6.13.13.27.26.41.38l-.06.06c-.13-.08-.22-.13-.26-.16-.03-.01-.06-.02-.08,0-.03,0-.08.04-.14.11l-.35.35.36.38.22.23Z"/>
            <path   d="m395.07,449.44l-.25,1.06c-.09.39-.17.73-.22,1.03h-.05c-.04-.08-.17-.32-.38-.69l-.64-1.13.17,1.24c.04.29.07.46.1.49.02.04.08.05.18.03h.04s.02.1.02.1c-.12,0-.22.02-.3.03-.05,0-.17.03-.33.06l-.02-.1h.08c.06-.02.1-.03.11-.06.01-.02.02-.06.02-.12,0-.15-.03-.31-.05-.49l-.13-.91c-.03-.22-.05-.37-.07-.44,0-.03-.03-.06-.05-.08-.02-.01-.07-.02-.14,0h-.07s-.02-.09-.02-.09c.16,0,.25-.02.29-.02.05,0,.12-.02.21-.04.15.28.31.58.5.9l.47.83.2-.85c.07-.3.15-.66.24-1.08.11,0,.19-.01.23-.02.05,0,.14-.02.28-.05l.02.1h-.09c-.07.02-.12.05-.13.08,0,.03,0,.15.03.36l.17,1.18c.03.26.06.41.09.45.02.04.08.05.16.04h.07s.01.09.01.09c-.12,0-.23.02-.35.04-.12.02-.25.04-.39.06v-.1s.07-.02.07-.02c.07,0,.11-.03.12-.07,0-.04,0-.2-.05-.48l-.18-1.31Z"/>
            <path   d="m397.91,449.74c-.07.13-.15.28-.23.44l-.42.83c-.15.29-.22.46-.22.5,0,.04.03.08.11.12l.08.04-.04.09c-.14-.08-.24-.14-.32-.18-.07-.03-.2-.09-.38-.18l.05-.09.1.05s.09.04.11.03c.02,0,.04-.03.07-.08.03-.04.1-.18.23-.41l.39-.77.15-.3c.06-.13.09-.21.08-.25,0-.04-.05-.08-.13-.12l-.06-.03.04-.09c.1.05.22.12.37.2.09.04.18.09.3.14.07.03.13.06.16.08.19.1.3.21.35.34.04.13.03.27-.05.41-.05.11-.13.19-.23.27-.1.07-.21.11-.33.13-.12.01-.23,0-.34-.06-.05-.02-.1-.06-.16-.12l.03-.12c.06.05.11.1.16.12.11.05.21.05.32,0,.1-.05.19-.14.26-.28.07-.14.09-.26.06-.37-.03-.11-.11-.2-.24-.27-.08-.04-.17-.06-.28-.07Z"/>
            <path   d="m399.41,451.56l.22.19c.09.07.14.11.16.12.02,0,.04,0,.07,0,.02-.01.08-.05.18-.13,0,0,.02-.02.04-.03l.06.05c-.12.13-.22.23-.28.3-.06.08-.15.18-.25.32l-.06-.05c.09-.14.13-.21.14-.23.01-.02.01-.05,0-.07,0-.02-.05-.06-.13-.13l-.25-.22-.62.73.39.33.03.03c.06.04.09.07.11.07.03,0,.07.01.11,0,.04,0,.13-.05.28-.13.01,0,.04-.02.07-.03l.06.05s-.2.14-.51.37c-.22-.2-.42-.38-.6-.54-.13-.11-.28-.23-.44-.36l.06-.07.19.08s.05.02.06,0c.02,0,.06-.03.1-.08l.02-.02.25-.29c.05-.05.14-.16.28-.33.21-.24.35-.41.45-.52.09-.11.14-.18.15-.2,0-.02,0-.02,0-.04,0-.01-.02-.05-.06-.1l-.08-.12.06-.07c.11.11.24.23.4.37.09.08.3.25.64.52-.12.15-.23.29-.34.45l-.06-.06c.07-.13.12-.22.13-.28.01-.03.01-.06,0-.08,0-.03-.05-.07-.12-.13l-.38-.32-.34.4-.21.24Z"/>
            <path   d="m401.22,453.96c-.08.15-.16.35-.25.57l-.18.44c-.03.08-.04.13-.04.14s0,.03.02.05l.02.02-.08.06c-.03-.06-.07-.12-.1-.16-.03-.04-.06-.08-.1-.12l.35-.84c.07-.15.13-.31.18-.47l.06-.02s.04.07.05.1c.09.12.19.18.32.2.12.01.25-.03.38-.12.11-.08.18-.18.2-.28.02-.1,0-.21-.08-.32-.05-.07-.12-.14-.21-.19-.15.09-.3.19-.46.31l-.64.46c-.31.22-.48.35-.5.39-.03.03-.02.09.03.15l.06.08-.08.06c-.08-.12-.14-.22-.2-.3-.05-.06-.13-.17-.25-.33l.08-.06.06.08c.04.06.08.08.13.07.03-.01.21-.13.53-.36l.63-.46c.27-.2.42-.32.44-.35.02-.04,0-.09-.04-.16l-.04-.06.08-.06c.09.13.16.24.21.31.03.05.09.11.16.2.08.1.13.17.17.22.09.12.14.23.15.32,0,.09,0,.17-.03.25-.03.07-.1.14-.18.21-.12.09-.25.13-.41.12-.14,0-.28-.06-.42-.17Z"/>
          </g>
        </g>
      </g>
      <polygon id="Bolsa_pañuelo" data-name="Bolsa pañuelo"   points="489.06 305.39 489.06 327.91 569.27 321.89 569.27 298.58 489.06 305.39"/>
      <polygon id="Pañuelo"   points="490.38 302.51 491.52 296.66 567.62 280.38 567.25 296.42 490.38 302.51"/>
      <g id="Ubicación_logo" data-name="Ubicación logo">
        <circle   cx="529" cy="351.5" r="21.64"/>
        <circle   cx="257" cy="351.5" r="21.64"/>
      </g>
    </g>
  </g>
</svg>
)
}

export default SacoMasculinoSvg