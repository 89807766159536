import React from 'react'

const ShadowsPoloFemale  = ({
    className,
    fill = '#dadada'
  }: {
    className: string
    fill?: string
  }) => {
    const getShadow = (color: string) => {
      switch (color) {
        case '#ffffff':
          return '#dadada80'
        case '#002D72':
          return '#123e8380'
        case '#BDBDBD':
          return '#cecece80'
        case '#4A94FF':
          return '#1195ee80'
        case '#CF0076':
          return '#df118780'
        case '#3B3B3B':
          return '#4c4c4c80'
        case '#000000':
          return '#22222280'
        default:
          return '#dadada80'
      }
    }
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 800 800'
        className={className}
      >
  
  <g id="Sombras">
    <g id="Sombras-2" data-name="Sombras" fill={getShadow(fill)}>
      <path   d="m72.39,215.4c12.61-17.4,44.06-54.79,52.61-66.4,14-19,25-38,41.32-44.26-.77,4.64.02,8.93-.01,13.66-1.36,1.03-6.93,3.52-8.22,4.69-19.8,18.7-23.91,42.35-42.61,61.04-19.8,20.9-28.24,35.2-2.93,57.21,23.1,22,26.36,36.81,55.98,61.14,17.98-61.36-3.18-97.81-6.11-140.34-.82-7.39-1.06-28.18,4.64-36.36.49,9.18,3.04,14.58,4.13,23.68,1.91,15.82,4.07,29.16,8.33,44.73,3.43,12.55,5.76,22.72,9.68,35.14,1.96,6.22,3.04,11.87,2.97,16.56-.79,9.57,1.74,4.76-3.01,14.2-1.55,5.48-5.35,22.09-7.34,27.88-7.57,6.6-5.02,22.44-9.42,31.24-2.2-6.6-9.97-14.06-14.29-17.41-3.31-3.95-8.28-8.55-13.66-13.43-5.39-4.89-11.19-10.07-17.28-15.36-10.08-8.77-20.91-17.84-31.84-26.38-11.4-8.91-19.08-16.96-29.96-23.78"/>
      <path   d="m677,150c-11.67-15.84-37.37-46.24-45.77-48.53.77,4.64-.27,9.21-.24,13.95,1.35,1.03,0,0,1.28,1.17,19.8,18.7,31.54,50.61,50.24,69.3,19.8,20.9,28.23,35.2,2.93,57.21-23.1,22-26.36,36.81-55.98,61.14-17.98-61.36,3.17-97.81,6.11-140.34.82-7.39,2.39-34.6-3.31-42.78-.5,9.18-2.15,18.79-3.25,27.89-1.91,15.82-4.86,31.67-9.12,47.24-3.43,12.55-6.55,26.36-10.46,38.78-1.96,6.22-5.23,13.16-5.14,17.86.78,9.57-.86,11.18,3.89,20.62,1.55,5.48,5.65,10.92,7.64,16.71,2.44,9.04,4.77,18.16,9.17,26.96,2.2-6.6,10.67-10.75,14.98-14.09,3.31-3.95,8.2-8.06,13.58-12.95,5.39-4.89,11.19-10.07,17.28-15.36,10.07-8.77,20.91-17.84,31.84-26.38,11.4-8.91,20.83-16.87,31.72-23.68-1.56-2.18-2.56-2.79-5.4-6.69-16-22-44.5-57.81-52-68Z"/>
      <path   d="m610.5,741.25c.78-6.15-.32-28.64-1.18-35.57-4.35-34.7-5.5-14.85-17.87-53.12-6.86-21.22-17.19-37.69-23.41-74.48-1.98-11.71-2.58-25.18-2.34-34.32.45-16.68-7.59-21.59-6.89-29.49.26-2.83-1.47-7.39-1.36-13.26.1-5.21,1.99-11.46,2.77-18.13.32-2.78.79-5.75,1.33-8.69,1.58-8.65,2.25-19.61,2.81-23.15.8-5.07-.08-5.9,1.02-10.58,22.27-38.07,32.91-182.6,45.84-215.33,2.07-6.47,4.19-13.18,6.22-20.25,2.04-7.07,3.99-14.5,5.72-22.43,2.25-18.33,4.28-36.69,6.78-56.27,0-.21.02-.42.02-.63.16-6.52.35-16.96.1-24.08-2.63-.67-5.46-2.06-7.99-2.73-4.13,35.93-10.21,68.57-15.69,90.54-2.74,10.98-5.31,20.39-7.38,28.39-.7,16.93-23.98,23.27-21.86,31.74.97,48.47-1.12,116.94-58.45,171.4,16.93,8.46,24.59,6.33,38.42,19.65.74,2.55-.51,7.17-2.94,12.71-7.27,16.73-26.05,39.21-31.34,46.61,25.39,15.52-17.63,42.32-17.63,62.06,33.85-14.1,26.8,66.3,31.03,86.04,3.84,20.16,36.62,19.59,38.79,45.14,6.35,74.76-147.58,63.16-212.47,60.34-86.05-5.65-167.67-44.82-107.02-111.12,26.55-29.03-4.09-76.45,13.4-111.44,6.35-12.69,4.76-28.56-3.57-50.58-2.78-7.34-6.31-15.36-10.54-24.17-1.41-22.57,7.68-25.49,24.6-36.07-48.67-58.81-51.88-133.62-54-169.77-10.58-6.35-7.98-2.92-16.44-.81-2.12-8.46,4.23-14.81,5.58-22.12,2.48-9.35,2.17-19.49-6.29-16.67-12.69,2.82-4.94,21.56-10.31,23.42,0,0-.7-3.08-2.29-8.76-1.69-5.8-4.06-13.92-6.77-23.22-10.87-37.2-20.72-91.62-20.72-91.62-1.41,31.74,2.27,65.45,12.29,98.92,2.5,8.37,3.21,30.27,5.46,38.3,6.47,25.99,45.08,150.17,47.04,204.41.13,3.58-.08,13.49,1.5,22.13.53,2.94,1.01,5.91,1.33,8.69.77,6.67,1.02,12.83,1.12,18.03.11,5.86.03,10.52.29,13.35.71,7.91-2.47,13.82-6.32,30.05-2.16,9.07-1.53,20.34-3.52,32.05-6.23,36.79-16.28,58.64-24.41,79.46-11.94,30.54-15.87,50.23-20.65,69.18-1.45,5.75,6.32,12.62,6.32,18.46,19.52,8.5,71.3,25.33,113.31,33.82,22.04,4.45,34.31,7.58,55.72,7.58,10.27,0,22.9.89,33.73.89.39,0,9.52-.62,9.91-.62,10.75,0,19.34-.88,29.54-1.98,21.89-2.36,45.38-4.5,68.11-6.6,61.21-5.66,94.72-18.48,111.55-35.3Z"/>
      <path   d="m606.1,264.89c-7.84-16.43-9.95-37.59-24.76-52.39,0,14.81,12.69,25.39,17.83,37.08,3.01,5.24,5.51,10.54,6.93,15.31Z"/>
      <path   d="m609.2,270.64c-1.02-7.88-1.69-16.59-.53-24.26,1.16-7.67,5.52-16.12,11.86-22.47,0,11.63-4.33,18.98-7.24,24.86-2.91,5.88-5.29,11.25-4.09,21.87Z"/>
      <path   d="m609.96,271.24c-.6-5.03-.13-10.97,3.39-15.82,3.3-4.81,12.56-9.43,24-7.3-19.52,1.57-28.06,16.29-27.39,23.12Z"/>
      <path   d="m609.74,275.56c-6.37-22.46-25.42-41.5-48.69-49.96,16.93,8.46,31.74,19.04,40.95,35.86,3.21,4.99,5.82,9.86,7.74,14.1Z"/>
      <path   d="m351.48,155.4c.32,1.44-.51,2.81-1.21,2.38-1.29-.79-2.58-9.47-2.56-9.96,0,0,3.45,6.14,3.77,7.58Z"/>
      <path   d="m417.43,215.04c-1.82-13.07-19.23-34.48-49.65-41.52h0c29.06,11.77,37.8,22.29,46.08,36.81,2.88,5.05,2.01,9.8,4.79,24.23.45-7.64-.82-16.72-1.21-19.52Z"/>
      <path   d="m405.12,189.61c-5.98-6.74-28.61-19.38-59.03-26.43l4.73,1.84c28.17,9.69,33.09,11.08,46.8,20.48,5.17,3.55,11.24,9.24,17.97,20.21-1.59-6.24-8.58-13.99-10.45-16.1Z"/>
      <path   d="m346.08,131.96c-9.88-14.56-23.3-39.26-24.55-56.41-.72-9.89.21-9.21,10.08-13.89,1.5-.75,17.8-.16,19.94-1.79-6.06.48-23.65-1.36-29.85,6.34-3.37,4.18-1.88,10.71-1.88,12.59.71,12.01,8.37,25.24,11.05,35.67,2.51,9.78,7.3,18.21,10.14,22.11,1.54,2.12,31.51,12.5,37.83,18.63-4.54-9.08-26.52-14.41-32.76-23.24Z"/>
      <path   d="m420.73,198.49c1.82-13.07,8.68-37.46,29.8-40.38l-9.31,4.45c-12.99,10.96-10.97,13.06-17.3,27.71-2.3,5.34-1.39,15.64-4.17,30.07-.45-7.64.58-19.05.97-21.85Z"/>
      <g id="Boton">
        <g>
          <path   d="m401.95,195.56c3.31.38,5.69,3.36,5.31,6.67-.38,3.3-3.36,5.68-6.67,5.31-3.3-.38-5.68-3.36-5.3-6.67.37-3.31,3.35-5.69,6.66-5.31Z"/>
          <path   d="m401.27,207.64c-.23,0-.45-.01-.68-.04-3.33-.38-5.73-3.4-5.36-6.73.18-1.62.98-3.07,2.26-4.08,1.27-1.01,2.86-1.47,4.47-1.28,3.33.38,5.74,3.4,5.37,6.73-.35,3.1-3,5.4-6.06,5.4Zm0-12.05c-1.34,0-2.63.45-3.7,1.3-1.25.99-2.03,2.41-2.21,4-.37,3.27,1.98,6.23,5.25,6.6,3.26.36,6.23-1.99,6.6-5.26.37-3.27-1.99-6.23-5.26-6.6-.23-.03-.46-.04-.69-.04Z"/>
        </g>
        <g>
          <path   d="m401.57,199.05c1.31.14,2.25,1.26,2.11,2.47-.14,1.23-1.3,2.1-2.6,1.95-1.31-.15-2.25-1.26-2.11-2.47.14-1.23,1.3-2.09,2.59-1.95Z"/>
          <path   d="m401.38,203.52c-.1,0-.2,0-.3-.02-.66-.07-1.27-.41-1.67-.91-.37-.46-.53-1.03-.46-1.59.06-.57.35-1.09.81-1.46.43-.34.97-.52,1.52-.52.09,0,.19,0,.28.02h0c1.31.15,2.27,1.27,2.13,2.5-.07.58-.36,1.1-.82,1.47-.43.34-.95.52-1.5.52Zm-.09-4.46c-.54,0-1.08.18-1.49.51-.45.36-.74.87-.8,1.43-.07.55.09,1.11.45,1.56.39.49.99.82,1.63.89.64.07,1.27-.1,1.77-.49.46-.36.74-.87.8-1.44.13-1.21-.81-2.31-2.09-2.45h0c-.09-.01-.18-.02-.27-.02Z"/>
        </g>
        <g>
          <g>
            <path   d="m400.44,201.64c.3.03.51.3.48.6-.03.3-.31.51-.6.47-.3-.03-.51-.3-.48-.59.04-.3.3-.51.6-.48Z"/>
            <path   d="m400.37,202.73s-.04,0-.06,0c-.3-.03-.51-.3-.48-.6.03-.28.27-.49.55-.49.02,0,.04,0,.06,0h0c.14.02.27.09.37.2.09.12.13.26.12.4-.02.14-.09.27-.2.36-.1.08-.22.12-.35.12Zm0-1.08c-.27,0-.5.2-.53.48-.03.29.18.56.47.59.02,0,.04,0,.06,0,.12,0,.25-.04.34-.12.11-.09.18-.21.19-.35.02-.14-.02-.28-.11-.4-.09-.11-.22-.18-.36-.2h0s-.04,0-.06,0Z"/>
          </g>
          <g>
            <path   d="m400.43,199.95c.3.03.51.3.47.59-.03.3-.3.51-.6.47-.3-.03-.51-.3-.48-.6.03-.3.3-.51.6-.47Z"/>
            <path   d="m400.38,201.03s-.05,0-.07,0c-.3-.03-.51-.3-.48-.6.02-.15.09-.27.21-.37.1-.08.21-.12.33-.12.02,0,.05,0,.07,0h0c.15.02.28.09.37.21.09.11.13.25.11.4-.02.15-.09.28-.21.37-.09.07-.21.11-.33.11Zm-.02-1.08c-.12,0-.23.04-.32.11-.11.09-.18.22-.2.36-.03.3.18.56.47.59.14.02.28-.02.39-.11.11-.09.18-.22.2-.36.02-.14-.02-.28-.11-.39-.09-.11-.22-.18-.36-.2h0s-.05,0-.07,0Z"/>
          </g>
          <g>
            <path   d="m402.04,201.64c.3.03.51.3.48.6-.03.3-.3.51-.6.48-.29-.04-.51-.3-.47-.59.03-.3.3-.51.59-.48Z"/>
            <path   d="m401.99,202.73s-.04,0-.06,0c-.3-.04-.51-.31-.48-.6.01-.14.08-.27.19-.37.1-.08.22-.12.34-.12.02,0,.04,0,.06,0h0c.14.01.27.08.37.19.09.12.14.26.12.41-.02.15-.09.27-.2.37-.1.08-.22.12-.34.12Zm0-1.08c-.12,0-.24.04-.34.12-.11.09-.18.22-.19.36-.04.29.17.55.47.59.02,0,.04,0,.06,0,.12,0,.24-.04.33-.12.11-.09.18-.22.2-.36.02-.15-.03-.29-.12-.4-.09-.11-.22-.18-.36-.19h0s-.04,0-.06,0Z"/>
          </g>
          <g>
            <path   d="m402.29,199.95c.3.03.51.3.47.59-.03.3-.3.51-.6.47-.29-.03-.5-.3-.47-.6.04-.3.3-.51.6-.47Z"/>
            <path   d="m402.23,201.03s-.05,0-.07,0c-.3-.03-.51-.3-.48-.6.04-.28.27-.48.54-.48.02,0,.04,0,.07,0h0c.14.02.27.09.36.2.09.11.13.26.11.4-.02.15-.09.28-.2.37-.1.08-.21.12-.33.12Zm-.01-1.08c-.27,0-.49.2-.53.47-.03.3.18.56.47.59.14.02.28-.02.39-.11.11-.09.18-.22.2-.36.02-.14-.02-.28-.11-.39-.09-.11-.21-.18-.36-.2h0s-.04,0-.07,0Z"/>
          </g>
        </g>
        <polygon   points="403.05 213.28 402.71 213.16 402.93 212.52 402.19 212.15 402.8 211.47 401.8 211.19 403.03 210.63 401.39 210.18 404.13 208.88 400.03 209.23 403.47 207.73 401.28 207.93 401.24 207.57 405.71 207.15 402.21 208.68 406.11 208.34 402.43 210.09 404.09 210.54 402.85 211.11 403.47 211.28 402.78 212.04 403.38 212.33 403.05 213.28"/>
        <g id="Semper">
          <path   d="m401.99,196.59s.1.08.16.1c.1.03.17,0,.19-.08.02-.07-.01-.12-.11-.19-.11-.08-.17-.17-.14-.27.03-.12.15-.17.29-.13.08.02.13.06.16.09l-.05.07s-.06-.06-.13-.08c-.1-.03-.16.02-.17.07-.02.07.02.12.11.19.11.09.16.17.13.28-.03.12-.14.19-.32.14-.07-.02-.15-.07-.18-.1l.04-.07Z"/>
          <path   d="m403,196.68l-.29-.12-.09.26.32.13-.03.08-.42-.17.26-.71.4.16-.03.08-.31-.12-.09.22.29.12-.03.08Z"/>
          <path   d="m403.76,197.08c.05-.1.11-.21.15-.3h0c-.07.06-.14.13-.23.21l-.3.26-.07-.04.07-.38c.02-.11.04-.21.07-.31h0c-.05.08-.12.19-.18.29l-.18.28-.09-.05.42-.65.11.06-.07.39c-.02.1-.04.18-.06.26h0c.05-.06.12-.12.2-.19l.3-.26.11.06-.33.7-.09-.05.15-.31Z"/>
          <path   d="m404.32,196.95s.1.06.17.11c.08.06.13.13.14.19.01.05,0,.11-.05.17-.04.05-.1.09-.15.09-.08.01-.17-.02-.24-.08-.02-.02-.05-.04-.06-.06l-.2.24-.08-.06.48-.59Zm-.15.35s.04.04.06.06c.1.08.2.08.26,0,.06-.08.04-.16-.05-.23-.04-.03-.06-.05-.08-.05l-.19.23Z"/>
          <path   d="m404.91,197.98l-.22-.22-.2.2.24.24-.06.06-.32-.31.56-.56.3.3-.06.06-.23-.23-.18.18.22.22-.06.06Z"/>
          <path   d="m405.36,197.91s.09.09.13.15c.07.09.1.16.09.22,0,.05-.03.1-.07.14-.08.06-.17.05-.24,0h0c.02.06,0,.13-.05.19-.07.09-.11.15-.12.18l-.07-.09s.05-.08.11-.16c.07-.09.07-.14.02-.22l-.06-.08-.26.21-.06-.08.59-.48Zm-.21.3l.06.08c.07.09.15.11.22.05.07-.06.06-.15,0-.24-.03-.04-.06-.07-.07-.08l-.22.18Z"/>
          <path   d="m405.55,199.06l-.25.04-.05-.09.8-.13.06.11-.55.62-.05-.1.17-.19-.13-.25Zm.19.2l.16-.18s.07-.07.11-.1h0s-.09.02-.14.03l-.23.04.11.21Z"/>
          <path   d="m406.33,199.4l.04.1-.63.27.13.32-.08.03-.16-.42.71-.3Z"/>
          <path   d="m406.5,200.11l-.07-.23.09-.02.16.55-.08.02-.07-.23-.67.2-.03-.1.67-.2Z"/>
          <path   d="m406.7,200.64l-.75.15-.02-.1.75-.15.02.1Z"/>
          <path   d="m406.79,200.99l-.46.04c-.17.02-.24.1-.23.21.01.12.09.18.27.17l.46-.04v.1s-.45.04-.45.04c-.24.02-.35-.09-.37-.26-.01-.16.07-.29.31-.31l.46-.04v.1Z"/>
          <path   d="m406.19,201.69s-.05.11-.05.18c0,.1.06.16.13.16s.12-.04.16-.15c.05-.13.11-.21.22-.21.12,0,.21.1.21.25,0,.08-.02.14-.04.17l-.08-.03s.04-.08.04-.15c0-.11-.06-.15-.12-.15-.07,0-.11.05-.15.16-.05.13-.11.2-.23.2s-.22-.09-.22-.27c0-.07.02-.16.05-.2l.09.02Z"/>
        </g>
      </g>
      <path   d="m186.2,266.03c.6-5.03.13-10.97-3.4-15.82-3.3-4.81-12.56-9.43-23.99-7.3,19.52,1.57,28.06,16.29,27.39,23.12Z"/>
      <path   d="m189.74,264.22c.94-7.33,1.06-13.74-1.14-18.52-2.19-4.78-7.14-8.83-16.31-11.65,4.94,5.64,9.26,10.15,12.11,14.44,2.85,4.29,4.73,8.99,5.34,15.73Z"/>
      <path   d="m189.68,264.22c8.31-14.93,12.54-31.85,29.47-42.43-4.23,6.35-8.46,14.81-14.72,18.84-5.55,6.43-10.89,13.78-14.75,23.59Z"/>
      <path   d="m191.21,266.03c9.65-24.16,26.58-43.21,51.97-49.55-12.7,8.46-27.51,12.7-36.87,25.87-7.05,8.11-11.02,15.51-15.11,23.69Z"/>
      <path id="Sombras-3" data-name="Sombras"   d="m311.46,70.86s-1.88-17.33,16.44-19.03c19.32-1.79,68.47-2.77,68.47-2.77,0,0,62.12,1.5,67.91,3.48,6.76,2.31,20.92-2.15,20.88,27.31-.03,23.05,23.49-28.67-18.54-32.56-42.03-3.89-73.02-3.6-73.02-3.6,0,0-43.69.42-51.86,1.48-8.17,1.06-34.68-.89-30.29,25.7Z"/>
      
    </g>
  </g>
      </svg>
    )
  }
export default ShadowsPoloFemale