/* eslint-disable react-hooks/exhaustive-deps */
import { Options } from 'components/formInputs/select'
import { ColumnsType } from 'antd/es/table'
import Tags from 'components/tag'
import { BooksTable } from 'interfaces/books-interface'
import { useEffect, useState } from 'react'
import { getCatalogStatus } from 'services/books/books'
import { getAllBooksList } from 'services/schoolUserServices/listOfElements'
import { handleErrors } from 'utilities/handleErrors'

export function useBookTable() {
  const BookColumn: ColumnsType<BooksTable> = [
    {
      title: 'ISBN',
      dataIndex: 'isbn',
      key: 'isbn'
    },
    {
      title: 'Título',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Editorial',
      dataIndex: 'editorial',
      key: 'editorial'
    },
    {
      title: 'Edición',
      dataIndex: 'edition',
      key: 'edition'
    },
    {
      title: 'Tipo',
      dataIndex: 'format',
      key: 'format'
    },
    {
      title: 'Idioma',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (text: number, field: any) => {
        
        let money = field.price
        if (field.special_price && field.special_price !== '0.00' && field.special_price !== null)
          money = field.special_price
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(money)
      }
    },
    {
      title: 'Estatus',
      dataIndex: 'status_name',
      key: 'status_name',
      render: (status: any) => {
        return <Tags variation={status} title={status} />
      }
    }
  ]
  const [status, setStatus] = useState('')
  const [books, setbooks] = useState([])
  const [loading, setloading] = useState(true)
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
const [search, setsearch] = useState('');
  const handleChangePage = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: e
      }
    })
  }
  const [pagination, setpagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    onChange: handleChangePage
  })

  useEffect(() => {
    hangleGetStatusCatalog()
  }, [])

  useEffect(() => {
    hangleGetbooks(status, pagination.current)
  }, [status, pagination.current])

  const hangleGetbooks = async (status: string, page: number, search:string='') => {
    setloading(true)
    try {
      const result = await getAllBooksList({ status, page, search })
      setbooks(result.data.data.books)
      setpagination((prev) => {
        return {
          ...prev,
          current: result.data.data.page,
          pageSize: 20,
          total: result.data.data.total
        }
      })
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false)
    }
  }

  const hangleGetStatusCatalog = async (): Promise<any> => {
    try {
      const res = await getCatalogStatus()
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])
    } catch (error) {
      handleErrors(error)
    }
  }
  let timeOutId: ReturnType<typeof setTimeout>

  const handleSearch = (value: string) => {
    setsearch(value ? value : '')
    setpagination((prev) => {
      return { ...prev, current: 1 }
    })
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(async () => {
      hangleGetbooks(status, 1, value)
    }, 1000)
  }
  const onHandleChangeStatus = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: 1
      }
    })
    if (e === 'todos') return setStatus('')
    return setStatus(e)
  }

  return {
    books,
    search,
    status,
    loading,
    pagination,
    BookColumn,
    statusCatalog,
    setStatus,
    onHandleChangeStatus,
    handleSearch
  }
}
