import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import ChalecoSvg from 'assets/img/uniforms/chaleco/ChalecoSvg'
import LabelChaleco from 'assets/img/uniforms/chaleco/LabelChaleco'
import GreenTagChaleco from 'assets/img/uniforms/chaleco/GreenTagChaleco'
import { setLabelsMenu } from 'redux/reducers/uniformsSlice'

const ChalecoEditable = () => {
  const { chalecoForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {chalecoForm.logoPosition.value !== '' && (
            <div
              className={`${
                style[`logo-position-saco-${chalecoForm.logoPosition.value}`]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <ChalecoSvg className={`${style['svg']}`} />
          </div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelChaleco className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagChaleco className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default ChalecoEditable
