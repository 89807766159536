import Icon from 'components/icons'
import React, { Fragment, useState } from 'react'
import style from './button.module.scss'

export interface ButtonInterface {
  id?: any
  label?: string
  children?: any
  onClick?: any
  disabled?: boolean
  RightIcon?: any
  RightIconName?: string
  rightIconCustom?: string
  LeftIcon?: any
  LeftIconName?: string
  leftIconCustom?: string
  isLoading?: boolean
  leftIconColor?: string
  rightIconColor?: string
  customStyle?: string
  buttonType?: 'button' | 'submit' | 'reset'
  type?: 'red' | 'blue' | 'grey' | 'transparent' | 'transparentBlue'
  loaderSize?: 'sm' | 'm' | 'x'
  rounded?: boolean
  size?: 'sm' | 'm'
  sector?: 'primary' | 'secondary' | 'tertiary'
  background?: 'color' | 'white'
}

const Button = ({
  label,
  id,
  onClick,
  disabled = false,
  RightIcon,
  RightIconName,
  rightIconCustom = style['icons-default'],
  LeftIcon,
  leftIconCustom = style['icons-default'],
  isLoading,
  leftIconColor,
  LeftIconName,
  rightIconColor,
  customStyle,
  type = 'red',
  buttonType = 'button',
  loaderSize = 'm',
  children,
  rounded,
  size = 'sm',
  sector = 'primary',
  background = 'color'
}: ButtonInterface) => {
  const [press, setPress] = useState(false)
  const getIconColor = (color: string) => {
    if (!press)
      switch (color) {
        case 'icon-primary-color' ||
          'icon-secondary-color' ||
          'icon-tertiary-color':
          return '#ffffff'
        case 'icon-primary-white':
          return '#0F48FF'

        case 'icon-secondary-white':
          return '#EF5D60'

        case 'icon-tertiary-white':
          return '#2FABB0'

        default:
          break
      }
    else return '#ffffff'
  }
  const onMouseDown = () => {
    if (background === 'white') setPress(true)
  }
  const onMouseUp = () => {
    if (background === 'white') setPress(false)
  }

  return (
    <button
      id={id}
      type={buttonType}
      onClick={(e) => onClick && onClick(e)}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      disabled={isLoading || disabled}
      className={`
            ${!rounded && style[`button-${type}`]}           
            ${rounded && style[`${sector}-${background}`]}
            ${rounded && style['rounded-full']}
            ${rounded && size && style[`button-${size}`]}
            ${customStyle}`}
    >
      {LeftIcon && (
        <LeftIcon
          stroke={
            rounded
              ? getIconColor(`icon-${sector}-${background}`)
              : leftIconColor
          }
          className={`${
            rounded ? style[`icon-rounded-size-${size}`] : leftIconCustom
          } `}
        />
      )}
      {LeftIconName && <Icon name={LeftIconName} />}
      {label}
      {children}
      {isLoading ? (
        <div className={`${style[`button-loader-${loaderSize}`]}`}></div>
      ) : (
        <Fragment>
          {RightIcon && (
            <RightIcon
              stroke={
                rounded
                  ? getIconColor(`icon-${sector}-${background}`)
                  : rightIconColor
              }
              className={`${
                rounded ? style[`icon-rounded-size-${size}`] : rightIconCustom
              }`}
            />
          )}
          {RightIconName && <Icon name={RightIconName} />}
        </Fragment>
      )}
    </button>
  )
}

export default Button
