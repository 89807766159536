import FormSelect from 'components/formInputs/select'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLayerOnView } from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import { Form } from 'antd'

const LAYERS_TYPE = [
  {
    value: 1,
    label: 'Ambas'
  },
  {
    value: 2,
    label: 'Imagenes'
  },
  {
    value: 3,
    label: 'Textos'
  }
]
const LayersPanel: any = memo(() => {
  useEffect(() => {
    return () => {
      dispatch(setLayerOnView(1))
    }
  }, [])
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const handleChangeSelect = (e: { layer_type: number }) => {
    dispatch(setLayerOnView(e.layer_type))
  }
  return (
    <Form
      onValuesChange={handleChangeSelect}
      layout='vertical'
      autoComplete='off'
      form={form}
    >
      Capas:
      <FormSelect
        name={'layer_type'}
        options={LAYERS_TYPE}
        placeholder={'Ambas'}
        label={''}
        hasFeedback={false}
      />
    </Form>
  )
})
LayersPanel.displayName = 'LayersPanel'
export default LayersPanel
