/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Form, FormInstance, SelectProps, TabsProps, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import {
  Books,
  BooksData,
  BooksTable,
  DataBook
} from 'interfaces/books-interface'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import * as services from 'services/books/books'
import * as servicesSchool from 'services/schoolAdminServices/schools'
import Tag from 'components/tag'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { TrashIcon } from '@heroicons/react/24/outline'
import {
  getLevels,
  getLevelsBySchool
} from 'services/schoolUserServices/levels'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeStatusBook,
  deleteBook,
  deleteBookAdmin,
  setBooksSchoolArray,
  setDataCollege,
  setIDGrade,
  setIDGradeAuth,
  setIDLevel,
  setIsBookAuth,
  setLevelsBooks,
  setLevelsBooksAdmin,
  setNewStructureBook,
  updateBook,
  updateCloseBook,
  updateEndList,
  updateFromBook,
  updateFromBookAdmin,
  updateSchoolBookAdmin
} from 'redux/reducers/books'
import {
  getGeneralModulesStatus,
  getLogs
} from '../../services/genericServices/genericServices'
import { RootState } from 'redux/store/store'
import { Options } from 'types'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
// import { setopenComments } from 'redux/reducers/comments'
import lodash from 'lodash'
import React from 'react'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import filterlevelsByPermission from 'utilities/filterlevelsByPermission'

const params: [string, string] = ['nuevo', 'editar']

export const INITIAL_PAGE: number = 1

export const INITIAL_TOTAL: number = 0

const INITIAL_STATE_BOOKS: Books = {
  total: 0,
  page: 1,
  limit: 10,
  totalPages: 0,
  books: []
}

export const BOOKS_TITLE_SEARCH = [
  {
    book: 'Libro'
  }
]

const INITIAL_STATE_BOOK: DataBook = {
  id: 0,
  name: '',
  status: 0,
  action: '',
  countLetters: 0,
  toast: 'Libro creado exitosamente.',
  id_school_book: 0,
  incomplete: false
}

export const BOOK_TYPES = [
  {
    value: 'FÍSICO',
    label: 'Físico'
  },
  {
    value: 'DIGITAL',
    label: 'Digital'
  }
]

export const setStatus = (status: number): string => {
  if (status === 1) {
    return 'Nuevo'
  }
  if (status === 2) {
    return 'En revisión'
  }
  if (status === 3) {
    return 'Con observaciones'
  }
  if (status === 4) {
    return 'Disponible'
  }
  if (status === 5) {
    return 'Autorizado'
  }

  return ''
}

interface Functions {
  actions: DataBook
  loading: boolean
  idSchool: number
  idLevel: number
  idGrade: number
  modalMassive: boolean
  modalSearch: boolean
  modalDelete: boolean
  modalAuthorize: boolean
  modalChanges: boolean
  modalEndList: boolean
  modalErrorsMassive: boolean
  action: string | undefined
  params: Array<string>
  form: FormInstance<any>
  bookColumns: ColumnsType<BooksTable>
  books: Books
  booksGral: any
  totalBooks: number
  booksSchool: any
  options: SelectProps<object>['options']
  tabs: TabsProps['items']
  selectedLevel: any
  page: number
  pageBooks: number
  searchBook: string
  searchGralBook: string
  schoolsCatalog: Options[]
  statusCatalog: Options[]
  selectSchool: string
  selectStatus: string
  changeViewAdmin: string
  donwloadLogs: () => void
  setSaveAction: (value: any) => void
  setSearchBook: (value: string) => void
  setSearchGralBook: (value: string) => void
  setPage: (page: number) => void
  setActions: (values: DataBook) => void
  setIdLevel: (value: number) => void
  setIdGrade: (value: number) => void
  onNavigate: (route: string) => void
  onHandleChangePage: (page: number) => void
  fetchSearchBooks: (page: number, search: string) => Promise<any>
  onHandleSearch: (search: string) => Promise<any>
  // onHandleAutoComplete: (value: string) => Promise<any>
  onHandleNewBook: (route: string) => void
  setTitle: (title: string) => string
  setModalMassive: (value: boolean) => void
  setModalSearch: (value: boolean) => void
  setModalAuthorize: (value: boolean) => void
  setModalEndList: (value: boolean) => void
  setModalChanges: (value: boolean) => void
  setModalErrorsMassive: (value: boolean) => void
  onHandleForm: (values: BooksData) => Promise<any>
  onHandleEditData: (route: string, values: BooksTable) => void
  openModalSearch: () => void
  onHandleCancelModal: () => void
  onHandleCancelModalSearch: () => void
  onHandleCancelModalAuthorize: () => void
  onHandleCancelModalEndList: () => void
  onHandleCancelModalChanges: () => void
  onHandleCancelModalErrorsMassive: () => void
  handleChangeLevelTab: (key: string) => void
  handleCollapseBooks: (idGrade: number) => void
  handleDeleteBookSchool: () => Promise<any>
  handleAssignBook: (values: any) => Promise<any>
  handleReviewStatus: (idGrade: any) => Promise<any>
  onHandleChangeSchool: (value: string) => void
  onHandleChangeStatus: (value: string) => void
  onHandleSearchBooks: (value: string) => void
  onHandleMultipleStatus: (values: any) => void
  handleFormComments: (values: any, id: number, idBook: number) => Promise<any>
  downloadBooks: () => Promise<any>
  setPageBooks: (page: number) => void
  getBooks: (
    page: number,
    school: string,
    status: string,
    search: string,
    fn?: () => void
  ) => Promise<void>

  onHandleIndividualAuthorize: (idGrade: number) => void
  onHandleOpenEndList: (idGrade: number) => void
  onHandleOpenChanges: (idGrade: number) => void
  handleRequestChanges: () => void
}
export const useBooks = (): Functions => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [modalMassive, setModalMassive] = useState<boolean>(false)
  const [modalSearch, setModalSearch] = useState<boolean>(false)
  const [modalDelete, setModalDelete] = useState<boolean>(false)
  const [modalAuthorize, setModalAuthorize] = useState<boolean>(false)
  const [modalEndList, setModalEndList] = useState<boolean>(false)
  const [modalChanges, setModalChanges] = useState<boolean>(false)
  const [modalErrorsMassive, setModalErrorsMassive] = useState<boolean>(false)
  const [actions, setActions] = useState<DataBook>(INITIAL_STATE_BOOK)
  const [page, setPage] = useState<number>(INITIAL_PAGE)
  const [pageBooks, setPageBooks] = useState<number>(INITIAL_PAGE)
  const [searchBook, setSearchBook] = useState<string>('')
  const [books, setBooks] = useState<Books>(INITIAL_STATE_BOOKS)
  const [booksGral, setBooksGral] = useState<any>([])
  const [totalBooks, setTotalBooks] = useState<number>(INITIAL_TOTAL)
  const [options] = useState<SelectProps<object>['options']>([])
  const [tabs, setTabs] = useState<TabsProps['items']>([])
  const [selectedLevel, setSelectedLevel] = useState<any>(undefined)
  const [idSchool, setIdSchool] = useState<any>()
  const [idLevel, setIdLevel] = useState<any>()
  const [idGrade, setIdGrade] = useState<any>()
  const [booksSchool, setBooksSchool] = useState<any>([])
  const [idBookSchool, setIdBookSchool] = useState<any>({})
  const [schoolsCatalog, setSchoolCatalog] = useState<Options[]>([])
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
  const [searchGralBook, setSearchGralBook] = useState<string>('')
  const [selectSchool, setSelectSchool] = useState<string>('')
  const [selectStatus, setSelectStatus] = useState<string>('')
  const [saveAction, setSaveAction] = useState<any>(false)

  const [paramsUri, setParamsUri] = useSearchParams()
  const search = paramsUri.get('collegeSearch') || false

  const [changeViewAdmin, setChangeViewAdmin] = useState<any>('all')

  const {
    booksSchool: reduxBooksSchool,
    sendAction,
    levelsBook,
    levelsBooksAdmin,
    IDLevel,
    oldISBN,
    newStructure,
    idBookAdmin,
    dataCollege
  } = useSelector((state: RootState) => state.books)

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const { action } = useParams()

  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { state } = useLocation()

  const changeUriParams = (id: number | string) => {
    const params22 = { collegeSearch: `${id}` }
    setParamsUri(id !== '' ? params22 : {})
    navigate({
      pathname: '/libros',
      search: id !== '' ? `?${createSearchParams(params22)}` : ''
    })
  }

  const onHandleDeleteBook = (body: any) => {
    setModalDelete(true)
    setIdBookSchool(body)
  }

  const handleDeleteBookSchool = async (): Promise<any> => {
    try {
      await services.deleteSchoolBook(idBookSchool?.id)

      dispatch(deleteBook(idBookSchool?.id))
      dispatch(deleteBookAdmin(idBookSchool?.id))
      SuccessToast('Libro eliminado con éxito')
      setModalDelete(false)

      const lengthBook: number =
        reduxBooksSchool.filter(
          (item: { idGrade: any }) => item.idGrade === idGrade
        ).length - 1

      if (lengthBook === 0) {
        await services.endListBooks({ id_grade: idGrade, close: false })

        dispatch(updateEndList({ idGrade, status: false }))
      }
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const bookColumns: ColumnsType<BooksTable> = [
    {
      title: 'ISBN',
      dataIndex: 'isbn',
      key: 'isbn',
      width: 150
    },
    {
      title: 'Título',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Editorial',
      dataIndex: 'editorial',
      key: 'editorial',
      width: 150
    },
    {
      title: 'Edición',
      dataIndex: 'edition',
      key: 'edition',
      width: 150
    },
    {
      title: 'Tipo',
      dataIndex: 'format',
      key: 'format',
      width: 150
    },
    {
      title: 'Idioma',
      dataIndex: 'language',
      key: 'language',
      width: 150
    },
    {
      title: 'Precio',
      dataIndex: 'special_price',
      key: 'special_price',
      width: 100,
      render: (text: number, field: any) => {
        let money = field.price
        if (field.special_price !== '0.00' && field.special_price !== null)
          money = field.special_price
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(money)
      }
    },

    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 300,
      render: (_, { status, last_comment, edition }: any) => (
        <>
          {(() => {
            let title = setStatus(status)
            if (title === 'Con observaciones' && last_comment)
              title = `${title} de ${last_comment}`

            if (uid.role === 'Ezetera' && !edition && status === 4)
              title += ' datos incompletos'

            return <Tag variation={status} title={title} />
          })()}
        </>
      )
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (_: any, record) => (
        <>
          {((uid.role !== 'Ezetera' &&
            uid.role !== 'Director' &&
            (record.status === 1 ||
              record.status === 2 ||
              record.status === 3 ||
              record.status === 4 ||
              record.status === 5) &&
            !record.close) ||
            (record.close && record.status === 3)) && (
            <Tooltip title='Eliminar libro'>
              <TrashIcon
                onClick={async (e) => {
                  e.stopPropagation()
                  onHandleDeleteBook(record)
                }}
                style={{ color: '#EF5D60', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
          {uid.role === 'Ezetera' &&
            (record.status === 1 ||
              record.status === 2 ||
              record.status === 3) && (
              <Tooltip title='Editar libro'>
                <PencilSquareIcon
                  onClick={async (e) => {
                    e.stopPropagation()
                    dispatch(
                      setDataCollege({
                        idSchool: record?.id_school,
                        idLevel: record?.id_level,
                        idGrade: record?.id_grade
                      })
                    )
                    navigate('/libros/editar', { state: record })
                  }}
                  style={{ color: '#7FA5EA', cursor: 'pointer' }}
                />
              </Tooltip>
            )}
        </>
      )
    }
  ]

  const getBooks = async (
    page: number,
    school: string,
    status: string,
    search: string,
    fn?: () => void
  ): Promise<any> => {
    try {
      setLoading(true)
      const res = await services.getBooks(page, school, status, search)
      setBooks(res?.data?.data)
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  const SchoolCatalog = async (): Promise<any> => {
    try {
      const res = await servicesSchool.getCatalogSchools()

      setSchoolCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.schools
      ])
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const StatusCatalog = async (): Promise<any> => {
    try {
      const res = await services.getCatalogStatus()

      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      SchoolCatalog()
      StatusCatalog()
    }
  }, [])

  useEffect(() => {
    if (uid.role === 'Ezetera') {
      getBooks(pageBooks, selectSchool, selectStatus, searchGralBook)
    } else {
      idGrade && handleCollapseBooks(idGrade)
    }
  }, [pageBooks, selectSchool, selectStatus, sendAction])

  useEffect(() => {
    if (uid.role !== 'Ezetera') {
      try {
        if (levelsBook.length === 0 || newStructure) {
          setLoading(true)

          const getSchoolBooks = async (): Promise<any> => {
            const data = await getLevels({})
            return data
          }

          getSchoolBooks().then((res) => {
            const filteredLevelsByPermision = filterlevelsByPermission(
              res.data.levels,
              uid.responsabilities,
              uid.role
            )
            const TabsByLevels = filteredLevelsByPermision.map((res: any) => {
              return {
                key: res.id,
                label: res.name,
                grades: res.grades
              }
            })

            dispatch(setLevelsBooks(TabsByLevels))
            dispatch(setIDLevel(TabsByLevels[0]?.key))
            setTabs(TabsByLevels)

            setSelectedLevel(levelsBook[0])
            setIdLevel(levelsBook[0]?.key)
            setIdSchool(uid?.id_school as number)

            dispatch(setNewStructureBook(false))
          })
        }
      } catch (error) {
        ErrorToast('Ups! Algo salio mal.')
      } finally {
        setLoading(false)
      }
    }
  }, [saveAction])

  const onNavigate = (route: string): void => {
    navigate(route)
  }

  const onHandleChangePage = (page: number): void => {
    setPageBooks(page)
  }

  ///INPUT SEARCH

  const fetchSearchBooks = async (
    page: number,
    searchBook: string
  ): Promise<any> => {
    try {
      setLoading(true)
      const res = await services.searchBook(searchBook, page)
      setBooksGral([...booksGral, ...res?.data?.data?.books])
      setTotalBooks(res?.data?.data?.totalPages)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (uid.role !== 'Ezetera') {
      if (!modalSearch) return
      fetchSearchBooks(page, searchBook)
    }
  }, [page, searchBook, modalSearch])

  const openModalSearch = () => {
    setModalSearch(true)
  }

  const onHandleSearch = async (value: string): Promise<any> => {
    setPage(INITIAL_PAGE)
    setBooksGral([])
    setSearchBook(value)
  }

  const onHandleCancelModalSearch = (): void => {
    setPage(INITIAL_PAGE)
    setModalSearch(false)
    setBooksGral([])
    // setSearchBook('')
    setTotalBooks(INITIAL_TOTAL)

    // inputSearch.
  }

  /// END SEARCH

  const onHandleNewBook = (route: string): void => {
    form.resetFields()
    setActions(INITIAL_STATE_BOOK)
    onNavigate(route)
  }

  const setTitle = (title: string): string => {
    if (!action) return 'Libros'

    if (title === 'nuevo') return 'Solicitar nuevo recurso'

    if (title === 'editar') return actions.name

    return ''
  }

  const onHandleEditData = (route: string, values: BooksTable): void => {
    setModalSearch(false)
    navigate(route, { state: values })
  }

  const onHandleForm = async (values: BooksData): Promise<any> => {
    try {
      setLoading(true)
      let idBook: any = actions?.id
      if (actions.action === 'edit') {
        const checkISBN = values?.isbn === oldISBN ? undefined : values.isbn
        let id_book_school: any = actions?.id_school_book
        const { isbn, ...rest } = values

        const sendValues = {
          isbn: checkISBN,
          ...rest,
          id_book_school,
          dataCollege
        }

        const res = await services.updateBook(
          state?.id_book || state?.id,
          sendValues
        )

        res?.data?.message === 'existe'
          ? dispatch(
              updateFromBook({
                id: id_book_school,
                values: res?.data?.bookSchool
              })
            )
          : dispatch(updateBook({ id: actions?.id, values }))
      } else {
        const sendData = {
          id_school: uid?.id_school as number,
          id_level: IDLevel,
          id_grade: idGrade
        }

        idBook = await services.createBook({ ...values, ...sendData })
      }

      form.resetFields()
      setPage(INITIAL_PAGE)
      onNavigate('/libros')
      if (uid.role !== 'Ezetera') {
        handleCollapseBooks(idGrade)
        dispatch(setIDGrade([idGrade]))
      }
      SuccessToast(actions.toast)

      if (uid.role === 'Ezetera') {
        getBooks(1, selectSchool, selectStatus, searchGralBook, () => undefined)
      }
    } catch (err: any) {
      ErrorToast(err?.response?.data?.error)
    } finally {
      setLoading(false)
      setSaveAction((prev: boolean) => !prev)
    }
  }
  const handleAssignBook = async (values: BooksData): Promise<any> => {
    try {
      setLoading(true)

      if (values.source === 'db') {
        const sendData = {
          id_school: uid?.id_school as number,
          id_level: IDLevel,
          id_grade: idGrade,
          id_book: values?.id
        }

        const resp = await services.createSchoolBook(sendData)

        await services.multipleBookStatus({
          id: [resp?.data?.Id],
          status: +[resp?.data?.status]
        })
        dispatch(
          changeStatusBook({
            id: [resp?.data?.Id],
            status: +[resp?.data?.status]
          })
        )
      }

      if (values.source === 'aws') {
        const sendData = {
          ...values,
          id_school: uid?.id_school as number,
          id_level: IDLevel,
          id_grade: idGrade
        }

        await services.createAwsBook(sendData)
      }

      setPage(INITIAL_PAGE)
      onNavigate('/libros')
      handleCollapseBooks(idGrade)
      dispatch(setIDGrade([idGrade]))

      setModalSearch(false)

      onHandleCancelModalSearch()

      SuccessToast('Libro asignado con éxito.')
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    } finally {
      setLoading(false)
    }
  }

  const onHandleCancelModal = (): void => {
    setModalMassive(false)

    setModalSearch(false)
    setModalDelete(false)
    setIdBookSchool({})
    onNavigate('/libros')
  }

  const handleChangeLevelTab = (key: string) => {
    setIdLevel(key)
    dispatch(setIDLevel(key))
    setSelectedLevel(tabs?.filter((res) => res.key === key)[0])
  }

  const handleCollapseBooks = async (idGrade: number): Promise<void> => {
    setLoading(true)

    try {
      const School: number = uid.id_school
        ? (uid.id_school as number)
        : idSchool
      const response = await services.getSchoolBooks(School, IDLevel, idGrade)

      const saveResponse = response?.data?.books.map((book: any) => {
        return {
          ...book,
          idGrade
        }
      })

      dispatch(setBooksSchoolArray(saveResponse))

      setBooksSchool(response?.data?.books)
    } catch (error) {
      ErrorToast('Ups Algo salio mal')
    } finally {
      setLoading(false)
    }
  }

  const getPDFListLogic = async () => {
    try {
      const result = await getGeneralModulesStatus()
      if (
        pathname.includes('libros') &&
        result.data.books_status === 'Completado'
      )
        dispatch(setCloseSupplyList(true))
    } catch (error) {
      console.log('error get pdf list', error)
    }
  }

  const handleReviewStatus = async (): Promise<any> => {
    try {
      const sendData = reduxBooksSchool
        .filter((item: { idGrade: any }) => item.idGrade === idGrade)
        .filter((item: any) => item.status === 1)
        .map((item: any) => item.id)

      await services.multipleBookStatus({ id: sendData, status: 2 })
      await services.endListBooks({ id_grade: idGrade, close: true })
      dispatch(changeStatusBook({ id: sendData, status: 2 }))
      dispatch(updateCloseBook({ idGrade: idGrade, close: true }))
      dispatch(updateEndList({ idGrade, status: true }))
      SuccessToast('Libros en revisión')
      setModalEndList(false)
      getPDFListLogic()
    } catch (error) {
      ErrorToast('Algo salio mal')
    }
  }

  useEffect(() => {
    if (search && books.total !== 0) {
      onHandleChangeSchool(search)
    }
  }, [paramsUri, books])

  const onHandleChangeSchool = async (value: string): Promise<any> => {
    setPageBooks(INITIAL_PAGE)
    setSelectSchool(value === 'todos' ? '' : value)

    setChangeViewAdmin(value === 'todos' ? 'all' : 'school')

    if (value !== 'todos') {
      changeUriParams(value)
      try {
        setLoading(true)

        const getSchoolBooks = async (): Promise<any> => {
          const data = await getLevelsBySchool(value)
          return data
        }

        getSchoolBooks().then((res) => {
          const TabsByLevels = res.data.levels.map((res: any) => {
            return {
              key: res.id,
              label: res.name,
              grades: res.grades
            }
          })

          dispatch(setLevelsBooksAdmin(TabsByLevels))

          setSelectedLevel(levelsBooksAdmin[0])
          setIdLevel(TabsByLevels[0]?.key)
          setIdGrade(TabsByLevels[0]?.grades?.[0]?.id)
          setIdSchool(value)
        })
      } catch (error) {
        ErrorToast('Ups! Algo salio mal.')
      } finally {
        setLoading(false)
      }
    } else changeUriParams('')
  }

  const onHandleChangeStatus = (value: string) => {
    setPageBooks(INITIAL_PAGE)
    setSelectStatus(value === 'todos' ? '' : value)
  }

  const debouncedSearch = React.useRef(
    lodash.debounce(async ({ selectSchool, selectStatus, value }: any) => {
      const res = await services.getBooks(
        1,
        selectSchool,
        selectStatus,
        value ? value : ''
      )
      setBooks(res?.data?.data)
    }, 300)
  ).current

  const onHandleSearchBooks = (value: string) => {
    setPageBooks(INITIAL_PAGE)
    setSearchGralBook(value ? value : '')
    debouncedSearch({
      selectSchool,
      selectStatus,
      value
    })
  }

  const onHandleMultipleStatus = async (values: any): Promise<void> => {
    try {
      await services.multipleBookStatus(values)
      dispatch(changeStatusBook(values))
      SuccessToast('Libros autorizados correctamente')
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    }
  }

  const handleFormComments = async (
    values: any,
    id: number,
    idBook: number
  ): Promise<any> => {
    try {
      const checkISBN = values?.isbn === oldISBN ? undefined : values.isbn
      let id_book_school: any = actions?.id_school_book

      const { isbn, ...rest } = values

      const sendValue = {
        isbn: checkISBN,
        ...rest,
        id_book_school,
        dataCollege
      }

      onNavigate('/libros')

      const sendValues = { id: id, ...sendValue }
      const res = await services.updateBook(
        idBook || state?.id_book,
        sendValues
      )

      getBooks(
        pageBooks,
        selectSchool,
        selectStatus,
        searchGralBook,
        () => undefined
      )
      SuccessToast('Datos actualizados con éxito.')

      res?.data?.message === 'existe'
        ? dispatch(
            updateFromBookAdmin({
              id: id_book_school,
              values: res?.data?.bookSchool
            })
          )
        : dispatch(updateSchoolBookAdmin({ id: idBookAdmin, values }))
      // dispatch(setopenComments(false))
    } catch (error: any) {
      ErrorToast(error?.response?.data?.error)
    }
  }

  const downloadBooks = async (): Promise<any> => {
    try {
      setLoading(true)
      // const res = await services.downloadBooks(selectStatus, searchGralBook, selectSchool)
      const res = await services.downloadGralBooks(selectSchool)
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = 'html-to-img.png'
      link.href = url
      link.click()

      // SuccessToast('Libros Descargados con éxito.')
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  const donwloadLogs = async () => {
    setLoading(true)
    try {
      const res: any = await getLogs('book')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')
    }
    setLoading(false)
  }

  const onHandleCancelModalAuthorize = () => {
    setModalAuthorize(false)
  }
  const onHandleCancelModalEndList = () => {
    setModalEndList(false)
  }
  const onHandleCancelModalChanges = () => {
    setModalChanges(false)
  }

  const onHandleIndividualAuthorize = (idGrade: number) => {
    setModalAuthorize(true)
    dispatch(setIDGradeAuth(idGrade))
    dispatch(setIsBookAuth(true))
  }
  const onHandleOpenEndList = (idGrade: number) => {
    setIdGrade(idGrade)
    setModalEndList(true)
  }
  const onHandleOpenChanges = (idGrade: number) => {
    setIdGrade(idGrade)
    setModalChanges(true)
  }

  const handleRequestChanges = async (): Promise<any> => {
    try {
      await services.requestChangesBooks({
        id_grade: idGrade,
        request_changes: true,
        status: 'sent'
      })
      // await services.endListBooks({ id_grade: idGrade, close: false })
      // dispatch(updateEndList({ idGrade, status: false }))
      setModalChanges(false)
      SuccessToast(
        'Se ha solicitado la modificación para esta lista, un administrador se encargará de verificar su pedido'
      )
    } catch (error) {
      ErrorToast('Ups. Algo salio mal')
    }
  }

  const onHandleCancelModalErrorsMassive = () => {
    setModalErrorsMassive(false)
  }

  return {
    actions,
    loading,
    idSchool,
    idLevel,
    idGrade,
    modalMassive,
    modalSearch,
    modalDelete,
    modalAuthorize,
    modalEndList,
    modalChanges,
    modalErrorsMassive,
    action,
    params,
    form,
    bookColumns,
    books,
    booksGral,
    totalBooks,
    booksSchool,
    options,
    tabs,
    selectedLevel,
    page,
    pageBooks,
    searchBook,
    searchGralBook,
    schoolsCatalog,
    statusCatalog,
    selectSchool,
    selectStatus,
    changeViewAdmin,
    donwloadLogs,
    setSaveAction,
    setSearchBook,
    setSearchGralBook,
    setPage,
    setActions,
    setIdLevel,
    setIdGrade,
    onNavigate,
    onHandleChangePage,
    openModalSearch,
    onHandleSearch,
    fetchSearchBooks,
    onHandleNewBook,
    setTitle,
    setModalMassive,
    setModalSearch,
    setModalAuthorize,
    setModalEndList,
    setModalChanges,
    setModalErrorsMassive,
    onHandleForm,
    onHandleEditData,
    onHandleCancelModal,
    onHandleCancelModalSearch,
    onHandleCancelModalAuthorize,
    onHandleCancelModalEndList,
    onHandleCancelModalChanges,
    onHandleCancelModalErrorsMassive,
    handleChangeLevelTab,
    handleCollapseBooks,
    handleDeleteBookSchool,
    handleAssignBook,
    handleReviewStatus,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleSearchBooks,
    onHandleMultipleStatus,
    handleFormComments,
    downloadBooks,
    setPageBooks,
    getBooks,
    onHandleIndividualAuthorize,
    onHandleOpenEndList,
    onHandleOpenChanges,
    handleRequestChanges
  }
}
