import React from 'react'
import { IconProps } from '../../../types'
export const Logo = ({
  height = 24,
  width = 123,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 123 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.104004 13.1634C0.324787 12.2613 0.476181 11.3356 0.777392 10.4604C2.75971 4.68534 9.54405 3.10043 13.8919 5.61578C16.1092 6.89947 17.15 8.99533 17.5774 11.4303C17.6846 12.0437 17.7319 12.6729 17.7367 13.2959C17.7446 14.3919 17.0996 14.9975 15.9452 15.0006C12.5357 15.0085 9.12456 15.0038 5.71504 15.0054C5.43433 15.0054 5.15362 15.0054 4.86661 15.0054C5.10631 18.724 8.32974 20.9302 12.061 19.9635C12.8858 19.7506 13.668 19.35 14.4439 18.9795C15.3838 18.5316 15.8553 18.6357 16.4199 19.5141C17.5001 21.1952 17.415 21.6919 15.7039 22.6839C14.313 23.4913 12.8274 24.0401 11.1668 23.9786C9.44943 23.9155 7.70997 24.1663 6.05253 23.4519C2.75182 22.0278 0.851512 19.5425 0.263283 16.032C0.225434 15.8049 0.1592 15.581 0.107158 15.3555C0.107158 14.6237 0.107158 13.8936 0.107158 13.1618L0.104004 13.1634ZM13.0703 12.1226C13.1002 9.67503 11.5863 8.03966 9.37215 8.02547C7.0697 8.01127 5.2309 9.86901 5.0385 12.1226H13.0703Z'
        fill='currentColor'
      />
      <path
        d='M59.7959 0.00157856C59.9662 0.526726 60.2501 1.04399 60.2832 1.57702C60.3573 2.74086 60.3068 3.91259 60.3068 5.17105C61.0859 5.17105 61.7861 5.16632 62.4863 5.17105C63.9986 5.18367 64.4118 5.61262 64.4024 7.15967C64.3945 8.46702 63.9009 8.92751 62.4973 8.93067C61.7956 8.93224 61.0954 8.93067 60.4062 8.93067C60.3589 9.08048 60.3163 9.15303 60.3179 9.22557C60.3447 11.7803 60.3289 14.3383 60.4204 16.8899C60.4772 18.4748 61.4707 19.4573 63.0382 19.7143C64.5301 19.9588 64.7083 20.1701 64.7178 21.7014C64.7288 23.6458 64.2147 24.0984 62.2418 23.8934C59.982 23.6585 58.0139 22.8763 56.7412 20.8813C55.9763 19.6828 55.7209 18.3187 55.7082 16.9214C55.6846 14.2894 55.7019 11.6589 55.7019 9.17195C54.915 8.89282 54.136 8.77454 53.5793 8.35979C53.2686 8.12954 53.2434 7.40727 53.2386 6.90577C53.2276 5.54638 53.4641 5.4013 55.8596 5.09378C55.8596 3.91259 55.7981 2.71405 55.888 1.52813C55.9275 1.00614 56.2791 0.509378 56.4889 0C57.5912 0 58.6935 0 59.7959 0V0.00157856Z'
        fill='currentColor'
      />
      <path
        d='M109.913 5.53536C110.473 4.94871 112.626 4.53238 113.511 5.0733C113.843 5.27673 114.059 5.73565 114.223 6.12359C114.339 6.39484 114.288 6.74021 114.288 7.05404C114.291 11.9097 114.291 16.7669 114.288 21.6225C114.288 21.9348 114.269 22.2486 114.23 22.5593C114.143 23.2358 113.793 23.6743 113.068 23.7089C111.915 23.7641 110.699 24.1032 109.729 22.9867C109.502 23.0908 109.246 23.2153 108.986 23.3257C108.018 23.7373 107.035 24.0717 105.952 23.9802C104.476 23.8556 102.979 24.1947 101.513 23.6758C97.9707 22.4253 96.0341 19.861 95.4191 16.2812C94.9507 13.5514 95.3197 10.9051 96.8936 8.55694C98.9485 5.49279 101.951 4.25482 105.556 4.55288C106.987 4.67116 108.384 5.17896 109.913 5.53694V5.53536ZM99.9467 14.671C100.021 15.1221 100.092 15.9043 100.283 16.6565C100.792 18.6625 102.333 19.9619 104.395 20.1985C106.174 20.4019 108.034 19.4541 108.806 17.8093C109.745 15.816 109.787 13.7359 109.108 11.6479C108.543 9.9116 107.373 8.80611 105.548 8.52224C103.612 8.22103 102.041 8.89442 100.95 10.5377C100.186 11.6857 100.011 13.0026 99.9451 14.6695L99.9467 14.671Z'
        fill='currentColor'
      />
      <path
        d='M40.0373 15.0038C40.4946 19.1403 43.9215 21.1557 47.9492 19.7617C48.5642 19.5488 49.143 19.2318 49.7281 18.9448C50.5355 18.5489 51.0528 18.6672 51.5353 19.41C51.734 19.7159 51.917 20.0345 52.0668 20.3672C52.4674 21.2614 52.2844 21.8291 51.4423 22.3559C49.7233 23.4314 47.8987 24.0843 45.8139 23.9912C44.1454 23.9171 42.4754 24.0622 40.8905 23.3084C38.6117 22.225 36.9479 20.5801 36.0332 18.2383C34.8379 15.1773 35.005 12.1399 36.5048 9.23505C37.9682 6.39956 40.4079 4.87301 43.5698 4.55287C45.221 4.38571 46.8405 4.51187 48.4034 5.25465C50.9077 6.44372 52.0968 8.54116 52.6945 11.077C52.8474 11.7299 52.8805 12.4206 52.8947 13.0956C52.9247 14.455 52.3712 15.0006 51.0149 15.0022C47.6574 15.0054 44.3 15.0038 40.9425 15.0038C40.6586 15.0038 40.3748 15.0038 40.0373 15.0038ZM48.2567 12.1494C48.3655 9.80277 46.7948 8.05386 44.6406 8.02862C42.3082 8.00181 40.1634 9.9999 40.2328 12.1494H48.2567Z'
        fill='currentColor'
      />
      <path
        d='M69.8748 15.0037C69.9868 18.541 73.0414 20.6605 76.3027 20.1086C77.3893 19.9256 78.4285 19.4052 79.4583 18.9684C80.384 18.5757 80.884 18.6798 81.4154 19.5535C81.6047 19.8641 81.7986 20.1811 81.9279 20.5186C82.2197 21.2771 82.032 21.8386 81.3523 22.2722C79.6476 23.3635 77.834 24.1189 75.7476 23.9723C74.4229 23.8792 73.0351 24.0022 71.7861 23.6442C67.9855 22.5529 65.855 19.842 65.2163 16.043C64.7337 13.1712 65.2652 10.4272 67.0298 8.0207C69.9931 3.98195 76.5929 3.23444 80.0876 6.52884C81.7182 8.06486 82.3537 10.0503 82.6439 12.1872C82.6755 12.419 82.6692 12.6556 82.6944 12.889C82.8458 14.2799 82.2008 14.999 80.7736 15.0022C77.4397 15.0069 74.1059 15.0037 70.7721 15.0037C70.4882 15.0037 70.206 15.0037 69.8716 15.0037H69.8748ZM78.0469 12.1304C78.1163 9.80904 76.6418 8.12321 74.5459 8.02859C72.2119 7.9245 70.135 9.78223 70.023 12.1304H78.0469Z'
        fill='currentColor'
      />
      <path
        d='M24.6852 19.7443C27.4197 19.7443 30.0833 19.7443 32.7469 19.7443C34.3712 19.7443 34.7545 20.1212 34.7545 21.7077C34.7545 23.4219 34.4138 23.7752 32.6996 23.7783C28.6056 23.7846 24.5117 23.7862 20.4193 23.7752C19.6908 23.7736 18.8234 23.8477 18.4859 23.0482C18.1405 22.2281 18.2746 21.3718 18.8675 20.6211C21.1148 17.7715 23.3447 14.9076 25.5888 12.0563C26.4073 11.0171 27.251 9.99675 28.1105 8.93383C27.6831 8.93383 27.2794 8.93383 26.8772 8.93383C24.8035 8.93383 22.7312 8.93383 20.6575 8.93383C19.1735 8.93226 18.7288 8.46861 18.7288 6.93102C18.7288 5.354 19.1467 4.89981 20.6165 4.89981C24.6048 4.89981 28.593 4.89981 32.5829 4.89981C33.2752 4.89981 34.0716 4.85881 34.3555 5.60632C34.6535 6.39325 34.6393 7.28585 34.0826 7.99866C31.2882 11.5801 28.47 15.1426 25.6519 18.7066C25.368 19.0662 25.0274 19.3832 24.6883 19.7475L24.6852 19.7443Z'
        fill='currentColor'
      />
      <path
        d='M89.0528 8.91968C89.742 8.06178 90.4974 6.84905 91.524 5.93753C92.2163 5.32249 93.2619 5.02601 94.2018 4.78473C95.0833 4.55764 95.6558 5.10486 95.7236 6.0148C95.7599 6.50841 95.7473 7.00675 95.7425 7.50194C95.7283 8.85817 95.3593 9.25874 93.9778 9.35493C93.0947 9.41644 92.2983 9.6388 91.5839 10.2034C89.9249 11.517 89.3729 13.3606 89.2405 15.3098C89.0891 17.5444 89.1537 19.7932 89.1301 22.0373C89.1159 23.3715 88.7532 23.7342 87.4001 23.7784C84.2382 23.8809 84.6419 23.1144 84.6435 21.1605C84.6466 16.3569 84.6435 11.5533 84.645 6.7497C84.645 5.30515 85.0629 4.89827 86.5233 4.89512C86.733 4.89512 86.9428 4.89512 87.1525 4.89512C88.5434 4.89827 89.0292 5.37454 89.0497 6.77808C89.0623 7.61075 89.0512 8.44342 89.0512 8.9181L89.0528 8.91968Z'
        fill='currentColor'
      />
      <path
        d='M122.954 4.55291C122.954 4.78789 122.924 5.0276 122.959 5.25626C123.116 6.26713 122.832 7.09507 121.978 7.69749C121.065 8.34092 120.035 8.49546 118.977 8.25733C116.999 7.81261 115.714 5.49755 116.308 3.54678C116.974 1.36418 119.033 0.271303 121.109 0.99831C122.361 1.43672 122.952 2.25046 122.952 3.53416C122.952 3.87322 122.952 4.21228 122.952 4.55134L122.954 4.55291ZM122.601 4.55765C122.593 2.88443 121.344 1.57235 119.793 1.60862C118.254 1.64331 117.098 2.92228 117.103 4.58288C117.108 6.2561 118.264 7.50667 119.817 7.52087C121.388 7.53506 122.607 6.23717 122.601 4.55922V4.55765Z'
        fill='currentColor'
      />
      <path
        d='M119.368 5.04649C119.368 5.4092 119.406 5.72618 119.352 6.02424C119.324 6.18037 119.131 6.42007 119.018 6.4185C118.865 6.41692 118.589 6.21979 118.584 6.09836C118.548 5.08591 118.556 4.07189 118.57 3.05786C118.57 2.94905 118.669 2.81027 118.764 2.74088C119.094 2.49645 120.738 2.68411 121.003 3.01213C121.456 3.57355 121.456 4.13024 120.959 4.69797C120.854 4.81782 120.876 5.10799 120.931 5.2925C121.032 5.62841 121.204 5.94381 121.347 6.27341C120.702 6.68186 120.522 6.2671 120.3 5.86181C120.114 5.52275 120.149 4.93452 119.37 5.04333L119.368 5.04649ZM119.407 4.56076C119.832 4.37783 120.158 4.30687 120.368 4.11447C120.466 4.02458 120.42 3.57671 120.302 3.49785C120.067 3.34331 119.737 3.33227 119.407 3.25184V4.56076Z'
        fill='currentColor'
      />
    </svg>
  )
}
