import React from 'react'

const GreenTagsTShirt = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='green'
      stroke='green'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Green_Tag" data-name="Green Tag" >
    <g id="Green_Tag-2" data-name="Green Tag">
      <g>
        <path   d="m359.74,38.69c.11-.08.19-.16.3-.24.32-.22.47-.56.63-.88.09-.2.02-.36-.2-.49-.68-.41-1.42-.51-2.2-.51-.02,0-.05,0-.07-.01.28-.08.59,0,.89-.02.08,0,.22.05.25-.05.01-.09-.1-.15-.17-.2-.1-.1-.22-.19-.32-.3.54.37,1.14.6,1.79.7.3.05.39-.02.42-.27.06-.38.1-.76-.15-1.14.44.39.95.72.93,1.37.14-.04.26-.07.39-.09.11-.03.19.05.25.12.11.12-.06.11-.09.15-.21.26-.53.42-.63.78-.06.24-.32.37-.55.49-.46.24-.95.43-1.48.59Z"/>
        <g>
          <path   d="m330.21,32.35l-.06.56s0,.05,0,.06c0,.04-.03.05-.07.04-.04,0-.06-.04-.08-.09-.07-.23-.19-.42-.36-.59-.17-.17-.37-.28-.63-.34-.32-.07-.6-.02-.84.16-.23.18-.38.42-.45.73-.08.34-.04.64.12.9.15.26.37.42.64.49.12.03.24.03.37.01.13-.02.22-.05.26-.09.05-.04.08-.11.1-.21.05-.21.07-.34.06-.4,0-.06-.04-.1-.08-.13-.04-.03-.14-.07-.28-.12-.06-.02-.08-.04-.07-.07,0-.04.04-.05.09-.04l.34.08,1.05.22s.07.03.06.06c0,.02-.02.03-.04.03-.02,0-.09,0-.2,0-.09,0-.14,0-.17.03-.03.02-.09.21-.18.55-.02.08-.03.12-.04.13,0,0-.1.03-.3.07-.19.04-.39.05-.58.04-.2,0-.39-.03-.57-.07-.31-.07-.58-.18-.8-.34s-.39-.34-.5-.56c-.11-.22-.13-.45-.08-.71.08-.36.3-.63.66-.82.36-.19.8-.23,1.33-.11.19.04.35.1.49.17.14.07.3.16.47.27.03.02.07.04.09.04.03,0,.06,0,.08-.04.03-.04.07-.06.1-.05.04,0,.06.05.05.13Z"/>
          <path   d="m332.28,33.85c.2.07.36.15.48.26.12.11.19.28.21.53.02.23.06.39.12.47s.15.14.28.18c.07.02.1.04.1.08,0,.04-.07.04-.18.02l-.88-.2c-.08-.09-.11-.24-.11-.44,0-.25,0-.44-.03-.55-.03-.11-.08-.2-.16-.26s-.18-.11-.3-.13c-.04,0-.08-.02-.15-.02-.09.44-.14.71-.16.81-.02.1,0,.18.03.23.04.05.12.1.25.13.07.02.1.04.09.08s-.04.04-.1.03l-.7-.16c-.09-.02-.22-.04-.37-.06-.14-.02-.22-.04-.26-.04-.06-.01-.09-.04-.08-.07,0-.03.04-.05.09-.04.08.02.17.02.25,0,.08-.02.15-.16.2-.42l.07-.35.14-.67c.07-.35.1-.56.07-.65-.02-.08-.11-.14-.28-.18-.06-.01-.09-.04-.08-.07,0-.04.05-.05.14-.03.43.09.8.15,1.1.2.13.02.25.04.36.06.35.07.61.18.78.34.17.15.24.32.21.5-.02.12-.09.22-.21.3-.11.08-.24.13-.39.15-.15.02-.32.02-.51,0Zm-.61-.21s.07.02.09.02c.13.03.27.03.41.02s.25-.06.33-.14c.07-.08.12-.17.14-.27.03-.16,0-.29-.09-.4-.1-.11-.23-.18-.41-.22-.07-.02-.16-.02-.26-.02l-.2,1Z"/>
          <path   d="m336.6,33.44c.01.2.02.41.04.61,0,.04-.03.05-.08.04-.02,0-.05-.03-.07-.07-.12-.22-.24-.37-.36-.45-.12-.08-.3-.15-.55-.19-.14-.03-.27-.04-.4-.05-.07.22-.13.46-.18.72,0,.04-.02.12-.04.23l.17.03c.11.02.2.03.29.02.08,0,.16-.05.23-.12.07-.07.11-.11.12-.14.02-.04.05-.05.08-.05.04,0,.06.03.06.06,0,0,0,.02-.01.03-.03.07-.08.3-.16.69,0,.05-.01.1,0,.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.04-.03,0-.05-.04-.06-.11,0-.12-.06-.22-.16-.3s-.26-.14-.5-.19c-.05.21-.08.35-.09.41-.05.29-.08.46-.08.51,0,.06.03.11.09.15.06.05.17.08.33.11.58.11.99.03,1.23-.24.03-.04.07-.05.1-.05.04,0,.06.03.06.06,0,.02-.01.04-.03.07l-.25.51-.83-.16c-.23-.04-.45-.09-.65-.14-.2-.04-.36-.08-.47-.1-.13-.02-.32-.05-.58-.09-.03,0-.06,0-.07,0-.07-.01-.1-.04-.1-.08,0-.04.04-.05.1-.04.15.03.25.01.32-.04s.11-.19.15-.39l.19-.96c.06-.33.09-.53.1-.61,0-.08-.01-.13-.05-.17-.04-.04-.11-.07-.23-.11-.05-.01-.07-.04-.06-.07,0-.04.04-.05.1-.04l.55.12,1.85.35Z"/>
          <path   d="m339.65,33.97c.02.2.03.41.04.61,0,.04-.03.05-.08.04-.02,0-.05-.03-.07-.07-.13-.21-.25-.36-.37-.45-.12-.08-.3-.15-.55-.19-.14-.02-.27-.04-.41-.04-.07.22-.12.46-.17.72,0,.04-.02.12-.03.23l.17.03c.11.02.2.02.29.01.08-.01.16-.05.23-.12s.11-.12.12-.14c.02-.04.05-.05.08-.05.04,0,.06.03.06.06,0,0,0,.02,0,.03-.03.07-.08.31-.15.69,0,.05-.01.1,0,.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.04-.03,0-.05-.04-.06-.11-.01-.12-.07-.22-.16-.3-.1-.08-.27-.14-.5-.18-.04.21-.07.35-.08.41-.05.29-.07.46-.07.51,0,.06.03.11.09.15.06.04.17.08.33.11.58.1,1,.01,1.23-.26.03-.04.07-.05.1-.05.04,0,.06.03.06.06,0,.02-.01.04-.03.07l-.24.51-.83-.15c-.23-.04-.45-.08-.65-.13-.2-.04-.36-.07-.48-.09-.13-.02-.32-.05-.58-.08-.03,0-.06,0-.07,0-.07-.01-.1-.04-.1-.08,0-.04.04-.05.1-.04.15.03.25,0,.32-.05.06-.06.11-.19.15-.39l.18-.97c.06-.33.09-.54.09-.61,0-.08-.01-.13-.05-.17-.04-.03-.12-.07-.23-.1-.05-.01-.07-.04-.06-.07,0-.04.04-.05.09-.04l.55.11,1.86.32Z"/>
          <path   d="m341.01,34.17c.63.8,1.15,1.45,1.58,1.94.01-.09.02-.14.03-.17l.12-.75c.03-.18.04-.3.02-.38-.01-.08-.06-.15-.13-.21-.07-.06-.16-.1-.27-.12-.06-.01-.08-.04-.08-.07,0-.04.04-.05.09-.04.03,0,.07.01.11.02.09.02.21.05.38.07.21.03.37.05.49.06.05,0,.09,0,.12.01.06,0,.08.03.08.07,0,.04-.05.05-.13.04-.1,0-.19.01-.29.06s-.15.16-.18.33l-.16,1.07-.14.8c-.01.07-.04.1-.1.09-.01,0-.03,0-.05-.02-.02-.01-.11-.11-.28-.31-.17-.2-.41-.5-.74-.91-.32-.41-.55-.7-.68-.88,0,.03-.06.35-.15.95-.03.19-.04.33-.04.44,0,.11.05.19.14.26s.18.1.26.11c.05,0,.08.03.07.06,0,.04-.04.05-.09.04-.04,0-.08-.02-.13-.03-.09-.02-.21-.05-.35-.07-.23-.04-.4-.06-.5-.06-.07,0-.11,0-.14-.01-.06,0-.09-.04-.08-.08,0-.03.06-.04.15-.03.18.02.29-.02.35-.1.06-.08.1-.19.12-.34.1-.65.16-1.05.18-1.19.01-.15-.02-.29-.1-.42-.08-.13-.2-.22-.34-.27-.06-.02-.09-.04-.08-.07,0-.04.04-.06.1-.05.02,0,.04,0,.07.01.04,0,.1.02.19.03.2.03.39.06.56.09Z"/>
          <path   d="m346.28,34.95c.8.13,1.39.22,1.77.27.29.04.61.08.96.11.13.01.23.02.31.03.03,0,.08.01.15.02,0,.05,0,.16,0,.33v.3s-.04.07-.09.06c-.04,0-.07-.04-.1-.11-.04-.11-.11-.21-.19-.29-.08-.09-.18-.15-.3-.19-.12-.04-.32-.08-.6-.12-.05.19-.1.43-.14.72-.04.3-.07.58-.1.86s-.03.45-.02.51c.01.06.04.11.08.14.04.03.15.07.33.1.06.01.09.03.08.07,0,.04-.03.05-.09.04h-.06s-.48-.09-.48-.09c-.37-.05-.6-.08-.68-.09l-.29-.03s-.07-.01-.14-.02c-.03-.01-.05-.03-.05-.05,0-.04.04-.05.1-.04.14.02.24,0,.32-.03s.13-.11.16-.2c.03-.1.07-.32.12-.66.07-.54.11-.98.12-1.32-.25-.04-.44-.05-.56-.05-.12,0-.23.03-.35.1-.12.06-.21.15-.3.26-.03.04-.07.06-.1.06-.05,0-.08-.03-.07-.07,0-.01.02-.09.06-.25s.08-.28.12-.38Z"/>
          <path   d="m350.9,35.53l.16.02c.16.39.37.99.64,1.82.13.38.22.61.27.69.06.08.15.14.29.18.05.01.07.03.06.06,0,.04-.04.05-.1.05-.03,0-.12-.02-.25-.05-.16-.03-.29-.06-.4-.07-.11-.01-.25-.03-.42-.04-.13,0-.21-.02-.25-.02-.07,0-.1-.03-.1-.07,0-.03.03-.04.08-.04.19.01.29-.04.31-.15,0-.05,0-.15-.05-.29-.04-.14-.08-.27-.13-.39-.1-.01-.18-.02-.23-.03-.31-.04-.55-.07-.73-.09-.17.28-.26.47-.28.57,0,.07.02.13.08.17.06.05.13.08.21.09.06.01.08.04.08.07,0,.03-.04.05-.1.04-.02,0-.09-.01-.19-.03-.13-.03-.23-.04-.29-.05-.12-.02-.24-.02-.35-.03-.03,0-.05,0-.07,0-.06,0-.08-.03-.08-.07,0-.03.02-.04.06-.05.1,0,.17-.03.23-.05.06-.02.12-.08.19-.16.07-.08.16-.22.27-.4.25-.42.61-.98,1.09-1.68Zm-.25.64l-.51.8.82.1c-.03-.1-.08-.25-.15-.43-.07-.2-.13-.35-.16-.46Z"/>
          <path   d="m355.68,36.38v.56s0,.05,0,.06c0,.04-.03.06-.07.05-.04,0-.07-.03-.09-.08-.1-.22-.23-.4-.42-.55-.18-.15-.4-.24-.66-.27-.33-.04-.6.04-.82.25s-.34.46-.38.77c-.04.34.03.64.21.88.18.24.41.38.69.42.12.01.24,0,.37-.02.13-.03.21-.07.25-.12.04-.05.07-.12.08-.21.03-.21.03-.35.02-.4-.01-.05-.04-.1-.09-.12-.05-.03-.14-.05-.29-.09-.06-.01-.08-.03-.08-.07,0-.04.03-.05.09-.05l.35.04,1.07.11s.07.02.07.06c0,.02-.01.03-.03.04-.02,0-.09,0-.2.02-.08,0-.14.02-.17.05-.03.03-.07.22-.12.56-.01.08-.02.12-.02.13,0,0-.1.04-.29.1-.19.05-.38.09-.58.1-.2.01-.39.01-.58-.01-.32-.04-.6-.12-.83-.26-.24-.13-.42-.3-.55-.51-.13-.21-.18-.44-.15-.69.04-.36.23-.66.57-.89.34-.23.77-.31,1.31-.25.19.02.36.06.5.12.15.06.31.13.5.22.04.02.07.03.1.03.03,0,.06-.01.08-.05.03-.05.06-.07.09-.06.04,0,.06.05.06.13Z"/>
        </g>
      </g>
      <g>
        <g>
          <path   d="m385.97,38.92l.04.56s0,.05,0,.06c0,.04-.02.06-.07.06-.04,0-.07-.03-.09-.07-.11-.21-.26-.39-.45-.53-.19-.14-.42-.22-.67-.23-.33-.02-.6.08-.8.3s-.31.48-.33.79c-.02.35.07.64.26.87s.43.36.71.37c.12,0,.24,0,.37-.05.13-.04.21-.08.25-.13.04-.05.06-.12.07-.22.01-.21,0-.35,0-.4-.02-.05-.05-.09-.1-.12-.05-.02-.14-.05-.29-.07-.06,0-.09-.03-.08-.06,0-.04.03-.05.08-.05l.35.02,1.08.04s.07.02.07.05c0,.02-.01.03-.03.04-.02,0-.08.02-.2.03-.08.01-.14.03-.17.06-.02.03-.05.22-.09.57,0,.08-.01.12-.02.13,0,0-.1.05-.28.11-.19.07-.38.11-.57.14-.19.03-.39.03-.58.02-.32-.02-.6-.09-.85-.2-.25-.12-.44-.27-.58-.47s-.21-.42-.19-.68c.02-.37.19-.67.52-.92.32-.25.75-.36,1.29-.33.19.01.36.04.51.09.15.05.32.11.51.19.04.02.07.03.1.03.03,0,.06-.02.08-.05.02-.05.05-.07.09-.07.04,0,.07.04.07.12Z"/>
          <path   d="m388.3,40.09c.21.04.38.1.51.18.13.09.23.25.28.49.05.23.12.38.18.45.07.07.17.12.3.13.07,0,.11.03.11.06,0,.04-.06.05-.18.05l-.9-.06c-.09-.08-.15-.22-.17-.42-.03-.25-.07-.43-.11-.54-.04-.11-.11-.18-.2-.23s-.2-.08-.32-.08c-.04,0-.08,0-.15,0-.02.45-.04.72-.04.83,0,.1.02.18.06.23.04.05.13.08.26.09.07,0,.1.03.1.06,0,.03-.03.05-.09.05l-.71-.05c-.1,0-.22,0-.37,0-.14,0-.23,0-.26,0-.06,0-.09-.02-.09-.06,0-.04.03-.05.09-.05.09,0,.17,0,.24-.04.08-.03.12-.18.13-.45l.02-.35.04-.68c.02-.35,0-.57-.02-.65-.03-.08-.13-.13-.3-.14-.07,0-.1-.02-.1-.06,0-.04.05-.05.13-.05.44.02.81.03,1.12.03.14,0,.26,0,.37,0,.35.02.63.09.82.21.19.13.29.28.28.47,0,.12-.06.23-.16.33-.1.09-.22.16-.37.2s-.31.06-.5.07Zm-.63-.11s.07,0,.09,0c.14,0,.27,0,.41-.04.14-.03.24-.1.3-.18.06-.09.09-.18.1-.29,0-.16-.04-.29-.15-.38-.11-.1-.26-.15-.44-.16-.08,0-.16,0-.26.02l-.05,1.02Z"/>
          <path   d="m392.53,39.07c.04.2.08.4.12.6,0,.04-.02.05-.07.05-.02,0-.05-.02-.08-.06-.15-.2-.29-.33-.42-.4s-.32-.11-.57-.12c-.14,0-.28,0-.41.01-.04.22-.06.47-.08.74,0,.05,0,.12,0,.23h.18c.11.02.21,0,.29-.01.08-.02.15-.07.21-.15.06-.08.09-.13.1-.15.02-.04.04-.06.08-.06.04,0,.06.02.06.05,0,0,0,.02,0,.03-.02.08-.04.31-.06.71,0,.05,0,.1.01.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.05-.03,0-.06-.04-.07-.1-.03-.12-.09-.21-.2-.28-.11-.07-.28-.11-.52-.12-.02.21-.03.35-.03.42-.01.29-.02.46,0,.52,0,.06.05.1.11.14.07.04.18.06.35.07.59.03.99-.11,1.19-.41.03-.04.06-.06.09-.06.04,0,.07.02.07.05,0,.02,0,.05-.02.08l-.18.54-.84-.04c-.24-.01-.46-.03-.67-.04-.21-.02-.37-.03-.48-.03-.13,0-.33,0-.59,0-.03,0-.06,0-.07,0-.07,0-.11-.02-.11-.06,0-.04.03-.05.09-.05.15,0,.25-.02.31-.09.06-.06.09-.2.1-.41l.06-.98c.02-.34.02-.54.01-.62,0-.08-.03-.13-.07-.16-.04-.03-.12-.05-.24-.07-.05,0-.07-.03-.07-.06,0-.04.03-.06.09-.05l.56.04,1.88.09Z"/>
          <path   d="m395.65,39.19c.04.2.08.4.12.6,0,.04-.02.05-.07.05-.02,0-.05-.02-.08-.06-.15-.2-.29-.33-.42-.39-.13-.07-.32-.1-.57-.11-.14,0-.28,0-.41.01-.04.22-.06.47-.07.74,0,.05,0,.12,0,.23h.18c.11.01.21,0,.29-.02.08-.02.15-.07.21-.15.06-.08.09-.13.1-.15.02-.04.04-.06.07-.06.04,0,.06.02.06.05,0,0,0,.02,0,.03-.02.08-.04.31-.06.71,0,.05,0,.1.01.15,0,.01,0,.02,0,.03,0,.04-.03.05-.08.05-.03,0-.06-.04-.08-.1-.03-.12-.09-.21-.2-.28-.11-.07-.28-.1-.52-.12-.02.21-.03.35-.03.42-.01.29-.01.46,0,.52s.05.1.11.14c.07.04.18.06.35.06.59.02.99-.11,1.19-.41.03-.04.06-.06.09-.06.04,0,.07.02.07.05,0,.02,0,.05-.02.08l-.17.54-.84-.04c-.24-.01-.46-.02-.67-.04-.21-.02-.37-.03-.48-.03-.13,0-.33,0-.59,0-.03,0-.06,0-.07,0-.07,0-.11-.02-.11-.06,0-.04.03-.05.09-.05.15,0,.25-.02.31-.09.05-.06.09-.2.09-.41l.05-.98c.01-.34.02-.54,0-.62,0-.08-.03-.13-.07-.16-.04-.03-.12-.05-.24-.07-.05,0-.07-.03-.07-.06,0-.04.03-.06.09-.05l.56.04,1.88.08Z"/>
          <path   d="m397.06,39.23c.72.72,1.32,1.3,1.81,1.73,0-.09,0-.14,0-.17l.03-.75c0-.18,0-.31-.02-.38-.02-.08-.07-.14-.15-.19-.08-.05-.17-.08-.28-.09-.06,0-.09-.03-.08-.06,0-.04.03-.05.08-.05.03,0,.07,0,.11.01.09.01.22.02.39.03.21,0,.38,0,.49,0,.05,0,.09,0,.12,0,.06,0,.09.02.09.06,0,.04-.04.06-.12.06-.1,0-.19.03-.28.09-.09.06-.13.17-.14.35l-.03,1.08-.04.81c0,.07-.03.1-.08.1-.01,0-.03,0-.05-.01s-.12-.1-.31-.28c-.19-.18-.47-.45-.84-.81s-.63-.63-.78-.79c0,.03-.02.35-.04.96,0,.19,0,.33.01.44.02.11.07.19.17.24.1.05.19.08.27.08.05,0,.08.02.08.05,0,.04-.03.06-.09.05-.04,0-.08,0-.14-.01-.09-.01-.21-.02-.36-.03-.23,0-.4,0-.5,0-.07,0-.11,0-.14,0-.06,0-.09-.02-.09-.07,0-.03.05-.05.15-.05.18,0,.29-.05.33-.14.05-.09.07-.21.08-.36.02-.66.03-1.06.03-1.2,0-.15-.06-.28-.15-.4-.1-.12-.22-.2-.37-.23-.06-.01-.09-.03-.09-.06,0-.04.03-.06.1-.06.02,0,.04,0,.07,0,.04,0,.1,0,.19.01.2,0,.39.02.56.02Z"/>
          <path   d="m401.16,39.34c.81.04,1.4.06,1.79.07.29,0,.62,0,.97,0,.13,0,.23,0,.31,0,.03,0,.08,0,.16,0,0,.05.02.16.03.32l.03.3s-.03.07-.08.07c-.04,0-.07-.03-.11-.09-.06-.1-.13-.19-.22-.27-.09-.08-.2-.13-.32-.16s-.32-.04-.61-.05c-.03.19-.05.44-.05.73,0,.3,0,.59,0,.87,0,.28.02.45.04.5.02.06.05.1.1.13.05.03.16.05.34.06.06,0,.09.02.09.06,0,.04-.03.05-.08.05h-.06s-.48-.03-.48-.03c-.38,0-.61-.01-.69-.01h-.29s-.07,0-.14,0c-.04,0-.05-.02-.05-.05,0-.04.03-.05.09-.05.14,0,.24-.02.31-.06s.12-.12.13-.22c.02-.1.03-.32.04-.67.01-.54,0-.98-.03-1.32-.26,0-.44,0-.56.01s-.23.06-.33.13-.2.17-.27.29c-.03.05-.06.07-.09.07-.05,0-.08-.02-.08-.06,0-.01.01-.09.04-.25.02-.16.05-.28.08-.39Z"/>
          <path   d="m405.8,39.41h.16c.2.37.48.95.83,1.74.17.36.28.58.34.66s.17.13.31.15c.05,0,.07.02.07.05,0,.04-.03.06-.09.06-.03,0-.12,0-.25-.02-.16-.01-.3-.02-.41-.03-.11,0-.25,0-.42,0-.13,0-.21,0-.25,0-.07,0-.11-.02-.11-.06,0-.03.03-.04.08-.05.19,0,.29-.07.29-.18,0-.05-.03-.15-.08-.28s-.11-.26-.17-.38c-.1,0-.18,0-.24,0-.31,0-.56-.01-.73-.01-.14.3-.21.5-.21.59,0,.07.03.12.1.16.07.04.14.06.22.07.06,0,.09.03.09.06,0,.03-.03.05-.1.05-.03,0-.09,0-.2-.01-.14-.01-.24-.02-.3-.02-.12,0-.24,0-.35.01-.03,0-.05,0-.07,0-.06,0-.09-.02-.08-.06,0-.03.02-.05.06-.05.09-.02.17-.04.22-.08.05-.03.11-.09.18-.18.06-.09.14-.23.23-.43.2-.44.5-1.04.9-1.79Zm-.18.66l-.42.85h.83c-.04-.09-.11-.23-.19-.41-.09-.19-.16-.34-.21-.44Z"/>
          <path   d="m410.66,39.75l.06.56s0,.05,0,.06c0,.04-.02.06-.06.06-.04,0-.07-.02-.1-.07-.12-.21-.28-.38-.47-.51-.2-.13-.42-.2-.68-.2-.33,0-.59.11-.79.33-.19.23-.29.49-.29.8,0,.35.09.63.3.86.21.22.45.34.73.34.12,0,.24-.02.37-.06s.2-.09.24-.14c.04-.05.05-.12.06-.22,0-.21,0-.35-.03-.4-.02-.05-.05-.09-.1-.11-.05-.02-.15-.04-.29-.06-.06,0-.09-.02-.09-.06,0-.04.03-.05.08-.05h.35s1.08,0,1.08,0c.05,0,.07.02.07.05,0,.02,0,.03-.03.04-.02,0-.08.02-.2.04-.08.01-.14.04-.16.07-.02.03-.04.22-.06.57,0,.08,0,.12-.01.13,0,0-.1.05-.28.13-.18.07-.37.13-.56.16s-.38.05-.57.05c-.32,0-.61-.06-.86-.17-.25-.11-.45-.25-.6-.45-.15-.19-.22-.42-.22-.67,0-.37.16-.68.48-.94.31-.26.74-.39,1.27-.38.19,0,.36.02.51.06.15.04.33.1.52.17.04.01.07.02.1.02.03,0,.06-.02.07-.05.02-.05.05-.07.09-.07.04,0,.07.04.08.12Z"/>
        </g>
        <path   d="m414.04,41.27c.12-.05.23-.1.36-.14.37-.11.62-.39.86-.65.15-.17.12-.33-.05-.52-.53-.6-1.21-.91-1.94-1.16-.01-.02-.04-.02-.06-.04.3,0,.56.18.86.26.08.03.19.12.24.03.07-.07-.04-.16-.08-.24-.07-.12-.16-.24-.21-.38.4.53.89.93,1.47,1.22.26.14.39.08.49-.13.16-.35.33-.69.21-1.13.3.49.68.98.46,1.58.16.01.28.02.41.03.12,0,.17.1.2.19.05.15-.09.09-.14.12-.26.18-.64.24-.82.56-.13.21-.42.25-.68.29-.51.08-1.04.11-1.59.11Z"/>
      </g>
      <g>
        <g>
          <path   d="m429.59,78.25s1.9-.87,2.24-1.04c0,0,.04-.02.01-.07-.04-.02-.01-.1.23-.31,0,0,.76-.56,1.08-1.09.29-.51.37-.72.13-1-.24-.3-.91-1.08-1.8-1.59-.89-.53-1.72-.94-2.49-1.17-.73-.26-1.26-.33-1.69-.41-.43-.09-.16-.14.23-.14.39.02,1.28.24,2.01.49.72.22,1.22.26,1.22.26,0,0-.08-.51-.49-1.05-.39-.51-.94-1.35-1.4-1.89-.47-.53-.71-.78-.65-.82.04-.06.14-.04.93.93.81.94,1.08,1.4,1.53,1.89.46.49.81.94,1.48,1.33.66.4,1.61.94,2.49,1.17,0,0,.59-.58.86-1.4.27-.81.26-.72.29-.67.02.04.06.13.06-.33s-.06-1.28-.09-1.43c-.01-.12,0-.14.07-.16.06-.02.2.24.38.55.18.32.52.92.78,1.59.23.69.5,1.29,0,2.22,0,0-.06.03.01.03.05,0,.45.01.61-.02.18,0,.53-.15.72.03.19.19.25.51.25.51,0,0,.18.1.2.12.04.06.02.06-.07.07-.07.03-.28.07-.54.22-.28.16-1.02.68-1.72.86,0,0-.08.71-.9,1.18-.82.44-1.55.64-3.06.87-1.51.28-2.31.37-2.84.4,0,0-.4.06-.05-.13Z"/>
          <path   d="m393.53,76.08c-.43.04-.45.09-.47.51v.61c0,.25.02.49.05.67-.44.12-1.02.26-1.59.28-1.59-.02-2.65-1-2.65-2.43,0-1.66,1.35-2.45,2.74-2.47.61,0,1.15.17,1.39.22,0,.27.07.64.11,1.12l-.2.02c-.16-.88-.7-1.1-1.38-1.1-1.11,0-1.93.79-1.93,2.12.02,1.04.63,2.25,2,2.24.25.02.47-.03.64-.12.13-.07.22-.16.22-.47v-.62c0-.52-.04-.57-.76-.63v-.18s1.84,0,1.84,0v.2Z"/>
          <path   d="m398.61,78.08c-.08,0-.16,0-.24,0-.49-.03-.77-.17-1.07-.6-.23-.32-.46-.74-.66-1.05-.11-.2-.23-.27-.56-.28h-.21s0,1.07,0,1.07c0,.54.07.59.59.62v.18h-1.72v-.18c.54-.05.59-.08.6-.64v-2.63c0-.58-.07-.6-.58-.65v-.18h1.65c.47,0,.79.06,1.03.24.27.16.45.47.46.86,0,.58-.37.92-.85,1.14.11.21.38.64.59.94.23.34.36.53.52.68.18.21.34.28.48.32l-.02.16Zm-2.42-2.14c.33.01.56-.02.7-.18.26-.2.37-.46.37-.8,0-.72-.47-.97-.96-.97-.19-.02-.3,0-.36.05-.04.05-.08.14-.06.31l-.02,1.61h.33Z"/>
          <path   d="m402.91,77c-.03.21-.18.84-.24,1.04h-3.16s0-.17,0-.17c.59-.07.64-.13.64-.66v-2.6c0-.59-.06-.62-.57-.67l.02-.18h2.91c0,.16.03.61.06.95l-.2.04c-.05-.27-.12-.42-.21-.57-.1-.15-.26-.19-.74-.19h-.5c-.21,0-.22.02-.21.22v1.5s.61,0,.61,0c.61,0,.63-.03.71-.5h.18v1.26s-.18,0-.18,0c-.08-.47-.13-.5-.71-.5h-.61v1.25c-.01.29.03.45.14.53.13.08.35.1.68.08.51,0,.68-.06.82-.21.11-.12.24-.37.33-.63l.19.03Z"/>
          <path   d="m406.98,77c-.04.21-.17.85-.22,1.03l-3.19.02v-.19c.61-.06.66-.11.64-.65v-2.61c.01-.59-.05-.62-.55-.66v-.17s2.93,0,2.93,0c0,.15.03.61.05.95l-.2.03c-.05-.27-.12-.42-.19-.57-.1-.15-.28-.16-.76-.17h-.5c-.2-.02-.23,0-.21.2v1.5l.61-.02c.61.02.63-.05.71-.48h.19s0,1.27,0,1.27h-.18c-.11-.47-.14-.51-.71-.52h-.64v1.26c0,.3.03.44.17.52.11.1.34.08.67.1.5,0,.69-.05.84-.22.12-.15.24-.36.32-.62l.2.02Z"/>
          <path   d="m412.46,73.97c-.37.01-.52.1-.53.38-.05.19-.06.51-.06,1.11v2.65s-.21,0-.21,0l-2.79-3.48h0s-.02,1.68-.02,1.68c0,.61.03.91.05,1.1.02.34.18.43.62.46v.18h-1.56v-.19c.38-.02.54-.11.57-.42.02-.2.04-.54.04-1.14v-1.44c0-.48,0-.52-.12-.67-.14-.15-.29-.21-.58-.22l-.02-.18h.98s2.75,3.28,2.75,3.28h.01v-1.59c0-.61-.03-.91-.04-1.11-.04-.28-.19-.38-.63-.4l-.02-.2,1.56.02v.19Z"/>
          <path   d="m418.59,74.49c-.07-.33-.14-.5-.2-.62-.13-.18-.26-.25-.79-.23h-.5s0,3.54,0,3.54c-.01.54.04.63.72.66v.2s-2.05,0-2.05,0v-.2c.64-.06.71-.11.7-.67v-3.55s-.44,0-.44,0c-.59,0-.74.02-.82.22-.1.12-.14.32-.23.63h-.21c.03-.43.07-.89.07-1.27h.17c.08.16.17.17.38.17h2.83c.21.02.26-.05.36-.18h.17c0,.34.03.87.06,1.28h-.2Z"/>
          <path   d="m421.3,78.06v-.18c.42-.06.47-.12.38-.36-.08-.26-.2-.64-.35-1.02h-1.39c-.11.3-.21.57-.3.83-.14.43-.09.48.42.54v.18s-1.45,0-1.45,0l.02-.18c.43-.06.52-.09.72-.66l1.36-3.46.21-.07c.4,1.13.85,2.39,1.26,3.53.2.56.3.62.72.67l.02.19h-1.63Zm-.65-3.52h-.02c-.21.57-.4,1.13-.6,1.67h1.2s-.58-1.67-.58-1.67Z"/>
          <path   d="m427.14,76.26c-.4.06-.41.09-.42.46v.58c0,.23,0,.44.04.61-.4.1-.93.24-1.48.24-1.43,0-2.4-.91-2.41-2.19.02-1.53,1.24-2.27,2.53-2.29.55.02,1.05.16,1.27.2.02.23.05.61.1,1.04l-.18.03c-.17-.81-.66-1.04-1.3-1.02-1-.02-1.75.73-1.74,1.94,0,.96.56,2.07,1.83,2.07.23,0,.43-.06.57-.14.13-.05.2-.13.22-.41v-.55c-.01-.49-.04-.53-.67-.56v-.2s1.64,0,1.64,0v.18Z"/>
        </g>
        <g>
          <path   d="m405.68,82.92h-.17c.03-.26.05-.55.08-.88.66.02,1.13.03,1.41.03.24,0,.66-.01,1.28-.03.02.34.04.62.07.88h-.17c-.07-.28-.12-.44-.14-.48-.02-.06-.04-.08-.08-.1-.04-.02-.14-.04-.3-.04h-.5c-.01.4-.01.89-.01,1.48,0,.55,0,.91.01,1.1,0,.04.02.08.04.08.02.02.15.06.38.11,0,0,.05,0,.09,0v.14c-.29-.01-.56-.02-.79-.02-.22,0-.45,0-.68.02v-.14c.06,0,.1-.01.13-.02.2-.03.32-.05.35-.08.02,0,.03-.02.04-.05,0-.01,0-.18,0-.49,0-.49.01-.84.01-1.07,0-.4,0-.75-.01-1.06h-.63c-.08,0-.14.01-.16.02-.05.03-.09.08-.13.17-.02.06-.06.2-.11.42Z"/>
          <path   d="m408.96,82.5v-.12c.29-.13.5-.21.62-.27.06-.03.15-.07.24-.12l.06.05c-.03.44-.05,1-.05,1.67,0,.57,0,.89,0,.97,0,.15.01.25.03.29.01.02.03.03.05.05,0,0,.12.03.34.09v.13c-.3-.01-.53-.02-.68-.02-.16,0-.36,0-.57.02v-.13c.22-.05.34-.09.36-.11.02,0,.03-.02.05-.04.01-.03.02-.13.02-.3v-2c0-.08-.02-.14-.04-.18-.02-.02-.06-.04-.1-.04-.05,0-.16.02-.34.06Z"/>
        </g>
        <g>
          <path   d="m395.46,88.82v-.66s-.01-.06-.02-.08c-.02,0-.05-.02-.1-.02l-.19-.05v-.08c.22,0,.37.02.46.02.1,0,.25,0,.44-.02v.08c-.15.03-.24.06-.24.06-.03.01-.06.01-.06.03-.01.02-.02.07-.02.16v1.38s0,.03,0,.03c0,.05,0,.07.03.09.02.02.11.05.28.07v.09c-.26-.01-.41-.01-.48-.01-.12,0-.26,0-.42.01v-.09l.26-.05s.02-.02.03-.03c.01-.02.01-.06.01-.1v-.66c-.23,0-.41,0-.55,0-.14,0-.29,0-.44,0v.68s0,.08,0,.09c.02.01.04.02.07.04,0,0,.1.02.23.04v.09c-.23-.01-.39-.01-.48-.01-.12,0-.26,0-.42.01v-.09c.1-.02.17-.03.22-.04.03,0,.07-.02.07-.03.01,0,.02-.04.02-.09v-1.45s0-.05,0-.05c0-.02,0-.05-.02-.06,0,0-.02-.01-.06-.02l-.23-.05v-.08c.17,0,.35.02.47.02s.27,0,.43-.02v.08l-.23.05s-.05.02-.06.02c-.01.02-.02.04-.02.08v.66c.14,0,.3,0,.45,0,.21,0,.4,0,.55,0Z"/>
          <path   d="m396.74,88.8h.33c.13,0,.21,0,.23-.02.03,0,.04-.01.08-.03,0-.03.02-.08.04-.18,0-.02,0-.04,0-.05h.09c0,.15,0,.27,0,.35,0,.08,0,.2,0,.35h-.09c-.02-.14-.04-.21-.04-.23,0-.02-.03-.04-.05-.04-.03-.02-.09-.02-.21-.02h-.38v.82h.64c.08,0,.13,0,.15-.01.05,0,.07-.03.1-.06.02-.02.07-.12.15-.24,0,0,.01-.04.02-.06h.1s-.07.21-.17.52c-.34-.01-.65-.01-.93-.01-.19,0-.4,0-.65.01v-.09l.23-.04s.06-.02.07-.03c0-.01.01-.05.01-.11v-.36c.01-.07.01-.19.01-.37,0-.28,0-.46,0-.59,0-.13,0-.2-.01-.22,0,0,0-.02,0-.02-.03-.01-.07-.02-.15-.04l-.16-.03v-.08c.18,0,.4.02.63.02.14,0,.45,0,.95-.02,0,.16.02.32.04.48h-.1c-.03-.13-.07-.21-.09-.26-.02-.02-.03-.04-.06-.05-.03-.02-.09-.02-.2-.02h-.56v.44s0,.28,0,.28Z"/>
          <path   d="m399.91,89.56l.05.11c-.16.1-.29.16-.43.2-.12.05-.27.07-.43.07-.18,0-.35-.05-.5-.13-.16-.08-.27-.2-.35-.36-.08-.16-.11-.32-.11-.52,0-.3.1-.55.3-.75.2-.2.46-.3.79-.3.21,0,.44.04.68.13v.17c0,.12,0,.25.02.37h-.12c-.04-.16-.07-.27-.12-.33-.03-.07-.08-.12-.18-.16-.08-.02-.19-.05-.32-.05-.27,0-.48.09-.62.29-.11.15-.15.33-.15.56,0,.27.07.48.22.64.15.16.34.23.59.23.1,0,.2-.01.31-.03.09-.03.21-.08.36-.15Z"/>
          <path   d="m401.71,88.82v-.1s0-.3,0-.3v-.27s0-.06-.01-.08c-.02,0-.05-.02-.12-.02l-.19-.05v-.08c.22,0,.37.02.46.02.11,0,.26,0,.44-.02v.08c-.16.03-.24.06-.26.06-.02.01-.03.01-.04.03,0,.02,0,.07,0,.16,0,.14,0,.37,0,.71v.67s0,.03,0,.03c0,.05,0,.07.02.09.03.02.12.05.29.07v.09c-.26-.01-.41-.01-.48-.01-.11,0-.25,0-.42.01v-.09l.26-.05s.02-.02.03-.03c.02-.02.02-.06.02-.1v-.66c-.24,0-.43,0-.55,0-.14,0-.28,0-.45,0v.68s0,.08.02.09c0,.01.03.02.06.04,0,0,.09.02.23.04v.09c-.23-.01-.38-.01-.48-.01-.11,0-.25,0-.42.01v-.09c.1-.02.18-.03.22-.04.04,0,.07-.02.09-.03,0,0,.01-.04.01-.09,0-.18,0-.44,0-.77l-.02-.69v-.05s0-.05,0-.06c0,0-.04-.01-.08-.02l-.23-.05v-.08c.19,0,.35.02.48.02s.28,0,.43-.02v.08l-.24.05s-.04.02-.05.02c-.02.02-.03.04-.03.08v.66c.15,0,.3,0,.46,0,.21,0,.39,0,.56,0Z"/>
          <path   d="m402.47,88.93c0-.31.09-.55.3-.75.19-.2.44-.3.75-.3.29,0,.51.09.69.26.17.17.26.41.26.71,0,.34-.1.61-.29.8-.19.2-.44.29-.75.29-.29,0-.52-.09-.7-.26-.18-.18-.26-.43-.26-.75Zm.31-.05c0,.31.05.55.2.69.13.16.3.23.5.23.14,0,.26-.03.35-.1.11-.07.19-.18.25-.32.04-.14.08-.29.08-.45,0-.22-.03-.4-.1-.53-.08-.14-.16-.24-.26-.3-.1-.05-.21-.08-.34-.08s-.26.03-.37.1c-.11.08-.19.18-.25.32-.04.14-.07.29-.07.44Z"/>
          <path   d="m405.96,88.8h.34c.12,0,.2,0,.23-.02.02,0,.05-.01.05-.03.02-.03.04-.08.06-.18,0-.02.01-.04.01-.05h.1c0,.15-.02.27-.02.35,0,.08.01.2.02.35h-.1c-.02-.14-.04-.21-.05-.23,0-.02-.03-.04-.05-.04-.02-.02-.08-.02-.21-.02h-.39v.82h.65c.08,0,.15,0,.15-.01.03,0,.06-.03.08-.06.03-.02.08-.12.16-.24,0,0,.02-.04.03-.06h.1s-.07.21-.17.52c-.34-.01-.65-.01-.94-.01-.19,0-.41,0-.64.01v-.09l.23-.04s.06-.02.07-.03c0-.01.02-.05.02-.11v-.36c0-.07,0-.19,0-.37v-.59c0-.13,0-.2-.02-.22,0,0,0-.02-.02-.02-.01-.01-.06-.02-.14-.04l-.16-.03v-.08c.18,0,.39.02.64.02.12,0,.44,0,.95-.02,0,.16.01.32.02.48h-.1c-.03-.13-.06-.21-.09-.26-.02-.02-.03-.04-.05-.05-.04-.02-.1-.02-.21-.02h-.57v.44s-.01.28-.01.28Z"/>
          <path   d="m407.24,88.01v-.08c.13,0,.2.02.27.02s.16,0,.3-.02l.4.5s.31.38.84,1.02v-.29c0-.13,0-.4-.02-.76,0-.17,0-.27,0-.28,0-.02,0-.04-.03-.04-.02-.01-.08-.04-.2-.05,0,0-.02,0-.06-.02v-.08c.16,0,.3.02.4.02.08,0,.22,0,.4-.02v.08s-.05.02-.05.02c-.13.02-.2.04-.22.05,0,0-.02,0-.02.02-.02.02-.02.05-.02.08v.39c0,.23,0,.46,0,.68,0,.25,0,.48.01.66l-.08.04-.58-.71-.78-.92v.83c.02.36.03.55.03.58,0,.01,0,.02.02.04.02.02.09.04.2.05.02,0,.04.01.08.01v.09c-.15-.01-.27-.01-.37-.01-.07,0-.22,0-.42.01v-.09s.06-.01.06-.01c.13-.02.19-.03.22-.05,0,0,0-.02.01-.04.01-.05.01-.21.02-.48v-.71c0-.23,0-.36,0-.39,0-.02-.01-.03-.03-.05-.03-.01-.11-.02-.27-.05,0,0-.03,0-.08-.02Z"/>
          <path   d="m410.29,88.01v-.08c.16,0,.28.02.36.02.09,0,.19,0,.3-.02.04.1.07.17.08.22.04.08.16.34.36.79.1.19.18.38.28.56l.6-1.25c.03-.08.08-.18.12-.32.13,0,.23.02.29.02s.16,0,.31-.02v.08s-.05.02-.05.02c-.14.03-.22.04-.23.05-.03.02-.03.06-.03.14v.47c0,.55,0,.89,0,1,0,.03,0,.05.01.06.01,0,.1.02.24.05.01,0,.03.01.03.01l.02.09c-.16-.01-.32-.01-.45-.01-.12,0-.27,0-.45.01v-.09s.06-.01.06-.01c.14-.03.2-.03.21-.05.01,0,.02-.01.03-.03,0-.03.02-.1.02-.23v-1.21c-.09.16-.28.57-.57,1.2-.09.18-.14.31-.19.41h-.08c-.21-.46-.45-1-.77-1.61l.02,1.29c0,.11,0,.16.03.18.01.02.08.03.21.05.01,0,.05.01.05.01v.09c-.13-.01-.27-.01-.38-.01s-.23,0-.39.01v-.09s.04-.01.04-.01c.14-.02.21-.05.22-.06.03-.02.04-.08.04-.18v-.31c0-.3.01-.57.01-.84v-.16c0-.08,0-.12-.01-.14,0,0-.02-.02-.04-.02-.04-.01-.11-.04-.24-.05,0,0-.03,0-.08-.02Z"/>
          <path   d="m413.69,88.8h.33c.13,0,.21,0,.23-.02.02,0,.05-.01.07-.03.02-.03.03-.08.05-.18,0-.02,0-.04,0-.05h.1c-.01.15-.01.27-.01.35,0,.08,0,.2.01.35h-.1c-.02-.14-.04-.21-.04-.23-.02-.02-.03-.04-.06-.04-.02-.02-.08-.02-.19-.02h-.39v.82h.64c.08,0,.13,0,.16-.01.03,0,.05-.03.08-.06.03-.02.08-.12.15-.24,0,0,.02-.04.03-.06h.11s-.07.21-.18.52c-.34-.01-.64-.01-.93-.01-.19,0-.4,0-.64.01v-.09l.22-.04s.06-.02.07-.03c.01-.01.03-.05.03-.11v-1.31c0-.13,0-.2-.01-.22,0,0-.01-.02-.01-.02-.02-.01-.07-.02-.14-.04l-.15-.03v-.08c.17,0,.38.02.62.02.14,0,.45,0,.95-.02,0,.16.01.32.03.48h-.1c-.03-.13-.07-.21-.09-.26-.02-.02-.04-.04-.06-.05-.04-.02-.11-.02-.2-.02h-.57v.44s0,.28,0,.28Zm.22-1.05h-.08c.06-.17.13-.33.17-.49h.15s.06,0,.13,0l.02.03c-.13.14-.26.3-.4.46Z"/>
          <path   d="m415.04,88.01v-.08c.15,0,.27.02.4.02.12,0,.28,0,.46-.02v.08c-.11.03-.17.05-.2.06-.01.01-.02.01-.02.03,0,.02.02.05.05.1.13.2.24.37.33.49.14-.2.28-.38.4-.55.01-.04.02-.05.02-.06,0,0,0-.01,0-.02-.01,0-.08-.02-.19-.05v-.08c.16,0,.27.02.36.02.09,0,.21,0,.34-.02v.08c-.11.03-.19.05-.24.07-.04.02-.1.1-.19.21-.18.21-.3.39-.4.55.07.12.18.28.3.47l.28.4s.03.03.06.05c.04.02.11.05.22.07v.09c-.16-.01-.3-.01-.39-.01s-.24,0-.43.01v-.09c.11-.03.18-.05.19-.06.01,0,.02-.02.02-.03s-.02-.05-.04-.09c-.14-.21-.25-.4-.38-.58-.11.15-.26.33-.42.58-.03.05-.05.08-.05.09,0,.01.01.02.02.04.02,0,.07.03.14.04.01,0,.03,0,.03,0l.02.09c-.14-.01-.27-.01-.39-.01-.13,0-.23,0-.32.01v-.09c.11-.02.18-.04.22-.07.05-.03.12-.11.23-.26.2-.26.33-.45.43-.58-.09-.14-.22-.34-.42-.61-.08-.12-.13-.19-.16-.21-.03-.02-.12-.06-.26-.09Z"/>
          <path   d="m418.07,87.92v.08s-.05.02-.05.02c-.14.01-.21.04-.23.05-.02.02-.03.06-.03.13v.61s0,.81,0,.81c0,.06.01.11.03.12.03.02.08.04.19.05,0,0,.05.01.09.01v.09c-.15-.01-.29-.01-.43-.01-.15,0-.3,0-.47.01v-.09h.06c.13-.03.21-.05.22-.07.03-.01.04-.06.04-.14v-.08s0-.5,0-.5c0-.53,0-.82-.01-.88,0-.03,0-.05-.03-.08-.02-.01-.08-.02-.21-.05,0,0-.04,0-.07-.02v-.08c.2,0,.36.02.47.02.13,0,.27,0,.43-.02Z"/>
          <path   d="m420.04,89.56l.06.11c-.15.1-.29.16-.43.2-.14.05-.28.07-.42.07-.2,0-.36-.05-.51-.13-.15-.08-.27-.2-.35-.36-.08-.16-.12-.32-.12-.52,0-.3.11-.55.3-.75.21-.2.48-.3.81-.3.2,0,.44.04.68.13,0,.08,0,.14,0,.17,0,.12,0,.25.03.37h-.11c-.04-.16-.08-.27-.11-.33-.05-.07-.11-.12-.2-.16-.09-.02-.2-.05-.32-.05-.27,0-.47.09-.61.29-.1.15-.16.33-.16.56,0,.27.08.48.22.64.16.16.36.23.6.23.11,0,.2-.01.3-.03.11-.03.22-.08.36-.15Z"/>
          <path   d="m420.33,88.93c0-.31.09-.55.29-.75.2-.2.45-.3.76-.3.28,0,.52.09.68.26.17.17.26.41.26.71,0,.34-.1.61-.29.8-.19.2-.44.29-.76.29-.29,0-.52-.09-.68-.26-.17-.18-.26-.43-.26-.75Zm.3-.05c0,.31.06.55.2.69.14.16.31.23.52.23.13,0,.25-.03.36-.1.1-.07.18-.18.24-.32.06-.14.08-.29.08-.45,0-.22-.02-.4-.11-.53-.06-.14-.15-.24-.26-.3-.1-.05-.21-.08-.34-.08-.14,0-.26.03-.37.1-.12.08-.2.18-.24.32-.06.14-.08.29-.08.44Z"/>
        </g>
      </g>
      <g>
        <g>
          <path   d="m439.78,39.29l.09.55s0,.05,0,.06c0,.04-.02.06-.06.06-.04,0-.07-.02-.1-.06-.13-.2-.3-.36-.5-.48-.2-.12-.44-.17-.69-.16-.33.01-.59.14-.77.38s-.26.51-.25.82c.02.35.13.62.35.84.22.21.47.31.75.3.12,0,.24-.03.36-.08.12-.05.2-.1.23-.16.03-.05.05-.13.04-.22,0-.21-.03-.35-.05-.4-.02-.05-.06-.09-.11-.1-.05-.02-.15-.03-.3-.04-.06,0-.09-.02-.09-.05,0-.04.02-.06.08-.06l.35-.02,1.08-.07s.07.01.08.05c0,.02,0,.03-.03.04s-.08.02-.19.05c-.08.02-.14.04-.16.08-.02.03-.03.22-.03.58,0,.08,0,.12,0,.13s-.09.06-.27.14c-.18.08-.36.15-.55.2-.19.05-.38.07-.57.08-.32.01-.61-.03-.87-.12-.26-.09-.46-.23-.63-.41-.16-.18-.25-.4-.26-.66-.02-.37.12-.69.42-.97.3-.28.71-.43,1.25-.45.19,0,.36,0,.52.03.16.03.33.08.53.14.04.01.07.02.1.02.03,0,.05-.02.07-.06.02-.05.05-.07.08-.08.04,0,.07.04.08.12Z"/>
          <path   d="m442.2,40.22c.21.01.39.06.53.13.14.07.25.23.33.46.08.22.15.36.23.43.08.07.18.1.31.1.07,0,.11.02.11.05,0,.04-.06.06-.17.06l-.9.03c-.1-.07-.17-.2-.21-.4-.06-.25-.11-.42-.16-.52-.05-.1-.12-.17-.22-.21s-.21-.06-.33-.05c-.04,0-.08,0-.15.01.02.45.04.72.05.83,0,.1.04.18.09.22.05.04.14.06.27.06.07,0,.11.02.11.05,0,.03-.03.05-.09.06l-.72.02c-.1,0-.22.01-.37.03-.14.01-.23.02-.26.02-.06,0-.1-.01-.1-.05,0-.04.03-.05.08-.06.09,0,.17-.03.24-.07.07-.04.1-.19.09-.46l-.02-.35-.03-.69c-.02-.35-.05-.57-.09-.64s-.15-.11-.31-.11c-.07,0-.1-.01-.1-.05,0-.04.04-.06.13-.06.44-.02.81-.05,1.11-.08.13-.01.26-.02.37-.03.35-.02.63.02.84.13.21.1.31.25.32.44,0,.12-.04.24-.12.34-.09.1-.2.18-.34.24-.14.06-.3.1-.5.12Zm-.64-.05s.07,0,.09,0c.14,0,.27-.03.41-.08.13-.05.23-.12.28-.21.05-.09.08-.19.07-.3,0-.16-.07-.28-.19-.36-.12-.08-.27-.12-.45-.11-.08,0-.16.02-.26.05l.05,1.02Z"/>
          <path   d="m446.3,38.76c.06.2.12.39.18.59,0,.04-.02.06-.06.06-.02,0-.05-.01-.09-.05-.17-.18-.33-.3-.46-.35s-.33-.07-.58-.06c-.14,0-.27.03-.4.05-.01.23-.01.47,0,.74,0,.05,0,.12.02.23h.18c.11-.02.21-.03.28-.06.08-.03.14-.09.19-.17.05-.08.08-.14.09-.16.01-.04.03-.06.07-.07.04,0,.07.01.07.04,0,0,0,.02,0,.03-.01.08,0,.32.01.71,0,.05.01.1.03.15,0,.01,0,.02,0,.03,0,.04-.02.05-.07.06-.03,0-.06-.03-.08-.1-.04-.12-.11-.2-.23-.26-.11-.06-.29-.08-.53-.06,0,.21,0,.35.01.42.02.29.03.46.05.52.01.05.06.1.13.13.07.03.19.04.35.03.59-.04.97-.21,1.14-.53.02-.04.05-.07.08-.07.04,0,.07.01.07.04,0,.02,0,.05,0,.08l-.12.56-.84.05c-.24.01-.46.02-.67.03s-.37.01-.48.02c-.13,0-.33.03-.59.05-.03,0-.06,0-.07,0-.07,0-.11-.01-.11-.05,0-.04.03-.06.08-.06.15,0,.25-.05.3-.12s.07-.21.05-.41l-.04-.98c-.02-.34-.04-.54-.05-.62-.01-.08-.04-.13-.09-.15-.04-.02-.13-.04-.25-.05-.05,0-.08-.02-.08-.05,0-.04.03-.06.08-.06l.56-.02,1.88-.11Z"/>
          <path   d="m449.43,38.55c.06.19.12.39.19.58,0,.04-.02.06-.06.06-.03,0-.05-.01-.09-.05-.17-.18-.33-.29-.46-.35-.14-.05-.33-.07-.58-.05-.14,0-.27.03-.4.06-.01.23,0,.47,0,.74,0,.05,0,.12.02.23h.18c.11-.02.2-.04.28-.07.08-.03.14-.09.19-.17s.08-.14.08-.16c.01-.04.03-.06.07-.07.04,0,.07.01.07.04,0,0,0,.02,0,.03-.01.08,0,.32.02.71,0,.05.01.1.03.15,0,.01,0,.02,0,.03,0,.04-.02.06-.07.06-.03,0-.06-.03-.09-.1-.04-.12-.12-.2-.23-.25-.11-.05-.29-.07-.53-.06,0,.21.01.35.02.42.02.29.04.46.05.52.01.05.06.1.13.12.07.03.19.04.35.03.59-.04.97-.22,1.14-.54.02-.04.05-.07.08-.07.04,0,.07.01.07.04,0,.02,0,.05,0,.08l-.11.56-.84.06c-.24.02-.46.03-.67.03-.21,0-.37.01-.48.02-.13,0-.33.03-.59.06-.03,0-.06,0-.07,0-.07,0-.11-.01-.11-.05,0-.04.03-.06.08-.06.15-.01.25-.05.3-.12.05-.07.06-.21.05-.41l-.05-.98c-.02-.34-.04-.54-.06-.62-.02-.08-.05-.13-.09-.15s-.13-.04-.25-.04c-.05,0-.08-.02-.08-.05,0-.04.03-.06.08-.06l.56-.02,1.88-.12Z"/>
          <path   d="m450.82,38.43c.8.63,1.46,1.14,1.99,1.52,0-.09,0-.14-.01-.17l-.06-.75c-.01-.18-.04-.3-.07-.38-.03-.07-.09-.13-.17-.17s-.18-.06-.29-.06c-.06,0-.09-.02-.09-.05,0-.04.02-.06.08-.06.03,0,.07,0,.11,0,.09,0,.22,0,.39-.02.21-.02.38-.03.49-.05.05,0,.09-.01.12-.01.06,0,.09.01.09.05,0,.04-.04.06-.11.07-.1.02-.19.06-.27.12-.08.07-.11.19-.1.36l.1,1.08.05.81c0,.07-.02.11-.07.11-.01,0-.03,0-.05,0-.02,0-.13-.09-.34-.24-.21-.15-.52-.39-.93-.71-.41-.32-.7-.56-.87-.7,0,.03.02.35.07.96.01.19.04.33.06.44.03.1.09.18.2.22.11.04.2.06.28.05.05,0,.08.01.08.04,0,.04-.02.06-.08.06-.04,0-.08,0-.14,0-.09,0-.21,0-.36.01-.23.02-.4.04-.5.06-.06.01-.11.02-.14.02-.06,0-.09-.01-.1-.06,0-.03.04-.05.14-.07.17-.02.28-.08.32-.17.04-.09.05-.21.04-.36-.05-.65-.09-1.06-.11-1.2-.02-.15-.09-.27-.2-.38-.11-.11-.24-.17-.39-.18-.06,0-.09-.02-.09-.05,0-.04.03-.07.09-.07.02,0,.04,0,.07,0,.04,0,.1,0,.19-.01.2-.02.39-.03.56-.04Z"/>
          <path   d="m454.87,38.08c.81-.06,1.4-.1,1.79-.14.29-.03.62-.06.97-.11.12-.02.23-.03.31-.04.03,0,.08,0,.16-.01.02.05.04.16.07.32l.06.3s-.02.08-.07.08c-.04,0-.08-.02-.12-.08-.07-.1-.15-.18-.25-.24-.1-.06-.21-.1-.33-.12s-.33,0-.61.02c0,.2,0,.44.03.73.03.3.06.58.1.86.04.28.07.44.1.5s.06.09.11.12c.05.02.17.03.34.02.06,0,.09.01.1.04,0,.04-.02.06-.07.06h-.07s-.48.04-.48.04c-.38.04-.6.06-.68.07l-.29.04s-.07,0-.14,0c-.04,0-.06-.02-.06-.04,0-.04.02-.06.08-.06.14-.01.24-.05.3-.1.07-.05.1-.13.11-.24,0-.1,0-.33-.04-.67-.05-.54-.11-.98-.18-1.31-.26.02-.44.05-.55.08s-.22.08-.32.17c-.1.09-.17.19-.23.32-.02.05-.05.08-.08.08-.05,0-.08-.01-.08-.05,0-.01,0-.1,0-.25,0-.16.01-.29.03-.39Z"/>
          <path   d="m459.48,37.58l.16-.02c.24.34.59.88,1.04,1.62.21.34.35.54.42.61.07.07.18.1.32.11.05,0,.07.01.08.04,0,.04-.02.06-.08.07-.03,0-.12,0-.25.01-.16,0-.3.01-.41.02-.11.01-.25.03-.42.06-.13.02-.21.03-.25.04-.07,0-.11,0-.11-.04,0-.03.02-.05.07-.06.19-.03.28-.1.27-.21,0-.05-.04-.14-.11-.27-.07-.13-.14-.25-.22-.35-.1,0-.18.02-.24.02-.31.03-.55.06-.73.08-.1.31-.15.52-.14.61,0,.07.05.12.12.15.07.03.15.05.22.04.06,0,.09.02.09.05s-.03.05-.09.06c-.02,0-.09,0-.2.01-.14,0-.24.01-.3.02-.12.01-.24.03-.35.06-.03,0-.05.01-.07.01-.06,0-.09-.01-.09-.05,0-.03.01-.05.05-.06.09-.03.16-.07.21-.1.05-.04.1-.1.15-.2.05-.1.11-.25.17-.45.15-.46.37-1.09.67-1.89Zm-.09.68l-.31.89.82-.1c-.05-.09-.13-.22-.24-.39-.12-.18-.2-.31-.26-.41Z"/>
          <path   d="m464.33,37.31l.13.54s0,.04.01.06c0,.04-.01.06-.06.07-.04,0-.07-.01-.1-.06-.14-.19-.32-.34-.53-.44-.21-.1-.45-.14-.7-.11-.33.04-.58.18-.74.43s-.22.53-.19.83c.04.34.18.61.41.81.23.2.49.28.77.24.12-.01.24-.05.36-.11.12-.06.19-.12.22-.17.03-.06.04-.13.03-.23-.03-.21-.05-.34-.08-.39-.03-.05-.07-.08-.12-.1s-.15-.02-.3-.02c-.06,0-.09-.01-.09-.04,0-.04.02-.06.08-.06l.35-.04,1.07-.14s.07,0,.08.04c0,.02,0,.03-.02.04-.02,0-.08.03-.19.06-.08.02-.13.05-.15.09-.02.03-.01.23.01.58,0,.08,0,.12,0,.13s-.09.06-.26.16-.35.18-.54.24-.37.1-.56.12c-.32.04-.61.02-.87-.05-.26-.07-.48-.19-.66-.36-.17-.17-.28-.38-.31-.64-.04-.36.07-.7.35-1,.28-.3.68-.48,1.21-.54.19-.02.36-.02.52,0,.16.02.34.05.54.1.04,0,.07.01.1,0,.03,0,.05-.02.07-.06.02-.05.04-.08.08-.08.04,0,.07.03.09.11Z"/>
        </g>
        <path   d="m469.35,38.43c.12-.04.23-.1.35-.13.37-.11.62-.39.86-.65.15-.17.13-.34-.04-.52-.54-.6-1.21-.91-1.94-1.15-.02-.02-.04-.03-.06-.04.29,0,.57.17.86.26.09.02.19.11.24.02.08-.07-.04-.16-.08-.24-.07-.12-.16-.24-.21-.39.4.53.89.94,1.47,1.23.26.13.39.08.5-.14.16-.35.33-.69.21-1.13.3.5.67.98.46,1.58.16.01.27.02.41.03.11,0,.16.1.2.19.05.15-.1.09-.14.13-.26.18-.64.23-.83.56-.13.21-.42.25-.68.29-.51.08-1.03.12-1.58.11Z"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagsTShirt
