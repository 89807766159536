import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ShortDeportivosEditable from './ShortDeportivosEditable'
import ShortsDeportivosLateralEditable from './ShortsDeportivosLateralEditable'

const ConjuntoShortDeportivo = () => {
  const { selectedMenu, female } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('short deportivo'))
    const data = []

    data.push(
      {
        label: 'Short',
        selected: 'short deportivo',
        click: () => dispatch(setPantalonesMenu('short deportivo'))
      },
      {
        label: 'Short lateral',
        selected: 'short lateral deportivo',
        click: () => dispatch(setPantalonesMenu('short lateral deportivo'))
      }
    )
    dispatch(setLabelsMenu(data))
  }, [female])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'short deportivo':
            return <ShortDeportivosEditable />
          case 'short lateral deportivo':
            return <ShortsDeportivosLateralEditable />
          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}
export default ConjuntoShortDeportivo
