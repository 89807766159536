import { AUTHSERVICE } from '../config'

const deletePackage = async (id: number): Promise<any> => {
  return await AUTHSERVICE().delete(`/notebook-package/${id}`)
}

const deletePackageNotebook = async (id: number) => {
  const results = await deletePackage(id)
  return results
}

export { deletePackageNotebook }
