import { Checkbox, Table } from 'antd'
import Button from 'components/buttons/Button/Button'
import { memo } from 'react'
import styles from './authorize.module.scss'
import { useAuthorize } from 'hooks/books/authorize/useAuthorize'

interface IAuthorizer {
  closeModal: () => void
}

const AuthorizeModule = memo((props: IAuthorizer) => {
  const {
    loading,
    bookColumns,
    books,
    selectedRowKeys,
    setSelectedRowKeys,
    handleAllAuthorize,
    handleAuthorize
  } = useAuthorize(props.closeModal)

  const handleSelect = (record: any, selected: any) => {
    if (selected) {
      setSelectedRowKeys((keys: any) => [...keys, record.id])
    } else {
      setSelectedRowKeys((keys: any) => {
        const index = keys.indexOf(record.id)
        return [...keys.slice(0, index), ...keys.slice(index + 1)]
      })
    }
  }

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys: any) =>
      keys.length === books.length ? [] : books.map((r: any) => r.id)
    )
  }

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < books.length
      }
      onChange={toggleSelectAll}
    />
  )

  const rowSelection = {
    selectedRowKeys,

    onSelect: handleSelect,
    columnTitle: headerCheckbox
    //onSelectAll: this.handleSelectAll
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.authTable}>
          <Table
            dataSource={books}
            loading={loading}
            rowSelection={rowSelection}
            columns={bookColumns}
            rowKey={(row) => row.id}
            // className={styles.booksTable}
            onRow={(record, _) => {
              return {
                onClick: (_) => {
                  //   openDrawComments()
                  //   dispatch(
                  //     setCommentsObjectInformation(record)
                  //   )
                  //   setIdGrade(record?.idGrade as number)
                }
              }
            }}
          />
        </div>
      </div>

      <div className={styles.button}>
        <Button
          label={'Autorizar'}
          isLoading={loading}
          rounded
          sector='tertiary'
          onClick={() => {
            handleAuthorize(props.closeModal)
          }}
        />
        <Button
          label={'Autorizar todo'}
          rounded
          isLoading={loading}
          sector='tertiary'
          onClick={() => {
            handleAllAuthorize(props.closeModal)
          }}
        />
      </div>
    </>
  )
})

export default AuthorizeModule
