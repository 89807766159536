import React from 'react'

const LabelJunper = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Etiqueta_lateral' data-name='Etiqueta lateral' stroke='black'>
        <g id='Etiqueta_lateral-2' data-name='Etiqueta lateral'>
          <path d='m244.62,389.93l-.39-.04-2.45,17.73c9.15,1.02,18.3,2.03,27.44,3.12,1.43.17,1.94-.14,2.12-1.63.55-4.66,1.16-9.33,1.93-13.97.28-1.7-.28-2.05-1.8-2.22-8.96-.95-17.9-1.98-26.86-2.99Z' />
          <path d='m255.56,405.3c-.36-.22-.7-.46-1.08-.64-1.16-.58-1.84-1.57-2.53-2.57-.41-.59-.33-1.11.28-1.61,1.89-1.57,4.17-2.15,6.55-2.49.09-.01.17-.03.25-.08-.97-.15-1.89.2-2.83.29-.27.03-.65.25-.8-.05-.16-.27.19-.5.36-.72.27-.35.57-.68.81-1.08-1.44,1.44-3.16,2.38-5.14,2.97-.86.26-1.23.03-1.49-.71-.42-1.2-.84-2.37-.3-3.67-1.13,1.39-2.49,2.65-1.99,4.69-.47-.06-.87-.09-1.28-.15-.41-.06-.57.23-.72.5-.25.42.24.32.39.46.81.74,1.95,1.12,2.46,2.24.35.76,1.25,1.03,2.06,1.32,1.61.57,3.28.98,5,1.29Z' />
        </g>
      </g>
    </svg>
  )
}

export default LabelJunper
