import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import style from '../poloeditable.module.scss'
import { RootState } from 'redux/store/store'
import PathTShirt from 'assets/img/uniforms/T-shirt/male/PathTShirt'
import ShadowsTShirt from 'assets/img/uniforms/T-shirt/male/ShadowsTShirt'
import LabelsTShirts from 'assets/img/uniforms/T-shirt/male/LabelsTShirts'
import GreenTagsTShirt from 'assets/img/uniforms/T-shirt/male/GreenTagsTShirt'
import PathTShirtFemale from 'assets/img/uniforms/T-shirt/female/PathTShirtFemale'
import ShadowsTShirtFemale from 'assets/img/uniforms/T-shirt/female/ShadowsTShirtFemale'
import LabelsTShirtsFemale from 'assets/img/uniforms/T-shirt/female/LabelsTShirtsFemale'
import GreenTagsTShirtFemale from 'assets/img/uniforms/T-shirt/female/GreenTagsTShirtFemale'


const TShirtPic = () => {
    const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
        (state: RootState) => state.uniforms
      )
  return (
    <div className={`${style['div-polo-svg-relative']}`}>
        {!female ? (
          <Fragment>
            <div
              className={`${
                style[`logo-position-${poloValues.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathTShirt className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsTShirt className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelsTShirts className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsTShirt className={`${style['svg']}`} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={`${
                style[`logo-position-${poloValuesFemale.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
            <div className={`${style['div-polo-svg-absolute']}`}>
              <PathTShirtFemale className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-shadows-svg-absolute']}`}>
              <ShadowsTShirtFemale className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-label-svg-absolute']}`}>
              <LabelsTShirtsFemale className={`${style['svg']}`} />
            </div>
            <div className={`${style['div-tags-svg-absolute']}`}>
              <GreenTagsTShirtFemale className={`${style['svg']}`} />
            </div>
          </Fragment>
        )}
      </div>
  )
}

export default TShirtPic