import React, { useState, Fragment } from 'react'
import style from './art.module.scss'
import SvgModuleText from './SvgModuleText'

const ModulodePrueba = () => {
  const [words, setWords] = useState('')

  const fontFamily = 'fantasy'

  return (
    <div className={`${style['div-container']}`}>
      <textarea
        onChange={(e) => {
          setWords(e.target.value)
        }}
        value={words}
      />

      <div className={`${style['padre']}`}>
        <SvgModuleText fillull='red' interlin={1} words={words} fontFamily={fontFamily} />
      </div>
    </div>
  )
}

export default ModulodePrueba
