import { memo, useEffect } from 'react'
import { Col, List, Table, Tabs } from 'antd'
import styles from './../../AdminUtilsCatalog/utils.module.scss'
import utilsStyles from '../AdminUtilsManagement.module.scss'
import Button from 'components/buttons/Button/Button'
import Collapse from 'components/Collapse/Collapse'
import B from 'components/texts/B/B'

const colStyle = { cursor: 'pointer', padding: '0 20px' }
const AccordionSchoolUtils = memo(
  ({
    tabs,
    packages,
    packagesMap,
    utilsColumns,
    selectStatus,
    selectedLevel,
    loadingSupplies,
    onRowClicked,
    onCollapseChange,
    setSelectedPackage,
    onChangeSelectedTab,
    handleOpenPackageModal,
  }: any) => {
    useEffect(() => {
      
   
    }, [tabs]);
    return (
      <>
        <Tabs items={tabs} onChange={onChangeSelectedTab} />
        <div>
          {selectedLevel?.grades?.map((res: any) => {
            return (
              <div key={`grade-${res.id}`} className={`${utilsStyles['']}`}>
                <Collapse id={res.id} title={res.name}>
                  <List.Item
                    style={{
                      padding: ' 10px 0',
                      background: '#F3F7FF80'
                    }}
                  >
                    <Col className='ListTitles' style={colStyle} span={8}>
                      <B size='Light'>Nombre del paquete</B>
                    </Col>
                  </List.Item>
                  {(() => {
                    const items = packages?.filter(
                      (it: any, ix: number) => res.id == it.id_grade
                    )
                    return (
                      <>
                        {items?.map((pck: any) => {
                          return (
                            <div key={`pck-div-${pck.id}`}>
                              {/* ACA ESTA EL PAQUET */}
                              <Collapse
                                id={pck.id}
                                title={pck.name}
                                customBg={utilsStyles['custom-collapse-bg']}
                                isDroped={(flag: boolean) => {
                                  onCollapseChange(flag, pck.id)
                                }}
                                customSideElement={
                                  <div style={{ width: '300px' }}>
                                    {pck.request_changes && (
                                      <Button
                                        rounded
                                        sector='secondary'
                                        label='Cambios Solicitados'
                                        onClick={() => {
                                          handleOpenPackageModal(pck.id)
                                        }}
                                      />
                                    )}
                                  </div>
                                }
                              >
                                <Table
                                  dataSource={
                                    selectStatus && selectStatus != 'todos'
                                      ? packagesMap[pck.id]?.filter(
                                          (it: any) => it.status == selectStatus
                                        )
                                      : packagesMap[pck.id]
                                  }
                                  loading={loadingSupplies}
                                  columns={utilsColumns}
                                  className={styles.utilstable}
                                  scroll={{ x: 1400 }}
                                  rowKey={'id'}
                                  pagination={(() => {
                                    let filtered = packagesMap[pck.id]

                                    if (
                                      selectStatus &&
                                      selectStatus != 'todos'
                                    ) {
                                      filtered = packagesMap[pck.id]?.filter(
                                        (it: any) => it.status == selectStatus
                                      )
                                    }

                                    return {
                                      total: filtered?.length,
                                      pageSize: 10,
                                      showSizeChanger: false
                                    }
                                  })()}
                                  onRow={(record: any, _: any) => ({
                                    onClick: (_: any) => {
                                      setSelectedPackage(pck.id)
                                      onRowClicked(record)
                                    }
                                  })}
                                ></Table>
                              </Collapse>
                            </div>
                          )
                        })}
                      </>
                    )
                  })()}
                </Collapse>
              </div>
            )
          })}
        </div>
      </>
    )
  }
)
AccordionSchoolUtils.displayName = 'AccordionSchoolUtils'
export default AccordionSchoolUtils
