/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'antd/lib/form/Form'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { AUTHSERVICE, AUTHSERVICE_FORMDATA } from 'services/config'
import {
  addNewSupplyMake,
  getSupplyMakeList
} from 'services/utilsServices/utils'
import { Options } from 'types'
import { handleErrors } from 'utilities/handleErrors'
import { optionsProviderFee } from 'hooks/books/editorial/useEditorials'
import { getOptionsListForProducts } from 'services/genericServices/genericServices'

export const PRESENTATION_TYPE = [
    {
      value: 'blizter',
      label: 'Blizter'
    },
    {
      value: 'bolsa',
      label: 'Bolsa'
    },
    {
      value: 'bote',
      label: 'Bote'
    },
    {
      value: 'caja',
      label: 'Caja'
    },
    {
      value: 'paquete',
      label: 'Paquete'
    },
    {
      value: 'pieza',
      label: 'Pieza'
    }
  ]
  
export const useAddUtilsForm = ({ selectedUtil, onFinishForm }: any): any => {
  const [savingData, setSavingData] = useState(false)
  const [fileData, setFileData] = useState<File | null>(null)
  const [modalMake, setmodalMake] = useState(false)
  const [loadingPost, setloadingPost] = useState(false)
  const [makeCompleteList, setMakeCompleteList] = useState<Array<Options>>([])
  const [OptionBrand, setOptionBrand] = useState<Options[]>([])
  const [form] = useForm()
  const [formEditorial] = useForm()


  const location = useLocation()

  const INPUTS_INITIAL_STATE = {
    name: {
      label: 'Nombre',
      name: 'name',
      placeholder: 'Nombre del material',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    make: {
      label: 'Marca',
      name: 'make',
      placeholder: 'Marca',
      value: '',
      type: 'autocomplete',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      options: [],
      rules: [
        { required: true, message: '' },
        {
          validator: async (algo: any, make: any) => {
            const found = makeCompleteList.find((it) => it.value === make)
            return found ? Promise.resolve() : Promise.reject()
          }
        }
      ],
      disabled:
        !location.state?.requestUtil &&
        (location.state?.status > 3 || location.state?.status === 2)
    },
    sku: {
      label: 'SKU',
      name: 'sku',
      placeholder: 'SKU',
      value: '',
      type: 'text',
      required: true,
      maxLength: 6, //maxLength solo funciona para tipo text
      status: '', // puede ser 'warning' o 'error' segun antd
      rules: [{ required: true, message: '' }]
    },
    presentation: {
      label: 'Presentación',
      name: 'presentation',
      placeholder: 'Presentación',
      value: '',
      type: 'select',
      required: true,
      options: PRESENTATION_TYPE,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    price: {
      label: 'Precio',
      name: 'price',
      placeholder: 'Precio',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        { required: true, message: '' },
        {
          validator: async (_: any, value: string) => {
            if (!value) return Promise.reject('')
            if (!value.match(/^\d*\.?\d*$/)) return Promise.reject('')
            return Promise.resolve()
          }
        }
      ]
    },
    return_percentage: {
      label: 'Porcentaje de devolución',
      name: 'return_percentage',
      placeholder: 'Porcentaje de devolución',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    provider: {
      label: 'Proveedor',
      name: 'provider',
      placeholder: 'Proveedor',
      value: '',
      type: 'number',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    image: {
      label: 'Imagen del producto',
      name: 'picture',
      placeholder: 'Imagen del producto',
      value: '',
      type: 'file',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [{ required: true, message: '' }]
    },
    refill_time: {
      label: 'Tiempo de resurtido',
      name: 'refill_time',
      placeholder: '0',
      value: '',
      type: 'text',
      required: true,
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
      rules: [
        {
          pattern: new RegExp(/^\d+$/),
          message: 'Escribe solo números'
        }
      ]
    },

    provider_fee: {
      label: 'Cuota de proveedor',
      name: 'provider_fee',
      placeholder: 'Cuota de proveedor',
      value: '',
      type: 'radio',
      maxLength: 100, //maxLength solo funciona para tipo text
      options: optionsProviderFee,
      span: 6
    },
    resource_free: {
      label: 'Recurso gratuito',
      name: 'resource_free',
      placeholder: 'Recurso gratuito',
      value: '',
      type: 'radio',
      maxLength: 100, //maxLength solo funciona para tipo text
      options: optionsProviderFee,
      span: 6
    },

    category: {
      label: 'Categoria',
      name: 'category',
      placeholder: 'Categoria',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: 'v0',
          label: 'v0'
        },
        {
          value: 'v4',
          label: 'v4'
        }
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
    },
    tax: {
      label: 'Impuesto',
      name: 'tax',
      placeholder: 'Impuesto',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: 'v0',
          label: 'v0'
        },
        {
          value: 'v4',
          label: 'v4'
        }
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: ''
    },
    price_list: {
      label: 'Precio de lista',
      name: 'price_list',
      placeholder: 'Precio de lista',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '4',
          label: '4'
        },
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
    },
    store: {
      label: 'Tienda',
      name: 'store',
      placeholder: 'Tienda',
      value: '',
      type: 'select',
      required: false,
      options: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '4',
          label: '4'
        },
      ],
      maxLength: 100, //maxLength solo funciona para tipo text
      status: '',
    },
    inventariable : {
      label: 'Inventariable',
      name: 'inventariable',
      placeholder: 'Inventariable',
      value: '',
      type: 'radio',
      maxLength: 100, //maxLength solo funciona para tipo text
      options: optionsProviderFee,
    },
  }

  const [addUtilForm, setaddUtilForm] = useState(INPUTS_INITIAL_STATE);


  const getOptionsForInputs = async () => {
    try {
      const result = await getOptionsListForProducts('Útiles')
      const listdata = result.data
      delete listdata.success
      const createLabelOptions = (customArray:any) =>{
        return customArray.map((res: any) => {
          return { label: res, value: res, id: res }
        })
      }
      setaddUtilForm(prev => {
        const category = {...prev.category}
        const tax = {...prev.tax}
        const price_list = {...prev.price_list}
        const store = {...prev.store}
        return {
          ...prev,
          category:{...category,
            options: createLabelOptions(listdata.categories)
          },
          tax:{...tax,
            options: createLabelOptions(listdata.tax)
          },
          store:{...store,
            options: createLabelOptions(listdata.store)
          },
          price_list:{...price_list,
            options: createLabelOptions(listdata.price_list)
          },
        }
      })

    } catch (error) {
      //handleErrors(error)
    }
  }


  const getSupplyMake = async () => {
    try {
      const result = await getSupplyMakeList()
      const allOptions = result.data.status
      const cleanOptions = allOptions.map((res: any) => {
        return { label: res.name, value: res.name, id: res.id }
      })

      

      setaddUtilForm(prev=>{
        const makeInput = {...prev.make}
        makeInput.options= cleanOptions
        makeInput.rules =  [
          { required: true, message: '' },
          {
            validator: async (algo: any, make: any) => {
              const found = cleanOptions.find((it:any) => it.value === make)
              return found ? Promise.resolve() : Promise.reject()
            }
          }
        ]

        return {...prev, make: makeInput}
      })
      setMakeCompleteList(cleanOptions)
      setOptionBrand(cleanOptions)
    } catch (error) {
      //handleErrors(error)
    }
  }

  useEffect(() => {
    getSupplyMake()
    getOptionsForInputs()
  }, [])

  const onFileChange = (file: File, name?: string) => {
    if (file) setFileData(file)
    else setFileData(null)
  }

  const onHandleAutoCompleteMarca = async (value: string): Promise<any> => {
    setOptionBrand(
      value
        ? makeCompleteList.filter((it) =>
            it.label?.toLocaleLowerCase().match(value?.toLocaleLowerCase())
          )
        : makeCompleteList
    )
  }

  const submit = async (id: number, values: any) => {
    setSavingData(true)

    if (!(selectedUtil || fileData)) {
      ErrorToast('Debe seleccionar una imagen')
      setSavingData(false)
      return
    }
    try {
      if (selectedUtil) {
        const res = await AUTHSERVICE().put('/supply', {
          ...values,
          id: selectedUtil?.id
        })

        // saving image
        if (fileData) {
          await AUTHSERVICE_FORMDATA().post('/supply/loadfile', {
            id: selectedUtil?.id,
            files: fileData
          })
        }

        setFileData(null)
        SuccessToast('El útil se actualizó correctamente')
      } else {
        // saving util data
        const res = await AUTHSERVICE().post('/supply', {
          ...values,
          picture: 'Pincture',
          quantity: 1
        })
        // saving the image
        const { supplyId } = res?.data
        await AUTHSERVICE_FORMDATA().post('/supply/loadfile', {
          id: supplyId,
          files: fileData
        })

        setFileData(null)
        SuccessToast('El útil se creó correctamente')
      }

      onFinishForm()
    } catch (error: any) {
      const { response }: any = error || {}
      const { data }: any = response || {}
      const { error: message }: any = data || {}

      if (message && message == 'Duplicate Sku supply') {
        ErrorToast(
          'El valor de SKU está duplicado. Por favor asigne uno diferente.'
        )
      } else {
        ErrorToast(
          'Ocurrió un error realizando la petición. Por favor inténtelo de nuevo más tarde.'
        )
      }

      setSavingData(false)
    }
  }

  const postNewMakeSupply = async (values: any) => {
    setloadingPost(true)
    try {
      await addNewSupplyMake(values)
      await getSupplyMake()
      setmodalMake(false)
      SuccessToast('Se ha agregado una nueva marca')
      formEditorial.resetFields()
    } catch (err: any) {
      handleErrors(err)
    }

    setloadingPost(false)
  }

  return {
    addUtilForm,
    INPUTS_INITIAL_STATE,
    OptionBrand,
    savingData,
    form,
    modalMake,
    formEditorial,
    loadingPost,
    submit,
    onHandleAutoCompleteMarca,
    setmodalMake,
    onFileChange,
    postNewMakeSupply
  }
}
