import React from 'react'

const LabelFaldaShortLateral = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path d="m420.8,286.52c-.71.02-.98.23-.93,1.38.16,3.14.25,6.28.3,9.43.02,1,.24,1.24.91,1.21,4.27-.2,8.53-.35,12.8-.51l-.38-11.96h-.18c-4.17.16-8.35.34-12.52.45Z"/>
      <path d="m423.99,295.64c.22-.12.44-.26.67-.37.72-.33,1.18-.96,1.65-1.58.28-.37.26-.72-.07-1.09-1.02-1.14-2.33-1.65-3.71-2.01-.05-.02-.1-.03-.14-.06.58-.05,1.1.24,1.66.35.15.03.36.2.48,0,.1-.18-.09-.34-.17-.5-.14-.25-.3-.48-.42-.76.77,1.03,1.72,1.75,2.85,2.25.49.21.73.09.92-.4.32-.78.64-1.54.4-2.43.57.99,1.31,1.91.89,3.24.28-.01.52-.02.76-.03.24-.01.33.19.4.37.12.29-.17.2-.26.29-.53.45-1.22.64-1.59,1.36-.24.49-.79.63-1.29.77-.99.3-1.99.48-3.02.59Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelFaldaShortLateral
