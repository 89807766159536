import React from 'react'

const Horizontal = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      stroke='black'
      className={className}
    >
      <g id='Portada_contraportada' data-name='Portada contraportada'>
        <g
          id='Path_portada_contraportada'
          data-name='Path portada contraportada'
        >
          stroke='#061d47'
          <g
            fill='#061d47'
            id='portada_contraportada'
            data-name='portada contraportada'
          >
            <path d='m35,640.5V128h664.38c10.28,0,18.62,8.33,18.62,18.62v475.27c0,10.28-8.33,18.62-18.62,18.62H35Z' />
          </g>
          <g stroke='#ffffff' fill='#ffffff'>
            <path d='m291.58,372.02c8.61,0,13.41,6.11,13.41,14.42,0,1.31-1.13,2.55-2.61,2.55h-18.45c.47,5.46,4.51,8.31,9.08,8.31,2.79,0,5.1-1.07,6.53-1.96,1.48-.71,2.55-.53,3.32.83l.71,1.19c.71,1.37.47,2.55-.83,3.32-1.96,1.25-5.52,3.03-10.27,3.03-10.03,0-16.26-7.24-16.26-15.84,0-9.32,6.29-15.84,15.37-15.84m5.94,12.4c-.12-4.21-2.67-6.71-6.11-6.71-3.86,0-6.59,2.67-7.3,6.71h13.41Z' />
            <path d='m306,400.38c0-1.25.3-1.84,1.07-2.79l12.52-15.66c1.42-1.72,2.55-2.79,2.55-2.79v-.12s-.83.12-2.79.12h-10.26c-1.66,0-2.49-.89-2.49-2.49v-1.43c0-1.6.83-2.49,2.49-2.49h20.77c1.6,0,2.49.71,2.49,2.02v.59c0,1.25-.3,1.84-1.07,2.79l-12.52,15.72c-1.36,1.72-2.61,2.79-2.61,2.79v.12s.89-.18,2.85-.18h11.27c1.66,0,2.49.89,2.49,2.49v1.42c0,1.66-.83,2.49-2.49,2.49h-21.78c-1.6,0-2.49-.71-2.49-2.02v-.59Z' />
            <path d='m348.97,372.02c8.61,0,13.41,6.11,13.41,14.42,0,1.31-1.13,2.55-2.61,2.55h-18.46c.47,5.46,4.51,8.31,9.08,8.31,2.79,0,5.11-1.07,6.53-1.96,1.48-.71,2.55-.53,3.32.83l.71,1.19c.71,1.37.48,2.55-.83,3.32-1.96,1.25-5.52,3.03-10.27,3.03-10.03,0-16.26-7.24-16.26-15.84,0-9.32,6.29-15.84,15.37-15.84m5.94,12.4c-.12-4.21-2.67-6.71-6.11-6.71-3.86,0-6.59,2.67-7.3,6.71h13.41Z' />
            <path d='m366.95,379.14h-1.6c-1.6,0-2.43-.83-2.43-2.49v-1.01c0-1.6.83-2.49,2.49-2.49h1.72v-6.17c0-1.6.89-2.49,2.49-2.49h2.37c1.6,0,2.49.89,2.49,2.49v6.17h4.39c1.6,0,2.49.89,2.49,2.49v1.01c0,1.66-.89,2.49-2.43,2.49h-4.45v11.75c0,4.51,2.79,5.4,4.87,5.64,1.84.18,2.49.77,2.49,2.43v1.84c0,1.78-1.01,2.49-3.09,2.49-5.4,0-11.81-2.91-11.81-11.1v-13.06Z' />
            <path d='m397.81,372.02c8.61,0,13.41,6.11,13.41,14.42,0,1.31-1.13,2.55-2.61,2.55h-18.46c.48,5.46,4.51,8.31,9.08,8.31,2.79,0,5.1-1.07,6.53-1.96,1.48-.71,2.55-.53,3.32.83l.71,1.19c.71,1.37.47,2.55-.83,3.32-1.96,1.25-5.52,3.03-10.27,3.03-10.03,0-16.26-7.24-16.26-15.84,0-9.32,6.29-15.84,15.37-15.84m5.93,12.4c-.12-4.21-2.67-6.71-6.11-6.71-3.86,0-6.59,2.67-7.3,6.71h13.41Z' />
            <path d='m414.3,375.22c0-1.6.83-2.49,2.43-2.49h2.31c1.6,0,2.49.89,2.49,2.49v2.85c0,1.13-.18,2.08-.18,2.08h.12c1.19-3.62,4.51-7.65,8.78-7.65,1.66,0,2.25.83,2.25,2.49v2.31c0,1.6-.95,2.49-2.55,2.49-5.52,0-8.19,5.46-8.19,10.98v9.73c0,1.66-.83,2.49-2.43,2.49h-2.61c-1.6,0-2.43-.83-2.43-2.49v-25.28Z' />
            <path d='m471.76,366.05c3.36,0,6.04,2.73,6.04,6.12s-2.68,6.14-6.04,6.14-5.97-2.74-5.97-6.14,2.68-6.12,5.97-6.12m0,10.9c2.57,0,4.55-2.11,4.55-4.78s-1.97-4.77-4.55-4.77-4.48,2.11-4.48,4.77,1.96,4.78,4.48,4.78m-2.09-7.17c0-.45.23-.7.7-.7h1.84c1.22,0,2.01.77,2.01,1.94,0,.95-.65,1.47-1.07,1.59v.03s.07.07.22.35l.72,1.39c.25.49.1.82-.35.82h-.22c-.4,0-.65-.17-.8-.52l-.8-1.74h-.82v1.55c0,.47-.2.7-.65.7h-.12c-.45,0-.65-.23-.65-.7v-4.71Zm2.21,2.16c.57,0,.89-.35.89-.92s-.32-.9-.85-.9h-.82v1.82h.79Z' />
            <path d='m460.26,372.73h-2.61c-1.18,0-1.94.49-2.26,1.38-2.3-1.34-5.06-2.09-8.15-2.09-9.2,0-15.56,6.59-15.56,15.84s6.36,15.84,15.56,15.84c3.09,0,5.85-.74,8.15-2.07.32.91,1.08,1.36,2.26,1.36h2.61c1.6,0,2.43-.83,2.43-2.49v-25.28c0-1.6-.83-2.49-2.43-2.49Zm-13.02,24.57c-4.93,0-7.91-3.8-7.91-9.44s2.98-9.43,7.91-9.43,7.8,3.86,7.8,9.43-2.87,9.44-7.8,9.44Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Horizontal
