import { Col, Row } from 'antd'
import CLoader from 'components/CLoader/CLoader'
import H from 'components/texts/H/H'
import useSells from 'hooks/reports/useSells'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import formatNumber from 'utilities/numberFormater'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory'
const Sells = memo(({ maxHeight }: { maxHeight: number }) => {
  const { loadingFilter } = useSelector((state: RootState) => state.sells)

  const { sells, loading } = useSells()
  const VALUES = Object.entries(sells).map(([key, values]: any) => {
    return { fecha: values.fecha, total: parseFloat(values.total) }
  })
  function generateXAxisLabels(data: any) {
    return data.map(() => {
      return ''
    })
  }
  if (loadingFilter || loading) return <Col span={24} >
        <Row style={{height:maxHeight, alignItems:'center', justifyContent:'center', display:'flex'}}>
          <CLoader />
        </Row>
      </Col>

  return (
    <>
      <Col span={24}>
        <H variation='4'>Ventas</H>
      </Col>
      <Col span={24} style={{ maxHeight }}>
        <VictoryChart
          width={1500}
          theme={VictoryTheme.material}
          domainPadding={15}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension='x'
              labels={({ datum }) =>
                `${datum.fecha}: $  ${formatNumber(datum.total)}`
              }
              labelComponent={
                <VictoryTooltip
                  cornerRadius={0}
                  flyoutStyle={{ fill: 'white' }}
                />
              }
            />
          }
        >
          <VictoryAxis
            tickValues={generateXAxisLabels(
              VALUES
            )} /*  tickFormat={tickFormat} */
          />
          <VictoryAxis dependentAxis />
          <VictoryLine
            /* style={{
                labels: {display:'none'}
              }} */
            /* data={data2} */
            data={VALUES}
            x='fecha'
            y='total'
            interpolation='natural'
          />
        </VictoryChart>
      </Col>
    </>
  )
})
Sells.displayName = 'Sells'
export default Sells
