import React from 'react'

const ShortDeportivoFrontalSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='black'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_short" data-name="Outline short"   d="m205.84,518.38s22.04-185.85,34.17-208.1c12.14-22.25,10.36-41.9,10.36-41.9,67.36-9.84,62.83-9.41,102.62-9.41h103.16s84.96,4.04,87.48,9.1c2.53,5.06,8.56,40.26,8.56,40.26,0,0,42.52,171.24,42.02,204.63-.51,33.38-188.63,26.68-188.63,26.68l-8.6-59.67-9.1,59.67s-100.13,18.7-182.05-21.24Z"/>
      <polyline id="Abertura_derecha" data-name="Abertura derecha"   points="588.25 467.16 602.39 500.37 593.6 506.33"/>
      <path id="Pretina"   d="m251.77,266.86s28.27-10.91,120.26-10.86c33.48.02,44.19.73,44.19.73,0,0,129.67,3.31,128.69,11.73-.97,8.41-122.18,10.8-122.18,10.8h-62.41s-94.9-3.69-108.55-12.4Z"/>
      <path id="Resorte"   d="m241.35,308.76s4.17-27.81,5.83-31.56c2.16-4.88,4.19-10.15,4.19-10.15,0,0,49.78,9.83,121.22,12.4,39.7,1.43,52.66,0,52.66,0,0,0,88.72-.89,119.27-10.8l5.88,30.33,1.81,9.34s-62.6,11.11-134.75,9.89c-33.18-.57-50.05.1-50.05.1,0,0-89.06,0-126.06-9.55Z"/>
      <line id="División_enmedio" data-name="División enmedio"   x1="397.13" y1="486.75" x2="396.43" y2="317.16"/>
      <g id="Costuras">
        <g>
          <path   d="m207.61,504.43s.23.1.68.3"/>
          <path   d="m209.66,505.31c13.9,5.84,95.98,38.11,178.48,23.23"/>
          <path   d="m388.89,528.41c.25-.05.49-.09.74-.14"/>
        </g>
        <g>
          <path   d="m593.03,504.43s-.22.12-.66.35"/>
          <path   d="m591.01,505.43c-10.38,4.85-62.24,25.51-186.72,21.87"/>
          <path   d="m403.53,527.28c-.25,0-.5-.02-.75-.02"/>
        </g>
        <line   x1="392.9" y1="486.75" x2="392.2" y2="317.16"/>
        <g id="Bolsa_derecha" data-name="Bolsa derecha">
          <g>
            <line   x1="252" y1="318.65" x2="252.74" y2="318.8"/>
            <line   x1="254.6" y1="319.19" x2="257.38" y2="319.77"/>
            <path   d="m258.31,319.97l.73.15s-.04.25-.12.74"/>
            <path   d="m258.69,322.35c-2.26,14.17-14.55,91.28-14.95,93.39-.3,1.59-1.23,7.56-1.66,10.38"/>
            <path   d="m241.97,426.87c-.07.46-.11.74-.11.74l-.74-.12"/>
            <line   x1="239.55" y1="427.24" x2="234.07" y2="426.37"/>
            <line   x1="233.29" y1="426.25" x2="232.55" y2="426.13"/>
          </g>
        </g>
        <g id="Bolsa_izquierda" data-name="Bolsa izquierda">
          <g>
            <line   x1="542.44" y1="326.43" x2="541.72" y2="326.62"/>
            <line   x1="539.88" y1="327.1" x2="537.12" y2="327.82"/>
            <path   d="m536.21,328.05l-.73.19s.05.25.15.73"/>
            <path   d="m535.94,330.45c2.95,14.05,19,90.46,19.5,92.55.38,1.57,1.6,7.5,2.17,10.29"/>
            <path   d="m557.76,434.04c.09.46.15.73.15.73l.73-.15"/>
            <line   x1="560.19" y1="434.29" x2="565.62" y2="433.15"/>
            <line   x1="566.4" y1="432.98" x2="567.13" y2="432.83"/>
          </g>
        </g>
      </g>
      <polyline id="Abertura_izquierda" data-name="Abertura izquierda"   points="211.75 471.63 197.61 504.85 206.4 510.81"/>
      <path id="Línea_1_izquierda" data-name="Línea 1 izquierda"   d="m252,311s-12.72,45.55-19.45,96.86c-1.12,8.54-14.8,115.28-14.8,115.28"/>
      <path id="Línea_2_izquierda" data-name="Línea 2 izquierda"   d="m245.96,309.84s-12.68,44.71-19.41,96.01c-1.12,8.54-14.8,115.28-14.8,115.28"/>
      <path id="Línea_1_derecha" data-name="Línea 1 derecha"   d="m541.57,309.82s13.09,52.1,23.22,102.84c10.32,51.72,19.22,112.34,19.22,112.34"/>
      <path id="Línea_2_derecha" data-name="Línea 2 derecha"   d="m547.57,308.82s13.09,52.1,23.22,102.84c10.32,51.72,18.86,109.48,18.86,109.48"/>
      <g id="Textura">
        <path   d="m394.74,290.13c-.02,2.09-1.03,4.49-.15,6.54.11-.93-.17-1.53-.16-2.38.03-.79.3-1.26.36-1.96.13-1.25.17-3.44.08-4.65"/>
        <path   d="m394.71,306.9c.48-1.74,1.4-5.48.26-6.48-.05-.37-.12-.45-.22-.75-.31,2.22.91,3.54.3,5.46"/>
        <path   d="m394.66,315.3c.13-1.43.18-3.04.45-4.41.14-.71.34-1.4.44-2.09-.14.18-.21.54-.29.8-.2-.41-.06-1.01-.04-1.52-.51-.3-.25,1.17-.22,1.54.04.6.03,1.32-.08,1.98-.18.99-.31,1.86-.41,2.92-.05.57-.08,1.36-.21,1.8"/>
        <path   d="m247.66,279.17c-.2,1.47.1,2.56,0,3.89-.12,1.6-.32,3.17-.07,4.51.09.54.19.91.17,1.54-.02.58-.28,1.24-.3,1.74-.09,1.76.66.26.95-.36.08.34.11.73.08,1.13.62-.99-.42-4.31-.58-5.14-.15-.82-.26-1.73-.27-2.68,0-.92.14-.93.34-1.71.48-.39.79-1.51.61-2.21-.33-.12-.4.75-.69.59-.17-.1-.2-.96-.19-1.3"/>
        <path   d="m250.24,288.14c.51-1.3.51-2.5.44-3.76-.07-1.34-.59-2.35-.6-3.57-.01-1.4,1.42-4.06.4-4.9-.51.72-.39,2.41-.47,3.33-.08,1.03-.43,2.38-.41,3.37.02,1.13.49,1.55.57,2.69.07.94-.05,1.79-.26,2.85"/>
        <path   d="m244.25,306.95c.42-2.52,1.4-4.8.85-6.97-.34-1.31-.57-1.9-.24-3.75.15.49.29,1.08.52,1.4.22-.43.37-1.03.43-1.55.93-.43-.17,1.96-.27,2.43-.25,1.06.02,2.54.03,3.66,0,1.76.13,5.86-1.19,6.54"/>
        <path   d="m247.53,306.09c.67-.22.83-1.97.97-3,.21-1.47.19-2.35.1-3.62-.06-.95-.2-2.12-.12-3.1.06-.82.36-2-.07-2.33-.11.42-.15.85-.2,1.27-.27.02-.61-.27-.59-.89.03-.67.41-.84.55-1.36.26-.91-.12-1.95-.53-1.95-.14.67-.12,1.4-.17,2.07-.03.44-.09.71-.04,1.12.03.42.43,1.01.39,1.4-.07.78-.7,1.42-.73,2.44-.03,1.07.24.57.6.81,1.26.82.1,4.65,0,6.6"/>
        <path   d="m396.41,313.44c-.15-.85.53-1.5.65-2.35.09-.65.16-1.42.18-2.06-.63.41-.32,1.47-.4,2.25-.1.74-.45,1.47-.53,2.31"/>
        <path   d="m353.63,309.62s2.61,1.07,2.45,2.01"/>
        <path   d="m252.67,270.32s-2.39,5.13-.97,5.09"/>
        <path   d="m253.96,272.19s-1.81,7.66.81,6.29"/>
        <path   d="m256.06,268.2c-.26,2.04-.29,2.81-.15,4.52.13,1.72.02,2.12-.82,3.46"/>
        <path   d="m257.23,273.37c-.54,1.76-.44,2.93.08,3.8-.31.52-.5,1.21-.41,1.81"/>
        <path   d="m260.37,269.54c-.17,1.32-.3,2.65-.5,3.94-.04-.06-.09-.1-.14-.09.44,1.27-.44,2.79-.47,3.8"/>
        <path   d="m259.99,279.82c.38-1.35.57-2.58.75-4.01"/>
        <path   d="m263.89,280.78c.46-2.28.09-3.06-.23-4.94-.25-1.36.5-4.13-.14-5"/>
        <path   d="m262.18,277.09c.32-2.58.44-4.99.83-7.45"/>
        <path   d="m265.12,275.29c.15-.62.49-1.09.54-1.78.03-.45-.23-.81-.21-1.29.04-.89.08-1.42.62-1.77"/>
        <path   d="m266.91,274.18c.05,1.37-.36,2.35-.25,3.89.08,1.12.34,2.16-.08,3.01"/>
        <path   d="m268.44,275.35c-.73-1.13-.69-2.85-.85-4.51"/>
        <path   d="m268.98,274.18c.12,1.28.54,3.53.34,4.73-.13.77-1.58,2.11-.43,2.78"/>
        <path   d="m270.63,277.34c.71-1.29.18-4.72.02-6.51"/>
        <path   d="m271.05,282.66c.1-1.33-.13-3.36-.5-4.57"/>
        <path   d="m272.3,274.73c.14,1.54-.6,2.55-.51,3.92"/>
        <path   d="m272.32,280.73c.89-.39,1.59-2.48,1.46-4.27-.05-.67-1.06-3.68.15-3.23.63.25.6,3.04.7,4.08.16,1.74.46,2.85.74,4.21"/>
        <path   d="m280.34,286.17c-.09-1.43.02-4.71-.84-5.46-.48,1.16.12,4.24.25,5.73"/>
        <path   d="m278.52,280.26c.02-1.32-.16-7.87-.98-8.14-1.46-.5.22,6.16.26,6.94"/>
        <path   d="m277.69,283.61c.09-1.84.03-4.88-.86-6.33-.88.97-.34,6.21.18,7.61"/>
        <path   d="m280.04,277.58c-.15-1.62.1-3.07-.42-4.59"/>
        <path   d="m281.38,279.46c.14,1.27.64,2.39.59,3.71-.02.73-.66,2.21-.08,2.69"/>
        <path   d="m283.34,280.26c-.13-2.18-2.4-5.3-.92-7.16"/>
        <path   d="m283.96,279.18c.18,1.92.14,2.63-.05,4.3-.12.92-.29,3.11.4,3.3"/>
        <path   d="m285.47,278.96c-.14-1.41-.69-3.94.19-4.55"/>
        <path   d="m285.65,281.79c.33,2.18-.27,4.2-.13,6.21"/>
        <path   d="m287.31,280.2c-.17-1.95-.77-4.93.15-6.21"/>
        <path   d="m287.37,287.76c-.1-1.22-.54-2.95-.19-3.98"/>
        <path   d="m287.96,282.53c.05-.37.04-.99-.05-1.49"/>
        <path   d="m289.02,273.32c.18,1.57.7,2.77.9,4.32.17,1.11.31,2.26.41,3.43"/>
        <path   d="m301.89,290.91c-.18-1.98.68-4.66-.34-6.45"/>
        <path   d="m304.84,288.56c.1-1.39.93-1.79.65-3.45-.14-.89-1.4-2.79-.68-3.54"/>
        <path   d="m306.3,281.59c-.6-1.66-1.41-3.92-1.5-5.89"/>
        <path   d="m306.94,290.74c-.1-1.13-.7-3.27-.57-4.23"/>
        <path   d="m308.18,287.33c0-1.72.16-3.35-.43-4.96-.58-1.55-.89-1.64-.97-3.6"/>
        <path   d="m308.89,283.1c2.49,2.38-.47,6.26.61,8.56"/>
        <path   d="m311.56,288.53c-.2-1.54-.07-3.1-.44-4.6-.34-1.32-.99-2.51-.63-4.2"/>
        <path   d="m312.78,283.86c.12,1.4.29,2.81.39,4.21.06.91-.15,2.22.14,2.51"/>
        <path   d="m311.83,279.38c-.55-1.34-.14-2.21-.41-3.58"/>
        <path   d="m312.69,280.85c.05.59.11,1.21.17,1.78.18.05.37.25.54.25"/>
        <path   d="m314.82,288.24c-.02.86.12,1.83-.3,2.37"/>
        <path   d="m315.58,291.34c-.14-1.51.02-3.18.79-3.81"/>
        <path   d="m316.55,286.35c-.99-.97.41-3.64-.12-5.14"/>
        <path   d="m312.77,278.1c.16-.57.04-1.15-.16-1.64"/>
        <path   d="m316.55,288.27c.72.53.39,2.04.46,3.09"/>
        <path   d="m317.53,287.89c-.14-.58-.15-1.09.06-1.34"/>
        <path   d="m316.23,276.97c1.04,1.39.53,4.7,1.12,6.46"/>
        <path   d="m318.28,290.71c.67-1.91-.39-5.05-.24-7.28"/>
        <path   d="m320.15,277.03c-.05,1.31-1.44,7.11-.29,7.38"/>
        <path   d="m320.95,291c.34-1.48-.2-5.08-.36-6.77"/>
        <path   d="m321.1,279.16c.21,2.29.85,5.72.36,7.77"/>
        <path   d="m322.83,291.18c-.15-1.66-.55-3.83-.13-5.31"/>
        <path   d="m323.73,285.89c-.09-.98-.6-4.16-.24-4.63"/>
        <path   d="m325.15,283.35c.2,2.16.62,4.07-.38,5.44-.18-2.03.2-4.09-.04-6.37"/>
        <path   d="m324.68,277.8c-.27.71-.49,2.01-.52,2.93"/>
        <path   d="m325.81,278.72c.17,1.91.29,3.53.03,5.33.8.32.51-1.28.42-2.26"/>
        <path   d="m327.53,282.75c.14-1.6-.9-5.16-.04-6.2.36,1.28-.2,6.37.94,6.37"/>
        <path   d="m327.85,291.73c-.19-1.57-.72-3.84.08-4.91.17,1.94.11,3.53.14,5.37"/>
        <path   d="m329.43,287.57c-.07-.86-.91-6.17-.41-6.23.89-.08.67,5.12.74,6"/>
        <path   d="m330.69,284.47c-.48-1.74-1.39-5.49-.26-6.48.05-.38.12-.46.21-.76.31,2.21-.91,3.54-.29,5.47"/>
        <path   d="m331.61,293.33c-.23-2.04-.72-3.95-.78-6,1.01-.39,1.08,4.12.97,5.13"/>
        <path   d="m332.58,287.51c-.13-1.55-.33-3.23-.34-4.72-.01-1.24-.3-1.88-.37-3.09.39,1.71.82,5.39.66,7.17"/>
        <path   d="m335.39,285.48c-.23-2.45-.53-4.79-.73-7.17,1.3-.67.61,3.78.78,4.84"/>
        <path   d="m337.13,290.45c-.1-2.02-.58-3.97-.72-6.01.04,0,.2-.03.24-.05.27.94.27,2.16.41,3.16.02-.69-.08-1.44-.13-2.14.69,1.05.73,2.89.83,4.41-.4-.39-.37-1.29-.77-1.84"/>
        <path   d="m338.15,281.58c.02,2.09,1.03,4.49.16,6.54-.11-.93.17-1.53.16-2.38-.04-.79-.3-1.25-.37-1.96-.12-1.25-.17-3.44-.08-4.65"/>
        <path   d="m339.92,294.8c-.14-1.49-.92-4.9-.68-5.97.77,1.64.83,2.82,1.02,4.9"/>
        <path   d="m250.35,282.98c1.06-.04,1.52-.21,1.81-2.6"/>
        <path   d="m254.06,279.76c-.67,1.94-1.61,3.95-1.74,6.21-.12,2.19.67,3.4-.03,5.8.43-1.52.66-2.97.9-4.92"/>
        <path   d="m254.44,288.57c.17-.63.5-1.15.82-1.46.17.9-.15,2.32-.25,3.4.11.04.22.03.33.04"/>
        <path   d="m254.63,286.16c.16-1.31-.21-2.27-.12-3.52.12-1.75.42-1.73,1.17-2.84"/>
        <path   d="m257.04,280.18c-.18,1.63-.51,4.21-.28,5.6.89-.92,1.79-3.85,2.3-5.5"/>
        <path   d="m257.31,291.17c.09-.78.79-4.39.28-4.68-.52-.29-1.14,2.58-1.19,3.44"/>
        <path   d="m260.7,292.46c.6-2.96,1.06-5.8,1.43-8.87.12-.99.47-2.48.12-3.1-.88,1.31-1.38,4.87-1.95,6.77-.41,1.33-.83,2.72-1.22,4.02"/>
        <path   d="m261.56,299.43c.27-1.24.47-2.73.55-3.99-.65.14-1.27,1.47-1.37,2.77"/>
        <path   d="m263.01,295.74c.44-3.54.84-7,1.1-10.48.11-1.4.52-5.06,1.51-4.4,1.39.89-.9,4.28-1.09,5.97-.13,1.23.22,2.21.18,3.44-.05,1.84-.46,3.94-.68,5.78"/>
        <path   d="m270.81,295.93c-.16-2.93-1.15-6.16-1.54-9.06-.23-1.74-1.04-2.52-1.25-4.07.04,1.45.54,2.78.74,4.17.14,1.01.22,2.08.36,3.06.33,2.3.55,4.03.49,6.29"/>
        <path   d="m272.74,297.06c-.24-2.12-.05-5.81-1.03-7.46-.12,1.2-.02,2.49-.08,3.69-.05.99-.22,1.86-.27,2.86"/>
        <path   d="m274.58,296.07c-.13-1.3.68-1.88.59-3.14-.07-.93-.82-2.48-1.17-3.23-.81-1.74-1.25-2.29-1.49-4.63.45,4.07.76,8.6,1,12.67.14.02.3.03.43-.05"/>
        <path   d="m277.41,299.21c-.02-.93.26-1.76.29-2.49-.07.16-.3.36-.38.47.1.06.21-.04.29,0-.13.08-.37.09-.52.1.23-.9-.12-2.09.23-3.04.43-1.18,1.45-.64,1.71-1.99.39-2-1.09-5.23-1.93-6.11-.1,1.31.29,2.63.23,3.92-.05,1.2-.48,1.5-.73,2.3-.53,1.73-.05,4.26.14,6.32"/>
        <path   d="m279.26,300.38c-.08-.89.2-1.67.21-2.55,0-.66-.12-1.46-.2-2.12-.45.97-.61,3.19-.72,4.47"/>
        <path   d="m281.68,299.93c-.26-2.68-.83-5.25-1.15-7.87-.17-1.34.02-3.52-.66-4.37-.22,2.01.35,5.04.54,7.14.2,2.17.15,3.22-.22,5.2"/>
        <path   d="m284.05,300.79c-.22-2.42-.48-4.91-.77-7.28-.48,1.92.76,6.28-.66,7.03"/>
        <path   d="m286.31,298.1c.39-2.19-.33-3.74-1-5.27-.53-1.17-.59-2.45-.71-3.98-.03,1.61-.1,2.8.04,4.43.14,1.52.32,3.05-.12,4.33.01.17.07.21.18.12"/>
        <path   d="m289.11,300.93c-.2-2.68-.9-5.62-1.79-7.85-.38,1.47.51,3.72.61,5.44.06,1.09.01,2.12.12,3.25"/>
        <path   d="m290.27,299.09c-.2-2.26-.23-5.02-1.08-6.76-.54-1.14-1.14-1.58-1.23-3.3.36,1.88.43,3.74.69,5.7.22,1.62.54,3.01.7,4.7"/>
        <path   d="m303.68,290.87c-.06-.91.46-1.08.49-1.81.03-.75-.35-1.57-.49-2.23"/>
        <path   d="m309.14,281.86c-.18-.68-.29-1.34-.37-2.1-.05-.59-.02-1.09-.21-1.53-.34-.78-.91-1-.95-2.18"/>
        <path   d="m314.96,284.42c-.01-.37-.14-.64-.3-.82-.16.22-.51.85-.55,1.24-.08.71.28,1.75.34,2.47"/>
        <path   d="m314.5,280.33c.17,0,.3-.18.37-.44-.04-.04-.12-.06-.16-.06-.02-.06-.03-.11,0-.16.11.14.05-.02.12.22-.2-.41-.17-.97-.21-1.51-.06-.67-.17-.94.22-1.18"/>
        <path   d="m322.44,281.4c-.26-.96-.29-1.72.05-2.54.33-.77.23-1.11-.13-1.94"/>
        <path   d="m333.32,283.63c.09-1.02.65-1.83.34-2.94-.29-1.04-.74-1.6-.69-2.89"/>
        <path   d="m337.3,280.15c-.14-.2-.37-.55-.45-.86-.12-.51.04-.47.04-.98.07.06.11.03.18.13-.03-.1-.06-.19-.12-.27-.08.42.14.46.18.77.03.24-.06.5-.04.77"/>
        <path   d="m290.27,291.94c-.06,2.63.78,4.73,1.2,7.27.16.97.13,1.63,0,2.6-.12.89,0,1.54.46.62.28-.61.22-1.69.31-2.51.12-1.01.12-2.03-.21-3.02-.21-.59-.51-1.04-.67-1.66-.17-.66-.19-1.49-.26-2.21"/>
        <path   d="m295.04,300.56c-.67-2.21-1.26-4.13-1.48-6.58-.12-1.32-.1-2.67-.59-3.62-.06,0-.1,0-.15,0-.26,1.9.22,3.84.8,5.5.59,1.72,1.11,3.76,1.34,5.72"/>
        <path   d="m295.72,292.28c.25,2.63.55,5.15,1.09,7.62.18.82.28,1.6.23,2.46-.02.48-.13.93-.08,1.43.92-.16.29-4.79.16-5.85-.15-1.21-.49-2.32-.87-3.32-.19-.48-.46-.9-.49-1.5"/>
        <path   d="m296.82,290.58c.22,2.45.44,4.31,1.35,6.25.24.52.51,1.06.6,1.72.09.7-.05,1.34.08,2.07,1.23-.45.17-3.45-.3-4.7-.3-.81-.59-1.11-.68-2.02-.09-.71-.14-1.42-.2-2.14"/>
        <path   d="m299.19,290.96c.12,1.23.21,2.45.29,3.64.36-.9.02-2.73-.08-3.85"/>
        <path   d="m299.19,294.73c.19,2.01.89,3.02,1.39,4.74.42,1.44.43,3.09.57,4.66"/>
        <path   d="m311.16,302.23c-1-3-1.38-6.35-1.75-9.71.21,1.04.3,1.92.48,2.96.15.98.26,2.32.49,3.29.17.77.51,1.21.65,2.01.12.68.14,1.5.2,2.21"/>
        <path   d="m311.67,301.76c-.17-1.4.08-2.31.06-3.47.08.9.16,1.77.24,2.65"/>
        <path   d="m311.59,295.57c-.42-.78-.78-1.55-.75-2.69.49.58.77,1.42.8,2.45"/>
        <path   d="m313.05,299.51c-.14-1.61-.17-3.33-.39-4.89-.32,1.4-.3,3.34-.14,4.87"/>
        <path   d="m313.49,297.81c.8-1.89.44-3.85.23-6.17"/>
        <path   d="m314.15,295.98c-.07,2.96.04,6.64,1.38,8.76-.11-2.09-.68-3.17-1.05-5.02-.2-.99-.42-2.65-.18-3.52"/>
        <path   d="m315.55,292.27c.14,1.82-.04,3.58.25,5.47.21,1.42.43,2.63.56,4.11"/>
        <path   d="m318.47,304.92c-.63-1.63-.7-3.33-.88-5.21"/>
        <path   d="m317.92,292.2c.04,2.33.9,4.63.65,7.01"/>
        <path   d="m319.79,298.75c.06.69.32,5.3.87,5.35.53.04-.54-3.97-.56-4.78"/>
        <path   d="m320.27,291.77c.23,2.48.52,4.98.55,7.42.01.98.35,2.35.54.87.12-.89-.31-2.62-.39-3.55"/>
        <path   d="m322.59,292.18c.13,1.15.5,2.17.64,3.38.12,1,.16,2.03.28,3.04.2,1.73.34,3.52.56,5.28.77.27.37-1.63.19-2.26-.34-1.22-.53-2.37-.69-3.74-.18-1.51-.04-4.71-.7-5.51"/>
        <path   d="m325.19,292.77c.22,2.41.81,4.5,1.09,6.87.17,1.4.07,2.76.26,4.11,1.06.83.2-1.86.1-2.41-.29-1.67-.6-3.62-.75-5.33"/>
        <path   d="m327.77,293.28c-.06,2.05.38,4.27.57,6.28.1,1.13.11,4.03.63,4.65.29-.76-.01-1.93-.2-2.74"/>
        <path   d="m330.37,301.07c-.13,1.1.27,2.63.23,3.82.96.03.16-3.11-.28-3.7"/>
        <path   d="m330.54,295.04c-.71-.12-.54,1.35-.46,2.2.1,1.01-.04,1.54-.03,2.38.7.14.49-1.09.41-1.93-.11-1.08.08-1.6.1-2.54"/>
        <path   d="m333.05,305.62c-.01-.91.04-1.91-.18-2.74-.18-.8-.49-1-.51-2.01.97.05,1.02.73,1.08,2.05.04.75.21,1.82.05,2.5"/>
        <path   d="m332.87,298.66c-.04-.67-.23-1.51-.55-1.92-.29-.37-.62-.02-.74-.74"/>
        <path   d="m334.24,298.48c-.1-1.14-.2-2.28-.31-3.44"/>
        <path   d="m323.77,289.88c.13.59.38,1.21.71,1.41.18-.78.66-1.32.59-2.28"/>
        <path   d="m326.4,289.31c-.03.33-.04.67-.03,1"/>
        <path   d="m326.12,285.53c.24.55.44,1.24.56,1.89"/>
        <path   d="m334.37,301.99c-.06,1,.3,1.53.34,2.41-.17.16-.17.38-.27.58"/>
        <path   d="m333.67,300.02c-.08-.51-.1-1.07-.08-1.61"/>
        <path   d="m338.04,295.62c.56,1.04.65,4.23.79,5.73"/>
        <path   d="m337.89,306.89c-.06-1.2-.37-2.3-.64-3.39-.4-1.69-.69-3.26-.98-5.02-.18-1.1-.97-3.34-.17-3.97.67,1.78.62,3.96,1.26,5.85.74,2.19.78,4.42,1.09,6.8"/>
        <path   d="m329.63,293.42c.2-.8-.08-1.9.08-2.76.22.54.1,1.4.19,2.09"/>
        <path   d="m330.93,285.67c.16.52.52,1.14.78,1.32.06-.69-.08-1.49-.14-2.2"/>
        <path   d="m332.61,291.82c.25.47.56.84.71,1.36"/>
        <path   d="m333.87,290.17c-.08-.86-.16-1.72-.24-2.58"/>
        <path   d="m339.34,306.48c-.27-.86-.42-2.36-.36-3.35.33.76.63,1.35.62,2.3,0,.61-.38.9-.07,1.51"/>
        <path   d="m340.54,302.93c-.31-.5-1.06-3.33-.46-3.52.23.91.54,1.73.59,2.74"/>
        <path   d="m341.86,303.97c.3-1.23-.15-3.83-.4-5.15-.21-1.05-.85-1.89-.94-2.98"/>
        <path   d="m341.55,306.81c-.16-.54-.39-1.05-.6-1.51"/>
        <path   d="m342.95,299.91c-.07-.78-.16-1.55-.2-2.31.25-.22.46-.6.67-.92-.24.57-.41,1.56-.35,2.35"/>
        <path   d="m343.63,307.39c-.02-1.46-.69-2.15-.52-3.5.12-.93.21-1.25.1-2.42"/>
        <path   d="m345.11,303.82c-.11-1.19-.19-2.22-.5-3.3-.21-.75-.42-1.08-.45-1.99.95,1.04.97,3.35,1.13,5.14"/>
        <path   d="m341.27,313.23c.5.98.65,1.89.47,3.04"/>
        <path   d="m339.41,280.38c-.18-.54-.34-1.48-.41-2.23.1.38.69,2.24,1.04,1.89"/>
        <path   d="m337.7,292.41c.18.55.5.8.53,1.53"/>
        <path   d="m336.98,293.2c.08.83-.41.62-.69.96-.08-.74-.02-1.84-.45-2.12"/>
        <path   d="m334.86,287.33c-.32.77,0,1.69.32,2.32-.3.73-.68-.19-.38-.83.22-.51,1.19-1.05.24-1.77"/>
        <path   d="m335.07,292.93c-.53.54-1.05-.33-1.05-1.45"/>
        <path   d="m250.56,290.77c-.2,1.47.1,2.56,0,3.89-.12,1.6-.32,3.17-.07,4.51.09.54.19.91.17,1.54-.02.58-.28,1.24-.3,1.74-.09,1.76.66.26.95-.36.08.34.11.73.08,1.13.62-.99-.42-4.31-.58-5.14-.15-.82-.26-1.73-.27-2.68,0-.92.14-.93.34-1.71.48-.39.79-1.51.61-2.21-.33-.12-.4.75-.69.59-.17-.1-.2-.96-.19-1.3"/>
        <path   d="m252.17,309.38c.51-1.3.51-2.5.44-3.76-.07-1.34-.59-2.35-.6-3.57-.01-1.4,1.42-4.06.4-4.9-.51.72-.39,2.41-.47,3.33-.08,1.03-.43,2.38-.41,3.37.02,1.13.49,1.55.57,2.69.07.94-.05,1.79-.26,2.85"/>
        <path   d="m253.2,296.18c.11-.9.65-3.43-.03-3.37-.36,1.24-.23,2.95-.69,3.95"/>
        <path   d="m254.77,301.9c.13-1.06.31-2.2.34-3.23.04-1.08-.2-1.91-.19-2.97,0-1.32.43-1.54.55-2.76.08-.88-.13-1.45-.63-1.17-.3,1.19.18,1.76.1,2.82-.07.94-.45,1.7-.45,2.66,0,1.05.2,1.59.1,2.77-.06.86-.2,1.74-.3,2.6"/>
        <path   d="m255.44,308.35c.42-2.52,1.4-4.8.85-6.97-.34-1.31-.57-1.9-.24-3.75.15.49.29,1.08.52,1.4.22-.43.37-1.03.43-1.55.93-.43-.17,1.96-.27,2.43-.25,1.06.02,2.54.03,3.66,0,1.76.13,5.86-1.19,6.54"/>
        <path   d="m258.72,307.48c.67-.22.83-1.97.97-3,.21-1.47.19-2.35.1-3.62-.06-.95-.2-2.12-.12-3.1.06-.82.36-2-.07-2.33-.11.42-.15.85-.2,1.27-.27.02-.61-.27-.59-.89.03-.67.41-.84.55-1.36.26-.91-.12-1.95-.53-1.95-.14.67-.12,1.4-.17,2.07-.03.44-.09.71-.04,1.12.03.42.43,1.01.39,1.4-.07.78-.7,1.42-.73,2.44-.03,1.07.24.57.6.81,1.26.82.1,4.65,0,6.6"/>
        <path   d="m262.55,300.38c.05,1.18-.45,1.09-.52,1.99-.08,1.08.61,1.88.91,2.57.59,1.4.34,3.21.48,4.87.05.54.31,1.81-.07,2.03-.16.11-.46-.39-.5-.76-.04-.4.14-.73.15-1.15.04-1.21-.42-2.67-.8-3.57-.29-.71-.82-1.43-.93-2.32-.07-.72.14-1.59.09-2.35-.02-.51-.33-1.93.2-1.66,0,.05-.05.04-.05.03.09.43.09.77.28,1.06.17-.44.53-1.06.82-.58"/>
        <path   d="m265.5,304.06c-.07-.73-.05-1.91-.21-2.58-.14-.65-.47-1.18-.67-1.7-.35-.99-.75-2.19-.82-3.39-.56,1.99-.05,3.25.66,4.62.61,1.15.74,2.19.89,3.71"/>
        <path   d="m267.71,310.35c-.22-2.35-.41-3.62-1.21-5.46-.44-1.01-1-2.45-.54-3.52.64.66.74,1.2.88,2.39.12,1.08.26,1.51.6,2.4.31.77.53,1.59.57,2.61.04.77-.49,2.9-.3,3.1"/>
        <path   d="m268.47,305.12c.92-1.07.62-3.7-.02-5.1-.56-1.22-.71-3.22-1.46-4.04-.04,1.17.61,1.92.89,2.87.21.72.27,1.3.34,2.16.1,1.02.29,1.48.48,2.31.17.71.23,1.32-.12,1.66"/>
        <path   d="m271.43,311.84c-.19-2.02-.28-2.68-1.03-4.02-.41-.73-1.13-2.32-.49-3.15.04,1.23.14,1.31.6,1.59.26.17.36-.17.61.14.17.22.38,1.07.46,1.43.34,1.52-.06,3.51.38,4.8"/>
        <path   d="m271.63,307.11c-.11-1.22.15-2.45-.09-3.83-.21-1.24-.65-1.71-1.05-2.82-.15-.44-.84-1.98-.81-2.5.05-.96.76-.53.99.06.29.76.15,1.88.32,2.7.15.76.49,1.3.66,2.14.14.73.29,1.79.29,2.57,0,.72-.44,1.88-.04,2.38"/>
        <path   d="m272.94,309.36s-.33-.83-.4-1.42"/>
        <path   d="m273.8,309.59c.15-.54.12-1.25.02-1.88"/>
        <path   d="m273.01,310.49c.08.55.05,1.07.09,1.62"/>
        <path   d="m274.03,306.43c-.55-1.42-.88-2.22-1.1-4.04-.08-.74-.17-1.44-.37-2.08-.17-.54-.45-.73-.51-1.38.26-.17,1-.06,1.24.24.68.85.51,1.98.46,2.9-.06,1.31.18,2.61.31,3.97"/>
        <path   d="m276.48,305.23c-.09-.62-.72-1.05-1.04-.67-.58.66.04,1.75.37,2.44.42.85.68,1.31.86,2.44.22,1.28.14,2.55.83,3.45.13-.91-.13-2.55-.32-3.5-.12-.65-.48-1.15-.63-1.78-.17-.77-.14-1.54-.19-2.35"/>
        <path   d="m277.83,301.25c-.09.56-.26,1.01-.17,1.64.68.04,1.09,2.17,1.21,3.17.05.55.05,1.19.15,1.73.11.54.4.71.39,1.38-.14-1.54-.25-3.28-.5-4.74-.25-1.43-1.14-1.71-.77-3.35"/>
        <path   d="m274.93,300.46c-.15.73.14,1.2.53.88,0-.21-.01-.51-.06-.71"/>
        <path   d="m278.29,312.43c.01.24.08.47.15.71.33,0,.41-.62.22-1.04"/>
        <path   d="m277.2,304.23c-.33-.43-.69-1.25-.44-1.91"/>
        <path   d="m281.48,313.39c.89-3.78-1.77-4.85-1.62-8.45.13.04.3.18.4.35-.1.2-.1.58-.16.72.24.19.46.4.69.5"/>
        <path   d="m281.27,306.9c.29-1.53.1-3.26-.06-4.92.42,1.84.78,3.44.96,5.36"/>
        <path   d="m284.04,313.84c-.06-2.28-.06-3.97-.57-6.16-.16-.73-.64-1.64-.67-2.45.08-.1.14-.15.22-.18.51.94.65,2.33.81,3.57.21-.05.44-.18.62-.34.4,1.41.23,3.52.32,5.04"/>
        <path   d="m285.38,311.02c.09-1.82-.12-4.47-.56-6.2-.22-.9-.73-2.24,0-2.69.09.81.15,1.64.23,2.44.36-.29.64-.92.52-1.66.69,1.61.14,3.16.07,4.68-.05,1.15.4,2.5.01,3.49"/>
        <path   d="m287.12,314.08c-.48-2.32-.31-4.38-.21-6.59"/>
        <path   d="m287.43,302.26c.21,1.16.55,2.15.75,3.23.18.87.16,1.26.58,2.01.15.24.41.48.51.69.38.88.37,1.89.48,3,.14,1.43.29,1.31,1.03,1.18"/>
        <path   d="m291.16,313.08c.33-1.34-.74-4.08-1.03-5.4-.1-.49-.58-2.2-.43-2.68.27-.85.64.04.76.55.16.69.03,1.45.13,2.14.09.71.37,1.33.52,2.03.31,1.57.65,3.22.79,4.84-.15-.36-.34-.89-.35-1.42"/>
        <path   d="m292.82,310.93c-.12-1.31-.16-2.87-.44-4.11-.16-.72-.69-1.48-.8-2.16-.08-.54-.06-1.02.23-1.17.55-.27.56,1.15.62,1.81.19,1.94.92,3.94.74,5.94"/>
        <path   d="m294.13,309.66c.14,1.57-.13,3.38,0,5.07-.67-2.14-.53-3.21-.48-5.24"/>
        <path   d="m307.8,310.5c-.79-1.39-1.02-2.14-1.16-4.09.49,1.22,1.49,3.13,1.71,4.49"/>
        <path   d="m311.79,305.02c-.07-.65-.04-1.32-.32-1.8-.01.4,0,.82.06,1.21"/>
        <path   d="m307.56,309.01c.11,1.29-.06,2.2-.02,3.38.04,1.15.49,2.54.79,3.5-1.71-.56-.67-5.13-1.69-6.39.12.28.24.47.37.73"/>
        <path   d="m309.45,313.24c-.74-.8.14-2.35.2-3.3.09-1.19-.65-2.31-.41-3.17"/>
        <path   d="m310.86,305.85c.04.37.08.74.11,1.11"/>
        <path   d="m311.1,315.27c-.29-1.03-1.06-3.11-.86-4.36.21-1.28,1.08.2,1.1,1.29-1.38-.3.34,2.86.39,3.45"/>
        <path   d="m312.11,306.45c.38.49.94,1.61.8,2.6-.04.38-.34.48-.38,1-.02.35.08.91.16,1.2-.13-.96-.53-1.71-.64-2.69-.09-.74-.1-1.49-.17-2.23"/>
        <path   d="m313.31,310.55c-.22.97-.22,1.56.09,2.4.14.41.24.44.3.95.04.27-.09.81-.04.99.11.41.49.51.63.08.15-.45-.15-.83-.3-1.21-.33-.8-.44-1.53-.54-2.5"/>
        <path   d="m314.46,310.28c-.02-.69.22-1.21.22-1.89-.01-.58-.19-1.1-.2-1.69.36.1.35,1.6.41,2.17"/>
        <path   d="m315.65,314.69c-.12-.8-.25-1.39-.53-2.07-.18-.43-.49-.92-.12-1.31.4.54.67,1.26.85,2.1.14.69.38,1.35.42,2.11"/>
        <path   d="m316.6,309.62c-.07-.85-.16-1.49-.37-2.28-.18-.6-.33-.97-.11-1.64.43.92.5,2.1.61,3.3"/>
        <path   d="m317.31,315.44c-.27-.74-.65-1.11-.75-2.05-.06-.58.04-1.14.02-1.73.67,0,.45,1.5.55,2.14.1.77.3,1.4.38,2.28"/>
        <path   d="m317.34,309.58c.23,1.11.9,1.56,1.21,2.52.1.29.08.58.16.9.16.67.51,1.16.79.29.35-1.18-.28-2.78-.68-3.72-.03.72.47,3.2.22,3.63"/>
        <path   d="m319.64,310.2c-.13-1.42-.59-3.2-.47-4.6.71,1.12.74,2.81.88,4.34"/>
        <path   d="m319.63,315.69c-.22-.07-.38-.39-.46-.75"/>
        <path   d="m321.46,312.38c-.08-.95-.11-1.82-.07-2.67,0-.49.05-.96-.09-1.54"/>
        <path   d="m324.64,305.84c-.22-.24-.4-.65-.45-1.08"/>
        <path   d="m339.84,307.69c.1.17.17.45.19.71"/>
        <path   d="m322.43,304.82c.07.44.03.87.08,1.32"/>
        <path   d="m320.82,305.48c0,.16-.01.32.01.49"/>
        <path   d="m321.03,315.68c-.03-.79-.37-1.16-.43-1.85"/>
        <path   d="m325.67,306.32c.08-.19.18-.39.27-.53"/>
        <path   d="m322.24,313.21c.06.36.03.76-.03,1.09"/>
        <path   d="m342.69,308.25c0,.44.2.71.39.93"/>
        <path   d="m321.6,306.78c.3.12.43.77.51,1.26.19,1.15.12,2.13.63,3.04.36.63.54.71.5-.25-.05-1.16-.69-1.88-.75-2.94"/>
        <path   d="m323.82,315.45c-.29-.45-.59-.97-.62-1.7.03-.05.11-.08.15-.11.25.31,1.01,1.66,1,2.22"/>
        <path   d="m323.94,308.86c-.07-.94-.39-1.66-.46-2.57.08.6.14,2.06.58,2.05"/>
        <path   d="m323.7,312.1c.04.5.25.77.38,1.16.16.06.21.05.36.24-.44-1.28-.53-1.4.25-1.6,0-.14-.03-.2-.07-.32"/>
        <path   d="m325.2,307.07c-.03.76.06,1.66.39,2.23.23.42.67.73.83.04-.31-.5-.93-.06-.9-1.1"/>
        <path   d="m325.2,310.31c0-.26,0-.48-.03-.74"/>
        <path   d="m325.64,312.92c.02.19.04.39.06.58"/>
        <path   d="m325.51,314.54c-.07.25-.14.53-.19.79"/>
        <path   d="m326.61,307.04c.03.38.05.85.14,1.13"/>
        <path   d="m327.23,315.64c-.08-1-.24-2.01-.49-2.96-.22-.81-.51-1.38-.6-2.25.3,1.17.61,1.94.73,3.21"/>
        <path   d="m328.87,315.76c-.2-.94-.18-1.17,0-2,.1-.5.14-.83.04-1.42-.26-1.39-.94-2.11-1.24-3.38.68,1.24,1.23,2.15,1.46,3.84.08.57.09,1.07.07,1.6-.01.58-.25,1.64-.13,2.07"/>
        <path   d="m329.84,311.63c-.49-1.32-.92-2.84-1.22-4.35-.2-.98.1-1.49.04-2.42.06.74.25,1.48.29,2.19,0-.24-.03-.66,0-.93.47,1.04.4,1.9.28,2.99-.13,1.12.25,1.43.55,2.37"/>
        <path   d="m332,315.63c-.09-.75-.24-1.49-.4-2.22.32-.84-.82-2.91-.82-4.04.61.31,1.27,2.27,1.4,3.38.12,1.12.16,2.2.21,3.32"/>
        <path   d="m332.55,312.06c.74-1.8-.96-4.01-.76-5.77,1.09.42,1.03,3.86,1.17,5.44"/>
        <path   d="m334.19,316.16c0-1.37-.22-2.57-.39-3.91-.08-.57-.37-1.66.08-1.36.56.36.22,1.28.18,1.93-.03.76.1,1.57.17,2.34"/>
        <path   d="m335.57,313.39c-.08-1.28-.67-2.68-.81-4.03-.08-.74-.13-1.47-.19-2.2"/>
        <path   d="m337.37,316.24c-.15-1.11-.66-2.83-.54-3.88"/>
        <path   d="m337.34,310.36c-.1-.44-.38-.8-.51-1.25"/>
        <path   d="m339.46,315.83c-.13-1.43-.18-3.04-.45-4.41-.14-.71-.34-1.4-.44-2.09.13.18.21.54.28.8.2-.41.06-1.01.05-1.52.5-.3.25,1.18.22,1.54-.04.6-.03,1.32.08,1.98.17.98.3,1.86.4,2.92.05.57.08,1.36.21,1.8"/>
        <path   d="m341.04,309.08c-.39,1.64-.52,2.28-.73,4.46.22-.56.53-1.94.71-2.99"/>
        <path   d="m342.71,311.92c-.02-.18-.03-.33-.04-.51"/>
        <path   d="m340.97,314.92c-.05-.26-.06-.56-.02-.75"/>
        <path   d="m343.78,316.68c.16-.85-.53-1.5-.65-2.35-.09-.65-.16-1.42-.18-2.06.63.41.32,1.48.4,2.26.09.74.45,1.46.53,2.31"/>
        <path   d="m345.32,310.84c.04.37.04.68-.08.93"/>
        <path   d="m342.78,310.54c-.3.49-.68-1.53.15-.42"/>
        <path   d="m244.58,292.04s.48-1.57,1.47-1.09c1,.47,1.54.24,2.25-.18.7-.43,2.81-.9,3.63-.4.81.49,1.35-.52,2.86.34,1.52.85,4.22.58,6.19,2.16,1.97,1.57,7.85,2.7,10.06,4.7,2.21,2,2.94.95,4.85,2.06,1.92,1.12,3.88.18,5.44,1.35,1.55,1.16,12.16,1.71,14.28,2.94,2.12,1.24,2.72-.1,4.39.43,1.66.53,4.19.45,5.19.92,1,.47,2.68.37,2.68.37,0,0,3.26-.81,4.68.3,1.42,1.11,1.55-.31,1.55-.31,0,0,2.46-.71,3.16-.34.7.35,1.2.41,1.6-.1.4-.51,3.67-.85,4.6-.85.95.02,3.82-.43,6.78.68,2.95,1.12,7.52,2.28,8.4,2.61.88.34,6.03,1.72,6.87,1.59.84-.12,7.67,1.17,9.49,1.35,1.81.2,5.05-1.24,5.98-.45.94.79,2.67.21,3.9.34,1.24.11,2.89-.3,4.02-.31,1.14-.03,2.93-.76,4.03-.31,1.08.46,2.27.12,2.75.2.48.1,7.07-.77,9.98-.9,2.92-.13,9.03-.84,10.05-1"/>
        <path   d="m394.58,291.72c-1.52,1.03-6.27,1.21-7.73,1.39-1.48.18-5.3.63-7.55.9-2.26.26-1.92.23-4.43.52-2.51.3-5.94-.14-8.27.14-2.35.28-4.33.63-6.94.82-2.63.19-1.08-.12-3.34.15-2.26.26-7.1-.25-9.73-.18-2.65.06-.77.81-5.16.37-4.39-.45-5.94-.74-6.42-1.05-.5-.31-1.11-.84-3.9-1.23-2.8-.39-5.42-2.62-10.74-2.12,0,0-2.63.2-3.93.34-1.3.15-1.71-.16-2.05-.12-.36.05-3.22.38-4.06.61-.85.22-1.6-.66-3.34-.45-1.74.2-3.16-.47-4.03-.38-.87.1-7.06-1.09-8.93-1.23-1.87-.14-2.98-1.09-4.31-1.06-1.32.03-2.58-.18-3.52-.55-.94-.38-1.25-.7-2.68-.77-1.42-.08-2.75-1.25-4.31-1.66-1.56-.42-2.34-.93-3.79-1.13-1.44-.19-5.17-2.65-6.79-2.94-1.64-.29-1.4-.44-4.27-.7-2.86-.27-2.82-1.71-4.66-1.62-1.83.1-.97-.01-2.98.11-2.01.12-1.8-.86-4.32-.57-2.51.29-4.49.77-4.49.77"/>
        <path   d="m309.47,304.71c-.14-1.61-.17-3.33-.38-4.91-.32,1.41-.31,3.34-.14,4.88"/>
        <path   d="m297.89,314.88c-.12-1.32-.15-2.85-.43-4.11-.17-.72-.7-1.48-.79-2.16-.08-.54-.07-1.02.22-1.16.55-.28.56,1.14.62,1.8.19,1.94.92,3.94.74,5.93"/>
        <path   d="m297.81,304.95c-.05.58-.19,1.06-.07,1.68.68-.1,1.2,1.95,1.37,2.93.09.55.12,1.19.26,1.72.13.51.43.62.47,1.3-.23-1.53-.43-3.24-.77-4.65-.33-1.4-1.23-1.48-.95-3.21"/>
        <path   d="m301.73,315.31c.66-3.95-2.03-4.48-2.08-8.13.14.02.3.13.41.28-.09.21-.07.59-.12.75.25.14.47.31.7.36"/>
        <path   d="m301.28,309.74c.2-1.6-.08-3.28-.34-4.92.53,1.78.98,3.3,1.25,5.2"/>
        <path   d="m304.19,315.39c-.2-2.29-.3-3.98-.91-6.07-.22-.7-.73-1.52-.81-2.31.07-.11.14-.18.21-.24.57.84.78,2.21,1,3.43.21-.1.42-.28.6-.47.47,1.32.43,3.48.6,4.99"/>
        <path   d="m305.3,313.97c0-1.83-.36-4.44-.89-6.09-.27-.85-.85-2.1-.16-2.69.14.78.25,1.6.37,2.39.33-.37.56-1.06.41-1.77.77,1.48.33,3.12.34,4.7.01,1.15.53,2.4.21,3.48"/>
        <path   d="m289.32,281.37c-.06.57-.2,1.06-.08,1.68.68-.1,1.2,1.96,1.36,2.92.1.55.13,1.2.26,1.71.14.51.43.63.47,1.29-.23-1.52-.43-3.23-.77-4.65-.33-1.38-1.23-1.48-.95-3.2"/>
        <path   d="m292.9,281.16c.66-3.95-2.03-4.47-2.09-8.13.13,0,.31.14.42.27-.09.23-.07.59-.12.75.24.15.47.31.7.37"/>
        <path   d="m292.71,288.77c.21-1.6-.08-3.29-.34-4.92.53,1.76.97,3.28,1.26,5.18"/>
        <path   d="m295.34,285.46c-.19-2.29-.29-3.99-.9-6.06-.22-.7-.73-1.52-.8-2.33.06-.11.13-.18.21-.22.55.84.78,2.21.99,3.42.2-.1.42-.27.59-.47.48,1.34.44,3.48.61,5"/>
        <path   d="m297.32,283.03c-.02-1.84-.37-4.46-.9-6.09-.27-.86-.85-2.11-.17-2.7.15.78.25,1.6.38,2.39.33-.37.56-1.05.41-1.76.77,1.48.32,3.13.35,4.68,0,1.15.53,2.42.2,3.49"/>
        <path   d="m296.76,282.79c-.05.57-.2,1.05-.08,1.67.68-.1,1.21,1.95,1.37,2.92.1.55.13,1.2.26,1.72.14.53.43.63.47,1.3-.22-1.52-.43-3.24-.76-4.65-.33-1.38-1.23-1.48-.95-3.19"/>
        <path   d="m300.63,283.02c.67-3.97-2.03-4.49-2.09-8.13.14.02.31.13.42.26-.08.23-.07.61-.12.76.26.14.47.31.71.36"/>
        <path   d="m299.55,289.53c.2-1.59-.08-3.28-.35-4.91.53,1.77.97,3.28,1.26,5.19"/>
        <path   d="m302.34,286.09c-.19-2.28-.29-3.97-.91-6.06-.22-.7-.74-1.51-.81-2.32.07-.1.13-.17.22-.22.56.83.78,2.2,1,3.41.2-.1.41-.27.58-.47.48,1.34.44,3.48.61,5.01"/>
        <path   d="m303.54,283.84c-.02-1.83-.36-4.45-.9-6.09-.27-.86-.85-2.11-.16-2.7.14.79.24,1.6.38,2.39.32-.36.56-1.05.4-1.77.78,1.49.33,3.15.34,4.7.01,1.16.54,2.42.21,3.5"/>
        <path   d="m300.87,292.28c-.05.58-.2,1.06-.08,1.68.68-.1,1.2,1.96,1.38,2.93.09.54.12,1.19.25,1.7.14.52.43.63.47,1.31-.23-1.53-.44-3.24-.77-4.64-.33-1.39-1.22-1.48-.95-3.21"/>
        <path   d="m304.97,299.77c.67-3.96-2.02-4.48-2.08-8.13.14.01.3.12.42.27-.08.23-.07.6-.12.76.25.13.47.3.71.36"/>
        <path   d="m303.69,304.46c.2-1.6-.08-3.29-.35-4.92.53,1.76.97,3.3,1.26,5.19"/>
        <path   d="m306.79,302.64c-.2-2.29-.3-3.98-.92-6.06-.21-.71-.73-1.52-.8-2.32.07-.11.14-.17.22-.22.56.83.78,2.2,1,3.41.21-.09.42-.27.59-.47.47,1.34.45,3.49.61,5"/>
        <path   d="m308,301.15c-.02-1.84-.37-4.45-.9-6.1-.27-.86-.86-2.1-.16-2.69.14.79.24,1.61.37,2.4.34-.37.56-1.05.41-1.78.77,1.48.32,3.15.33,4.69.01,1.15.54,2.41.21,3.5"/>
        <path   d="m295.41,312.66c.32-1.35-.75-4.08-1.03-5.41-.09-.48-.59-2.19-.43-2.68.27-.86.64.04.76.55.16.69.04,1.45.13,2.15.1.69.38,1.32.52,2.02.32,1.57.65,3.23.79,4.84-.14-.36-.33-.88-.35-1.42"/>
        <path   d="m340.2,287.64c-.11-1.22-.54-2.95-.19-3.98"/>
        <path   d="m340.93,289.37c.06-.37.04-.99-.04-1.49"/>
        <path   d="m341.3,279c.18,1.57.69,2.77.9,4.32.17,1.11.31,2.26.42,3.43"/>
        <path   d="m356.17,286.1c-.18-1.98.67-4.66-.33-6.45"/>
        <path   d="m358.6,286.37c.1-1.39.93-1.79.65-3.45-.14-.89-1.4-2.79-.67-3.54"/>
        <path   d="m361.21,285.37c-.59-1.66-1.41-3.92-1.5-5.9"/>
        <path   d="m360,290.99c-.1-1.13-.71-3.26-.57-4.22"/>
        <path   d="m361.9,294.73c0-1.72.16-3.34-.44-4.96-.58-1.54-.88-1.64-.97-3.6"/>
        <path   d="m361.41,280.34c2.48,2.38-.47,6.26.61,8.56"/>
        <path   d="m364.27,294.73c-.21-1.54-.07-3.1-.45-4.59-.34-1.32-.99-2.52-.62-4.2"/>
        <path   d="m364.01,280.07c.12,1.4.29,2.81.38,4.21.06.91-.15,2.22.14,2.51"/>
        <path   d="m365.65,287.88c-.55-1.34-.15-2.2-.41-3.58"/>
        <path   d="m366.65,279.84c.06.58.11,1.2.18,1.78.17.05.37.26.53.25"/>
        <path   d="m367.51,291.58c-.02.86.12,1.83-.3,2.37"/>
        <path   d="m369.42,293.72c-.14-1.51.02-3.18.79-3.81"/>
        <path   d="m368.67,289.98c-.99-.97.41-3.64-.12-5.14"/>
        <path   d="m367.05,285.29c.16-.57.05-1.15-.15-1.64"/>
        <path   d="m369.97,281.47c.72.53.39,2.03.46,3.08"/>
        <path   d="m369.82,288.26c-.15-.58-.15-1.1.06-1.34"/>
        <path   d="m370.78,287.41c1.04,1.38.53,4.7,1.11,6.45"/>
        <path   d="m371.8,287.35c.67-1.91-.4-5.05-.24-7.28"/>
        <path   d="m374.58,285.89c-.05,1.31-1.44,7.12-.29,7.38"/>
        <path   d="m373.08,286.3c.35-1.48-.19-5.08-.36-6.77"/>
        <path   d="m374.58,282.86c.21,2.29.85,5.72.35,7.77"/>
        <path   d="m376.24,285.26c-.15-1.66-.56-3.82-.13-5.3"/>
        <path   d="m377.5,289.06c-.09-.98-.6-4.16-.25-4.63"/>
        <path   d="m378.71,281.17c.2,2.16.62,4.07-.38,5.44-.18-2.04.2-4.1-.04-6.37"/>
        <path   d="m379.5,287.27c-.27.71-.49,2.02-.52,2.93"/>
        <path   d="m380.48,280.59c.17,1.91.29,3.53.04,5.32.8.32.51-1.28.43-2.26"/>
        <path   d="m380.81,292.93c.15-1.6-.89-5.16-.04-6.2.36,1.27-.2,6.36.93,6.36"/>
        <path   d="m382.97,285.09c-.2-1.57-.72-3.84.08-4.91.16,1.94.11,3.53.14,5.37"/>
        <path   d="m384.72,293.43c-.07-.86-.91-6.18-.41-6.23.89-.08.66,5.12.74,6"/>
        <path   d="m385.86,287.91c-.48-1.74-1.4-5.49-.26-6.48.05-.37.12-.46.21-.76.32,2.22-.9,3.54-.29,5.47"/>
        <path   d="m387.57,292.75c-.23-2.04-.72-3.95-.78-6,1.01-.39,1.08,4.12.97,5.13"/>
        <path   d="m389.35,292.03c-.14-1.55-.32-3.24-.34-4.72-.01-1.24-.3-1.89-.37-3.09.39,1.72.83,5.39.66,7.18"/>
        <path   d="m389.68,287.56c-.22-2.45-.53-4.78-.73-7.16,1.3-.67.62,3.78.78,4.84"/>
        <path   d="m391.68,292.4c-.1-2.02-.58-3.97-.72-6.01.04,0,.2-.03.24-.05.27.95.27,2.16.41,3.17.03-.7-.07-1.44-.13-2.15.69,1.05.73,2.89.83,4.41-.4-.39-.37-1.29-.77-1.84"/>
        <path   d="m392.14,282.42c.02,2.09,1.03,4.49.15,6.54-.11-.93.18-1.54.16-2.38-.04-.79-.3-1.25-.37-1.96-.12-1.25-.17-3.44-.07-4.65"/>
        <path   d="m394.77,286.12c-.14-1.49-.92-4.9-.68-5.97.78,1.64.83,2.81,1.02,4.9"/>
        <path   d="m357.86,287.32c-.06-.91.46-1.08.48-1.81.03-.75-.34-1.56-.49-2.23"/>
        <path   d="m366.21,294.54c-.18-.67-.3-1.34-.38-2.1-.05-.59,0-1.09-.21-1.52-.34-.78-.91-1-.95-2.18"/>
        <path   d="m367.45,286.84c-.01-.36-.15-.64-.3-.82-.16.22-.51.85-.56,1.24-.08.7.28,1.75.34,2.47"/>
        <path   d="m368.86,283.13c.18,0,.3-.18.37-.45-.04-.04-.12-.06-.16-.06-.02-.06-.03-.11,0-.16.11.14.06-.02.12.22-.2-.41-.17-.97-.21-1.51-.06-.67-.17-.94.22-1.18"/>
        <path   d="m376.22,294.42c-.26-.96-.29-1.72.05-2.54.32-.77.23-1.1-.14-1.95"/>
        <path   d="m387.26,286.19c.09-1.02.65-1.83.34-2.94-.29-1.04-.74-1.61-.69-2.89"/>
        <path   d="m345.24,296.68c.18,2.02.89,3.02,1.39,4.74.42,1.43.42,3.09.56,4.66"/>
        <path   d="m358.09,306.06c-1-3-1.38-6.36-1.76-9.72.22,1.04.3,1.92.48,2.96.15.97.26,2.32.49,3.28.17.78.51,1.21.65,2.01.13.69.14,1.51.21,2.21"/>
        <path   d="m357.98,299.48c-.17-1.4.09-2.31.07-3.47.08.9.16,1.78.24,2.65"/>
        <path   d="m359.66,295.3c-.42-.78-.77-1.55-.75-2.68.49.58.78,1.41.79,2.44"/>
        <path   d="m359.47,305.09c-.15-1.61-.18-3.33-.39-4.89-.32,1.4-.3,3.34-.14,4.87"/>
        <path   d="m360.24,302.2c.8-1.89.44-3.85.23-6.16"/>
        <path   d="m361.74,296.85c-.07,2.96.05,6.65,1.38,8.76-.11-2.09-.68-3.16-1.05-5.02-.2-.99-.42-2.65-.18-3.52"/>
        <path   d="m363.87,300.68c.15,1.82-.05,3.58.25,5.47.21,1.41.43,2.63.56,4.11"/>
        <path   d="m364.71,300.9c-.63-1.63-.7-3.33-.88-5.21"/>
        <path   d="m365.3,297.56c.04,2.33.9,4.63.64,7.01"/>
        <path   d="m366.38,302.13c.06.69.32,5.3.88,5.35.53.04-.54-3.97-.57-4.78"/>
        <path   d="m367.93,301c.23,2.48.52,4.98.55,7.42.01.98.35,2.35.54.87.11-.89-.31-2.62-.4-3.54"/>
        <path   d="m367.52,295.04c.13,1.15.5,2.17.64,3.38.12,1,.16,2.03.28,3.04.2,1.72.34,3.52.56,5.28.77.27.37-1.63.19-2.26-.34-1.23-.53-2.37-.69-3.74-.18-1.52-.04-4.71-.69-5.52"/>
        <path   d="m369.85,298.04c.22,2.41.81,4.5,1.09,6.87.17,1.4.07,2.76.26,4.11,1.06.83.19-1.85.09-2.41-.28-1.67-.61-3.62-.75-5.32"/>
        <path   d="m370.68,294.73c-.06,2.05.38,4.27.58,6.28.11,1.13.11,4.03.63,4.65.29-.76-.01-1.93-.2-2.74"/>
        <path   d="m372.87,305.32c-.12,1.1.27,2.63.24,3.82.96.04.15-3.1-.28-3.7"/>
        <path   d="m372.98,297.54c-.71-.11-.54,1.35-.46,2.21.1,1-.04,1.54-.03,2.38.7.14.49-1.09.41-1.93-.11-1.08.08-1.6.1-2.54"/>
        <path   d="m373.92,299.27c0-.91.04-1.91-.17-2.74-.19-.8-.49-1-.51-2.01.97.05,1.02.73,1.08,2.06.04.75.2,1.82.05,2.49"/>
        <path   d="m374.48,306.41c-.04-.67-.23-1.51-.55-1.92-.28-.37-.62-.02-.74-.74"/>
        <path   d="m374.59,303.92c-.11-1.14-.21-2.28-.32-3.44"/>
        <path   d="m377.63,292.02c.13.59.38,1.2.71,1.4.18-.77.66-1.32.59-2.27"/>
        <path   d="m383.21,290.97c-.02.33-.04.68-.03,1"/>
        <path   d="m382.91,287.93c.24.55.44,1.24.55,1.89"/>
        <path   d="m379.33,299.19c-.06,1,.3,1.53.34,2.4-.17.16-.16.38-.26.59"/>
        <path   d="m382.13,302.87c-.08-.51-.09-1.07-.09-1.6"/>
        <path   d="m377.89,294.67c.55,1.04.65,4.23.78,5.73"/>
        <path   d="m378.43,308.13c-.06-1.2-.36-2.29-.63-3.38-.41-1.69-.69-3.26-.99-5.02-.18-1.1-.97-3.34-.17-3.97.67,1.78.61,3.96,1.26,5.85.74,2.18.77,4.42,1.08,6.79"/>
        <path   d="m379.47,296.81c.2-.8-.08-1.9.07-2.76.22.54.11,1.4.2,2.09"/>
        <path   d="m380.85,294.45c.16.52.52,1.14.79,1.32.06-.69-.08-1.48-.14-2.2"/>
        <path   d="m382.86,293.74c.26.47.56.83.71,1.36"/>
        <path   d="m385.58,295.83c-.08-.86-.16-1.71-.24-2.58"/>
        <path   d="m385.92,312.28c-.27-.86-.42-2.36-.37-3.35.33.76.63,1.35.62,2.31,0,.61-.37.89-.07,1.5"/>
        <path   d="m384.88,299.5c-.31-.5-1.06-3.32-.46-3.52.23.91.53,1.73.59,2.75"/>
        <path   d="m385.63,308.47c.31-1.24-.15-3.83-.4-5.15-.21-1.05-.85-1.89-.93-2.98"/>
        <path   d="m387.3,299.04c-.15-.54-.38-1.05-.6-1.5"/>
        <path   d="m390.88,296.98c-.07-.77-.16-1.55-.2-2.31.25-.22.45-.6.67-.92-.24.57-.42,1.55-.36,2.34"/>
        <path   d="m389.2,305.96c-.02-1.46-.7-2.15-.53-3.5.12-.93.21-1.25.11-2.42"/>
        <path   d="m389.02,299.01c-.11-1.19-.19-2.22-.5-3.3-.21-.75-.42-1.08-.44-1.99.94,1.04.96,3.36,1.13,5.14"/>
        <path   d="m386.42,298.84c.49.98.65,1.89.47,3.05"/>
        <path   d="m382.75,298.02c-.18-.54-.34-1.48-.41-2.23.1.38.69,2.24,1.04,1.89"/>
        <path   d="m381.81,306.27c.18.54.5.8.53,1.53"/>
        <path   d="m381.89,304.02c.07.83-.41.62-.69.96-.08-.74-.03-1.84-.46-2.13"/>
        <path   d="m381.5,298.68c-.33.77,0,1.69.32,2.33-.31.73-.69-.2-.39-.84.22-.51,1.19-1.04.24-1.76"/>
        <path   d="m381.29,298.18c-.53.54-1.05-.33-1.05-1.46"/>
        <path   d="m345.17,317.08c-.12-1.31-.16-2.87-.44-4.11-.16-.72-.69-1.48-.8-2.16-.07-.55-.06-1.03.23-1.17.55-.27.56,1.15.61,1.81.19,1.94.92,3.94.74,5.95"/>
        <path   d="m347.43,311.72c.14,1.58-.13,3.38,0,5.07-.67-2.14-.53-3.21-.48-5.24"/>
        <path   d="m357.55,309.26c-.79-1.38-1.02-2.13-1.16-4.08.49,1.22,1.5,3.13,1.72,4.49"/>
        <path   d="m359.47,309.15c-.07-.65-.04-1.32-.32-1.8-.01.4,0,.82.06,1.21"/>
        <path   d="m357.99,309.95c.11,1.29-.06,2.2-.02,3.38.04,1.15.49,2.54.8,3.5-1.71-.56-.67-5.12-1.69-6.39.12.28.24.47.37.73"/>
        <path   d="m361.25,309.7c-.75-.8.14-2.35.2-3.3.09-1.2-.64-2.31-.41-3.17"/>
        <path   d="m359.25,310.91c.04.37.08.74.11,1.1"/>
        <path   d="m360.13,317.23c-.28-1.02-1.06-3.1-.86-4.36.22-1.27,1.08.2,1.1,1.3-1.38-.3.34,2.86.4,3.45"/>
        <path   d="m361.43,311.49c.38.49.95,1.61.8,2.6-.04.39-.34.49-.38,1-.02.35.08.91.16,1.2-.13-.96-.53-1.71-.64-2.69-.08-.74-.1-1.5-.16-2.24"/>
        <path   d="m363.25,312.52c-.23.97-.23,1.56.08,2.4.14.41.25.44.3.95.05.27-.09.81-.04.99.11.4.49.51.63.08.16-.44-.15-.83-.3-1.2-.33-.8-.45-1.53-.54-2.49"/>
        <path   d="m365.43,310.28c-.02-.69.22-1.21.22-1.89-.01-.58-.19-1.1-.2-1.69.37.1.35,1.6.41,2.17"/>
        <path   d="m365.34,314.73c-.12-.8-.25-1.4-.53-2.07-.18-.43-.49-.93-.12-1.31.4.53.67,1.26.84,2.09.14.69.38,1.34.42,2.11"/>
        <path   d="m367.24,317.47c-.08-.85-.16-1.49-.37-2.28-.18-.6-.33-.97-.11-1.64.42.92.49,2.11.6,3.3"/>
        <path   d="m368.29,314.25c-.27-.75-.65-1.11-.75-2.05-.06-.58.03-1.14.02-1.73.67,0,.45,1.5.55,2.14.1.76.3,1.39.38,2.28"/>
        <path   d="m369.08,313.2c.23,1.11.9,1.56,1.2,2.52.1.29.08.58.16.9.16.67.51,1.16.79.29.36-1.18-.29-2.78-.68-3.72-.03.71.48,3.2.22,3.63"/>
        <path   d="m371.38,314.58c-.12-1.41-.58-3.19-.47-4.59.72,1.12.74,2.81.88,4.35"/>
        <path   d="m376.69,305.18c-.22-.07-.38-.39-.46-.75"/>
        <path   d="m372.91,317.34c-.08-.94-.11-1.82-.07-2.67,0-.49.06-.96-.09-1.54"/>
        <path   d="m376.05,303.33c-.22-.24-.4-.65-.45-1.08"/>
        <path   d="m375.83,299.13c.1.17.17.44.19.71"/>
        <path   d="m377.09,306.82c.07.44.03.87.08,1.32"/>
        <path   d="m375.12,308.33c-.02.16-.02.32,0,.49"/>
        <path   d="m373.41,312.81c-.03-.79-.36-1.16-.43-1.85"/>
        <path   d="m373.73,315c.06.17.14.34.22.47"/>
        <path   d="m375.6,312.27c.07.36.03.76-.03,1.09"/>
        <path   d="m376.27,308.93c0,.43.2.71.39.93"/>
        <path   d="m379.6,304.03c.31.12.42.77.51,1.26.19,1.15.12,2.13.63,3.05.36.63.53.7.49-.26-.05-1.16-.7-1.88-.75-2.94"/>
        <path   d="m374.48,312.46c-.29-.45-.6-.97-.63-1.7.04-.05.11-.08.15-.1.25.31,1.01,1.66,1,2.21"/>
        <path   d="m375.3,316.72c-.07-.94-.39-1.66-.46-2.57.08.6.15,2.06.58,2.05"/>
        <path   d="m375.59,310.52c.03.5.25.77.38,1.16.16.07.21.05.36.25-.44-1.28-.52-1.4.25-1.6,0-.14-.03-.2-.07-.32"/>
        <path   d="m376.09,313.66c-.03.77.05,1.67.39,2.23.23.43.67.73.83.04-.31-.5-.93-.06-.89-1.09"/>
        <path   d="m377.9,313.92c0-.26,0-.48-.02-.74"/>
        <path   d="m378.26,315.76c.02.19.04.39.06.58"/>
        <path   d="m378.38,310.91c-.06.25-.14.54-.19.79"/>
        <path   d="m379.4,312.46c.03.37.05.85.14,1.13"/>
        <path   d="m379.88,317.15c-.08-1-.24-2.01-.49-2.96-.22-.81-.51-1.39-.6-2.26.3,1.18.61,1.94.73,3.22"/>
        <path   d="m381.19,316.58c-.2-.94-.18-1.17,0-2,.1-.5.14-.83.03-1.42-.25-1.39-.94-2.11-1.23-3.39.68,1.25,1.23,2.15,1.46,3.85.08.56.09,1.06.07,1.6-.01.58-.24,1.64-.13,2.07"/>
        <path   d="m383.55,316.34c-.48-1.32-.92-2.84-1.22-4.35-.2-.99.1-1.49.04-2.42.07.74.25,1.47.3,2.19,0-.24-.03-.67,0-.93.48,1.04.4,1.9.28,2.99-.12,1.12.25,1.43.56,2.37"/>
        <path   d="m385.02,317.19c-.09-.75-.24-1.5-.4-2.23.32-.84-.81-2.9-.82-4.04.61.31,1.27,2.27,1.39,3.39.12,1.11.16,2.2.22,3.31"/>
        <path   d="m383.76,306.7c.74-1.8-.96-4.01-.76-5.77,1.08.42,1.02,3.85,1.17,5.44"/>
        <path   d="m387.56,316.81c0-1.37-.22-2.57-.4-3.91-.07-.57-.38-1.66.08-1.36.57.36.22,1.28.18,1.92-.03.77.1,1.57.17,2.34"/>
        <path   d="m387.41,308.2c-.08-1.28-.66-2.68-.81-4.03-.08-.73-.13-1.47-.2-2.2"/>
        <path   d="m388.92,312.78c-.15-1.12-.65-2.84-.54-3.88"/>
        <path   d="m390.64,307.04c-.11-.44-.38-.8-.5-1.25"/>
        <path   d="m390.65,316.14c-.13-1.43-.18-3.04-.44-4.41-.14-.71-.34-1.4-.44-2.09.13.18.2.54.28.8.2-.41.06-1.01.05-1.52.51-.3.25,1.18.22,1.54-.04.6-.03,1.32.08,1.98.18.98.31,1.86.41,2.92.05.57.08,1.36.21,1.8"/>
        <path   d="m392.46,304.02c-.27-.59-.35-.81-.39-1.57.17.2.38.7.48,1.07"/>
        <path   d="m393.14,295.57c-.02-.18-.04-.34-.05-.51"/>
        <path   d="m390.67,301.38c-.05-.27-.06-.57-.02-.75"/>
        <path   d="m393.02,316.28c.15-.85-.54-1.5-.66-2.35-.09-.65-.16-1.42-.17-2.06.63.41.32,1.48.4,2.26.1.74.45,1.46.53,2.31"/>
        <path   d="m392.83,305.44c.04.37.04.68-.08.93"/>
        <path   d="m392.66,310.29c-.3.49-.68-1.53.14-.41"/>
        <path   d="m355.18,301.57c-.14-1.62-.18-3.34-.39-4.91-.31,1.41-.31,3.34-.14,4.88"/>
        <path   d="m349.02,308.93c-.12-1.32-.15-2.85-.43-4.11-.16-.72-.7-1.48-.79-2.16-.08-.54-.08-1.02.22-1.16.55-.28.56,1.14.62,1.8.19,1.94.92,3.94.75,5.93"/>
        <path   d="m350.45,309.46c-.05.58-.19,1.06-.08,1.68.68-.1,1.2,1.94,1.37,2.93.1.55.12,1.19.26,1.72.13.51.43.62.46,1.3-.23-1.53-.43-3.24-.77-4.65-.33-1.39-1.22-1.48-.95-3.2"/>
        <path   d="m353.46,310.2c.67-3.95-2.03-4.47-2.08-8.13.13.01.3.13.41.28-.08.21-.06.59-.12.75.25.14.47.31.7.36"/>
        <path   d="m352.89,316.08c.2-1.6-.08-3.28-.35-4.92.53,1.77.97,3.3,1.25,5.2"/>
        <path   d="m355.51,310.21c-.19-2.29-.3-3.98-.91-6.07-.22-.69-.72-1.52-.8-2.31.07-.11.13-.17.21-.23.56.84.78,2.22,1,3.43.21-.1.42-.28.6-.47.47,1.32.43,3.48.6,5"/>
        <path   d="m355.67,317.59c0-1.83-.36-4.44-.89-6.09-.27-.85-.85-2.1-.16-2.69.14.78.25,1.6.37,2.4.33-.37.56-1.06.41-1.77.77,1.48.33,3.12.34,4.69.02,1.15.54,2.4.21,3.48"/>
        <path   d="m343.22,278.92c-.06.57-.2,1.06-.08,1.68.68-.1,1.21,1.96,1.37,2.92.1.55.13,1.2.26,1.72.15.52.43.63.47,1.29-.23-1.52-.43-3.23-.76-4.65-.33-1.38-1.23-1.48-.95-3.2"/>
        <path   d="m347.9,287.49c.67-3.95-2.03-4.47-2.08-8.13.13.01.31.14.42.27-.09.23-.07.6-.12.75.25.15.47.3.7.37"/>
        <path   d="m347.19,295.1c.21-1.6-.08-3.29-.34-4.92.53,1.76.97,3.28,1.25,5.18"/>
        <path   d="m348.61,295.19c-.19-2.29-.29-3.98-.91-6.06-.21-.7-.73-1.52-.8-2.33.06-.11.12-.17.21-.21.56.84.78,2.21,1,3.42.21-.1.42-.28.6-.47.47,1.34.43,3.48.61,5"/>
        <path   d="m349.82,287.49c-.02-1.84-.36-4.46-.9-6.09-.27-.85-.85-2.11-.17-2.7.14.78.24,1.6.37,2.39.33-.37.57-1.04.41-1.76.77,1.48.32,3.13.34,4.68,0,1.15.53,2.42.21,3.49"/>
        <path   d="m349.39,287.14c-.06.57-.2,1.05-.08,1.67.68-.1,1.2,1.95,1.37,2.92.1.55.12,1.2.26,1.71.14.53.43.63.47,1.3-.22-1.52-.43-3.24-.76-4.65-.33-1.38-1.23-1.48-.95-3.19"/>
        <path   d="m353.27,287.94c.67-3.97-2.03-4.49-2.08-8.13.14.02.31.13.42.26-.08.23-.06.61-.12.77.25.14.47.3.71.36"/>
        <path   d="m353.28,294.49c.2-1.59-.08-3.28-.34-4.91.53,1.76.97,3.28,1.26,5.19"/>
        <path   d="m355.36,291.6c-.19-2.28-.3-3.98-.91-6.06-.21-.71-.73-1.51-.81-2.32.07-.1.14-.18.22-.22.56.83.78,2.21,1,3.41.2-.1.41-.27.59-.47.48,1.34.44,3.49.61,5"/>
        <path   d="m357.75,295.29c-.02-1.83-.37-4.45-.9-6.09-.28-.86-.85-2.11-.17-2.7.13.79.24,1.6.38,2.39.32-.36.56-1.05.41-1.77.78,1.49.33,3.15.34,4.7.01,1.16.53,2.42.21,3.5"/>
        <path   d="m342.01,287.64c-.06.57-.2,1.06-.08,1.68.68-.1,1.2,1.96,1.38,2.94.09.54.11,1.18.25,1.7.14.52.43.63.47,1.31-.23-1.54-.44-3.24-.77-4.65-.32-1.39-1.22-1.48-.95-3.21"/>
        <path   d="m346.22,294.81c.66-3.96-2.02-4.48-2.08-8.13.14.01.3.12.42.27-.08.23-.07.59-.12.76.25.14.47.3.71.37"/>
        <path   d="m347.82,301.08c.2-1.6-.08-3.29-.34-4.93.52,1.76.97,3.3,1.26,5.19"/>
        <path   d="m350.71,304.97c-.2-2.29-.3-3.98-.92-6.06-.21-.71-.73-1.52-.8-2.32.07-.11.13-.17.21-.22.56.83.77,2.2,1,3.41.21-.09.42-.27.59-.47.48,1.34.45,3.49.61,5"/>
        <path   d="m352.79,304.22c-.02-1.84-.37-4.45-.9-6.1-.27-.86-.85-2.1-.16-2.69.14.79.24,1.61.37,2.4.33-.37.56-1.05.41-1.78.77,1.48.32,3.15.34,4.69.02,1.15.54,2.41.21,3.5"/>
        <path   d="m348.96,315.59c.32-1.34-.75-4.08-1.03-5.4-.08-.49-.58-2.2-.42-2.69.27-.85.64.04.76.55.16.69.04,1.45.12,2.14.11.7.38,1.33.52,2.02.32,1.57.66,3.22.79,4.84-.15-.35-.33-.88-.35-1.41"/>
        <path   d="m442.21,310.65s-2.61,1.07-2.44,2.01"/>
        <path   d="m543.16,271.35s2.39,5.13.97,5.09"/>
        <path   d="m541.88,273.21s1.81,7.66-.81,6.29"/>
        <path   d="m539.77,269.23c.25,2.04.29,2.81.15,4.52-.13,1.72-.01,2.12.82,3.46"/>
        <path   d="m538.61,274.4c.54,1.76.43,2.93-.08,3.81.31.52.49,1.21.41,1.81"/>
        <path   d="m535.47,270.57c.17,1.32.3,2.65.5,3.94.04-.06.09-.1.14-.09-.44,1.27.43,2.79.47,3.8"/>
        <path   d="m535.85,280.85c-.38-1.36-.57-2.59-.75-4.01"/>
        <path   d="m531.95,281.81c-.46-2.28-.09-3.06.23-4.94.26-1.36-.5-4.13.13-5.01"/>
        <path   d="m533.65,278.12c-.32-2.58-.44-4.99-.83-7.45"/>
        <path   d="m530.72,276.32c-.15-.62-.49-1.09-.54-1.78-.03-.45.23-.81.21-1.29-.04-.89-.08-1.42-.62-1.77"/>
        <path   d="m528.92,275.21c-.05,1.37.37,2.36.25,3.89-.08,1.12-.34,2.16.08,3.01"/>
        <path   d="m527.39,276.38c.73-1.13.69-2.85.85-4.51"/>
        <path   d="m526.86,275.21c-.12,1.28-.54,3.53-.34,4.73.13.77,1.58,2.11.43,2.78"/>
        <path   d="m525.21,278.37c-.71-1.28-.18-4.71-.02-6.51"/>
        <path   d="m524.79,283.69c-.11-1.34.13-3.36.5-4.57"/>
        <path   d="m523.54,275.76c-.14,1.54.6,2.55.51,3.92"/>
        <path   d="m523.52,281.75c-.89-.39-1.59-2.48-1.46-4.27.06-.67,1.06-3.68-.15-3.23-.63.25-.61,3.04-.7,4.08-.16,1.74-.46,2.85-.75,4.21"/>
        <path   d="m515.5,287.2c.09-1.44-.03-4.71.83-5.46.49,1.16-.11,4.23-.25,5.73"/>
        <path   d="m517.32,281.29c-.02-1.32.16-7.87.98-8.14,1.46-.5-.22,6.16-.26,6.94"/>
        <path   d="m518.15,284.64c-.09-1.84-.04-4.89.85-6.33.88.97.34,6.21-.18,7.61"/>
        <path   d="m515.8,278.61c.15-1.62-.1-3.07.42-4.59"/>
        <path   d="m514.46,280.49c-.14,1.27-.64,2.39-.59,3.71.01.73.65,2.21.08,2.69"/>
        <path   d="m512.5,281.29c.13-2.19,2.4-5.3.92-7.16"/>
        <path   d="m511.88,280.21c-.18,1.92-.14,2.63.05,4.3.12.92.29,3.11-.4,3.3"/>
        <path   d="m510.37,279.98c.14-1.41.69-3.94-.19-4.55"/>
        <path   d="m510.19,282.81c-.33,2.18.27,4.2.12,6.21"/>
        <path   d="m508.52,281.22c.18-1.95.78-4.93-.14-6.21"/>
        <path   d="m508.46,288.78c.1-1.22.54-2.95.19-3.98"/>
        <path   d="m507.88,283.56c-.05-.37-.04-.99.04-1.49"/>
        <path   d="m506.82,274.35c-.18,1.57-.7,2.76-.9,4.32-.17,1.11-.31,2.26-.42,3.43"/>
        <path   d="m493.94,291.93c.18-1.97-.67-4.66.33-6.45"/>
        <path   d="m491,289.58c-.1-1.39-.93-1.79-.65-3.45.14-.89,1.4-2.79.67-3.54"/>
        <path   d="m489.54,282.62c.59-1.66,1.4-3.91,1.5-5.89"/>
        <path   d="m488.9,291.76c.1-1.13.71-3.26.57-4.22"/>
        <path   d="m487.66,288.35c0-1.72-.16-3.34.43-4.96.58-1.54.89-1.64.97-3.6"/>
        <path   d="m486.94,284.13c-2.48,2.38.47,6.27-.61,8.56"/>
        <path   d="m484.28,289.56c.2-1.54.06-3.1.44-4.6.34-1.32,1-2.52.63-4.2"/>
        <path   d="m483.05,284.88c-.12,1.4-.3,2.81-.38,4.22-.06.91.15,2.22-.14,2.51"/>
        <path   d="m484,280.4c.55-1.35.14-2.21.41-3.58"/>
        <path   d="m483.15,281.87c-.05.59-.1,1.21-.17,1.78-.18.06-.37.26-.54.25"/>
        <path   d="m481.01,289.27c.03.86-.12,1.83.3,2.38"/>
        <path   d="m480.26,292.36c.14-1.51-.02-3.19-.79-3.81"/>
        <path   d="m479.29,287.38c.99-.97-.42-3.64.12-5.15"/>
        <path   d="m483.07,279.12c-.15-.57-.04-1.15.15-1.64"/>
        <path   d="m479.28,289.3c-.72.53-.38,2.03-.45,3.08"/>
        <path   d="m478.31,288.91c.14-.57.15-1.09-.06-1.34"/>
        <path   d="m479.61,277.99c-1.04,1.39-.53,4.7-1.12,6.46"/>
        <path   d="m477.56,291.73c-.67-1.91.39-5.05.24-7.28"/>
        <path   d="m475.69,278.06c.05,1.31,1.44,7.11.29,7.38"/>
        <path   d="m474.89,292.03c-.35-1.48.19-5.08.36-6.77"/>
        <path   d="m474.74,280.19c-.21,2.29-.85,5.72-.36,7.77"/>
        <path   d="m473,292.2c.14-1.66.55-3.83.13-5.3"/>
        <path   d="m472.1,286.92c.09-.98.6-4.16.25-4.63"/>
        <path   d="m470.68,284.38c-.2,2.15-.62,4.07.39,5.44.18-2.03-.2-4.09.04-6.37"/>
        <path   d="m471.16,278.83c.27.71.49,2.01.52,2.93"/>
        <path   d="m470.03,279.75c-.18,1.92-.29,3.53-.03,5.33-.8.32-.51-1.28-.42-2.26"/>
        <path   d="m468.31,283.78c-.14-1.6.9-5.16.04-6.2-.37,1.27.2,6.36-.94,6.36"/>
        <path   d="m467.99,292.75c.2-1.56.72-3.84-.08-4.91-.16,1.94-.11,3.52-.14,5.36"/>
        <path   d="m466.4,288.6c.07-.86.92-6.18.41-6.23-.9-.08-.66,5.12-.74,6"/>
        <path   d="m465.15,285.49c.48-1.74,1.39-5.49.26-6.48-.05-.38-.13-.46-.21-.76-.31,2.22.91,3.54.3,5.47"/>
        <path   d="m464.23,294.35c.23-2.04.72-3.95.78-6-1.01-.39-1.07,4.12-.97,5.13"/>
        <path   d="m463.26,288.53c.13-1.55.33-3.23.34-4.72.01-1.24.3-1.88.37-3.09-.38,1.71-.83,5.39-.65,7.17"/>
        <path   d="m460.45,286.51c.22-2.45.53-4.78.73-7.17-1.3-.67-.61,3.79-.78,4.84"/>
        <path   d="m458.71,291.48c.1-2.01.58-3.97.71-6-.04,0-.2-.04-.24-.05-.27.94-.27,2.15-.41,3.16-.03-.69.08-1.44.13-2.15-.68,1.05-.73,2.89-.82,4.41.4-.39.37-1.29.77-1.84"/>
        <path   d="m457.69,282.61c-.02,2.09-1.03,4.49-.16,6.54.11-.93-.17-1.53-.15-2.38.04-.79.3-1.26.36-1.96.13-1.25.17-3.44.08-4.65"/>
        <path   d="m455.91,295.83c.14-1.49.92-4.9.68-5.97-.78,1.64-.83,2.82-1.02,4.9"/>
        <path   d="m545.49,284c-1.06-.04-1.52-.21-1.81-2.6"/>
        <path   d="m541.78,280.79c.67,1.95,1.6,3.96,1.73,6.21.12,2.19-.67,3.4.03,5.8-.43-1.52-.66-2.97-.9-4.92"/>
        <path   d="m541.4,289.6c-.17-.63-.5-1.15-.82-1.47-.18.91.15,2.33.26,3.4-.12.04-.22.03-.33.03"/>
        <path   d="m541.2,287.18c-.16-1.31.21-2.27.12-3.52-.11-1.75-.42-1.73-1.16-2.84"/>
        <path   d="m538.79,281.2c.18,1.63.52,4.21.28,5.6-.89-.92-1.79-3.85-2.3-5.49"/>
        <path   d="m538.53,292.2c-.1-.78-.79-4.39-.29-4.67.52-.3,1.14,2.57,1.19,3.44"/>
        <path   d="m535.14,293.49c-.6-2.96-1.06-5.8-1.44-8.87-.11-.99-.46-2.48-.11-3.1.88,1.31,1.39,4.87,1.95,6.77.41,1.33.83,2.72,1.22,4.03"/>
        <path   d="m534.28,300.45c-.28-1.24-.48-2.73-.55-3.99.65.14,1.27,1.47,1.37,2.77"/>
        <path   d="m532.83,296.76c-.44-3.54-.83-6.99-1.1-10.48-.11-1.4-.52-5.06-1.51-4.4-1.39.9.91,4.28,1.09,5.97.13,1.23-.22,2.21-.18,3.45.05,1.83.46,3.93.68,5.78"/>
        <path   d="m525.03,296.96c.15-2.93,1.15-6.15,1.54-9.06.24-1.74,1.04-2.51,1.25-4.07-.05,1.45-.54,2.78-.75,4.17-.14,1.02-.22,2.08-.36,3.06-.33,2.3-.55,4.03-.49,6.29"/>
        <path   d="m523.09,298.09c.24-2.12.05-5.81,1.03-7.46.13,1.2.02,2.49.08,3.69.06.99.22,1.86.28,2.86"/>
        <path   d="m521.26,297.09c.12-1.3-.68-1.88-.58-3.14.06-.93.82-2.48,1.16-3.23.81-1.74,1.26-2.29,1.48-4.64-.45,4.08-.76,8.6-1.01,12.67-.13.02-.29.02-.42-.05"/>
        <path   d="m518.42,300.24c.02-.93-.25-1.76-.28-2.49.07.16.29.36.38.47-.1.06-.21-.04-.29,0,.12.08.36.09.51.1-.23-.9.12-2.09-.23-3.04-.43-1.18-1.45-.64-1.71-1.99-.38-2,1.09-5.23,1.93-6.11.1,1.31-.29,2.63-.23,3.92.05,1.21.49,1.5.73,2.29.52,1.73.05,4.26-.14,6.33"/>
        <path   d="m516.57,301.4c.08-.89-.21-1.66-.21-2.55,0-.66.13-1.47.2-2.12.45.97.61,3.19.72,4.46"/>
        <path   d="m514.16,300.95c.26-2.68.83-5.25,1.15-7.87.16-1.34-.02-3.52.66-4.37.22,2.01-.35,5.04-.54,7.14-.2,2.17-.15,3.22.22,5.2"/>
        <path   d="m511.79,301.82c.22-2.42.48-4.91.77-7.28.47,1.91-.76,6.28.65,7.03"/>
        <path   d="m509.53,299.13c-.39-2.2.33-3.74,1-5.28.53-1.17.59-2.45.71-3.98.03,1.61.1,2.8-.05,4.43-.15,1.52-.32,3.05.12,4.33-.01.18-.07.22-.18.12"/>
        <path   d="m506.73,301.96c.2-2.67.9-5.62,1.79-7.85.38,1.48-.51,3.73-.62,5.44-.06,1.09,0,2.13-.12,3.26"/>
        <path   d="m505.57,300.12c.21-2.26.24-5.02,1.09-6.76.55-1.14,1.14-1.58,1.23-3.3-.35,1.88-.43,3.75-.69,5.7-.22,1.62-.54,3-.69,4.7"/>
        <path   d="m492.15,291.9c.06-.91-.46-1.08-.48-1.81-.03-.75.34-1.57.49-2.23"/>
        <path   d="m486.7,282.89c.18-.68.29-1.34.37-2.1.05-.59.01-1.09.21-1.52.34-.78.91-1,.95-2.18"/>
        <path   d="m480.88,285.45c0-.37.14-.64.3-.82.15.22.51.85.55,1.24.08.71-.28,1.75-.34,2.48"/>
        <path   d="m481.34,281.36c-.18,0-.3-.18-.37-.45.04-.04.12-.06.16-.06.02-.05.03-.11,0-.16-.11.14-.06-.02-.12.22.2-.41.17-.97.21-1.51.06-.67.17-.93-.22-1.17"/>
        <path   d="m473.39,282.43c.26-.96.3-1.72-.04-2.54-.33-.77-.23-1.11.13-1.94"/>
        <path   d="m462.52,284.66c-.09-1.02-.64-1.83-.34-2.94.29-1.04.74-1.61.69-2.89"/>
        <path   d="m458.54,281.18c.14-.2.37-.55.45-.86.12-.51-.04-.47-.04-.98-.07.06-.11.03-.18.13.04-.1.06-.19.12-.27.09.42-.14.46-.17.77-.03.24.06.49.04.77"/>
        <path   d="m505.57,292.97c.05,2.63-.78,4.73-1.2,7.27-.16.97-.13,1.63,0,2.59.12.89,0,1.54-.46.62-.29-.61-.23-1.69-.31-2.51-.12-1.01-.12-2.03.21-3.02.21-.58.51-1.04.67-1.66.17-.66.19-1.49.26-2.21"/>
        <path   d="m500.8,301.59c.67-2.21,1.25-4.13,1.48-6.58.12-1.32.1-2.67.59-3.62h.15c.27,1.9-.22,3.84-.8,5.5-.59,1.72-1.11,3.76-1.34,5.71"/>
        <path   d="m500.12,293.31c-.26,2.63-.55,5.15-1.09,7.62-.18.82-.28,1.6-.23,2.46.02.48.13.93.08,1.43-.92-.16-.29-4.79-.16-5.85.15-1.21.49-2.32.87-3.32.2-.48.46-.89.5-1.5"/>
        <path   d="m499.03,291.6c-.23,2.44-.44,4.31-1.36,6.24-.24.52-.51,1.05-.6,1.72-.09.7.05,1.35-.07,2.07-1.24-.45-.17-3.45.29-4.7.3-.81.58-1.11.68-2.02.09-.71.14-1.42.2-2.14"/>
        <path   d="m496.64,291.99c-.11,1.23-.21,2.45-.29,3.63-.36-.9-.02-2.73.08-3.85"/>
        <path   d="m496.64,295.75c-.18,2.02-.89,3.02-1.39,4.74-.43,1.44-.43,3.09-.57,4.65"/>
        <path   d="m484.68,303.25c1-3,1.38-6.35,1.75-9.71-.21,1.04-.3,1.92-.48,2.96-.16.98-.27,2.32-.49,3.29-.17.77-.51,1.21-.65,2.01-.12.68-.14,1.51-.2,2.21"/>
        <path   d="m484.17,302.79c.17-1.4-.08-2.31-.07-3.46-.08.89-.16,1.77-.24,2.65"/>
        <path   d="m484.25,296.6c.42-.78.78-1.55.76-2.69-.49.58-.77,1.41-.79,2.44"/>
        <path   d="m482.79,300.54c.14-1.61.17-3.33.39-4.89.32,1.4.3,3.34.14,4.87"/>
        <path   d="m482.35,298.84c-.8-1.9-.44-3.85-.24-6.17"/>
        <path   d="m481.69,297.01c.07,2.96-.04,6.65-1.38,8.76.12-2.1.69-3.16,1.05-5.02.2-.99.42-2.65.18-3.52"/>
        <path   d="m480.29,293.3c-.15,1.83.04,3.58-.25,5.47-.21,1.42-.43,2.63-.56,4.12"/>
        <path   d="m477.37,305.95c.63-1.63.7-3.33.88-5.21"/>
        <path   d="m477.92,293.23c-.04,2.33-.9,4.63-.64,7.01"/>
        <path   d="m476.05,299.78c-.07.69-.32,5.3-.88,5.35-.53.04.54-3.98.56-4.78"/>
        <path   d="m475.57,292.79c-.23,2.48-.51,4.98-.55,7.42-.02.98-.35,2.35-.54.87-.12-.89.31-2.62.39-3.55"/>
        <path   d="m473.25,293.2c-.13,1.15-.5,2.17-.64,3.38-.12,1-.16,2.03-.28,3.05-.21,1.73-.35,3.52-.56,5.28-.77.27-.37-1.63-.19-2.26.33-1.23.53-2.37.69-3.74.18-1.52.04-4.71.69-5.52"/>
        <path   d="m470.65,293.8c-.23,2.41-.81,4.5-1.09,6.87-.17,1.4-.07,2.76-.26,4.11-1.05.83-.19-1.85-.09-2.41.28-1.67.61-3.62.75-5.33"/>
        <path   d="m468.07,294.31c.07,2.05-.38,4.27-.57,6.28-.1,1.13-.11,4.03-.62,4.65-.29-.76,0-1.93.19-2.74"/>
        <path   d="m465.46,302.09c.13,1.1-.26,2.64-.23,3.82-.96.04-.15-3.1.28-3.7"/>
        <path   d="m465.29,296.06c.71-.12.54,1.35.46,2.2-.09,1.01.04,1.54.03,2.37-.7.14-.49-1.09-.4-1.93.1-1.08-.08-1.6-.1-2.53"/>
        <path   d="m462.79,306.64c.01-.91-.04-1.91.17-2.74.18-.8.49-1,.51-2.01-.97.06-1.02.73-1.07,2.05-.04.75-.21,1.83-.06,2.5"/>
        <path   d="m462.97,299.68c.04-.67.23-1.51.55-1.91.29-.37.62-.02.74-.74"/>
        <path   d="m461.59,299.51c.11-1.14.2-2.28.32-3.44"/>
        <path   d="m472.07,290.9c-.13.59-.38,1.2-.71,1.41-.18-.77-.66-1.32-.58-2.28"/>
        <path   d="m469.43,290.34c.03.33.04.68.03,1.01"/>
        <path   d="m469.71,286.56c-.24.55-.45,1.24-.56,1.89"/>
        <path   d="m461.46,303.02c.06,1-.3,1.53-.34,2.41.16.16.16.37.26.58"/>
        <path   d="m462.17,301.04c.08-.51.09-1.07.08-1.6"/>
        <path   d="m457.8,296.65c-.55,1.04-.65,4.23-.79,5.72"/>
        <path   d="m457.95,307.92c.07-1.2.37-2.29.64-3.39.4-1.68.69-3.26.99-5.02.17-1.1.96-3.34.16-3.97-.67,1.78-.62,3.96-1.26,5.85-.74,2.19-.78,4.42-1.08,6.8"/>
        <path   d="m466.2,294.45c-.2-.8.07-1.9-.08-2.76-.22.54-.1,1.4-.19,2.09"/>
        <path   d="m464.9,286.7c-.16.52-.51,1.14-.79,1.32-.06-.69.08-1.49.15-2.2"/>
        <path   d="m463.23,292.84c-.25.47-.56.84-.71,1.36"/>
        <path   d="m461.97,291.19c.08-.86.16-1.72.24-2.58"/>
        <path   d="m456.49,307.51c.27-.86.42-2.36.36-3.35-.33.76-.62,1.35-.62,2.3,0,.61.37.89.07,1.51"/>
        <path   d="m455.29,303.95c.31-.5,1.05-3.33.46-3.52-.23.91-.54,1.73-.59,2.75"/>
        <path   d="m453.97,305c-.3-1.24.15-3.83.4-5.16.21-1.05.85-1.9.94-2.98"/>
        <path   d="m454.29,307.84c.15-.54.39-1.05.6-1.51"/>
        <path   d="m452.89,300.94c.07-.77.16-1.55.2-2.31-.25-.22-.46-.6-.68-.92.24.56.42,1.56.36,2.34"/>
        <path   d="m452.2,308.41c.02-1.45.69-2.14.53-3.5-.12-.93-.21-1.25-.1-2.42"/>
        <path   d="m450.73,304.84c.11-1.19.2-2.22.5-3.31.21-.75.42-1.08.44-1.99-.95,1.04-.96,3.36-1.13,5.14"/>
        <path   d="m454.57,314.26c-.5.98-.65,1.9-.47,3.04"/>
        <path   d="m456.43,281.41c.18-.54.35-1.48.41-2.23-.1.38-.69,2.24-1.04,1.89"/>
        <path   d="m458.14,293.44c-.18.54-.5.8-.53,1.53"/>
        <path   d="m458.85,294.22c-.08.83.41.62.69.96.08-.74.03-1.84.46-2.12"/>
        <path   d="m460.97,288.36c.32.77,0,1.69-.32,2.32.3.73.69-.19.38-.84-.22-.51-1.19-1.04-.24-1.77"/>
        <path   d="m460.77,293.96c.53.54,1.06-.33,1.05-1.45"/>
        <path   d="m546.71,281.81c.24,1.12-.14,2.53-.57,2.83"/>
        <path   d="m550.06,294.5c-.3-.93-.82-1.24-1.17-1.96"/>
        <path   d="m551.67,308.28c-.1-.59.07-1.58-.12-2.09-.12-.31-.49-.43-.69-.58-.16-.8.23-1.89.53-2.15"/>
        <path   d="m550.11,296.62c.45,1,.24,1.67.01,2.35-.22.64-.39,1.65-.37,2.61"/>
        <path   d="m548.8,297.52c.03.32.07.61.12.94-.71-.29-.92-1.33-1.38-2.25"/>
        <path   d="m547.69,294.43c-.13-.87.01-1.9.52-1.57.15.66-.3,1.47-.41,2.01-.12-.09-.15-.25-.19-.44"/>
        <path   d="m547.6,299.04c.32,2.3-.3,4.01-.09,6.1.11,1.13.47,1.67.67,2.77.12.63.33,2.09-.21,1.7-.04-.49-.36-2.2-.56-2.69-.03,0-.09,0-.12,0-.01.61.3,1.38.27,1.9-.06.93-.61-.3-.74-.97-.25-1.4.24-2.83.46-3.83.15-.63.02-1.27.12-1.9.11-.74.29-.78.07-1.63-.3-1.25-.76-2.28-.21-3.25.12.31.18.71.2,1.09"/>
        <path   d="m545.28,291.8c.2,1.47-.1,2.56,0,3.89.12,1.6.32,3.17.07,4.51-.1.54-.19.92-.17,1.54.02.58.28,1.24.3,1.74.1,1.76-.65.26-.94-.36-.08.33-.12.73-.08,1.13-.62-.99.42-4.31.58-5.14.15-.82.26-1.73.27-2.69,0-.92-.14-.93-.34-1.71-.47-.39-.79-1.51-.61-2.21.34-.12.41.75.69.59.17-.1.2-.96.19-1.3"/>
        <path   d="m543.67,310.41c-.51-1.31-.51-2.5-.44-3.76.07-1.34.59-2.34.61-3.57.01-1.39-1.42-4.05-.4-4.9.51.72.4,2.41.47,3.33.08,1.03.43,2.37.41,3.37-.01,1.13-.49,1.54-.57,2.69-.07.94.05,1.79.26,2.85"/>
        <path   d="m542.63,297.21c-.11-.9-.64-3.43.04-3.37.36,1.23.23,2.95.69,3.95"/>
        <path   d="m541.07,302.93c-.13-1.06-.31-2.2-.34-3.23-.04-1.09.2-1.91.19-2.97,0-1.32-.43-1.54-.55-2.76-.08-.88.12-1.45.63-1.17.29,1.19-.18,1.76-.1,2.82.07.94.44,1.7.45,2.66-.01,1.04-.21,1.59-.11,2.77.06.86.2,1.74.3,2.6"/>
        <path   d="m540.4,309.37c-.42-2.52-1.4-4.8-.85-6.96.33-1.31.57-1.9.24-3.75-.15.48-.28,1.08-.52,1.4-.22-.43-.37-1.03-.43-1.55-.93-.43.16,1.95.27,2.43.25,1.07-.02,2.54-.03,3.66,0,1.76-.13,5.86,1.19,6.54"/>
        <path   d="m537.12,308.51c-.67-.22-.83-1.97-.97-3-.21-1.47-.19-2.36-.1-3.62.06-.95.2-2.11.12-3.1-.06-.82-.36-2,.07-2.33.11.42.15.85.2,1.27.27.02.61-.27.59-.89-.03-.67-.41-.84-.55-1.36-.25-.91.13-1.94.54-1.95.13.67.12,1.4.17,2.07.03.44.09.71.04,1.12-.04.42-.44,1.01-.38,1.4.07.78.7,1.42.73,2.44.03,1.07-.25.57-.6.81-1.27.82-.11,4.65,0,6.61"/>
        <path   d="m533.67,299.57c-.05,1.18.44,1.1.52,2,.08,1.08-.61,1.88-.9,2.57-.6,1.4-.34,3.21-.48,4.87-.06.54-.31,1.8.07,2.03.16.11.46-.39.5-.76.04-.4-.14-.73-.15-1.15-.04-1.21.42-2.67.8-3.57.29-.71.82-1.43.92-2.32.07-.72-.14-1.59-.09-2.35.02-.51.33-1.93-.19-1.66,0,.05.04.04.05.03-.09.43-.1.77-.28,1.06-.17-.44-.53-1.06-.82-.58"/>
        <path   d="m530.33,305.09c.07-.73.05-1.91.21-2.58.14-.65.48-1.17.67-1.7.35-.99.75-2.2.82-3.4.56,1.99.04,3.26-.67,4.62-.61,1.15-.74,2.19-.89,3.71"/>
        <path   d="m528.13,311.38c.21-2.35.41-3.62,1.2-5.46.44-1.01,1-2.45.54-3.52-.64.66-.74,1.2-.88,2.39-.12,1.07-.26,1.5-.6,2.39-.31.77-.53,1.59-.57,2.61-.04.77.5,2.9.3,3.09"/>
        <path   d="m527.36,306.15c-.91-1.07-.61-3.7.02-5.1.56-1.22.71-3.22,1.46-4.04.04,1.17-.61,1.92-.89,2.87-.22.72-.27,1.3-.35,2.15-.09,1.02-.28,1.48-.47,2.31-.17.71-.23,1.32.12,1.66"/>
        <path   d="m524.67,312.12c.18-2.02.27-2.69,1.03-4.02.4-.73,1.13-2.32.49-3.15-.05,1.23-.14,1.31-.61,1.59-.26.17-.36-.17-.61.14-.18.22-.38,1.07-.45,1.43-.34,1.52.06,3.51-.38,4.8"/>
        <path   d="m524.2,308.14c.1-1.22-.15-2.45.1-3.83.2-1.24.65-1.71,1.05-2.82.15-.44.84-1.98.81-2.5-.05-.96-.76-.52-.99.06-.29.76-.15,1.88-.32,2.71-.16.76-.49,1.3-.66,2.14-.14.73-.29,1.79-.29,2.57,0,.72.44,1.88.04,2.38"/>
        <path   d="m522.9,310.39s.33-.83.4-1.42"/>
        <path   d="m522.04,310.62c-.15-.54-.11-1.25-.02-1.88"/>
        <path   d="m522.83,311.52c-.08.55-.05,1.07-.08,1.62"/>
        <path   d="m521.81,307.46c.55-1.42.87-2.22,1.09-4.05.09-.74.16-1.44.37-2.08.17-.54.45-.73.51-1.38-.26-.16-1.01-.06-1.25.25-.68.85-.51,1.98-.46,2.9.06,1.31-.18,2.61-.3,3.97"/>
        <path   d="m519.88,305.09c.09-.61.72-1.05,1.05-.67.57.66-.05,1.75-.37,2.44-.42.85-.68,1.31-.85,2.43-.22,1.29-.14,2.55-.84,3.45-.13-.91.13-2.54.32-3.5.12-.65.48-1.14.63-1.78.18-.76.14-1.54.19-2.35"/>
        <path   d="m518.01,302.27c.09.56.26,1.01.17,1.64-.68.04-1.09,2.17-1.21,3.17-.05.55-.05,1.19-.15,1.73-.1.54-.4.71-.39,1.38.14-1.54.25-3.28.51-4.74.25-1.43,1.15-1.71.77-3.35"/>
        <path   d="m520.91,301.49c.15.73-.14,1.2-.53.88,0-.2.02-.51.07-.71"/>
        <path   d="m517.06,311.92c-.01.24-.08.47-.14.71-.33,0-.41-.62-.22-1.04"/>
        <path   d="m518.64,305.26c.33-.44.69-1.26.43-1.92"/>
        <path   d="m514.36,314.42c-.89-3.78,1.78-4.85,1.62-8.45-.14.05-.3.18-.4.35.09.2.1.58.16.72-.24.19-.46.4-.68.5"/>
        <path   d="m514.57,307.93c-.29-1.53-.1-3.25.06-4.92-.43,1.84-.78,3.44-.96,5.36"/>
        <path   d="m511.8,314.87c.06-2.28.06-3.97.57-6.16.16-.73.64-1.64.67-2.45-.08-.1-.14-.15-.23-.18-.51.94-.65,2.33-.81,3.57-.21-.05-.44-.19-.62-.34-.4,1.41-.23,3.52-.33,5.04"/>
        <path   d="m510.46,312.04c-.09-1.82.11-4.47.56-6.2.22-.9.73-2.24,0-2.68-.09.8-.15,1.64-.22,2.44-.37-.3-.64-.92-.53-1.66-.69,1.62-.14,3.16-.07,4.68.05,1.15-.4,2.5,0,3.49"/>
        <path   d="m508.72,315.1c.48-2.32.31-4.38.2-6.59"/>
        <path   d="m508.41,303.28c-.22,1.16-.55,2.16-.76,3.23-.18.87-.16,1.26-.57,2.01-.15.25-.42.48-.51.7-.38.88-.37,1.88-.48,3-.14,1.43-.29,1.3-1.04,1.18"/>
        <path   d="m504.67,314.1c-.33-1.34.74-4.08,1.03-5.4.09-.49.59-2.19.42-2.68-.27-.86-.63.04-.76.55-.16.69-.03,1.45-.13,2.14-.1.7-.37,1.32-.51,2.03-.32,1.57-.66,3.22-.79,4.84.15-.36.34-.89.35-1.42"/>
        <path   d="m503.02,311.95c.12-1.31.16-2.87.44-4.11.16-.72.69-1.48.8-2.16.08-.55.06-1.03-.23-1.17-.55-.27-.56,1.15-.62,1.81-.19,1.94-.92,3.94-.74,5.94"/>
        <path   d="m501.7,310.69c-.14,1.57.13,3.38,0,5.07.67-2.14.53-3.21.48-5.24"/>
        <path   d="m488.04,311.52c.79-1.38,1.02-2.14,1.16-4.08-.49,1.22-1.5,3.13-1.72,4.49"/>
        <path   d="m484.05,306.05c.06-.65.04-1.32.32-1.8.02.4,0,.82-.06,1.21"/>
        <path   d="m488.28,310.03c-.11,1.29.06,2.2.02,3.38-.04,1.15-.49,2.54-.79,3.5,1.71-.56.67-5.13,1.69-6.39-.12.28-.24.47-.37.73"/>
        <path   d="m486.39,314.27c.75-.8-.14-2.35-.2-3.3-.1-1.2.64-2.31.41-3.17"/>
        <path   d="m484.97,306.88c-.04.38-.07.74-.11,1.11"/>
        <path   d="m484.74,316.3c.29-1.03,1.05-3.11.86-4.36-.22-1.28-1.08.2-1.1,1.3,1.38-.3-.34,2.86-.39,3.45"/>
        <path   d="m483.73,307.48c-.38.49-.95,1.61-.8,2.6.04.38.34.48.38,1,.03.35-.08.91-.16,1.2.14-.96.53-1.71.64-2.69.09-.74.1-1.5.17-2.23"/>
        <path   d="m482.53,311.57c.22.97.22,1.56-.09,2.4-.14.41-.25.44-.3.95-.05.27.09.81.04.99-.1.4-.48.51-.63.08-.16-.44.15-.83.3-1.2.33-.8.44-1.53.53-2.49"/>
        <path   d="m481.38,311.3c.02-.69-.23-1.21-.23-1.89.01-.58.19-1.1.2-1.69-.37.11-.35,1.6-.41,2.17"/>
        <path   d="m480.18,315.72c.12-.8.25-1.39.53-2.07.18-.43.5-.92.12-1.31-.4.53-.67,1.26-.85,2.09-.14.69-.38,1.35-.42,2.11"/>
        <path   d="m479.24,310.65c.08-.86.16-1.49.37-2.28.18-.6.33-.97.11-1.64-.42.92-.49,2.1-.61,3.3"/>
        <path   d="m478.52,316.47c.27-.74.65-1.11.76-2.05.06-.58-.03-1.14-.02-1.73-.67,0-.45,1.5-.55,2.13-.1.77-.31,1.39-.38,2.28"/>
        <path   d="m478.5,310.61c-.23,1.1-.91,1.56-1.21,2.52-.1.29-.07.57-.16.9-.16.67-.51,1.15-.79.29-.36-1.18.29-2.78.67-3.72.04.72-.47,3.2-.21,3.64"/>
        <path   d="m476.2,311.23c.13-1.42.58-3.2.47-4.6-.71,1.12-.74,2.81-.88,4.35"/>
        <path   d="m474.37,313.41c.08-.95.11-1.83.07-2.67,0-.49-.06-.96.1-1.54"/>
        <path   d="m471.2,306.87c.22-.24.4-.65.45-1.08"/>
        <path   d="m455.99,308.72c-.1.17-.17.45-.19.71"/>
        <path   d="m473.41,305.84c-.06.44-.03.87-.08,1.32"/>
        <path   d="m475.01,306.51c0,.16.01.32-.01.49"/>
        <path   d="m474.81,316.7c.03-.79.36-1.16.43-1.85"/>
        <path   d="m470.17,307.35c-.08-.2-.18-.39-.28-.53"/>
        <path   d="m473.6,314.24c-.06.36-.03.76.03,1.09"/>
        <path   d="m453.14,309.27c0,.44-.2.71-.39.93"/>
        <path   d="m474.23,307.8c-.3.12-.42.77-.51,1.26-.2,1.16-.12,2.13-.63,3.04-.36.64-.53.71-.5-.25.05-1.16.7-1.89.76-2.94"/>
        <path   d="m472.02,316.48c.29-.45.6-.97.63-1.7-.04-.05-.11-.08-.15-.11-.25.31-1.01,1.66-1,2.22"/>
        <path   d="m471.9,309.89c.07-.94.39-1.66.46-2.57-.08.61-.14,2.06-.58,2.05"/>
        <path   d="m472.14,313.13c-.03.5-.25.77-.38,1.16-.15.06-.21.05-.36.24.44-1.28.52-1.4-.25-1.6,0-.14.03-.2.07-.32"/>
        <path   d="m470.64,308.09c.03.77-.06,1.67-.39,2.23-.23.42-.67.73-.83.04.31-.49.93-.06.9-1.09"/>
        <path   d="m470.64,311.34c0-.26,0-.49.03-.74"/>
        <path   d="m470.19,313.95c-.02.19-.04.39-.06.58"/>
        <path   d="m470.33,315.57c.07.25.14.53.19.79"/>
        <path   d="m469.23,308.06c-.03.38-.05.85-.14,1.12"/>
        <path   d="m468.61,316.67c.07-1,.24-2.01.49-2.96.22-.81.51-1.38.59-2.26-.3,1.18-.61,1.94-.73,3.21"/>
        <path   d="m466.97,316.79c.2-.94.18-1.17,0-2-.1-.5-.14-.83-.03-1.42.25-1.39.94-2.11,1.24-3.38-.69,1.25-1.23,2.15-1.46,3.84-.08.57-.09,1.07-.08,1.61.02.59.24,1.64.13,2.07"/>
        <path   d="m466,312.66c.49-1.32.93-2.84,1.22-4.35.2-.98-.1-1.5-.03-2.42-.07.75-.25,1.48-.3,2.19,0-.24.03-.66,0-.93-.47,1.04-.39,1.9-.27,2.99.12,1.12-.25,1.43-.55,2.37"/>
        <path   d="m463.83,316.65c.09-.75.24-1.49.4-2.22-.32-.84.81-2.9.82-4.03-.61.31-1.27,2.27-1.4,3.38-.12,1.12-.16,2.2-.21,3.32"/>
        <path   d="m463.28,313.09c-.74-1.8.97-4.01.77-5.77-1.09.42-1.03,3.86-1.17,5.44"/>
        <path   d="m461.65,317.19c0-1.36.22-2.57.39-3.91.08-.57.37-1.66-.08-1.36-.57.36-.22,1.28-.19,1.93.03.76-.1,1.57-.16,2.34"/>
        <path   d="m460.26,314.42c.08-1.28.67-2.68.82-4.03.08-.73.13-1.47.2-2.19"/>
        <path   d="m458.47,317.27c.15-1.12.65-2.84.54-3.88"/>
        <path   d="m458.5,311.39c.1-.44.37-.8.5-1.24"/>
        <path   d="m456.37,316.86c.13-1.43.18-3.04.45-4.41.14-.71.34-1.4.45-2.09-.14.18-.21.54-.29.8-.2-.41-.06-1.01-.04-1.52-.51-.3-.25,1.18-.22,1.54.04.6.02,1.32-.08,1.99-.18.98-.31,1.86-.41,2.92-.05.57-.08,1.36-.21,1.79"/>
        <path   d="m454.8,310.11c.39,1.64.52,2.28.73,4.45-.22-.56-.53-1.94-.71-2.98"/>
        <path   d="m453.12,312.94c.02-.18.04-.33.05-.51"/>
        <path   d="m454.87,315.94c.06-.26.06-.57.02-.75"/>
        <path   d="m452.06,317.7c-.16-.85.53-1.5.65-2.35.09-.65.16-1.42.17-2.06-.63.41-.31,1.48-.4,2.26-.09.74-.45,1.46-.53,2.31"/>
        <path   d="m450.52,311.87c-.04.37-.04.68.09.92"/>
        <path   d="m453.06,311.56c.31.49.68-1.53-.14-.42"/>
        <path   d="m550.06,292.97s.72-1.46-.27-.99c-.99.47-1.54.24-2.24-.18-.69-.43-2.81-.9-3.63-.4-.81.49-1.36-.52-2.87.34-1.52.84-4.21.58-6.19,2.15-1.97,1.58-7.85,2.7-10.06,4.7-2.21,2-2.94.95-4.85,2.06-1.92,1.12-3.88.19-5.43,1.35-1.55,1.15-12.17,1.71-14.28,2.94-2.12,1.25-2.73-.1-4.39.43-1.67.53-4.2.45-5.2.92-1.01.46-2.68.36-2.68.36,0,0-3.26-.81-4.68.3-1.42,1.11-1.55-.31-1.55-.31,0,0-2.46-.71-3.16-.34-.69.36-1.2.41-1.6-.1-.4-.51-3.67-.86-4.61-.86-.95.02-3.82-.43-6.77.68-2.96,1.11-7.51,2.28-8.41,2.61-.88.34-6.03,1.72-6.87,1.59-.83-.12-7.67,1.17-9.49,1.35-1.8.2-5.05-1.23-5.97-.44-.94.79-2.67.21-3.9.34-1.25.11-2.89-.29-4.02-.31-1.13-.03-2.93-.76-4.03-.31-1.08.46-2.27.12-2.75.2-.49.1-7.07-.77-9.98-.9-2.91-.12-13.48-1.87-14.5-2.03"/>
        <path   d="m394.58,291.72c1.51,1.03,12.95,2.24,14.41,2.41,1.48.18,5.3.63,7.55.9,2.26.26,1.92.22,4.43.52,2.51.3,5.93-.14,8.27.14,2.35.29,4.33.63,6.94.83,2.63.19,1.09-.12,3.34.15,2.25.26,7.1-.25,9.73-.18,2.64.06.77.81,5.16.37,4.4-.44,5.94-.74,6.42-1.05.5-.3,1.11-.84,3.9-1.23,2.8-.39,5.42-2.63,10.73-2.11,0,0,2.63.2,3.93.34,1.3.15,1.72-.16,2.05-.12.35.05,3.22.38,4.06.61.85.22,1.6-.66,3.33-.45,1.75.2,3.16-.47,4.03-.37.87.1,7.06-1.09,8.93-1.23,1.87-.14,2.98-1.09,4.32-1.06,1.31.03,2.58-.18,3.52-.55.94-.37,1.25-.7,2.68-.77,1.42-.08,2.75-1.25,4.31-1.66,1.56-.42,2.35-.93,3.78-1.13,1.44-.19,5.17-2.65,6.8-2.94,1.64-.29,1.4-.43,4.26-.7,2.86-.27,2.82-1.71,4.67-1.62,1.84.1.97,0,2.99.11,2.01.12,1.8-.86,4.32-.57,2.51.29,4.49.77,4.49.77"/>
        <path   d="m486.36,305.74c.14-1.61.18-3.33.39-4.91.32,1.41.31,3.34.14,4.88"/>
        <path   d="m497.95,315.9c.11-1.32.15-2.85.43-4.11.16-.72.7-1.48.79-2.16.08-.54.07-1.02-.21-1.16-.56-.28-.56,1.14-.63,1.81-.19,1.94-.92,3.94-.74,5.93"/>
        <path   d="m498.02,305.98c.04.58.19,1.06.07,1.68-.68-.1-1.2,1.95-1.37,2.93-.09.55-.12,1.19-.26,1.72-.13.51-.43.62-.46,1.3.23-1.53.43-3.24.77-4.65.33-1.39,1.22-1.48.95-3.2"/>
        <path   d="m494.11,316.34c-.67-3.95,2.03-4.48,2.08-8.13-.13.01-.3.13-.41.28.09.21.07.59.12.75-.25.15-.47.31-.7.37"/>
        <path   d="m494.55,310.76c-.2-1.6.08-3.28.35-4.92-.53,1.78-.97,3.3-1.25,5.2"/>
        <path   d="m491.65,316.42c.2-2.29.3-3.98.91-6.07.22-.7.73-1.52.81-2.31-.07-.11-.14-.18-.21-.24-.56.84-.78,2.22-1,3.43-.21-.1-.41-.28-.6-.47-.47,1.33-.43,3.48-.61,5"/>
        <path   d="m490.54,315c0-1.83.36-4.44.89-6.1.27-.85.85-2.09.16-2.69-.14.78-.25,1.6-.37,2.4-.33-.37-.56-1.06-.41-1.77-.78,1.48-.33,3.12-.34,4.7-.01,1.15-.53,2.4-.21,3.48"/>
        <path   d="m506.51,282.4c.06.57.2,1.06.07,1.68-.68-.1-1.2,1.95-1.36,2.92-.1.55-.13,1.2-.26,1.71-.14.51-.43.63-.46,1.29.22-1.52.43-3.23.76-4.65.33-1.38,1.23-1.48.95-3.2"/>
        <path   d="m502.94,282.19c-.66-3.95,2.03-4.47,2.09-8.13-.13.01-.3.14-.42.27.1.23.07.59.12.75-.25.15-.47.3-.71.37"/>
        <path   d="m503.13,289.8c-.21-1.6.07-3.29.34-4.92-.53,1.76-.97,3.28-1.26,5.18"/>
        <path   d="m500.5,286.49c.19-2.29.29-3.99.91-6.06.22-.7.73-1.52.8-2.33-.06-.11-.13-.18-.21-.22-.56.83-.78,2.21-.99,3.42-.21-.1-.42-.28-.59-.47-.48,1.34-.44,3.48-.61,5"/>
        <path   d="m498.52,284.06c.02-1.84.36-4.46.9-6.09.27-.86.85-2.11.17-2.7-.14.78-.24,1.6-.37,2.39-.33-.37-.57-1.04-.41-1.76-.77,1.48-.33,3.13-.35,4.69,0,1.15-.52,2.42-.2,3.49"/>
        <path   d="m499.08,283.82c.06.57.2,1.05.08,1.67-.68-.1-1.2,1.96-1.37,2.93-.1.55-.12,1.19-.26,1.71-.14.53-.43.63-.46,1.3.22-1.52.43-3.23.76-4.65.33-1.38,1.23-1.48.95-3.19"/>
        <path   d="m495.2,284.05c-.67-3.97,2.03-4.49,2.08-8.13-.13.02-.31.13-.42.26.08.22.07.6.12.76-.25.15-.47.31-.71.36"/>
        <path   d="m496.29,290.56c-.2-1.59.08-3.28.35-4.91-.53,1.77-.97,3.28-1.26,5.19"/>
        <path   d="m493.5,287.11c.19-2.28.29-3.98.91-6.06.21-.7.74-1.51.81-2.32-.07-.1-.13-.17-.22-.22-.55.83-.78,2.2-1,3.41-.2-.1-.42-.27-.59-.47-.48,1.34-.44,3.49-.61,5.01"/>
        <path   d="m492.29,284.87c.02-1.83.37-4.45.9-6.09.28-.85.85-2.11.16-2.7-.13.79-.24,1.6-.37,2.39-.33-.36-.57-1.05-.41-1.77-.78,1.49-.33,3.15-.34,4.7-.01,1.16-.54,2.42-.21,3.5"/>
        <path   d="m494.97,293.31c.05.57.2,1.06.08,1.67-.68-.1-1.21,1.96-1.38,2.94-.09.54-.12,1.18-.25,1.7-.14.52-.43.63-.47,1.31.22-1.53.44-3.24.77-4.64.33-1.39,1.22-1.48.95-3.21"/>
        <path   d="m490.87,300.8c-.66-3.96,2.03-4.48,2.09-8.13-.14.02-.3.12-.42.27.08.22.06.59.12.76-.25.13-.48.3-.71.36"/>
        <path   d="m492.14,305.49c-.2-1.6.08-3.3.35-4.93-.53,1.76-.97,3.3-1.26,5.19"/>
        <path   d="m489.05,303.67c.2-2.29.3-3.98.92-6.06.21-.71.73-1.52.8-2.32-.07-.11-.14-.17-.22-.22-.55.83-.77,2.2-1,3.41-.2-.09-.42-.27-.59-.46-.48,1.34-.45,3.48-.62,5"/>
        <path   d="m487.84,302.18c.02-1.84.37-4.46.9-6.1.27-.85.86-2.1.16-2.69-.14.79-.24,1.61-.37,2.4-.34-.37-.56-1.05-.41-1.78-.77,1.48-.32,3.15-.33,4.69-.01,1.15-.54,2.41-.21,3.5"/>
        <path   d="m500.42,313.68c-.32-1.35.75-4.08,1.03-5.4.09-.49.59-2.2.43-2.69-.28-.86-.63.04-.76.55-.15.69-.04,1.45-.12,2.15-.11.69-.38,1.32-.52,2.02-.31,1.57-.65,3.23-.79,4.84.15-.36.33-.89.35-1.42"/>
        <path   d="m455.64,288.66c.1-1.22.53-2.95.19-3.98"/>
        <path   d="m454.91,290.4c-.05-.37-.04-.99.05-1.49"/>
        <path   d="m454.53,280.03c-.18,1.57-.69,2.77-.9,4.32-.17,1.11-.31,2.26-.42,3.43"/>
        <path   d="m439.67,287.13c.18-1.98-.67-4.66.33-6.45"/>
        <path   d="m437.24,287.4c-.1-1.39-.93-1.79-.65-3.45.14-.89,1.4-2.79.67-3.54"/>
        <path   d="m434.63,286.4c.6-1.66,1.41-3.92,1.5-5.9"/>
        <path   d="m435.84,292.02c.1-1.13.7-3.26.56-4.23"/>
        <path   d="m433.94,295.76c0-1.72-.16-3.34.43-4.96.58-1.54.89-1.64.97-3.6"/>
        <path   d="m434.43,281.37c-2.48,2.38.47,6.27-.61,8.56"/>
        <path   d="m431.57,295.76c.2-1.54.07-3.1.45-4.6.34-1.32.99-2.52.62-4.2"/>
        <path   d="m431.83,281.09c-.12,1.4-.29,2.81-.38,4.21-.06.91.15,2.22-.14,2.51"/>
        <path   d="m430.19,288.91c.55-1.35.15-2.21.42-3.58"/>
        <path   d="m429.18,280.87c-.05.58-.11,1.2-.17,1.77-.18.05-.37.26-.54.25"/>
        <path   d="m428.33,292.61c.02.85-.12,1.83.3,2.38"/>
        <path   d="m426.42,294.75c.13-1.51-.02-3.19-.79-3.81"/>
        <path   d="m427.17,291.01c.99-.97-.41-3.64.12-5.14"/>
        <path   d="m428.79,286.31c-.16-.57-.05-1.15.15-1.64"/>
        <path   d="m425.86,282.49c-.72.53-.38,2.03-.45,3.08"/>
        <path   d="m426.02,289.29c.14-.58.14-1.09-.05-1.34"/>
        <path   d="m425.06,288.44c-1.04,1.38-.53,4.7-1.12,6.45"/>
        <path   d="m424.03,288.37c-.67-1.91.4-5.05.24-7.28"/>
        <path   d="m421.25,286.92c.05,1.31,1.44,7.11.29,7.38"/>
        <path   d="m422.76,287.33c-.35-1.48.19-5.08.36-6.77"/>
        <path   d="m421.25,283.89c-.21,2.3-.85,5.72-.35,7.77"/>
        <path   d="m419.59,286.29c.15-1.66.56-3.83.14-5.3"/>
        <path   d="m418.34,290.09c.09-.98.59-4.16.25-4.63"/>
        <path   d="m417.12,282.2c-.2,2.16-.62,4.07.38,5.44.18-2.03-.2-4.1.04-6.37"/>
        <path   d="m416.34,288.3c.27.71.49,2.01.53,2.93"/>
        <path   d="m415.36,281.62c-.17,1.92-.29,3.53-.04,5.32-.8.32-.5-1.27-.42-2.26"/>
        <path   d="m415.03,293.95c-.15-1.6.89-5.16.04-6.2-.36,1.27.19,6.36-.94,6.36"/>
        <path   d="m412.87,286.12c.19-1.57.72-3.84-.09-4.91-.16,1.94-.11,3.52-.14,5.37"/>
        <path   d="m411.12,294.46c.07-.86.91-6.18.4-6.23-.9-.08-.67,5.12-.74,6"/>
        <path   d="m409.98,288.94c.48-1.74,1.39-5.49.26-6.48-.05-.37-.12-.45-.21-.76-.32,2.22.91,3.54.29,5.47"/>
        <path   d="m408.27,293.78c.22-2.04.72-3.95.78-6-1.01-.39-1.08,4.12-.97,5.13"/>
        <path   d="m406.48,293.05c.14-1.55.33-3.24.34-4.72.01-1.24.3-1.88.38-3.09-.39,1.71-.83,5.39-.66,7.17"/>
        <path   d="m406.16,288.58c.22-2.45.52-4.78.72-7.16-1.3-.67-.61,3.78-.78,4.83"/>
        <path   d="m404.16,293.43c.1-2.02.58-3.97.72-6.01-.04,0-.2-.03-.24-.05-.27.94-.27,2.16-.41,3.16-.03-.7.08-1.44.13-2.15-.68,1.05-.73,2.89-.83,4.41.4-.39.37-1.29.77-1.84"/>
        <path   d="m403.69,283.45c-.02,2.09-1.03,4.49-.15,6.54.11-.93-.18-1.53-.16-2.39.03-.79.3-1.25.36-1.95.13-1.25.17-3.44.08-4.65"/>
        <path   d="m401.07,287.14c.14-1.49.92-4.9.67-5.97-.78,1.64-.83,2.81-1.02,4.9"/>
        <path   d="m437.98,288.35c.06-.91-.46-1.08-.49-1.81-.03-.75.34-1.57.49-2.23"/>
        <path   d="m429.63,295.57c.18-.68.29-1.34.37-2.1.05-.59.02-1.09.21-1.52.34-.78.91-1.01.96-2.18"/>
        <path   d="m428.39,287.86c.01-.36.14-.64.3-.82.16.22.51.85.56,1.24.08.71-.28,1.75-.34,2.48"/>
        <path   d="m426.98,284.16c-.17,0-.3-.18-.37-.45.04-.04.12-.06.15-.06.02-.05.03-.11,0-.16-.11.14-.06-.02-.12.22.2-.41.17-.97.22-1.51.06-.67.17-.94-.22-1.18"/>
        <path   d="m419.62,295.45c.26-.95.3-1.72-.05-2.54-.32-.77-.22-1.1.14-1.95"/>
        <path   d="m408.58,287.22c-.09-1.02-.65-1.83-.34-2.94.29-1.04.74-1.61.69-2.89"/>
        <path   d="m450.6,297.71c-.19,2.02-.89,3.02-1.39,4.74-.42,1.43-.43,3.09-.57,4.66"/>
        <path   d="m437.75,307.09c1-3.01,1.38-6.36,1.75-9.72-.21,1.04-.3,1.92-.48,2.96-.16.98-.26,2.32-.49,3.28-.17.78-.51,1.21-.65,2.01-.12.69-.14,1.51-.2,2.21"/>
        <path   d="m437.86,300.51c.17-1.4-.09-2.31-.07-3.47-.08.89-.16,1.78-.24,2.65"/>
        <path   d="m436.17,296.33c.43-.78.77-1.55.76-2.69-.49.58-.78,1.42-.79,2.45"/>
        <path   d="m436.37,306.12c.14-1.61.17-3.33.39-4.89.31,1.4.3,3.34.13,4.87"/>
        <path   d="m435.59,303.23c-.8-1.9-.44-3.85-.23-6.17"/>
        <path   d="m434.09,297.88c.07,2.96-.04,6.65-1.38,8.76.12-2.1.69-3.17,1.05-5.02.2-.99.42-2.65.18-3.52"/>
        <path   d="m431.97,301.71c-.15,1.83.04,3.58-.25,5.47-.21,1.42-.43,2.63-.56,4.12"/>
        <path   d="m431.13,301.92c.63-1.62.7-3.33.88-5.21"/>
        <path   d="m430.54,298.59c-.04,2.32-.9,4.62-.64,7.01"/>
        <path   d="m429.45,303.16c-.06.69-.32,5.3-.87,5.35-.53.04.54-3.98.56-4.78"/>
        <path   d="m427.9,302.03c-.23,2.48-.52,4.98-.55,7.42-.01.98-.35,2.35-.54.87-.12-.89.31-2.62.39-3.54"/>
        <path   d="m428.31,296.06c-.13,1.15-.5,2.17-.64,3.38-.12,1.01-.16,2.03-.28,3.04-.2,1.73-.34,3.52-.56,5.28-.77.27-.37-1.63-.19-2.26.33-1.23.53-2.37.69-3.74.18-1.52.04-4.71.7-5.52"/>
        <path   d="m425.99,299.07c-.23,2.4-.82,4.49-1.09,6.86-.17,1.4-.07,2.76-.26,4.11-1.06.83-.2-1.85-.09-2.41.28-1.67.6-3.62.75-5.32"/>
        <path   d="m425.15,295.76c.07,2.05-.38,4.27-.57,6.28-.11,1.13-.12,4.03-.63,4.65-.29-.76.01-1.93.2-2.74"/>
        <path   d="m422.96,306.35c.13,1.09-.27,2.63-.23,3.82-.96.03-.15-3.11.28-3.7"/>
        <path   d="m422.85,298.57c.71-.11.54,1.35.46,2.2-.1,1,.04,1.54.03,2.38-.7.14-.49-1.09-.4-1.93.11-1.08-.07-1.6-.1-2.54"/>
        <path   d="m421.91,300.3c0-.91-.04-1.91.17-2.74.19-.8.5-1,.51-2.01-.97.05-1.02.73-1.08,2.05-.04.75-.21,1.83-.05,2.5"/>
        <path   d="m421.36,307.44c.04-.67.23-1.51.55-1.92.28-.37.62-.02.74-.74"/>
        <path   d="m421.25,304.95c.1-1.14.2-2.29.32-3.44"/>
        <path   d="m418.2,293.04c-.13.59-.38,1.2-.71,1.41-.18-.77-.65-1.32-.59-2.27"/>
        <path   d="m412.62,292c.03.33.04.68.03,1.01"/>
        <path   d="m412.93,288.96c-.23.55-.45,1.24-.56,1.89"/>
        <path   d="m416.5,300.21c.06,1-.29,1.53-.33,2.41.17.16.16.38.26.59"/>
        <path   d="m413.71,303.9c.08-.51.09-1.07.08-1.61"/>
        <path   d="m417.95,295.7c-.56,1.04-.65,4.23-.78,5.73"/>
        <path   d="m417.41,309.16c.06-1.2.37-2.29.64-3.38.4-1.69.68-3.26.99-5.02.18-1.1.97-3.34.17-3.97-.67,1.78-.62,3.95-1.26,5.85-.74,2.18-.77,4.42-1.08,6.79"/>
        <path   d="m416.37,297.84c-.2-.8.08-1.9-.07-2.76-.22.54-.1,1.4-.19,2.09"/>
        <path   d="m414.99,295.48c-.16.52-.51,1.14-.78,1.32-.07-.69.08-1.49.14-2.2"/>
        <path   d="m412.97,294.77c-.25.47-.56.83-.71,1.36"/>
        <path   d="m410.26,296.86c.08-.85.16-1.72.24-2.58"/>
        <path   d="m409.91,313.31c.26-.86.42-2.36.36-3.35-.33.76-.63,1.36-.62,2.31,0,.61.37.89.07,1.51"/>
        <path   d="m410.95,300.53c.31-.5,1.06-3.32.46-3.52-.22.91-.53,1.73-.59,2.74"/>
        <path   d="m410.21,309.49c-.31-1.24.15-3.83.4-5.15.2-1.04.85-1.89.93-2.98"/>
        <path   d="m408.54,300.07c.15-.54.39-1.05.6-1.5"/>
        <path   d="m404.96,298.01c.08-.78.16-1.55.2-2.31-.25-.22-.46-.6-.68-.92.24.56.42,1.55.36,2.34"/>
        <path   d="m406.64,306.99c.02-1.45.69-2.14.52-3.5-.12-.93-.21-1.25-.1-2.42"/>
        <path   d="m406.81,300.04c.11-1.19.19-2.22.5-3.3.21-.75.42-1.08.44-1.99-.95,1.04-.97,3.36-1.13,5.14"/>
        <path   d="m409.41,299.87c-.49.98-.65,1.9-.47,3.04"/>
        <path   d="m413.08,299.04c.18-.54.34-1.48.41-2.23-.1.38-.69,2.24-1.03,1.89"/>
        <path   d="m414.02,307.29c-.18.55-.5.8-.54,1.53"/>
        <path   d="m413.95,305.04c-.08.83.41.62.69.96.08-.75.02-1.84.45-2.13"/>
        <path   d="m414.34,299.71c.32.77,0,1.69-.32,2.32.31.73.69-.19.39-.84-.22-.51-1.19-1.05-.24-1.76"/>
        <path   d="m414.54,299.21c.53.54,1.05-.33,1.05-1.46"/>
        <path   d="m450.66,318.1c.12-1.31.16-2.87.44-4.11.16-.72.69-1.48.79-2.16.08-.55.06-1.03-.22-1.17-.55-.28-.56,1.15-.62,1.81-.19,1.93-.92,3.94-.74,5.95"/>
        <path   d="m448.41,312.75c-.15,1.58.13,3.38,0,5.07.67-2.14.53-3.21.47-5.24"/>
        <path   d="m438.29,310.28c.79-1.38,1.01-2.14,1.16-4.08-.49,1.22-1.49,3.13-1.72,4.49"/>
        <path   d="m436.37,310.17c.07-.65.04-1.32.32-1.8.01.4,0,.82-.06,1.21"/>
        <path   d="m437.85,310.98c-.12,1.29.06,2.2.01,3.38-.04,1.15-.49,2.54-.79,3.5,1.71-.56.67-5.12,1.69-6.39-.12.28-.24.47-.37.73"/>
        <path   d="m434.59,310.73c.75-.8-.14-2.35-.2-3.3-.09-1.2.64-2.32.41-3.17"/>
        <path   d="m436.59,311.93c-.04.37-.07.74-.11,1.1"/>
        <path   d="m435.71,318.26c.29-1.03,1.06-3.1.86-4.36-.21-1.28-1.08.2-1.1,1.3,1.38-.3-.34,2.86-.39,3.45"/>
        <path   d="m434.4,312.52c-.38.49-.95,1.61-.8,2.6.04.39.34.48.38,1,.02.35-.08.91-.16,1.2.13-.96.53-1.71.64-2.69.09-.74.1-1.5.16-2.23"/>
        <path   d="m432.59,313.55c.22.97.23,1.56-.09,2.4-.14.41-.25.44-.3.95-.04.27.08.81.04.99-.11.41-.49.51-.63.08-.16-.45.15-.83.3-1.21.33-.8.44-1.53.53-2.49"/>
        <path   d="m430.41,311.3c.02-.69-.22-1.21-.22-1.89.02-.58.19-1.1.2-1.69-.37.11-.36,1.6-.41,2.17"/>
        <path   d="m430.5,315.76c.12-.8.25-1.4.53-2.08.18-.43.49-.93.13-1.31-.41.53-.68,1.26-.85,2.09-.14.69-.38,1.34-.42,2.11"/>
        <path   d="m428.6,318.49c.08-.85.15-1.49.37-2.28.17-.6.32-.97.11-1.64-.42.92-.49,2.1-.6,3.3"/>
        <path   d="m427.54,315.27c.28-.74.65-1.11.76-2.05.06-.58-.03-1.13-.02-1.73-.67,0-.46,1.5-.55,2.14-.11.76-.31,1.39-.39,2.28"/>
        <path   d="m426.75,314.23c-.23,1.1-.91,1.56-1.21,2.52-.1.29-.07.58-.16.9-.16.67-.51,1.16-.79.29-.36-1.19.29-2.78.68-3.72.03.72-.47,3.2-.21,3.64"/>
        <path   d="m424.46,315.61c.13-1.41.59-3.2.47-4.59-.71,1.12-.74,2.81-.88,4.34"/>
        <path   d="m419.14,306.21c.22-.07.38-.39.46-.75"/>
        <path   d="m422.93,318.37c.08-.94.11-1.82.07-2.67,0-.49-.05-.96.1-1.54"/>
        <path   d="m419.79,304.36c.22-.24.4-.65.45-1.08"/>
        <path   d="m420.01,300.15c-.1.17-.17.45-.19.71"/>
        <path   d="m418.74,307.85c-.07.44-.02.87-.08,1.32"/>
        <path   d="m420.71,309.36c.01.16.02.32,0,.49"/>
        <path   d="m422.43,313.84c.03-.79.36-1.16.43-1.85"/>
        <path   d="m420.24,313.29c-.06.36-.03.76.03,1.09"/>
        <path   d="m419.57,309.96c0,.43-.2.71-.39.93"/>
        <path   d="m416.24,305.05c-.3.12-.42.77-.51,1.26-.19,1.15-.12,2.13-.63,3.04-.36.63-.54.7-.5-.26.05-1.15.7-1.88.75-2.94"/>
        <path   d="m421.36,313.49c.29-.45.6-.97.62-1.7-.04-.05-.11-.08-.16-.1-.25.3-1.01,1.66-.99,2.22"/>
        <path   d="m420.54,317.74c.07-.94.39-1.66.46-2.57-.08.6-.14,2.06-.58,2.05"/>
        <path   d="m420.25,311.54c-.03.5-.25.77-.38,1.16-.16.06-.22.05-.36.25.45-1.28.53-1.4-.25-1.59,0-.14.03-.21.08-.32"/>
        <path   d="m419.75,314.68c.04.76-.06,1.66-.39,2.23-.23.43-.67.73-.84.04.31-.5.94-.07.9-1.1"/>
        <path   d="m417.94,314.95c0-.26,0-.48.02-.74"/>
        <path   d="m417.46,311.94c.07.25.14.54.19.79"/>
        <path   d="m416.44,313.49c-.04.38-.05.85-.15,1.13"/>
        <path   d="m415.96,318.18c.08-1,.24-2.01.49-2.96.22-.81.51-1.39.6-2.26-.31,1.18-.61,1.94-.73,3.22"/>
        <path   d="m414.65,317.61c.21-.94.18-1.17,0-2-.09-.5-.14-.83-.03-1.42.26-1.39.94-2.11,1.24-3.39-.68,1.25-1.22,2.15-1.46,3.84-.07.56-.09,1.07-.07,1.6.01.59.24,1.64.12,2.07"/>
        <path   d="m412.29,317.36c.48-1.32.92-2.84,1.22-4.35.2-.99-.1-1.49-.04-2.42-.06.74-.25,1.47-.29,2.19,0-.24.03-.66,0-.93-.47,1.03-.39,1.9-.28,2.98.12,1.12-.25,1.43-.56,2.37"/>
        <path   d="m410.81,318.21c.09-.75.24-1.5.4-2.23-.32-.84.82-2.9.83-4.04-.61.31-1.27,2.27-1.4,3.39-.12,1.12-.16,2.2-.22,3.32"/>
        <path   d="m412.08,307.73c-.74-1.8.96-4.01.76-5.77-1.09.42-1.02,3.86-1.17,5.44"/>
        <path   d="m408.28,317.84c0-1.37.22-2.57.39-3.91.08-.57.38-1.66-.08-1.36-.56.36-.22,1.28-.18,1.92.03.77-.1,1.57-.17,2.34"/>
        <path   d="m408.43,309.23c.07-1.28.67-2.68.81-4.04.08-.73.14-1.47.19-2.19"/>
        <path   d="m406.91,313.81c.15-1.11.65-2.84.54-3.88"/>
        <path   d="m405.19,308.07c.11-.44.38-.8.5-1.25"/>
        <path   d="m405.18,317.16c.13-1.43.18-3.04.44-4.41.14-.71.34-1.4.45-2.09-.13.18-.21.55-.28.8-.2-.41-.06-1.01-.05-1.52-.5-.3-.24,1.18-.22,1.54.04.6.03,1.32-.08,1.99-.18.98-.31,1.86-.4,2.92-.05.57-.08,1.36-.22,1.8"/>
        <path   d="m403.38,305.05c.27-.58.34-.81.39-1.57-.17.21-.38.7-.48,1.07"/>
        <path   d="m402.7,296.6c.02-.18.03-.33.05-.51"/>
        <path   d="m405.17,302.4c.05-.27.06-.56.02-.75"/>
        <path   d="m402.82,317.31c-.16-.85.53-1.5.65-2.36.09-.65.16-1.42.17-2.06-.63.41-.32,1.48-.4,2.26-.09.74-.45,1.46-.53,2.31"/>
        <path   d="m403.01,306.47c-.04.37-.04.68.09.93"/>
        <path   d="m403.18,311.32c.31.49.68-1.53-.15-.42"/>
        <path   d="m440.66,302.6c.14-1.62.17-3.34.38-4.91.32,1.41.31,3.34.14,4.88"/>
        <path   d="m446.82,309.95c.11-1.32.15-2.85.43-4.11.16-.72.7-1.47.79-2.16.08-.55.07-1.02-.22-1.16-.55-.28-.55,1.14-.62,1.81-.19,1.94-.92,3.94-.74,5.93"/>
        <path   d="m445.38,310.49c.04.58.19,1.06.07,1.68-.68-.1-1.2,1.94-1.37,2.93-.09.54-.12,1.19-.26,1.72-.13.51-.43.62-.46,1.3.23-1.53.43-3.24.77-4.65.33-1.39,1.22-1.48.95-3.2"/>
        <path   d="m442.38,311.23c-.67-3.95,2.03-4.48,2.08-8.13-.14.01-.3.13-.41.28.09.21.07.59.12.75-.25.14-.47.31-.7.36"/>
        <path   d="m442.95,317.1c-.2-1.6.08-3.28.35-4.92-.53,1.77-.97,3.3-1.25,5.2"/>
        <path   d="m440.32,311.24c.2-2.29.3-3.97.91-6.07.22-.69.73-1.52.8-2.31-.07-.11-.13-.17-.21-.24-.56.84-.78,2.22-1,3.43-.2-.1-.42-.28-.6-.47-.47,1.32-.43,3.48-.6,5"/>
        <path   d="m440.16,318.62c0-1.83.36-4.44.89-6.09.27-.85.85-2.1.16-2.69-.14.78-.25,1.61-.37,2.4-.33-.37-.56-1.06-.4-1.77-.78,1.48-.33,3.12-.34,4.7-.01,1.15-.54,2.4-.21,3.48"/>
        <path   d="m452.62,279.94c.06.57.2,1.06.08,1.68-.69-.1-1.2,1.96-1.37,2.93-.1.55-.13,1.2-.25,1.71-.14.52-.43.63-.47,1.29.23-1.52.43-3.22.76-4.64.34-1.38,1.23-1.48.95-3.2"/>
        <path   d="m447.94,288.52c-.66-3.95,2.03-4.47,2.09-8.13-.14.01-.31.14-.42.27.1.23.08.6.13.75-.25.15-.47.31-.71.37"/>
        <path   d="m448.64,296.13c-.21-1.6.08-3.29.34-4.92-.53,1.76-.97,3.28-1.26,5.18"/>
        <path   d="m447.23,296.22c.19-2.29.29-3.98.91-6.06.22-.71.73-1.52.8-2.33-.06-.11-.13-.17-.21-.21-.56.83-.78,2.21-1,3.42-.21-.1-.42-.28-.59-.47-.47,1.34-.43,3.48-.61,5"/>
        <path   d="m446.01,288.52c.02-1.84.37-4.46.9-6.09.27-.85.86-2.11.17-2.7-.14.78-.25,1.61-.38,2.39-.33-.37-.56-1.04-.41-1.76-.77,1.48-.32,3.13-.35,4.69,0,1.15-.52,2.42-.2,3.49"/>
        <path   d="m446.45,288.17c.05.57.2,1.05.08,1.67-.68-.1-1.2,1.95-1.37,2.92-.1.55-.13,1.2-.26,1.71-.14.53-.43.63-.46,1.3.22-1.52.43-3.24.76-4.65.33-1.38,1.23-1.48.95-3.19"/>
        <path   d="m442.56,288.97c-.67-3.97,2.03-4.49,2.09-8.14-.14.02-.31.13-.42.27.08.22.07.61.12.76-.25.15-.47.3-.71.37"/>
        <path   d="m442.56,295.52c-.2-1.59.08-3.28.34-4.91-.53,1.77-.97,3.28-1.26,5.19"/>
        <path   d="m440.47,292.63c.19-2.28.29-3.98.91-6.06.22-.71.74-1.51.81-2.32-.07-.1-.13-.18-.21-.22-.55.83-.78,2.2-1,3.41-.21-.1-.41-.27-.59-.47-.48,1.34-.44,3.49-.61,5"/>
        <path   d="m438.09,296.31c.02-1.83.37-4.45.89-6.09.28-.86.85-2.11.16-2.7-.13.79-.24,1.6-.38,2.39-.32-.36-.56-1.05-.4-1.77-.78,1.49-.33,3.14-.34,4.69-.01,1.16-.54,2.42-.21,3.5"/>
        <path   d="m453.83,288.67c.05.57.2,1.06.08,1.68-.68-.1-1.2,1.96-1.38,2.94-.09.54-.12,1.18-.25,1.7-.14.52-.42.63-.47,1.3.23-1.53.44-3.24.77-4.64.33-1.39,1.22-1.48.95-3.21"/>
        <path   d="m449.62,295.83c-.67-3.97,2.02-4.48,2.08-8.13-.14.01-.3.12-.42.27.09.23.07.59.13.76-.25.14-.48.31-.71.36"/>
        <path   d="m448.02,302.11c-.2-1.6.08-3.3.35-4.93-.53,1.76-.97,3.3-1.26,5.19"/>
        <path   d="m445.13,305.99c.2-2.29.3-3.98.92-6.06.21-.71.73-1.52.81-2.32-.07-.11-.14-.17-.22-.22-.56.83-.78,2.2-1,3.41-.2-.09-.42-.27-.58-.46-.48,1.34-.45,3.48-.62,5"/>
        <path   d="m443.05,305.25c.02-1.84.37-4.45.9-6.1.27-.86.86-2.11.17-2.69-.14.79-.24,1.61-.37,2.4-.34-.37-.56-1.05-.42-1.78-.77,1.48-.32,3.15-.34,4.69-.01,1.15-.54,2.41-.21,3.5"/>
        <path   d="m446.88,316.62c-.32-1.35.75-4.08,1.03-5.41.09-.48.58-2.2.43-2.69-.27-.86-.63.04-.76.55-.16.69-.03,1.45-.12,2.14-.1.7-.38,1.33-.52,2.02-.31,1.57-.65,3.23-.79,4.84.15-.36.33-.88.35-1.41"/>
        <path   d="m471.76,306.58c.07,2.96-.04,6.65-1.38,8.76"/>
        <path   d="m399.41,300.38c.07-.86.91-6.18.4-6.23-.89-.08-.66,5.12-.73,6"/>
        <path   d="m396.69,301.4c.48-1.74,1.4-5.48.26-6.48-.05-.37-.12-.45-.22-.75-.31,2.22.91,3.54.3,5.46"/>
        <path   d="m402.05,293.67c.23-2.04.72-3.95.78-5.99-1.01-.39-1.08,4.12-.97,5.13"/>
        <path   d="m400.74,294.07c.14-1.55.33-3.23.34-4.72.02-1.24.3-1.88.37-3.09-.39,1.71-.83,5.39-.66,7.17"/>
        <path   d="m398.8,289.27c.22-2.45.53-4.78.73-7.17-1.3-.67-.61,3.78-.78,4.84"/>
        <path   d="m398.34,292.91c.1-2.01.58-3.97.72-6-.04,0-.2-.04-.24-.06-.27.95-.27,2.16-.41,3.16-.02-.69.08-1.44.13-2.14-.69,1.05-.73,2.89-.83,4.41.4-.38.37-1.29.77-1.84"/>
        <path   d="m397.25,283.97c-.02,2.09-1.03,4.49-.15,6.54.11-.93-.17-1.53-.16-2.38.03-.79.3-1.26.36-1.96.13-1.25.17-3.44.08-4.65"/>
        <path   d="m401.84,306.67c-.55,1.04-.65,4.23-.79,5.73"/>
        <path   d="m401.25,307.98c.06-1.2.37-2.29.64-3.39.4-1.69.68-3.26.98-5.02.18-1.1.97-3.34.17-3.97-.67,1.78-.62,3.96-1.26,5.85-.74,2.19-.77,4.42-1.08,6.79"/>
        <path   d="m400.76,299.37c.27-.86.42-2.36.36-3.34-.33.76-.63,1.35-.62,2.3,0,.61.37.89.07,1.51"/>
        <path   d="m397.11,307.89c.11-1.19.2-2.22.5-3.31.21-.75.42-1.08.44-1.99-.95,1.04-.97,3.36-1.13,5.14"/>
        <path   d="m398.14,301.53c-.08.83.41.62.69.96.08-.74.03-1.84.46-2.13"/>
        <path   d="m406,302.87c-.31.12-.42.77-.51,1.26-.19,1.15-.12,2.13-.63,3.04-.36.63-.53.71-.5-.25.05-1.16.7-1.88.75-2.94"/>
        <path   d="m401.39,317.57c.2-.95.17-1.17.01-2-.1-.5-.14-.83-.04-1.42.26-1.39.94-2.11,1.24-3.39-.68,1.25-1.23,2.15-1.46,3.85-.08.56-.09,1.06-.07,1.6.02.58.25,1.63.13,2.07"/>
        <path   d="m398.42,316.7c.49-1.32.92-2.84,1.23-4.35.19-.98-.1-1.49-.04-2.42-.07.75-.25,1.48-.3,2.19,0-.24.03-.66,0-.93-.47,1.04-.4,1.9-.28,2.99.13,1.12-.25,1.43-.55,2.37"/>
        <path   d="m399.56,306.93c.09-.74.24-1.49.4-2.22-.31-.84.82-2.9.83-4.03-.61.31-1.27,2.27-1.4,3.38-.12,1.12-.16,2.2-.21,3.32"/>
        <path   d="m404.64,303.43c-.74-1.8.96-4.01.76-5.77-1.08.42-1.03,3.86-1.17,5.44"/>
        <path   d="m397.41,316.78c0-1.37.22-2.57.39-3.91.07-.57.37-1.66-.08-1.36-.56.36-.22,1.28-.18,1.92.03.76-.1,1.57-.17,2.34"/>
        <path   d="m391.8,302.89c.13-1.43.18-3.04.45-4.41.14-.71.34-1.4.44-2.09-.14.18-.21.54-.29.8-.2-.41-.06-1.01-.04-1.52-.51-.3-.25,1.17-.22,1.54.04.6.03,1.32-.08,1.98-.18.99-.31,1.86-.41,2.92-.05.57-.08,1.36-.21,1.8"/>
      </g>
      <g id="Escudo">
        <g>
          <circle   cx="537" cy="487" r="18.5"/>
          <path d="m537,469c9.93,0,18,8.07,18,18s-8.07,18-18,18-18-8.07-18-18,8.07-18,18-18m0-1c-10.49,0-19,8.51-19,19s8.51,19,19,19,19-8.51,19-19-8.51-19-19-19h0Z"/>
        </g>
        <g>
          <circle   cx="252" cy="487" r="18.5"/>
          <path d="m252,469c9.93,0,18,8.07,18,18s-8.07,18-18,18-18-8.07-18-18,8.07-18,18-18m0-1c-10.49,0-19,8.51-19,19s8.51,19,19,19,19-8.51,19-19-8.51-19-19-19h0Z"/>
        </g>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default ShortDeportivoFrontalSvg
