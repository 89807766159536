import { IconProps } from '../../../types'
export const File = ({
    height = 30,
    width = 22,
    fill = 'none'
}: IconProps): JSX.Element => {
    return (
        <svg width="30" height="37" viewBox="0 0 30 37" fill="none"
        >
            <path d="M20.3763 0.583984H4.2513C2.28047 0.583984 0.667969 2.19648 0.667969 4.16732V32.834C0.667969 34.8048 2.28047 36.4173 4.2513 36.4173H25.7513C27.7221 36.4173 29.3346 34.8048 29.3346 32.834V9.54232L20.3763 0.583984ZM4.2513 32.834V4.16732H18.5846V11.334H25.7513V32.834H4.2513ZM22.168 14.9173V23.8757C22.168 27.8352 18.9609 31.0423 15.0013 31.0423C11.0417 31.0423 7.83464 27.8352 7.83464 23.8757V12.2298C7.83464 9.59607 10.0921 7.49982 12.7796 7.76857C15.1088 8.00148 16.793 10.1336 16.793 12.4807V23.8757H13.2096V12.2298C13.2096 11.7282 12.8155 11.334 12.3138 11.334C11.8121 11.334 11.418 11.7282 11.418 12.2298V23.8757C11.418 25.8465 13.0305 27.459 15.0013 27.459C16.9721 27.459 18.5846 25.8465 18.5846 23.8757V14.9173H22.168Z" fill="black" />
        </svg>
    )
}
