import React from 'react'

const GreenTagJacket = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
     <g id="Green_Tag" data-name="Green Tag" stroke='#095540'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <rect   x="382.46" y="86.94" width="28.5" height="36.27" transform="translate(289.63 501.25) rotate(-89.71)"/>
      <rect   x="380.4" y="92.68" width="32.64" height="24.48"/>
      <g>
        <path   d="m393.15,97.16s.26,0,.72.13c.46.14.98.35,1.36.54.38.19.79.47.92.6.13.13.43.47.44.55.02.07-.01.26-.11.4-.1.14-.35.42-.46.51-.11.1-.21.17-.24.18-.03,0-.05.04-.05.04,0,0,0,.02,0,.04,0,.01.02.05.02.05,0,0-.27.14-.53.27-.26.12-.46.2-.56.25-.1.05-.19.1-.19.1,0,0-.02.05,0,.05s.47-.04,1.33-.18c.86-.14,1.38-.25,1.72-.4.34-.14.48-.31.56-.4.08-.09.11-.16.14-.25.03-.08.04-.14.04-.14,0,0,.25-.07.4-.14.15-.08.37-.22.45-.27.08-.05.2-.09.27-.11.06-.02.18-.05.18-.05,0,0-.03-.06-.05-.07-.01-.01-.09-.11-.09-.16s-.13-.21-.21-.21-.22,0-.31.01c-.09.01-.22.03-.27.03-.06,0-.09,0-.09,0,0,0,0-.05.03-.08.02-.03.19-.37.17-.54-.03-.17-.17-.64-.31-.92-.14-.28-.36-.66-.43-.76-.07-.1-.1-.12-.12-.08-.02.04-.01.21,0,.36.02.16.03.28.04.31,0,.03,0,.16,0,.18s-.03.09-.02.09c.01,0,.02.04.02.04l-.09.02s-.01.08-.03.13c-.02.05-.08.23-.17.41-.09.18-.19.37-.24.45-.06.08-.11.14-.14.14s-.29-.11-.59-.24c-.3-.13-.53-.27-.86-.46-.32-.19-.74-.68-.79-.75-.06-.07-.23-.29-.4-.51-.18-.22-.42-.52-.51-.63-.09-.1-.22-.21-.26-.24-.03-.03-.11-.08-.12-.07-.02.01,0,.04.03.07.02.04.29.36.45.55.15.19.48.62.56.74.08.13.24.38.29.47.05.09.07.23.07.23,0,0-.05,0-.22-.03-.16-.04-.79-.24-.92-.27-.13-.03-.48-.1-.56-.11-.09,0-.14,0-.18,0-.04,0-.16.02-.11.08Z"/>
        <g>
          <path   d="m387.97,103.73v.12l-.32.08s-.06.02-.07.04c-.01.02-.02.04-.03.07-.01.06-.02.22-.02.47,0,.15,0,.36.02.61-.06.01-.19.04-.39.09-.27.06-.43.09-.5.1-.13.02-.28.03-.43.03-.49,0-.87-.13-1.13-.4-.26-.26-.39-.62-.39-1.07,0-.49.15-.89.46-1.19.31-.31.72-.46,1.23-.46.13,0,.25,0,.37.02.03,0,.26.05.72.13,0,.08,0,.14,0,.18,0,.19,0,.4.03.64h-.16c-.06-.24-.12-.4-.18-.48-.05-.08-.15-.14-.27-.18-.17-.07-.35-.1-.54-.1-.43,0-.76.16-.97.49-.15.23-.23.52-.23.85,0,.43.12.76.36.99.24.22.54.33.91.33.23,0,.47-.05.72-.15,0-.15.01-.37.01-.67,0-.16,0-.25-.01-.28,0-.03-.03-.05-.05-.07-.03-.02-.13-.05-.29-.08l-.08-.02v-.12l.71.02c.16,0,.34,0,.54-.02Z"/>
          <path   d="m388.23,102.79v-.11c.34.02.62.02.83.02.12,0,.23,0,.34,0,.14,0,.23,0,.29,0,.25,0,.45.06.58.17.13.11.19.27.19.47,0,.13-.03.25-.08.35-.06.11-.15.19-.26.26-.08.05-.19.09-.34.13l.65.81c.08.1.14.16.16.18.03.03.07.05.13.07.07.03.2.06.37.1v.11c-.21-.01-.36-.02-.45-.02-.11,0-.23,0-.34.02-.06-.1-.14-.22-.24-.36-.1-.14-.16-.22-.18-.25-.01-.01-.03-.03-.04-.04l-.43-.57c-.11,0-.23-.01-.35-.02v.94s.03.06.05.08c.03.03.13.05.29.08.02,0,.06.01.11.02v.11c-.24-.01-.44-.02-.62-.02-.2,0-.41,0-.65.02v-.11l.09-.02c.18-.04.29-.06.32-.09.02-.02.04-.06.04-.11,0-.04,0-.36,0-.96v-.88c0-.14-.01-.21-.02-.23,0-.02-.02-.03-.04-.05-.07-.03-.17-.06-.3-.08-.02,0-.05,0-.09-.02Zm.82,1.14c.14.01.24.02.32.02.24,0,.41-.05.53-.15.11-.11.17-.24.17-.42,0-.16-.05-.28-.15-.37-.1-.09-.25-.14-.44-.14-.07,0-.21,0-.41.02v1.04Z"/>
          <path   d="m394.59,103.85h.45c.17,0,.27,0,.3-.01.03,0,.05-.03.07-.05.02-.03.04-.11.07-.25,0-.02,0-.04.02-.06h.12c-.01.21-.02.36-.02.47,0,.11.01.27.02.47h-.12c-.04-.18-.06-.28-.07-.3-.01-.03-.03-.05-.07-.06-.03-.02-.12-.02-.27-.02h-.51v1.09h.85c.11,0,.17,0,.2-.02.05-.01.09-.04.12-.08.04-.04.1-.15.2-.32.01-.02.03-.04.04-.07h.13s-.09.27-.23.7c-.45-.01-.86-.02-1.23-.02-.26,0-.55,0-.86.02v-.11l.3-.07s.07-.03.09-.04c.02-.02.03-.07.03-.15v-.47c.01-.08.01-.25.01-.49,0-.36,0-.62,0-.79,0-.17,0-.26-.02-.28,0-.02-.02-.03-.03-.04-.02,0-.07-.03-.17-.05l-.2-.04v-.11c.23.01.51.02.83.02.17,0,.59,0,1.26-.02,0,.21.02.43.04.63h-.13c-.05-.17-.09-.28-.12-.34-.02-.04-.05-.06-.08-.08-.04-.02-.13-.02-.27-.02h-.75v.6s-.02.36-.02.36Z"/>
          <path   d="m391.99,103.85h.45c.17,0,.27,0,.3-.01.03,0,.05-.03.08-.05.01-.03.04-.11.07-.25,0-.02.01-.04.02-.06h.12c0,.21-.02.36-.02.47,0,.11.01.27.02.47h-.12c-.04-.18-.06-.28-.07-.3-.01-.03-.03-.05-.06-.06-.03-.02-.12-.02-.28-.02h-.51v1.09h.85c.1,0,.17,0,.2-.02.05-.01.09-.04.12-.08.04-.04.1-.15.2-.32.01-.02.03-.04.05-.07h.13s-.09.27-.23.7c-.45-.01-.86-.02-1.23-.02-.26,0-.55,0-.86.02v-.11l.3-.07s.08-.03.09-.04c.02-.02.03-.07.03-.15v-.47c0-.08,0-.25,0-.49,0-.36,0-.62,0-.79,0-.17,0-.26-.02-.28,0-.02-.02-.03-.03-.04-.02,0-.07-.03-.17-.05l-.2-.04v-.11c.23.01.51.02.83.02.17,0,.6,0,1.26-.02,0,.21.02.43.04.63h-.13c-.05-.17-.09-.28-.12-.34-.02-.04-.04-.06-.08-.08-.04-.02-.13-.02-.27-.02h-.75v.6s-.02.36-.02.36Z"/>
          <path   d="m396.45,102.74v-.11c.16.02.28.02.37.02.07,0,.2,0,.38-.02l.53.67c.05.05.42.51,1.12,1.35,0-.17,0-.3,0-.38,0-.18-.01-.52-.02-1.01,0-.23-.01-.35-.02-.37,0-.02-.02-.04-.04-.05-.02-.02-.11-.04-.26-.07-.02,0-.05-.01-.09-.02v-.11c.22.02.39.02.52.02s.3,0,.52-.02v.11s-.06.01-.07.02c-.17.03-.26.06-.28.07-.01.01-.02.02-.03.04-.01.02-.02.06-.03.11,0,.02,0,.19,0,.51,0,.31,0,.62,0,.91,0,.35,0,.64.02.88l-.12.06-.77-.96-1.01-1.23v1.1c.02.48.02.74.03.77,0,.02.02.04.03.06.03.02.12.05.28.08.01,0,.04,0,.08.02v.11c-.19-.01-.35-.02-.48-.02-.11,0-.3,0-.57.02v-.11s.07-.02.1-.02c.15-.03.23-.05.26-.07.01-.01.02-.03.03-.06.01-.06.03-.28.03-.65,0-.53,0-.85,0-.94,0-.31,0-.49-.01-.52,0-.02-.02-.05-.05-.06-.03-.02-.14-.04-.35-.08-.02,0-.06-.01-.1-.02Z"/>
          <path   d="m400.89,103.17h-.16c.02-.24.05-.51.07-.81.62.01,1.06.02,1.32.02.22,0,.62,0,1.2-.02.02.31.04.58.06.81h-.15c-.07-.25-.11-.4-.13-.44-.02-.05-.05-.08-.09-.1-.04-.02-.13-.03-.28-.03h-.47c-.01.37-.02.83-.02,1.38,0,.5,0,.85.02,1.03,0,.04.01.07.03.08.02.02.14.05.35.09.01,0,.05,0,.09.02v.13c-.28-.02-.53-.03-.74-.03-.21,0-.42,0-.63.03v-.13s.08-.02.11-.03c.19-.03.3-.06.33-.08.01-.01.02-.03.03-.05,0-.02.01-.18.02-.46,0-.45,0-.78,0-.99,0-.38,0-.71,0-.99h-.59c-.08,0-.13.01-.16.03-.04.03-.07.07-.1.15-.03.06-.06.19-.11.39Z"/>
          <path   d="m404.44,102.68h.11c.16.43.36.91.6,1.45l.43.94s.04.06.07.07c.01,0,.1.04.26.09v.11c-.22-.01-.38-.02-.49-.02s-.28,0-.54.02v-.11c.14-.03.23-.06.25-.07.03-.02.04-.04.04-.07s-.01-.07-.04-.13l-.18-.45c-.26,0-.44,0-.56,0h-.49s-.19.47-.19.47c-.03.07-.05.12-.05.14,0,.02,0,.03.02.04.01,0,.08.03.21.06.01,0,.03,0,.06.02v.11c-.21-.01-.35-.02-.44-.02-.11,0-.25,0-.41.02v-.11c.11-.03.18-.05.22-.08.03-.02.06-.06.1-.12.07-.12.24-.51.52-1.17l.5-1.17Zm-.48,1.64c.17,0,.29,0,.36,0,.02,0,.21,0,.56,0l-.46-1.1-.46,1.1Z"/>
          <path   d="m408.75,103.91v.1l-.28.07s-.05.02-.07.03c-.01.01-.02.03-.03.06-.01.05-.02.2-.02.42,0,.13,0,.32.02.55-.05,0-.17.03-.35.07-.23.05-.38.08-.44.09-.12.02-.24.03-.38.03-.43,0-.76-.12-.99-.35-.23-.23-.35-.55-.35-.95,0-.43.14-.78.41-1.05.26-.27.63-.4,1.08-.4.11,0,.22,0,.32.02.02,0,.23.04.63.12,0,.07,0,.12,0,.16,0,.17,0,.36.02.56h-.14c-.06-.21-.11-.35-.15-.41-.05-.07-.13-.13-.24-.17-.15-.06-.31-.08-.48-.08-.38,0-.66.14-.85.43-.13.2-.2.46-.2.76,0,.38.11.67.32.87.21.2.47.3.8.3.21,0,.42-.04.64-.13,0-.13,0-.33,0-.59,0-.14,0-.22-.01-.25,0-.02-.02-.04-.04-.06-.03-.02-.11-.04-.25-.07l-.07-.02v-.1h.63c.14.01.3,0,.47,0Z"/>
        </g>
      </g>
      <g>
        <path   d="m386.97,114.4h-.78v.37c0,.12,0,.2,0,.24,0,.04.02.06.05.08.02.02.07.03.13.03h.04v.05h-.67v-.05h.04c.06,0,.11,0,.13-.02.03-.02.04-.04.05-.08,0-.03.01-.12.01-.25v-.75c0-.13,0-.21-.01-.24,0-.03-.02-.06-.04-.07-.03-.01-.07-.02-.14-.02h-.03v-.05h.67v.05h-.03c-.07,0-.12,0-.14.02-.02,0-.04.03-.04.07,0,.03,0,.11,0,.25v.3h.78v-.3c0-.13,0-.21,0-.24,0-.03-.02-.06-.04-.07-.02-.01-.07-.02-.15-.02h-.04v-.05h.68v.05h-.02c-.07,0-.12,0-.14.02-.02,0-.04.03-.05.07,0,.03,0,.11,0,.25v.75c0,.12,0,.2,0,.24,0,.04.02.06.05.08.02.02.07.03.14.03h.03v.05h-.68v-.05h.05c.06,0,.11,0,.13-.02.02-.02.04-.04.05-.08,0-.03,0-.12,0-.25v-.37Z"/>
        <path   d="m388.77,114.69h.05l-.03.48h-1.23v-.05h.03c.07,0,.12,0,.14-.03.02-.02.03-.05.04-.08,0-.03,0-.11,0-.24v-.75c0-.12,0-.2,0-.24,0-.04-.02-.07-.04-.08-.03-.01-.07-.02-.14-.02v-.05h1.07l.03.39h-.05c-.02-.11-.05-.19-.08-.23-.03-.04-.07-.07-.12-.08-.05-.01-.12-.02-.23-.02h-.2v.65h.16c.07,0,.12,0,.15-.02.03-.01.05-.03.06-.06,0-.02.01-.08.01-.15h.05v.53h-.05c0-.09,0-.14-.02-.17-.02-.03-.04-.05-.06-.05-.03-.01-.07-.01-.14-.01h-.16v.37c0,.18.01.29.04.32.02.03.08.05.18.05h.11c.08,0,.14,0,.19-.03.05-.02.1-.07.15-.13.04-.06.08-.15.11-.27Z"/>
        <path   d="m390.31,114.59h.05v.54h-.05c-.01-.06-.04-.12-.07-.16-.13.15-.3.23-.5.23s-.39-.07-.53-.21c-.13-.14-.21-.33-.21-.56,0-.15.03-.3.1-.43.06-.14.15-.23.26-.3.11-.06.24-.09.37-.09.11,0,.21.02.3.07.09.05.16.1.21.16.03-.07.05-.14.05-.2h.05v.59s-.04,0-.04,0c-.03-.21-.1-.35-.2-.43-.1-.08-.22-.12-.35-.12-.15,0-.28.06-.37.2-.09.13-.14.32-.14.56s.05.41.15.53c.1.12.22.18.38.18.08,0,.16-.02.24-.06.09-.04.15-.11.2-.21.05-.09.08-.19.09-.28Z"/>
        <path   d="m391.8,114.4h-.78v.37c0,.12,0,.2,0,.24,0,.04.02.06.05.08.02.02.07.03.13.03h.04v.05h-.67v-.05h.04c.06,0,.11,0,.13-.02.02-.02.04-.04.05-.08,0-.03,0-.12,0-.25v-.75c0-.13,0-.21,0-.24-.01-.03-.02-.06-.05-.07-.02-.01-.07-.02-.15-.02h-.03v-.05h.67v.05h-.03c-.07,0-.12,0-.14.02-.02,0-.04.03-.04.07,0,.03-.01.11-.01.25v.3h.78v-.3c0-.13,0-.21,0-.24,0-.03-.02-.06-.05-.07-.02-.01-.07-.02-.15-.02h-.04v-.05h.68v.05h-.02c-.07,0-.12,0-.15.02-.02,0-.04.03-.04.07-.01.03-.01.11-.01.25v.75c0,.12,0,.2,0,.24,0,.04.02.06.05.08.02.02.07.03.14.03h.03v.05h-.68v-.05h.05c.07,0,.11,0,.13-.02.02-.02.04-.04.05-.08,0-.03,0-.12,0-.25v-.37Z"/>
        <path   d="m392.42,114.39c0-.24.07-.43.23-.58.15-.15.34-.22.58-.22s.43.07.58.21c.16.14.23.33.23.58s-.08.43-.22.58c-.15.15-.35.22-.59.22s-.43-.07-.58-.22c-.15-.15-.23-.35-.23-.59Zm.25,0c0,.25.05.44.14.56.1.12.24.19.43.19.17,0,.3-.06.4-.19.1-.13.14-.31.14-.56s-.05-.43-.14-.55c-.09-.13-.24-.19-.42-.19s-.32.06-.41.19c-.09.12-.14.31-.14.55Z"/>
        <path   d="m396.01,114.69h.05l-.03.48h-1.23v-.05h.03c.07,0,.12,0,.14-.03.02-.02.04-.05.04-.08,0-.03.01-.11.01-.24v-.75c0-.12,0-.2-.01-.24,0-.04-.02-.07-.04-.08-.02-.01-.07-.02-.14-.02v-.05h1.07l.04.39h-.05c-.02-.11-.06-.19-.09-.23-.03-.04-.08-.07-.12-.08-.04-.01-.12-.02-.23-.02h-.2v.65h.16c.08,0,.12,0,.15-.02.03-.01.05-.03.06-.06,0-.02.02-.08.02-.15h.05v.53h-.05c0-.09,0-.14-.02-.17-.02-.03-.03-.05-.06-.05-.03-.01-.07-.01-.14-.01h-.16v.37c0,.18,0,.29.03.32.02.03.08.05.18.05h.11c.08,0,.14,0,.19-.03.05-.02.1-.07.15-.13.04-.06.08-.15.11-.27Z"/>
        <path   d="m397.51,115.21l-1.05-1.45v1.07c0,.09,0,.15.01.18,0,.03.03.06.07.08.04.02.1.03.18.03v.05h-.56v-.05c.07,0,.13-.02.16-.04.04-.02.06-.04.07-.07,0-.03,0-.08,0-.18v-.82c0-.12,0-.2-.01-.24-.01-.04-.03-.07-.05-.08-.03,0-.07-.01-.16-.01v-.05h.44l.88,1.21v-.92c0-.07,0-.11-.02-.14-.01-.03-.04-.05-.08-.06-.04-.02-.09-.03-.15-.03v-.05h.54v.05c-.06,0-.11.02-.15.04-.04.02-.06.04-.07.07,0,.03-.01.07-.01.14v1.28h-.05Z"/>
        <path   d="m399.42,114.91l.54-1.28h.46v.05h-.05c-.07,0-.12,0-.14.02-.02,0-.04.03-.05.07,0,.03-.01.11-.01.25v.75c0,.12,0,.2.01.24,0,.04.02.06.04.08.03.02.07.03.14.03h.07v.05h-.71v-.05h.05c.07,0,.11,0,.13-.02.02-.02.04-.04.05-.08,0-.03.01-.12.01-.25v-.98l-.59,1.39h-.05l-.6-1.39v.91c0,.13,0,.21.01.25,0,.05.03.09.07.12.04.03.09.05.16.05v.05h-.53v-.05c.07,0,.12-.02.16-.05.04-.03.06-.07.07-.12.01-.05.01-.14.01-.26v-.67c0-.13,0-.21,0-.24,0-.03-.02-.06-.04-.07-.03-.01-.07-.02-.15-.02h-.04v-.05h.45l.55,1.28Z"/>
        <path   d="m401.75,114.69h.05l-.03.48h-1.23v-.05h.03c.08,0,.12,0,.15-.03.02-.02.03-.05.04-.08,0-.03,0-.11,0-.24v-.75c0-.12,0-.2,0-.24,0-.04-.02-.07-.04-.08-.02-.01-.07-.02-.14-.02v-.05h1.07l.03.39h-.05c-.02-.11-.05-.19-.08-.23-.04-.04-.08-.07-.12-.08-.04-.01-.12-.02-.22-.02h-.21v.65h.16c.07,0,.13,0,.15-.02.03-.01.05-.03.06-.06,0-.02.01-.08.01-.15h.05v.53h-.05c0-.09-.01-.14-.03-.17-.01-.03-.03-.05-.06-.05-.02-.01-.07-.01-.14-.01h-.16v.37c0,.18.01.29.03.32.02.03.09.05.18.05h.11c.08,0,.15,0,.19-.03.05-.02.1-.07.15-.13.04-.06.08-.15.11-.27Z"/>
        <path   d="m402.8,114.32l.39.49c.14.17.22.27.26.29.04.02.09.03.15.03v.05h-.74v-.05c.08,0,.13,0,.16-.02.03-.01.04-.03.04-.07,0-.02,0-.04-.02-.07,0-.02-.05-.07-.1-.14l-.27-.34-.23.3c-.05.07-.09.12-.11.14-.02.03-.03.06-.03.08,0,.08.06.11.17.11v.05h-.58v-.05c.06,0,.12-.01.17-.04.05-.03.1-.06.14-.1.04-.04.1-.12.18-.22l.25-.31-.39-.49-.13-.16s-.05-.06-.09-.08c-.03-.02-.08-.03-.13-.03v-.05h.63v.05h-.02c-.08,0-.12.02-.12.07,0,.02,0,.04.02.06.01.02.04.06.09.12l.27.34.24-.32c.05-.07.09-.11.09-.13.01-.02.02-.04.02-.05,0-.04-.02-.06-.05-.07-.03-.01-.08-.02-.15-.02v-.05h.56v.05c-.09,0-.17.03-.24.09-.06.06-.14.14-.22.24l-.23.31Z"/>
        <path   d="m403.66,113.68v-.05h.68v.05h-.03c-.07,0-.12,0-.14.02-.02,0-.04.03-.05.07,0,.03,0,.11,0,.25v.75c0,.12,0,.2,0,.24,0,.04.02.06.04.08.03.02.07.03.14.03h.04v.05h-.68v-.05h.05c.06,0,.11,0,.13-.02.03-.02.04-.04.05-.08,0-.03.01-.12.01-.25v-.75c0-.13,0-.21-.01-.24,0-.03-.02-.06-.04-.07-.02-.01-.07-.02-.15-.02h-.03Z"/>
        <path   d="m405.82,114.59h.05v.54h-.05c-.01-.06-.04-.12-.07-.16-.13.15-.3.23-.5.23-.22,0-.39-.07-.53-.21-.14-.14-.2-.33-.2-.56,0-.15.03-.3.1-.43.06-.14.15-.23.26-.3.11-.06.24-.09.37-.09.11,0,.21.02.3.07.09.05.16.1.2.16.03-.07.05-.14.06-.2h.05v.59s-.04,0-.04,0c-.03-.21-.1-.35-.2-.43-.1-.08-.22-.12-.34-.12-.16,0-.28.06-.37.2-.09.13-.14.32-.14.56s.05.41.15.53c.1.12.22.18.37.18.08,0,.16-.02.25-.06.08-.04.15-.11.2-.21.05-.09.08-.19.09-.28Z"/>
        <path   d="m406.13,114.39c0-.24.08-.43.23-.58.16-.15.35-.22.58-.22s.42.07.58.21c.16.14.23.33.23.58s-.07.43-.22.58c-.15.15-.35.22-.59.22s-.43-.07-.58-.22c-.15-.15-.23-.35-.23-.59Zm.25,0c0,.25.05.44.15.56.09.12.24.19.43.19.16,0,.3-.06.39-.19.1-.13.15-.31.15-.56s-.05-.43-.14-.55c-.1-.13-.24-.19-.42-.19s-.32.06-.41.19c-.1.12-.15.31-.15.55Z"/>
      </g>
      <path   d="m395.86,110.2l-.02-.19c.22-.42.54-.77.86-1.11.24-.26.5-.57.5-.94,0-.3-.2-.59-.51-.59-.2,0-.41.11-.41.32,0,.14.11.2.11.34,0,.12-.1.22-.22.22-.17,0-.26-.15-.26-.3,0-.43.44-.71.83-.71.42,0,.87.29.87.75,0,.42-.33.71-.62.95-.45.36-.55.47-.87.95h.4c.26,0,.48,0,.62-.05.12-.05.24-.18.34-.43l.12.03-.18.76h-1.56Z"/>
      
    </g>
  </g>
    </svg>
  )
}

export default GreenTagJacket
