import React, { useEffect, useState } from 'react'

import PathSujecionSpiralOutside from 'assets/img/notebooks/profesional_20x26/PathSujecionSpiralOutside'
import PathPortada from 'assets/img/notebooks/profesional_20x26/PathPortada'
import PathSujecionPortadasVertical from 'assets/img/notebooks/profesional_20x26/PathSujecionPortadasVertical'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './notebooksEitable.module.scss'
import VerticalEditabledraggable from './VerticalEditabledraggable'
import EditablePanel from './EditablePanel'
import DraggableImgCenterProporsal from '../EditorDiv/DraggableImgCenterProporsal'
import { setPositionsCovers } from 'redux/reducers/notebooksSlice'

const VerticalPortadasEitable = () => {
  const {
    notebookFrontValues,
    notebookSelected,
    edithUpload,
    positionCovers,
    isOnEdith
  } = useSelector((state: RootState) => state.notebooks)
  const dispatch = useDispatch()
  const { spine_color, binding_type, binding_color, rounded, cover_design } =
    notebookFrontValues

  return (
    <div className={`${style['div-container-sticky']}`}>
      <EditablePanel />
      <div className={`${style['div-notebook-svg']}`}>
        
        {cover_design.value === 'design' && <VerticalEditabledraggable />}
        {[1, 2].map((item: number, index: number) => {
          let viewBox = item === 1 ? '147 0 620 700' : '147 0 620 700'
          return (
            <div
              key={index}
              className={`${style['div-polo-notebook-relative']} 
                ${item === 1 && style['transform-invert']}`}
            >
              <div className={`${style[`logo-position-`]}`}></div>
              <div className={`${style['div-polo-svg-absolute']}`}>
                <PathPortada
                  viewBox={viewBox}
                  showSizes={item}
                  className={`${style['svg']}`}
                  notebookSelected={notebookSelected}
                  colorLomo={spine_color.value as string}
                  rounded={rounded.value as boolean}
                />
              </div>
              <div className={`${style['div-shadows-svg-absolute']}`}>
                {cover_design.value === 'upload' &&
                  !edithUpload.left &&
                  !edithUpload.right && (
                    <DraggableImgCenterProporsal
                      setPositions={(dat: { back: number; front: number }) =>
                        dispatch(setPositionsCovers(dat))
                      }
                      positions={positionCovers}
                      edithSides={edithUpload}
                      item={item}
                    />
                  )}
              </div>

              <div className={`${style['div-tags-svg-absolute']}`}>
                {binding_type.value === 'Espiral' ? (
                  <PathSujecionSpiralOutside
                    className={`${style['svg']}`}
                    viewBox={viewBox}
                    show={2}
                    spiralColor={binding_color.value as string}
                  />
                ) : (
                  <PathSujecionPortadasVertical
                    color={binding_color.value as string}
                    viewBox={viewBox}
                    className={`${style['svg']}`}
                    show={1}
                  />
                )}
              </div>

              <div className={`${style['div-sujecion-svg-absolute']}`}>
                {cover_design.value === 'upload' &&
                  (edithUpload.left || edithUpload.right) && (
                    <DraggableImgCenterProporsal
                      setPositions={(dat: { back: number; front: number }) =>
                        dispatch(setPositionsCovers(dat))
                      }
                      positions={positionCovers}
                      edithSides={edithUpload}
                      item={item}
                    />
                  )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VerticalPortadasEitable
