import { Fragment, useEffect, useState } from 'react'
import { Input, Modal, Select, Skeleton, Space, Table, Tag } from 'antd'

import style from './userList.module.scss'
import './ant-modal.scss'
import type { ColumnsType } from 'antd/es/table'
import {
  userManagementGetUsersList,
  userManagementGetUsersListBySchool
} from 'services/userManagement/getUsers'
import {
  IUserApi,
  IUserInfo,
  IUserTableDataAnt
} from 'interfaces/user-management-interface'
import { useDispatch } from 'react-redux'
import {
  resetFormDataUser,
  setFormDataUser
} from 'redux/reducers/usersManagementSlice'

import { useNavigate } from 'react-router-dom'
import { userManagementEdithUserById } from 'services/userManagement/edithUser'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import {
  ExclamationTriangleIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import Button from 'components/buttons/Button/Button'
import useIsAdminEzetera from 'hooks/isAdminEzetera/useIsAdminEzetera'
import { getAllSchoolsList } from 'services/schoolAdminServices/schools'
import { ISchools } from 'interfaces/schools-interface'
import useIdSchoolUser from 'hooks/idSchoolUser/useIdSchoolUser'
import useIsAdmin from 'hooks/isAdminEzetera/useIsAdmin'
import { getLogs } from 'services/genericServices/genericServices'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const UserListModule = () => {
  const isAdmin = useIsAdminEzetera()
  const isNormalAdmin = useIsAdmin()
  const thisUserIdShcool = useIdSchoolUser()
  const [usersData, setUsersData] = useState<null | IUserTableDataAnt[]>(null)
  const [usersDataCopy, setUsersDataCopy] = useState<
    null | IUserTableDataAnt[]
  >(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userData, setUserData] = useState<IUserApi>()
  const [SchoolsList, setSchoolsList] = useState<ISchools[]>([])
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loadingDownload, setloadingDowload] = useState(false)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  let disableCreate = false
  if (isAdmin) disableCreate = true
  else if (isNormalAdmin) disableCreate = true

  const DataUser = (dat: IUserApi) => {
    dispatch(setFormDataUser({ ...dat }))
    navigate('/users-management/form-user')
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const changeEnabledUser = (dat: IUserApi) => {
    if (dat.is_enabled) {
      const getOnlyIds = (permissionsResponsabilitiesAdmin: any) => {
        if (
          permissionsResponsabilitiesAdmin !== null &&
          permissionsResponsabilitiesAdmin.length !== 0
        ) {
          let data: any = []
          permissionsResponsabilitiesAdmin.forEach((scho: any) => {
            const arrPermisions: any = []
            scho.permissions.forEach((items: any) => {
              if (items.check) arrPermisions.push(items.id)
            })
            let arrRespon: any = []
            scho.responsabilities.forEach((items: any) => {
              items.grades.forEach((grads: any) => {
                if (grads.check) arrRespon.push(grads.id)
              })
            })
            data.push({
              id: scho.id_school,
              permissions: arrPermisions,
              responsabilities: arrRespon
            })
          })
          return data
        }
      }

      let userInfo2: any = {
        name: dat.name,
        first_surname: dat.first_surname,
        second_surname: dat.second_surname,
        mail: dat.mail,
        role: dat.role,
        is_deleted: !dat.is_deleted,
        position: dat.position,
        description: dat.description,
        schools: getOnlyIds(dat.schools)
      }

      userManagementEdithUserById(dat.id, userInfo2 as IUserInfo)
        .then(() => {
          SuccessToast(
            `El usuario ${dat.name} fue ${
              !dat.is_deleted ? 'desabilitado' : 'habilitado'
            }.`
          )
          getApiUsers()
        })
        .catch(() =>
          ErrorToast(
            `No se pudo editar al usuario ${dat.name}, contactar con soporte.`
          )
        )
    }
  }
  const columns: ColumnsType<IUserTableDataAnt> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Apellido paterno',
      dataIndex: 'first_surname',
      key: 'first_surname',
      sorter: (a, b) => a.first_surname.length - b.first_surname.length
    },
    {
      title: 'Apellido materno',
      dataIndex: 'second_surname',
      key: 'secondLastNAme',
      sorter: (a, b) => a.second_surname.length - b.second_surname.length
    },
    {
      title: 'Rol',
      key: 'role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.length - b.role.length,
      render: (_, { role }) => (
        <>
          <Tag color={'processing'} key={role}>
            {role.toUpperCase()}
          </Tag>
        </>
      )
    },
    {
      title: 'Email',
      dataIndex: 'mail',
      key: 'mail',
      sorter: (a, b) => a.mail.length - b.mail.length
    },
    {
      title: 'Habilitado',
      dataIndex: 'enabled',
      key: 'enabled',
      sorter: (a, b) =>
        a.is_deleted === b.is_deleted ? 0 : a.is_deleted ? -1 : 1,
      render: (_, { is_deleted, is_enabled }, index) => (
        <>
          {(() => {
            const handleColor = (confirmed: boolean, deleted: boolean) => {
              let value = ''
              if (confirmed) {
                if (!deleted) value = 'success'
                else value = 'error'
              } else value = 'warning'
              return value
            }
            const getState = (confirmed: boolean, deleted: boolean) => {
              let value = ''
              if (confirmed) {
                if (!deleted) value = 'Habilitado'
                else value = 'Desabilitado'
              } else value = 'Sin Confirmar'
              return value
            }
            return (
              <Tag color={handleColor(is_enabled, is_deleted)} key={index}>
                {getState(is_enabled, is_deleted)}
              </Tag>
            )
          })()}
        </>
      )
    },
    {
      title: '',
      key: 'action',
      render: (
        _,
        {
          is_enabled,
          is_deleted,
          name,
          first_surname,
          second_surname,
          mail,
          description,
          position,
          key,
          role,
          id_school,
          sales_manager,
          responsabilities,
          permissions,
          schools
        }
      ) => (
        <div className={`${style['div-icons']}`}>
          {(() => {
            const sendData = () => {
              return {
                id: key,
                is_enabled,
                is_deleted,
                role,
                name,
                first_surname,
                second_surname,
                mail,
                id_school,
                description,
                position,
                sales_manager,
                responsabilities,
                permissions,
                schools
              }
            }

            return (
              <Fragment>
                {disableCreate && (
                  <PencilSquareIcon
                    onClick={() => DataUser(sendData())}
                    className={`${style['size-icons']} ${style['edith-color']}`}
                  />
                )}
              </Fragment>
            )
          })()}

          <Fragment>
            {(() => {
              const sendData = () => {
                return {
                  id: key,
                  is_deleted,
                  is_enabled,
                  role,
                  name,
                  first_surname,
                  second_surname,
                  mail,
                  id_school,
                  description,
                  position,
                  sales_manager,
                  responsabilities,
                  permissions,
                  schools
                }
              }

              return (
                <Fragment>
                  {disableCreate && (
                    <TrashIcon
                      onClick={() => {
                        setUserData(sendData())
                        showModal()
                      }}
                      className={`${style['size-icons']} ${style['trash-color']}`}
                    />
                  )}
                </Fragment>
              )
            })()}
          </Fragment>
        </div>
      )
    }
  ]

  const changeData = (dat: IUserApi[]) => {
    let user: IUserTableDataAnt[] = []
    dat.forEach((item) => {
      user.push({ ...item, key: item.id })
    })
    return user
  }
  const getSchoolListById = (idSchool: number) =>
    userManagementGetUsersListBySchool(idSchool)
      .then((users) => {
        setUsersData(changeData(users.data.users))
        setUsersDataCopy(changeData(users.data.users))
      })
      .catch((e) => console.log(e))
  const getApiUsers = () => {
    if (isAdmin) {
      const getLabelSchools = (data: ISchools[]) => {
        let arr: ISchools[] = []
        data.forEach((item: ISchools) => {
          arr.push({ ...item, label: item.name })
        })
        setSchoolsList(arr)
      }
      userManagementGetUsersList().then((users) => {
        setUsersData(changeData(users.data.users))
        setUsersDataCopy(changeData(users.data.users))
      })
      getAllSchoolsList().then((dat) => getLabelSchools(dat.data.schools))
    } else getSchoolListById(thisUserIdShcool)
  }
  const onSchoolChange = (value: string) => {
    if (value !== undefined) {
      const selected = value.split('$#-#$')
      getSchoolListById(parseInt(selected[1]))
    } else {
      getApiUsers()
    }
  }
  const getSearch = () => {
    let list: any[] = []
    usersData?.forEach((item) => {
      const { name, first_surname, second_surname, mail } = item
      const word =
        `${name} ${first_surname} ${second_surname} ${mail}`.toUpperCase()
      if (word.includes(search.toUpperCase())) list.push(item)
    })
    setUsersDataCopy(list)
  }
  useEffect(() => {
    getApiUsers()
  }, [])
  useEffect(() => {
    getSearch()
  }, [search])

  const donwloadLogs = async () => {
    setloadingDowload(true)
    try {
      const res: any = await getLogs('user')
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (error) {
      ErrorToast('No hay datos')
    }
    setloadingDowload(false)
  }

  const buttonsTitleEzetera = [
    {
      label: 'Crear nuevo usuario',
      onClick: () => {
        dispatch(resetFormDataUser())
        navigate('/users-management/form-user')
      },
      rounded: true,
      size: 'm',
      sector: 'secondary'
    },
    {
      label: 'Descargar Logs',
      onClick: () => donwloadLogs(),
      background: 'white',
      rounded: true,
      size: 'm',
      sector: 'tertiary',
      isLoading: loadingDownload
      /* disabled:true */
    }
  ]

  return (
    <MainTemplate>
      <TitleHeader
        IconName='UserIcons'
        widthIcon={'2rem'}
        heightIcon={'2rem'}
        title='Roles y permisos'
        disabled={!disableCreate}
        buttonProps={
          uid.role === 'Ezetera'
            ? buttonsTitleEzetera
            : [
                {
                  label: 'Crear nuevo usuario',
                  onClick: () => {
                    dispatch(resetFormDataUser())
                    navigate('/users-management/form-user')
                  },
                  rounded: true,
                  size: 'm',
                  sector: 'secondary'
                }
              ]
        }
      />
      {isAdmin && (
        <Space.Compact>
          <Select
            showSearch
            style={{ minWidth: 200 }}
            placeholder='Selecciona una escuela'
            optionFilterProp='children'
            size='large'
            allowClear
            onChange={onSchoolChange}
            filterOption={(input: any, option: any) => {
              return (
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >
                0
              )
            }}
          >
            {SchoolsList?.map((item: any, index: number) => (
              <Select.Option
                key={`${item.name + index}`}
                value={`${item.name}$#-#$${item.id}`}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <Input.Search
            placeholder='buscar por nombre, apellido, correo...'
            onSearch={() => getSearch()}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            style={{ width: 'auto' }}
            size='large'
          />
        </Space.Compact>
      )}
      <Skeleton loading={usersDataCopy === null} active>
        {usersDataCopy !== null && (
          <Table
            className={style.booksTable}
            showSorterTooltip={{ title: 'Clic para ordenar' }}
            dataSource={usersDataCopy}
            style={{ marginTop: '2.5rem' }}
            columns={columns}
            rowKey={(record) => record.id}
            loading={false}
            onRow={(_, __) => {
              return {
                onClick: () => undefined
              }
            }}
          />
        )}
      </Skeleton>
      <Modal
        title='Eliminar usuario'
        className='modal-in-upload-img-uniforms'
        centered
        maskStyle={{ background: '#FFFFFF99' }}
        open={isModalOpen}
        width={572}
        onCancel={handleCancel}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() => {
                changeEnabledUser(userData as IUserApi)
                handleOk()
              }}
              rounded
              label='Eliminar'
              sector='secondary'
            />
          </div>
        ]}
      >
        <div className={`${style['div-warning']}`}>
          <div className={`${style['div-center-warning']}`}>
            <ExclamationTriangleIcon
              className={`${style['exclamation-triangle']}`}
            />
          </div>
          <p className={`${style['label-modal']}`}>
            Nota: el usuario solo se esta cambiando de un estado habilitado a
            desabilitado y viceverza en lo que nos indica la accion que
            realizara por parte del cliente. Al mismo tiempo las
            responsabilidades y permisos del usuario (en caso de ser diferente a
            administrador) se setearan en blanco quedando sin permisos o
            responsabilidades por lo cual se deberan agregar nuevamente en caso
            de habilitarlo de nuevo.
          </p>
        </div>
      </Modal>
    </MainTemplate>
  )
}

export default UserListModule
