import { TFormShortDeportivoOptions } from 'interfaces/uniforms-interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleShortDeportivaForm } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import ConstructorFormulary from './ConstructorFormulary'

const ShortDeportivoOptions = () => {
  const { selectedMenu, shortDeportivaForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormShortDeportivoOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleShortDeportivaForm({
        ...shortDeportivaForm,
        [keyName]: {
          ...shortDeportivaForm[keyName as TFormShortDeportivoOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        switch (selectedMenu) {
          case 'short deportivo':
            return (
              <ConstructorFormulary
                handleChange={handleChange}
                form={shortDeportivaForm}
              />
            )
          default:
            break
        }
      })()}
    </div>
  )
}

export default ShortDeportivoOptions
