import { memo, useEffect, useState, Fragment } from 'react'
import {
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd'
import bookStyle from '../../../modules/books/books.module.scss'
import style from './../../Utils/AdminUtilsManagement/AdminUtilsManagement.module.scss'
import styles from './../../Utils/AdminUtilsCatalog/utils.module.scss'
import utilsStyles from './../../Utils/AdminUtilsManagement/AdminUtilsManagement.module.scss'
import globalStyle from '../../../styles/global.module.scss'
import Button from 'components/buttons/Button/Button'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { useLocation, useParams } from 'react-router-dom'
import BackButton from 'components/buttons/BackButton/BackButton'
import { useRequireUtilsForm } from 'hooks/utils/useRequireUtilsForm'
import UploadInput from 'components/inputs/UploadInput'
import FormInput from 'components/formInputs/input'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import Collapse from 'components/Collapse/Collapse'
import B from 'components/texts/B/B'
import FormRadio from 'components/formInputs/radio'
import FormSelect from 'components/formInputs/select'
import { useManagementNotebooks } from 'hooks/notebooks/useManagementNotebooks'
import Modals from 'components/modal'
import ErrorAlert from 'assets/img/errorAlert.svg'
import { Options } from 'types'
import { PRESENTATION_TYPE } from 'modules/Utils/AddUtilsForm/hook/useAddUtilsForm'
import NotebookEdthParamsModal from '../notebooksList/components/Modals/NotebookEdthParamsModal'
import { useDispatch, useSelector } from 'react-redux'
import { handleOptionsOutside } from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import { getNotebookPrice } from 'services/notebooks/getNotebooksPrice'
import { getNotebookListBySchoolId } from 'services/notebooks/getListOfNotebooks'
import { INotebook } from 'services/notebooks/getNotebookByIdUuid'
import { UtilsInterface } from 'services/utilsServices/utils'

const colStyle = { cursor: 'pointer', padding: '0 20px' }

const AdminNotebooksManagement = memo(() => {
  const { edithOutside4specs } = useSelector(
    (state: RootState) => state.notebooks
  )

  const schoolId = window.location?.search.split('=')[1]

  const {
    params,
    schoolsCatalog,
    statusCatalog,
    utilsList,
    LoadingList,
    pagination,
    utilsColumns,
    tabs,
    selectedLevel,
    packages,
    loadingSupplies,
    sidebarFormFilled,
    selectStatus,
    packagesMap,
    selectedPackage,
    loadingDownload,
    openDeleteModal,
    deletingItem,
    statusNumberMap,
    showRequestChangesModal,
    requestingChanges,
    showUpdatePriceModal,
    navigate,
    setParams,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onRowClicked,
    onChangeSelectedTab,
    onCollapseChange,
    updateSupplies,
    getUtilsList,
    setSelectedPackage,
    downloadClicked,
    setOpenDeleteModal,
    handleDeleteItem,
    setShowRequestChangesModal,
    updateRequestChanges,
    setShowUpdatePriceModal,
    handleNotebooksPriceUpdate,
    donwloadLogs,
    levels,
    onCollapseRecharge,
    selectSchool
  } = useManagementNotebooks()

  const {
    schoolData,
    INPUTS_INITIAL_STATE,
    form,
    optionsFee,
    handleFileChange,
    onSubmit,
    isStatusGT4,
    isStatusET4,
    isInReview
  } = useRequireUtilsForm()

  const dispatch = useDispatch()

  const location = useLocation()
  const { Option } = Select
  const [priceList, setpriceList] = useState(0)
  const [restarTab, setRestarTab] = useState(false)
  const [reAcordeon, setReAcordeon] = useState(0)
  const [tableMode, setTableMode] = useState(false)
  const [pricenotebook, setPricenotebook] = useState(0)

  const [notebooksByIdSchool, setNotebooksByIdSchool] = useState<
    null | INotebook[] | UtilsInterface[]
  >(null)

  useEffect(() => {
    if (restarTab) setRestarTab(false)
  }, [restarTab])

  const closeModalOptions = () => {
    dispatch(
      handleOptionsOutside({
        ...edithOutside4specs,
        showModal: false
      })
    )
    //setPackageForm({ name: '', quanty: 1 })
  }

  const renderCorrectInput2 = (
    key: number,
    objInput: any,
    disable: boolean
  ) => {
    if (objInput.type === 'select')
      return (
        <FormSelect
          name={objInput.name}
          options={PRESENTATION_TYPE}
          placeholder={objInput.placeholder}
          label={objInput.label}
          rules={objInput.rules}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'file')
      return (
        <UploadInput
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleFileChange}
          disabled={disable}
        />
      )
    return (
      <FormInput
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        rules={objInput.rules}
        disabled={disable}
      />
    )
  }

  const getNotebooksBySchoolId = (id: number) => {
    getNotebookListBySchoolId(id)
      .then((d) => {
        setNotebooksByIdSchool(d.data.data.notebook)
      })
      .catch((e) => {
        console.log(e)
        setNotebooksByIdSchool(null)
      })
  }

  const getPrice = () => {
    getNotebookPrice()
      .then((data) => {
        setpriceList(parseFloat(data.data.price_notebook))
        setPricenotebook(parseFloat(data.data.price_notebook))
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getPrice()
  }, [])

  useEffect(() => {
    if (reAcordeon !== 0) {
      onCollapseRecharge(reAcordeon)
      setReAcordeon(0)
    }
  }, [reAcordeon])

  useEffect(() => {
    if (levels.length !== 0) {
      onChangeSelectedTab(levels[0].id)
      setRestarTab(true)
    }
  }, [levels])

  useEffect(() => {
    if (schoolId !== undefined) {
      getNotebooksBySchoolId(parseInt(schoolId))
    } else {
      setNotebooksByIdSchool(null)
    }
  }, [selectSchool])

  useEffect(() => {
    if (notebooksByIdSchool?.length === 0) setTableMode(false)
  }, [notebooksByIdSchool])

  const positive = (val: number) => {
    if (val > 0) setPricenotebook(val)
  }

  return (
    <>
      {params.has('action') &&
      (params.get('action') == 'nuevo' || params.get('action') == 'editar') ? (
        <div className={style.AdminUtilsManagement}>
          <BackButton
            onClick={() => {
              navigate(-1)
            }}
            textDecoration='underline'
          />
          <TitleHeader IconName='utilsIcon' title={location.state?.name} />
          <Form
            onFinish={(values) =>
              onSubmit(
                location?.state?.id || location?.state?.id_supply,
                values
              )
            }
            layout='vertical'
            autoComplete='off'
            initialValues={schoolData}
            className={style.CreateSchoolForm}
            form={form}
            disabled={isStatusGT4 || isInReview}
          >
            <Row gutter={[16, 16]}>
              {Object.keys(schoolData).map((res: any, key) => {
                const objKey = res as keyof typeof INPUTS_INITIAL_STATE
                const objInput = schoolData[objKey]
                const disableInput =
                  (isStatusET4 && objKey != 'quantity') ||
                  isStatusGT4 ||
                  isInReview
                return (
                  <Col span={12}>
                    {renderCorrectInput2(key, objInput, disableInput)}
                  </Col>
                )
              })}
              <Col span={6}>
                <FormRadio
                  name='provider_fee'
                  label='Cuota de proveedor'
                  options={optionsFee}
                  initialValue={location?.state['provider_free']}
                  optionType='button'
                  disabled={isStatusET4 || isInReview}
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Col>
              <Col span={6}>
                <FormRadio
                  name='resource_free'
                  label='Recurso gratuito'
                  options={optionsFee}
                  initialValue={location?.state['resource_free']}
                  optionType='button'
                  disabled={isStatusET4 || isInReview}
                  buttonStyle='solid'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción'
                    }
                  ]}
                />
              </Col>
            </Row>
            <Button
              size='m'
              rounded={true}
              sector='primary'
              onClick={() => {
                form.submit()
              }}
              disabled={isStatusGT4 || isInReview}
            >
              Actualizar
            </Button>
          </Form>
        </div>
      ) : (
        <div className={style.AdminUtilsManagement}>
          <CommentsComponent
            innerFormFilled={sidebarFormFilled}
            afterBookStatusUpdated={() => {
              if (params.has('school')) {
                updateSupplies(selectedPackage)
              } else {
                const page = parseInt(params.get('page') || '1')
                const search = params.get('search') || ''
                const status = params.get('status')
                const controller = new AbortController()
                getUtilsList(
                  page,
                  controller,
                  search,
                  status ? parseInt(status) : undefined
                )
              }
            }}
          />
          <TitleHeader
            IconName='utilsIcon'
            title={`Cuadernos`}
            additional={`Precio de lista: ${priceList} MXN`}
          />
          <div className={bookStyle.filters}>
            <div>
              <Typography style={{ whiteSpace: 'nowrap' }}>Colegios</Typography>
              {/* <Select
                  value={selectSchool}
                  style={{ width: 200 }}
                  defaultValue={'todos'}
                  placeholder={'Colegios'}
                  onChange={onHandleChangeSchool}
                  options={schoolsCatalog}
                /> */}
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Colegios'
                optionFilterProp='children'
                onChange={onHandleChangeSchool}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                {schoolsCatalog?.map((school: Options) => (
                  <Option key={school?.value as number} value={school?.value}>
                    {school?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Typography style={{ whiteSpace: 'nowrap' }}>Estatus</Typography>
              <Select
                value={
                  selectStatus == 'todos'
                    ? selectStatus
                    : statusNumberMap[selectStatus]
                }
                style={{ width: 180 }}
                defaultValue={'todos'}
                placeholder={'Estatus'}
                onChange={onHandleChangeStatus}
                options={statusCatalog.filter(
                  (item: any) => item.label !== 'Nuevo'
                )}
              />
            </div>
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar'
              onClick={downloadClicked}
              isLoading={loadingDownload}
            />
            <Button
              sector='tertiary'
              rounded
              size='m'
              label='Actualizar precio'
              onClick={() => setShowUpdatePriceModal(true)}
            />
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar Logs'
              onClick={donwloadLogs}
              isLoading={loadingDownload}
            />
          </div>
          <Input.Search
            placeholder='Buscar'
            enterButton
            size='large'
            allowClear={true}
            onSearch={(e: string) => {
              setParams({
                ...(e ? { search: e } : {})
              })
            }}
          />
          <>
            <div className={`${style['button-margin']}`}>
              {schoolId !== undefined && (
                <Button
                  label={`Cambiar a modo ${tableMode ? 'acordeon' : 'tabla'}`}
                  rounded
                  onClick={() => setTableMode(!tableMode)}
                />
              )}
            </div>
            {tableMode ? (
              <div className={styles['table-container']}>
                <Table
                  dataSource={notebooksByIdSchool as UtilsInterface[]}
                  loading={false}
                  columns={utilsColumns}
                  className={styles.utilstable}
                  scroll={{ x: 800 }}
                  rowKey={'id'}
                  onRow={(record: any, _: any) => ({
                    onClick: (_: any) => {
                      onRowClicked(record)
                    }
                  })}
                />
              </div>
            ) : (
              <Fragment>
                {params.has('school') ? (
                  <div>
                    {!restarTab && (
                      <Tabs items={tabs} onChange={onChangeSelectedTab} />
                    )}
                    <div>
                      {selectedLevel?.grades?.map((res: any) => {
                        return (
                          <div
                            key={`grade-${res.id}`}
                            className={`${style['']}`}
                          >
                            <Collapse id={res.id} title={res.name}>
                              <List.Item
                                style={{
                                  padding: ' 10px 0',
                                  background: '#F3F7FF80'
                                }}
                              >
                                <Col
                                  className='ListTitles'
                                  style={colStyle}
                                  span={8}
                                >
                                  <B size='Light'>Nombre del paquete</B>
                                </Col>
                              </List.Item>
                              {(() => {
                                const items = packages?.filter(
                                  (it: any, ix: number) => res.id == it.id_grade
                                )
                                return (
                                  <>
                                    {items?.map((pck: any) => (
                                      <div key={`pck-div-${pck.id}`}>
                                        <Collapse
                                          id={pck.id}
                                          title={pck.name}
                                          customBg={
                                            utilsStyles['custom-collapse-bg']
                                          }
                                          isDroped={(flag: boolean) => {
                                            onCollapseChange(flag, pck.id)
                                          }}
                                          customSideElement={
                                            <div style={{ width: '300px' }}>
                                              {pck.request_changes && (
                                                <Button
                                                  rounded
                                                  sector='secondary'
                                                  label='Cambios Solicitados'
                                                  onClick={(e: Event) => {
                                                    e?.stopPropagation()
                                                    setSelectedPackage(pck?.id)
                                                    setShowRequestChangesModal(
                                                      true
                                                    )
                                                  }}
                                                />
                                              )}
                                            </div>
                                          }
                                        >
                                          <Table
                                            dataSource={
                                              selectStatus &&
                                              selectStatus != 'todos'
                                                ? packagesMap[pck.id]?.filter(
                                                    (it: any) =>
                                                      it.status == selectStatus
                                                  )
                                                : packagesMap[pck.id]
                                            }
                                            loading={loadingSupplies}
                                            columns={utilsColumns}
                                            className={styles.utilstable}
                                            scroll={{ x: 800 }}
                                            rowKey={'id'}
                                            pagination={(() => {
                                              let filtered = packagesMap[pck.id]

                                              if (
                                                selectStatus &&
                                                selectStatus != 'todos'
                                              ) {
                                                filtered = packagesMap[
                                                  pck.id
                                                ]?.filter(
                                                  (it: any) =>
                                                    it.status == selectStatus
                                                )
                                              }

                                              return {
                                                total: filtered?.length,
                                                pageSize: 10,
                                                showSizeChanger: false
                                              }
                                            })()}
                                            onRow={(record: any, _: any) => ({
                                              onClick: (_: any) => {
                                                setSelectedPackage(pck?.id)
                                                onRowClicked(record)
                                              }
                                            })}
                                          ></Table>
                                        </Collapse>
                                      </div>
                                    ))}
                                  </>
                                )
                              })()}
                            </Collapse>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles['table-container']}>
                      <Table
                        dataSource={utilsList}
                        loading={LoadingList}
                        columns={utilsColumns}
                        className={styles.utilstable}
                        scroll={{ x: 800 }}
                        rowKey={'id'}
                        onRow={(record: any, _: any) => ({
                          onClick: (_: any) => {
                            onRowClicked(record)
                          }
                        })}
                        pagination={{
                          showSizeChanger: false,
                          total: pagination?.total,
                          pageSize: pagination?.limit,
                          onChange: (page: number, pageSize: number) => {
                            onHandleChangePage(page)
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </>
          <NotebookEdthParamsModal
            show={edithOutside4specs.showModal}
            onOk={() => {
              closeModalOptions()
              getUtilsList()
              setReAcordeon(edithOutside4specs.id_package)
            }}
            onCancel={closeModalOptions}
          />
          <Modal
            title='Solicitud de cambio'
            open={showRequestChangesModal}
            onCancel={() => {
              setShowRequestChangesModal(false)
              setSelectedPackage(-1)
            }}
            className={globalStyle.confirmModal}
            footer={<></>}
          >
            <div className={globalStyle.confirmModalBody}>
              <img src={ErrorAlert} alt='lista alerta' />
              <B size='Light'>
                ¿Estás seguro que deseas permitir esta la modificación del
                paquete?, Al hacer esto, el estatus del paquete pasará a "En
                Construcción" y el usuario podra hacer cambios sobre el mismo
              </B>
            </div>
            <br></br>

            <div className={globalStyle.confirmModalFooter}>
              <Button
                rounded
                onClick={() => {
                  updateRequestChanges({ open: false, request_changes: false })
                }}
                label={'No'}
                sector='secondary'
                isLoading={requestingChanges}
              />
              <Button
                rounded
                onClick={() => {
                  updateRequestChanges({ open: true, request_changes: false })
                }}
                label={'Si'}
                isLoading={requestingChanges}
              />
            </div>
          </Modal>
          <Modals
            title='Eliminar registro'
            open={openDeleteModal}
            onCancel={() => setOpenDeleteModal(false)}
          >
            <>
              <span>¿Estás seguro de eliminar el registro?</span>
              <div>
                <Button
                  isLoading={deletingItem}
                  label={'Eliminar'}
                  rounded
                  sector='primary'
                  onClick={() => handleDeleteItem()}
                />
              </div>
            </>
          </Modals>
          <Modals
            title='Actualizar precio de los cuadernos'
            open={showUpdatePriceModal}
            onCancel={() => setShowUpdatePriceModal(false)}
          >
            <div>
              <Form form={form} layout='vertical'>
                <input
                  className={`${style['input-notebook']}`}
                  value={pricenotebook}
                  placeholder='Precio de los cuadernos'
                  onChange={(e) => positive(parseFloat(e.target.value))}
                  type='number'
                />
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Button
                    isLoading={deletingItem}
                    label={'Actualizar'}
                    rounded
                    onClick={() => {
                      handleNotebooksPriceUpdate(pricenotebook, getPrice)
                    }}
                  />
                </div>
              </Form>
            </div>
          </Modals>
        </div>
      )}
    </>
  )
})

AdminNotebooksManagement.displayName = 'AdminUtilsManagement'
export default AdminNotebooksManagement
