import { AUTHSERVICE } from '../config'
interface IResults {
  status: number
  statusText: string
  data: {
    success: boolean
    images: {
      img: string
    }[]
  }
}

const getBucket = async (id: number): Promise<any> => {
  return await AUTHSERVICE().get(`/notebook/upload-file/${id}`)
}

const getImgsBucket = async (id: number) => {
  const results = await getBucket(id)
  return results as IResults
}

export { getImgsBucket }
