import React from 'react'

const HoodieTraseraSvg= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_hoodie" data-name="Outline hoodie"   d="m546.14,299.53s4.58,24.5,1.55,42.52c-3.82,22.63-7.88,94.16-7.88,94.16,0,0-9.68,66.28-3.1,92.56,9,35.93,13.97,126.01,13.97,126.01,0,0-114.18.76-151.74,1.27-35.61.47-144.2,2.33-144.2,2.33l-8.54.65s-2.21-78.94,10.52-134.53c5.7-24.91-5.1-83.26-5.1-83.26,0,0-5.93-27.04,13.09-65.22,12.17-24.41,24.73-48.98,24.73-48.98,0,0,15.31-28.69,20.83-42.8,6.87-17.58,12.83-39.48,12.83-39.48,0,0,16.4,8.79,73.05,8.05,65-.84,68.38-18.52,68.38-18.52,0,0,22.54,17.84,67.72,44.47,12.19,7.18,13.91,20.76,13.91,20.76Z"/>
      <path id="Cintura"   d="m253.64,704.92s111.19,5.32,149.25,5.01c35.36-.29,142.31-7.8,142.31-7.8,0,0,2-16.7,2.07-22.26.08-6.04-1.09-25.04-1.09-25.04,0,0-106.91.87-143.52,1.18-37.19.3-152.11,3.05-152.11,3.05,0,0-1.17,16.19-1.06,21.96.11,5.82,4.16,23.92,4.16,23.92Z"/>
      <g id="Manga_derecha" data-name="Manga derecha">
        <path id="Piño_derecha" data-name="Piño derecha"   d="m594.02,696.54l3.13,45.92s11.52,1.33,24.02.92c9.6-.32,22.79-2.26,22.79-2.26l2.45-45.75s-13.65-5.44-24.63-4.66c-13.43.96-27.76,5.83-27.76,5.83Z"/>
        <path id="Manga_derecha-2" data-name="Manga derecha"   d="m469.08,236.27c2.94,1.11,106.85,28.09,133.42,124.42,9.64,34.95,21.45,58.35,27.37,80.8,16.43,62.38,21.97,99.34,21.97,99.34,0,0,.8,50.5,1.89,115.9.29,17.02-2.55,39.86-2.55,39.86,0,0-22.24-5.96-29.39-5.88-8.21.08-32.07,6.46-32.07,6.46,0,0-3.09-16.15-2.16-31.42,1.33-21.94.31-18.83-.43-33.33-1.66-32.82-4.16-69.56-7.56-88.08-11.31-61.68-38.05-105.49-38.05-105.49,0,0-16.06-69.78-27.22-89.13-46.76-81.07-45.21-113.44-45.21-113.44Z"/>
      </g>
      <g id="Manga_izquierda" data-name="Manga izquierda">
        <path id="Puño_izquierdo" data-name="Puño izquierdo"   d="m154.91,701.49l3.25,44.05s18.39,3.33,24.26,3.25c5.98-.07,22.15-3.93,22.15-3.93l2.19-44.1s-21.88-4.41-28.06-4.29c-5.77.1-23.79,5.02-23.79,5.02Z"/>
        <path id="Manga_izquierda-2" data-name="Manga izquierda"   d="m322.58,238.91c-3.48,2.69-106.24,30.1-130.78,126.91-8.91,35.12-20.22,58.74-25.66,81.3-15.11,62.68-19.88,99.73-19.88,99.73,0,0,.27,50.51.55,115.93.07,17.01,3.39,39.79,3.39,39.79,0,0,22.12-6.37,29.27-6.44,8.21-.08,32.18,5.86,32.18,5.86,0,0,2.76-16.2,1.51-31.46-1.8-21.9-.71-18.81-.27-33.33.97-32.83,2.7-69.62,5.7-88.21,10.02-61.88,35.83-106.18,35.83-106.18,0,0,18.83-56.61,27.9-77,45.82-103.03,40.27-126.89,40.27-126.89Z"/>
      </g>
      <g>
        <path id="Gorro"   d="m317.65,240.91s12.44,20.61,59.18,21.7c6.97.16,13.13.19,18.56.13,26.23-.3,35.29-2.75,35.29-2.75,0,0,22.73-2.7,38.39-23.72,3.42-4.6-10.29-3.21,5.97-28.63,5.15-8.06,8.65-27.31,4.15-58.2-4.5-30.9-28.12-76.72-28.12-76.72,0,0-8.43-23.97-70.29-21.31-61.86,2.66-60.51,68.73-62.2,73.52-1.69,4.8-17.32,55-5.29,76.26,17.01,30.08,4.36,39.72,4.36,39.72Z"/>
        <path   d="m390.27,51.22s.27,48.24.27,93.43-1.92,79.36,4.48,89.65c6.39,10.3,4.09,7.31.38,10.03-3.71,2.72,0,18.41,0,18.41"/>
      </g>
      <circle id="Escudo"   cx="397.07" cy="388.67" r="62.78"/>
    </g>
  </g>
</svg>
)
}

export default HoodieTraseraSvg