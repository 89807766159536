import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './poloeditable.module.scss'
import HoodieTraseraSvg from 'assets/img/uniforms/hoodie/trasera/HoodieTraseraSvg'
import ShadowsHoodieTrasera from 'assets/img/uniforms/hoodie/trasera/ShadowsHoodieTrasera'

const HoodieTraseraEditable = () => {
  const { hoodieForm, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {hoodieForm.logoPosition.value === 'espalda' && (
            <div
              className={`${
                style[`logo-position-hoodie-${hoodieForm.logoPosition.value}`]
              }`}
            >
              {pictureLogo !== '' && (
                <img className={`${style['svg-logo']}`} src={pictureLogo} />
              )}
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <HoodieTraseraSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsHoodieTrasera className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      </div>
    </div>
  )
}

export default HoodieTraseraEditable
