import { Col, Form, Modal, Row } from 'antd'
import Button from 'components/buttons/Button/Button'
import Input from 'components/inputs/Input'
import Date from 'components/inputs/Date'
import Select from 'components/inputs/Select'
import S from 'components/texts/S/S'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { useUserSchoolDetails } from 'hooks/userSchool/useUserSchoolDetails'
import style from 'styles/global.module.scss'
import React, { memo, useState } from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import ErrorAlert from 'assets/img/errorAlert.svg'
import B from 'components/texts/B/B'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'

const index = memo(() => {
  const {
    onSubmit,
    handleChangeSelect,
    handleChangeDate,
    handleChange,
    schoolData,
    INPUTS_INITIAL_STATE,
    openDeleteModal,
    setOpenDeleteModal,
    loadingPost
  } = useUserSchoolDetails()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const SCHOOL_TYPES = [
    {
      value: 'u',
      label: 'Mixto'
    },
    {
      value: 'm',
      label: 'Masculino'
    },
    {
      value: 'f',
      label: 'Femenino'
    }
  ]
  const renderCorrectInput = (key: number, objInput: any) => {
    if (objInput.type === 'date')
      return (
        <Date
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleChangeDate}
          disabled={objInput.disabled}
        />
      )
    if (objInput.type === 'select')
      return (
        <Select
          key={key}
          name={objInput.name}
          label={objInput.label}
          placeholder={objInput.placeholder}
          value={objInput.value}
          onChange={handleChangeSelect}
          options={SCHOOL_TYPES}
          disabled={objInput.disabled}
        />
      )
    return (
      <Input
        key={key}
        name={objInput.name}
        placeholder={objInput.placeholder}
        label={objInput.label}
        type={objInput.type}
        value={objInput.value}
        onChange={handleChange}
        disabled={objInput.disabled}
      />
    )
  }

  return (
    <MainTemplate>
      <TitleHeader IconName='schoolIcon' title={'Mi colegio'} />
      <Row justify='space-between'>
        <Col style={{ color: '#7FA5EA' }}>
          <S>Información</S>
        </Col>
        <Col>
          {uid.role !== 'Director' && uid.role !== 'Responsable de listas' && (
            <Button
              size='m'
              rounded
              background='white'
              label='Guardar Cambios'
              onClick={() => setOpenDeleteModal(true)}
            />
          )}
        </Col>
      </Row>
      <Form
        onFinish={() => setOpenDeleteModal(true)}
        layout='vertical'
        autoComplete='off'
        /*  className={style.CreateSchoolForm} */
      >
        <Row gutter={[16, 16]}>
          {Object.keys(schoolData).map((res: any, key) => {
            const objKey = res as keyof typeof INPUTS_INITIAL_STATE
            const objInput = schoolData[objKey]
            return <Col span={12}>{renderCorrectInput(key, objInput)}</Col>
          })}
        </Row>
      </Form>

      <Modal
        title={`Cambiar fecha de apertura y precio de cuadernos`}
        centered
        className={style.confirmModal}
        open={openDeleteModal}
        footer={
          <div className={style.confirmModalFooter}>
            <Button
              rounded
              sector='secondary'
              isLoading={loadingPost}
              onClick={onSubmit}
              label='Actualizar'
            />
          </div>
        }
        onCancel={(_) => setOpenDeleteModal(false)}
      >
        <div className={style.confirmModalBody}>
          <img src={ErrorAlert} alt='descargar' />
          <B size='Light'>
            ¿ Realmente desea actualizar estos campos ?
          </B>
        </div>
      </Modal>
    </MainTemplate>
  )
})
index.displayName = 'SchoolDetails'
export default index
