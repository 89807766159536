export default function TranslateDateToSpanish(fecha:string) {
    const mesesEnIngles = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const mesesEnEspañol = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
     const partesFecha = fecha.split(' ');
    const dia = partesFecha[0];
    const mesEnIngles = partesFecha[1];
    const año = partesFecha[2];
     const indiceMes = mesesEnIngles.indexOf(mesEnIngles);
    const mesEnEspañol = mesesEnEspañol[indiceMes];
     const fechaTraducida = `${dia} ${mesEnEspañol} ${año}`;
    return fechaTraducida;
  }
