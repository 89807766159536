import React from 'react'

const LabelsTShirtsFemale = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral" stroke='black'>
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path stroke='#f5e7ca' fill='#f5e7ca' d="m616.34,663.55c9.49-2.56,18.99-5.07,28.49-7.59l-7.67-28.75-.57.15c-10.33,2.76-20.66,5.54-31,8.26l6.95,19.24,3.8,8.69Z"/>
      <path fill='green' stroke='green'  d="m618.99,655.77c.49-.43.95-.89,1.47-1.27,1.6-1.2,2.39-2.99,3.2-4.76.47-1.08.24-1.9-.78-2.61-3.18-2.19-6.71-2.69-10.34-2.75-.13-.01-.25-.01-.38-.08,1.41-.44,2.86-.04,4.28-.1.42-.02,1.01.29,1.19-.25.16-.48-.4-.78-.7-1.11-.49-.52-1.02-1-1.46-1.61,2.48,2.07,5.25,3.28,8.33,3.85,1.35.24,1.84-.2,2.05-1.49.36-2.05.72-4.08-.38-6.11,1.99,2.07,4.31,3.87,4.04,7.33.69-.18,1.27-.33,1.86-.5.6-.18.92.26,1.2.67.46.64-.3.58-.48.83-1.05,1.39-2.66,2.25-3.16,4.21-.33,1.31-1.62,1.95-2.76,2.6-2.26,1.28-4.65,2.29-7.15,3.15Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelsTShirtsFemale
