import React from 'react'
import style from '../../add-user.module.scss'

const CheckOption = ({
  label,
  id,
  onHandleCheck,
  checked
}: {
  label: string

  id: number
  checked: boolean
  onHandleCheck?: () => void
}) => {
  return (
    <label className={`${style['label-option']}`}>
      <input
        onChange={(_e) => {
          onHandleCheck && onHandleCheck()
        }}
        checked={checked}
        type={'checkbox'}
        value={id}
      />
      {label}
    </label>
  )
}

export default CheckOption
