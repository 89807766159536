import React, { useState } from 'react'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { Modal } from 'antd'
import Button from 'components/buttons/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setInsiseLogo } from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import style from './selectedcardsoption.module.scss'
import { UploadNotebookInsideLogo } from 'services/notebooks/uploadImg'
import { IEdithNotebook } from 'services/notebooks/putNotebook'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
const ModalUploadNotebooks = ({
  isModalOpen,
  handleOk,
  handleCancel,
  uuid,
  saveChange,
  onlySave
}: {
  isModalOpen: boolean
  onlySave: () => void
  handleOk: () => void
  handleCancel: () => void
  uuid: number
  saveChange: (
    data: IEdithNotebook,
    showToast?: boolean | undefined,
    ejecute?: (() => void) | undefined
  ) => void
}) => {
  const { insideLogo, notebookInsideValues } = useSelector(
    (state: RootState) => state.notebooks
  )

  const dispatch = useDispatch()
  const [file, setFile] = useState<any | null>(null)
  const [disable, setDisable] = useState(false)
  const { interior_inks } = notebookInsideValues

  const onImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const files: any[] = e.target.files
      setFile(files[0])
      let reader = new FileReader()
      reader.onload = (e: any) => {
        dispatch(setInsiseLogo(e.target.result as string))
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const uploadImg = () => {
    setDisable(true)
    if (insideLogo !== '') {
      onlySave()

      let dat = new FormData()
      dat.append('logo', file, 'image.png')
      interior_inks.value === '#003da6'
        ? dat.append('color', '2')
        : dat.append('color', '1')

      UploadNotebookInsideLogo(uuid, dat)
        .then((data) => {
          saveChange({ int_logo: data.data.url }, false, () =>
            SuccessToast('Imagen subida correctamente')
          )
          setDisable(false)
          handleOk && handleOk()
        })
        .catch((e) => {
          console.log(e)
          ErrorToast(
            'No se ha podido subir la imagen, contactar con soporte o verificar el formato del archivo.'
          )
          setDisable(false)
        })
    }
  }
  return (
    <Modal
      title='Subir logotipo para cuadernos'
      className='modal-in-upload-img-uniforms'
      centered
      bodyStyle={{ background: '#F3F7FF' }}
      keyboard
      destroyOnClose
      open={isModalOpen}
      onOk={() => {
        setFile(null)
        handleOk()
      }}
      onCancel={() => {
        setFile(null)
        handleCancel()
      }}
      width={572}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={uploadImg}
            disabled={file === null && !disable}
            rounded
            label='Subir'
            isLoading={disable}
          />
        </div>
      ]}
    >
      <div className={`${style['div-container-modal']}`}>
        <p className={`${style['p-modal']}`}>
          Sube un logotipo para la parte interna de tus cuadernos, de
          preferencia una imagen con trasparencia del tipo archivo.png.
        </p>
        <div className={`${style['div-cover-white']}`}>
          {insideLogo !== '' && (
            <div className={`${style['container-notebook-logo']}`}>
              <img className={`${style['notebook-logo']}`} src={insideLogo} />
            </div>
          )}
          <p className={`${style['p-title-text']}`}>* Sube tu logotipo : </p>
          <label className={`${style['button-upload']}`}>
            <ArrowUpTrayIcon className={`${style['arrow-svg']}`} />
            Click para subir archivo
            <input
              className={`${style['input-archive-file']}`}
              type='file'
              accept='image/png'
              onChange={onImageChange}
            />
          </label>

          <p className={`${style['p-description']}`}>
            Resolución recomendada de 640 X 640 px
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploadNotebooks
