import { AUTHSERVICE } from '../config'

interface ICreateNewNotebook {
  matter: string
  id_package: number
}

interface IResults {
  status: number
  statusText: string
  data: {
    notebookId: number
    success: boolean
  }
}

const newNotebook = async (data: ICreateNewNotebook): Promise<any> => {
  return await AUTHSERVICE().post(`/notebook`, data)
}

const createNewNotebook = async (data: ICreateNewNotebook) => {
  const results = await newNotebook(data)
  return results as IResults
}

export { createNewNotebook }
