import React from 'react'
import { IconProps } from '../../../types'
export const Uniform = ({
  height = 22,
  width = 22,
  fill = 'none'
}: IconProps): JSX.Element => {
  return (
    <svg
    width={width}
    height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 7.5V17.5M17.5 17.5V21H4.5V17.5M17.5 17.5H21V7.5C21 6 20 4.25 18.5 3C17 1.75 15 1 15 1M4.5 17.5H1V7.5C1 6 2 4.25 3.5 3C5 1.75 7 1 7 1M4.5 17.5V7.5M7 1H15M7 1L11 7.5M15 1L11 7.5M11 7.5V21'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
