import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EnableFlag,
  handleNotebookForm,
  handleNotebookInsideForm,
  resetFormValues,
  setCollegeProposal,
  setEzeteraProposal,
  setInsiseLogo,
  setJsonImgBack,
  setJsonImgFront,
  setJsonTextBack,
  setJsonTextfront,
  setOutsidePropuesta,
  setOutsidePropuestaBack,
  setPositionsCovers,
  setRequireCoverDesing,
  setRequireCoverImg,
  setRequireCoverText,
  setSelectedNotebook,
  setStepNotebook
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'

import {
  getNotebookByIdUuid,
  INotebook
} from 'services/notebooks/getNotebookByIdUuid'
import { edithNotebook, IEdithNotebook } from 'services/notebooks/putNotebook'

const useNotebooks = ({ uuid }: { uuid: number }) => {
  const dispatch = useDispatch()
  const {
    notebookFrontValues,
    notebookInsideValues,
    notebookSelected,
    stepNotebook,
    ezetera_proposal,
    college_proposal,
    outsidePropuesta,
    outsidePropuestaBack,
    require_cover_desing,
    require_cover_img,
    require_cover_text,
    positionCovers,
    jsonImgBack,
    jsonImgfront,
    jsonTextBack,
    jsonTextfront
  } = useSelector((state: RootState) => state.notebooks)

  const [notebook, setNotebook] = useState<INotebook | null>(null)
  //console.log(notebook)

  const getNotebookData = () => {
    getNotebookByIdUuid(uuid)
      .then((data) => {
        setNotebook(data.data.notebookPackage)
      })
      .catch((e) => {})
  }
  const {
    binding_type,
    binding_color,
    spine_color,
    cover_finishing,
    rounded: roundedTips,
    cover_design
  } = notebookFrontValues
  const {
    number_of_sheets,
    striped,
    rounded,
    guide_point,
    logo_position,
    interior_inks,
    margin_date,
    magin_complete,
    date_type
  } = notebookInsideValues

  const saveSendDataNotebookChange = (
    data: IEdithNotebook,
    showToast?: boolean,
    ejecute?: () => void
  ) => {
    let thisNotebook: IEdithNotebook = {
      ...data,
      id: notebook!.id || 0,
      id_package: notebook!.id_package || 0
    }
    edithNotebook(thisNotebook)
      .then(() => {
        getNotebookData()
        showToast && SuccessToast('Se guardó con éxito')
        ejecute && ejecute()
      })
      .catch((e) => {
        ErrorToast('No se pudo guardar el cambio al cuaderno')
      })
  }
  const saveNotebookChange = (then?: () => void) => {
    let thisNotebook: IEdithNotebook = {
      ...getfrontValues(),
      id: notebook!.id || 0,
      id_package: notebook!.id_package || 0
    }
    edithNotebook(thisNotebook)
      .then(() => {
        getNotebookData()
        SuccessToast('Se guardó con éxito')
        then && then()
      })
      .catch((e) => {
        ErrorToast('No se pudo guardar el cambio al cuaderno')
      })
  }

  const getfrontValues = () => {
    let extValues = {}

    if (notebookSelected !== 0)
      extValues = {
        ...extValues,
        id_format: notebookSelected
      }

    extValues = {
      ...extValues,
      step: stepNotebook
    }
    extValues = {
      ...extValues,
      ezetera_proposal
    }
    extValues = {
      ...extValues,
      college_proposal
    }
    extValues = {
      ...extValues,
      front_cover_img: outsidePropuesta
    }
    extValues = {
      ...extValues,
      back_cover_img: outsidePropuestaBack
    }
    extValues = {
      ...extValues,
      require_cover_desing
    }
    extValues = {
      ...extValues,
      require_cover_img
    }
    extValues = {
      ...extValues,
      require_cover_text
    }

    extValues = {
      ...extValues,
      jsonUploadPosition: positionCovers
    }
    extValues = {
      ...extValues,
      jsonImgBack: jsonImgBack
    }
    extValues = {
      ...extValues,
      jsonImgFront: jsonImgfront
    }
    extValues = {
      ...extValues,
      jsonTextBack: jsonTextBack
    }
    extValues = {
      ...extValues,
      jsonTextFront: jsonTextfront
    }

    Object.keys(notebookFrontValues).map((res: any) => {
      const objKey = res as keyof typeof notebookFrontValues
      switch (objKey) {
        case 'binding_type':
          if (binding_type.value !== '') {
            extValues = {
              ...extValues,
              ext_binding: binding_type.value
            }
          }
          break
        case 'binding_color':
          if (binding_color.value !== '')
            extValues = {
              ...extValues,
              ext_spinecolor: binding_color.value
            }
          break
        case 'spine_color':
          if (spine_color.value !== '')
            extValues = {
              ...extValues,
              ext_color: spine_color.value
            }
          break
        case 'cover_finishing':
          if (cover_finishing.value !== '')
            extValues = {
              ...extValues,
              ext_coverfinish: cover_finishing.value
            }
          break
        case 'rounded':
          if (roundedTips.value !== '') {
            extValues = {
              ...extValues,
              ext_rountips: roundedTips.value
            }
          }
          break
        case 'cover_design':
          if (cover_design.value !== '' && ezetera_proposal === true) {
            extValues = {
              ...extValues,
              ext_cover_desing: cover_design.value,
              ezetera_proposal: true
            }
          } else if (cover_design.value !== '' && college_proposal === true) {
            extValues = {
              ...extValues,
              ext_cover_desing: cover_design.value,
              college_proposal: true
            }
          } else if (
            (cover_design.value === 'design' &&
              college_proposal === false &&
              ezetera_proposal === false) ||
            (cover_design.value === 'design' &&
              college_proposal === null &&
              ezetera_proposal === null)
          ) {
            extValues = {
              ...extValues,
              ext_cover_desing: cover_design.value,
              ezetera_proposal: false,
              college_proposal: false
            }
          }
          break
        default:
          break
      }
    })

    Object.keys(notebookInsideValues).map((res: any) => {
      const objKey = res as keyof typeof notebookInsideValues
      switch (objKey) {
        case 'number_of_sheets':
          if (number_of_sheets.value !== '') {
            extValues = {
              ...extValues,
              int_sheets: number_of_sheets.value
            }
          }
          break
        case 'striped':
          if (striped.value !== '') {
            extValues = {
              ...extValues,
              int_striped: striped.value
            }
          }
          break
        case 'rounded':
          if (rounded.value !== '') {
            extValues = {
              ...extValues,
              int_roundtips: rounded.value
            }
          }
          break
        case 'guide_point':
          if (guide_point.value !== '') {
            extValues = {
              ...extValues,
              int_guidepoint: guide_point.value
            }
          }
          break
        case 'logo_position':
          if (logo_position.value !== '') {
            extValues = {
              ...extValues,
              int_positionlogos: logo_position.value
            }
          }
          break
        case 'interior_inks':
          if (interior_inks.value !== '') {
            extValues = {
              ...extValues,
              int_interiorinks: interior_inks.value
            }
          }
          break
        case 'magin_complete':
          if (magin_complete.value !== '') {
            extValues = {
              ...extValues,
              int_fullmargin: magin_complete.value
            }
          }
          break
        case 'margin_date':
          if (margin_date.value !== '') {
            extValues = {
              ...extValues,
              int_datarange: margin_date.value
            }
          }
          break
        case 'date_type':
          if (date_type.value !== '') {
            extValues = {
              ...extValues,
              int_datetype: date_type.value
            }
          }
          break

        default:
          break
      }
    })
    return extValues
  }

  //Use effects------------------->

  useEffect(() => {
    dispatch(resetFormValues())
    getNotebookData()
  }, [])

  useEffect(() => {
    if (notebook) {
      dispatch(setSelectedNotebook(notebook?.id_format))
      dispatch(
        setInsiseLogo(notebook?.int_logo !== '' ? notebook?.int_logo : '')
      )
      dispatch(setStepNotebook(notebook?.step))
      dispatch(setCollegeProposal(notebook?.college_proposal))
      dispatch(setEzeteraProposal(notebook?.ezetera_proposal))

      dispatch(setRequireCoverText(notebook?.require_cover_text))
      dispatch(setRequireCoverImg(notebook?.require_cover_img))
      dispatch(setRequireCoverDesing(notebook?.require_cover_desing))
      notebook?.uploadposition !== null &&
        dispatch(setPositionsCovers(JSON.parse(notebook?.uploadposition || '')))

      notebook.jsonimgback !== null &&
        Object.keys(JSON.parse(notebook.jsonimgback || '')).length !== 0 &&
        dispatch(setJsonImgBack(JSON.parse(notebook.jsonimgback || '')))

      notebook.jsonimgfront !== null &&
        Object.keys(JSON.parse(notebook.jsonimgfront || '')).length !== 0 &&
        dispatch(setJsonImgFront(JSON.parse(notebook.jsonimgfront || '')))

      notebook.jsontextback !== null &&
        Object.keys(JSON.parse(notebook.jsontextback || '')).length !== 0 &&
        dispatch(setJsonTextBack(JSON.parse(notebook.jsontextback || '')))

      notebook.jsontextfront !== null &&
        Object.keys(JSON.parse(notebook.jsontextfront || '')).length !== 0 &&
        dispatch(setJsonTextfront(JSON.parse(notebook.jsontextfront || '')))

      const { college_proposal, ezetera_proposal } = notebook

      dispatch(
        setOutsidePropuesta(
          notebook.front_cover_img !== '' ? notebook.front_cover_img : ''
        )
      )
      dispatch(
        setOutsidePropuestaBack(
          notebook.back_cover_img !== '' ? notebook.back_cover_img : ''
        )
      )

      dispatch(
        handleNotebookForm({
          ...notebookFrontValues,
          binding_type: {
            ...notebookFrontValues['binding_type'],
            value: notebook.ext_binding !== null ? notebook.ext_binding : ''
          },
          binding_color: {
            ...notebookFrontValues['binding_color'],
            value:
              notebook.ext_spinecolor !== null ? notebook.ext_spinecolor : ''
          },
          spine_color: {
            ...notebookFrontValues['spine_color'],
            value: notebook.ext_color !== null ? notebook.ext_color : ''
          },
          cover_finishing: {
            ...notebookFrontValues['cover_finishing'],
            value:
              notebook.ext_coverfinish !== null ? notebook.ext_coverfinish : ''
          },
          rounded: {
            ...notebookFrontValues['rounded'],
            value: notebook.ext_rountips !== null ? notebook.ext_rountips : ''
          },
          cover_design: {
            ...notebookFrontValues['cover_design'],
            value:
              notebook.ext_cover_desing !== null
                ? notebook.ext_cover_desing
                : '',
            enabled: ezetera_proposal
              ? !ezetera_proposal
              : college_proposal
              ? !college_proposal
              : true
          }
        })
      )

      dispatch(
        handleNotebookInsideForm({
          ...notebookInsideValues,
          number_of_sheets: {
            ...notebookInsideValues['number_of_sheets'],
            value: notebook.int_sheets !== null ? notebook.int_sheets : ''
          },
          striped: {
            ...notebookInsideValues['striped'],
            value: notebook.int_striped !== null ? notebook.int_striped : ''
          },
          rounded: {
            ...notebookInsideValues['rounded'],
            value: notebook.int_roundtips !== null ? notebook.int_roundtips : ''
          },
          guide_point: {
            ...notebookInsideValues['guide_point'],
            value:
              notebook.int_guidepoint !== null ? notebook.int_guidepoint : ''
          },
          logo_position: {
            ...notebookInsideValues['logo_position'],
            value:
              notebook.int_positionlogos !== null
                ? notebook.int_positionlogos
                : ''
          },
          interior_inks: {
            ...notebookInsideValues['interior_inks'],
            value:
              notebook.int_interiorinks !== null
                ? notebook.int_interiorinks
                : ''
          },
          magin_complete: {
            ...notebookInsideValues['magin_complete'],
            value:
              notebook.int_fullmargin !== null ? notebook.int_fullmargin : ''
          },
          margin_date: {
            ...notebookInsideValues['margin_date'],
            value: notebook.int_datarange !== null ? notebook.int_datarange : ''
          },
          date_type: {
            ...notebookInsideValues['date_type'],
            value:
              notebook.int_datetype !== null
                ? parseInt(notebook.int_datetype)
                : ''
          }
        })
      )

      setTimeout(() => {
        dispatch(EnableFlag())
      }, 100)
    }
  }, [notebook])

  return {
    notebook,
    saveSendDataNotebookChange,
    saveNotebookChange,
    getNotebookData
  }
}

export default useNotebooks
