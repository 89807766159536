import { IGradesDinamic } from 'interfaces/schools-interface'
import React, { useState, useEffect } from 'react'
import style from './thiscomponents.module.scss'

const GradeCheck = ({
  name,
  allSelect,
  check,
  onHandleCheck
}: {
  allSelect: boolean
  name: string
  check: boolean
  onHandleCheck: () => void
}) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(allSelect)
  }, [allSelect])

  return (
    <label className={`${style['center-label']}`}>
      <input
        checked={check}
        onChange={(_) => onHandleCheck && onHandleCheck()}
        type={'checkbox'}
      />{' '}
      {name}
    </label>
  )
}

export default GradeCheck
