/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { memo, useEffect, useState } from 'react'
import { Form, Input, DatePicker, UploadFile, UploadProps } from 'antd'
import { AllIconsInterface, inputTypeInterface } from '../../../types'
import './UploadInput.scss'
import { UploadOutlined } from '@ant-design/icons'

import { Button, Upload } from 'antd'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
interface InputProps {
  label?: string
  name: string
  placeholder?: string
  validations?: any
  disabled?: boolean
  iconName?: AllIconsInterface
  prefix?: JSX.Element
  type?: inputTypeInterface
  form?: string
  value: string
  maxLength?: number
  minLength?: number
  bordered?: boolean
  onChange?: (file: any, name: string) => void
  onClick?: (e: any) => void
}

const CUploadInput = memo((props: any) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const { onChange, name, value } = props

  useEffect(() => {
    if (typeof value === 'string' && value !== '') {
      setFileList(
        typeof value === 'string' && value !== ''
          ? [
              {
                uid: `${value}-uniqueid`,
                name: value,
                status: 'done',
                url: value
              }
            ]
          : []
      )
    }
  }, [value])

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      if (props.disabled) return
      if (uid.role === 'Director') return
      if (onChange) onChange(undefined)
      setFileList([])
    },
    onChange(info) {
      console.log(info)
    },
    beforeUpload: (file) => {
      if (props.disabled) return
      if (onChange) onChange(file, name)
      setFileList([...fileList, file])
      return false
    },
    fileList
  }

  return (
    <Form.Item className='upload-input-component' label={props.label}>
      <Upload {...uploadProps} disabled={props.disabled}>
        {fileList.length === 0 && (
          <Button
            className='uploadInputButton'
            disabled={props.disabled}
            icon={<UploadOutlined />}
          >
            Click to Upload
          </Button>
        )}
      </Upload>
    </Form.Item>
  )
})

Input.displayName = 'Input'
export default CUploadInput
