import React from 'react'

const LabelShortDeportivoLateral = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='black'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Etiqueta_Lateral" data-name="Etiqueta Lateral">
    <g id="Etiqueta_lateral" data-name="Etiqueta lateral">
      <path d="m424.19,301.94c-.83.02-1.14.26-1.08,1.6.19,3.65.29,7.3.35,10.96.02,1.17.28,1.44,1.05,1.41,4.96-.24,9.92-.41,14.88-.59l-.44-13.91h-.21c-4.85.19-9.71.39-14.56.52Z"/>
      <path d="m427.9,312.55c.26-.14.51-.3.78-.43.84-.38,1.38-1.11,1.92-1.84.32-.44.3-.84-.08-1.27-1.19-1.33-2.71-1.92-4.32-2.33-.06-.02-.11-.04-.17-.07.68-.06,1.28.28,1.93.4.18.04.42.24.55,0,.12-.21-.1-.39-.2-.58-.16-.29-.34-.56-.48-.89.9,1.2,2.01,2.04,3.32,2.62.57.25.84.1,1.07-.46.37-.9.75-1.79.46-2.83.67,1.15,1.52,2.22,1.04,3.76.32-.01.6-.02.88-.04.28-.02.38.22.47.43.13.34-.19.23-.3.33-.62.52-1.42.74-1.85,1.59-.28.57-.92.73-1.5.9-1.15.35-2.32.55-3.52.69Z"/>
    </g>
  </g>
    </svg>
  )
}

export default LabelShortDeportivoLateral
