/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnsType } from 'antd/es/table'
import {
  ErrorToast,
  SuccessToast,
  WarningToast
} from 'components/Toasts/Toasts'
import { BooksTable } from 'interfaces/books-interface'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeStatusBook } from 'redux/reducers/books'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import { RootState } from 'redux/store/store'
import * as services from 'services/books/books'
import { getGeneralModulesStatus } from 'services/genericServices/genericServices'
import { handleErrors } from 'utilities/handleErrors'

export const useAuthorize = (closeModal: () => void) => {
  const { pathname } = useLocation()

  const [books, setBooks] = useState([])
  const [loading, setLoading] = useState<boolean>(false)

  const [idLevel] = useState<any>()
  const [idGrade] = useState<any>()

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [allSelectedRowKeys, setAllSelectedRowKeys] = useState<any>([])
  // const [action,setAction] = useState<boolean>(false)

  const { IDLevel, IDGradeAuth, isBookAuth } = useSelector(
    (state: RootState) => state.books
  )

  const dispatch = useDispatch()

  const fetchBooks = async (level: number, grade: number): Promise<any> => {
    try {
      setLoading(true)
      const res = await services.getBooksAuthorize(level, grade)
      setBooks(res?.data?.books)
      const allIds = res?.data?.books?.map((book: any) => book.id)
      setAllSelectedRowKeys(allIds)
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isBookAuth) {
      fetchBooks(idLevel, idGrade)
    } else {
      fetchBooks(IDLevel, IDGradeAuth)
    }
  }, [closeModal])

  const bookColumns: ColumnsType<BooksTable> = [
    {
      title: 'ISBN',
      dataIndex: 'isbn',
      key: 'isbn'
    },
    {
      title: 'Título',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Editorial',
      dataIndex: 'editorial',
      key: 'editorial'
    },

    {
      title: 'Precio',
      dataIndex: 'special_price',
      key: 'special_price',
      render: (text: number, field: any) => {
        let money = field.price
        if (field.special_price !== '0.00' && field.special_price !== null)
          money = field.special_price
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(money)
      }
    }
  ]

  const checkIfListAreDone = async () => {
    try {
      const result = await getGeneralModulesStatus()      
      if (pathname.includes('libros') && result.data.books_status === 'Completado')
      dispatch(setCloseSupplyList(true))
    } catch (err: any) {
      handleErrors(err)
    } 
  }

  const handleAllAuthorize = async (close: Function) => {
    try {
      setLoading(true)
      if (allSelectedRowKeys.length <= 0) {
        WarningToast('No exista nada para autorizar')
        return
      }
      await services.multipleBookStatus({ id: allSelectedRowKeys, status: 5 })
      dispatch(changeStatusBook({ id: allSelectedRowKeys, status: 5 }))
      SuccessToast('Libros autorizados correctamente')
      close()
      setSelectedRowKeys([])
      setAllSelectedRowKeys([])
      checkIfListAreDone()
    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  const handleAuthorize = async (close: Function) => {
    try {
      setLoading(true)
      if (selectedRowKeys.length <= 0) {
        WarningToast('Selecciona uno o más libros para autorizar.')
        return
      }
      await services.multipleBookStatus({ id: selectedRowKeys, status: 5 })
      dispatch(changeStatusBook({ id: selectedRowKeys, status: 5 }))
      SuccessToast('Libros autorizados correctamente')
      close()
      setSelectedRowKeys([])
      setAllSelectedRowKeys([])
      checkIfListAreDone()

    } catch (error) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    bookColumns,
    books,
    selectedRowKeys,
    setSelectedRowKeys,
    handleAllAuthorize,
    handleAuthorize
  }
}
