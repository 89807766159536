import { Col, List } from 'antd'
import Collapse from 'components/Collapse/Collapse'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import B from 'components/texts/B/B'
import React, { memo } from 'react'

const utilsTitle = [
  {
    1: 'Nombre del material',
    2: 'Descripción',
    3: 'ISBN',
    5: 'Precio'
  }
]

const dummyPAck = [
  {
    id: '15',
    name: 'PAckete'
  }
]

const utilsDummy = [
  {
    name: 'Nombre de Libro',
    edition: 'EDICION',
    isbn: 'ISBN',
    price: 'PRECIO'
  }
]

const colStyle = { cursor: 'pointer' }
const UtilitiesModule = memo(() => {
  const RenderPacks = (Packs: any): any => {
    return Packs.map((res: any, key: number) => {
      return (
        <Collapse key={key + res.id} id={res.id} title={res.name}>
          <>
            <List
              itemLayout='horizontal'
              dataSource={utilsTitle}
              renderItem={(title: any) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  {Object.keys(title).map((res, key) => {
                    return (
                      <Col
                        key={key}
                        className='ListTitles'
                        style={colStyle}
                        span={4}
                      >
                        <B size='Light'>{title[res]}</B>
                      </Col>
                    )
                  })}
                </List.Item>
              )}
            />
            <List
              itemLayout='horizontal'
              dataSource={utilsDummy}
              renderItem={(grade: any) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  <Col style={colStyle} span={4}>
                    <B size='Light'>{grade.name}</B>
                  </Col>
                  <Col style={colStyle} span={4}>
                    {' '}
                    <B size='Light'>{grade.edition}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{grade.isbn}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{grade.price}</B>
                  </Col>
                </List.Item>
              )}
            />
          </>
        </Collapse>
      )
    })
  }

  return (
    <div>
      {utilsDummy.length === 0 ? (
        <EmptyListMessage
          icon='book'
          iconWidth={34}
          iconHeight={40}
          text='Aún no has asignado libros a esta lista'
        />
      ) : (
        RenderPacks(dummyPAck)
      )}
    </div>
  )
})
UtilitiesModule.displayName = 'UtilitiesModule'
export default UtilitiesModule
