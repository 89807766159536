import React, { Fragment } from 'react'
import style from '../poloeditable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import CorbataSvg from 'assets/img/uniforms/corbata/CorbataSvg'

const CorbatPics = () => {
  const { pictureLogo } = useSelector((state: RootState) => state.uniforms)

  return (
    <div className={`${style['div-polo-svg-relative']}`}>
      <Fragment>
        <div className={`${style[`logo-position-${'left'}`]}`}>
          <img className={`${style['svg-logo']}`} src={pictureLogo} />
        </div>
        <div className={`${style['div-polo-svg-absolute']}`}>
          <CorbataSvg className={`${style['svg']}`} />
        </div>
        <div className={`${style['div-shadows-svg-absolute']}`}></div>
        <div className={`${style['div-label-svg-absolute']}`}></div>
        <div className={`${style['div-tags-svg-absolute']}`}></div>
      </Fragment>
    </div>
  )
}

export default CorbatPics
