import { memo } from 'react'
import { Table } from 'antd'
import { ScrollTable } from 'types'
import { ColumnsType } from 'antd/es/table'

interface ListProps {
  loading: boolean
  scroll?: ScrollTable
  columns: ColumnsType<any>
  quotas: any
  onHandleRow?: any
  onChangePage: (page: number) => void
}

const ListQuotasModule = memo((props: ListProps) => {
  const { quotas, total, page } = props?.quotas

  return (
    <>
      <Table
         dataSource={quotas}
        columns={props.columns}
        scroll={props.scroll}
        pagination={{
          total,
          current: page,
          onChange: (pageNumber) => props.onChangePage(pageNumber),
          showSizeChanger: false
        }}
        showSorterTooltip={{ title: 'Clic para ordenar' }}
        rowKey={(record) => record.id}
        loading={props.loading}
        onRow={props.onHandleRow}
      />
    </>
  )
})

export default ListQuotasModule