import { memo } from 'react'
import {
  Col,
  Form,
  Input,
  List,
  Modal,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd'
import bookStyle from '../../../modules/books/books.module.scss'
import style from './AdminUtilsManagement.module.scss'
import styles from './../AdminUtilsCatalog/utils.module.scss'
import utilsStyles from './AdminUtilsManagement.module.scss'
import Button from 'components/buttons/Button/Button'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import { useLocation } from 'react-router-dom'
import BackButton from 'components/buttons/BackButton/BackButton'
import { useRequireUtilsForm } from 'hooks/utils/useRequireUtilsForm'
import FormInput from 'components/formInputs/input'
import { useManagementUtils } from 'hooks/utils/useManagementUtils'
import CommentsComponent from 'components/CommentsComponent/CommentsComponent'
import Collapse from 'components/Collapse/Collapse'
import FormCommentsModule from 'modules/books/formComments/FormCommentsModule'
import B from 'components/texts/B/B'
import globalStyle from 'styles/global.module.scss'
import ErrorAlert from 'assets/img/errorAlert.svg'
import { Options } from 'types'
import ManagementUtilsForm from './ManagementUtilsForm'
import AccordionSchoolUtils from './components/AccordionSchoolUtils'
import { InitUtilsPagination } from 'utilities/restructurePagination'

const colStyle = { cursor: 'pointer', padding: '0 20px' }

const AdminUtilsManagement = memo(() => {
  const {
    params,
    schoolsCatalog,
    statusCatalog,
    utilsList,
    LoadingList,
    pagination,
    utilsColumns,
    tabs,
    selectedLevel,
    packages,
    loadingSupplies,
    sidebarFormFilled,
    selectSchool,
    selectStatus,
    packagesMap,
    selectedPackage,
    loadingDownload,
    searchText,
    statusNumberMap,
    editPackageStatusModal,
    donwloadLogs,
    accordionView,
    handleCancelPackageModal,
    handleOpenPackageModal,
    navigate,
    setParams,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onRowClicked,
    onChangeSelectedTab,
    handleCommentsSubmit,
    onCollapseChange,
    setSidebarFormFilled,
    updateSupplies,
    getUtilsList,
    setSelectedPackage,
    downloadClicked,
    setSearchText,
    aceptRequestChanges,
    negateRequestChanges,
    setAccordionView,
    setPagination
  } = useManagementUtils()

  const {
    form,
    setmodalMake,
    modalMake,
    formEditorial,
    postNewMakeSupply,
    loadingMake
  } = useRequireUtilsForm()

  const location = useLocation()

  const { Option } = Select

  const AllUtilesTable = () => {
    return (
      <div className={styles['table-container']}>
        <Table
          dataSource={utilsList}
          loading={LoadingList}
          columns={utilsColumns}
          className={styles.utilstable}
          scroll={{ x: 800 }}
          rowKey={(record: any) => {
            return `${record.id}-${record.id_supply_package}-${record.id_supply_school}`
          }}
          onRow={(record: any, _: any) => ({
            onClick: (_: any) => {
              onRowClicked(record)
            }
          })}
          pagination={{
            showSizeChanger: false,
            current: pagination?.page,
            total: pagination?.total,
            pageSize: pagination?.limit,
            onChange: (page: number, pageSize: number) => {
              onHandleChangePage(page)
            }
          }}
        />
      </div>
    )
  }

  return (
    <>
      {params.has('action') &&
      (params.get('action') == 'nuevo' || params.get('action') == 'editar') ? (
        <div className={style.AdminUtilsManagement}>
          <BackButton
            onClick={() => {
              navigate(-1)
            }}
            textDecoration='underline'
          />
          <TitleHeader IconName='utilsIcon' title={location.state?.name} />
          <ManagementUtilsForm updateSupplies={updateSupplies} />
          <Modal
            title='Agregar marca'
            open={modalMake}
            centered
            onCancel={() => {
              formEditorial.resetFields()
              setmodalMake(false)
            }}
            footer={false}
            className={globalStyle.confirmModal}
          >
            <Form
              form={formEditorial}
              layout='vertical'
              onFinish={postNewMakeSupply}
              autoComplete='off'
            >
              <FormInput
                name='name'
                placeholder='Nombre'
                label='Nombre'
                rules={[{ required: true, message: '' }]}
              />
              <div className={globalStyle.confirmModalFooter}>
                <Button
                  isLoading={loadingMake}
                  buttonType='submit'
                  rounded
                  sector='primary'
                >
                  Agregar
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      ) : (
        <div className={style.AdminUtilsManagement}>
          <CommentsComponent
            innerFormFilled={sidebarFormFilled}
            afterBookStatusUpdated={() => {
              if (params.has('school')) {
                updateSupplies(selectedPackage)
              } else {
                /* const page = parseInt(params.get('page') || '1')
                const search = params.get('search') || ''
                const status = params.get('status')
                const controller = new AbortController()
                getUtilsList(
                  page,
                  controller,
                  search,
                  status ? parseInt(status) : undefined
                ) */
              }
              const page = parseInt(params.get('page') || '1')
                const search = params.get('search') || ''
                const status = params.get('status')
                const controller = new AbortController()
                getUtilsList(
                  page,
                  controller,
                  search,
                  status ? parseInt(status) : undefined
                )
            }}
            moduleForm={
              <FormCommentsModule
                form={form}
                handleSubmit={handleCommentsSubmit}
                fromUtils={true}
                setFormFilledOut={(flag: boolean) => {
                  setSidebarFormFilled(flag)
                }}
              />
            }
          />
          <TitleHeader IconName='utilsIcon' title='Manejo de catálogos' />
          <div className={bookStyle.filters}>
            <div>
              <Typography>Colegios</Typography>
              {/* <Select
                value={selectSchool}
                style={{ width: 200 }}
                defaultValue={'todos'}
                placeholder={'Colegios'}
                onChange={onHandleChangeSchool}
                options={schoolsCatalog}
              /> */}
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Colegios'
                optionFilterProp='children'
                onChange={onHandleChangeSchool}
                value={selectSchool}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                {schoolsCatalog?.map((school: Options) => (
                  <Option key={school?.value as number} value={school?.value}>
                    {school?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Typography>Estatus</Typography>
              <Select
                value={
                  selectStatus == 'todos'
                    ? selectStatus
                    : statusNumberMap[selectStatus]
                }
                style={{ width: 200 }}
                defaultValue={'todos'}
                placeholder={'Estatus'}
                onChange={onHandleChangeStatus}
                options={statusCatalog.filter(
                  (item: any) => item.label !== 'Nuevo'
                )}
              />
            </div>
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar'
              onClick={downloadClicked}
              isLoading={loadingDownload}
            />
            <Button
              sector='tertiary'
              rounded
              background='white'
              size='m'
              label='Descargar Logs'
              onClick={donwloadLogs}
              isLoading={loadingDownload}
            />
          </div>
          <Input.Search
            placeholder='Buscar'
            enterButton
            size='large'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear={true}
            onSearch={(e: string) => {
              const prev: any = {}
              for (let key of params.keys()) prev[key] = params.get(key)
              const newParams = { ...prev, search: e, page: 1 }
              if (e === '') delete newParams['search']
              setParams(newParams)
            }}
          />
          <>
            {params.has('school') ? (
              <div>
                <div style={{ marginTop: '18px', marginBottom: '12px' }}>
                  <Button
                    sector='primary'
                    rounded
                    size='sm'
                    label={
                      accordionView
                        ? 'Cambiar a modo tabla'
                        : 'Cambiar a modo acordeón'
                    }
                    onClick={() => setAccordionView((prev: boolean) => !prev)}
                  />
                </div>

                {accordionView ? (
                  <AccordionSchoolUtils
                    tabs={tabs}
                    packages={packages}
                    packagesMap={packagesMap}
                    utilsColumns={utilsColumns}
                    selectStatus={selectStatus}
                    selectedLevel={selectedLevel}
                    loadingSupplies={loadingSupplies}
                    onRowClicked={onRowClicked}
                    onCollapseChange={onCollapseChange}
                    setSelectedPackage={setSelectedPackage}
                    onChangeSelectedTab={onChangeSelectedTab}
                    handleOpenPackageModal={handleOpenPackageModal}
                  />
                ) : (
                  <Table
                    dataSource={utilsList}
                    loading={LoadingList}
                    columns={utilsColumns}
                    className={styles.utilstable}
                    scroll={{ x: 800 }}
                    rowKey={(record: any) => {
                      return `${record.id}-${record.id_supply_package}-${record.id_supply_school}`
                    }}
                    onRow={(record: any, _: any) => ({
                      onClick: (_: any) => {
                        onRowClicked(record)
                      }
                    })}
                    pagination={{
                      showSizeChanger: false,
                      current: pagination?.page,
                      total: pagination?.total,
                      pageSize: pagination?.limit,
                      onChange: (page: number, pageSize: number) => {
                        onHandleChangePage(page)
                      }
                    }}
                  />
                )}
              </div>
            ) : (
              <AllUtilesTable />
            )}
          </>
        </div>
      )}

      <Modal
        title='Solicitud de cambio'
        open={editPackageStatusModal.visible}
        onCancel={handleCancelPackageModal}
        className={globalStyle.confirmModal}
        footer={<></>}
      >
        <div className={globalStyle.confirmModalBody}>
          <img src={ErrorAlert} alt='lista alerta' />
          <B size='Light'>
            ¿Estás seguro que deseas permitir esta la modificación del paquete?,
            Al hacer esto, el estatus del paquete pasará a "En Construcción" y
            el usuario podra hacer cambios sobre el mismo
          </B>
        </div>
        <br></br>

        <div className={globalStyle.confirmModalFooter}>
          <Button
            rounded
            onClick={negateRequestChanges}
            label={'No'}
            sector='secondary'
          />
          <Button rounded onClick={aceptRequestChanges} label={'Si'} />
        </div>
      </Modal>
    </>
  )
})
AdminUtilsManagement.displayName = 'AdminUtilsManagement'
export default AdminUtilsManagement
