import React from 'react'

const LabelJacket = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Etiqueta_Lateral' data-name='Etiqueta Lateral' stroke='black'>
        <g id='Etiqueta_lateral' data-name='Etiqueta lateral'>
          <path
            stroke='#f5e7ca'
            fill='#f5e7ca'
            d='m585.77,573.37c-1.42.07-1.96.39-1.82,2.15.37,4.8.6,9.61.76,14.42.05,1.54.5,1.89,1.83,1.81,8.53-.51,17.06-.93,25.58-1.37l-.96-18.31-.36.02c-8.34.44-16.69.9-25.03,1.28Z'
          />
          <path
            fill='green'
            stroke='green'
            d='m596.58,586.98c.35-.2.69-.41,1.05-.58,1.12-.52,1.82-1.49,2.53-2.46.42-.59.38-1.11-.14-1.67-1.64-1.73-3.7-2.47-5.88-2.98-.08-.02-.15-.04-.22-.1.91-.09,1.73.34,2.59.49.25.04.57.3.75,0,.16-.27-.14-.52-.28-.76-.23-.38-.49-.73-.67-1.15,1.23,1.56,2.75,2.64,4.53,3.38.78.32,1.14.11,1.43-.64.48-1.19.95-2.37.55-3.73.93,1.5,2.11,2.88,1.5,4.93.44-.02.81-.03,1.18-.06.39-.03.52.27.64.56.19.44-.25.31-.4.44-.81.71-1.89,1.01-2.44,2.13-.37.75-1.23.97-2,1.21-1.53.48-3.1.79-4.71.99Z'
          />
        </g>
      </g>
    </svg>
  )
}

export default LabelJacket
