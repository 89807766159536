import React from 'react'

const PantalonSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Path" stroke='black'>
    <g id="Path-2" data-name="Path">
      <path id="Contorno_pantalon" data-name="Contorno pantalon"   d="m376.44,398.77c0-6.9.45-5.54.7-15.25.51-20.11,16.2-135.7,23.1-140.74,6.91-5.04,20.94,145.9,21.51,153.59.52,6.94-.18,3.46.96,13.84.66,6.02,6.52,331.36,6.52,331.36,65.83,3.81,35.39,5.57,65.83,3.81,12.46-.72,39.68-3.78,39.68-3.78l-.1-252.41.11-22.83-3.86-62.69s.95-61.55,1.86-144.69c.93-85.07-8.5-172.07-8.5-172.07,0,0,.32-19.77-4.55-28.41-2.52-4.47-70.87-5.31-70.87-5.31,0,0-21.37.7-84.44-.26-63.16-.97-83.56,6.9-83.56,6.9,0,0-13.37,106.75-13.46,114.97-.04,3.67-2.3,140.19,0,146.04,2.3,5.84-.78,69.28.54,73.15v57.17l-2.67,39.8,2.67,250.29,24.64,5.88h35.37c32.34,0,40.79-4.58,42.32-5.19,1.53-.61,4.62-330.69,4.62-330.69.61-7.32,1.56-12.49,1.56-12.49Z"/>
      <path id="Cintura"   d="m276.61,88.68l4.23-28.84s46.25,14.76,84.75,15.66c38.5.91,82.91-2,82.91-2,0,0,68.26-7.25,71.31-14.78.74-1.81,4.43,26.6,4.43,26.6,0,0-1.08,18.35-83.43,20.09-69.94,1.48-101.92-1.93-101.92-1.93,0,0-39.95-1.68-62.29-14.8Z"/>
      <line id="linea_cierre" data-name="linea cierre"   x1="400.24" y1="242.78" x2="400.01" y2="75.81"/>
      <path id="Boton"   d="m418.02,91.63c0,3.26-2.64,5.91-5.9,5.91s-5.91-2.64-5.91-5.91,2.64-5.91,5.91-5.91,5.9,2.65,5.9,5.91Z"/>
      <path id="Cinturon"   d="m476.26,70.68l-11.62,2.02s2.81,38.86,2.72,42.22h11.89s-1.84-44.45-3-44.24Z"/>
      <path id="Cinturon-2" data-name="Cinturon"   d="m324.46,71.21l11.61,2.02s-2.81,38.86-2.72,42.22h-11.89s1.84-44.45,3-44.25Z"/>
      <g id="Bolsa">
        <polyline   points="277.65 209.8 277.36 210.66 277.06 209.93 276.73 210.78 276.47 210.04 276.11 210.87 275.88 210.11 275.49 210.96 275.29 210.18 274.86 211.01 274.71 210.22 274.25 211.04 274.12 210.25 273.63 211.05 273.54 210.24 273.01 211.03 272.96 210.21 272.4 211 272.4 210.15 271.8 210.91 271.84 210.06 271.25 210.82 271.26 209.98 270.73 210.76 270.66 209.95 270.2 210.76 270.04 209.99 269.68 210.83 269.41 210.09 269.15 210.94 268.77 210.27"/>
        <polyline   points="284.38 104.79 284.17 105.54 283.88 104.87 283.64 105.62 283.38 104.93 283.12 105.67 282.88 104.96 282.59 105.7 282.37 104.99 282.05 105.71 281.87 104.99 281.53 105.7 281.37 104.98 281 105.68 280.87 104.94 280.46 105.63 280.37 104.88 279.93 105.56 279.89 104.79 279.41 105.45 279.4 104.68 278.93 105.33 278.9 104.57 278.49 105.25 278.38 104.51 278.03 105.22 277.85 104.51 277.59 105.25 277.32 104.57 277.14 105.32 276.77 104.69"/>
        <polyline   points="524.93 107.5 524.82 108.39 524.38 107.68 524.22 108.57 523.81 107.83 523.63 108.71 523.25 107.95 523.02 108.84 522.67 108.06 522.42 108.94 522.1 108.14 521.82 109.01 521.53 108.21 521.21 109.07 520.95 108.25 520.59 109.09 520.38 108.26 519.97 109.1 519.8 108.24 519.36 109.06 519.23 108.19 518.8 109 518.64 108.14 518.27 108.99 518.04 108.16 517.75 109.03 517.43 108.24 517.25 109.13 516.83 108.4 516.74 109.29 516.23 108.63"/>
        <polyline   points="532.39 212.09 532.13 212.94 531.82 212.21 531.53 213.04 531.25 212.29 530.93 213.12 530.69 212.35 530.33 213.18 530.11 212.4 529.72 213.21 529.55 212.42 529.13 213.22 528.98 212.43 528.53 213.21 528.41 212.41 527.93 213.17 527.85 212.35 527.33 213.12 527.3 212.27 526.74 213.02 526.76 212.17 526.21 212.9 526.19 212.07 525.7 212.83 525.61 212.02 525.19 212.82 525.01 212.04 524.68 212.87 524.4 212.12 524.17 212.96 523.78 212.28"/>
        <g>
          <g>
            <line   x1="266.77" y1="210.95" x2="267.27" y2="210.95"/>
            <line   x1="268.34" y1="210.95" x2="276.4" y2="210.95"/>
            <path   d="m276.94,210.95h.5s0-.17.01-.5"/>
            <path   d="m277.48,209.46c.23-7.99,1.33-45.73,1.54-51.12.33-8.66,3.99-44.88,4.65-51.35"/>
            <path   d="m283.72,106.49c.03-.32.05-.49.05-.49l-.48-.13"/>
            <line   x1="282.21" y1="105.59" x2="276.29" y2="104"/>
            <line   x1="275.75" y1="103.86" x2="275.27" y2="103.73"/>
          </g>
          <path   d="m533,213.73h-10.66s-1.36-46.57-1.59-52.6c-.36-9.52-4.75-52.34-4.75-52.34l8.5-2.27"/>
        </g>
      </g>
      <g id="Bolsa-2" data-name="Bolsa">
        <path id="Bolsa_diagonal_izquierda" data-name="Bolsa diagonal izquierda"   d="m266.97,203.42s16.05-40.87,18.99-51.28c2.94-10.42,10.43-55.43,10.43-55.43"/>
        <path id="Bolsa_diagonal_derecha" data-name="Bolsa diagonal derecha"   d="m532.35,203.42s-16.06-40.87-19-51.28c-2.94-10.42-10.16-53.79-10.16-53.79"/>
        <path   d="m266.97,209.23s18.64-44.61,21.58-55.03c2.94-10.42,10.43-55.42,10.43-55.42"/>
        <path   d="m531.71,208.55s-18.64-44.62-21.58-55.03c-2.94-10.42-10.43-55.43-10.43-55.43"/>
      </g>
      <g id="Costuras">
        <path   d="m277.81,87.24s20.65,14.64,85.11,16.24c44.06,1.09,89.79,0,89.79,0,0,0,62.5-4.09,71.45-18.75"/>
        <path   d="m475.11,72.36l-9.1,1.8s3.12,35.95,3.04,39.31l8.65-.11s-1.43-41.19-2.58-40.99Z"/>
        <path   d="m325.78,73.62l8.53,1.37s-3.12,35.35-2.34,38.85h-8.86s1.86-40.36,2.67-40.22Z"/>
        <line   x1="402.1" y1="243.15" x2="401.88" y2="76.17"/>
        <path   d="m424.6,105.41v65.63s.19,17.46-11.13,27.07c-5.92,5.03-13.2,5.31-13.2,5.31"/>
        <path   d="m270.24,727.04s32.88,11.85,100.61,2.33"/>
        <path   d="m534.75,724.41s-37.88,10.88-105.61,1.37"/>
      </g>
      <circle   cx="298.99" cy="208.55" r="15.13"/>
      <circle   cx="502.99" cy="208.55" r="15.13"/>
    </g>
  </g>
    </svg>
  )
}

export default PantalonSvg
