import { useState } from 'react'
import { recoverPassword, recoverPasswordData } from 'services/auth/users'
import { MailOutlined } from '@ant-design/icons'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { InputFormProps } from 'components/formInputs/input'
import { Form, FormInstance } from 'antd'

export const InputForgot: InputFormProps = {
  name: 'mail',
  placeholder: 'Correo electrónico',
  rules: [
    { required: true, message: '' },
    { type: 'email', message: 'Correo no valido.' }
  ],

  prefix: <MailOutlined />
}

interface Functions {
  loading: boolean
  form: FormInstance<any>
  handleForgotPwd: (value: recoverPasswordData) => Promise<void>
}

export const useForgotPwd = (): Functions => {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const handleForgotPwd = async (value: recoverPasswordData): Promise<void> => {
    try {
      setLoading(true)
      await recoverPassword(value)
      SuccessToast(
        `Te hemos enviado un correo a ${value.mail.trim()} con las instrucciones de recuperación.`
      )

      form.resetFields()
    } catch (error: any) {
      console.log(error)
      ErrorToast('No existe el correo.')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    form,
    handleForgotPwd
  }
}
