import { Row } from 'antd'
import PreBuyFooter from 'components/PreBuyFooter/PreBuyFooter'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import AdminNotebooksManagement from 'modules/notebooks/AdminNotebooksManagement/AdminNotebooksManagement'
import NotebooksListModule from 'modules/notebooks/notebooksList/NotebooksListModule'
import { memo } from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import bkgPreBuyNotebooks from '../../assets/img/preBuyBackgrounds/bkgPreBuyNotebooks.svg'
import { useValidatePermission } from 'hooks/utils/useValidatePermission'

const acquired = true

const index = memo(() => {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  useValidatePermission()
  if (acquired) {
    if (uid.role !== 'Ezetera') return (
      <MainTemplate>
        <Row align='middle' gutter={[0, 16]}>
          <NotebooksListModule />
        </Row>
      </MainTemplate>
    )
    else return (
      <MainTemplate>
        <Row align='middle' gutter={[0, 16]}>
          <AdminNotebooksManagement />
        </Row>
      </MainTemplate>
    )
  }

  return (
    <MainTemplate preBuyPage={bkgPreBuyNotebooks}>
      <PreBuyFooter
        title='Cuadernos'
        imgTitle={{
          name: 'notebook',
          height: 20,
          width: 20
        }}
        subTitle='Maecenas eget condimentum velit, sit amet feugiat lectus.'
        description={[
          'Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a',
          ' , mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.'
        ]}
        topics={[
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. '
        ]}
        buttonProps={[
          {
            label: 'Contratar este módulo',
            onClick: () => alert('click'),
            sector: 'tertiary',
            rounded: true,
            size: 'm'
          }
        ]}
      />
    </MainTemplate>
  )
})

index.displayName = 'Notebooks'
export default index
