import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { handleErrors } from 'utilities/handleErrors'
import { Form } from 'antd'
import { getLevelsBySchool } from 'services/schoolUserServices/levels'
import {
  downReportAccess,
  downReportSells,
  getSchoolSelectors
} from 'services/sells/sells'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { RootState } from 'redux/store/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  ApliedFiltersI,
  setAppliedFilters,
  setInputFilters,
  setLevelsAndGrades,
  setloadingFilter
} from 'redux/reducers/sells'
import dayjs from 'dayjs'
import { useGetInitialParams } from './useGetInitialParams'

const convertOptionsFromKlaar = (options: any = []) => {
 return options.map((res: any) => {
    return { label: res, value: res }
  })
}
/* const convertGradesFromKlaar = (options:any) =>{
  return options.map((res:any)=>{
  return {label:res.Grado,value:res.IdGrado}
  })
} */
const converGradesFromLevels = (options: any) => {
  let allGrades: any = []
  options.forEach((res: any) => {
    allGrades = [...allGrades, ...res.grades]
  })
  return allGrades.map((res: any) => {
    return { label: res.name, value: res.name }
  })
}
/* const convertLevels = (options: any) => {
  return options.map((res: any) => {
    return { label: res.name, value: res.name }
  })
} */
const dateFormat = 'YYYY/MM/DD'

const parsedBackDataToInputs = (
  allResponses: any,
  res: any,
  inputFilters: any,
  initialDate: string
) => {
  return {
    ...inputFilters,
    date: {
      ...inputFilters.date
      /*  value: [initialDate, dayjs().format(dateFormat)],
      defaultValue: [initialDate, dayjs().format(dateFormat)] */
    },
    editorial: {
      ...inputFilters.editorial,
      options: convertOptionsFromKlaar(allResponses.data?.editorials)
    },
    grupoarticulo: {
      ...inputFilters.grupoarticulo,
      options: convertOptionsFromKlaar(allResponses.data?.articles)
    },
    grado: {
      ...inputFilters.grado,
      options: converGradesFromLevels(res?.data?.levels)
    }
    /*  seccion: {
      ...inputFilters.seccion,
      options: convertLevels(res?.data?.levels)
    } */
  }
}

const parsedFilterData = (values: any, state: any, uid: any) => {
  const { date } = values
  let dateini = ''
  let datefin = ''
  let schoolId = state?.id_gea
  if (uid.role !== 'Ezetera') {
    schoolId = uid?.id_gea
  }
  if (date) {
    dateini = dayjs(date[0]).format('YYYY-MM-DD')
    datefin = dayjs(date[1]).format('YYYY-MM-DD')
  }
  const newFilter = { ...values, dateini, datefin, idcolegio: schoolId }
  delete newFilter.date
  return newFilter
}

export const INPUTS_INITIAL_STATE = {
  date: {
    label: 'Periodo de ventas',
    name: 'date',
    placeholder: ['Fecha de inicio', 'Fecha de fin'],
    value: '',
    type: 'date',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: [],
    defaultValue: []
  },
  /*  seccion: {
    label: 'Niveles',
    name: 'seccion',
    placeholder: 'Niveles',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  }, */
  grado: {
    label: 'Grados',
    name: 'grado',
    placeholder: 'Grados',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  },
  editorial: {
    label: 'Editorial',
    name: 'editorial',
    placeholder: 'Editorial',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  },
  grupoarticulo: {
    label: 'Grupo de articulos',
    name: 'grupoarticulo',
    placeholder: 'Grupo de articulos',
    value: '',
    type: 'select',
    required: true,
    maxLength: 100, //maxLength solo funciona para tipo text
    status: '',
    options: []
  }
}

export function useFilters() {
  const { state } = useLocation()
  const [form] = Form.useForm()
  const { loadingFilter, inputFilters, apliedFilters } = useSelector(
    (state: RootState) => state.sells
  )
  const [loadingReport, setloadingReport] = useState(false)
  const distpach = useDispatch()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const { schoolIDGea, schoolId, initialDate } = useGetInitialParams()

  const setFormValues = () => {
    form.setFieldsValue({
      date: [dayjs(initialDate, dateFormat), dayjs(dayjs(), dateFormat)],
      editorial: undefined,
      grado: undefined,
      grupoarticulo: undefined,
      seccion: undefined
    })

    setTimeout(() => {
      getSchoolData()
    }, 500)
  }

  const getSchoolData = async () => {
    try {
      const res: any = await getLevelsBySchool(schoolId)
      const allResponses = await getSchoolSelectors(schoolIDGea)

      distpach(
        setInputFilters(
          parsedBackDataToInputs(
            allResponses,
            res,
            INPUTS_INITIAL_STATE,
            initialDate
          )
        )
      )
      distpach(setLevelsAndGrades(res.data.levels))
      const parsedFilters = parsedFilterData(
        {
          date: [dayjs(initialDate, dateFormat), dayjs(dayjs(), dateFormat)],
          editorial: undefined,
          grado: undefined,
          grupoarticulo: undefined,
          seccion: undefined
        },
        state,
        uid
      )
      distpach(setAppliedFilters(parsedFilters))
    } catch (err: any) {
      handleErrors(err)
    } finally {
      distpach(setloadingFilter(false))
    }
  }

  const handleDownloadSellsReport = async () => {
    setloadingReport(true)
    try {
      const res: any = await downReportSells({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingReport(false)
    }
  }

  const handleDownloadAccessReport = async () => {
    setloadingReport(true)
    try {
      const res: any = await downReportAccess({
        ...apliedFilters,
        idcolegio: schoolIDGea,
        id: schoolId
      })
      const { url } = res?.data
      const link = document.createElement('a')
      link.download = url
      link.href = url
      link.click()
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingReport(false)
    }
  }

  useEffect(() => {
    if (initialDate !== '' && schoolId && schoolIDGea) setFormValues()
    return () => {
      distpach(setloadingFilter(true))
      form.resetFields()
    }
  }, [initialDate, schoolId, schoolIDGea])

  useEffect(() => {
    inputFilters.grado.options.length > 0 &&
      form.setFieldsValue({
        date: [dayjs(initialDate, dateFormat), dayjs(dayjs(), dateFormat)],
        editorial: undefined,
        grado: undefined,
        grupoarticulo: undefined,
        seccion: undefined
      })
  }, [inputFilters.grado.options])

  const onSubmit = (values: ApliedFiltersI) => {
    const parsedFilters = parsedFilterData(values, state, uid)
    distpach(setAppliedFilters(parsedFilters))
  }

  return {
    onSubmit,
    loadingFilter,
    INPUTS_INITIAL_STATE,
    form,
    loadingReport,
    handleDownloadSellsReport,
    handleDownloadAccessReport
  }
}
