import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import Blusa_3_4Svg from 'assets/img/uniforms/blusa3_4/Blusa_3_4Svg'
import GreenTagBlusa_3_4 from 'assets/img/uniforms/blusa3_4/GreenTagBlusa_3_4'
import { handleClotheName, setLabelsMenu } from 'redux/reducers/uniformsSlice'

const Blusa_3_4_Editable = () => {
  const { blusa_3_4, pictureLogo } = useSelector(
    (state: RootState) => state.uniforms
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))

    dispatch(
      handleClotheName({
        name: 'Blusa 3/4',
        description: 'Descripcion de la Blusa 3/4'
      })
    )
  }, [])

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          {blusa_3_4.logoPosition.value !== '' && (
            <div
              className={`${
                style[`logo-position-3_4-${blusa_3_4.logoPosition.value}`]
              }`}
            >
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            </div>
          )}
          <div className={`${style['div-polo-svg-absolute']}`}>
            <Blusa_3_4Svg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <GreenTagBlusa_3_4 className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default Blusa_3_4_Editable
