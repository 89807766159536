import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from '../reducers/auth'
import levelsAndGradesReducer from '../reducers/levelsAndGrades'
import { userManagementReducer } from 'redux/reducers/usersManagementSlice'
import packagesReducer from '../reducers/packages'
import { uniformsReducer } from 'redux/reducers/uniformsSlice'
import { notebooksReducer } from 'redux/reducers/notebooksSlice'
import booksReducer from '../reducers/books'
import quotasReducer from '../reducers/quotas'
import closeListReducer from '../reducers/closeList'
import CommentsReducer from '../reducers/comments'
import utilsAdminPageSlice from '../reducers/utilsAdminPageSlice'
import utilsSchoolPageSlice from '../reducers/utilsSchoolPageSlice'
import addSupplyModal from '../reducers/addSupplyModal'
import sellsReducer from '../reducers/sells'




export const store = configureStore({
  reducer: {
    auth: authReducer,
    levelsAndGrades: levelsAndGradesReducer,
    userManagement: userManagementReducer,
    packages: packagesReducer,
    uniforms: uniformsReducer,
    notebooks: notebooksReducer,
    books: booksReducer,
    comments: CommentsReducer,
    utilsAdmin: utilsAdminPageSlice,
    utilsSchool: utilsSchoolPageSlice,
    quotas: quotasReducer,
    closeList: closeListReducer,
    supplyToCatModal: addSupplyModal,
    sells:sellsReducer

  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
