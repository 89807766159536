/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, memo } from 'react'

interface InfiniteScrollProps {
  source?: Array<any>
  setPage: (page: number) => void
  page: number
  total?: any
  children?: JSX.Element
}
const InfiniteScrolls = memo((props: InfiniteScrollProps) => {
  const divScroll = document.getElementById(
    'divScroll'
  ) as HTMLInputElement | null

  useEffect(() => {
    if ((props?.source?.length as number) > 0)
      divScroll?.addEventListener('scroll', onScroll)
    return () => divScroll?.removeEventListener('scroll', onScroll)
  }, [props?.source])

  const onScroll = (e: any) => {
    const scrollTop: any = divScroll?.scrollTop
    const scrollHeight: any = divScroll?.scrollHeight
    const clientHeight: any = divScroll?.clientHeight

    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      if ((props?.total as number) > props?.page) {
        props?.setPage(props.page + 1)
      }
    }
  }

  return (
    <div
      id='divScroll'
      style={{
        maxHeight: '268px',
        overflow: 'auto',
        width: '100%',
        overflowX: 'hidden'
      }}
    >
      {props.children}
    </div>
  )
})

export default InfiniteScrolls
