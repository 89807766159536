import { useState } from 'react'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import * as services from 'services/books/books'
import * as utilServices from 'services/utilsServices/utils'
import convertBase64 from 'utilities/convertBase64'
import { EXTENSIONS } from '../../../constants'
import { RcFile } from 'antd/es/upload/interface'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setErrorMassive, setIDGrade } from 'redux/reducers/books'

interface Functions {
  loading: boolean
  showList: boolean
  downloadFormatBooks: () => void
  beforeUploadBooks: (file: RcFile) => void
  uploadBooks: (close: any) => Promise<any>
  showErrors: any
}

export const useMassive = (errorMassive: any): Functions => {
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const [showList, setShowList] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState<any>('w3343')
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const downloadFormatBooks = async (): Promise<any> => {
    try {
      setLoading(true)
      let res = null

      if (pathname.includes('libros')) {
        res = await services.downloadFormatBooks()
      } else if (pathname.includes('utiles')) {
        res = await utilServices.downloadExcelFormat()
      } else {
        throw Error('Sin contexto para el archivo')
      }

      const link = document.createElement('a')
      link.download = res?.data?.url
      link.href = res?.data?.url
      link.click()
    } catch (err: any) {
      ErrorToast('Ups. Algo salio mal.')
    } finally {
      setLoading(false)
    }
  }

  const beforeUploadBooks = (file: RcFile) => {
    if (!EXTENSIONS.validate.includes(file?.type)) {
      ErrorToast(
        'Formato incompatible. Solo acepta extensiones .xls,.xlsx,.csv'
      )
      setShowList(false)
      setFile(null)
    } else {
      setFile(file)
      setShowList(true)
    }
  }

  const uploadBooks = async (close: Function): Promise<any> => {
    try {
      setLoading(true)

      if (!file) {
        ErrorToast(
          'Formato incompatible. Solo acepta extensiones .xls,.xlsx,.csv'
        )

        return
      }

      const base64 = await convertBase64(file)

      const split = base64?.split(',')
      let resp = null

      if (pathname.includes('libros')) {
        resp = await services.uploadBooks({ file: split?.[1] })
        dispatch(setIDGrade([]))
      } else if (pathname.includes('utiles')) {
        resp = await utilServices.uploadBooks({ file: split?.[1] })
      } else {
        throw Error('Sin contexto para el archivo')
      }

      if (resp?.data?.errors.length) {
        dispatch(setErrorMassive(resp?.data?.errors))

        errorMassive(true)
      } else {
        SuccessToast('Libros cargados con éxito')
      }

      setShowList(false)
      setFile(null)

      close()

      // setTimeout(() => {
      //   window.location.reload()
      // }, 500);
    } catch (err: any) {
      ErrorToast('Error desconocido')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    showList,
    downloadFormatBooks,
    beforeUploadBooks,
    uploadBooks,
    showErrors
  }
}
