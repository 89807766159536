import { memo } from 'react'
import PicazoBackground from 'components/picazoBackground/PicazoBackground'
import NewPasswordModule from 'modules/newPassword/NewPasswordModule'

const index = memo(() => {
  return (
    <PicazoBackground>
      <NewPasswordModule />
    </PicazoBackground>
  )
})
index.displayName = 'NewPassword'
export default index
