import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import HoodieEditable from './HoodieEditable'
import HoodieTraseraEditable from './HoodieTraseraEditable'

const ConjuntoHoodieEditable = () => {
  const { selectedMenu } = useSelector((state: RootState) => state.uniforms)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('hoodie'))
    dispatch(
      setLabelsMenu([
        {
          label: 'hoodie',
          selected: 'hoodie',
          click: () => dispatch(setPantalonesMenu('hoodie'))
        },
        {
          label: 'hoodie trasero',
          selected: 'hoodie trasero',
          click: () => dispatch(setPantalonesMenu('hoodie trasero'))
        }
      ])
    )
  }, [])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'hoodie':
            return <HoodieEditable />
          case 'hoodie trasero':
            return <HoodieTraseraEditable />
          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}

export default ConjuntoHoodieEditable
