/* eslint-disable react-hooks/exhaustive-deps */
import { Options } from 'components/formInputs/select'
import Tags from 'components/tag'
import { useEffect, useState } from 'react'
import { getCatalogStatus } from 'services/books/books'
import { getAllUtilsList } from 'services/schoolUserServices/listOfElements'
import { handleErrors } from 'utilities/handleErrors'
export function useUtilsTable() {
  const UtilsColumns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Marca',
      dataIndex: 'make',
      key: 'make'
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Presentación',
      dataIndex: 'presentation',
      key: 'presentation'
    },

    {
      title: 'Estatus',
      dataIndex: 'status_name',
      key: 'status_name',
      render: (status: any) => {
        return <Tags variation={status} title={status} />
      }
    }
  ]
  const [status, setStatus] = useState('')
  const [utils, setUtils] = useState([])
  const [loading, setloading] = useState(true)
  const [statusCatalog, setStatusCatalog] = useState<Options[]>([])
  const [search, setsearch] = useState('');

  const handleChangePage = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: e
      }
    })
  }
  const [pagination, setpagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    onChange: handleChangePage
  })

  useEffect(() => {
    hangleGetStatusCatalog()
  }, [])

  useEffect(() => {
    hangleGetUtils(status, pagination.current)
  }, [status, pagination.current])

  const hangleGetUtils = async (status: string, page: number, search:string='') => {
    setloading(true)
    try {
      const result = await getAllUtilsList({ status, page, search })
      setUtils(result.data.data.supplies)
      setpagination((prev) => {
        return {
          ...prev,
          current: result.data.data.page,
          pageSize: 20,
          total: result.data.data.total
        }
      })
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false)
    }
  }

  const hangleGetStatusCatalog = async (): Promise<any> => {
    try {
      const res = await getCatalogStatus()
      setStatusCatalog([
        { label: 'Todos', value: 'todos' },
        ...res?.data?.status
      ])
    } catch (error) {
      handleErrors(error)
    }
  }
  
  const onHandleChangeStatus = (e: any) => {
    setpagination((prev) => {
      return {
        ...prev,
        current: 1
      }
    })
    if (e === 'todos') return setStatus('')
    return setStatus(e)
  }
  let timeOutId: ReturnType<typeof setTimeout>

  const handleSearch = (value: string) => {
    setsearch(value ? value : '')
    setpagination((prev) => {
      return { ...prev, current: 1 }
    })
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(async () => {
      hangleGetUtils(status, 1, value)
    }, 1000)
  }
  return {
    utils,
    status,
    loading,
    pagination,
    UtilsColumns,
    statusCatalog,
    search,
    setStatus,
    onHandleChangeStatus,
    handleSearch
  }
}
