import React from 'react'

const GreenTagSueter = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Green_Tag" data-name="Green Tag" stroke='black'>
    <g id="Green_Tag-2" data-name="Green Tag">
      <polygon   points="417.11 156.68 381.95 156.48 382.08 125.84 417.26 126.04 417.11 156.68"/>
      <rect   x="383.79" y="127.93" width="31.65" height="26.33"/>
      <g>
        <path   d="m396.15,132.74s.25,0,.7.14c.45.15.94.38,1.32.58.37.2.77.51.89.65.13.14.41.51.43.59.02.08-.01.28-.1.43-.1.15-.34.45-.45.55-.1.1-.21.18-.23.19-.03,0-.05.04-.05.04,0,0,0,.02,0,.04,0,.01.02.06.02.06,0,0-.26.15-.52.29-.25.14-.44.22-.54.27-.09.05-.18.11-.18.11,0,0-.02.05,0,.05s.45-.04,1.29-.2c.83-.16,1.33-.27,1.66-.43.33-.15.47-.34.55-.44.08-.09.1-.17.13-.27.02-.09.04-.15.04-.15,0,0,.23-.07.39-.15.15-.08.36-.24.43-.3.08-.06.2-.1.27-.12.06-.02.17-.05.17-.05,0,0-.03-.06-.04-.07-.02-.01-.1-.11-.1-.17s-.12-.22-.2-.22c-.07,0-.22,0-.3.01-.08.02-.21.03-.26.04-.05,0-.09,0-.09,0,0,0,0-.05.03-.08.03-.03.19-.4.17-.58-.03-.19-.16-.68-.3-.98-.14-.3-.35-.72-.42-.82-.06-.1-.1-.13-.11-.09-.02.05-.01.22,0,.39.02.17.03.3.04.33.01.03,0,.17,0,.2,0,.02-.03.1-.02.1.01,0,.02.05.02.05l-.08.02s-.02.09-.03.14c-.02.05-.08.24-.16.44-.09.2-.18.39-.24.48-.05.09-.11.15-.14.15-.03,0-.28-.12-.57-.25-.3-.14-.52-.29-.83-.5-.31-.21-.71-.73-.77-.81-.05-.08-.22-.31-.39-.55-.17-.24-.41-.56-.5-.67-.09-.11-.22-.23-.25-.26-.03-.04-.1-.09-.12-.08-.02.01,0,.04.02.08.02.04.28.38.43.59.15.21.46.66.54.8.08.14.23.4.28.5.05.1.07.25.07.25,0,0-.05,0-.21-.04-.16-.04-.76-.26-.89-.29-.13-.03-.46-.11-.55-.11-.08,0-.13,0-.17.01-.04,0-.15.02-.1.09Z"/>
        <g>
          <path   d="m391.12,139.8v.12l-.31.09s-.05.02-.07.04c-.01.01-.02.04-.03.07-.01.07-.02.24-.02.51,0,.16,0,.38.02.66-.06.01-.19.04-.38.1-.26.06-.42.1-.49.11-.13.01-.27.03-.42.03-.48,0-.84-.14-1.09-.42-.25-.28-.38-.66-.38-1.15,0-.53.15-.96.45-1.28.3-.33.7-.49,1.19-.49.12,0,.24,0,.36.03.02,0,.26.05.69.14,0,.08,0,.15,0,.2,0,.2.01.43.03.69h-.16c-.06-.26-.12-.43-.17-.51-.05-.08-.14-.15-.26-.2-.17-.07-.34-.11-.53-.11-.42,0-.74.18-.94.53-.15.25-.22.56-.22.92,0,.46.12.82.35,1.06.23.24.52.36.88.36.22,0,.46-.05.7-.16.01-.16.01-.4.01-.71,0-.18,0-.27-.01-.3,0-.03-.02-.05-.05-.07-.03-.02-.12-.05-.27-.08l-.08-.02v-.12l.69.02c.15,0,.33,0,.52-.02Z"/>
          <path   d="m391.38,138.8v-.12c.33.02.6.02.8.02.12,0,.23,0,.33,0,.14,0,.23,0,.28,0,.25,0,.43.06.56.18.13.12.19.29.19.5,0,.15-.03.27-.08.38-.06.11-.14.21-.25.28-.07.05-.19.1-.33.14l.63.87c.08.11.13.17.16.19.03.03.07.05.12.07.07.03.19.07.36.11v.12c-.2-.01-.35-.02-.44-.02-.1,0-.22,0-.33.02-.05-.1-.13-.23-.23-.38-.1-.15-.16-.24-.18-.27-.01-.01-.02-.03-.04-.05l-.42-.61c-.11,0-.22-.01-.34-.03v1.01s.03.07.05.09c.03.03.13.06.28.09.02,0,.06.01.1.03v.12c-.23-.01-.43-.02-.6-.02-.19,0-.4,0-.63.02v-.12l.08-.02c.18-.04.28-.07.31-.1.03-.02.04-.06.04-.12,0-.04,0-.38.01-1.03v-.94c-.01-.15-.02-.23-.02-.25,0-.02-.02-.04-.04-.05-.07-.03-.16-.07-.3-.09-.02,0-.05,0-.09-.02Zm.79,1.22c.13.02.24.03.31.03.23,0,.4-.06.51-.16.11-.11.16-.26.16-.45,0-.17-.05-.3-.14-.4-.1-.1-.25-.15-.43-.15-.07,0-.2.01-.4.02v1.12Z"/>
          <path   d="m397.55,139.94h.43c.16,0,.27,0,.3-.01.03,0,.05-.03.07-.06.02-.04.04-.12.07-.27,0-.02.01-.04.02-.07h.12c0,.22-.02.39-.02.51s.01.29.02.5h-.12c-.04-.2-.05-.3-.07-.32-.01-.03-.03-.05-.07-.07-.03-.02-.11-.02-.27-.02h-.49v1.17h.83c.1,0,.17,0,.19-.02.04-.02.09-.05.12-.09.04-.04.1-.16.19-.34.01-.02.03-.05.04-.08h.13s-.09.29-.22.75c-.43-.01-.83-.02-1.19-.02-.25,0-.53,0-.83.02v-.12l.29-.07s.07-.03.08-.04c.02-.03.03-.08.03-.16v-.51c.01-.09.01-.27.01-.53,0-.39,0-.67,0-.85,0-.18,0-.28-.02-.31,0-.02-.02-.03-.03-.04-.02,0-.07-.03-.17-.05l-.19-.05v-.12c.22.01.49.02.8.02.17,0,.58,0,1.22-.02,0,.23.02.46.05.68h-.13c-.05-.18-.08-.3-.11-.36-.02-.04-.05-.07-.08-.08-.04-.02-.13-.02-.26-.02h-.72v.65s-.02.39-.02.39Z"/>
          <path   d="m395.02,139.94h.43c.16,0,.26,0,.29-.01.03,0,.05-.03.07-.06.02-.04.04-.12.07-.27,0-.02,0-.04.02-.07h.11c0,.22-.02.39-.02.51s.01.29.02.5h-.11c-.03-.2-.06-.3-.07-.32-.01-.03-.03-.05-.06-.07-.03-.02-.12-.02-.27-.02h-.49v1.17h.83c.1,0,.17,0,.19-.02.05-.02.09-.05.12-.09.04-.04.1-.16.19-.34.01-.02.02-.05.04-.08h.13s-.09.29-.22.75c-.44-.01-.84-.02-1.19-.02-.25,0-.53,0-.83.02v-.12l.29-.07s.08-.03.09-.04c.01-.03.03-.08.03-.16v-.51c.01-.09.01-.27.01-.53,0-.39,0-.67,0-.85,0-.18,0-.28-.02-.31,0-.02-.02-.03-.03-.04-.01,0-.07-.03-.17-.05l-.19-.05v-.12c.23.01.49.02.81.02.17,0,.58,0,1.22-.02,0,.23.01.46.04.68h-.13c-.05-.18-.09-.3-.11-.36-.02-.04-.05-.07-.08-.08-.04-.02-.13-.02-.26-.02h-.72l-.02.65v.39Z"/>
          <path   d="m399.34,138.75v-.12c.15.02.27.02.36.02.07,0,.2,0,.37-.02l.51.71c.05.06.4.54,1.08,1.45,0-.19,0-.32,0-.41,0-.2,0-.56-.02-1.09,0-.25-.01-.38-.01-.4,0-.02-.02-.04-.04-.06-.02-.02-.11-.04-.25-.07-.02,0-.05-.01-.08-.02v-.12c.21.02.38.02.51.02s.29,0,.51-.02v.12s-.06.01-.07.02c-.16.03-.25.06-.27.07-.01.01-.02.02-.03.04-.02.02-.02.06-.03.12,0,.02,0,.21-.01.55,0,.33,0,.66,0,.98,0,.38,0,.69.02.94l-.12.06-.75-1.03-.98-1.32v1.18c.01.52.02.79.03.83,0,.02.01.04.03.06.03.03.11.05.27.08,0,0,.04,0,.08.02v.11c-.18-.01-.33-.02-.46-.02-.11,0-.29,0-.55.02v-.11s.07-.02.1-.03c.14-.03.23-.06.25-.08.01-.02.02-.04.03-.06.01-.07.02-.3.02-.7,0-.57,0-.91,0-1.01,0-.33,0-.52-.01-.56,0-.02-.02-.05-.05-.06-.02-.02-.14-.05-.34-.08-.02,0-.06-.01-.1-.02Z"/>
          <path   d="m403.65,139.21h-.15c.02-.26.05-.55.07-.88.6.02,1.03.03,1.28.03.22,0,.6,0,1.16-.03.02.34.04.63.06.88h-.15c-.07-.27-.11-.43-.13-.48-.02-.05-.05-.08-.08-.1-.04-.02-.13-.03-.27-.03h-.45c-.01.4-.02.89-.02,1.48,0,.54,0,.92.02,1.11,0,.04.01.07.03.09.03.02.14.05.34.1.02,0,.05,0,.08.02v.13c-.27-.02-.51-.03-.72-.03-.2,0-.4,0-.61.03v-.13s.08-.03.1-.03c.19-.04.3-.06.33-.09.01-.01.02-.03.03-.05,0-.02.01-.19.02-.5,0-.48,0-.83,0-1.07,0-.4,0-.76,0-1.07h-.57c-.08,0-.13.01-.15.03-.04.03-.07.08-.1.16-.02.06-.06.21-.11.42Z"/>
          <path   d="m407.1,138.68h.11c.16.46.35.98.57,1.55l.42,1.01s.04.06.06.08c.01.01.1.04.25.09v.12c-.21-.01-.37-.02-.47-.02s-.27,0-.52.02v-.12c.14-.04.22-.06.24-.08.03-.02.04-.05.04-.07,0-.03-.02-.07-.04-.14l-.18-.48c-.25,0-.43,0-.54,0h-.48s-.19.5-.19.5c-.03.08-.05.13-.05.15s0,.03.02.05c.01,0,.08.03.2.06.01,0,.03,0,.06.02v.12c-.2-.01-.34-.02-.43-.02-.11,0-.24,0-.4.02v-.12c.11-.03.18-.06.22-.09.03-.02.06-.07.1-.13.07-.13.24-.55.51-1.26l.48-1.26Zm-.46,1.76c.16,0,.28,0,.35,0,.02,0,.2,0,.54,0l-.45-1.18-.44,1.18Z"/>
          <path   d="m411.27,140.01v.11l-.27.08s-.05.02-.07.04c-.01.01-.02.04-.02.06-.01.06-.02.21-.02.45,0,.15,0,.34.02.59-.05,0-.16.04-.34.08-.22.06-.37.09-.42.1-.12.01-.23.03-.36.03-.42,0-.74-.12-.96-.37-.23-.25-.34-.59-.34-1.02,0-.46.13-.84.39-1.13.26-.29.61-.44,1.04-.44.11,0,.21,0,.31.02.02,0,.22.05.61.12,0,.07,0,.13,0,.18,0,.18,0,.38.02.6h-.14c-.05-.23-.1-.38-.15-.44-.05-.07-.13-.14-.23-.18-.14-.06-.3-.09-.46-.09-.37,0-.64.15-.83.46-.13.22-.19.49-.19.81,0,.41.1.73.31.94.2.21.46.32.77.32.2,0,.4-.05.62-.14,0-.14.01-.35.01-.63,0-.15,0-.24-.02-.27,0-.03-.02-.05-.04-.06-.03-.02-.11-.05-.24-.07l-.07-.02v-.11h.61c.14.01.29,0,.45,0Z"/>
        </g>
      </g>
      <g>
        <path   d="m390.15,151.28h-.75v.4c0,.13,0,.22.01.26,0,.04.02.07.04.09.02.02.07.03.13.03h.04v.06h-.65v-.06h.03c.06,0,.11,0,.13-.03.02-.02.04-.05.05-.08,0-.03.01-.13.01-.27v-.81c0-.14,0-.22-.01-.26,0-.04-.02-.06-.05-.08-.02-.01-.07-.02-.14-.02h-.03v-.06h.65v.06h-.03c-.07,0-.11,0-.14.02-.02.01-.04.04-.04.07,0,.04-.01.12-.01.27v.33h.75v-.33c0-.14,0-.22,0-.26,0-.04-.02-.06-.04-.08-.02-.01-.07-.02-.15-.02h-.04v-.06h.66v.06h-.02c-.07,0-.12,0-.14.02-.02.01-.03.04-.04.07,0,.04,0,.12,0,.27v.81c0,.13,0,.22,0,.26,0,.04.02.07.05.09.02.02.07.03.13.03h.03v.06h-.66v-.06h.05c.06,0,.11,0,.13-.03.02-.02.04-.05.05-.08,0-.03,0-.13,0-.27v-.4Z"/>
        <path   d="m391.89,151.6h.05l-.03.52h-1.2v-.06h.03c.07,0,.12,0,.14-.03.02-.02.03-.05.04-.08,0-.03,0-.12,0-.26v-.81c0-.13,0-.22,0-.25,0-.04-.01-.07-.04-.09-.03-.01-.07-.02-.14-.02v-.06h1.04l.04.42h-.05c-.02-.12-.05-.2-.08-.25s-.07-.08-.11-.09c-.05-.01-.12-.02-.22-.02h-.2v.69h.15c.07,0,.12,0,.14-.02.04-.02.05-.03.06-.06,0-.03.01-.08.01-.16h.05v.56h-.05c0-.09-.01-.15-.02-.19-.01-.03-.03-.05-.06-.06-.03-.01-.07-.02-.13-.02h-.16v.39c0,.2.01.31.03.35.02.04.08.05.17.05h.11c.08,0,.14,0,.19-.03.05-.02.1-.07.14-.14.04-.07.08-.16.1-.28Z"/>
        <path   d="m393.39,151.49h.05v.58h-.05c0-.06-.04-.13-.06-.17-.13.17-.3.24-.49.24-.21,0-.38-.07-.51-.22-.13-.15-.2-.35-.2-.6,0-.16.03-.32.1-.46.06-.15.15-.25.26-.33.11-.06.22-.1.36-.1.11,0,.21.02.3.07.09.05.15.11.2.17.02-.07.05-.15.05-.22h.05v.63s-.04,0-.04,0c-.03-.23-.1-.38-.19-.47-.1-.08-.21-.13-.33-.13-.15,0-.27.07-.36.21-.09.14-.14.34-.14.61s.05.44.15.57c.1.13.22.2.36.2.08,0,.16-.02.24-.06.08-.05.15-.12.19-.22.04-.1.08-.2.08-.3Z"/>
        <path   d="m394.83,151.28h-.75v.4c0,.13,0,.22,0,.26,0,.04.02.07.04.09.02.02.07.03.13.03h.04v.06h-.65v-.06h.04c.06,0,.1,0,.13-.03.02-.02.04-.05.05-.08,0-.03,0-.13,0-.27v-.81c0-.14,0-.22,0-.26,0-.04-.02-.06-.05-.08-.02-.01-.07-.02-.14-.02h-.03v-.06h.65v.06h-.03c-.07,0-.12,0-.14.02-.02.01-.04.04-.04.07,0,.04-.01.12-.01.27v.33h.75v-.33c0-.14,0-.22,0-.26,0-.04-.02-.06-.04-.08-.02-.01-.07-.02-.14-.02h-.04v-.06h.66v.06h-.02c-.07,0-.12,0-.14.02-.02.01-.04.04-.04.07-.01.04-.01.12-.01.27v.81c0,.13,0,.22,0,.26,0,.04.02.07.04.09.02.02.07.03.13.03h.03v.06h-.66v-.06h.05c.06,0,.1,0,.13-.03.03-.02.04-.05.05-.08,0-.03,0-.13,0-.27v-.4Z"/>
        <path   d="m395.44,151.28c0-.26.07-.47.22-.62.15-.15.33-.23.56-.23s.42.07.57.23c.15.15.22.36.22.62s-.07.47-.22.63c-.14.16-.33.24-.57.24s-.41-.08-.56-.24c-.15-.16-.22-.37-.22-.63Zm.24,0c0,.27.05.47.14.6.09.14.23.2.42.2.16,0,.29-.07.38-.2.1-.13.14-.33.14-.6s-.04-.46-.14-.59c-.09-.14-.23-.21-.41-.21s-.31.07-.4.2c-.09.13-.14.33-.14.59Z"/>
        <path   d="m398.92,151.6h.05l-.03.52h-1.19v-.06h.03c.07,0,.11,0,.13-.03.02-.02.04-.05.04-.08,0-.03,0-.12,0-.26v-.81c0-.13,0-.22-.01-.25,0-.04-.02-.07-.04-.09-.02-.01-.06-.02-.13-.02v-.06h1.03l.04.42h-.05c-.02-.12-.05-.2-.09-.25-.03-.05-.07-.08-.12-.09-.04-.01-.11-.02-.22-.02h-.19v.69h.15c.08,0,.12,0,.15-.02.03-.02.05-.03.05-.06.01-.03.02-.08.02-.16h.04v.56h-.04c0-.09,0-.15-.02-.19-.01-.03-.03-.05-.06-.06-.02-.01-.07-.02-.13-.02h-.15v.39c0,.2,0,.31.03.35.02.04.08.05.18.05h.1c.08,0,.14,0,.19-.03.05-.02.1-.07.14-.14.04-.07.08-.16.1-.28Z"/>
        <path   d="m400.38,152.15l-1.02-1.56v1.16c0,.1,0,.16.01.19.01.03.03.06.07.09.04.03.09.04.17.04v.06h-.54v-.06c.07,0,.13-.02.16-.04.04-.02.05-.05.07-.07,0-.03,0-.09,0-.2v-.87c0-.13,0-.22-.02-.26,0-.05-.02-.07-.05-.09-.02,0-.07-.01-.15-.01v-.06h.43l.86,1.3v-.99c0-.07,0-.12-.02-.15-.02-.03-.04-.05-.08-.07-.04-.02-.09-.03-.15-.03v-.06h.52v.06c-.06,0-.11.02-.15.04-.04.02-.06.05-.07.08,0,.03-.01.08-.01.15v1.37h-.05Z"/>
        <path   d="m402.23,151.83l.53-1.38h.44v.06h-.05c-.07,0-.12,0-.14.02-.02.01-.04.04-.04.07,0,.04-.01.12-.01.27v.81c0,.13,0,.22.01.26,0,.04.02.07.04.09.02.02.07.03.14.03h.06v.06h-.69v-.06h.05c.07,0,.1,0,.13-.03.02-.02.04-.05.04-.08,0-.03.01-.13.01-.27v-1.06l-.57,1.49h-.05l-.57-1.49v.97c0,.14,0,.22.01.27,0,.05.03.09.07.13.04.03.09.05.16.06v.06h-.52v-.06c.06,0,.11-.02.15-.05.04-.03.06-.08.07-.13,0-.05,0-.15,0-.28v-.72c0-.14,0-.22,0-.26-.01-.04-.02-.06-.04-.08-.02-.01-.07-.02-.14-.02h-.04v-.06h.44l.53,1.38Z"/>
        <path   d="m404.48,151.6h.05l-.03.52h-1.19v-.06h.03c.08,0,.11,0,.14-.03.02-.02.03-.05.04-.08,0-.03,0-.12,0-.26v-.81c0-.13,0-.22,0-.25,0-.04-.02-.07-.04-.09-.02-.01-.07-.02-.14-.02v-.06h1.04l.03.42h-.05c-.02-.12-.05-.2-.08-.25-.04-.05-.07-.08-.12-.09-.04-.01-.12-.02-.22-.02h-.2v.69h.15c.07,0,.12,0,.15-.02.03-.02.04-.03.06-.06,0-.03.01-.08.01-.16h.05v.56h-.05c0-.09,0-.15-.02-.19,0-.03-.03-.05-.06-.06-.02-.01-.07-.02-.13-.02h-.16v.39c0,.2.01.31.03.35.02.04.08.05.18.05h.1c.08,0,.14,0,.19-.03.05-.02.1-.07.14-.14.04-.07.08-.16.1-.28Z"/>
        <path   d="m405.51,151.2l.37.52c.13.19.22.29.25.31.04.02.08.03.14.03v.06h-.72v-.06c.08,0,.13,0,.15-.02.03-.01.04-.04.04-.07,0-.02,0-.05-.02-.07-.01-.02-.05-.07-.1-.15l-.26-.37-.23.32c-.05.07-.09.12-.1.15-.02.03-.03.06-.03.09,0,.08.06.13.17.13v.06h-.56v-.06c.06,0,.11-.02.16-.04.05-.03.09-.06.13-.11.04-.05.1-.13.18-.24l.24-.33-.38-.53-.12-.17s-.05-.07-.08-.09c-.03-.02-.07-.03-.13-.03v-.06h.62v.06h-.02c-.08,0-.12.02-.12.07,0,.02,0,.04.02.06.01.02.04.07.09.13l.27.37.24-.35c.05-.07.08-.12.09-.14s.02-.04.02-.06c0-.04-.02-.06-.05-.07-.03-.01-.07-.02-.14-.02v-.06h.54v.06c-.09,0-.17.03-.23.09-.06.06-.13.14-.21.26l-.23.33Z"/>
        <path   d="m406.34,150.51v-.06h.66v.06h-.03c-.07,0-.12,0-.14.02-.02.01-.03.04-.04.07,0,.04,0,.12,0,.27v.81c0,.13,0,.22,0,.26,0,.04.02.07.04.09.03.02.07.03.14.03h.04v.06h-.66v-.06h.04c.06,0,.1,0,.13-.03.03-.02.04-.05.05-.08,0-.03.01-.13.01-.27v-.81c0-.14,0-.22-.01-.26,0-.04-.02-.06-.04-.08-.02-.01-.07-.02-.14-.02h-.03Z"/>
        <path   d="m408.43,151.49h.05v.58h-.05c-.01-.06-.04-.13-.07-.17-.13.17-.3.24-.49.24-.21,0-.38-.07-.51-.22-.13-.15-.2-.35-.2-.6,0-.16.03-.32.1-.46.06-.15.14-.25.25-.33.11-.06.23-.1.36-.1.11,0,.2.02.3.07.08.05.15.11.2.17.03-.07.04-.15.05-.22h.04l.02.63h-.05c-.03-.23-.1-.38-.19-.47-.1-.08-.21-.13-.34-.13-.15,0-.27.07-.36.21-.09.14-.13.34-.13.61s.05.44.15.57c.1.13.22.2.36.2.08,0,.16-.02.24-.06.08-.05.15-.12.19-.22.04-.1.07-.2.08-.3Z"/>
        <path   d="m408.73,151.28c0-.26.08-.47.22-.62.15-.15.33-.23.56-.23s.41.07.56.23c.15.15.22.36.22.62s-.07.47-.21.63c-.15.16-.34.24-.57.24s-.42-.08-.56-.24c-.14-.16-.22-.37-.22-.63Zm.24,0c0,.27.05.47.14.6.09.14.23.2.42.2.16,0,.29-.07.38-.2.09-.13.14-.33.14-.6s-.05-.46-.14-.59c-.1-.14-.23-.21-.41-.21s-.31.07-.4.2c-.09.13-.14.33-.14.59Z"/>
      </g>
      <path   d="m398.77,146.77l-.02-.2c.22-.45.52-.82.83-1.19.24-.28.48-.61.48-1.01,0-.32-.19-.63-.5-.63-.19,0-.4.12-.4.35,0,.15.11.22.11.36,0,.12-.1.23-.21.23-.16,0-.25-.16-.25-.32,0-.46.43-.77.81-.77.41,0,.84.31.84.8,0,.45-.32.76-.61,1.02-.44.38-.54.5-.84,1.02h.39c.25,0,.46,0,.6-.06.12-.05.23-.2.33-.46l.12.03-.17.82h-1.52Z"/>
    </g>
  </g>
    </svg>
  )
}

export default GreenTagSueter
