import React, { Fragment, useEffect, useState } from 'react'
import { Input, Select, Skeleton, Space, Table, Tag } from 'antd'
import TitleHeader from 'components/TitleHeader/TitleHeader'

import style from './comision.module.scss'
import { getAllSchoolsList } from 'services/schoolAdminServices/schools'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import { ColumnsType } from 'antd/es/table'
import {
  userManagementGetUsersList,
  userManagementGetUsersListBySchool
} from 'services/userManagement/getUsers'
import {
  IUserApi,
  IUserTableDataAnt
} from 'interfaces/user-management-interface'
import { ISchools } from 'interfaces/schools-interface'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const ComisionesPorColegioModule = () => {
  const [usersData, setUsersData] = useState<null | IUserTableDataAnt[]>(null)
  const [usersDataCopy, setUsersDataCopy] = useState<
    null | IUserTableDataAnt[]
  >(null)
  const [SchoolsList, setSchoolsList] = useState<ISchools[]>([])
  const [search, setSearch] = useState('')

  const navigate = useNavigate()

  const getApiUsers = () => {
    const getLabelSchools = (data: ISchools[]) => {
      let arr: ISchools[] = []
      data.forEach((item: ISchools) => {
        arr.push({ ...item, label: item.name })
      })
      setSchoolsList(arr)
    }
    userManagementGetUsersList().then((users) => {
      setUsersData(changeData(users.data.users))
      setUsersDataCopy(changeData(users.data.users))
    })

    getAllSchoolsList().then((dat) => setSchoolsList(dat.data.schools))
  }
  const changeData = (dat: IUserApi[]) => {
    let user: IUserTableDataAnt[] = []
    dat.forEach((item) => {
      user.push({ ...item, key: item.id })
    })
    return user
  }
  const getSchoolListById = (idSchool: number) =>
    userManagementGetUsersListBySchool(idSchool)
      .then((users) => {
        setUsersData(changeData(users.data.users))
        setUsersDataCopy(changeData(users.data.users))
      })
      .catch((e) => console.log(e))
  const onSchoolChange = (value: string) => {
    if (value !== undefined) {
      const selected = value.split('$#-#$')
      getSchoolListById(parseInt(selected[1]))
    } else {
      getApiUsers()
    }
  }
  useEffect(() => {
    getApiUsers()
  }, [])

  const getSearch = () => {
    let list: any[] = []
    usersData?.forEach((item) => {
      const { name, first_surname, second_surname, mail } = item
      const word =
        `${name} ${first_surname} ${second_surname} ${mail}`.toUpperCase()
      if (word.includes(search.toUpperCase())) list.push(item)
    })
    setUsersDataCopy(list)
  }

  useEffect(() => {
    getSearch()
  }, [search])

  const columns: ColumnsType<any> = [
    {
      title: 'Nombre de la escuela',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Convenio',
      dataIndex: 'status',
      key: 'status',
      render: (_, scope) => (
        <>
          <Tag
            color={scope.status === 'Autorizado' ? 'success' : 'processing'}
            key={scope.address}
          >
            {scope.status === 'Autorizado' ? 'Con convenio' : 'Sin convenio'}
          </Tag>
        </>
      )
    },
    {
      title: 'Productos sin comisión',
      dataIndex: 'status',
      key: 'status',
      render: (_, scope) => (
        <>
          <p className={style['p-products']}>2</p>
        </>
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'status',
      key: 'status',
      render: (_, scope) => (
        <>
          <ClipboardDocumentListIcon
            onClick={() =>
              navigate(`/comisiones/convenios-por-colegio/${scope.id}`)
            }
            className={`${style['icon-svg']}`}
          />
        </>
      )
    }
  ]

  return (
    <MainTemplate>
      <TitleHeader
        IconName='UserIcons'
        widthIcon={'2rem'}
        heightIcon={'2rem'}
        title='Gestion de convenios por colegio'
        disabled={false}
        variaton='4'
        buttonProps={[
          {
            label: 'Gestion de comisiones',
            onClick: () => undefined,
            rounded: true,
            size: 'm',
            sector: 'secondary'
          }
        ]}
      />
      {true && (
        <Space.Compact>
          <Select
            showSearch
            style={{ minWidth: 200 }}
            placeholder='Selecciona una escuela'
            optionFilterProp='children'
            size='large'
            allowClear
            onChange={onSchoolChange}
            filterOption={(input: any, option: any) => {
              return (
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >
                0
              )
            }}
          >
            {SchoolsList?.map((item: any, index: number) => (
              <Select.Option
                key={`${item.name + index}`}
                value={`${item.name}$#-#$${item.id}`}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <Input.Search
            placeholder='buscar por nombre, apellido, correo...'
            onSearch={() => getSearch()}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            style={{ width: 'auto' }}
            size='large'
          />
        </Space.Compact>
      )}
      <Skeleton loading={false} active>
        <Table
          className={style.booksTable}
          showSorterTooltip={{ title: 'Clic para ordenar' }}
          dataSource={SchoolsList}
          style={{ marginTop: '2.5rem' }}
          columns={columns}
          rowKey={(record) => record.id}
          loading={false}
          onRow={(_, __) => {
            return {
              onClick: () => undefined
            }
          }}
        />
      </Skeleton>
    </MainTemplate>
  )
}

export default ComisionesPorColegioModule
