import { AUTHSERVICE } from '../config'

export const getLogs = async (type: string): Promise<any> => {
  return await AUTHSERVICE().get(`/logs?type=${type}`)
}

export const getDetailedLog = async ({type,id}:{type:string,id:number|string}): Promise<any> => {
  return await AUTHSERVICE().get(`/logs?type=${type}&id_module=${id}`)
}

export const getGeneralModulesStatus = async (): Promise<any> => {
  return await AUTHSERVICE().get(`/general-status`)
}

export const sendEndListPDF = async (type:string): Promise<any> => {
  return await AUTHSERVICE().get(`/custom-modules/pdf/${type}`)
}

export const getCategoriesForCreateItemsForms = async (type:'Libros'|'Útiles'|'Cuadernos'): Promise<any> => {
  return await AUTHSERVICE().get(`/klaar/category?group=${type}`)
}

export const getOptionsListForProducts = async (type:'Libros'|'Útiles'|'Cuadernos'): Promise<any> => {
  return await AUTHSERVICE().get(`/klaar/catalog?group=${type}`)
}