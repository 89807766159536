import { createSlice } from '@reduxjs/toolkit'
import {
  IPermissionsRoles,
  ISchoolsNewUser
} from 'interfaces/user-management-interface'

const usersRoles: { label: string }[] = [
  { label: 'Ezetera' },
  { label: 'Administrador' },
  { label: 'Director' },
  { label: 'Responsable de listas' }
]

interface IInputsForm {
  [key: string]: {
    label: string
    name: string
    placeholder: string
    value: string
    type: string
    required: true
    maxLength: number //maxLength solo funciona para tipo text
    status: string
  }
}

const INPUTS_INITIAL_STATE: IInputsForm = {
  name: {
    label: 'Nombre del usuario',
    name: 'name',
    placeholder: 'John Ferras',
    value: '',
    type: 'text',
    required: true,
    maxLength: 30,
    status: ''
  },
  first_surname: {
    label: 'Primer apellido',
    name: 'first_surname',
    placeholder: 'Doe',
    value: '',
    type: 'text',
    required: true,
    maxLength: 30,
    status: ''
  },
  second_surname: {
    label: 'Segundo apellido',
    name: 'second_surname',
    placeholder: 'Perez',
    value: '',
    type: 'text',
    required: true,
    maxLength: 30,
    status: ''
  },
  mail: {
    label: 'Email',
    name: 'mail',
    placeholder: 'example@gmail.com',
    value: '',
    type: 'email',
    required: true,
    maxLength: 0,
    status: ''
  },
  position: {
    label: 'Puesto',
    name: 'position',
    placeholder: 'puesto que ocupa',
    value: '',
    type: 'text',
    required: true,
    maxLength: 300,
    status: ''
  },
  description: {
    label: 'Descripción',
    name: 'description',
    placeholder: 'Descripción del puesto',
    value: '',
    type: 'text',
    required: true,
    maxLength: 300,
    status: ''
  }
}

const dataUserInit = {
  first_surname: '',
  id: 0,
  isenabled: '',
  mail: '',
  name: '',
  role: '',
  second_surname: '',
  id_school: { id: 0, label: '' }
}

const getThisValue = (
  payload: any,
  action:
    | 'role'
    | 'deleted'
    | 'formUser'
    | 'id'
    | 'id_school'
    | 'sales_manager'
    | 'permissions'
    | 'responsabilities'
    | 'schools'
) => {
  let obj: IInputsForm = {}
  switch (action) {
    case 'role':
      return payload.role
    case 'deleted':
      return payload.is_deleted
    case 'id':
      return payload.id
    case 'sales_manager':
      return payload.sales_manager
    case 'id_school':
      if (payload.id_school !== undefined)
        return [{ id: payload.id_school, label: '' }]
      else return []
    case 'formUser':
      Object.keys(payload).forEach((item: string) => {
        if (
          item !== 'id' &&
          item !== 'is_enabled' &&
          item !== 'is_deleted' &&
          item !== 'role' &&
          item !== 'sales_manager' &&
          item !== 'id_school' &&
          item !== 'permissions' &&
          item !== 'responsabilities' &&
          item !== 'schools'
        )
          obj[item] = {
            ...INPUTS_INITIAL_STATE[item],
            value: `${payload[item]}`
          }
      })

      return obj
    case 'permissions':
      return payload.permissions
    case 'responsabilities':
      return payload.responsabilities
    case 'schools':
      return payload.schools || []

    default:
      break
  }
}
interface SchoolIdI {
  label: string
  id: number
}

const userManagementSlice = createSlice({
  name: 'userManagementSlice',
  initialState: {
    dataUser: dataUserInit,
    formUserValues: INPUTS_INITIAL_STATE as IInputsForm,
    role: null as null | string,
    schoolId: [] as SchoolIdI[],
    isOnEdith: false,
    deletedUser: false,
    userId: 0,
    salesManager: false,
    permiisions: [] as number[],
    responsabilities: [] as number[],
    globalRoles: usersRoles,
    thisUserRole: '',
    thisUserIdShcool: 0,
    schools: [],
    newPermissionsResponsabilities: [] as IPermissionsRoles[]
  },
  reducers: {
    setThisUserIdSchool: (state, action) => {
      state.thisUserIdShcool = action.payload
    },
    setThisUserRole: (state, action) => {
      state.thisUserRole = action.payload
    },
    setDataUser: (state, action) => {
      state.dataUser = action.payload
    },
    setRole: (state, action) => {
      state.role = action.payload
    },
    setSchoolId: (state, action) => {
      state.schoolId = action.payload
    },
    setFormDataUser: (state, action) => {
      state.schools = getThisValue(action.payload, 'schools')
      state.formUserValues = getThisValue(action.payload, 'formUser')
      state.role = getThisValue(action.payload, 'role')
      state.deletedUser = getThisValue(action.payload, 'deleted')
      state.isOnEdith = true
      state.userId = getThisValue(action.payload, 'id')
      state.schoolId = getThisValue(action.payload, 'id_school')
      state.salesManager = getThisValue(action.payload, 'sales_manager')
      state.permiisions = getThisValue(action.payload, 'permissions')
      state.responsabilities = getThisValue(action.payload, 'responsabilities')
    },
    setCheckSalesUser: (state, action) => {
      state.salesManager = action.payload
    },
    resetFormDataUser: (state) => {
      state.formUserValues = INPUTS_INITIAL_STATE
      state.role = null
      state.dataUser = dataUserInit
      state.schoolId = []
      state.isOnEdith = false
      state.deletedUser = false
      state.userId = 0
      state.salesManager = false
      state.permiisions = []
      state.responsabilities = []
      state.schools = []
    }
  }
})

export const {
  setThisUserRole,
  setDataUser,
  setFormDataUser,
  setRole,
  setSchoolId,
  setCheckSalesUser,
  resetFormDataUser,
  setThisUserIdSchool
} = userManagementSlice.actions

export const userManagementReducer = userManagementSlice.reducer
