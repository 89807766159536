import ChalecoSvg from 'assets/img/uniforms/chaleco/ChalecoSvg'
import GreenTagChaleco from 'assets/img/uniforms/chaleco/GreenTagChaleco'
import LabelChaleco from 'assets/img/uniforms/chaleco/LabelChaleco'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from '../poloeditable.module.scss'


const ChalecoPics = () => {
    const { pictureLogo} = useSelector(
        (state: RootState) => state.uniforms
      )
    return (
    <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div
            className={`${
              style[`logo-position-${"left"}`]
            }`}
          >
            <img className={`${style['svg-logo']}`} src={pictureLogo} />
          </div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <ChalecoSvg className={`${style['svg']}`} />
          </div>

          <div className={`${style['div-label-svg-absolute']}`}>
            <LabelChaleco className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-tags-svg-absolute']}`}>
            <GreenTagChaleco className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
  )
}

export default ChalecoPics