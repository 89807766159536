import { AutoComplete, Form, Input } from 'antd'
import { memo, KeyboardEvent } from 'react'
import { Options } from 'types'

export interface InputFormProps {
  name: string
  options?: Options[]
  placeholder: string
  label?: string | JSX.Element
  type?:string
  maxLength?: number
  rules?: Array<any>
  prefix?: JSX.Element
  suffix?: JSX.Element
  onSearch: (search: string) => Promise<any>
  onkeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  disabled?:boolean
  hasFeedback?:boolean

}

const FormAutoComplete = memo((props: InputFormProps) => {
  return (
    <Form.Item
      name={props.name}
      rules={props.rules}
      label={props.label}
      hasFeedback={props?.hasFeedback != undefined ? props.hasFeedback: true}
    >
      <AutoComplete
        dropdownMatchSelectWidth={1}
        onSearch={props.onSearch}
        filterOption={true}
        options={props.options}
        disabled={props.disabled}
      >
        <Input type={props.type} size='large' style={{height:"41px"}} placeholder={props.placeholder} disabled={props.disabled}/>
      </AutoComplete>
    </Form.Item>
  )
})

export default FormAutoComplete
