/* const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN'
}) */

const formatNumber = (amount: string): string => {
 const parseAmount = parseFloat(amount)
  const formatedAmount = parseAmount.toLocaleString('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatedAmount
}

export default formatNumber
