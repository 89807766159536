import React from 'react'

const DatosFechaLogoHorizontal = ({
  className,
  datos2,
  positionDates,
  dateStyle,
  viewBox = '0 0 800 800',
  marginDate,
  ink,
  page
}: {
  datos2: string
  className: string
  positionDates: string
  dateStyle: number
  viewBox?: string
  marginDate: boolean
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox={viewBox}
      className={className}
    >
      <g>
        <title>Path Cuadros para Datos</title>
        <g id='Capa_2' data-name='Capa 2'>
          <g id='Path_Cuadros_para_Datos' data-name='Path Cuadros para Datos'>
            <g
              id='Path_Cuadros_para_Datos-2'
              data-name='Path Cuadros para Datos'
              fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
              stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
            >
              {(() => {
                let thisPos = positionDates
                if (page === 1) {
                  if (positionDates === 'left') thisPos = 'right'
                  if (positionDates === 'right') thisPos = 'left'
                }
                switch (thisPos) {
                  case 'right':
                    return (
                      <g fill='white'>
                        <g
                          id='datos_nombre_logo_derecho'
                          data-name='datos nombre logo derecho'
                        >
                          <g
                            id='datos_nombre_logo_derecho-2'
                            data-name='datos nombre logo derecho'
                          >
                            {false && (
                              <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                                <path d='M666.61,183.66a20.06,20.06,0,1,1,20.06-20.06A20.08,20.08,0,0,1,666.61,183.66Z' />
                                <path d='M666.61,143.79A19.81,19.81,0,1,1,646.8,163.6a19.84,19.84,0,0,1,19.81-19.81m0-.5a20.31,20.31,0,1,0,20.31,20.31,20.31,20.31,0,0,0-20.31-20.31Z' />
                              </g>
                            )}
                            <rect
                              stroke={`${!marginDate && 'transparent'}`}
                              x='85.52'
                              y='145.84'
                              width='550.27'
                              height='35.52'
                              rx='4.97'
                            />

                            <line
                              x1='85.23'
                              y1='163.6'
                              x2='635.28'
                              y2='163.6'
                            />
                          </g>
                        </g>
                        <g id='Datos_2' data-name='Datos 2'>
                          <g
                            id='Datos_2-2'
                            data-name='Datos 2'
                            fill='black'
                            stroke='transparent'
                          >
                            {(() => {
                              switch (dateStyle) {
                                case 1:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 177.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Fecha:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 177.3)'
                                                >
                                                  Título:
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 2:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha / Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título /
                                                  </tspan>
                                                  <tspan
                                                    className={datos2}
                                                    x='36.68'
                                                    y='0'
                                                  >
                                                    {' '}
                                                  </tspan>
                                                  <tspan
                                                    className={datos2}
                                                    x='39.8'
                                                    y='0'
                                                  >
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Fecha / Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  Título /
                                                  <tspan x='36.68' y='0'>
                                                    {' '}
                                                  </tspan>
                                                  <tspan x='39.8' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 3:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  <tspan>T</tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      </g>
                    )
                  case 'left':
                    return (
                      <g fill='white'>
                        <g
                          id='datos_nombre_logo_izquierda'
                          data-name='datos nombre logo izquierda'
                        >
                          <g
                            id='datos_nombre_logo_derecho-3'
                            data-name='datos nombre logo derecho'
                          >
                            {false && (
                              <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                                <path d='M105.54,183.66A20.06,20.06,0,1,1,125.6,163.6,20.08,20.08,0,0,1,105.54,183.66Z' />
                              </g>
                            )}
                            <g stroke={`${!marginDate && 'transparent'}`}>
                              <rect
                                x='136.36'
                                y='145.84'
                                width='550.27'
                                height='35.52'
                                rx='4.97'
                              />

                              <path d='M681.65,146.14a4.69,4.69,0,0,1,4.68,4.68v25.57a4.69,4.69,0,0,1-4.68,4.68H141.33a4.69,4.69,0,0,1-4.68-4.68V150.82a4.69,4.69,0,0,1,4.68-4.68H681.65m0-.59H141.33a5.26,5.26,0,0,0-5.26,5.27v25.57a5.25,5.25,0,0,0,5.26,5.26H681.65a5.26,5.26,0,0,0,5.27-5.26V150.82a5.27,5.27,0,0,0-5.27-5.27Z' />
                            </g>
                            <line
                              x1='686.92'
                              y1='163.6'
                              x2='136.87'
                              y2='163.6'
                            />
                          </g>
                        </g>
                        <g id='Datos_1' data-name='Datos 1'>
                          <g
                            id='Datos_1-2'
                            data-name='Datos 1'
                            fill='black'
                            stroke='transparent'
                          >
                            {(() => {
                              switch (dateStyle) {
                                case 1:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 159.28) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 177.34) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 159.28)'
                                                >
                                                  Fecha:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 177.34)'
                                                >
                                                  Título:
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 2:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 159.28) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha / Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 177.34) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título /
                                                  </tspan>
                                                  <tspan x='36.79' y='0'>
                                                    {' '}
                                                  </tspan>
                                                  <tspan x='39.91' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.82' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 159.28)'
                                                >
                                                  Fecha / Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 177.34)'
                                                >
                                                  Título /
                                                  <tspan
                                                    x='36.79'
                                                    y='0'
                                                  ></tspan>
                                                  <tspan x='39.91' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.82' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 3:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 159.28) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(677.5 177.34) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='6.9' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 159.28)'
                                                >
                                                  Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(145.2 177.34)'
                                                >
                                                  <tspan>T</tspan>
                                                  <tspan x='6.9' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      </g>
                    )

                  case 'none':
                    return (
                      <g fill='white'>
                        <g
                          id='cuadro_datos_sin_logo'
                          data-name='cuadro datos sin logo'
                        >
                          <g
                            id='datos_nombre_logo_derecho-4'
                            data-name='datos nombre logo derecho'
                          >
                            <g>
                              <g stroke={`${!marginDate && 'transparent'}`}>
                                <path d='m90.73,145.86h590.68c2.87,0,5.2,2.33,5.2,5.2v25.1c0,2.87-2.33,5.2-5.2,5.2H90.73c-2.87,0-5.2-2.33-5.2-5.2v-25.1c0-2.87,2.33-5.2,5.2-5.2Z' />
                                <path d='m681.42,146.16c2.7,0,4.89,2.19,4.89,4.89v25.1c0,2.7-2.19,4.89-4.89,4.89H90.73c-2.7,0-4.89-2.19-4.89-4.89v-25.1c0-2.7,2.19-4.89,4.89-4.89h590.68m0-.61H90.73c-3.04,0-5.5,2.46-5.5,5.5v25.1c0,3.04,2.46,5.5,5.5,5.5h590.68c3.04,0,5.5-2.46,5.5-5.5v-25.1c0-3.04-2.46-5.5-5.5-5.5h0Z' />
                              </g>
                              <line
                                x1='85.23'
                                y1='163.6'
                                x2='686.04'
                                y2='163.6'
                              />
                            </g>
                          </g>
                        </g>
                        <g
                          id='Datos_cuadro_sin_logo'
                          data-name='Datos cuadro sin logo'
                        >
                          <g id='Datos_2-3' data-name='Datos 2'>
                            {(() => {
                              switch (dateStyle) {
                                case 1:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(674.61 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(674.61 177.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 177.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título:
                                                  </tspan>
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 2:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(674.61 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha / Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(674.61 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título /
                                                  </tspan>
                                                  <tspan x='36.68' y='0'>
                                                    {' '}
                                                  </tspan>
                                                  <tspan x='39.8' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha / Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título /
                                                  </tspan>
                                                  <tspan x='36.68' y='0'>
                                                    {' '}
                                                  </tspan>
                                                  <tspan x='39.8' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 3:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(675.37 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(675.37 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )
                                          case 2:
                                            return (
                                              <>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  fill={`${
                                                    ink !== 'black'
                                                      ? '#003da6'
                                                      : 'black'
                                                  }`}
                                                  stroke='transparent'
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      </g>
                    )
                  case 'extremos':
                    return (
                      <g fill='white'>
                        <g
                          id='datos_nombre_logo_derecho'
                          data-name='datos nombre logo derecho'
                        >
                          <g
                            id='datos_nombre_logo_derecho-2'
                            data-name='datos nombre logo derecho'
                          >
                            {false && (
                              <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
                                <path d='M666.61,183.66a20.06,20.06,0,1,1,20.06-20.06A20.08,20.08,0,0,1,666.61,183.66Z' />
                                <path d='M666.61,143.79A19.81,19.81,0,1,1,646.8,163.6a19.84,19.84,0,0,1,19.81-19.81m0-.5a20.31,20.31,0,1,0,20.31,20.31,20.31,20.31,0,0,0-20.31-20.31Z' />
                              </g>
                            )}
                            <rect
                              stroke={`${!marginDate && 'transparent'}`}
                              x='85.52'
                              y='145.84'
                              width='550.27'
                              height='35.52'
                              rx='4.97'
                            />

                            <line
                              x1='85.23'
                              y1='163.6'
                              x2='635.28'
                              y2='163.6'
                            />
                          </g>
                        </g>
                        <g id='Datos_2' data-name='Datos 2'>
                          <g
                            id='Datos_2-2'
                            data-name='Datos 2'
                            fill='black'
                            stroke='transparent'
                          >
                            {(() => {
                              switch (dateStyle) {
                                case 1:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 177.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Fecha:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 177.3)'
                                                >
                                                  Título:
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 2:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Fecha / Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Título /
                                                  </tspan>
                                                  <tspan
                                                    className={datos2}
                                                    x='36.68'
                                                    y='0'
                                                  >
                                                    {' '}
                                                  </tspan>
                                                  <tspan
                                                    className={datos2}
                                                    x='39.8'
                                                    y='0'
                                                  >
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Fecha / Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  Título /
                                                  <tspan x='36.68' y='0'>
                                                    {' '}
                                                  </tspan>
                                                  <tspan x='39.8' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='46.68' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )
                                case 3:
                                  return (
                                    <g>
                                      {(() => {
                                        switch (page) {
                                          case 1:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 159.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    Date:
                                                  </tspan>
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(624.09 176.3) rotate(-180) scale(1 -1)'
                                                >
                                                  <tspan x='0' y='0'>
                                                    T
                                                  </tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )
                                          case 2:
                                            return (
                                              <g>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 159.3)'
                                                >
                                                  Date:
                                                </text>
                                                <text
                                                  className={datos2}
                                                  transform='translate(96.37 176.3)'
                                                >
                                                  <tspan>T</tspan>
                                                  <tspan x='6.88' y='0'>
                                                    itle:
                                                  </tspan>
                                                </text>
                                              </g>
                                            )

                                          default:
                                            return <></>
                                        }
                                      })()}
                                    </g>
                                  )

                                default:
                                  return <></>
                              }
                            })()}
                          </g>
                        </g>
                      </g>
                    )
                  default:
                    return <></>
                }
              })()}
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DatosFechaLogoHorizontal
