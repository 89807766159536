import { IconProps } from '../../../types'

export const ChevronLeft = ({
    height = 24,
    width = 24,
    fill = 'none'
}: IconProps): JSX.Element => {
    return (
        <svg width={width} height={height} fill="currentColor" viewBox="0 0 16 16">
            <path stroke-width="1.0" stroke="currentColor" fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
    )
}