import { createSlice } from '@reduxjs/toolkit'
import {
  IInputsBaseForm,
  IInputsBlusa3_4Form,
  IInputsCamisaDeportivaForm,
  IInputsCapitonadosChalecoForm,
  IInputsCapitonadosChamarraForm,
  IInputsChamarraDeportivaForm,
  IInputsCorbataForm,
  IInputsFaldaForm,
  IInputsFaldaShortDeportivoForm,
  IInputsHoodieForm,
  IInputsJumperForm,
  IInputsPantalonForm,
  IInputsPantsForm,
  IInputsPoloFemaleForm,
  IInputsPoloMaleForm,
  IInputsSacoForm,
  IInputsSacoTraseroForm,
  IInputsShortDeportivoForm,
  IInputsSportShirtForm,
  IInputsSueterFemaleForm,
  IInputsSueterMaleForm,
  IInputsTrajeBanoFemale2pzaForm,
  IInputsTrajeBanoFemaleForm
} from 'interfaces/uniforms-interface'
import uac from 'assets/img/uniforms/UAC.png'

import {
  INPUTS_BLUSA_3_4,
  INPUTS_CAMISA_DEPORTIVA,
  INPUTS_CAMISA_ML_MC,
  INPUTS_CAPITONADO_CHALECO,
  INPUTS_CAPITONADO_CHAMARRA,
  INPUTS_CHALECO,
  INPUTS_CHAMARRA_DEPORTIVA,
  INPUTS_CORBATA,
  INPUTS_FALDA,
  INPUTS_FALDASHORT_DEPORTIVA,
  INPUTS_HOODIE,
  INPUTS_JUMPER,
  INPUTS_PANTALON_FRONTAL,
  INPUTS_PANTS,
  INPUTS_POLO_FEMALE,
  INPUTS_POLO_MALE,
  INPUTS_SACO_FRONTAL,
  INPUTS_SACO_TRASERO,
  INPUTS_SHORT_DEPORTIVA,
  INPUTS_SPORT_SHIRT,
  INPUTS_SUETER_FEMALE,
  INPUTS_SUETER_MALE,
  INPUTS_TRAJE_BANO_FEMALE_1PZ,
  INPUTS_TRAJE_BANO_FEMALE_2PZ,
  INPUTS_TRAJE_BANO_MALE,
  INPUTS_T_SHIRT,
  optionsGeneralPolos
} from 'utilities/uniforms-utilities'

const initStatusBar = {
  step1: { success: false, onCurse: true },
  step2: { success: false, onCurse: false },
  step3: { success: false, onCurse: false },
  step4: { success: false, onCurse: false }
}

const CheckMalePoloRules = (
  fabric: string | boolean,
  form: IInputsPoloMaleForm
) => {
  if (fabric === 'micro-pique') {
    return {
      ...form,
      color: { ...form.color, value: '#ffffff' }
    }
  } else return form
}
const CheckPoloFemaleRules = (
  fabric: string | boolean,
  form: IInputsPoloFemaleForm
) => {
  if (fabric === true) {
    return {
      ...form,
      neckDesign: { ...form.neckDesign, showThisOption: false }
    }
  } else if (fabric === false) {
    return {
      ...form,
      neckDesign: { ...form.neckDesign, showThisOption: true }
    }
  } else return form
}

const uniformsSlice = createSlice({
  name: 'uniformsSlice',
  initialState: {
    generalOptionsClothes: optionsGeneralPolos,
    poloValues: INPUTS_POLO_MALE as IInputsPoloMaleForm,
    poloValuesFemale: INPUTS_POLO_FEMALE as IInputsPoloFemaleForm,
    sportShirtMale: INPUTS_SPORT_SHIRT as IInputsSportShirtForm,
    sportShirtFemale: INPUTS_SPORT_SHIRT as IInputsSportShirtForm,
    TShirtMale: INPUTS_T_SHIRT as IInputsSportShirtForm,
    TShirtFemale: INPUTS_T_SHIRT as IInputsSportShirtForm,
    blusa_3_4: INPUTS_BLUSA_3_4 as IInputsBlusa3_4Form,
    jumperForm: INPUTS_JUMPER as IInputsJumperForm,
    faldaForm: INPUTS_FALDA as IInputsFaldaForm,
    sueterMaleForm: INPUTS_SUETER_MALE as IInputsSueterMaleForm,
    sueterFemaleForm: INPUTS_SUETER_FEMALE as IInputsSueterFemaleForm,
    sacoForm: INPUTS_SACO_FRONTAL as IInputsSacoForm,
    sacoTraseroForm: INPUTS_SACO_TRASERO as IInputsSacoTraseroForm,
    corbataForm: INPUTS_CORBATA as IInputsCorbataForm,
    hoodieForm: INPUTS_HOODIE as IInputsHoodieForm,
    chalecoCapitonadoForm:
      INPUTS_CAPITONADO_CHALECO as IInputsCapitonadosChalecoForm,
    chamarraCapitonadoForm:
      INPUTS_CAPITONADO_CHAMARRA as IInputsCapitonadosChamarraForm,
    camisaMlMcForm: INPUTS_CAMISA_ML_MC as IInputsHoodieForm,
    chalecoForm: INPUTS_CHALECO as IInputsBaseForm,
    pantalonFrontalForm: INPUTS_PANTALON_FRONTAL as IInputsPantalonForm,
    trajeBanoMaleForm: INPUTS_TRAJE_BANO_MALE as IInputsPantalonForm,
    trajeBanoFemaleForm1pz:
      INPUTS_TRAJE_BANO_FEMALE_1PZ as IInputsTrajeBanoFemaleForm,
    trajeBanoFemale2pzaForm:
      INPUTS_TRAJE_BANO_FEMALE_2PZ as IInputsTrajeBanoFemale2pzaForm,
    chamarraDeportivaForm:
      INPUTS_CHAMARRA_DEPORTIVA as IInputsChamarraDeportivaForm,
    pantsDeportivaForm: INPUTS_PANTS as IInputsPantsForm,
    faldaShortDeportivaForm:
      INPUTS_FALDASHORT_DEPORTIVA as IInputsFaldaShortDeportivoForm,
    shortDeportivaForm: INPUTS_SHORT_DEPORTIVA as IInputsShortDeportivoForm,
    female: false,
    pictureLogo: '',
    showModalPictureLogo: false,
    clotheSelected: 0,
    clotheNameDescription: { name: '', description: '' },
    isOnEdith: false,
    statusBar: initStatusBar,
    step: 1,
    conjuntoLabelstMenu: null as
      | null
      | { selected: string; label: string; click: () => void }[],
    selectedMenu: ''
  },
  reducers: {
    handleClotheName: (state, action) => {
      state.clotheNameDescription = action.payload
    },
    handleShortDeportivaForm: (state, action) => {
      state.shortDeportivaForm = action.payload
    },
    handlefaldaShortDeportivaForm: (state, action) => {
      state.faldaShortDeportivaForm = action.payload
    },
    handlePantsDeportivaForm: (state, action) => {
      state.pantsDeportivaForm = action.payload
    },
    handleChamarraDeportivaForm: (state, action) => {
      state.chamarraDeportivaForm = action.payload
    },
    handleTrajeBanoFemaleForm2pz: (state, action) => {
      state.trajeBanoFemale2pzaForm = action.payload
    },
    handleTrajeBanoFemaleForm1pz: (state, action) => {
      state.trajeBanoFemaleForm1pz = action.payload
    },
    handleTrajeBanoMaleForm: (state, action) => {
      state.trajeBanoMaleForm = action.payload
    },
    handlePantalonFrontalForm: (state, action) => {
      state.pantalonFrontalForm = action.payload
    },
    handleChalecoForm: (state, action) => {
      state.chalecoForm = action.payload
    },
    handleCamisaMlMcForm: (state, action) => {
      state.camisaMlMcForm = action.payload
    },
    handleModalPicture: (state, action) => {
      state.showModalPictureLogo = action.payload
    },
    handleChamarraCapitonadoForm: (state, action) => {
      state.chamarraCapitonadoForm = action.payload
    },
    handleChalecoCapitonadoForm: (state, action) => {
      state.chalecoCapitonadoForm = action.payload
    },
    handleHoodieForm: (state, action) => {
      state.hoodieForm = action.payload
    },
    handleCorbataForm: (state, action) => {
      state.corbataForm = action.payload
    },
    handleSacoTraseroForm: (state, action) => {
      state.sacoTraseroForm = action.payload
    },
    handleSacoForm: (state, action) => {
      state.sacoForm = action.payload
    },
    handleSueterFemaleForm: (state, action) => {
      state.sueterFemaleForm = action.payload
    },
    handleSueterMaleForm: (state, action) => {
      state.sueterMaleForm = action.payload
    },
    handleFaldaForm: (state, action) => {
      state.faldaForm = action.payload
    },
    handleJumperForm: (state, action) => {
      state.jumperForm = action.payload
    },
    handleBlusa3_4: (state, action) => {
      state.blusa_3_4 = action.payload
    },
    setLabelsMenu: (state, action) => {
      state.conjuntoLabelstMenu = action.payload
    },
    setPantalonesMenu: (state, action) => {
      state.selectedMenu = action.payload
    },

    setFemale: (state, action) => {
      state.female = action.payload
    },
    setSelectedClothe: (state, action) => {
      state.clotheSelected = action.payload
    },
    handleTShirtMaleForm: (state, action) => {
      state.TShirtMale = action.payload
    },
    handleTShirtFemaleForm: (state, action) => {
      state.TShirtFemale = action.payload
    },
    handleSportShirtMaleForm: (state, action) => {
      state.sportShirtMale = action.payload
    },
    handleSportShirtMFemaleForm: (state, action) => {
      state.sportShirtFemale = action.payload
    },
    handlePoloForm: (state, action) => {
      state.poloValues = action.payload
      state.poloValues = CheckMalePoloRules(
        state.poloValues.fabric.value,
        state.poloValues
      )
    },
    handlePoloFormFemale: (state, action) => {
      state.poloValuesFemale = action.payload
      state.poloValuesFemale = CheckPoloFemaleRules(
        state.poloValuesFemale.neckStyle.value,
        state.poloValuesFemale
      )
    },
    setStep1: (state) => {
      state.step = 1
      state.statusBar = {
        ...state.statusBar,
        step1: { success: false, onCurse: true },
        step2: { success: false, onCurse: false },
        step3: { success: false, onCurse: false },
        step4: { success: false, onCurse: false }
      }
    },
    setStep2: (state) => {
      state.step = 2
      state.statusBar = {
        ...state.statusBar,
        step1: { success: true, onCurse: false },
        step2: { success: false, onCurse: true },
        step3: { success: false, onCurse: false },
        step4: { success: false, onCurse: false }
      }
    },
    setStep3: (state) => {
      state.step = 3
      state.statusBar = {
        ...state.statusBar,
        step1: { success: true, onCurse: true },
        step2: { success: true, onCurse: false },
        step3: { success: false, onCurse: true },
        step4: { success: false, onCurse: false }
      }
    },
    setStep4: (state) => {
      state.step = 4
      state.statusBar = {
        ...state.statusBar,
        step1: { success: true, onCurse: true },
        step2: { success: true, onCurse: true },
        step3: { success: true, onCurse: false },
        step4: { success: false, onCurse: true }
      }
    },
    setPictureLogo: (state, action) => {
      state.pictureLogo = action.payload
    }
  }
})

export const {
  handleSportShirtMaleForm,
  setPictureLogo,
  setSelectedClothe,
  handlePoloForm,
  setStep1,
  setStep2,
  setStep3,
  setStep4,
  handlePoloFormFemale,
  setFemale,
  handleSportShirtMFemaleForm,
  handleTShirtFemaleForm,
  handleTShirtMaleForm,
  setLabelsMenu,
  setPantalonesMenu,
  handleBlusa3_4,
  handleJumperForm,
  handleFaldaForm,
  handleSueterFemaleForm,
  handleSueterMaleForm,
  handleSacoForm,
  handleSacoTraseroForm,
  handleCorbataForm,
  handleHoodieForm,
  handleChalecoCapitonadoForm,
  handleChamarraCapitonadoForm,
  handleModalPicture,
  handleCamisaMlMcForm,
  handleChalecoForm,
  handlePantalonFrontalForm,
  handleTrajeBanoMaleForm,
  handleTrajeBanoFemaleForm1pz,
  handleTrajeBanoFemaleForm2pz,
  handleChamarraDeportivaForm,
  handlePantsDeportivaForm,
  handlefaldaShortDeportivaForm,
  handleShortDeportivaForm,
  handleClotheName
} = uniformsSlice.actions

export const uniformsReducer = uniformsSlice.reducer
