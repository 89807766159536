import React, { memo } from 'react'
import PicazoBackground from 'components/picazoBackground/PicazoBackground'
import LoginModule from 'modules/login/LoginModule'
const index = memo(():any => {
  return (
        <PicazoBackground>
          <LoginModule />
        </PicazoBackground>
  )
})
index.displayName = 'Login'
export default index
