import React from 'react'

const FaldaShortFrontalSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
       <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outine_falda" data-name="Outine falda"   d="m266.46,534.87s18.87-223.01,22.48-241.06c4.16-20.84,7.97-31.71,7.97-31.71,22.44-4.73,32.92-8.06,77.09-8.06,29.1,0,66.65,2.14,66.65,2.14,0,0,59.62,2.97,61.4,6.68,1.77,3.71,6.01,29.52,6.01,29.52,0,0,25.84,220.25,25.49,244.73l-91.28,9.66s-130.67,1.72-175.8-11.88Z"/>
      <path id="Resorte"   d="m289.87,292.69s2.93-20.4,4.09-23.15c1.51-3.57,2.94-7.44,2.94-7.44,0,0,26.32,7.08,76.47,8.96,27.86,1.05,45.57.14,45.57.14,0,0,62.27-.65,83.71-7.92l4.13,22.24,1.27,6.85s-43.93,8.15-94.57,7.25c-23.29-.42-35.13.07-35.13.07,0,0-62.51,0-88.48-7Z"/>
      <g id="Textura">
        <path   d="m368.68,293.32s1.83.78,1.71,1.48"/>
        <path   d="m297.82,264.5s-1.67,3.77-.68,3.74"/>
        <path   d="m298.73,265.87s-1.27,5.62.57,4.61"/>
        <path   d="m300.2,262.95c-.18,1.5-.2,2.06-.11,3.31.09,1.26.01,1.56-.58,2.54"/>
        <path   d="m301.02,266.74c-.38,1.29-.31,2.15.05,2.79-.22.38-.35.88-.29,1.33"/>
        <path   d="m303.22,263.93c-.12.97-.21,1.94-.35,2.89-.03-.04-.06-.07-.1-.07.31.93-.31,2.04-.33,2.79"/>
        <path   d="m302.96,271.47c.27-.99.4-1.89.53-2.94"/>
        <path   d="m305.69,272.17c.32-1.67.06-2.24-.16-3.62-.18-1,.35-3.03-.09-3.67"/>
        <path   d="m304.49,269.47c.22-1.89.31-3.66.58-5.46"/>
        <path   d="m306.56,268.15c.1-.46.34-.8.38-1.31.02-.33-.16-.59-.15-.95.03-.65.06-1.04.44-1.3"/>
        <path   d="m307.81,267.33c.04,1.01-.26,1.73-.17,2.85.06.82.24,1.59-.05,2.21"/>
        <path   d="m308.89,268.19c-.51-.83-.49-2.09-.59-3.31"/>
        <path   d="m309.27,267.33c.08.94.38,2.59.24,3.47-.09.56-1.11,1.55-.3,2.04"/>
        <path   d="m310.42,269.65c.5-.95.13-3.46.01-4.77"/>
        <path   d="m310.72,273.55c.07-.98-.1-2.46-.35-3.36"/>
        <path   d="m311.59,267.73c.1,1.13-.42,1.87-.36,2.87"/>
        <path   d="m311.61,272.13c.63-.29,1.12-1.82,1.02-3.13-.04-.49-.74-2.7.11-2.37.44.18.42,2.23.49,2.99.11,1.27.32,2.09.52,3.09"/>
        <path   d="m317.24,276.13c-.06-1.05.02-3.46-.59-4-.34.85.08,3.1.17,4.2"/>
        <path   d="m315.96,271.79c.01-.97-.11-5.77-.69-5.97-1.03-.36.15,4.52.18,5.1"/>
        <path   d="m315.38,274.25c.06-1.35.02-3.58-.61-4.65-.61.71-.23,4.56.13,5.58"/>
        <path   d="m317.03,269.83c-.1-1.18.07-2.25-.3-3.36"/>
        <path   d="m317.97,271.21c.1.93.45,1.75.42,2.72,0,.54-.46,1.62-.06,1.97"/>
        <path   d="m319.34,271.79c-.09-1.6-1.69-3.88-.65-5.25"/>
        <path   d="m319.78,271c.13,1.41.1,1.93-.04,3.15-.09.68-.2,2.28.28,2.42"/>
        <path   d="m320.84,270.84c-.09-1.03-.48-2.89.14-3.33"/>
        <path   d="m320.96,272.91c.23,1.6-.19,3.08-.09,4.55"/>
        <path   d="m322.13,271.74c-.12-1.43-.54-3.61.11-4.55"/>
        <path   d="m322.18,277.29c-.07-.9-.38-2.17-.14-2.92"/>
        <path   d="m322.59,273.46c.04-.27.03-.72-.03-1.1"/>
        <path   d="m323.33,266.7c.13,1.15.49,2.03.63,3.17.12.81.22,1.66.29,2.52"/>
        <path   d="m332.37,279.6c-.13-1.45.47-3.41-.24-4.73"/>
        <path   d="m334.44,277.87c.07-1.02.65-1.31.45-2.53-.1-.65-.98-2.05-.47-2.6"/>
        <path   d="m335.46,272.77c-.42-1.21-.99-2.87-1.06-4.32"/>
        <path   d="m335.91,279.48c-.07-.83-.49-2.4-.4-3.1"/>
        <path   d="m336.78,276.97c0-1.26.11-2.45-.31-3.64-.4-1.13-.62-1.2-.68-2.64"/>
        <path   d="m337.28,273.87c1.75,1.75-.33,4.6.43,6.28"/>
        <path   d="m339.15,277.86c-.14-1.13-.05-2.28-.31-3.37-.24-.97-.7-1.84-.44-3.08"/>
        <path   d="m340.01,274.43c.09,1.03.21,2.06.27,3.09.04.67-.1,1.63.1,1.84"/>
        <path   d="m339.34,271.14c-.38-.99-.1-1.62-.29-2.63"/>
        <path   d="m339.94,272.22c.03.43.08.88.12,1.3.12.04.26.19.38.18"/>
        <path   d="m341.44,277.64c-.01.63.09,1.34-.21,1.74"/>
        <path   d="m341.97,279.91c-.1-1.11.01-2.34.55-2.79"/>
        <path   d="m342.65,276.26c-.69-.71.29-2.67-.08-3.77"/>
        <path   d="m340,270.2c.11-.42.03-.84-.11-1.2"/>
        <path   d="m342.65,277.67c.5.39.27,1.49.32,2.26"/>
        <path   d="m343.34,277.38c-.1-.42-.1-.8.04-.98"/>
        <path   d="m342.43,269.38c.73,1.01.37,3.45.78,4.73"/>
        <path   d="m343.87,279.45c.47-1.4-.28-3.7-.17-5.34"/>
        <path   d="m345.18,269.42c-.03.96-1.01,5.22-.21,5.41"/>
        <path   d="m345.74,279.67c.24-1.08-.14-3.72-.25-4.96"/>
        <path   d="m345.84,270.98c.14,1.68.6,4.2.25,5.7"/>
        <path   d="m347.06,279.8c-.1-1.22-.39-2.81-.09-3.89"/>
        <path   d="m347.69,275.92c-.06-.72-.42-3.05-.17-3.39"/>
        <path   d="m348.69,274.05c.14,1.58.44,2.99-.27,3.99-.12-1.49.14-3-.03-4.67"/>
        <path   d="m348.36,269.99c-.19.52-.35,1.48-.37,2.15"/>
        <path   d="m349.15,270.66c.12,1.41.2,2.59.03,3.91.56.23.35-.94.29-1.66"/>
        <path   d="m350.36,273.62c.1-1.17-.63-3.78-.03-4.55.26.93-.14,4.66.66,4.66"/>
        <path   d="m350.58,280.2c-.14-1.15-.5-2.81.06-3.6.11,1.42.08,2.59.1,3.94"/>
        <path   d="m351.7,277.15c-.05-.63-.64-4.53-.28-4.57.63-.06.47,3.76.52,4.4"/>
        <path   d="m352.57,274.87c-.34-1.28-.98-4.02-.18-4.75.04-.27.09-.33.15-.56.22,1.63-.64,2.6-.2,4.01"/>
        <path   d="m353.22,281.37c-.16-1.49-.51-2.89-.55-4.4.71-.29.76,3.02.68,3.76"/>
        <path   d="m353.91,277.1c-.09-1.14-.23-2.37-.24-3.46,0-.91-.21-1.38-.26-2.27.27,1.26.58,3.95.46,5.26"/>
        <path   d="m355.87,275.62c-.16-1.79-.37-3.51-.51-5.25.91-.49.43,2.78.54,3.55"/>
        <path   d="m357.1,279.27c-.07-1.48-.41-2.91-.5-4.4.03,0,.14-.02.17-.04.19.69.19,1.58.29,2.32.02-.51-.05-1.06-.09-1.57.48.77.51,2.12.58,3.23-.28-.28-.26-.95-.54-1.35"/>
        <path   d="m357.81,272.76c.02,1.53.72,3.29.11,4.79-.08-.69.12-1.12.11-1.75-.03-.58-.21-.92-.26-1.43-.09-.92-.12-2.52-.05-3.41"/>
        <path   d="m359.06,282.45c-.1-1.1-.65-3.59-.48-4.38.54,1.2.58,2.06.72,3.59"/>
        <path   d="m296.19,273.78c.75-.03,1.07-.15,1.27-1.9"/>
        <path   d="m298.8,271.43c-.47,1.43-1.13,2.9-1.22,4.55-.09,1.61.47,2.5-.02,4.25.3-1.11.46-2.17.63-3.6"/>
        <path   d="m299.06,277.89c.12-.45.35-.84.58-1.07.12.66-.11,1.71-.18,2.5.08.03.15.02.23.02"/>
        <path   d="m299.2,276.11c.11-.96-.15-1.66-.09-2.58.08-1.28.3-1.27.82-2.08"/>
        <path   d="m300.89,271.73c-.13,1.2-.36,3.09-.2,4.11.63-.68,1.26-2.82,1.62-4.03"/>
        <path   d="m301.07,279.79c.07-.57.56-3.22.2-3.43-.36-.21-.8,1.89-.83,2.52"/>
        <path   d="m303.45,280.74c.42-2.17.74-4.26,1.01-6.51.08-.72.33-1.82.08-2.27-.62.96-.97,3.57-1.36,4.96-.29.98-.58,1.99-.86,2.95"/>
        <path   d="m304.06,285.84c.19-.91.33-2,.39-2.93-.46.1-.89,1.08-.96,2.03"/>
        <path   d="m305.07,283.14c.31-2.59.59-5.13.77-7.69.08-1.03.36-3.71,1.06-3.23.98.66-.63,3.14-.76,4.38-.09.9.16,1.62.12,2.53-.03,1.35-.32,2.88-.48,4.24"/>
        <path   d="m310.55,283.29c-.11-2.15-.8-4.51-1.08-6.64-.16-1.28-.73-1.85-.88-2.99.03,1.07.37,2.04.52,3.05.1.75.16,1.52.26,2.24.23,1.69.39,2.96.34,4.61"/>
        <path   d="m311.91,284.11c-.17-1.56-.04-4.26-.72-5.47-.09.88-.01,1.83-.06,2.7-.04.72-.15,1.36-.19,2.1"/>
        <path   d="m313.2,283.38c-.09-.95.48-1.38.41-2.3-.05-.68-.58-1.82-.82-2.37-.57-1.28-.88-1.68-1.04-3.4.31,2.99.53,6.31.7,9.29.1.02.21.02.3-.04"/>
        <path   d="m315.18,285.69c-.02-.68.18-1.29.2-1.83-.05.12-.21.26-.27.34.07.04.15-.03.2,0-.09.06-.26.07-.36.07.16-.66-.09-1.53.16-2.23.3-.86,1.02-.47,1.2-1.46.27-1.46-.76-3.83-1.35-4.48-.07.96.21,1.93.16,2.88-.03.88-.34,1.1-.51,1.68-.37,1.27-.03,3.13.1,4.64"/>
        <path   d="m316.48,286.54c-.06-.65.14-1.22.15-1.87,0-.48-.09-1.07-.14-1.55-.32.71-.43,2.34-.51,3.27"/>
        <path   d="m318.18,286.21c-.18-1.96-.58-3.85-.8-5.78-.12-.98.01-2.58-.46-3.21-.15,1.47.25,3.7.38,5.24.14,1.59.11,2.36-.15,3.81"/>
        <path   d="m319.84,286.85c-.15-1.77-.34-3.6-.54-5.34-.33,1.4.53,4.6-.46,5.16"/>
        <path   d="m321.43,284.88c.28-1.61-.23-2.74-.7-3.87-.37-.86-.42-1.8-.5-2.92-.02,1.18-.07,2.05.03,3.24.1,1.11.22,2.24-.09,3.18.01.13.05.16.13.09"/>
        <path   d="m323.39,286.95c-.14-1.96-.63-4.12-1.25-5.75-.27,1.08.36,2.73.43,3.99.04.8,0,1.56.09,2.39"/>
        <path   d="m324.21,285.6c-.14-1.66-.16-3.68-.76-4.96-.38-.84-.8-1.16-.86-2.42.25,1.38.3,2.75.48,4.18.16,1.18.38,2.21.49,3.45"/>
        <path   d="m333.62,279.57c-.04-.66.33-.79.34-1.33.02-.55-.24-1.15-.34-1.64"/>
        <path   d="m337.45,272.97c-.12-.5-.2-.99-.26-1.54-.04-.43-.01-.8-.15-1.12-.24-.57-.64-.73-.67-1.6"/>
        <path   d="m341.54,274.84c0-.27-.1-.47-.21-.6-.11.16-.36.63-.39.91-.06.52.2,1.28.24,1.82"/>
        <path   d="m341.21,271.85c.12,0,.21-.13.26-.33-.03-.03-.08-.04-.11-.04-.02-.04-.02-.08,0-.12.08.11.04-.01.08.16-.14-.3-.12-.71-.15-1.11-.04-.49-.12-.69.15-.86"/>
        <path   d="m346.79,272.63c-.18-.7-.21-1.26.04-1.86.23-.56.16-.81-.09-1.43"/>
        <path   d="m354.42,274.26c.06-.75.45-1.34.24-2.15-.2-.76-.52-1.18-.49-2.12"/>
        <path   d="m357.22,271.71c-.1-.15-.26-.4-.32-.63-.08-.37.03-.34.03-.72.04.05.07.02.13.09-.03-.07-.04-.13-.08-.19-.06.31.09.33.12.57.02.18-.04.36-.03.57"/>
        <path   d="m324.21,280.35c-.04,1.93.55,3.47.84,5.33.11.71.09,1.19,0,1.9-.08.65,0,1.13.32.46.2-.45.16-1.24.22-1.84.08-.74.08-1.49-.15-2.21-.14-.43-.36-.76-.47-1.22-.12-.49-.13-1.09-.18-1.62"/>
        <path   d="m327.56,286.68c-.47-1.62-.88-3.03-1.04-4.83-.09-.97-.07-1.96-.42-2.66-.04,0-.08,0-.11,0-.19,1.39.16,2.81.56,4.03.42,1.26.78,2.75.94,4.19"/>
        <path   d="m328.04,280.61c.18,1.92.39,3.77.76,5.59.12.6.19,1.18.16,1.8-.02.35-.09.68-.05,1.05.65-.12.2-3.52.11-4.3-.1-.88-.34-1.7-.61-2.44-.14-.35-.32-.66-.35-1.1"/>
        <path   d="m328.8,279.36c.15,1.79.31,3.16.95,4.58.17.38.36.77.42,1.26.07.52-.04.99.05,1.52.87-.33.12-2.53-.2-3.45-.21-.59-.41-.81-.48-1.48-.06-.52-.1-1.05-.14-1.57"/>
        <path   d="m330.47,279.64c.08.9.15,1.79.2,2.67.25-.66.01-2-.06-2.82"/>
        <path   d="m330.47,282.4c.13,1.48.63,2.22.97,3.48.3,1.05.3,2.26.4,3.41"/>
        <path   d="m338.87,287.9c-.7-2.2-.97-4.66-1.23-7.12.15.76.21,1.41.34,2.17.11.71.18,1.7.34,2.41.12.57.36.89.45,1.47.08.5.1,1.1.14,1.62"/>
        <path   d="m339.22,287.56c-.12-1.03.06-1.69.04-2.54.06.66.11,1.3.17,1.94"/>
        <path   d="m339.17,283.02c-.29-.57-.54-1.14-.53-1.97.34.43.54,1.04.56,1.79"/>
        <path   d="m340.19,285.91c-.1-1.18-.12-2.44-.27-3.59-.22,1.03-.21,2.45-.1,3.57"/>
        <path   d="m340.51,284.66c.56-1.39.31-2.82.16-4.52"/>
        <path   d="m340.97,283.32c-.05,2.17.03,4.87.97,6.42-.08-1.54-.48-2.32-.74-3.68-.14-.72-.3-1.94-.13-2.58"/>
        <path   d="m341.95,280.6c.1,1.34-.03,2.62.18,4.01.15,1.04.3,1.93.39,3.02"/>
        <path   d="m344,289.87c-.45-1.19-.49-2.44-.62-3.82"/>
        <path   d="m343.61,280.55c.03,1.71.63,3.39.45,5.14"/>
        <path   d="m344.92,285.35c.04.51.23,3.89.62,3.92.37.03-.38-2.92-.4-3.51"/>
        <path   d="m345.26,280.23c.16,1.82.36,3.66.38,5.44,0,.72.24,1.73.38.64.08-.65-.21-1.92-.28-2.6"/>
        <path   d="m346.89,280.53c.09.84.35,1.59.45,2.48.08.73.11,1.49.2,2.23.14,1.27.24,2.58.39,3.87.54.2.26-1.2.13-1.66-.23-.9-.37-1.74-.48-2.74-.13-1.11-.03-3.45-.49-4.04"/>
        <path   d="m348.72,280.97c.16,1.76.57,3.3.76,5.04.12,1.03.05,2.02.18,3.01.74.61.14-1.36.07-1.77-.2-1.22-.42-2.65-.52-3.91"/>
        <path   d="m350.52,281.34c-.04,1.5.27,3.13.41,4.6.07.83.08,2.95.44,3.41.2-.56,0-1.42-.14-2.01"/>
        <path   d="m352.35,287.05c-.09.81.19,1.93.16,2.8.67.02.1-2.28-.2-2.71"/>
        <path   d="m352.47,282.63c-.5-.08-.38.99-.32,1.62.07.74-.02,1.13-.02,1.75.49.1.34-.8.28-1.42-.07-.79.05-1.17.07-1.86"/>
        <path   d="m354.23,290.39c0-.67.03-1.4-.12-2.01-.13-.59-.35-.73-.36-1.47.68.04.72.53.76,1.5.03.55.15,1.34.04,1.83"/>
        <path   d="m354.11,285.28c-.03-.49-.16-1.11-.38-1.4-.2-.27-.43-.02-.52-.54"/>
        <path   d="m355.07,285.16c-.07-.84-.14-1.68-.22-2.53"/>
        <path   d="m347.72,278.84c.09.43.27.89.49,1.04.13-.57.46-.97.41-1.67"/>
        <path   d="m349.57,278.43c-.02.24-.03.5-.02.74"/>
        <path   d="m349.38,275.66c.16.4.31.91.39,1.39"/>
        <path   d="m355.16,287.73c-.04.73.21,1.12.24,1.77-.12.11-.12.27-.19.43"/>
        <path   d="m354.67,286.28c-.06-.37-.07-.79-.06-1.18"/>
        <path   d="m357.74,283.06c.39.76.46,3.1.56,4.2"/>
        <path   d="m357.63,291.32c-.04-.88-.26-1.68-.45-2.48-.29-1.24-.48-2.39-.69-3.68-.12-.81-.68-2.45-.12-2.91.47,1.3.43,2.9.88,4.29.52,1.6.55,3.24.76,4.98"/>
        <path   d="m351.84,281.44c.14-.58-.06-1.39.05-2.02.15.39.07,1.03.13,1.53"/>
        <path   d="m352.75,275.76c.11.38.36.84.55.97.04-.51-.06-1.09-.1-1.61"/>
        <path   d="m353.92,280.27c.18.34.39.61.5,1"/>
        <path   d="m354.81,279.05c-.05-.63-.11-1.26-.17-1.89"/>
        <path   d="m358.65,291.02c-.19-.63-.3-1.73-.25-2.45.23.56.44.99.43,1.69,0,.45-.26.65-.05,1.1"/>
        <path   d="m359.5,288.41c-.22-.37-.74-2.44-.33-2.58.16.67.38,1.27.41,2.01"/>
        <path   d="m360.42,289.18c.21-.91-.11-2.81-.28-3.78-.15-.77-.6-1.38-.66-2.18"/>
        <path   d="m360.2,291.26c-.11-.39-.27-.77-.42-1.1"/>
        <path   d="m361.18,286.2c-.05-.57-.11-1.14-.14-1.69.17-.16.32-.44.47-.68-.17.41-.29,1.14-.25,1.72"/>
        <path   d="m361.66,291.68c-.01-1.07-.49-1.57-.37-2.57.09-.68.15-.92.07-1.77"/>
        <path   d="m362.7,289.06c-.08-.87-.14-1.63-.35-2.42-.15-.55-.3-.79-.31-1.46.67.76.68,2.46.79,3.77"/>
        <path   d="m360,295.97c.35.72.46,1.39.33,2.23"/>
        <path   d="m358.69,271.88c-.13-.4-.24-1.09-.29-1.64.07.28.48,1.64.73,1.38"/>
        <path   d="m357.5,280.7c.13.4.35.59.37,1.12"/>
        <path   d="m356.99,281.28c.06.61-.28.45-.48.7-.06-.54-.02-1.35-.32-1.56"/>
        <path   d="m355.5,276.98c-.23.57,0,1.24.23,1.7-.21.53-.48-.14-.27-.62.16-.37.83-.77.17-1.3"/>
        <path   d="m355.65,281.08c-.37.4-.74-.24-.74-1.07"/>
        <path   d="m296.33,279.5c-.14,1.08.07,1.88,0,2.85-.08,1.17-.22,2.32-.05,3.31.06.4.13.67.12,1.13-.01.42-.2.91-.22,1.27-.06,1.29.46.19.66-.26.06.25.08.53.06.83.44-.73-.3-3.16-.41-3.77-.11-.6-.18-1.27-.19-1.97,0-.67.1-.68.24-1.25.33-.28.56-1.1.43-1.62-.23-.09-.28.55-.48.43-.12-.08-.14-.7-.13-.95"/>
        <path   d="m297.47,293.15c.36-.96.36-1.83.31-2.76-.05-.98-.42-1.72-.42-2.62-.01-1.02,1-2.98.28-3.6-.36.53-.28,1.77-.33,2.44-.06.76-.3,1.75-.29,2.48.01.83.34,1.13.4,1.97.05.69-.04,1.31-.18,2.09"/>
        <path   d="m298.19,283.46c.08-.66.45-2.51-.02-2.47-.25.91-.16,2.16-.49,2.9"/>
        <path   d="m299.29,287.66c.09-.78.22-1.62.24-2.37.03-.79-.14-1.4-.13-2.18,0-.97.3-1.13.38-2.03.06-.65-.09-1.06-.44-.86-.21.87.13,1.29.07,2.07-.05.69-.31,1.25-.31,1.95,0,.77.14,1.17.07,2.03-.04.63-.14,1.28-.21,1.91"/>
        <path   d="m299.76,292.39c.3-1.85.98-3.52.6-5.11-.23-.96-.4-1.4-.17-2.75.11.35.2.79.36,1.02.15-.32.26-.76.3-1.13.66-.32-.12,1.43-.19,1.77-.17.78.02,1.86.02,2.69,0,1.29.09,4.29-.84,4.8"/>
        <path   d="m302.07,291.75c.47-.15.58-1.44.68-2.2.15-1.08.13-1.73.07-2.66-.04-.69-.14-1.55-.08-2.28.04-.6.25-1.46-.05-1.71-.08.31-.1.63-.14.94-.19.01-.43-.2-.42-.66.02-.49.29-.61.39-1,.18-.67-.08-1.42-.37-1.43-.1.49-.09,1.03-.12,1.52-.02.32-.06.52-.03.82.02.31.31.74.27,1.03-.05.57-.49,1.04-.51,1.79-.02.78.17.42.42.59.89.6.07,3.4,0,4.84"/>
        <path   d="m304.75,286.54c.03.86-.31.81-.37,1.46-.05.79.43,1.38.64,1.89.42,1.03.24,2.36.34,3.57.04.39.21,1.32-.05,1.48-.11.08-.32-.29-.35-.56-.03-.29.09-.54.1-.84.03-.88-.3-1.96-.56-2.62-.2-.53-.57-1.05-.65-1.7-.05-.53.1-1.16.07-1.72-.01-.37-.23-1.41.14-1.22,0,.04-.03.03-.03.02.06.32.06.57.2.78.12-.32.37-.78.58-.42"/>
        <path   d="m306.83,289.25c-.05-.54-.04-1.4-.15-1.89-.1-.47-.33-.86-.47-1.25-.25-.73-.53-1.61-.57-2.49-.4,1.46-.03,2.39.46,3.39.43.84.52,1.61.62,2.72"/>
        <path   d="m308.37,293.86c-.15-1.72-.29-2.66-.85-4-.31-.74-.7-1.8-.38-2.58.45.48.52.88.62,1.75.09.79.19,1.11.42,1.76.21.57.37,1.17.4,1.91.03.57-.34,2.12-.21,2.27"/>
        <path   d="m308.91,290.02c.64-.79.43-2.71-.02-3.74-.39-.89-.5-2.36-1.03-2.96-.03.86.43,1.41.63,2.1.15.53.19.96.24,1.58.07.75.2,1.08.34,1.69.12.53.16.97-.08,1.22"/>
        <path   d="m310.99,294.95c-.13-1.48-.2-1.97-.72-2.95-.28-.54-.79-1.7-.34-2.31.03.9.1.96.42,1.17.19.12.26-.12.43.1.12.16.27.78.32,1.05.24,1.11-.04,2.57.27,3.52"/>
        <path   d="m311.13,291.49c-.08-.9.1-1.8-.07-2.81-.14-.91-.46-1.26-.74-2.07-.11-.32-.59-1.45-.57-1.83.04-.7.54-.38.69.05.2.55.11,1.38.22,1.98.1.56.35.95.46,1.57.1.53.21,1.32.21,1.89,0,.53-.31,1.38-.03,1.74"/>
        <path   d="m312.04,293.13s-.23-.61-.28-1.04"/>
        <path   d="m312.65,293.3c.1-.4.08-.92.02-1.38"/>
        <path   d="m312.09,293.96c.06.4.04.79.06,1.19"/>
        <path   d="m312.81,290.98c-.39-1.04-.62-1.63-.77-2.97-.06-.54-.12-1.05-.26-1.52-.12-.4-.32-.53-.36-1.01.18-.12.7-.04.87.18.48.62.36,1.45.32,2.12-.04.96.13,1.92.21,2.91"/>
        <path   d="m314.53,290.1c-.07-.45-.51-.77-.73-.5-.41.49.03,1.28.26,1.79.3.63.47.96.6,1.79.15.94.09,1.87.58,2.53.09-.67-.09-1.87-.22-2.57-.09-.48-.34-.84-.44-1.31-.12-.56-.1-1.13-.13-1.72"/>
        <path   d="m315.47,287.18c-.06.41-.18.74-.12,1.2.48.03.76,1.59.85,2.32.04.4.04.87.11,1.27.08.4.28.52.28,1.01-.1-1.13-.17-2.4-.35-3.47-.18-1.05-.8-1.25-.54-2.46"/>
        <path   d="m313.44,286.61c-.1.53.1.88.37.65,0-.15,0-.37-.04-.52"/>
        <path   d="m315.8,295.38c.01.18.05.35.1.53.23,0,.29-.46.15-.76"/>
        <path   d="m315.04,289.36c-.23-.32-.49-.92-.31-1.4"/>
        <path   d="m318.04,296.09c.63-2.77-1.25-3.55-1.14-6.19.1.03.21.13.28.25-.07.15-.07.43-.11.53.17.14.33.29.48.37"/>
        <path   d="m317.89,291.33c.21-1.12.07-2.38-.04-3.6.3,1.35.55,2.52.67,3.93"/>
        <path   d="m319.83,296.42c-.04-1.68-.04-2.92-.4-4.52-.11-.54-.45-1.2-.47-1.79.06-.07.1-.11.16-.13.36.69.46,1.71.57,2.62.15-.04.31-.14.44-.25.28,1.03.16,2.58.23,3.7"/>
        <path   d="m320.78,294.34c.06-1.33-.08-3.27-.39-4.54-.16-.66-.51-1.65,0-1.97.06.59.11,1.2.16,1.79.25-.22.45-.68.37-1.22.48,1.19.1,2.32.05,3.43-.04.84.28,1.84,0,2.56"/>
        <path   d="m322,296.59c-.34-1.7-.22-3.21-.14-4.83"/>
        <path   d="m322.21,287.92c.15.85.39,1.58.53,2.37.12.64.11.93.41,1.47.11.18.29.35.36.51.27.65.26,1.39.34,2.2.1,1.05.2.96.73.87"/>
        <path   d="m324.83,295.86c.23-.99-.52-3-.72-3.97-.06-.36-.41-1.61-.3-1.96.19-.63.44.03.53.4.11.5.02,1.07.09,1.57.06.52.26.97.36,1.49.22,1.15.46,2.36.55,3.55-.1-.26-.24-.65-.24-1.04"/>
        <path   d="m326,294.28c-.09-.96-.11-2.1-.31-3.02-.11-.53-.48-1.08-.56-1.58-.05-.4-.04-.75.16-.86.38-.2.39.84.43,1.33.13,1.42.64,2.89.52,4.36"/>
        <path   d="m326.92,293.35c.1,1.15-.09,2.48,0,3.72-.47-1.57-.37-2.36-.33-3.84"/>
        <path   d="m336.51,293.96c-.56-1.02-.71-1.57-.81-3,.34.9,1.05,2.3,1.2,3.29"/>
        <path   d="m339.31,289.95c-.05-.47-.03-.97-.22-1.32-.01.29,0,.6.04.89"/>
        <path   d="m336.34,292.87c.08.94-.04,1.61-.01,2.48.02.84.34,1.86.55,2.57-1.2-.41-.47-3.76-1.19-4.68.09.21.17.34.26.53"/>
        <path   d="m337.67,295.98c-.52-.59.1-1.72.14-2.42.06-.88-.45-1.7-.29-2.33"/>
        <path   d="m338.66,290.56c.03.27.05.54.07.81"/>
        <path   d="m338.83,297.46c-.2-.75-.74-2.28-.6-3.2.15-.93.76.15.77.95-.97-.22.24,2.09.27,2.53"/>
        <path   d="m339.54,291c.27.36.66,1.18.56,1.9-.03.28-.24.35-.27.73-.02.26.06.67.11.88-.09-.7-.37-1.25-.45-1.97-.06-.54-.07-1.1-.12-1.63"/>
        <path   d="m340.38,294c-.16.71-.16,1.15.06,1.76.1.3.17.32.21.7.03.2-.06.59-.03.72.07.3.34.38.45.06.11-.33-.11-.61-.21-.88-.23-.59-.31-1.13-.38-1.83"/>
        <path   d="m341.19,293.8c-.01-.51.16-.89.16-1.39,0-.43-.13-.81-.14-1.24.26.08.25,1.17.29,1.59"/>
        <path   d="m342.02,297.04c-.09-.59-.18-1.02-.37-1.52-.13-.32-.34-.68-.09-.96.28.39.47.92.59,1.54.1.5.26.99.3,1.54"/>
        <path   d="m342.69,293.32c-.05-.63-.11-1.1-.26-1.67-.12-.44-.23-.71-.08-1.2.3.68.35,1.54.43,2.42"/>
        <path   d="m343.19,297.59c-.19-.54-.46-.81-.53-1.5-.04-.42.02-.83.01-1.27.47,0,.32,1.1.39,1.57.07.56.21,1.02.27,1.67"/>
        <path   d="m343.21,293.29c.16.81.63,1.15.85,1.85.07.21.05.42.11.66.11.49.35.85.55.21.25-.87-.2-2.04-.48-2.73-.02.52.33,2.34.15,2.66"/>
        <path   d="m344.82,293.74c-.09-1.04-.41-2.34-.34-3.37.5.82.52,2.06.62,3.19"/>
        <path   d="m344.82,297.77c-.15-.05-.27-.29-.32-.55"/>
        <path   d="m346.1,295.35c-.06-.69-.08-1.34-.05-1.96,0-.36.04-.7-.07-1.13"/>
        <path   d="m348.33,290.55c-.15-.18-.28-.48-.31-.79"/>
        <path   d="m359,291.91c.07.12.12.33.13.52"/>
        <path   d="m346.78,289.8c.04.32.02.64.05.97"/>
        <path   d="m345.65,290.28c0,.12,0,.24.01.36"/>
        <path   d="m345.8,297.76c-.02-.58-.26-.85-.31-1.35"/>
        <path   d="m349.05,290.9c.06-.15.12-.29.19-.39"/>
        <path   d="m346.65,295.95c.05.26.02.56-.02.8"/>
        <path   d="m361,292.32c0,.32.14.52.27.68"/>
        <path   d="m346.2,291.24c.21.09.3.57.36.93.13.85.08,1.56.44,2.23.26.47.38.52.35-.19-.04-.85-.49-1.38-.53-2.15"/>
        <path   d="m347.76,297.6c-.2-.33-.42-.71-.44-1.25.03-.03.08-.06.11-.08.18.23.71,1.22.7,1.62"/>
        <path   d="m347.84,292.76c-.05-.69-.28-1.22-.32-1.88.05.44.1,1.51.4,1.5"/>
        <path   d="m347.67,295.14c.02.37.17.57.27.85.11.04.15.04.25.18-.31-.94-.37-1.02.18-1.17,0-.1-.02-.15-.05-.24"/>
        <path   d="m348.73,291.45c-.02.56.04,1.22.27,1.63.16.31.47.54.58.03-.21-.36-.65-.05-.63-.8"/>
        <path   d="m348.73,293.83c0-.19,0-.35-.02-.54"/>
        <path   d="m349.04,295.74c.01.14.03.28.04.43"/>
        <path   d="m348.94,296.93c-.05.18-.1.39-.13.58"/>
        <path   d="m349.72,291.42c.02.28.04.62.1.83"/>
        <path   d="m350.15,297.74c-.05-.73-.17-1.47-.35-2.17-.15-.6-.36-1.02-.42-1.66.21.87.43,1.42.52,2.36"/>
        <path   d="m351.3,297.83c-.14-.69-.13-.86,0-1.46.07-.37.1-.61.03-1.04-.18-1.02-.66-1.55-.87-2.48.48.91.86,1.58,1.02,2.82.05.41.07.78.05,1.18-.01.43-.18,1.2-.09,1.52"/>
        <path   d="m351.98,294.8c-.34-.97-.64-2.09-.86-3.19-.14-.72.07-1.1.03-1.78.05.55.17,1.08.21,1.61,0-.17-.02-.49,0-.68.33.76.28,1.39.19,2.19-.09.82.17,1.05.39,1.73"/>
        <path   d="m353.5,297.72c-.06-.55-.17-1.09-.28-1.63.22-.62-.57-2.13-.58-2.96.43.22.89,1.66.98,2.48.09.82.11,1.61.15,2.43"/>
        <path   d="m353.88,295.11c.52-1.32-.68-2.94-.53-4.23.76.31.72,2.83.82,3.99"/>
        <path   d="m355.03,298.12c0-1-.16-1.89-.28-2.87-.06-.42-.26-1.21.06-1,.4.27.15.94.13,1.41-.02.56.07,1.15.12,1.71"/>
        <path   d="m356.01,296.09c-.06-.94-.47-1.97-.57-2.96-.06-.54-.09-1.08-.14-1.61"/>
        <path   d="m357.26,298.18c-.11-.82-.46-2.08-.38-2.85"/>
        <path   d="m357.25,293.87c-.07-.33-.27-.59-.35-.91"/>
        <path   d="m358.73,297.87c-.09-1.05-.13-2.23-.32-3.23-.09-.52-.23-1.03-.31-1.53.09.13.15.4.2.59.14-.3.05-.74.03-1.11.36-.22.17.86.16,1.13-.03.44-.02.96.05,1.45.12.72.21,1.36.28,2.14.04.42.05,1,.15,1.32"/>
        <path   d="m359.84,292.92c-.27,1.2-.36,1.68-.51,3.27.15-.42.37-1.42.5-2.19"/>
        <path   d="m361.02,295.01c-.01-.13-.03-.25-.03-.37"/>
        <path   d="m359.79,297.21c-.04-.19-.04-.41-.02-.55"/>
        <path   d="m361.76,298.5c.11-.62-.38-1.1-.46-1.73-.06-.47-.11-1.04-.12-1.51.44.3.22,1.08.28,1.66.06.54.31,1.07.37,1.69"/>
        <path   d="m362.84,294.22c.03.27.03.5-.06.68"/>
        <path   d="m361.06,293.99c-.21.36-.48-1.12.1-.3"/>
        <path   d="m292.14,280.43s.33-1.15,1.03-.8c.7.35,1.08.18,1.58-.13.49-.32,1.98-.66,2.55-.29.56.36.95-.38,2.01.25,1.07.62,2.96.42,4.34,1.58,1.38,1.16,5.51,1.98,7.06,3.45,1.55,1.47,2.06.7,3.4,1.51,1.35.82,2.73.13,3.82.99,1.09.85,8.54,1.25,10.02,2.16,1.49.91,1.91-.07,3.08.32,1.17.39,2.95.33,3.65.67.7.34,1.89.27,1.89.27,0,0,2.29-.6,3.28.22,1,.81,1.09-.23,1.09-.23,0,0,1.73-.52,2.22-.25.49.26.84.3,1.12-.08.28-.37,2.57-.62,3.23-.62.66.02,2.68-.32,4.75.5,2.07.82,5.28,1.67,5.9,1.92.62.25,4.24,1.26,4.82,1.17.58-.09,5.38.86,6.66.99,1.27.15,3.54-.9,4.2-.32.66.58,1.87.15,2.74.25.87.08,2.03-.22,2.82-.23.8-.02,2.06-.55,2.83-.22.75.34,1.59.09,1.93.14.34.07,4.96-.57,7-.66,2.04-.09,5.86-.82,6.58-.93"/>
        <path   d="m397.42,280.2c-1.06.75-4.4.89-5.42,1.02-1.04.13-3.72.46-5.3.66-1.58.19-1.35.16-3.11.38-1.76.22-4.17-.1-5.8.11-1.65.21-3.04.46-4.87.6-1.84.14-.76-.09-2.34.11-1.58.2-4.99-.18-6.83-.13-1.86.05-.54.6-3.62.27-3.08-.33-4.17-.54-4.5-.77-.35-.23-.78-.62-2.74-.91-1.97-.29-3.8-1.92-7.54-1.55,0,0-1.84.14-2.75.25-.91.11-1.2-.12-1.44-.09-.25.03-2.26.28-2.85.45-.6.16-1.13-.48-2.34-.33-1.23.15-2.22-.35-2.83-.28-.61.08-4.96-.8-6.27-.9-1.31-.11-2.09-.8-3.03-.78-.93.02-1.81-.13-2.47-.41-.66-.28-.88-.51-1.88-.56-1-.05-1.93-.91-3.03-1.22-1.1-.31-1.65-.68-2.66-.83-1.01-.14-3.63-1.94-4.77-2.15-1.15-.22-.98-.32-3-.52-2.01-.19-1.98-1.25-3.27-1.19-1.29.08-.68,0-2.1.08-1.41.09-1.26-.63-3.03-.42-1.76.21-3.15.56-3.15.56"/>
        <path   d="m337.69,289.72c-.1-1.18-.12-2.44-.27-3.6-.22,1.03-.21,2.45-.1,3.58"/>
        <path   d="m329.56,297.18c-.08-.97-.11-2.09-.3-3.01-.12-.53-.49-1.08-.56-1.58-.06-.4-.05-.75.15-.85.38-.21.39.84.44,1.32.13,1.42.64,2.89.52,4.35"/>
        <path   d="m329.5,289.9c-.03.43-.13.78-.05,1.23.48-.07.84,1.43.96,2.15.06.4.08.87.18,1.26.1.38.3.46.33.95-.16-1.12-.3-2.38-.54-3.41-.23-1.02-.86-1.09-.67-2.35"/>
        <path   d="m332.25,297.49c.46-2.9-1.43-3.28-1.46-5.96.1.01.21.1.29.21-.06.16-.05.43-.08.55.17.1.33.22.5.27"/>
        <path   d="m331.94,293.41c.14-1.17-.06-2.4-.24-3.61.37,1.3.68,2.42.88,3.81"/>
        <path   d="m333.98,297.56c-.14-1.68-.21-2.92-.64-4.45-.15-.51-.51-1.11-.56-1.7.05-.08.09-.13.15-.17.39.62.55,1.62.7,2.51.15-.08.29-.21.42-.35.33.97.3,2.55.42,3.67"/>
        <path   d="m334.75,296.51c0-1.34-.25-3.26-.63-4.47-.19-.62-.6-1.54-.11-1.97.1.57.18,1.18.27,1.76.23-.27.39-.77.28-1.3.54,1.09.23,2.29.24,3.45.01.84.38,1.76.15,2.55"/>
        <path   d="m323.55,272.6c-.04.42-.14.78-.06,1.24.48-.07.84,1.43.96,2.14.07.4.09.88.18,1.26.1.38.3.46.33.95-.16-1.12-.3-2.37-.54-3.41-.23-1.01-.87-1.08-.67-2.34"/>
        <path   d="m326.05,272.45c.47-2.9-1.42-3.27-1.46-5.96.09.01.21.1.29.2-.06.17-.05.44-.09.55.17.11.33.22.5.27"/>
        <path   d="m325.92,278.03c.14-1.17-.05-2.41-.24-3.61.37,1.29.68,2.41.88,3.8"/>
        <path   d="m327.77,275.6c-.13-1.68-.2-2.92-.63-4.44-.15-.52-.52-1.11-.56-1.71.04-.08.09-.12.15-.16.39.62.55,1.62.7,2.51.14-.08.29-.2.42-.35.33.98.31,2.55.43,3.67"/>
        <path   d="m329.16,273.82c-.01-1.35-.26-3.27-.63-4.47-.19-.63-.6-1.55-.12-1.98.1.57.17,1.18.26,1.75.23-.27.4-.77.29-1.29.54,1.09.23,2.3.24,3.44,0,.84.37,1.78.14,2.56"/>
        <path   d="m328.76,273.65c-.04.41-.14.77-.06,1.22.47-.07.84,1.43.96,2.14.07.4.09.88.18,1.26.1.39.3.46.32.95-.16-1.12-.3-2.37-.54-3.41-.23-1.01-.86-1.08-.66-2.34"/>
        <path   d="m331.48,273.82c.47-2.91-1.43-3.29-1.46-5.96.1.02.22.09.3.19-.06.16-.05.45-.09.56.18.11.33.22.5.27"/>
        <path   d="m330.72,278.59c.14-1.17-.06-2.41-.24-3.61.37,1.3.68,2.41.89,3.81"/>
        <path   d="m332.68,276.06c-.13-1.67-.21-2.92-.64-4.45-.15-.52-.52-1.11-.57-1.7.05-.07.09-.13.15-.16.39.61.55,1.62.7,2.5.14-.07.29-.2.41-.35.34.98.31,2.56.43,3.67"/>
        <path   d="m333.53,274.42c-.01-1.34-.25-3.26-.63-4.47-.19-.63-.59-1.55-.11-1.97.09.58.17,1.17.26,1.76.23-.26.39-.77.28-1.3.54,1.09.23,2.31.24,3.44.01.85.38,1.78.15,2.56"/>
        <path   d="m331.65,280.6c-.04.42-.14.78-.06,1.23.48-.07.84,1.43.97,2.15.06.39.08.87.18,1.25.1.38.3.46.33.96-.16-1.13-.31-2.38-.54-3.41-.23-1.02-.86-1.09-.67-2.36"/>
        <path   d="m334.52,286.1c.47-2.91-1.42-3.29-1.46-5.97.1.01.21.09.29.2-.06.17-.04.44-.09.56.18.1.33.22.5.26"/>
        <path   d="m333.63,289.54c.14-1.17-.06-2.42-.25-3.61.37,1.29.68,2.42.89,3.81"/>
        <path   d="m335.8,288.2c-.14-1.68-.21-2.92-.65-4.45-.15-.52-.51-1.11-.56-1.7.05-.08.09-.13.15-.16.39.61.54,1.62.7,2.5.14-.07.3-.2.41-.34.34.98.31,2.56.43,3.67"/>
        <path   d="m336.65,287.11c-.01-1.35-.26-3.27-.63-4.47-.19-.63-.6-1.54-.11-1.98.1.58.17,1.18.26,1.76.24-.27.39-.77.29-1.3.54,1.09.23,2.31.23,3.44,0,.85.38,1.77.15,2.57"/>
        <path   d="m327.82,295.55c.23-.99-.53-2.99-.72-3.96-.06-.36-.41-1.61-.3-1.97.19-.63.44.03.54.4.11.51.02,1.07.09,1.57.07.51.27.97.36,1.48.22,1.15.46,2.37.55,3.55-.1-.26-.23-.65-.24-1.04"/>
        <path   d="m359.25,277.2c-.07-.9-.38-2.17-.13-2.92"/>
        <path   d="m359.76,278.47c.04-.27.03-.72-.03-1.09"/>
        <path   d="m360.03,270.87c.13,1.15.49,2.03.63,3.17.12.82.21,1.66.29,2.51"/>
        <path   d="m370.46,276.07c-.13-1.45.47-3.42-.23-4.73"/>
        <path   d="m372.17,276.27c.07-1.02.65-1.31.45-2.53-.09-.65-.98-2.05-.47-2.6"/>
        <path   d="m374,275.54c-.42-1.21-.99-2.87-1.06-4.32"/>
        <path   d="m373.15,279.66c-.07-.82-.49-2.39-.4-3.1"/>
        <path   d="m374.48,282.4c0-1.26.11-2.45-.3-3.64-.41-1.13-.62-1.2-.68-2.64"/>
        <path   d="m374.14,271.85c1.74,1.75-.33,4.6.42,6.28"/>
        <path   d="m376.15,282.41c-.15-1.13-.05-2.28-.31-3.37-.24-.97-.7-1.85-.44-3.08"/>
        <path   d="m375.96,271.64c.09,1.03.21,2.06.27,3.09.04.67-.1,1.63.1,1.84"/>
        <path   d="m377.11,277.38c-.39-.99-.1-1.62-.29-2.62"/>
        <path   d="m377.82,271.48c.04.43.08.88.12,1.3.12.04.26.19.38.19"/>
        <path   d="m378.42,280.09c-.01.63.08,1.35-.21,1.74"/>
        <path   d="m379.76,281.66c-.1-1.11.01-2.34.55-2.8"/>
        <path   d="m379.23,278.92c-.7-.71.29-2.67-.09-3.77"/>
        <path   d="m378.1,275.48c.11-.42.03-.84-.1-1.2"/>
        <path   d="m380.15,272.67c.51.39.27,1.49.32,2.26"/>
        <path   d="m380.04,277.66c-.1-.42-.11-.8.04-.98"/>
        <path   d="m380.72,277.03c.73,1.02.37,3.45.78,4.73"/>
        <path   d="m381.43,276.99c.47-1.4-.28-3.7-.17-5.34"/>
        <path   d="m383.38,275.92c-.04.96-1.01,5.22-.2,5.41"/>
        <path   d="m382.33,276.22c.24-1.09-.14-3.72-.25-4.97"/>
        <path   d="m383.38,273.7c.15,1.68.6,4.2.25,5.7"/>
        <path   d="m384.55,275.46c-.1-1.22-.39-2.81-.09-3.89"/>
        <path   d="m385.43,278.25c-.06-.72-.42-3.05-.17-3.39"/>
        <path   d="m386.29,272.46c.14,1.58.43,2.99-.27,3.99-.13-1.49.14-3.01-.03-4.67"/>
        <path   d="m386.84,276.93c-.19.52-.35,1.48-.37,2.15"/>
        <path   d="m387.52,272.04c.12,1.4.2,2.59.03,3.91.56.24.35-.94.29-1.66"/>
        <path   d="m387.75,281.08c.1-1.17-.63-3.78-.03-4.54.26.93-.14,4.66.66,4.66"/>
        <path   d="m389.27,275.33c-.14-1.15-.51-2.81.06-3.6.11,1.42.08,2.58.1,3.93"/>
        <path   d="m390.5,281.45c-.05-.63-.64-4.53-.29-4.57.63-.06.47,3.75.52,4.4"/>
        <path   d="m391.3,277.4c-.34-1.27-.98-4.02-.18-4.75.03-.28.09-.34.15-.56.22,1.63-.63,2.6-.21,4.01"/>
        <path   d="m392.5,280.95c-.16-1.49-.51-2.89-.55-4.4.71-.29.76,3.02.68,3.76"/>
        <path   d="m393.75,280.42c-.1-1.14-.23-2.37-.24-3.46,0-.91-.21-1.38-.26-2.26.27,1.25.58,3.95.46,5.26"/>
        <path   d="m393.98,277.14c-.15-1.8-.37-3.51-.51-5.26.92-.49.43,2.78.55,3.55"/>
        <path   d="m395.38,280.7c-.07-1.48-.41-2.91-.51-4.41.03,0,.15-.02.17-.04.19.7.19,1.58.28,2.32.02-.51-.05-1.06-.09-1.58.48.77.51,2.12.58,3.23-.28-.28-.26-.95-.54-1.35"/>
        <path   d="m395.71,273.38c.01,1.53.72,3.29.11,4.79-.08-.68.12-1.12.11-1.75-.03-.58-.21-.92-.26-1.43-.09-.92-.12-2.52-.05-3.41"/>
        <path   d="m397.55,276.08c-.1-1.1-.65-3.59-.48-4.38.55,1.21.58,2.07.72,3.59"/>
        <path   d="m371.65,276.97c-.04-.67.32-.79.34-1.32.02-.55-.24-1.15-.34-1.64"/>
        <path   d="m377.51,282.26c-.13-.5-.21-.98-.26-1.54-.03-.43,0-.8-.14-1.12-.24-.57-.64-.73-.67-1.6"/>
        <path   d="m378.38,276.61c-.01-.26-.1-.46-.21-.6-.11.16-.36.63-.39.91-.06.52.2,1.28.24,1.82"/>
        <path   d="m379.37,273.9c.12,0,.21-.13.26-.33-.03-.03-.08-.05-.11-.05-.01-.04-.02-.08,0-.11.08.1.04-.01.09.16-.14-.3-.12-.71-.15-1.1-.04-.49-.12-.69.15-.87"/>
        <path   d="m384.53,282.18c-.18-.7-.21-1.27.03-1.87.23-.56.16-.81-.09-1.43"/>
        <path   d="m392.28,276.14c.06-.75.45-1.34.24-2.15-.2-.76-.52-1.18-.48-2.12"/>
        <path   d="m362.79,283.83c.13,1.48.62,2.22.98,3.48.3,1.05.3,2.26.4,3.42"/>
        <path   d="m371.81,290.71c-.7-2.2-.97-4.66-1.23-7.13.15.76.21,1.41.34,2.17.11.71.19,1.7.34,2.41.12.57.36.89.45,1.47.09.5.1,1.11.14,1.62"/>
        <path   d="m371.73,285.88c-.12-1.02.06-1.69.05-2.54.06.66.11,1.3.17,1.94"/>
        <path   d="m372.91,282.82c-.3-.57-.54-1.14-.53-1.97.34.43.55,1.04.56,1.79"/>
        <path   d="m372.77,290c-.1-1.18-.12-2.44-.27-3.59-.22,1.03-.21,2.45-.1,3.58"/>
        <path   d="m373.32,287.88c.56-1.39.31-2.82.16-4.52"/>
        <path   d="m374.37,283.96c-.05,2.17.03,4.87.97,6.42-.08-1.53-.48-2.32-.73-3.68-.14-.72-.3-1.94-.13-2.58"/>
        <path   d="m375.86,286.77c.1,1.34-.03,2.62.17,4.02.15,1.04.3,1.93.39,3.02"/>
        <path   d="m376.45,286.93c-.44-1.19-.49-2.44-.62-3.82"/>
        <path   d="m376.87,284.48c.03,1.7.63,3.39.45,5.14"/>
        <path   d="m377.63,287.83c.04.5.23,3.89.62,3.92.37.03-.38-2.92-.4-3.51"/>
        <path   d="m378.72,287c.16,1.82.36,3.66.39,5.44,0,.72.24,1.72.38.64.08-.65-.22-1.92-.28-2.6"/>
        <path   d="m378.43,282.63c.09.84.35,1.59.45,2.48.08.74.11,1.49.2,2.23.14,1.27.24,2.58.39,3.87.54.2.26-1.2.13-1.66-.23-.9-.37-1.74-.48-2.74-.12-1.11-.03-3.45-.49-4.04"/>
        <path   d="m380.06,284.83c.16,1.77.57,3.3.76,5.04.12,1.03.05,2.03.18,3.02.74.61.14-1.36.07-1.77-.2-1.22-.43-2.65-.53-3.9"/>
        <path   d="m380.65,282.4c-.05,1.5.27,3.13.4,4.6.07.83.08,2.95.44,3.41.2-.56,0-1.42-.14-2.01"/>
        <path   d="m382.18,290.17c-.09.81.19,1.93.16,2.8.67.02.11-2.28-.2-2.71"/>
        <path   d="m382.26,284.46c-.5-.08-.38.99-.33,1.62.07.73-.03,1.13-.02,1.74.49.11.35-.8.28-1.42-.07-.79.05-1.17.07-1.86"/>
        <path   d="m382.92,285.73c0-.67.03-1.4-.12-2.01-.13-.59-.34-.73-.36-1.47.68.04.72.53.75,1.51.03.55.15,1.34.04,1.83"/>
        <path   d="m383.31,290.97c-.03-.49-.16-1.11-.39-1.4-.2-.27-.43-.02-.52-.54"/>
        <path   d="m383.39,289.14c-.07-.84-.15-1.68-.22-2.53"/>
        <path   d="m385.52,280.41c.09.44.27.88.5,1.04.13-.57.46-.97.41-1.67"/>
        <path   d="m389.44,279.65c-.02.24-.03.5-.02.73"/>
        <path   d="m389.22,277.42c.17.4.31.91.39,1.39"/>
        <path   d="m386.72,285.67c-.04.73.21,1.12.24,1.76-.12.12-.11.27-.19.43"/>
        <path   d="m388.68,288.37c-.06-.38-.07-.79-.06-1.18"/>
        <path   d="m385.7,282.36c.39.76.45,3.1.55,4.2"/>
        <path   d="m386.08,292.23c-.04-.88-.26-1.68-.45-2.48-.28-1.24-.48-2.39-.69-3.68-.13-.81-.68-2.45-.12-2.91.47,1.3.43,2.9.88,4.29.52,1.6.55,3.24.76,4.98"/>
        <path   d="m386.81,283.93c.14-.59-.05-1.39.05-2.02.15.39.07,1.03.13,1.53"/>
        <path   d="m387.78,282.2c.11.38.36.84.55.97.04-.51-.06-1.09-.1-1.61"/>
        <path   d="m389.2,281.68c.18.34.39.61.5,1"/>
        <path   d="m391.1,283.21c-.06-.63-.11-1.26-.17-1.89"/>
        <path   d="m391.34,295.27c-.18-.63-.3-1.73-.25-2.45.23.56.44.99.44,1.69,0,.45-.26.65-.04,1.11"/>
        <path   d="m390.62,285.9c-.22-.37-.74-2.44-.33-2.58.16.67.38,1.27.41,2.01"/>
        <path   d="m391.14,292.48c.21-.91-.1-2.81-.28-3.78-.15-.77-.6-1.39-.66-2.18"/>
        <path   d="m392.31,285.57c-.11-.4-.27-.77-.42-1.1"/>
        <path   d="m394.82,284.05c-.05-.57-.11-1.14-.14-1.69.17-.16.32-.44.47-.68-.17.41-.3,1.14-.25,1.72"/>
        <path   d="m393.64,290.64c-.01-1.07-.49-1.58-.37-2.57.08-.68.15-.92.07-1.77"/>
        <path   d="m393.52,285.54c-.08-.87-.13-1.63-.35-2.43-.15-.55-.29-.79-.31-1.46.66.76.67,2.46.79,3.77"/>
        <path   d="m391.69,285.42c.35.72.46,1.39.33,2.23"/>
        <path   d="m389.12,284.81c-.13-.4-.24-1.09-.28-1.64.07.28.48,1.64.72,1.38"/>
        <path   d="m388.46,290.86c.13.4.35.59.38,1.12"/>
        <path   d="m388.51,289.21c.05.6-.29.45-.48.7-.06-.54-.02-1.35-.32-1.56"/>
        <path   d="m388.24,285.3c-.23.57,0,1.24.23,1.7-.22.53-.48-.14-.27-.61.15-.37.83-.77.17-1.3"/>
        <path   d="m388.09,284.93c-.37.39-.74-.24-.74-1.07"/>
        <path   d="m362.74,298.79c-.08-.96-.11-2.1-.31-3.02-.11-.53-.49-1.08-.56-1.58-.05-.4-.04-.75.17-.86.38-.2.39.84.43,1.33.14,1.42.65,2.89.52,4.36"/>
        <path   d="m364.33,294.86c.1,1.15-.09,2.48,0,3.71-.47-1.56-.37-2.35-.34-3.84"/>
        <path   d="m371.43,293.05c-.55-1.01-.71-1.56-.81-2.99.34.9,1.05,2.3,1.2,3.29"/>
        <path   d="m372.77,292.98c-.04-.47-.03-.97-.22-1.32,0,.29,0,.6.04.89"/>
        <path   d="m371.74,293.57c.08.94-.05,1.61-.01,2.48.03.85.35,1.86.56,2.57-1.2-.41-.47-3.76-1.19-4.68.08.21.17.35.26.53"/>
        <path   d="m374.02,293.38c-.52-.59.1-1.72.14-2.42.06-.88-.45-1.7-.29-2.33"/>
        <path   d="m372.62,294.26c.03.27.06.54.08.81"/>
        <path   d="m373.24,298.91c-.2-.75-.74-2.28-.6-3.2.15-.94.76.15.77.95-.97-.22.24,2.1.27,2.53"/>
        <path   d="m374.15,294.69c.27.36.67,1.18.56,1.91-.03.28-.24.35-.26.73-.02.26.05.67.11.88-.09-.71-.37-1.25-.45-1.97-.06-.54-.07-1.1-.11-1.64"/>
        <path   d="m375.43,295.45c-.16.72-.16,1.15.06,1.76.1.3.17.32.21.7.03.2-.06.59-.03.72.08.3.34.38.45.06.11-.32-.11-.6-.21-.88-.23-.59-.31-1.12-.38-1.83"/>
        <path   d="m376.96,293.8c-.01-.51.16-.89.16-1.39,0-.43-.13-.81-.14-1.24.26.08.25,1.17.29,1.59"/>
        <path   d="m376.9,297.07c-.09-.59-.18-1.02-.37-1.52-.13-.32-.35-.68-.09-.96.28.39.47.92.59,1.53.1.5.27.99.3,1.55"/>
        <path   d="m378.23,299.08c-.05-.63-.11-1.1-.26-1.67-.12-.44-.23-.71-.07-1.2.3.67.34,1.55.42,2.42"/>
        <path   d="m378.97,296.72c-.19-.55-.46-.81-.53-1.5-.04-.42.02-.83.01-1.26.47,0,.32,1.1.39,1.56.07.56.22,1.02.27,1.67"/>
        <path   d="m379.52,295.95c.16.81.63,1.14.85,1.85.07.21.05.42.11.66.11.49.36.85.55.21.25-.87-.2-2.04-.48-2.73-.02.52.34,2.34.15,2.66"/>
        <path   d="m381.14,296.96c-.09-1.04-.41-2.34-.34-3.37.5.82.52,2.06.62,3.19"/>
        <path   d="m384.86,290.07c-.15-.05-.27-.29-.32-.55"/>
        <path   d="m382.21,298.98c-.05-.69-.08-1.34-.05-1.95,0-.36.04-.7-.07-1.13"/>
        <path   d="m384.41,288.71c-.15-.18-.28-.48-.31-.79"/>
        <path   d="m384.26,285.63c.07.12.12.33.13.52"/>
        <path   d="m385.15,291.27c.05.32.02.64.05.97"/>
        <path   d="m383.76,292.37c-.01.12-.01.24,0,.36"/>
        <path   d="m382.56,295.66c-.02-.58-.26-.85-.3-1.35"/>
        <path   d="m382.78,297.27c.04.12.1.25.15.35"/>
        <path   d="m384.1,295.26c.04.26.02.56-.02.8"/>
        <path   d="m384.57,292.81c0,.32.14.52.27.68"/>
        <path   d="m386.91,289.22c.21.09.29.57.36.93.13.84.08,1.56.44,2.23.26.47.38.52.35-.18-.03-.85-.49-1.38-.52-2.16"/>
        <path   d="m383.31,295.41c-.21-.33-.42-.71-.44-1.25.03-.04.08-.06.11-.08.18.23.71,1.22.7,1.62"/>
        <path   d="m383.88,298.53c-.05-.69-.27-1.22-.32-1.88.06.44.1,1.51.41,1.5"/>
        <path   d="m384.09,293.98c.02.37.17.57.27.85.11.05.15.04.25.18-.31-.94-.37-1.03.18-1.17,0-.1-.02-.15-.05-.24"/>
        <path   d="m384.44,296.28c-.02.56.04,1.22.27,1.63.16.32.47.54.58.03-.22-.36-.65-.05-.63-.8"/>
        <path   d="m385.71,296.47c0-.19,0-.35-.02-.54"/>
        <path   d="m385.96,297.82c.01.14.03.28.04.43"/>
        <path   d="m386.04,294.27c-.04.18-.09.39-.13.58"/>
        <path   d="m386.76,295.4c.02.28.04.62.1.83"/>
        <path   d="m387.1,298.84c-.05-.73-.16-1.47-.34-2.17-.16-.59-.36-1.02-.42-1.65.21.86.43,1.42.52,2.36"/>
        <path   d="m388.02,298.43c-.14-.69-.12-.86,0-1.46.07-.37.09-.61.02-1.04-.18-1.02-.66-1.55-.87-2.48.48.91.86,1.57,1.02,2.82.05.41.06.78.05,1.18,0,.43-.17,1.2-.09,1.52"/>
        <path   d="m389.68,298.25c-.34-.97-.65-2.09-.86-3.19-.14-.72.07-1.1.03-1.78.05.54.18,1.08.21,1.61,0-.17-.03-.49,0-.69.33.76.28,1.39.19,2.19-.09.82.18,1.05.39,1.73"/>
        <path   d="m390.71,298.87c-.06-.55-.17-1.1-.28-1.63.22-.62-.57-2.13-.58-2.96.43.23.89,1.66.98,2.48.08.82.11,1.61.15,2.43"/>
        <path   d="m389.82,291.18c.52-1.32-.67-2.94-.54-4.23.76.31.72,2.83.82,3.99"/>
        <path   d="m392.49,298.59c0-1-.16-1.89-.28-2.87-.05-.42-.27-1.22.06-1,.4.27.15.94.13,1.41-.02.56.07,1.15.12,1.71"/>
        <path   d="m392.39,292.28c-.06-.94-.47-1.96-.57-2.96-.06-.54-.1-1.08-.14-1.61"/>
        <path   d="m393.45,295.64c-.1-.82-.46-2.08-.38-2.85"/>
        <path   d="m394.66,291.43c-.07-.32-.27-.59-.35-.91"/>
        <path   d="m394.66,298.1c-.09-1.05-.13-2.23-.31-3.23-.1-.53-.24-1.03-.31-1.53.09.13.14.4.2.59.14-.3.04-.74.04-1.11.35-.22.17.86.15,1.13-.03.44-.02.96.06,1.45.12.72.21,1.36.29,2.14.03.42.05,1,.15,1.32"/>
        <path   d="m395.93,289.22c-.19-.43-.24-.6-.28-1.15.12.15.27.51.34.78"/>
        <path   d="m396.41,283.02c-.01-.13-.03-.24-.03-.37"/>
        <path   d="m394.67,287.28c-.04-.19-.04-.42-.02-.55"/>
        <path   d="m396.32,298.21c.11-.62-.38-1.1-.46-1.73-.06-.47-.11-1.04-.12-1.51.45.3.23,1.08.28,1.66.07.54.32,1.07.37,1.69"/>
        <path   d="m396.19,290.26c.03.27.02.5-.06.68"/>
        <path   d="m396.07,293.81c-.21.36-.48-1.12.1-.31"/>
        <path   d="m369.76,287.42c-.1-1.18-.12-2.44-.27-3.6-.22,1.03-.21,2.45-.1,3.58"/>
        <path   d="m365.45,292.81c-.08-.97-.1-2.1-.3-3.01-.12-.53-.49-1.08-.56-1.58-.05-.4-.05-.75.16-.85.38-.21.39.84.44,1.32.13,1.42.65,2.89.52,4.35"/>
        <path   d="m366.45,293.21c-.03.42-.13.78-.05,1.23.48-.08.84,1.43.97,2.15.06.4.08.87.18,1.26.09.38.3.46.32.95-.16-1.12-.3-2.37-.54-3.41-.23-1.02-.86-1.08-.67-2.35"/>
        <path   d="m368.56,293.74c.47-2.9-1.42-3.28-1.46-5.96.1,0,.21.09.29.2-.06.16-.04.43-.08.55.17.1.33.22.49.26"/>
        <path   d="m368.16,298.06c.14-1.17-.06-2.4-.24-3.61.37,1.3.68,2.42.88,3.81"/>
        <path   d="m370,293.76c-.14-1.68-.21-2.92-.64-4.45-.15-.51-.51-1.11-.56-1.7.05-.08.09-.12.15-.17.4.62.55,1.62.7,2.51.14-.07.3-.21.42-.35.33.98.3,2.55.42,3.67"/>
        <path   d="m370.11,299.17c0-1.34-.25-3.26-.63-4.47-.19-.63-.6-1.54-.11-1.97.09.57.17,1.18.26,1.76.24-.27.39-.78.29-1.3.55,1.09.23,2.29.24,3.44,0,.84.38,1.76.15,2.55"/>
        <path   d="m361.37,270.81c-.04.42-.14.78-.05,1.24.48-.08.84,1.43.96,2.14.07.4.09.88.18,1.26.1.38.3.46.33.95-.16-1.12-.3-2.37-.54-3.41-.23-1.01-.87-1.08-.67-2.34"/>
        <path   d="m364.65,277.09c.47-2.9-1.42-3.27-1.46-5.96.1,0,.22.1.3.2-.07.17-.05.44-.09.55.17.11.33.22.49.27"/>
        <path   d="m364.16,282.67c.15-1.17-.06-2.41-.24-3.61.37,1.29.68,2.41.88,3.8"/>
        <path   d="m365.15,282.74c-.13-1.68-.2-2.92-.64-4.44-.15-.52-.51-1.12-.56-1.71.04-.08.09-.13.15-.16.39.61.55,1.62.7,2.51.15-.08.29-.2.42-.35.33.98.3,2.55.43,3.67"/>
        <path   d="m366,277.09c-.01-1.35-.26-3.27-.63-4.47-.19-.63-.6-1.55-.12-1.98.1.57.17,1.18.26,1.75.24-.27.4-.77.29-1.29.54,1.09.23,2.3.24,3.44,0,.84.37,1.78.15,2.56"/>
        <path   d="m365.7,276.84c-.04.42-.14.77-.06,1.23.48-.08.84,1.43.96,2.14.07.4.09.88.18,1.26.1.39.3.46.33.95-.16-1.12-.3-2.38-.54-3.41-.23-1.01-.87-1.08-.67-2.34"/>
        <path   d="m368.43,277.42c.47-2.91-1.42-3.29-1.46-5.97.1.02.22.1.3.19-.06.16-.05.45-.09.56.18.11.33.22.5.27"/>
        <path   d="m368.43,282.23c.14-1.16-.06-2.4-.24-3.6.37,1.29.68,2.4.88,3.81"/>
        <path   d="m369.9,280.11c-.13-1.67-.21-2.92-.64-4.44-.15-.52-.52-1.11-.57-1.7.05-.07.1-.13.15-.16.39.61.55,1.62.7,2.5.14-.07.29-.2.41-.35.34.98.31,2.56.43,3.67"/>
        <path   d="m371.57,282.81c-.01-1.34-.26-3.26-.63-4.47-.2-.63-.6-1.55-.12-1.98.1.58.17,1.18.26,1.76.23-.26.39-.77.28-1.3.55,1.09.23,2.31.24,3.44,0,.85.38,1.77.15,2.56"/>
        <path   d="m360.52,277.2c-.04.42-.14.78-.06,1.23.48-.07.85,1.43.97,2.15.06.39.08.87.18,1.25.09.38.3.46.33.96-.16-1.13-.31-2.38-.54-3.4-.23-1.02-.86-1.09-.67-2.36"/>
        <path   d="m363.48,282.46c.47-2.91-1.42-3.29-1.46-5.96.1.01.21.09.3.2-.06.16-.05.43-.09.56.17.1.34.22.5.26"/>
        <path   d="m364.6,287.06c.14-1.17-.05-2.42-.24-3.61.37,1.29.68,2.42.88,3.81"/>
        <path   d="m366.63,289.91c-.14-1.68-.21-2.92-.65-4.45-.15-.52-.51-1.11-.56-1.7.05-.08.09-.13.15-.16.39.61.54,1.62.7,2.5.15-.07.29-.2.42-.35.33.98.31,2.56.43,3.67"/>
        <path   d="m368.09,289.36c-.01-1.35-.26-3.27-.63-4.47-.19-.63-.6-1.54-.11-1.98.1.58.17,1.18.26,1.76.23-.27.39-.77.29-1.3.54,1.09.23,2.31.24,3.44,0,.85.38,1.77.14,2.57"/>
        <path   d="m365.4,297.7c.23-.98-.53-2.99-.72-3.96-.06-.36-.41-1.61-.3-1.97.19-.62.45.03.53.41.11.51.02,1.07.09,1.57.08.51.27.97.36,1.48.22,1.15.46,2.37.56,3.55-.1-.26-.23-.65-.24-1.04"/>
        <path   d="m430.85,294.07s-1.83.79-1.71,1.48"/>
        <path   d="m501.7,265.26s1.68,3.77.68,3.73"/>
        <path   d="m500.8,266.62s1.27,5.62-.57,4.61"/>
        <path   d="m499.32,263.7c.18,1.5.2,2.06.11,3.31-.09,1.26-.01,1.56.58,2.54"/>
        <path   d="m498.51,267.49c.38,1.29.3,2.15-.05,2.79.22.39.35.89.29,1.33"/>
        <path   d="m496.3,264.68c.12.97.21,1.94.35,2.89.03-.04.06-.07.1-.07-.31.93.3,2.05.33,2.79"/>
        <path   d="m496.57,272.22c-.27-.99-.4-1.9-.53-2.94"/>
        <path   d="m493.83,272.93c-.33-1.67-.06-2.24.16-3.62.18-1-.35-3.03.09-3.67"/>
        <path   d="m495.03,270.22c-.22-1.89-.31-3.66-.58-5.46"/>
        <path   d="m492.97,268.9c-.1-.46-.35-.8-.38-1.3-.02-.33.16-.59.14-.95-.03-.65-.06-1.04-.44-1.3"/>
        <path   d="m491.71,268.08c-.03,1.01.26,1.73.17,2.85-.06.82-.23,1.59.05,2.21"/>
        <path   d="m490.63,268.94c.51-.83.49-2.09.59-3.31"/>
        <path   d="m490.26,268.08c-.08.94-.38,2.59-.24,3.47.09.56,1.11,1.55.3,2.04"/>
        <path   d="m489.1,270.41c-.5-.94-.12-3.46-.01-4.77"/>
        <path   d="m488.81,274.31c-.08-.98.09-2.46.35-3.36"/>
        <path   d="m487.93,268.49c-.1,1.13.42,1.87.36,2.87"/>
        <path   d="m487.91,272.89c-.63-.29-1.11-1.82-1.02-3.13.04-.49.74-2.71-.11-2.37-.44.18-.43,2.23-.49,2.99-.11,1.27-.32,2.09-.52,3.08"/>
        <path   d="m482.28,276.88c.06-1.05-.02-3.46.59-4,.34.85-.08,3.11-.18,4.2"/>
        <path   d="m483.56,272.55c-.01-.97.11-5.77.69-5.97,1.02-.37-.15,4.52-.18,5.09"/>
        <path   d="m484.15,275c-.06-1.35-.03-3.58.6-4.65.62.71.24,4.56-.13,5.58"/>
        <path   d="m482.5,270.58c.11-1.18-.06-2.25.29-3.36"/>
        <path   d="m481.56,271.96c-.1.93-.45,1.75-.42,2.72,0,.53.46,1.62.06,1.97"/>
        <path   d="m480.18,272.55c.1-1.6,1.69-3.88.65-5.25"/>
        <path   d="m479.75,271.75c-.12,1.41-.1,1.93.04,3.15.08.67.2,2.28-.28,2.42"/>
        <path   d="m478.69,271.59c.1-1.03.48-2.89-.14-3.33"/>
        <path   d="m478.56,273.66c-.23,1.6.19,3.08.09,4.56"/>
        <path   d="m477.39,272.5c.12-1.44.55-3.61-.1-4.55"/>
        <path   d="m477.35,278.04c.07-.9.38-2.17.13-2.92"/>
        <path   d="m476.94,274.21c-.04-.27-.03-.72.03-1.09"/>
        <path   d="m476.2,267.46c-.13,1.15-.49,2.03-.63,3.17-.12.82-.22,1.66-.29,2.52"/>
        <path   d="m467.16,280.35c.13-1.45-.47-3.42.24-4.73"/>
        <path   d="m465.09,278.63c-.07-1.02-.65-1.31-.46-2.53.1-.65.98-2.05.47-2.6"/>
        <path   d="m464.07,273.52c.42-1.21.99-2.87,1.05-4.32"/>
        <path   d="m463.62,280.23c.07-.83.5-2.4.4-3.1"/>
        <path   d="m462.74,277.72c0-1.26-.11-2.45.3-3.63.41-1.13.62-1.2.68-2.64"/>
        <path   d="m462.25,274.63c-1.74,1.75.33,4.59-.43,6.28"/>
        <path   d="m460.38,278.61c.14-1.13.04-2.28.31-3.37.24-.97.7-1.84.44-3.08"/>
        <path   d="m459.52,275.18c-.09,1.03-.21,2.06-.27,3.09-.04.67.1,1.63-.1,1.84"/>
        <path   d="m460.18,271.9c.38-.99.1-1.62.29-2.63"/>
        <path   d="m459.58,272.98c-.03.43-.07.88-.12,1.3-.12.04-.26.19-.38.19"/>
        <path   d="m458.08,278.39c.01.63-.09,1.34.21,1.75"/>
        <path   d="m457.55,280.67c.1-1.11-.01-2.34-.55-2.79"/>
        <path   d="m456.87,277.01c.7-.71-.29-2.67.08-3.77"/>
        <path   d="m459.53,270.96c-.11-.42-.03-.84.11-1.2"/>
        <path   d="m456.87,278.42c-.51.39-.27,1.49-.32,2.26"/>
        <path   d="m456.18,278.14c.1-.42.1-.8-.04-.98"/>
        <path   d="m457.1,270.13c-.73,1.02-.38,3.45-.79,4.73"/>
        <path   d="m455.66,280.2c-.47-1.4.28-3.7.17-5.34"/>
        <path   d="m454.35,270.17c.04.96,1.01,5.21.21,5.41"/>
        <path   d="m453.79,280.42c-.24-1.08.14-3.72.25-4.96"/>
        <path   d="m453.68,271.74c-.15,1.68-.6,4.2-.25,5.7"/>
        <path   d="m452.46,280.55c.1-1.22.39-2.81.09-3.89"/>
        <path   d="m451.83,276.68c.06-.72.42-3.05.17-3.39"/>
        <path   d="m450.83,274.81c-.14,1.58-.43,2.99.27,3.99.12-1.49-.14-3,.03-4.67"/>
        <path   d="m451.16,270.74c.19.52.34,1.48.37,2.15"/>
        <path   d="m450.38,271.42c-.12,1.4-.2,2.59-.02,3.91-.57.23-.36-.94-.3-1.66"/>
        <path   d="m449.17,274.37c-.1-1.17.63-3.78.03-4.55-.25.93.14,4.67-.66,4.67"/>
        <path   d="m448.94,280.95c.14-1.15.51-2.82-.06-3.6-.11,1.42-.08,2.59-.1,3.94"/>
        <path   d="m447.83,277.91c.05-.63.64-4.53.29-4.57-.63-.06-.47,3.75-.52,4.4"/>
        <path   d="m446.95,275.63c.34-1.28.98-4.02.19-4.75-.04-.28-.09-.34-.15-.56-.22,1.62.64,2.6.21,4.01"/>
        <path   d="m446.3,282.12c.16-1.49.5-2.9.55-4.4-.71-.28-.75,3.02-.68,3.77"/>
        <path   d="m445.62,277.86c.09-1.14.23-2.37.24-3.46.01-.91.21-1.38.26-2.26-.27,1.26-.58,3.95-.46,5.26"/>
        <path   d="m443.65,276.37c.15-1.8.37-3.51.51-5.26-.91-.49-.43,2.78-.55,3.55"/>
        <path   d="m442.43,280.02c.07-1.48.41-2.91.5-4.4-.03,0-.14-.02-.17-.04-.19.69-.19,1.58-.28,2.32-.02-.51.05-1.06.09-1.57-.48.77-.51,2.12-.58,3.23.28-.28.26-.95.54-1.35"/>
        <path   d="m441.71,273.51c-.01,1.53-.72,3.29-.11,4.79.08-.68-.12-1.12-.11-1.75.03-.58.21-.92.25-1.43.09-.91.12-2.52.06-3.41"/>
        <path   d="m440.47,283.21c.1-1.1.64-3.59.48-4.38-.54,1.2-.58,2.06-.72,3.59"/>
        <path   d="m503.34,274.53c-.74-.03-1.07-.15-1.27-1.9"/>
        <path   d="m500.73,272.18c.47,1.43,1.13,2.9,1.21,4.55.08,1.61-.47,2.5.02,4.25-.3-1.12-.46-2.17-.63-3.6"/>
        <path   d="m500.46,278.64c-.11-.46-.35-.85-.57-1.07-.13.66.1,1.71.18,2.49-.08.03-.15.02-.23.02"/>
        <path   d="m500.33,276.87c-.11-.96.15-1.66.08-2.58-.08-1.28-.3-1.27-.82-2.09"/>
        <path   d="m498.63,272.48c.13,1.2.36,3.09.2,4.11-.62-.67-1.25-2.82-1.61-4.03"/>
        <path   d="m498.45,280.55c-.07-.57-.56-3.22-.2-3.43.36-.22.8,1.89.84,2.52"/>
        <path   d="m496.07,281.49c-.42-2.17-.75-4.26-1.01-6.51-.08-.72-.33-1.82-.08-2.27.62.96.98,3.57,1.37,4.96.29.98.58,1.99.85,2.95"/>
        <path   d="m495.47,286.6c-.2-.91-.34-2.01-.39-2.93.46.11.89,1.08.96,2.03"/>
        <path   d="m494.45,283.9c-.31-2.59-.59-5.13-.78-7.69-.07-1.03-.36-3.71-1.06-3.23-.97.66.64,3.14.76,4.38.09.9-.16,1.62-.13,2.53.04,1.35.33,2.88.48,4.24"/>
        <path   d="m488.98,284.04c.11-2.15.8-4.51,1.08-6.64.16-1.28.73-1.85.88-2.99-.03,1.07-.38,2.04-.52,3.05-.1.75-.15,1.52-.25,2.24-.23,1.69-.39,2.96-.34,4.61"/>
        <path   d="m487.62,284.87c.17-1.56.03-4.26.72-5.47.09.88.01,1.83.05,2.71.04.72.15,1.36.2,2.1"/>
        <path   d="m486.33,284.14c.08-.95-.48-1.38-.41-2.3.04-.68.58-1.82.82-2.37.57-1.28.88-1.68,1.04-3.4-.31,2.99-.53,6.31-.71,9.29-.09.02-.2.02-.3-.03"/>
        <path   d="m484.34,286.44c.02-.68-.18-1.29-.2-1.83.05.12.2.26.27.34-.07.05-.15-.03-.2,0,.09.06.25.06.36.07-.16-.66.09-1.53-.16-2.23-.31-.87-1.02-.47-1.2-1.46-.27-1.46.76-3.83,1.35-4.48.07.96-.21,1.93-.16,2.87.03.89.34,1.1.51,1.68.37,1.27.03,3.13-.1,4.64"/>
        <path   d="m483.04,287.3c.06-.65-.15-1.22-.15-1.87,0-.49.09-1.07.14-1.56.32.72.42,2.34.51,3.28"/>
        <path   d="m481.35,286.97c.18-1.96.58-3.85.8-5.78.11-.98-.02-2.58.46-3.2.15,1.47-.25,3.7-.38,5.24-.14,1.59-.11,2.36.15,3.81"/>
        <path   d="m479.68,287.6c.15-1.77.33-3.6.54-5.34.33,1.4-.53,4.6.46,5.16"/>
        <path   d="m478.09,285.63c-.27-1.61.23-2.74.71-3.87.37-.86.41-1.8.49-2.92.02,1.18.07,2.05-.03,3.25-.1,1.12-.22,2.24.08,3.18-.01.12-.05.15-.13.09"/>
        <path   d="m476.13,287.7c.14-1.96.63-4.12,1.26-5.75.27,1.08-.36,2.73-.43,3.99-.04.8,0,1.56-.08,2.39"/>
        <path   d="m475.32,286.35c.15-1.66.16-3.68.76-4.96.39-.84.8-1.16.87-2.42-.25,1.38-.3,2.75-.48,4.18-.16,1.19-.38,2.2-.49,3.45"/>
        <path   d="m465.9,280.33c.05-.66-.32-.79-.34-1.32-.02-.55.24-1.15.34-1.64"/>
        <path   d="m462.07,273.72c.13-.5.2-.98.26-1.54.03-.43,0-.8.15-1.12.24-.57.64-.73.67-1.6"/>
        <path   d="m457.99,275.6c0-.27.1-.47.21-.6.11.16.36.63.39.91.06.52-.2,1.28-.24,1.82"/>
        <path   d="m458.31,272.6c-.12,0-.21-.13-.26-.33.03-.03.08-.05.11-.05.01-.04.02-.08,0-.11-.08.1-.04-.01-.08.16.14-.3.12-.71.15-1.11.04-.49.12-.69-.15-.86"/>
        <path   d="m452.73,273.38c.19-.7.21-1.26-.03-1.86-.23-.56-.16-.81.09-1.43"/>
        <path   d="m445.1,275.01c-.06-.75-.45-1.34-.24-2.16.2-.76.52-1.18.49-2.12"/>
        <path   d="m442.31,272.46c.1-.15.26-.4.31-.63.08-.37-.03-.35-.02-.72-.05.05-.08.02-.13.09.03-.07.05-.13.08-.19.06.31-.1.33-.12.57-.02.18.04.36.03.57"/>
        <path   d="m475.32,281.11c.04,1.93-.55,3.47-.84,5.33-.12.71-.09,1.2,0,1.9.08.65,0,1.13-.32.46-.2-.45-.16-1.24-.22-1.84-.08-.74-.08-1.49.14-2.21.15-.43.36-.76.47-1.22.12-.49.13-1.09.18-1.62"/>
        <path   d="m471.97,287.43c.47-1.62.88-3.03,1.04-4.83.09-.97.07-1.96.42-2.65.04,0,.08,0,.11,0,.19,1.39-.16,2.82-.56,4.03-.42,1.26-.78,2.75-.94,4.19"/>
        <path   d="m471.49,281.36c-.18,1.93-.39,3.77-.77,5.59-.12.6-.19,1.17-.16,1.8.02.35.09.69.06,1.05-.65-.12-.2-3.52-.11-4.29.1-.89.34-1.7.6-2.44.14-.35.32-.66.35-1.1"/>
        <path   d="m470.72,280.11c-.16,1.79-.31,3.16-.95,4.58-.17.38-.36.77-.42,1.27-.07.52.04.99-.05,1.52-.87-.33-.12-2.53.2-3.45.21-.59.41-.82.48-1.48.06-.52.1-1.05.14-1.57"/>
        <path   d="m469.05,280.39c-.08.9-.15,1.8-.2,2.66-.25-.66-.01-2,.06-2.82"/>
        <path   d="m469.05,283.15c-.13,1.48-.63,2.22-.98,3.48-.3,1.05-.3,2.26-.4,3.41"/>
        <path   d="m460.65,288.65c.7-2.2.97-4.66,1.23-7.12-.15.76-.21,1.41-.34,2.17-.11.72-.19,1.7-.34,2.41-.12.57-.36.89-.45,1.47-.08.5-.1,1.1-.14,1.62"/>
        <path   d="m460.3,288.31c.12-1.03-.06-1.69-.05-2.54-.06.66-.11,1.3-.17,1.94"/>
        <path   d="m460.35,283.77c.3-.57.54-1.14.53-1.97-.34.43-.54,1.04-.56,1.79"/>
        <path   d="m459.33,286.66c.1-1.18.12-2.44.27-3.59.22,1.03.21,2.45.1,3.57"/>
        <path   d="m459.02,285.41c-.56-1.39-.31-2.82-.16-4.52"/>
        <path   d="m458.56,284.07c.05,2.17-.03,4.87-.97,6.42.08-1.54.48-2.32.74-3.68.14-.72.3-1.95.13-2.58"/>
        <path   d="m457.58,281.35c-.1,1.34.03,2.62-.18,4.02-.15,1.04-.3,1.93-.39,3.02"/>
        <path   d="m455.53,290.62c.44-1.19.49-2.44.61-3.82"/>
        <path   d="m455.91,281.3c-.03,1.7-.63,3.39-.45,5.14"/>
        <path   d="m454.6,286.1c-.05.51-.23,3.89-.62,3.92-.37.03.38-2.92.4-3.51"/>
        <path   d="m454.26,280.98c-.16,1.82-.36,3.66-.38,5.44-.01.72-.24,1.73-.38.64-.08-.65.22-1.92.28-2.6"/>
        <path   d="m452.64,281.28c-.09.84-.35,1.59-.45,2.48-.08.74-.11,1.49-.2,2.23-.14,1.27-.24,2.58-.4,3.87-.54.2-.25-1.19-.13-1.66.23-.9.37-1.74.48-2.74.13-1.11.03-3.46.49-4.05"/>
        <path   d="m450.81,281.72c-.15,1.76-.57,3.3-.76,5.04-.12,1.03-.05,2.02-.18,3.02-.74.61-.14-1.36-.07-1.77.2-1.23.43-2.66.53-3.91"/>
        <path   d="m449,282.09c.05,1.5-.27,3.13-.4,4.6-.07.83-.08,2.95-.44,3.41-.21-.56,0-1.42.14-2.01"/>
        <path   d="m447.17,287.8c.09.8-.18,1.93-.16,2.8-.67.02-.11-2.28.2-2.71"/>
        <path   d="m447.05,283.38c.5-.09.38.99.33,1.61-.07.74.03,1.13.02,1.74-.49.1-.34-.8-.28-1.41.07-.8-.06-1.17-.07-1.86"/>
        <path   d="m445.29,291.14c0-.67-.03-1.4.12-2.01.13-.59.35-.73.36-1.47-.68.04-.72.53-.75,1.5-.03.55-.15,1.34-.04,1.83"/>
        <path   d="m445.42,286.03c.03-.49.16-1.11.38-1.4.2-.27.43-.02.52-.54"/>
        <path   d="m444.45,285.91c.07-.83.14-1.68.22-2.53"/>
        <path   d="m451.81,279.59c-.09.43-.27.88-.5,1.04-.12-.57-.46-.97-.41-1.67"/>
        <path   d="m449.96,279.18c.02.24.03.49.02.73"/>
        <path   d="m450.15,276.41c-.17.4-.31.91-.39,1.39"/>
        <path   d="m444.36,288.48c.04.73-.21,1.12-.24,1.76.12.12.11.28.19.43"/>
        <path   d="m444.85,287.03c.06-.38.06-.79.06-1.18"/>
        <path   d="m441.79,283.81c-.39.76-.46,3.1-.55,4.2"/>
        <path   d="m441.9,292.07c.05-.88.26-1.68.45-2.48.28-1.24.48-2.39.69-3.68.12-.8.68-2.45.11-2.91-.46,1.3-.43,2.9-.88,4.29-.52,1.6-.54,3.24-.76,4.98"/>
        <path   d="m447.69,282.19c-.14-.58.05-1.39-.05-2.02-.15.39-.07,1.03-.13,1.53"/>
        <path   d="m446.78,276.51c-.11.38-.36.84-.55.97-.04-.51.06-1.09.1-1.61"/>
        <path   d="m445.6,281.02c-.18.34-.39.61-.5,1"/>
        <path   d="m444.72,279.81c.05-.63.11-1.26.17-1.89"/>
        <path   d="m440.87,291.77c.19-.63.3-1.73.26-2.45-.23.56-.44.99-.43,1.69,0,.45.26.65.04,1.1"/>
        <path   d="m440.03,289.16c.22-.37.74-2.44.33-2.58-.16.66-.38,1.27-.41,2.01"/>
        <path   d="m439.11,289.93c-.21-.91.11-2.81.28-3.78.15-.77.59-1.39.66-2.18"/>
        <path   d="m439.33,292.01c.11-.39.27-.77.42-1.1"/>
        <path   d="m438.34,286.96c.05-.57.11-1.14.14-1.7-.17-.16-.32-.44-.47-.67.17.41.29,1.14.25,1.72"/>
        <path   d="m437.87,292.44c.01-1.07.49-1.57.37-2.57-.09-.68-.15-.92-.08-1.77"/>
        <path   d="m436.83,289.82c.08-.87.14-1.63.35-2.42.15-.55.29-.79.31-1.46-.66.76-.68,2.46-.79,3.77"/>
        <path   d="m439.52,296.73c-.35.72-.46,1.39-.33,2.23"/>
        <path   d="m440.83,272.63c.13-.4.24-1.09.29-1.64-.07.28-.48,1.64-.73,1.38"/>
        <path   d="m442.03,281.45c-.13.4-.35.59-.38,1.12"/>
        <path   d="m442.53,282.03c-.05.61.29.46.49.71.05-.54.02-1.35.32-1.56"/>
        <path   d="m444.02,277.73c.23.56,0,1.24-.23,1.7.21.53.48-.14.27-.62-.15-.37-.83-.76-.17-1.29"/>
        <path   d="m443.87,281.84c.37.4.74-.24.74-1.07"/>
        <path   d="m504.19,272.93c.17.82-.1,1.86-.4,2.08"/>
        <path   d="m506.54,282.23c-.21-.68-.58-.91-.82-1.44"/>
        <path   d="m507.68,292.34c-.07-.43.05-1.16-.08-1.53-.08-.23-.34-.32-.48-.43-.11-.59.16-1.39.37-1.58"/>
        <path   d="m506.58,283.79c.31.73.17,1.22,0,1.72-.15.47-.28,1.21-.26,1.91"/>
        <path   d="m505.66,284.45c.02.24.05.45.09.69-.5-.21-.65-.98-.97-1.65"/>
        <path   d="m504.88,282.18c-.1-.63,0-1.39.36-1.15.11.49-.21,1.08-.29,1.48-.09-.07-.1-.19-.13-.32"/>
        <path   d="m504.82,285.57c.23,1.69-.21,2.94-.06,4.47.08.83.33,1.22.47,2.03.09.46.23,1.54-.14,1.25-.03-.36-.25-1.62-.4-1.97-.02,0-.06,0-.08,0-.01.45.21,1.01.19,1.4-.05.68-.43-.22-.52-.71-.17-1.02.17-2.08.32-2.81.1-.46.02-.93.09-1.39.08-.54.2-.57.05-1.2-.21-.92-.53-1.67-.15-2.38.08.22.13.52.14.79"/>
        <path   d="m503.19,280.25c.14,1.08-.07,1.87,0,2.85.08,1.17.23,2.32.05,3.31-.07.4-.13.67-.12,1.13.01.42.2.91.21,1.28.07,1.29-.46.19-.66-.26-.05.25-.08.53-.05.83-.44-.73.29-3.16.41-3.77.1-.6.18-1.27.19-1.97,0-.67-.1-.68-.24-1.26-.33-.28-.56-1.1-.43-1.62.23-.09.28.55.49.43.12-.08.14-.71.13-.95"/>
        <path   d="m502.06,293.9c-.36-.95-.36-1.83-.31-2.76.05-.98.41-1.72.43-2.62,0-1.02-1-2.98-.29-3.6.36.53.28,1.77.33,2.44.06.76.3,1.74.29,2.47,0,.83-.35,1.13-.4,1.97-.05.69.04,1.31.18,2.09"/>
        <path   d="m501.33,284.22c-.07-.66-.45-2.51.03-2.47.25.91.16,2.16.49,2.9"/>
        <path   d="m500.23,288.41c-.09-.78-.22-1.61-.24-2.37-.03-.8.14-1.4.14-2.18,0-.97-.31-1.13-.39-2.03-.06-.64.09-1.06.45-.86.21.87-.13,1.29-.07,2.07.05.69.31,1.25.32,1.95-.01.77-.14,1.16-.08,2.03.04.63.14,1.28.21,1.9"/>
        <path   d="m499.76,293.14c-.3-1.85-.99-3.52-.6-5.11.23-.96.4-1.4.17-2.75-.11.35-.2.79-.36,1.02-.15-.32-.26-.75-.3-1.14-.66-.32.12,1.43.19,1.78.18.78-.01,1.87-.02,2.69,0,1.29-.09,4.29.84,4.79"/>
        <path   d="m497.46,292.51c-.47-.16-.59-1.45-.68-2.2-.15-1.08-.13-1.73-.07-2.66.04-.69.14-1.55.08-2.28-.04-.6-.25-1.46.05-1.71.07.31.11.63.14.94.19.02.43-.2.42-.66-.02-.49-.29-.61-.39-1-.18-.67.09-1.42.38-1.43.09.49.09,1.03.12,1.51.02.32.06.52.03.82-.02.31-.31.74-.27,1.03.05.57.49,1.04.52,1.79.02.78-.17.42-.42.59-.89.6-.07,3.41,0,4.84"/>
        <path   d="m494.77,287.3c-.03.86.31.8.37,1.46.06.79-.43,1.38-.64,1.89-.42,1.03-.24,2.36-.34,3.57-.04.39-.22,1.32.05,1.48.11.08.32-.29.35-.56.03-.29-.1-.54-.11-.84-.03-.89.3-1.96.56-2.62.2-.52.58-1.05.65-1.7.05-.53-.1-1.16-.06-1.72.01-.38.23-1.41-.14-1.22,0,.04.03.03.04.02-.07.31-.07.57-.2.77-.12-.32-.37-.78-.58-.42"/>
        <path   d="m492.7,290c.05-.54.03-1.41.14-1.89.1-.48.34-.87.47-1.25.24-.73.53-1.61.58-2.49.39,1.46.03,2.39-.47,3.39-.42.84-.52,1.61-.62,2.72"/>
        <path   d="m491.15,294.61c.15-1.72.29-2.65.84-4,.31-.74.7-1.8.38-2.58-.45.48-.52.88-.62,1.75-.09.79-.18,1.11-.42,1.76-.22.57-.37,1.17-.4,1.91-.03.57.35,2.12.21,2.27"/>
        <path   d="m490.61,290.78c-.64-.79-.43-2.72.02-3.74.4-.89.5-2.36,1.03-2.97.03.86-.43,1.41-.63,2.1-.15.53-.19.96-.24,1.58-.07.74-.2,1.08-.33,1.69-.12.53-.16.96.08,1.22"/>
        <path   d="m488.54,295.71c.13-1.49.19-1.97.72-2.95.28-.54.79-1.7.34-2.31-.03.9-.1.96-.43,1.16-.18.13-.25-.12-.43.1-.12.16-.27.78-.32,1.05-.24,1.11.04,2.57-.26,3.52"/>
        <path   d="m488.4,292.24c.07-.89-.11-1.8.07-2.81.14-.91.45-1.25.73-2.07.11-.32.59-1.45.57-1.83-.04-.7-.53-.38-.69.05-.2.56-.1,1.38-.22,1.98-.11.56-.34.95-.46,1.57-.1.54-.2,1.32-.2,1.89,0,.53.31,1.38.03,1.74"/>
        <path   d="m487.48,293.88s.23-.61.28-1.04"/>
        <path   d="m486.88,294.05c-.1-.4-.08-.92-.02-1.38"/>
        <path   d="m487.43,294.71c-.06.4-.04.78-.06,1.19"/>
        <path   d="m486.72,291.73c.39-1.05.61-1.63.77-2.97.06-.54.12-1.06.26-1.52.12-.4.31-.54.36-1.01-.18-.12-.7-.04-.87.18-.48.62-.36,1.45-.32,2.12.04.96-.13,1.92-.21,2.91"/>
        <path   d="m484.99,290.85c.07-.45.51-.77.73-.5.4.49-.03,1.28-.26,1.79-.3.63-.48.96-.6,1.79-.16.94-.1,1.87-.59,2.53-.09-.66.09-1.87.23-2.57.08-.48.34-.84.44-1.31.12-.56.1-1.13.14-1.72"/>
        <path   d="m484.05,287.93c.06.41.18.74.12,1.2-.48.03-.76,1.59-.85,2.33-.03.4-.03.87-.11,1.27-.07.4-.28.52-.27,1.01.1-1.13.17-2.4.35-3.47.18-1.05.81-1.25.54-2.46"/>
        <path   d="m486.08,287.36c.1.53-.1.88-.38.65,0-.15.01-.37.05-.52"/>
        <path   d="m483.73,296.13c0,.18-.05.35-.1.53-.23,0-.29-.46-.15-.76"/>
        <path   d="m484.49,290.12c.23-.32.48-.92.3-1.4"/>
        <path   d="m481.49,296.84c-.63-2.77,1.25-3.55,1.14-6.19-.1.03-.21.13-.28.26.07.15.07.43.11.53-.17.14-.32.29-.48.37"/>
        <path   d="m481.63,292.08c-.21-1.12-.07-2.39.05-3.6-.3,1.35-.55,2.52-.68,3.93"/>
        <path   d="m479.69,297.17c.04-1.68.04-2.91.39-4.52.12-.54.45-1.2.47-1.8-.06-.07-.1-.11-.16-.13-.36.69-.45,1.71-.57,2.62-.15-.04-.31-.14-.44-.25-.28,1.03-.16,2.58-.23,3.7"/>
        <path   d="m478.75,295.1c-.06-1.33.08-3.27.39-4.54.15-.66.51-1.65,0-1.97-.06.59-.11,1.2-.16,1.79-.25-.22-.45-.68-.37-1.22-.48,1.18-.1,2.32-.05,3.43.04.84-.28,1.83,0,2.56"/>
        <path   d="m477.53,297.34c.34-1.7.22-3.21.14-4.83"/>
        <path   d="m477.31,288.67c-.15.85-.38,1.58-.53,2.37-.12.64-.11.93-.4,1.47-.11.18-.29.35-.36.51-.26.65-.26,1.39-.34,2.2-.1,1.05-.2.95-.73.86"/>
        <path   d="m474.69,296.61c-.23-.98.52-2.99.72-3.96.07-.36.41-1.61.3-1.97-.19-.63-.45.03-.53.4-.11.5-.02,1.07-.09,1.57-.07.52-.26.97-.36,1.48-.22,1.15-.46,2.36-.56,3.55.1-.26.24-.65.24-1.04"/>
        <path   d="m473.53,295.03c.09-.96.11-2.1.31-3.02.11-.53.48-1.08.56-1.58.05-.4.04-.75-.16-.86-.38-.2-.4.84-.44,1.33-.13,1.42-.65,2.89-.52,4.36"/>
        <path   d="m472.61,294.11c-.1,1.15.09,2.48,0,3.71.47-1.56.37-2.35.33-3.84"/>
        <path   d="m463.02,294.71c.55-1.02.71-1.57.81-3-.34.9-1.05,2.3-1.2,3.29"/>
        <path   d="m460.21,290.7c.05-.47.03-.97.22-1.32.01.29,0,.6-.04.89"/>
        <path   d="m463.18,293.63c-.08.94.04,1.61.01,2.48-.03.84-.35,1.86-.56,2.56,1.2-.41.47-3.76,1.19-4.68-.09.21-.16.35-.26.54"/>
        <path   d="m461.85,296.73c.52-.59-.1-1.72-.14-2.42-.07-.88.45-1.7.29-2.32"/>
        <path   d="m460.86,291.31c-.03.27-.05.55-.08.81"/>
        <path   d="m460.7,298.22c.2-.75.74-2.28.61-3.2-.15-.94-.76.15-.77.95.97-.22-.25,2.09-.28,2.53"/>
        <path   d="m459.99,291.75c-.27.36-.67,1.18-.56,1.9.03.28.24.35.27.73.02.26-.06.67-.11.88.1-.7.37-1.25.45-1.97.06-.54.07-1.1.12-1.64"/>
        <path   d="m459.15,294.75c.16.71.16,1.15-.06,1.76-.1.3-.17.32-.21.7-.03.2.06.59.03.72-.07.3-.34.38-.44.06-.11-.33.11-.6.21-.88.23-.59.31-1.13.37-1.83"/>
        <path   d="m458.34,294.56c.01-.51-.16-.89-.16-1.39,0-.43.14-.81.14-1.24-.26.08-.25,1.17-.29,1.59"/>
        <path   d="m457.5,297.79c.09-.59.18-1.02.37-1.52.13-.32.35-.68.09-.96-.28.39-.47.92-.6,1.54-.1.5-.26.99-.29,1.54"/>
        <path   d="m456.84,294.08c.06-.63.11-1.1.26-1.67.12-.44.23-.71.08-1.2-.3.68-.35,1.54-.43,2.42"/>
        <path   d="m456.33,298.34c.19-.54.46-.81.53-1.5.04-.42-.02-.83-.02-1.27-.47,0-.32,1.1-.38,1.57-.07.56-.21,1.02-.27,1.67"/>
        <path   d="m456.32,294.04c-.17.81-.64,1.14-.85,1.85-.07.21-.05.42-.11.66-.11.49-.35.85-.55.21-.25-.87.2-2.04.47-2.73.03.53-.33,2.34-.15,2.67"/>
        <path   d="m454.7,294.5c.09-1.04.41-2.35.34-3.37-.5.82-.52,2.06-.62,3.19"/>
        <path   d="m453.42,296.1c.06-.69.08-1.34.05-1.95,0-.36-.04-.7.07-1.13"/>
        <path   d="m451.19,291.3c.15-.18.28-.47.31-.79"/>
        <path   d="m440.52,292.66c-.07.12-.12.33-.13.52"/>
        <path   d="m452.75,290.55c-.05.33-.02.64-.05.97"/>
        <path   d="m453.87,291.04c0,.12.01.24,0,.36"/>
        <path   d="m453.73,298.52c.02-.58.25-.85.3-1.35"/>
        <path   d="m450.47,291.66c-.06-.14-.13-.29-.2-.39"/>
        <path   d="m452.88,296.71c-.04.26-.02.56.02.8"/>
        <path   d="m438.52,293.07c0,.32-.14.52-.27.68"/>
        <path   d="m453.32,291.99c-.21.08-.3.57-.35.93-.14.84-.09,1.56-.45,2.23-.26.46-.38.52-.35-.19.03-.85.49-1.38.53-2.16"/>
        <path   d="m451.77,298.35c.2-.33.42-.71.44-1.24-.03-.04-.08-.06-.11-.08-.17.22-.71,1.22-.7,1.62"/>
        <path   d="m451.69,293.52c.05-.69.27-1.22.32-1.88-.06.44-.1,1.51-.41,1.5"/>
        <path   d="m451.85,295.89c-.02.37-.17.57-.27.85-.11.05-.15.04-.25.18.31-.94.37-1.03-.18-1.17,0-.1.02-.15.05-.24"/>
        <path   d="m450.8,292.2c.02.56-.04,1.22-.28,1.63-.16.31-.47.54-.58.03.22-.36.65-.04.63-.8"/>
        <path   d="m450.8,294.58c0-.19,0-.35.02-.54"/>
        <path   d="m450.49,296.49c-.01.14-.03.29-.04.43"/>
        <path   d="m450.58,297.69c.05.18.1.39.13.58"/>
        <path   d="m449.81,292.18c-.03.28-.04.62-.1.83"/>
        <path   d="m449.38,298.49c.05-.73.17-1.48.35-2.17.15-.6.36-1.01.42-1.66-.21.87-.43,1.43-.51,2.36"/>
        <path   d="m448.23,298.58c.14-.69.12-.86,0-1.46-.07-.37-.1-.61-.03-1.04.18-1.02.66-1.55.87-2.48-.48.91-.86,1.58-1.02,2.82-.06.42-.07.78-.06,1.18.01.43.17,1.2.09,1.52"/>
        <path   d="m447.55,295.55c.34-.97.65-2.08.85-3.19.14-.72-.07-1.1-.03-1.78-.04.54-.18,1.08-.21,1.61,0-.17.02-.49,0-.68-.33.76-.28,1.39-.19,2.19.09.82-.17,1.05-.39,1.74"/>
        <path   d="m446.02,298.48c.06-.55.17-1.1.28-1.63-.22-.62.57-2.13.58-2.96-.43.23-.89,1.66-.98,2.48-.08.82-.11,1.61-.15,2.43"/>
        <path   d="m445.64,295.86c-.52-1.32.68-2.94.54-4.23-.76.31-.72,2.82-.82,3.99"/>
        <path   d="m444.49,298.87c0-1,.16-1.89.28-2.87.05-.42.26-1.22-.06-1-.4.26-.15.94-.13,1.41.02.56-.07,1.15-.12,1.71"/>
        <path   d="m443.52,296.84c.05-.94.47-1.96.57-2.96.06-.54.1-1.08.14-1.61"/>
        <path   d="m442.26,298.93c.11-.82.46-2.08.38-2.84"/>
        <path   d="m442.28,294.62c.07-.32.26-.59.35-.91"/>
        <path   d="m440.79,298.63c.09-1.05.13-2.23.31-3.23.1-.52.24-1.02.31-1.53-.1.13-.15.4-.2.59-.14-.3-.04-.74-.03-1.11-.36-.22-.17.86-.15,1.13.03.44.02.97-.05,1.46-.13.72-.22,1.36-.29,2.14-.03.42-.05,1-.15,1.32"/>
        <path   d="m439.69,293.68c.27,1.2.37,1.67.51,3.27-.15-.42-.37-1.42-.5-2.19"/>
        <path   d="m438.51,295.76c.01-.13.03-.25.03-.37"/>
        <path   d="m439.73,297.96c.04-.19.04-.41.02-.55"/>
        <path   d="m437.76,299.25c-.11-.62.37-1.1.46-1.73.06-.47.11-1.04.12-1.51-.44.3-.22,1.08-.28,1.66-.06.54-.31,1.07-.37,1.69"/>
        <path   d="m436.68,294.97c-.03.27-.03.5.06.68"/>
        <path   d="m438.46,294.74c.22.36.48-1.12-.1-.3"/>
        <path   d="m506.54,281.11s.51-1.07-.19-.73c-.7.34-1.08.18-1.57-.13-.49-.32-1.98-.66-2.55-.29-.57.36-.95-.38-2.01.25-1.07.62-2.96.43-4.34,1.58-1.38,1.16-5.51,1.98-7.06,3.45-1.55,1.46-2.06.7-3.41,1.51-1.35.82-2.72.13-3.81.99-1.09.85-8.54,1.25-10.02,2.16-1.49.91-1.91-.07-3.08.32-1.17.39-2.94.33-3.64.67-.71.34-1.89.27-1.89.27,0,0-2.29-.59-3.28.22-1,.81-1.09-.23-1.09-.23,0,0-1.72-.52-2.22-.25-.49.26-.84.3-1.12-.07-.28-.37-2.57-.63-3.23-.63-.66.02-2.68-.32-4.75.5-2.07.82-5.28,1.67-5.9,1.92-.62.25-4.23,1.26-4.82,1.17-.58-.09-5.39.85-6.66.99-1.27.14-3.54-.91-4.19-.33-.66.58-1.87.15-2.74.25-.87.08-2.03-.22-2.82-.23-.79-.02-2.06-.56-2.82-.23-.76.34-1.59.09-1.93.15-.34.07-4.96-.57-7-.66-2.05-.09-9.47-1.37-10.18-1.48"/>
        <path   d="m397.42,280.2c1.06.75,9.09,1.64,10.12,1.77,1.04.13,3.72.46,5.3.66,1.59.19,1.35.16,3.11.38,1.76.22,4.17-.1,5.8.1,1.65.21,3.04.46,4.87.6,1.84.14.76-.09,2.35.11,1.58.19,4.98-.18,6.83-.13,1.86.05.54.6,3.62.27,3.08-.33,4.17-.54,4.5-.77.35-.23.78-.62,2.74-.9,1.97-.29,3.81-1.92,7.54-1.55,0,0,1.85.14,2.75.25.91.11,1.2-.11,1.44-.09.25.04,2.26.28,2.85.45.59.16,1.12-.48,2.34-.33,1.22.15,2.22-.35,2.83-.28.61.08,4.96-.8,6.27-.9,1.31-.11,2.09-.8,3.03-.78.92.02,1.81-.13,2.47-.4.66-.28.88-.52,1.88-.57,1-.05,1.93-.91,3.03-1.21,1.1-.31,1.65-.68,2.66-.83,1.01-.14,3.63-1.94,4.77-2.15,1.15-.21.98-.32,2.99-.52,2.01-.19,1.98-1.25,3.27-1.19,1.29.07.68,0,2.1.08,1.41.09,1.27-.63,3.03-.42,1.76.21,3.15.57,3.15.57"/>
        <path   d="m461.84,290.47c.1-1.18.12-2.44.27-3.6.22,1.03.21,2.45.1,3.57"/>
        <path   d="m469.97,297.93c.08-.97.11-2.09.3-3.01.12-.53.49-1.09.55-1.59.06-.4.05-.75-.15-.85-.39-.21-.39.84-.44,1.32-.13,1.42-.64,2.89-.52,4.35"/>
        <path   d="m470.02,290.65c.04.42.14.78.05,1.23-.48-.07-.84,1.43-.96,2.15-.07.4-.09.87-.18,1.26-.09.38-.3.46-.32.95.16-1.12.3-2.38.54-3.41.23-1.02.86-1.08.67-2.35"/>
        <path   d="m467.27,298.25c-.47-2.9,1.43-3.28,1.46-5.96-.09,0-.21.09-.29.2.06.16.05.43.08.55-.17.11-.33.22-.49.27"/>
        <path   d="m467.59,294.16c-.14-1.17.06-2.41.24-3.61-.37,1.3-.68,2.42-.88,3.81"/>
        <path   d="m465.55,298.31c.14-1.68.21-2.92.64-4.45.15-.51.51-1.11.56-1.69-.05-.08-.09-.13-.15-.17-.39.62-.55,1.63-.71,2.51-.14-.08-.29-.21-.42-.34-.33.97-.3,2.55-.42,3.66"/>
        <path   d="m464.77,297.27c0-1.34.26-3.26.63-4.47.19-.62.6-1.54.11-1.97-.1.57-.18,1.18-.26,1.76-.23-.27-.4-.78-.29-1.3-.55,1.09-.23,2.29-.24,3.44-.01.84-.38,1.76-.15,2.55"/>
        <path   d="m475.98,273.36c.04.42.14.78.06,1.23-.48-.07-.85,1.43-.96,2.15-.07.4-.09.88-.18,1.25-.1.38-.31.46-.33.95.16-1.12.3-2.37.54-3.41.23-1.01.86-1.08.67-2.34"/>
        <path   d="m473.47,273.2c-.47-2.9,1.42-3.28,1.47-5.96-.09,0-.22.1-.3.2.07.17.05.43.09.55-.17.11-.33.22-.49.27"/>
        <path   d="m473.6,278.79c-.15-1.17.05-2.41.24-3.61-.37,1.29-.68,2.4-.88,3.8"/>
        <path   d="m471.76,276.36c.14-1.68.2-2.93.64-4.44.15-.52.51-1.12.56-1.71-.04-.08-.09-.13-.15-.16-.39.61-.55,1.62-.7,2.51-.14-.08-.29-.2-.41-.35-.33.98-.31,2.55-.43,3.67"/>
        <path   d="m470.37,274.57c.01-1.35.26-3.27.63-4.47.19-.63.6-1.54.12-1.97-.1.57-.17,1.17-.26,1.75-.23-.27-.4-.77-.29-1.29-.54,1.09-.23,2.3-.24,3.43,0,.84-.37,1.78-.15,2.56"/>
        <path   d="m470.77,274.4c.04.42.14.77.06,1.23-.48-.07-.85,1.43-.96,2.14-.07.4-.09.87-.18,1.25-.1.39-.31.46-.33.95.16-1.12.3-2.37.54-3.41.23-1.01.86-1.08.67-2.34"/>
        <path   d="m468.04,274.57c-.47-2.91,1.43-3.29,1.46-5.97-.1.02-.22.09-.3.19.06.17.05.45.09.56-.18.11-.33.22-.5.27"/>
        <path   d="m468.81,279.35c-.14-1.17.06-2.41.24-3.6-.37,1.29-.68,2.4-.88,3.81"/>
        <path   d="m466.85,276.82c.13-1.67.2-2.91.64-4.44.15-.52.52-1.11.56-1.7-.05-.07-.09-.13-.15-.16-.39.61-.55,1.62-.7,2.5-.14-.07-.29-.2-.41-.35-.34.98-.31,2.56-.43,3.67"/>
        <path   d="m466,275.17c.01-1.34.26-3.26.63-4.47.2-.63.6-1.55.11-1.98-.09.58-.17,1.17-.26,1.76-.23-.26-.39-.77-.28-1.3-.55,1.09-.23,2.31-.24,3.44,0,.85-.38,1.78-.15,2.57"/>
        <path   d="m467.88,281.36c.04.42.14.78.06,1.23-.48-.07-.85,1.43-.97,2.15-.06.39-.08.87-.18,1.25-.1.38-.3.46-.33.96.16-1.12.31-2.38.54-3.41.23-1.02.86-1.09.67-2.36"/>
        <path   d="m465,286.85c-.46-2.91,1.42-3.29,1.46-5.96-.1,0-.21.09-.29.2.06.17.04.44.09.56-.18.1-.33.22-.5.26"/>
        <path   d="m465.9,290.29c-.14-1.17.06-2.42.24-3.61-.37,1.29-.68,2.42-.88,3.81"/>
        <path   d="m463.72,288.96c.14-1.68.21-2.92.64-4.44.14-.52.51-1.11.56-1.7-.05-.08-.1-.13-.15-.16-.39.61-.54,1.62-.7,2.5-.14-.06-.29-.2-.41-.34-.34.98-.32,2.56-.43,3.67"/>
        <path   d="m462.87,287.86c.01-1.35.26-3.27.63-4.48.19-.63.6-1.54.11-1.97-.1.58-.17,1.18-.26,1.76-.24-.27-.39-.77-.29-1.31-.54,1.09-.22,2.31-.23,3.44-.01.85-.38,1.77-.15,2.57"/>
        <path   d="m471.7,296.3c-.22-.99.53-2.99.72-3.96.06-.36.41-1.61.3-1.97-.19-.63-.44.03-.53.4-.11.51-.03,1.07-.09,1.57-.08.51-.27.97-.36,1.48-.22,1.15-.46,2.37-.56,3.55.1-.26.23-.65.24-1.04"/>
        <path   d="m440.27,277.95c.07-.9.38-2.16.14-2.92"/>
        <path   d="m439.76,279.23c-.04-.27-.03-.72.03-1.09"/>
        <path   d="m439.5,271.62c-.12,1.15-.49,2.03-.63,3.16-.11.82-.22,1.66-.29,2.52"/>
        <path   d="m429.07,276.83c.13-1.45-.47-3.42.23-4.73"/>
        <path   d="m427.36,277.02c-.07-1.02-.65-1.31-.45-2.53.1-.65.98-2.05.47-2.6"/>
        <path   d="m425.53,276.29c.42-1.22.99-2.87,1.05-4.32"/>
        <path   d="m426.38,280.41c.07-.82.49-2.39.4-3.09"/>
        <path   d="m425.05,283.16c0-1.26-.11-2.46.3-3.64.4-1.13.62-1.2.68-2.64"/>
        <path   d="m425.39,272.6c-1.75,1.75.33,4.59-.43,6.28"/>
        <path   d="m423.38,283.16c.14-1.13.04-2.28.31-3.37.24-.97.7-1.84.44-3.08"/>
        <path   d="m423.56,272.4c-.09,1.03-.21,2.06-.27,3.09-.04.67.1,1.63-.1,1.84"/>
        <path   d="m422.41,278.13c.39-.99.1-1.62.29-2.63"/>
        <path   d="m421.71,272.24c-.04.43-.08.88-.12,1.3-.12.04-.26.19-.38.18"/>
        <path   d="m421.1,280.85c.02.63-.08,1.34.21,1.74"/>
        <path   d="m419.76,282.42c.1-1.11-.01-2.33-.55-2.8"/>
        <path   d="m420.29,279.67c.7-.71-.29-2.67.09-3.77"/>
        <path   d="m421.43,276.23c-.11-.42-.03-.84.11-1.2"/>
        <path   d="m419.38,273.43c-.51.39-.27,1.49-.32,2.26"/>
        <path   d="m419.49,278.41c.1-.42.1-.8-.04-.98"/>
        <path   d="m418.81,277.79c-.73,1.02-.38,3.45-.79,4.73"/>
        <path   d="m418.09,277.74c-.47-1.4.28-3.7.17-5.34"/>
        <path   d="m416.14,276.68c.03.96,1.01,5.21.2,5.41"/>
        <path   d="m417.2,276.97c-.24-1.08.14-3.72.25-4.96"/>
        <path   d="m416.14,274.45c-.14,1.68-.6,4.2-.25,5.7"/>
        <path   d="m414.98,276.21c.11-1.22.39-2.81.09-3.89"/>
        <path   d="m414.09,279c.07-.72.42-3.05.18-3.39"/>
        <path   d="m413.24,273.21c-.14,1.58-.43,2.99.27,3.99.12-1.49-.14-3,.03-4.67"/>
        <path   d="m412.69,277.69c.19.52.34,1.48.37,2.15"/>
        <path   d="m412,272.79c-.12,1.4-.2,2.59-.02,3.91-.57.23-.36-.94-.3-1.66"/>
        <path   d="m411.77,281.83c-.1-1.17.63-3.78.03-4.54-.26.93.14,4.66-.66,4.66"/>
        <path   d="m410.26,276.08c.14-1.15.5-2.82-.06-3.6-.11,1.42-.08,2.59-.1,3.94"/>
        <path   d="m409.03,282.2c.05-.63.64-4.53.28-4.57-.63-.06-.47,3.76-.52,4.4"/>
        <path   d="m408.23,278.16c.34-1.28.98-4.02.18-4.75-.04-.27-.09-.33-.15-.56-.22,1.63.63,2.6.21,4.01"/>
        <path   d="m407.03,281.7c.16-1.49.51-2.89.55-4.4-.71-.29-.76,3.02-.68,3.76"/>
        <path   d="m405.77,281.17c.1-1.14.23-2.37.24-3.46.01-.91.21-1.38.26-2.27-.27,1.26-.58,3.95-.46,5.26"/>
        <path   d="m405.55,277.9c.15-1.8.37-3.51.51-5.25-.91-.49-.43,2.77-.55,3.55"/>
        <path   d="m404.14,281.45c.07-1.48.41-2.91.51-4.4-.03,0-.14-.02-.17-.04-.19.69-.19,1.58-.29,2.32-.02-.51.06-1.06.09-1.57-.48.77-.51,2.12-.58,3.24.28-.29.26-.95.54-1.35"/>
        <path   d="m403.81,274.13c-.01,1.53-.72,3.29-.11,4.8.08-.68-.12-1.12-.11-1.75.02-.58.21-.92.25-1.43.09-.92.12-2.52.06-3.41"/>
        <path   d="m401.98,276.84c.1-1.1.64-3.59.47-4.38-.54,1.21-.58,2.07-.72,3.6"/>
        <path   d="m427.88,277.72c.04-.67-.32-.79-.34-1.33-.02-.55.24-1.15.34-1.64"/>
        <path   d="m422.02,283.02c.13-.49.21-.98.26-1.54.04-.43.01-.8.15-1.12.24-.57.64-.74.67-1.6"/>
        <path   d="m421.15,277.37c0-.27.1-.47.21-.6.11.16.36.63.39.91.06.52-.19,1.28-.24,1.82"/>
        <path   d="m420.16,274.65c-.12,0-.21-.13-.26-.33.03-.03.08-.04.11-.05.01-.04.02-.08,0-.11-.07.1-.04-.01-.08.16.14-.3.12-.71.15-1.1.04-.49.12-.69-.15-.87"/>
        <path   d="m414.99,282.93c.18-.7.21-1.26-.03-1.86-.22-.56-.16-.81.1-1.43"/>
        <path   d="m407.24,276.89c-.06-.75-.45-1.34-.24-2.15.2-.76.52-1.18.49-2.12"/>
        <path   d="m436.74,284.59c-.13,1.48-.62,2.22-.98,3.47-.3,1.05-.3,2.26-.4,3.41"/>
        <path   d="m427.72,291.46c.7-2.2.97-4.66,1.23-7.13-.15.76-.21,1.41-.34,2.17-.11.72-.19,1.7-.34,2.41-.12.57-.36.89-.45,1.47-.09.5-.1,1.11-.15,1.63"/>
        <path   d="m427.8,286.64c.12-1.02-.06-1.69-.05-2.54-.06.66-.12,1.3-.17,1.95"/>
        <path   d="m426.61,283.57c.3-.57.54-1.14.53-1.97-.35.43-.55,1.04-.56,1.79"/>
        <path   d="m426.75,290.75c.1-1.18.12-2.44.27-3.59.22,1.03.21,2.45.1,3.57"/>
        <path   d="m426.21,288.63c-.56-1.39-.31-2.82-.16-4.52"/>
        <path   d="m425.15,284.71c.05,2.17-.03,4.87-.97,6.42.08-1.54.48-2.32.73-3.68.14-.72.3-1.94.13-2.58"/>
        <path   d="m423.66,287.52c-.1,1.34.03,2.62-.18,4.01-.15,1.04-.3,1.93-.39,3.02"/>
        <path   d="m423.07,287.68c.44-1.19.49-2.44.62-3.82"/>
        <path   d="m422.66,285.23c-.03,1.71-.63,3.39-.45,5.14"/>
        <path   d="m421.89,288.58c-.04.51-.22,3.89-.61,3.92-.37.03.38-2.91.39-3.5"/>
        <path   d="m420.81,287.75c-.16,1.82-.36,3.66-.38,5.44-.01.72-.25,1.73-.38.64-.08-.65.22-1.92.28-2.6"/>
        <path   d="m421.09,283.38c-.09.84-.35,1.59-.45,2.48-.08.74-.11,1.49-.2,2.23-.14,1.27-.24,2.58-.39,3.87-.54.2-.26-1.2-.13-1.66.23-.9.38-1.74.48-2.74.13-1.11.03-3.46.49-4.04"/>
        <path   d="m419.47,285.58c-.16,1.76-.57,3.3-.77,5.04-.11,1.03-.04,2.03-.18,3.02-.74.61-.14-1.36-.07-1.77.2-1.22.43-2.65.52-3.9"/>
        <path   d="m418.88,283.15c.05,1.5-.27,3.13-.4,4.6-.07.83-.08,2.96-.44,3.41-.2-.56,0-1.42.14-2.01"/>
        <path   d="m417.34,290.92c.09.8-.19,1.93-.16,2.8-.67.02-.11-2.28.19-2.72"/>
        <path   d="m417.26,285.22c.5-.08.38.99.33,1.62-.07.74.03,1.13.02,1.74-.49.11-.34-.8-.28-1.42.08-.79-.05-1.17-.07-1.86"/>
        <path   d="m416.6,286.48c0-.67-.03-1.4.12-2.01.13-.59.35-.73.36-1.47-.68.04-.71.53-.76,1.5-.03.55-.15,1.34-.04,1.83"/>
        <path   d="m416.21,291.72c.03-.49.16-1.11.38-1.41.2-.27.43-.02.52-.54"/>
        <path   d="m416.14,289.9c.07-.84.14-1.68.22-2.53"/>
        <path   d="m414,281.16c-.09.43-.27.88-.5,1.03-.12-.57-.46-.97-.41-1.67"/>
        <path   d="m410.08,280.4c.02.24.03.5.02.73"/>
        <path   d="m410.3,278.17c-.17.4-.31.91-.4,1.39"/>
        <path   d="m412.8,286.42c.04.73-.21,1.13-.23,1.76.12.12.11.28.18.43"/>
        <path   d="m410.85,289.13c.06-.38.06-.79.06-1.18"/>
        <path   d="m413.82,283.11c-.39.76-.45,3.1-.55,4.2"/>
        <path   d="m413.44,292.98c.04-.88.26-1.68.45-2.48.28-1.24.48-2.39.69-3.68.13-.81.68-2.45.12-2.92-.47,1.31-.43,2.91-.88,4.29-.52,1.6-.54,3.24-.76,4.98"/>
        <path   d="m412.71,284.68c-.14-.59.05-1.39-.05-2.02-.15.39-.07,1.03-.13,1.53"/>
        <path   d="m411.74,282.95c-.11.38-.36.84-.55.97-.05-.51.05-1.09.1-1.61"/>
        <path   d="m410.33,282.43c-.18.34-.39.61-.5,1"/>
        <path   d="m408.42,283.96c.06-.63.11-1.26.17-1.89"/>
        <path   d="m408.18,296.03c.19-.63.3-1.73.26-2.45-.23.56-.44.99-.44,1.69,0,.45.26.66.05,1.1"/>
        <path   d="m408.91,286.66c.21-.37.74-2.44.32-2.58-.16.66-.38,1.27-.41,2.01"/>
        <path   d="m408.39,293.23c-.22-.91.1-2.81.28-3.78.15-.77.6-1.39.66-2.18"/>
        <path   d="m407.22,286.32c.11-.39.27-.77.42-1.1"/>
        <path   d="m404.7,284.81c.05-.57.11-1.14.14-1.7-.17-.16-.32-.44-.48-.67.17.41.3,1.14.26,1.72"/>
        <path   d="m405.88,291.39c.01-1.07.49-1.58.37-2.57-.09-.69-.15-.92-.08-1.78"/>
        <path   d="m406,286.29c.08-.87.14-1.63.35-2.42.14-.55.29-.79.31-1.45-.66.76-.68,2.46-.79,3.77"/>
        <path   d="m407.83,286.17c-.35.72-.46,1.39-.33,2.23"/>
        <path   d="m410.41,285.56c.13-.4.24-1.09.29-1.64-.07.28-.48,1.64-.73,1.38"/>
        <path   d="m411.06,291.61c-.12.4-.35.59-.37,1.12"/>
        <path   d="m411.01,289.97c-.05.61.29.45.49.71.05-.54.02-1.35.32-1.56"/>
        <path   d="m411.29,286.06c.22.56,0,1.24-.23,1.7.22.53.49-.14.28-.61-.16-.38-.84-.76-.17-1.29"/>
        <path   d="m411.43,285.69c.37.4.74-.24.74-1.07"/>
        <path   d="m436.78,299.54c.09-.96.11-2.1.31-3.01.11-.53.49-1.08.56-1.58.05-.4.04-.75-.16-.86-.39-.2-.39.84-.43,1.33-.13,1.42-.65,2.89-.52,4.36"/>
        <path   d="m435.2,295.62c-.11,1.15.09,2.48,0,3.71.47-1.57.37-2.35.33-3.84"/>
        <path   d="m428.1,293.81c.56-1.01.71-1.57.82-3-.34.9-1.05,2.3-1.21,3.29"/>
        <path   d="m426.75,293.73c.05-.47.03-.97.23-1.32,0,.29,0,.6-.04.89"/>
        <path   d="m427.79,294.32c-.08.94.05,1.62.01,2.48-.03.84-.34,1.86-.55,2.56,1.2-.41.47-3.76,1.19-4.68-.09.21-.17.35-.26.54"/>
        <path   d="m425.5,294.13c.52-.59-.1-1.72-.14-2.42-.06-.88.45-1.7.29-2.33"/>
        <path   d="m426.9,295.02c-.03.27-.05.54-.07.81"/>
        <path   d="m426.28,299.66c.2-.75.74-2.28.61-3.2-.15-.94-.76.15-.78.95.97-.22-.24,2.09-.27,2.53"/>
        <path   d="m425.37,295.45c-.27.36-.67,1.18-.56,1.9.03.29.24.36.27.73.02.26-.05.67-.11.88.09-.7.37-1.25.45-1.97.06-.54.07-1.1.12-1.64"/>
        <path   d="m424.09,296.2c.16.71.16,1.15-.06,1.76-.1.3-.17.32-.21.7-.03.2.06.59.03.72-.08.3-.34.38-.45.06-.11-.33.1-.61.21-.88.23-.59.31-1.12.38-1.83"/>
        <path   d="m422.57,294.56c.01-.51-.16-.89-.16-1.39.01-.43.13-.81.14-1.24-.26.08-.25,1.17-.29,1.59"/>
        <path   d="m422.63,297.82c.09-.58.18-1.03.37-1.52.13-.32.35-.68.09-.96-.28.39-.48.93-.59,1.54-.1.5-.27.98-.29,1.54"/>
        <path   d="m421.29,299.83c.06-.62.11-1.09.26-1.67.12-.44.23-.71.08-1.2-.3.67-.35,1.54-.43,2.42"/>
        <path   d="m420.55,297.47c.19-.55.45-.81.53-1.5.04-.42-.02-.83-.01-1.27-.47,0-.32,1.1-.39,1.57-.07.56-.21,1.02-.27,1.67"/>
        <path   d="m420,296.7c-.17.81-.64,1.14-.85,1.85-.07.21-.05.42-.11.66-.11.49-.35.85-.55.21-.25-.87.2-2.04.47-2.73.02.53-.33,2.34-.15,2.67"/>
        <path   d="m418.39,297.71c.09-1.04.42-2.34.33-3.37-.5.82-.52,2.06-.62,3.19"/>
        <path   d="m414.66,290.82c.15-.05.27-.29.32-.55"/>
        <path   d="m417.31,299.74c.06-.69.08-1.34.05-1.96,0-.36-.04-.7.07-1.13"/>
        <path   d="m415.11,289.46c.15-.18.28-.47.32-.79"/>
        <path   d="m415.27,286.38c-.07.12-.12.33-.14.52"/>
        <path   d="m414.38,292.02c-.05.32-.02.64-.05.96"/>
        <path   d="m415.76,293.13c0,.12.01.23,0,.36"/>
        <path   d="m416.97,296.41c.02-.58.26-.85.3-1.35"/>
        <path   d="m415.43,296.01c-.05.26-.02.56.02.8"/>
        <path   d="m414.96,293.57c0,.32-.14.52-.27.68"/>
        <path   d="m412.62,289.97c-.21.09-.3.57-.36.92-.14.85-.09,1.57-.45,2.23-.25.47-.37.52-.35-.18.03-.85.49-1.38.53-2.16"/>
        <path   d="m416.21,296.16c.2-.33.42-.71.44-1.25-.03-.04-.08-.06-.11-.08-.18.23-.71,1.22-.7,1.63"/>
        <path   d="m415.64,299.28c.05-.69.28-1.22.32-1.88-.06.44-.1,1.51-.41,1.5"/>
        <path   d="m415.44,294.73c-.02.37-.17.57-.26.86-.11.05-.15.04-.25.18.31-.94.37-1.03-.18-1.17,0-.1.02-.15.06-.23"/>
        <path   d="m415.08,297.04c.03.56-.04,1.22-.27,1.63-.16.32-.47.54-.59.03.22-.36.66-.05.63-.8"/>
        <path   d="m413.81,297.23c0-.19,0-.35.02-.54"/>
        <path   d="m413.48,295.02c.05.18.1.39.13.58"/>
        <path   d="m412.76,296.15c-.02.28-.04.62-.1.83"/>
        <path   d="m412.42,299.6c.05-.73.17-1.48.35-2.17.15-.6.36-1.02.42-1.65-.21.86-.43,1.42-.52,2.36"/>
        <path   d="m411.51,299.18c.14-.69.12-.86,0-1.46-.06-.37-.09-.61-.02-1.04.18-1.02.66-1.55.87-2.48-.48.91-.86,1.58-1.02,2.82-.05.41-.07.78-.05,1.17,0,.43.17,1.2.09,1.52"/>
        <path   d="m409.85,299c.34-.97.65-2.09.85-3.19.14-.72-.07-1.1-.03-1.77-.05.54-.18,1.08-.2,1.6,0-.17.02-.49,0-.68-.33.76-.28,1.39-.19,2.19.09.82-.18,1.05-.39,1.73"/>
        <path   d="m408.81,299.62c.06-.54.17-1.09.28-1.63-.22-.62.57-2.13.58-2.96-.43.23-.89,1.66-.98,2.48-.09.82-.11,1.61-.15,2.43"/>
        <path   d="m409.7,291.93c-.52-1.32.67-2.94.53-4.23-.76.31-.72,2.83-.82,3.99"/>
        <path   d="m407.03,299.35c0-1,.16-1.89.28-2.87.05-.42.26-1.21-.06-1-.39.27-.15.94-.13,1.41.02.56-.07,1.15-.11,1.71"/>
        <path   d="m407.14,293.04c.05-.94.46-1.97.57-2.96.06-.54.1-1.08.14-1.61"/>
        <path   d="m406.08,296.39c.1-.82.46-2.08.38-2.84"/>
        <path   d="m404.87,292.18c.07-.32.26-.59.35-.91"/>
        <path   d="m404.86,298.85c.09-1.05.13-2.23.31-3.23.1-.52.24-1.03.31-1.53-.09.13-.15.4-.2.59-.14-.3-.04-.74-.03-1.11-.35-.22-.17.86-.15,1.13.03.44.02.96-.05,1.45-.12.72-.22,1.36-.29,2.14-.03.41-.06,1-.15,1.32"/>
        <path   d="m403.59,289.97c.19-.43.24-.6.28-1.15-.12.15-.27.51-.34.78"/>
        <path   d="m403.11,283.77c.01-.13.02-.25.03-.37"/>
        <path   d="m404.86,288.03c.03-.19.04-.42.01-.55"/>
        <path   d="m403.2,298.96c-.11-.62.37-1.1.46-1.73.06-.48.11-1.04.12-1.51-.44.3-.22,1.08-.28,1.66-.07.54-.31,1.07-.37,1.69"/>
        <path   d="m403.33,291.01c-.03.27-.03.5.06.68"/>
        <path   d="m403.46,294.57c.21.36.48-1.12-.1-.31"/>
        <path   d="m429.76,288.17c.1-1.18.12-2.45.27-3.6.22,1.03.21,2.45.1,3.58"/>
        <path   d="m434.08,293.57c.08-.97.1-2.09.3-3.01.12-.53.49-1.08.56-1.59.05-.4.05-.75-.15-.85-.39-.21-.39.84-.44,1.32-.13,1.42-.64,2.89-.52,4.35"/>
        <path   d="m433.07,293.96c.03.42.14.78.05,1.23-.48-.07-.84,1.43-.97,2.15-.06.4-.08.87-.18,1.26-.09.38-.3.46-.32.95.16-1.12.3-2.38.54-3.41.23-1.02.86-1.08.66-2.35"/>
        <path   d="m430.97,294.5c-.47-2.9,1.43-3.28,1.46-5.96-.09.01-.21.09-.29.2.06.16.05.43.08.55-.17.11-.33.23-.49.27"/>
        <path   d="m431.37,298.81c-.14-1.17.06-2.41.24-3.61-.37,1.3-.68,2.42-.88,3.81"/>
        <path   d="m429.53,294.51c.14-1.68.21-2.92.64-4.45.15-.51.51-1.11.56-1.7-.05-.08-.09-.12-.15-.17-.39.62-.55,1.62-.7,2.51-.14-.07-.3-.21-.42-.35-.33.97-.3,2.55-.43,3.67"/>
        <path   d="m429.41,299.92c0-1.34.25-3.26.63-4.47.19-.62.6-1.54.11-1.97-.1.57-.18,1.18-.26,1.76-.23-.27-.39-.78-.28-1.3-.55,1.08-.23,2.29-.24,3.44-.01.84-.38,1.76-.15,2.55"/>
        <path   d="m438.15,271.56c.04.42.14.78.05,1.24-.48-.08-.84,1.43-.96,2.14-.07.4-.09.88-.18,1.26-.1.38-.31.46-.33.95.16-1.12.3-2.37.53-3.41.24-1.02.86-1.08.67-2.34"/>
        <path   d="m434.87,277.85c-.47-2.9,1.43-3.28,1.47-5.96-.09,0-.21.1-.3.2.07.17.05.44.09.55-.17.11-.33.22-.5.27"/>
        <path   d="m435.37,283.43c-.15-1.17.05-2.41.24-3.61-.37,1.29-.68,2.41-.88,3.8"/>
        <path   d="m434.37,283.49c.13-1.68.2-2.92.64-4.45.15-.52.51-1.12.56-1.71-.04-.08-.09-.13-.15-.16-.39.61-.55,1.62-.7,2.51-.14-.07-.29-.2-.41-.35-.33.98-.31,2.55-.43,3.67"/>
        <path   d="m433.52,277.84c.01-1.35.26-3.27.63-4.47.19-.63.6-1.54.12-1.97-.1.57-.17,1.18-.26,1.75-.23-.27-.4-.77-.29-1.29-.54,1.09-.23,2.3-.24,3.43,0,.84-.37,1.78-.14,2.56"/>
        <path   d="m433.82,277.59c.04.42.14.77.06,1.22-.48-.07-.85,1.43-.96,2.14-.07.4-.09.87-.18,1.26-.1.39-.3.46-.33.95.16-1.12.3-2.38.53-3.41.24-1.01.87-1.08.67-2.34"/>
        <path   d="m431.1,278.18c-.47-2.91,1.43-3.29,1.46-5.97-.1.02-.22.1-.29.2.06.16.05.44.09.56-.18.11-.33.22-.5.27"/>
        <path   d="m431.09,282.98c-.14-1.17.06-2.41.24-3.6-.37,1.29-.68,2.41-.89,3.81"/>
        <path   d="m429.63,280.86c.13-1.67.2-2.92.64-4.44.15-.52.52-1.11.57-1.7-.05-.07-.09-.13-.15-.16-.39.61-.55,1.62-.7,2.5-.14-.07-.29-.2-.41-.35-.33.98-.31,2.56-.43,3.67"/>
        <path   d="m427.95,283.56c.01-1.34.26-3.26.63-4.47.19-.63.6-1.55.11-1.98-.09.58-.17,1.17-.26,1.76-.22-.26-.39-.77-.28-1.3-.55,1.09-.23,2.31-.24,3.44-.01.85-.38,1.78-.15,2.57"/>
        <path   d="m439,277.96c.04.42.14.78.06,1.23-.48-.07-.84,1.43-.97,2.15-.06.4-.08.87-.18,1.25-.1.38-.3.46-.33.96.16-1.12.31-2.38.54-3.4.23-1.02.86-1.09.66-2.36"/>
        <path   d="m436.05,283.21c-.47-2.91,1.42-3.29,1.46-5.96-.1,0-.21.09-.3.2.06.17.05.44.09.56-.18.1-.33.22-.5.27"/>
        <path   d="m434.92,287.81c-.14-1.17.06-2.42.24-3.61-.37,1.29-.68,2.42-.88,3.81"/>
        <path   d="m432.9,290.66c.14-1.68.21-2.92.64-4.44.15-.52.51-1.11.57-1.7-.05-.08-.1-.12-.15-.16-.39.61-.55,1.62-.7,2.5-.14-.07-.29-.2-.41-.34-.33.98-.31,2.56-.43,3.67"/>
        <path   d="m431.44,290.12c0-1.35.26-3.27.63-4.48.19-.63.6-1.54.11-1.97-.1.58-.17,1.18-.26,1.76-.24-.27-.39-.77-.29-1.3-.54,1.09-.23,2.31-.23,3.44-.01.85-.38,1.77-.15,2.57"/>
        <path   d="m434.12,298.45c-.23-.99.53-2.99.73-3.96.06-.36.41-1.61.3-1.97-.19-.63-.45.03-.54.4-.11.51-.02,1.07-.09,1.57-.07.51-.26.97-.36,1.48-.22,1.16-.46,2.37-.56,3.55.1-.26.23-.65.25-1.04"/>
        <path   d="m451.59,291.1c.05,2.17-.03,4.87-.97,6.42"/>
        <path   d="m400.81,286.55c.05-.63.64-4.53.28-4.57-.62-.06-.46,3.76-.52,4.4"/>
        <path   d="m398.9,287.3c.34-1.28.98-4.02.18-4.75-.04-.27-.09-.33-.15-.55-.22,1.63.64,2.6.2,4.01"/>
        <path   d="m402.66,281.63c.16-1.49.5-2.89.55-4.4-.71-.29-.76,3.02-.68,3.76"/>
        <path   d="m401.74,281.92c.1-1.14.23-2.37.24-3.46,0-.91.21-1.38.26-2.27-.27,1.26-.58,3.95-.46,5.26"/>
        <path   d="m400.38,278.39c.16-1.8.37-3.51.51-5.25-.91-.49-.43,2.78-.55,3.55"/>
        <path   d="m400.06,281.07c.07-1.48.41-2.91.5-4.4-.03,0-.14-.02-.17-.04-.19.69-.19,1.58-.29,2.32-.02-.51.05-1.06.09-1.57-.48.77-.51,2.12-.58,3.23.28-.28.26-.95.54-1.35"/>
        <path   d="m399.29,274.51c-.01,1.53-.72,3.29-.11,4.79.08-.69-.12-1.12-.11-1.75.02-.58.21-.92.26-1.43.09-.92.12-2.52.05-3.41"/>
        <path   d="m402.52,291.16c-.39.76-.46,3.1-.55,4.2"/>
        <path   d="m402.1,292.12c.05-.88.26-1.68.45-2.48.28-1.24.48-2.39.69-3.68.13-.81.68-2.45.12-2.91-.47,1.3-.44,2.9-.89,4.29-.52,1.6-.54,3.24-.76,4.98"/>
        <path   d="m401.75,285.8c.19-.63.3-1.73.26-2.46-.24.56-.44.99-.44,1.69,0,.45.26.66.05,1.11"/>
        <path   d="m399.2,292.05c.08-.87.14-1.63.35-2.42.15-.55.29-.79.31-1.46-.66.76-.68,2.46-.79,3.77"/>
        <path   d="m399.92,287.38c-.06.61.29.45.48.7.06-.54.02-1.35.32-1.56"/>
        <path   d="m405.43,288.37c-.21.09-.3.57-.36.93-.13.85-.09,1.56-.44,2.23-.26.47-.38.52-.35-.19.03-.85.49-1.38.53-2.16"/>
        <path   d="m402.2,299.16c.14-.7.12-.86,0-1.47-.07-.37-.1-.61-.03-1.04.18-1.02.66-1.55.87-2.48-.48.91-.86,1.58-1.02,2.82-.05.41-.07.78-.05,1.18.01.43.17,1.2.09,1.52"/>
        <path   d="m400.11,298.51c.34-.97.65-2.08.86-3.19.14-.72-.07-1.09-.03-1.77-.05.54-.18,1.08-.21,1.6,0-.17.02-.49,0-.68-.33.76-.28,1.39-.2,2.19.09.82-.17,1.05-.39,1.73"/>
        <path   d="m400.91,291.35c.06-.55.17-1.1.28-1.63-.22-.62.58-2.13.58-2.96-.43.23-.89,1.66-.98,2.48-.09.82-.11,1.61-.15,2.43"/>
        <path   d="m404.48,288.78c-.52-1.32.67-2.94.53-4.23-.76.31-.72,2.82-.82,3.99"/>
        <path   d="m399.41,298.57c0-1,.16-1.89.28-2.87.05-.42.26-1.22-.06-1-.4.26-.15.94-.13,1.41.02.56-.07,1.15-.12,1.71"/>
        <path   d="m395.47,288.38c.09-1.05.12-2.23.31-3.23.1-.52.24-1.03.31-1.53-.09.13-.15.4-.2.59-.14-.3-.04-.74-.03-1.11-.36-.22-.17.86-.16,1.13.03.44.02.97-.06,1.45-.12.72-.21,1.37-.29,2.14-.03.42-.05,1-.15,1.32"/>
        <path   d="m398.71,296.12c-.11-.62.37-1.1.45-1.73.06-.47.11-1.04.13-1.51-.44.3-.22,1.08-.28,1.66-.07.54-.31,1.07-.37,1.69"/>
        <path   d="m397.07,290.02c.34-1.28.98-4.02.19-4.75-.04-.28-.09-.33-.15-.56-.22,1.63.64,2.6.2,4.01"/>
        <path   d="m397.46,277.24c-.01,1.53-.72,3.29-.11,4.79.08-.68-.12-1.12-.11-1.75.02-.58.21-.92.25-1.43.09-.91.12-2.52.06-3.41"/>
        <path   d="m397.36,294.77c.08-.87.14-1.63.35-2.42.15-.55.29-.79.31-1.46-.66.76-.68,2.46-.79,3.77"/>
        <path   d="m396.88,298.84c-.11-.62.37-1.09.46-1.72.06-.47.11-1.04.12-1.51-.44.3-.22,1.08-.28,1.66-.06.54-.31,1.08-.37,1.69"/>
        <path   d="m293.18,273.32c.74-.03,1.07-.15,1.27-1.91"/>
        <path   d="m295.78,270.97c-.47,1.43-1.13,2.9-1.22,4.55-.08,1.61.47,2.49-.02,4.25.3-1.12.46-2.18.63-3.61"/>
        <path   d="m293.32,279.04c-.14,1.08.07,1.88,0,2.85-.08,1.17-.23,2.33-.05,3.31.07.4.13.67.12,1.13-.02.42-.2.91-.22,1.27-.06,1.29.46.19.66-.26.06.25.08.53.06.83.44-.73-.3-3.16-.41-3.77-.11-.6-.18-1.27-.19-1.97,0-.68.1-.68.24-1.25.33-.28.56-1.1.43-1.62-.24-.09-.28.55-.48.43-.12-.07-.14-.7-.13-.95"/>
        <path   d="m294.45,292.69c.36-.96.36-1.83.31-2.76-.05-.98-.42-1.72-.42-2.62-.01-1.02,1-2.97.28-3.59-.36.53-.28,1.77-.33,2.44-.06.76-.3,1.75-.29,2.48.01.83.34,1.13.4,1.97.05.69-.04,1.31-.19,2.09"/>
        <path   d="m292.15,292.43c.36-.96.36-1.83.31-2.76-.05-.98-.42-1.72-.42-2.62-.01-1.02,1-2.97.28-3.59-.36.53-.28,1.76-.33,2.44-.06.76-.3,1.74-.28,2.47.01.83.34,1.14.4,1.97.05.69-.04,1.31-.18,2.09"/>
      </g>
      <path id="Costura"   d="m269.78,522.56s77.84,7.34,135.88,6.85c58.04-.49,126.97-6.85,126.97-6.85"/>
      <path id="Short"   d="m284.36,538.85l.12,4.99s33.3,4.89,44.79,5.93c11.48,1.04,54.16,1.53,54.16,1.53v-5.05"/>
      <path id="Short-2" data-name="Short"   d="m515.72,539.54l-.12,4.99s-33.3,4.89-44.79,5.93c-11.48,1.04-54.16,1.53-54.16,1.53v-5.05"/>
      <path id="Linea_1_izquierda" data-name="Linea 1 izquierda"   d="m300.46,295.77s-6.46,55.36-9.52,100.07c-.48,6.99-11.66,141.27-11.66,141.27"/>
      <path id="Linea_2_izquierda" data-name="Linea 2 izquierda"   d="m293.18,295.01s-6.46,55.36-9.52,100.07c-.48,6.99-11.66,141.27-11.66,141.27"/>
      <path id="Linea_1_derecha" data-name="Linea 1 derecha"   d="m493.22,293.44s9.93,53.08,12.99,97.8c.48,6.99,14.74,147.37,14.74,147.37"/>
      <path id="Linea_2_derecha" data-name="Linea 2 derecha"   d="m501.13,292.42s9.93,53.08,12.99,97.8c.48,6.99,14.74,147.37,14.74,147.37"/>
      <g id="Escudo">
        <g>
          <circle   cx="487" cy="497" r="14.5"/>
          <path d="m487,483c7.72,0,14,6.28,14,14s-6.28,14-14,14-14-6.28-14-14,6.28-14,14-14m0-1c-8.28,0-15,6.72-15,15s6.72,15,15,15,15-6.72,15-15-6.72-15-15-15h0Z"/>
        </g>
        <g>
          <circle   cx="313" cy="497" r="14.5"/>
          <path d="m313,483c7.72,0,14,6.28,14,14s-6.28,14-14,14-14-6.28-14-14,6.28-14,14-14m0-1c-8.28,0-15,6.72-15,15s6.72,15,15,15,15-6.72,15-15-6.72-15-15-15h0Z"/>
        </g>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default FaldaShortFrontalSvg
