import React, { useEffect, useState } from 'react'
import style from './../../../components/ListOfList/list.module.scss'
import styles from './notebooks.module.scss'
import {
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { Checkbox, Col, List, Modal, Row, Spin, Table, Tooltip } from 'antd'
import Button from 'components/buttons/Button/Button'
import Collapse from 'components/Collapse/Collapse'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCommentsObjectInformation,
  setopenComments
} from 'redux/reducers/comments'
import {
  getNotebookListInPackage,
  IListNotebookPackage
} from 'services/notebooks/getListOfNotebooks'
import Tag from 'components/tag'
import { useLocation, useNavigate } from 'react-router-dom'
import Modals from 'components/modal'
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { AUTHSERVICE } from 'services/config'
import globalStyle from 'styles/global.module.scss'
import ErrorAlert from 'assets/img/errorAlert.svg'
import B from 'components/texts/B/B'
import Tags from 'components/tag'
import { INotebook } from 'services/notebooks/getNotebookByIdUuid'
import { setCloseSupplyList } from 'redux/reducers/closeList'
import { getGeneralModulesStatus } from 'services/genericServices/genericServices'
import { getNotebookPrice } from 'services/notebooks/getNotebooksPrice'
import { OpenPackageNotebookBiId } from 'services/notebooks/putNotebook'
import { RootState } from 'redux/store/store'

const statusNumberMap: any = {
  1: 'Nuevo',
  2: 'En revisión',
  3: 'Con observaciones',
  4: 'Disponible',
  5: 'Autorizado',
  6: 'Borrador',
  7: 'Solicitud propuesta',
  8: 'Diseño de propuesta',
  9: 'Diseño propuesto',
  10: 'Propuesta enviada',
  11: 'Revisión de propuesta',
  12: 'Propuesta enviada'
}

const NotebooksItemsTable = ({
  colStyle,
  name,
  subtitle,
  id,
  addButtonInPackage,
  edithButtonInPackage,
  eraseButtonInPackage,
  quantity,
  packageData,
  data,
  selectedLevel,
  setSelectedLevel
}: {
  packageData?: any
  quantity: number
  name: string
  subtitle: string
  colStyle: any
  id: number
  addButtonInPackage?: { onClick: (dat: any) => void; moreProps: any }
  edithButtonInPackage?: { onClick: (dat: any) => void; moreProps: any }
  eraseButtonInPackage?: { onClick: (dat: any) => void; moreProps: any }
  data: any
  selectedLevel?: any
  setSelectedLevel?: (data: any) => void
}) => {
  let { refreshComent } = useSelector((state: RootState) => state.notebooks)

  const [listPackage, setListPackage] = useState<IListNotebookPackage[]>([])
  const [authList, setAuthList] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<any | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deletingItem, setDeletingItem] = useState<boolean>(false)
  const [openFinishingModal, setOpenFinishingModal] = useState(false)
  const [finishingPackage, setFinishingPackage] = useState(false)
  const [openModificationModal, setOpenModificationModal] = useState(false)
  const [requestingModification, setRequestingModification] = useState(false)
  const [hasAvailableItems, setHasAvailableItems] = useState(false)
  const [openAuthorizationModal, setOpenAuthorizationModal] = useState(false)
  const [doingAuthorization, setDoingAuthorization] = useState(false)
  const [priceList, setpriceList] = useState(0)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setShowDeleteModal(itemToDelete ? true : false)
  }, [itemToDelete])

  useEffect(() => {
    const item = listPackage?.find((it: any) => it?.status == 4)
    setHasAvailableItems(item != undefined)
    setAuthList(
      listPackage
        ?.filter((it: any) => it?.status == 4)
        ?.map((it: any) => ({
          ...it,
          checked: false
        }))
    )
  }, [listPackage])

  const reloadPage = () => {
    window.location.reload()
  }

  const getPackage = (id: number, isShow: boolean) => {
    if (isShow) {
      setLoading(true)
      getNotebookListInPackage(id)
        .then((data) => {
          if (data.data.notebookPackage.length === 0) {
            OpenPackageNotebookBiId(id)
              .then(() => {
                if (!packageData?.open) reloadPage()
              })
              .catch((w) => console.log(w))
          }
          setListPackage(
            data.data.notebookPackage?.map((it: any) => ({
              ...it,
              pkg_open: packageData?.open as boolean
            }))
          )
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          ErrorToast(
            'Hubo un error al cagar la lista de cuadernos en el paquete'
          )
          setLoading(false)
        })
    }
  }

  const openDrawComments = (record: any) => {
    dispatch(setopenComments({ openComments: true }))
    dispatch(setCommentsObjectInformation(record))
  }

  const handleDeleteItem = async () => {
    setDeletingItem(true)

    try {
      await AUTHSERVICE().delete(`/notebook/${itemToDelete?.id}`)
      setListPackage((prev) => prev.filter((it) => it.id != itemToDelete?.id))
      setItemToDelete(null)
      setShowDeleteModal(false)
      SuccessToast('se borro exitosamente')
      getPackage(id, true)
    } catch (error) {
      ErrorToast('Ocurrió un error, inténtelo de nuevo más tarde')
    }

    setDeletingItem(false)
  }
  const getPDFListLogic = async () => {
    try {
      const result = await getGeneralModulesStatus()
      if (
        pathname.includes('cuadernos') &&
        result.data.notebooks_status === 'Completado'
      )
        dispatch(setCloseSupplyList(true))
    } catch (error) {
      console.log('error get pdf list', error)
    }
  }

  const handleFinishPackage = async () => {
    // check that notebooks with status 6 don't exist
    const draftNotebooks = listPackage.filter((it: any) => it?.status == 6)
    if (draftNotebooks.length > 0) {
      ErrorToast(
        "Existen cuadernos en estado de 'Borrador'. Por favor defina el diseño para estos cuadernos."
      )
      return
    }

    setFinishingPackage(true)

    try {
      // update the package
      await AUTHSERVICE().put('/notebook-package', {
        id,
        open: false,
        mode: 'end-list'
      })

      // update the notebooks
      // update the notebooks with status 1
      const idsToUpdate = listPackage
        ?.filter((it: any) => it?.status == 1)
        ?.map((it) => it.id)
      await AUTHSERVICE().put('/notebook/status', {
        id: idsToUpdate,
        status: 2
      })
      // update the notebooks with status 7
      const requestedIds = listPackage
        ?.filter((it: any) => it?.status == 7)
        ?.map((it) => it.id)
      await AUTHSERVICE().put('/notebook/status', {
        id: requestedIds,
        status: 8
      })
      // update the notebooks with status 10
      const proposalIds = listPackage
        ?.filter((it: any) => it?.status == 10)
        ?.map((it) => it.id)
      await AUTHSERVICE().put('/notebook/status', {
        id: proposalIds,
        status: 11
      })

      // update the list
      setSelectedLevel &&
        setSelectedLevel((prev: any) => {
          const grade = prev?.grades?.find(
            (it: any) => it?.id == packageData?.id_grade
          )
          if (!grade) return prev

          const pkg = grade?.packages?.find(
            (it: any) => it?.id == packageData?.id
          )
          if (!pkg) return prev

          pkg.open = false

          return prev
        })

      // update the notebooks
      setListPackage((prev: Array<any>) =>
        prev?.map((it: any) => ({
          ...it,
          status: idsToUpdate.includes(it.id)
            ? 2
            : requestedIds.includes(it.id)
            ? 8
            : proposalIds.includes(it.id)
            ? 11
            : it.status
        }))
      )

      setOpenFinishingModal(false)
    } catch (error) {
      ErrorToast('Ocurrió un error, inténtelo de nuevo más tarde')
    }
    await getPDFListLogic()
    setFinishingPackage(false)
  }

  const handleRequestModification = async () => {
    setRequestingModification(true)
    try {
      await AUTHSERVICE().put('/notebook-package', {
        id,
        request_changes: true,
        mode: 'sent'
      })

      setSelectedLevel &&
        setSelectedLevel((prev: any) => {
          const grade = prev?.grades?.find(
            (it: any) => it?.id == packageData?.id_grade
          )
          if (!grade) return prev

          const pkg = grade?.packages?.find(
            (it: any) => it?.id == packageData?.id
          )
          if (!pkg) return prev

          pkg.request_changes = true

          return prev
        })

      setOpenModificationModal(false)
      SuccessToast('La solicitud de modificación se envió correctamente')
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtelo de nuevo más tarde.')
    }
    setRequestingModification(false)
  }

  const { pathname } = useLocation()

  const handleDoAuthorization = async (authorizeAll: boolean = false) => {
    setDoingAuthorization(true)

    try {
      await AUTHSERVICE().put('/notebook/status', {
        id: [
          ...(authorizeAll
            ? authList?.map((it: any) => it?.id)
            : authList
                ?.filter((it: any) => it?.checked)
                ?.map((it: any) => it?.id))
        ],
        status: 5
      })

      // update notebooks
      setListPackage((prev: Array<any>) => {
        if (authorizeAll)
          return prev?.map((it: any) => ({
            ...it,
            status: it?.status == 4 ? 5 : it?.status
          }))

        const nList = structuredClone(prev)

        authList
          ?.filter((it: any) => it?.checked)
          ?.forEach((it: any) => {
            const el = nList?.find((it2: any) => it2?.id == it?.id)
            if (el) el.status = 5
          })

        return nList
      })

      setOpenAuthorizationModal(false)
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtelo de nuevo más tarde.')
    }
    await getPDFListLogic()
    setDoingAuthorization(false)
  }

  const columns: ColumnsType<INotebook> = [
    {
      title: 'Nombre',
      dataIndex: 'matter',
      key: 'matter'
    },
    {
      title: 'Tipo',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '# hojas',
      dataIndex: 'int_sheets',
      key: 'int_sheets'
    },
    {
      title: 'Tipo de rayado',
      dataIndex: 'int_striped',
      key: 'int_striped'
    },

    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status',
      render: (_, record: any) => (
        <>
          {(() => {
            let status = record?.status

            const message = (num: number) => {
              if (num === 3 && record.last_comment !== undefined) {
                return statusNumberMap[status] + ` de ${record.last_comment}`
              }
              return statusNumberMap[status]
            }
            return <Tag variation={status} title={message(status) as string} />
          })()}
        </>
      )
    },
    {
      title: '',
      key: 'actions',
      render: (_: any, record: INotebook) => (
        <>
          <div style={{ display: 'flex' }}>
            {(packageData?.open ||
              (!packageData?.open &&
                [3, 7, 9, 10].includes(
                  parseInt(record?.status as string)
                ))) && (
              <PencilSquareIcon
                onClick={async (e) => {
                  e.stopPropagation()
                  if (
                    ![1, 3, 6, 7, 9, 10].includes(
                      parseInt(record?.status as string)
                    )
                  )
                    return

                  let nextURL = 'select-type'
                  if (record?.step == 1) nextURL = 'edith-front'
                  else if (record?.step == 2) nextURL = 'edith-inside'
                  else if (record?.step == 3) nextURL = 'preview'

                  navigate(`/cuadernos/${nextURL}/${record?.id}`)
                }}
                style={{
                  color: '#0F48FF',
                  cursor: 'pointer',
                  width: '1rem',
                  height: '1rem',
                  ...([1, 3, 6, 7, 9, 10].includes(
                    parseInt(record?.status as string)
                  )
                    ? {}
                    : { opacity: 0.3 })
                }}
              />
            )}

            {(packageData?.open ||
              (!packageData?.open &&
                [1, 3].includes(parseInt(record?.status as string)))) && (
              <TrashIcon
                onClick={async (e) => {
                  e.stopPropagation()
                  setItemToDelete(record)
                }}
                style={{
                  color: '#EF5D60',
                  cursor: 'pointer',
                  width: '1rem',
                  height: '1rem'
                }}
              />
            )}
          </div>
        </>
      )
    }
  ]

  const getPrice = () => {
    getNotebookPrice()
      .then((data) => {
        setpriceList(parseFloat(data.data.price_notebook))
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getPrice()
  }, [])

  useEffect(() => {
    getPackage(id, true)
  }, [refreshComent])

  return (
    <>
      <Collapse
        title={name}
        customStyle={`${style['descriptionstyle']}`}
        customBg={`${style['custom-bg']}`}
        showMiddleText
        subTitle={subtitle}
        showSubTitle
        isDroped={(dat) => {
          getPackage(id, dat)
        }}
        id={id}
        customSideElement={
          <Col
            style={{
              ...colStyle,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              columnGap: '0.8rem'
            }}
            span={12}
          >
            {packageData?.open && (
              <>
                <Button
                  label='Agregar cuaderno'
                  rounded
                  sector='tertiary'
                  background='white'
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    addButtonInPackage?.onClick({ name: name, id: id })
                  }}
                />
                <Tooltip title='Editar Paquete'>
                  <div>
                    <PencilSquareIcon
                      className={`${style['iconStyle']} ${style['notebook-icon']}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        edithButtonInPackage?.onClick({
                          packageInfo: { name: name, id: id, quanty: quantity },
                          data,
                          packageId: id
                        })
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title='Eliminar Paquete'>
                  <div>
                    <TrashIcon
                      className={`${style['iconStyle']} ${style['trash-icon']}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        eraseButtonInPackage?.onClick({ name: name, id: id })
                      }}
                    />
                  </div>
                </Tooltip>
              </>
            )}
            {listPackage && listPackage?.length > 0 && (
              <Tooltip title='Estatus del paquete'>
                <p
                  className={`${style['status-pacgake']} ${
                    packageData?.open ? '' : style['finished']
                  }`}
                >
                  {packageData?.open
                    ? 'En construcción'
                    : (() => {
                        let authorizedList = false
                        listPackage?.forEach((it: any) => {
                          if (it?.status != 5) authorizedList = false
                        })

                        return authorizedList
                          ? 'Lista autorizada'
                          : 'Lista terminada'
                      })()}
                </p>
              </Tooltip>
            )}

            {!(packageData?.open || packageData?.request_changes) && (
              <Tags
                click={(e: any) => {
                  e.stopPropagation()
                  setOpenModificationModal(true)
                }}
                variation={6}
                title='Solicitar modificación'
              />
            )}
            {!(packageData?.open || packageData?.request_changes) &&
              hasAvailableItems && (
                <Tags
                  click={(e: any) => {
                    e.stopPropagation()
                    setOpenAuthorizationModal(true)
                  }}
                  variation={6}
                  title='Autorizar'
                />
              )}
          </Col>
        }
      >
        {loading ? (
          <div className={`${style['spin-center']}`}>
            <Spin tip='Cargando' />
          </div>
        ) : (
          <>
            {!packageData?.open && (
              <p className={`${style['p-total']}`}>
                Precio total del paquete:{' '}
                <span className={`${style['span-color']}`}>
                  ${listPackage.length * priceList} MXN
                </span>
              </p>
            )}
            {listPackage.length !== 0 ? (
              <div className={`${style['padding-inline']}`}>
                <Table
                  onRow={(dat) => {
                    return {
                      onClick: () => openDrawComments(dat)
                    }
                  }}
                  columns={columns as any}
                  dataSource={listPackage}
                />

                {packageData?.open && (
                  <div className={`${style['footer-bar-2']}`}>
                    <p className={`${style['p-total']}`}>
                      Precio total del paquete:{' '}
                      <span className={`${style['span-color']}`}>
                        ${listPackage.length * priceList} MXN
                      </span>
                    </p>
                    <Button
                      label='Terminar paquete'
                      rounded
                      sector='secondary'
                      background='white'
                      onClick={() => {
                        setOpenFinishingModal(true)
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <EmptyListMessage
                iconHeight={40}
                iconWidth={40}
                icon='utilsIcon'
                text='Aún no has agregado cuadernos a este paquete'
                buttonProps={[
                  {
                    label: 'Asignar recurso',
                    onClick: () => {
                      addButtonInPackage?.onClick({ name: name, id: id })
                    },
                    rounded: true,
                    sector: 'tertiary',
                    background: 'white',
                    size: 'm'
                  }
                ]}
              />
            )}
          </>
        )}
      </Collapse>
      <Modal
        title={`Terminar Paquete`}
        open={openFinishingModal}
        centered
        className={globalStyle.confirmModal}
        onCancel={() => setOpenFinishingModal(false)}
        footer={
          <div className={globalStyle.confirmModalFooter}>
            <Button
              rounded
              isLoading={finishingPackage}
              onClick={handleFinishPackage}
              label={'Terminar'}
              sector='secondary'
            />
          </div>
        }
      >
        <div className={globalStyle.confirmModalBody}>
          <img src={ErrorAlert} alt='descargar' />
          <B size='Light'>
            ¿Estás seguro que deseas terminar este paquete?, No podrás editar o
            borrar dicho paquete, tampoco tendrás permitido agregar más
            cuadernos en él.
          </B>
        </div>
      </Modal>
      <Modal
        title='Autorizar paquete'
        open={openAuthorizationModal}
        onCancel={() => {
          setOpenAuthorizationModal(false)
        }}
        className={globalStyle.confirmModal}
        width={700}
        footer={
          <div
            style={{ gap: '6px' }}
            className={globalStyle.confirmModalFooter}
          >
            <Button
              rounded
              isLoading={doingAuthorization}
              onClick={() => handleDoAuthorization(false)}
              label={'Autorizar'}
              sector='tertiary'
            />
            <Button
              rounded
              isLoading={doingAuthorization}
              onClick={() => handleDoAuthorization(true)}
              label={'Autorizar todo'}
              sector='tertiary'
            />
          </div>
        }
      >
        <div className={styles.bodyEndList}>
          <B size='Light'>
            Al autorizar este paquete se cerrará definitivamente y no se podrá
            modificar posteriormente
          </B>
        </div>
        <div>
          <List
            itemLayout='horizontal'
            dataSource={[
              {
                0: 'Nombre',
                1: 'Cantidad',
                2: 'Hojas',
                3: 'Rayado',
                4: 'Materia'
              }
            ]}
            renderItem={(title: any, key) => (
              <List.Item style={{ padding: ' 10px 0' }}>
                {Object.keys(title).map((res, key) => {
                  return (
                    <Col key={key} className='ListTitles' span={4}>
                      <B size='Light'>{title[res]}</B>
                    </Col>
                  )
                })}
              </List.Item>
            )}
          />
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            <List
              itemLayout='horizontal'
              dataSource={authList}
              renderItem={(util: any, key: number) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  <Col span={1}>
                    <Checkbox
                      checked={util.checked}
                      onClick={() => {
                        const list = structuredClone(authList)
                        const selected: any = list?.find(
                          (it: any) => it?.id == util?.id
                        )

                        if (selected) {
                          selected.checked = !selected?.checked
                        }

                        setAuthList(list)
                      }}
                    />
                  </Col>
                  <Col span={4}>
                    <B size='Light'>{util.name}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{util.quantity}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{util.int_sheets}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{util.int_striped}</B>
                  </Col>
                  <Col span={4}>
                    {' '}
                    <B size='Light'>{util.matter}</B>
                  </Col>
                </List.Item>
              )}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title='Solicitar modificación'
        open={openModificationModal}
        onCancel={() => {
          setOpenModificationModal(false)
        }}
        className={globalStyle.confirmModal}
        footer={
          <div
            style={{ gap: '6px' }}
            className={globalStyle.confirmModalFooter}
          >
            <Button
              rounded
              isLoading={requestingModification}
              onClick={handleRequestModification}
              label={'Solicitar modificación'}
              sector='tertiary'
            />
          </div>
        }
      >
        <div className={styles.bodyEndList}>
          <B size='Light'>
            Al solicitar una modificación, se le solicitará al administrador el
            permiso para modificar este paquete, por lo que su paquete volverá a
            estar abierto a cambios
          </B>
        </div>
      </Modal>
      <Modals
        title='Eliminar registro'
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
      >
        <>
          <span>¿Estás seguro de eliminar el registro?</span>
          <div>
            <Button
              isLoading={deletingItem}
              label={'Eliminar'}
              rounded
              sector='primary'
              onClick={() => handleDeleteItem()}
            />
          </div>
        </>
      </Modals>
    </>
  )
}

export default NotebooksItemsTable
