const getShadow = (color: string) => {
    switch (color) {
      case '#ffffff':
        return '#dadada80'
      case '#002D72':
        return '#123e8380'
      case '#BDBDBD':
        return '#cecece80'
      case '#4A94FF':
        return '#1195ee80'
      case '#CF0076':
        return '#df118780'
      case '#3B3B3B':
        return '#4c4c4c80'
      case '#000000':
        return '#22222280'
      default:
        return '#dadada80'
    }
  }

  export {getShadow}