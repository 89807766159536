import React, { memo } from 'react'
import style from './S.module.scss'
interface SInterface {
  variation?: '1' | '2'
  size?: 'Bold' | 'Regular' | 'Light'
  children: string | React.ReactNode
}
const S = memo(
  ({ variation = '1', size = 'Regular', children }: SInterface) => {
    return (
      <h5 className={style.S} data-variation={variation} data-size={size}>
        {children}
      </h5>
    )
  }
)
S.displayName = 'Subtitle'
export default S
