import React, { Fragment } from 'react'
import AcotacionesHorizontal from 'assets/img/notebooks/horizontals/AcotacionesHorizontal'
import DatosFechaLogoHorizontal from 'assets/img/notebooks/horizontals/DatosFechaLogoHorizontal'

import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import style from './notebooksEitable.module.scss'
import PathSquare5mmHorizontal from 'assets/img/notebooks/horizontals/PathSquare5mmHorizontal'
import PathSquare6mmHorizontal from 'assets/img/notebooks/horizontals/PathSquare6mmHorizontal'
import PathSquare7mmHorizontal from 'assets/img/notebooks/horizontals/PathSquare7mmHorizontal'
import PathSquare10mmHorizontal from 'assets/img/notebooks/horizontals/PathSquare10mmHorizontal'
import PathSquare14mmHorizontal from 'assets/img/notebooks/horizontals/PathSquare14mmHorizontal'
import PathDobleRayaHorizontal from 'assets/img/notebooks/horizontals/PathDobleRayaHorizontal'
import PathPautadoHorizontal from 'assets/img/notebooks/horizontals/PathPautadoHorizontal'
import PathRayado7mmHorizontal from 'assets/img/notebooks/horizontals/PathRayado7mmHorizontal'
import { TPoints, TRayadosInside } from 'redux/reducers/notebooksSlice'
import PathBlancoHorizontal from 'assets/img/notebooks/horizontals/PathBlancoHorizontal'
import PathSujecionHorizontal from 'assets/img/notebooks/horizontals/PathSujecionHorizontal'
import PathSujecionSpiralHorizontalInside from 'assets/img/notebooks/horizontals/PathSujecionSpiralHorizontalInside'

const HorizontalNotebookEditable = () => {
  const {
    notebookFrontValues,
    notebookInsideValues,
    notebookSelected,
    insideLogo
  } = useSelector((state: RootState) => state.notebooks)
  const {
    striped,
    rounded,
    magin_complete,
    guide_point,
    logo_position,
    date_type,
    margin_date,
    interior_inks
  } = notebookInsideValues
  const { binding_color, binding_type } = notebookFrontValues
  return (
    <div className={`${style['div-container-sticky']}`}>
      <div className={`${style['div-notebook-svg']}`}>
        {[1, 2].map((item: number, index: number) => {
          let viewBox = item === 1 ? '37 0 780 700' : '34 0 780 700'
          return (
            <div
              key={index}
              className={`${style['div-polo-notebook-relative']} 
              ${item === 1 && style['transform-invert']}`}
            >
              {logo_position.value !== 'none' && logo_position.value !== '' && (
                <Fragment>
                  {(() => {
                    let thisPosition = logo_position.value
                    if (item === 1) {
                      if (logo_position.value === 'left') thisPosition = 'right'
                      if (logo_position.value === 'right') thisPosition = 'left'
                    }
                    return (
                      <div
                        className={`
                        ${style[`logo-position-hori-${thisPosition}`]}
                        ${item === 1 && style['transform-invert']}`}
                      >
                        <img className={`${style['svg']}`} src={insideLogo} />
                      </div>
                    )
                  })()}
                </Fragment>
              )}
              <div className={`${style['div-polo-svg-absolute']}`}>
                <AcotacionesHorizontal
                  viewBox={viewBox}
                  notebookType={notebookSelected}
                  className={`${style['svg']}`}
                  item={item}
                />
              </div>
              <div className={`${style['div-shadows-svg-absolute']}`}></div>
              <div className={`${style['div-label-svg-absolute']}`}>
                {(() => {
                  {
                    switch (striped.value as TRayadosInside) {
                      case 'blanco':
                        return (
                          <PathBlancoHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            ink={interior_inks.value as string}
                          />
                        )
                      case '5mm':
                        return (
                          <PathSquare5mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case '6mm':
                        return (
                          <PathSquare6mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case '7mm':
                        return (
                          <PathSquare7mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case '10mm':
                        return (
                          <PathSquare10mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case '14mm':
                        return (
                          <PathSquare14mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case 'rayado':
                        return (
                          <PathDobleRayaHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )
                      case 'Pautado':
                        return (
                          <PathPautadoHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            ink={interior_inks.value as string}
                          />
                        )
                      case 'rayado 7mm':
                        return (
                          <PathRayado7mmHorizontal
                            viewBox={viewBox}
                            marginComplete={magin_complete.value as boolean}
                            roundedMargin={rounded.value as boolean}
                            className={`${style['svg']}`}
                            puntoGuia={guide_point.value as TPoints}
                            ink={interior_inks.value as string}
                            page={item}
                          />
                        )

                      default:
                        return <></>
                    }
                  }
                })()}
              </div>
              <div className={`${style['div-tags-svg-absolute']}`}>
                <DatosFechaLogoHorizontal
                  viewBox={viewBox}
                  dateStyle={date_type.value as number}
                  positionDates={logo_position.value as string}
                  datos2={`${style['date1-font-12']} 
                  ${interior_inks.value !== 'black' && style['date1-blue']}`}
                  className={`${style['svg']}`}
                  marginDate={margin_date.value as boolean}
                  ink={interior_inks.value as string}
                  page={item}
                />
              </div>
              <div className={`${style['div-sujecion-svg-absolute']}`}>
                {binding_type.value === 'Espiral' ? (
                  <Fragment>
                    {(() => {
                      let thisViewBox =
                        item === 1 ? '47 0 780 700' : '44 0 780 700'
                      return (
                        <PathSujecionSpiralHorizontalInside
                          spiralColor={binding_color.value as string}
                          className={`${style['svg']}`}
                          viewBox={thisViewBox}
                          show={2}
                        />
                      )
                    })()}
                  </Fragment>
                ) : (
                  <PathSujecionHorizontal
                    className={`${style['svg']}`}
                    viewBox={viewBox}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HorizontalNotebookEditable
