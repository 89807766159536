import { Col, List } from 'antd'
import Collapse from 'components/Collapse/Collapse'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import Tags from 'components/tag'
import B from 'components/texts/B/B'
import React, { memo } from 'react'
import UniformPrev from '../../../../assets/img/uniformPrev.svg'
const uniformTitle = [
  {
    1: 'Preview',
    2: 'Nombre del uniforme',
    3: 'Descripción',
    4: 'Estatus'
  }
]

const dummyCategory = [
  {
    id: '1',
    name: 'DEPORTE'
  }
]

const bookDummy = [
  {
    prev: <img src={UniformPrev} alt='nombre' />,
    name: 'Nombre del uniforme',
    description: 'Descripción',
    status: {
      id: 1,
      label: 'En revisión'
    }
  }
]
const colStyle = { cursor: 'pointer' }

const UniformModule = memo(() => {
  const RenderCategory = (category: any): any => {
    return category.map((res: any, key: number) => {
      return (
        <Collapse key={key + res.id} id={res.id} title={res.name}>
          <>
            <List
              itemLayout='horizontal'
              dataSource={uniformTitle}
              renderItem={(title: any) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  {Object.keys(title).map((res, key) => {
                    return (
                      <Col
                        key={key}
                        className='ListTitles'
                        style={colStyle}
                        span={6}
                      >
                        <B size='Light'>{title[res]}</B>
                      </Col>
                    )
                  })}
                </List.Item>
              )}
            />
            <List
              itemLayout='horizontal'
              dataSource={bookDummy}
              renderItem={(grade: any) => (
                <List.Item style={{ padding: ' 10px 0' }}>
                  <Col style={colStyle} span={6}>
                    <B size='Light'>{grade.prev}</B>
                  </Col>
                  <Col style={colStyle} span={6}>
                    {' '}
                    <B size='Light'>{grade.name}</B>
                  </Col>
                  <Col style={colStyle} span={6}>
                    {' '}
                    <B size='Light'>{grade.description}</B>
                  </Col>
                  <Col style={colStyle} span={6}>
                    {' '}
                    <Tags
                      variation={grade.status.id}
                      title={grade.status.label}
                    />
                  </Col>
                </List.Item>
              )}
            />
          </>
        </Collapse>
      )
    })
  }

  return (
    <div>
      {bookDummy.length === 0 ? (
        <EmptyListMessage
          icon='book'
          iconWidth={34}
          iconHeight={40}
          text='Aún no has asignado libros a esta lista'
        />
      ) : (
        RenderCategory(bookDummy)
      )}
    </div>
  )
})
UniformModule.displayName = 'UniformModule'
export default UniformModule
