import React from 'react'
import ConstructorFormulary from './ConstructorFormulary'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { handleTrajeBanoFemaleForm2pz } from 'redux/reducers/uniformsSlice'
import { TFormTrajeBanoFemale2pzaOptions } from 'interfaces/uniforms-interface'

const TrajeBanoFema2pzaOptionsEditable = () => {
  const { trajeBanoFemale2pzaForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormTrajeBanoFemale2pzaOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleTrajeBanoFemaleForm2pz({
        ...trajeBanoFemale2pzaForm,
        [keyName]: {
          ...trajeBanoFemale2pzaForm[
            keyName as TFormTrajeBanoFemale2pzaOptions
          ],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary
            handleChange={handleChange}
            form={trajeBanoFemale2pzaForm}
          />
        )
      })()}
    </div>
  )
}

export default TrajeBanoFema2pzaOptionsEditable
