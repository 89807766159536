import React from 'react'

const SportShirtFemaleSvg = ({
  className,
  stroke = '#000000',fill,neckType
  ,neckColor
}: {
  className: string
  stroke: string
  fill?:string
  neckType?:string
  neckColor?:string

}) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 800 800'
        className={className}
      >
        <g id="Path" stroke={stroke}>
    <g id="Path-2" data-name="Path">
      <path id="Trazo_playera" fill={fill}  data-name="Trazo playera"   d="m494.51,15c8.84,5.72,7.73,6.14,18.02,11.72,9.14,4.96,33.82,8.74,57.9,9.95,11.71.59,23.39,2,34.98,3.77,4.89.75,9.77,1.57,14.62,2.54,5.14,1.03,9.85,2.68,14.78,4.37s10.31,3.31,15.14,5.76c9.76,4.93,18.13,12.24,24.32,21.26.22.33.44.65.66.98,4.59,6.94,9.57,13.63,14.55,20.3,6.72,9.01,13.14,15.95,20.02,24.85,7.08,9.15,14.63,20.26,21.79,29.35,5.75,7.31,11.53,14.6,17.32,21.88,1.64,2.06,3.28,4.12,4.92,6.17,1.5,1.87,3.4,3.62,4.12,5.97s.65,5.04.31,7.43c-.8,5.65-2.96,8.18-2.96,8.18-6.25,8.58-13.34,16.61-20.41,24.51-10.61,11.85-21.69,23.32-33.45,34.03-10.71,9.74-22.16,19.5-35.19,26.02-3.93,1.97-8.11,3.55-12.48,4.2-2.53.38-9.86,1.59-10.97-1.75-2-6-5.15-14.9-5.15-14.9l-17.05-46.37s-17.78,115.85-22.14,127.47c-4.36,11.62-10.03,50.68-16.37,57.59-6.33,6.9-2.68,16.48-4.54,23.45-1.86,6.96-7.63,48.91-6.33,53.95,1.3,5.03,10.7,34.57,9.54,38.49-1.17,3.92,1.61,43.48,3.96,44.96,2.36,1.47,24.12,81.23,33.84,95.82,9.71,14.59,10.94,62.82,9.78,68.56-1.15,5.73-32.1,30.34-99.7,37.75-67.6,7.41-167.06,18.35-221.25,6.51-54.19-11.83-127.38-38.76-127.38-38.76,0,0-16.06-6.23-8.63-26.51,7.46-20.38,15.68-66.39,19.92-72.31,4.37-6.11,17.05-46.68,22.09-66.08,5.04-19.4,6.3-60.8,11.88-67.78,5.57-6.98-.54-85.75-7.74-95.81-7.19-10.07-12.69-69.35-13.41-74.44-.71-5.09-23.21-103.35-27.07-111.32-3.86-7.96-11.84,55.9-18.13,58.74-6.3,2.85-22.88.3-61.12-37-6.5-6.34-12.03-11.27-17-16-24.27-23.13-31.43-30.16-35-36,0,0-4.59-7.61-3-10,1.59-2.39,7-10,7-10,0,0,31.59-41.12,55-71,10.28-13.12,18.43-23.8,22-28,17-20,29.91-24.2,40.27-27.27,18.73-5.54,74.42-10.5,74.42-10.5,0,0,29.19-5.36,44.98-15.46,8.44-5.41,4.22-2.44,12.04-8.29,0,0,55.09,5.83,93.15,6.63,38.06.8,103.15-7.61,103.15-7.61Z"/>
      <g id="Manga_izquierda"  data-name="Manga izquierda">
        <path id="Manga_izquierda-2" fill={fill} data-name="Manga izquierda"   d="m44.43,182.49c2.01-2.26,3.76-4.73,5.61-7.12,1.34-1.72,2.68-3.45,4.02-5.17,1.62-2.08,3.23-4.16,4.85-6.24,1.85-2.37,3.69-4.75,5.54-7.12,2.02-2.6,4.05-5.2,6.07-7.8,2.15-2.76,4.3-5.53,6.46-8.29s4.46-5.72,6.7-8.58,4.52-5.79,6.78-8.69,4.48-5.73,6.72-8.59,4.34-5.54,6.51-8.31c2.05-2.61,4.1-5.22,6.15-7.83,1.88-2.39,3.76-4.77,5.64-7.15,1.66-2.1,3.32-4.19,4.99-6.28,1.39-1.74,2.78-3.49,4.18-5.22,1.07-1.33,2.14-2.65,3.22-3.97.49-.59.98-1.18,1.47-1.77,11.56-13.62,13.54-12.56,22.98-17.98,0,0,2.2,34.66,5.2,47.76,3.83,16.72,5.01,37.92,19.25,85.33,7.23,24.08-.05,32.86-.05,32.86l-.48,1.83-1.3,4.95-1.91,7.24-2.3,8.72-2.47,9.37c-.81,3.07-1.62,6.14-2.43,9.21l-2.17,8.23-1.69,6.43c-.33,1.27-.59,2.58-.98,3.83-.76,2.51-3.36,3.24-5.73,3.39-3.87.24-7.8-.89-11.41-2.15-21.97-7.68-81.67-68.27-87.18-74.27,0,0-9.6-9.17-13.32-15.85-1.07-1.93-1.82-4.17-1.47-6.4.25-1.58,1.01-2.71,2.06-3.85.16-.17.31-.34.47-.52Z"/>
        <path id="Línea_1_manga_izquierda" data-name="Línea 1 manga izquierda"   d="m44.43,182.49s59.59,83.78,116.58,99.65"/>
        <path id="Línea_2_manga_izquierda" data-name="Línea 2 manga izquierda"   d="m47.46,178.76s59.01,78.34,114.52,99.54"/>
      </g>
      <g id="Manga_derecha" data-name="Manga derecha">
        <path id="Manga_derecha-2" fill={fill} data-name="Manga derecha"   d="m752.31,176.69s-68.26-90.57-79.81-104.19c-11.56-13.62-14.55-14.58-24-20,0,0,.41,41.8-2.59,54.91-3.83,16.73-5,37.92-19.25,85.32-7.23,24.08-6.38,33.78-6.38,33.78l22.37,59.58s2.01,5.78,16.93.57c9.88-3.45,28.44-16.89,44.92-32.15,20.16-18.66,37.86-39.6,40.89-42.91,0,0,9.44-10.08,12.11-18.09,3-9-5.19-16.81-5.19-16.81Z"/>
        <path id="Línea_1_manga_derecha" data-name="Línea 1 manga derecha"   d="m641.15,283.39s6.14-.65,19.35-7.89c25.92-14.2,71.04-46.22,94.33-95.74"/>
        <path id="Línea_2_manga_derecha" data-name="Línea 2 manga derecha"   d="m639.72,278.28c2.75-.7,8.45-2.58,17.41-7.48,25.92-14.2,71.04-46.22,94.33-95.74"/>
      </g>
      <path id="Cuello_interno" fill={neckColor} data-name="Cuello interno"   d="m301.9,40.59c109.57,23.3,187.6-2.09,187.6-2.09l3-11s-53.6,9.75-74.15,10.2c-20.36.45-39.98.22-39.98.22,0,0-71.11-5.98-78.86-9.42l2,10,.4,2.09Z"/>
      
      <g>
      {(() => {
            switch (neckType) {
              case "V":
                return <g id="Cuello_V" data-name="Cuello V">
                <g id="Cuello_V_izquierda"  data-name="Cuello V izquierda">
                  <path id="Cuello_V_lado_izquierdo" fill={neckColor} data-name="Cuello V lado izquierdo"   d="m297.5,17.5l-14.75,8.88s-.24,12.22,20.25,57.54c6.67,14.75,19.75,39.38,38.62,58.53,24.86,25.24,58.88,43.05,58.88,43.05l-.06-21.47s-1.75,1.83-14.08-6.36c-19.87-13.19-33.68-27.67-52.1-50.8-24.21-30.4-35.37-90.34-35.37-90.34l-1.4.96Z"/>
                  <path id="Linea_1_cuello_V_izquierda" data-name="Linea 1 cuello V izquierda"   d="m286.5,24.5s19.48,62.7,40,89c36.24,46.45,74,65,74,65"/>
                  <path id="Linea_2_cuello_V_izquierda" data-name="Linea 2 cuello V izquierda"   d="m290.5,21.5s16.87,59.01,37.39,85.31c36.24,46.45,72.61,65.69,72.61,65.69"/>
                </g>
                <g id="Cuello_V_derecha" data-name="Cuello V derecha">
                  <path id="Cuello_V_lado_derecho" fill={neckColor} data-name="Cuello V lado derecho"   d="m494.51,15c-10.38,36.09-23.85,68.86-36.99,89.88-15.68,25.08-32.62,41.16-44.54,50.19-8.55,6.47-12.54,8.97-12.54,8.97l.06,21.47s26.69-19.93,51.55-45.17c18.87-19.16,31.95-43.79,38.62-58.53,20.5-45.32,20.26-56.06,20.26-56.06l-16.41-10.74"/>
                  <path id="Línea_1_cuello_V_derecha" data-name="Línea 1 cuello V derecha"   d="m505.5,22.5s-21.48,67.7-42,94c-36.24,46.45-63,62-63,62"/>
                  <path id="Línea_2_cuello_V_derecha" data-name="Línea 2 cuello V derecha"   d="m501.5,19.5s-22.07,67.1-42.59,93.4c-36.24,46.45-58.41,59.6-58.41,59.6"/>
                </g>
              </g>
              case "redondo":
                return <g id="Cuello_redondo" data-name="Cuello redondo">
                <path id="Cuello" fill={neckColor}  d="m279,28.11l18.85-11.7s8.23,115.49,98.42,111.4c82.59-3.75,98.39-110.52,98.73-112.82,0-.02.02-.03.04-.01l3.59,2.59,5.82,4.2c1.24.9,2.54,1.71,3.89,2.42,2.06,1.1,4.12,2.2,6.18,3.31-.16.59-.32,1.17-.49,1.76-.18.62-.36,1.24-.54,1.86-.24.82-.49,1.63-.75,2.45-.31,1-.63,2-.96,2.99-.38,1.17-.77,2.33-1.17,3.49-.45,1.32-.92,2.63-1.39,3.94-.53,1.45-1.07,2.9-1.62,4.34-.6,1.57-1.22,3.13-1.85,4.69-.68,1.67-1.37,3.34-2.08,5-.75,1.76-1.53,3.51-2.32,5.26-.83,1.83-1.69,3.65-2.56,5.47s-1.85,3.76-2.81,5.63-2.02,3.84-3.07,5.74-2.19,3.89-3.32,5.81-2.36,3.91-3.58,5.83-2.53,3.89-3.85,5.8-2.71,3.84-4.12,5.72-2.89,3.77-4.4,5.6-3.08,3.66-4.68,5.43-3.26,3.52-4.96,5.21-3.46,3.34-5.25,4.94-3.65,3.14-5.55,4.63-3.85,2.91-5.85,4.26-4.06,2.64-6.15,3.85-4.26,2.35-6.46,3.39-4.48,2.02-6.77,2.89-4.7,1.66-7.09,2.33-4.92,1.26-7.41,1.73-5.15.84-7.74,1.08-5.38.38-8.07.38c-1.23,0-2.47-.02-3.7-.07-104.54-3.96-114.91-120.82-114.91-120.82Z"/>
                <path id="Línea_2_cuello_redondo" data-name="Línea 2 cuello redondo"   d="m283.58,25.27s13.45,114.62,108.92,115.86c92.54,1.2,117-116.63,117-116.63"/>
                <path id="Línea_2_cuello_redondo-2" data-name="Línea 2 cuello redondo"   d="m290.04,21.26s7,111.57,102.46,112.81c92.54,1.2,110.69-113.24,110.69-113.24"/>
              </g>

              default:
                return <g id="Cuello_redondo" data-name="Cuello redondo">
                <path id="Cuello" fill={neckColor}  d="m279,28.11l18.85-11.7s8.23,115.49,98.42,111.4c82.59-3.75,98.39-110.52,98.73-112.82,0-.02.02-.03.04-.01l3.59,2.59,5.82,4.2c1.24.9,2.54,1.71,3.89,2.42,2.06,1.1,4.12,2.2,6.18,3.31-.16.59-.32,1.17-.49,1.76-.18.62-.36,1.24-.54,1.86-.24.82-.49,1.63-.75,2.45-.31,1-.63,2-.96,2.99-.38,1.17-.77,2.33-1.17,3.49-.45,1.32-.92,2.63-1.39,3.94-.53,1.45-1.07,2.9-1.62,4.34-.6,1.57-1.22,3.13-1.85,4.69-.68,1.67-1.37,3.34-2.08,5-.75,1.76-1.53,3.51-2.32,5.26-.83,1.83-1.69,3.65-2.56,5.47s-1.85,3.76-2.81,5.63-2.02,3.84-3.07,5.74-2.19,3.89-3.32,5.81-2.36,3.91-3.58,5.83-2.53,3.89-3.85,5.8-2.71,3.84-4.12,5.72-2.89,3.77-4.4,5.6-3.08,3.66-4.68,5.43-3.26,3.52-4.96,5.21-3.46,3.34-5.25,4.94-3.65,3.14-5.55,4.63-3.85,2.91-5.85,4.26-4.06,2.64-6.15,3.85-4.26,2.35-6.46,3.39-4.48,2.02-6.77,2.89-4.7,1.66-7.09,2.33-4.92,1.26-7.41,1.73-5.15.84-7.74,1.08-5.38.38-8.07.38c-1.23,0-2.47-.02-3.7-.07-104.54-3.96-114.91-120.82-114.91-120.82Z"/>
                <path id="Línea_2_cuello_redondo" data-name="Línea 2 cuello redondo"   d="m283.58,25.27s13.45,114.62,108.92,115.86c92.54,1.2,117-116.63,117-116.63"/>
                <path id="Línea_2_cuello_redondo-2" data-name="Línea 2 cuello redondo"   d="m290.04,21.26s7,111.57,102.46,112.81c92.54,1.2,110.69-113.24,110.69-113.24"/>
              </g>
            }
          })()}
      </g>
      
      
      <g id="Escudo">
        <g id="Escudo_derecho" data-name="Escudo derecho">
          <circle   cx="527" cy="255" r="29.5"/>
          <path d="m527,226c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
        </g>
        <g id="Escudo_izquierdo" data-name="Escudo izquierdo">
          <circle   cx="289" cy="255" r="29.5"/>
          <path d="m289,226c15.99,0,29,13.01,29,29s-13.01,29-29,29-29-13.01-29-29,13.01-29,29-29m0-1c-16.57,0-30,13.43-30,30s13.43,30,30,30,30-13.43,30-30-13.43-30-30-30h0Z"/>
        </g>
      </g>
      <g id="Costuras">
        <path   d="m52.03,173.61c10.38,13.19,48.23,70.47,110.97,100.39"/>
        <g>
          <path   d="m55.23,168.38c.06.08.11.16.17.24"/>
          <path   d="m55.66,168.99c.36.51.72,1.02,1.08,1.54"/>
          <path   d="m56.88,170.71c24.32,34.52,61.54,83.67,107.63,98.13"/>
          <path   d="m164.71,268.91c.1.03.19.06.29.09"/>
        </g>
        <path   d="m749.31,171.69c-19.76,31.97-56.42,69.28-109.48,102.21"/>
        <g>
          <path   d="m636.24,268.23c.08-.05.17-.1.25-.15"/>
          <path   d="m636.87,267.84c.53-.32,1.06-.65,1.59-.98"/>
          <path   d="m638.66,266.74c38.62-24.03,89.86-68.41,107.09-99.78"/>
          <path   d="m745.85,166.76c.05-.09.1-.17.14-.26"/>
        </g>
        <path   d="m174.64,709.41c110.7,58.66,313.46,68.22,452.38,3.62"/>
        <path   d="m306.45,37.46c79.16,16.6,145.55,7.05,181.77-1.95"/>
      </g>    
    </g>
  </g>
      </svg>
  )
}

export default SportShirtFemaleSvg