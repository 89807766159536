import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare7mmVertical = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      className={className}
      stroke='black'
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 7mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_7mm_Completo'
              data-name='Path Cuadro 7mm Completo'
            >
              <g
                id='Path_Cuadro_7mm_Completo-2'
                data-name='Path Cuadro 7mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='219' y1='103.68' x2='219' y2='737.16' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='628.95' y1='103.39' x2='628.95' y2='736.88' />
                  </g>
                  <line x1='647.94' y1='141.13' x2='200.14' y2='141.13' />
                  <line x1='647.94' y1='122.65' x2='200.14' y2='122.65' />
                  <line x1='647.94' y1='159.61' x2='200.14' y2='159.61' />
                  <line x1='648.14' y1='178.4' x2='200.34' y2='178.4' />
                  <line x1='647.94' y1='215.66' x2='200.14' y2='215.66' />
                  <line x1='647.94' y1='197.18' x2='200.14' y2='197.18' />
                  <line x1='647.94' y1='234.14' x2='200.14' y2='234.14' />
                  <line x1='648.14' y1='252.93' x2='200.34' y2='252.93' />
                  <line x1='647.94' y1='290.21' x2='200.14' y2='290.21' />
                  <line x1='647.94' y1='271.73' x2='200.14' y2='271.73' />
                  <line x1='647.94' y1='308.69' x2='200.14' y2='308.69' />
                  <line x1='648.14' y1='327.48' x2='200.34' y2='327.48' />
                  <line x1='647.94' y1='364.74' x2='200.14' y2='364.74' />
                  <line x1='647.94' y1='346.26' x2='200.14' y2='346.26' />
                  <line x1='647.94' y1='383.22' x2='200.14' y2='383.22' />
                  <line x1='648.14' y1='402.01' x2='200.34' y2='402.01' />
                  <line x1='647.94' y1='439.27' x2='200.14' y2='439.27' />
                  <line x1='647.94' y1='420.79' x2='200.14' y2='420.79' />
                  <line x1='647.94' y1='457.75' x2='200.14' y2='457.75' />
                  <line x1='648.14' y1='476.54' x2='200.34' y2='476.54' />
                  <line x1='647.94' y1='513.81' x2='200.14' y2='513.81' />
                  <line x1='647.94' y1='495.33' x2='200.14' y2='495.33' />
                  <line x1='647.94' y1='532.29' x2='200.14' y2='532.29' />
                  <line x1='648.14' y1='551.07' x2='200.34' y2='551.07' />
                  <line x1='647.94' y1='588.35' x2='200.14' y2='588.35' />
                  <line x1='647.94' y1='569.87' x2='200.14' y2='569.87' />
                  <line x1='647.94' y1='606.83' x2='200.14' y2='606.83' />
                  <line x1='648.14' y1='625.62' x2='200.34' y2='625.62' />
                  <line x1='647.94' y1='662.89' x2='200.14' y2='662.89' />
                  <line x1='647.94' y1='644.41' x2='200.14' y2='644.41' />
                  <line x1='647.94' y1='681.37' x2='200.14' y2='681.37' />
                  <line x1='648.14' y1='700.15' x2='200.34' y2='700.15' />
                  <line x1='648.14' y1='718.94' x2='200.34' y2='718.94' />
                  <line x1='237.48' y1='103.68' x2='237.48' y2='737.16' />
                  <line x1='256.27' y1='103.39' x2='256.27' y2='736.88' />
                  <line x1='293.54' y1='103.68' x2='293.54' y2='737.16' />
                  <line x1='275.06' y1='103.68' x2='275.06' y2='737.16' />
                  <line x1='312.02' y1='103.68' x2='312.02' y2='737.16' />
                  <line x1='330.8' y1='103.39' x2='330.8' y2='736.88' />
                  <line x1='368.08' y1='103.68' x2='368.08' y2='737.16' />
                  <line x1='349.6' y1='103.68' x2='349.6' y2='737.16' />
                  <line x1='386.57' y1='103.68' x2='386.57' y2='737.16' />
                  <line x1='405.35' y1='103.39' x2='405.35' y2='736.88' />
                  <line x1='442.62' y1='103.68' x2='442.62' y2='737.16' />
                  <line x1='424.14' y1='103.68' x2='424.14' y2='737.16' />
                  <line x1='461.1' y1='103.68' x2='461.1' y2='737.16' />
                  <line x1='479.88' y1='103.39' x2='479.88' y2='736.88' />
                  <line x1='517.15' y1='103.68' x2='517.15' y2='737.16' />
                  <line x1='498.67' y1='103.68' x2='498.67' y2='737.16' />
                  <line x1='535.63' y1='103.68' x2='535.63' y2='737.16' />
                  <line x1='554.42' y1='103.39' x2='554.42' y2='736.88' />
                  <line x1='591.68' y1='103.68' x2='591.68' y2='737.16' />
                  <line x1='573.2' y1='103.68' x2='573.2' y2='737.16' />
                  <line x1='610.16' y1='103.68' x2='610.16' y2='737.16' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m638.69,115.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,133.18c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,149.7c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,189.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,171.41c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,205.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,244.92c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,227.29c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,261.44c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,300.79c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,283.16c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,317.31c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,356.67c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,339.03c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,373.18c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,412.54c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,394.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,429.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,691.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,468.41c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,450.77c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,484.93c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,524.28c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,506.65c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,540.8c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,580.15c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,562.52c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,596.67c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,636.03c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,618.39c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,652.54c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,674.26c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,708.42c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,730.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m638.62,151.97c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,168.01c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,115.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,130.73c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,189.25c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,205.29c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,226.53c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,242.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,263.8c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,279.84c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,301.08c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,317.12c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,338.36c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,354.4c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,375.64c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,391.68c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,412.91c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,428.95c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,450.19c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,466.23c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,487.47c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,503.51c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,524.75c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,540.79c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,562.02c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,578.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,599.3c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,615.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,636.58c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,652.62c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,673.86c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,689.9c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,711.13c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,727.17c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M210,115.32a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,133.18a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,149.7a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,5.08,5.08,0,0,1,.51.94l.12.29c.13.32.22.56.26.7a5,5,0,0,1,.56-.43l.34-.23a14.87,14.87,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,189.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,171.41a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,205.57a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.11,1.11,0,0,1,.52.41,4.63,4.63,0,0,1,.51.93l.12.29c.13.33.22.56.26.7a6.57,6.57,0,0,1,.56-.43l.34-.23a15.2,15.2,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,244.92a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,227.29a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M210.81,261.44a12.64,12.64,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.78-.95a1.32,1.32,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69a6.57,6.57,0,0,1,.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,300.79a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,283.16a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,317.31a12.93,12.93,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.78-.95a1.36,1.36,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,356.67a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,339a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,339' />
                                    <path d='M210.81,373.18a12.69,12.69,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,4.77,4.77,0,0,1,.51.94l.12.29a7.12,7.12,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a14.92,14.92,0,0,1,1.8-2.83,12.52,12.52,0,0,1,2.15-2.23l-.16-.23a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,412.54a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,394.9a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,394.9' />
                                    <path d='M210.81,429.06a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,5.08,5.08,0,0,1,.51.94l.12.29c.13.32.22.56.26.7a5,5,0,0,1,.56-.43l.34-.23a14.87,14.87,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,691.9a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,691.9' />
                                    <path d='M210,468.41a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,450.77a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,484.93a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.11,1.11,0,0,1,.52.41,4.63,4.63,0,0,1,.51.93l.12.29c.13.33.22.56.26.7a6.57,6.57,0,0,1,.56-.43l.34-.23a15.2,15.2,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,524.28a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,506.65a1.5,1.5,0,1,0-1.49-1.5,1.5,1.5,0,0,0,1.49,1.5' />
                                    <path d='M210.81,540.8a12.64,12.64,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.78-1a1.32,1.32,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69a6.57,6.57,0,0,1,.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,580.15a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,562.52a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,596.67a12.93,12.93,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.78-1a1.36,1.36,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83A12.64,12.64,0,0,1,213.3,595l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,636a1.5,1.5,0,1,0-1.49-1.5A1.5,1.5,0,0,0,210,636' />
                                    <path d='M210,618.39a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,652.54a12.93,12.93,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,4.49,4.49,0,0,1,.51.94l.12.29c.13.32.22.55.26.69.1-.09.29-.23.56-.42l.34-.23a14.92,14.92,0,0,1,1.8-2.83,12.52,12.52,0,0,1,2.15-2.23l-.16-.23a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,674.26a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,708.42a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,5.08,5.08,0,0,1,.51.94l.12.29c.13.32.22.56.26.7a5,5,0,0,1,.56-.43l.34-.23a14.87,14.87,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,730.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M210,152a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,152' />
                                    <path d='M210.87,168a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,115.32a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,130.73a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,189.25a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,205.29a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,226.53a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,242.57a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,263.8a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,263.8' />
                                    <path d='M210.87,279.84a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,301.08a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,317.12a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,338.36a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,354.4a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,375.64a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,391.68a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,412.91a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,429a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,450.19a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,466.23a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,487.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,503.51a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,524.75a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,540.79a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,562a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,562' />
                                    <path d='M210.87,578.06a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,599.3a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,599.3' />
                                    <path d='M210.87,615.34a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,636.58a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,652.62a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.13,1.13,0,0,1,.53.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,673.86a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,689.9a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,711.13a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,727.17a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.38,1.38,0,0,0-.9.52,1.17,1.17,0,0,1,.53.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 7mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_7mm_Datos' data-name='Path Cuadro 7mm Datos'>
              <g id='Path_Cuadro_7mm_Datos-2' data-name='Path Cuadro 7mm Datos'>
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='219' y1='148.83' x2='219' y2='744.52' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='628.95' y1='148.56' x2='628.95' y2='744.25' />
                  </g>
                  <line x1='647.94' y1='186.3' x2='200.14' y2='186.3' />
                  <line x1='647.94' y1='167.82' x2='200.14' y2='167.82' />
                  <line x1='647.94' y1='204.78' x2='200.14' y2='204.78' />
                  <line x1='648.14' y1='223.57' x2='200.34' y2='223.57' />
                  <line x1='647.94' y1='260.83' x2='200.14' y2='260.83' />
                  <line x1='647.94' y1='242.35' x2='200.14' y2='242.35' />
                  <line x1='647.94' y1='279.31' x2='200.14' y2='279.31' />
                  <line x1='648.14' y1='298.1' x2='200.34' y2='298.1' />
                  <line x1='647.94' y1='335.38' x2='200.14' y2='335.38' />
                  <line x1='647.94' y1='316.9' x2='200.14' y2='316.9' />
                  <line x1='647.94' y1='353.86' x2='200.14' y2='353.86' />
                  <line x1='648.14' y1='372.65' x2='200.34' y2='372.65' />
                  <line x1='647.94' y1='409.91' x2='200.14' y2='409.91' />
                  <line x1='647.94' y1='391.43' x2='200.14' y2='391.43' />
                  <line x1='647.94' y1='428.39' x2='200.14' y2='428.39' />
                  <line x1='648.14' y1='447.18' x2='200.34' y2='447.18' />
                  <line x1='647.94' y1='484.45' x2='200.14' y2='484.45' />
                  <line x1='647.94' y1='465.97' x2='200.14' y2='465.97' />
                  <line x1='647.94' y1='502.93' x2='200.14' y2='502.93' />
                  <line x1='648.14' y1='521.71' x2='200.34' y2='521.71' />
                  <line x1='647.94' y1='558.98' x2='200.14' y2='558.98' />
                  <line x1='647.94' y1='540.5' x2='200.14' y2='540.5' />
                  <line x1='647.94' y1='577.46' x2='200.14' y2='577.46' />
                  <line x1='648.14' y1='596.24' x2='200.34' y2='596.24' />
                  <line x1='647.94' y1='633.53' x2='200.14' y2='633.53' />
                  <line x1='647.94' y1='615.05' x2='200.14' y2='615.05' />
                  <line x1='647.94' y1='652.01' x2='200.14' y2='652.01' />
                  <line x1='648.14' y1='670.79' x2='200.34' y2='670.79' />
                  <line x1='647.94' y1='708.06' x2='200.14' y2='708.06' />
                  <line x1='647.94' y1='689.58' x2='200.14' y2='689.58' />
                  <line x1='647.94' y1='726.54' x2='200.14' y2='726.54' />
                  <line x1='237.48' y1='148.83' x2='237.48' y2='744.52' />
                  <line x1='256.27' y1='148.56' x2='256.27' y2='744.25' />
                  <line x1='293.54' y1='148.83' x2='293.54' y2='744.52' />
                  <line x1='275.06' y1='148.83' x2='275.06' y2='744.52' />
                  <line x1='312.02' y1='148.83' x2='312.02' y2='744.52' />
                  <line x1='330.8' y1='148.56' x2='330.8' y2='744.25' />
                  <line x1='368.08' y1='148.83' x2='368.08' y2='744.52' />
                  <line x1='349.6' y1='148.83' x2='349.6' y2='744.52' />
                  <line x1='386.56' y1='148.83' x2='386.56' y2='744.52' />
                  <line x1='405.35' y1='148.56' x2='405.35' y2='744.25' />
                  <line x1='442.62' y1='148.83' x2='442.62' y2='744.52' />
                  <line x1='424.14' y1='148.83' x2='424.14' y2='744.52' />
                  <line x1='461.1' y1='148.83' x2='461.1' y2='744.52' />
                  <line x1='479.88' y1='148.56' x2='479.88' y2='744.25' />
                  <line x1='517.15' y1='148.83' x2='517.15' y2='744.52' />
                  <line x1='498.67' y1='148.83' x2='498.67' y2='744.52' />
                  <line x1='535.63' y1='148.83' x2='535.63' y2='744.52' />
                  <line x1='554.42' y1='148.56' x2='554.42' y2='744.25' />
                  <line x1='591.68' y1='148.83' x2='591.68' y2='744.52' />
                  <line x1='573.2' y1='148.83' x2='573.2' y2='744.52' />
                  <line x1='610.16' y1='148.83' x2='610.16' y2='744.52' />
                </g>
                {(() => {
                  switch (page) {
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M210,159.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,177.33a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,193.85a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,5.08,5.08,0,0,1,.51.94l.12.29c.13.32.22.56.26.7a5,5,0,0,1,.56-.43l.34-.23a15.2,15.2,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,233.2a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,233.2' />
                                    <path d='M210,215.56a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,249.72a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.32,1.32,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.29c.13.33.22.56.26.7a6.57,6.57,0,0,1,.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,289.07a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,271.44a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,305.59a12.64,12.64,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.78-.95a1.32,1.32,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83A12.64,12.64,0,0,1,213.3,304l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,344.94a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,327.31a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,361.46a12.93,12.93,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.78-.95a1.36,1.36,0,0,0-.89.52,1.15,1.15,0,0,1,.52.4,4.49,4.49,0,0,1,.51.94l.12.29c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,13,13,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,400.82a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,383.18a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,417.34a12.36,12.36,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,4.77,4.77,0,0,1,.51.94l.12.29a7.12,7.12,0,0,1,.26.7A5,5,0,0,1,209,421l.34-.23a14.87,14.87,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a10.72,10.72,0,0,0-2.33,1.87' />
                                    <path d='M210,456.69a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,439.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,473.21a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,5.08,5.08,0,0,1,.51.94l.12.29c.13.32.22.56.26.7a5,5,0,0,1,.56-.43l.34-.23a15.2,15.2,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,736.05a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,512.56a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,494.92a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,529.08a12.64,12.64,0,0,0-2,2.5l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.17,1.17,0,0,1,.52.41,4.63,4.63,0,0,1,.51.93l.12.29c.13.33.22.56.26.7a6.57,6.57,0,0,1,.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.87' />
                                    <path d='M210,568.43a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,550.8a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,550.8' />
                                    <path d='M210.81,585a12.64,12.64,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-.95-.78-.95a1.32,1.32,0,0,0-.89.52,1.08,1.08,0,0,1,.52.4,4.63,4.63,0,0,1,.51.93l.12.3c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,12.64,12.64,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,624.3a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,624.3' />
                                    <path d='M210,606.67a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,640.82a12.93,12.93,0,0,0-2,2.5l-.14-.31c-.29-.63-.54-1-.78-1a1.36,1.36,0,0,0-.89.52,1.15,1.15,0,0,1,.52.4,4.49,4.49,0,0,1,.51.94l.12.29c.13.32.22.55.26.69.1-.09.29-.23.56-.43l.34-.22a14.92,14.92,0,0,1,1.8-2.83,13,13,0,0,1,2.15-2.24l-.16-.22a10.65,10.65,0,0,0-2.33,1.86' />
                                    <path d='M210,680.18a1.5,1.5,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210,662.54a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.81,696.69a12.69,12.69,0,0,0-2,2.51l-.14-.32c-.29-.63-.54-.94-.78-.94a1.34,1.34,0,0,0-.89.51,1.15,1.15,0,0,1,.52.4,4.77,4.77,0,0,1,.51.94l.12.29a7.12,7.12,0,0,1,.26.7,5,5,0,0,1,.56-.43l.34-.23a14.87,14.87,0,0,1,1.8-2.82,12.3,12.3,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.33,1.86' />
                                    <path d='M210,718.41a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M210,196.12a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,212.16a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.38,1.38,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,159.47a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,174.88a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M210,233.4a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,233.4' />
                                    <path d='M210.87,249.44a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,270.68a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,286.72a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,308a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,308' />
                                    <path d='M210.87,324a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.89,15.89,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M210,345.23a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,361.27a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,382.51a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,398.55a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,419.79a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,435.83a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,457.06a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,473.1a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,494.34a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,510.38a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,531.62a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,547.66a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,568.9a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,568.9' />
                                    <path d='M210.87,584.94a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,606.17a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,622.21a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,4.83,4.83,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,11.86,11.86,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,643.45a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,659.49a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M210,680.73a1.49,1.49,0,1,0-1.49-1.49,1.49,1.49,0,0,0,1.49,1.49' />
                                    <path d='M210.87,696.77a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.34,1.34,0,0,0-.9.52,1.1,1.1,0,0,1,.53.4,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M210,718a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,210,718' />
                                    <path d='M210.87,734.05a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.36,1.36,0,0,0-.9.51,1.17,1.17,0,0,1,.53.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,11.66,11.66,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m638.69,159.47c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,177.33c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,193.85c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,233.2c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,215.56c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,249.72c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,289.07c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,271.44c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,305.59c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,344.94c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,327.31c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,361.46c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,400.82c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,383.18c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,417.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,456.69c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,439.05c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,473.21c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,736.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,512.56c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,494.92c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,529.08c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,568.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,550.8c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,584.95c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,624.3c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,606.67c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,640.82c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,680.18c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m638.69,662.54c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.84,696.69c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.69,718.41c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m638.62,196.12c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,212.16c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,159.47c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,174.88c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,233.4c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,249.44c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,270.68c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,286.72c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,307.95c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,323.99c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,345.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,361.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,382.51c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,398.55c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,419.79c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,435.83c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,457.06c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,473.1c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,494.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,510.38c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,531.62c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,547.66c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,568.9c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,584.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,606.17c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,622.21c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,643.45c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,659.49c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,680.73c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,696.77c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m638.62,718.01c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m637.78,734.05c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 7mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_7mm_Completo'
                data-name='Margenes Cuadro 7mm Completo'
              >
                <g
                  id='Margenes_Cuadro_7mm_Completo-2'
                  data-name='Margenes Cuadro 7mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V723.08a13.09,13.09,0,0,1-13.08,13.08H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V723.08A14.08,14.08,0,0,0,214,737.16H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56v631.6H200.93V104.56H647.24m1-1H199.93v633.6H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            {' '}
            <title>Margenes Cuadro 7mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_7mm_Datos'
                data-name='Margenes Cuadro 7mm Datos'
              >
                <g
                  id='Margenes_Cuadro_7mm_Datos-2'
                  data-name='Margenes Cuadro 7mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08v567.8a13.08,13.08,0,0,1-13.08,13.08H214a13.09,13.09,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08v567.8A14.08,14.08,0,0,0,214,744.52H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56v594H200.93v-594H647.24m1-1H199.93v596H648.24v-596Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare7mmVertical
