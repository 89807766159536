import React from 'react'

const PathSujecionHorizontal = ({
  className,
  viewBox = '0 0 800 800'
}: {
  className: string
  viewBox?: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      <defs>
        <style>
          {`
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        fill: url(#Degradado_sin_nombre_39);
      }

      .cls-3 {
        fill: url(#Degradado_sin_nombre_39-2);
      }`}
        </style>
        <linearGradient
          id='Degradado_sin_nombre_39'
          data-name='Degradado sin nombre 39'
          x1='6.19'
          y1='384.35'
          x2='63.81'
          y2='384.35'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#fff' stopOpacity='0' />
          <stop offset='.1' stopColor='#d9d9da' stopOpacity='.29' />
          <stop offset='.25' stopColor='#a8a8aa' stopOpacity='.67' />
          <stop offset='.36' stopColor='#898a8c' stopOpacity='.91' />
          <stop offset='.43' stopColor='#7e7f81' />
          <stop offset='.45' stopColor='#7a7b7d' />
          <stop offset='.46' stopColor='#707173' />
          <stop offset='.47' stopColor='#606061' />
          <stop offset='.49' stopColor='#494949' />
          <stop offset='.49' stopColor='#3d3d3d' />
          <stop offset='.5' stopColor='#515151' />
          <stop offset='.5' stopColor='#616162' />
          <stop offset='.51' stopColor='#6c6c6e' />
          <stop offset='.52' stopColor='#737375' />
          <stop offset='.54' stopColor='#757577' />
          <stop offset='.64' stopColor='#858587' stopOpacity='.84' />
          <stop offset='.82' stopColor='#b0b0b1' stopOpacity='.43' />
          <stop offset='1' stopColor='#deddde' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='Degradado_sin_nombre_39-2'
          data-name='Degradado sin nombre 39'
          x1='687.84'
          x2='745.47'
          href='#Degradado_sin_nombre_39'
        />
      </defs>
      <g
        id='Encuadernado_Engomado_Cosido'
        data-name='Encuadernado Engomado/Cosido'
      >
        <g
          id='Encuadernado_Engomado_Cosido-2'
          data-name='Encuadernado Engomado/Cosido'
        >
          <rect
            stroke='transparent'
            id='Encuadernado_Engomado_Cosido_Izquierdo'
            data-name='Encuadernado Engomado/Cosido Izquierdo'
            className='cls-2'
            x='6.19'
            y='128'
            width='57.62'
            height='512.7'
          />
        </g>
      </g>
    </svg>
  )
}

export default PathSujecionHorizontal
