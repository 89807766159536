import Icon from 'components/icons'
import { memo } from 'react'
import style from './Navbar.module.scss'
import BackButton from 'components/buttons/BackButton/BackButton'
import { LogoutOutlined, TeamOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useDispatch } from 'react-redux'
import {
  setIDLevel,
  setLevelsBooks,
  setNewStructureBook
} from 'redux/reducers/books'
interface navbarInterface {
  complete?: boolean
  onClick?: () => void
}
const Navbar = memo(({ complete, onClick }: navbarInterface) => {
  const navigate = useNavigate()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const dispatch = useDispatch()

  const handleLoggout = () => {
    sessionStorage.removeItem('ezeteraToken')
    window.location.reload()
    dispatch(setLevelsBooks([]))
    dispatch(setIDLevel(0))
    dispatch(setNewStructureBook(false))
  }
  const handleChangeSchool = () => {
    navigate('/selecciona-una-escuela')
    dispatch(setLevelsBooks([]))
    dispatch(setIDLevel(0))
    dispatch(setNewStructureBook(false))
  }
  return (
    <div className={style.navbar} data-variation={complete ? 'complete' : ''}>
      {onClick ? (
        <div className='left'>
          {complete && <BackButton onClick={onClick} />}
        </div>
      ) : (
        <div className='left'>{complete && <BackButton />}</div>
      )}

      <div className={style.right}>
        <div
          className='div'
          onClick={() =>
            navigate(
              `${uid.role == 'Ezetera' ? '/lista-de-colegios' : '/estructura'}`
            )
          }
        >
          <Icon name='logo' />
        </div>
        {uid.role !== 'Ezetera' && uid?.schools?.length > 1 && (
          <Tooltip title='Cambiar Colegio'>
            <TeamOutlined onClick={handleChangeSchool} />
          </Tooltip>
        )}

        <Tooltip title='Desconexión'>
          <LogoutOutlined onClick={handleLoggout} />
        </Tooltip>
      </div>
    </div>
  )
})

export default Navbar
