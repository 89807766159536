import { Col, Collapse, Input, Select, Table, Tabs, Typography } from 'antd'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useQuotas } from 'hooks/quotas/useQuotas'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIDGrade } from 'redux/reducers/quotas'
import { RootState } from 'redux/store/store'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import styles from './quotas.module.scss'
import B from 'components/texts/B/B'
import Button, { ButtonInterface } from 'components/buttons/Button/Button'
import TitleHeader from 'components/TitleHeader/TitleHeader'
import Modals from 'components/modal'
import ErrorAlert from 'assets/img/errorAlert.svg'
import FormQuotasModule from './form'
import QuotasActionsModule from './actions'
import QuotasAuthorizeModule from './authorize'
import ListQuotasModule from './list/ListQuotasModule'
import TabsAdminModule from './tabsAdmin/TabsAdminModule'
import { Options } from 'types'
import EndListModal from 'components/EndListModal/EndListModal'

const QuotasModule = memo(() => {
  const {
    actions,
    loading,
    openModalQuota,
    form,
    quotaColumns,
    modalEndList,
    modalAuthorize,
    selectedRowKeys,
    modalRequestChanges,
    schoolsCatalog,
    statusCatalog,
    changeViewAdmin,
    quotas,
    idSchool,
    idGrade,
    idLevel,
    selectStatus,
    searchGralQuotas,
    quotaSchool,
    loadingDowload,
    setIdLevel,
    handleOpenModalQuota,
    handleCloseModalQuota,
    setIdGrade,
    handleChangeLevelTab,
    onHandleForm,
    handleCollapseQuotas,
    onHandleDeleteQuota,
    onHandleOpenModalEndList,
    onHandleCancelModalEndList,
    handleEndList,
    handleOpenAuth,
    handleCancelAuth,
    handleAllAuthorize,
    handleAuthorize,
    setSelectedRowKeys,
    handleOpenRequestChanges,
    handleCancelRequestChanges,
    handleRequestChanges,
    onHandleChangeSchool,
    onHandleChangeStatus,
    onHandleChangePage,
    onHandleSearchQuotas,
    downloadQuotas,
    donwloadLogs
  } = useQuotas()

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const { levelsQuotas, IDLevel, IDGrade, quotasSchool } = useSelector(
    (state: RootState) => state.quotas
  )
  const [changeViewMode, setChangeViewMode] = useState(false)

  const dispatch = useDispatch()
  const { Panel } = Collapse

  const { Option } = Select

  const onChange = (key: string | string[]) => {
    key.length > 0 && handleCollapseQuotas(+key.slice(-1))
    key.length > 0 && setIdGrade(+key.slice(-1))
    dispatch(setIDGrade(key))
  }

  const renderTitleButtons = () => {
    if (uid.role === 'Ezetera') {
      return [
        
        {
          label: 'Descargar',
          onClick: () => downloadQuotas(),
          background: 'white',
          rounded: true,
          size: 'm',
          sector: 'tertiary',
          isLoading: loading
        },
        {
          label: 'Descargar Logs',
          onClick: () => donwloadLogs(),
          background: 'white',
          rounded: true,
          size: 'm',
          sector: 'tertiary',
          isLoading: loadingDowload,
          /* disabled:true */
        },
      ]
    }

    let buttons: ButtonInterface[] = [
      {
        label: 'Descargar',
        onClick: () => downloadQuotas(),
        background: 'white',
        rounded: true,
        size: 'm',
        sector: 'tertiary',
        isLoading: loading
      }
    ]

    return buttons
  }

  return (
    <MainTemplate>
      <>
        <TitleHeader
          IconName='rightArrow'
          buttonProps={renderTitleButtons()}
          widthIcon={'35'}
          heightIcon={'35'}
          title={'Cuota'}
        />

        {uid.role === 'Ezetera' && (
          <>
            <div className={styles.filters}>
              <div>
                <Typography>Colegios</Typography>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder='Colegios'
                  optionFilterProp='children'
                  onChange={onHandleChangeSchool}
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {schoolsCatalog?.map((school: Options) => (
                    <Option key={school?.value as number} value={school?.value}>
                      {school?.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <Typography>Estatus</Typography>
                <Select
                  style={{ width: 200 }}
                  defaultValue={'todos'}
                  placeholder={'Estatus'}
                  onChange={onHandleChangeStatus}
                  options={statusCatalog.filter(
                    (item: any) => item.label !== 'Nuevo'
                  )}
                />
              </div>
            </div>
            <Input.Search
              placeholder='Buscar'
              onChange={(e: any) => onHandleSearchQuotas(e.target.value)}
              value={searchGralQuotas}
              enterButton
              size='large'
              className={styles.inputSearchGral}
            />
            {changeViewAdmin === 'school' && (
              <div style={{ marginBottom: 16 }}>
                <Button
                  rounded
                  onClick={() => setChangeViewMode(!changeViewMode)}
                >
                  Cambiar a modo {changeViewMode ? 'acordeon' : 'tabla'}
                </Button>
              </div>
            )}

            {changeViewAdmin === 'all' && (
              <ListQuotasModule
                loading={loading}
                scroll={{ x: 820 }}
                columns={quotaColumns}
                quotas={quotas}
                onChangePage={onHandleChangePage}
              />
            )}

            {changeViewAdmin === 'school' && !changeViewMode && (
              <TabsAdminModule
                quotaColumns={quotaColumns}
                idSchool={idSchool}
                idLevel={idLevel}
                idGrade={idGrade}
                setIdLevel={setIdLevel}
                setIdGrade={setIdGrade}
                selectStatus={selectStatus}
                searchGralQuotas={searchGralQuotas}
              />
            )}

            {changeViewAdmin === 'school' && changeViewMode && (
              <ListQuotasModule
                loading={loading}
                scroll={{ x: 820 }}
                columns={quotaColumns}
                quotas={quotas}
                onChangePage={onHandleChangePage}
              />
            )}
          </>
        )}
        {uid.role !== 'Ezetera' && (
          <>
            <Col span={24}>
              <Tabs
                items={levelsQuotas}
                defaultActiveKey={IDLevel}
                onChange={handleChangeLevelTab}
              />
            </Col>
            <Col span={24}>
              {levelsQuotas
                ?.find((level: any) => level.key === IDLevel)
                ?.grades?.map((res: any) => {
                  const total = quotasSchool
                    ?.filter(
                      (item: { idGrade: any }) => item.idGrade === res.id
                    )
                    ?.reduce(
                      (
                        acc: number,
                        cv: {
                          price: string | number
                        }
                      ) => {
                        return acc + +cv.price
                      },
                      0
                    )
                  return (
                    <Collapse
                      key={res.id}
                      activeKey={IDGrade}
                      onChange={onChange}
                      bordered={false}
                    >
                      <Panel
                        className={styles.collapsePanel}
                        extra={
                          <QuotasActionsModule
                            dataQuotas={quotasSchool}
                            handleAssign={handleOpenModalQuota}
                            handleAuthorize={handleOpenAuth}
                            handleOpenRequestChanges={handleOpenRequestChanges}
                            idGrade={res.id}
                            endQuotas={res.endQuotas}
                          />
                        }
                        header={
                          <>
                            <B>
                              {res?.name} {'   '}
                              {total ? (
                                <b style={{ color: '#0F48FF' }}>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2
                                  }).format(total)}
                                </b>
                              ) : (
                                ''
                              )}
                            </B>
                          </>
                        }
                        key={res.id}
                      >
                        {quotasSchool.filter(
                          (item: { idGrade: any }) => item.idGrade === res.id
                        )?.length > 0 ? (
                          <>
                            <Table
                              dataSource={quotasSchool.filter(
                                (item: { idGrade: any }) =>
                                  item.idGrade === res.id
                              )}
                              loading={loading}
                              // rowSelection={{ ...rowSelection }}
                              columns={quotaColumns}
                              scroll={{ x: 800 }}
                              rowKey={(row) => row.id}
                              className={styles.booksTable}
                              onRow={(record, _) => {
                                return {
                                  onClick: (_) => {
                                    setIdGrade(record?.idGrade as number)
                                  }
                                }
                              }}
                            />
                            {!res.endQuotas && uid.role !== 'Director' && (
                              <div className={styles.endList}>
                                <Button
                                  label={'Terminar'}
                                  rounded
                                  sector='secondary'
                                  background='white'
                                  onClick={() => {
                                    onHandleOpenModalEndList(res.id)
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <EmptyListMessage
                            iconHeight={40}
                            iconWidth={40}
                            icon='emptySchoolListIcon'
                            text='Aún no has asignado cuotas'
                            {...(uid.role !== 'Director' && {
                              buttonProps: [
                                {
                                  label: 'Asignar primer cuota',
                                  onClick: () => {
                                    handleOpenModalQuota(res.id)
                                  },

                                  rounded: true,
                                  sector: 'tertiary',
                                  background: 'white',
                                  size: 'm'
                                }
                              ]
                            })}
                          />
                        )}
                      </Panel>
                    </Collapse>
                  )
                })}
            </Col>
          </>
        )}

        <Modals
          title={
            actions.action !== 'delete'
              ? actions.action === 'edit'
                ? `Actualizar ${actions.name}`
                : 'Nueva cuota'
              : `Eliminar ${actions.name}`
          }
          width={460}
          open={openModalQuota}
          onCancel={handleCloseModalQuota}
        >
          {actions.action !== 'delete' ? (
            <FormQuotasModule
              loading={loading}
              quotaSchool={quotaSchool}
              form={form}
              onHandleForm={onHandleForm}
              actions={actions}
            />
          ) : (
            <>
              <span>¿Estás seguro de eliminar el registro?</span>
              <div className={styles.button}>
                <Button
                  label={'Eliminar'}
                  rounded
                  sector='primary'
                  onClick={onHandleDeleteQuota}
                />
              </div>
            </>
          )}
        </Modals>

        <Modals
          title='Terminar lista'
          open={modalEndList}
          onCancel={onHandleCancelModalEndList}
        >
          <>
            <div className={styles.bodyEndList}>
              <img src={ErrorAlert} alt='Terminar lista' />
              <B size='Light'>
                ¿Estás seguro que deseas terminar esta lista?, No podrás editar
                o borrar dicha lista, tampoco tendras permitido agregar más
                cuotas en ella.
              </B>
            </div>
            <div className={styles.button}>
              <Button
                rounded
                onClick={handleEndList}
                label={'Terminar'}
                sector='secondary'
              />
            </div>
          </>
        </Modals>
        <Modals
          title='Autorizar Cuotas'
          width={800}
          open={modalAuthorize}
          onCancel={handleCancelAuth}
        >
          <QuotasAuthorizeModule
            loading={loading}
            handleAllAuthorize={handleAllAuthorize}
            handleAuthorize={handleAuthorize}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </Modals>

        <Modals
          title='Solicitar modificación'
          open={modalRequestChanges}
          onCancel={handleCancelRequestChanges}
        >
          <>
            <div className={styles.bodyEndList}>
              <B size='Light'>
                Al solicitar una modificación, se le solicitará al administrador
                el permiso para modificar esta lista, por lo que su lista
                volverá a estar abierta a cambios.
              </B>
            </div>
            <div className={styles.button}>
              <Button
                rounded
                onClick={handleRequestChanges}
                label={'Solicitar modificación'}
                sector='tertiary'
              />
            </div>
          </>
        </Modals>
      </>
      <EndListModal/>
    </MainTemplate>
  )
})

export default QuotasModule
