import React from 'react'

const LabelPolo = ({
  className,
  stroke = '#305599'
}: {
  className: string
  stroke: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id='Capa_2' data-name='Capa 2'>
        <g id='Etiqueta_lateral' data-name='Etiqueta lateral'>
          <g id='Etiqueta'>
            <g id='Forma'>
              <path
                stroke={stroke}
                fill={stroke}
                d='M592.2,597.29c-1.63,0-2.26.42-2.13,2.53.33,5.79.5,11.58.59,17.37,0,1.85.54,2.28,2.08,2.22,9.81-.4,19.63-.7,29.44-1l-.74-22-.42,0C611.42,596.68,601.81,597,592.2,597.29Z'
              />
            </g>
            <g id='Logo'>
              <path
                stroke='#123c18'
                fill='#123c18'
                d='M608.23,613.64c.4-.22.79-.48,1.21-.68a8.06,8.06,0,0,0,3-2.9,1.42,1.42,0,0,0-.13-2,12.62,12.62,0,0,0-6.69-3.73.68.68,0,0,1-.26-.12,10.81,10.81,0,0,1,3,.65c.29.06.66.38.86,0s-.15-.63-.31-.92a13.4,13.4,0,0,1-.75-1.41,12.64,12.64,0,0,0,5.14,4.18c.89.4,1.31.16,1.66-.73a6.82,6.82,0,0,0,.7-4.48c1,1.83,2.36,3.52,1.62,6,.5,0,.93,0,1.37,0s.59.34.72.69c.21.53-.3.37-.47.52-.94.83-2.19,1.18-2.84,2.5a3.38,3.38,0,0,1-2.32,1.42A29.42,29.42,0,0,1,608.23,613.64Z'
              />
            </g>
            <rect stroke='transparent' width='800' height='800' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LabelPolo
