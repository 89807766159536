import PreBuyFooter from 'components/PreBuyFooter/PreBuyFooter'
import BooksModule from 'modules/books'
import { memo } from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import bkgPreBuyBooks from '../../assets/img/preBuyBackgrounds/bkgPreBuyBooks.svg'
import { useValidatePermission } from 'hooks/utils/useValidatePermission'

const acquired = true

const index = memo(() => {
  useValidatePermission()

  if(acquired) return <BooksModule />
  return (
    <MainTemplate preBuyPage={bkgPreBuyBooks}>
      <PreBuyFooter
        title='Libros'
        imgTitle={{
          name: 'book',
          height: 20,
          width: 20
        }}
        subTitle='Maecenas eget condimentum velit, sit amet feugiat lectus.'
        description={[
          'Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a',
          ' , mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.'
        ]}
        topics={[
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          'Vorem ipsum dolor sit amet, consectetur adipiscing elit. '
        ]}
        buttonProps={[
          {
            label: 'Contratar este módulo',
            onClick: () => alert('click'),
            sector: 'tertiary',
            rounded: true,
            size: 'm'
          }
        ]}
      />
    </MainTemplate>
  )
})

index.displayName = 'Books'
export default index
