import React from 'react'
import { TPoints } from 'redux/reducers/notebooksSlice'

const PathSquare7mmHorizontal = ({
  className,
  marginComplete,
  roundedMargin,
  puntoGuia,
  viewBox = '0 0 800 800',
  ink,
  page
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  puntoGuia: TPoints
  viewBox?: string
  ink: string
  page: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Cuadro 7mm Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g
              id='Path_Cuadro_7mm_Completo'
              data-name='Path Cuadro 7mm Completo'
            >
              <g
                id='Path_Cuadro_7mm_Completo-2'
                data-name='Path Cuadro 7mm Completo'
              >
                <g
                  id='Cuadricula_completa'
                  data-name='Cuadricula completa'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='105.27' y1='152.02' x2='105.27' y2='622.51' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='667.4' y1='152.02' x2='667.4' y2='622.51' />
                  </g>
                  <line x1='124.5' y1='152.02' x2='124.5' y2='622.51' />
                  <line x1='143.72' y1='152.02' x2='143.72' y2='622.51' />
                  <line x1='163.27' y1='151.8' x2='163.27' y2='622.3' />
                  <line x1='202.05' y1='152.02' x2='202.05' y2='622.51' />
                  <line x1='182.82' y1='152.02' x2='182.82' y2='622.51' />
                  <line x1='221.28' y1='152.02' x2='221.28' y2='622.51' />
                  <line x1='240.83' y1='151.8' x2='240.83' y2='622.3' />
                  <line x1='279.62' y1='152.02' x2='279.62' y2='622.51' />
                  <line x1='260.39' y1='152.02' x2='260.39' y2='622.51' />
                  <line x1='298.85' y1='152.02' x2='298.85' y2='622.51' />
                  <line x1='318.39' y1='151.8' x2='318.39' y2='622.3' />
                  <line x1='357.17' y1='152.02' x2='357.17' y2='622.51' />
                  <line x1='337.94' y1='152.02' x2='337.94' y2='622.51' />
                  <line x1='376.4' y1='152.02' x2='376.4' y2='622.51' />
                  <line x1='395.95' y1='151.8' x2='395.95' y2='622.3' />
                  <line x1='434.72' y1='152.02' x2='434.72' y2='622.51' />
                  <line x1='415.49' y1='152.02' x2='415.49' y2='622.51' />
                  <line x1='453.95' y1='152.02' x2='453.95' y2='622.51' />
                  <line x1='473.5' y1='151.8' x2='473.5' y2='622.3' />
                  <line x1='512.28' y1='152.02' x2='512.28' y2='622.51' />
                  <line x1='493.05' y1='152.02' x2='493.05' y2='622.51' />
                  <line x1='531.5' y1='152.02' x2='531.5' y2='622.51' />
                  <line x1='551.05' y1='151.8' x2='551.05' y2='622.3' />
                  <line x1='589.84' y1='152.02' x2='589.84' y2='622.51' />
                  <line x1='570.61' y1='152.02' x2='570.61' y2='622.51' />
                  <line x1='609.07' y1='152.02' x2='609.07' y2='622.51' />
                  <line x1='628.62' y1='151.8' x2='628.62' y2='622.3' />
                  <line x1='648.17' y1='152.02' x2='648.17' y2='622.51' />
                  <line x1='85.5' y1='602.69' x2='686.92' y2='602.69' />
                  <line x1='85.5' y1='583.27' x2='686.92' y2='583.27' />
                  <line x1='85.23' y1='563.53' x2='686.65' y2='563.53' />
                  <line x1='85.5' y1='524.38' x2='686.92' y2='524.38' />
                  <line x1='85.5' y1='543.79' x2='686.92' y2='543.79' />
                  <line x1='85.5' y1='504.96' x2='686.92' y2='504.96' />
                  <line x1='85.23' y1='485.22' x2='686.65' y2='485.22' />
                  <line x1='85.5' y1='446.05' x2='686.92' y2='446.05' />
                  <line x1='85.5' y1='465.47' x2='686.92' y2='465.47' />
                  <line x1='85.5' y1='426.63' x2='686.92' y2='426.63' />
                  <line x1='85.23' y1='406.9' x2='686.65' y2='406.9' />
                  <line x1='85.5' y1='367.74' x2='686.92' y2='367.74' />
                  <line x1='85.5' y1='387.16' x2='686.92' y2='387.16' />
                  <line x1='85.5' y1='348.32' x2='686.92' y2='348.32' />
                  <line x1='85.23' y1='328.59' x2='686.65' y2='328.59' />
                  <line x1='85.5' y1='289.43' x2='686.92' y2='289.43' />
                  <line x1='85.5' y1='308.85' x2='686.92' y2='308.85' />
                  <line x1='85.5' y1='270.01' x2='686.92' y2='270.01' />
                  <line x1='85.23' y1='250.28' x2='686.65' y2='250.28' />
                  <line x1='85.5' y1='211.12' x2='686.92' y2='211.12' />
                  <line x1='85.5' y1='230.54' x2='686.92' y2='230.54' />
                  <line x1='85.5' y1='191.7' x2='686.92' y2='191.7' />
                  <line x1='85.23' y1='171.97' x2='686.65' y2='171.97' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m677.71,164.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,182.6c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,200.04c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,241.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,222.68c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,258.67c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,299.87c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,281.31c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,317.31c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,358.5c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,339.94c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,375.94c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,417.13c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,398.58c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,434.57c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,475.77c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,457.21c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,493.21c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,534.4c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,515.84c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,551.84c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,593.03c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.71,574.48c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,610.47c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m677.71,203.09c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,220.01c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,164.05c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,180.97c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,242.14c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,259.06c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,281.18c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,298.11c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,320.23c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,337.15c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,359.27c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,376.2c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,398.32c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,415.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,437.37c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,454.29c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,476.41c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,493.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,515.46c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,532.38c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,554.5c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,571.43c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.71,593.55c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m676.86,610.47c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M96,164.05a1.5,1.5,0,1,0-1.49-1.5,1.49,1.49,0,0,0,1.49,1.5' />
                                    <path d='M96,182.6a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,182.6' />
                                    <path d='M96.85,200a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,96.85,200' />
                                    <path d='M96,241.23a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,241.23' />
                                    <path d='M96,222.68a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,222.68' />
                                    <path d='M96.85,258.67a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83A12.19,12.19,0,0,1,99.35,257l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,299.87a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,299.87' />
                                    <path d='M96,281.31a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,281.31' />
                                    <path d='M96.85,317.31a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,358.5A1.49,1.49,0,1,0,94.51,357,1.49,1.49,0,0,0,96,358.5' />
                                    <path d='M96,339.94a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,339.94' />
                                    <path d='M96.85,375.94a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,417.13a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,417.13' />
                                    <path d='M96,398.58a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,398.58' />
                                    <path d='M96.85,434.57a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,475.77a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,475.77' />
                                    <path d='M96,457.21a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,457.21' />
                                    <path d='M96.85,493.21a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,534.4a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,534.4' />
                                    <path d='M96,515.84a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,515.84' />
                                    <path d='M96.85,551.84a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,593a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,593' />
                                    <path d='M96,574.48A1.49,1.49,0,1,0,94.51,573,1.49,1.49,0,0,0,96,574.48' />
                                    <path d='M96.85,610.47a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M96,203.09a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,203.09' />
                                    <path d='M96.85,220a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.25,11.25,0,0,0,96.85,220' />
                                    <path d='M96,164.05a1.5,1.5,0,1,0-1.49-1.5,1.49,1.49,0,0,0,1.49,1.5' />
                                    <path d='M96.85,181a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,96.85,181' />
                                    <path d='M96,242.14a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,242.14' />
                                    <path d='M96.85,259.06a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,281.18a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,281.18' />
                                    <path d='M96.85,298.11a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,320.23a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,320.23' />
                                    <path d='M96.85,337.15a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,359.27a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,359.27' />
                                    <path d='M96.85,376.2a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,398.32a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,398.32' />
                                    <path d='M96.85,415.24a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,437.37a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,437.37' />
                                    <path d='M96.85,454.29a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,476.41a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,476.41' />
                                    <path d='M96.85,493.34a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M96,515.46A1.49,1.49,0,1,0,94.51,514,1.49,1.49,0,0,0,96,515.46' />
                                    <path d='M96.85,532.38a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22A15.54,15.54,0,0,1,97.2,533a12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,554.5A1.49,1.49,0,1,0,94.51,553,1.48,1.48,0,0,0,96,554.5' />
                                    <path d='M96.85,571.43a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23A15.84,15.84,0,0,1,97.2,572a12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,593.55a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,593.55' />
                                    <path d='M96.85,610.47a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Cuadro 7mm Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Cuadro_7mm_Datos' data-name='Path Cuadro 7mm Datos'>
              <g id='Path_Cuadro_7mm_Datos-2' data-name='Path Cuadro 7mm Datos'>
                <g
                  id='Cuadricula_Datos'
                  data-name='Cuadricula Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 2 ? '#f5333f' : ink}`}
                  >
                    <line x1='105.27' y1='196.75' x2='105.27' y2='608.45' />
                  </g>
                  <g
                    stroke={`
                    ${ink !== 'black' && page === 1 ? '#f5333f' : ink}`}
                  >
                    <line x1='667.4' y1='196.75' x2='667.4' y2='608.45' />
                  </g>
                  <line x1='124.5' y1='196.75' x2='124.5' y2='608.45' />
                  <line x1='143.72' y1='196.75' x2='143.72' y2='608.45' />
                  <line x1='163.27' y1='196.56' x2='163.27' y2='608.27' />
                  <line x1='202.05' y1='196.75' x2='202.05' y2='608.45' />
                  <line x1='182.82' y1='196.75' x2='182.82' y2='608.45' />
                  <line x1='221.28' y1='196.75' x2='221.28' y2='608.45' />
                  <line x1='240.83' y1='196.56' x2='240.83' y2='608.27' />
                  <line x1='279.62' y1='196.75' x2='279.62' y2='608.45' />
                  <line x1='260.39' y1='196.75' x2='260.39' y2='608.45' />
                  <line x1='298.85' y1='196.75' x2='298.85' y2='608.45' />
                  <line x1='318.39' y1='196.56' x2='318.39' y2='608.27' />
                  <line x1='357.17' y1='196.75' x2='357.17' y2='608.45' />
                  <line x1='337.94' y1='196.75' x2='337.94' y2='608.45' />
                  <line x1='376.4' y1='196.75' x2='376.4' y2='608.45' />
                  <line x1='395.95' y1='196.56' x2='395.95' y2='608.27' />
                  <line x1='434.72' y1='196.75' x2='434.72' y2='608.45' />
                  <line x1='415.49' y1='196.75' x2='415.49' y2='608.45' />
                  <line x1='453.95' y1='196.75' x2='453.95' y2='608.45' />
                  <line x1='473.5' y1='196.56' x2='473.5' y2='608.27' />
                  <line x1='512.28' y1='196.75' x2='512.28' y2='608.45' />
                  <line x1='493.05' y1='196.75' x2='493.05' y2='608.45' />
                  <line x1='531.5' y1='196.75' x2='531.5' y2='608.45' />
                  <line x1='551.05' y1='196.56' x2='551.05' y2='608.27' />
                  <line x1='589.84' y1='196.75' x2='589.84' y2='608.45' />
                  <line x1='570.61' y1='196.75' x2='570.61' y2='608.45' />
                  <line x1='609.07' y1='196.75' x2='609.07' y2='608.45' />
                  <line x1='628.62' y1='196.56' x2='628.62' y2='608.27' />
                  <line x1='648.17' y1='196.75' x2='648.17' y2='608.45' />
                  <line x1='85.5' y1='569.14' x2='686.92' y2='569.14' />
                  <line x1='85.5' y1='588.55' x2='686.92' y2='588.55' />
                  <line x1='85.5' y1='549.72' x2='686.92' y2='549.72' />
                  <line x1='85.23' y1='529.98' x2='686.65' y2='529.98' />
                  <line x1='85.5' y1='490.81' x2='686.92' y2='490.81' />
                  <line x1='85.5' y1='510.23' x2='686.92' y2='510.23' />
                  <line x1='85.5' y1='471.39' x2='686.92' y2='471.39' />
                  <line x1='85.23' y1='451.65' x2='686.65' y2='451.65' />
                  <line x1='85.5' y1='412.5' x2='686.92' y2='412.5' />
                  <line x1='85.5' y1='431.92' x2='686.92' y2='431.92' />
                  <line x1='85.5' y1='393.08' x2='686.92' y2='393.08' />
                  <line x1='85.23' y1='373.34' x2='686.65' y2='373.34' />
                  <line x1='85.5' y1='334.19' x2='686.92' y2='334.19' />
                  <line x1='85.5' y1='353.61' x2='686.92' y2='353.61' />
                  <line x1='85.5' y1='314.77' x2='686.92' y2='314.77' />
                  <line x1='85.23' y1='295.03' x2='686.65' y2='295.03' />
                  <line x1='85.5' y1='255.88' x2='686.92' y2='255.88' />
                  <line x1='85.5' y1='275.3' x2='686.92' y2='275.3' />
                  <line x1='85.5' y1='236.46' x2='686.92' y2='236.46' />
                  <line x1='85.23' y1='216.72' x2='686.65' y2='216.72' />
                </g>
                {(() => {
                  switch (page) {
                    case 1:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='m677.88,209.07c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,227.63c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,245.07c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,286.26c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,267.71c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,303.7c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,344.9c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,326.34c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,362.34c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,403.53c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,384.97c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,420.97c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,462.16c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,443.61c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,479.6c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,520.8c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,502.24c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,538.24c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,579.43c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.88,560.87c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,596.87c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='m677.88,248.12c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,265.04c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,209.07c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,226c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,287.17c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,304.09c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,326.21c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,343.14c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,365.26c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,382.18c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,404.3c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,421.23c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,443.35c-.82,0-1.49-.67-1.49-1.49,0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,460.27c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,482.39c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49c0,.82-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,499.32c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,521.44c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,538.36c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,560.49c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                    <path d='m677.03,577.41c.8.81,1.45,1.64,1.96,2.5l.14-.32c.28-.63.54-.94.77-.94.27,0,.57.17.89.51-.19.07-.37.2-.52.4-.15.2-.32.51-.5.93l-.13.29c-.13.32-.22.55-.26.7-.1-.09-.29-.24-.56-.43l-.33-.23c-.42-.88-1.02-1.82-1.81-2.83-.79-1.01-1.5-1.75-2.15-2.24l.16-.23c.76.43,1.54,1.05,2.34,1.86' />
                                    <path d='m677.88,599.53c-.82,0-1.49-.67-1.49-1.49s.67-1.49,1.49-1.49,1.49.67,1.49,1.49-.67,1.49-1.49,1.49' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )
                    case 2:
                      return (
                        <g
                          fill={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                          stroke={`${ink !== 'black' ? '#f5333f' : 'black'}`}
                        >
                          {(() => {
                            switch (puntoGuia) {
                              case 'PuntoPuntoPaloma':
                                return (
                                  <g id='Punto_guia' data-name='Punto guia'>
                                    <path d='M96,209.07a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,209.07' />
                                    <path d='M96,227.63a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,227.63' />
                                    <path d='M96.85,245.07a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,286.26a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,286.26' />
                                    <path d='M96,267.71a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,267.71' />
                                    <path d='M96.85,303.7a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,344.9a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,344.9' />
                                    <path d='M96,326.34a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,326.34' />
                                    <path d='M96.85,362.34a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,403.53A1.49,1.49,0,1,0,94.51,402,1.49,1.49,0,0,0,96,403.53' />
                                    <path d='M96,385a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,385' />
                                    <path d='M96.85,421a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22A10.93,10.93,0,0,0,96.85,421' />
                                    <path d='M96,462.16a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,462.16' />
                                    <path d='M96,443.61a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,443.61' />
                                    <path d='M96.85,479.6a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.34,1.34,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83A12.29,12.29,0,0,1,99.35,478l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,520.8a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,520.8' />
                                    <path d='M96,502.24a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,502.24' />
                                    <path d='M96.85,538.24a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M96,579.43a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,579.43' />
                                    <path d='M96,560.87a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,560.87' />
                                    <path d='M96.85,596.87a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.23,1.23,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                  </g>
                                )
                              case 'PuntoPaloma':
                                return (
                                  <g id='Punto_guia-2' data-name='Punto guia'>
                                    <path d='M96,248.12a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,248.12' />
                                    <path d='M96.85,265a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69a6.07,6.07,0,0,1,.57-.42l.33-.23a15.54,15.54,0,0,1,1.81-2.83,12.19,12.19,0,0,1,2.15-2.23l-.16-.23A10.93,10.93,0,0,0,96.85,265' />
                                    <path d='M96,209.07a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,209.07' />
                                    <path d='M96.85,226a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23A11.33,11.33,0,0,0,96.85,226' />
                                    <path d='M96,287.17a1.5,1.5,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,287.17' />
                                    <path d='M96.85,304.09a13,13,0,0,0-2,2.5l-.14-.32c-.28-.62-.54-.94-.77-.94a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,326.21a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,326.21' />
                                    <path d='M96.85,343.14a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.49,15.49,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11,11,0,0,0-2.34,1.87' />
                                    <path d='M96,365.26a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,365.26' />
                                    <path d='M96.85,382.18a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,404.3a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,404.3' />
                                    <path d='M96.85,421.23a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,5.15,5.15,0,0,1,.5.94l.13.29a7.3,7.3,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,443.35a1.49,1.49,0,1,0-1.49-1.49A1.49,1.49,0,0,0,96,443.35' />
                                    <path d='M96.85,460.27a13.33,13.33,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-.95-.77-.95a1.34,1.34,0,0,0-.89.52,1.2,1.2,0,0,1,.52.4,4.54,4.54,0,0,1,.5.94l.13.29c.13.32.21.55.25.69.1-.09.29-.23.57-.43l.33-.22a15.54,15.54,0,0,1,1.81-2.83,12.63,12.63,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,482.39a1.49,1.49,0,1,0-1.49-1.49A1.48,1.48,0,0,0,96,482.39' />
                                    <path d='M96.85,499.32a13,13,0,0,0-2,2.5l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.16,1.16,0,0,1,.52.41,4.69,4.69,0,0,1,.5.93l.13.29c.13.33.21.56.25.7a5.68,5.68,0,0,1,.57-.43l.33-.23a15.84,15.84,0,0,1,1.81-2.82,12,12,0,0,1,2.15-2.24l-.16-.23a11.33,11.33,0,0,0-2.34,1.87' />
                                    <path d='M96,521.44A1.49,1.49,0,1,0,94.51,520,1.49,1.49,0,0,0,96,521.44' />
                                    <path d='M96.85,538.36a13.07,13.07,0,0,0-2,2.51l-.14-.32c-.28-.63-.54-.94-.77-.94a1.32,1.32,0,0,0-.89.51,1.2,1.2,0,0,1,.52.4,4.83,4.83,0,0,1,.5.94l.13.29a6.44,6.44,0,0,1,.25.7,4.46,4.46,0,0,1,.57-.43l.33-.23A15.49,15.49,0,0,1,97.2,539a12,12,0,0,1,2.15-2.24l-.16-.23a11.25,11.25,0,0,0-2.34,1.86' />
                                    <path d='M96,560.49A1.49,1.49,0,1,0,94.51,559,1.49,1.49,0,0,0,96,560.49' />
                                    <path d='M96.85,577.41a13,13,0,0,0-2,2.5l-.14-.31c-.28-.63-.54-1-.77-1a1.3,1.3,0,0,0-.89.52,1.13,1.13,0,0,1,.52.4,4.69,4.69,0,0,1,.5.93l.13.3c.13.32.21.55.25.69a5.68,5.68,0,0,1,.57-.43l.33-.22A15.54,15.54,0,0,1,97.2,578a12.29,12.29,0,0,1,2.15-2.24l-.16-.22a10.93,10.93,0,0,0-2.34,1.86' />
                                    <path d='M96,599.53A1.49,1.49,0,1,0,94.51,598,1.49,1.49,0,0,0,96,599.53' />
                                  </g>
                                )

                              default:
                                return <></>
                            }
                          })()}
                        </g>
                      )

                    default:
                      return <></>
                  }
                })()}
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Cuadro 7mm Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_7mm_Completo'
                data-name='Margenes Cuadro 7mm Completo'
              >
                <g
                  id='Margenes_Cuadro_7mm_Completo-2'
                  data-name='Margenes Cuadro 7mm Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                        rx='13.58'
                      />
                      <path d='M672.84,152.56a13.1,13.1,0,0,1,13.08,13.08V608.43a13.1,13.1,0,0,1-13.08,13.08H99.31a13.1,13.1,0,0,1-13.08-13.08V165.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V608.43a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V165.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='152.06'
                        width='600.69'
                        height='469.95'
                      />
                      <path d='M685.92,152.56v469H86.23V152.56H685.92m1-1H85.23v471H686.92V151.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            <title>Margenes Cuadro 7mm Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Cuadro_7mm_Datos'
                data-name='Margenes Cuadro 7mm Datos'
              >
                <g
                  id='Margenes_Cuadro_7mm_Datos-2'
                  data-name='Margenes Cuadro 7mm Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.89'
                        rx='13.58'
                      />
                      <path d='M672.84,197.56a13.1,13.1,0,0,1,13.08,13.08V594.37a13.09,13.09,0,0,1-13.08,13.08H99.31a13.09,13.09,0,0,1-13.08-13.08V210.64a13.1,13.1,0,0,1,13.08-13.08H672.84m0-1H99.31a14.08,14.08,0,0,0-14.08,14.08V594.37a14.08,14.08,0,0,0,14.08,14.08H672.84a14.08,14.08,0,0,0,14.08-14.08V210.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <rect
                        x='85.73'
                        y='197.06'
                        width='600.69'
                        height='410.89'
                      />
                      <path d='M685.92,197.56V607.45H86.23V197.56H685.92m1-1H85.23V608.45H686.92V196.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathSquare7mmHorizontal
