import React from 'react'

const ChalecoCapitonadoSvg= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_chaleco" data-name="Outline chaleco"   d="m577.82,695.87c-3.82-217.63,1.57-341.43,1.57-341.43-22.75-11.92-24.93-162.67-11.73-202.58l-94.88-30.95s-54.58,17.29-70.66,17.29l-74.47-17.29-95.3,30.95c12.65,34.24,10.49,197.17-11.74,202.58,0,0,7.64,175.25,3.29,340.83-.55,20.89,73.22,23.01,176.15,23.01s155.95-14.83,177.78-22.41"/>
      <path id="Linea_cintura" data-name="Linea cintura"   d="m224.28,678.92c19.56,7.36,71.4,22.35,174.34,22.35s155.95-14.83,177.78-22.41"/>
      <g id="Cuello">
        <path id="Cuello-2" data-name="Cuello"   d="m334.98,85.6s34.21-3.92,66.63-3.92,65.71,3.92,65.71,3.92c0,0-6.54,39.79-65.71,39.79s-66.63-39.79-66.63-39.79Z"/>
        <path id="Cuello-3" data-name="Cuello"   d="m401.27,165.86c45.19,0,70.24-29.97,75.02-38.12,0,0,1.11-3.41-1.4-12.13-2.52-8.72-7.61-26.53-6.44-30.7,0,0-15.22,34.45-65.16,34.87-.17,0-.85,8.29-1.55,8.29-.78,0-1.58-8.29-1.77-8.29-50.76,0-65.88-34.87-65.88-34.87,1.18,4.17-3.91,21.98-6.43,30.7-2.51,8.72-1.4,12.13-1.4,12.13,4.78,8.15,29.83,38.12,75.02,38.12"/>
        <path   d="m326.25,122.99c4.78,8.15,29.83,40.5,75.02,40.5s70.24-32.35,75.02-40.5"/>
      </g>
      <g id="Cierre">
        <path   d="m407.22,133.08c.02-2.06-2.13-3.76-4.8-3.77-2.67-.03-4.84,1.61-4.86,3.68,0,1.1.6,2.09,1.57,2.78h-.09l-.07,6.65,6.65.07.07-6.64h-.11c.99-.68,1.63-1.66,1.65-2.76"/>
        <path   d="m383.62,149.21s13.13,2.17,37.01,0"/>
        <line id="Linea_cierre_izquierda" data-name="Linea cierre izquierda"   x1="395.31" y1="120.45" x2="395.31" y2="719"/>
        <line id="Linea_cierre_derecha" data-name="Linea cierre derecha"   x1="408.22" y1="120.45" x2="408.22" y2="719"/>
        <line id="Linea_cierre_centro" data-name="Linea cierre centro"   x1="401.27" y1="128.07" x2="401.27" y2="719"/>
        <path   d="m407.22,133.08c.02-2.06-2.13-3.76-4.8-3.77-2.67-.03-4.84,1.61-4.86,3.68,0,1.1.6,2.09,1.57,2.78h-.09l-.07,6.65,6.65.07.07-6.64h-.11c.99-.68,1.63-1.66,1.65-2.76Z"/>
        <path   d="m407.1,132.76c.02-2.06-2.13-3.75-4.79-3.77-2.67-.03-4.85,1.62-4.87,3.67-.02,1.1.6,2.09,1.58,2.79h-.11s-.06,6.66-.06,6.66l6.66.06.07-6.66h-.11c.99-.67,1.62-1.65,1.63-2.75"/>
        <path   d="m407.1,132.76c.02-2.06-2.13-3.75-4.79-3.77-2.67-.03-4.85,1.62-4.87,3.67-.02,1.1.6,2.09,1.58,2.79h-.11s-.06,6.66-.06,6.66l6.66.06.07-6.66h-.11c.99-.67,1.62-1.65,1.63-2.75Z"/>
        <polygon   points="400.67 133.96 404.33 133.99 404.21 146.24 400.56 146.21 400.67 133.96"/>
        <polygon   points="400.47 133.74 404.12 133.78 407.76 153.58 396.97 153.52 400.47 133.74"/>
        <polygon   points="401.55 131.48 402.76 131.49 402.72 135.38 401.51 135.37 401.55 131.48"/>
        <polygon   points="401.55 131.48 402.76 131.49 402.72 135.38 401.51 135.37 401.55 131.48"/>
        <path   d="m399.21,150.93c0-.38.89-3.37,1.95-3.35l2.75.02c1.08,0,1.95,3.01,1.95,3.39,0,.39-.88.69-1.95.68l-2.75-.03c-1.08,0-1.96-.32-1.95-.71"/>
        <polyline   points="401.23 134.76 401.23 135.56 402.9 135.58 402.91 134.77"/>
      </g>
      <g id="Cierre_derecha" data-name="Cierre derecha">
        <path   d="m540.54,532.15c0-1.34-1.4-2.44-3.16-2.46-1.76-.01-3.19,1.06-3.21,2.39,0,.72.4,1.36,1.04,1.81h-.07l-.04,4.33,4.38.04.04-4.33h-.06c.64-.44,1.06-1.07,1.08-1.78Z"/>
        <path   d="m537.39,533.03s-6.42,8.16,0,18.62c0,0,4.82-11.25,0-18.62Z"/>
        <path id="Linea_lado_derecho" data-name="Linea lado derecho"   d="m536.35,687.96c-1.05-64.96-2.7-307.95,30.14-359.02"/>
      </g>
      <g id="Cierre_izquierda" data-name="Cierre izquierda">
        <path   d="m264.6,532.15c.01-1.34-1.4-2.44-3.15-2.46-1.76-.01-3.2,1.06-3.21,2.39,0,.72.41,1.36,1.05,1.81h-.07l-.04,4.33,4.38.04.04-4.33h-.07c.65-.44,1.06-1.07,1.07-1.78Z"/>
        <path   d="m261.45,533.03s-6.42,8.16,0,18.62c0,0,4.82-11.25,0-18.62Z"/>
        <path id="Linea_lado_izquierdo" data-name="Linea lado izquierdo"   d="m261.71,689.2c1.05-63.05,4.22-308.86-28.84-360.25"/>
      </g>
      <g id="Costuras">
        <path   d="m224.2,682.79c19.56,7.36,71.49,22.38,174.42,22.38s155.95-14.83,177.78-22.41"/>
        <path   d="m225.06,627.53c19.56,7.36,70.63,19.11,173.56,19.11s155.95-10.92,177.78-18.5"/>
        <path   d="m224.78,571.39c19.56,7.36,70.9,19.11,173.84,19.11s155.95-10.92,177.78-18.51"/>
        <path   d="m224.78,516.09c19.56,7.36,70.9,18.28,173.84,18.28s155.95-10.93,177.78-18.51"/>
        <path   d="m222.47,459.12c19.56,7.36,73.22,19.11,176.15,19.11s155.95-10.92,177.78-18.5"/>
        <path   d="m238.81,185.53c28.82,6.07,86.61,12.02,159.81,12.02s129.91-5.55,159.91-11.49"/>
        <path   d="m284.1,135.24c28.24,3.55,65.88,6.17,114.53,6.17,25.47,0,48.06-.7,67.93-1.88,17.89-1.06,33.58-2.49,47.22-4.14"/>
        <line   x1="389.46" y1="120.19" x2="389.46" y2="719"/>
        <line   x1="414.61" y1="120.19" x2="414.61" y2="719"/>
        <path   d="m222.47,402.98c19.56,7.37,73.22,19.12,176.15,19.12s155.95-10.93,177.78-18.51"/>
        <path   d="m227.27,350.06c24.78,7.18,81.29,15.9,171.35,15.9s149.76-8.19,176.65-15.31"/>
        <path   d="m239.72,242.81c29.32,5.66,90.3,10.88,158.9,10.88s128.74-4.91,159.07-10.43"/>
        <path   d="m237.49,298.51c29.16,5.83,90.77,11.31,161.13,11.31s131.15-5.16,161.39-10.85"/>
      </g>
      <g id="Ubicación_escudo" data-name="Ubicación escudo">
        <circle   cx="486.37" cy="275.44" r="19.04"/>
        <circle   cx="304.37" cy="275.44" r="19.04"/>
      </g>
    </g>
  </g>
</svg>
)
}

export default ChalecoCapitonadoSvg