import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { settotalSells } from 'redux/reducers/sells'
import { RootState } from 'redux/store/store'
import { getSellsBySchool, getTotalSells } from 'services/sells/sells'
import { useGetInitialParams } from './useGetInitialParams'

interface TableDataI {
  alumnosTotal: number
  Grado: string
  IdGrado: number
  Total: number
  cantidadTotal: number
  id: number
  name: string
  male_population?: number
  female_population?: string
  id_magento: number
  endListBooks: boolean
  listBooksChange: boolean
  endQuotas: boolean
  listQuotasChange: boolean
}
interface StudentsDataI {
  SKU: number
  GrupoArticulo: string
  Grado: string
  Colegio: string
  Fecha: string
  Precio: number
  Total: string
  Alumno: string
  Editorial: string
  Cantidad: number
  Factura: number
  Id_Colegio: number
}

interface StudentCompactInfoI {
  id?: string
  itemspurchased?: StudentsDataI[]
  quantity?: string
  total?: number
  grade?: string
  name?: string
}

export default function useTableSells() {
  const { LevelsAndGrades, apliedFilters, loadingFilter, totalSells } =
    useSelector((state: RootState) => state.sells)
  const [TableData, setTableData] = useState<TableDataI[]>([])
  const { state } = useLocation()
  const dispatch = useDispatch()
  const [loading, setloading] = useState(true)
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const [studentsTable, setStudentsTable] = useState<StudentsDataI[]>([])
  const [studentSellsDetail, setstudentSellsDetail] = useState<StudentsDataI[]>(
    []
  )
  const [openModal, setopenModal] = useState(false)

  const { schoolIDGea } = useGetInitialParams()

  const parsedTotalSells = (total: any) => {
    const newTableData: TableDataI[] = []
    for (let x = 0; x < total.data.data.length; x++) {
      let newDataForGrade = { ...total.data.data[x] }
      for (let y = 0; y < LevelsAndGrades.length; y++) {
        const gradeOnLevel = LevelsAndGrades[y].grades
        const gradeExits = gradeOnLevel.filter(
          (data: any) => data.name === newDataForGrade.Grado
        )

        const male = gradeExits[0]?.male_population
        const female = gradeExits[0]?.female_population
        let sul_population = 0
        if (male !== null) sul_population += male
        if (female !== null) sul_population += female

        if (gradeExits.length > 0) {
          newDataForGrade = {
            ...newDataForGrade,
            ...gradeExits[0],
            level: LevelsAndGrades[y],
            sul_population
          }
          break
        }
      }

      newTableData.push(newDataForGrade)
    }
    return newTableData
  }

  const handleGetInitialData = async () => {
    !loading && setloading(true)
    totalSells.length > 0 && dispatch(settotalSells([]))
    try {
      const total: any = await getTotalSells({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      const newTableData = parsedTotalSells(total)
      dispatch(settotalSells(total.data.data))
      setTableData(newTableData)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const parsedAllStudentsToObject = (AllStudents: StudentsDataI[]) => {
    const students: any = {}
    AllStudents.forEach((item: StudentsDataI) => {
      const object_key = item.Alumno.replace(/ /g, '_')
      if (!students[object_key]) {
        students[object_key] = {
          id: object_key,
          itemspurchased: [item],
          quantity: item.Cantidad,
          total: parseFloat(item.Total),
          grade: item.Grado,
          name: item.Alumno
        }
      } else {
        const itemsArray = [...students[object_key].itemspurchased]
        itemsArray.push(item)
        students[object_key].itemspurchased = itemsArray
        students[object_key].quantity =
          students[object_key].quantity + item.Cantidad
        const suma: number = parseFloat(students[object_key].total) + parseFloat(item.Total)
        students[object_key].total = suma.toFixed(2)
      }
    })
    return students
  }

  const parsedSchoolData = async () => {
    !loading && setloading(true)
    totalSells.length > 0 && dispatch(settotalSells([]))
    try {
      const total: any = await getSellsBySchool({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      const totalSells: any = await getTotalSells({
        ...apliedFilters,
        idcolegio: schoolIDGea
      })
      dispatch(settotalSells(totalSells.data.data))
      const AllStudents = total.data.data
      const objectStudents = parsedAllStudentsToObject(AllStudents)
      const studentsArray = Object.entries(objectStudents).map(
        ([, res]: any) => {
          return res
        }
      )
      setStudentsTable(studentsArray)

      /*  const newTableData: TableDataI[] = []
      for (let x = 0; x < total.data.data.length; x++) {
        let newDataForGrade = { ...total.data.data[x] }
        for (let y = 0; y < LevelsAndGrades.length; y++) {
          const gradeOnLevel = LevelsAndGrades[y].grades
          const gradeExits = gradeOnLevel.filter(
            (data: any) => data.name === newDataForGrade.Grado
          )

          if (gradeExits.length > 0) {
            newDataForGrade = {
              ...newDataForGrade,
              ...gradeExits[0],
              level: LevelsAndGrades[y]
            }
            break
          }
        }
        newTableData.push(newDataForGrade)
      }
      dispatch(settotalSells(total.data.data))
      setTableData(newTableData) */
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const handleRowClick = (record: any) => {
    setstudentSellsDetail(record.itemspurchased)
    setopenModal(true)
  }
  const handleCloseModal = () => {
    setopenModal(false)
  }
  useEffect(() => {
    if (LevelsAndGrades.length === 0 || loadingFilter) return
    if (apliedFilters.grado || apliedFilters.seccion) {
      parsedSchoolData()
      return
    }
    handleGetInitialData()
  }, [LevelsAndGrades, apliedFilters, loadingFilter])

  return {
    TableData,
    loading,
    studentsTable,
    openModal,
    handleRowClick,
    handleCloseModal,
    studentSellsDetail
  }
}
