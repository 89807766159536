/* eslint-disable react-hooks/exhaustive-deps */
import { Col, List } from 'antd'
import EmptyListMessage from 'components/EmptyListMessage/EmptyListMessage'
import Tag from 'components/tag'
import B from 'components/texts/B/B'
import { setStatus, useBooks } from 'hooks/books/useBooks'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getSchoolBooks } from 'services/books/books'

const bookTitle = [
  {
    1: 'Nombre del libro',
    2: 'Edición',
    3: 'Editorial',
    4: 'ISBN',
    5: 'Precio',
    6: 'Estatus'
  }
]

const bookDummy = [
  {
    name: 'Nombre de Libro',
    edition: 'EDICION',
    editorial: 'EDITORIAL',
    isbn: 'ISBN',
    price: 'PRECIO'
  }
]

const BooksModule = memo(() => {
  const location = useLocation()
  const [bookList, setbookList] = useState([])

  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()

  const { onHandleEditData } = useBooks()
  const getBooksByLevel = async () => {
    
    try {
      const result = await getSchoolBooks(
        uid?.id_school as number,
        location.state.level.id,
        location.state.id
      )
      setbookList(result.data.books)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBooksByLevel()
  }, [])

  const colStyle = { cursor: 'pointer' }
  return (
    <div>
      {bookDummy.length === 0 ? (
        <EmptyListMessage
          icon='book'
          iconWidth={34}
          iconHeight={40}
          text='Aún no has asignado libros a esta lista'
        />
      ) : (
        <>
          <List
            itemLayout='horizontal'
            dataSource={bookTitle}
            renderItem={(title: any) => (
              <List.Item style={{ padding: ' 10px 0' }}>
                {Object.keys(title).map((res, key) => {
                  return (
                    <Col
                      key={key}
                      className='ListTitles'
                      style={colStyle}
                      span={4}
                    >
                      <B size='Light'>{title[res]}</B>
                    </Col>
                  )
                })}
              </List.Item>
            )}
          />
          <List
            itemLayout='horizontal'
            dataSource={bookList}
            renderItem={(book: any) => (
              <List.Item
                style={{ padding: ' 10px 0' }}
                onClick={() => {
                  onHandleEditData(`/libros/editar`, book)
                }}
              >
                <Col style={colStyle} span={4}>
                  <B size='Light'>{book.name}</B>
                </Col>
                <Col style={colStyle} span={4}>
                  {' '}
                  <B size='Light'>{book.edition}</B>
                </Col>
                <Col style={colStyle} span={4}>
                  {' '}
                  <B size='Light'>{book.editorial}</B>
                </Col>
                <Col span={4}>
                  {' '}
                  <B size='Light'>{book.isbn}</B>
                </Col>
                <Col span={4}>
                  {' '}
                  <B size='Light'>{book.price}</B>
                </Col>
                <Col span={4}>
                  {' '}
                  <B size='Light'>
                    <Tag
                      variation={book.status}
                      title={setStatus(book.status)}
                    />
                    {}
                  </B>
                </Col>
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  )
})
BooksModule.displayName = 'BooksModule'
export default BooksModule
