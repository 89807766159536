import Button from 'components/buttons/Button/Button'
import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setJsonImgBack,
  setJsonImgFront,
  setJsonTextBack,
  setJsonTextfront
} from 'redux/reducers/notebooksSlice'
import { RootState } from 'redux/store/store'
import style from './layers.module.scss'
import OptionFloating from './OptionFloating'

const FloatingLayers = () => {
  const { jsonImgBack, jsonImgfront, jsonTextBack, jsonTextfront } =
    useSelector((state: RootState) => state.notebooks)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(0)

  const leftImghideFn = (dat: boolean, num: number) => {
    let me = jsonImgBack
    me = { ...me, [num]: { ...me[num], show: dat } }
    dispatch(setJsonImgBack(me))
  }
  const rightImghideFn = (dat: boolean, num: number) => {
    let me = jsonImgfront
    me = { ...me, [num]: { ...me[num], show: dat } }
    dispatch(setJsonImgFront(me))
  }
  const leftTexthideFn = (dat: boolean, num: number) => {
    let me = jsonTextBack
    me = { ...me, [num]: { ...me[num], show: dat } }
    dispatch(setJsonTextBack(me))
  }
  const rightTexthideFn = (dat: boolean, num: number) => {
    let me = jsonTextfront
    me = { ...me, [num]: { ...me[num], show: dat } }
    dispatch(setJsonTextfront(me))
  }

  const setNumber = (dat: number) => {
    if (dat === selected) setSelected(0)
    else setSelected(dat)
  }
  return (
    <div className={`${style['div-container']}`}>
      <div className={`${style['buttons-layers']}`}>
        {Object.keys(jsonImgBack).length !== 0 && (
          <Button
            sector={selected === 1 ? 'secondary' : 'primary'}
            background={selected === 1 ? 'white' : 'color'}
            rounded
            onClick={() => setNumber(1)}
            label='Back Img'
          />
        )}
        {Object.keys(jsonTextBack).length !== 0 && (
          <Button
            sector={selected === 2 ? 'secondary' : 'primary'}
            background={selected === 2 ? 'white' : 'color'}
            rounded
            onClick={() => setNumber(2)}
            label='Back text'
          />
        )}
        {Object.keys(jsonImgfront).length !== 0 && (
          <Button
            sector={selected === 3 ? 'secondary' : 'primary'}
            background={selected === 3 ? 'white' : 'color'}
            rounded
            onClick={() => setNumber(3)}
            label='Front img'
          />
        )}
        {Object.keys(jsonTextfront).length !== 0 && (
          <Button
            sector={selected === 4 ? 'secondary' : 'primary'}
            background={selected === 4 ? 'white' : 'color'}
            rounded
            onClick={() => setNumber(4)}
            label='Front text'
          />
        )}
        {selected !== 0 && (
          <Button
            sector={'tertiary'}
            background={'white'}
            rounded
            onClick={() => setSelected(0)}
            label='Esconder'
            customStyle={style['padding']}
          />
        )}
      </div>
      {(() => {
        switch (selected) {
          case 1:
            return (
              <div className={`${style['pestana']}`}>
                {Object.keys(jsonImgBack).length !== 0 &&
                  Object.keys(jsonImgBack).map((item: any, index: number) => {
                    const objKey = item as keyof typeof jsonImgBack
                    const objInput = jsonImgBack[objKey]
                    return (
                      <Fragment key={index}>
                        <OptionFloating
                          id={objInput.id}
                          showMe={objInput.show as boolean}
                          img={objInput.img as string}
                          clickMe={(dat: boolean, num: number) =>
                            leftImghideFn(dat, num)
                          }
                        />
                      </Fragment>
                    )
                  })}
              </div>
            )
          case 2:
            return (
              <div className={`${style['pestana']}`}>
                {Object.keys(jsonTextBack).length !== 0 &&
                  Object.keys(jsonTextBack).map((item: any, index: number) => {
                    const objKey = item as keyof typeof jsonTextBack
                    const objInput = jsonTextBack[objKey]
                    return (
                      <Fragment key={index}>
                        <OptionFloating
                          id={objInput.id}
                          showMe={objInput.show as boolean}
                          text={objInput.text.label}
                          clickMe={(dat: boolean, num: number) =>
                            leftTexthideFn(dat, num)
                          }
                        />
                      </Fragment>
                    )
                  })}
              </div>
            )
          case 3:
            return (
              <div className={`${style['pestana']}`}>
                {Object.keys(jsonImgfront).length !== 0 &&
                  Object.keys(jsonImgfront).map((item: any, index: number) => {
                    const objKey = item as keyof typeof jsonImgfront
                    const objInput = jsonImgfront[objKey]
                    return (
                      <Fragment key={index}>
                        <OptionFloating
                          id={objInput.id}
                          showMe={objInput.show as boolean}
                          img={objInput.img as string}
                          clickMe={(dat: boolean, num: number) =>
                            rightImghideFn(dat, num)
                          }
                        />
                      </Fragment>
                    )
                  })}
              </div>
            )
          case 4:
            return (
              <div className={`${style['pestana']}`}>
                {Object.keys(jsonTextfront).length !== 0 &&
                  Object.keys(jsonTextfront).map((item: any, index: number) => {
                    const objKey = item as keyof typeof jsonTextfront
                    const objInput = jsonTextfront[objKey]
                    return (
                      <Fragment key={index}>
                        <OptionFloating
                          id={objInput.id}
                          showMe={objInput.show as boolean}
                          text={objInput.text.label}
                          clickMe={(dat: boolean, num: number) =>
                            rightTexthideFn(dat, num)
                          }
                        />
                      </Fragment>
                    )
                  })}
              </div>
            )

          default:
            return <></>
        }
      })()}
    </div>
  )
}

export default FloatingLayers
