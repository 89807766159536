import React, { Fragment } from 'react'
import style from './poloeditable.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import PantalonTraseraSvg from 'assets/img/uniforms/pantalon/trasera/PantalonTraseraSvg'
import ShadowsPantalonTrasero from 'assets/img/uniforms/pantalon/trasera/ShadowsPantalonTrasero'

const PantalonTraseroEditable = () => {
  const { poloValues, pictureLogo, poloValuesFemale, female } = useSelector(
    (state: RootState) => state.uniforms
  )

  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div className={`${style[`logo-position-${''}`]}`}></div>
          <div className={`${style['div-polo-svg-absolute']}`}>
            <PantalonTraseraSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}>
            <ShadowsPantalonTrasero className={`${style['svg']}`} />
          </div>
        </Fragment>
      </div>
    </div>
  )
}

export default PantalonTraseroEditable
