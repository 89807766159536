import { UpdateUtilsStatusInterface } from 'services/utilsServices/utils'
import { AUTHSERVICE } from '../config'

interface ICreatePackageData {
  name: string
  description: string
  id: number
  quantity: number
}

const edithPackage = async (data: ICreatePackageData): Promise<any> => {
  return await AUTHSERVICE().put(`/notebook-package`, data)
}

const edithPackageNotebook = async (data: ICreatePackageData) => {
  const results = await edithPackage(data)
  return results
}

export const updateStatusNotebook =  async ({
  data
}: {
  data: UpdateUtilsStatusInterface
}): Promise<any> => {
  return await AUTHSERVICE().put(`/notebook/status`, data)
}

export { edithPackageNotebook }
