import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import { InputFormConfig } from 'types'
import { getLevels } from 'services/schoolUserServices/levels'
import {
  INPUTS_INITIAL_STATE_PACKAGES,
  setPackagesForm
} from 'redux/reducers/packages'
import { useDispatch } from 'react-redux'
import {
  createPackage,
  deletePackage,
  downloadUtilsList,
  getPackagesByLevel,
  updatePackage
} from 'services/utilsServices/utils'
import { handleErrors } from 'utilities/handleErrors'
import { useQuery } from 'hooks/useQuery'
import { useNavigate } from 'react-router-dom'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import filterlevelsByPermission from 'utilities/filterlevelsByPermission'

interface LevelInterface {
  name: string
  id?: number | string
}

interface GradeInterface {
  name: string
  male_population?: number
  female_population?: number
  id_level?: number | string
  id?: number | string
  packages?: any
}

export interface levelsFormI {
  name: InputFormConfig
}

interface tabInterface {
  key: any
  id: string
  label: string
  grades: any[]
}
export function usePackages() {
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  Object.freeze(INPUTS_INITIAL_STATE_PACKAGES)
  const dispatch = useDispatch()
  const query: any = useQuery()
  const navigate = useNavigate()
  const [LoadingList, setLoadingList] = useState(true)
  const [open, setOpen] = useState(false)
  const [loadingModal, setloadingModal] = useState(false)
  const [tabs, setTabs] = useState<tabInterface[]>([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedLevel, setSelectedLevel] = useState<any>(undefined)
  const [downloadList, setdownloadList] = useState(false)

  useEffect(() => {
    let unmounted = false
    const controller = new AbortController()
    if (!unmounted && tabs.length === 0) void getlevelList(controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (query.tab) setSelectedTab(parseInt(query.tab))
  }, [query])

  useEffect(() => {
    if (tabs.length > 0) {
      handleGetPackageListByLevel()
    }
  }, [selectedTab, tabs])

  const handleChangeLevelTab = (key: any) => {
    setSelectedTab(key)
    const path = {
      pathname: '.',
      search: `?tab=${key}&nivel=${tabs[key].id}`
    }
    navigate(path)
  }

  const handleGetPackageListByLevel = () => {
    void getPackagesListByLevel(tabs[selectedTab].id)
  }

  /* GETS */

  const getlevelList = async (controller?: AbortController) => {
    /* if (tabs.length > 0) return */
    try {
      const result:any = await getLevels({ controller })
      const filteredLevelsByPermision = filterlevelsByPermission(
        result.data.levels,
        uid.responsabilities,
        uid.role
      )
      const TabsByLevels: any = filteredLevelsByPermision.map(
        (res: any, key: number) => {
          return {
            key,
            id: res.id,
            label: res.name,
            grades: res.grades
          }
        }
      )
      const path = {
        pathname: '.',
        search: `?tab=${query.tab}&nivel=${TabsByLevels[query.tab].id}`
      }
      navigate(path)
      setTabs(TabsByLevels)
    } catch (err: any) {
      const Error = err?.response?.data?.message
      if (Error) ErrorToast(Error)
    }
  }

  const getPackagesListByLevel = async (id: any) => {
    try {
      const result = await getPackagesByLevel({ id })
      const levelsGradesPackages = reOrderPackagesOnGrades(
        tabs[selectedTab],
        result.data.details
      )
      setSelectedLevel(levelsGradesPackages)
    } catch (error) {
      console.log(error)
    }
  }

  const reOrderPackagesOnGrades = (selectedLevel: any, packages: any) => {
    let grades = selectedLevel.grades
    const gradesWithPackages = grades.map((res: any) => {
      const packagesForThisGrade = packages.filter(
        (grade: any) => grade.id_grade === res.id
      )
      return { ...res, packages: packagesForThisGrade }
    })
    return { ...selectedLevel, grades: gradesWithPackages }
  }

  /* FORM */

  const cleanDataToPost = (
    structuredForm: any,
    id_grade?: number | string
  ): any => {
    let goodData: any = {
      name: '',
      id_level: selectedLevel.id,
      id_grade,
      description: 'a'
    }
    Object.keys(structuredForm).forEach((element) => {
      const objKey = element as keyof typeof INPUTS_INITIAL_STATE_PACKAGES
      const objInput = structuredForm[objKey]
      goodData = { ...goodData, [objKey]: objInput?.value }
    })
    return goodData
  }

  const AfterSuccess = (successMessage: string) => {
    setloadingModal(false)
    dispatch(setPackagesForm({ packageForm: INPUTS_INITIAL_STATE_PACKAGES }))
    SuccessToast(successMessage)
    getlevelList()
  }

  const postNewPackage = async (
    gradeData: GradeInterface,
    handleCancel: () => void,
    packageForm: any
  ) => {
    setloadingModal(true)
    try {
      await createPackage(cleanDataToPost(packageForm, gradeData.id))
      handleCancel()
      void getPackagesListByLevel(tabs[selectedTab].id)
      AfterSuccess('El paquete fue creado correctamente')
    } catch (err: any) {
      handleErrors(err)
    }finally{
      setloadingModal(false)
    }
  }

  const requestChanges = async (id: any, handleCancel: () => void) => {
    const data = { id, request_changes: true, status_request: 'sent' }
    setloadingModal(true)
    try {
      await updatePackage(data)
      handleCancel()
      SuccessToast(
        'Se ha solicitado la modificación para este paquete, un administrador se encargará de verificar su pedido'
      )
      const button = document.getElementById('get-packages-again')
      button?.click()
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingModal(false)
    }
  }

  const updatePackageById = async (
    id: any,
    handleCancel: () => void,
    packageFormState: any
  ) => {
    const cleanData = cleanDataToPost(packageFormState)
    const data = { ...cleanData, id }
    setloadingModal(true)
    try {
      await updatePackage(data)
      handleCancel()
      AfterSuccess('El paquete fue editado correctamente')
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingModal(false)
    }
  }

  const deletePackageById = async (id: any, handleCancel: () => void) => {
    setloadingModal(true)
    try {
      await deletePackage(id)
      handleCancel()
      AfterSuccess('El paquete fue removido correctamente')
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadingModal(false)
    }
  }

  const handleDownloadUtilsList = async () => {
    setdownloadList(true)
    try {
      const res = await downloadUtilsList()
      window.open(res.data.url, '_blank')
    } catch (error) {
      handleErrors(error)
    } finally {
      setdownloadList(false)
    }
  }

  return {
    open,
    tabs,
    LoadingList,
    selectedTab,
    loadingModal,
    selectedLevel,
    downloadList,
    setOpen,
    requestChanges,
    postNewPackage,
    setloadingModal,
    deletePackageById,
    updatePackageById,
    handleChangeLevelTab,
    handleGetPackageListByLevel,
    handleDownloadUtilsList
  }
}
