import React from 'react'

const ShadowsHoodie= ({ className }: { className: string }) => {
return (
<svg
xmlns='http://www.w3.org/2000/svg'
fill='none'
viewBox='0 0 800 800'
stroke='black'
className={className}
>
<g id="Sombras">
    <g id="Sombras-2" data-name="Sombras">
      <path   d="m273.55,525s-.44-37.47,1.8-36.08c2.24,1.38,16.62,23.24,16.62,23.24,0,0,.57-5.9-.08-7.73-2.62-7.3-12.6-28.21-16.74-34.83-4.4-7.03-21.54-26.8-17.23-19.7,6.44,10.62,9.87,19.13,9.87,19.13l5.76,55.97Z"/>
      <path   d="m506.32,441.43c-2.52-4.86,7.28-14.72,9.47-19.72,4.37-9.95,6.13-42.75,6.13-42.75,0,0,13.58,48.01,7.11,62.47-.65,1.45-3.63,3.47-5.24,3.86-4.35,1.06-15.49-.05-17.47-3.86Z"/>
      <polygon   points="164.02 500.31 169.85 524.87 189.26 555.63 161.94 529.17 164.02 500.31"/>
      <path   d="m153.78,659.97c.26,4.11,3.03,15.01,6.05,17.22,4.65,3.44,23.08,15.59,19.28,11.31-3.77-4.24,9.75-4.89,12.73-5.02,5.86-.26-20.88-.35-26.12-3.46-4.67-2.77-12.27-25.27-11.94-20.06Z"/>
      <path   d="m594.53,399.93c-4.33,11.84-10.32,19.21-8.72,26.66,1.73,8.07,5.78,13.68,13.89,24.24,3.6,4.7-20.89-19.8-22.13-25.49-1.86-8.5,19.95-33.63,16.95-25.41Z"/>
      <path   d="m537.03,329.66s-6.15-36.28-11.54-47.44c-2.31-4.8-14.03-14.33-16.59-16.97-2.55-2.65-12.25-6.95-12.25-6.95,0,0,19.36,1.59,24.71,4.74,4.58,2.68,10.06,12.26,11.79,17.08,4.19,11.77,3.88,49.55,3.88,49.55Z"/>
      <path   d="m591.7,551.59c13.43-21.74,9.66-20.61,25.84-13.6,1.6.69,3.3-5.08,2.16-6.35-3.27-3.62-13.35-23.92-17.41-21.12-4.23,2.93-10.6,41.07-10.6,41.07Z"/>
      <polygon   points="600.41 671.99 637.55 675.16 621.25 677.42 598.98 678.33 600.41 671.99"/>
      <path   d="m301.47,276.07c6.57,5.65-10.07,32.41-10.07,32.41,0,0,12.38-27.75,6.42-29.23-9.59-2.38-34.63,35.66-34.63,35.66,0,0,13.79-25.8-.66-19.35-8.04,3.58,32.48-25.06,38.94-19.5Z"/>
      <path   d="m312.12,497.15l53.88-4.55,116.01-3.26c-39.99-2.57-86.95,3.35-109.74,7.24-3.01.51-60.15.57-60.15.57Z"/>
      <path   d="m446.06,552.75c-8.1,22.34-45.04,37.38-101.03,46.77-24.26,4.07-31.8,31.96-74.42,21.43-1.7-.42,44.16-4.42,43.62-5.79-5.78-14.6,132.4-43.35,129.91-65.54-.11-.94,2.29,2.13,1.93,3.12Z"/>
      <path   d="m462.24,651.6l57.79-15.64,11.01-19.99s-7.96,28.03-10.92,28.76c-2.96.72-57.88,6.87-57.88,6.87Z"/>
      <path   d="m230.65,459.23c-7.96,3.33-26.94-20.84-26.94-20.84,0,0,21.59,20.99,25.34,16.56,6.03-7.11-20.42-42.63-20.42-42.63,0,0,19.19,21.42,18.7,6.85-.27-8.09,11.14,36.78,3.32,40.06Z"/>
      <path   d="m185.29,640.63c5.51,5.4,17.25,6.53,18.07,23.74.11,2.47,1.38-14.18.28-16.41-2.45-4.97-22.39-11.28-18.35-7.33Z"/>
    </g>
  </g>
</svg>
)
}

export default ShadowsHoodie