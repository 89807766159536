import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts'
import { useEffect, useState } from 'react'
import {
  InitUtilsPagination,
  UtilsPagination
} from 'utilities/restructurePagination'
import { handleErrors } from 'utilities/handleErrors'
import {
  addUtilToPackageById,
  getUtilsPaginated,
  UtilsInterface
} from 'services/utilsServices/utils'
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { SelectProps } from 'antd'
import { SessionData, useDecodeToken } from 'hooks/decodeToken/useDecodeToken'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { AUTHSERVICE } from 'services/config'

export const ActionsMap = (action: string | null) => {
  if (action == 'nuevo') return 'Agregar nuevo útil'
  else if (action == 'editar') return 'Editar útil'
  return 'Sin definir'
}

export function useUtils() {
  const [utilsList, setUtilsList] = useState<UtilsInterface[]>([])
  const [allUtilsList, setAllUtilsList] = useState([])
  const [LoadingList, setLoadingList] = useState(true)
  const [pagination, setPagination] =
    useState<UtilsPagination>(InitUtilsPagination)
  const [OpenSearchModal, setOpenSearchModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openMassiveModal, setOpenMassiveModal] = useState(false)
  const [loadingModal, setloadingModal] = useState(false)
  const [saveAction, setSaveAction] = useState(false)
  const [actions, setActions] = useState<{ status: number }>({ status: 0 })
  const [options] = useState<SelectProps<object>['options']>([])
  const [params, setParams] = useSearchParams()
  const [itemToEdit, setItemToEdit] = useState<UtilsInterface | null>(null)
  const [itemToDelete, setItemToDelete] = useState<UtilsInterface | null>(null)
  const [deletingItem, setDeletingItem] = useState(false)
  const [downloadingCatalog, setDownloadingCatalog] = useState(false)
  const [loadNewUtil, setloadNewUtil] = useState(false)
  const { action } = useParams()
  const { dataToken } = useDecodeToken()
  const { uid }: SessionData = dataToken()
  const location = useLocation()
  const reactNavigate = useNavigate()

  useEffect(() => {
    const controller = new AbortController()
    getUtilsList(pagination.page, controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (params.has('action')) return

    const page = parseInt(params.get('page') || '1')
    const search = params.get('search') || ''

    const controller = new AbortController()
    getUtilsList(page, controller, search)
    return () => {
      controller.abort()
    }
  }, [params])

  useEffect(() => {
    if (itemToDelete) setOpenDeleteModal(true)
  }, [itemToDelete])

  const changePage = (e: any) => {
    setLoadingList(true)
    const search = params.get('search')
    getUtilsList(e.current, new AbortController(), search ? search : undefined)
  }

  const getUtilsList = async (
    page: number = 1,
    controller?: AbortController,
    search = params.get('search') || undefined
  ) => {
    setLoadingList(true)
    try {
      const result: any = await getUtilsPaginated(page || 1, search)
      setUtilsList(result?.data?.data?.supplies)
      const { total, page: currentPage, totalPages, limit } = result?.data?.data
      setPagination({
        page: currentPage,
        total,
        pages: totalPages,
        limit
      })
    } catch (err: any) {}

    setLoadingList(false)
  }

  const getAllUtilsList = async (page: number, search: string) => {
    try {
      const result = await getUtilsPaginated(page, search)
      setAllUtilsList(result.data.supply)
      setLoadingList(false)
    } catch (err: any) {
      setLoadingList(false)
      handleErrors(err)
    }
  }

  const handleOpenModal = () => {
    setOpenSearchModal(true)
    getAllUtilsList(1, '')
  }

  const handleCancelModal = () => {
    setOpenSearchModal(false)
  }

  const onHandleSearch = async (value: string): Promise<any> => {
    if (value.length !== 0) {
      getAllUtilsList(1, value)
    } else {
      getAllUtilsList(1, '')
    }
  }

  const addUtil = async (util: any) => {
    setloadNewUtil(true)
    const packageData = location.state
    const data = {
      id_supply_package: packageData.id,
      id_supply: util.id,
      supply_package_quantity: 1
    }
    try {
      await addUtilToPackageById(data)
      getUtilsList(pagination.page)
      SuccessToast('El Útil fue agregado a este paquete satisfactoriamente')
      handleCancelModal()
    } catch (err: any) {
      handleErrors(err)
    } finally {
      setloadNewUtil(false)
    }
  }

  const onHandleChangePage = (page: number): void => {
    const prev: any = {}
    for (let key of params.keys()) prev[key] = params.get(key)
    setParams({
      ...prev,
      page
    })
  }

  const handleDeleteItem = async () => {
    setDeletingItem(true)

    try {
      const res: any = await AUTHSERVICE().delete(`/supply/${itemToDelete?.id}`)
      setItemToDelete(null)
      setOpenDeleteModal(false)
      getUtilsList(1)
    } catch (error) {
      ErrorToast(
        'Ocurrió un error eliminando el elemento. Por favor inténtelo de nuevo más tarde.'
      )
    }

    setDeletingItem(false)
  }

  const handleDownloadCatalog = async () => {
    setDownloadingCatalog(true)

    try {
      let url = '/supply/download-all'

      const res: any = await AUTHSERVICE().get(url)
      const { url: fileUrl } = res?.data

      if (fileUrl) {
        window.open(fileUrl, '__blank')
      }
    } catch (error) {
      ErrorToast('Ocurrió un error. Inténtelo de nuevo más tarde.')
    }

    setDownloadingCatalog(false)
  }

  const utilsColumns: ColumnsType<UtilsInterface> = [
    {
      title: 'Imagen',
      dataIndex: 'picture',
      key: 'picture',
      render: (_, record: any) => (
        <img
          src={record?.picture}
          style={{
            minWidth: 60,
            maxWidth: 60,
            minHeight: 60,
            maxHeight: 60
          }}
        />
      )
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Marca',
      dataIndex: 'make',
      key: 'make'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Presentación',
      dataIndex: 'presentation',
      key: 'presentation'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price'
    },
    {
      ...(uid.role == 'Ezetera' && {
        title: '',
        key: 'actions',
        render: (_: any, record) => (
          <>
            <div style={{ display: 'flex' }}>
              <PencilSquareIcon
                onClick={async (e) => {
                  e.stopPropagation()
                  reactNavigate(`?action=editar`)
                  setItemToEdit(record)
                }}
                style={{
                  color: '#0F48FF',
                  cursor: 'pointer',
                  width: '1rem',
                  height: '1rem'
                }}
              />
              <TrashIcon
                onClick={async (e) => {
                  e.stopPropagation()
                  setItemToDelete(record)
                }}
                style={{
                  color: '#EF5D60',
                  cursor: 'pointer',
                  width: '1rem',
                  height: '1rem'
                }}
              />
            </div>
          </>
        )
      })
    }
  ]

  return {
    utilsList,
    pagination,
    LoadingList,
    loadingModal,
    OpenSearchModal,
    allUtilsList,
    action,
    actions,
    params,
    options,
    utilsColumns,
    openDeleteModal,
    deletingItem,
    itemToEdit,
    openMassiveModal,
    downloadingCatalog,
    loadNewUtil,
    changePage,
    onHandleSearch,
    onHandleChangePage,
    setOpenSearchModal,
    setSaveAction,
    setOpenDeleteModal,
    getUtilsList,
    setDeletingItem,
    handleDeleteItem,
    addUtil,
    handleCancelModal,
    handleOpenModal,
    setParams,
    setItemToEdit,
    setOpenMassiveModal,
    handleDownloadCatalog
  }
}
