import React, { Fragment, useEffect, useState } from 'react'
import Navbar from 'components/Navbar/Navbar'
import FixedInfoFooter from 'modules/uniforms/components/fixedInfoFooter/FixedInfoFooter'

import style from './notebook.module.scss'
import VerticalNotebookEditable from './components/notebookEditables/VerticalNotebookEditable'
import HorizontalNotebookEditable from './components/notebookEditables/HorizontalNotebookEditable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { allNotebooks, DisableFlag } from 'redux/reducers/notebooksSlice'
import NotebookOptionsInsideHidden from './components/notebooksOptions/NotebookOptionsInsideHidden'
import { useNavigate, useParams } from 'react-router-dom'
import useNotebooks from 'hooks/notebooks/useNotebooks'
import ShowSpin from '../notebooksList/components/showSpin/ShowSpin'

const NotebookEditorInsideModule = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [show, setshow] = useState(false)

  const { saveNotebookChange, saveSendDataNotebookChange, notebook } =
    useNotebooks({
      uuid: parseInt(uuid || '0')
    })

  const { notebookSelected } = useSelector(
    (state: RootState) => state.notebooks
  )

  const goBack = () => {
    let thisPath = window.location.pathname
    if (thisPath.includes('cuadernos')) {
      let path = thisPath.split('/')
      switch (path[2]) {
        case 'select-type':
          saveSendDataNotebookChange({ step: 0 }, false, () =>
            navigate(`/cuadernos`)
          )
          break
        case 'edith-front':
          saveSendDataNotebookChange({ step: 1 }, false, () =>
            navigate(`/cuadernos/select-type/${uuid}`)
          )
          break
        case 'edith-inside':
          saveSendDataNotebookChange({ step: 2 }, false, () =>
            navigate(`/cuadernos/edith-front/${uuid}`)
          )
          break
        case 'preview':
          saveSendDataNotebookChange({ step: 3 }, false, () =>
            navigate(`/cuadernos/edith-front/${uuid}`)
          )
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    dispatch(DisableFlag())
  }, [])

  useEffect(() => {
    if (notebook !== null) {
      if (`${notebook.status}` === '4' || `${notebook.status}` === '5') {
        navigate(`/cuadernos/preview/${uuid}`)
      } else {
        setshow(true)
      }
    }
  }, [notebook])

  return (
    <div className={`${style['body-div']}`}>
      {show ? (
        <Fragment>
          <Navbar complete onClick={goBack} />
          <FixedInfoFooter
            uuidNotebook={uuid}
            save={() => {
              saveNotebookChange()
            }}
            next={(step?: number, fn?: () => void) => {
              saveNotebookChange()
              saveSendDataNotebookChange(
                { step: step || 0 },
                false,
                () => fn && fn()
              )
            }}
          />
          <div className={`${style['div-grid']}`}>
            <div className={`${style['div-left']}`}>
              {(() => {
                //horizontal 4,6,8
                //vertical 1,2,3,5,7
                switch (notebookSelected) {
                  case 1:
                    return <VerticalNotebookEditable />
                  case 2:
                    return <VerticalNotebookEditable />
                  case 3:
                    return <VerticalNotebookEditable />
                  case 5:
                    return <VerticalNotebookEditable />
                  case 7:
                    return <VerticalNotebookEditable />
                  case 4:
                    return <HorizontalNotebookEditable />
                  case 6:
                    return <HorizontalNotebookEditable />
                  case 8:
                    return <HorizontalNotebookEditable />

                  default:
                    return <></>
                }
              })()}
            </div>

            <div className={`${style['div-select-options']}`}>
              <div className={`${style['div-title-type']}`}>
                <p className={`${style['p-label']}`}>
                  Diseña el interior del cuaderno y sus acabados
                </p>
                <h3 className={`${style['title-h']}`}>
                  {allNotebooks.map((item) => {
                    if (item.id === notebookSelected) return item.label
                  })}
                </h3>
                <p className={`${style['description-title']}`}></p>
              </div>
              <NotebookOptionsInsideHidden
                saveChange={saveSendDataNotebookChange}
                uuid={parseInt(uuid || '0')}
                onlySave={saveNotebookChange}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <ShowSpin />
        </Fragment>
      )}
    </div>
  )
}

export default NotebookEditorInsideModule
