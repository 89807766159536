/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react'
import { Button, Form } from 'antd'
import styles from './newPassword.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import { InputsNewPass, useNewPassword } from 'hooks/newPassword/useNewPassword'
import { changePasswordData } from 'services/auth/users'
import FormInput from 'components/formInputs/input'
import decode from 'jwt-decode'
import { IInfo } from 'interfaces/user-management-interface'

const NewPasswordModule = memo(() => {
  const { loading, form, handleNewPassword } = useNewPassword()
  const navigate = useNavigate()
  const [confirm, setConfirm] = useState<undefined | boolean>(false)

  const location = useLocation()
  const token = location.search.split('token=')[1]

  const onSubmit = async (value: changePasswordData) => {
    await handleNewPassword(
      {
        password: value.password
      },
      token
    )
  }

  useEffect(() => {
    if (location.search.includes('token=')) {
      const deco: IInfo = decode(location.search.split('token=')[1])
      if (deco.uid.confirmed !== null) setConfirm(deco.uid.confirmed)
    } else navigate('/')
  }, [token])

  return (
    <>
      
      <Form
        form={form}
        onFinish={onSubmit}
        className={styles.formNewPwd}
        layout='vertical'
        autoComplete='off'
      >
        {InputsNewPass.map((input) => (
          <FormInput key={input.name} {...input} />
        ))}

        <Button
          className={styles.btn}
          type='primary'
          htmlType='submit'
          loading={loading}
        >
          {confirm ? 'Confirmar' : 'Actualizar'}
        </Button>
      </Form>
      <Button
        className={styles.btnInit}
        type='link'
        onClick={() => navigate(`/`)}
      >
        Regresar al inicio
      </Button>
    </>
  )
})
export default NewPasswordModule
