import { RcFile } from "antd/es/upload/interface"

const convertBase64 = (file: RcFile): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export default convertBase64
