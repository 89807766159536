import React from 'react'

const AcotacionesHorizontal = ({
  className,
  notebookType,
  viewBox = '0 0 800 800',
  item
}: {
  className: string
  notebookType: number
  viewBox?: string
  item: number
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      <g>
        <title>Path acotaciones</title>
        <g id='Capa_2' data-name='Capa 2'>
          <g id='Path_acotaciones' data-name='Path acotaciones'>
            <g id='Path_acotaciones-2' data-name='Path acotaciones'>
              <g id='portada_contraportada' data-name='portada contraportada'>
                <rect x='35.5' y='128.5' width='682' height='511.5' />
                <path d='M717,129V639.5H36V129H717m1-1H35V640.5H718V128Z' />
              </g>
              {item === 2 && (
                <g>
                  <g id='Medida_ancho' data-name='Medida ancho'>
                    <line x1='716.65' y1='676.96' x2='35.26' y2='676.96' />
                    <line x1='716.65' y1='685.56' x2='716.65' y2='668.36' />
                    <line x1='35' y1='686.05' x2='35' y2='668.84' />
                  </g>
                  <g stroke='transparent' fill='black'>
                    {(() => {
                      switch (notebookType) {
                        case 4:
                          return (
                            <g
                              id='College_horizontal_ancho_26_cm'
                              data-name='College horizontal ancho 26 cm'
                            >
                              <text transform='translate(353.61 696.53)'>
                                2
                                <tspan x='6.85' y='0'>
                                  4
                                </tspan>
                                <tspan x='14.11' y='0'>
                                  .5 cm
                                </tspan>
                              </text>
                              <text
                                id='College_horizontal_alto_19.5_cm'
                                data-name='College horizontal alto 19.5 cm'
                                transform='translate(765.23 362.48) rotate(90)'
                              >
                                1
                                <tspan x='6.14' y='0'>
                                  9
                                </tspan>
                                <tspan x='13.12' y='0'>
                                  .5 cm
                                </tspan>
                              </text>
                            </g>
                          )
                        case 6:
                          return (
                            <g
                              id='Italiano_ancho_21_cm'
                              data-name='Italiano ancho 21 cm'
                            >
                              <text transform='translate(359.07 696.53)'>
                                21 cm
                              </text>
                              <text
                                id='Italiano_alto_16_cm'
                                data-name='Italiano alto 16 cm'
                                transform='translate(765.23 367.3) rotate(90)'
                              >
                                16 cm
                              </text>
                            </g>
                          )
                        case 8:
                          return (
                            <g
                              id='Marquilla_ancho_32_cm'
                              data-name='Marquilla ancho 32 cm'
                            >
                              <text transform='translate(358.7 696.53)'>
                                32 cm
                              </text>
                              <text
                                id='Marquilla_alto_24_cm'
                                data-name='Marquilla alto 24 cm'
                                transform='translate(765.23 366.84) rotate(90)'
                              >
                                <tspan>2</tspan>
                                <tspan x='6.85' y='0'>
                                  4 cm
                                </tspan>
                              </text>
                            </g>
                          )

                        default:
                          return <></>
                      }
                    })()}
                  </g>
                  <g id='Medida_alto' data-name='Medida alto'>
                    <line x1='764.52' y1='127.7' x2='747.31' y2='127.7' />
                    <line x1='765' y1='640.7' x2='747.79' y2='640.7' />
                    <line x1='755.91' y1='128' x2='755.91' y2='640.5' />
                  </g>
                </g>
              )}
            </g>
          </g>
        </g>
        )
      </g>
    </svg>
  )
}

export default AcotacionesHorizontal
