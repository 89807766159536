import React from 'react'

const GreenTagsPoloFemale  = ({
    className,
    stroke = '#305599',
    styledFont,
    aperture
  }: {
    className: string
    stroke: string
    styledFont?: string
    aperture: boolean
  }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 800 800'
        className={className}
      >
    <g id="Green_Tag" data-name="Green Tag">
    <g id="Green_Tag-2" data-name="Green Tag" stroke='green'>
      <g>
        <g>
          <path   d="m428.18,88.79s3.02-1.39,3.57-1.68c0,0,.07-.03,0-.11-.05-.03,0-.15.38-.49,0,0,1.2-.89,1.71-1.74.47-.82.59-1.14.22-1.59-.38-.48-1.45-1.72-2.87-2.53-1.43-.84-2.75-1.49-3.95-1.85-1.18-.41-2.03-.53-2.71-.65-.69-.16-.25-.22.37-.22.61.02,2.03.37,3.19.77,1.17.36,1.94.42,1.94.42,0,0-.13-.81-.79-1.67-.62-.81-1.5-2.15-2.23-3.02-.75-.84-1.12-1.24-1.02-1.31.06-.1.21-.07,1.48,1.48,1.29,1.5,1.71,2.22,2.43,3,.72.78,1.29,1.5,2.35,2.12,1.05.62,2.57,1.49,3.96,1.85,0,0,.94-.92,1.38-2.22.43-1.29.41-1.15.45-1.07.03.06.11.21.11-.53,0-.69-.11-2.04-.15-2.26-.03-.21,0-.24.1-.26.11-.04.33.38.62.88.28.51.82,1.46,1.23,2.53.38,1.1.77,2.06-.03,3.53,0,0-.08.05.03.05.09,0,.72.03.97-.02.28,0,.85-.24,1.16.05.3.29.39.79.39.79,0,0,.27.16.33.21.06.08.03.08-.11.11-.12.05-.47.11-.88.34-.43.24-1.61,1.09-2.72,1.37,0,0-.14,1.14-1.45,1.89-1.3.7-2.44,1.01-4.86,1.4-2.41.43-3.67.59-4.53.64,0,0-.63.11-.07-.21Z"/>
          <path   d="m370.81,85.32c-.68.08-.72.15-.75.82v.96c.02.4.04.77.08,1.07-.71.18-1.62.41-2.53.43-2.53-.02-4.21-1.59-4.19-3.86,0-2.63,2.13-3.9,4.35-3.93.96.02,1.83.27,2.2.36.03.42.11,1.01.17,1.78l-.32.04c-.27-1.39-1.12-1.74-2.2-1.75-1.77,0-3.05,1.27-3.06,3.36.02,1.67.98,3.59,3.17,3.57.39.03.75-.05,1.01-.18.21-.11.36-.26.35-.74l.02-.98c0-.82-.08-.92-1.23-1v-.29s2.93.02,2.93.02v.32Z"/>
          <path   d="m378.88,88.51c-.11.01-.26,0-.36,0-.79-.06-1.23-.27-1.69-.95-.37-.51-.74-1.17-1.06-1.67-.18-.32-.36-.43-.89-.44l-.33.02v1.67c0,.88.07.94.91,1v.29s-2.74,0-2.74,0v-.29c.86-.08.94-.13.94-1.04v-4.17c0-.91-.08-.95-.91-1.04l.02-.29,2.59.02c.76,0,1.27.08,1.65.37.43.26.72.75.72,1.36,0,.91-.58,1.45-1.34,1.8.18.33.62,1.03.93,1.5.37.55.59.83.85,1.07.27.33.53.44.75.51l-.04.26Zm-3.84-3.39c.53.01.88-.05,1.12-.3.41-.31.58-.73.58-1.28,0-1.14-.73-1.56-1.53-1.53-.3-.03-.46,0-.57.07-.07.08-.11.22-.1.49l-.02,2.57.51-.02Z"/>
          <path   d="m385.73,86.8c-.04.33-.27,1.33-.37,1.65h-5.03s0-.28,0-.28c.94-.11,1.03-.2,1.03-1.03l.02-4.14c-.03-.94-.1-.98-.94-1.07l.03-.29,4.64.02c0,.26.06.96.09,1.51l-.3.07c-.08-.43-.2-.67-.33-.91-.16-.24-.43-.29-1.19-.3l-.78-.03c-.34.02-.35.05-.34.36v2.39s.97,0,.97,0c.94,0,1-.06,1.13-.8h.31v1.99s-.31,0-.31,0c-.13-.74-.19-.79-1.11-.8h-.98s-.01,1.98-.01,1.98c-.01.47.05.7.24.83.2.13.56.16,1.09.14.8,0,1.09-.09,1.31-.33.18-.19.37-.59.53-.99l.3.05Z"/>
          <path   d="m392.21,86.8c-.07.34-.27,1.35-.38,1.64l-5.04.03v-.31c.95-.08,1.04-.17,1.03-1.03v-4.14c0-.94-.08-.98-.87-1.07v-.27s4.64,0,4.64,0c0,.24.06.96.09,1.51l-.3.05c-.09-.43-.19-.65-.32-.89-.15-.24-.44-.27-1.19-.28h-.79c-.33-.03-.36,0-.36.32v2.4s1-.04,1-.04c.95.03,1-.09,1.12-.78l.3.02v2s-.29,0-.29,0c-.17-.74-.21-.81-1.12-.82l-1.01.02v1.96c0,.48.06.72.27.84.17.16.55.14,1.07.15.78,0,1.1-.08,1.33-.35.18-.24.38-.58.5-.98l.32.03Z"/>
          <path   d="m400.93,81.97c-.59,0-.83.15-.86.6-.07.32-.09.8-.09,1.77v4.21s-.33.01-.33.01l-4.44-5.52-.02.02v2.65c-.02.97.03,1.46.06,1.75.03.53.28.69.99.72v.29h-2.48s0-.3,0-.3c.61-.04.87-.17.92-.67.03-.32.06-.85.06-1.81v-2.29c.01-.76,0-.81-.2-1.06-.22-.24-.46-.33-.93-.36v-.29s1.55,0,1.55,0l4.37,5.23h.02v-2.54c0-.96-.04-1.44-.08-1.75-.05-.46-.28-.61-1-.65v-.32s2.46.03,2.46.03v.3Z"/>
          <path   d="m410.67,82.81c-.11-.53-.24-.8-.33-.99-.2-.29-.4-.4-1.25-.36l-.81-.02v5.65c-.02.87.09,1.01,1.16,1.07v.32s-3.26,0-3.26,0v-.32c1.02-.09,1.12-.17,1.11-1.07l.02-5.63h-.72c-.93,0-1.18.05-1.32.35-.15.19-.22.52-.37,1l-.31.03c.04-.71.11-1.45.11-2.06l.24-.02c.14.27.29.28.61.28h4.51c.33.04.41-.08.56-.29h.27c0,.54.05,1.39.1,2.05h-.33Z"/>
          <path   d="m414.98,88.5l-.02-.29c.66-.1.76-.2.62-.59-.13-.4-.33-1-.57-1.61l-2.22-.02c-.18.48-.34.91-.48,1.34-.22.69-.13.77.68.86v.27s-2.29.02-2.29.02v-.29c.69-.1.82-.15,1.16-1.07l2.17-5.51.34-.1c.63,1.8,1.35,3.8,2.02,5.61.32.9.46.99,1.14,1.07v.31s-2.55,0-2.55,0Zm-1.04-5.61h-.01c-.34.9-.65,1.8-.97,2.66l1.92.02-.94-2.67Z"/>
          <path   d="m424.29,85.62c-.64.09-.67.14-.68.73v.93c0,.37,0,.69.07.97-.64.17-1.49.38-2.34.39-2.28,0-3.83-1.45-3.84-3.49.03-2.44,1.96-3.63,4.01-3.64.88.02,1.67.25,2.02.31.03.37.08.97.16,1.66l-.27.05c-.28-1.26-1.06-1.63-2.07-1.61-1.6-.02-2.79,1.16-2.77,3.08,0,1.53.9,3.28,2.92,3.29.35,0,.68-.08.91-.21.21-.08.31-.23.34-.66v-.88c-.01-.77-.08-.84-1.08-.9l-.02-.32,2.63.02.02.28Z"/>
        </g>
        <g>
          <path   d="m390.13,96.21h-.27c.05-.41.09-.88.12-1.39,1.05.03,1.8.04,2.26.04.37,0,1.05-.01,2.04-.04.03.53.07,1,.11,1.39h-.27c-.11-.43-.18-.69-.21-.77-.03-.07-.08-.13-.15-.16-.06-.03-.22-.05-.48-.05h-.79c-.02.63-.03,1.42-.03,2.35,0,.87,0,1.45.03,1.76,0,.07.02.12.05.14.04.03.24.08.59.15.04,0,.08.02.15.03v.21c-.47-.03-.89-.04-1.26-.04-.35,0-.71.01-1.07.04v-.21c.08-.02.14-.04.19-.04.33-.06.52-.1.57-.14.02-.02.04-.04.05-.08.01-.03.02-.3.02-.79.01-.76.02-1.33.02-1.7,0-.64,0-1.2-.02-1.69h-1c-.14,0-.22.02-.27.05-.07.04-.13.13-.18.26-.04.1-.1.32-.19.67Z"/>
          <path   d="m395.34,95.54v-.19c.47-.19.81-.33,1-.43.09-.04.21-.11.35-.19l.1.08c-.05.71-.08,1.59-.08,2.64,0,.91,0,1.43,0,1.55,0,.24.02.4.06.46.02.03.04.05.07.07.01,0,.2.05.55.15v.2c-.49-.03-.85-.04-1.07-.04-.26,0-.57.01-.91.04v-.2c.35-.09.54-.14.57-.16.03-.02.06-.05.07-.08.02-.04.04-.2.05-.46,0-.09,0-.57,0-1.43v-1.43s0-.33,0-.33c0-.14-.02-.23-.05-.27-.04-.04-.09-.06-.16-.06s-.27.03-.55.08Z"/>
        </g>
        <g>
          <path   d="m373.88,105.6v-.63s0-.42,0-.42c0-.06,0-.1-.03-.11-.02-.02-.08-.05-.17-.06l-.3-.07v-.13c.34.02.59.02.74.02.17,0,.4,0,.69-.02v.13c-.24.05-.38.08-.41.1-.03.02-.05.03-.06.05-.01.03-.03.11-.03.24,0,.22,0,.6,0,1.14v1.05s.01.06.01.06c0,.07.01.11.04.13.04.03.2.07.46.11v.13c-.41-.02-.66-.02-.78-.02-.18,0-.4,0-.66.02v-.13l.41-.09s.04-.02.05-.04c.02-.03.03-.09.04-.18v-1.04c-.38,0-.68-.01-.88-.01-.23,0-.46,0-.71.01v1.07c0,.08.01.13.03.15.01.02.05.04.11.06.01,0,.13.03.36.08v.13c-.36-.02-.62-.02-.77-.02-.18,0-.4,0-.67.02v-.13c.16-.03.28-.06.36-.07.06-.01.1-.03.11-.05.02-.03.03-.08.03-.16,0-.28,0-.68,0-1.21v-1.1s0-.07,0-.07c0-.05-.01-.09-.03-.11-.01-.02-.05-.04-.11-.05l-.37-.07v-.13c.3.02.55.02.75.02.21,0,.44,0,.69-.02v.13l-.37.08s-.08.03-.1.05c-.02.02-.02.06-.02.11v1.05c.22,0,.46,0,.71,0,.33,0,.62,0,.88,0Z"/>
          <path   d="m375.9,105.56h.54c.21,0,.33,0,.36-.02.04-.01.07-.03.09-.07.02-.03.05-.13.08-.29,0-.02.01-.04.02-.08h.14c-.02.24-.02.43-.02.56s0,.32.02.56h-.14c-.04-.21-.07-.33-.08-.36-.02-.03-.04-.06-.08-.08-.04-.02-.15-.02-.33-.02h-.61v1.3h1.02c.13,0,.21,0,.24-.02.06-.02.11-.05.15-.1.04-.05.12-.17.24-.38.01-.02.03-.05.05-.09h.15s-.11.32-.27.83c-.54-.02-1.03-.02-1.48-.02-.31,0-.65,0-1.03.02v-.13l.36-.08c.06-.02.09-.03.11-.05.02-.03.03-.09.03-.17v-.56c.01-.1.01-.3.01-.59,0-.43,0-.74,0-.94,0-.2,0-.31-.02-.34,0-.02-.02-.03-.04-.04-.02-.01-.09-.03-.21-.06l-.24-.05v-.13c.28.02.61.02,1,.02.21,0,.72,0,1.51-.02,0,.26.02.51.05.76h-.16c-.06-.2-.11-.33-.14-.4-.02-.05-.05-.08-.09-.09-.05-.02-.16-.03-.33-.03h-.89l-.02.71v.44Z"/>
          <path   d="m380.95,106.78l.09.17c-.24.15-.46.26-.68.33-.22.07-.45.11-.68.11-.3,0-.57-.07-.81-.21-.24-.13-.43-.33-.55-.57-.12-.25-.19-.52-.19-.82,0-.49.16-.89.48-1.21.32-.31.75-.47,1.27-.47.32,0,.68.07,1.08.2,0,.13,0,.23,0,.29,0,.19,0,.39.03.6h-.19c-.05-.26-.11-.44-.18-.53-.06-.1-.16-.17-.3-.24-.14-.06-.31-.09-.51-.09-.43,0-.75.15-.97.46-.17.24-.25.53-.25.9,0,.43.11.76.35,1.01.24.25.55.37.93.37.17,0,.33-.02.48-.07.16-.04.35-.12.57-.23Z"/>
          <path   d="m383.82,105.6v-.63s0-.42,0-.42c0-.06,0-.1-.03-.11-.02-.02-.08-.05-.17-.06l-.3-.07v-.13c.34.02.59.02.74.02.17,0,.4,0,.69-.02v.13c-.24.05-.38.08-.41.1-.03.02-.05.03-.06.05-.02.03-.03.11-.03.24,0,.22,0,.6,0,1.14v1.05s0,.06,0,.06c0,.07.01.11.04.13.04.03.2.07.46.11v.13c-.41-.02-.67-.02-.78-.02-.18,0-.4,0-.66.02v-.13l.41-.09s.04-.02.05-.04c.02-.03.03-.09.04-.18v-1.04c-.38,0-.68-.01-.88-.01-.22,0-.46,0-.71.01v1.07c0,.08.02.13.03.15.01.02.05.04.11.06.01,0,.13.03.36.08v.13c-.36-.02-.62-.02-.77-.02-.18,0-.4,0-.67.02v-.13c.16-.03.28-.06.36-.07.06-.01.1-.03.11-.05.02-.03.03-.08.03-.16,0-.28,0-.68,0-1.21v-1.1s0-.07,0-.07c0-.05,0-.09-.02-.11-.02-.02-.05-.04-.11-.05l-.37-.07v-.13c.3.02.55.02.75.02.21,0,.44,0,.69-.02v.13l-.37.08s-.08.03-.1.05c-.02.02-.02.06-.02.11v1.05c.22,0,.46,0,.71,0,.33,0,.62,0,.88,0Z"/>
          <path   d="m385.04,105.77c0-.49.16-.88.47-1.19.31-.31.72-.47,1.2-.47.46,0,.82.14,1.09.41.27.27.4.65.4,1.13,0,.53-.15.96-.46,1.27-.3.31-.7.46-1.2.46-.47,0-.84-.14-1.11-.42s-.4-.68-.4-1.19Zm.48-.07c0,.49.11.85.32,1.1.21.24.48.37.81.37.21,0,.41-.06.58-.17.17-.11.29-.28.38-.5.08-.22.13-.46.13-.73,0-.33-.05-.61-.16-.83-.11-.22-.25-.38-.41-.47-.16-.09-.34-.14-.54-.14-.22,0-.42.06-.59.17-.17.11-.3.28-.38.5-.08.22-.13.45-.13.7Z"/>
          <path   d="m390.59,105.56h.54c.21,0,.33,0,.36-.02.04-.01.07-.03.09-.07.02-.03.05-.13.08-.29,0-.02.01-.04.02-.08h.15c-.02.24-.02.43-.02.56s0,.32.02.56h-.15c-.04-.21-.07-.33-.08-.36-.01-.03-.04-.06-.08-.08-.04-.02-.15-.02-.33-.02h-.61v1.3h1.02c.13,0,.21,0,.24-.02.06-.02.11-.05.15-.1.05-.05.12-.17.24-.38.01-.02.03-.05.05-.09h.15s-.11.32-.27.83c-.54-.02-1.03-.02-1.48-.02-.31,0-.65,0-1.03.02v-.13l.36-.08c.06-.02.09-.03.11-.05.02-.03.03-.09.03-.17v-.56c.02-.1.02-.3.02-.59,0-.43,0-.74,0-.94,0-.2-.01-.31-.02-.34,0-.02-.02-.03-.04-.04-.02-.01-.09-.03-.21-.06l-.24-.05v-.13c.28.02.61.02,1,.02.21,0,.71,0,1.51-.02,0,.26.02.51.05.76h-.15c-.06-.2-.11-.33-.14-.4-.02-.05-.06-.08-.09-.09-.05-.02-.16-.03-.32-.03h-.9v.71s-.02.44-.02.44Z"/>
          <path   d="m392.61,104.31v-.13c.19.02.33.02.44.02.09,0,.24,0,.47-.02l.63.79c.05.07.5.61,1.33,1.62v-.45c0-.22,0-.62-.02-1.21,0-.27-.01-.42-.02-.45,0-.02-.02-.05-.05-.06-.02-.02-.13-.05-.31-.08-.02,0-.06-.02-.1-.03v-.13c.26.02.47.02.63.02.15,0,.36,0,.62-.02v.13s-.07.02-.09.02c-.2.04-.31.07-.33.08-.02.01-.03.02-.04.04-.01.02-.02.07-.03.13,0,.03,0,.23,0,.6,0,.37,0,.74,0,1.09,0,.42,0,.76.02,1.05l-.14.07-.92-1.15-1.21-1.46v1.31c.02.57.02.88.03.92,0,.03.02.05.04.07.03.03.14.06.33.09.02,0,.05,0,.1.02v.13c-.23-.02-.42-.02-.57-.02-.13,0-.36,0-.68.02v-.13c.05-.01.09-.02.12-.02.18-.03.28-.06.31-.09.02-.01.03-.04.04-.07.02-.07.02-.33.03-.77,0-.63,0-1.01,0-1.12,0-.37,0-.58-.01-.62,0-.03-.03-.05-.06-.08-.03-.02-.17-.05-.42-.09-.03,0-.07-.02-.12-.03Z"/>
          <path   d="m397.48,104.31v-.13c.25.02.43.02.56.02.15,0,.3,0,.47-.02.06.16.11.27.14.36.05.11.24.53.58,1.24.14.31.29.61.43.9l.94-1.99c.06-.12.12-.29.21-.5.2.02.35.02.46.02.1,0,.26,0,.49-.02v.13s-.08.02-.1.03c-.21.04-.32.07-.34.09-.04.03-.06.1-.06.22v.73c0,.88,0,1.42,0,1.61,0,.04.01.07.04.09.02.02.14.05.38.1.02,0,.05,0,.09.02v.13c-.27-.02-.5-.02-.7-.02s-.45,0-.73.02v-.13c.05-.01.1-.02.13-.03.18-.03.28-.06.31-.07.02-.01.04-.03.05-.06.02-.04.02-.16.02-.36v-1.94c-.14.27-.44.91-.91,1.92-.13.28-.23.5-.29.64h-.13c-.33-.74-.74-1.59-1.21-2.56l.03,2.07c0,.17.01.26.04.28.03.02.14.05.33.08.02,0,.06.01.1.02v.13c-.26-.02-.46-.02-.62-.02s-.36,0-.63.02v-.13s.08-.02.09-.02c.2-.04.31-.07.33-.1.04-.04.05-.14.05-.3v-.5c.02-.46.02-.9.02-1.32v-.27c0-.11-.01-.17-.02-.21-.01-.03-.04-.05-.07-.06-.05-.02-.18-.05-.37-.08-.03,0-.07-.02-.13-.03Z"/>
          <path   d="m402.87,105.56h.54c.21,0,.33,0,.37-.02.04-.01.07-.03.09-.07.02-.03.05-.13.09-.29,0-.02,0-.04.02-.08h.14c-.02.24-.02.43-.02.56s0,.32.02.56h-.14c-.04-.21-.07-.33-.08-.36-.02-.03-.04-.06-.08-.08-.04-.02-.15-.02-.33-.02h-.61v1.3h1.02c.13,0,.21,0,.24-.02.06-.02.11-.05.15-.1.04-.05.12-.17.24-.38,0-.02.03-.05.05-.09h.15s-.11.32-.27.83c-.54-.02-1.03-.02-1.48-.02-.31,0-.65,0-1.03.02v-.13l.36-.08c.05-.02.09-.03.11-.05.02-.03.03-.09.03-.17v-.56c.01-.1.01-.3.01-.59,0-.43,0-.74,0-.94,0-.2,0-.31-.02-.34,0-.02-.02-.03-.04-.04s-.09-.03-.21-.06l-.24-.05v-.13c.28.02.61.02,1,.02.21,0,.72,0,1.51-.02,0,.26.02.51.05.76h-.16c-.06-.2-.11-.33-.14-.4-.02-.05-.05-.08-.09-.09-.05-.02-.16-.03-.33-.03h-.89l-.02.71v.44Zm.37-1.67h-.13c.1-.27.19-.53.28-.78h.25s.09,0,.2,0l.03.04c-.22.23-.43.47-.62.73Z"/>
          <path   d="m405.03,104.31v-.13c.23.02.44.02.63.02s.45,0,.74-.02v.13c-.18.04-.29.07-.32.1-.02.01-.03.03-.03.05,0,.02.02.08.07.16.21.33.38.58.53.78.24-.31.45-.6.64-.87.03-.05.05-.08.05-.09,0-.02,0-.03-.03-.04-.02-.02-.11-.04-.29-.08v-.13c.25.02.44.02.58.02.15,0,.33,0,.54-.02v.13c-.19.04-.31.08-.38.12s-.17.15-.31.32c-.26.34-.48.63-.65.87.13.2.29.45.49.74l.45.65s.05.05.09.07c.05.03.17.07.35.11v.13c-.26-.02-.47-.02-.63-.02-.15,0-.38,0-.69.02v-.13c.2-.05.3-.08.32-.09.02-.01.02-.03.02-.05,0-.03-.02-.08-.06-.15-.21-.33-.41-.64-.61-.9-.18.22-.4.52-.66.9-.06.08-.08.14-.08.16,0,.02.01.05.03.06.02.02.1.04.24.07.01,0,.04,0,.06.02v.13c-.21-.02-.42-.02-.62-.02-.18,0-.35,0-.5.02v-.13c.17-.03.28-.07.34-.11.06-.04.18-.18.36-.4.31-.41.54-.72.68-.92-.14-.22-.36-.54-.66-.98-.12-.18-.21-.28-.27-.32-.05-.04-.19-.08-.42-.15Z"/>
          <path   d="m409.85,104.18v.13c-.05.01-.09.02-.11.03-.2.03-.31.06-.34.1-.03.03-.05.1-.05.2v.97s0,1.29,0,1.29c0,.1.02.17.05.2.03.03.14.05.31.08.04,0,.08.01.15.02v.13c-.24-.02-.47-.02-.69-.02s-.48,0-.75.02v-.13s.07-.02.1-.02c.21-.03.33-.07.36-.09.03-.03.05-.1.05-.22v-.11s0-.81,0-.81c0-.84,0-1.3,0-1.39,0-.05-.02-.08-.05-.11s-.14-.06-.33-.1c-.03,0-.07-.02-.11-.03v-.13c.32.02.57.02.75.02.21,0,.43,0,.69-.02Z"/>
          <path   d="m412.98,106.78l.09.17c-.24.15-.46.26-.68.33-.22.07-.45.11-.68.11-.3,0-.57-.07-.81-.21-.24-.13-.43-.33-.55-.57-.12-.25-.19-.52-.19-.82,0-.49.16-.89.48-1.21.32-.31.75-.47,1.27-.47.32,0,.69.07,1.08.2,0,.13,0,.23,0,.29,0,.19,0,.39.03.6h-.19c-.05-.26-.11-.44-.17-.53-.06-.1-.16-.17-.3-.24-.14-.06-.31-.09-.51-.09-.43,0-.75.15-.97.46-.17.24-.25.53-.25.9,0,.43.12.76.36,1.01.24.25.55.37.93.37.17,0,.33-.02.48-.07.16-.04.35-.12.57-.23Z"/>
          <path   d="m413.43,105.77c0-.49.16-.88.47-1.19.31-.31.72-.47,1.2-.47.46,0,.82.14,1.09.41.27.27.4.65.4,1.13,0,.53-.15.96-.46,1.27-.3.31-.7.46-1.2.46-.46,0-.83-.14-1.1-.42-.27-.28-.4-.68-.4-1.19Zm.48-.07c0,.49.11.85.32,1.1.22.24.49.37.81.37.21,0,.41-.06.58-.17.17-.11.29-.28.38-.5.09-.22.13-.46.13-.73,0-.33-.05-.61-.16-.83-.11-.22-.25-.38-.41-.47-.16-.09-.34-.14-.54-.14-.22,0-.42.06-.59.17-.17.11-.3.28-.38.5-.08.22-.12.45-.12.7Z"/>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="m317.88,62.25l.42.14s.03.01.05.02c.03.01.04.03.03.06-.01.02-.04.03-.07.04-.18,0-.35.04-.49.12-.15.07-.26.19-.32.34-.09.2-.08.39.04.58.11.19.28.33.51.43.25.11.49.15.7.09.22-.05.36-.16.44-.33.03-.07.05-.15.05-.24s-.02-.15-.04-.19c-.02-.04-.08-.07-.15-.1-.15-.07-.26-.11-.3-.11-.04,0-.08,0-.11.03-.03.02-.07.07-.11.16-.02.03-.04.04-.07.03-.03-.01-.03-.03-.02-.07l.09-.21.28-.65s.03-.04.06-.03c.01,0,.02.02.02.03,0,.01,0,.06-.02.13-.02.05-.01.1,0,.12.02.02.15.1.4.22.06.03.09.05.09.05,0,0,.02.08.02.21s0,.26-.02.39c-.03.12-.06.24-.11.36-.08.19-.2.34-.34.46-.14.11-.3.19-.48.22-.18.03-.36,0-.55-.08-.27-.12-.45-.32-.57-.59-.11-.27-.09-.57.05-.88.05-.11.11-.21.18-.29.07-.08.15-.17.26-.26.02-.02.03-.04.04-.06,0-.02,0-.04-.02-.06-.03-.03-.04-.06-.03-.08.01-.02.05-.02.11,0Z"/>
            <path d="m319.62,61.31c.1-.09.2-.15.31-.18.11-.03.24,0,.42.09.17.08.29.12.36.12.07,0,.14-.03.19-.1.04-.03.06-.04.08-.03.02.02,0,.06-.04.12l-.38.45c-.08,0-.19-.03-.33-.11-.17-.11-.3-.17-.38-.2-.08-.03-.16-.03-.23,0-.06.02-.12.07-.17.13-.02.02-.03.04-.06.08.28.23.45.36.51.41s.12.07.17.07c.05,0,.1-.03.16-.1.03-.03.06-.04.08-.02s.02.04,0,.07l-.31.35s-.09.11-.15.19c-.06.07-.09.12-.11.14-.02.03-.05.04-.07.02-.02-.02-.02-.04,0-.07.04-.04.06-.09.06-.15.01-.06-.07-.15-.23-.28l-.22-.18-.42-.35c-.22-.18-.36-.28-.43-.3-.06-.02-.13,0-.2.09-.03.03-.05.04-.07.02-.02-.02-.02-.05.02-.09.18-.22.33-.41.45-.57.06-.07.1-.14.15-.19.15-.18.3-.29.45-.33s.29-.02.4.07c.08.06.13.15.15.24.02.1.02.2-.01.29s-.08.19-.15.3Zm-.31.28s.03-.03.04-.04c.06-.07.1-.15.13-.24.03-.09.03-.17,0-.25-.03-.07-.08-.14-.14-.19-.1-.08-.2-.11-.3-.1s-.19.07-.27.16c-.03.04-.06.09-.09.15l.63.52Z"/>
            <path d="m321.24,58.7c.12.11.24.22.36.33.02.02.02.04,0,.06-.01,0-.03,0-.07,0-.18-.06-.31-.08-.41-.07s-.21.07-.34.16c-.07.06-.14.11-.2.18.09.15.2.32.33.49.02.03.06.08.11.15l.09-.07c.06-.04.1-.09.13-.14.03-.05.04-.11.03-.18,0-.07-.02-.12-.02-.14-.01-.03,0-.05.01-.06.02-.02.04-.02.06,0,0,0,0,.01,0,.02.03.06.14.21.32.47.03.03.06.06.08.09l.02.02s0,.04-.02.06c-.02.01-.05,0-.09-.03-.07-.06-.15-.1-.24-.09-.09,0-.19.05-.32.14.1.14.16.23.19.28.14.19.22.3.25.32.03.03.07.04.13.04s.12-.04.21-.1c.31-.23.44-.48.4-.75,0-.04,0-.06.01-.07.02-.02.04-.02.06,0,.01.01.02.03.03.06l.18.41-.45.32c-.13.09-.24.18-.36.25-.11.08-.2.14-.26.18-.07.05-.17.13-.3.24-.02.02-.03.02-.03.03-.04.03-.07.03-.09,0-.02-.02,0-.05.02-.07.08-.06.11-.12.11-.18,0-.06-.06-.16-.15-.29l-.46-.63c-.16-.22-.26-.35-.3-.39-.04-.05-.08-.07-.11-.07s-.09.02-.16.06c-.03.02-.05.01-.06,0-.02-.02,0-.05.02-.07l.3-.21.99-.73Z"/>
            <path d="m323.29,57.66c.1.13.2.26.29.39.01.02,0,.04-.02.06-.02,0-.04,0-.07-.02-.16-.09-.29-.14-.39-.15s-.22.02-.37.1c-.08.04-.16.09-.23.14.06.17.13.35.23.54.02.03.04.09.08.16l.11-.05c.06-.03.11-.06.15-.11.04-.04.06-.1.07-.17s0-.12,0-.14c0-.03,0-.06.02-.06.02-.01.04,0,.06.01,0,0,0,.02,0,.02.02.06.1.23.23.52.02.04.04.07.06.1,0,0,.01.02.02.02.01.02,0,.04-.02.06-.02,0-.05,0-.08-.05-.06-.08-.13-.12-.22-.13-.09-.02-.2.01-.35.08.07.15.12.26.14.31.1.21.16.33.19.37.03.04.06.06.12.06.05,0,.13-.02.22-.06.35-.17.53-.39.53-.66,0-.04,0-.06.02-.07.03-.01.05,0,.06.01,0,.02.02.04.02.06l.1.44-.5.23c-.14.07-.27.13-.4.18-.13.05-.22.09-.29.13-.08.04-.19.1-.34.18-.02.01-.03.02-.04.02-.05.02-.07.02-.09-.01-.01-.02,0-.05.03-.06.09-.04.14-.09.15-.16,0-.06-.02-.17-.1-.32l-.33-.71c-.12-.24-.19-.39-.22-.44-.03-.05-.07-.08-.1-.09-.04,0-.09,0-.17.02-.03,0-.05,0-.06-.02-.01-.02,0-.05.03-.06l.34-.15,1.11-.53Z"/>
            <path d="m324.35,57.38c.63.38,1.16.67,1.57.89-.02-.06-.03-.11-.04-.13l-.19-.57c-.04-.14-.08-.23-.11-.28-.04-.05-.08-.09-.14-.11s-.13-.02-.19,0c-.04,0-.06,0-.07-.03,0-.03,0-.05.03-.06.02,0,.04-.01.07-.02.06-.01.14-.03.25-.07.13-.04.23-.08.3-.11.03,0,.05-.02.07-.03.04,0,.06,0,.07.02s-.01.05-.06.07c-.06.03-.11.07-.15.13-.04.06-.04.16,0,.29l.27.82.19.62c.02.05,0,.09-.02.1,0,0-.02,0-.03,0-.02,0-.1-.05-.27-.14-.16-.09-.4-.23-.73-.42-.33-.19-.56-.33-.69-.42,0,.03.08.27.23.73.05.15.09.25.13.33.04.07.1.12.17.14.07.02.14.02.19,0,.03-.01.05,0,.06.02,0,.03,0,.05-.04.06-.02,0-.05.02-.08.02-.06.01-.14.03-.23.06-.15.05-.25.08-.31.11-.04.02-.07.03-.08.03-.04.01-.06,0-.07-.03,0-.02.02-.05.08-.07.11-.04.16-.1.17-.18,0-.07,0-.17-.05-.28-.16-.5-.26-.8-.3-.91-.04-.11-.11-.2-.2-.27-.09-.07-.19-.1-.28-.09-.04,0-.06,0-.07-.02-.01-.03,0-.05.04-.07,0,0,.02,0,.04,0,.02,0,.06-.02.12-.04.13-.04.24-.08.35-.11Z"/>
            <path d="m327.94,56.39c.53-.06.91-.1,1.16-.14.19-.03.4-.06.63-.1.08-.02.15-.03.2-.03.02,0,.06,0,.11-.01,0,.04.03.12.06.25l.05.24s0,.06-.04.06c-.02,0-.05-.02-.08-.06-.05-.08-.11-.14-.17-.19-.07-.05-.15-.08-.23-.09-.08,0-.21,0-.4.02,0,.15.02.35.05.58.03.23.07.46.11.68.04.22.06.35.09.4.02.04.04.07.08.09.04.02.11.02.23.02.04,0,.06,0,.06.03,0,.03-.01.04-.05.05h-.04s-.32.04-.32.04c-.24.03-.39.06-.44.06l-.19.03s-.05,0-.09.01c-.02,0-.04-.02-.04-.03,0-.03.01-.05.05-.05.09-.01.15-.04.19-.08s.06-.11.06-.19c0-.08-.02-.26-.05-.53-.06-.43-.12-.77-.18-1.04-.17.02-.28.04-.36.07-.07.02-.14.07-.2.14-.06.07-.11.16-.14.26,0,.04-.03.06-.05.06-.03,0-.05,0-.06-.04,0,0,0-.08,0-.2,0-.12,0-.23,0-.31Z"/>
            <path d="m331,55.98h.11c.16.27.38.71.68,1.31.14.28.23.44.28.5.05.06.12.09.21.09.03,0,.05.01.05.03,0,.03-.02.05-.06.05-.02,0-.08,0-.17,0-.11,0-.19,0-.27,0-.07,0-.16.02-.27.04-.08.01-.14.02-.16.02-.05,0-.07,0-.07-.04,0-.02.01-.04.04-.04.13-.02.18-.08.18-.16,0-.04-.03-.11-.07-.22-.05-.11-.1-.2-.14-.28-.07,0-.12,0-.15.01-.2.02-.36.03-.48.04-.06.24-.09.41-.09.49,0,.06.03.1.07.12.05.02.1.04.15.04.04,0,.06.01.06.04,0,.03-.02.04-.06.05-.02,0-.06,0-.13,0-.09,0-.16,0-.2,0-.08,0-.15.02-.23.03-.02,0-.03,0-.05,0-.04,0-.06-.01-.06-.04,0-.02,0-.04.03-.05.06-.02.1-.05.14-.07.03-.03.06-.08.1-.15s.07-.19.11-.36c.09-.37.24-.86.43-1.49Zm-.06.54l-.2.7.54-.05c-.04-.08-.09-.18-.16-.32-.08-.15-.14-.26-.17-.33Z"/>
            <path d="m334.25,55.98l.06.44s0,.04,0,.05c0,.03-.02.05-.04.05-.02,0-.05-.02-.06-.05-.09-.16-.2-.29-.33-.39-.13-.09-.28-.14-.45-.13-.22,0-.38.11-.49.3-.11.19-.17.4-.15.65.01.28.09.5.23.67.15.17.31.25.49.24.08,0,.15-.02.23-.06.08-.04.13-.08.15-.12s.03-.1.02-.18c0-.17-.02-.28-.04-.32-.01-.04-.04-.07-.07-.09-.03-.02-.1-.02-.2-.03-.04,0-.06-.02-.06-.04,0-.03.02-.04.05-.05h.23s.7-.06.7-.06c.03,0,.05.01.05.04,0,.02,0,.02-.02.03-.01,0-.05.02-.13.03-.06.02-.09.04-.1.06s-.02.18-.01.46c0,.06,0,.1,0,.11,0,0-.06.04-.18.11-.11.07-.23.12-.36.15-.12.04-.24.06-.37.06-.21.01-.4-.02-.57-.1-.17-.07-.3-.18-.41-.33s-.17-.32-.18-.53c-.01-.29.08-.55.27-.77.19-.22.46-.34.81-.36.12,0,.24,0,.34.03.1.02.21.06.35.11.02.01.05.02.06.02.02,0,.03-.02.04-.05.01-.04.03-.06.05-.06.03,0,.05.03.06.09Z"/>
          </g>
          <path  d="m339.43,57.13c.09-.03.17-.07.26-.09.27-.06.47-.26.68-.45.12-.12.14-.25.05-.41-.25-.49-.65-.77-1.1-1-.01,0-.02-.02-.04-.04.2.03.35.17.53.25.05.02.11.1.16.04.05-.05,0-.13-.02-.19-.03-.1-.06-.2-.08-.31.18.43.45.76.79,1.03.15.12.24.09.34-.07.17-.26.34-.51.32-.86.12.4.3.79.06,1.24.09.02.17.03.26.05.08.01.09.09.1.16.02.11-.07.06-.1.08-.21.12-.46.14-.65.38-.12.16-.32.16-.5.18-.35.03-.71.03-1.07,0Z"/>
        </g>
        <g>
          <g>
            <path  d="m368.92,53.53l.07.44s0,.04,0,.05c0,.03-.01.05-.03.05-.02,0-.05-.02-.07-.05-.09-.16-.2-.28-.34-.38-.14-.1-.29-.14-.46-.13-.22.01-.38.11-.49.3-.11.19-.16.41-.15.66.01.28.1.49.24.66.15.17.31.24.49.23.08,0,.15-.03.23-.06.08-.04.13-.09.15-.13.02-.04.03-.1.02-.18,0-.17-.02-.28-.04-.32-.02-.04-.04-.07-.07-.08-.04-.02-.1-.02-.19-.03-.04,0-.06-.02-.06-.04,0-.03.02-.05.05-.05h.23s.7-.07.7-.07c.03,0,.05,0,.05.04,0,.02,0,.03-.02.03-.01,0-.05.02-.12.04-.06.02-.09.03-.11.06-.01.02-.01.18,0,.46,0,.06,0,.1,0,.11,0,0-.06.05-.18.11-.11.07-.23.12-.35.16-.13.04-.25.06-.37.07-.21.01-.4-.02-.57-.09-.17-.07-.31-.18-.42-.32-.11-.15-.17-.32-.19-.53-.02-.29.07-.55.26-.78.19-.22.46-.35.81-.37.12,0,.23,0,.34.02.1.02.22.06.35.11.03,0,.05.01.07.01.02,0,.03-.02.04-.05.01-.04.03-.06.05-.06.03,0,.05.03.06.1Z"/>
            <path  d="m370.52,54.28c.14.01.25.05.34.11.09.06.17.18.22.37.05.18.1.29.15.35.05.05.11.08.2.08.05,0,.07.01.07.04,0,.02-.04.04-.11.05h-.59c-.06-.05-.11-.15-.14-.31-.04-.2-.07-.34-.11-.42-.04-.08-.09-.14-.15-.17-.06-.03-.13-.05-.21-.05-.02,0-.06,0-.1,0,.02.36.03.58.03.66,0,.08.02.14.06.18.03.03.09.05.18.05.05,0,.07.02.07.04s-.02.04-.06.04h-.47c-.06.01-.14.02-.24.03-.09,0-.15.01-.17.01-.04,0-.06-.01-.06-.04,0-.03.02-.05.05-.05.06,0,.11-.02.15-.05.05-.03.07-.15.06-.37l-.02-.28-.02-.55c-.01-.28-.03-.45-.06-.51-.03-.06-.1-.09-.21-.09-.04,0-.06-.01-.06-.04,0-.03.03-.04.08-.05.28-.02.53-.03.73-.05.08,0,.16-.02.23-.02.23,0,.41.03.55.11.13.09.2.2.21.35,0,.1-.02.19-.08.27s-.13.15-.22.19c-.09.04-.2.07-.32.09Zm-.42-.04h.06c.09,0,.18-.02.26-.06.09-.04.15-.09.19-.17s.05-.15.04-.23c0-.13-.05-.23-.13-.29-.08-.07-.18-.1-.3-.1-.05,0-.11.02-.16.04l.03.81Z"/>
            <path  d="m373.21,53.21c.04.16.08.32.11.47,0,.03-.02.04-.05.04-.02,0-.03-.01-.05-.04-.11-.15-.21-.25-.3-.29s-.21-.06-.38-.06c-.09,0-.18.01-.26.03-.02.18-.02.38,0,.59,0,.04,0,.1,0,.18h.11c.07,0,.14-.02.19-.04s.09-.06.13-.13c.04-.07.06-.11.06-.13,0-.03.02-.05.05-.05.03,0,.04,0,.04.03,0,0,0,.02,0,.02,0,.06,0,.25,0,.57,0,.04,0,.08.02.12,0,.01,0,.02,0,.02,0,.03-.02.05-.05.05-.02,0-.04-.03-.06-.08-.02-.09-.07-.16-.15-.21-.07-.05-.19-.07-.34-.06v.34c0,.23.02.37.03.41,0,.05.03.08.08.1.04.03.12.04.23.03.39-.01.64-.14.76-.39,0-.04.03-.06.05-.06.03,0,.05.01.05.04,0,.02,0,.04,0,.06l-.09.44-.55.02c-.16,0-.3,0-.44,0-.13,0-.24,0-.32,0-.09,0-.21.01-.39.03-.02,0-.04,0-.05,0-.05,0-.07-.01-.07-.04s.02-.05.06-.05c.1,0,.16-.03.19-.09.03-.05.05-.16.04-.33l-.02-.78c0-.27-.01-.43-.02-.49s-.03-.1-.06-.12c-.03-.02-.08-.04-.16-.04-.03,0-.05-.02-.05-.04,0-.03.02-.04.06-.05h.36l1.23-.04Z"/>
            <path  d="m375.27,53.16c.03.16.07.32.11.48,0,.03-.02.04-.04.04-.02,0-.04-.02-.06-.04-.11-.15-.2-.25-.29-.29-.09-.05-.21-.07-.37-.06-.09,0-.18,0-.27.03-.02.18-.02.37-.02.59,0,.04,0,.1,0,.19h.11c.07,0,.14-.02.19-.04.05-.02.1-.06.13-.13.04-.06.06-.11.06-.13,0-.03.02-.05.05-.05.03,0,.04.01.04.03,0,0,0,.02,0,.02-.01.06-.02.25,0,.57,0,.04,0,.08.02.12,0,0,0,.02,0,.02,0,.03-.02.04-.05.04-.02,0-.04-.02-.05-.08-.02-.09-.07-.16-.15-.21-.07-.05-.19-.07-.34-.07,0,.17,0,.28,0,.34,0,.23.01.37.02.41,0,.04.03.08.08.11.04.03.12.04.22.03.39,0,.64-.13.76-.38.02-.03.04-.05.06-.06.03,0,.05.01.05.04,0,.02,0,.04,0,.06l-.09.44h-.55c-.16.01-.3.01-.44,0-.13,0-.24,0-.32,0-.08,0-.22,0-.38.02-.02,0-.04,0-.05,0-.05,0-.07-.02-.07-.05,0-.03.02-.04.06-.04.1,0,.17-.03.2-.08.03-.05.05-.16.04-.32v-.79c-.01-.27-.02-.43-.03-.49s-.02-.1-.05-.12c-.03-.02-.09-.04-.16-.05-.04,0-.05-.02-.05-.04,0-.03.02-.05.06-.05h.37s1.23-.02,1.23-.02Z"/>
            <path  d="m376.28,53.13c.49.55.9.99,1.22,1.32,0-.07,0-.11,0-.13v-.6c0-.14,0-.24-.02-.31-.02-.06-.06-.11-.11-.15-.06-.04-.11-.06-.19-.06-.04,0-.06-.02-.06-.05,0-.03.02-.04.05-.04.02,0,.04,0,.07,0,.06,0,.14,0,.25,0,.14,0,.25,0,.32-.01.03,0,.05,0,.07,0,.04,0,.06.02.06.04,0,.03-.03.05-.08.05-.06,0-.12.03-.18.08-.06.05-.08.14-.08.28l.02.86v.65c0,.06-.03.08-.06.08,0,0-.02,0-.03,0,0,0-.08-.08-.21-.21-.13-.13-.32-.34-.57-.62-.25-.28-.43-.48-.53-.61,0,.03,0,.28,0,.77,0,.15,0,.27.02.35.02.09.06.15.12.19s.13.06.18.06c.04,0,.06.01.06.04,0,.03-.02.04-.06.05-.02,0-.05,0-.09,0-.06,0-.14-.01-.23-.01-.15,0-.26,0-.33.02-.05,0-.07,0-.09,0-.04,0-.06-.02-.06-.05,0-.02.03-.04.1-.05.11,0,.19-.05.22-.12.02-.07.04-.16.04-.28,0-.53,0-.85-.02-.96,0-.12-.04-.22-.11-.32-.07-.1-.15-.15-.24-.17-.04,0-.06-.02-.06-.04,0-.03.02-.05.06-.05,0,0,.02,0,.04,0,.02,0,.06,0,.13,0,.13,0,.25,0,.37,0Z"/>
            <path  d="m379.81,53.12c.53.02.92.03,1.17.03.19,0,.4,0,.63-.01.08,0,.15,0,.2,0,.02,0,.05,0,.1,0,0,.04.02.13.03.26l.02.24s-.02.06-.05.06c-.02,0-.05-.02-.07-.07-.04-.08-.09-.15-.15-.21-.06-.06-.13-.1-.21-.12-.08-.02-.21-.03-.39-.03-.02.15-.03.35-.03.58,0,.24,0,.47,0,.69s.02.36.03.4.04.08.07.1.11.04.22.05c.04,0,.06.02.06.04,0,.03-.02.04-.05.04h-.04s-.32-.01-.32-.01h-.64s-.04,0-.09,0c-.02,0-.04-.02-.04-.03,0-.03.02-.05.06-.05.09,0,.16-.02.2-.05.05-.04.07-.1.09-.18,0-.08.02-.26.02-.53,0-.43,0-.79-.03-1.06-.17,0-.29,0-.37.02-.07.02-.15.05-.22.11-.07.06-.13.14-.17.24-.02.04-.04.06-.06.06-.04,0-.05-.02-.05-.04,0,0,0-.08.02-.2.01-.13.03-.23.05-.31Z"/>
            <path  d="m382.8,53.11h.11c.13.29.32.75.57,1.37.11.29.19.46.23.52.04.06.11.1.2.11.03,0,.05.02.05.04,0,.03-.02.05-.06.05-.02,0-.08,0-.17-.01-.11,0-.19-.01-.27-.01-.07,0-.16,0-.28,0-.08,0-.14,0-.16,0-.05,0-.07-.01-.07-.04,0-.02.02-.03.05-.04.12-.01.19-.06.19-.15,0-.04-.02-.11-.06-.23-.04-.11-.08-.21-.12-.3h-.63c-.09.24-.13.4-.13.48,0,.06.02.1.07.13.04.03.09.05.14.06.04,0,.06.02.06.05,0,.03-.02.04-.06.04-.02,0-.06,0-.13,0-.09,0-.15-.01-.19-.01-.08,0-.15,0-.23.02-.02,0-.03,0-.05,0-.04,0-.06-.02-.06-.05,0-.02.01-.04.04-.05.06-.02.11-.04.15-.06.03-.03.07-.07.11-.15.04-.07.09-.19.15-.35.13-.36.32-.83.56-1.44Zm-.11.53l-.26.68h.54c-.03-.09-.07-.2-.13-.34-.06-.15-.11-.27-.15-.35Z"/>
            <path  d="m386,53.23l.06.44s0,.04,0,.05c0,.03,0,.05-.04.05-.02,0-.04-.02-.06-.05-.09-.16-.2-.28-.33-.38-.14-.1-.29-.14-.46-.13-.22,0-.38.11-.49.3-.11.19-.16.41-.15.66.02.28.1.5.24.67.15.17.31.25.49.23.08,0,.16-.02.23-.06.08-.04.13-.08.15-.12.02-.04.03-.1.02-.18,0-.17-.02-.28-.04-.32-.02-.04-.04-.07-.07-.08-.03-.02-.1-.02-.19-.03-.04,0-.06-.02-.06-.04,0-.03.02-.05.05-.05h.23s.7-.06.7-.06c.03,0,.05,0,.05.04,0,.02,0,.02-.02.03-.01,0-.05.02-.13.04-.05.02-.08.04-.1.06-.02.02-.02.18-.02.46,0,.06,0,.1,0,.11,0,0-.06.05-.17.11-.12.06-.23.12-.36.15-.12.04-.25.06-.37.06-.21,0-.4-.02-.57-.09-.17-.07-.31-.18-.41-.33-.11-.15-.17-.32-.18-.53-.02-.29.07-.55.26-.77.19-.22.46-.34.81-.36.12,0,.23,0,.34.02.1.02.22.06.34.11.03,0,.05.02.07.01.02,0,.04-.02.04-.05.01-.04.03-.06.06-.06.03,0,.05.03.06.09Z"/>
          </g>
          <path  d="m387.43,53.94c.09-.01.18-.04.27-.05.27-.03.5-.19.73-.36.14-.1.17-.22.11-.39-.19-.52-.56-.85-.97-1.14-.01-.01-.03-.02-.03-.04.19.05.32.21.49.32.05.03.1.11.15.05.06-.05.02-.13,0-.19-.02-.11-.03-.21-.04-.32.12.45.35.82.65,1.12.14.14.23.12.35-.03.2-.24.4-.46.43-.81.07.41.19.82-.11,1.24.09.03.17.06.25.08.08.02.08.1.08.17,0,.11-.08.05-.12.07-.23.1-.48.08-.68.3-.14.14-.34.12-.52.11-.36-.01-.71-.06-1.06-.15Z"/>
        </g>
        <g>
          <g>
            <path   d="m410.75,52.74l.06.44s0,.04,0,.05c0,.03,0,.05-.04.05s-.05-.02-.06-.05c-.08-.16-.19-.29-.33-.39-.13-.1-.28-.15-.45-.14-.22,0-.38.11-.49.29-.12.19-.18.4-.17.65,0,.28.09.5.22.67.14.17.3.25.49.25.08,0,.16-.02.23-.06.08-.04.13-.08.15-.12s.03-.1.03-.18c0-.17-.02-.28-.03-.32s-.04-.07-.07-.09c-.03-.01-.1-.02-.19-.03-.04,0-.06-.02-.06-.04,0-.03.02-.04.05-.05h.23s.7-.04.7-.04c.03,0,.05,0,.05.03,0,.02,0,.03-.02.03s-.06.02-.13.03c-.05.01-.09.03-.11.06-.01.02-.02.18-.02.46,0,.06,0,.1,0,.11,0,0-.06.04-.18.11s-.24.11-.36.15c-.12.03-.25.05-.37.05-.21,0-.4-.03-.57-.1-.16-.08-.3-.19-.41-.34-.11-.15-.16-.32-.17-.53,0-.29.08-.55.28-.77.19-.22.47-.33.82-.34.12,0,.24,0,.33.03.11.02.22.06.35.12.02.01.05.02.06.02.02,0,.04-.02.05-.05.01-.04.03-.06.06-.06.02,0,.05.03.05.09Z"/>
            <path   d="m412.36,53.55c.14.02.25.06.34.12.09.06.16.19.21.38.04.18.09.29.14.35.05.06.11.09.2.09.05,0,.07.02.07.05,0,.03-.04.04-.12.04h-.59c-.06-.06-.11-.18-.13-.33-.03-.2-.06-.34-.1-.42s-.08-.14-.14-.18c-.06-.03-.13-.05-.21-.05-.02,0-.06,0-.1,0,0,.36,0,.58.02.66,0,.08.02.14.05.18.03.03.09.06.18.06.04,0,.06.02.06.04,0,.03-.02.04-.06.04h-.47c-.06,0-.14,0-.24,0-.09,0-.14.01-.17.01-.04,0-.06-.02-.06-.04,0-.03.02-.05.06-.05.06,0,.11-.01.16-.04.05-.03.07-.15.06-.36v-.28s0-.55,0-.55c0-.28-.02-.45-.05-.52-.02-.06-.09-.1-.2-.1-.05,0-.07-.02-.07-.04s.03-.05.09-.04c.28,0,.53-.02.73-.03.09,0,.17,0,.24,0,.23,0,.41.04.54.13.13.09.2.21.2.36,0,.1-.03.19-.09.27-.06.08-.14.14-.23.18-.09.04-.2.07-.33.08Zm-.41-.06h.06c.09,0,.18-.02.27-.05.09-.04.15-.09.19-.16.04-.07.06-.15.06-.23,0-.13-.04-.23-.12-.29-.07-.07-.17-.11-.29-.1-.05,0-.11,0-.17.03v.82Z"/>
            <path   d="m415.14,52.56c.03.16.06.32.09.48,0,.03-.01.05-.05.04-.02,0-.03-.01-.05-.04-.11-.15-.2-.25-.29-.3-.08-.05-.21-.07-.37-.07-.09,0-.18,0-.27.02-.02.18-.03.38-.03.59,0,.04,0,.1,0,.19h.11c.07,0,.14,0,.19-.03.05-.02.1-.06.13-.13.04-.06.06-.11.06-.12,0-.03.02-.05.04-.05.03,0,.04.01.04.04,0,0,0,.02,0,.02-.01.06-.02.25-.02.56,0,.04,0,.08.02.12,0,0,0,.02,0,.02,0,.03,0,.04-.05.04-.02,0-.04-.03-.05-.08-.02-.1-.06-.17-.14-.21-.07-.05-.19-.08-.34-.08,0,.17,0,.28,0,.33,0,.23,0,.37.01.41,0,.04.03.08.08.11.04.03.12.04.23.04.39,0,.64-.12.76-.37.02-.03.04-.05.06-.05.03,0,.04.01.04.04,0,.02,0,.04,0,.06l-.1.44h-.56c-.15,0-.3,0-.43-.02-.13,0-.24,0-.32,0-.09,0-.21,0-.38.02-.02,0-.04,0-.05,0-.05,0-.07-.02-.07-.05,0-.03.02-.04.06-.04.1,0,.16-.03.2-.08.03-.05.05-.16.05-.33v-.78c.01-.27,0-.43,0-.49,0-.06-.02-.1-.05-.12-.03-.02-.09-.04-.16-.05-.03,0-.05-.02-.05-.04,0-.03.02-.05.06-.05h.37s1.23.02,1.23.02Z"/>
            <path   d="m417.2,52.59c.03.16.06.32.09.48,0,.03-.02.04-.04.04-.02,0-.04-.01-.06-.04-.1-.15-.2-.25-.28-.3-.09-.05-.21-.08-.37-.08-.09,0-.18,0-.27.02-.02.18-.03.38-.03.59,0,.04,0,.1,0,.19h.11c.07,0,.13,0,.19-.02.05-.02.1-.06.14-.12.03-.06.06-.11.06-.12,0-.03.03-.05.05-.05.03,0,.04.02.04.04,0,0,0,.02,0,.02-.02.06-.02.25-.03.57,0,.04,0,.08,0,.12,0,0,0,.02,0,.02,0,.03-.02.04-.05.04-.02,0-.04-.02-.05-.08-.02-.09-.06-.17-.13-.22-.07-.05-.19-.08-.34-.08,0,.17-.02.28-.02.34,0,.23,0,.37,0,.41,0,.04.03.08.08.11s.12.04.23.04c.39,0,.65-.11.77-.36.02-.03.04-.05.06-.05.02,0,.04.01.04.04,0,.02,0,.04,0,.06l-.11.44h-.55c-.15-.02-.3-.02-.43-.03-.13,0-.24-.01-.32-.01-.09,0-.22,0-.39,0-.02,0-.04,0-.05,0-.05,0-.07-.02-.07-.05,0-.03.02-.04.06-.04.1,0,.16-.02.2-.07.03-.05.05-.16.06-.33l.02-.78c0-.27,0-.43,0-.49,0-.06-.02-.1-.05-.13-.02-.02-.08-.04-.16-.05-.03,0-.05-.02-.05-.04,0-.03.02-.05.06-.05l.36.02,1.23.02Z"/>
            <path   d="m418.16,52.6c.47.57.86,1.03,1.17,1.37,0-.07,0-.11,0-.13l.02-.6c0-.14,0-.24-.01-.31-.01-.06-.05-.11-.1-.15-.05-.04-.11-.06-.18-.07-.04,0-.06-.02-.05-.05,0-.03.02-.04.05-.04.02,0,.04,0,.07,0,.06,0,.14.01.26.02.14,0,.25,0,.32,0h.08s.06.02.06.04c0,.03-.02.05-.08.05-.06,0-.13.03-.18.07s-.09.14-.09.28l-.02.86-.03.65c0,.06-.02.09-.06.08,0,0-.02,0-.03,0-.01,0-.07-.08-.2-.22-.12-.14-.3-.35-.54-.65-.24-.29-.41-.5-.51-.62,0,.03,0,.28-.03.77,0,.15,0,.27.01.35s.04.15.11.19c.06.04.12.06.18.06.03,0,.05.01.05.04,0,.03-.02.05-.06.05-.02,0-.05,0-.09,0-.06,0-.14-.02-.23-.02-.15,0-.26,0-.33,0-.04,0-.07,0-.09,0-.04,0-.06-.02-.06-.06,0-.02.04-.04.1-.04.11,0,.19-.04.22-.11.03-.07.05-.16.05-.29.02-.52.02-.84.02-.96s-.03-.23-.1-.32c-.06-.1-.15-.15-.24-.18-.04,0-.06-.02-.06-.05,0-.03.02-.05.06-.05.01,0,.02,0,.04,0s.06,0,.13,0c.13,0,.25,0,.37.02Z"/>
            <path   d="m421.68,52.72c.53.05.92.07,1.17.09.19.01.4.02.63.02.08,0,.15,0,.2,0,.02,0,.05,0,.1,0,0,.04,0,.13.01.26v.24s-.01.06-.04.06c-.02,0-.05-.03-.07-.08-.03-.08-.08-.16-.14-.22s-.12-.11-.2-.13c-.08-.02-.21-.04-.39-.05-.02.15-.04.35-.06.58,0,.24-.02.47-.02.69,0,.22,0,.36,0,.4.01.04.03.08.06.1.03.02.11.04.22.06.04,0,.06.02.06.05s-.02.04-.06.04h-.04s-.31-.03-.31-.03c-.24-.02-.39-.02-.45-.02h-.19s-.05,0-.09,0c-.02,0-.03-.02-.03-.04,0-.03.02-.04.06-.04.09,0,.16-.01.2-.05s.08-.09.1-.17c.02-.08.03-.26.05-.53.02-.43.03-.78.02-1.05-.17,0-.29,0-.36,0-.08,0-.15.04-.22.1-.07.06-.13.13-.18.23-.02.04-.04.06-.06.06-.04,0-.05-.02-.05-.05,0,0,0-.07.03-.2s.04-.23.06-.31Z"/>
            <path   d="m424.74,52.86h.11c.11.31.27.78.47,1.42.09.29.16.47.2.53.04.06.1.11.19.13.03,0,.04.02.04.04,0,.03-.02.05-.06.05-.02,0-.07,0-.16-.02-.11-.02-.19-.03-.27-.03-.07,0-.16,0-.28,0-.08,0-.14,0-.16,0-.05,0-.07-.02-.07-.05,0-.02.02-.03.05-.03.13,0,.19-.05.2-.14,0-.04-.01-.11-.04-.23-.03-.11-.06-.21-.1-.31-.06,0-.12,0-.15-.01-.2-.01-.36-.02-.47-.03-.11.23-.16.39-.16.46,0,.06.01.1.06.13.04.03.09.06.14.06.04,0,.06.02.05.05,0,.03-.02.04-.06.04-.02,0-.06,0-.13-.02-.09-.01-.15-.02-.19-.02-.08,0-.16,0-.23,0-.02,0-.04,0-.05,0-.04,0-.06-.02-.05-.05,0-.02.01-.04.04-.04.06-.01.11-.03.15-.05.04-.02.08-.07.12-.14.05-.07.1-.18.17-.34.15-.35.37-.81.66-1.4Zm-.14.53l-.31.66.54.03c-.02-.08-.06-.19-.11-.33-.05-.16-.1-.28-.12-.36Z"/>
            <path   d="m427.98,53.32v.45s0,.04,0,.05c0,.03-.02.04-.04.04-.02,0-.04-.02-.06-.06-.06-.17-.16-.31-.28-.42-.12-.11-.27-.18-.43-.19-.22-.02-.39.06-.53.23-.14.17-.22.38-.23.63-.02.28.02.51.15.69s.27.29.45.3c.08,0,.16,0,.24-.03.08-.03.14-.06.16-.1.02-.04.04-.1.05-.18.01-.17.02-.28,0-.32,0-.04-.03-.08-.06-.09-.03-.02-.1-.04-.19-.06-.04,0-.05-.02-.05-.05,0-.03.02-.04.06-.04l.23.02.7.04s.05.02.04.04c0,.02,0,.02-.02.03-.01,0-.06.01-.13.02-.06,0-.09.02-.11.05-.02.02-.04.17-.07.45,0,.06-.02.1-.02.11,0,0-.07.04-.19.09-.12.05-.25.09-.38.11-.13.02-.25.02-.37.02-.21-.02-.39-.07-.55-.17-.16-.09-.28-.22-.37-.38-.09-.15-.12-.34-.11-.54.02-.29.14-.54.36-.73s.5-.28.85-.25c.13.01.23.03.33.07.1.04.21.09.33.16.03.01.05.02.07.02.02,0,.03,0,.05-.04.02-.04.04-.06.06-.06.03,0,.04.03.04.1Z"/>
          </g>
          <path   d="m428.75,55.17c.09-.01.18-.04.27-.05.27-.02.5-.18.74-.33.14-.09.18-.22.12-.39-.18-.52-.53-.87-.94-1.16-.02-.01-.03-.02-.03-.04.19.05.32.22.48.33.05.03.09.11.15.06.06-.05.02-.13,0-.2-.01-.1-.03-.21-.03-.32.11.45.32.82.62,1.14.13.14.22.13.35-.02.2-.23.41-.45.45-.79.05.41.17.83-.14,1.24.09.03.17.06.25.08.08.02.08.1.08.18,0,.12-.08.05-.12.07-.23.09-.48.07-.69.27-.14.14-.34.11-.52.1-.36-.02-.7-.08-1.05-.17Z"/>
        </g>
        <g>
          <path   d="m465.65,112.5l-.43-.12s-.03,0-.04-.02c-.03-.01-.04-.03-.03-.06.01-.02.03-.04.07-.04.18-.02.34-.06.49-.15.14-.08.25-.2.31-.36.08-.2.06-.39-.07-.57-.12-.18-.3-.32-.53-.4-.26-.1-.49-.12-.7-.06-.21.06-.36.18-.42.35-.03.07-.04.15-.03.24,0,.09.02.15.05.19.03.04.08.07.15.1.16.06.26.1.31.1.04,0,.08-.01.11-.03.02-.02.06-.08.11-.16.02-.03.04-.05.06-.04.03.01.04.03.02.06l-.08.22-.24.66s-.03.04-.05.03c-.02,0-.02-.02-.02-.03,0-.02,0-.06.02-.13,0-.06,0-.1-.01-.12-.02-.02-.16-.09-.42-.2-.06-.02-.09-.04-.09-.04,0,0-.02-.07-.03-.2-.02-.14-.01-.26,0-.39.02-.13.05-.25.09-.36.08-.2.18-.36.32-.48.13-.13.29-.21.47-.25.18-.04.36-.02.55.05.28.11.48.29.6.56.12.26.12.56,0,.89-.05.11-.1.21-.16.3-.06.08-.14.17-.24.27-.02.02-.03.04-.04.06,0,.02,0,.04.02.06.03.03.04.05.03.07,0,.03-.05.03-.11.01Z"/>
          <path   d="m464.28,113.67c-.07.12-.15.21-.24.27-.09.06-.23.07-.43.04-.18-.03-.31-.03-.37,0-.07.02-.13.07-.16.15-.02.04-.04.06-.07.05-.03-.01-.03-.05,0-.12l.23-.54c.08-.03.19-.03.35,0,.2.05.34.07.43.08.09,0,.16-.02.21-.06.06-.04.1-.1.13-.17,0-.02.02-.05.03-.09-.33-.13-.53-.22-.61-.24-.08-.03-.14-.04-.19-.02-.05.02-.09.06-.12.14-.02.04-.04.06-.07.05-.02-.01-.03-.04-.02-.07l.19-.43c.02-.06.05-.13.08-.23.03-.08.05-.14.06-.16.02-.04.04-.05.07-.04.02.01.03.03.02.07-.02.05-.03.11-.02.16,0,.06.11.12.31.2l.26.11.51.21c.26.11.42.16.49.16.07,0,.12-.05.16-.15.02-.04.04-.06.07-.05.02.01.03.05,0,.1-.11.27-.19.49-.26.68-.03.08-.06.16-.08.22-.09.22-.2.36-.33.45-.13.09-.27.1-.4.05-.1-.04-.16-.1-.21-.19-.05-.09-.08-.18-.08-.28s.02-.21.06-.33Zm.22-.36s-.02.04-.02.06c-.03.08-.05.17-.06.27,0,.09.02.17.07.23.06.06.12.11.19.14.12.05.22.05.32,0,.09-.04.16-.12.21-.23.02-.05.03-.1.04-.17l-.75-.31Z"/>
          <path   d="m464.09,116.62c-.16-.03-.32-.06-.48-.1-.02,0-.03-.03-.02-.06,0-.02.02-.02.06-.04.18-.03.31-.08.39-.15.08-.06.15-.17.22-.32.04-.08.06-.17.08-.25-.16-.09-.34-.17-.53-.26-.03-.01-.09-.04-.17-.07l-.05.11c-.03.06-.04.13-.04.18,0,.06.02.11.06.17s.08.1.09.11c.03.02.03.04.03.06,0,.02-.03.03-.05.02,0,0-.01,0-.02-.02-.06-.03-.23-.11-.51-.24-.04-.02-.08-.03-.11-.03-.01,0-.02,0-.02,0-.03-.01-.03-.03-.02-.06,0-.02.04-.02.09-.01.1.02.18,0,.26-.05.07-.05.14-.14.2-.28-.15-.07-.26-.12-.31-.14-.21-.09-.34-.14-.38-.15-.04,0-.09,0-.13.03-.04.03-.09.09-.13.19-.15.36-.14.64.04.85.02.03.04.05.03.07,0,.03-.03.04-.05.03-.02,0-.03-.02-.05-.03l-.36-.26.22-.51c.06-.14.12-.27.18-.4.06-.12.1-.22.13-.29.03-.08.08-.2.14-.36,0-.02,0-.04.01-.05.02-.04.04-.06.07-.05s.03.04.02.07c-.04.09-.04.16,0,.21.04.05.13.11.28.17l.72.32c.24.11.4.17.45.18.06.02.11.02.14,0,.03-.02.07-.06.11-.13.02-.03.03-.04.06-.03.03.01.03.04.02.07l-.15.33-.49,1.13Z"/>
          <path   d="m463.27,118.49c-.16-.03-.32-.07-.47-.11-.03,0-.04-.03-.02-.06,0-.02.02-.02.06-.03.18-.03.31-.08.39-.14.08-.06.15-.16.22-.32.04-.08.07-.16.08-.25-.15-.09-.33-.18-.53-.26-.03-.02-.09-.04-.17-.07l-.05.11c-.03.06-.05.13-.05.19,0,.06.02.11.06.17.04.06.07.1.09.11.02.02.03.04.02.06-.01.03-.03.03-.05.02,0,0-.01,0-.02-.01-.05-.04-.22-.12-.51-.25-.04-.02-.08-.03-.11-.03-.01,0-.02,0-.02,0-.03-.01-.04-.03-.02-.06,0-.02.04-.02.1-.01.09.02.18,0,.25-.04.07-.05.14-.14.21-.28-.15-.07-.26-.12-.31-.14-.21-.1-.34-.15-.38-.16-.05-.01-.09,0-.13.03-.04.03-.08.09-.13.19-.16.35-.15.64.03.85.02.03.03.05.02.07-.01.02-.03.03-.05.02-.02,0-.03-.02-.05-.03l-.36-.27.23-.5c.06-.14.12-.27.19-.39s.11-.21.14-.28c.03-.08.08-.2.14-.36,0-.02.01-.03.02-.05.02-.04.04-.06.07-.04.02.01.03.03.01.07-.04.09-.04.16,0,.22.03.05.13.11.28.18l.71.32c.24.11.4.17.45.19.06.02.1.02.14,0,.03-.02.06-.06.11-.12.02-.03.04-.04.06-.03.03.01.03.04.02.07l-.16.33-.5,1.12Z"/>
          <path   d="m462.88,119.38c-.7.21-1.28.39-1.72.55.06.03.11.05.12.06l.54.25c.13.06.23.1.29.11.06,0,.12,0,.18-.03s.1-.08.13-.14c.02-.03.04-.04.06-.03.03.01.03.03.02.06,0,.02-.02.04-.03.06-.03.05-.07.13-.12.23-.06.13-.1.22-.12.29-.01.03-.02.05-.03.07-.02.04-.04.05-.06.03-.02,0-.03-.04-.01-.09.02-.06.02-.13,0-.2-.02-.07-.1-.13-.22-.19l-.79-.36-.58-.28c-.05-.02-.06-.05-.05-.08,0,0,0-.02.02-.02.01,0,.11-.04.28-.1.18-.06.45-.14.81-.25.36-.11.62-.19.77-.22-.02-.02-.26-.12-.69-.32-.14-.06-.24-.11-.33-.13-.08-.03-.15-.02-.22.03-.06.04-.11.09-.13.14-.02.03-.03.04-.06.03-.03-.01-.03-.04-.02-.07,0-.02.02-.05.04-.08.03-.05.07-.12.11-.2.06-.14.11-.24.13-.31.01-.04.02-.07.03-.09.02-.03.04-.05.07-.03.02.01.02.05,0,.11-.04.11-.03.19.02.24s.13.11.24.16c.48.22.77.36.88.4s.22.06.33.03c.11-.02.2-.07.26-.15.02-.03.04-.05.06-.03.03.01.04.04.02.08,0,0-.01.02-.02.04-.01.02-.03.06-.06.11-.06.12-.11.23-.16.33Z"/>
        </g>
        <g>
          <g>
            <path   d="m318.32,95.53l.4-.2s.03-.02.05-.02c.03,0,.05,0,.06.02.01.02,0,.05-.02.08-.13.13-.21.27-.27.43-.05.16-.05.32.02.48.08.2.22.33.43.38.21.05.44.03.67-.05.26-.1.45-.24.56-.43.12-.19.15-.37.08-.54-.03-.07-.07-.14-.13-.2s-.12-.1-.16-.11c-.05,0-.11,0-.18.03-.16.06-.26.11-.29.13-.03.02-.06.06-.06.09s0,.1.03.2c0,.04,0,.06-.02.07-.03,0-.05,0-.06-.04l-.08-.21-.26-.66s0-.05.02-.06c.02,0,.03,0,.04,0,0,.01.03.05.07.11.03.05.06.07.09.08.03,0,.18-.04.44-.13.06-.02.09-.03.1-.04,0,0,.06.05.16.14.1.09.19.19.26.29.07.11.13.22.17.33.07.19.1.39.08.57-.02.18-.08.35-.19.49-.11.15-.25.25-.45.33-.28.11-.55.1-.82-.02-.27-.11-.47-.33-.59-.66-.04-.11-.07-.23-.08-.33,0-.11,0-.23,0-.37,0-.02,0-.05,0-.06,0-.02-.03-.03-.06-.03-.04,0-.06-.01-.07-.03-.01-.03.01-.06.07-.08Z"/>
            <path   d="m318.57,93.81c-.03-.14-.02-.26,0-.36.03-.11.13-.22.29-.32.15-.1.25-.19.28-.25.04-.06.04-.14.02-.22-.01-.05,0-.07.02-.08.03,0,.06.02.08.09l.19.56c-.04.08-.13.15-.27.23-.18.1-.3.17-.36.23-.07.06-.11.12-.12.19-.01.07,0,.15.02.22,0,.02.02.05.04.08.34-.12.54-.19.62-.23.08-.03.13-.06.15-.11.02-.04.02-.1,0-.19,0-.05,0-.07.02-.08.02-.01.04,0,.06.04l.15.45c.02.06.05.13.09.22.04.08.06.14.07.16.02.04,0,.06-.02.07-.03,0-.05,0-.06-.04-.02-.05-.05-.1-.09-.13s-.16-.02-.36.06l-.27.1-.52.19c-.26.1-.42.17-.47.21-.05.05-.06.12-.02.22.02.04,0,.06-.02.07-.03.01-.05,0-.07-.06-.1-.27-.19-.49-.26-.68-.04-.08-.07-.15-.09-.22-.08-.22-.1-.4-.06-.56.04-.15.13-.25.27-.31.1-.03.19-.03.28,0,.1.03.18.08.25.15.07.07.13.17.18.29Zm.08.4s-.01-.04-.02-.05c-.03-.09-.07-.16-.14-.24-.06-.07-.13-.11-.21-.12s-.16,0-.24.02c-.12.05-.2.11-.24.21-.04.09-.04.2,0,.31.02.04.04.1.08.15l.76-.28Z"/>
            <path   d="m316.76,91.46c.14-.08.28-.15.42-.23.03,0,.05,0,.06.02,0,.02,0,.04-.02.07-.11.14-.18.27-.19.36-.02.1,0,.23.04.38.03.08.06.16.11.24.18-.03.36-.09.57-.15.03-.01.09-.03.18-.06l-.03-.11c-.02-.07-.05-.13-.09-.17s-.09-.07-.16-.09c-.07-.02-.12-.02-.14-.02-.04,0-.06,0-.06-.03,0-.03,0-.04.02-.05,0,0,.01,0,.02,0,.06,0,.24-.06.54-.15.04-.02.08-.03.11-.05,0,0,.02,0,.02,0,.03,0,.05,0,.06.03,0,.02-.02.04-.06.07-.09.05-.14.11-.16.2s-.02.2.03.35c.16-.05.27-.08.32-.09.22-.07.35-.12.39-.14.04-.02.07-.06.08-.11,0-.05,0-.13-.03-.23-.12-.36-.31-.57-.58-.61-.04,0-.06-.02-.06-.04,0-.03,0-.04.02-.05.02,0,.04,0,.06-.01l.45-.04.17.52c.05.15.09.29.12.42.04.13.06.23.09.3.02.08.07.21.13.36,0,.02.01.04.02.05.02.05,0,.07-.02.08-.03,0-.05,0-.06-.05-.03-.09-.07-.15-.14-.16s-.17,0-.33.05l-.75.23c-.26.08-.41.14-.47.16-.06.02-.09.06-.11.09-.01.03-.01.09,0,.16,0,.03,0,.05-.02.06-.03,0-.05,0-.06-.04l-.1-.35-.37-1.17Z"/>
            <path   d="m316.18,89.48c.14-.07.29-.15.43-.22.03,0,.05,0,.06.03,0,.02,0,.03-.03.06-.12.15-.19.26-.21.36-.02.1-.01.23.03.38.02.09.06.17.09.25.18-.03.37-.07.57-.14.04,0,.1-.02.18-.05l-.03-.11c-.02-.07-.05-.13-.08-.17-.03-.05-.08-.08-.16-.1-.07-.02-.12-.03-.14-.03-.03,0-.05,0-.06-.03,0-.03,0-.04.02-.05,0,0,.01,0,.02,0,.06,0,.24-.05.55-.13.04-.02.08-.03.11-.05,0,0,.02,0,.02,0,.03,0,.05,0,.05.04,0,.02-.01.04-.06.07-.09.05-.14.11-.17.19-.02.08-.02.19.02.35.17-.04.28-.07.33-.08.22-.06.36-.11.4-.12s.06-.05.08-.11c.01-.04,0-.12-.02-.23-.11-.37-.29-.58-.56-.64-.04,0-.06-.02-.06-.04,0-.03,0-.05.02-.06.02,0,.04,0,.06,0l.45-.02.15.53c.04.15.08.29.11.42s.06.23.08.31c.02.08.06.2.12.37,0,.02.01.04.02.05,0,.04,0,.07-.03.08-.02,0-.05,0-.06-.05-.03-.09-.07-.15-.13-.16-.06-.02-.17,0-.33.04l-.76.2c-.26.06-.41.11-.47.14-.06.02-.09.05-.11.08-.01.03-.02.09,0,.17,0,.03,0,.05-.03.06-.03,0-.05,0-.06-.04l-.09-.36-.33-1.19Z"/>
            <path   d="m315.95,88.53c.42-.61.76-1.11,1-1.5-.07.02-.11.02-.13.03l-.59.14c-.14.03-.23.06-.29.1-.06.03-.09.07-.11.14-.02.06-.03.12-.02.19,0,.04,0,.06-.03.07-.03,0-.05,0-.06-.04,0-.02,0-.04,0-.07,0-.06-.02-.14-.05-.25-.03-.14-.06-.24-.09-.31,0-.03-.02-.05-.02-.07,0-.04,0-.06.03-.07.02,0,.05.02.06.06.02.06.06.11.12.15.06.04.16.05.29.01l.83-.21.63-.15c.06,0,.08,0,.09.03,0,0,0,.02,0,.03,0,.01-.06.1-.15.25-.1.15-.26.39-.48.7-.21.31-.37.53-.46.66.02,0,.28-.06.74-.18.15-.04.26-.07.34-.1.08-.03.13-.08.15-.16.02-.07.02-.14.01-.19,0-.03,0-.05.03-.06.03,0,.05,0,.06.04,0,.02,0,.05.02.09,0,.06.02.14.04.23.03.15.06.25.09.32.01.04.02.07.03.09,0,.04,0,.06-.03.07-.02,0-.04-.02-.06-.08-.04-.11-.09-.17-.17-.18-.07,0-.17,0-.29.02-.51.12-.82.2-.93.23-.11.03-.2.1-.28.18s-.11.18-.11.28c0,.04,0,.06-.03.07-.03,0-.06,0-.06-.05,0,0,0-.02,0-.04,0-.02-.01-.07-.02-.13-.03-.13-.06-.25-.08-.36Z"/>
            <path   d="m315.14,85.05c-.08-.52-.15-.9-.19-1.15-.03-.19-.08-.4-.14-.62-.02-.08-.03-.15-.04-.2,0-.02,0-.06-.02-.1.04-.02.12-.04.25-.07l.23-.07s.06,0,.06.04c0,.02-.02.05-.06.08-.07.05-.14.11-.18.19-.05.07-.07.15-.07.23,0,.08,0,.21.04.39.16,0,.35-.04.58-.08.23-.04.46-.09.68-.14s.35-.09.39-.11.07-.05.09-.08c.01-.03.01-.11,0-.23,0-.04,0-.06.03-.07.03,0,.05,0,.05.05v.04s.06.31.06.31c.05.24.08.39.09.45l.04.18s0,.05.02.1c0,.02,0,.04-.03.04-.03,0-.05-.01-.05-.05-.02-.09-.05-.15-.1-.19-.04-.04-.11-.06-.19-.05-.08,0-.25.03-.53.08-.42.08-.77.16-1.03.23.03.16.06.28.09.35.03.07.08.14.15.19s.16.1.27.12c.04,0,.06.02.07.05,0,.04,0,.06-.04.06,0,0-.07,0-.2.02-.13,0-.23.02-.32.01Z"/>
            <path   d="m314.54,81.99l-.02-.1c.27-.18.68-.45,1.26-.79.26-.16.42-.27.47-.32.05-.06.08-.13.07-.22,0-.03.01-.05.03-.05.03,0,.05,0,.06.05,0,.02,0,.08.02.17,0,.11.02.19.03.27s.03.16.06.27c.02.08.03.14.04.16,0,.05,0,.07-.03.08-.02,0-.04,0-.05-.04-.03-.12-.09-.18-.18-.16-.04,0-.11.03-.21.09-.1.05-.19.11-.28.17.01.06.02.11.02.15.04.2.06.36.08.47.25.05.41.06.49.05.06,0,.1-.04.12-.09.02-.05.03-.1.03-.15,0-.04,0-.06.04-.06s.04.01.05.05c0,.02,0,.06.02.13,0,.09.01.15.02.19.02.08.03.15.06.22,0,.02,0,.03.01.05,0,.03,0,.05-.04.06-.02,0-.04,0-.05-.03-.03-.05-.06-.1-.09-.12-.03-.03-.08-.06-.16-.09-.08-.03-.2-.05-.36-.08-.37-.06-.87-.17-1.51-.31Zm.54.02l.72.15-.1-.53c-.07.04-.17.11-.3.19-.14.09-.24.15-.32.2Z"/>
            <path   d="m314.24,78.79l.43-.11s.04,0,.05-.01c.03,0,.05,0,.06.03,0,.02-.01.05-.04.07-.15.11-.27.23-.35.37-.08.14-.11.3-.08.46.03.22.15.37.35.46.2.1.42.12.67.09.27-.04.49-.15.64-.31.15-.16.22-.33.19-.51-.01-.08-.04-.15-.09-.23s-.09-.12-.14-.13c-.05-.02-.11-.02-.18,0-.17.02-.27.05-.31.07-.04.02-.06.04-.08.07,0,.04-.02.1,0,.2,0,.04-.01.06-.03.06-.03,0-.05,0-.05-.04l-.03-.23-.12-.69s0-.05.03-.05c.02,0,.03,0,.03.01,0,.01.02.05.05.12.02.05.04.08.07.1.02,0,.18,0,.46-.04.06,0,.1-.01.11,0,0,0,.05.06.13.16.08.11.14.22.19.34.05.12.08.24.1.36.03.21.02.4-.04.57-.06.18-.15.33-.28.45-.14.12-.3.2-.51.23-.29.04-.55-.02-.79-.18-.24-.17-.39-.43-.44-.77-.02-.12-.02-.24,0-.34.01-.11.04-.22.07-.35,0-.03,0-.05,0-.07s-.02-.03-.05-.04c-.04,0-.06-.02-.06-.05,0-.03.02-.05.09-.06Z"/>
          </g>
          <path   d="m316.64,77.47c-.03-.08-.08-.16-.11-.25-.1-.26-.31-.44-.53-.62-.13-.11-.26-.11-.41,0-.46.31-.69.74-.88,1.2,0,.02-.02.03-.03.04,0-.2.13-.37.19-.56.02-.05.09-.12.02-.16-.06-.04-.13.02-.19.04-.1.04-.19.08-.3.12.41-.23.71-.53.93-.91.1-.16.06-.25-.11-.33-.27-.13-.54-.27-.88-.22.38-.16.75-.38,1.22-.2,0-.09.02-.18.02-.26,0-.08.08-.1.15-.12.11-.03.07.07.09.1.15.2.19.44.45.59.17.1.2.3.24.47.08.35.11.7.11,1.06Z"/>
        </g>
        <g>
          <path   d="m480.52,73.64l-.44-.06s-.03,0-.05,0c-.02,0-.04-.03-.03-.05s.03-.04.06-.05c.18-.04.33-.11.46-.21.13-.11.21-.24.25-.4.05-.21,0-.4-.15-.56-.15-.16-.34-.27-.58-.33-.27-.06-.5-.05-.7.04-.21.09-.33.23-.37.4-.02.08-.02.16,0,.24.01.09.04.15.08.18.03.03.09.06.16.07.17.04.27.06.32.06.04,0,.08-.02.1-.05.02-.03.05-.09.08-.18.02-.04.03-.05.06-.05.03,0,.04.03.03.06l-.05.23-.15.69s-.02.04-.05.04c-.02,0-.02-.01-.03-.02,0-.02,0-.06,0-.13,0-.06,0-.1-.03-.11-.02-.02-.17-.07-.44-.14-.06-.02-.1-.02-.1-.03,0,0-.03-.07-.06-.2-.03-.13-.05-.26-.05-.39,0-.13.02-.25.04-.37.05-.2.13-.38.25-.52.12-.14.26-.24.43-.3.17-.06.36-.07.56-.02.28.07.51.22.67.47.16.24.2.54.12.88-.03.12-.07.23-.12.32s-.12.19-.2.3c-.02.02-.03.04-.03.06,0,.02,0,.04.03.06.03.02.05.04.04.07,0,.02-.04.03-.11.02Z"/>
          <path   d="m479.36,74.94c-.05.13-.11.23-.2.3-.08.07-.22.11-.42.11-.18,0-.3.02-.37.05-.06.03-.11.09-.14.17-.02.05-.04.06-.06.06-.03,0-.03-.05-.02-.12l.15-.57c.07-.04.19-.06.35-.04.2.02.35.02.44.01.08-.01.15-.04.2-.09.05-.05.09-.12.1-.19,0-.02.01-.06.02-.09-.35-.09-.56-.14-.64-.15-.09-.02-.15-.01-.19,0-.04.02-.07.07-.1.16-.02.04-.04.06-.06.06s-.04-.03-.02-.07l.12-.45c.02-.06.03-.14.05-.23.02-.09.03-.15.03-.17,0-.04.03-.06.06-.05s.04.03.03.06c-.01.06-.01.11,0,.16.02.06.13.11.33.16l.28.06.53.13c.28.06.45.1.51.09.07,0,.11-.07.14-.17.01-.04.03-.06.06-.05.03,0,.03.04.02.09-.06.28-.12.52-.15.71-.01.09-.03.17-.05.23-.05.23-.14.39-.26.5-.12.11-.25.14-.4.11-.1-.02-.18-.07-.24-.15-.06-.08-.1-.17-.12-.27-.01-.1-.02-.21,0-.33Zm.16-.39s-.02.04-.02.06c-.02.09-.02.18-.02.27.02.09.05.17.11.22.06.05.13.09.21.11.12.03.23.02.32-.04.09-.06.15-.14.17-.26.01-.05.02-.1.01-.17l-.79-.19Z"/>
          <path   d="m479.66,77.82c-.16,0-.32-.02-.49-.02-.03,0-.04-.02-.04-.05,0-.02.02-.03.06-.04.17-.06.29-.13.36-.21.07-.07.12-.19.16-.35.02-.09.04-.18.04-.27-.17-.06-.36-.11-.57-.16-.04,0-.1-.02-.18-.04l-.03.11c-.02.07-.02.13-.02.19,0,.06.04.11.09.16s.09.08.11.09c.03.02.04.04.03.06,0,.03-.02.04-.05.03,0,0,0,0-.02,0-.06-.03-.24-.08-.54-.15-.05-.01-.08-.02-.12-.02,0,0-.02,0-.03,0-.03,0-.03-.02-.03-.06,0-.02.04-.03.09-.03.1,0,.18-.03.24-.09.07-.06.12-.16.16-.31-.17-.05-.28-.07-.33-.09-.22-.05-.36-.08-.4-.09-.04,0-.08.01-.12.05-.03.04-.06.11-.09.21-.09.37-.03.65.18.83.03.02.04.05.03.06,0,.03-.02.04-.04.04-.02,0-.04-.01-.06-.02l-.4-.2.14-.53c.03-.15.07-.29.11-.42.04-.13.06-.23.08-.3.02-.08.05-.21.08-.38,0-.02,0-.04,0-.05,0-.05.03-.06.06-.06.03,0,.04.03.02.06-.02.1-.01.17.03.22.05.05.15.09.31.12l.76.2c.26.06.42.1.48.11.06,0,.11,0,.13-.02.03-.02.06-.07.09-.14,0-.03.03-.05.05-.04.03,0,.04.02.03.06l-.1.36-.29,1.19Z"/>
          <path   d="m479.15,79.86c-.16,0-.32-.02-.49-.02-.03,0-.04-.02-.03-.05,0-.02.02-.03.06-.04.17-.07.29-.14.36-.21.06-.07.12-.19.16-.35.02-.09.03-.18.04-.26-.17-.06-.36-.12-.57-.17-.03,0-.1-.02-.18-.04l-.03.11c-.02.07-.02.13-.02.19s.03.11.09.16c.05.05.09.08.11.09.03.02.04.04.03.06,0,.03-.02.04-.04.03,0,0-.01,0-.02,0-.06-.03-.24-.08-.54-.15-.04-.01-.08-.02-.12-.02,0,0-.02,0-.03,0-.03,0-.03-.02-.02-.06,0-.02.03-.03.09-.03.09,0,.17-.03.24-.08.06-.06.11-.16.15-.32-.16-.04-.27-.07-.32-.09-.23-.06-.36-.09-.4-.09-.05,0-.09.02-.12.05-.03.04-.07.11-.09.21-.09.38-.04.65.17.83.03.02.04.05.03.06,0,.03-.02.04-.04.03-.02,0-.03-.01-.06-.02l-.4-.2.13-.53c.04-.15.08-.29.12-.42s.07-.23.08-.3c.02-.08.05-.21.08-.38,0-.02,0-.04,0-.05.01-.05.03-.06.06-.06.03,0,.03.03.03.06-.02.1-.02.17.03.21s.15.09.3.13l.76.2c.26.06.42.1.49.11.06,0,.1,0,.13-.02.03-.02.06-.07.09-.14,0-.03.03-.04.05-.04.03,0,.04.03.03.07l-.1.35-.3,1.19Z"/>
          <path   d="m478.94,80.8c-.66.34-1.19.62-1.59.85.06.02.11.03.13.03l.58.15c.14.03.23.05.3.05.06,0,.12-.02.17-.06.05-.04.08-.1.11-.16.01-.03.03-.05.06-.04.03,0,.04.03.03.06,0,.02-.01.04-.02.07-.02.06-.05.14-.07.24-.04.13-.06.24-.07.31,0,.03-.01.06-.02.07,0,.04-.02.06-.06.05-.03,0-.04-.03-.03-.09,0-.06,0-.13-.03-.19-.03-.07-.11-.12-.25-.15l-.83-.21-.62-.17c-.06-.01-.08-.04-.07-.07,0,0,0-.02.02-.03,0-.01.09-.06.25-.15.16-.09.41-.22.75-.39.34-.18.58-.29.72-.36-.03,0-.27-.07-.74-.19-.14-.03-.26-.06-.35-.07-.08,0-.15.02-.21.07-.05.05-.09.11-.1.16,0,.03-.03.05-.05.04-.03,0-.04-.03-.03-.06,0-.02.02-.05.03-.08.02-.06.04-.13.07-.22.04-.15.06-.26.07-.32,0-.04.01-.07.02-.09,0-.04.03-.05.06-.05.02,0,.02.04.02.11-.02.11,0,.19.06.24.06.05.15.08.27.11.51.13.82.21.93.23.11.02.23.02.33-.03.11-.04.19-.11.23-.19.02-.03.04-.05.06-.04.03,0,.05.03.03.07,0,0,0,.02-.01.04,0,.02-.02.06-.03.12-.03.13-.06.25-.1.36Z"/>
          <path   d="m478.05,84.2c-.15.5-.26.88-.33,1.12-.05.19-.1.39-.15.62-.02.08-.04.15-.05.2,0,.02-.01.05-.03.1-.04,0-.13-.02-.26-.04l-.23-.04s-.06-.03-.05-.06c0-.02.04-.04.09-.05.09-.02.17-.05.24-.09.07-.04.13-.1.17-.17.04-.07.08-.19.13-.37-.15-.06-.33-.12-.56-.18-.23-.06-.45-.12-.67-.17-.21-.05-.34-.08-.39-.07-.05,0-.09.02-.11.04-.03.02-.06.09-.1.2-.02.03-.03.05-.06.05-.03,0-.04-.03-.03-.06v-.04s.11-.3.11-.3c.06-.23.1-.38.12-.43l.04-.19s0-.05.03-.09c0-.02.02-.03.04-.02.03,0,.04.03.03.07-.02.09-.02.16,0,.21.02.05.07.1.15.13.07.03.24.08.51.15.41.12.76.2,1.03.24.04-.16.07-.28.08-.36s0-.15-.05-.24c-.04-.08-.11-.16-.19-.23-.04-.03-.05-.05-.04-.07,0-.03.03-.05.06-.04.01,0,.07.02.19.07.12.04.22.08.29.12Z"/>
          <path   d="m477.26,87.19l-.03.1c-.32.04-.81.09-1.48.15-.31.02-.49.05-.56.07-.07.02-.13.07-.17.16-.01.02-.03.04-.05.03-.03,0-.04-.03-.03-.07,0-.02.03-.07.06-.16.04-.1.07-.18.09-.25.02-.06.04-.15.07-.26.02-.08.03-.14.04-.16,0-.05.03-.06.06-.06.02,0,.03.02.02.06-.03.12,0,.2.09.22.04.01.11.02.23.01.12,0,.22-.01.32-.02.02-.06.03-.11.04-.15.06-.2.11-.35.14-.46-.2-.15-.34-.24-.42-.26-.05-.02-.1,0-.14.02-.04.03-.07.07-.09.12-.02.04-.03.05-.06.04-.03,0-.03-.03-.02-.07,0-.02.02-.06.04-.12.03-.09.05-.15.06-.19.02-.08.04-.15.05-.22,0-.02,0-.03,0-.05.01-.03.03-.05.06-.04.02,0,.03.02.03.05,0,.06,0,.11.02.15.01.04.05.09.1.15.06.06.15.14.29.24.31.22.71.54,1.22.96Zm-.48-.25l-.57-.45-.15.52c.09,0,.2-.01.36-.03.16-.02.29-.03.37-.03Z"/>
          <path   d="m476.06,90.13l-.44-.11s-.03,0-.04-.01c-.03,0-.04-.03-.03-.05,0-.02.03-.04.07-.04.18-.02.34-.07.48-.16s.24-.21.29-.37c.07-.2.04-.4-.09-.57-.14-.18-.32-.3-.55-.39-.26-.09-.49-.1-.7-.03-.22.07-.35.19-.41.36-.03.07-.04.16-.03.24,0,.09.03.15.06.19s.08.06.15.09c.16.06.27.09.31.09.04,0,.08,0,.11-.04.02-.02.06-.08.11-.17.02-.03.03-.05.06-.04s.04.03.02.06l-.08.22-.22.67s-.03.04-.05.03c-.02,0-.02-.02-.02-.03,0,0,0-.06.01-.13,0-.06,0-.09-.02-.12-.02-.02-.16-.08-.42-.18-.06-.02-.09-.04-.1-.04,0,0-.02-.07-.04-.2s-.02-.26,0-.39c0-.13.04-.25.08-.37.07-.2.17-.36.3-.49s.28-.21.46-.26c.17-.05.36-.03.56.03.28.1.48.28.61.53.14.26.15.56.03.89-.04.11-.09.22-.15.3-.06.09-.14.18-.23.28-.02.02-.03.04-.04.06,0,.02,0,.04.03.06.03.02.04.05.03.07,0,.02-.04.03-.11.02Z"/>
        </g>
        <g>
          <g>
            <path   d="m452.44,54.45v.44s-.01.04-.01.05c0,.03-.02.05-.05.04-.03,0-.04-.02-.06-.06-.06-.17-.15-.32-.27-.43-.12-.12-.26-.19-.42-.21-.22-.03-.4.05-.54.21-.14.17-.23.37-.26.62-.03.27,0,.51.12.7.11.19.26.3.45.32.08,0,.16,0,.24-.03.08-.02.14-.06.17-.1s.05-.1.06-.17c.02-.17.02-.28.02-.32-.01-.04-.03-.07-.06-.1-.03-.02-.09-.04-.19-.06-.04,0-.05-.02-.05-.05,0-.03.02-.04.06-.04l.23.03.7.07s.04.02.04.04c0,.02,0,.02-.02.03-.01,0-.06.01-.13.02-.06,0-.09.02-.11.04-.02.02-.05.17-.09.45,0,.06-.02.1-.02.11s-.06.03-.19.08c-.13.05-.25.08-.38.1-.13.01-.25.02-.37,0-.21-.02-.39-.09-.54-.19-.15-.1-.27-.23-.35-.39-.08-.16-.11-.35-.09-.55.03-.29.16-.53.39-.72.22-.19.51-.26.86-.22.12.01.23.04.33.08.1.04.2.1.32.17.02.01.05.02.06.02.02,0,.04-.01.06-.04.02-.03.04-.05.06-.05.03,0,.04.03.04.1Z"/>
            <path   d="m453.91,55.48c.13.04.24.1.32.17.08.08.13.21.15.4.02.18.05.3.09.36s.1.1.19.12c.05,0,.07.03.07.06,0,.03-.05.04-.12.03l-.58-.1c-.05-.07-.07-.18-.08-.34,0-.2-.01-.35-.03-.43s-.06-.15-.11-.19c-.06-.04-.12-.07-.2-.08-.03,0-.06,0-.1,0-.05.36-.07.57-.08.66s0,.15.02.18c.02.04.08.07.17.08.04,0,.06.03.06.06,0,.03-.03.04-.07.03l-.46-.07c-.06-.01-.14-.02-.24-.03-.09,0-.15,0-.17-.02-.04,0-.06-.02-.06-.05,0-.03.02-.04.06-.04.06,0,.11,0,.17-.02.05-.02.09-.14.12-.35l.04-.28.07-.54c.03-.28.05-.45.03-.52-.02-.06-.08-.11-.19-.12-.04,0-.06-.02-.05-.05,0-.03.03-.04.09-.03.28.04.53.06.72.08.09,0,.17.01.24.02.23.03.4.1.52.21.11.11.17.23.15.38-.02.1-.06.18-.13.25-.07.07-.15.12-.25.15-.1.02-.21.03-.33.02Zm-.4-.12s.04,0,.06,0c.08.01.18,0,.27-.01s.16-.06.21-.13c.05-.06.08-.14.09-.22.02-.12,0-.23-.07-.31-.06-.08-.15-.13-.27-.14-.05,0-.11,0-.17,0l-.11.81Z"/>
            <path   d="m456.8,54.92c0,.16.02.32.02.48,0,.03-.02.04-.05.04-.02,0-.03-.02-.05-.05-.08-.17-.16-.28-.24-.34-.08-.06-.2-.11-.36-.13-.09-.02-.18-.02-.26-.02-.05.18-.08.37-.11.58,0,.04-.01.1-.02.18l.11.02c.07,0,.13,0,.19,0,.05-.01.1-.05.15-.11.05-.06.07-.1.08-.11.02-.03.03-.05.06-.04.02,0,.04.02.04.04,0,0,0,.02,0,.02-.02.06-.06.24-.11.55,0,.04,0,.08,0,.12,0,0,0,.02,0,.02,0,.03-.02.04-.06.03-.02,0-.03-.03-.04-.09,0-.1-.04-.17-.11-.23-.06-.06-.17-.1-.33-.12-.03.17-.05.28-.06.33-.03.23-.05.37-.05.41,0,.04.02.08.06.11s.11.06.22.07c.38.06.65-.03.81-.25.02-.03.05-.05.06-.04.03,0,.04.02.04.04,0,.02,0,.04-.02.06l-.16.42-.55-.08c-.15-.02-.3-.05-.43-.07-.13-.03-.23-.05-.31-.06-.08-.02-.21-.03-.38-.04-.02,0-.04,0-.05,0-.05,0-.07-.03-.06-.06,0-.03.02-.04.06-.03.1.02.16,0,.21-.05.04-.05.07-.15.1-.32l.12-.77c.04-.27.06-.43.07-.49s0-.11-.03-.13c-.02-.02-.08-.05-.15-.07-.03-.01-.05-.03-.04-.05,0-.03.02-.04.06-.04l.36.06,1.21.19Z"/>
            <path   d="m458.84,55.25c0,.16.01.32.02.48,0,.03-.02.04-.05.04-.02,0-.03-.02-.05-.05-.08-.17-.15-.28-.23-.34-.08-.06-.2-.11-.36-.13-.09-.02-.18-.02-.27-.02-.05.18-.09.37-.12.58,0,.03-.01.1-.02.18l.11.02c.07,0,.13,0,.19,0,.05-.01.1-.05.15-.11.04-.06.07-.1.08-.11.02-.03.03-.04.06-.04.03,0,.04.02.04.04,0,0,0,.02,0,.02-.02.06-.06.25-.11.56,0,.04,0,.08,0,.12,0,.01,0,.02,0,.02,0,.03-.02.04-.06.04-.02,0-.03-.03-.04-.09,0-.1-.04-.18-.1-.23-.06-.06-.17-.11-.32-.14-.03.17-.05.28-.06.33-.04.23-.06.36-.06.41,0,.04.02.08.06.12.04.03.11.06.22.07.38.06.65-.01.81-.24.02-.03.04-.05.06-.04.03,0,.04.02.04.04,0,.02,0,.03-.02.06l-.17.42-.54-.09c-.15-.02-.3-.06-.43-.08-.13-.03-.23-.05-.31-.06-.08-.02-.21-.03-.38-.05-.02,0-.04,0-.05,0-.05,0-.06-.02-.06-.06s.03-.04.06-.03c.1.02.17,0,.21-.04.04-.05.07-.15.1-.32l.14-.77c.04-.26.07-.43.07-.49,0-.06,0-.11-.03-.13-.03-.02-.08-.05-.15-.07-.03,0-.05-.02-.04-.05,0-.03.02-.04.06-.03l.36.07,1.21.2Z"/>
            <path   d="m459.78,55.4c.38.63.7,1.14.96,1.53.01-.07.02-.11.02-.13l.11-.59c.03-.14.04-.24.03-.31,0-.06-.03-.12-.07-.16-.05-.05-.1-.08-.17-.1-.04,0-.05-.03-.05-.06,0-.03.02-.04.06-.04.02,0,.04.01.07.02.06.02.14.03.25.05.14.03.24.04.32.05.03,0,.06,0,.08,0,.03,0,.05.02.05.05,0,.03-.03.04-.08.04-.06,0-.13,0-.19.04-.06.04-.11.13-.13.27l-.15.85-.12.64c-.01.06-.03.08-.06.07-.01,0-.02,0-.03-.02,0,0-.06-.09-.17-.24-.1-.15-.25-.39-.45-.72-.19-.32-.33-.56-.41-.69,0,.02-.05.28-.14.75-.03.15-.04.26-.05.35,0,.09.02.15.09.2.06.05.11.08.17.09.03,0,.05.02.04.05,0,.03-.02.04-.06.03-.02,0-.05,0-.09-.02-.06-.02-.13-.04-.23-.05-.15-.03-.26-.04-.32-.05-.05,0-.07,0-.09,0-.04,0-.06-.03-.05-.06,0-.02.04-.03.11-.02.11,0,.19-.02.23-.08.04-.06.08-.15.1-.28.1-.51.15-.83.16-.95s0-.23-.05-.33c-.05-.11-.12-.18-.21-.21-.04-.02-.06-.03-.05-.06,0-.03.03-.05.07-.04.01,0,.02,0,.04,0,.02,0,.06.02.12.03.13.02.25.05.36.07Z"/>
            <path   d="m463.26,56.05c.51.12.9.21,1.14.26.19.04.4.07.63.11.08.01.15.02.2.04.02,0,.05.01.1.02,0,.04-.01.13-.03.26l-.03.24s-.03.06-.06.05c-.03,0-.05-.03-.06-.09-.02-.09-.05-.16-.1-.23s-.11-.12-.18-.16c-.07-.04-.2-.07-.38-.11-.05.15-.1.33-.14.57-.05.23-.09.46-.13.68-.04.22-.05.35-.05.4,0,.05.02.09.04.11.03.03.1.06.21.09.04.02.06.03.05.05,0,.03-.02.04-.06.03h-.04s-.31-.08-.31-.08c-.24-.05-.39-.08-.44-.09l-.19-.03s-.05-.01-.09-.02c-.02,0-.03-.02-.03-.04,0-.03.03-.04.07-.03.09.02.16.01.21-.01.05-.03.09-.08.12-.16.02-.07.06-.25.12-.52.09-.42.15-.77.18-1.04-.16-.03-.29-.05-.36-.05-.08,0-.15.02-.23.07-.08.04-.15.11-.22.2-.02.03-.05.05-.07.05-.03,0-.05-.03-.04-.06,0-.01.02-.07.06-.19.03-.12.07-.22.11-.3Z"/>
            <path   d="m466.26,56.64l.1.02c.07.31.15.8.26,1.46.05.31.09.49.11.56.03.07.09.12.18.15.03,0,.04.03.04.05,0,.03-.03.04-.07.03-.02,0-.07-.02-.16-.05-.1-.03-.18-.06-.26-.07-.07-.02-.15-.03-.27-.05-.08-.01-.14-.02-.16-.03-.05,0-.07-.03-.06-.06,0-.02.02-.03.05-.03.12.02.19-.02.21-.1,0-.04,0-.12,0-.23-.01-.11-.03-.22-.05-.32-.06-.02-.11-.03-.15-.04-.2-.04-.36-.08-.46-.1-.14.21-.21.36-.23.44,0,.05,0,.1.03.14.04.04.08.07.13.08.03.01.05.03.05.06,0,.03-.03.04-.07.02-.02,0-.06-.01-.12-.03-.09-.02-.15-.04-.19-.05-.08-.02-.15-.03-.23-.03-.02,0-.03,0-.05,0-.04,0-.05-.02-.04-.05,0-.02.02-.04.04-.04.06,0,.11-.02.15-.03.04-.02.09-.06.14-.12.06-.06.13-.16.22-.31.2-.32.49-.75.86-1.28Zm-.22.49l-.4.61.53.11c-.02-.08-.03-.2-.06-.35-.03-.16-.05-.28-.06-.37Z"/>
            <path   d="m469.39,57.58l-.06.44s0,.03,0,.04c0,.03-.02.05-.05.04-.02,0-.04-.02-.05-.06-.04-.18-.11-.33-.21-.46-.11-.13-.23-.21-.4-.25-.21-.05-.4,0-.56.15-.16.15-.27.34-.33.58-.06.27-.05.5.04.71.09.2.23.32.4.37.07.02.16.02.24,0,.08-.02.15-.04.18-.07.03-.04.06-.09.08-.17.03-.17.06-.27.05-.32,0-.04-.02-.08-.04-.1-.03-.02-.09-.05-.18-.08-.04-.01-.05-.03-.04-.06,0-.03.02-.04.06-.03l.22.05.69.15s.04.02.03.05c0,.02-.01.02-.02.03,0,0-.06,0-.13,0-.06,0-.1,0-.11.02-.02.02-.07.17-.14.44-.02.06-.03.09-.03.1s-.07.03-.2.06c-.13.03-.26.05-.39.05-.13,0-.25-.01-.37-.04-.2-.05-.37-.13-.52-.25s-.24-.26-.31-.43c-.06-.17-.07-.36-.02-.56.07-.28.22-.51.47-.67.24-.16.54-.2.88-.12.12.03.23.06.32.12.09.05.19.12.3.2.02.02.04.03.06.03.02,0,.04,0,.05-.04.02-.03.05-.04.07-.04.03,0,.04.04.03.11Z"/>
          </g>
          <path   d="m469.83,59.49c.09,0,.18-.01.27,0,.27.02.52-.1.78-.22.16-.07.21-.19.18-.37-.1-.54-.4-.94-.75-1.29-.01-.01-.02-.02-.03-.04.18.08.28.27.43.4.04.04.08.13.14.09.06-.04.04-.13.04-.19,0-.1,0-.21.01-.32.04.46.2.86.45,1.22.11.16.2.16.35.03.23-.19.47-.38.56-.72,0,.42.04.84-.32,1.2.09.04.16.08.23.12.08.03.06.11.05.19-.01.12-.09.04-.13.05-.24.06-.49,0-.72.17-.16.11-.35.06-.53.02-.35-.07-.68-.19-1.02-.33Z"/>
        </g>
        <g>
          <g>
            <path   d="m330.92,128.12l.37-.25s.03-.02.04-.02c.03-.02.05-.01.06.02.01.02,0,.05-.02.08-.1.15-.17.3-.2.47-.03.16,0,.32.08.46.1.19.26.3.48.32.22.02.44-.03.65-.14.24-.14.41-.3.5-.51.09-.2.09-.38,0-.54-.04-.07-.09-.13-.16-.19-.07-.06-.13-.08-.18-.08-.05,0-.11.02-.17.06-.15.08-.24.14-.27.17-.03.03-.05.06-.05.1,0,.03.02.1.06.19.02.03.01.06,0,.07-.02.01-.05,0-.06-.03l-.11-.2-.35-.61s-.01-.05.01-.06c.01,0,.02,0,.03,0,.01,0,.04.04.09.1.04.04.07.06.1.06.03,0,.17-.06.42-.19.06-.02.09-.04.09-.04,0,0,.07.03.18.11.11.07.21.16.3.25.08.1.15.2.22.31.1.18.15.37.16.55,0,.19-.03.36-.12.52-.08.16-.22.28-.4.39-.26.14-.53.17-.81.09-.28-.07-.51-.27-.67-.57-.06-.11-.1-.21-.12-.32-.02-.1-.04-.22-.05-.36,0-.03,0-.05-.02-.06-.01-.02-.03-.03-.06-.02-.04,0-.07,0-.08-.02-.02-.02,0-.05.06-.09Z"/>
            <path   d="m330.91,126.36c-.05-.13-.06-.25-.05-.36s.09-.22.24-.35c.14-.12.22-.22.24-.29.03-.07.02-.14-.01-.23-.02-.04-.02-.07,0-.08.02-.01.06.02.09.08l.27.52c-.02.08-.1.17-.23.27-.16.12-.27.21-.33.28-.06.06-.09.13-.09.2,0,.07.02.14.06.21,0,.02.02.05.05.08.31-.17.51-.28.58-.32.07-.04.11-.09.13-.13.02-.05,0-.11-.03-.19-.02-.04-.02-.06,0-.08.02-.01.05,0,.06.03l.21.42c.03.05.07.12.12.21.05.08.08.13.09.15.02.04.02.06,0,.08-.02.01-.05,0-.06-.03-.02-.05-.06-.09-.11-.11s-.17,0-.35.11l-.25.14-.48.26c-.25.13-.4.23-.44.28-.04.05-.04.12,0,.22.02.04.02.06,0,.08-.03.02-.06,0-.08-.05-.14-.25-.26-.46-.36-.63-.05-.07-.09-.14-.12-.2-.11-.2-.16-.39-.14-.54.02-.16.09-.27.22-.34.09-.05.18-.06.28-.05.1.02.19.06.26.12.08.07.15.15.22.26Zm.14.39s-.02-.04-.03-.05c-.04-.08-.1-.15-.17-.21-.07-.06-.15-.1-.23-.09-.08,0-.16.02-.23.06-.11.06-.18.14-.21.24-.03.1-.01.21.05.31.02.04.06.09.1.13l.72-.39Z"/>
            <path   d="m328.79,124.38c.13-.1.26-.2.38-.3.02-.01.05,0,.06.02,0,0,0,.03-.01.06-.09.16-.14.29-.14.39,0,.1.03.22.1.37.04.08.09.15.14.22.17-.06.35-.14.54-.24.03-.02.09-.04.16-.08l-.05-.11c-.03-.06-.07-.11-.11-.15s-.1-.06-.17-.06c-.07,0-.12,0-.14,0-.03,0-.05,0-.06-.02-.01-.03,0-.04.01-.06,0,0,.02,0,.02,0,.06-.02.23-.1.51-.24.04-.02.07-.04.1-.06,0,0,.01,0,.02-.01.02-.01.05,0,.06.02,0,.02,0,.05-.05.08-.08.06-.12.14-.13.22-.01.08.02.2.08.34.15-.07.26-.12.31-.14.21-.11.33-.17.36-.2.03-.02.06-.06.06-.12,0-.05-.02-.13-.06-.22-.17-.34-.4-.52-.67-.52-.04,0-.06,0-.07-.02-.02-.03,0-.04.01-.06.02,0,.03-.02.06-.02l.44-.11.24.49c.07.14.13.27.19.4.06.12.1.22.13.28.04.08.1.19.19.34,0,.02.02.03.02.04.02.04.02.07,0,.08-.02.02-.05,0-.06-.03-.04-.09-.1-.14-.16-.15-.06,0-.17.03-.32.1l-.7.35c-.24.11-.39.19-.44.23-.05.03-.08.06-.09.1,0,.04,0,.09.03.16.01.03,0,.05-.02.06-.03.02-.05,0-.06-.03l-.15-.33-.55-1.11Z"/>
            <path   d="m327.89,122.44c.13-.09.26-.19.4-.28.03-.01.05,0,.06.02,0,.02,0,.03-.02.06-.09.16-.15.29-.15.39,0,.1.02.23.09.37.04.08.08.16.13.23.17-.06.35-.13.55-.22.03-.02.09-.04.17-.07l-.05-.11c-.03-.07-.06-.12-.11-.16-.04-.04-.09-.06-.17-.07s-.12,0-.14,0c-.03,0-.06,0-.06-.02-.01-.02,0-.05.02-.06,0,0,.02,0,.02,0,.06-.02.24-.08.52-.22.04-.02.08-.04.11-.06,0,0,.02,0,.02-.01.02,0,.05,0,.06.02,0,.02,0,.05-.05.09-.08.05-.13.13-.14.21-.02.09,0,.2.07.35.16-.06.26-.11.31-.14.21-.09.33-.15.37-.18.04-.02.06-.06.06-.11,0-.06-.01-.13-.06-.22-.16-.36-.37-.53-.65-.54-.04,0-.06-.01-.07-.03-.01-.02,0-.04.02-.05.02,0,.03-.01.06-.02l.44-.09.23.5c.06.14.12.28.17.4.05.12.09.22.12.29.03.08.09.19.17.34.01.02.02.04.02.04.02.04.01.07-.02.08s-.05,0-.06-.03c-.04-.09-.09-.14-.15-.15-.06,0-.17.02-.32.09l-.71.32c-.25.11-.4.18-.45.21-.06.03-.08.06-.09.1s0,.09.02.17c0,.03,0,.05-.02.06-.02.02-.05,0-.06-.03l-.14-.34-.51-1.13Z"/>
            <path   d="m327.56,121.63c.33-.66.58-1.21.77-1.63-.06.02-.11.04-.12.05l-.56.23c-.13.05-.22.1-.27.14-.05.04-.08.09-.09.15-.02.06-.01.13,0,.19.01.04,0,.06-.02.07-.02.01-.05,0-.06-.03,0-.02-.01-.04-.02-.07-.02-.06-.05-.14-.09-.24-.06-.13-.1-.23-.13-.29-.01-.02-.02-.05-.03-.07-.02-.03,0-.06.02-.07.03-.01.05,0,.08.05.03.06.08.1.14.14s.16.02.29-.03l.79-.33.6-.23c.05-.02.09-.02.1.02,0,0,0,.02,0,.03,0,.01-.04.11-.12.27s-.2.43-.36.76c-.16.34-.28.58-.36.72.02,0,.26-.11.71-.29.14-.06.24-.11.32-.15.07-.05.11-.11.12-.18.01-.07,0-.14-.02-.19-.01-.03,0-.05.02-.06.03,0,.05,0,.06.03,0,.02.02.05.03.09.02.06.04.13.08.22.06.14.1.24.14.3.02.04.03.06.04.08.02.04,0,.06-.02.07-.02,0-.05-.01-.08-.07-.06-.1-.12-.15-.19-.15s-.17.02-.28.07c-.49.2-.78.32-.89.37-.11.05-.19.13-.25.22-.06.1-.08.19-.06.29,0,.04,0,.06-.02.07-.03.01-.06,0-.07-.04,0,0,0-.02-.02-.04,0-.02-.02-.06-.05-.12-.05-.12-.1-.24-.14-.34Z"/>
            <path   d="m326.21,118.26c-.16-.5-.28-.87-.36-1.11-.06-.18-.14-.38-.23-.59-.03-.08-.06-.14-.07-.19,0-.02-.02-.05-.03-.1.04-.02.11-.06.23-.11l.22-.1s.06,0,.07.03c0,.02,0,.05-.05.09-.06.06-.11.13-.15.21-.04.08-.05.16-.04.24,0,.08.04.21.1.38.15-.03.34-.09.56-.17.23-.08.44-.16.65-.24.2-.08.33-.14.37-.16.04-.03.06-.06.07-.1.01-.03,0-.11-.03-.22,0-.04,0-.06.02-.07.03,0,.05,0,.06.03v.04s.11.3.11.3c.08.23.13.37.15.42l.07.18s.02.04.03.09c0,.02,0,.04-.03.05-.03,0-.05,0-.06-.04-.03-.08-.07-.14-.12-.17-.05-.03-.11-.04-.19-.02-.08.02-.25.07-.51.16-.41.15-.73.27-.98.39.06.16.1.27.14.34.04.06.1.12.18.17.08.04.17.07.28.08.04,0,.07.01.07.03.01.04,0,.06-.02.07,0,0-.07.02-.19.05s-.23.05-.31.06Z"/>
            <path   d="m325.17,115.31l-.03-.1c.23-.22.61-.54,1.13-.96.23-.2.38-.32.42-.39.05-.06.06-.13.04-.23,0-.03,0-.05.02-.05.03-.01.05,0,.06.04,0,.02.02.07.04.16.02.1.05.19.07.25.02.07.06.15.1.26.03.07.05.13.06.15.02.04,0,.07-.02.08-.02,0-.04,0-.05-.03-.05-.12-.12-.16-.2-.13-.04.01-.11.05-.2.12-.09.07-.17.13-.25.2.02.06.04.11.05.15.06.2.11.35.15.45.25,0,.42,0,.49-.02.05-.02.09-.05.1-.11.02-.05.02-.1,0-.15,0-.03,0-.06.03-.07.02,0,.04,0,.06.05,0,.02.02.06.03.12.02.09.04.15.05.19.02.08.05.15.09.21,0,.02.02.03.02.04,0,.04,0,.06-.03.07-.02,0-.04,0-.05-.02-.04-.05-.07-.09-.11-.12-.03-.02-.09-.04-.17-.06-.08-.02-.21-.03-.37-.03-.38,0-.89-.04-1.55-.09Zm.54-.06l.73.04-.18-.51c-.06.05-.15.13-.27.23-.12.11-.22.19-.29.24Z"/>
            <path   d="m324.4,112.17l.41-.17s.03-.02.05-.02c.03,0,.05,0,.06.03,0,.02,0,.05-.03.07-.14.12-.23.26-.29.42-.06.15-.06.31-.02.47.06.21.2.35.41.41.21.06.43.06.67-.01.26-.08.46-.21.59-.4.13-.18.17-.36.11-.53-.02-.07-.06-.14-.12-.21-.06-.06-.11-.11-.16-.11-.05,0-.11,0-.18.02-.16.05-.26.09-.3.11-.04.02-.06.06-.06.09s0,.1.02.19c0,.04,0,.06-.02.07-.03,0-.05,0-.06-.04l-.07-.22-.22-.67s0-.04.02-.06c.02,0,.03,0,.04,0,0,.01.03.05.07.11.03.05.06.08.08.08.03,0,.18-.02.45-.1.06-.02.1-.02.11-.03,0,0,.06.05.15.15.1.09.17.2.24.31.06.11.11.23.15.34.06.2.08.4.05.57-.03.18-.1.34-.22.49-.11.14-.27.24-.47.3-.28.09-.55.06-.81-.06s-.45-.36-.55-.7c-.03-.11-.05-.23-.05-.33,0-.1,0-.23.02-.36,0-.03,0-.06,0-.07,0-.02-.02-.03-.06-.03-.04,0-.06-.02-.07-.04,0-.03.02-.05.07-.08Z"/>
          </g>
          <path   d="m326.5,110.02c-.04-.08-.1-.15-.14-.23-.13-.24-.37-.39-.61-.54-.14-.09-.27-.07-.4.05-.41.37-.58.83-.7,1.32,0,.02-.01.03-.02.05-.02-.19.08-.38.11-.58.01-.05.07-.13,0-.16-.07-.03-.13.04-.18.07-.09.05-.17.11-.28.16.37-.28.63-.63.8-1.03.08-.18.03-.25-.15-.31-.29-.09-.58-.19-.9-.09.35-.22.69-.48,1.18-.37,0-.09-.01-.18-.02-.26-.01-.08.07-.11.13-.14.1-.05.07.06.11.08.18.17.25.41.53.53.18.08.24.26.3.44.12.33.2.68.26,1.04Z"/>
        </g>
        <path   d="m473.57,91.12c.02.09.06.17.08.26.05.27.24.47.43.69.11.13.24.15.4.07.49-.25.79-.64,1.04-1.08,0-.02.02-.03.03-.04-.03.19-.18.35-.26.52-.02.05-.1.11-.04.16.06.05.13,0,.2-.02.1-.02.2-.05.31-.07-.43.17-.78.43-1.05.76-.12.15-.1.24.06.34.25.17.5.35.84.35-.4.11-.8.27-1.25.02-.01.09-.04.18-.05.26,0,.09-.09.09-.17.1-.12.02-.06-.07-.08-.11-.12-.22-.13-.47-.36-.65-.15-.12-.16-.32-.16-.5-.03-.36,0-.71.04-1.07Z"/>
      </g>
      <g>
        <path   d="m173.25,718.8l.56.15s.05.01.06.02c.04.01.05.03.04.06-.01.02-.05.03-.1.03-.24,0-.46.04-.65.11-.19.07-.34.18-.43.33-.12.19-.1.39.06.58.15.19.38.34.68.44.33.12.65.16.93.11.29-.04.48-.15.58-.32.04-.07.06-.15.06-.24,0-.09-.02-.15-.06-.19-.04-.04-.1-.07-.2-.11-.21-.07-.34-.11-.4-.12-.06,0-.11,0-.14.02-.04.02-.09.07-.15.16-.03.03-.05.04-.08.03-.04-.01-.04-.04-.02-.07l.12-.21.36-.64s.04-.04.07-.03c.02,0,.03.02.03.03,0,.01-.01.06-.03.13-.01.05-.01.09,0,.12.02.02.2.1.54.23.08.03.12.05.12.05,0,0,.02.07.03.21,0,.13,0,.26-.03.39-.03.12-.08.24-.15.36-.11.19-.26.34-.45.45-.19.11-.4.18-.64.21-.24.03-.48,0-.73-.1-.36-.13-.61-.33-.76-.6-.15-.27-.13-.57.06-.88.07-.11.15-.21.24-.28.09-.08.21-.16.34-.25.03-.02.05-.04.06-.05.01-.02,0-.04-.03-.06-.04-.03-.05-.05-.04-.08.01-.03.06-.03.14,0Z"/>
        <path   d="m175.11,717.76c.1-.11.21-.2.33-.25.13-.05.32-.06.57-.02.24.04.4.05.5.03.09-.02.17-.07.22-.14.03-.04.06-.06.09-.04.04.01.03.05,0,.12l-.33.52c-.11.03-.26.02-.46-.03-.26-.06-.45-.09-.56-.1-.12,0-.21,0-.29.05-.08.04-.13.1-.18.17-.01.02-.03.05-.05.09.43.15.7.25.8.28.1.03.18.04.24.03.06-.01.11-.06.17-.13.03-.04.06-.05.09-.04.03.01.04.04.02.07l-.27.41c-.03.06-.07.13-.12.22-.04.08-.07.14-.08.16-.02.04-.05.05-.09.04s-.04-.04-.02-.07c.03-.05.04-.1.03-.16,0-.06-.14-.13-.4-.22l-.34-.12-.66-.24c-.34-.12-.56-.18-.65-.19-.09,0-.16.04-.23.14-.02.04-.05.05-.09.04-.04-.01-.04-.04,0-.09.15-.26.28-.48.37-.66.04-.08.08-.15.12-.22.12-.21.28-.35.46-.43.18-.08.36-.08.54-.02.12.04.21.11.28.2s.09.18.09.29c0,.1-.03.21-.09.32Zm-.3.34s.03-.04.03-.05c.05-.08.08-.17.08-.26,0-.1-.02-.17-.09-.24-.07-.06-.15-.11-.25-.15-.16-.05-.3-.06-.42-.03-.13.04-.22.11-.29.21-.03.04-.05.1-.06.16l.99.35Z"/>
        <path   d="m175.39,714.91c.21.04.42.08.63.12.04.01.05.03.03.06,0,.01-.04.02-.08.03-.24.03-.41.07-.52.13-.11.06-.2.16-.29.31-.05.08-.09.16-.11.25.21.09.44.19.7.28.05.02.12.04.23.08l.06-.1c.04-.06.06-.12.06-.18s-.02-.11-.08-.17c-.06-.06-.1-.1-.12-.11-.04-.02-.05-.04-.03-.06.01-.03.04-.03.07-.02,0,0,.02,0,.03.01.07.04.3.12.68.26.05.02.1.03.15.04.01,0,.03,0,.03,0,.03.01.04.03.03.06-.01.02-.05.02-.13.01-.13-.02-.24-.01-.34.03-.1.04-.19.13-.28.27.21.08.34.13.41.15.28.1.45.16.51.17.06.01.11,0,.17-.02.06-.03.11-.09.17-.19.21-.35.2-.63-.04-.84-.03-.03-.04-.05-.03-.07.02-.03.04-.04.07-.02.02,0,.04.02.07.03l.48.28-.3.5c-.08.14-.17.27-.25.39-.08.12-.14.21-.18.28-.04.08-.11.19-.19.35,0,.02-.02.03-.02.04-.03.04-.06.06-.1.04-.03-.01-.04-.04-.02-.07.05-.09.05-.16,0-.21-.05-.05-.17-.12-.37-.19l-.95-.34c-.33-.11-.53-.18-.61-.2s-.14-.02-.18,0c-.04.02-.09.06-.15.12-.02.03-.05.04-.08.03-.04-.01-.04-.04-.03-.07l.21-.33.67-1.1Z"/>
        <path   d="m176.48,713.07c.21.04.42.08.63.12.04.01.04.03.03.06,0,.01-.04.03-.08.03-.24.03-.41.07-.52.13-.11.06-.2.16-.29.31-.05.08-.09.17-.11.25.21.09.44.19.7.28.04.02.12.04.22.08l.07-.1c.04-.07.06-.13.06-.18,0-.06-.02-.11-.08-.17-.06-.06-.1-.1-.12-.11-.03-.02-.05-.04-.03-.06.02-.03.04-.03.07-.02,0,0,.02,0,.03.01.07.04.29.12.68.26.05.02.1.03.15.04.01,0,.02,0,.03,0,.04.01.04.03.03.06-.01.02-.05.02-.12.01-.13-.02-.24-.01-.34.03-.1.04-.19.14-.28.28.2.08.34.13.41.15.28.1.45.15.51.17.06.01.11,0,.17-.02.06-.03.11-.09.17-.19.21-.35.19-.63-.04-.84-.03-.03-.04-.05-.03-.07.02-.03.04-.03.07-.02.02,0,.04.02.07.03l.48.28-.3.5c-.08.14-.17.27-.25.39-.08.12-.14.21-.18.28-.04.08-.11.19-.19.35,0,.02-.02.04-.02.04-.03.04-.06.06-.1.04-.03-.01-.04-.04-.02-.07.05-.09.06-.16,0-.21-.05-.05-.17-.12-.37-.19l-.95-.34c-.33-.11-.53-.18-.61-.2-.08-.02-.14-.02-.18,0-.04.02-.09.06-.15.12-.02.03-.05.04-.08.03-.04-.01-.04-.04-.02-.07l.21-.33.66-1.1Z"/>
        <path   d="m176.95,712.24c.93-.2,1.69-.38,2.27-.53-.08-.03-.14-.05-.16-.06l-.74-.26c-.17-.06-.3-.1-.38-.11-.08-.01-.16,0-.23.03-.07.03-.13.08-.17.14-.02.03-.05.04-.09.03-.04-.01-.04-.03-.03-.07.01-.02.03-.04.05-.06.04-.05.09-.12.15-.23.08-.12.13-.22.16-.29.01-.03.03-.05.04-.07.02-.04.05-.05.08-.03.03.01.04.04.02.09-.03.06-.03.12,0,.2.03.07.13.13.3.19l1.05.36.79.28c.07.02.09.05.07.08,0,0-.01.02-.03.02-.01,0-.14.04-.37.1-.23.06-.59.14-1.07.25-.48.11-.82.18-1.02.22.03.01.34.12.93.33.18.06.33.11.44.13.11.02.21.01.29-.03.08-.04.14-.09.17-.14.02-.03.04-.04.08-.03.04.01.05.04.03.07-.01.02-.03.05-.05.08-.04.05-.09.12-.14.2-.08.14-.13.24-.16.3-.02.04-.03.07-.04.08-.02.04-.05.05-.09.03-.03,0-.03-.04,0-.1.05-.11.04-.19-.03-.25s-.18-.11-.33-.16c-.64-.22-1.03-.35-1.18-.4-.14-.04-.29-.06-.44-.04-.15.02-.26.07-.34.15-.03.03-.06.04-.09.03-.04-.01-.05-.04-.03-.08,0,0,.01-.02.03-.04.02-.02.04-.06.07-.11.07-.12.14-.23.2-.33Z"/>
        <path   d="m178.79,709.08c.29-.47.51-.82.64-1.05.1-.17.21-.37.31-.58.04-.08.07-.14.1-.18.01-.02.03-.05.06-.09.05.01.17.04.33.08l.31.08s.06.04.05.07c-.01.02-.06.04-.13.04-.12,0-.23.02-.34.05-.1.03-.19.08-.25.14-.07.06-.15.18-.25.34.18.08.41.17.7.27.29.1.58.19.85.28.28.08.45.13.51.14.06,0,.11,0,.16-.02.04-.02.1-.08.17-.18.02-.04.05-.05.08-.04.04.01.04.03.03.06l-.02.04-.18.28c-.13.22-.21.36-.23.41l-.09.18s-.03.04-.05.08c-.02.02-.04.02-.06.02-.04-.01-.04-.04-.02-.07.05-.08.06-.15.04-.21-.02-.06-.08-.11-.17-.15s-.31-.12-.64-.24c-.53-.18-.96-.32-1.31-.41-.09.15-.14.26-.17.34-.02.07-.01.15.02.24.04.09.11.17.2.25.04.03.05.06.04.08-.02.03-.05.04-.08.03-.01,0-.09-.04-.23-.1-.15-.06-.26-.12-.36-.17Z"/>
        <path   d="m180.36,706.29l.05-.09c.43,0,1.08.02,1.97.06.41.02.66.02.76,0,.1-.01.18-.06.24-.14.02-.03.04-.03.07-.03.04.01.05.04.03.07-.01.02-.04.07-.1.15-.07.09-.12.17-.16.24-.04.06-.08.15-.13.25-.04.08-.06.13-.08.15-.02.04-.05.06-.09.04-.03,0-.03-.03-.02-.06.05-.12.02-.19-.08-.23-.05-.02-.15-.03-.3-.04-.15-.01-.29-.02-.42-.02-.04.06-.06.11-.08.14-.11.18-.19.33-.25.43.25.18.42.28.51.32.07.02.13.02.19,0,.06-.03.11-.06.14-.11.02-.03.05-.04.09-.03.03.01.04.04.02.07,0,.02-.03.05-.08.11-.05.08-.09.13-.11.17-.04.07-.08.14-.1.21,0,.02-.01.03-.02.04-.02.03-.05.04-.09.03-.03,0-.04-.03-.03-.05.01-.06.01-.12,0-.16-.01-.04-.05-.1-.12-.16-.07-.07-.18-.16-.35-.27-.37-.26-.86-.63-1.47-1.11Zm.59.32l.69.52.27-.49c-.11,0-.27-.01-.47-.02-.22,0-.38,0-.5-.01Z"/>
        <path   d="m182.27,703.51l.57.14s.04.01.06.02c.04.01.05.03.04.06-.01.02-.05.03-.1.04-.24,0-.46.04-.65.12-.19.07-.33.19-.42.34-.11.2-.09.39.07.57.16.19.39.33.69.43.34.11.65.15.94.1.29-.05.48-.16.57-.32.04-.07.06-.15.06-.24,0-.09-.02-.15-.06-.19-.04-.04-.11-.07-.2-.1-.21-.07-.34-.11-.4-.11-.06,0-.11,0-.14.02-.04.02-.09.08-.15.16-.02.03-.05.04-.08.03-.04-.01-.04-.03-.03-.07l.12-.21.35-.64s.04-.04.07-.03c.02,0,.03.02.03.03,0,.01,0,.06-.03.13-.01.05,0,.09.01.12.02.02.2.1.54.22.08.03.12.04.13.05,0,0,.02.07.03.21.01.13,0,.26-.02.39-.03.12-.08.24-.14.36-.11.19-.26.34-.44.46-.19.11-.4.19-.63.21-.24.03-.48,0-.73-.08-.36-.12-.62-.31-.77-.58-.16-.27-.14-.56.04-.88.06-.11.14-.21.23-.29.09-.08.2-.16.33-.25.03-.02.05-.04.05-.05.01-.02,0-.04-.03-.06-.04-.03-.05-.05-.04-.08.02-.02.06-.03.14,0Z"/>
      </g>
      <path   d="m183.45,702.85c.01-.08,0-.17.02-.26.04-.26-.11-.5-.25-.74-.1-.14-.26-.19-.5-.16-.76.11-1.32.4-1.84.75-.02,0-.04.02-.06.03.13-.18.39-.27.58-.42.05-.04.18-.07.12-.14-.05-.06-.17-.03-.26-.03-.14,0-.29,0-.44,0,.65-.05,1.22-.21,1.73-.46.23-.11.23-.2.06-.33-.25-.22-.5-.44-.95-.51.58,0,1.17-.07,1.64.27.07-.08.13-.15.19-.22.06-.08.16-.06.26-.05.16.01.05.08.06.12.05.23-.04.46.18.69.14.15.06.33,0,.5-.13.34-.31.66-.54.97Z"/>
      
    </g>
  </g>
      </svg>
    )
  }
export default GreenTagsPoloFemale