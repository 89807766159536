import { Col, Modal, Table } from 'antd'
import useTableSells from 'hooks/reports/useTable'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import formatNumber from 'utilities/numberFormater'

const columns = [
  {
    title: 'Sección',
    dataIndex: 'seccion',
    key: 'seccion',
    render: (_: any, record: any) => {
      return record?.level?.name
    }
  },
  {
    title: 'Grado',
    dataIndex: 'Grado',
    key: 'Grado'
  },
  {
    title: 'Cantidad',
    dataIndex: 'cantidadTotal',
    key: 'cantidadTotal'
  },
  {
    title: 'Total',
    dataIndex: 'Total',
    key: 'Total',
    render: (_: any, record: any) => {
      return `$ ${formatNumber(record?.Total)}`
    }
  },
  {
    title: 'Poblacion SUL',
    dataIndex: 'sul_population',
    key: 'sul_population',
    
  },
  {
    title: 'Alumnos que han comprado',
    dataIndex: 'alumnosTotal',
    key: 'alumnosTotal'
  },
  {
    title: '% Absorción',
    dataIndex: 'percentage',
    key: 'male_population',
    render: (_: any, record: any) => {
      const male = record?.male_population
        ? parseInt(record?.male_population)
        : 0
      const female = record?.female_population
        ? parseInt(record?.female_population)
        : 0
      const total = male + female
      const percentage = (parseFloat(record?.alumnosTotal) * 100) / total
      return percentage.toFixed(2)
    }
  }
]

const StudentDetailsColumn = [
  {
    title: 'Nombre',
    dataIndex: 'Alumno',
    key: 'Alumno'
  },
  
  {
    title: 'Cantidad',
    dataIndex: 'Cantidad',
    key: 'Cantidad'
  },

  {
    title: 'Fecha',
    dataIndex: 'Fecha',
    key: 'Fecha'
  },
  {
    title: 'GrupoArticulo',
    dataIndex: 'GrupoArticulo',
    key: 'GrupoArticulo'
  },
  {
    title: 'Sku',
    dataIndex: 'SKU',
    key: 'SKU'
  },
  {
    title: 'Precio',
    dataIndex: 'Precio',
    key: 'Precio'
  }
]

const AllStudentsColumns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Grado',
    dataIndex: 'grade',
    key: 'grade'
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total'
  }
]

const TableSells = memo(() => {
  const { loadingFilter, apliedFilters } = useSelector(
    (state: RootState) => state.sells
  )
  const {
    TableData,
    loading,
    studentsTable,
    openModal,
    handleCloseModal,
    studentSellsDetail,
    handleRowClick
  } = useTableSells()

  return (
    <Col span={24}>
      <Modal title={studentSellsDetail[0]?.Alumno} width={1000} centered open={openModal}
      footer={false}
      onCancel={handleCloseModal}>
        <Table
          style={{
            maxHeight: '500px',
            overflowY: 'auto'
          }}
          dataSource={studentSellsDetail}
          columns={StudentDetailsColumn}
          rowKey={(row: any) => row.id}
        />
      </Modal>
      {apliedFilters.grado || apliedFilters.seccion ? (
        <Table
          style={{
            maxHeight: '500px',
            overflowY: 'auto'
          }}
          dataSource={studentsTable}
          columns={AllStudentsColumns}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record)
            }
          }}
          rowKey={(row) => row.Id_Colegio}
          loading={loading || loadingFilter}
        />
      ) : (
        <Table
          style={{
            maxHeight: '500px',
            overflowY: 'auto'
          }}
          dataSource={TableData}
          columns={columns}
          rowKey={(row) => row.id}
          loading={loading || loadingFilter}
        />
      )}
    </Col>
  )
})

export default TableSells
