import React from 'react'

const PathPautadoVertical = ({
  className,
  marginComplete,
  roundedMargin,
  viewBox = '0 0 800 800',
  ink
}: {
  className: string
  marginComplete: boolean
  roundedMargin: boolean
  viewBox?: string
  ink: string
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={viewBox}
      stroke='black'
      className={className}
    >
      {marginComplete ? (
        <g>
          <title>Path Pautado Completo</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Pautado_Completo' data-name='Path Pautado Completo'>
              <g id='Path_Pautado_Completo-2' data-name='Path Pautado Completo'>
                <g
                  id='Rayado_completo'
                  data-name='Rayado completo'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='128.34'
                    x2='199.93'
                    y2='128.34'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='117.83'
                    x2='648.23'
                    y2='117.83'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='138.85'
                    x2='199.93'
                    y2='138.85'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='149.35'
                    x2='199.93'
                    y2='149.35'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='159.86'
                    x2='199.93'
                    y2='159.86'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='209.77'
                    x2='199.93'
                    y2='209.77'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='199.26'
                    x2='648.23'
                    y2='199.26'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='220.28'
                    x2='199.93'
                    y2='220.28'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='230.79'
                    x2='199.93'
                    y2='230.79'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='241.29'
                    x2='199.93'
                    y2='241.29'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='291.2'
                    x2='199.93'
                    y2='291.2'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='280.7'
                    x2='648.23'
                    y2='280.7'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='301.71'
                    x2='199.93'
                    y2='301.71'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='312.22'
                    x2='199.93'
                    y2='312.22'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='322.73'
                    x2='199.93'
                    y2='322.73'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='372.64'
                    x2='199.93'
                    y2='372.64'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='362.13'
                    x2='648.23'
                    y2='362.13'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='383.14'
                    x2='199.93'
                    y2='383.14'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='393.65'
                    x2='199.93'
                    y2='393.65'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='404.16'
                    x2='199.93'
                    y2='404.16'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='454.07'
                    x2='199.93'
                    y2='454.07'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='443.56'
                    x2='648.23'
                    y2='443.56'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='464.58'
                    x2='199.93'
                    y2='464.58'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='475.08'
                    x2='199.93'
                    y2='475.08'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='485.59'
                    x2='199.93'
                    y2='485.59'
                  />
                  <line
                    id='Hoja_esq._recta-26'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='535.5'
                    x2='199.93'
                    y2='535.5'
                  />
                  <line
                    id='Hoja_esq._recta-27'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='524.99'
                    x2='648.23'
                    y2='524.99'
                  />
                  <line
                    id='Hoja_esq._recta-28'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='546.01'
                    x2='199.93'
                    y2='546.01'
                  />
                  <line
                    id='Hoja_esq._recta-29'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='556.52'
                    x2='199.93'
                    y2='556.52'
                  />
                  <line
                    id='Hoja_esq._recta-30'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='567.02'
                    x2='199.93'
                    y2='567.02'
                  />
                  <line
                    id='Hoja_esq._recta-31'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='616.93'
                    x2='199.93'
                    y2='616.93'
                  />
                  <line
                    id='Hoja_esq._recta-32'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='606.43'
                    x2='648.23'
                    y2='606.43'
                  />
                  <line
                    id='Hoja_esq._recta-33'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='627.44'
                    x2='199.93'
                    y2='627.44'
                  />
                  <line
                    id='Hoja_esq._recta-34'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='637.95'
                    x2='199.93'
                    y2='637.95'
                  />
                  <line
                    id='Hoja_esq._recta-35'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='648.46'
                    x2='199.93'
                    y2='648.46'
                  />
                  <line
                    id='Hoja_esq._recta-36'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='698.37'
                    x2='199.93'
                    y2='698.37'
                  />
                  <line
                    id='Hoja_esq._recta-37'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='687.86'
                    x2='648.23'
                    y2='687.86'
                  />
                  <line
                    id='Hoja_esq._recta-38'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='708.87'
                    x2='199.93'
                    y2='708.87'
                  />
                  <line
                    id='Hoja_esq._recta-39'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='719.38'
                    x2='199.93'
                    y2='719.38'
                  />
                  <line
                    id='Hoja_esq._recta-40'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='729.89'
                    x2='199.93'
                    y2='729.89'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      ) : (
        <g>
          <title>Path Pautado Datos</title>
          <g id='Capa_2' data-name='Capa 2'>
            <g id='Path_Pautado_Datos' data-name='Path Pautado Datos'>
              <g id='Path_Pautado_Datos-2' data-name='Path Pautado Datos'>
                <g
                  id='Rayado_Datos'
                  data-name='Rayado Datos'
                  stroke={`${ink !== 'black' ? ink : 'black'}`}
                >
                  <line
                    id='Hoja_esq._recta'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='193.98'
                    x2='199.93'
                    y2='193.98'
                  />
                  <line
                    id='Hoja_esq._recta-2'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='183.47'
                    x2='648.23'
                    y2='183.47'
                  />
                  <line
                    id='Hoja_esq._recta-3'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='204.48'
                    x2='199.93'
                    y2='204.48'
                  />
                  <line
                    id='Hoja_esq._recta-4'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='214.99'
                    x2='199.93'
                    y2='214.99'
                  />
                  <line
                    id='Hoja_esq._recta-5'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='225.5'
                    x2='199.93'
                    y2='225.5'
                  />
                  <line
                    id='Hoja_esq._recta-6'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='275.41'
                    x2='199.93'
                    y2='275.41'
                  />
                  <line
                    id='Hoja_esq._recta-7'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='264.9'
                    x2='648.23'
                    y2='264.9'
                  />
                  <line
                    id='Hoja_esq._recta-8'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='285.92'
                    x2='199.93'
                    y2='285.92'
                  />
                  <line
                    id='Hoja_esq._recta-9'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='296.42'
                    x2='199.93'
                    y2='296.42'
                  />
                  <line
                    id='Hoja_esq._recta-10'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='306.93'
                    x2='199.93'
                    y2='306.93'
                  />
                  <line
                    id='Hoja_esq._recta-11'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='356.84'
                    x2='199.93'
                    y2='356.84'
                  />
                  <line
                    id='Hoja_esq._recta-12'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='346.33'
                    x2='648.23'
                    y2='346.33'
                  />
                  <line
                    id='Hoja_esq._recta-13'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='367.35'
                    x2='199.93'
                    y2='367.35'
                  />
                  <line
                    id='Hoja_esq._recta-14'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='377.86'
                    x2='199.93'
                    y2='377.86'
                  />
                  <line
                    id='Hoja_esq._recta-15'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='388.36'
                    x2='199.93'
                    y2='388.36'
                  />
                  <line
                    id='Hoja_esq._recta-16'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='438.27'
                    x2='199.93'
                    y2='438.27'
                  />
                  <line
                    id='Hoja_esq._recta-17'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='427.77'
                    x2='648.23'
                    y2='427.77'
                  />
                  <line
                    id='Hoja_esq._recta-18'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='448.78'
                    x2='199.93'
                    y2='448.78'
                  />
                  <line
                    id='Hoja_esq._recta-19'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='459.29'
                    x2='199.93'
                    y2='459.29'
                  />
                  <line
                    id='Hoja_esq._recta-20'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='469.8'
                    x2='199.93'
                    y2='469.8'
                  />
                  <line
                    id='Hoja_esq._recta-21'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='519.71'
                    x2='199.93'
                    y2='519.71'
                  />
                  <line
                    id='Hoja_esq._recta-22'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='509.2'
                    x2='648.23'
                    y2='509.2'
                  />
                  <line
                    id='Hoja_esq._recta-23'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='530.21'
                    x2='199.93'
                    y2='530.21'
                  />
                  <line
                    id='Hoja_esq._recta-24'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='540.72'
                    x2='199.93'
                    y2='540.72'
                  />
                  <line
                    id='Hoja_esq._recta-25'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='551.23'
                    x2='199.93'
                    y2='551.23'
                  />
                  <line
                    id='Hoja_esq._recta-26'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='601.14'
                    x2='199.93'
                    y2='601.14'
                  />
                  <line
                    id='Hoja_esq._recta-27'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='590.63'
                    x2='648.23'
                    y2='590.63'
                  />
                  <line
                    id='Hoja_esq._recta-28'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='611.65'
                    x2='199.93'
                    y2='611.65'
                  />
                  <line
                    id='Hoja_esq._recta-29'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='622.15'
                    x2='199.93'
                    y2='622.15'
                  />
                  <line
                    id='Hoja_esq._recta-30'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='632.66'
                    x2='199.93'
                    y2='632.66'
                  />
                  <line
                    id='Hoja_esq._recta-31'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='682.57'
                    x2='199.93'
                    y2='682.57'
                  />
                  <line
                    id='Hoja_esq._recta-32'
                    data-name='Hoja esq. recta'
                    x1='199.93'
                    y1='672.06'
                    x2='648.23'
                    y2='672.06'
                  />
                  <line
                    id='Hoja_esq._recta-33'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='693.08'
                    x2='199.93'
                    y2='693.08'
                  />
                  <line
                    id='Hoja_esq._recta-34'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='703.59'
                    x2='199.93'
                    y2='703.59'
                  />
                  <line
                    id='Hoja_esq._recta-35'
                    data-name='Hoja esq. recta'
                    x1='648.23'
                    y1='714.09'
                    x2='199.93'
                    y2='714.09'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      )}
      <g stroke={`${ink !== 'black' ? ink : 'black'}`}>
        {marginComplete ? (
          <g>
            <title>Margenes Pautado Completo</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g
                id='Margenes_Pautado_Completo'
                data-name='Margenes Pautado Completo'
              >
                <g
                  id='Margenes_Pautado_Completo-2'
                  data-name='Margenes Pautado Completo'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,104.56a13.09,13.09,0,0,1,13.08,13.08V730.92A13.09,13.09,0,0,1,634.16,744H214a13.1,13.1,0,0,1-13.08-13.08V117.64A13.1,13.1,0,0,1,214,104.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V730.92A14.08,14.08,0,0,0,214,745H634.16a14.08,14.08,0,0,0,14.08-14.08V117.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,104.56V744H200.93V104.56H647.24m1-1H199.93V745H648.24V103.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        ) : (
          <g>
            {' '}
            <title>Margenes Pautado Datos</title>
            <g id='Capa_2' data-name='Capa 2'>
              <g id='Margenes_Pautado_Datos' data-name='Margenes Pautado Datos'>
                <g
                  id='Margenes_Pautado_Datos-2'
                  data-name='Margenes Pautado Datos'
                >
                  {roundedMargin ? (
                    <g
                      id='Hoja_esq._redondeada'
                      data-name='Hoja esq. redondeada'
                    >
                      <path d='M634.16,149.56a13.09,13.09,0,0,1,13.08,13.08V734.92A13.09,13.09,0,0,1,634.16,748H214a13.1,13.1,0,0,1-13.08-13.08V162.64A13.1,13.1,0,0,1,214,149.56H634.16m0-1H214a14.08,14.08,0,0,0-14.08,14.08V734.92A14.08,14.08,0,0,0,214,749H634.16a14.08,14.08,0,0,0,14.08-14.08V162.64a14.08,14.08,0,0,0-14.08-14.08Z' />
                    </g>
                  ) : (
                    <g id='Hoja_esq._recta' data-name='Hoja esq. recta'>
                      <path d='M647.24,149.56V748H200.93V149.56H647.24m1-1H199.93V749H648.24V148.56Z' />
                    </g>
                  )}
                </g>
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}

export default PathPautadoVertical
