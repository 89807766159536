import React from 'react'
import ConstructorFormulary from './ConstructorFormulary'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import { handleTrajeBanoMaleForm } from 'redux/reducers/uniformsSlice'
import { TFormTrajeBanoMaleOptions } from 'interfaces/uniforms-interface'

const TrajeBanoMaleOptionsEditable = () => {
  const { trajeBanoMaleForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()

  const handleChange = (
    keyName: TFormTrajeBanoMaleOptions,
    value: string | boolean
  ) => {
    dispatch(
      handleTrajeBanoMaleForm({
        ...trajeBanoMaleForm,
        [keyName]: {
          ...trajeBanoMaleForm[keyName as TFormTrajeBanoMaleOptions],
          value: value
        }
      })
    )
  }

  return (
    <div>
      {(() => {
        return (
          <ConstructorFormulary
            handleChange={handleChange}
            form={trajeBanoMaleForm}
          />
        )
      })()}
    </div>
  )
}
export default TrajeBanoMaleOptionsEditable
