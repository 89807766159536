import React, { Fragment, useEffect } from 'react'
import style from './poloeditable.module.scss'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store/store'
import CorbataSvg from 'assets/img/uniforms/corbata/CorbataSvg'
import { setLabelsMenu } from 'redux/reducers/uniformsSlice'

const CorbataEditable = () => {
  const { pictureLogo, corbataForm } = useSelector(
    (state: RootState) => state.uniforms
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLabelsMenu(null))
  }, [])
  return (
    <div className={`${style['div-polo-svg-sticky']}`}>
      <div className={`${style['div-polo-svg-relative']}`}>
        <Fragment>
          <div className={`${style[`logo-position-corbata-${'center'}`]}`}>
            {pictureLogo !== '' && corbataForm.con_logo.value === 'con' && (
              <img className={`${style['svg-logo']}`} src={pictureLogo} />
            )}
          </div>

          <div className={`${style['div-polo-svg-absolute']}`}>
            <CorbataSvg className={`${style['svg']}`} />
          </div>
          <div className={`${style['div-shadows-svg-absolute']}`}></div>
          <div className={`${style['div-label-svg-absolute']}`}></div>
          <div className={`${style['div-tags-svg-absolute']}`}></div>
        </Fragment>
      </div>
    </div>
  )
}

export default CorbataEditable
