import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelsMenu, setPantalonesMenu } from 'redux/reducers/uniformsSlice'
import { RootState } from 'redux/store/store'
import TrajebanoFemenino1pzaFrontalEditable from './TrajebanoFemenino1pzaFrontalEditable'
import TrajeFemenino1pzaTraseroEditable from './TrajeFemenino1pzaTraseroEditable'

const ConjuntoTrajeBanoFem = () => {
  const { selectedMenu } = useSelector((state: RootState) => state.uniforms)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPantalonesMenu('traje fem 1pza'))
    dispatch(
      setLabelsMenu([
        {
          label: 'Frontal',
          selected: 'traje fem 1pza',
          click: () => dispatch(setPantalonesMenu('traje fem 1pza'))
        },
        {
          label: 'Seccion trasera',
          selected: 'traje fem 1pza trasero',
          click: () => dispatch(setPantalonesMenu('traje fem 1pza trasero'))
        }
      ])
    )
  }, [])

  return (
    <Fragment>
      {(() => {
        switch (selectedMenu) {
          case 'traje fem 1pza':
            return <TrajebanoFemenino1pzaFrontalEditable />
          case 'traje fem 1pza trasero':
            return <TrajeFemenino1pzaTraseroEditable />
          default:
            return <></>
        }
      })()}
    </Fragment>
  )
}

export default ConjuntoTrajeBanoFem
