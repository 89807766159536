import { memo } from 'react'
import Navbar from 'components/Navbar/Navbar'
import Sidebar from 'components/Sidebar/Sidebar'
import style from './MainTemplate.module.scss'
const MainTemplate = memo(({ children, preBuyPage }: any) => {
  let CONTENT_STYLE = {}
  if (preBuyPage)
    CONTENT_STYLE = {
      ...CONTENT_STYLE,
      backgroundImage: `url('${preBuyPage}')`
    }
  return (
    <div className={style.mainDashboard}>
      <Sidebar />
      <Navbar />
      <div
        style={CONTENT_STYLE}
        className={style.content}
        data-variation={preBuyPage ? 'bkg' : ''}
      >
        {children}
      </div>
    </div>
  )
})
MainTemplate.displayName = 'MainTemplate'
export default MainTemplate
