import React from 'react'

const ShortDeportivoLateralSvg = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      viewBox='0 0 800 800'
      className={className}
    >
      <g id="Path">
    <g id="Path-2" data-name="Path">
      <path id="Outline_short" data-name="Outline short"   d="m286.74,317.56l2.31-34.14s74.06-14.88,96.25-13.84c22.19,1.03,83.08-4.65,83.08-4.65l30.24-2.41,17.43,36.86s21.2,61.21,4.45,128.1c-5.63,22.46-6.24,43.43-5.9,66.37.21,13.8-1.63,29.87,1.37,43.42,1.03,4.65-211.95,20.05-242.91-20.72l13.68-198.99Z"/>
      <path id="Resorte"   d="m286.12,326.54s69.92-28.98,126.16-30.49c56.24-1.51,72.27.96,72.27.96l31.5,2.38-17.43-36.86s-60.64-7.52-97.11-6.42c-29.98.9-76.22,14.37-76.22,14.37l-36.23,12.94-2.93,43.11Z"/>
      <path id="Línea_2_lateral" data-name="Línea 2 lateral"   d="m417.32,295.92s2.09,200.93-6.43,246.56"/>
      <path id="Linea_1_lateral" data-name="Linea 1 lateral"   d="m409.42,295.97s2.05,202.29-6.47,247.92"/>
      <path id="División"   d="m422.57,295.59s.59,16.25.76,20.96c.93,26.22,0,69.18,0,69.18,0,0-1.45,73.89.41,88.73.05.43-.95,29.28-.95,29.28l-2.04,38.73"/>
      <polygon id="Abertura" points="426.23 543.95 415.79 544 422.79 499.49 426.23 543.95"/>
      <g id="Costuras">
        <path   d="m289.82,298.88s87.57-24.99,112.98-26.29c28.04-1.43,101.66,2.86,101.66,2.86"/>
        <path   d="m289.43,314.01s89.38-26.17,114.79-27.46c28.04-1.43,107.35,3.71,107.35,3.71"/>
        <path   d="m275.45,506.01s20.76,11.92,44.21,18.45c23.45,6.53,91.77,7.3,91.77,7.3,0,0,43.17-.39,49.71,0,6.53.39,54.24-3.51,54.24-3.51"/>
        <g>
          <line   x1="410.33" y1="543.98" x2="410.47" y2="543.24"/>
          <line   x1="410.75" y1="541.73" x2="417.92" y2="503.27"/>
          <polyline   points="418.06 502.52 418.19 501.78 418.94 501.73"/>
          <line   x1="420.25" y1="501.64" x2="424.82" y2="501.33"/>
          <polyline   points="425.48 501.29 426.23 501.24 426.35 501.98"/>
          <line   x1="426.59" y1="503.5" x2="432.91" y2="542.36"/>
          <line   x1="433.03" y1="543.12" x2="433.15" y2="543.86"/>
        </g>
        <g>
          <line   x1="406.57" y1="318.15" x2="405.82" y2="318.17"/>
          <line   x1="404.5" y1="318.2" x2="399.9" y2="318.31"/>
          <path   d="m399.24,318.33l-.75.02s0,.26,0,.75"/>
          <path   d="m398.48,320.58c-.07,14.51-.56,95.21-2.55,106.51-.32,1.83-.34,8.88-.33,12"/>
          <path   d="m395.6,439.84c0,.47,0,.75,0,.75l.75.02"/>
          <line   x1="397.65" y1="440.64" x2="404.79" y2="440.81"/>
          <line   x1="405.44" y1="440.83" x2="406.19" y2="440.85"/>
        </g>
      </g>
      <g id="Textura">
        <path   d="m291.94,288.3s-3.54,8.19-.33,7.12"/>
        <path   d="m300.35,293.91c.7-1.43,1.15-2.77,1.63-4.32"/>
        <path   d="m305.73,292.92c.97-2.45.7-3.38.71-5.51-.01-1.55,1.37-4.5.84-5.58"/>
        <path   d="m310.23,296.51c1.05-1.31,1.13-5.2,1.3-7.21"/>
        <path   d="m309.69,302.86c.38-1.46.52-3.74.34-5.15"/>
        <path   d="m311.72,299.92c-.14,1.73-1.18,2.72-1.35,4.26"/>
        <path   d="m318.03,298.38c.18-1.61.97-5.23.15-6.19-.77,1.2-.71,4.71-.85,6.39"/>
        <path   d="m313.74,300.37c.47-2.02,1.01-5.41.29-7.17-1.18.93-1.61,6.83-1.3,8.47"/>
        <path   d="m319.8,293.95c-.09,1.43.25,2.76-.07,4.22-.16.8-1.17,2.34-.62,2.96"/>
        <path   d="m323.13,290.87c-.06,2.47-1.13,4.61-1.37,6.86"/>
        <path   d="m337.55,297.49c.38-1.52,1.39-1.83,1.4-3.72.02-1.01-1.02-3.33-.05-4.04"/>
        <path   d="m340.85,296.58c.11-1.26-.14-3.73.2-4.78"/>
        <path   d="m340.45,283.89c2.31,3.06-1.77,6.87-1.01,9.59"/>
        <path   d="m345.4,294.28c-.05.66-.12,1.35-.16,2,.19.09.37.34.56.37"/>
        <path   d="m348.94,303.96c.14-1.7.65-3.53,1.63-4.09"/>
        <path   d="m349.43,294.15c.7.71.03,2.32-.1,3.5"/>
        <path   d="m350.68,294.67c.68-1.58.79-5.66.94-7.57"/>
        <path   d="m355.84,287.92c-.2,2.42-.11,4.61-1.51,5.97.2-2.28,1.03-4.51,1.21-7.07"/>
        <path   d="m357.81,302.11c.09-1.77-.04-4.38,1.07-5.43-.21,2.18-.58,3.93-.91,5.97"/>
        <path   d="m361.15,293.63c.16-1.74.28-3.64.55-5.29.23-1.38.04-2.14.19-3.49.1,1.96-.14,6.11-.68,8.06"/>
        <path   d="m289.58,297.61c1.19.14,1.74.02,2.54-2.58"/>
        <path   d="m296.52,282.42c-1.13,2.04-2.58,4.11-3.17,6.59-.57,2.41.07,3.88-1.19,6.42.78-1.62,1.32-3.18,1.98-5.3"/>
        <path   d="m294.72,299.16c.31-.67.78-1.2,1.21-1.49.02,1.03-.63,2.55-.95,3.73.12.07.24.07.36.09"/>
        <path   d="m294.45,296.85c.43-1.43.21-2.55.56-3.93.47-1.92.82-1.85,1.87-2.95"/>
        <path   d="m298.26,288.46c-.53,1.78-1.41,4.58-1.42,6.16,1.18-.87,2.76-3.97,3.67-5.71"/>
        <path   d="m294.35,311.23c.26-.84,1.75-4.73,1.24-5.13-.52-.41-1.79,2.66-2.02,3.61"/>
        <path   d="m295.49,309.02c1.26-3.18,2.34-6.25,3.37-9.6.32-1.07,1.01-2.67.74-3.41-1.24,1.3-2.51,5.17-3.52,7.18-.73,1.41-1.47,2.88-2.16,4.26"/>
        <path   d="m302.73,319.5c.55-1.33,1.07-2.95,1.41-4.34-.76.05-1.71,1.42-2.08,2.84"/>
        <path   d="m298.68,308.54c1.19-3.85,2.32-7.62,3.31-11.43.4-1.54,1.58-5.53,2.56-4.63,1.38,1.23-1.85,4.6-2.39,6.44-.39,1.34-.19,2.49-.49,3.85-.42,2.03-1.29,4.28-1.9,6.3"/>
        <path   d="m306.04,309.07c.4-3.27-.07-7.01.06-10.3.09-1.97-.66-2.96-.59-4.72-.24,1.62.04,3.17,0,4.74-.04,1.15-.16,2.35-.19,3.45-.09,2.6-.17,4.56-.7,7.06"/>
        <path   d="m307.87,303.29c.15-2.39,1.09-6.45.32-8.44-.38,1.31-.51,2.76-.81,4.08-.26,1.08-.62,2.02-.88,3.12"/>
        <path   d="m312.84,314.58c.12-1.46,1.14-1.97,1.28-3.38.11-1.04-.43-2.89-.67-3.77-.55-2.07-.95-2.75-.74-5.39-.31,4.6-.85,9.66-1.38,14.22.15.05.32.07.49.01"/>
        <path   d="m315.7,314.94c.16-1.04.63-1.91.81-2.72-.11.17-.4.35-.52.46.1.08.25-.01.32.05-.16.07-.43.04-.6.02.44-.96.28-2.33.86-3.33.72-1.23,1.76-.47,2.31-1.92.83-2.15-.18-5.98-.95-7.09-.37,1.43-.19,2.97-.52,4.38-.29,1.33-.84,1.58-1.27,2.42-.93,1.83-.9,4.72-1.09,7.03"/>
        <path   d="m314.59,304.61c.09-1,.56-1.81.74-2.78.13-.74.15-1.65.19-2.39-.7,1-1.31,3.43-1.69,4.83"/>
        <path   d="m321.44,312.94c.24-3.01.11-5.96.27-8.92.08-1.51.72-3.89.12-4.95-.64,2.19-.6,5.65-.8,8.01-.21,2.44-.47,3.59-1.27,5.73"/>
        <path   d="m324.52,311.91c.24-2.72.43-5.52.58-8.21-.91,2.04-.39,7.08-2.12,7.69"/>
        <path   d="m324.34,302.07c.87-2.36.37-4.2-.08-6.01-.36-1.39-.18-2.82,0-4.53-.35,1.78-.67,3.09-.83,4.92-.14,1.71-.24,3.44-.99,4.78-.02.19.04.25.18.17"/>
        <path   d="m327.53,310.61c.3-3,.1-6.39-.45-9-.72,1.57-.16,4.21-.39,6.13-.15,1.22-.41,2.36-.51,3.63"/>
        <path   d="m327.07,300.52c.22-2.54.73-5.6.12-7.68-.38-1.36-.96-1.94-.72-3.86.02,2.15-.26,4.22-.35,6.43-.07,1.83.01,3.42-.15,5.33"/>
        <path   d="m330.3,298.34c-.58,2.9-.07,5.37-.1,8.25-.01,1.1-.18,1.83-.52,2.88-.3.97-.3,1.71.39.77.45-.63.59-1.84.85-2.73.33-1.09.53-2.23.36-3.38-.12-.68-.37-1.23-.42-1.95-.06-.77.08-1.68.15-2.5"/>
        <path   d="m329.33,299.81c-.32-2.56-.59-4.78-.36-7.55.12-1.48.41-2.98.05-4.11-.06,0-.12-.01-.17-.02-.67,2.06-.51,4.29-.19,6.23.33,2.01.5,4.35.38,6.56"/>
        <path   d="m333.01,296.32c-.24,2.95-.4,5.79-.29,8.63.03.94,0,1.82-.23,2.76-.12.53-.33,1.02-.37,1.58,1.07-.03,1.27-5.27,1.34-6.47.08-1.36-.08-2.64-.31-3.83-.13-.56-.34-1.06-.26-1.75"/>
        <path   d="m330.58,288.33c-.23,2.76-.36,4.85.28,7.16.16.62.37,1.25.33,2.01-.04.8-.33,1.48-.32,2.31,1.47-.29.87-3.8.6-5.26-.18-.95-.44-1.33-.37-2.35.04-.8.13-1.6.2-2.41"/>
        <path   d="m332.88,290.77c-.11,1.39-.25,2.75-.4,4.08.58-.94.56-3.02.67-4.28"/>
        <path   d="m335.48,297.96c-.19,2.27.41,3.5.62,5.48.19,1.66-.14,3.49-.29,5.25"/>
        <path   d="m342.48,292.88c-.53-3.5-.29-7.28-.04-11.06.03,1.18-.05,2.18-.04,3.36-.02,1.11-.17,2.62-.11,3.72.04.89.33,1.43.33,2.33,0,.78-.14,1.69-.21,2.49"/>
        <path   d="m345.01,305.06c.09-1.58.55-2.55.76-3.83-.09,1-.17,1.99-.25,2.98"/>
        <path   d="m343.94,295.22c.15-1.81.47-3.72.53-5.5-.63,1.51-1,3.66-1.12,5.38"/>
        <path   d="m346.3,295.83c1.27-1.96,1.25-4.19,1.48-6.79"/>
        <path   d="m348,294.84c-.67,3.27-1.27,7.38-.19,9.94.28-2.34-.14-3.62-.19-5.74-.02-1.13.05-3.01.49-3.93"/>
        <path   d="m352.92,291.23c-.08,1.3.14,2.49.05,3.86-.06,1.13-.22,2.27-.29,3.42-.11,1.95-.31,3.96-.42,5.95.81.43.74-1.75.66-2.48-.13-1.42-.12-2.72-.03-4.26.1-1.71.88-5.23.31-6.23"/>
        <path   d="m356.44,290.58c-.22,2.7.02,5.12-.14,7.8-.09,1.59-.47,3.07-.52,4.6,1.01,1.1.58-2.02.58-2.66.01-1.89.04-4.11.22-6.03"/>
        <path   d="m361.69,299.25c.09-.75.04-1.71-.24-2.22-.25-.45-.69-.12-.68-.94"/>
        <path   d="m359.85,302.29c.11-1.28.22-2.57.33-3.87"/>
        <path   d="m366.25,294.23c.42,1.24-.1,4.8-.25,6.48"/>
        <path   d="m361.24,299.47c.19.56.46,1.02.53,1.63"/>
        <path   d="m368.09,300.21c.58-1.32.59-4.27.57-5.79-.03-1.19-.58-2.24-.47-3.45"/>
        <path   d="m369.68,299.66c.07-.88.13-1.75.23-2.6.32-.2.62-.59.93-.91-.38.58-.78,1.65-.86,2.54"/>
        <path   d="m365.38,293.97c-.08.93-.58.62-.97.95.05-.83.33-2.05-.09-2.43"/>
        <path   d="m363.01,296c-.51.81-.33,1.87-.09,2.63-.49.75-.74-.33-.26-.99.35-.53,1.53-.96.62-1.92"/>
        <path   d="m289.79,298.58c-.49,1.21-.35,2.84.08,3.24"/>
        <path   d="m288.67,308.48c.52-.97,1.16-1.24,1.69-1.98"/>
        <path   d="m288.82,313.05c-.7,1.04-.6,1.8-.48,2.6.13.74.12,1.89-.09,2.96"/>
        <path   d="m289,310.76c-.09.35-.19.67-.32,1.01.86-.2,1.3-1.32,1.99-2.26"/>
        <path   d="m289.54,305.08c.32-.94.36-2.11-.27-1.83-.3.71.04,1.68.06,2.3.16-.08.21-.26.29-.45"/>
        <path   d="m290.42,313.79c-.82,2.5-.46,4.49-1.11,6.77-.35,1.23-.85,1.77-1.29,2.95-.27.68-.78,2.27-.11,1.92.14-.53.84-2.38,1.16-2.89.02,0,.1.02.14.01-.11.68-.6,1.48-.68,2.06-.12,1.05.74-.22,1.01-.95.56-1.51.29-3.19.25-4.33-.04-.73.23-1.42.24-2.12.02-.84-.17-.91.24-1.82.58-1.34,1.3-2.39.87-3.56-.19.32-.34.76-.44,1.17"/>
        <path   d="m292.76,298.41c-.52,1.6-.4,2.85-.76,4.31-.45,1.76-.99,3.46-.97,4.99,0,.62.03,1.05-.11,1.74-.14.64-.55,1.33-.68,1.87-.45,1.93.68.39,1.13-.24.03.39-.02.83-.14,1.27.9-1,.38-4.85.36-5.8,0-.94.05-1.96.23-3.02.17-1.02.33-1,.71-1.84.61-.35,1.19-1.53,1.13-2.35-.35-.2-.6.77-.89.54-.17-.14-.03-1.1.04-1.47"/>
        <path   d="m291.09,324.2c.82-1.36,1.06-2.67,1.24-4.09.19-1.49-.2-2.7.03-4.06.26-1.55,2.4-4.26,1.42-5.37-.72.71-.92,2.6-1.19,3.62-.29,1.14-.95,2.56-1.12,3.67-.21,1.25.24,1.79.1,3.08-.11,1.06-.41,1.97-.85,3.11"/>
        <path   d="m293.51,306.37c.3-.98,1.4-3.7.63-3.74-.64,1.31-.84,3.23-1.56,4.26"/>
        <path   d="m293.67,322.6c.36-1.15.79-2.39,1.02-3.53.26-1.19.16-2.15.37-3.32.27-1.46.79-1.63,1.17-2.97.26-.97.14-1.63-.48-1.4-.56,1.26-.15,1.98-.44,3.14-.27,1.03-.84,1.81-1.03,2.87-.19,1.16-.08,1.8-.42,3.09-.24.95-.57,1.9-.86,2.83"/>
        <path   d="m296.04,320.75c.98-2.73,2.52-5.08,2.34-7.57-.12-1.51-.27-2.21.47-4.2.08.56.11,1.24.31,1.63.33-.45.62-1.08.78-1.64,1.14-.33-.57,2.13-.78,2.64-.49,1.14-.48,2.82-.7,4.06-.34,1.96-1.01,6.52-2.62,7.06"/>
        <path   d="m299.19,321.78c.8-.12,1.32-2.05,1.69-3.17.52-1.6.67-2.58.83-3.99.12-1.06.19-2.38.48-3.46.23-.9.79-2.15.38-2.6-.2.45-.33.92-.47,1.38-.31-.02-.63-.41-.48-1.09.16-.74.62-.86.88-1.42.47-.96.25-2.17-.21-2.25-.28.72-.42,1.54-.6,2.26-.13.48-.24.77-.27,1.24-.05.48.29,1.19.16,1.62-.24.86-1.07,1.46-1.31,2.59-.25,1.18.16.67.51.99,1.26,1.13-.8,5.16-1.31,7.32"/>
        <path   d="m305.64,317.33c.07-.82.32-2.13.27-2.9-.02-.74-.3-1.38-.41-1.99-.19-1.16-.41-2.56-.24-3.9-1.02,2.1-.7,3.6-.17,5.23.45,1.37.39,2.55.26,4.26"/>
        <path   d="m307.3,317.61c1.24-1.04,1.42-4,.98-5.66-.39-1.44-.15-3.69-.84-4.73-.28,1.29.31,2.23.43,3.32.1.84.05,1.5-.04,2.45-.09,1.15.03,1.69.08,2.64.04.82,0,1.5-.45,1.82"/>
        <path   d="m309.22,315.91c.12-1.37.66-2.69.66-4.26.01-1.41-.39-2.01-.62-3.3-.09-.52-.55-2.34-.41-2.91.24-1.05.95-.45,1.09.23.18.89-.21,2.11-.18,3.05.02.87.3,1.52.32,2.49.01.83-.03,2.04-.18,2.89-.14.8-.86,2.02-.52,2.63"/>
        <path   d="m337.4,307.59c.24-2.56.46-4.46.18-6.87-.1-.81-.52-1.8-.45-2.7.1-.1.18-.17.29-.21.45,1.01.44,2.58.44,3.95.25-.08.52-.23.76-.42.27,1.56-.2,3.94-.31,5.65"/>
        <path   d="m335.85,297.89c1.53-4.29-1.38-5.31-.72-9.37.15.04.31.18.42.37-.14.24-.19.65-.29.82.25.19.47.42.73.52"/>
        <path   d="m340.31,306.91c.23-2.57.45-4.46.17-6.87-.1-.83-.52-1.81-.44-2.71.1-.11.18-.17.28-.21.46,1.01.44,2.57.45,3.95.25-.06.53-.23.75-.42.27,1.57-.19,3.94-.29,5.64"/>
        <path   d="m342.84,306.19c.35-2.05.47-5,.2-6.91-.13-1-.54-2.48.35-3.02,0,.9-.04,1.83-.06,2.72.45-.35.83-1.07.81-1.9.57,1.77-.26,3.54-.55,5.25-.21,1.28.12,2.76-.46,3.92"/>
        <path   d="m371.86,284.16c-.19,2.27.4,3.5.62,5.49.19,1.66-.13,3.5-.29,5.25"/>
        <path   d="m382.67,296.73c-1.42-3.28-2.18-7.01-2.92-10.74.35,1.15.52,2.13.83,3.28.27,1.08.52,2.57.87,3.64.27.85.7,1.31.92,2.19.21.76.3,1.68.44,2.47"/>
        <path   d="m381.94,287.21c.2,3.32.69,7.44,2.4,9.7-.33-2.34-1.08-3.49-1.67-5.54-.32-1.09-.73-2.94-.54-3.92"/>
        <path   d="m386.63,296.68c-.88-1.77-1.11-3.67-1.5-5.76"/>
        <path   d="m386.25,283.39c.26,1.28.78,2.39,1.04,3.74.23,1.11.38,2.26.62,3.38.39,1.92.73,3.91,1.14,5.86.9.25.26-1.86,0-2.55-.49-1.34-.83-2.61-1.13-4.13-.35-1.69-.5-5.27-1.32-6.12"/>
        <path   d="m389.76,284.17c.49,2.67,1.35,4.97,1.89,7.6.32,1.55.34,3.09.68,4.58,1.27.85.05-2.09-.12-2.7-.48-1.85-1.03-4.01-1.36-5.91"/>
        <path   d="m412.33,294.66c-.19-1.34-.64-2.54-1.05-3.74-.62-1.85-1.09-3.6-1.59-5.54-.31-1.21-1.41-3.66-.57-4.44.93,1.94,1.07,4.38,1.98,6.45,1.05,2.38,1.31,4.89,1.88,7.52"/>
        <path   d="m380.19,297.92c-.32-1.79-.52-3.71-.91-5.47-.22,1.61-.02,3.77.31,5.48"/>
        <path   d="m373.57,298.93c.55-1.74.56-3.66.59-5.51.25,2.04.44,3.8.39,5.95"/>
        <path   d="m377.7,297.83c-.2-2.07-.85-4.96-1.6-6.76-.39-.94-1.17-2.29-.45-3,.23.87.43,1.78.65,2.66.34-.44.53-1.22.3-2.03,1.01,1.6.67,3.5.83,5.22.13,1.29.84,2.66.58,3.91"/>
        <path   d="m498.22,296.38c.02-2.23-1.2-5.17-.24-7.25"/>
        <path   d="m494.66,293.99c-.25-1.55-1.22-1.92-1.06-3.81.08-1.01,1.32-3.25.42-4.02"/>
        <path   d="m492.49,296.6c0-1.27.49-3.71.24-4.77"/>
        <path   d="m490.71,297.59c-.17-1.93-.5-3.74.02-5.59.5-1.78.85-1.9.75-4.11"/>
        <path   d="m488.66,287.24c-2.58,2.88,1.13,6.98.14,9.64"/>
        <path   d="m486.16,293.54c.08-1.75-.22-3.48.06-5.19.26-1.5.88-2.9.3-4.76"/>
        <path   d="m484.33,288.39c0,1.58-.06,3.17-.03,4.76.03,1.02.38,2.47.08,2.82"/>
        <path   d="m484.98,290.71c0,.66,0,1.36-.02,2-.2.07-.4.32-.59.32"/>
        <path   d="m480.72,292.61c.11.96.04,2.06.57,2.64"/>
        <path   d="m480.17,296.14c.01-1.71-.33-3.56-1.26-4.21"/>
        <path   d="m461.31,294.47c-.94-2.09-.04-5.69-.43-8.18"/>
        <path   d="m468.57,287.26c.18,1.46,2.31,7.84,1.04,8.23"/>
        <path   d="m459.74,285.96c-.01,2.59-.42,6.48.34,8.73"/>
        <path   d="m449.42,295.05c0-1.1.27-4.7-.16-5.2"/>
        <path   d="m443,287.56c-.02,2.43-.31,4.61.95,6.06,0-2.29-.62-4.57-.56-7.14"/>
        <path   d="m440.99,293.98c-.32-1.77.52-5.85-.55-6.94-.29,1.45.83,7.11-.45,7.2"/>
        <path   d="m465.2,294.92c-.08-2.27.28-4.5.24-6.79-.05,0-.23-.02-.27-.04-.22,1.08-.11,2.44-.16,3.58-.1-.78-.05-1.62-.06-2.41-.68,1.23-.55,3.3-.51,5,.41-.47.29-1.48.7-2.12"/>
        <path   d="m472.32,288.37c.17,2.35-.74,5.11.45,7.33.04-1.06-.34-1.71-.4-2.66-.04-.89.22-1.43.22-2.22.02-1.41-.13-3.87-.35-5.22"/>
        <path   d="m503.82,293.57c-.65-2.42.02-4.21.64-5.98.48-1.35.43-2.79.42-4.51.19,1.79.38,3.13.37,4.96-.02,1.72-.07,3.45.56,4.84,0,.19-.06.25-.19.15"/>
        <path   d="m500.93,296.97c-.04-3.01.48-6.37,1.27-8.93.57,1.62-.23,4.21-.18,6.14.04,1.23.19,2.39.18,3.66"/>
        <path   d="m499.43,295c.02-2.55-.21-5.64.58-7.66.51-1.32,1.14-1.86,1.08-3.8-.22,2.14-.12,4.23-.23,6.44-.1,1.83-.33,3.41-.34,5.32"/>
        <path   d="m496.19,296.49c-.02-1.02-.62-1.17-.72-1.98-.1-.84.24-1.79.34-2.55"/>
        <path   d="m454.57,294.62c.2-1.09.17-1.96-.3-2.85-.44-.83-.37-1.22-.04-2.19"/>
        <path   d="m483.25,283.58c.31,2.93-.43,5.36-.66,8.23-.1,1.1,0,1.84.25,2.91.21.98.14,1.73-.46.73-.38-.66-.42-1.87-.6-2.78-.23-1.12-.33-2.26-.05-3.39.18-.68.47-1.21.6-1.92.12-.76.07-1.68.08-2.5"/>
        <path   d="m435.07,281.57c-.04,2.96-.13,5.8-.5,8.62-.12.93-.16,1.82-.02,2.77.07.54.24,1.04.22,1.6-1.06-.11-.79-5.35-.75-6.55.05-1.36.33-2.63.66-3.79.17-.55.44-1.04.42-1.72"/>
        <path   d="m431.74,282.91c-.02,2.76-.08,4.86-.93,7.11-.21.6-.48,1.22-.51,1.98-.04.79.19,1.5.11,2.32-1.44-.4-.52-3.85-.12-5.29.26-.93.56-1.29.58-2.31.03-.8.02-1.61.02-2.41"/>
        <path   d="m429.52,282.5c-.02,2.28-.72,3.46-1.12,5.42-.34,1.64-.18,3.49-.19,5.25"/>
        <path   d="m402.66,294.58c.84-3.44.95-7.23,1.05-11.03-.14,1.18-.15,2.18-.26,3.36-.08,1.11-.08,2.62-.23,3.72-.12.88-.47,1.4-.54,2.3-.07.78-.02,1.69-.02,2.5"/>
        <path   d="m399.03,285.34c.37,3.3.59,7.45-.72,9.92-.07-2.35.47-3.6.7-5.71.13-1.12.22-3-.14-3.95"/>
        <path   d="m396.33,284.89c-.02,2.8-.1,5.62.09,8.36.08,1.1-.17,2.66-.53,1.02-.21-.99.1-2.96.1-4.01"/>
        <path   d="m394.45,282.71c-.02,2.71-.49,5.1-.58,7.78-.05,1.59.19,3.1.11,4.63-1.11,1.02-.4-2.06-.34-2.69.16-1.89.34-4.1.34-6.03"/>
        <path   d="m448.27,291.91c-.18-2.57-.51-5.24-.89-6.16-.16,3.29-.66,5.54-.52,9.65"/>
        <path   d="m425.42,294.02c0-.97.01-1.94.03-2.9"/>
        <path   d="m510.83,297.55c-.39-1.77-.23-3.69-.09-5.53-.43,2.02-.78,3.75-.92,5.9"/>
        <path   d="m505.52,297.62c-.39-1.76-.22-3.68-.08-5.53-.43,2.02-.78,3.76-.93,5.92"/>
        <path   d="m508.25,297.73c-.15-2.06,0-5.01.44-6.9.22-.98.76-2.43-.08-3.03-.08.9-.12,1.81-.19,2.71-.4-.37-.74-1.13-.63-1.95-.73,1.73-.07,3.55.07,5.28.1,1.3-.37,2.76.1,3.93"/>
        <path   d="m423.38,294.34c-1.13-4.39,1.86-5.18,1.58-9.28-.15.02-.33.16-.45.33.12.25.13.66.21.84-.27.17-.51.38-.77.46"/>
        <path   d="m414.24,293.18c-.16-2.07-.01-5.02.43-6.91.22-.98.76-2.43-.08-3.03-.08.9-.12,1.83-.19,2.72-.42-.38-.73-1.13-.64-1.95-.73,1.72-.07,3.55.06,5.27.1,1.29-.37,2.74.1,3.94"/>
        <path   d="m456.54,292.64c-.04-1.34.2-2.59.4-3.84.3-1.92.47-3.71.64-5.7.09-1.25.77-3.82-.19-4.47-.58,2.05-.32,4.49-.87,6.66-.63,2.51-.45,5.01-.57,7.69"/>
        <path   d="m476.47,295.37c-.16-2.06-.02-5.02.43-6.89.23-.98.77-2.43-.07-3.03-.09.88-.12,1.81-.19,2.7-.41-.38-.74-1.12-.64-1.93-.73,1.72-.06,3.53.06,5.27.1,1.29-.37,2.76.1,3.93"/>
        <path   d="m438.14,292.64c-.04-1.34.2-2.59.4-3.84.3-1.93.47-3.72.64-5.71.1-1.25.78-3.82-.19-4.46-.58,2.05-.31,4.49-.86,6.65-.63,2.51-.46,5.01-.58,7.7"/>
        <path   d="m388.01,281.3c-.18.54-.34,1.48-.41,2.23.1-.38.68-2.24,1.03-1.88"/>
        <path   d="m389.91,282.68c.18-1.56.69-2.76.9-4.31.17-1.11.31-2.25.42-3.42"/>
        <path   d="m382.2,283.15c-.18,1.97.67,4.65-.33,6.44"/>
        <path   d="m384.63,282.88c.1,1.39.93,1.79.65,3.44-.14.89-1.4,2.79-.67,3.54"/>
        <path   d="m398.5,281.94c-.59,1.65-1.4,3.91-1.5,5.89"/>
        <path   d="m386.03,278.27c-.1,1.13-.7,3.26-.57,4.22"/>
        <path   d="m399.19,272.59c0,1.72.15,3.34-.43,4.95-.58,1.54-.88,1.63-.97,3.59"/>
        <path   d="m398.7,286.96c2.48-2.38-.47-6.25.61-8.55"/>
        <path   d="m401.56,272.59c-.21,1.54-.07,3.1-.45,4.59-.34,1.32-.99,2.51-.62,4.2"/>
        <path   d="m401.3,287.23c.12-1.4.29-2.8.38-4.21.06-.91-.15-2.22.14-2.51"/>
        <path   d="m402.93,279.44c-.55,1.34-.15,2.2-.41,3.57"/>
        <path   d="m403.93,287.46c.06-.58.11-1.2.18-1.77.17-.05.37-.25.53-.25"/>
        <path   d="m393.52,277.68c-.02-.86.12-1.83-.3-2.37"/>
        <path   d="m406.7,273.6c-.14,1.51.02,3.18.79,3.8"/>
        <path   d="m405.95,277.34c-.99.97.41,3.63-.12,5.13"/>
        <path   d="m404.33,282.02c.16.57.05,1.15-.15,1.64"/>
        <path   d="m407.25,285.84c.72-.53.39-2.03.46-3.08"/>
        <path   d="m407.1,279.05c-.15.58-.15,1.09.06,1.34"/>
        <path   d="m408.05,279.9c1.04-1.38.53-4.69,1.11-6.44"/>
        <path   d="m409.08,279.97c.66,1.91-.4,5.04-.24,7.27"/>
        <path   d="m357.57,287.8c-.05-1.31-1.44-7.1-.29-7.36"/>
        <path   d="m356.07,287.39c.35,1.48-.19,5.07-.35,6.76"/>
        <path   d="m357.57,290.82c.21-2.29.85-5.71.35-7.76"/>
        <path   d="m359.22,288.43c-.15,1.66-.56,3.82-.13,5.29"/>
        <path   d="m360.48,284.63c-.09.98-.6,4.15-.25,4.62"/>
        <path   d="m298.83,286.84c.2-2.15.62-4.06-.38-5.43-.18,2.03.2,4.09-.04,6.36"/>
        <path   d="m362.48,286.42c-.27-.71-.49-2.01-.52-2.92"/>
        <path   d="m300.6,287.41c.17-1.91.29-3.52.04-5.31.8-.32.51,1.27.43,2.25"/>
        <path   d="m363.78,280.78c.15,1.6-.89,5.15-.04,6.18.36-1.27-.19-6.35.93-6.35"/>
        <path   d="m303.08,282.92c-.2,1.57-.72,3.83.08,4.9.16-1.94.11-3.52.14-5.36"/>
        <path   d="m367.69,280.27c-.07.86-.91,6.17-.41,6.22.89.08.66-5.11.74-5.99"/>
        <path   d="m305.97,280.11c-.48,1.74-1.39,5.48-.26,6.46.05.37.12.46.21.76.32-2.21-.9-3.54-.29-5.46"/>
        <path   d="m370.53,280.95c-.23,2.03-.72,3.94-.78,5.99,1.01.39,1.08-4.11.97-5.12"/>
        <path   d="m310.38,278.73c.19,1.54.36,3.23.65,4.68.25,1.21.1,1.9.28,3.09.03-1.76-.31-5.43-.84-7.14"/>
        <path   d="m311.62,283.03c.29,2.43.48,4.77.77,7.15,1.41.39-.18-3.82-.24-4.88"/>
        <path   d="m312.57,277.88c.32,1.99.26,4,.54,6.01.04,0,.21,0,.25,0,.07-.98-.18-2.16-.26-3.18.17.68.23,1.42.32,2.12.45-1.17.11-2.97-.11-4.48-.31.46-.1,1.34-.37,1.96"/>
        <path   d="m315.1,287.53c-.42-2.04.07-4.6-1.21-6.42.09.93.49,1.46.65,2.29.13.78-.03,1.29.05,1.99.14,1.25.55,3.39.89,4.55"/>
        <path   d="m316.89,283.38c.18,1.49.12,4.98.57,5.97.42-1.76.22-2.92-.02-4.99"/>
        <path   d="m383.9,281.94c-.06.91.46,1.07.48,1.81.03.74-.34,1.56-.48,2.23"/>
        <path   d="m403.49,272.79c-.18.67-.29,1.34-.38,2.1-.05.59,0,1.09-.21,1.52-.34.78-.91,1-.95,2.18"/>
        <path   d="m404.73,280.48c-.01.36-.15.64-.3.82-.16-.21-.5-.85-.56-1.24-.08-.7.28-1.74.34-2.47"/>
        <path   d="m406.13,284.17c.18,0,.3.18.37.45-.04.04-.12.06-.16.06-.02.06-.03.11,0,.16.11-.14.06.02.12-.22-.2.41-.17.97-.21,1.51-.06.67-.17.94.22,1.18"/>
        <path   d="m413.48,272.9c-.26.95-.29,1.72.05,2.54.32.77.23,1.1-.14,1.94"/>
        <path   d="m307.36,281.83c.09,1.02.65,1.83.34,2.93-.29,1.04-.74,1.6-.69,2.88"/>
        <path   d="m385.7,273.98c-.42.78-.77,1.55-.75,2.68.49-.58.78-1.41.79-2.44"/>
        <path   d="m365.88,285.76c.24-.54.44-1.24.55-1.89"/>
        <path   d="m391.82,282.76c-.06-.57-.2-1.06-.08-1.68.68.1,1.2-1.95,1.36-2.92.1-.55.13-1.2.25-1.71.15-.52.43-.62.47-1.29-.23,1.52-.43,3.22-.76,4.64-.33,1.38-1.23,1.47-.95,3.19"/>
        <path   d="m396.49,274.21c.67,3.94-2.02,4.46-2.08,8.11.13-.01.31-.14.42-.27-.09-.23-.07-.6-.12-.75.25-.15.47-.3.7-.37"/>
        <path   d="m375.87,281.77c-.02,1.84-.36,4.45-.9,6.08-.27.85-.85,2.11-.17,2.69.14-.78.24-1.6.37-2.38.33.37.56,1.04.41,1.75.77-1.48.32-3.12.34-4.67,0-1.14.53-2.42.21-3.49"/>
        <path   d="m375.45,282.12c-.06-.57-.2-1.05-.08-1.67.68.1,1.2-1.95,1.36-2.92.1-.55.12-1.19.25-1.71.14-.53.43-.62.47-1.3-.22,1.52-.43,3.23-.76,4.64-.33,1.38-1.23,1.48-.95,3.18"/>
        <path   d="m379.32,281.32c.67,3.96-2.02,4.48-2.08,8.12.14-.02.31-.13.42-.26-.08-.23-.06-.61-.12-.77.25-.14.47-.3.71-.36"/>
        <path   d="m379.32,274.78c.2,1.58-.08,3.27-.34,4.9.53-1.76.97-3.27,1.25-5.18"/>
        <path   d="m381.4,277.66c-.19,2.27-.29,3.97-.91,6.05-.21.7-.73,1.51-.81,2.31.07.1.13.18.22.22.56-.83.78-2.2,1-3.4.2.1.41.27.59.47.48-1.34.44-3.48.61-5"/>
        <path   d="m383.79,273.98c-.02,1.83-.37,4.45-.89,6.08-.28.86-.85,2.1-.17,2.69.13-.79.24-1.6.38-2.39.32.36.56,1.05.41,1.77.78-1.49.33-3.14.34-4.69.01-1.16.53-2.42.21-3.49"/>
        <path   d="m473.85,283.87s1.97-4.23.8-4.2"/>
        <path   d="m472.79,282.34s1.49-6.32-.67-5.19"/>
        <path   d="m471.06,285.62c.21-1.68.24-2.32.12-3.73-.11-1.42-.01-1.75.68-2.85"/>
        <path   d="m470.1,281.36c.44-1.45.36-2.41-.06-3.14.26-.43.41-1,.34-1.49"/>
        <path   d="m467.51,284.51c.14-1.09.25-2.18.41-3.25.03.05.07.08.12.07-.36-1.05.36-2.3.39-3.13"/>
        <path   d="m467.82,276.04c-.32,1.12-.47,2.13-.62,3.31"/>
        <path   d="m464.61,275.25c-.38,1.88-.07,2.52.19,4.07.21,1.12-.41,3.41.11,4.13"/>
        <path   d="m466.01,278.29c-.26,2.13-.36,4.11-.68,6.14"/>
        <path   d="m463.59,279.78c-.12.51-.41.9-.44,1.47-.03.37.19.66.17,1.06-.03.74-.07,1.17-.51,1.46"/>
        <path   d="m462.11,280.69c-.04-1.13.3-1.94.21-3.21-.06-.93-.28-1.78.06-2.48"/>
        <path   d="m440.96,267.02c.7.87.81,2.27,1.08,3.63"/>
        <path   d="m440.62,268.03c-.21-1.04-.74-2.85-.68-3.85.04-.64,1.12-1.87.12-2.32"/>
        <path   d="m439,265.58c-.47,1.11.26,3.88.54,5.34"/>
        <path   d="m438.2,261.25c.02,1.1.39,2.74.8,3.71"/>
        <path   d="m437.85,267.86c-.25-1.25.28-2.14.09-3.26"/>
        <path   d="m437.32,262.95c-.7.4-1.09,2.17-.83,3.62.11.54,1.18,2.93.15,2.66-.54-.15-.76-2.44-.92-3.29-.28-1.41-.62-2.3-.97-3.39"/>
        <path   d="m430.28,259.16c.19,1.17.38,3.87,1.15,4.4.3-.99-.45-3.46-.7-4.68"/>
        <path   d="m432.28,263.85c.09,1.08.8,6.44,1.5,6.59,1.24.29-.71-5.04-.81-5.67"/>
        <path   d="m432.68,261.04c.09,1.51.39,4.01,1.24,5.12.64-.87-.25-5.12-.8-6.23"/>
        <path   d="m431.26,266.18c.26,1.31.18,2.53.73,3.72"/>
        <path   d="m430,264.75c-.22-1.03-.73-1.9-.8-2.99-.05-.6.35-1.87-.16-2.21"/>
        <path   d="m428.33,264.27c.29,1.78,2.42,4.14,1.37,5.79"/>
        <path   d="m427.92,265.21c-.31-1.56-.34-2.15-.33-3.53.02-.76-.03-2.58-.61-2.67"/>
        <path   d="m426.69,265.52c.23,1.14.9,3.17.23,3.74"/>
        <path   d="m426.3,263.22c-.46-1.76-.14-3.47-.43-5.1"/>
        <path   d="m425.07,264.66c.31,1.59,1.06,3.98.41,5.1"/>
        <path   d="m424.38,258.47c.19.99.69,2.37.5,3.25"/>
        <path   d="m424.34,262.8c-.01.31.05.81.16,1.22"/>
        <path   d="m424.26,270.44c-.28-1.27-.81-2.21-1.11-3.46-.23-.9-.45-1.83-.64-2.78"/>
        <path   d="m412.2,257.13c.31,1.6-.15,3.88.82,5.26"/>
        <path   d="m409.99,259.3c.03,1.15-.61,1.55-.24,2.89.19.72,1.38,2.17.85,2.85"/>
        <path   d="m409.39,265.14c.63,1.31,1.49,3.09,1.73,4.7"/>
        <path   d="m408.08,257.7c.18.92.86,2.62.83,3.41"/>
        <path   d="m407.35,260.6c.14,1.41.16,2.76.78,4.03.61,1.22.87,1.27,1.1,2.87"/>
        <path   d="m407.13,264.12c-2.24-1.74-.15-5.18-1.23-6.97"/>
        <path   d="m404.48,259.9c.3,1.25.32,2.54.76,3.73.39,1.05,1.03,1.98.87,3.39"/>
        <path   d="m403.21,263.88c-.42-1.34-.88-2.68-1.27-4.03-.25-.88-.33-2.2-.67-2.42"/>
        <path   d="m405.04,267.43c.56,1.06.31,1.8.65,2.9"/>
        <path   d="m403.94,266.8c-.17-.56-.36-1.15-.54-1.7-.18-.02-.42-.17-.58-.13"/>
        <path   d="m400.29,260.04c-.16-.84-.5-1.76-.21-2.38"/>
        <path   d="m398.9,257.17c.45,1.45.66,3.11.04,3.89"/>
        <path   d="m399.01,262.24c1.17.74.36,3.64,1.21,4.99"/>
        <path   d="m404.38,268.56c-.07.48.06.95.27,1.33"/>
        <path   d="m398.6,260.37c-.81-.36-.8-1.9-1.1-2.91"/>
        <path   d="m397.73,260.95c.26.53.37,1.04.23,1.32"/>
        <path   d="m401.31,271.33c-1.31-1.13-1.51-4.47-2.45-6.06"/>
        <path   d="m396.4,258.36c-.25,2,1.45,4.84,1.77,7.05"/>
        <path   d="m397.47,272.09c-.23-1.29-.1-7.24-1.27-7.26"/>
        <path   d="m393.74,258.63c-.03,1.52,1.26,4.91,1.78,6.53"/>
        <path   d="m396.1,270.22c-.69-2.19-2.04-5.4-1.99-7.51"/>
        <path   d="m391.86,258.86c.49,1.59,1.35,3.62,1.25,5.15"/>
        <path   d="m392.1,264.2c.29.94,1.46,3.93,1.22,4.46"/>
        <path   d="m391.25,266.99c-.65-2.06-1.46-3.84-.77-5.39.6,1.94.67,4.04,1.39,6.2"/>
        <path   d="m392.88,272.3c.11-.75.05-2.07-.11-2.97"/>
        <path   d="m391.59,271.64c-.58-1.83-1.03-3.39-1.16-5.19-.85-.14-.23,1.35.07,2.3"/>
        <path   d="m389.06,268.07c.2,1.59,1.97,4.84,1.35,6.04-.63-1.17-1.15-6.25-2.26-6.01"/>
        <path   d="m386.85,259.39c.53,1.48,1.51,3.59.96,4.8-.57-1.86-.85-3.42-1.27-5.2"/>
        <path   d="m386.18,263.77c.25.82,2.2,5.83,1.71,5.99-.86.27-1.73-4.85-1.99-5.7"/>
        <path   d="m385.62,267.07c.84,1.6,2.52,5.06,1.62,6.26.03.38-.03.47-.05.79-.77-2.1.14-3.65-.87-5.4"/>
        <path   d="m382.84,258.62c.66,1.94,1.54,3.7,2.03,5.69-.9.59-1.92-3.79-2.04-4.8"/>
        <path   d="m383.13,264.5c.46,1.49,1,3.09,1.33,4.53.28,1.21.69,1.77,1.02,2.94-.74-1.59-1.95-5.09-2.15-6.86"/>
        <path   d="m380.82,267.07c.73,2.34,1.52,4.56,2.22,6.84-1.13.93-1.4-3.56-1.78-4.56"/>
        <path   d="m378.07,262.59c.52,1.94,1.41,3.75,1.97,5.7-.04,0-.18.08-.22.1-.46-.86-.72-2.04-1.07-3,.12.68.38,1.39.58,2.07-.89-.88-1.32-2.67-1.74-4.13.47.29.63,1.18,1.14,1.63"/>
        <path   d="m378.95,271.46c-.46-2.04-1.95-4.16-1.54-6.34.3.88.16,1.53.35,2.35.2.76.56,1.16.77,1.83.39,1.19.89,3.32,1.06,4.52"/>
        <path   d="m374.42,258.94c.45,1.43,1.94,4.58,1.93,5.68-1.11-1.44-1.41-2.58-2.03-4.56"/>
        <path   d="m485.45,281.97c-.87.03-1.25.17-1.49,2.14"/>
        <path   d="m482.39,284.61c.55-1.6,1.32-3.26,1.43-5.12.1-1.81-.55-2.8.03-4.78-.35,1.25-.55,2.45-.74,4.05"/>
        <path   d="m482.07,277.35c-.14.52-.41.95-.68,1.21-.15-.75.12-1.92.21-2.8-.1-.04-.18-.02-.27-.03"/>
        <path   d="m481.91,279.34c-.13,1.08.17,1.87.1,2.9-.09,1.44-.35,1.43-.96,2.34"/>
        <path   d="m479.93,284.27c.15-1.35.43-3.47.23-4.61-.73.76-1.47,3.17-1.9,4.53"/>
        <path   d="m479.71,275.21c-.08.64-.65,3.62-.24,3.85.43.24.94-2.12.98-2.84"/>
        <path   d="m476.92,274.15c-.49,2.44-.88,4.78-1.19,7.31-.09.81-.38,2.04-.09,2.55.73-1.08,1.14-4.02,1.6-5.58.34-1.1.69-2.24,1-3.32"/>
        <path   d="m497.21,276.09c-.23,1.02-.39,2.25-.46,3.29.54-.12,1.04-1.21,1.13-2.28"/>
        <path   d="m496.01,279.13c-.36,2.91-.69,5.77-.91,8.64-.09,1.16-.43,4.17-1.25,3.63-1.15-.74.75-3.53.89-4.92.11-1.01-.18-1.82-.14-2.84.04-1.51.38-3.24.56-4.77"/>
        <path   d="m489.58,278.97c.13,2.41.95,5.07,1.27,7.47.19,1.43.85,2.07,1.03,3.35-.04-1.2-.44-2.29-.61-3.43-.11-.84-.18-1.71-.3-2.52-.27-1.9-.46-3.32-.41-5.19"/>
        <path   d="m454.26,276.93c.2,1.75.04,4.79.85,6.15.1-.99.01-2.05.06-3.04.05-.81.18-1.53.23-2.36"/>
        <path   d="m452.74,277.76c.1,1.07-.56,1.55-.48,2.59.05.77.68,2.04.96,2.67.67,1.44,1.04,1.89,1.22,3.82-.37-3.36-.62-7.09-.83-10.45-.11-.02-.24-.02-.35.04"/>
        <path   d="m450.41,275.17c.02.77-.21,1.45-.23,2.05.06-.13.24-.29.31-.39-.08-.05-.18.03-.24,0,.1-.07.3-.07.42-.08-.19.74.1,1.72-.19,2.51-.35.97-1.2.53-1.41,1.64-.32,1.65.9,4.31,1.59,5.04.08-1.08-.24-2.17-.19-3.23.04-.99.4-1.23.6-1.89.43-1.43.04-3.51-.12-5.21"/>
        <path   d="m448.88,274.21c.07.73-.17,1.37-.17,2.1,0,.55.11,1.21.17,1.75.37-.8.5-2.63.59-3.68"/>
        <path   d="m446.89,274.58c.21,2.21.68,4.33.94,6.49.14,1.1-.02,2.9.54,3.6.18-1.65-.29-4.16-.45-5.89-.16-1.79-.12-2.65.18-4.29"/>
        <path   d="m444.94,273.87c.18,1.99.4,4.05.63,6,.39-1.58-.62-5.17.54-5.8"/>
        <path   d="m443.07,276.08c-.32,1.81.27,3.08.83,4.35.43.97.48,2.02.59,3.28.02-1.33.08-2.31-.04-3.65-.12-1.25-.26-2.52.1-3.57-.01-.14-.06-.18-.15-.1"/>
        <path   d="m431.25,275.86c.16,2.2.74,4.63,1.47,6.47.31-1.22-.42-3.07-.51-4.48-.05-.9,0-1.75-.1-2.68"/>
        <path   d="m430.3,277.38c.17,1.86.19,4.14.89,5.57.45.94.94,1.3,1.02,2.72-.29-1.55-.35-3.09-.57-4.7-.18-1.33-.45-2.48-.57-3.88"/>
        <path   d="m410.74,257.31c.13.74-.28.92-.24,1.52.04.62.41,1.26.59,1.79"/>
        <path   d="m407.04,265.16c.2.54.35,1.08.49,1.69.09.48.1.89.3,1.23.34.61.83.74.97,1.7"/>
        <path   d="m400.97,263.79c.09.36.27.59.47.74.1-.24.32-.94.28-1.33-.07-.71-.64-1.65-.85-2.34"/>
        <path   d="m402.29,267.68c-.17.03-.26.24-.27.51.05.03.13.03.17.03.03.05.05.1.04.15-.14-.11-.05.03-.17-.19.28.35.37.91.53,1.43.2.64.37.87.04,1.19"/>
        <path   d="m394.3,268.32c.46.88.65,1.62.49,2.49-.16.82,0,1.13.54,1.87"/>
        <path   d="m383.23,268.44c.13,1.02-.24,1.92.29,2.94.5.95,1.06,1.41,1.28,2.67"/>
        <path   d="m380.08,272.67c.18.17.48.46.62.75.22.47.06.47.17.97-.08-.05-.11,0-.2-.09.06.09.1.17.17.23,0-.43-.23-.42-.33-.72-.08-.23-.05-.5-.13-.75"/>
        <path   d="m430.3,283.28c.04-2.16-.64-3.9-.99-5.99-.14-.8-.11-1.34,0-2.14.1-.73,0-1.27-.38-.51-.24.5-.19,1.39-.26,2.07-.1.83-.1,1.67.17,2.49.17.48.42.85.55,1.37.14.55.15,1.23.21,1.82"/>
        <path   d="m426.36,276.17c.55,1.82,1.03,3.4,1.22,5.43.1,1.09.08,2.2.49,2.98h.13c.22-1.57-.18-3.17-.66-4.54-.49-1.42-.91-3.1-1.11-4.71"/>
        <path   d="m425.8,282.99c-.21-2.17-.46-4.24-.9-6.29-.14-.68-.23-1.32-.19-2.03.02-.39.11-.77.06-1.18-.76.14-.24,3.95-.13,4.83.13,1,.4,1.91.71,2.74.16.39.38.74.41,1.24"/>
        <path   d="m424.9,284.4c-.19-2.01-.37-3.55-1.12-5.15-.2-.43-.42-.87-.5-1.42-.07-.58.04-1.11-.06-1.7-1.02.37-.14,2.84.24,3.87.25.67.48.92.56,1.67.07.59.11,1.17.17,1.77"/>
        <path   d="m422.94,284.08c-.09-1.01-.17-2.02-.24-3-.29.74-.01,2.25.07,3.17"/>
        <path   d="m422.94,280.98c-.15-1.66-.74-2.49-1.14-3.91-.35-1.18-.36-2.55-.47-3.84"/>
        <path   d="m436.75,275.96c.82,2.47,1.14,5.24,1.45,8.01-.18-.86-.25-1.59-.4-2.44-.13-.81-.22-1.91-.4-2.71-.14-.64-.42-1-.53-1.65-.1-.56-.11-1.24-.17-1.83"/>
        <path   d="m436.33,276.34c.14,1.15-.07,1.9-.05,2.85-.06-.74-.13-1.46-.2-2.18"/>
        <path   d="m436.39,281.45c.35.64.64,1.28.62,2.21-.41-.48-.64-1.17-.66-2.01"/>
        <path   d="m435.19,278.2c.12,1.33.14,2.74.32,4.03.26-1.16.25-2.76.11-4.02"/>
        <path   d="m391.22,260.33c-.25-.55-.63-1.09-.99-1.23,0,.79-.36,1.43-.09,2.35"/>
        <path   d="m388.77,261.44c-.04-.32-.11-.67-.19-.99"/>
        <path   d="m389.84,265.06c-.35-.49-.7-1.12-.95-1.73"/>
        <path   d="m385.12,265.94c-.27-.47-.74-1-1.05-1.12.09.69.39,1.43.61,2.11"/>
        <path   d="m381.31,262.18c.26.82.52,1.64.78,2.46"/>
        <path   d="m377.98,272.9c.29.49.65,1.37.88,2.09-.18-.35-1.15-2.04-1.41-1.62"/>
        <path   d="m380.94,265.16c.15-.82-.35-1.65-.8-2.2.14-.78.71.04.55.74-.11.54-.94,1.27.14,1.78"/>
        <path   d="m486.45,283.78c.2-.93-.12-2.09-.47-2.33"/>
        <path   d="m505.3,279.48c-.25.76-.67,1.02-.96,1.62"/>
        <path   d="m498.45,265.94c-.08.49.06,1.3-.1,1.72-.1.26-.41.36-.57.48-.13.66.19,1.56.43,1.77"/>
        <path   d="m505.34,277.74c.37-.83.2-1.37,0-1.93-.18-.53-.33-1.36-.31-2.15"/>
        <path   d="m504.26,276.99c.02-.26.06-.51.1-.77-.58.24-.76,1.1-1.14,1.86"/>
        <path   d="m503.35,279.54c-.11.72.01,1.57.43,1.3.12-.55-.25-1.21-.34-1.66-.1.08-.12.21-.15.36"/>
        <path   d="m495.1,273.56c.27-1.9-.25-3.31-.07-5.03.09-.93.39-1.37.55-2.28.1-.52.27-1.73-.17-1.4-.03.4-.3,1.82-.46,2.22-.02,0-.07,0-.1,0-.01-.5.25-1.13.22-1.57-.05-.77-.5.24-.61.8-.2,1.15.2,2.34.38,3.16.12.52.02,1.05.1,1.56.09.61.24.64.05,1.34-.24,1.03-.62,1.88-.17,2.68.1-.25.15-.59.17-.89"/>
        <path   d="m501.36,281.71c.16-1.21-.08-2.11,0-3.21.1-1.32.26-2.61.06-3.72-.08-.45-.16-.76-.14-1.27.01-.48.23-1.02.25-1.43.08-1.45-.54-.21-.78.29-.07-.28-.09-.6-.06-.93-.51.82.35,3.56.48,4.24.12.68.22,1.43.22,2.21,0,.76-.11.77-.28,1.41-.39.32-.65,1.24-.5,1.82.28.1.33-.62.57-.49.14.08.16.79.16,1.07"/>
        <path   d="m491.86,264.19c-.42,1.08-.42,2.06-.37,3.1.06,1.1.48,1.93.5,2.95,0,1.15-1.17,3.34-.33,4.04.42-.59.33-1.98.38-2.75.07-.85.36-1.96.34-2.78,0-.93-.4-1.27-.47-2.22-.06-.78.04-1.48.21-2.35"/>
        <path   d="m499.18,277.25c-.09.74-.53,2.83.03,2.78.29-1.02.19-2.43.57-3.26"/>
        <path   d="m497.89,272.54c-.11.87-.26,1.82-.28,2.67-.03.89.16,1.58.16,2.45,0,1.09-.36,1.27-.45,2.28-.07.73.1,1.2.52.96.24-.98-.15-1.45-.08-2.32.06-.77.37-1.4.37-2.19-.01-.86-.17-1.31-.09-2.28.05-.71.16-1.44.25-2.14"/>
        <path   d="m489.16,265.04c-.35,2.08-1.16,3.96-.7,5.74.27,1.08.47,1.57.2,3.09-.13-.4-.23-.89-.43-1.15-.18.36-.31.85-.35,1.28-.77.36.13-1.61.22-2,.21-.88-.02-2.1-.02-3.02,0-1.45-.11-4.83.98-5.39"/>
        <path   d="m486.45,265.75c-.55.18-.69,1.63-.8,2.48-.17,1.21-.15,1.94-.08,2.98.05.78.16,1.74.1,2.56-.05.68-.29,1.64.06,1.92.09-.34.12-.7.16-1.05.23-.02.51.22.49.74-.02.55-.34.69-.46,1.12-.21.75.11,1.6.44,1.61.11-.55.1-1.16.14-1.7.03-.36.07-.58.03-.93-.03-.35-.36-.83-.32-1.16.06-.64.58-1.17.61-2.01.03-.88-.2-.47-.5-.66-1.05-.68-.09-3.83,0-5.45"/>
        <path   d="m483.61,273.13c-.04-.97.36-.9.43-1.64.07-.89-.5-1.55-.74-2.12-.5-1.16-.28-2.65-.4-4.01-.05-.45-.25-1.49.05-1.67.13-.09.38.33.42.63.03.33-.12.6-.12.95-.03,1,.35,2.2.66,2.95.24.59.67,1.18.76,1.91.06.59-.11,1.31-.08,1.94.02.42.27,1.59-.16,1.37,0-.04.04-.04.04-.02-.07-.35-.08-.64-.23-.87-.14.37-.44.87-.68.48"/>
        <path   d="m493.95,272.27c.06.61.04,1.58.17,2.13.12.53.4.97.55,1.4.29.82.62,1.81.68,2.8.46-1.64.04-2.68-.55-3.81-.5-.95-.61-1.81-.73-3.06"/>
        <path   d="m479.05,263.39c.18,1.93.34,2.99.99,4.5.36.83.83,2.02.45,2.9-.53-.54-.61-.99-.73-1.97-.1-.89-.22-1.24-.5-1.97-.25-.64-.44-1.31-.47-2.15-.03-.64.41-2.39.25-2.55"/>
        <path   d="m491.5,271.39c-.75.89-.51,3.05.02,4.2.46,1,.58,2.65,1.21,3.33.04-.96-.5-1.58-.73-2.37-.18-.6-.23-1.08-.29-1.78-.08-.84-.23-1.22-.39-1.9-.14-.59-.19-1.09.1-1.37"/>
        <path   d="m476.19,262.78c.15,1.67.23,2.21.85,3.32.33.6.93,1.91.4,2.6-.04-1.01-.12-1.08-.5-1.31-.22-.14-.3.14-.5-.12-.15-.18-.32-.88-.38-1.18-.28-1.25.05-2.89-.31-3.96"/>
        <path   d="m475.81,266.06c.09,1-.13,2.02.08,3.16.17,1.03.53,1.41.86,2.33.12.36.69,1.64.67,2.06-.04.79-.63.43-.81-.05-.24-.63-.12-1.55-.26-2.23-.13-.63-.41-1.07-.55-1.76-.12-.6-.24-1.48-.24-2.12,0-.59.36-1.55.04-1.96"/>
        <path   d="m474.73,264.21s.27.69.33,1.17"/>
        <path   d="m474.02,264.02c-.12.45-.09,1.03-.02,1.55"/>
        <path   d="m474.67,263.28c-.07-.46-.04-.88-.07-1.34"/>
        <path   d="m473.83,266.62c.45,1.17.72,1.83.9,3.34.07.61.14,1.19.31,1.71.14.44.37.6.42,1.13-.22.14-.83.05-1.03-.2-.56-.7-.42-1.63-.38-2.39.05-1.08-.15-2.15-.25-3.27"/>
        <path   d="m472.24,268.57c.08.51.59.87.86.55.47-.55-.04-1.44-.31-2.01-.35-.7-.56-1.08-.7-2.01-.18-1.06-.11-2.1-.69-2.85-.1.75.1,2.1.26,2.88.1.54.4.94.52,1.47.14.63.12,1.27.16,1.93"/>
        <path   d="m470.7,270.9c.07-.46.21-.84.14-1.35-.56-.03-.89-1.79-1-2.62-.04-.45-.04-.98-.13-1.43-.09-.44-.33-.59-.32-1.13.12,1.27.2,2.7.42,3.9.2,1.18.94,1.41.63,2.76"/>
        <path   d="m452.46,274.14c.12-.6-.12-.99-.44-.73,0,.17.01.42.05.59"/>
        <path   d="m469.92,262.94c-.01-.2-.06-.39-.12-.59-.27,0-.34.51-.18.86"/>
        <path   d="m450.59,271.03c.27.36.57,1.04.35,1.58"/>
        <path   d="m467.69,260.88c-.74,3.12,1.46,4,1.34,6.97-.11-.04-.25-.15-.33-.29.07-.17.08-.48.13-.59-.2-.16-.38-.33-.56-.41"/>
        <path   d="m467.86,266.23c-.24,1.26-.08,2.68.05,4.05-.35-1.52-.65-2.83-.79-4.42"/>
        <path   d="m465.58,260.51c.05,1.88.05,3.28.47,5.08.14.6.53,1.35.55,2.02-.07.08-.12.12-.19.15-.42-.78-.53-1.92-.67-2.94-.18.04-.36.15-.51.28-.33-1.17-.19-2.9-.27-4.16"/>
        <path   d="m464.48,262.84c-.07,1.5.09,3.68.46,5.11.18.74.6,1.85,0,2.21-.08-.66-.12-1.35-.18-2.01-.3.25-.53.76-.43,1.37-.57-1.33-.12-2.6-.06-3.86.04-.95-.33-2.06,0-2.88"/>
        <path   d="m463.04,260.32c.4,1.91.26,3.61.17,5.43"/>
        <path   d="m462.79,270.07c-.18-.96-.45-1.78-.62-2.67-.14-.72-.13-1.04-.47-1.66-.12-.2-.35-.4-.42-.58-.31-.73-.3-1.55-.4-2.47-.12-1.18-.24-1.08-.85-.97"/>
        <path   d="m459.71,261.14c-.27,1.11.61,3.37.85,4.46.08.41.48,1.81.35,2.21-.22.71-.52-.03-.63-.45-.13-.57-.02-1.2-.1-1.77-.08-.58-.31-1.09-.42-1.67-.26-1.29-.54-2.65-.65-3.99.12.3.28.73.29,1.17"/>
        <path   d="m458.34,262.92c.1,1.08.13,2.36.36,3.39.13.59.57,1.22.66,1.78.06.45.05.85-.19.96-.45.23-.46-.95-.51-1.49-.16-1.6-.76-3.25-.61-4.9"/>
        <path   d="m457.26,263.96c-.12-1.3.11-2.79,0-4.18.55,1.76.44,2.65.39,4.32"/>
        <path   d="m446,263.28c.66,1.14.84,1.76.96,3.36-.4-1-1.23-2.58-1.42-3.7"/>
        <path   d="m436.23,273.66c.05.53.03,1.09.26,1.48.01-.33,0-.68-.05-1"/>
        <path   d="m446.19,264.5c-.09-1.06.05-1.82.01-2.79-.03-.95-.41-2.09-.66-2.88,1.41.46.55,4.23,1.4,5.27-.1-.23-.19-.39-.3-.6"/>
        <path   d="m444.63,261.01c.62.66-.11,1.94-.16,2.72-.08.99.53,1.91.33,2.61"/>
        <path   d="m443.47,267.1c-.03-.31-.06-.61-.09-.91"/>
        <path   d="m443.27,259.34c.24.85.87,2.56.71,3.6-.18,1.05-.89-.17-.91-1.07,1.13.25-.28-2.35-.32-2.84"/>
        <path   d="m442.44,266.61c-.31-.4-.78-1.33-.66-2.14.04-.32.28-.4.31-.83.02-.29-.07-.75-.13-.99.12.79.44,1.41.53,2.22.07.61.09,1.23.14,1.84"/>
        <path   d="m438.14,273.91c.12,1.33.15,2.75.32,4.05.26-1.16.25-2.76.11-4.02"/>
        <path   d="m454.16,259.66c.09,1.09.12,2.35.36,3.39.14.59.58,1.22.65,1.78.07.45.06.84-.18.96-.46.23-.46-.94-.52-1.49-.16-1.6-.76-3.25-.61-4.89"/>
        <path   d="m454.23,267.84c.04-.48.16-.87.06-1.38-.56.08-.99-1.6-1.13-2.42-.07-.45-.1-.98-.21-1.41-.11-.42-.35-.51-.38-1.07.19,1.26.36,2.67.64,3.83.27,1.15,1.01,1.22.78,2.64"/>
        <path   d="m451,259.3c-.55,3.26,1.67,3.69,1.71,6.7-.11-.01-.25-.11-.34-.23.07-.18.06-.48.1-.61-.2-.12-.39-.25-.58-.3"/>
        <path   d="m451.36,263.9c-.16,1.32.07,2.7.29,4.06-.44-1.46-.8-2.72-1.03-4.28"/>
        <path   d="m448.97,259.23c.16,1.89.25,3.28.75,5,.18.58.6,1.25.66,1.9-.06.09-.11.14-.18.2-.46-.7-.64-1.83-.83-2.83-.17.08-.34.23-.49.39-.39-1.1-.35-2.87-.5-4.12"/>
        <path   d="m448.06,260.41c0,1.51.3,3.66.74,5.03.22.7.7,1.73.13,2.21-.11-.64-.21-1.32-.31-1.98-.27.3-.46.87-.34,1.46-.64-1.22-.27-2.57-.28-3.87-.01-.94-.44-1.98-.17-2.87"/>
        <path   d="m423.33,263.87c0-.47.07-.88-.08-1.39-.55.14-1.15-1.5-1.37-2.28-.13-.44-.21-.97-.36-1.38-.16-.41-.41-.48-.49-1.02.31,1.23.63,2.61,1.02,3.74.39,1.11,1.14,1.11,1.05,2.54"/>
        <path   d="m420.41,264.35c-.21,3.3,2.05,3.49,2.41,6.49-.11,0-.26-.09-.37-.19.06-.2,0-.49.04-.63-.21-.1-.41-.21-.61-.24"/>
        <path   d="m419.92,258.09c-.04,1.33.34,2.69.7,4-.58-1.4-1.08-2.6-1.47-4.14"/>
        <path   d="m418.04,261.03c.35,1.86.58,3.24,1.26,4.89.24.56.73,1.18.86,1.84-.04.09-.09.16-.16.2-.53-.64-.83-1.74-1.11-2.72-.16.1-.32.26-.45.44-.51-1.06-.66-2.82-.93-4.05"/>
        <path   d="m416.63,263.19c.17,1.51.68,3.62,1.26,4.92.3.68.88,1.66.37,2.2-.18-.63-.34-1.29-.51-1.92-.24.33-.37.9-.19,1.48-.76-1.15-.54-2.54-.69-3.81-.11-.94-.64-1.94-.47-2.85"/>
        <path   d="m417.11,263.34c0-.47.08-.88-.08-1.38-.55.14-1.15-1.5-1.37-2.28-.13-.44-.2-.97-.36-1.38-.16-.42-.41-.48-.49-1.02.31,1.23.63,2.61,1.02,3.74.39,1.1,1.14,1.11,1.05,2.53"/>
        <path   d="m413.91,263.48c-.21,3.31,2.05,3.5,2.4,6.49-.11,0-.27-.08-.37-.18.05-.19,0-.5.03-.63-.22-.1-.41-.21-.61-.24"/>
        <path   d="m414.24,258.05c-.03,1.32.35,2.68.7,4-.59-1.41-1.08-2.61-1.48-4.15"/>
        <path   d="m412.25,261.12c.35,1.85.58,3.23,1.26,4.89.23.56.74,1.17.86,1.83-.05.08-.09.15-.16.2-.53-.63-.83-1.74-1.11-2.71-.16.1-.32.26-.44.44-.51-1.06-.66-2.82-.93-4.05"/>
        <path   d="m411.45,263.06c.17,1.5.68,3.62,1.26,4.92.3.68.88,1.65.37,2.2-.18-.64-.34-1.29-.51-1.93-.24.32-.37.91-.18,1.49-.77-1.16-.54-2.55-.68-3.82-.11-.95-.65-1.94-.47-2.85"/>
        <path   d="m421.56,283c.04-.47.16-.87.07-1.38-.56.08-.99-1.61-1.13-2.42-.07-.45-.1-.98-.21-1.4-.11-.43-.36-.52-.39-1.08.18,1.26.36,2.67.63,3.83.27,1.15,1.01,1.22.79,2.65"/>
        <path   d="m418.18,276.82c-.55,3.27,1.67,3.69,1.72,6.7-.11-.01-.25-.1-.34-.22.07-.18.05-.49.1-.62-.21-.11-.39-.25-.59-.3"/>
        <path   d="m419.23,272.95c-.17,1.32.07,2.72.29,4.06-.44-1.45-.8-2.72-1.04-4.28"/>
        <path   d="m416.68,274.45c.16,1.89.25,3.28.76,5,.17.59.6,1.25.66,1.91-.06.09-.11.14-.18.18-.46-.68-.64-1.82-.82-2.81-.17.07-.35.22-.48.38-.4-1.1-.37-2.87-.51-4.12"/>
        <path   d="m415.68,275.68c.01,1.52.3,3.67.74,5.03.22.7.71,1.73.13,2.22-.12-.65-.2-1.33-.31-1.98-.28.3-.46.87-.34,1.47-.63-1.22-.27-2.6-.27-3.87-.01-.95-.45-1.99-.18-2.88"/>
        <path   d="m456.2,261.49c-.27,1.11.62,3.36.85,4.46.07.4.48,1.81.35,2.21-.23.71-.52-.03-.63-.45-.13-.57-.03-1.2-.1-1.77-.09-.57-.31-1.09-.43-1.66-.26-1.3-.54-2.66-.65-3.99.12.3.27.73.29,1.17"/>
        <path   d="m375.67,265.99c.36,1.17,1.15,2.77,1.02,3.85"/>
        <path   d="m374.59,264.45c.03.37.17.97.36,1.45"/>
        <path   d="m376.41,274.65c-.5-1.49-1.26-2.55-1.79-4.02-.4-1.05-.78-2.14-1.13-3.26"/>
        <path   d="m360.42,270.86c.59,1.89.33,4.69,1.69,6.22"/>
        <path   d="m357.99,271.11c.2,1.38-.53,1.94.1,3.51.32.84,1.96,2.43,1.4,3.31"/>
        <path   d="m355.65,272.64c.94,1.49,2.2,3.52,2.71,5.43"/>
        <path   d="m355.65,266.9c.34,1.08,1.38,3.04,1.44,4"/>
        <path   d="m353,263.65c.35,1.68.56,3.3,1.47,4.74.89,1.38,1.21,1.41,1.71,3.31"/>
        <path   d="m356.52,277.59c-2.93-1.8-.87-6.21-2.4-8.22"/>
        <path   d="m350.69,264.15c.52,1.46.72,3.01,1.41,4.39.61,1.22,1.5,2.25,1.49,3.97"/>
        <path   d="m354.04,278.41c-.42-1.34-.88-2.68-1.27-4.03-.25-.88-.33-2.2-.67-2.42"/>
        <path   d="m350.79,271.13c.82,1.2.61,2.12,1.16,3.4"/>
        <path   d="m351.51,279.18c-.17-.56-.36-1.15-.54-1.69-.18-.01-.42-.17-.58-.13"/>
        <path   d="m345.88,266.22c.45,1.45.65,3.11.03,3.89"/>
        <path   d="m347.4,269.72c1.17.74.37,3.64,1.2,4.99"/>
        <path   d="m349.97,273.96c-.03.59.2,1.13.49,1.57"/>
        <path   d="m347.93,278.3c-.81-.37-.8-1.9-1.1-2.91"/>
        <path   d="m346.64,271.64c.26.53.37,1.04.23,1.32"/>
        <path   d="m345.98,274.83c-1.3-1.14-1.5-4.48-2.43-6.07"/>
        <path   d="m345,275.11c-.26,2,1.44,4.85,1.74,7.06"/>
        <path   d="m342.58,277.1c-.22-1.29-.07-7.24-1.25-7.26"/>
        <path   d="m343.97,276.39c-.04,1.52,1.24,4.92,1.75,6.53"/>
        <path   d="m343.21,280.06c-.68-2.2-2.02-5.41-1.96-7.52"/>
        <path   d="m341.09,278.06c.49,1.59,1.34,3.62,1.23,5.15"/>
        <path   d="m339.08,274.61c.29.94,1.44,3.94,1.2,4.47"/>
        <path   d="m339.53,282.57c-.64-2.07-1.45-3.84-.75-5.39.59,1.95.66,4.04,1.36,6.21"/>
        <path   d="m337.5,276.77c.12-.75.06-2.06-.09-2.97"/>
        <path   d="m337.92,283.5c-.57-1.83-1.01-3.39-1.14-5.19-.85-.15-.23,1.35.06,2.29"/>
        <path   d="m335.05,271.53c.19,1.59,1.94,4.85,1.33,6.04-.62-1.17-1.13-6.25-2.24-6.01"/>
        <path   d="m334.57,279.63c.51,1.49,1.5,3.6.93,4.81-.56-1.86-.83-3.42-1.25-5.21"/>
        <path   d="m331.13,271.85c.25.82,2.17,5.84,1.69,6-.86.26-1.71-4.86-1.97-5.7"/>
        <path   d="m331.16,277.47c.83,1.6,2.5,5.07,1.6,6.27.03.37-.03.47-.05.78-.77-2.1.15-3.65-.85-5.4"/>
        <path   d="m328.48,273.1c.64,1.94,1.52,3.71,2,5.7-.91.59-1.91-3.8-2.01-4.81"/>
        <path   d="m326.89,274.18c.46,1.49.99,3.09,1.31,4.54.27,1.21.68,1.78,1.01,2.94-.74-1.59-1.92-5.09-2.13-6.87"/>
        <path   d="m327.51,278.61c.72,2.34,1.5,4.56,2.19,6.84-1.13.93-1.38-3.56-1.76-4.56"/>
        <path   d="m324.54,274.29c.52,1.95,1.39,3.76,1.95,5.72-.04,0-.19.07-.23.1-.46-.87-.71-2.05-1.05-3.01.12.69.37,1.39.57,2.07-.89-.89-1.31-2.67-1.72-4.13.47.29.63,1.18,1.14,1.64"/>
        <path   d="m326.16,284.13c-.45-2.04-1.94-4.17-1.51-6.35.3.89.15,1.53.34,2.36.19.76.55,1.16.76,1.83.38,1.19.88,3.32,1.04,4.52"/>
        <path   d="m322.83,281.07c.44,1.43,1.91,4.59,1.9,5.69-1.1-1.44-1.39-2.58-2.01-4.57"/>
        <path   d="m358.51,270.03c.25.87-.22,1.15-.1,1.87.13.74.67,1.46.95,2.08"/>
        <path   d="m348.84,264.75c.32.62.57,1.25.81,1.97.18.56.24,1.06.52,1.44.49.69,1.1.79,1.39,1.93"/>
        <path   d="m349.26,272.53c.09.35.28.59.47.73.11-.24.32-.94.28-1.33-.07-.71-.64-1.64-.85-2.34"/>
        <path   d="m348.66,276.44c-.17.04-.25.24-.26.52.05.03.13.03.16.03.03.05.05.1.04.15-.14-.11-.05.03-.17-.19.28.36.37.91.53,1.43.2.64.36.88.03,1.19"/>
        <path   d="m330.15,279.44c.13,1.02-.25,1.92.27,2.94.5.95,1.05,1.42,1.28,2.68"/>
        <path   d="m334.04,276.84c-.34-.49-.7-1.12-.94-1.73"/>
        <path   d="m374.57,275.13c-.06-.57-.03-1.08-.28-1.66-.65.24-1.59-1.66-1.95-2.56-.21-.51-.38-1.14-.61-1.62-.25-.47-.56-.52-.73-1.16.54,1.43,1.1,3.05,1.73,4.37.62,1.28,1.51,1.18,1.6,2.92"/>
        <path   d="m368.19,267.76c.19,3.99,2.93,3.93,3.76,7.49-.13.02-.33-.07-.47-.18.05-.24-.05-.6-.03-.76-.27-.09-.53-.2-.77-.21"/>
        <path   d="m365.87,260.4c.67,2.19,1.12,3.83,2.16,5.72.36.64,1.04,1.33,1.27,2.11-.04.12-.09.2-.16.25-.72-.69-1.23-1.99-1.69-3.12-.18.14-.35.36-.48.59-.74-1.2-1.16-3.3-1.65-4.75"/>
        <path   d="m366.31,268.16c.41,1.79,1.3,4.27,2.16,5.75.45.77,1.28,1.88.74,2.59-.3-.73-.58-1.52-.87-2.25-.24.43-.33,1.14-.03,1.8-1.06-1.28-.98-2.99-1.33-4.5-.25-1.12-1.02-2.25-.94-3.36"/>
        <path   d="m366.81,268.42c-.07-.57-.03-1.07-.28-1.65-.64.24-1.59-1.65-1.95-2.56-.21-.51-.38-1.14-.61-1.62-.25-.49-.55-.52-.73-1.17.54,1.44,1.1,3.07,1.73,4.37.62,1.27,1.51,1.18,1.6,2.91"/>
        <path   d="m362.85,268.45c.19,4.01,2.93,3.95,3.76,7.5-.14,0-.33-.06-.46-.17.03-.23-.06-.61-.04-.77-.28-.09-.53-.19-.77-.21"/>
        <path   d="m361.46,262.06c.14,1.59.77,3.18,1.37,4.72-.89-1.61-1.63-2.99-2.32-4.8"/>
        <path   d="m360.04,265.32c.67,2.18,1.13,3.82,2.17,5.72.36.64,1.04,1.32,1.28,2.09-.04.12-.09.2-.16.26-.71-.69-1.23-1.98-1.7-3.11-.18.14-.35.35-.47.59-.75-1.21-1.17-3.31-1.66-4.75"/>
        <path   d="m356.93,262.23c.4,1.78,1.3,4.27,2.16,5.75.45.78,1.28,1.88.73,2.6-.3-.74-.57-1.51-.87-2.25-.23.42-.32,1.14-.02,1.81-1.08-1.29-.99-3-1.33-4.51-.26-1.13-1.04-2.25-.94-3.37"/>
        <path   d="m373.9,266.37c-.07-.57-.03-1.08-.27-1.65-.64.24-1.58-1.66-1.97-2.58-.2-.5-.36-1.13-.61-1.61-.24-.48-.55-.52-.73-1.17.54,1.45,1.12,3.07,1.73,4.36.62,1.29,1.51,1.19,1.6,2.93"/>
        <path   d="m368.28,260.26c.19,4.01,2.92,3.95,3.75,7.49-.13.02-.32-.05-.47-.17.04-.24-.06-.59-.03-.77-.27-.08-.53-.2-.77-.2"/>
        <path   d="m322.44,274.49c.65,1.94,1.52,3.71,2.01,5.69-.91.59-1.9-3.8-2.01-4.8"/>
        <path   d="m321.07,274.38c.45,1.48.99,3.09,1.31,4.54.27,1.21.68,1.77,1,2.94-.74-1.59-1.92-5.09-2.13-6.86"/>
        <path   d="m320.17,279.47c.73,2.34,1.51,4.56,2.2,6.85-1.13.93-1.39-3.57-1.76-4.56"/>
        <path   d="m318.97,276.01c.51,1.94,1.39,3.75,1.95,5.71-.04.01-.19.08-.22.1-.46-.87-.71-2.05-1.05-3,.12.68.37,1.39.57,2.06-.89-.88-1.31-2.67-1.72-4.13.47.29.63,1.18,1.13,1.63"/>
        <path   d="m319.76,284.96c-.45-2.04-1.94-4.17-1.51-6.35.3.89.15,1.53.34,2.36.2.77.55,1.17.76,1.84.38,1.19.88,3.32,1.04,4.52"/>
        <path   d="m316.44,281.63c-.45-2.04-1.94-4.17-1.51-6.35.3.89.15,1.53.34,2.36.2.77.55,1.17.76,1.84.38,1.19.88,3.32,1.04,4.52"/>
      </g>
    </g>
  </g>
    </svg>
  )
}

export default ShortDeportivoLateralSvg
